const component = {
  MuiInputLabel: {
    styleOverrides: {
      root: ({ theme }) => ({
        ...theme.typography.bodyText,
        color: theme.palette.text.light,
        paddingLeft: "10px",
        lineHeight: "unset",
        transform: "translate(10px, 16px) scale(1)",
        "&.Mui-focused": {
          color: theme.palette.text.light,
          transform: "translate(10px, 10px) scale(0.8)",
        },
        "&.MuiFormLabel-filled": {
          color: theme.palette.text.main,
          transform: "translate(10px, 10px) scale(0.8)",
          backgroundColor: "transparent !important",
        },
      }),
      asterisk: { color: "red" },
    },
  },
};

export default component;
