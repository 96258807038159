import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  IconButton,
  Stack,
  Button,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import {
  FormInputText,
  FormInputDropdownSearch,
  FormInputDate,
} from "../../../components/FormInput";
import { validatorRequired, validatorDiscount } from "../../../util/validators";
import { useFieldArray, useWatch, useForm } from "react-hook-form";

import { DeleteOutlineRounded, AddRounded } from "@mui/icons-material";
import moment from "moment";
import { TableContent } from "../../../constants/Invoicing/TableContent";
import { TABLE } from "../../../constants/Invoicing/Table";
import useSnack from "../../../hooks/useSnack";
import { TableNoItemsOverlay } from "../../../assets/svg/table-no-items-overlay";
import { SnackType } from "../../../enums/Snacks";

export const PaymentTermForm = ({
  control,
  watch,
  setValue,
  options,
  totalValue,
  // companySetting = {},
  // activeTaxKey,
  // currentTransaction = false,
}) => {
  //* const function section
  const paymentTermOptions = options.map((term) => {
    return {
      label: term.name,
      value: term.payment_term_id,
    };
  });
  const [paymentTermFocus, setPaymentTermFocus] = useState(null);
  const { callSnack } = useSnack();

  //   const productOptions = products.map((product) => {
  //     return {
  //       value: product.product_id,
  //       label: product.name,
  //       // group: product.tag_group,
  //     };
  //   });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "payment_terms",
  });
  //   const watchActiveTax = watch("active_tax");
  //   const watchTaxInclusive = useWatch({
  //     control,
  //     name: `tax_inclusive`,
  //   });

  //   const taxList = watchActiveTax ? transactionTax[activeTaxKey] : [];

  const defaultValues = {
    index: 0,
    // form_item_id: null,
    payment_term_id: null,
    due_on: moment(new Date()),
    amount: 0.0,
    description: "",
  };
  const { handleSubmit } = useForm({
    defaultValues: defaultValues,
    shouldFocusError: true,
    shouldUnregister: false,
  });

  const paymentTermsWatcher = useWatch({
    control,
    name: `payment_terms`,
  });

  const handleSubmitNewOptions = (data) => {
    // const defaultPaymentTerm = companySetting.default_payment_term;
    const paymentTermsList =
      paymentTermsWatcher !== undefined ? paymentTermsWatcher : [];
    const index = data.index + paymentTermsList.length;
    if (index > 0) {
      const watchItemTotalAmount = watch("form_items_total");

      const paymentTermsTotal = paymentTermsList.reduce((total, term) => {
        if (typeof term.amount === "string" && term.amount.endsWith("%")) {
          const value =
            (watchItemTotalAmount * parseFloat(term.amount.replace(/%$/, ""))) /
            100;
          return total + value;
        }
        return total + term.amount;
      }, 0);
      const minusAmount = watchItemTotalAmount - paymentTermsTotal;
      const getNewOptionAmount =
        minusAmount > 0 ? (minusAmount / watchItemTotalAmount) * 100 : 0;
      const result = {
        ...data,
        index: index,
        amount: getNewOptionAmount.toFixed(2) + "%",
      };

      append(result);
    } else {
      const result = {
        ...data,
        index: index,
        amount: "100.00%",
      };

      append(result);
    }
  };

  const handleRemoveNewOptions = (indexToRemove) => {
    remove(indexToRemove);
  };

  const amountFocusedValue = (index) => {
    if (
      paymentTermsWatcher !== undefined &&
      paymentTermsWatcher[index] !== undefined
    ) {
      const matchedIndex =
        paymentTermFocus !== null && paymentTermFocus === index;
      const isAmountPercent = paymentTermsWatcher[index].amount
        ? paymentTermsWatcher[index].amount.endsWith("%")
        : false;

      if (matchedIndex) {
        return null;
      }
      if (isAmountPercent) {
        const amountValue =
          (parseFloat(totalValue) *
            parseFloat(paymentTermsWatcher[index].amount.replace(/%$/, ""))) /
          100;
        return amountValue.toFixed(2);
      }

      return null;
    }
    return null;
  };

  const plainText = (text, isBold = false) => {
    return (
      <Typography
        sx={({ typography }) => ({
          ...typography[isBold ? "bodyTextBold" : "bodyText"],
        })}
      >
        {text}
      </Typography>
    );
  };

  const addButton = (buttonText, onClick) => {
    return (
      <Button
        variant="contained"
        onClick={handleSubmit(onClick)}
        startIcon={
          <AddRounded
            sx={({ icons, palette }) => ({
              ...icons.standard,
              color: palette.primary.main,
            })}
          />
        }
        sx={{
          width: "265px !important",
          marginLeft: "15px !important",
          marginTop: "30px !important",
        }}
      >
        {buttonText}
      </Button>
    );
  };

  const numberingCell = (index) => {
    return (
      <Box
        sx={{ alignSelf: "center", textAlign: "center", marginTop: "20px" }}
        width={"35px"}
      >
        {plainText((index + 1).toString())}
      </Box>
    );
  };

  const gridHeaderRenderer = (headers) => {
    return (
      <Box sx={{ borderBottom: "1px solid #E6E6E6" }}>
        <Grid container>
          <Box width={"35px"}></Box>
          {headers.map((header) => {
            const gridFlex = header.flex > 0 ? header.flex : true;
            return (
              <Grid
                key={`header_${header.headerName}`}
                item
                xs={gridFlex}
                sm={gridFlex}
              >
                <Box padding={"10px 0"}>
                  <Typography
                    sx={({ typography }) => ({
                      ...typography.bodyTextTitle,
                    })}
                  >
                    {header.headerName}
                  </Typography>
                </Box>
              </Grid>
            );
          })}
          <Box width={"35px"}></Box>
        </Grid>
      </Box>
    );
  };

  const onChangeAmount = (value, index) => {
    const priceRegex = new RegExp(/^\d+(\.\d{2})?%?$|^[0-9]+%?$/);
    if (priceRegex.test(value)) {
      if (value.endsWith("%")) {
        setValue(`payment_terms.[${index}].amount`, value, {
          shouldDirty: true,
        });

        return value;
      }
      const result = parseFloat(value) ? parseFloat(value).toFixed(2) : value;
      setValue(`payment_terms.[${index}].amount`, result, {
        shouldDirty: true,
      });

      return result;
    }
    callSnack({
      type: SnackType.Error,
      message: "Invalid Format",
      timeout: 3000,
    });
    return "0.00";
  };

  const onAmountFocus = (index) => {
    setPaymentTermFocus(index);
  };

  const clearPaymentTerm = (index) => {
    setValue(`payment_terms[${index}].payment_term_id`, null, {
      shouldDirty: true,
    });
  };

  const paymentTermOnChange = (payload, index) => {
    const isObject = typeof payload === "object";

    const getPaymentTerm = (value) => {
      const paymentTerm = options.find(
        (term) => term.payment_term_id === value
      );

      return paymentTerm ? paymentTerm : null;
    };

    const paymentTermObject = isObject
      ? getPaymentTerm(payload.target.value)
      : getPaymentTerm(payload);

    if (paymentTermObject) {
      // const todayDate = new Date();
      const watchDateValue = watch("date");

      const momentTodayDate = moment(watchDateValue);
      const paymentTermAddedValue = momentTodayDate.add(
        paymentTermObject.value,
        paymentTermObject.name === "EOFM" ? "months" : "days"
      );
      const paymentTermEndOf =
        paymentTermObject.name === "EOFM" || paymentTermObject.name === "EOM"
          ? paymentTermAddedValue.endOf("month")
          : paymentTermAddedValue;
      const paymentTermDate = paymentTermEndOf.format("YYYY-MM-DD");

      const result = moment(new Date(paymentTermDate));
      setValue(`payment_terms[${index}].due_on`, result, {
        shouldDirty: true,
      });
    } else {
      setValue(`payment_terms[${index}].payment_term_id`, null, {
        shouldDirty: true,
      });
    }
  };

  const gridRowsRenderer = (
    <Stack>
      {fields.length === 0 ? (
        <TableNoItemsOverlay />
      ) : (
        fields.map((data, index) => {
          //   const { type = null } = data;
          //   const itemTotalAmount =
          //     paymentTermsWatcher && paymentTermsWatcher[index]?.total_amount;

          return (
            <Grid key={data.id} container>
              {numberingCell(index)}
              <Grid
                item
                xs={2}
                sm={2}
                sx={{ paddingRight: "10px", marginTop: "20px" }}
              >
                <FormInputDropdownSearch
                  control={control}
                  options={paymentTermOptions}
                  name={`payment_terms[${index}].payment_term_id`}
                  variant={"filled"}
                  label={"Select payment term"}
                  formOnChange={(payload) => {
                    paymentTermOnChange(payload, index);
                  }}
                  disableClearable={false}
                  formOnClear={() => clearPaymentTerm(index)}
                />
              </Grid>

              <Grid
                item
                xs={3}
                sm={3}
                sx={{ paddingRight: "10px", marginTop: "20px" }}
              >
                {/* <FormInputText
                  isDisabled={true}
                  control={control}
                  name={`payment_terms[${index}].due_on`}
                  // variant={"standard"}
                  required
                  rules={{
                    required: validatorRequired(),
                  }}
                /> */}

                <FormInputDate
                  isDisabled={
                    paymentTermsWatcher[index]
                      ? paymentTermsWatcher[index].payment_term_id
                      : true
                  }
                  control={control}
                  name={`payment_terms[${index}].due_on`}
                  label={"Due Date"}
                  required
                  rules={{
                    required: validatorRequired(),
                  }}
                />
              </Grid>

              <Grid
                item
                xs={3}
                sm={3}
                sx={{ paddingRight: "10px", marginTop: "20px", border: "none" }}
              >
                <FormInputText
                  control={control}
                  type={"string"}
                  name={`payment_terms[${index}].amount`}
                  // required
                  rules={{
                    pattern: validatorDiscount(),
                  }}
                  placeholder={"Amt or %"}
                  variant={"filled"}
                  formOnBlur={(value) => {
                    setPaymentTermFocus(null);
                    return onChangeAmount(value, index);
                  }}
                  formOnFocus={() => {
                    onAmountFocus(index);
                  }}
                  formValue={amountFocusedValue(index)}
                />
              </Grid>

              <Grid
                item
                xs={true}
                sm={true}
                sx={{ paddingRight: "10px", marginTop: "20px" }}
              >
                <FormInputText
                  control={control}
                  type={"string"}
                  name={`payment_terms[${index}].description`}
                  disabledErrorText={true}
                  label={"Description"}
                  variant={"filled"}
                />
              </Grid>
              <Box
                sx={{
                  alignSelf: "center",
                  textAlign: "center",
                  marginTop: "20px",
                }}
                width={"35px"}
              >
                <IconButton
                  onClick={() => {
                    handleRemoveNewOptions(index);
                  }}
                  sx={({ icons, palette }) => ({
                    ...icons.standard.default,
                    color: palette.icon.delete,
                    "&:hover": {
                      color: palette.icon.delete,
                    },
                  })}
                >
                  <DeleteOutlineRounded fontSize="inherit" />
                </IconButton>
              </Box>
            </Grid>
          );
        })
      )}
    </Stack>
  );

  return (
    <Stack
      sx={{
        marginTop: "20px",
      }}
      spacing={2}
    >
      <Box>
        {gridHeaderRenderer(
          TableContent[TABLE.TRANSACTIONS.TRANSACTION_PAYMENT_TERMS].columns()
        )}
        {gridRowsRenderer}
      </Box>
      {addButton("Term", handleSubmitNewOptions)}
    </Stack>
  );
};

PaymentTermForm.propTypes = {
  control: PropTypes.any,
  watch: PropTypes.any,
  setValue: PropTypes.any,
  activeTaxKey: PropTypes.any,
  options: PropTypes.any,
  totalValue: PropTypes.any,
};
