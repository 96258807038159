import { call, delay, put, takeLatest } from "redux-saga/effects";

import {
  setNewSystemIcon,
  setNewSystemIconSuccess,
  setNewSystemIconError,
  setCurrSelectedTab,
  setCurrSelectedTabSuccess,
  setCurrSelectedTabError,
} from "../stores/slices/systemSlices";

function* setNewSystemIconSaga({ payload }) {
  try {
    yield put(setNewSystemIconSuccess(payload));
  } catch (error) {
    yield put(setNewSystemIconError(error));
    console.error(error);
  }
}

function* setCurrSelectedTabSaga({ payload }) {
  try {
    window.location.replace(`/#/${payload}`);
    yield put(setCurrSelectedTabSuccess(payload));
  } catch (error) {
    yield put(setCurrSelectedTabError(error));
    console.error(error);
  }
}

export function* systemSagaWatcher() {
  yield takeLatest(setNewSystemIcon.type, setNewSystemIconSaga);
  yield takeLatest(setCurrSelectedTab.type, setCurrSelectedTabSaga);
}
