import { call, delay, put, takeLatest } from "redux-saga/effects";
import { setGaPortalContext, setGaPortalContextError, setGaPortalContextSuccess } from "../stores/slices/gaPortalSlices";


function* setGaPortalContextSaga({payload}){
    try{
        yield put(setGaPortalContextSuccess(payload));
    } catch (error){
        
        yield put(setGaPortalContextError(error));
        console.error(error);
    }
}

export function* gaPortalSagaWatcher(){
    yield takeLatest(setGaPortalContext.type, setGaPortalContextSaga);
}