import React, { useState, useEffect } from 'react'
import { selectAnalyticsContext, selectNewGraphData, setAnalyticsContext } from '../../../stores/slices/analyticsSlices';
import { useDispatch } from 'react-redux';

function FilterComponent(props) {

    const {
        filterList,
        analytics,
        filterState,
        dispatchFilterList,
    } = selectAnalyticsContext();

    const xAxis = analytics?.xAxis;
    const newGraphData = selectNewGraphData();
    const graph = newGraphData.GraphData;
    const [filterCheckState, setFilterCheckedState] = useState(
        new Array(graph?.length).fill(false)
    )

    const dispatch = useDispatch();

    useEffect(() => {
        var selectedFilter = filterList;
        var newRawData = graph?.map(item => !selectedFilter?.includes(item[xAxis[0]?.["key"]]) ? false : true);

        setFilterCheckedState(newRawData)
    },[graph])

    useEffect(() => {

        const newFilterList = [];

        filterCheckState?.map((item, index) => {
            if(item === true){
                newFilterList.push(graph[index][xAxis[0]?.["key"]])
            }
        })

        dispatch(setAnalyticsContext({filterList : newFilterList}))
        // dispatchFilterList({type: "CHANGE_FILTER_LIST", payload: newFilterList})

    },[filterCheckState, graph])

    const handleOnChange = (position) => {
        const updatedCheckedState = filterCheckState.map((item, index) =>
            index === position ? !item : item
        );

        setFilterCheckedState(updatedCheckedState);

    };

  return (
    <div className={`filterContainer ${filterState && 'filterContainer-active'}`}>
        <div className='filterTitle'>
            <span>Filter</span>
        </div>
        <div className='filterList'>
            {graph && graph.map((item, index) => {
                return(
                    <div className='filter' key={index}>
                        <div className='checkbox'>
                            <label style={{ display: 'flex', alignItems: 'center' }}>
                                <input
                                    className=""
                                    value={item[xAxis[0]?.["key"]]}
                                    checked={filterCheckState !== undefined ? filterCheckState[index] : false}
                                    type="checkbox"
                                    onChange={() => handleOnChange(index)}
                                />
                                <span className="checkmark"></span>
                            </label>
                        </div>
                        <div className='name'>
                            <span>{item[xAxis[0]?.["key"]]}</span>
                        </div>
                    </div>
                )
            })
            }
        </div>
    </div>
  )
}

export default FilterComponent