import React from 'react';
import "./C_DialogButton.scss";

const C_DialogButton = (props) => {
    return (
        <div className='dialog-btn' style={{
                width: props.width,
                height: props.height,
                background: props.background,
                color: props.color,
                borderColor: props.borderColor,
            }}
            onClick={props.onClick}
            data-bs-target={'#'+props.targetID} data-bs-toggle={props.toggleAction} data-bs-dismiss={props.dismissAction}>
                {props.buttonText}
        </div>
    );
}

export default C_DialogButton;