import { createSlice, current } from "@reduxjs/toolkit"

import { useAppSelector } from "../../hooks/storeHooks";


const initialState = {
    setFormIDLoading: false,

    setFormIDError: undefined,

    formID: []
}

const formIDSlice = createSlice({
    name: "formID",
    initialState,
    reducers: {
        setFormID(state){
            state.setFormIDLoading = true
        },

        setFormIDSuccess(state, {payload}){
            const currentState = payload
            state.formID = currentState
            state.setFormIDLoading = false
        },

        setFormIDError(state,{payload}){
            const errorState = payload
            state.setFormIDError = errorState
            state.setFormIDLoading = false
        }
    }
})

export const {
    setFormID,
    setFormIDSuccess,
    setFormIDError,
} = formIDSlice.actions

//export selector
// data
export const selectFormID = () =>
  useAppSelector((state) => state.formID.formID);

// loading
export const selectSetFormIDLoading = () =>
  useAppSelector((state) => state.formID.setFormIDLoading);

// error
export const selectSetFormIDError = () =>
  useAppSelector((state) => state.formID.setFormIDError);

const formIDReducer = formIDSlice.reducer

export default formIDReducer
