import React from "react";
import './RefreshLoading.scss'

function RefreshLoading() {

  return (
    <div className="preloadMainContainer">
        <img src={require("../assets/img/preload.gif")}/>
        <span>Loading...</span>
    </div>
  )

};

export default RefreshLoading;