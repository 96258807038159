import React, { useState, useEffect, Fragment } from "react";
import './GraphGenerator.css'
import moment from "moment";
import Graph_Area_LIVE from "../publish/Graph.area.publish";
import Graph_Bar_LIVE from "../publish/Graph.bar.publish";
import Graph_Line_LIVE from "../publish/Graph.line.publish";
import Graph_Pie_LIVE from "../publish/Graph.pie.publish";
import { selectCurrSa } from "../../../stores/slices/persistRolesSlices";
import { selectAnalyticsContext } from "../../../stores/slices/analyticsSlices";
import { selectCurrUserContext } from "../../../stores/slices/companySlices";
import { selectUser } from "../../../stores/slices/userAuthSlices";

function PublishedContent(props) {

    const sidebarCollapse = props.sidebarCollapse
    const searchText = props.searchText

    const user = selectUser();
    const currSa = selectCurrSa();
    const { currUserSA } = selectCurrUserContext();
    const { publishedGraph, publishedGraphData } = selectAnalyticsContext();
    const [content, setContent] = useState([]);
    const [graphData, setGraphData] = useState([]);
    
    useEffect(() => {

        if(publishedGraph !== undefined &&
           publishedGraphData !== undefined &&
           publishedGraph.length > 0 &&
           publishedGraphData.length > 0
          ) {
            const tempPublishedGraph = [...publishedGraph];
            if(currSa){
                const sortedPublishedGraph = tempPublishedGraph.sort((a, b) => moment(b).format() - moment(a).format())
                setContent(sortedPublishedGraph);
            } else {
                const sortedPublishedGraph = tempPublishedGraph.filter((item) => {
                    const viewableBy = item?.ViewableBy;
                    const isViewableByCurrentUser = viewableBy.some(
                    (viewableByUser) =>
                        viewableByUser.uid === user?.uid || item.uid === user?.uid
                    );
                    return isViewableByCurrentUser;
                }).sort((a, b) => moment(b).format() - moment(a).format())
                setContent(sortedPublishedGraph);
            }
           
            setGraphData(publishedGraphData);

           }

    }, [publishedGraph, publishedGraphData])

    useEffect(() => {

        const newContent = content?.filter(item => {
            if(item['GraphTitle'].toString().toLocaleLowerCase().includes(searchText.toString().toLocaleLowerCase())
                || item['GraphType'].toString().toLocaleLowerCase().includes(searchText.toString().toLocaleLowerCase())
            ){
                return item
            }
        })
        setContent(newContent);

    },[searchText])

  return (
    <div className="content">
            {((content && content.length > 0) && (graphData && graphData.length > 0)) ?
            content.map((item, contentIndex) => {
                var type = item.GraphType
                var author = currUserSA.find(user => user.uid === item.UserID);
                var graphDataObj = graphData.find((data => data.AnalyticsID === item.AnalyticsID));
                switch(type){
                    case "Line":
                        return (
                            <Fragment key={contentIndex}>
                                <Graph_Line_LIVE sidebarCollapse={sidebarCollapse} userList={currUserSA} author={author} analytics={item} CreatedAt={moment(item.CreatedAt).format("YYYY-MM-DD")}
                                GraphData={graphDataObj !== undefined ? graphDataObj.processedData : []}/>
                            </Fragment>
                        )
                    case "Bar":
                        return (
                            <Fragment key={contentIndex}>
                                <Graph_Bar_LIVE sidebarCollapse={sidebarCollapse} userList={currUserSA} author={author} analytics={item} CreatedAt={moment(item.CreatedAt).format("YYYY-MM-DD")}
                                GraphData={graphDataObj !== undefined ? graphDataObj.processedData : []}/>
                            </Fragment>
                        )
                    case "Area":
                        return(
                            <Fragment key={contentIndex}>
                                <Graph_Area_LIVE sidebarCollapse={sidebarCollapse} userList={currUserSA} author={author} analytics={item} CreatedAt={moment(item.CreatedAt).format("YYYY-MM-DD")}
                                GraphData={graphDataObj !== undefined ? graphDataObj.processedData : []}/>
                            </Fragment>
                        )
                    case "Pie":
                        return(
                            <Fragment key={contentIndex}>
                                <Graph_Pie_LIVE sidebarCollapse={sidebarCollapse} userList={currUserSA} author={author} analytics={item} CreatedAt={moment(item.CreatedAt).format("YYYY-MM-DD")}
                                GraphData={graphDataObj !== undefined ? graphDataObj.processedData : []}/>
                            </Fragment>
                        )
                    default:
                    break;
                }
            })
            :
            <div className="unpublished">
                <img src={require('../../../assets/img/unpublished.gif')} alt=""/>
                <span>No content has been published</span>
            </div>
        }
    </div>
  )
}

export default PublishedContent