import React, {useEffect, useState, useRef} from 'react'
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

import { selectAnalyticsContext } from '../../../stores/slices/analyticsSlices';
import { selectCurrUserContext } from '../../../stores/slices/companySlices';

function Graph_Area(props) {

  const {
    analytics,
    graphData,
  } = selectAnalyticsContext();

  const editorState = props.editorState;
  const graphRef = useRef(null);
  const [graphWidth, setGraphWidth] = useState(0);
  const [graphHeight, setGraphHeight] = useState(0);
  const yAxis = analytics.yAxis;
  const xAxis = analytics.xAxis;
  const Unit = analytics.Unit;
  const Label = analytics.Label;

  useEffect(() => {
    setGraphWidth(graphRef.current?.clientWidth * 0.9);
    setGraphHeight(graphRef.current?.clientHeight * 0.85);
  },[xAxis, yAxis, graphData, editorState]);

const { currUsers } = selectCurrUserContext();

  const renderArea = () => {
      const area = yAxis.map((value, index) => {
        if(value.key === "Handler"){
          const userObj = currUsers.find(user => user.uid === value.id);
          if(userObj){
           return <Area key={index} type={value.lineType} fillOpacity={value.areaFillOpacity} stroke={value.areaLineColor} strokeWidth={value.areaLineWidth} dataKey={userObj.displayName} fill={value.color} stackId={value.areaStack ? "stack" : null}/>
          }
        } else {
          return (
            <Area key={index} type={value.lineType} fillOpacity={value.areaFillOpacity} stroke={value.areaLineColor} strokeWidth={value.areaLineWidth} dataKey={value.key} fill={value.color} stackId={value.areaStack ? "stack" : null}/>
          );
        }
      })

      return area
  }

  const renderXAxis = () => {
      const x = xAxis.map((value, index) => (
        <XAxis key={index} 
          dataKey={value.key} 
          angle={value.angle} 
          tickSize={value.tickSize} 
          minTickGap={value.minTickGap} 
          padding={{ left: value.paddingLeft, right: value.paddingRight }}
        />
      ))
  
      return x
    
  };

  return (
    <div className='graphContainer' ref={graphRef}>
      <AreaChart
        width={graphWidth}
        height={graphHeight}
        data={graphData}
      >
          <CartesianGrid strokeDasharray="3 3" />
          <YAxis unit={Unit ? Unit : ""} label={{ value: Label ? Label : "", angle: -90, position: 'insideLeft' }}/>
          {renderXAxis()}
          <Tooltip />
          {renderArea()}
      </AreaChart>
    </div>
  )
}

export default Graph_Area