const component = {
  MuiIconButton: {
    styleOverrides: {
      root: ({ theme }) => ({
        "&.sidebarButton:hover": {
          backgroundColor: theme.palette.background.white,
          color: theme.palette.text.primary,
          border: `2px solid ${theme.palette.border.active}`,
        },

        "&.MuiIconButton-colorInfo": {
          color: theme.palette.icon.primary,
        },

        "&.MuiIconButton-colorInfo.Mui-disabled": {
          color: theme.palette.icon.disabled,
        },

        "&.primaryBtn": {
          color: theme.palette.primary.white,
          width: "40px",
          height: "40px",
          backgroundColor: theme.palette.primary.main,
          border: `1px solid ${theme.palette.primary.main}`,
          borderRadius: "4px",
          "& svg": {
            color: theme.palette.primary.white,
          },
        },

        "&.primaryBtn:active": {
          "& svg ": {
            color: theme.palette.action.main,
          },
          borderRadius: "4px",
          border: `1px solid ${theme.palette.action.main}`,
          color: theme.palette.action.main,
          backgroundColor: theme.palette.primary.white,
        },
      }),
    },
  },
};

export default component;
