import React, {
  useState,
  useEffect,
  useRef,
  Fragment,
} from "react";
import './GaPortal.scss'
import moment from "moment";
import { useDispatch } from "react-redux";
import C_Loading from "../../components/C_Loading";
import { child, equalTo, get,orderByChild, query, ref, update} from 'firebase/database'
import { db } from '../../firebaseInit';
import { Table, Column } from "react-virtualized";
import Draggable from "react-draggable";
import { BiSearch, BiSortAlt2 } from "react-icons/bi";
import { MdFilterList, MdOutlineViewColumn } from "react-icons/md";
import { BsArrowDown, BsArrowUp, BsBoxFill, BsThreeDotsVertical } from "react-icons/bs";
import { country_and_states } from "../../enums/json/country-states";
import { selectGaPortalContext } from "../../stores/slices/gaPortalSlices";
import { selectUser, setUser } from "../../stores/slices/userAuthSlices";
import { Modal } from "bootstrap";
import C_PopoutNoti from "../../components/C_PopoutNoti";
import { AiOutlineClose} from "react-icons/ai";
import C_DialogButton from "../../components/C_DialogButton";

function GaMemberList(props) {
  // const updateGaProfile = () => {
  //   var body = {
  //     UserID: user.uid,
  //     TenantID: null
  //   }
  //   dispatch(updateTenantDetails(body))
  // }

  const user = selectUser();
  const dispatch = useDispatch();
  const { currAllUserList, currAdminList, currClientList } = selectGaPortalContext();
  // console.log("Client : ", currClientList)
  // console.log("User : ", currAllUserList)
  const dbRef = ref(db);

  const [searchValue,setSearchValue] = useState("");
  const [searchValueAdmin, setSearchValueAdmin] = useState("");
  const [searchValueMember, setSearchValueMember] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [clientList,setClientList] = useState([]);
  const [adminList, setAdminList] = useState([]);
  const [memberList, setMemberList] = useState([]);
  const [consTenantList,setConsTenantList] = useState([]);
  const [isSort,setIsSort] = useState(false);
  const [isFilter,setIsFilter] = useState(false);
  const [isSearch,setIsSearch] = useState(false);
  const [isSearchAdmin, setIsSearchAdmin] = useState(false);
  const [isSearchMember, setIsSearchMember] = useState(false);
  const [isColumn,setIsColumn] = useState(false);
  const [notifyMessage, setNotifyMessage] = useState("");
  const statusFilter = [true,false];
  const countryFilter = currClientList.length > 0 ? [...new Set(currClientList.map(item=>item?.country))] : [];
  const [selectedStatus,setSelectedStatus] = useState([]);
  const [selectedCountry,setSelectedCountry] = useState([]);
  const [selectedColumn,setSelectedColumn] = useState(["index"]);
  const [sortValue,setSortValue] = useState("Newest");
  const [confirmationUserID, setConfirmationUserID] = useState("");
  const [actionType, setActionType] = useState("");

  const MAX_WIDTH = props.TOTAL_WIDTH * 0.95;
  const TABLE_TOTAL_WIDTH = props.TOTAL_WIDTH;
  const TABLE_TOTAL_HEIGHT = window.innerHeight * 0.8;
  
  const inputRef = useRef(null);
  const adminSearchInputRef = useRef(null);
  const memberSearchInputRef = useRef(null);
  const searchRef = useRef(null);
  const adminSearchRef = useRef(null);
  const memberSearchRef = useRef(null);
  const columnRef = useRef(null);
  const columnContainerRef = useRef(null);
  const filterRef = useRef(null);
  const filterContainerRef = useRef(null);
  const sortRef = useRef(null);
  const sortContainerRef = useRef(null);
  const confirmationRef = useRef(null);

  const countryIcon = country_and_states.icon;

  const tableHeaderList = [
    {
      columnKey: "displayName",
      columnName: "Name"
    },
    {
      columnKey: "isTenant",
      columnName: "Tenant Status"
    },
    {
      columnKey: "createdAt",
      columnName: "Date Joined"
    },
    {
      columnKey: "email",
      columnName: "Email Address"
    },
    {
      columnKey: "phoneNum",
      columnName: "Phone Number"
    },
    {
      columnKey: "country",
      columnName: "Country"
    },
    {
      columnKey: "state",
      columnName: "State"
    },
  ];

  const zumaxHeaderList = [
    {
      columnKey: "action",
      columnName: "Action"
    },
    {
      columnKey: "isTenant",
      columnName: "Tenant Status"
    },
    {
      columnKey: "displayName",
      columnName: "Name"
    },
    {
      columnKey: "email",
      columnName: "Email Address"
    },
  ];

  const [column,setColumn] = useState([]);
  
  const [tableState, setTableState] = useState();
  
  const [tab, setTab] = useState("");

  const showConfirmationModal = () => {
    const modalEle = confirmationRef.current;
    const bsModal = new Modal(modalEle, {
      backdrop: "static",
      keyboard: false,
    });
    bsModal.show();
  };

  const hideConfirmationModal = () => {
    const modalEle = confirmationRef.current;
    const bsModal = Modal.getInstance(modalEle);
    bsModal.hide();
  };


  useEffect(()=>{
    setTab("Client");
    setColumn(tableHeaderList);
  },[])

  useEffect(()=>{
    switch(tab){
      case "Client" :
        const clientList = [...currClientList].sort((a,b)=>a?.createdAt?.localeCompare(b?.createdAt))
        setClientList(clientList);
        // setConsTenantList(clientList);
        setColumn(tableHeaderList)
        break;
      case "Zumax" : 
        const zumaxAdminList = [...currAdminList].sort((a,b)=>a?.createdAt?.localeCompare(b?.createdAt))
        const zumaxMemberList = [...currAllUserList].sort((a,b)=>a?.createdAt?.localeCompare(b?.createdAt))
        setAdminList(zumaxAdminList);
        setMemberList(zumaxMemberList);
        // setConsTenantList(userList);
        setColumn(zumaxHeaderList);
        break;
      default : 
      const defaultClientList = [...currClientList].sort((a,b)=>a?.createdAt?.localeCompare(b?.createdAt))
      setClientList(defaultClientList);
      // setConsTenantList(defaultClientList);
      setColumn(tableHeaderList);
    }
  }, [tab, currClientList, currAdminList, currAllUserList])


  useEffect(() => {
    let widths = {}
    for (var x = 0; x < column.length; x++) {
        widths = {
            ...widths,
            index: 0.05,
            [column[x].columnKey]: tab === "Client" ? (column.length < 4 ? 0.25 : 0.2) : (column.length < 5 ? 0.23 : 0.2),
            action: 0.1
        } 
    }

    setTableState({ width: widths })
}, [column]);

  useEffect(() => {
      function handleClickOutside(event) {
          if (searchRef?.current && !searchRef?.current.contains(event.target)) {
              setIsSearch(false);
          } else {
            if(searchRef?.current){
              searchRef.current.style.backgroundColor = "none"
              searchRef.current.style.cursor = "pointer";
              searchRef.current.classList.remove("hover-effect");
            }
          }

          if (adminSearchRef?.current && !adminSearchRef?.current.contains(event.target)) {
              setIsSearch(false);
          } else {
            if(adminSearchRef?.current){
              adminSearchRef.current.style.backgroundColor = "none"
              adminSearchRef.current.style.cursor = "pointer";
              adminSearchRef.current.classList.remove("hover-effect");
            }
          }

          if (memberSearchRef?.current && !memberSearchRef?.current.contains(event.target)) {
              setIsSearch(false);
          } else {
            if(memberSearchRef?.current){
              memberSearchRef.current.style.backgroundColor = "none"
              memberSearchRef.current.style.cursor = "pointer";
              memberSearchRef.current.classList.remove("hover-effect");
            }
          }

          if (filterRef.current && filterContainerRef.current && !filterRef.current.contains(event.target) && !filterContainerRef.current.contains(event.target)) {
            setIsFilter(false);
          }

          if (sortRef.current && sortContainerRef.current && !sortRef.current.contains(event.target) && !sortContainerRef.current.contains(event.target)) {
            setIsSort(false);
          }

          if (columnRef.current && columnContainerRef.current && !columnRef.current.contains(event.target) && !columnContainerRef.current.contains(event.target)) {
            setIsColumn(false);
          }

      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      
      // Unbind the event listener on cleanup
      return () => {
          document.removeEventListener("mousedown", handleClickOutside)
      };
  }, [adminSearchRef, memberSearchRef, searchRef,filterRef,filterContainerRef,sortRef,columnRef,columnContainerRef]);
  

  useEffect(() => {
      if(selectedCountry.length === 0 && selectedStatus.length === 0) {
          setClientList(currClientList);
          // setConsTenantList(currClientList);
      } else {
        const usersRef = child(dbRef, 'users');
        const queries = [];
        const matchingValues = [];
        const uniqueID = new Set();

          [selectedCountry, selectedStatus].forEach((array) => {
            array.forEach((data) => {
                switch (array) {
                  case selectedCountry:
                    queries.push(get(query(usersRef, orderByChild('country'), equalTo(data))));
                    break;
                  case selectedStatus:
                    queries.push(get(query(usersRef, orderByChild('isTenant'), equalTo(data))));
                    break;
                  default:
                    break;
                }
            });
          });

          Promise.all(queries).then((snapshots) => {
            snapshots.forEach((snapshot) => {
              snapshot.forEach((childSnapshot) => {
                const childValue = childSnapshot.val();
                if (!uniqueID.has(childValue.uid) && !childValue.email.includes("@zumaxdigital.com")) {
                  matchingValues.push(childValue);
                  uniqueID.add(childValue.uid);
                }
              });
            });
          });

        setClientList(matchingValues)
        // setConsTenantList(matchingValues);
      }
        
  },[selectedCountry,selectedStatus])

  useEffect(() => {
    if (isSearch) {
      // Focus the input when switching to the search mode
      inputRef.current.focus();
    }

    if(isSearchAdmin){
      adminSearchInputRef.current.focus();
    }

    if(isSearchMember){
      memberSearchInputRef.current.focus();
    }
  }, [isSearch, isSearchAdmin, isSearchMember]);

  useEffect(()=>{
    let newList = []
    let currentClientList = [...clientList];
    let currentMemberList = [...memberList];
    let currentAdminList = [...adminList];
    if(searchValue !== ""){
       newList = currentClientList.filter(item => {

        const matches = tableHeaderList.map((col,index)=>{
            if(item[col.columnKey] !== undefined && item[col.columnKey].toString().toLocaleLowerCase().includes(searchValue.toString().toLocaleLowerCase())){
              return true
            }
          })
          if(matches.includes(true)){
            return item
          }
      })
      setClientList(newList)
    } else {
      setClientList(currClientList)
    }

    if(searchValueAdmin !== ""){
        newList = currentAdminList.filter(item => {

        const matches = zumaxHeaderList.map((col,index)=>{
            if(item[col.columnKey] !== undefined && item[col.columnKey].toString().toLocaleLowerCase().includes(searchValueAdmin.toString().toLocaleLowerCase())){
              return true
            }
          })
          if(matches.includes(true)){
            return item
          }
      })
      setAdminList(newList)
    } else {
      setAdminList(currAdminList)
    }

    if(searchValueMember !== ""){
        newList = currentMemberList.filter(item => {

        const matches = zumaxHeaderList.map((col,index)=>{
            if(item[col.columnKey] !== undefined && item[col.columnKey].toString().toLocaleLowerCase().includes(searchValueMember.toString().toLocaleLowerCase())){
              return true
            }
          })
          if(matches.includes(true)){
            return item
          }
      })
      setMemberList(newList)
    } else {
      setMemberList(currAllUserList)
    }

  },[searchValue, searchValueAdmin, searchValueMember])

  useEffect(()=>{
    const columnArray = tableHeaderList.filter((col,index)=>{
      if(selectedColumn.length > 1){
        if(selectedColumn.includes(col.columnKey)){
            return col
        }
      } else if(selectedColumn.length === 1){
            return col
      }
    })
    
    setColumn(columnArray)
  },[selectedColumn])

  useEffect(()=>{
    const list = [...clientList];
    
    list?.sort((a,b)=>{
        if(sortValue === "Newest"){
            return moment(b.createdAt) - moment(a.createdAt)
        } else if(sortValue === "Oldest") {
            return moment(a.createdAt) - moment(b.createdAt)
        }
    })
    .map(data=>{
      return data
    })

    setClientList(list);
    // setConsTenantList(list);

},[sortValue])

  useEffect(()=>{

    const newTenantList = [...currClientList].sort((a,b)=>a?.createdAt?.localeCompare(b?.createdAt))
    setClientList(newTenantList);
    // setConsTenantList(newTenantList);

  },[currClientList])

  const handleSelectedStatus = (isSelected,status) => {
    if(isSelected){
      const newList = selectedStatus.filter(data => data !== status);
      setSelectedStatus(newList)
    }else{
      const newList = [...selectedStatus, status];
      setSelectedStatus(newList)
    }
  }

  const handleSelectedCountry = (isSelected,country) => {
    if(isSelected){
      const newList = selectedCountry.filter(data => data !== country);
      setSelectedCountry(newList)
    }else{
      const newList = [...selectedCountry, country];
      setSelectedCountry(newList)
    }
  }

  const handleSelectedColumn = (isSelected,col) => {
    if(isSelected){
      const newList = selectedColumn.filter(data => data !== col);
      setSelectedColumn(newList)
    }else{
      const newList = [...selectedColumn, col];
      setSelectedColumn(newList)
    }
  }

  const _indexRenderer = ({ columnData, dataKey, disableSort, label, sortBy, sortDirection }) => { 
    return (
        <label 
          style={{  
            alignItems: 'center', display: 'flex',
            width:tableState && tableState.width["index"] * TABLE_TOTAL_WIDTH 
          }}
        >
           No.
        </label>
    );
  }

  function _rowRenderer({
    style,
    key,
    index,
    type = null,
  }) {
    const list = type ? (type === "Admin" ? adminList : memberList) : clientList; 
    return (
        <div
          key={key}
          className={`ReactVirtualized__Table__row ${index % 2 && ''} tenant-table-row`}
          role="row"
          style={style}
        >
          <div
              className="ReactVirtualized__Table__rowColumn row-text"
              role="gridcell"
              style={{ overflow: "hidden", justifyContent: 'center' }}
          >
              <label 
                style={{
                  overflow: "hidden", 
                  width: tableState && tableState.width["index"] * TABLE_TOTAL_WIDTH,
                  gap:"0.5rem"
                }}>
                  <span>
                    {index + 1}
                  </span>
              </label>
          </div>
          {column && column.map((item, colIndex) => {
            return(
              <div
              // ${item.columnKey === "action" ? 'justify-content-center' : ""}
                className={`ReactVirtualized__Table__rowColumn row-text 
                ${item.columnKey === "createdAt"} `}
                role="gridcell"
                key={colIndex}
                style={
                  item.columnKey === "country" 
                  ? 
                  {overflow: "hidden", 
                  width: tableState && tableState.width[item.columnKey] * TABLE_TOTAL_WIDTH,
                  gap:"0.5rem"} 
                  : 
                  {overflow: "hidden", 
                  width: tableState && tableState.width[item.columnKey] * TABLE_TOTAL_WIDTH}
                }
                title={list[index][item.columnKey]}
              >
                {item.columnKey === "index" &&
                  <span style={{display:'flex',alignItems:'center'}}>{index + 1}.</span>
                }

                {item.columnKey === "plan" &&
                  <span style={{display:'flex',alignItems:'center'}}>{list[index][item.columnKey]}</span>
                }

                {item.columnKey === "isTenant" &&
                  <div className={`
                    ${list[index][item.columnKey] === true && "active-btn"}
                    ${list[index][item.columnKey] === false && "rejected-btn"}
                    `
                  }>
                      <div className="status-legend"></div>
                      <span style={{display:'flex',alignItems:'center'}}>{list[index][item.columnKey] ? "Tenant" : "Not Tenant"}</span>
                  </div>
                  
                }

                {item.columnKey === "displayName" &&
              
                  <div className="d-flex" style={{gap:"15px"}}>
                    <img className="user-profile-photo" src={list[index]["photoURL"]} alt="" style={{display:'flex',alignItems:'center'}}/>
                    <span style={{display:'flex',alignItems:'center'}}>{list[index][item.columnKey]}</span>
                  </div>
                 
                }

                {item.columnKey === "email" &&
                  <>
                    {/* <div>
                        <HiOutlineEnvelope size={17} color={""} style={{ marginRight: "10px" }} />
                    </div> */}
                    <span style={{display:'flex',alignItems:'center'}}>{list[index][item.columnKey]}</span>
                  </>
                }

                {item.columnKey === "phoneNum" &&
                   <>
                    {/* <div>
                        <HiOutlinePhone size={17} color={""} style={{ marginRight: "10px" }} />
                    </div> */}
                    <span style={{display:'flex',alignItems:'center'}}>{list[index][item.columnKey]}</span>
                  </>
                }

                {item.columnKey === "country" &&
                <>
                  <div>
                      <img className="country-icon" src={countryIcon[list[index][item.columnKey]]?.image} alt="country flag"/>
                  </div>
                  <span style={{display:'flex',alignItems:'center'}}>{list[index][item.columnKey]}</span>
                </>
                }

                {item.columnKey === "state" &&
                  <span style={{display:'flex',alignItems:'center'}}>{list[index][item.columnKey]}</span>
                }

                {item.columnKey === "createdAt" &&
                  <span style={{display:'flex',alignItems:'center'}}>{moment(list[index][item.columnKey]).format("YYYY-MM-DD")}</span>
                }
                {item.columnKey === "action" &&
                  <div className="d-flex w-100 h-100">
                    <div className={`admin-btn ${type === "Admin" ? (user.uid === list[index].uid ? "disabled-admin-btn" : "remove-admin-btn") : "make-admin-btn"} `} 
                      onClick={()=>{
                        //not admin or (if it is admin but uid is not the same)
                        if (type !== "Admin" || user.uid !== list[index].uid) {
                          showConfirmationModal();
                          setConfirmationUserID(list[index].uid);
                          setActionType(type);
                        }
                       
                      }}> 
                      {/* <div className="approval-icon"><BsBoxFill size={16}/></div> */}
                      <span>{type === "Admin" ? "Remove Admin" : "Make Admin"}</span>
                    </div>
                  </div>
                }
              </div>
            )
          })}
          
        </div>
    );
  };

  const headerRenderer = ({ columnData, dataKey, disableSort, label, sortBy, sortDirection }) => {
    return (
        <Fragment key={dataKey}>
          <div className="header-text">
            <div className="ReactVirtualized__Table__headerTruncatedText" 
              style={{  
                width: tableState && tableState.width[dataKey] * TABLE_TOTAL_WIDTH,
                alignItems: 'center', display: 'flex'
              }}>
                <span>{label}</span>
            </div>
            <Draggable
                  axis="x"
                  defaultClassName="DragHandle"
                  defaultClassNameDragging="DragHandleActive"
                  onDrag={(event, { deltaX }) =>
                      resizeRow({
                          dataKey,
                          deltaX
                      })
                  }
                  position={{ x: 0 }}
                  zIndex={999}
            >
                <span className="DragHandleIcon" style={{cursor:'pointer'}}>⋮</span>
            </Draggable>
          </div>
          
        </Fragment>
    );
  };

  const resizeRow = ({ dataKey, deltaX }) => {
    setTableState(prevState => {
        const prevWidths = prevState.width;
        const percentDelta = deltaX / TABLE_TOTAL_WIDTH;

        let nextDataKey = ""
        column?.map((item, index, { length }) => {
            if (item.columnName === dataKey) {
                if (index + 1 === length) {
                    nextDataKey = "IsLastIndex"
                }
                else if (index + 1 <= length) {
                    nextDataKey = column[index + 1].columnName
                }
            }
        })

        if (nextDataKey === "IsLastIndex") {
            return {
                width: {
                    ...prevWidths,
                    [dataKey]: prevWidths[dataKey] + percentDelta,
                }
            };
        }
        else {
            return {
                width: {
                    ...prevWidths,
                    [dataKey]: prevWidths[dataKey] + percentDelta,
                    [nextDataKey]: prevWidths[nextDataKey] - percentDelta
                }
            };
        }

    });
  };

  const onHandleActionAdmin = async () => {
    const uid = confirmationUserID;
    const type = actionType
    if(type === "Member"){
      await update(ref(db, 'users/' + uid), {isGa : true})
      .then(() => {
        // console.log('User updated to GA successfully');
        hideConfirmationModal();
      })
      .catch((error) => {
        console.error('Error updating user to GA:', error);
      });

    } else {
      await update(ref(db, 'users/' + uid), {isGa : false})
      .then(() => {
        // console.log('GA removed successfully');
        hideConfirmationModal();
      })
      .catch((error) => {
        console.error('Error updating user to GA:', error);
      });
    }
  }


  return (
    <>
    <C_Loading isLoading={isLoading}/>
    <div className='user-list-main-container'>
    <C_PopoutNoti triggerSaved={notifyMessage}/>
    {/* Modal start */}
      <div className="modal fade" tabIndex="-1" id="tenant-confirmation" ref={confirmationRef} style={{}}>
        <div className="modal-dialog modal-dialog-centered" style={{}}>
            <div className="modal-content px-3" style={{overflowY: "scroll" }}>
                <div className="modal-header modalHeader">
                    <div className="title">
                        <span className="font-s">
                          Confirmation
                        </span>
                    </div>
                    <AiOutlineClose cursor='pointer' size={16} onClick={()=>{hideConfirmationModal();}}/>
                </div>
                <div className="modal-body action-body">
                    <span className="font-xs" style={{ fontWeight: "bold" }}>
                        Are you sure you want proceed with this action ? 
                    </span>
                    <div className="confirmation-action">
                      <C_DialogButton 
                        background={'#ffffff'}
                        color={'#3eb986'}
                        borderColor={'#3eb986'}
                        buttonText={"Back"}
                        onClick={()=>{hideConfirmationModal();}}
                      />
                      <C_DialogButton
                        color={"white"}
                        background={'#3eb986'}
                        borderColor={'#3eb986'}
                        buttonText={"Yes"}
                        onClick={() => {onHandleActionAdmin();}}
                      />
                    </div>
                  
              </div>
            </div>
        </div>
      </div>
      {/* Modal end */}

      <div className="user-list-header">
          <span>User List</span>
      </div>
      <div className="user-table-outer-container">
        <div className="user-tab-action-container">
          <div className="user-tab-container">
            <div className={`tabs ${tab === "Client" ? "tabs-selected" : ""}`} onClick={() => {setTab("Client");}}><span className="tabs-span">Client</span></div>
            <div className={`tabs ${tab === "Zumax" ? "tabs-selected" : ""}`} onClick={() => setTab("Zumax")}><span className="tabs-span">Zumax</span></div>
          </div>
          <div className="user-action-container">
            <div className="user-list-action">
              {isFilter &&
                <div className="filter-action-container" ref={filterContainerRef}>
                  <div className="filter-list-container">
                    <div className="tag-outer-container">
                      <span>Status</span>
                      <div className="tag-container">
                        {statusFilter.map((status,index)=>{
                          let isSelected = selectedStatus.includes(status)
                          return(
                            <div className={`${isSelected ? "tag-clicked" : "tag-ori"} tag-default`} 
                            onClick={()=>{handleSelectedStatus(isSelected,status)}}>
                              {status === true ? "Active" : "Not Tenant"}
                            </div>
                          )})}
                      </div>
                    </div>
                    <div className="tag-outer-container">
                      <span>Country</span>
                      <div className="tag-container">
                        {countryFilter.map((country,index)=>{
                          let isSelected = selectedCountry.includes(country)
                          return(
                            <div className={`${isSelected ? "tag-clicked" : "tag-ori"} tag-default`} 
                            onClick={()=>{handleSelectedCountry(isSelected,country)}}>
                              {country}
                            </div>
                          )})}
                      </div>
                    </div>
                  </div>
                </div>
              }
              {isSort &&
                <div className="sort-action-container" ref={sortContainerRef}>
                    <div className={`sort-container sort-newest-container-border-bottom`} onClick={()=>{setSortValue("Newest");setIsSort(false);}}>
                        <div><BsArrowDown size={16}/></div>
                        <span>Newest {`(Date Joined)`}</span>
                    </div>
                    <div className={`sort-container`} onClick={()=>{setSortValue("Oldest");setIsSort(false);}}>
                        <div><BsArrowUp size={16}/></div>
                        <span>Oldest {`(Date Joined)`}</span>
                    </div>
                </div>
              }

              {isColumn &&
                <div className="column-action-container" ref={columnContainerRef}>
                    <div className="column-inner-container">
                      {tableHeaderList.map((col,index)=>{
                        if(col.columnKey !== "index" && col.columnKey !== "photoURL"){
                          let isSelected = selectedColumn.includes(col.columnKey)
                          return(
                              <label className="column-content">
                                  <input type='checkbox' style={{ width: '12px', height: '12px' }} 
                                  checked={isSelected} value={col.columnKey} onChange={(e)=>{handleSelectedColumn(isSelected,e.target.value)}}/>
                                  <span className="checkmark"></span>
                                  <span>{col.columnName}</span>
                              </label>
                          );}
                        })}
                    </div>
                </div>
              }
   
              {tab === "Client" && 
              <Fragment>
                <div className={` ${isSearch ? "search-action-btn selected-search-action-btn" : "action-btn"}`} ref={searchRef} onClick={()=>{setIsSearch(true)}}>
                  <div className="action-icon"><BiSearch size={15}/></div>
                  {!isSearch && <span>Search</span>}
                  {isSearch && <input ref={inputRef} value={searchValue} className="search-action" placeholder="Search for everything" onChange={(e)=>{setSearchValue(e.target.value);}}/>}
                </div>
                
            
                <div className={`action-btn ${isColumn ? "selected-action-btn" : ""}`} ref={columnRef} onClick={()=>{setIsColumn(true)}}>
                  <div className="action-icon"><MdOutlineViewColumn size={17}/></div>
                  <span>Column</span>
                </div>
                
    
                <div className={`action-btn ${isFilter ? "selected-action-btn" : ""}`} ref={filterRef} onClick={()=>{setIsFilter(true)}}>
                  <div className="action-icon"><MdFilterList size={17}/></div>
                  <span>Filter</span>
                </div>

        
                <div className={`action-btn ${isSort ? "selected-action-btn" : ""}`} ref={sortRef} onClick={()=>{setIsSort(true)}}>
                  <div className="action-icon"><BiSortAlt2 size={15}/></div>
                  <span>Sort</span>
                </div>
              </Fragment>
              }
            </div>
          </div>
        </div>
        {tab  === "Client" && 
          <div className="client-table">
            <div className="client-table-container">
              <Table
                  width={TABLE_TOTAL_WIDTH}
                  height={TABLE_TOTAL_HEIGHT}
                  headerHeight={50}
                  rowHeight={50}
                  rowCount={clientList?.length}
                  rowGetter={({ index }) => clientList[index]}
                  rowRenderer={({ key, index, style }) => _rowRenderer({ key, index, style })}>
                  <Column
                    dataKey="index"
                    headerRenderer={_indexRenderer}
                  />
                  {column && column.map((item, index) => {
                    return (
                      <Column key={index} headerRenderer={headerRenderer} label={item.columnName} dataKey={item.columnKey} width={tableState && tableState.width[item.columnKey] * TABLE_TOTAL_WIDTH} />
                    )})}
              </Table>
            </div>
          </div>
        }

        {tab === "Zumax" &&
          <div className="admin-table">
            <div className="table-header-container">
              <span className="table-header">Admin List</span>
              <div className={` ${isSearchAdmin ? "search-action-btn selected-search-action-btn" : "action-btn"}`} ref={adminSearchRef} onClick={()=>{setIsSearchAdmin(true)}}>
                  <div className="action-icon"><BiSearch size={15}/></div>
                  {!isSearchAdmin && <span>Search</span>}
                  {isSearchAdmin && <input ref={adminSearchInputRef} value={searchValueAdmin} className="search-action" placeholder="Search for everything" onChange={(e)=>{setSearchValueAdmin(e.target.value);}}/>}
              </div>
            </div>
            <div className="admin-table-container">
              <Table
                  width={TABLE_TOTAL_WIDTH}
                  height={TABLE_TOTAL_HEIGHT}
                  headerHeight={40}
                  rowHeight={40}
                  rowCount={adminList?.length}
                  rowGetter={({ index }) => adminList[index]}
                  rowRenderer={({ key, index, style }) => _rowRenderer({ key, index, style, type:"Admin" })}>
                  <Column
                    dataKey="index"
                    headerRenderer={_indexRenderer}
                  />
                  {column && column.map((item, index) => {
                    return (
                      <Column key={index} headerRenderer={headerRenderer} label={item.columnName} dataKey={item.columnKey} width={tableState && tableState.width[item.columnKey] * TABLE_TOTAL_WIDTH} />
                    )})}
              </Table>
            </div>
          </div>
        }

        {tab === "Zumax" && 
          <div className="user-table">
            <div className="table-header-container">
              <span className="table-header">Member List</span>
              <div className={` ${isSearchMember ? "search-action-btn selected-search-action-btn" : "action-btn"}`} ref={memberSearchRef} onClick={()=>{setIsSearchMember(true)}}>
                  <div className="action-icon"><BiSearch size={15}/></div>
                  {!isSearchMember && <span>Search</span>}
                  {isSearchMember && <input ref={memberSearchInputRef} value={searchValueMember} className="search-action" placeholder="Search for everything" onChange={(e)=>{setSearchValueMember(e.target.value);}}/>}
              </div>
            </div>
            <div className="user-table-container">
              <Table
                  width={TABLE_TOTAL_WIDTH}
                  height={TABLE_TOTAL_HEIGHT}
                  headerHeight={40}
                  rowHeight={40}
                  rowCount={memberList?.length}
                  rowGetter={({ index }) => memberList[index]}
                  rowRenderer={({ key, index, style }) => _rowRenderer({ key, index, style, type:"Member" })}>
                  <Column
                    dataKey="index"
                    headerRenderer={_indexRenderer}
                  />
                  {column && column.map((item, index) => {
                    return (
                      <Column key={index} headerRenderer={headerRenderer} label={item.columnName} dataKey={item.columnKey} width={tableState && tableState.width[item.columnKey] * TABLE_TOTAL_WIDTH} />
                    )})}
              </Table>
            </div>
          </div>
        }
        
      </div>
    </div>
    </>
  )
}

export default GaMemberList;