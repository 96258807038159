import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { InputSelect } from "../Input/InputSelect";

export const FormInputSelect = ({
  // options,
  defaultValue,
  minWidth,
  maxWidth,
  fullWidth,
  menuItems,
  formOnChange,
  ...props
}) => {
  return (
    <Controller
      {...props}
      shouldUnregister
      defaultValue={defaultValue}
      render={({ field: { onChange, value, ref } }) => {
        return (
          <InputSelect
            value={value}
            menuItems={menuItems}
            onChange={
              formOnChange
                ? (event) => {
                    const data = event.target.checked;
                    formOnChange(data);
                    onChange(data);
                  }
                : onChange
            }
            name={props.name}
            minWidth={minWidth}
            maxWidth={maxWidth}
            fullWidth={fullWidth}
            fieldRef={ref}
          />
        );
      }}
    />
  );
};

FormInputSelect.propTypes = {
  // options: PropTypes.array,
  defaultValue: PropTypes.any,
  minWidth: PropTypes.any,
  fullWidth: PropTypes.any,
  maxWidth: PropTypes.any,
  formOnChange: PropTypes.any,
  name: PropTypes.string,
  menuItems: PropTypes.any,
};
