const component = {
  MuiDivider: {
    styleOverrides: {
      root: ({ theme }) => ({
        backgroundColor: theme.palette.border.grey,
      }),
    },
  },
};

export default component;
