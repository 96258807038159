import { createSlice, current } from "@reduxjs/toolkit"

import { useAppSelector } from "../../hooks/storeHooks";

//initial state
const initialState = {

    //loading
    createClientHandlerLoading : false,
    bulkCreateClientHandlerLoading : false,
    retrieveClientHandlerLoading : false,
    retrieveSpecificClientHandlerLoading : false,
    deleteClientHandlerLoading : false,

    //error
    createClientHandlerError : undefined,
    bulkCreateClientHandlerError : undefined,
    retrieveClientHandlerError : undefined,
    retrieveSpecificClientHandlerError : undefined,
    deleteClientHandlerError : undefined,

    //data
    client_groups_handler : [],
    currClientGroupHandler : []
}

//slices
const clientHandlerSlice = createSlice({
    name: "clientHandlers",
    initialState,
    reducers : {
        createClientHandler(state){
            state.createClientHandlerLoading = true;
        },
        createClientHandlerSuccess(state, {payload}){
            const currentState = payload;
            state.client_groups_handler = currentState;

            state.createClientHandlerLoading = false;
        },
        createClientHandlerError(state, {payload}){
            const errorState = payload;
            state.createClientHandlerError = errorState;

            state.createClientHandlerLoading = false;
        },

        bulkCreateClientHandler(state){
            state.bulkCreateClientHandlerLoading = true;
        },
        bulkCreateClientHandlerSuccess(state, {payload}){
            const currentState = payload;
            state.client_groups_handler = currentState;

            state.bulkCreateClientHandlerLoading = false;
        },
        bulkCreateClientHandlerError(state, {payload}){
            const errorState = payload;
            state.bulkCreateClientHandlerError = errorState;

            state.bulkCreateClientHandlerLoading = false;
        },

        retrieveClientHandler(state){
            state.retrieveClientHandlerLoading = true;
        },
        retrieveClientHandlerSuccess(state, {payload}){
            const currentState = payload;
            state.client_groups_handler = currentState;

            state.retrieveClientHandlerLoading = false;
        },
        retrieveClientHandlerError(state, {payload}){
            const errorState = payload;
            state.retrieveClientHandlerError = errorState;

            state.retrieveClientHandlerLoading = false;
        },

        deleteClientHandler(state){
            state.deleteClientHandlerLoading = true;
        },
        deleteClientHandlerSuccess(state, {payload}){
            const currentState = current(state.allClientHandler);
            const updatedState = currentState.filter((item) => item.ClientGroupHandlerID !== payload.ClientGroupHandlerID);
            state.client_groups_handler = updatedState;

            state.deleteClientHandlerLoading = false;
        },
        deleteClientHandlerError(state, {payload}){
            const errorState = payload;
            state.deleteClientHandlerError = errorState;

            state.deleteClientHandlerLoading = false;
        },


        //unused
        retrieveSpecificClientHandler(state){
            state.retrieveSpecificClientHandlerLoading = true;
        },
        retrieveSpecificClientHandlerSuccess(state, {payload}){
            const currentState = payload;
            state.currClientGroupHandler = currentState;

            state.retrieveSpecificClientHandlerLoading = false;
        },
        retrieveSpecificClientHandlerError(state, {payload}){
            const errorState = payload;
            state.retrieveSpecificClientHandlerError = errorState;

            state.retrieveSpecificClientHandlerLoading = false;
        },
        
    }
})

//export function
export const {
    createClientHandler,
    createClientHandlerSuccess,
    createClientHandlerError,
    bulkCreateClientHandler,
    bulkCreateClientHandlerSuccess,
    bulkCreateClientHandlerError,
    retrieveClientHandler,
    retrieveClientHandlerSuccess,
    retrieveClientHandlerError,
    retrieveSpecificClientHandler,
    retrieveSpecificClientHandlerSuccess,
    retrieveSpecificClientHandlerError,
    deleteClientHandler,
    deleteClientHandlerSuccess,
    deleteClientHandlerError
} = clientHandlerSlice.actions

//export selector

// data
export const selectClientGroupsHandler = () =>
  useAppSelector((state) => state.clientHandlers.client_groups_handler);

export const selectCurrClientGroupHandler = () =>
  useAppSelector((state) => state.clientHandlers.currClientGroupHandler);

// loading
export const selectCreateClientHandlerLoading = () =>
  useAppSelector((state) => state.clientHandlers.createClientHandlerLoading);

export const selectBulkCreateClientHandlerLoading = () =>
  useAppSelector((state) => state.clientHandlers.bulkCreateClientHandlerLoading);

export const selectRetrieveClientHandlerLoading = () =>
  useAppSelector((state) => state.clientHandlers.retrieveClientHandlerLoading);

export const selectRetrieveSpecificClientHandlerLoading = () =>
  useAppSelector((state) => state.clientHandlers.retrieveSpecificClientHandlerLoading);

export const selectDeleteClientHandlerLoading = () =>
  useAppSelector((state) => state.clientHandlers.deleteClientHandlerLoading);

// error
export const selectCreateClientHandlerError = () =>
  useAppSelector((state) => state.clientHandlers.createClientHandlerError);

export const selectBulkCreateClientHandlerError = () =>
  useAppSelector((state) => state.clientHandlers.bulkCreateClientHandlerError);

export const selectRetrieveClientHandlerError = () =>
  useAppSelector((state) => state.clientHandlers.retrieveClientHandlerError);

export const selectRetrieveSpecificClientHandlerError = () =>
  useAppSelector((state) => state.clientHandlers.retrieveSpecificClientHandlerError);

export const selectDeleteClientHandlerError = () =>
  useAppSelector((state) => state.clientHandlers.deleteClientHandlerError);

//export reducer
const clientHandlerReducer = clientHandlerSlice.reducer;

export default clientHandlerReducer;
