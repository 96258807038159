import React from 'react'
import DataSets from './DataSets';
import './WorkSpace.scss'

function WorkSpace(props) {

  return (
    <div className={`workspace`}>
      <div className="" style={{ width: "100%", height: "100vh", overflow: "hidden" }}>
        <DataSets/>
      </div>
    </div>
  )
}

export default WorkSpace;