const transactionTypeNameEnum = {
    ["quotations"]: "Quotation",
    ["sale-orders"]: "Sale Order",
    ["delivery-orders"]: "Delivery Order",
    ["invoices"]: "Invoice",
    ["credit-notes"]: "Credit Note",
    ["payments"]: "Payment",
    ["refunds"]: "Refund",
    ["purchases-order"]: "Purchases Order",
    ["goods-received-notes"]: "Goods Received Note",
    ["bills"]: "Bill",
    ["money-in"]: "Money In",
    ["money-out"]: "Money Out",
  };
  
  const transactionIsDepositTo = {
    "sales/payments": true,
    "sales/refunds": false,
    "purchases/payments": false,
    "purchases/refunds": true,
    "money-in": true,
    "money-out": false,
  };
  
  const transactionIsPurchases = [
    "purchases/purchase-orders",
    "purchases/goods-received-notes",
    "purchases/bills",
    "purchases/credit-notes",
    "purchases/payments",
    "purchases/refunds",
  ];
  
  const transferTransactionList = {
    "sales/quotations": null,
    "sales/sale-orders": ["quotations"],
    "sales/delivery-orders": ["quotations", "sale-orders"],
    "sales/invoices": ["quotations", "sale-orders", "delivery-orders"],
    "sales/credit-notes": null,
    "sales/payments": null,
    "sales/refunds": null,
    "purchases/purchases-order": null,
    "purchases/goods-received-notes": ["purchases-order"],
    "purchases/bills": ["purchases-order", "goods-received-notes"],
    "purchases/credit-notes": null,
    "purchases/payments": null,
    "purchases/refunds": null,
  };
  const transactionPaymentFormType = {
    "sales/payments": {
      title: "Invoice Payments",
      total: "Payments Total",
      sub: "Invoice Paid",
    },
    "sales/refunds": {
      title: "Refund Credits",
      total: "Refund Total",
  
      sub: "Invoice Refunded",
    },
  
    "sales/credit-notes": {
      title: "Credit to invoices",
      total: "Credit Total",
      sub: "Credit Applied",
    },
  
    "purchases/credit-notes": {
      title: "Credit to bills",
      total: "Credit Total",
      sub: "Credit Applied",
    },
    "purchases/payments": {
      title: "Purchase Payments",
      total: "Payments Total",
  
      sub: "Purchase Paid",
    },
    "purchases/refunds": {
      title: "Credit Refunds",
      total: "Refund Total",
  
      sub: "Purchase Refunded",
    },
    "bank/money-in": {
      title: "Payment Received",
      total: "Payment Total",
    },
    "bank/money-out": {
      title: "Payment Made",
      total: "Payment Total",
    },
  };
  
  const defaultTransactionColumn = (formDesign) => {
    const {
      sku_labels = null,
      quantity_labels = null,
      uom_labels = null,
      base_quantity_labels = null,
      base_uom_labels = null,
      unit_price_labels = null,
      discount_labels = null,
    } = formDesign || {};
  
    return [
      {
        title: "#",
        style: {
          width: 5,
        },
      },
      {
        title: "Code",
        // style: {
        //   width: 15,
        // },
      },
      {
        title: sku_labels ? sku_labels : "SKU",
        // style: {
        //   width: 10,
        // },
      },
      {
        title: "Image",
        // style: {
        //   width: 20,
        // },
      },
  
      {
        title: "Description",
        style: {
          width: 50,
        },
      },
      // {
      //   title: "Bin",
      // },
  
      { title: quantity_labels ? quantity_labels : "Qty" },
      {
        title: uom_labels ? uom_labels : "Uom",
      },
      {
        title: base_quantity_labels ? base_quantity_labels : "Base Qty",
        // style: {
        //   width: 18,
        // },
      },
      {
        title: base_uom_labels ? base_uom_labels : "Base Uom",
        // style: {
        //   width: 18,
        // },
      },
      {
        title: unit_price_labels ? unit_price_labels : "U/Price",
        // style: {
        //   width: 15,
        // },
      },
  
      { title: "Amt" },
      { title: discount_labels ? discount_labels : "Disc" },
      { title: "Tax" },
      {
        title: "Net Amt",
        // style: {
        //   width: 15,
        // },
      },
      {
        title: "Cost",
      },
      {
        title: "Profit",
      },
    ];
  };
  const defaultTransactionPaymentColumn = (isPaid = false) => [
    {
      title: "Transaction",
      // style: {
      //   width: 15,
      // },
    },
    {
      title: "Date",
      // style: {
      //   width: 10,
      // },
    },
  
    {
      title: "Description",
      style: {
        width: 50,
      },
    },
    // {
    //   title: "Bin",
    // },
  
    { title: "Amount" },
    { title: isPaid ? "Paid" : "Refunded " },
    {
      title: "Balance",
    },
  ];
  
  const defaultBankTransactionColumn = [
    {
      title: "No.",
      // style: {
      //   width: 15,
      // },
    },
    {
      title: "Description",
      // style: {
      //   width: 15,
      // },
    },
    {
      title: "Amt",
      // style: {
      //   width: 15,
      // },
    },
    {
      title: "Tax",
      // style: {
      //   width: 15,
      // },
    },
  ];
  
  const shareTransactionDefaultColumns = (formDesign) => ({
    quotations: defaultTransactionColumn(formDesign),
    ["sale-orders"]: defaultTransactionColumn(formDesign),
    ["delivery-orders"]: defaultTransactionColumn(formDesign),
    ["invoices"]: defaultTransactionColumn(formDesign),
    ["sales-credit-notes"]: defaultTransactionColumn(formDesign),
    ["sales-payments"]: defaultTransactionPaymentColumn(true),
    ["sales-refunds"]: defaultTransactionPaymentColumn(),
    ["purchases-payments"]: defaultTransactionPaymentColumn(),
    ["purchases-refunds"]: defaultTransactionPaymentColumn(true),
    ["purchase-orders"]: defaultTransactionColumn(formDesign),
    ["goods-received-notes"]: defaultTransactionColumn(formDesign),
    ["bills"]: defaultTransactionColumn(formDesign),
    ["money-in"]: defaultBankTransactionColumn,
    ["money-out"]: defaultBankTransactionColumn,
  });
  
  const showTransactionColumnList = (formDesign) => {
    const {
      show_transaction_no,
      show_account_code,
      show_sku,
      show_product_image,
      show_quantity,
      show_uom,
      show_base_quantity,
      show_base_uom,
      show_unit_price,
      show_amount,
      show_discount,
      show_tax,
      show_net_amount,
      show_cost,
      show_profit,
    } = formDesign || {};
    return [
      show_transaction_no,
      show_account_code,
      show_sku,
      show_product_image,
      show_quantity,
      show_uom,
      show_base_quantity,
      show_base_uom,
      show_unit_price,
      show_amount,
      show_discount,
      show_tax,
      show_net_amount,
      show_cost,
      show_profit,
    ];
  };
  
  const bankItemsConverter = (formItems, tax_inclusive) => {
    const result = formItems.reduce(
      (obj, item, index) => {
        const {
          tableData,
          totalTax,
          totalAmount: objTotalAmount,
          subTotal,
        } = obj;
  
        const { descriptions, amount, tax } = item;
        const amt = parseFloat(amount);
        const taxNumerator = tax_inclusive ? 100 : 100 + parseFloat(tax || "0");
        const taxPercentage =
          taxNumerator / (tax_inclusive ? 100 + parseFloat(tax || "0") : 100);
        const taxAmount = tax ? amt * taxPercentage : 0;
        const totalVAT = tax_inclusive ? amt : amt + taxAmount;
  
        const tableDataResult = [index + 1, descriptions, amt, taxAmount];
  
        return {
          tableData: [...tableData, tableDataResult],
          subTotal: subTotal + amt,
          totalTax: totalTax + taxAmount,
          totalAmount: objTotalAmount + totalVAT,
        };
      },
      {
        tableData: [],
        subTotal: 0,
        totalTax: 0,
        totalAmount: 0,
      }
    );
  
    return result;
  };
  
  const paymentItemsConverter = (formPayments, isRefunds = false) => {
    const result = formPayments.reduce(
      (obj, item) => {
        const {
          tableData,
  
          totalApplyAmount,
          // totalAmount: objTotalAmount,
        } = obj;
  
        const { refer_transaction, apply_amount } = item;
  
        const {
          transaction_no,
          date,
          FormItems: referFormItems,
          TransactionPayments = [],
        } = refer_transaction;
        const referFormItemsTotal = isRefunds
          ? TransactionPayments.reduce((total, payment) => {
              return total + parseFloat(payment.amount);
            }, 0)
          : referFormItems.reduce(
              (total, item) =>
                total + parseFloat(item.amount) * parseFloat(item.quantity),
              0
            );
        const parseApplyAmount = parseFloat(apply_amount);
        const balance = referFormItemsTotal - parseApplyAmount;
        const tableDataResult = [
          transaction_no,
          date,
          referFormItems[0] ? referFormItems[0].descriptions : "-",
  
          referFormItemsTotal.toFixed(2),
  
          apply_amount,
          isRefunds ? "0.00" : balance.toFixed(2),
        ];
  
        return {
          tableData: [...tableData, tableDataResult],
          // subTotal: subTotal + referFormItemsTotal,
          totalApplyAmount: totalApplyAmount + parseApplyAmount,
  
          // totalAmount: objTotalAmount + balance,
        };
      },
      {
        tableData: [],
        totalApplyAmount: 0,
        // totalAmount: 0,
      }
    );
  
    return result;
  };
  
  module.exports = {
    transactionTypeNameEnum,
    transactionIsDepositTo,
    transactionPaymentFormType,
    transactionIsPurchases,
    transferTransactionList,
    shareTransactionDefaultColumns,
    showTransactionColumnList,
    bankItemsConverter,
    paymentItemsConverter,
  };
  