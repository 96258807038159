import { Route } from "react-router-dom"
import { crmRoutes } from "../enums/Route"
import ProtectedRoute from "./ProtectedRoute"
import TenantPortal from "../containers/tenant/TenantPortal"
import { Fragment } from "react"
import Preload from "../containers/preload/Preload"

export const CompanyPreloadRoute = () => (
    <Fragment>
        <Route 
            path={crmRoutes.company.preload}
            element={
                <ProtectedRoute>
                    <Preload />
                </ProtectedRoute>
            }
        />
    </Fragment> 
)

