import { createSlice, current } from "@reduxjs/toolkit"

import { useAppSelector } from "../../hooks/storeHooks";

//initial state
const initialState = {

    //loading
    setCompanyIDLoading : false,
    setCompanyIDForRoleLoading : false,

    //error
    setCompanyIDError : undefined,
    setCompanyIDForRoleError : undefined,

    //data
    companyID : null,
    companyIDForRole : null

}

//slices
const companyIDSlices = createSlice({
    name: "companyID",
    initialState,
    reducers : {
        setCompanyID(state){
            state.setCompanyIDLoading = true;
        },
        setCompanyIDSuccess(state, {payload}){
            const currentState = payload;
            state.companyID = currentState;

            state.setCompanyIDLoading = false;
        },
        setCompanyIDError(state, {payload}){
            const errorState = payload;
            state.setCompanyIDError = errorState;

            state.setCompanyIDLoading = false;
        },

        setCompanyIDForRole(state){
            state.setCompanyIDForRoleLoading = true;
        },
        setCompanyIDForRoleSuccess(state, {payload}){
            const currentState = payload;
            state.companyIDForRole = currentState;

            state.setCompanyIDForRoleLoading = false;
        },
        setCompanyIDForRoleError(state, {payload}){
            const errorState = payload;
            state.setCompanyIDForRoleError = errorState;

            state.setCompanyIDForRoleLoading = false;
        },
        
    }
})

//export function
export const {
    setCompanyID,
    setCompanyIDSuccess,
    setCompanyIDError,
    setCompanyIDForRole,
    setCompanyIDForRoleSuccess,
    setCompanyIDForRoleError,
} = companyIDSlices.actions

//export selector

// data
export const selectCompanyID = () =>
  useAppSelector((state) => state.companyID.companyID);

export const selectCompanyIDForRole = () =>
  useAppSelector((state) => state.companyID.companyIDForRole);

// loading
export const selectSetCompanyIDLoading = () =>
  useAppSelector((state) => state.companyID.setCompanyIDLoading);

export const selectSetCompanyIDForRoleLoading = () =>
  useAppSelector((state) => state.companyID.setCompanyIDForRoleLoading);

// error
export const selectSetCompanyIDError = () =>
  useAppSelector((state) => state.companyID.setCompanyIDError);

export const selectSetCompanyIDForRoleError = () =>
  useAppSelector((state) => state.companyID.setCompanyIDForRoleError);


//export reducer
const companyIDReducer = companyIDSlices.reducer;

export default companyIDReducer;
