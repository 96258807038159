import { call, delay, put, takeLatest } from "redux-saga/effects";

import {
  setIsGA,
  setIsGASuccess,
  setIsGAError,
  setTenant,
  setTenantSuccess,
  setTenantError,
  setIsSA,
  setIsSASuccess,
  setIsSAError,
  setCurrRoles,
  setCurrRolesSuccess,
  setCurrRolesError,
  setIsFirstLogin,
  setIsFirstLoginSuccess,
  setIsFirstLoginError,
  setIdleTime,
  setIdleTimeSuccess,
  setIdleTimeError,
} from "../stores/slices/persistRolesSlices";

function* setIsGASaga({ payload }) {
  try {
    yield put(setIsGASuccess(payload))
  } catch (error) {
    yield put(setIsGAError(error));
    console.error(error);
  }
}

function* setTenantSaga({ payload }) {
  try {
    yield put(setTenantSuccess(payload))
  } catch (error) {
    yield put(setTenantError(error));
    console.error(error);
  }
}

function* setIsSASaga({ payload }) {
  try {
    yield put(setIsSASuccess(payload))
  } catch (error) {
    yield put(setIsSAError(error));
    console.error(error);
  }
}

// Not used
// function* setCurrRolesSaga({payload}){
//     try{
//         const {data} = yield call(setCurrRolesSuccess(payload))
//     } catch (error){
//
//         const errorCode = error.response.data.code;
//         yield put(setCurrRolesError(error));
//         console.error(error);
//     }
// }

function* setIsFirstLoginSaga({ payload }) {
  try {
    yield put(setIsFirstLoginSuccess(payload))
  } catch (error) {
    yield put(setIsFirstLoginError(error));
    console.error(error);
  }
}

function* setIdleTimeSaga({ payload }) {
  try {
    yield put(setIdleTimeSuccess(payload))
  } catch (error) {
    yield put(setIdleTimeError(error));
    console.error(error);
  }
}

export function* persistRolesSagaWatcher() {
  yield takeLatest(setIsGA.type, setIsGASaga);
  yield takeLatest(setTenant.type, setTenantSaga);
  yield takeLatest(setIsSA.type, setIsSASaga);
  // yield takeLatest(setCurrRoles.type, setCurrRolesSaga);
  yield takeLatest(setIsFirstLogin.type, setIsFirstLoginSaga);
  yield takeLatest(setIdleTime.type, setIdleTimeSaga);
}
