import { call, delay, put, takeLatest } from "redux-saga/effects";
import { 
    createCompany, 
    createCompanyError, 
    createCompanySuccess, 
    deleteCompany, 
    deleteCompanyError, 
    deleteCompanySuccess, 
    retrieveCompany, 
    retrieveCompanyError, 
    retrieveCompanySuccess, 
    setCompanyContext, 
    setCompanyContextError, 
    setCompanyContextSuccess, 
    setCompanyDataAndUsers, 
    setCompanyDataAndUsersError, 
    setCompanyDataAndUsersSuccess, 
    setCompanyPlanRequestData, 
    setCompanyPlanRequestDataError, 
    setCompanyPlanRequestDataSuccess, 
    setCompanyUsage, 
    setCompanyUsageError, 
    setCompanyUsageSuccess, 
    setCompanyUserInvitation, 
    setCompanyUserInvitationError, 
    setCompanyUserInvitationSuccess, 
    setCompanyUsers, 
    setCompanyUsersError, 
    setCompanyUsersSuccess, 
    setCurrUserContext, 
    setCurrUserContextError, 
    setCurrUserContextSuccess, 
    updateCompany, 
    updateCompanyError, 
    updateCompanySuccess 
} from "../stores/slices/companySlices";
import { ApiRoute } from "../enums/ApiRoute";
import { API } from "../http-common";
import { setAnalyticsContext } from "../stores/slices/analyticsSlices";
import { retrieveAllAgent, retrieveCompanyAgent } from "../stores/slices/companyAgentSlices";
import { retrieveCurrUsage } from "../stores/slices/usageSlices";
import { child, off, onValue, ref } from "firebase/database";
import { db } from "../firebaseInit";
import { retrieveCurrRoles } from "../stores/slices/roleSlices";

const dbRef = ref(db);

//firebase
function getCompanyUserInvitation(TenantID, CompanyID) {
    return new Promise((resolve, reject) => {
        const invitationRef = ref(db, 'invitationMail/' + TenantID + '/' + CompanyID + '/');
        onValue(invitationRef, (snapshot) => {
            if(snapshot.exists()){
                const userObj = snapshot.val();
                const userArrMail = Object.keys(userObj).map((key) => ({ ...userObj[key] }));
                resolve(userArrMail);
            } else {
                resolve([]);
            }
        }, (error) => {
            reject(error);
        });
    });
}

//firebase
function getAllUsage(TenantID) {
    return new Promise((resolve, reject) => {
        const allUsageRef = ref(db, 'usage/' + TenantID + '/');
        onValue(allUsageRef, (snapshot) => {
            if (snapshot.exists()) {
                const usageObj = snapshot.val();
                const usages = Object.keys(usageObj).map((key) => ({ ...usageObj[key] }));
                resolve(usages);
            } else {
                resolve([]);
            }
        }, (error) => {
            reject(error);
        });
    });
}

//firebase
function getSingleCompanyUsage(TenantID, CompanyID){
    return new Promise((resolve, reject)=>{
        const usageRef = ref(db, 'usage/' + TenantID + '/' + CompanyID + '/');
        onValue(usageRef, async (snapshot) => {

            if(snapshot.exists()){
                const usageObj = snapshot.val();
                const usages = Object.keys(usageObj.usage).map((key) => ({ ...usageObj.usage[key] }));
                const currUsageDetails = usageObj;
                const currPlan = usageObj.plan;
                const currUsage = usages;

                resolve({ currUsageDetails, currPlan, currUsage });
                
            }

        },(error) => {
            reject(error);
        });
    })
}

//firebase
function getUserDataAndRequestData(UserID) {
    return new Promise((resolve, reject) => {
        const currUserDataRef = child(dbRef, `users/${UserID}`);
        const requestRef = child(dbRef, `planRequest/${UserID}/`);

        let currUserData = null;
        let requestData = [];
        let userFetched = false;
        let requestFetched = false;

        const checkAndResolve = () => {
            if (userFetched && requestFetched) {
                resolve({ currUserData, requestData });
                
            }
        };

        onValue(currUserDataRef, (snapshot) => {
            if (snapshot.exists()) {
                currUserData = snapshot.val();
            }
            userFetched = true;
            checkAndResolve();
        }, (error) => {
            reject(error);
        });

        onValue(requestRef, (snapshot) => {
            if (snapshot.exists()) {
                const userObj = snapshot.val();
                requestData = Object.keys(userObj).map((item) => userObj[item]);
            } else {
                requestData = [];
               
            }
            requestFetched = true;
            checkAndResolve();
        }, (error) => {
            reject(error);
        });
    });
}

function getCompanyAgentUsers(companyAgent, TenantID){
    let combinedArray = [];
    let combinedArraySA = []
    return new Promise((resolve,reject) => {
        const usersRef = (child(dbRef,`users`))
            //monitoring realtime db changes then return latest value after any action
            onValue(usersRef, async (snapshot) => {
                if (snapshot.exists()) {
                    const userObj = snapshot.val();
                    const userArr = Object.keys(userObj)
                        .map((key) => ({ ...userObj[key] }))
                        .filter(element => {
                            const isUserInCompanyAgent = companyAgent.map(item=>item.UserID).includes(element.uid)
                            const isTenantSA = element.isTenant && element.TenantID === TenantID
                            return isUserInCompanyAgent || isTenantSA;
                        });
    
                    const userArrSA = Object.keys(userObj)  
                        .map((key) => ({ ...userObj[key] }))
                        .filter(element=>{
                            const isUserInCompanyAgent = companyAgent.map(item=>item.UserID).includes(element.uid)
                            const isTenantSA = element.isTenant && element.TenantID === TenantID
                            return isUserInCompanyAgent || isTenantSA;
                    });
                    
                    combinedArray = userArr.map(user => {
                        const matchingAgent = companyAgent.find(agent => agent.UserID === user.uid);
                        if (matchingAgent) {
                            return { ...user, ...matchingAgent };
                        }
                        return user;
                    });
    
                    combinedArraySA = userArrSA.map(user => {
                        const matchingAgent = companyAgent.find(agent => agent.UserID === user.uid);
                        if (matchingAgent) {
                        return { ...user, ...matchingAgent };
                        }
                        return user;
                    });

                    resolve({ currUsers : combinedArray, currUserSA: combinedArraySA });
                } else {
                    reject("no users")
                }
            });
    })
}

function getAllCompanyAgentUsers(allCompanyAgent,TenantID){

    let allCombinedArraySA = [];
    return new Promise((resolve, reject) => {
        const usersRef = (child(dbRef,`users`))
        //monitoring realtime db changes then return latest value after any action
        onValue(usersRef, async (snapshot) => {
            if (snapshot.exists()) {
                const userObj = snapshot.val();
    
                const allUserArrSA = Object.keys(userObj)  
                    .map((key) => ({ ...userObj[key] }))
                    .filter(element=>{
                        const isUserInCompanyAgent = allCompanyAgent.map(item=>item.UserID).includes(element.uid)
                        const isTenantSA = element.isTenant && element.TenantID === TenantID
                        return isUserInCompanyAgent || isTenantSA;
                });
    
                allCombinedArraySA = allUserArrSA.map(user => {
                    const matchingAgent = allCompanyAgent.find(agent => agent.UserID === user.uid);
                    if (matchingAgent) {
                    return { ...user, ...matchingAgent };
                    }
                    return user;
                });
                resolve({ currAllUser : allCombinedArraySA});
            } else {
                reject("no users")
            }
        });
    })
}


function* createCompanySaga({payload}) {
    try{
        const { data } = yield call(API.post, ApiRoute.company.createCompany, payload);
        yield put(createCompanySuccess(data));
    } catch (error){
        yield put(createCompanyError(error));
        console.error(error);
    }
}

function* retrieveCompanySaga({payload}) {
    try{
        const { data } = yield call(API.post, ApiRoute.company.retrieveAllCompany, payload);
        yield put(setCompanyContext({companyList : data}));
        yield put(retrieveCompanySuccess(data));
    } catch (error){
        yield put(retrieveCompanyError(error));
        console.error(error);
    }
}

function* updateCompanySaga({payload}) {
    try{
        yield call(API.post, ApiRoute.company.updateCompany, payload);;
        yield put(updateCompanySuccess(payload));
    } catch (error){
        yield put(updateCompanyError(error));
        console.error(error);
    }
}

function* deleteCompanySaga({payload}) {
    try{
        const { data } = yield call(API.post, ApiRoute.company.deleteCompany, payload);
        yield put(deleteCompanySuccess(data));
    } catch (error){
        yield put(deleteCompanyError(error));
        console.error(error);
    }
}

function* setCompanyContextSaga({payload}) {
    try{
        yield put(setCompanyContextSuccess(payload));
    } catch (error){
        yield put(setCompanyContextError(error));
        console.error(error);
    }
}

function* setCurrUserContextSaga({payload}) {
    try{
        yield put(setCurrUserContextSuccess(payload));
    } catch (error){
        yield put(setCurrUserContextError(error));
        console.error(error);
    }
}

function* setCompanyPlanRequestDataSaga({payload}) {
    const { UserID = null } = payload
    try{
        if(UserID){
            const { currUserData, requestData } = yield call(getUserDataAndRequestData, UserID);
            yield put(setCurrUserContext({currUserData : currUserData, requestData : requestData}));
            yield put(setCompanyPlanRequestDataSuccess(payload));
        }
    } catch (error){
        yield put(setCompanyPlanRequestDataError(error));
        console.error(error);
    }
}

function* setCompanyUsersSaga({payload}) {
    const { TenantID = null, companyAgent = [], allCompanyAgent = [] } = payload;
    try{
        
        if(companyAgent.length > 0){
           const { currUsers, currUserSA } = yield call(getCompanyAgentUsers, companyAgent, TenantID)
           yield put(setCurrUserContext({
            currUsers : currUsers, 
            currUserSA : currUserSA,
        }));
        }

        if(allCompanyAgent.length > 0){
            const { currAllUser } = yield call(getAllCompanyAgentUsers, allCompanyAgent, TenantID)
            yield put(setCurrUserContext({
                currAllUser : currAllUser
            }));
        }
        yield put(setCompanyUsersSuccess(payload));
    } catch (error){
        yield put(setCompanyUsersError(error));
        console.error(error);
    }
}

function* setCompanyUserInvitationSaga({payload}) {
    const { TenantID = null, CompanyID = null } = payload;
    try{
        let currUserInvitation = [];
        //currUser invitation
        if(TenantID && CompanyID){
            
            currUserInvitation = yield call(getCompanyUserInvitation, TenantID, CompanyID)
        }
        yield put(setCurrUserContext({
            currUserInvitation : currUserInvitation, 
        }));
        yield put(setCompanyUserInvitationSuccess(payload));
    } catch (error){
        yield put(setCompanyUserInvitationError(error));
        console.error(error);
    }
}

function* setCompanyUsageSaga({payload}) {
    const { 
        TenantID = null , 
        CompanyID = null, 
        currUserSA = [], 
        currUserInvitation = [], 
    } = payload;
    try{
        let currPlan = null;
        let currUsage = null;
        let currUsageDetails = null;
        let allUsage = [];
        let currTotalUsage = [];

        const { data : usageData } =  yield call(API.post, ApiRoute.usage.retrieveCurrentUsage, {TenantID: TenantID, CompanyID: CompanyID});
    
        //currUsage
        if(TenantID && CompanyID){
            
            const { 
                currUsageDetails: usageDetails, 
                currPlan: plan, 
                currUsage: usage 
            } = yield call(getSingleCompanyUsage, TenantID, CompanyID);

            currUsageDetails = usageDetails;
            currPlan = plan;
            currUsage = usage;
        }

        if(TenantID){
            try{
                allUsage = yield call(getAllUsage, TenantID);
            } catch(error){
                console.log("Error getting all usage : ", error);
            }
        }
        //total usage
        if(usageData !== undefined && usageData !== null){
            const newUsersSet = [...currUserInvitation, ...currUserSA];
            const mergeUsageData = {
                s1: newUsersSet.length ?? 0,
                ...usageData
            };

            
            currTotalUsage = [
                {
                    SectionID: "s1",
                    Usage: mergeUsageData?.s1,
                },
                {
                    SectionID: "s2",
                    Usage: mergeUsageData?.s2,
                },
                {
                    SectionID: "s3",
                    Usage: mergeUsageData?.s3,
                },
                {
                    SectionID: "s4",
                    Usage: mergeUsageData?.s4,
                },
                {
                    SectionID: "s5",
                    Usage: mergeUsageData?.s5,
                },
                {
                    SectionID: "s6",
                    Usage: mergeUsageData?.s6,
                },
                {
                    SectionID: "s7",
                    Usage: mergeUsageData?.s7,
                },
                {
                    SectionID: "s8",
                    Usage: mergeUsageData?.s8,
                },
                {
                    SectionID: "s9",
                    Usage: mergeUsageData?.s9,
                },
                {
                    SectionID: "s10",
                    Usage: mergeUsageData?.s10,
                },
            ];
        }
  
        yield put(setCurrUserContext({
            currUsageDetails : currUsageDetails, 
            currPlan : currPlan, 
            currUsage : currUsage,
            currTotalUsage : currTotalUsage,
            allUsage : allUsage
        }));
        yield put(setCompanyUsageSuccess(payload));
    } catch (error){
        yield put(setCompanyUsageError(error));
        console.error(error);
    }
}

function* setCompanyDataAndUsersSaga({payload}) {
    const { TenantID = null, UserID = null, CompanyID = null} = payload;
    
    try{
        const { data : companyAgent  } = yield call(API.post, ApiRoute.companyAgent.retrieveAllCompanyAgent, {TenantID: TenantID, CompanyID: CompanyID})
        const { data : allCompanyAgent } =  yield call(API.post, ApiRoute.companyAgent.retrieveAllAgent, {TenantID : TenantID});

        if(UserID){
            yield put(setCompanyPlanRequestData({UserID : UserID}));
        }
       
        if(TenantID){
            yield put(setCompanyUsers({
                TenantID : TenantID, 
                companyAgent : companyAgent, 
                allCompanyAgent : allCompanyAgent
            }));
            yield put(setCompanyUserInvitation({TenantID : TenantID, CompanyID : CompanyID}));
        }
        yield put(setCompanyDataAndUsersSuccess(payload));
    } catch (error){
        yield put(setCompanyDataAndUsersError(error));
        console.error(error);
    }
}


export function* companySagaWatcher(){
    yield takeLatest(createCompany.type, createCompanySaga);
    yield takeLatest(updateCompany.type, updateCompanySaga);
    yield takeLatest(deleteCompany.type, deleteCompanySaga);
    yield takeLatest(retrieveCompany.type, retrieveCompanySaga);
    yield takeLatest(setCompanyContext.type, setCompanyContextSaga);
    yield takeLatest(setCurrUserContext.type, setCurrUserContextSaga);
    yield takeLatest(setCompanyDataAndUsers.type, setCompanyDataAndUsersSaga);
    yield takeLatest(setCompanyPlanRequestData.type, setCompanyPlanRequestDataSaga);
    yield takeLatest(setCompanyUsers.type, setCompanyUsersSaga);
    yield takeLatest(setCompanyUserInvitation.type, setCompanyUserInvitationSaga);
    yield takeLatest(setCompanyUsage.type, setCompanyUsageSaga);
}