import React from "react";
import PropTypes from "prop-types";
import { FormLabel, Stack, Typography } from "@mui/material";

export const FormInputContainer = ({
  label,
  direction = "column",
  description,
  renderInput,
  minWidth,
  maxWidth,
}) => {
  const renderLabel = (
    <FormLabel>
      <Typography variant="bodyTextTitle">{label}</Typography>
    </FormLabel>
  );

  const renderDescription = description ? (
    <FormLabel sx={{ lineHeight: "0.8em" }}>
      <Typography
        variant="remark"
        sx={({ palette }) => ({ color: palette.text.light })}
      >
        {description}
      </Typography>
    </FormLabel>
  ) : null;

  return (
    <Stack
      direction={direction}
      gap="15px"
      width={1}
      minWidth={minWidth}
      maxWidth={maxWidth}
      justifyContent="flex-start"
      alignItems={direction === "row" ? "center" : "unset"}
    >
      <Stack
        direction="column"
        spacing={0}
        width={direction === "row" ? 0.7 : 1}
      >
        {renderLabel}
        {renderDescription}
      </Stack>
      {renderInput}
    </Stack>
  );
};

FormInputContainer.propTypes = {
  label: PropTypes.string,
  direction: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  renderInput: PropTypes.node,
  minWidth: PropTypes.any,
  maxWidth: PropTypes.any,
};
