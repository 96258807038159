import React, { useState } from "react";
import './GraphGenerator.css';
import { TbAxisX, TbAxisY } from 'react-icons/tb'
import { AiOutlineRise, AiOutlineDotChart, AiOutlineBarChart, AiFillCaretDown, AiFillCaretUp, AiFillPieChart, AiOutlineCalendar } from 'react-icons/ai'
import { TiChartAreaOutline } from 'react-icons/ti'
import { MdOpacity, MdOutlineAutoGraph, MdTextRotationAngledown, MdOutlineSpaceBar, MdLabelImportant, MdOutlineStackedBarChart} from 'react-icons/md'
import { IoMdResize, IoMdColorPalette } from "react-icons/io";
import { TbResize } from "react-icons/tb";
import { RiMoneyDollarCircleFill, RiBarChart2Fill } from "react-icons/ri";
import GraphYAxisDropdown from "./GraphYAxisDropdown";
import GraphXAxisDropdown from "./GraphXAxisDropdown";
import GraphPathTypeDropdown from "./GraphPathTypeDropdown";
import Switch from "react-switch";
import { selectAnalyticsContext } from "../../../stores/slices/analyticsSlices";

function ToolsBox(props) {

const {
    // dispatchAnalytics,
    // selectedDateSettings,
    // dispatchDateSettings,
    analytics,
    yAxisTarget,
    xAxisTarget,
    graphType,
} = selectAnalyticsContext();

const setAnalytics= props.setAnalytics;
const Unit = analytics?.Unit;
const Label = analytics?.Label;
const pieProperty = analytics?.PieProperty;
const xAxis = analytics?.xAxis;
const yAxis = analytics?.yAxis;
let data = {...analytics};


const [expandable, setExpandable] = useState({
    dateSettings : false,
    yAxisLabelSetting: false,
    yAxisSetting: false,
    xAxisSetting: false
});
const dateRangeValue = props.dateRangeValue;
const availableDateColumn = props.availableDateColumn;

const dateRange = ["Today", "This Week", "This Month", "6 Months", "1 Year", "2 Years", "3 Years", "5 Years"];

const keyName = "id";

function handleOnChangeY({value, type, target}){

    switch(type){
        case "lineWidth":
            let newWidth = yAxis.map(obj => {
                if(obj[keyName] === target){
                    return {...obj, lineWidth: value};
                }
                return obj;
            })
            data = {...analytics, yAxis: newWidth };
            break;
        case "areaLineWidth":
            let newAreaWidth = yAxis.map(obj => {
                if(obj[keyName] === target){
                    return {...obj, areaLineWidth: parseInt(value)};
                }
                return obj;
            })
            data = {...analytics, yAxis: newAreaWidth };
            break;
        case "areaLineColor":
            let newAreaLineColor = yAxis.map(obj => {
                if(obj[keyName] === target){
                    return {...obj, areaLineColor: value};
                }
                return obj;
            })
            data = {...analytics, yAxis: newAreaLineColor };
            break;
        case "areaFillOpacity":
            let newAreaFillOpacity = yAxis.map(obj => {
                if(obj[keyName] === target){
                    return {...obj, areaFillOpacity: value};
                }
                return obj;
            })
            data = {...analytics, yAxis: newAreaFillOpacity };
            break;
        case "barWidth":
            let newBarWidth = yAxis.map(obj => {
                if(obj[keyName] === target){
                    return {...obj, barWidth: parseInt(value)};
                }
                return obj;
            })
            data = {...analytics, yAxis: newBarWidth };
            break;
        case "barStack":
            let newBarStack = yAxis.map(obj => {
                if(obj[keyName] === target){
                    return {...obj, barStack: value};
                }
                return obj;
            })
            data = {...analytics, yAxis: newBarStack };
            break;
        case "areaStack":
            let newAreaStack = yAxis.map(obj => {
                if(obj[keyName] === target){
                    return {...obj, areaStack: value};
                }
                return obj;
            })
            data = {...analytics, yAxis: newAreaStack };
            break;
        case "areaOpacity":
            let newAreaOpacity = yAxis.map(obj => {
                if(obj[keyName] === target){
                    return {...obj, areaOpacity: parseInt(value)};
                }
                return obj;
            })
            data = {...analytics, yAxis: newAreaOpacity };
            break;
        case "color":
            let newColor = yAxis.map(obj => {
                if(obj[keyName] === target){
                    return {...obj, color: value};
                }
                return obj;
            })
            data = {...analytics, yAxis: newColor };
            break;
        case "dot":
            let newDotRadius = yAxis.map(obj => {
                if(obj[keyName] === target){
                    return {...obj, activeDotRadius: parseInt(value)};
                }
                return obj;
            })
            data = {...analytics, yAxis: newDotRadius };
            break;
        default:
            return;
    }
    
    setAnalytics(data)
}

function handleOnChangeX({value, type, target}){
    switch(type){
        case "angle":
            let newAngle = xAxis.map(obj => {
                if(obj[keyName] === target){
                    return {...obj, angle: parseInt(value)};
                }
                return obj;
            })

            data = {...analytics, xAxis: newAngle };
            break;
        case "left":
            let newLeft = xAxis.map(obj => {
                if(obj[keyName] === target){
                    return {...obj, paddingLeft: parseInt(value)}
                }
                return obj;
            })

            data = {...analytics, xAxis: newLeft };
            break;
        case "right":
            let newRight = xAxis.map(obj => {
                if(obj[keyName] === target){
                    return {...obj, paddingRight: parseInt(value)};
                }
                return obj;
            })

            break;
        case "size":
            let newSize = xAxis.map(obj => {
                if(obj[keyName] === target){
                    return {...obj, tickSize: parseInt(value)};
                }
                return obj;
            })

            data = {...analytics, xAxis: newSize };
            break;
        case "gap":
            let newGap = xAxis.map(obj => {
                if(obj[keyName] === target){
                    return {...obj, minTickGap: parseInt(value)};
                }
                return obj;
            })

            data = {...analytics, xAxis: newGap };
            break;
        default:
            return;
    }
    
    setAnalytics(data)
}

function handleChangePie({value, type}){
    
    switch(type){
        case "innerRadius":
            pieProperty = {...pieProperty, innerRadius: value}
            break;
        case "colorPalette":
            pieProperty = {...pieProperty, colorPalette: value}
            break;
        case "textColor":
            pieProperty = {...pieProperty, textColor: value}
            break;
        case "textSize":
            pieProperty = {...pieProperty, textSize: value}
            break;
        case "strokeColor":
            pieProperty = {...pieProperty, strokeColor: value}
            break;
        case "strokeWidth":
            pieProperty = {...pieProperty, strokeWidth: value}
            break;
        case "LabelPosition":
            pieProperty = {...pieProperty, LabelPosition: value}
            break;
        case "LabelKey":
            pieProperty = {...pieProperty, LabelKey: value}
            break;
        case "LabelLine":
            pieProperty = {...pieProperty, LabelLine: value}
            break;
        case "Percentage":
            pieProperty = {...pieProperty, Percentage: value}
            break;
        default:
            return;
    }

    data = {...data, PieProperty : pieProperty};

    setAnalytics(data)

};

function handleChangeUnit(val){
    data = {...data, Unit : val};
    
    setAnalytics(data)
};

function handleChangeLabel(val){
    data = {...data, Label : val};
    
    setAnalytics(data)
};

  return (
    <>
    {graphType !== "Pie" ?
    <div className="toolBox">
        <div className="title">
            <AiOutlineBarChart size={18}/>
            <span>Chart Editor</span>
        </div>
        <div className="toolboxComponent">
            {/* <div className="axisSetting" onClick={() => {
                setExpandable({
                    ...expandable,
                    dateSettings: !expandable.dateSettings
                })
            }}>
                <div className="name">
                    <AiOutlineCalendar className="icon" size={15}/>
                    <span>Date Settings</span>
                </div>
                {expandable.dateSettings ?
                 <AiFillCaretUp className="icon" size={13}/>
                 :
                 <AiFillCaretDown className="icon" size={13}/>}
            </div>
            <div className={`${expandable.dateSettings ? 'date-settings active-setting' : 'date-settings deactive-setting'}`}>

                   
                <div className="date-settings-container">
                    <div className="inner-block">
                        <span>Column</span>
                        <select className={`dropdown ${availableDateColumn?.length > 0 ? "dropdown-active" : "dropdown-disabled"}`} title={selectedDateSettings?.dateColumn} onChange={(e) => { 
                            // setSelectedDateColumn(e.target.value);
                            dispatchDateSettings({ type: 'CHANGE_DATE_SETTINGS', payload: {...selectedDateSettings, dateColumn: e.target.value}})
                            // handleOnChangeDateSettings(e.target.value, "dateColumn", index)
                        }}
                        disabled={availableDateColumn.length > 0 ? false : true}
                        value={selectedDateSettings?.dateColumn ?? ""}
                        >
                            {selectedDateSettings.dateColumn === "" ? <option selected value={""}>{}</option> : null}
                            {availableDateColumn.map((col, index) => {
                                return(
                                <option title={col} key={index} className="optionClass" value={col}>
                                    {col}
                                </option>
                                )
                            }) 
                            }
                        </select>
                    </div>
                    <div className="inner-block">
                        <span>Date Range</span>
                        <select className={`dropdown ${availableDateColumn.length > 0 ? "dropdown-active" : "dropdown-disabled"}`} title={dateRangeValue.dateRange} onChange={(e) => { 
                            // handleOnChangeDateSettings(e.target.value, "dateRange", index)
                            // setDateRangeValue(e.target.value);
                            dispatchDateSettings({ type: 'CHANGE_DATE_SETTINGS', payload: {...selectedDateSettings, dateRange: e.target.value}})
                        }}
                        disabled={availableDateColumn.length > 0 ? false : true}
                        value={selectedDateSettings?.dateRange ?? ""}
                        >
                            {selectedDateSettings.dateRange === "" ? <option selected value={""}>{}</option> : null}
                            {dateRange.map((date, index) => {
                                return(
                                <option title={date} key={index} className="optionClass" value={date}>
                                    {date}
                                </option>
                                )
                            }) 
                            }
                        </select>
                    </div>
                        
                </div>
                     
            </div> */}
            <div className="axisSetting" onClick={() => {
                setExpandable({
                    ...expandable,
                    yAxisLabelSetting: !expandable.yAxisLabelSetting
                })
            }}>
                <div className="name">
                    <TbAxisY className="icon" size={15}/>
                    <span>Column</span>
                </div>
                {expandable.yAxisLabelSetting ?
                 <AiFillCaretUp className="icon" size={13}/>
                 :
                 <AiFillCaretDown className="icon" size={13}/>}
            </div>
            <div className={`${expandable.yAxisLabelSetting ? 'settings active-setting' : 'settings deactive-setting'}`}>
                <div className="lineWidth">
                    <div className="name">
                        <RiMoneyDollarCircleFill size={11}/>
                        <span>Unit</span>
                    </div>
                    <input
                        type='text'
                        placeholder="Unit (e.g KG,ML,RM,USD)"
                        onChange={(e) => {
                            var value = e.target.value;
                            handleChangeUnit(value);
                        }}
                        value={Unit ? Unit : ""}
                    />
                </div>

                <div className="lineWidth">
                    <div className="name">
                        <MdLabelImportant size={11}/>
                        <span>Label</span>
                    </div>
                    <input
                        type='text'
                        placeholder="Label"
                        onChange={(e) => {
                            var value = e.target.value;
                            handleChangeLabel(value);
                        }}
                        value={Label ? Label : ""}
                    />
                </div>
            </div>

            <div className="axisSetting" onClick={() => {
                setExpandable({
                    ...expandable,
                    yAxisSetting: !expandable.yAxisSetting
                })
            }}>
                <div className="name">
                    <AiOutlineRise className="icon" size={15}/>
                    <span>Series</span>
                </div>
                {expandable.yAxisSetting ?
                 <AiFillCaretUp className="icon" size={13}/>
                 :
                 <AiFillCaretDown className="icon" size={13}/>
                }
            </div>

            <div className={`${expandable.yAxisSetting ? 'settings active-setting' : 'settings deactive-setting'}`}>
                <div className="applyTo">
                    <span>Target Series</span>
                    <GraphYAxisDropdown className="dropdown" optionClass="option" data={yAxis}/>
                </div>
                {yAxis && yAxis?.map(item => {
                if(item[keyName] === yAxisTarget){
                    return(
                        <>
                        {graphType === "Line" &&
                        <div className="lineWidth">
                            <div className="name">
                                <IoMdResize size={11}/>
                                <span>Stroke Width</span>
                            </div>
                            <input
                                type='number'
                                onChange={(e) => {
                                    var value = e.target.value;
                                    handleOnChangeY({value: value, type: "lineWidth", target: yAxisTarget})
                                }}
                                value={item.lineWidth}
                            />
                        </div>
                        }

                        {graphType === "Area" &&
                        <>
                        <div className="lineWidth">
                            <div className="name">
                                <IoMdResize size={11}/>
                                <span>Stroke Width</span>
                            </div>
                            <input
                                type='number'
                                onChange={(e) => {
                                    var value = e.target.value;
                                    handleOnChangeY({value: value, type: "areaLineWidth", target: yAxisTarget})
                                }}
                                value={item.areaLineWidth}
                            />
                        </div>
                        <div className="lineColor">
                            <div className="name">
                                <IoMdColorPalette size={14}/>
                                <span>Stroke Color</span>
                            </div>
                            <input
                                type="color"
                                onChange={(e) => {
                                    var value = e.target.value;
                                    handleOnChangeY({value: value, type: "areaLineColor", target: yAxisTarget})
                                }}
                                value={item.areaLineColor}
                            />
                        </div>
                        </>
                        }

                        {graphType === "Bar" &&
                        <>
                        <div className="barStack">
                            <div className="name">
                                <MdOutlineStackedBarChart size={11}/>
                                <span>Stack</span>
                            </div>
                            <Switch
                                checked={item.barStack}
                                onChange={(e) => { handleOnChangeY({value: e , type: "barStack", target: yAxisTarget}) }}
                                onColor="#4BAB00"
                                onHandleColor="#FFFFFF"
                                handleDiameter={16}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 3px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 7px rgba(0, 0, 0, 0.2)"
                                height={18}
                                width={37}
                            />
                        </div>
                        <div className="barWidth">
                            <div className="name">
                                <RiBarChart2Fill size={11}/>
                                <span>Bar Width</span>
                            </div>
                            <input
                                type='number'
                                onChange={(e) => {
                                    var value = e.target.value;
                                    handleOnChangeY({value: value, type: "barWidth", target: yAxisTarget})
                                }}
                                value={item.barWidth}
                            />
                        </div>
                        </>
                        }

                        {graphType === "Area" &&
                        <>
                        <div className="areaStack">
                            <div className="name">
                                <TiChartAreaOutline size={11}/>
                                <span>Stack</span>
                            </div>
                            <Switch
                                checked={item.areaStack}
                                onChange={(e) => { handleOnChangeY({value: e , type: "areaStack", target: yAxisTarget}) }}
                                onColor="#4BAB00"
                                onHandleColor="#FFFFFF"
                                handleDiameter={16}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 3px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 7px rgba(0, 0, 0, 0.2)"
                                height={18}
                                width={37}
                            />
                        </div>
                        </>
                        }

                        <div className="lineColor">
                            <div className="name">
                                <IoMdColorPalette size={14}/>
                                <span>Color</span>
                            </div>
                            <input
                                type={"color"}
                                onChange={(e) => {
                                    var value = e.target.value;
                                    handleOnChangeY({value: value, type: "color", target: yAxisTarget})
                                }}
                                value={item.color}
                            />
                        </div>
                        
                        {graphType === "Line" &&
                            <>
                            <div className="LineType" >
                                <div className="name">
                                    <MdOutlineAutoGraph size={14}/>
                                    <span>Path Type</span>
                                </div>
                                <GraphPathTypeDropdown default={item.lineType}/>
                            </div>

                            <div className="dotRadius">
                                <div className="name">
                                    <AiOutlineDotChart size={14}/>
                                    <span>Active Dot Radius</span>
                                </div>
                                <input 
                                    type='number'
                                    onChange={(e) => {
                                        var value = e.target.value;
                                        handleOnChangeY({value: value, type: "dot", target: yAxisTarget})
                                    }}
                                    value={item.activeDotRadius}    
                                />
                            </div>
                            </>
                        }

                        {graphType === "Area" &&
                        <div className="dotRadius">
                            <div className="name">
                                <MdOpacity size={14}/>
                                <span>Area Fill Opacity</span>
                            </div>
                            <input 
                                type='number'
                                onChange={(e) => {
                                    var value = e.target.value;
                                    handleOnChangeY({value: value, type: "areaFillOpacity", target: yAxisTarget})
                                }}
                                value={item.areaFillOpacity}    
                            />
                        </div>
                        }

                        </>
                    )
                    }
                })}
            </div>
            
            <div className="axisSetting" onClick={() => {
                setExpandable({
                    ...expandable,
                    xAxisSetting: !expandable.xAxisSetting
                })
            }}>
                <div className="name">
                    <TbAxisX className="icon" size={15}/>
                    <span>Rows</span>
                </div>
                {expandable.xAxisSetting ?
                 <AiFillCaretUp className="icon" size={13}/>
                 :
                 <AiFillCaretDown className="icon" size={13}/>
                }
            </div>
            <div className={`${expandable.xAxisSetting ? 'settings active-setting' : 'settings deactive-setting'}`}>
                <div className="applyTo">
                    <span>Target Series</span>
                    <GraphXAxisDropdown className="dropdown" optionClass="option" data={xAxis}/>
                </div>
                {xAxis && xAxis?.map(item => {
                    if(item[keyName] === xAxisTarget){
                    return(
                        <>
                        <div className="angle">
                            <div className="name">
                                <MdTextRotationAngledown size={14}/>
                                <span>Angle</span>
                            </div>
                            <input 
                                type='number'
                                onChange={(e) => {
                                    var value = e.target.value;
                                    handleOnChangeX({value: value, type: "angle", target: xAxisTarget})
                                }}
                                value={item.angle}    
                            />
                        </div>
                        <div className="paddingContainer">
                            <div className="name">
                                    <MdOutlineSpaceBar size={14}/>
                                    <span>Padding</span>
                            </div>
                            <div className="inputContainer">
                                <div className="left">
                                    <span>Left:</span>
                                    <input 
                                        type='number'
                                        onChange={(e) => {
                                            var value = e.target.value;
                                            handleOnChangeX({value: value, type: "left", target: xAxisTarget})
                                        }}
                                        value={item.left}
                                    />
                                </div>
                                <div className="right">
                                    <span>right:</span>
                                    <input 
                                        type='number'
                                        onChange={(e) => {
                                            var value = e.target.value;
                                            handleOnChangeX({value: value, type: "right", target: xAxisTarget})
                                        }}
                                        value={item.right}    
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="tickSize">
                            <div className="name">
                                <TbResize size={14}/>
                                <span>Tick Size</span>
                            </div>
                            <input 
                                type='number'
                                onChange={(e) => {
                                    var value = e.target.value;
                                    handleOnChangeX({value: value, type: "size", target: xAxisTarget})
                                }}
                                value={item.tickSize}    
                            />
                        </div>
                        <div className="tickGap">
                            <div className="name">
                                <MdOutlineSpaceBar size={14}/>
                                <span>Min. Tick Gap</span>
                            </div>
                            <input 
                                type='number'
                                onChange={(e) => {
                                    var value = e.target.value;
                                    handleOnChangeX({value: value, type: "gap", target: xAxisTarget})
                                }}
                                value={item.minTickGap}    
                            />
                        </div>
                        </>
                    )
                    }
                })}
            </div>
        </div>
    </div>
    :
    <div className="toolBox">
        <div className="title">
            <AiFillPieChart size={18}/>
            <span>Pie Editor</span>
        </div>
        <div className="toolboxComponent">
            <div className="settings active-setting">
                <div className="innerRadius">
                    <div className="name">
                        <MdOutlineSpaceBar size={14}/>
                        <span>Inner Radius</span>
                    </div>
                    <input 
                        type='number'
                        onChange={(e) => {
                            var value = e.target.value;
                            handleChangePie({value: value, type: "innerRadius"})
                        }}
                        value={pieProperty?.innerRadius}    
                    />
                </div>

                <div className="colorPalette">
                    <div className="name">
                        <MdOutlineSpaceBar size={14}/>
                        <span>Color palette</span>
                    </div>
                    <select onChange={(e) => { 
                        handleChangePie({value: e.target.value , type: "colorPalette" })
                    }} value={pieProperty?.colorPalette}>
                        <option disabled value>Select an option</option>
                        <option value="default">
                            default
                        </option>
                        <option value="palette 1">
                            Palette 1
                        </option>
                        <option value="palette 2">
                            Palette 2
                        </option>
                        <option value="palette 3">
                            Palette 3
                        </option>
                        <option value="palette 4">
                            Palette 4
                        </option>
                        <option value="palette 5">
                            Palette 5
                        </option>
                    </select>
                </div>

                <div className="textColor">
                    <div className="name">
                        <MdOutlineSpaceBar size={14}/>
                        <span>Text Color</span>
                    </div>
                    <input
                        type="color"
                        onChange={(e) => {
                            var value = e.target.value;
                            handleChangePie({value: value, type: "textColor"})
                        }}
                        value={pieProperty?.textColor}
                    />
                </div>

                <div className="textSize">
                    <div className="name">
                        <MdOutlineSpaceBar size={14}/>
                        <span>Text Size</span>
                    </div>
                    <input
                        type='number'
                        onChange={(e) => {
                            var value = e.target.value;
                            handleChangePie({value: value , type: "textSize" })
                        }}
                        value={pieProperty?.textSize}    
                    />
                </div>

                <div className="strokeColor">
                    <div className="name">
                        <MdOutlineSpaceBar size={14}/>
                        <span>Stroke Color</span>
                    </div>
                    <input
                        type="color"
                        onChange={(e) => {
                            var value = e.target.value;
                            handleChangePie({value: value, type: "strokeColor"})
                        }}
                        value={pieProperty?.strokeColor}
                    />
                </div>

                <div className="strokeWidth">
                    <div className="name">
                        <MdOutlineSpaceBar size={14}/>
                        <span>Stroke Width</span>
                    </div>
                    <input
                        type='number'
                        onChange={(e) => {
                            var value = e.target.value;
                            handleChangePie({value: value , type: "strokeWidth" })
                        }}
                        value={pieProperty?.strokeWidth}    
                    />
                </div>

                <div className="labelPosition">
                    <div className="name">
                        <MdOutlineSpaceBar size={14}/>
                        <span>Label Position</span>
                    </div>
                    <select onChange={(e) => { 
                        handleChangePie({value: e.target.value , type: "LabelPosition" })
                    }} value={pieProperty?.LabelPosition}>
                        <option disabled value>Select an option</option>
                        <option value="Inner">
                            Inner
                        </option>
                        <option value="Outer">
                            Outer
                        </option>
                    </select>
                </div>

                <div className="labelKey">
                    <div className="name">
                        <MdOutlineSpaceBar size={14}/>
                        <span>Label Key</span>
                    </div>
                    <Switch
                        checked={pieProperty?.LabelKey}
                        onChange={(e) => { handleChangePie({value: e , type: "LabelKey" }) }}
                        onColor="#4BAB00"
                        onHandleColor="#FFFFFF"
                        handleDiameter={16}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow="0px 1px 3px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 7px rgba(0, 0, 0, 0.2)"
                        height={18}
                        width={37}
                    />
                </div>

                <div className="labelLine">
                    <div className="name">
                        <MdOutlineSpaceBar size={14}/>
                        <span>Label Line</span>
                    </div>
                    <Switch
                        checked={pieProperty?.LabelLine}
                        onChange={(e) => { handleChangePie({value: e , type: "LabelLine" }) }}
                        onColor="#4BAB00"
                        onHandleColor="#FFFFFF"
                        handleDiameter={16}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow="0px 1px 3px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 7px rgba(0, 0, 0, 0.2)"
                        height={18}
                        width={37}
                    />
                </div>

                <div className="percentage">
                    <div className="name">
                        <MdOutlineSpaceBar size={14}/>
                        <span>Percentage</span>
                    </div>
                    <Switch
                        checked={pieProperty?.Percentage}
                        onChange={(e) => { handleChangePie({value: e , type: "Percentage" }) }}
                        onColor="#4BAB00"
                        onHandleColor="#FFFFFF"
                        handleDiameter={16}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow="0px 1px 3px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 7px rgba(0, 0, 0, 0.2)"
                        height={18}
                        width={37}
                    />
                </div>
                
            </div>
        </div>
    </div>
    }
    </>
  )
}

export default ToolsBox