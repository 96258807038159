const component = {
  MuiBox: {
    styleOverrides: {
      root: () => ({
        marginLeft: 0,
      }),
    },
  },
};

export default component;
