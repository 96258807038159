import React, { useEffect, useState, cloneElement, Children } from "react";
import { Popover, Button, Box } from "@mui/material";
import { convertSxToArray } from "../../util/mui/convertSxToArray";
import { selectNewCompanyModal } from "../../stores/slices/toggleSlice";

export const PopoverButton = ({
  variant = "text",
  buttonChild,
  children,
  sxButton = [],
  sxPopover = [],
  endIcon = null,
  popoverClassName = "",
  hoverMode = false,
  sx = [],
  anchor = {
    vertical: "bottom",
    horizontal: "right",
  },
  close = false,
}) => {
  const isOpenNewCompanyModal = selectNewCompanyModal();
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (isOpenNewCompanyModal) {
      setAnchorEl(null);
    }
  }, [isOpenNewCompanyModal]);

  const handleOpenPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const isOpen = Boolean(anchorEl);
  return (
    <Box sx={[...convertSxToArray(sx)]}>
      <Button
        aria-owns={
          hoverMode ? (isOpen ? "mouse-over-popover" : undefined) : undefined
        }
        aria-haspopup={isOpen ? "true" : ""}
        sx={[
          {
            width: "1",
            backgroundColor:
              popoverClassName === "manage_transfer_item"
                ? "white !important"
                : popoverClassName === "table_filter"
                ? "#5D45FE !important"
                : "transparent !important",
            color:
              popoverClassName === "manage_transfer_item"
                ? "#5D45FE !important"
                : popoverClassName === "table_filter"
                ? "white !important"
                : "#1C2534 !important",
          },
          ...convertSxToArray(sxButton),
        ]}
        variant={variant}
        // onMouseEnter={(event) => {
        //   if (hoverMode) {
        //     handleOpenPopover(event);
        //   }
        // }}
        // onMouseLeave={() => {
        //   if (hoverMode) {
        //     handleClosePopover();
        //   }
        // }}
        onClick={(event) => {
          if (!hoverMode) {
            handleOpenPopover(event);
          }
        }}
        endIcon={endIcon}
      >
        {buttonChild}
      </Button>
      <Popover
        className={popoverClassName}
        sx={[...convertSxToArray(sxPopover)]}
        open={!close && isOpen}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={anchor}
        disableRestoreFocus={hoverMode ? true : false}
      >
        {children
          ? Children.map(children, (child) =>
              cloneElement(child, { onClose: handleClosePopover })
            )
          : null}
      </Popover>
    </Box>
  );
};
