import React from "react";

import { Typography, Stack, Divider, Button, IconButton } from "@mui/material";

import { VisibilityRounded, VisibilityOffRounded } from "@mui/icons-material";
import PropTypes from "prop-types";

export const DataTableManageColumn = ({
  columns,
  hiddenColumns,
  onClick,
  showAllColumn,
  hideAllColumn,
}) => {
  const disabledHide = (column) => {
    return column.disableHide !== undefined ? column.disableHide : false;
  };
  const columnIsNotHidden = (column) => {
    return hiddenColumns[column.field] !== undefined
      ? hiddenColumns[column.field]
      : true;
  };

  const columnIsHidden = (column) => {
    return hiddenColumns[column.field] !== undefined
      ? !hiddenColumns[column.field]
      : false;
  };
  const shownColumnList = columns.filter(
    (column) => columnIsNotHidden(column) && !disabledHide(column)
  );
  const hiddenColumnList = columns.filter(
    (column) => columnIsHidden(column) && !disabledHide(column)
  );

  const manageColumnShownSection = (list) => {
    return (
      <>
        <Divider />
        <Stack
          direction="row"
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={({ typography }) => ({
              ...typography.remark,
              fontSize: 14,
            })}
          >
            {"Shown Columns"}
          </Typography>

          <Button
            variant="text"
            size="small"
            sx={({ typography }) => ({
              ...typography.textLink,
              minWidth: "unset",
            })}
            onClick={() => {
              const hiddenColumn = columns.reduce((result, item) => {
                //if is disable hide then dont add it in
                if (disabledHide(item)) {
                  return result;
                }
                return {
                  ...result,
                  [item.field]: false,
                };
              }, {});
              hideAllColumn(hiddenColumn);
            }}
          >
            {"Hide All"}
          </Button>
        </Stack>
        {list.map((column) => {
          return manageColumnListTile(column);
        })}
      </>
    );
  };

  const manageColumnHiddenSection = (list) => {
    return (
      <>
        <Divider />
        <Stack
          direction="row"
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={({ typography }) => ({
              ...typography.remark,
              fontSize: 14,
              padding: "unset",
              margin: "unset",
            })}
          >
            {"Hidden Columns"}
          </Typography>

          <Button
            variant="text"
            size="small"
            sx={({ typography }) => ({
              ...typography.textLink,
              minWidth: "unset",
            })}
            onClick={() => {
              const shownColumn = columns.reduce((result, item) => {
                //if is disable hide then dont add it in
                if (disabledHide(item)) {
                  return result;
                }
                return {
                  ...result,
                  [item.field]: true,
                };
              }, {});
              showAllColumn(shownColumn);
            }}
          >
            {"Show All"}
          </Button>
        </Stack>
        {list.map((column) => {
          return manageColumnListTile(column);
        })}
      </>
    );
  };

  const manageColumnListTile = (column) => {
    return (
      <Stack
        direction="row"
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          sx={({ typography, palette }) => ({
            ...typography.bodyText,
            ...(columnIsNotHidden(column)
              ? {}
              : { color: palette.text.default }),
          })}
        >
          {column.headerName}
        </Typography>
        {columnIsNotHidden(column) ? (
          <IconButton
            onClick={() => {
              onClick(column);
            }}
          >
            <VisibilityRounded
              sx={({ icons }) => ({
                ...icons.standard.primary,
              })}
            />
          </IconButton>
        ) : (
          <IconButton
            onClick={() => {
              onClick(column);
            }}
          >
            <VisibilityOffRounded
              sx={({ icons }) => ({
                ...icons.standard,
                ...(columnIsNotHidden(column)
                  ? {}
                  : { color: icons.standard.disabled }),
              })}
            />
          </IconButton>
        )}
      </Stack>
    );
  };

  return (
    <Stack
      sx={{
        padding: "10px",
        gap: "5px",
        width: "250px",
        height: "300px",
        overflow:"scroll"
      }}
    >
      <Typography
        sx={({ typography }) => ({
          ...typography.bodyTextBold,
          padding: "5px 0px",
        })}
      >
        {"Manage Column"}
      </Typography>

      {shownColumnList.length > 0 ? (
        manageColumnShownSection(shownColumnList)
      ) : (
        <></>
      )}
      {hiddenColumnList.length > 0 ? (
        manageColumnHiddenSection(hiddenColumnList)
      ) : (
        <></>
      )}
    </Stack>
  );
};

DataTableManageColumn.propTypes = {
  columns: PropTypes.array.isRequired,
  hiddenColumns: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  showAllColumn: PropTypes.func.isRequired,
  hideAllColumn: PropTypes.func.isRequired,
};
