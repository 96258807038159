import React from 'react'

import { selectAnalyticsContext, setAnalyticsContext } from '../../../stores/slices/analyticsSlices';
import { useDispatch } from 'react-redux';
import { selectCurrUserContext } from '../../../stores/slices/companySlices';

function GraphYAxisDropdown(props) {

  const { 
    dispatchYAxisTarget,
  } = selectAnalyticsContext();
  const { currUsers } = selectCurrUserContext();
  const dispatch = useDispatch();

  function handleChangeTarget(value){
    dispatch(setAnalyticsContext({yAxisTarget : value}));
    // dispatchYAxisTarget({ type:"CHANGE_Y_AXIS_TARGET", payload: value })
  }

  return (
    <select className={props.className} onChange={(e) => { 
      handleChangeTarget(e.target.value)
    }}>
      <option value={""} selected></option>
      {props.data?.map((item, index) => {
        if(item.key === "Handler"){
          const userObj = currUsers.find(user => user.UserID === item.id);
          if(userObj){
            return(
              <option key={index} className="" value={item?.["id"]}>
                {userObj.displayName}
              </option>
            )
          }
          
        } else {
          return(
            <option key={index} className="" value={item?.["id"]}>
              {item?.["key"]}
            </option>
          )
        }
      }) 
    }
    </select>
  )
}

export default GraphYAxisDropdown