import { call, delay, put, takeLatest } from "redux-saga/effects";
import { 
    createAnalytics, 
    createAnalyticsError, 
    createAnalyticsSuccess, 
    deleteAnalytics, 
    deleteAnalyticsError, 
    deleteAnalyticsSuccess,
    getAnalytics, 
    getAnalyticsError, 
    getAnalyticsSuccess, 
    getAvailableHandler, 
    getAvailableHandlerError, 
    getAvailableHandlerSuccess, 
    getClientForAnalytics, 
    getClientForAnalyticsError, 
    getClientForAnalyticsSuccess, 
    getGraphData, 
    getGraphDataError, 
    getGraphDataSuccess, 
    getPublishAnalytics, 
    getPublishAnalyticsError, 
    getPublishAnalyticsSuccess, 
    getSingleAnalytics, 
    getSingleAnalyticsError, 
    getSingleAnalyticsSuccess, 
    setAnalyticsContext, 
    setAnalyticsContextError, 
    setAnalyticsContextSuccess, 
    updateAnalytics, 
    updateAnalyticsError, 
    updateAnalyticsSuccess, 
    getAnalyticsDataSetSuccess,
    getAnalyticsDataSetError,
    getAnalyticsDataSet
} from "../stores/slices/analyticsSlices";

import { ApiRoute } from "../enums/ApiRoute";
import { API } from "../http-common";

function* createAnalyticsSaga({payload}) {
    try{
        const { data } = yield call(API.post, ApiRoute.analytics.createAnalytics, payload);
        yield put(createAnalyticsSuccess(data));
    } catch (error){
        
        yield put(createAnalyticsError(error));
        console.error(error);
    }
}

function* getAnalyticsSaga({payload}) {
    try{
        const { data } = yield call(API.post, ApiRoute.analytics.getAnalytics, payload);
        yield put(getAnalyticsSuccess(data));
    } catch (error){
        
        yield put(getAnalyticsError(error));
        console.error(error);
    }
}

function* getSingleAnalyticsSaga({payload}) {
    try{
        const { data } = yield call(API.post, ApiRoute.analytics.getSingleAnalytics, payload);
        yield put(getSingleAnalyticsSuccess(data));
    } catch (error){
        
        yield put(getSingleAnalyticsError(error));
        console.error(error);
    }
}

function* getPublishAnalyticsSaga({payload}) {
    try{
        const { data } = yield call(API.post, ApiRoute.analytics.getPublishAnalytics, payload);
        yield put(setAnalyticsContext({
            publishedGraph : data.publishedGraph,
            publishedGraphData : data.publishedGraphData
        }))
        yield put(getPublishAnalyticsSuccess(data));
    } catch (error){
        
        yield put(getPublishAnalyticsError(error));
        console.error(error);
    }
}

function* getClientForAnalyticsSaga({payload}) {
    try{
        const { data } = yield call(API.post, ApiRoute.analytics.getClientForAnalytics, payload);
        yield put(getClientForAnalyticsSuccess(data));
    } catch (error){
        
        yield put(getClientForAnalyticsError(error));
        console.error(error);
    }
}

function* updateAnalyticsSaga({payload}) {
    try{
        const { data } = yield call(API.post, ApiRoute.analytics.updateAnalytics, payload);
        yield put(updateAnalyticsSuccess(data));
    } catch (error){
        
        yield put(updateAnalyticsError(error));
        console.error(error);
    }
}

function* deleteAnalyticsSaga({payload}) {
    try{
        const { data } = yield call(API.post, ApiRoute.analytics.deleteAnalytics, payload);
        yield put(deleteAnalyticsSuccess(data));
    } catch (error){
        
        yield put(deleteAnalyticsError(error));
        console.error(error);
    }
}

function* getAnalyticsDataSetSaga({payload}) {
    try{
        const { data } = yield call(API.post, ApiRoute.analytics.retrieveAnalyticsDataset, payload);
        yield put(getAnalyticsDataSetSuccess(data));
        yield put(window.location.replace(`/#/WorkSpace`));
    } catch (error){
        
        yield put(getAnalyticsDataSetError(error));
        console.error(error);
    }
}

function* getGraphDataSaga({payload}) {
    try{
        const { data } = yield call(API.post, ApiRoute.analytics.getGraphData, payload);
        yield put(getGraphDataSuccess(data));
    } catch (error){
        
        yield put(getGraphDataError(error));
        console.error(error);
    }
}

function* getAvailableHandlerSaga({payload}) {
    try{
        const { data } = yield call(API.post, ApiRoute.analytics.getAvailableHandler, payload);
        
        yield put(getAvailableHandlerSuccess(data));
    } catch (error){
        
        yield put(getAvailableHandlerError(error));
        console.error(error);
    }
}

function* setAnalyticsContextSaga({payload}) {
    try{
        yield put(setAnalyticsContextSuccess(payload));
    } catch (error){
        
        yield put(setAnalyticsContextError(error));
        console.error(error);
    }
}

export function* analyticsSagaWatcher(){
    yield takeLatest(createAnalytics.type, createAnalyticsSaga);
    yield takeLatest(getAnalytics.type, getAnalyticsSaga);
    yield takeLatest(getSingleAnalytics.type, getSingleAnalyticsSaga);
    yield takeLatest(getPublishAnalytics.type, getPublishAnalyticsSaga);
    yield takeLatest(getClientForAnalytics.type, getClientForAnalyticsSaga);
    yield takeLatest(updateAnalytics.type, updateAnalyticsSaga);
    yield takeLatest(deleteAnalytics.type, deleteAnalyticsSaga);
    yield takeLatest(getAnalyticsDataSet.type, getAnalyticsDataSetSaga);
    yield takeLatest(getGraphData.type, getGraphDataSaga);
    yield takeLatest(getAvailableHandler.type, getAvailableHandlerSaga);
    yield takeLatest(setAnalyticsContext.type, setAnalyticsContextSaga);
}