import React from 'react';
import { selectAnalyticsContext, setAnalyticsContext } from '../../../stores/slices/analyticsSlices';

function GraphPathTypeDropdown(props) {

  const { 
    analytics,
    dispatchYAxis,
    yAxisTarget,
  } = selectAnalyticsContext();

  const yAxis = analytics?.yAxis;

  function handleChangeType(value){
    let newType = yAxis.map(obj => {
        if(obj?.["id"] === yAxisTarget){
            return {...obj, lineType: value};
        }
        return obj;
    })
    // dispatchYAxis({ type:"CHANGE_Y_AXIS", payload: newType })
  }

  return (
    <select className={props.className} onChange={(e) => { 
      handleChangeType(e.target.value)
    }} defaultValue={props.default}>
          <option className={props.optionClass} value='natural'>
            basis
          </option>
          <option className={props.optionClass} value='monotoneX'>
            monotoneX
          </option>
          <option className={props.optionClass} value='monotoneY'>
            monotoneY
          </option>
          <option className={props.optionClass} value='monotone'>
            monotone
          </option>
          <option className={props.optionClass} value='step'>
            step
          </option>
          <option className={props.optionClass} value='linear'>
            linear
          </option>
    </select>
  )
  
}

export default GraphPathTypeDropdown