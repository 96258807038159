import { call, delay, put, takeLatest } from "redux-saga/effects";
import { ApiRoute } from "../enums/ApiRoute";
import { API, deleteHeaders, setHeaders } from "../http-common";
import { setUser, setUserError, setUserSuccess } from "../stores/slices/userAuthSlices";
import { setCurrUser, setCurrUserError, setCurrUserSuccess } from "../stores/slices/authSlices";
import { addToLocalStorage, getFromLocalStorage, removeFromLocalStorage, updateLocalStorage } from "../util/LocalStorage";
import { setIsGA, setIsSA, setTenant } from "../stores/slices/persistRolesSlices";
import { retrieveCompany } from "../stores/slices/companySlices";
import { retrieveAllAgent } from "../stores/slices/companyAgentSlices";
import { retrieveCurrRoles } from "../stores/slices/roleSlices";
import { setCompanyID } from "../stores/slices/companyIDSlices";

function* setUserSaga({payload}) {
    const { firebaseAuth, tenant = null, roles = null, company = null } = payload;
    const IS_GA = getFromLocalStorage("IS_GA");
    try{
        if(Object.keys(firebaseAuth).length > 0){
            if(firebaseAuth?.error){
                if(firebaseAuth?.error === "TokenExpiredError"){
                    console.log("Session Expired");
                    const removableHeader = [
                        Headers.Authorization,
                    ];

                    deleteHeaders(removableHeader);
                    removeFromLocalStorage("ACCESS_TOKEN");
                    removeFromLocalStorage("ACCESS_TOKEN_EXPIRY");
                    
                    //Show expiration modal
                    addToLocalStorage("LOGIN_EXPIRATION", "EXPIRED");
                    return;
                }
            }
            const user = firebaseAuth?.user;
            const token = firebaseAuth?.tokens;
                if(user){
                    const SA = user?.customClaims?.isSA;
                    if(SA && roles === "SA"){
                        yield put(setIsSA(true));
                    } 
                    if(IS_GA){
                        yield put(setIsGA(IS_GA === "true"));
                    }
                    
                }
                
                if(token){
                    updateLocalStorage("ACCESS_TOKEN_EXPIRY", token.access.expires)
                    updateLocalStorage("ACCESS_TOKEN", token.access.token);
                    setHeaders({
                        [Headers.Authorization]: `Bearer ${token.access.token}`,
                    });
                }

                if(tenant){
                    yield put(setTenant(tenant));
                }

                if(company && roles && tenant){
                    
                    if(user && user?.customClaims){
                        const SA = user?.customClaims?.isSA;
                        const TenantID = user?.customClaims.TenantID;
    
                        if(SA && TenantID === tenant) {
                            yield put(setIsSA(SA));
                            yield put(retrieveCompany({TenantID:tenant, isSA: true, UserID: user?.uid})),
                            yield put(retrieveAllAgent({TenantID:tenant}))
                        } else {
                            yield put(setIsSA(false));
                            yield put(retrieveCompany({TenantID:tenant, isSA: false, UserID: user?.uid})),
                            yield put(retrieveAllAgent({TenantID:tenant}))
                            yield put(retrieveCurrRoles({TenantID:tenant, RoleID:roles}))
                        }
                        
                        yield put(setCompanyID(company));
                    }
                   
                 }

            yield put(setUserSuccess(user));
        }
    } catch (error){
        yield put(setUserError(error));
        console.error(error);
    }
}

function* setCurrUserSaga({payload}) {
    try{
        
        yield put(setCurrUserSuccess(payload));
    } catch (error){
        yield put(setCurrUserError(error));
        console.error(error);
    }
}


export function* userAuthSagaWatcher(){
    yield takeLatest(setUser.type, setUserSaga);
    yield takeLatest(setCurrUser.type, setCurrUserSaga);
}