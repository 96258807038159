import axios from "axios";
import { accUrl, apiUrl } from "./constants/env";
import { getFromLocalStorage } from "./util/LocalStorage";
import { onValue, ref } from "firebase/database";
import { db } from "./firebaseInit";

export const API = axios.create({
    baseURL: apiUrl, //change from the file will do
    headers: {
        "Content-type": "application/json"
      }
});

setInitialHeaders();

//parameter headers is a list of headers
export const setHeaders = (headers, axiosInstance = API) => {
  const commonHeaders = { ...axiosInstance.defaults.headers.common };
  Object.keys(headers).forEach((key) => (commonHeaders[key] = headers[key]));
  axiosInstance.defaults.headers.common = commonHeaders;
};

export const deleteHeaders = (headerKeys, axiosInstance = API) => {
  const commonHeaders = { ...axiosInstance.defaults.headers.common };
  headerKeys.forEach((key) => commonHeaders[key] && delete commonHeaders[key]);
  axiosInstance.defaults.headers.common = commonHeaders;
};

export function setInitialHeaders() {
  const accessToken = getFromLocalStorage("ACCESS_TOKEN");
  API.defaults.headers.common["Content-Type"] = "application/json";
  if (accessToken) {
    API.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${accessToken}`;
  }
}

API.interceptors.request.use(
  (config) => {
    const accessToken = getFromLocalStorage("ACCESS_TOKEN");
    // const refreshToken = getFromLocalStorage("REFRESH_TOKEN");

    config.headers.Authorization = `Bearer ${accessToken}`;
    return config
  },
  (error) => Promise.reject(error)
)

API.interceptors.response.use(
  (config) => {
    return config;
  },
  async (error) =>{
    const errorCode = error.response?.data?.code;
    const originalRequest = error.config;

    if (errorCode === "ACCESS_TOKEN_EXPIRED" && !originalRequest._retry) {
      const refreshToken = getFromLocalStorage("REFRESH_TOKEN");
      originalRequest._retry = true;
      console.info("Access expired, Retrying...");
      return API.post(`/refreshUserAuth`, {refreshToken}).then((res) => {
        if (res.status === 200 || res.status === 201) {
          const accessToken = res.data.tokens.access.token;
          addToLocalStorage("ACCESS_TOKEN", accessToken);
          API.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${accessToken}`;
          
          return API(originalRequest);
        }
      });
    } else {
      return Promise.reject(error);
    }
    
  }

)

export const ACC_API = axios.create({
  baseURL: accUrl,
  responseType: "json",
});

export let globalAccCompanyID = null;

/* Reading localstorage data and adding common headers on axios initialization */
export function setInitialAccHeaders() {
  ACC_API.defaults.headers.common["Content-Type"] = "application/json";
  const companyRef = ref(db, 'accCompanyID');
  onValue(companyRef, async (snapshot) => {

      if(snapshot.exists()){
          globalAccCompanyID = snapshot.val();
          // const local_company_id = "5a8d23e2ead32d6a547bb3800ad43fa918ba3b77de1";
          ACC_API.defaults.headers.common["company-id"] = globalAccCompanyID;
        
      }
  });

}

setInitialAccHeaders();


ACC_API.interceptors.request.use(
  (config) => {
    const local_company_id = "5a8d23e2ead32d6a547bb3800ad43fa918ba3b77de1";
    const header_company_id = "5a8d23e2ead32d6a547bb3800ad43fa918ba3b77de1";

    config.headers.company_id = local_company_id;
    
    return config;
  },
  (error) => Promise.reject(error)
);
