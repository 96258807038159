import { Route } from "react-router-dom"
import { crmRoutes } from "../enums/Route"
import ProtectedRoute from "./ProtectedRoute"
import { Fragment } from "react"
import Appointment from "../containers/appointment/Appointment"

export const AppointmentRoute = ({currRolesState, currSaState}) => (
    <Fragment>
        <Route 
            path={crmRoutes.appointments.appointments}
            element={
                <ProtectedRoute>
                    <Appointment 
                        isSuperAdmin={currSaState} 
                        Appointment_ViewAll={currRolesState !== undefined ? currRolesState?.Appointment_ViewAll : false} 
                        Appointment_Manage={currRolesState !== undefined ? currRolesState?.Appointment_Manage : false}
                    />
                </ProtectedRoute>
            }
        />
    </Fragment> 
)

