export const advanceM2MOrganize = (
  originList,
  updatedList,
  updatedDirtyList,
  primaryField
) => {
  const organizedObj = updatedList.reduce(
    (obj, item, index) => {
      const isPrimaryFieldExist = item[primaryField];

      if (isPrimaryFieldExist) {
        obj.updated = [
          ...obj.updated,
          {
            [primaryField]: item[primaryField],
            ...updatedDirtyList[index],
          },
        ];
      }
      if (!isPrimaryFieldExist) {
        obj.added = [...obj.added, item];
      }
      return obj;
    },
    {
      added: [],
      updated: [],
    }
  );
  const updatedIdsList = updatedList.map((item) => item[primaryField]);

  const removedList = originList.reduce((list, item) => {
    const isIncludePrimaryField = item[primaryField];
    if (isIncludePrimaryField) {
      const updatedListNotContainItem = updatedIdsList.includes(
        item[primaryField]
      );
      if (!updatedListNotContainItem) {
        list.push(item[primaryField]);
      }
    }

    return list;
  }, []);

  const removedObject = removedList.length > 0 ? { removed: removedList } : {};
  const addedObject =
    organizedObj.added.length > 0 ? { added: organizedObj.added } : {};
  const updatedObject =
    organizedObj.updated.length > 0 ? { updated: organizedObj.updated } : {};

  const results = {
    ...removedObject,
    ...addedObject,
    ...updatedObject,
  };
  return results;
};
