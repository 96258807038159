export const crmRoutes = {
    analytics: {
        analytics : "/Analytics",
        workspace : "/Workspace",
        graphGenerator : "/Workspace/GraphGenerator",
    },
  
    appointments: {
        appointments : "/Appointment"
    },
  
    form: {
        formList : "/FormList",
        formSubmission : "/FormList/FormSubmission",
        formTemplate : "/FormTemplate",
        formsSettings : "/FormTemplate/FormsSettings",
        formSearch : "/FormTemplate/FormSearch",
    },
  
    client: {
        clients : "/Clients",
        clientGroup : "/ClientGroup",
    },
    
    company: {
        companySetting : "/companySetting",
        company : "/Company",
        preload : "/Preload",
    },
  
    other: {
        emailInvitation : "/EmailInvitation",
        acceptInvitation : "/AcceptInvitation",
        expiredInvitation : "/ExpiredInvitation",
        pageNotFound : "*",
        tenant : "/Tenant",
    },
    ga : {
        gaPortal : "/GaPortal",
        gaTenantQuota : "/GaPortal/GaTenantQuota",
        gaTenantUpgrade : "/GaPortal/GaTenantUpgrade"
    },
    dashboard : {
        dashboard : "/Dashboard",
        activityLogs : "/ActivityLogs"
    },

    kpi : {
        kpi : "/Kpi",
    },
    invoicing : {
        invoiceMain : "/Invoicing",
    },
  
    auth: {
        emailVerification : "/EmailVerification",
        auth : "/Auth",
        register : "/Register",
    },
    guide : {
        client : "/Guide/Clients"
    }
  

  };
  