import { createSlice, current } from "@reduxjs/toolkit"

import { useAppSelector } from "../../hooks/storeHooks";

const initialState = {
    //loading
    setIsGALoading:false,
    setTenantLoading:false,
    setIsSALoading:false,
    // setCurrRolesLoading:false, not used
    setIsFirstLoginLoading:false,
    setIdleTimeLoading:false,

    //error
    setIsGAError:undefined,
    setTenantError:undefined,
    setIsSAError:undefined,
    // setCurrRolesError:undefined,
    setIsFirstLoginError:undefined,
    setIdleTimeError:undefined,

    //data
    currGa:false,
    currTenant:null,
    // currRoles:[], 
    currSa:null,
    currFirstLogin:[],
    currIdleTime:[],
    }

    const persistRolesSlice = createSlice({
        name:"persistRoles",
        initialState,
        reducers: {
            setIsGA(state){
                state.setIsGALoading = true
            },

            setIsGASuccess(state, {payload}){
                const currentState = payload
                state.currGa = currentState
                state.setIsGALoading = false
            },

            setIsGAError(state, {payload}){
                const errorState = payload
                state.setIsGAError = errorState
                state.setIsGALoading = false
            },

            setTenant(state){
                state.setTenantLoading = true
            },

            setTenantSuccess(state,{payload}){
                const currentState = payload
                state.currTenant = currentState
                state.setTenantLoading = false
            },

            setTenantError(state,{payload}){
                const errorState = payload
                state.setTenantError = errorState
                state.setTenantLoading = false
            },

            setIsSA(state){
                state.setIsSALoading = true
            },

            setIsSASuccess(state,{payload}){
                const currentState = payload
                state.currSa = currentState
                state.setIsSALoading = false
            },

            setIsSAError(state,{payload}){
                const errorState = payload
                state.setIsSAError = errorState
                state.setIsSALoading = false
            },

            // setCurrRoles(state){
            //     state.setCurrRolesLoading = true
            // },

            // setCurrRolesSuccess(state,{payload}){
            //     const currentState = payload
            //     state.currRoles = currentState
            //     state.setCurrRolesLoading = false
            // },

            // setCurrRolesError(state,{payload}){
            //     const errorState = payload
            //     state.setCurrRolesError = errorState
            //     state.setCurrRolesLoading = false
            // },

            setIsFirstLogin(state){
                state.setIsFirstLoginLoading = true
            },

            setIsFirstLoginSuccess(state,{payload}){
                const currentState = payload
                state.currFirstLogin = currentState
                state.setIsFirstLoginLoading = false
            },

            setIsFirstLoginError(state,{payload}){
                const errorState = payload
                state.setIsFirstLoginError = errorState
                state.setIsFirstLoginLoading = false
            },

            setIdleTime(state){
                state.setIdleTimeLoading = true
            },

            setIdleTimeSuccess(state,{payload}){
                const currentState = payload
                state.currIdleTime = currentState
                state.setIdleTimeLoading = false
            },

            setIdleTimeError(state,{payload}){
                const errorState = payload
                state.setIdleTimeError = errorState
                state.setIdleTimeLoading = false
            }
        }
    })

    export const {
        setIsGA,
        setIsGASuccess,
        setIsGAError,
        setTenant,
        setTenantSuccess,
        setTenantError,
        setIsSA,
        setIsSASuccess,
        setIsSAError,
        // setCurrRoles,
        // setCurrRolesSuccess,
        // setCurrRolesError,
        setIsFirstLogin,
        setIsFirstLoginSuccess,
        setIsFirstLoginError,
        setIdleTime,
        setIdleTimeSuccess,
        setIdleTimeError,
    } = persistRolesSlice.actions

    //export selector

    // data
    export const selectCurrGa = () =>
        useAppSelector((state) => state.persistRoles.currGa);

    export const selectCurrTenant = () =>
        useAppSelector((state) => state.persistRoles.currTenant);

    export const selectCurrSa = () =>
        useAppSelector((state) => state.persistRoles.currSa);

    export const selectCurrFirstLogin = () =>
        useAppSelector((state) => state.persistRoles.currFirstLogin);

    export const selectCurrIdleTime = () =>
        useAppSelector((state) => state.persistRoles.currIdleTime);

    // loading
    export const selectSetIsGALoading = () =>
        useAppSelector((state) => state.persistRoles.setIsGALoading);

    export const selectSetTenantLoading = () =>
        useAppSelector((state) => state.persistRoles.setTenantLoading);

    export const selectSetIsSALoading = () =>
        useAppSelector((state) => state.persistRoles.setIsSALoading);

    export const selectSetIsFirstLoginLoading = () =>
        useAppSelector((state) => state.persistRoles.setIsFirstLoginLoading);

    export const selectSetIdleTimeLoading = () =>
        useAppSelector((state) => state.persistRoles.setIdleTimeLoading);

    // error
    export const selectSetIsGAError = () =>
        useAppSelector((state) => state.persistRoles.setIsGAError);

    export const selectSetTenantError = () =>
        useAppSelector((state) => state.persistRoles.setTenantError);

    export const selectSetIsSAError = () =>
        useAppSelector((state) => state.persistRoles.setIsSAError);

    export const selectSetIsFirstLoginError = () =>
    useAppSelector((state) => state.persistRoles.setIsFirstLoginError);

    export const selectSetIdleTimeError = () =>
        useAppSelector((state) => state.persistRoles.setIdleTimeError);

    //export reducer 
    const persistRolesReducer = persistRolesSlice.reducer

    export default persistRolesReducer