import React, {
  useState,
  useEffect,
  Fragment,
} from "react";
import moment from "moment";
import C_Button from "../../components/C_Button";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "./Appointments.css";
import "./Appointment.scss";
import { useDispatch } from "react-redux";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { GoTelescope } from "react-icons/go";
import { Modal } from "bootstrap";
import C_Search from "../../components/C_Search";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import "./Appointment.scss";
import AppointmentCalendar from "./AppointmentCalendar";
import CreateAppointment from "./CreateAppointment";
import EditAppointment from "./EditAppointment";
import AppointmentAll from "./AppointmentAll";
import { FaUserAlt } from "react-icons/fa";
import C_PopoutNoti from "../../components/C_PopoutNoti";

//slices
import { retrieveAppointment, selectAppointmentContext, selectAppointments, setAppointmentContext } from "../../stores/slices/appointmentsSlices";
import { retrieveAvailableGroup } from "../../stores/slices/clientSlices";
import { retrieveCurrUsage } from "../../stores/slices/usageSlices";
import { retrieveCompanyAgent } from "../../stores/slices/companyAgentSlices";
import { selectCurrSa, selectCurrTenant } from "../../stores/slices/persistRolesSlices";
import { selectTenant } from "../../stores/slices/tenantSlices";
import { selectCompanyID } from "../../stores/slices/companyIDSlices";
import { useRef } from "react";
import { selectCurrUserContext } from "../../stores/slices/companySlices";
import { selectUser } from "../../stores/slices/userAuthSlices";

function Appointment(props) {

  const isSuperAdmin = props.isSuperAdmin;
  const Appointment_ViewAll = props.Appointment_ViewAll;
  const Appointment_Manage = props.Appointment_Manage;
  const dispatch = useDispatch();
  const user = selectUser();
  const currSa = selectCurrSa();
  const tenant = selectCurrTenant();
  const companyID = selectCompanyID();
  const appointments = selectAppointments();

  const {
    dispatchCurrAppointment,
    triggerAction,
    // modalRef,
  } = selectAppointmentContext();
  const { currUsage, currPlan, currTotalUsage, currUserInvitation } = selectCurrUserContext();

  const modalRef = useRef(null);

  const [upcomingEvent, setUpcomingEvent] = useState(0);
  const [viewAll, setViewAll] = useState(false);
  const [searchText, setSearchText] = useState("");

  const [appointmentQuota, setAppointmentQuota] = useState(0);
  const [appointmentUsage, setAppointmentUsage] = useState(0);

  useEffect(() => {
      if(currUsage && currTotalUsage !== undefined && currTotalUsage && currUserInvitation){
          const filterQuota = currUsage?.find(item => item?.SectionID === "s10")?.Quota;
          const filterUsage = currTotalUsage?.find(item => item?.SectionID === "s10")?.Usage;

          setAppointmentQuota(filterQuota);
          setAppointmentUsage(filterUsage);
      }else{
          setAppointmentQuota(0);
          setAppointmentUsage(0);
      }
  },[currUsage, currTotalUsage, currUserInvitation])

  async function refreshUsage(){
     dispatch(retrieveCurrUsage({TenantID:tenant,CompanyID:companyID}));
  };

  useEffect(() => {
    if(tenant && companyID){
      refreshUsage();
    }
 
  },[tenant, companyID])

  useEffect(() => {

    const UpcomingEvent = [];
    appointments?.map(item => {
      if(moment(item.end).format("YYYY-MM-DD") >= moment().format("YYYY-MM-DD")){
        UpcomingEvent.push(item)
      }
    })
    setUpcomingEvent(UpcomingEvent.length);

  },[appointments])

  useEffect(() => {

    if(tenant){
      dispatch(retrieveAppointment({TenantID:tenant,CompanyID:companyID}));
      dispatch(retrieveCompanyAgent({TenantID:tenant,CompanyID:companyID}));
      dispatch(retrieveAvailableGroup({ TenantID:tenant,CompanyID: companyID, UserID: user?.uid, isSA: currSa }));
    }
 
  }, [tenant, companyID]);


  const showModal = () => {
    const modalEle = modalRef.current;
    const bsModal = new Modal(modalEle, {
      backdrop: "static",
      keyboard: false,
    });
    bsModal.show();
  };

  const onSelectNewEvent = () => {
    dispatch(setAppointmentContext({currAppointment : 
      {
        title: '',
        description: '',
        start: '',
        end: '',
        organizer: user?.uid,
        memberAttendees: [],
        clientAttendees: [],
        AppointmentVenue: '',
        AppointmentColor: '#c3ffc2',
        AppointmentStatus: ''
      }}
    ))
    showModal();
  };

  return (
    <Fragment>
      <div className="sidebar-main-container">
        <Sidebar/>
      </div>
      <div className="main-container">
      <Header title={"Appointment"}/>
      <EditAppointment/>
        <div className="content-box">

          <C_PopoutNoti triggerSaved={triggerAction}/>

          <div className="appointmentMainContainer">
              <CreateAppointment modalRef={modalRef}/>
              <div className="appointmentHeader">
                <div className="headerDetails">
                  {!viewAll ?
                    <span className="font-s" style={{ fontWeight: "400", marginLeft: '10px', letterSpacing: '1.5px' }}>
                      Upcoming Event: {upcomingEvent}
                    </span>
                    :
                    null
                  }
                  {(isSuperAdmin || Appointment_ViewAll) && viewAll ?
                    <C_Search
                        placeholder="Search appointment by it's title, start date, end date, description or venue"
                        onChange={(e) => {
                            setSearchText(e.target.value);
                        }}
                        value={searchText}
                        searchText={searchText}
                        closeFunction={() => {
                            setSearchText("");
                        }}
                        containerWidth="100%"
                    />
                    :null
                  }
                </div>
                <div className="headerTools">
                  
                  {(isSuperAdmin || Appointment_ViewAll) ?
                    <C_Button
                      width={"120px"}
                      buttonText={!viewAll ? "View All" : "Personal"}
                      onClick={() => {
                        setViewAll(!viewAll)
                      }}
                      marginRight={"0.5rem"}
                      gap={"10px"}
                      justify={"center"}
                      textColor={"#FFFFFF"}
                      background={"#f8fafa"}
                      color={"#000000"}
                      border={"1px solid"}
                      borderColor={"#BCBCBC"}
                      icon={!viewAll ? <GoTelescope size={15} /> : <FaUserAlt size={15} />}
                    />
                    :
                    null
                  }
                  
                  {(isSuperAdmin || Appointment_Manage) && !viewAll ?
                  <>
                    {(currPlan !== "Trial")?
                      <C_Button
                        width={"120px"}
                        buttonText={"New Event"}
                        onClick={() => {
                          onSelectNewEvent();
                        }}
                        marginRight={"0.5rem"}
                        gap={"10px"}
                        justify={"center"}
                        background={"#f8fafa"}
                        color={"#000000"}
                        border={"1px solid"}
                        borderColor={"#BCBCBC"}
                        icon={<AiOutlinePlusCircle size={15} />}
                      />
                      :
                      <>
                      {appointmentUsage < appointmentQuota ?
                        <>
                        <C_Button
                          width={"120px"}
                          buttonText={"New Event"}
                          onClick={() => {
                            onSelectNewEvent();
                          }}
                          marginRight={"0.5rem"}
                          gap={"10px"}
                          justify={"center"}
                          textColor={"#FFFFFF"}
                          icon={<AiOutlinePlusCircle size={15} />}
                          background={"#f8fafa"}
                          color={"#000000"}
                          border={"1px solid"}
                          borderColor={"#BCBCBC"}
                        />
                        </>
                        :
                        <>
                        <div className="quotaRestrictedContainerNoPaddingWidth">
                          <span>You've reached the limit, <a href="https://zumaxdigital.com/crm-v1/" target="_blank" className="link">upgrade now!</a></span>
                        </div>
                        </>
                      }
                      </>
                      
                    }
                  </>
                  :
                  null
                  }
                </div>
              </div>

              {!viewAll && <AppointmentCalendar isSuperAdmin={isSuperAdmin} Appointment_Manage={Appointment_Manage}/>}

              {viewAll && <AppointmentAll searchText={searchText}/>}
          </div>

        </div>
      </div>
    </Fragment>
  );
}

export default Appointment;
