import React from "react";
import { Autocomplete, MenuItem, TextField, Chip } from "@mui/material";

import { HighlightOffRounded } from "@mui/icons-material";
import { ChipStatus } from "../../enums/components/Chip";

export const InputDropdownMultipleSearch = ({
  options,
  name,
  value,
  transform,
  onChange,
  groupBy = undefined,
  isStandard,
  limitTags = 2,

  isOptionEqualToValue = (option, value) => {
    return option.value == value.value || option.value == value;
  },
  labelField = null,

  minWidth,
  maxWidth,
  fullWidth = true,
}) => {
  /**
   * * listen to focus element changes to render icon
   */

  const handleChange = (event, payload) => {
    if (payload) transform ? onChange(transform(payload)) : onChange(payload);
  };
  const inputTextProps = {
    // style: { textTransform: setTextTransform },
    "data-testid": `${name}DropdownSearch`,
  };
  //* if got group by the value will have spacing
  const renderMenuOptions = (item) => (
    <MenuItem {...item} sx={{ textIndent: groupBy ? "20px" : "0px" }}>
      {item.key}
    </MenuItem>
  );
  const renderStartAd = (params) => {
    return (
      <TextField {...params} variant={isStandard ? "standard" : "filled"} />
    );
  };

  return (
    <Autocomplete
      id={`auto-complete-${name}`}
      onChange={handleChange}
      disablePortal
      options={options}
      multiple
      //* when it change to groupby it not get the row instead of value
      getOptionLabel={(option) => {
        return labelField ? option[`${labelField}`] : option.label;
      }}
      isOptionEqualToValue={isOptionEqualToValue}
      //* if not put or == "" empty value will get warning message
      limitTags={limitTags}
      renderOption={renderMenuOptions}
      sx={{ minWidth: minWidth, maxWidth: maxWidth }}
      fullWidth={fullWidth}
      clearOnBlur
      //* must need put null to prevent the isOptionEqualToValue's value == to ""
      value={value || []}
      clearIcon={
        <HighlightOffRounded sx={({ icons }) => ({ ...icons.standard })} />
      }
      forcePopupIcon={false}
      groupBy={groupBy}
      renderTags={(value, getTagProps) => {
        return value.map((option, index) => {
          const isOject = typeof option === "object";

          const getOption = isOject
            ? null
            : options.find((o) => o.tag_id === option);
          const label = isOject
            ? option[`${labelField ? labelField : "label"}`]
            : getOption[`${labelField ? labelField : "label"}`];
          return (
            <Chip
              key={index}
              sx={{
                marginRight: "10px",
              }}
              label={`${label}`}
              color={ChipStatus.Success}
              {...getTagProps({ index })}
            />
          );
        });
      }}
      renderInput={(params) => {
        return isStandard ? (
          <TextField
            {...params}
            variant={"standard"}
            autoComplete="off"
            aria-autocomplete="none"
            // onBlur={handleBlur}
            onChange={({ payload }) => {
              handleChange(payload);
            }}
            value={value || ""}
            // label={label}
            // inputRef={fieldRef}
            // helperText={helperHandling()}
            // placeholder={placeholder}
            inputProps={{
              ...params.inputProps,
              ...inputTextProps,
            }}
            InputProps={{
              ...params.InputProps,
              style: { height: "55px" },
            }}
          />
        ) : (
          renderStartAd(params)
        );
      }}
    />
  );
};

