import { call, delay, put, takeLatest } from "redux-saga/effects";
import { 
    checkAgentExist,
    checkAgentExistError,
    checkAgentExistSuccess,
    createCompanyAgent,
    createCompanyAgentError, 
    createCompanyAgentSuccess, 
    deleteCompanyAgent, 
    deleteCompanyAgentError, 
    deleteCompanyAgentSuccess, 
    retrieveAllAgent, 
    retrieveAllAgentError, 
    retrieveAllAgentSuccess, 
    retrieveCompanyAgent, 
    retrieveCompanyAgentError, 
    retrieveCompanyAgentSuccess,
    sendAgentInvitation,
    sendAgentInvitationError,
    sendAgentInvitationSuccess,
    updateCompanyAgent,
    updateCompanyAgentError,
    updateCompanyAgentMemberRole,
    updateCompanyAgentMemberRoleError,
    updateCompanyAgentMemberRoleSuccess,
    updateCompanyAgentRole,
    updateCompanyAgentRoleError,
    updateCompanyAgentRoleSuccess,
    updateCompanyAgentSuccess
} from "../stores/slices/companyAgentSlices";
import { ApiRoute } from "../enums/ApiRoute";
import { API } from "../http-common";
import { deleteTenantAgents } from "../stores/slices/tenantAgentSlices";
import { setCompanyDataAndUsers } from "../stores/slices/companySlices";

function* createCompanyAgentSaga({payload}) {
    try{
        const { data } = yield call(API.post, ApiRoute.companyAgent.createCompanyAgent, payload);
        yield put(createCompanyAgentSuccess(data));
    } catch (error){
        yield put(createCompanyAgentError(error));
        console.error(error);
    }
}

function* retrieveCompanyAgentSaga({payload}) {
    const { TenantID, CompanyID, Navigation = null, user = null } = payload;
    try{
        const { data } = yield call(API.post, ApiRoute.companyAgent.retrieveAllCompanyAgent, payload);

        if(Navigation){
            const currData = data.find(item => item.UserID === user?.uid)
            const RoleID = currData?.RoleID;
            if(RoleID){
                window.location.replace(`/#/Preload?RoleID=${RoleID}&CompanyID=${CompanyID}`)
            }
            yield put(retrieveCompanyAgentSuccess(data));
        } else {
            yield put(retrieveCompanyAgentSuccess(data));
        }
        

    } catch (error){
        yield put(retrieveCompanyAgentError(error));
        console.error(error);
    }
}

function* retrieveAllAgentSaga({payload}) {
    try{
        const { data } = yield call(API.post, ApiRoute.companyAgent.retrieveAllAgent, payload);
        yield put(retrieveAllAgentSuccess(data));
    } catch (error){
        yield put(retrieveAllAgentError(error));
        console.error(error);
    }
}

function* updateCompanyAgentSaga({payload}) {
    try{
        const { data } = yield call(API.post, ApiRoute.companyAgent.updateCompanyAgent, payload);        yield put(updateCompanyAgentSuccess(data));
    } catch (error){
        yield put(updateCompanyAgentError(error));
        console.error(error);
    }
}

function* updateCompanyAgentRoleSaga({payload}) {
    try{
        const { data } = yield call(API.post, ApiRoute.companyAgent.updateCompanyAgentRole, payload);        yield put(updateCompanyAgentRoleSuccess(data));
    } catch (error){
        yield put(updateCompanyAgentRoleError(error));
        console.error(error);
    }
}

function* updateCompanyAgentMemberRoleSaga({payload}) {
    const { companyData = null, ...otherPayloads } = payload;
    try{
        yield call(API.post, ApiRoute.companyAgent.updateCompanyAgentMemberRole, otherPayloads);
        if(companyData){
            yield put(setCompanyDataAndUsers(companyData));
            yield put(updateCompanyAgentMemberRoleSuccess(payload));
        } else {
            yield put(updateCompanyAgentMemberRoleSuccess(payload));
        }
       
        
    } catch (error){
        yield put(updateCompanyAgentMemberRoleError(error));
        console.error(error);
    }
}

function* deleteCompanyAgentSaga({payload}) {
    const { UserID = null, TenantID, CompanyAgentID, CompanyID = null } = payload
    try{
        const { data } = yield call(API.post, ApiRoute.companyAgent.deleteCompanyAgent, payload);
        if(UserID && CompanyID){
            yield put(checkAgentExist({TenantID: TenantID, UserID: UserID}))
            yield put(retrieveCompanyAgent({TenantID: TenantID,CompanyID: CompanyID}));
            yield put(deleteCompanyAgentSuccess(data));
        } else {
            yield put(retrieveCompanyAgent({TenantID: TenantID,CompanyID: CompanyID}));
            yield put(deleteCompanyAgentSuccess(data));
        }

    } catch (error){
        yield put(deleteCompanyAgentError(error));
        console.error(error);
    }
}

function* sendAgentInvitationSaga({payload}) {
    try{
        const { data } = yield call(API.post, ApiRoute.companyAgent.sendAgentInvitation, payload);
        yield put(sendAgentInvitationSuccess(data));
    } catch (error){
        yield put(sendAgentInvitationError(error));
        console.error(error);
    }
}

function* checkAgentExistSaga({payload}) {
    try{
        const { data } = yield call(API.post, ApiRoute.companyAgent.checkAgentExist, payload);  
        if(data !== true){
            yield put(deleteTenantAgents(payload));
            yield put(checkAgentExistSuccess(data));
        } else {
            yield put(checkAgentExistSuccess(data));
        }     
    } catch (error){
        yield put(checkAgentExistError(error));
        console.error(error);
    }
}

export function* companyAgentSagaWatcher(){
    yield takeLatest(createCompanyAgent.type, createCompanyAgentSaga);
    yield takeLatest(retrieveCompanyAgent.type, retrieveCompanyAgentSaga);
    yield takeLatest(retrieveAllAgent.type, retrieveAllAgentSaga);
    yield takeLatest(updateCompanyAgent.type, updateCompanyAgentSaga);
    yield takeLatest(updateCompanyAgentRole.type, updateCompanyAgentRoleSaga);
    yield takeLatest(updateCompanyAgentMemberRole.type, updateCompanyAgentMemberRoleSaga);
    yield takeLatest(deleteCompanyAgent.type, deleteCompanyAgentSaga);
    yield takeLatest(sendAgentInvitation.type, sendAgentInvitationSaga);
    yield takeLatest(checkAgentExist.type, checkAgentExistSaga);
}