import { createSlice, current } from "@reduxjs/toolkit"

import { useAppSelector } from "../../hooks/storeHooks";

//initial state
const initialState = {

    //loading
    createClientLoading : false,
    importClientLoading : false,
    retrieveClientLoading : false,
    retrieveAllClientsCountLoading : false,
    retrieveAllGroupLoading : false,
    retrieveAvailableGroupLoading : false,
    singleTransferClientLoading : false,
    bulkTransferClientLoading : false,
    updateClientLoading : false,
    updateClientDataLoading : false,
    deleteClientLoading : false,
    checkExistingClientDataLoading : false,
    checkAllExistingClientDataLoading : false,
    retrieveClientFormLoading : false,
    retrieveClientFilterDataLoading : false,
    setToggleTabStateLoading : false,
    setDndActionLoading : false,
    setClientDndActionLoading : false,
    processedImportDataLoading : false,
    setClientContextLoading : false,
    //onhold
    getInitialClientDataLoading : false, 
    setClientInitialStateLoading : false,
    getProcessedClientDataLoading : false,

    //error
    createClientError : undefined,
    importClientError : undefined,
    retrieveClientError : undefined,
    retrieveAllClientsCountError : undefined,
    retrieveAllGroupError : undefined,
    retrieveAvailableGroupError : undefined,
    singleTransferClientError : undefined,
    bulkTransferClientError : undefined,
    updateClientError : undefined,
    updateClientDataError : undefined,
    deleteClientError : undefined,
    checkExistingClientDataError : undefined,
    checkAllExistingClientDataError : undefined,
    retrieveClientFormError : undefined,
    retrieveClientFilterDataError : undefined,
    setToggleTabStateError : undefined,
    setDndActionError : undefined,
    setClientDndActionError : undefined,
    processedImportDataError : undefined,
    setClientContextError : undefined,
    getInitialClientDataError : undefined,
    setClientInitialStateError : undefined,
    getProcessedClientDataError : undefined,
    // getTransferableGroupsError : undefined,

    //data
    toggleTabState : false,
    client : [],
    clientsCount : [{Total_Clients: 0}],
    allClientsGroup : [],
    availableClientsGroup : [],
    dndAction : false, 
    clientDndAction : false, 
    clientFilterData : [],
    ClientForms : [],
    duplicatedClientData : [],
    existingClientData : "",
    processedImportData : {},
    // transferableGroups : [],
    
    context : {

      filteredClientData : [],
      tempClientMergedData : [], //clone 

      clientColumnArray : [], //fix
      clientColArray : [], //clone of clientColumnArray 
      filteredClientColumnArray : [],

      clientRteIndex : "",
      exportHandlerType : "handlerName",     

      //checkedbox
      checkedClientState : [],
      checkedAllClientState : [],
      selectedClientRow : [],
      tempFilterClientRowArray : [],
      finalSelectedClientRow : [],

      allClientsGroups : [],
      filteredRowData : [], 
      clientColData : [], 
      clientCsvData : [],
      clientCsvTemplateHeader : [], 
      clientCsvHeader : [], 

      //client column punya
      selectedGroup : null,
      selectedGroupID : null,
      selectedGroupValue : null,

      selectedViewableColumn : {
          Member : [],
          Leader : []
      },
    },

    defaultContext : {
      filteredClientData : [],
      tempClientMergedData : [], //clone 

      clientColumnArray : [], //fix
      clientColArray : [], //clone of clientColumnArray 
      filteredClientColumnArray : [],

      clientRteIndex : "",
      exportHandlerType : "handlerName",     
      checkedClientState : [],
      checkedAllClientState : [],
      selectedClientRow : [],
      tempFilterClientRowArray : [],
      finalSelectedClientRow : [],

      allClientsGroups : [],
      filteredRowData : [], 
      clientColData : [], 
      clientCsvData : [],
      clientCsvTemplateHeader : [], 
      clientCsvHeader : [], 

      //client column punya
      selectedGroup : null,
      selectedGroupID : null,

      selectedGroupValue : null,

      selectedViewableColumn : {
          Member : [],
          Leader : []
      },
    }
}

//slices
const clientSlice = createSlice({
    name: "clients",
    initialState,
    reducers : {
        createClient(state){
            state.createClientLoading = true;
        },
        createClientSuccess(state, {payload}){
          const data = payload.data;
          const currentState = current(state.client);
          const updatedState = [...currentState, data];
          state.client = updatedState;

            state.createClientLoading = false;
        },
        createClientError(state, {payload}){
            const errorState = payload;
            state.createClientError = errorState;

            state.createClientLoading = false;
        },

        importClient(state){
            state.importClientLoading = true;
        },
        importClientSuccess(state, {payload}){
            const data = payload.data;
            const currentState = current(state.client);
            const updatedState = [...currentState, ...data];
            state.client = updatedState;

            state.importClientLoading = false;
        },
        importClientError(state, {payload}){
            const errorState = payload;
            state.importClientError = errorState;

            state.importClientLoading = false;
        },

        checkExistingClientData(state){
            state.checkExistingClientDataLoading = true;
        },
        checkExistingClientDataSuccess(state, {payload}){
            const currentState = current(state.client);
            const updatedState = [...currentState, payload];
            state.existingClientData = updatedState;

            state.checkExistingClientDataLoading = false;
        },
        checkExistingClientDataError(state, {payload}){
            const errorState = payload;
            state.checkExistingClientDataError = errorState;

            state.checkExistingClientDataLoading = false;
        },

        checkAllExistingClientData(state){
            state.checkAllExistingClientDataLoading = true;
        },
        checkAllExistingClientDataSuccess(state, {payload}){
            const updatedState = payload;
            state.duplicatedClientData = updatedState;

            state.checkAllExistingClientDataLoading = false;
        },
        checkAllExistingClientDataError(state, {payload}){
            const errorState = payload;
            state.checkAllExistingClientDataError = errorState;

            state.checkAllExistingClientDataLoading = false;
        },

        retrieveClient(state){
            state.retrieveClientLoading = true;
        },
        retrieveClientSuccess(state, {payload}){
            const currentState = payload;
            state.client = currentState;

            state.retrieveClientLoading = false;
        },
        retrieveClientError(state, {payload}){
            const errorState = payload;
            state.retrieveClientError = errorState;

            state.retrieveClientLoading = false;
        },

        retrieveAllClientsCount(state){
            state.retrieveAllClientsCountLoading = true;
        },
        retrieveAllClientsCountSuccess(state, {payload}){
  
          // const updatedState = payload.map((count)=>{
          //   const payloadCount = count.Total_Clients;
          //   const stateCount = state.clientsCount[0].Total_Clients;

          //   return {
          //     Total_Clients : stateCount + payloadCount
          //   }
          // })
          state.clientsCount = payload;
          state.retrieveAllClientsCountLoading = false;
        },
        retrieveAllClientsCountError(state, {payload}){
            const errorState = payload;
            state.retrieveAllClientsCountError = errorState;

            state.retrieveAllClientsCountLoading = false;
        },

        retrieveAllGroup(state){
            state.retrieveAllGroupLoading = true;
        },
        retrieveAllGroupSuccess(state, {payload}){
            const currentState = payload;
            state.allClientsGroup = currentState;

            state.retrieveAllGroupLoading = false;
        },
        retrieveAllGroupError(state, {payload}){
            const errorState = payload;
            state.retrieveAllGroupError = errorState;

            state.retrieveAllGroupLoading = false;
        },

        retrieveAvailableGroup(state){
            state.retrieveAvailableGroupLoading = true;
        },
        retrieveAvailableGroupSuccess(state, {payload}){
            const currentState = payload;
            state.availableClientsGroup = currentState;

            state.retrieveAvailableGroupLoading = false;
        },
        retrieveAvailableGroupError(state, {payload}){
            const errorState = payload;
            state.retrieveAvailableGroupError = errorState;

            state.retrieveAvailableGroupLoading = false;
        },

        updateClient(state){
            state.updateClientLoading = true;
        },
        updateClientSuccess(state, {payload}){
            const data = payload.data;
            const currentState = current(state.client);
            const updatedState = currentState.map((item) => {
              const updatedObj = data.find(client => client.ClientID === item.ClientID)
                if (updatedObj) {
                    return {
                        ...item,
                        ...updatedObj, 
                    };
                }
                return item;
            });
            state.client = updatedState;

            state.updateClientLoading = false;
        },
        updateClientError(state, {payload}){
            const errorState = payload;
            state.updateClientError = errorState;

            state.updateClientLoading = false;
        },

        updateClientData(state){
            state.updateClientDataLoading = true;
        },
        updateClientDataSuccess(state, {payload}){
            // const currentState = payload;
            // state.client = currentState;

            state.updateClientDataLoading = false;
        },
        updateClientDataError(state, {payload}){
            const errorState = payload;
            state.updateClientDataError = errorState;

            state.updateClientDataLoading = false;
        },

        deleteClient(state){
            state.deleteClientLoading = true;
        },
        deleteClientSuccess(state, {payload}){
            const data = payload.data;
            const currentState = current(state.client);
            const updatedState = currentState.filter((item) => !data.includes(item.ClientID));
            state.client = updatedState;

            state.deleteClientLoading = false;
        },
        deleteClientError(state, {payload}){
            const errorState = payload;
            state.deleteClientError = errorState;

            state.deleteClientLoading = false;
        },

        retrieveClientForm(state){
            state.retrieveClientFormLoading = true;
        },
        retrieveClientFormSuccess(state, {payload}){
            const currentState = payload;
            state.ClientForms = currentState;

            state.retrieveClientFormLoading = false;
        },
        retrieveClientFormError(state, {payload}){
            const errorState = payload;
            state.retrieveClientFormError = errorState;

            state.retrieveClientFormLoading = false;
        },

        retrieveClientFilterData(state){
            state.retrieveClientFilterDataLoading = true;
        },
        retrieveClientFilterDataSuccess(state, {payload}){
            const currentState = payload;
            state.clientFilterData = currentState;

            state.retrieveClientFilterDataLoading = false;
        },
        retrieveClientFilterDataError(state, {payload}){
            const errorState = payload;
            state.retrieveClientFilterDataError = errorState;

            state.retrieveClientFilterDataLoading = false;
        },

        setToggleTabState(state){
            state.setToggleTabStateLoading = true;
        },
        setToggleTabStateSuccess(state, {payload}){
            const currentState = payload;
            state.toggleTabState = currentState;

            state.setToggleTabStateLoading = false;
        },
        setToggleTabStateError(state, {payload}){
            const errorState = payload;
            state.setToggleTabStateError = errorState;

            state.setToggleTabStateLoading = false;
        },

        setDndAction(state){
            state.setDndActionLoading = true;
        },
        setDndActionSuccess(state, {payload}){
            const currentState = payload;
            state.dndAction = currentState;

            state.setDndActionLoading = false;
        },
        setDndActionError(state, {payload}){
            const errorState = payload;
            state.setDndActionError = errorState;

            state.setDndActionLoading = false;
        },

        setClientDndAction(state){
            state.setClientDndActionLoading = true;
        },
        setClientDndActionSuccess(state, {payload}){
            const currentState = payload;
            state.clientDndAction = currentState;

            state.setClientDndActionLoading = false;
        },
        setClientDndActionError(state, {payload}){
            const errorState = payload;
            state.setClientDndActionError = errorState;

            state.setClientDndActionLoading = false;
        },
        processImportData(state){
          state.processedImportDataLoading = true;
        },
        processImportDataSuccess(state, {payload}){
          const currentState = payload;
          if(payload){
            state.processedImportData = currentState;
          } else {
            state.processedImportData = {};
          }
         

          state.processedImportDataLoading = false;
        },
        processImportDataError(state, {payload}){
            const errorState = payload;
            state.processedImportDataError = errorState;

            state.processedImportDataLoading = false;
        },
        //context
        setClientContext(state){
          state.setClientContextLoading = true;
        },
        setClientContextSuccess(state, {payload}){
          const currentState = current(state.context);
          if(Object.values(payload).length > 0){
            const updatedState = {
                ...currentState,
                ...payload,
            }
            state.context = updatedState;
          } else {
              state.context = state.defaultContext;
          }

          state.setClientContextLoading = false;
        },
        setClientContextError(state, {payload}){
            const errorState = payload;
            state.setClientContextError = errorState;

            state.setClientContextLoading = false;
        },

        getInitialClientData(state){
          state.getInitialClientDataLoading = true;
        },
        getInitialClientDataSuccess(state, {payload}){

          state.getInitialClientDataLoading = false;
        },
        getInitialClientDataError(state, {payload}){
            const errorState = payload;
            state.getInitialClientDataError = errorState;

            state.getInitialClientDataLoading = false;
        },

        setClientInitialState(state){
          state.setClientInitialStateLoading = true;
        },
        setClientInitialStateSuccess(state, {payload}){
          if(Object.values(payload).length > 0){
            Object.assign(state, payload);
          } 
          state.setClientInitialStateLoading = false;
        },
        setClientInitialStateError(state, {payload}){
            const errorState = payload;
            state.setClientInitialStateError = errorState;

            state.setClientInitialStateLoading = false;
        },

        getProcessedClientData(state){
          state.getProcessedClientDataLoading = true;
        },
        getProcessedClientDataSuccess(state, {payload}){
          state.getProcessedClientDataLoading = false;
        },
        getProcessedClientDataError(state, {payload}){
            const errorState = payload;
            state.getProcessedClientDataError = errorState;

            state.getProcessedClientDataLoading = false;
        },

        singleTransferClient(state){
          state.singleTransferClientLoading = true;
        },
        singleTransferClientSuccess(state, {payload}){
          const ClientID = payload.ClientID;
          const currentState = current(state.client);
          const updatedState = currentState.filter((item) => item.ClientID !== ClientID);
          state.client = updatedState;

          state.singleTransferClientLoading = false;
        },
        singleTransferClientError(state, {payload}){
          const errorState = payload;
          state.singleTransferClientError = errorState;

          state.singleTransferClientLoading = false;
        },

        bulkTransferClient(state){
          state.bulkTransferClientLoading = true;
        },
        bulkTransferClientSuccess(state, {payload}){
          const ClientID = payload.data.map(client => client.ClientID);
          const currentState = current(state.client);
          const updatedState = currentState.filter((item) => !ClientID.includes(item.ClientID));
          state.client = updatedState;

          state.bulkTransferClientLoading = false;
        },
        bulkTransferClientError(state, {payload}){
          const errorState = payload;
          state.bulkTransferClientError = errorState;

          state.getProcessedClientDataLoading = false;
      },
    }
})

//export function
export const {
    createClient,
    createClientSuccess,
    createClientError,

    importClient,
    importClientSuccess,
    importClientError,

    retrieveClient,
    retrieveClientSuccess,
    retrieveClientError,

    retrieveAllClientsCount,
    retrieveAllClientsCountSuccess,
    retrieveAllClientsCountError,

    retrieveAllGroup,
    retrieveAllGroupSuccess,
    retrieveAllGroupError,

    retrieveAvailableGroup,
    retrieveAvailableGroupSuccess,
    retrieveAvailableGroupError,

    updateClient,
    updateClientSuccess,
    updateClientError,

    updateClientData,
    updateClientDataSuccess,
    updateClientDataError,

    deleteClient,
    deleteClientSuccess,
    deleteClientError,

    checkExistingClientData,
    checkExistingClientDataSuccess,
    checkExistingClientDataError,

    checkAllExistingClientData,
    checkAllExistingClientDataSuccess,
    checkAllExistingClientDataError,

    retrieveClientForm,
    retrieveClientFormSuccess,
    retrieveClientFormError,

    retrieveClientFilterData,
    retrieveClientFilterDataSuccess,
    retrieveClientFilterDataError,

    setToggleTabState,
    setToggleTabStateSuccess,
    setToggleTabStateError,

    setDndAction,
    setDndActionSuccess,
    setDndActionError,

    setClientDndAction,
    setClientDndActionSuccess,
    setClientDndActionError,

    processImportData,
    processImportDataError,
    processImportDataSuccess,

    setClientContext,
    setClientContextError,
    setClientContextSuccess,

    getInitialClientData,
    getInitialClientDataError,
    getInitialClientDataSuccess,

    setClientInitialState,
    setClientInitialStateError,
    setClientInitialStateSuccess,

    getProcessedClientData,
    getProcessedClientDataError,
    getProcessedClientDataSuccess,

    singleTransferClient,
    singleTransferClientSuccess,
    singleTransferClientError,

    bulkTransferClient,
    bulkTransferClientSuccess,
    bulkTransferClientError

} = clientSlice.actions

//export selector

// data

export const selectClient = () =>
  useAppSelector((state) => state.clients.client);

export const selectClientsCount = () =>
  useAppSelector((state) => state.clients.clientsCount);

export const selectAllClientsGroup = () =>
  useAppSelector((state) => state.clients.allClientsGroup);

export const selectAvailableClientsGroup = () =>
  useAppSelector((state) => state.clients.availableClientsGroup);

export const selectToggleTabState = () =>
  useAppSelector((state) => state.clients.toggleTabState);

export const selectDndAction = () =>
  useAppSelector((state) => state.clients.dndAction);

export const selectClientDndAction = () =>
  useAppSelector((state) => state.clients.clientDndAction);

export const selectClientForms = () =>
  useAppSelector((state) => state.clients.ClientForms);

export const selectClientFilterData = () =>
  useAppSelector((state) => state.clients.clientFilterData);

export const selectDuplicatedClientData = () =>
  useAppSelector((state) => state.clients.duplicatedClientData);

export const selectExistingClientData = () =>
  useAppSelector((state) => state.clients.existingClientData);

export const selectProcessedImportData = () =>
  useAppSelector((state) => state.clients.processedImportData);

export const selectClientContext = () =>
  useAppSelector((state) => state.clients.context);

// loading
export const selectCreateClientLoading = () =>
  useAppSelector((state) => state.clients.createClientLoading);

export const selectImportClientLoading = () =>
  useAppSelector((state) => state.clients.importClientLoading);

export const selectRetrieveClientLoading = () =>
  useAppSelector((state) => state.clients.retrieveClientLoading);

export const selectRetrieveAllClientsCountLoading = () =>
  useAppSelector((state) => state.clients.retrieveAllClientsCountLoading);

export const selectRetrieveAllGroupLoading = () =>
  useAppSelector((state) => state.clients.retrieveAllGroupLoading);

export const selectRetrieveAvailableGroupLoading = () =>
  useAppSelector((state) => state.clients.retrieveAvailableGroupLoading);

export const selectUpdateClientLoading = () =>
  useAppSelector((state) => state.clients.updateClientLoading);

export const selectUpdateClientDataLoading = () =>
  useAppSelector((state) => state.clients.updateClientDataLoading);

export const selectDeleteClientLoading = () =>
  useAppSelector((state) => state.clients.deleteClientLoading);

export const selectCheckExistingClientDataLoading = () =>
  useAppSelector((state) => state.clients.checkExistingClientDataLoading);

export const selectCheckAllExistingClientDataLoading = () =>
  useAppSelector((state) => state.clients.checkAllExistingClientDataLoading);

export const selectRetrieveClientFormLoading = () =>
  useAppSelector((state) => state.clients.retrieveClientFormLoading);

export const selectRetrieveClientFilterDataLoading = () =>
  useAppSelector((state) => state.clients.retrieveClientFilterDataLoading);

export const selectSetToggleTabStateLoading = () =>
  useAppSelector((state) => state.clients.setToggleTabStateLoading);

export const selectSetDndActionLoading = () =>
  useAppSelector((state) => state.clients.setDndActionLoading);

export const selectSetClientDndActionLoading = () =>
  useAppSelector((state) => state.clients.setClientDndActionLoading);

export const selectProcessedImportDataLoading = () =>
  useAppSelector((state) => state.clients.processedImportDataLoading);

export const selectSetClientContextLoading = () => 
  useAppSelector((state) => state.client.setClientContextLoading);

export const selectGetInitialClientDataLoading = () => 
  useAppSelector((state) => state.clients.getInitialClientDataLoading);

export const selectSetClientInitialStateLoading = () => 
  useAppSelector((state) => state.clients.setClientInitialStateLoading);

export const selectGetProcessedClientDataLoading = () => 
  useAppSelector((state) => state.clients.getProcessedClientDataLoading);

export const selectSingleTransferClientLoading = () => 
  useAppSelector((state) => state.clients.singleTransferClientLoading);

export const selectBulkTransferClientLoading = () => 
  useAppSelector((state) => state.clients.bulkTransferClientLoading);

// error
export const selectCreateClientError = () =>
  useAppSelector((state) => state.clients.createClientError);

export const selectImportClientError = () =>
  useAppSelector((state) => state.clients.importClientError);

export const selectRetrieveClientError = () =>
  useAppSelector((state) => state.clients.retrieveClientError);

export const selectRetrieveAllClientsCountError = () =>
  useAppSelector((state) => state.clients.retrieveAllClientsCountError);

export const selectRetrieveAllGroupError = () =>
  useAppSelector((state) => state.clients.retrieveAllGroupError);

export const selectRetrieveAvailableGroupError = () =>
  useAppSelector((state) => state.clients.retrieveAvailableGroupError);

export const selectUpdateClientError = () =>
  useAppSelector((state) => state.clients.updateClientError);

export const selectUpdateClientDataError = () =>
  useAppSelector((state) => state.clients.updateClientDataError);

export const selectDeleteClientError = () =>
  useAppSelector((state) => state.clients.deleteClientError);

export const selectCheckExistingClientDataError = () =>
  useAppSelector((state) => state.clients.checkExistingClientDataError);

export const selectCheckAllExistingClientDataError = () =>
  useAppSelector((state) => state.clients.checkAllExistingClientDataError);

export const selectRetrieveClientFormError = () =>
  useAppSelector((state) => state.clients.retrieveClientFormError);

export const selectRetrieveClientFilterDataError = () =>
  useAppSelector((state) => state.clients.retrieveClientFilterDataError);

export const selectSetToggleTabStateError = () =>
  useAppSelector((state) => state.clients.setToggleTabStateError);

export const selectSetDndActionError = () =>
  useAppSelector((state) => state.clients.setDndActionError);

export const selectSetClientDndActionError = () =>
  useAppSelector((state) => state.clients.setClientDndActionError);

export const selectSetClientContextError = () =>
  useAppSelector((state) => state.clients.setClientContextError);

export const selectSingleTransferClientError = () => 
  useAppSelector((state) => state.clients.singleTransferClientError);

export const selectBulkTransferClientError = () => 
  useAppSelector((state) => state.clients.bulkTransferClientError);

//export reducer
const clientReducer = clientSlice.reducer;

export default clientReducer;
