import { createSlice, current } from "@reduxjs/toolkit"
 
import { useAppSelector } from "../../hooks/storeHooks";

const initialState ={
    //loading

    createLogsLoading: false,
    retrieveLogsLoading: false,
    retrieveAllLogsCountLoading: false,

    //error
    createLogsError:undefined,
    retrieveLogsError:undefined,
    retrieveAllLogsCountError:undefined,

    //data
    logs: [],
    logsCount: [],
}

const logSlice = createSlice({
    name:"logs",
    initialState,
    reducers:{
        createLogs(state){
            state.createLogsLoading = true
        },

        createLogsSuccess(state,{payload}){
            const currentState = payload
            state.logs = currentState
            state.createLogsLoading = false
        },

        createLogsError(state,{payload}){
            const errorState = payload
            state.createLogsError = errorState
            state.createLogsLoading = false
        },

        retrieveLogs(state){
            state.retrieveLogsLoading = true
        },

        retrieveLogsSuccess(state,{payload}){
            const currentState = payload
            state.logs = currentState
            state.retrieveLogsLoading = false
        },

        retrieveLogsError(state,{payload}){
            const errorState = payload
            state.retrieveLogsError = errorState
            state.retrieveLogsLoading = false
        },

        retrieveAllLogsCount(state){
            state.retrieveAllLogsCountLoading = true
        },

        retrieveAllLogsCountSuccess(state, {payload}){
            const currentState = payload
            state.logsCount = currentState
            state.retrieveAllLogsCountLoading = false
        },

        retrieveAllLogsCountError(state, {payload}){
            const errorState = payload
            state.retrieveAllLogsCountError = errorState
            state.retrieveAllLogsCountLoading = false
        },
    }
})

export const {
    createLogs,
    createLogsSuccess,
    createLogsError,
    retrieveLogs,
    retrieveLogsSuccess,
    retrieveLogsError,
    retrieveAllLogsCount,
    retrieveAllLogsCountSuccess,
    retrieveAllLogsCountError,
} = logSlice.actions

//export selector

// data
export const selectLogs = () =>
  useAppSelector((state) => state.logs.logs);

export const selectLogsCount = () =>
  useAppSelector((state) => state.logs.logsCount);

// loading
export const selectCreateLogsLoading = () =>
  useAppSelector((state) => state.logs.createLogsLoading);

export const selectRetrieveLogsLoading = () =>
  useAppSelector((state) => state.logs.retrieveLogsLoading);

export const selectRetrieveAllLogsCountLoading = () =>
  useAppSelector((state) => state.logs.retrieveAllLogsCountLoading);

// error
export const selectCreateLogsError = () =>
  useAppSelector((state) => state.logs.createLogsError);

export const selectRetrieveLogsError = () =>
  useAppSelector((state) => state.logs.retrieveLogsError);

export const selectRetrieveAllLogsCountError = () =>
  useAppSelector((state) => state.logs.retrieveAllLogsCountError);

const logReducer = logSlice.reducer

export default logReducer