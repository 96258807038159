const component = {
  MuiPopover: {
    styleOverrides: {
      root: () => ({
        background: "none",
        "&.table_filter": {
          "& .MuiPopover-paper": {
            right: "50px",
            maxHeight: `calc(70% - 16px)`,
          },
        },
      }),
    },
  },
};

export default component;
