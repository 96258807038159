import { call, delay, put, takeLatest } from "redux-saga/effects";
import { 
    createAppointment, 
    createAppointmentError, 
    createAppointmentSuccess, 
    deleteAppointment, 
    deleteAppointmentError, 
    deleteAppointmentSuccess, 
    retrieveAppointment, 
    retrieveAppointmentError, 
    retrieveAppointmentSuccess, 
    selectEvent, 
    selectEventError, 
    selectEventSuccess, 
    setAppointmentContext, 
    setAppointmentContextError, 
    setAppointmentContextSuccess, 
    updateAppointment, 
    updateAppointmentError, 
    updateAppointmentSuccess 
} from "../stores/slices/appointmentsSlices";

import { ApiRoute } from "../enums/ApiRoute";
import { API } from "../http-common";

function* createAppointmentSaga({payload}) {
    try{
        const { data } = yield call(API.post, ApiRoute.appointments.createAppointment, payload);
        yield put(createAppointmentSuccess(data));
    } catch (error){
        
        yield put(createAppointmentError(error));
        console.error(error);
    }
}

function* updateAppointmentSaga({payload}) {
    try{
        const { data } = yield call(API.post, ApiRoute.appointments.updateAppointment, payload);
        yield put(updateAppointmentSuccess(data));
    } catch (error){
        
        yield put(updateAppointmentError(error));
        console.error(error);
    }
}

function* deleteAppointmentSaga({payload}) {
    try{
        const { data } = yield call(API.post, ApiRoute.appointments.deleteAppointment, payload);
        yield put(deleteAppointmentSuccess(data));
    } catch (error){
        
        yield put(deleteAppointmentError(error));
        console.error(error);
    }
}

function* retrieveAppointmentSaga({payload}) {
    try{
        const { data } = yield call(API.post, ApiRoute.appointments.retrieveAllAppointment, payload);
        yield put(retrieveAppointmentSuccess(data));
    } catch (error){
        
        yield put(retrieveAppointmentError(error));
        console.error(error);
    }
}

function* selectEventSaga({payload}) {
    try{
        yield put(selectEventSuccess(payload));
    } catch (error){
        
        yield put(selectEventError(error));
        console.error(error);
    }
}

function* setAppointmentContextSaga({payload}) {
    try{
        yield put(setAppointmentContextSuccess(payload));
    } catch (error){
        
        yield put(setAppointmentContextError(error));
        console.error(error);
    }
}

export function* appointmentsSagaWatcher(){
    yield takeLatest(createAppointment.type, createAppointmentSaga);
    yield takeLatest(updateAppointment.type, updateAppointmentSaga);
    yield takeLatest(deleteAppointment.type, deleteAppointmentSaga);
    yield takeLatest(retrieveAppointment.type, retrieveAppointmentSaga);
    yield takeLatest(selectEvent.type, selectEventSaga);
    yield takeLatest(setAppointmentContext.type, setAppointmentContextSaga);
}