import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  DeleteOutlineRounded,
  FileDownloadRounded,
  HelpOutlineRounded,
  WarningRounded,
} from "@mui/icons-material";
import { useDispatch } from "react-redux";
import FileManagerModal from "../FileManager/FileManagerModal";
import { toggleFileManagerModal } from "../../stores/slices/toggleSlice";
import { Image } from "../DataDisplay/Image";

export const InputImage = ({
  name,
  isDisabled,
  onBlur,
  onChange,
  tooltips,
  minWidth,
  maxWidth,
  fullWidth = true,
  value,
  label,
  required,
  fieldRef,
  helperText,
}) => {
  const dispatch = useDispatch();
  const [fakePath, setFakePath] = useState(""); // To handle remove and upload same image multiple times
  const [error, setError] = useState(null);

  const inputTextProps = {
    "data-testid": `${name}FileInput`,
  };

  const handleViewFileManager = (event) => {
    event.preventDefault();
    dispatch(toggleFileManagerModal(true));
  };

  const handleChange = (payload) => {
    if (payload) {
      onChange(payload);
      setFakePath(payload.name);
      setError(null);
    } else {
      onChange("");
      setFakePath("");
    }
  };

  const handleRemoveImage = () => {
    onChange("");
  };

  // Handle render helper text and error text
  const helperHandling = helperText ? (
    <Typography
      sx={({ typography, palette }) => ({
        ...typography.remark,
        color: palette.text.light,
        lineHeight: "unset",
        marginTop: "10px",
        marginLeft: "0px",
        padding: "unset",
        paddingLeft: "10px",
      })}
    >
      {helperText}
    </Typography>
  ) : null;

  const errorHandling = error ? (
    <Typography
      variant="bodyText"
      color={({ palette }) => palette.snack.error}
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "10px",
        marginTop: "5px",
      }}
    >
      <WarningRounded
        sx={({ palette, icons }) => ({
          ...icons.standard,
          color: palette.snack.error,
          pointerEvents: "none",
        })}
      />
      {error}
    </Typography>
  ) : null;

  const startAdornmentComponent = (
    <InputAdornment
      position="start"
      sx={({ palette, spacing }) => ({
        cursor: "pointer",
        background: palette.fileInput.light,
        paddingInline: spacing(3),
        borderRadius: "6px",
        maxHeight: "unset",
        height: "100%",
        width: "fit-content",
        "&:hover": {
          opacity: 0.5,
        },
      })}
    >
      <Typography
        sx={({ typography, palette }) => ({
          ...typography["bodyTextSemiBold"],
          color: palette.primary.txt_default,
        })}
      >
        Select
      </Typography>
    </InputAdornment>
  );

  const tooltipsAdornmentComponent = tooltips ? (
    <InputAdornment position="end">
      <Tooltip title={tooltips}>
        <IconButton
          sx={{ position: "absolute", right: 0, marginRight: "-40px" }}
        >
          <HelpOutlineRounded sx={({ icons }) => ({ ...icons.standard })} />
        </IconButton>
      </Tooltip>
    </InputAdornment>
  ) : null;

  const renderImageDisplay = value ? (
    <Box
      component="div"
      sx={({ palette, spacing }) => ({
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: spacing(2, 0),
        width: 1,
        border: `1px solid ${palette.fileInput.light}`,
        borderTop: "none",
      })}
    >
      <IconButton
        sx={({ spacing }) => ({
          position: "absolute",
          right: 0,
          top: 0,
          margin: spacing(1),
        })}
        onClick={handleRemoveImage}
      >
        <DeleteOutlineRounded
          sx={({ icons, palette }) => ({
            ...icons.standard,
            color: palette.action.delete,
          })}
        />
      </IconButton>
      <Image src={value.url} alt={value.name} width={0.2} heigth="auto" />
    </Box>
  ) : null;

  return (
    <Fragment>
      <FileManagerModal defaultValue={value} handleChange={handleChange} />
      <Box
        sx={{
          minWidth: minWidth,
          maxWidth: maxWidth,
        }}
      >
        <TextField
          id="file-input"
          hidden
          error={!!error}
          fullWidth={!minWidth ? fullWidth : false}
          placeholder={label}
          hiddenLabel
          value={fakePath}
          required={required}
          onBlur={onBlur}
          onClick={handleViewFileManager}
          inputRef={fieldRef}
          disabled={isDisabled}
          inputProps={inputTextProps}
        />
        <Box
          component="label"
          htmlFor="file-input"
          sx={({ palette }) => ({
            position: "relative",
            display: "flex",
            alignItems: "center",
            background: "none",
            border: `1px solid ${palette.border.main}`,
            borderRadius: "6px",
            height: "55px",
          })}
        >
          {startAdornmentComponent}
          <Box
            component="div"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 0.5,
              overflow: "hidden",
            }}
          >
            <FileDownloadRounded
              sx={({ icons, palette }) => ({
                ...icons.standard,
                color: palette.text.light,
              })}
            />
            <Typography
              sx={({ typography, palette }) => ({
                ...typography.bodyText,
                color: palette.text.light,
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              })}
            >
              {value ? value.name : label}
            </Typography>
          </Box>
          {tooltipsAdornmentComponent}
        </Box>
        {renderImageDisplay}
        {helperHandling}
        {errorHandling}
      </Box>
    </Fragment>
  );
};

InputImage.propTypes = {
  name: PropTypes.any,
  isDisabled: PropTypes.any,
  value: PropTypes.any,
  onBlur: PropTypes.any,
  onChange: PropTypes.any,
  tooltips: PropTypes.any,
  minWidth: PropTypes.any,
  maxWidth: PropTypes.any,
  fullWidth: PropTypes.any,
  label: PropTypes.any,
  required: PropTypes.any,
  maxImageSize: PropTypes.number,
  fieldRef: PropTypes.any,
  error: PropTypes.any,
  helperText: PropTypes.any,
};
