import React, {
    useState,
    useEffect,
    useRef,
} from "react";
import './Tenant.scss'
import moment from "moment";
import { Modal } from "bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { signOut } from 'firebase/auth';
import { ref, set, update } from 'firebase/database'
import { db, auth } from '../../firebaseInit';
import { AiFillStar, AiOutlineArrowRight, AiOutlineClose } from "react-icons/ai";
import C_DialogButton from "../../components/C_DialogButton";

import { addToLocalStorage, getFromLocalStorage, removeFromLocalStorage, updateLocalStorage } from "../../util/LocalStorage";

//slices
import { retrieveAvailableTenant, selectAvailableTenant, handleSelectTenant } from "../../stores/slices/tenantSlices";
import { selectCurrUserContext} from "../../stores/slices/companySlices";
import { selectUser } from "../../stores/slices/userAuthSlices";
import { selectCurrGa } from "../../stores/slices/persistRolesSlices";

function TenantPortal() {

    const requestModal = useRef(null);
    const user = selectUser();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const { requestData, currUserData } = selectCurrUserContext();

    const availableTenant = selectAvailableTenant();
    const currGa = selectCurrGa();

    const [workspaceName, setWorkspaceName] = useState("");
    const [errorWorkspaceName, setErrorWorkspaceName] = useState(false);
    const [availableTenantList, setAvailableTenantList] = useState([]);

    useEffect(() => {
        if(user){
             dispatch(retrieveAvailableTenant({ UserID: user?.uid }));
        }
    },[user, requestData]);

    useEffect(() => {
        setAvailableTenantList(availableTenant);
    },[availableTenant]);

    const showRequestModal = (item) => {
        const modalEle = requestModal.current;
        const bsModal = new Modal(modalEle, {
          backdrop: "static",
          keyboard: false,
        });
        bsModal.show();
        setWorkspaceName("");
        setErrorWorkspaceName(false);
    };

    const hideRequestModal = () => {
        const modalEle = requestModal.current;
        const bsModal = Modal.getInstance(modalEle);
        bsModal.hide();
        setErrorWorkspaceName(false);
    };
    
    const handleLogout = async () => {
        try {
            await set(ref(db, 'users/' + user?.uid + '/fcm'), '')
                .then((result) => {
                    console.log("REMOVED NOTIFICATION TOKEN")
                })
                .catch(err => {
                    console.log(err)
                })
            await signOut(auth)
            removeFromLocalStorage("ACCESS_TOKEN");
            removeFromLocalStorage("REFRESH_TOKEN");
            removeFromLocalStorage("CURR_TENANT");
            removeFromLocalStorage("CURR_COMPANY");
            removeFromLocalStorage("CURR_ROLE");
            removeFromLocalStorage("ACCESS_TOKEN_EXPIRY");
            removeFromLocalStorage("LOGIN_EXPIRATION");
            removeFromLocalStorage("IS_SA");
            removeFromLocalStorage("IS_GA");
            navigate("/Auth");
        } catch (error) {
            console.log(error.message)
        }
    };

    function selectTenant(item){

        const { HostID, TenantID } = item;
        const isSA = HostID === user?.uid;
        addToLocalStorage("IS_SA", isSA);
        const tenantLocalStorage = getFromLocalStorage("CURR_TENANT")
        tenantLocalStorage ? updateLocalStorage("CURR_TENANT", TenantID) : addToLocalStorage("CURR_TENANT", TenantID);

        dispatch(handleSelectTenant({TenantID : TenantID, isSA : isSA, UserID : user?.uid, navigation:true}));
    };

    async function handleRequestWorkspace(){
        const createdAt = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
        if(workspaceName !== "" && currUserData){
            setErrorWorkspaceName(false);
            try{
                await set(ref(db, 'planRequest/' + user?.uid + '/' + currUserData?.companyID + '/'), {
                    TenantID: "",
                    companyID: currUserData?.companyID,
                    companyName: currUserData?.companyName,
                    companySize: currUserData?.companySize,
                    displayName: currUserData?.displayName,
                    firstName: currUserData?.firstName,
                    lastName: currUserData?.lastName,
                    state: currUserData?.state,
                    country: currUserData?.country,
                    email: currUserData?.email,
                    phoneNum: currUserData?.phoneNum,
                    plan: "Trial",
                    status: "pending",
                    uid: user?.uid,
                    workspaceName: workspaceName,
                    createdAt:createdAt,
                    isTenant:currUserData?.isTenant,
                    approvalDate:"",
                }).then(() => {
                    hideRequestModal();
                    setErrorWorkspaceName(false);
                    
                })
            }catch(err){
                alert(err);
                setErrorWorkspaceName(false);
            }

        }else{
            setErrorWorkspaceName(true);
        }
    };

    const handleResubmitRequest = async (uid,companyID) => {
        await update(ref(db, 'planRequest/' + uid + '/' + companyID + '/'), {
            status:"resubmission",
        });
    }

  return(
    <>
    <div className="modal fade" tabIndex="-1" ref={requestModal}>
        <div className="modal-dialog modal-dialog-centered" style={{}}>
            <div className="modal-content px-3" style={{overflowY: "scroll" }}>
                <div className="modal-header modalHeader">
                    <div className="title">
                        <span className="font-s">Request for 7-Days Free Trial</span>
                    </div>
                    <AiOutlineClose cursor='pointer' size={16} onClick={() => {
                        hideRequestModal();
                    }}/>
                </div>
                <div className="modal-body">
                    <div className="request-tenant-body">
                        <span>Name your workspace * {errorWorkspaceName && <span className="error">Workspace name can't be empty!</span>}</span>
                        <input type="text" placeholder="Enter your workspace name" value={workspaceName} onChange={(e)=>{setWorkspaceName(e.target.value)}}/>
                    </div>
                    <div style={{ display: "flex", justifyContent: "flex-end", columnGap:'20px', alignItems: "center" }} className="mt-3 modalshit">
                        <C_DialogButton 
                            color={'#ca4254'}
                            background={'white'}
                            borderColor={'#ca4254'}
                            buttonText={"DISCARD"}
                            onClick={() => { hideRequestModal() }}
                        />
                        <C_DialogButton
                            color={"white"}
                            background={'#3eb986'}
                            borderColor={'#3eb986'}
                            buttonText={"REQUEST"}
                            onClick={() => { 
                                handleRequestWorkspace();
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className='tenant-portal-main-container'>
        <div className="tenant-portal-intro-container">
            <div className="intro-sub-container">
                <div className="introduction-container">
                    <span className="welcomeback">Welcome back, {user?.displayName}</span>
                    <span>Boost your customer engagement and sales with Zumax CRM system. Streamline your sales, marketing, and support efforts with powerful tools that save time and improve your bottom line!</span>
                    <div className="rating-container">
                        <div className="commentor">
                            <span>- Zumax Digital</span>
                        </div>
                        <div className="star">
                            <AiFillStar size={20}/>
                            <AiFillStar size={20}/>
                            <AiFillStar size={20}/>
                            <AiFillStar size={20}/>
                            <AiFillStar size={20}/>
                        </div>
                    </div>
                </div>
                <div className="background-container">
                </div>
            </div>
        </div>
        <div className="tenant-portal-list-container">
            <div className="tenant-list-sub-container">
                <div className="company-logo-container">
                    <img src={require("../../assets/img/zumax-logo-black.webp")} alt=""/>
                    <span className="welcome">Welcome to Zumax CRM</span>
                    <span className="choose">Choose a workspace to start</span>
                </div>
                {isLoading ?
                <div className={`loading-container ${!isLoading && 'hide-loading-container'}`}>
                    <div className="loading-spinner">
                    </div>
                </div>
                :
                <div className="tenant-list-container">
                    {(requestData && requestData.length === 0) && 
                        <>
                        <div className="request-button" onClick={() => { showRequestModal(); }}>
                            <span>Start a free 7-days trial</span>
                            <AiOutlineArrowRight className="icon" size={18}/>
                        </div>
                        </>
                    }
                    {(requestData && requestData.length === 1 && requestData[0]?.status !== "active") && 
                        <div className="tenant-list" onClick={()=>{requestData[0]?.status === "rejected" && handleResubmitRequest(requestData[0]?.uid,requestData[0]?.companyID);}}>
                            <span className="profile">{requestData[0]?.workspaceName[0]}</span>
                            <div className="detail">
                                <span>{requestData[0]?.workspaceName} {requestData[0]?.status === "rejected" &&<span style={{ fontWeight: "bold",color:'#b61e1e',fontSize:'11px' }}>Click to resubmit request.</span>}</span>
                                <div className="subDetail">
                                    <span>My workspace</span>
                                    <span style={{
                                        color: "#FFF",
                                        backgroundColor: requestData[0]?.status === "pending" ? "#909b10" :
                                        requestData[0]?.status === "active" ? "#737272" : 
                                        requestData[0]?.status === "resubmission" ? "#3f4dce" : "#656565"
                                    }}>{requestData[0]?.status}</span>
                                </div>
                            </div>
                        </div>
                    }
                    
                    {availableTenantList && availableTenantList?.filter(e => e?.HostID === user?.uid)?.length > 0 &&
                        <div className="workspaces-title">
                            <span>My Workspace</span>
                        </div>
                    }
                    {availableTenantList && availableTenantList?.filter(e => e?.HostID === user?.uid)?.length > 0 &&
                        availableTenantList?.filter(e => e?.HostID === user?.uid)?.map((item, index) => {
                            return(
                                <div key={index} className="tenant-list" onClick={() => { selectTenant(item) }}>
                                    <span className="profile">{item?.WorkspaceName[0]}</span>
                                    <span>{item?.WorkspaceName}</span>
                                </div>
                            )
                        })
                    }
                    {currGa ? 
                     <div className="tenant-list" onClick={() => { navigate("/GaPortal") }}>
                        <span className="profile">GA</span>
                        <span className="name">General Admin Portal</span>
                     </div>
                    : <></>}
                    {availableTenantList && availableTenantList?.filter(e => e?.HostID !== user?.uid)?.length > 0 &&
                        <div className="workspaces-title">
                            <span>Shared Workspace</span>
                        </div>
                    }
                    {availableTenantList && availableTenantList?.filter(e => e?.HostID !== user?.uid)?.length > 0 &&
                        availableTenantList?.filter(e => e?.HostID !== user?.uid)?.map((item, index) => {
                            const tenantID = item.TenantID
                            const email = item.EmailAddress;
                            const username = email.split('@')[0]; 
                            const slicedID = tenantID.slice(3, 10);
                            const mixedString = username + slicedID;

                            return(
                                <div key={index} className="tenant-list" onClick={() => { selectTenant(item) }}>
                                    <span className="profile">{item?.WorkspaceName[0]}</span>
                                    <span className="name">{`${item?.WorkspaceName}`}<span className="id">{` (${mixedString})`}</span></span>
                                </div>
                            )
                        })
                    }
                </div>
                }
                <span className="signout" onClick={() => {
                    handleLogout();
                }}>Sign out</span>
            </div>
        </div>
    </div>
    </>
  )
}

export default TenantPortal;