import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.scss';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import 'react-circular-progressbar/dist/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css'
import 'react-toastify/dist/ReactToastify.css';
import 'react-virtualized/styles.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import 'react-quill/dist/quill.snow.css';
import 'react-circular-progressbar/dist/styles.css';
import 'reactflow/dist/style.css';
import { Provider } from 'react-redux';
import { HashRouter } from "react-router-dom";
import { PersistGate } from 'redux-persist/integration/react'
// import { persistor, store } from './redux/store';
import { store } from './stores/index.js';
import { GoDeviceMobile } from 'react-icons/go';
import { MdOutlineNetworkCheck } from 'react-icons/md';
import moment from 'moment';
import 'moment-timezone';
import { globalStyles, theme } from './styles/index.js';
import { ThemeProvider } from '@mui/material';


moment.tz.setDefault('Asia/Kuala_Lumpur');
const currentTime = moment().format('MMMM Do YYYY, h:mm:ss a');
const root = ReactDOM.createRoot(document.getElementById('root'));
const isOnline = navigator.onLine;
const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent);

root.render(
  <>
  {isOnline ?
    <Provider store={store}>

    <ThemeProvider theme={theme}>
    {globalStyles}
      {/* <PersistGate loading={null} persistor={persistor}> */}
        <HashRouter>
            {isMobile ?
            <div className='restrictionMobile'>
              <div className='detection'>
                <span>Mobile/Tablet Detected</span>
                <GoDeviceMobile size={16} color="#FFF"/>
              </div>
              <span>You can't browse this page in Mobile/Tablet</span>
            </div>
            :
            <App/>
            }
            {/* <App/> */}
        </HashRouter>
        </ThemeProvider>
      {/* </PersistGate> */}
    </Provider>
    :
    <div className='restrictionNetwork'>
      <div className='detection'>
        <span>Network Error. Please check your connection.</span>
        <MdOutlineNetworkCheck size={16} color="#FFF"/>
      </div>
      <span>You must have wifi enabled to access.</span>
    </div>
  }
  </>
);
