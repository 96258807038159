import { call, delay, put, takeLatest } from "redux-saga/effects";
import { 
    checkAllExistingClientData, 
    checkAllExistingClientDataError, 
    checkAllExistingClientDataSuccess, 

    checkExistingClientData, 
    checkExistingClientDataError, 
    checkExistingClientDataSuccess, 
    
    createClient, 
    createClientError, 
    createClientSuccess, 
    
    deleteClient, 
    deleteClientError, 
    deleteClientSuccess, 
    
    importClient, 
    importClientError, 
    importClientSuccess, 
    
    retrieveAllClientsCount, 
    retrieveAllClientsCountError, 
    retrieveAllClientsCountSuccess,
    
    retrieveAllGroup, 
    retrieveAllGroupError, 
    retrieveAllGroupSuccess, 

    retrieveAvailableGroup, 
    retrieveAvailableGroupError, 
    retrieveAvailableGroupSuccess, 

    retrieveClient, 
    retrieveClientError, 
    retrieveClientSuccess, 

    retrieveClientFilterData, 
    retrieveClientFilterDataError, 
    retrieveClientFilterDataSuccess, 

    retrieveClientForm, 
    retrieveClientFormError, 
    retrieveClientFormSuccess, 

    setClientDndAction, 
    setClientDndActionError, 
    setClientDndActionSuccess, 
    
    setDndAction, 
    setDndActionError, 
    setDndActionSuccess, 
    
    setToggleTabState, 
    setToggleTabStateError, 
    setToggleTabStateSuccess, 
    
    updateClient, 
    updateClientData, 
    updateClientDataError,
    
    updateClientDataSuccess, 
    updateClientError, 
    updateClientSuccess, 

    processImportDataSuccess,
    processImportData,
    processImportDataError,
    setClientContextSuccess,
    setClientContextError,
    setClientContext,
    getInitialClientDataSuccess,
    getInitialClientDataError,
    getInitialClientData,
    setClientInitialStateSuccess,
    setClientInitialStateError,
    setClientInitialState,
    getProcessedClientData,
    getProcessedClientDataSuccess,
    getProcessedClientDataError,
    singleTransferClientSuccess,
    singleTransferClient,
    bulkTransferClientSuccess,
    bulkTransferClient
} from "../stores/slices/clientSlices";
import { ApiRoute } from "../enums/ApiRoute";
import { API } from "../http-common";
import { getAllClientAgent, importClientAgent, removeClientAgents } from "../stores/slices/clientAgentSlices";
import { createLogs } from "../stores/slices/logSlices";
import { v4 } from "uuid";
import moment from "moment";
import { retrieveClientHandler } from "../stores/slices/clientGroupHandlerSlices";
import { retrieveFormTemplate } from "../stores/slices/formTemplateSlices";
import { retrieveAllClientGroupLeader, retrieveAllClientGroupLeaderSuccess } from "../stores/slices/clientGroupLeaderSlices";
import { retrieveCurrUsage } from "../stores/slices/usageSlices";
import CommonFunc from "../util/CommonFunc";
import { getFromLocalStorage } from "../util/LocalStorage";
import { getClientGroupRolesSuccess, getCurrUserClientGroupRoles } from "../stores/slices/clientGroupRolesSlices";

function* createClientSaga({payload}) {
    const { TenantID, GroupID = null, UserID = null , isSA = null, data,
            refreshUsage, setIsLoading, setNotifyMessage, setLoadData
     } = payload
    try{
        yield call(API.post, ApiRoute.client.createClient, {TenantID, data});
        if(GroupID && UserID && isSA){
            yield put(getAllClientAgent({TenantID : TenantID, GroupID : GroupID, UserID: UserID, isSA: isSA}))
            yield put(createClientSuccess({TenantID, data}));
        } else {
            yield put(createClientSuccess(payload));
        }

        yield put(setClientContext({
            checkedClientState : [],
        }));
        refreshUsage();
        setIsLoading(false);
        setNotifyMessage(CommonFunc.notify("create"));
        setLoadData(false);

    } catch (error){
        yield put(createClientError(error));
        console.error(error);
    }
}

function* importClientSaga({payload}) {
    try{
        yield call(API.post, ApiRoute.client.importClient, payload);
        yield put(importClientSuccess(payload));
    } catch (error){
        yield put(importClientError(error));
        console.error(error);
    }
}

function* retrieveClientSaga({payload}) {
    try{
        const { availableClientsGroup = null, currUserSA = null, selectedGroupValue = null, user = null, ...otherPayloads } = payload;
        const isSA = payload.isSA;
        const { data : clients } = yield call(API.post, ApiRoute.client.retrieveAllClient, otherPayloads);
        // if(user && availableClientsGroup && currUserSA && selectedGroupValue){
        // console.log("clients : ", clients)
            yield put(getProcessedClientData({
                clients,
                availableClientsGroup,
                currUserSA,
                selectedGroupValue,
                isSA,
                user
            }));
        // }
        yield put(retrieveClientSuccess(clients));
    } catch (error){
        yield put(retrieveClientError(error));
        console.error(error);
    }
}

function* retrieveAllClientsCountSaga({payload}) {
    const {TenantID, CurrGroup, ...otherPayload} = payload;
    try{
        if(TenantID && CurrGroup){
            const { data } = yield call(API.post, ApiRoute.client.retrieveClientCount, payload);
            yield put(retrieveAllClientsCountSuccess(data));
        }
       
    } catch (error){
        yield put(retrieveAllClientsCountError(error));
        console.error(error);
    }
}

function* retrieveAllGroupSaga({payload}) {
    try{
        const { data } = yield call(API.post, ApiRoute.client.retrieveAllClientGroup, payload);
        yield put(retrieveAllGroupSuccess(data));
    } catch (error){
        yield put(retrieveAllGroupError(error));
        console.error(error);
    }
}

function* retrieveAvailableGroupSaga({payload}) {
    try{
        const { data } = yield call(API.post, ApiRoute.client.retrieveClientGroup, payload);
        yield put(retrieveAvailableGroupSuccess(data));
    } catch (error){
        yield put(retrieveAvailableGroupError(error));
        console.error(error);
    }
}

function* updateClientSaga({payload}) {
    try{
        const { TenantID, GroupID = null, UserID = null , isSA = null, data,
            setLoadData, setNotifyMessage, setIsLoading, setMixedClients, setSharedHandlerList
        } = payload

        yield call(API.post, ApiRoute.client.updateClient, payload);
        if(GroupID && UserID && isSA){
            yield put(getAllClientAgent({TenantID : TenantID, GroupID : GroupID, UserID: UserID, isSA: isSA}))
            yield put(updateClientSuccess(payload));
        } else { 
            yield put(updateClientSuccess(payload));
        }
        
        yield put(setClientContext({
            checkedClientState : [],
            checkedAllClientState : false
        }));
        setLoadData(false);
        setNotifyMessage(CommonFunc.notify("edit"));
        setIsLoading(false);
        setMixedClients(0);
        setSharedHandlerList([])

    } catch (error){
        yield put(updateClientError(error));
        console.error(error);
    }
}

function* updateClientDataSaga({payload}) {
    try{
        yield call(API.post, ApiRoute.client.updateClientData, payload);
        yield put(updateClientDataSuccess(payload));
    } catch (error){
        yield put(updateClientDataError(error));
        console.error(error);
    }
}

function* deleteClientSaga({payload}) {
    const { removeHandlerList = null, clientList = null, TenantID, data, GroupID, UserID, isSA, clientData } = payload;

    try{
        yield call(API.post, ApiRoute.client.deleteClient, {TenantID, data});
        if(removeHandlerList && clientList){
            yield put(removeClientAgents({TenantID: TenantID, removeHandlerList, clientList}))
            yield put(getAllClientAgent({TenantID : TenantID, GroupID : GroupID, UserID: UserID, isSA: isSA}))
            yield put(deleteClientSuccess(payload));
        } else {
            yield put(deleteClientSuccess(payload));
        }
       
    } catch (error){
        yield put(deleteClientError(error));
        console.error(error);
    }
}

function* checkExistingClientDataSaga({payload}) {
    try{
        const { data } = yield call(API.post, ApiRoute.client.checkExistingClientData, payload);
        yield put(checkExistingClientDataSuccess(data));
    } catch (error){
        yield put(checkExistingClientDataError(error));
        console.error(error);
    }
}

function* checkAllExistingClientDataSaga({payload}) {
    try{
        const { data } = yield call(API.post, ApiRoute.client.checkAllExistingClientData, payload);
        yield put(checkAllExistingClientDataSuccess(data));
    } catch (error){
        yield put(checkAllExistingClientDataError(error));
        console.error(error);
    }
}

function* retrieveClientFormSaga({payload}) {
    try{
        const { data } = yield call(API.post, ApiRoute.client.retrieveClientForm, payload);
        yield put(retrieveClientFormSuccess(data));
    } catch (error){
        yield put(retrieveClientFormError(error));
        console.error(error);
    }
}

function* retrieveClientFilterDataSaga({payload}) {
    try{
        const { data } = yield call(API.post, ApiRoute.client.retrieveAllClientFilterData, payload);
       
        yield put(retrieveClientFilterDataSuccess(data));
    } catch (error){
        yield put(retrieveClientFilterDataError(error));
        console.error(error);
    }
}

function* setToggleTabStateSaga({payload}) {
    try{
        yield put(setToggleTabStateSuccess(payload));
    } catch (error){
        yield put(setToggleTabStateError(error));
        console.error(error);
    }
}

function* setDndActionSaga({payload}) {
    try{
        yield put(setDndActionSuccess(payload));
    } catch (error){
        yield put(setDndActionError(error));
        console.error(error);
    }
}

function* setClientDndActionSaga({payload}) {
    try{
        yield put(setClientDndActionSuccess(payload));
    } catch (error){
        yield put(setClientDndActionError(error));
        console.error(error);
    }
}

function* processImportDataSaga({payload}) {
    try{
        if(payload){
            const {setNotifyMessage, ...otherPayload} = payload;
            const currSa = payload.currSa;
            const user = payload.user;
            const tenant = payload.tenant;
            const companyID = payload.companyID;
            const groupID = payload.groupID;
            const clientColumnArray = payload.clientColumnArray;
            const importedData = payload.importedData;
            const isSuperAdmin = payload.isSuperAdmin;
            const searchText = payload.searchText;
            const sortType = payload.sortType;
            const sortOrder = payload.sortOrder;
            const dataFilterArr = payload.dataFilterArr;
        
            const rowDataArr = [];
    
            const { data : clientGroupRole } = yield call(API.post, ApiRoute.clientGroupRoles.getCurrUserClientGroupRoles, {TenantID: tenant, GroupID : groupID, UserID: user?.uid});
            const ViewableColumn = clientGroupRole ? clientGroupRole.ViewableColumn : [];
            const { data : allClientGroupLeader } = yield call(API.post, ApiRoute.clientGroupLeader.retrieveAllClientGroupLeader, {TenantID: tenant, GroupID : groupID});
            const { data } = yield call(API.post, ApiRoute.client.processImportData, {...otherPayload, ViewableColumn });
            const newImportedRow = data.newImportedRow;
            const isValid = data.isValid;
            if(isValid){
    
                const clientArr = newImportedRow.map(item=>item.filter(data=>data.columnID !== "Handler"));
                const handlerArr = newImportedRow.map(item=>item.filter(data=>data.columnID === "Handler"));
                const newHandlerArr = handlerArr.map(item=>{
                let value;
                item.map(data=>{
                    value = data.rowValue;
                })
                return {ClientID:"", UserID: value};
                }) ?? [];
    
                const uniqueImportedRow = clientArr.map((item) => {
                    // Create a copy of the existing item to avoid mutating the original array
                    const updatedItem = [...item];
                
                    clientColumnArray.forEach((col) => {
                        const existingEntry = item.find(entry => entry.columnID === col.columnID);
                
                        if (!existingEntry) {
                            let newEntry = null;
                
                            if (col.privilege === "Hidden") {
                                if (col.columnType === "Form") {
                                    newEntry = { rowID: v4(), columnID: col.columnID, rowValue: "-" };
                                } else if (col.columnType === "Checkboxes") {
                                    newEntry = { rowID: v4(), columnID: col.columnID, rowValue: [] };
                                } else if (col.columnType === "Switch") {
                                    newEntry = { rowID: v4(), columnID: col.columnID, rowValue: true };
                                } else if (col.columnType === "Member") {
                                    newEntry = { rowID: v4(), columnID: col.columnID, rowValue: "" };
                                } else {
                                    newEntry = { rowID: v4(), columnID: col.columnID, rowValue: "" };
                                }
                            } else {
                                if (col.columnType === "Member" && !currSa) {
                                    newEntry = { rowID: v4(), columnID: col.columnID, rowValue: "-" };
                                } else if (col.columnType === "Form") {
                                    newEntry = { rowID: v4(), columnID: col.columnID, rowValue: "-" };
                                } else if (col.columnType === "Alert" && !isSuperAdmin && !allClientGroupLeader.some(element => element.UserID === user.uid)) {
                                    newEntry = { rowID: v4(), columnID: col.columnID, rowValue: { status: false, expiry: null } };
                                } else {
                                    newEntry = { rowID: v4(), columnID: col.columnID, rowValue: "" };
                                }
                            }
                
                            if (newEntry) {
                                updatedItem.push(newEntry);
                            }
                        }
                    });
                
                    return updatedItem;
                });
                
                if(uniqueImportedRow.length > 0) {      
                uniqueImportedRow.filter(item=>item.columnID !== "Handler").forEach((item,index)=>{
                    [...clientColumnArray]
                    .sort((a, b) => a.columnIndex - b.columnIndex)
                    .forEach((element) => {
                        item.forEach((rowData, index) => {
                            // Elements swap by index
                            // Index not match, find the correct index and swap it
                        if (rowData.columnID === element?.columnID && index !== element.columnIndex) {
                            [item[index], item[element.columnIndex]] = [item[element.columnIndex], item[index]];
                        }
                        });
                    });
                    const ClientID = v4();
                    rowDataArr.push({
                        ClientID: ClientID,
                        UserID : user.uid,
                        GroupID: groupID,
                        CompanyID : companyID,
                        ClientData: JSON.stringify(item),
                        Status: 1,
                        DeletedAt : null,
                        CreatedAt: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                    })
                    newHandlerArr[index].ClientID = ClientID; 
                })
    
                let flattenHandlerArr = [];
                if(currSa){
                    flattenHandlerArr = newHandlerArr?.flatMap((item) =>
                    Array.isArray(item.UserID)
                        ? item.UserID.map((handler) => ({ ClientID: item.ClientID, UserID: handler }))
                        : []
                    );
                    
                };
    
                const isAlertAvailable = clientColumnArray.find(item => item.columnType === "Alert");
                if ((isSuperAdmin || allClientGroupLeader.some(element=>element.UserID === user.uid)) && isAlertAvailable && isAlertAvailable.columnType === "Alert") {
                    // Iterate over data array
                    rowDataArr.forEach(entry => {
                        // Parse ClientData as JSON
                        const clientData = JSON.parse(entry.ClientData);
                        // Find the corresponding row in ClientData for "Alert" column
                        const alertRow = clientData.find(row => row.columnID === isAlertAvailable.columnID);
                        // If the row is found, update the rowValue to date only
                        if (alertRow && alertRow.rowValue) {
                            const dateOnly = moment(alertRow.rowValue).format("YYYY-MM-DD");
                            alertRow.rowValue = {status: false , expiry: dateOnly};
                        }
                
                        // Update the entry's ClientData
                        entry.ClientData = JSON.stringify(clientData);
                    });
                }
    
                yield put(importClient({TenantID: tenant, data: rowDataArr}));
                    const ActivityLogsID = v4();
                    const UserID = user.uid;
                    const CompanyID = companyID;
                    const GroupID = groupID;
                    const Title = "List of client(s) have been imported";
                    const Activity = `imported ${newImportedRow.length} client(s)`;
                    const Action = "import";
                    const Section = "Clients";
                    const Keyword = "import";
                    const CreatedAt = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
    
                    const logsData = {
                        ActivityLogsID,
                        UserID,
                        CompanyID,
                        GroupID,
                        Title,
                        Activity,
                        Action,
                        Section,
                        Keyword,
                        CreatedAt,
                    };
    
                    if(currSa){
                        yield put(importClientAgent({TenantID: tenant, GroupID:groupID, handlerArr: flattenHandlerArr}));
                    }
                    yield put(createLogs({TenantID: tenant, data:logsData}))
                    
                    yield put(retrieveAllClientsCount({ TenantID: tenant, CompanyID: companyID, SearchText: searchText, SearchBy: "", UserID: user.uid, 
                        CurrGroup: groupID, SelectedDateColumn: "", StartDate: "", EndDate: "", 
                        DataFilterArr: dataFilterArr })),
                    yield put(retrieveClient({ TenantID: tenant, CompanyID: companyID, CurrPage: 0, SortType: sortType, SortOrder: sortOrder, SearchText: searchText, 
                        SearchBy: "", UserID: user.uid, CurrGroup: groupID, SelectedDateColumn: "", 
                        StartDate: "", EndDate: "", DataFilterArr: dataFilterArr })),
                    yield put(retrieveClientFilterData({ TenantID: tenant, SearchText: searchText, DataFilterArr: dataFilterArr, CompanyID: companyID, GroupID: groupID, UserID: user.uid }))
                    setNotifyMessage(CommonFunc.notify("import"))
                }
            }
           
            yield put(processImportDataSuccess(data));
        } else {
            yield put(processImportDataSuccess(payload));
        }
        
    } catch (error){
        yield put(processImportDataError(error));
        console.error(error);
    }

}


function* setClientContextSaga({payload}) {
    try{
        yield put(setClientContextSuccess(payload));
    } catch (error){
        yield put(setClientContextError(error));
        console.error(error);
    }
}

//change group api

//retrieveClientFunction api (sort, search etc)

function* getInitialClientDataSaga({payload}) {
    const {
        TenantID = null,
        UserID = null,
        CompanyID = null,
        isSA = false,
        CurrGroupData : payloadCurrGroupData = null,
        CurrGroup : payloadCurrGroup = null,
        user,
    } = payload;

    const savedGroupID = getFromLocalStorage("GROUP_ID");
    try{
        if(TenantID && UserID){
            //availableGroup
            let CurrGroupData;
            let CurrGroup;
            let GroupID;
            let availableClientsGroup = [];
            //GroupID and CurrGroup same
            const response = yield call(API.post, ApiRoute.client.retrieveClientGroup, {TenantID, UserID, CompanyID, isSA});
            availableClientsGroup = response.data;
            if(!payloadCurrGroup && !payloadCurrGroupData){
                if(availableClientsGroup && availableClientsGroup.length > 0){
                    const savedGroup = availableClientsGroup.find((group) => group.GroupID === savedGroupID);
                    CurrGroup = savedGroup ? savedGroupID : availableClientsGroup[0].GroupID;
                    GroupID = savedGroup ? savedGroupID : availableClientsGroup[0].GroupID;
                    CurrGroupData = savedGroup ? savedGroup : availableClientsGroup[0];
                }
               
            } else {
                CurrGroup = payloadCurrGroup;
                CurrGroupData = payloadCurrGroupData;
                GroupID = payloadCurrGroup
            }
            const CurrPage = 0;
            const SortType = "";
            const SortOrder = "";
            const SearchText = "";
            const SearchBy = "";
            const SelectedDateColumn = "";
            const StartDate = "";
            const EndDate = "";
            const DataFilterArr = [];

            if(GroupID){
                //slices
                // yield put(getCurrUserClientGroupRoles({TenantID, GroupID, UserID}));
                yield put(retrieveClient({
                    TenantID,
                    CompanyID,
                    CurrPage,
                    SortType,
                    SortOrder,
                    SearchText,
                    SearchBy,
                    UserID,
                    CurrGroup, 
                    isSA,
                    SelectedDateColumn,
                    StartDate,
                    EndDate,
                    DataFilterArr,
                }));
                yield put(retrieveCurrUsage({ TenantID: TenantID, CompanyID: CompanyID }));
                yield put(retrieveClientHandler({TenantID}));
                yield put(retrieveFormTemplate({TenantID, CompanyID}));
                yield put(retrieveClientFilterData({TenantID, CompanyID, GroupID, UserID, isSA}));
                yield put(getAllClientAgent({TenantID, CompanyID, GroupID, UserID, isSA}));
                yield put(retrieveAllClientsCount({
                    TenantID,
                    CompanyID,
                    SearchText,
                    SearchBy,
                    UserID,
                    CurrGroup,
                    isSA,
                    SelectedDateColumn,
                    StartDate,
                    EndDate,
                    DataFilterArr,
                }));
                const { data : allClientGroupLeader } = yield call(API.post, ApiRoute.clientGroupLeader.retrieveAllClientGroupLeader, {TenantID, GroupID});
                const { clientColData, clientColumnArray, selectedGroupValue } = yield call(processColumnData, CurrGroupData, allClientGroupLeader, isSA, availableClientsGroup, user)

                // const { clientCsvTemplateHeader, clientCsvHeader } = yield call(processCSVImportTemplate, clientColumnArray, allClientGroupLeader, isSA, availableClientsGroup, user );
                const { data : clientGroupRole } = yield call(API.post, ApiRoute.clientGroupRoles.getCurrUserClientGroupRoles, {TenantID, GroupID, UserID});
                const ViewableColumn = clientGroupRole ? clientGroupRole.ViewableColumn : [];
                const { clientCsvTemplateHeader, clientCsvHeader } = yield call(processCSVImportTemplate, clientColumnArray, allClientGroupLeader, isSA, availableClientsGroup, user, ViewableColumn );

                // const memberViewableColumn = clientColumnArray.filter((col)=> ViewableColumn.includes(col.columnID));
                yield put(getClientGroupRolesSuccess(clientGroupRole));
                yield put(setClientContext({
                    clientColData, 
                    clientColumnArray,
                    // filteredClientColumnArray,
                    clientCsvTemplateHeader,
                    clientCsvHeader,
                    selectedGroupValue : selectedGroupValue,
                    clientColArray : clientColumnArray
                }))
                yield put(setClientInitialState({
                    availableClientsGroup,
                    allClientGroupLeader,
                }))
            }
        }
        yield put(getInitialClientDataSuccess(payload));
    } catch (error){
        yield put(getInitialClientDataError(error));
        console.error(error);
    }
}

function* setClientInitialStateSaga({payload}) {
    try{
        yield put(setClientInitialStateSuccess(payload));
    } catch (error){
        yield put(setClientInitialStateError(error));
        console.error(error);
    }
}

function* getProcessedClientDataSaga({payload}) {
    // client, availableClientsGroup, currUserSA, clientColData
    const {
        client,
        availableClientsGroup,
        currUserSA,
        selectedGroupValue,
        isSA,
        user,
    } = payload;
    
    try{
       const { clientColData } = yield call(processColumnData, selectedGroupValue, null, isSA, availableClientsGroup, user)
       const {filteredClientData, filteredRowData} = yield call(processClientData, 
           client, availableClientsGroup, currUserSA, clientColData
       )

       yield put(setClientContext({
        filteredClientData,
        filteredRowData,
        tempClientMergedData : filteredClientData
    }))

        yield put(getProcessedClientDataSuccess(payload));
    } catch (error){
        yield put(getProcessedClientDataError(error));
        console.error(error);
    }
}

function processCSVImportTemplate(clientColumnArray, allClientGroupLeader, isSA, availableClientsGroup, user, ViewableColumn ){
    const clientGroupLeader = allClientGroupLeader.filter(element => availableClientsGroup.map(clientGroup => clientGroup.GroupID).includes(element.GroupID));
    // const filteredArray = isSA ? clientColumnArray : clientColumnArray.filter(col => ViewableColumn.includes(col.columnID));
    const filteredArray = clientColumnArray;
    let tempTemplateHeader = [];
    let tempColHeader = [];

    return new Promise((resolve, reject)=>{
        if(filteredArray.length > 0){
            if(isSA){
                for (var x = 0; x < filteredArray.length; x++) {
                    if (filteredArray[x]?.columnName !== "ClientID" && filteredArray[x]?.columnName !== "DepartmentID" && filteredArray[x]?.columnName !== "PosID"
                        && filteredArray[x]?.columnName !== "RoleID" && filteredArray[x]?.columnName !== "UserPassword" && filteredArray[x]?.privilege !== "Hidden"
                        && filteredArray[x]?.columnType !== "Form" && filteredArray[x]?.columnType !== "Alert"
                    ) { 
                        tempTemplateHeader.push({
                            label: filteredArray[x]?.columnName,
                            key: filteredArray[x]?.columnName
                        })
                    }
                    if (filteredArray[x]?.columnName !== "ClientID" && filteredArray[x]?.columnName !== "DepartmentID" && filteredArray[x]?.columnName !== "PosID" && filteredArray[x]?.privilege !== "Hidden"
                    ) {
                        const isNotRestricted = ViewableColumn?.includes(filteredArray[x]?.columnID);
                        if(isNotRestricted){
                            tempColHeader.push({
                                label: filteredArray[x]?.columnName,
                                key: filteredArray[x]?.columnName
                            })
                        }
                        
                    }
                }
            } else {
                for (var x = 0; x < filteredArray.length; x++) {
                    if (filteredArray[x]?.columnName !== "ClientID" && filteredArray[x]?.columnName !== "DepartmentID" && filteredArray[x]?.columnName !== "PosID"
                        && filteredArray[x]?.columnName !== "RoleID" && filteredArray[x]?.columnName !== "UserPassword" && filteredArray[x]?.privilege !== "Hidden"
                        && filteredArray[x]?.columnType !== "Form" && filteredArray[x]?.columnType !== "Member" && filteredArray[x]?.columnType !== "Alert"
                    ) {
                        tempTemplateHeader.push({
                            label: filteredArray[x]?.columnName,
                            key: filteredArray[x]?.columnName
                        })
                    }
                    if (filteredArray[x]?.columnName !== "ClientID" && filteredArray[x]?.columnName !== "DepartmentID" && filteredArray[x]?.columnName !== "PosID" && filteredArray[x]?.privilege !== "Hidden" && filteredArray[x]?.columnName !== "Member"
                    ) {
                        const isNotRestricted = ViewableColumn?.includes(filteredArray[x]?.columnID);
                        if(isNotRestricted){
                            tempColHeader.push({
                                label: filteredArray[x]?.columnName,
                                key: filteredArray[x]?.columnName
                            })
                        }
                    }
                }
            }
    
            if(isSA || clientGroupLeader.some(element=>element.UserID === user?.uid)){   
                const alert = filteredArray.find(item => item.columnType === "Alert");
                tempColHeader.push({
                    label: "Handler",
                    key: "handlerIDs"
                })
                if(alert){
                    tempTemplateHeader.push({ 
                        label: alert?.columnName, 
                        key: alert?.columnName 
                    })
                }
            }
    
            if(isSA){
                tempTemplateHeader.push({label:"Handler",key:"Handler"})
            }
            
            resolve({
                clientCsvTemplateHeader : tempTemplateHeader, //getTemplate
                clientCsvHeader : tempColHeader //export
            })
        } else {
            reject("Invalid column");
        }
        
    })
}

function processColumnData(CurrGroup, allClientGroupLeader, isSA, availableClientsGroup, user){
     return new Promise((resolve, reject)=>{
        if(CurrGroup){
            //null check
            const clientColData = [{...CurrGroup, ColumnData: JSON.parse(CurrGroup?.ColumnData)}];
            const parsedColumnData = JSON.parse(CurrGroup?.ColumnData);
            const clientColumnArray = parsedColumnData;
            const selectedGroupValue = CurrGroup;
            const clientGroupLeader = (allClientGroupLeader && availableClientsGroup) ? allClientGroupLeader.filter(element => availableClientsGroup.map(clientGroup => clientGroup.GroupID).includes(element.GroupID)) : [];

            return resolve({clientColData, clientColumnArray, selectedGroupValue});
            
        } else {
            resolve("undefined CurrGroup")
        }
    })
}

function processClientData(client, availableClientsGroup, currUserSA, clientColData){
    return new Promise((resolve, reject)=>{
       
        if(availableClientsGroup && clientColData && availableClientsGroup.length > 0){
            const tempClient = client.map((data) => {
                return {
                    ...data,
                    ClientData : JSON.parse(data.ClientData),
                    handlerIDs : data.handlerIDs !== undefined ? data.handlerIDs : [],
                    handlerName : data.handlerName !== undefined ? data.handlerName : [],
                    CreatedAt : moment(data.CreatedAt).format("YYYY-MM-DD HH:mm:ss"),
                    DeletedAt : moment(data.CreatedAt).format("YYYY-MM-DD HH:mm:ss")
                }
            })
    
            const filteredRowData = tempClient.filter((elem, index, self) => {
                return self.findIndex((t) => (
                t.ClientID === elem.ClientID
                )) === index;
            });
    
            //reduce data
            const getClient = filteredRowData?.map(item => {
                
                const parsedData = CommonFunc.isJSON(item.ClientData) ? JSON.parse(item.ClientData) : item.ClientData;
                const data = parsedData?.map((value, index) => {
                    return value
                })
                return data;
            });
    
            const mergeData = getClient?.map((item, index) => {
                return item.reduce((acc, value) => {
                    if(value.columnID !== undefined)
                    {
                        const objKey = clientColData.length > 0 && clientColData[0]?.ColumnData.filter(key => { 
                        
                        if(key?.columnID === value?.columnID) 
                        {
                            return key.columnName;
                        }
                        })
        
                        if(objKey[0]?.columnID === value?.columnID){
                            const GroupName = availableClientsGroup?.find(group => group.GroupID === filteredRowData[index].GroupID)?.GroupName;
                            const handlerName = currUserSA.filter(element => filteredRowData[index].handlerIDs.includes(element.uid)).map(element => element.displayName)
                            const handlerID = currUserSA.filter(element => filteredRowData[index].handlerIDs.includes(element.uid)).map(element => element.uid)
                            return {...acc, 
                                [objKey[0].columnName]: objKey[0].columnType ? value.otherValue ? value.rowValue.concat(value.otherValue) : value.rowValue : value.rowValue,
                                ClientID : filteredRowData[index].ClientID, 
                                UserID: filteredRowData[index].UserID, 
                                handlerName: handlerName.join(', '),
                                handlerID: handlerID.join(', '),
                                handlerIDs: handlerID.join(', '),
                                CreatedAt: filteredRowData[index].CreatedAt,
                                DeletedAt: filteredRowData[index].DeletedAt,
                                GroupName: GroupName, 
                            };
                        }
                    }
                    return acc;
                }, {})
            }); 
    
            resolve({ filteredRowData : filteredRowData, filteredClientData : mergeData });
    
        } else {
            reject("available client groups length < 1 or client column data missing")
        }
    })
}

function* singleTransferClientSaga({payload}) {

    try{
        yield call(API.post, ApiRoute.client.singleTransferClient, payload);
        yield put(singleTransferClientSuccess(payload));
    } catch (error){
        yield put(setClientInitialStateError(error));
        console.error(error);
    }
}

function* bulkTransferClientSaga({payload}) {
    const { setNotifyMessage, ...otherPayload } = payload
    try{
        yield call(API.post, ApiRoute.client.singleTransferClient, otherPayload);
        setNotifyMessage(CommonFunc.notify("transfer"))
        yield put(bulkTransferClientSuccess(otherPayload));
    } catch (error){
        yield put(setClientInitialStateError(error));
        console.error(error);
    }
}

export function* clientSagaWatcher(){
    yield takeLatest(createClient.type, createClientSaga);
    yield takeLatest(importClient.type, importClientSaga);
    yield takeLatest(retrieveClient.type, retrieveClientSaga);
    yield takeLatest(retrieveAllClientsCount.type, retrieveAllClientsCountSaga);
    yield takeLatest(retrieveAllGroup.type, retrieveAllGroupSaga);
    yield takeLatest(retrieveAvailableGroup.type, retrieveAvailableGroupSaga);
    yield takeLatest(updateClient.type, updateClientSaga);
    yield takeLatest(updateClientData.type, updateClientDataSaga);
    yield takeLatest(deleteClient.type, deleteClientSaga);
    yield takeLatest(checkExistingClientData.type, checkExistingClientDataSaga);
    yield takeLatest(checkAllExistingClientData.type, checkAllExistingClientDataSaga);
    yield takeLatest(retrieveClientForm.type, retrieveClientFormSaga);
    yield takeLatest(retrieveClientFilterData.type, retrieveClientFilterDataSaga);
    yield takeLatest(setToggleTabState.type, setToggleTabStateSaga);
    yield takeLatest(setDndAction.type, setDndActionSaga);
    yield takeLatest(setClientDndAction.type, setClientDndActionSaga);
    yield takeLatest(processImportData.type, processImportDataSaga);
    yield takeLatest(setClientContext.type, setClientContextSaga);
    yield takeLatest(getInitialClientData.type, getInitialClientDataSaga);
    yield takeLatest(setClientInitialState.type, setClientInitialStateSaga);
    yield takeLatest(getProcessedClientData.type, getProcessedClientDataSaga);
    yield takeLatest(singleTransferClient.type, singleTransferClientSaga);
    yield takeLatest(bulkTransferClient.type, bulkTransferClientSaga);
}