import React from "react";
import { Box } from "@mui/material";
import { convertSxToArray } from "../../util/mui/convertSxToArray";

export const Image = ({ src, alt, className, width, height, sx = [] }) => {
  return (
    <Box
      component="img"
      className={className}
      sx={[() => ({}), ...convertSxToArray(sx)]}
      src={src}
      alt={alt}
      width={width ?? "100%"}
      height={height ?? "100%"}
    ></Box>
  );
};

