import { createSlice, current } from "@reduxjs/toolkit"

import { useAppSelector } from "../../hooks/storeHooks";

//initial state
const initialState = {

    //loading
    createClientGroupRoleAgentsLoading : false,
    deleteClientGroupRoleAgentsLoading : false,

    //error
    createClientGroupRoleAgentsError : false,
    deleteClientGroupRoleAgentsError : false,

    //data
    clientGroupRoleAgents : [],

}

//slices
const clientGroupRoleAgentsSlices = createSlice({
    name: "clientGroupRoleAgents",
    initialState,
    reducers : {
        createClientGroupRoleAgents(state){
            state.createClientGroupRoleAgentsLoading = true;
        },
        createClientGroupRoleAgentsSuccess(state, {payload}){
            // const currentState = payload;
            // state.clientGroupRoleAgents = currentState;

            state.createClientGroupRoleAgentsLoading = false;
        },
        createClientGroupRoleAgentsError(state, {payload}){
            const errorState = payload;
            state.createClientGroupRoleAgentsError = errorState;

            state.createClientGroupRoleAgentsLoading = false;
        },

        deleteClientGroupRoleAgents(state){
            state.deleteClientGroupRoleAgentsLoading = true;
        },
        deleteClientGroupRoleAgentsSuccess(state, {payload}){
            // const currentState = payload;
            // state.clientGroupRoleAgents = currentState;

            state.deleteClientGroupRoleAgentsLoading = false;
        },
        deleteClientGroupRoleAgentsError(state, {payload}){
            const errorState = payload;
            state.deleteClientGroupRoleAgentsError = errorState;

            state.deleteClientGroupRoleAgentsLoading = false;
        },
        
    }
})

//export function
export const {
    createClientGroupRoleAgents,
    createClientGroupRoleAgentsError,
    createClientGroupRoleAgentsSuccess,
    deleteClientGroupRoleAgents,
    deleteClientGroupRoleAgentsError,
    deleteClientGroupRoleAgentsSuccess,
} = clientGroupRoleAgentsSlices.actions;

//export selector

// data
export const selectClientGroupRoleAgents = () =>
  useAppSelector((state) => state.clientGroupRoleAgents.clientGroupRoleAgents);

// loading
export const selectCreateClientGroupRoleAgentsLoading = () =>
    useAppSelector((state) => state.clientGroupRoleAgents.createClientGroupRoleAgentsLoading);

export const selectDeleteClientGroupRoleAgentsLoading = () =>
    useAppSelector((state) => state.clientGroupRoleAgents.deleteClientGroupRoleAgentsLoading);

// error
export const selectCreateClientGroupRoleAgentsError = () =>
    useAppSelector((state) => state.clientGroupRoleAgents.createClientGroupRoleAgentsError);

export const selectDeleteClientGroupRoleAgentsError = () =>
    useAppSelector((state) => state.clientGroupRoleAgents.deleteClientGroupRoleAgentsError);


//export reducer
const clientGroupRoleAgentsReducer = clientGroupRoleAgentsSlices.reducer;

export default clientGroupRoleAgentsReducer;
