import React from 'react'
import './C_Search.scss'
import { RiSearch2Line } from 'react-icons/ri'
import { AiOutlineCloseCircle } from 'react-icons/ai'


const C_Search = (props) => {
    return (
        <div className='search-bar-container' style={{ height:props.height, width: props.containerWidth,borderRadius:props.borderRadius }}>
            { props.searchText === '' ?
            <RiSearch2Line color={'#979797'} size={props.iconSize ? props.iconSize : 16}/>
            :
            <></>
            }
            <input className='input-container font-s' placeholder={props.placeholder} onChange={props.onChange} value={props.value} style={{paddingLeft:'8px',width: props.width ? props.width : '100%' }} onKeyDown={props.onKeyDown}/>
            { props.searchText !== "" ? 
            <AiOutlineCloseCircle color={'#979797'} size={props.iconSize ? props.iconSize : 16} onClick={props.closeFunction} cursor="pointer"/>:
            <></>
            }
        </div>
    );
}

export default C_Search;