import { Route } from "react-router-dom"
import { crmRoutes } from "../enums/Route"
import ProtectedRoute from "./ProtectedRoute"
import { Fragment } from "react"
import Kpi from "../containers/Kpi/Kpi"

export const KpiRoute = ({currSaState, currRolesState}) => (
    <Fragment>
        <Route 
            path={crmRoutes.kpi.kpi}
            element={
                <ProtectedRoute>
                    <Kpi 
                        isSuperAdmin={currSaState} 
                        Kpi_Manage={currRolesState !== undefined ? currRolesState?.Kpi_Manage : false} 
                        Kpi_Delete={currRolesState !== undefined ? currRolesState?.Kpi_Delete : false}
                    />
                </ProtectedRoute>
            }
        />
    </Fragment> 
)

