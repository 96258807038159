import React, { useState, useRef, useEffect } from "react";
import C_Button from "../components/C_Button";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { useNavigate,createSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import "./LoginExpirationModal.scss";
import { auth, db } from "../firebaseInit";
import { signOut } from "firebase/auth";
import { Modal } from "bootstrap";
import { set, ref} from 'firebase/database'

import { getFromLocalStorage, removeFromLocalStorage } from "../util/LocalStorage";

//slices
import { loginWithEmailAndPassword, selectLoginWithEmailAndPasswordError } from "../stores/slices/firebaseAuthSlices";
import { selectUser } from "../stores/slices/userAuthSlices";

function LoginExpirationModal() {
    const expirationModalRef = useRef();
    const dispatch = useDispatch();
    const dbRef = ref(db);
    const [passwordVisibility, setPasswordVisibility] = useState(false);
    const [email, setEmail] = useState("");
    const [resetEmail,setResetEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isLoading,setIsLoading] = useState(false);
    const [resendSuccess,setResendSuccess] = useState(false);

    const navigate = useNavigate();
    const [error, setError] = useState("");
    const [isSignIn, setIsSignIn] = useState(false);

    const user = selectUser();

    const accessTokenExpiry = getFromLocalStorage("ACCESS_TOKEN_EXPIRY");
    const refreshToken = getFromLocalStorage("REFRESH_TOKEN");
    const loginExpiration = getFromLocalStorage("LOGIN_EXPIRATION");
    const loginError = selectLoginWithEmailAndPasswordError();

    useEffect(()=>{
        if(loginError){
          if(email === "" && password === ""){
            setError("Email and password must not be empty !");
          } else {
            setError("Wrong Password");
          }
        } else {
            if(isSignIn){
                hideModalFunc();
            }
        }
      }, [loginError])


    const showModalFunc = () => {
        const modalEle = expirationModalRef.current;
        const bsModal = new Modal(modalEle, {
        backdrop: "static",
        keyboard: false,
        });
        bsModal.show();
    };

    const hideModalFunc = () => {
        const modalEle = expirationModalRef.current;
        const bsModal = Modal.getInstance(modalEle);
        bsModal.hide();
    };

    useEffect(() => {
        if(loginExpiration === "EXPIRED"){
            showModalFunc();
        };
    },[loginExpiration]);

    async function signIn(){
        setError("");
        hideModalFunc();
        dispatch(loginWithEmailAndPassword({Email: email, Password: password}));
    };
    
    async function handleLogout(){
        try {
            await set(ref(db, 'users/' + user?.uid + '/fcm'), '')
              .then((result) => {
                  console.log("REMOVED NOTIFICATION TOKEN")
              })
              .catch(err => {
                  console.log(err)
              })
              
              await signOut(auth).then(()=>{navigate("/Auth");})
              removeFromLocalStorage("ACCESS_TOKEN");
              removeFromLocalStorage("REFRESH_TOKEN");
              removeFromLocalStorage("CURR_TENANT");
              removeFromLocalStorage("CURR_COMPANY");
              removeFromLocalStorage("CURR_ROLE");
              removeFromLocalStorage("ACCESS_TOKEN_EXPIRY");
              removeFromLocalStorage("LOGIN_EXPIRATION");
              removeFromLocalStorage("IS_SA");
              removeFromLocalStorage("IS_GA");
              hideModalFunc();
          } catch (error) {
              console.log(error.message)
          }
    };

    return (
        <div ref={expirationModalRef} className="modal fade modal show" id="expiration-modal" tabIndex="-1" style={{}}>
            <div className="modal-dialog modal-dialog-centered" style={{}}>
                <div className="modal-content" >
                    <div className="modal-body d-flex flex-column" >
                        <div className="d-flex flex-column px-2 py-2" style={{justifyContent:'center',textAlign:'left', gap: "1rem"}}>
                            <a style={{ cursor: "pointer", width: "fit-content" }} target="_blank" href="https://zumaxdigital.com/crm-v1/">
                                <img style={{ width: "auto", height: "60px" }} src={require("../assets/img/zumax-logo-black.webp")}/>
                            </a>
                            <form className="form_container">
                                <span className="font-l" style={{ fontWeight: "500" }}>Session expired. Please login again.</span>
                                <div className="form__group__login field d-flex email_container">
                                    <div style={{ width: "100%" }}>
                                        <label className="form__label_email">Enter your email</label>
                                        <input
                                            type="text"
                                            className={`form__field_email`}
                                            onChange={(e) => { setEmail(e.target.value) }}
                                        />
                                    </div>
                                </div>
                                <div className="form__group__login field d-flex password_container">
                                    <div style={{ width: "100%" }}>
                                    <label className="form__label_password">
                                        Enter your password
                                    </label>
                                    <div className="d-flex">
                                        <input
                                            type={passwordVisibility ? "text" : "password"}
                                            className={`form__field_password`}
                                            onChange={(e) => { setPassword(e.target.value) }}
                                        />
                                        <div style={{ display:'flex', alignItems: 'center' }}>
                                        {passwordVisibility ? (
                                            <FaRegEye
                                                className=""
                                                size={20}
                                                onClick={() => {
                                                    setPasswordVisibility(!passwordVisibility);
                                                }}
                                            />
                                        ) : (
                                            <FaRegEyeSlash
                                                className=""
                                                size={20}
                                                onClick={() => {
                                                    setPasswordVisibility(!passwordVisibility);
                                                }}
                                            />
                                        )}
                                        </div>
                                    </div>
                                    
                                    </div>
                                </div>
                                {error !== ""  ? <span style={{color: "red"}}>{error}</span> : <></>}
                                <C_Button
                                    width={"100%"}
                                    buttonText={"LOGIN"}
                                    onClick={() => { 
                                        signIn();
                                    }}
                                    textColor={"#FFFFFF"}
                                    justify={"center"}
                                />

                                <C_Button
                                    background={"#FFF"}
                                    width={"100%"}
                                    buttonText={"Continue to Logout"}
                                    onClick={() => { 
                                        handleLogout();
                                    }}
                                    border={"1px solid #000"}
                                    color={"#000"}
                                    justify={"center"}
                                />
                                
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoginExpirationModal;