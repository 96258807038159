import { call, delay, put, takeLatest } from "redux-saga/effects";
import{    
    createLogs,
    createLogsSuccess,
    createLogsError,
    retrieveLogs,
    retrieveLogsSuccess,
    retrieveLogsError,
    retrieveAllLogsCount,
    retrieveAllLogsCountSuccess,
    retrieveAllLogsCountError
} from "../stores/slices/logSlices"

import { ApiRoute } from "../enums/ApiRoute";
import { API } from "../http-common";

    function* createLogsSaga({payload}){
        try{
            const { data } = yield call(API.post, ApiRoute.log.createLogs, payload);
            yield put(createLogsSuccess(data))
        }catch (error){
            const errorCode = error.response.data.code;
            yield put(createLogsError(error));
            console.error(error);
        }
    }

    function* retrieveLogsSaga({payload}){
        // const { UserID } = payload;
        try{
            const { TenantID, CompanyID, UserID, CurrPage, MemberID, Section, Action, GroupID } = payload;
            let data; 

            if(UserID === ""){

                const logsData = {
                    CompanyID: CompanyID,
                    MemberID: MemberID,
                    Section: Section,
                    CurrPage: CurrPage,
                    Action:Action,
                    GroupID:GroupID
                }
        
                data = yield call(API.post, ApiRoute.log.retrieveAllLogs,({TenantID, logsData}));
                const returnedData = data.data;
                yield put(retrieveLogsSuccess(returnedData))
              } else {
                const logsData = {
                    CompanyID: CompanyID,
                    UserID: UserID,
                    Section: Section,
                    CurrPage: CurrPage,
                    Action:Action,
                    GroupID:GroupID
                }
        
                data = yield call(API.post, ApiRoute.log.retrievePersonalLogs,({TenantID, logsData}))
                const returnedData = data.data;

                yield put(retrieveLogsSuccess(returnedData))
              }
        }catch (error){
            const errorCode = error.response.data.code;
            yield put(retrieveLogsError(error));
            console.error(error);
        }
    }

    function* retrieveAllLogsCountSaga({payload}){
        try{
            const { TenantID, CompanyID, UserID, MemberID, Section, Action, GroupID } = payload;
            let data;

            if(UserID === ""){

                const logsData = {
                  CompanyID: CompanyID,
                  MemberID: MemberID,
                  Section: Section,
                  Action:Action,
                  GroupID:GroupID
                }
            
                data = yield call(API.post, ApiRoute.log.retrieveAllLogsCount,({TenantID, logsData}));
                const returnedData = data.data;
                yield put(retrieveAllLogsCountSuccess(returnedData))
                  
              }else{
                const logsData = {
                  CompanyID: CompanyID,
                  UserID: UserID,
                  Section: Section,
                  Action:Action,
                  GroupID:GroupID
                }
            
                data = yield call(API.post, ApiRoute.log.retrieveAllPersonalLogsCount,({TenantID, logsData}));
                const returnedData = data.data;
                yield put(retrieveAllLogsCountSuccess(returnedData))
                  
              }
        }catch (error){
            const errorCode = error.response.data.code;
            yield put(retrieveAllLogsCountError(error));
            console.error(error);
        }
    }

    export function* logsSagaWatcher(){
        yield takeLatest(createLogs.type, createLogsSaga);
        yield takeLatest(retrieveLogs.type, retrieveLogsSaga);
        yield takeLatest(retrieveAllLogsCount.type, retrieveAllLogsCountSaga);
    }