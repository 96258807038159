import React, { useMemo } from "react";
import PropTypes from "prop-types";
import {
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
  Chip
} from "@mui/material";
import { HelpOutlineRounded, WarningRounded } from "@mui/icons-material";
import { InputTextType } from "../../enums/components/InputTextType";
import { ChipStatus } from "../../enums/components/Chip";

export const InputText = ({
  type,
  textType = InputTextType.Normal,
  name,
  isDisabled,
  value,
  transform,
  onChange,
  onBlur,
  inputProps,
  startAdornment,
  endAdornment,
  tooltips,
  multiline = false,
  minWidth,
  maxWidth,
  fullWidth = true,
  label,
  required,
  fieldRef,
  error,
  helperText,
  hidden,
  variant,
  formOnBlur = null,
  placeholder = "",
  disabledErrorText = false,
  formOnFocus = null,
  sx,
  id
}) => {
  const handleChange = (event) => {
    transform ? onChange(transform(event)) : onChange(event);
  };

  const handleBlur = () => {
    const trimmedValue = value && value?.trim ? value.trim() : value;
    const afterBlurOnChangeValue = formOnBlur
      ? formOnBlur(trimmedValue)
      : trimmedValue;
    onChange({ target: { value: afterBlurOnChangeValue } });
    onBlur && onBlur();
  };

  // Handle jest test ID and text transformation
  const setTextTransform = useMemo(() => {
    if (textType === InputTextType.Uppercase) return "uppercase";
    if (textType === InputTextType.Capitalize) return "capitalize";
    return "unset";
  }, [textType]);

  const inputTextProps = {
    style: { textTransform: setTextTransform, color: "black !important" },
    "data-testid": `${name}TextInput`,
    ...inputProps,
  };

  // Handle render helper text and error text
  const helperHandling = () => {
    if (helperText && !error)
      return (
        <Typography
          className="helper"
          variant="remark"
          color={({ palette }) => palette.text.light}
          sx={{ marginLeft: "10px" }}
        >
          {helperText}
        </Typography>
      );
    if (error && !disabledErrorText) {
      return (
        <Typography
          variant="bodyText"
          color={({ palette }) => palette.snack.error}
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <WarningRounded
            sx={({ palette, icons }) => ({
              ...icons.standard,
              color: palette.snack.error,
              pointerEvents: "none",
            })}
          />
          {error}
        </Typography>
      );
    }

    return null;
  };

  const startAdornmentComponent = startAdornment ? (
    <InputAdornment position="start" sx={{ marginLeft: "5px" }}>
      {startAdornment}
    </InputAdornment>
  ) : null;

  const endAdornmentComponent = endAdornment ? (
    <InputAdornment position="end" sx={{ marginRight: "5px" }}>
      {endAdornment}
    </InputAdornment>
  ) : null;

  const defaultUomChip =
    name === "product_uom[0].uom" ? (
      <InputAdornment position="end" sx={{ marginRight: "5px" }}>
        <Chip
          sx={{
            marginRight: "10px",
          }}
          label={"Base"}
          color={ChipStatus.Success}
        />
      </InputAdornment>
    ) : null;

  const tooltipsAdornmentComponent = tooltips ? (
    <InputAdornment position="end">
      <Tooltip title={tooltips}>
        <IconButton
          sx={{ position: "absolute", right: 0, marginRight: "-40px" }}
        >
          <HelpOutlineRounded sx={({ icons }) => ({ ...icons.standard })} />
        </IconButton>
      </Tooltip>
    </InputAdornment>
  ) : null;

  const endAdornmentCombinationComponent = (
    <>
      {endAdornmentComponent}
      {tooltipsAdornmentComponent}
    </>
  );

  return (
    <TextField
      sx={{
        minWidth: minWidth,
        maxWidth: maxWidth,
        "& .MuiInputBase-input": {
          paddingTop: label === undefined ? "0px !important" : "15px",
          paddingRight:
          id === "batchPaymentRefund_effective_rate" ||
          id === "batchBank_effective_rate"
            ? "0px !important"
            : "12px",
        },
        
        "& .MuiFilledInput-input": {
          paddingTop: label === undefined ? "0px !important" : "15px",
          paddingRight:
          id === "batchPaymentRefund_effective_rate" ||
          id === "batchBank_effective_rate"
            ? "0px !important"
            : "12px",
        },
      }}
      autoComplete="off"
      hidden={hidden}
      aria-autocomplete="none"
      type={type}
      error={!!error}
      multiline={multiline}
      fullWidth={fullWidth}
      label={label}
      variant={variant ? variant : "filled"}
      required={required}
      inputRef={fieldRef}
      disabled={isDisabled}
      value={value}
      placeholder={placeholder}
      onChange={handleChange}
      onFocus={formOnFocus}
      inputProps={inputTextProps}
      helperText={helperHandling()}
      onBlur={handleBlur}
      InputProps={{
        style: multiline
          ? label === "Billing Party"
            ? { height: "333px" }
            : { height: "196px" }
          : label === "Rate"
          ? { height: "50px" }
          : { height: "55px" },
        startAdornment: startAdornmentComponent,
        endAdornment:
          name === "product_uom[0].uom"
            ? defaultUomChip
            : endAdornmentCombinationComponent,
      }}
    />
  );
};

InputText.propTypes = {
  id: PropTypes.any,
  type: PropTypes.any,
  textType: PropTypes.any,
  name: PropTypes.any,
  isDisabled: PropTypes.any,
  value: PropTypes.any,
  onChange: PropTypes.any,
  onBlur: PropTypes.any,
  transform: PropTypes.any,
  inputProps: PropTypes.any,
  startAdornment: PropTypes.any,
  endAdornment: PropTypes.any,
  tooltips: PropTypes.any,
  multiline: PropTypes.any,
  minWidth: PropTypes.any,
  maxWidth: PropTypes.any,
  fullWidth: PropTypes.any,
  label: PropTypes.any,
  required: PropTypes.any,
  fieldRef: PropTypes.any,
  error: PropTypes.any,
  helperText: PropTypes.any,
  InputProps: PropTypes.any,
  hidden: PropTypes.bool,
  variant: PropTypes.any,
  placeholder: PropTypes.any,
  formOnBlur: PropTypes.any,
  disabledErrorText: PropTypes.any,
  formOnFocus: PropTypes.any,
};
