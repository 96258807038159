import { createSlice, current } from "@reduxjs/toolkit"

import { useAppSelector } from "../../hooks/storeHooks";
import moment from "moment";

const initialState = {
    //loading
    createKpiLoading:false,
    retrieveKpiLoading:false,
    updateKpiLoading:false,
    deleteKpiLoading:false,
    setKpiContextLoading : false,

    //error
    createKpiError:undefined,
    retrieveKpiError:undefined,
    updateKpiError:undefined,
    deleteKpiError:undefined,
    setKpiContextError : undefined,

    //data
    kpi:[],

    context : {
        triggerAction : '',
        showKpiSetting : false,
        newKpi : false,
        newKpiData : {
            KpiID: "",
            title: "",
            startDate: moment().format("YYYY-MM-DD"),
            dueDate: moment().format("YYYY-MM-DD"),
            definition: "",
            sourceOfData: "",
            measuredBy: "",
            metricValue: "",
            target: "",
            unitOfMeasurement: "",
            targetMembers: [],
            teamContribution: false,
            createdAt: "",
            assignedBy: ""
        },
        viewKpi : false,
        viewKpiData : {
            item: {
                KpiID: "",
                title: "",
                startDate: moment().format("YYYY-MM-DD"),
                dueDate: moment().format("YYYY-MM-DD"),
                definition: "",
                sourceOfData: "",
                measuredBy: "",
                metricValue: "",
                target: "",
                unitOfMeasurement: "",
                targetMembers: [],
                teamContribution: false,
                createdAt: "",
                assignedBy: "",
                percentageWidth: "",
                totalValue: "",
                percentage: ""
            },
            percentage: "",
            percentageWidth: "",
            totalValue: ""
        },
        analyticsGrouping : "Days",
        analyticsData : null,
        tableData : [],
        tableField : [],

        // kpiData : '', //kpi
        // sourceData : [], //formTemplates
        // allUser : '', //currUserSA
        // submission : '', //submissions
    },

    defaultContext : {
        triggerAction : '',
        showKpiSetting : false,
        newKpi : false,
        newKpiData : {
            KpiID: "",
            title: "",
            startDate: moment().format("YYYY-MM-DD"),
            dueDate: moment().format("YYYY-MM-DD"),
            definition: "",
            sourceOfData: "",
            measuredBy: "",
            metricValue: "",
            target: "",
            unitOfMeasurement: "",
            targetMembers: [],
            teamContribution: false,
            createdAt: "",
            assignedBy: ""
        },
        viewKpi : false,
        viewKpiData : {
            item: {
                KpiID: "",
                title: "",
                startDate: moment().format("YYYY-MM-DD"),
                dueDate: moment().format("YYYY-MM-DD"),
                definition: "",
                sourceOfData: "",
                measuredBy: "",
                metricValue: "",
                target: "",
                unitOfMeasurement: "",
                targetMembers: [],
                teamContribution: false,
                createdAt: "",
                assignedBy: "",
                percentageWidth: "",
                totalValue: "",
                percentage: ""
            },
            percentage: "",
            percentageWidth: "",
            totalValue: ""
        },
        analyticsGrouping : "Days",
        analyticsData : null,
        tableData : [],
        tableField : [],

        // kpiData : '', //kpi
        // sourceData : [], //formTemplates
        // allUser : '', //currUserSA
        // submission : '', //submissions
    }
}

const kpiSlice = createSlice({
    name:"kpi",
    initialState,
    reducers: {
        createKpi(state){
            state.createKpiLoading = true
        },

        createKpiSuccess(state,{payload}){
            const data = payload.data;
            const processedData = {
                ...data,
                targetMembers : JSON.parse(data.targetMembers)
            }
            const currentState = current(state.kpi)

            const updatedState = [
                ...currentState,
                processedData
            ]

            state.kpi = updatedState
            state.createKpiLoading = false
        },

        createKpiError(state,{payload}){
            const errorState = payload
            state.createKpiError = errorState
            state.createKpiLoading = false
        },

        retrieveKpi(state){
            state.retrieveKpiLoading = true
        },

        retrieveKpiSuccess(state,{payload}){
            const currentState = payload
            state.kpi = currentState
            state.retrieveKpiLoading = false
        },

        retrieveKpiError(state,{payload}){
            const errorState = payload
            state.retrieveKpiError = errorState
            state.retrieveKpiLoading = false
        },

        updateKpi(state){
            state.updateKpiLoading = true
        },

        updateKpiSuccess(state,{payload}){
            const data = payload.data;
            const currentState = current(state.kpi)
            const updatedState = currentState.map((item) => {
                if(item.KpiID === data.KpiID) {
                    return{
                        ...data,
                        targetMembers: JSON.parse(data.targetMembers)
                    }
                } else {
                    return item
                }
            });
            state.kpi = updatedState
            state.updateKpiLoading = false
        },

        updateKpiError(state,{payload}){
            const errorState = payload
            state.updateKpiError = errorState
            state.updateKpiLoading = false
        },

        deleteKpi(state){
            state.deleteKpiLoading = true
        },

        deleteKpiSuccess(state,{payload}){
            const KpiID = payload.KpiID;
            const currentState = current(state.kpi)
            const updatedState = currentState.filter((item) => item.KpiID !== KpiID)
            state.kpi = updatedState
            state.deleteKpiLoading =false
        },

        deleteKpiError(state,{payload}){
            const errorState = payload
            state.deleteKpiError = errorState
            state.deleteKpiLoading =false
        },
        setKpiContext(state){
            state.setKpiContextLoading = true;
        },
        setKpiContextSuccess(state,{payload}){
            const currentState = current(state.context);
            if(Object.values(payload).length > 0){
                const updatedState = {
                ...currentState,
                ...payload,
                }
                state.context = updatedState;
                
            } else {
                state.context = state.defaultContext;
            }
            
            state.setKpiContextLoading = false;
        },

        setKpiContextError(state,{payload}){
            const errorState = payload
            state.setKpiContextError = errorState
            state.setKpiContextLoading = false
        },
    }
})

export const {
    createKpi,
    createKpiSuccess,
    createKpiError,
    retrieveKpi,
    retrieveKpiSuccess,
    retrieveKpiError,
    updateKpi,
    updateKpiSuccess,
    updateKpiError,
    deleteKpi,
    deleteKpiSuccess,
    deleteKpiError,
    setKpiContext,
    setKpiContextSuccess,
    setKpiContextError,
} = kpiSlice.actions

// data
export const selectKpi = () =>
  useAppSelector((state) => state.kpi.kpi);

export const selectKpiContext = () => 
  useAppSelector((state) => state.kpi.context);

// loading
export const selectCreateKpiLoading = () =>
  useAppSelector((state) => state.kpi.createKpiLoading);

export const selectRetrieveKpiLoading = () =>
  useAppSelector((state) => state.kpi.retrieveKpiLoading);

export const selectUpdateKpiLoading = () =>
  useAppSelector((state) => state.kpi.updateKpiLoading);

export const selectDeleteKpiLoading = () =>
  useAppSelector((state) => state.kpi.deleteKpiLoading);

export const selectKpiContextLoading = () =>
  useAppSelector((state) => state.kpi.setKpiContextLoading);

// error
export const selectCreateKpiError = () =>
  useAppSelector((state) => state.kpi.createKpiError);

export const selectRetrieveKpiError = () =>
  useAppSelector((state) => state.kpi.retrieveKpiError);

export const selectUpdateKpiError = () =>
  useAppSelector((state) => state.kpi.updateKpiError);

export const selectDeleteKpiError = () =>
  useAppSelector((state) => state.kpi.deleteKpiError);

export const selectKpiContextError = () =>
  useAppSelector((state) => state.kpi.setKpiContextError);

//export reducer
const kpiReducer = kpiSlice.reducer

export default kpiReducer