const component = {
  MuiAutocomplete: {
    styleOverrides: {
      root: ({ theme }) => ({
        "& .MuiFilledInput-root": {
          paddingLeft: "12px",
          paddingTop: "10px",
          height: "55px",
        },
        "& .MuiAutocomplete-endAdornment": {
          top: "calc(50% - 12px)",
        },
        "& .MuiAutocomplete-endAdornment .MuiAutocomplete-popupIndicator": {
          transform: "none",
          top: "calc(50% - 12px)",
        },
        "& .Mui-disabled svg ": {
          color: theme.palette.text.disabled,
        },
      }),
    },
  },
};

export default component;
