const transactionTax = {
    active_sales_tax: [
      {
        value: "10",
        label: "SL10 (10%)",
      },
      {
        value: "5",
        label: "SL5 (5%)",
      },
      {
        value: "6",
        label: "SL6 (6%)",
      },
      {
        value: "8",
        label: "SL8 (8%)",
      },
    ],
    active_services_tax: [
      {
        value: "10",
        label: "PSL10 (10%)",
      },
      {
        value: "5",
        label: "PSL5 (5%)",
      },
      {
        value: "6",
        label: "PSL6 (6%)",
      },
    ],
  };
  
  module.exports = {
    transactionTax,
  };
  