
export const ApiRoute = {
  analytics: {
    createAnalytics: "/createAnalytics",
    getAnalytics: "/getAnalytics",
    getSingleAnalytics: "/getSingleAnalytics",
    getPublishAnalytics: "/getPublishAnalytics",
    getClientForAnalytics: "/getClientForAnalytics",
    updateAnalytics: "/updateAnalytics",
    deleteAnalytics: "/deleteAnalytics",
    retrieveAnalyticsDataset: "/getAnalyticsDataset",
    getGraphData: "/getGraphData",
    getAvailableHandler: "/getAvailableHandler",
  },

  appointments: {
    retrieveAllAppointment: "/findAllAppointment",
    createAppointment: "/createAppointment",
    updateAppointment: "/updateAppointment",
    deleteAppointment: "/deleteAppointment",
  },

  auth: {
    retrieveAllUser: "/findAllUser"
  },

  clientAgent: {
    importClientAgent: "/importClientAgent",
    createClientAgent: "/createClientAgent",
    getAllClientAgent: "/getAllClientAgent",
    getClientAgent: "/getClientAgent",
    deleteClientAgent: "/deleteClientAgent",
    bulkCreateClientAgent: "/bulkCreateClientAgent",
    bulkRemoveClientAgent: "/bulkRemoveClientAgent",
    insertClientAgents: "/insertClientAgents",
    removeClientAgents: "/removeClientAgents",
  },

  clientGroupHandler: {
    retrieveClientHandler: "/findAllHandler",
    createClientHandler: "/createClientHandler",
    deleteClientHandler: "/deleteClientHandler",
    retrieveSpecificClientHandler: "/findGroupHandler",
    bulkCreateClientHandler: "/bulkCreateClientHandler",
  },

  clientGroupLeader: {
    createClientGroupLeader: "/createClientGroupLeader",
    retrieveClientGroupLeader: "/getClientGroupLeader",
    deleteClientGroupLeader: "/deleteClientGroupLeader",
    retrieveAllClientGroupLeader: "/getAllClientGroupLeader",
  },

  clientGroup: {
    retrieveAllClientGroups: "/findAllClientGroups",
    createClientGroups: "/createClientGroups",
    updateClientGroups: "/updateClientGroups",
    deleteClientGroups: "/deleteClientGroups",
  },

  clientGroupRoles: {
    getClientGroupRoles: "/getClientGroupRoles",
    createClientGroupRoles: "/createClientGroupRoles",
    setClientGroupRoles: "/setClientGroupRoles",
    deleteClientGroupRoles: "/deleteClientGroupRoles",
    getCurrUserClientGroupRoles : "/getCurrUserClientGroupRoles"
  },

  clientGroupRoleAgents: {
    createClientGroupRoleAgents: "/createClientGroupRoleAgents",
    deleteClientGroupRoleAgents: "/deleteClientGroupRoleAgents",
  },

  client: {
    createClient: "/createClient",
    retrieveAllClient: "/findAllClient",
    updateClient: "/updateClient",
    deleteClient: "/deleteClient",
    importClient: "/importClient",
    retrieveClientCount: "/getClientCount",
    retrieveAllClientGroup: "/getAllClientGroup",
    retrieveClientGroup: "/getClientGroup",
    updateClientData: "/updateClientData",
    checkExistingClientData: "/checkExistingClientData",
    checkAllExistingClientData: "/checkAllExistingClientData",
    retrieveClientForm: "/findClientForm",
    retrieveAllClientFilterData: "/findAllClientFilterData",
    processImportData: "/importClientData",
    singleTransferClient: "/singleTransferClient",
  },

  clientSelector: {
    retrieveClientSelector: "/findClientSelector",
    retrieveClientSelectorCount: "/findClientSelectorCount",
    retrieveClientSelected: "/findClientSelected"
  },
  
  companyAgent: {
    retrieveAllCompanyAgent: "/findAllCompanyAgent",
    createCompanyAgent: "/createCompanyAgent",
    updateCompanyAgent: "/updateCompanyAgent",
    updateCompanyAgentRole: "/updateCompanyAgentRole",
    updateCompanyAgentMemberRole: "/updateCompanyAgentMemberRole",
    deleteCompanyAgent: "/deleteCompanyAgent",
    retrieveAllAgent: "/retrieveAllAgent",
    sendAgentInvitation: "/sendAgentInvitation",
    checkAgentExist: "/checkAgentExist"
  },

  company: {
    retrieveAllCompany: "/findAllCompany",
    createCompany: "/createCompany",
    updateCompany: "/updateCompany",
    deleteCompany: "/removeCompany"
  },

  firebaseAuth: {
    createFirebaseUser: "/createFirebaseUser",
    deleteFirebaseUser: "/deleteFirebaseUser",
    updateFirebaseUser: "/updateFirebaseUser",
    checkUserExists: "/checkUserExists",
    loginWithEmailAndPassword: "/loginWithEmailAndPassword",
    refreshUserAuth: "/refreshUserAuth",
  },

  formSubmission: {
    createFormSubmission: "/createFormSubmission",
    retrieveAllFormSubmission: "/findAllFormSubmission",
    updateFormSubmission: "/updateFormSubmission",
    deleteFormSubmission: "/deleteFormSubmission",
    retrievePersonalFormSubmission: "/findPersonalFormSubmission",
    retrieveAllFormsCount: "/getAllFormsCount",
    retrieveAllPersonalFormsCount: "/getPersonalFormsCount",
    retrievePersonalOngoingForm: "/findPersonalOngoingForm",
    retrievePersonalApprovedForm: "/findPersonalApprovedForm",
    retrievePersonalApprovedFormCount: "/getPersonalApprovedFormCount",
    retrievePersonalOngoingFormCount: "/getPersonalOngoingFormCount"
  },

  formTemplate: {
    deleteAnalyticsByFormID: "/deleteAnalyticsByFormID",
    createFormTemplate: "/createFormTemplate",
    retrieveAllFormTemplate: "/findAllFormTemplate",
    updateFormTemplate: "/updateFormTemplate",
    deleteFormTemplate: "/deleteFormTemplate",
    deleteAllSubmission: "/deleteAllSubmission"
  },

  kpi: {
    retrieveAllKpi: "/findAllKpi",
    createKpi: "/createKpi",
    updateKpi: "/updateKpi",
    deleteKpi: "/deleteKpi"
  },

  log: {
    retrieveAllLogs: "/findAllLogs",
    createLogs: "/createLogs",
    retrievePersonalLogs: "/findPersonalLogs",
    retrieveAllLogsCount: "/getLogsCount",
    retrieveAllPersonalLogsCount: "/getPersonalLogsCount"
  },

  roles: {
    retrieveAllRoles: "/findAllRole",
    createRole: "/createRole",
    updateRole: "/updateRole",
    deleteRole: "/deleteRole",
    findCurrRoles: "/findCurrRole"
  },

  tenantAgent: {
    createTenantAgents: "/createTenantAgents",
    deleteTenantAgents: "/deleteTenantAgents"
  },
  tenant: {
    createTenantUserAuth: "/createTenantUserAuth",
    createTenantDatabase: "/createTenantDatabase",
    retrieveAllTenant: "/findAllTenant",
    retrieveAvailableTenant: "/findAvailableTenant",
    updateTenant: "/updateTenant",
    deleteTenant: "/deleteTenant",
    retrieveAllTenantCount: "/findAllTenantCount",
    updateGaTenantDetails: "/updateGaTenantDetails",
    sendSignupConfirmationMail: "/sendSignupConfirmationMail",
    sendTenantApprovalMail: "/sendTenantApprovalMail",
    sendTenantNotificationMail: "/sendTenantNotificationMail",
    sendTenantQuotaNotificationMail: "/sendTenantQuotaNotificationMail",
    sendTenantUpgradeNotificationMail: "/sendTenantUpgradeNotificationMail",
    sendTenantExtendNotificationMail: "/sendTenantExtendNotificationMail",
    sendVerificationMail: "/sendVerificationMail"
  },
  usage: {
    retrieveCurrentUsage: "/retrieveCurrentUsage"
  },
  transaction: {
    retrieveAll: "transaction/retrieve-all",
    retrieve: "transaction/retrieve",
    retrieveOptions: "transaction/retrieve-options",
    retrievePaymentOptions: "transaction/payment-options",
    retrieveCreditOptions: "transaction/credit-options",
    retrievePayment: "transaction/retrieve-payment",
    retrieveCredit: "transaction/retrieve-credit",

    create: "transaction/create",
    createPayment: "transaction/create-payment",
    createCredit: "transaction/create-credit",

    updatePayment: "transaction/update-payment",
    updateCredit: "transaction/update-credit",
    updateStatus: "transaction/update-status",
    update: "transaction/update",
    remove: "transaction/remove",
    bulkCreate: "transaction/create/batch",
    bulkCreatePayments: "transaction/create/payments/batch",
    bulkUpdate: "transaction/bulk-update",
    bulkRemove: "transaction/bulk-remove",
    share: "transaction/share",
    send: "transaction/send",
  },
};
