import React, { useState, useRef, useEffect } from "react";
import Colors from "../constants/Colors";
import C_Button from "../components/C_Button";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { useNavigate,createSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import "./Login.scss";
import { auth, db } from "../firebaseInit";
import { sendPasswordResetEmail} from "firebase/auth";
import { Modal } from "bootstrap";
import C_Loading_Login from "../components/C_Loading_Login";
import ga from '../enums/json/generalAdmin.json';
import { child, get, ref } from "firebase/database";

import { addToLocalStorage, getFromLocalStorage, getFromlocalStorage, removeFromLocalStorage } from "../util/LocalStorage";
import { setHeaders } from "../http-common";
import { setIdleTime, setIsFirstLogin, setIsGA } from "../stores/slices/persistRolesSlices";
import { loginWithEmailAndPassword, selectFirebaseAuth, selectLoginWithEmailAndPasswordError } from "../stores/slices/firebaseAuthSlices";

function Login() {

  const dispatch = useDispatch();
  const dbRef = ref(db);
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [email, setEmail] = useState("");
  const [resetEmail,setResetEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading,setIsLoading] = useState(false);
  const [resendSuccess,setResendSuccess] = useState(false);
  const [isLogin, setIsLogin] = useState(false);

  const firebaseAuth = selectFirebaseAuth();

  const navigate = useNavigate();
  const [error, setError] = useState("");

  const modalRef = useRef();
  const forgotPasswordRef = useRef();
  
  const refreshToken = getFromLocalStorage("REFRESH_TOKEN");
  const IS_GA = getFromLocalStorage("IS_GA");

  const loginError = selectLoginWithEmailAndPasswordError();

  const showModalFunc = () => {
    const modalEle = modalRef.current;
    const bsModal = new Modal(modalEle, {
      backdrop: "static",
      keyboard: false,
    });
    bsModal.show();
  };

  const hideModalFunc = () => {
    const modalEle = modalRef.current;
    const bsModal = Modal.getInstance(modalEle);
    bsModal.hide();
  };


  const showForgotPasswordModal = () => {
    const modalEle = forgotPasswordRef.current;
    const bsModal = new Modal(modalEle, {
      backdrop: "static",
      keyboard: false,
    });
    bsModal.show();
  };

  const hideForgotPasswordModal = () => {
    const modalEle = forgotPasswordRef.current;
    const bsModal = Modal.getInstance(modalEle);
    bsModal.hide();
  };

  useEffect(()=>{
    if(loginError){
      if(email === "" && password === ""){
        setError("Email and password must not be empty !");
      } else {
        setError("Wrong Password");
      }
    }
  }, [loginError])

  useEffect(()=>{
    if(refreshToken){
      navigate("/Tenant");
      // if(IS_GA === true){
      //   navigate("/GaPortal");
      // } else {
      //   navigate("/Tenant");
      // }
    }
  }, [refreshToken])

  // function handleKeyDownEvent(event){
  //   console.log(event)
  //   if(event.key === 'Enter'){
  //     console.log("Enter Key Pressed");
  //     signIn();
  //   }
  // }

  async function signIn(){
    // setIsLoading(true)
    setError("");
    dispatch(loginWithEmailAndPassword({Email: email, Password: password}));
    setIsLogin(true);
  };

  const forgetPassword = async () => {
    setIsLoading(true)
  
      sendPasswordResetEmail(auth, resetEmail).then(() => {
        setTimeout(()=>{
          setIsLoading(false);
          showModalFunc();
          setError("");
        },2000)
      }).catch((err)=>
      {
        var msg = "Email does not exist"
        setError(msg.toUpperCase());
        setIsLoading(false);
      })
  
  };

  const resendEmail = async () => {
  
      sendPasswordResetEmail(auth, resetEmail).then(() => {
        setTimeout(()=>{
          setIsLoading(false);
          setError("");
          setResendSuccess(true);
        },2000)
      }).catch((err)=>
      {
        var msg = "Email does not exist"
        setError(msg.toUpperCase());
      })
  
  };

  return (
    <div className="login_container">
      <div
        className="modal fade"
        // id="forgot-password"
        ref={forgotPasswordRef}
        tabIndex="-1"
      >
        <div className="modal-dialog modal-dialog-centered w-100">
            <div className="modal-content">
              <div className="modal-body d-flex flex-column w-100">
                  {/* <img src={require('../assets/img/working_on_it.gif')}/> */}
                  <div className="d-flex flex-column py-2 px-2 w-100">
                    <p className="font-xl"> Forgot your password?</p>
                    <p className="font-xxs">Enter the email address you used when you joined and we will send you instructions to reset your password.</p>
                    {/* <span style={{ color: "blue", fontWeight: 'bold' }}>We're working on this feature. Please contact the developer for password modification.</span> */}
                      <div className=" w-100">
                        <div style={{ width: "100%" }}>
                          <input
                            type={"text"}
                            className={`forgot_password_container`}
                            onChange={(e) => { setResetEmail(e.target.value) }}
                          />
                        </div>
                      </div>
                  </div>
                  <div className="d-flex justify-content-end pt-4">
                  <C_Button
                    width={"100px"}
                    buttonText={"CLOSE"}
                    justify={"center"}
                    onClick={() => {hideForgotPasswordModal();}}
                    textColor={"#FFFFFF"}
                    marginLeft={"0.5rem"}
                    dismissAction="modal"
                  />
                  <C_Button
                    width={"160px"}
                    buttonText={"Send Reset Instructions"}
                    justify={"center"}
                    onClick={() => {forgetPassword()}}
                    textColor={"#FFFFFF"}
                    marginLeft={"0.5rem"}
                    dismissAction="modal"
                  />
                  
                  </div>
              </div>
            </div>
        </div>
      </div>

      
      <div ref={modalRef} className="modal fade modal" id="activation" tabIndex="-1" style={{}}>
            <div className="modal-dialog modal-dialog-centered" style={{}}>
              <div className="modal-content" >
                <div className="modal-body d-flex flex-column" >
                    <div className="d-flex flex-column px-2 py-2" style={{justifyContent:'center',textAlign:'left'}}>
                        
                        
                        {/* <img src={require('../assets/img/partner.png')} alt=""/> */}
                        <p className="font-xl" style={{fontWeight:'600',textAlign:'center'}}>Processing ...</p>
                        <p className="font-m" style={{opacity:'0.8'}}>Instructions will be sent to your provided email account within few minutes.</p>

                        <p className="font-m" style={{opacity:'0.8'}}>Remember to check your <span style={{fontWeight:'bold',color:'rgb(95, 94, 175)'}}>“junk mail”</span> folder or <span style={{fontWeight:'bold',color:'rgb(95, 94, 175)'}}>“spam”</span> folder.</p>
                        <p className="font-m" style={{opacity:'0.8'}} onClick={()=>{resendEmail();}}>If you do not see your email within 5 minutes, <span style={{color:'rgb(95, 94, 175)',borderBottom:'1px solid', cursor:'pointer',fontWeight:'bold'}}>Click Here</span> to send again.</p>

                        {resendSuccess && 
                          <p className="font-xs" style={{opacity:'0.8',color:'green'}}>Email has been resent successfully !</p>
                        }

                        <C_Button
                            backgroundColor={Colors.generalButtonColor}
                            buttonText={"Continue"}
                            height={"50px"}
                            justify={"center"}
                            textColor={"#FFFFFF"}
                            onClick={()=>{hideModalFunc();}}
                          />
                    </div>
                </div>
              </div>
            </div>
        </div>

      <div className="login_container_left left-container-width">
        <div className="sub_left_container" style={{ }}/>
          <div className="d-flex" style={{ alignItems: "center" }}>
            <span className="font-s" style={{ color: "#FFFFFF" }}>Powered By</span>
            <a target="_blank" href="https://zumaxdigital.com/">
              <img
                style={{ width: "auto", height: "60px", marginLeft: "10px", cursor: 'pointer' }}
                src={require("../assets/img/zumax-logo.webp")}
              />
            </a>
          </div>
          <div style={{ marginTop: '20px' }}> <small>Copyright &copy; 2023 Zumax Digital. All Rights Reserved</small> </div>
      </div>
      <div className="login_container_right right-container-width">
      {isLoading ?
      <C_Loading_Login isLoading={isLoading} />
      :
      <>
        <img
          style={{ width: "auto", height: "100px", marginBottom: "2rem" }}
          src={require('../assets/img/zumax-logo-black.webp')}
          alt=""
        />
        <form className="sub_right_container">
          <div>
            <span style={{ fontSize: "36px", fontWeight: "bold" }}>Login</span>
          </div>
          <div className="form__group__login field d-flex email_container">
            <div style={{ width: "100%" }}>
                <label className="form__label_email">Enter your email</label>
                <input
                  type="text"
                  className={`form__field_email`}
                  onChange={(e) => { setEmail(e.target.value) }}
                />
               
            </div>
          </div>
          <div
            className="form__group__login field d-flex password_container"
          >
            <div style={{ width: "100%" }}>
            <label className="form__label_password">
                Enter your password
              </label>
              <div className="d-flex">
                <input
                  type={passwordVisibility ? "text" : "password"}
                  className={`form__field_password`}
                  onChange={(e) => { setPassword(e.target.value) }}
                />
                <div style={{ display:'flex', alignItems: 'center' }}>
                  {passwordVisibility ? (
                    <FaRegEye
                      className=""
                      size={20}
                      onClick={() => {
                        setPasswordVisibility(!passwordVisibility);
                      }}
                    />
                  ) : (
                    <FaRegEyeSlash
                      className=""
                      size={20}
                      onClick={() => {
                        setPasswordVisibility(!passwordVisibility);
                      }}
                    />
                  )}
                </div>
              </div>
              
            </div>
          </div>

          <C_Button
            backgroundColor={Colors.generalButtonColor}
            width={"100%"}
            buttonText={"LOGIN"}
            onClick={() => { 
              signIn();
            }}
            textColor={"#FFFFFF"}
            justify={"center"}
          />

          <div className="FontStyle" style={{ alignSelf: "center" }} onClick={()=>{
            showForgotPasswordModal();
          }} >
            Forgot your password?
          </div>
          
        </form>
        <div className="registerFontStyle" style={{ alignSelf: "center" }} onClick={() => { navigate('/Register') }}>
            New to Zumax CRM?
          </div>
        {error !== "" &&
          <div className="alert alert-danger mt-3 mb-0">{error}</div>
        }
        </>
      }
      </div>
    </div>
  );
}

export default Login;
