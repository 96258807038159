import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import C_Loading from "../../components/C_Loading";
import './UsersAndPermissions.scss'
import { v4 } from "uuid";
import { db } from "../../firebaseInit";
import moment from "moment";
import C_Button from "../../components/C_Button";
import { AiOutlineClose } from "react-icons/ai";
import C_PopoutNoti from "../../components/C_PopoutNoti";
import { Modal } from "bootstrap";
import Users from "./Users";
import Permissions from "./Permissions";
import { HiOutlineUserPlus } from "react-icons/hi2";
import C_DialogButton from '../../components/C_DialogButton';
import { set, ref } from "firebase/database";

import { checkUserExists, selectCreateFirebaseUserLoading, selectFirebaseUser, selectFirebaseUserExist } from "../../stores/slices/firebaseAuthSlices";
import { retrieveCurrUsage } from "../../stores/slices/usageSlices";
import { retrieveRoles, selectCurrRoles, selectRoles } from "../../stores/slices/roleSlices";
import { retrieveCompanyAgent, sendAgentInvitation } from "../../stores/slices/companyAgentSlices";
import { selectCompanyID } from "../../stores/slices/companyIDSlices";
import { selectAvailableTenant, selectTenant } from "../../stores/slices/tenantSlices";
import { selectCurrSa, selectCurrTenant } from "../../stores/slices/persistRolesSlices";
import { selectCompanyContext, selectCurrUserContext, setCompanyDataAndUsers } from "../../stores/slices/companySlices";
import { selectUser } from "../../stores/slices/userAuthSlices";


function UsersAndPermissions(props) {

    const UserNameRef = useRef(null);
    const inviteModalRef = useRef(null);

    const dispatch = useDispatch();
    const user = selectUser();
    const { companyList } = selectCompanyContext();
    const { currUsers, currUserSA, currUsage, currUserInvitation, currTotalUsage } = selectCurrUserContext();

    const companyID = selectCompanyID();
    const tenant = selectCurrTenant();
    const currSa = selectCurrSa();
    const currRoles = selectCurrRoles();
    const roles = selectRoles();
    const availableTenant = selectAvailableTenant();
    const firebaseUser = selectFirebaseUser();
    const firebaseUserExist = selectFirebaseUserExist();
    const firebaseUserExistLoading = selectCreateFirebaseUserLoading();

    const [currUserList, setCurrUserList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [updateError, setUpdateError] = useState(false);
    const [notifyMessage, setNotifyMessage] = useState("");

    const [subSection, setSubSection] = useState("users");
    const [invitationDetails, setInvitationDetails] = useState({
        email: "",
        RoleID: ""
    });
    const [companyRolesList, setCompanyRolesList] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [isInviteLoading, setIsInviteLoading] = useState(false);

    const [collaboratorQuota, setCollaboratorQuota] = useState(0);
    const [collaboratorUsage, setCollaboratorUsage] = useState(0);

    const [isSendMail, setIsSendMail] = useState(false);

    useEffect(()=>{

        if(isSendMail){
            const mailID = v4();
            const TenantID = tenant;
            const CompanyID = companyID; //companyID that users invited to
            const UserID = user.uid; 
            const RoleID = invitationDetails.RoleID;
            const displayName = user.displayName;
            const email = invitationDetails.email;
            const hostEmail = user.email;
            const tenantName = availableTenant?.find(item => item?.TenantID === tenant)?.CompanyName ?? "Tenant";
            const companyName = companyList?.find(item => item?.CompanyID === companyID)?.CompanyName ?? "Company"; //companyName from tenant table
            const isExist = firebaseUserExist !== "ERROR" && firebaseUserExist; //existing member UserID or false
    
            dispatch(setCompanyDataAndUsers({
                TenantID : tenant, 
                isSA: true, 
                UserID : user?.uid,
                RoleID : "SA",
                CompanyID : companyID
            }));
            createInvitationMail({mailID,TenantID,CompanyID,UserID,RoleID,email,isExist});
            //mail template condition is made based on isExist whether is true or false
            dispatch(retrieveCurrUsage({TenantID:tenant,CompanyID:companyID}));
            dispatch(sendAgentInvitation({mailID,TenantID,CompanyID,UserID,RoleID,displayName,email,hostEmail,tenantName,companyName, isExist}))
            setTimeout(()=>{
                setIsInviteLoading(false);
                setInvitationDetails({
                    email: "",
                    RoleID: ""
                });
                notify("sent");
                setIsSendMail(false);
            }, 2000)    
        }
       
    }, [firebaseUserExist, isSendMail])

    useEffect(() => {
        if(tenant && companyID){
            dispatch(retrieveCompanyAgent({TenantID:tenant,CompanyID:companyID}))
        }
    },[tenant, companyID]);
    
    useEffect(() => {
        if(currUsage && currTotalUsage !== undefined && currTotalUsage && currUserInvitation){
            const filterQuota = currUsage?.find(item => item.SectionID === "s1")?.Quota;
            const filterUsage = currTotalUsage?.find(item => item.SectionID === "s1")?.Usage;
            setCollaboratorQuota(filterQuota);
            setCollaboratorUsage(filterUsage);
        }else{
            setCollaboratorQuota(0);
            setCollaboratorUsage(0);
        }
    },[currUsage, currTotalUsage, currUserInvitation])

    useEffect(() => {
        if(companyID && tenant){
            dispatch(retrieveCurrUsage({TenantID:tenant,CompanyID:companyID}));
        }
    },[companyID, tenant])

    function refreshUsage(){
       dispatch(retrieveCurrUsage({TenantID:tenant,CompanyID:companyID}));
    };

    useEffect(() => {
        if(Array.isArray(currUserInvitation) && Array.isArray(currUserSA)){
            const newUsersSet = [...currUserInvitation, ...currUserSA];
            setCurrUserList(newUsersSet);
        }
    },[currUserInvitation, currUserSA]);

    useEffect(() => {
        // setSelectedRole(null);
        if(Array.isArray(roles) && roles && roles.length > 0){
          setCompanyRolesList(roles);
        }
    },[roles, subSection]);

    useEffect(() => {
        //do retrieve function
        if(tenant && companyID){
            switch(subSection){
                case "users":
                    dispatch(retrieveRoles({TenantID:tenant,CompanyID:companyID}));
                    break;
                case "permissions":
                    dispatch(retrieveRoles({TenantID:tenant,CompanyID:companyID}));
                    break;
                default:
                    break;
            }
        }
      
    },[subSection]);

    const showInviteModalFunc = () => {
        const modalEle = inviteModalRef.current;
        const bsModal = new Modal(modalEle, {
          backdrop: "static",
          keyboard: false,
        });
        bsModal.show();
    };

    const hideInviteModalFunc = () => {
        const modalEle = inviteModalRef.current;
        const bsModal = Modal.getInstance(modalEle);
        bsModal.hide();
    };

    function notify(type){
        switch(type){
          case "edit":
            setNotifyMessage("UPDATED COMPANY")
            break;
          case "delete":
            setNotifyMessage("DELETED COMPANY")
            break;
          case "copied":
            setNotifyMessage("COPIED TEXT")
            break;
          case "sent":
            setNotifyMessage("INVITATION SENT")
            break;
          default:
            break;
        }
    
        setTimeout(() => {
          setNotifyMessage("");
        }, 1500);
    };

    async function copyToClipboard(text){
        switch(text){
            case "CompanyID":
                try {
                    await navigator.clipboard.writeText(UserNameRef.current.innerText);
                    notify("copied")
                  } catch (err) {
                    console.error('Failed to copy text: ', err);
                  }
                break;
            default:
                break;
        }
    };

    function handleChangeSubSection(tab){
        setSubSection(tab);
    };

    function handleInput({type, value}){
        switch(type){
            case "email":
                setInvitationDetails({...invitationDetails, email: value})
                break;
            case "role":
                setInvitationDetails({...invitationDetails, RoleID: value})
                break;
            default:
                break;
        };
    };

    const createInvitationMail = async ({mailID,TenantID,CompanyID,UserID,RoleID,email,isExist}) => {
        const createdAt = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
        await set(ref(db, 'invitationMail/' + `${TenantID}/` + `${CompanyID}/` +mailID ), {
            mailID:mailID,
            tenantID:TenantID,
            email: email,
            companyID:CompanyID,
            hostID:UserID,
            roleID:RoleID,
            createdAt:createdAt,
            isExist:isExist
        });
    };

    const emailValidation = async (email) => {
        let regexEmail = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
        // let regexEmailLength = /^[^\s@]{6,}@[^\s@]+\.[^\s@]+$/;
    
        return regexEmail.test(email);
    }

    //when invite users
    const sendMail = async () => {
        refreshUsage();
        dispatch(retrieveCompanyAgent({TenantID:tenant,CompanyID:companyID}))
        setIsInviteLoading(true);
        if(collaboratorUsage < collaboratorQuota){
            //check current company user;
            const validateEmail = await emailValidation(invitationDetails.email);
            if(invitationDetails.email !== "" && invitationDetails.RoleID !== ""){
                if(invitationDetails.email === user.email){
                    setIsInviteLoading(false);
                    setErrorMessage("You are not suppose to add yourself");
                }else{
                    if(validateEmail){
                        const checkExisted = currUserSA?.some(item => item?.email === invitationDetails.email);
                        if(checkExisted){
                            setIsInviteLoading(false);
                            setErrorMessage("User Existed");
                        } else {
                            setErrorMessage("");
                            dispatch(checkUserExists({email: invitationDetails.email}));
                            setTimeout(()=>{
                                setIsSendMail(true);
                                hideInviteModalFunc();
                                setIsInviteLoading(false);
                            }, 2000)
                            
                        }
                    }else{
                        setErrorMessage("Incorrect Email Format");
                        setIsInviteLoading(false);
                    }
                }
            }else{
                setErrorMessage("Please complete the field");
                setIsInviteLoading(false);
            }
        }else{
            alert("You've reached the limit");
            hideInviteModalFunc();
            setInvitationDetails({
                email: "",
                RoleID: ""
            });
            setIsInviteLoading(false);
        }

    };

    return (
        <>
        <C_Loading isLoading={isLoading}/>
        <C_PopoutNoti triggerSaved={notifyMessage}/>
        <div className="modal fade" tabIndex="-1" ref={inviteModalRef}>
            <div className="modal-dialog modal-dialog-centered" style={{}}>
                <div className="modal-content px-3" style={{overflowY: "scroll" }}>
                    <div className="modal-header modalHeader">
                        <div className="title">
                            <HiOutlineUserPlus size={17} color={"#3eb986"}/>
                            <span className="font-s">Invite user</span>
                        </div>
                        <AiOutlineClose cursor='pointer' size={16} onClick={() => {
                            hideInviteModalFunc();
                        }}/>
                    </div>
                    <div className="modal-body">
                        <div className="inviteMemberBody">
                            <div className="titleContainer">
                                <span>Add a user to <strong>{Array.isArray(companyList) && companyList?.find(item => item?.CompanyID === companyID)?.CompanyName}</strong></span>
                            </div>
                            <div className="inputMainContainer">
                                <div className="inputContainer">
                                    <input
                                        placeholder="Enter an email to invite user"
                                        value={invitationDetails?.email}
                                        onChange={(e) => {
                                            handleInput({ type: 'email', value: e.target.value})
                                        }}
                                    />
                                </div>
                                <div className="inputContainer">
                                    <select value={invitationDetails?.RoleID} onChange={(e) => {
                                        handleInput({ type: 'role', value: e.target.value})
                                    }}>
                                    <option selected disabled value="">Select a role</option>
                                    {companyRolesList?.map((role, index) => {
                                        return (
                                            <option key={index} value={role?.RoleID}>{role?.RoleName}</option>
                                        );
                                    })}
                                    </select>
                                </div>
                            </div>
                        </div>
                        {collaboratorUsage < collaboratorQuota ?
                        <div style={{ display: "flex", justifyContent: errorMessage === "" ? "flex-end" : "space-between", columnGap:'20px', alignItems: "center" }} className="mt-3 modalshit">
                            {errorMessage !== "" && <span className="font-s" style={{ color:"#cd3c07" }}>{errorMessage}</span>}
                            {isInviteLoading ?
                                <div className={`inviteMemberloadingContainer ${!isInviteLoading && 'hideLoadingMemberContainer'}`}>
                                    <div className="loading-spinner">
                                    </div>
                                </div>
                                :
                                <C_DialogButton
                                    color={"white"}
                                    background={'#3eb986'}
                                    borderColor={'#3eb986'}
                                    buttonText={"SEND"}
                                    onClick={() => { 
                                        sendMail();
                                    }}
                                />
                            }
                        </div>
                        :
                        <div className="quotaRestrictedContainer">
                            <span>You've reached the limit, <a href="https://zumaxdigital.com/crm-v1/" target="_blank" className="link">upgrade now!</a></span>
                        </div>
                        }
                    </div>
                </div>
            </div>
        </div>
        <div className="UsersAndPermissionsMainContainer">
            <div className="UsersAndPermissionsContainer">
                <div className="SubSectionTabContainer">
                    <div className="mainActionContainer">
                        <div className={`subTabContainer ${subSection === "users" && 'selectedSubTab'}`} onClick={() => { handleChangeSubSection("users") }}>
                            <span>Users</span>
                        </div>
                        <div className={`subTabContainer ${subSection === "permissions" && 'selectedSubTab'}`} onClick={() => { handleChangeSubSection("permissions") }}>
                            <span>Permissions</span>
                        </div>
                    </div>
                    <div className="otherActionContainer">
                        <C_Button
                            buttonText={"Invite User"}
                            background={"#3eb986"}
                            width={window.innerWidth > 1500 ? "130px" : "110px"}
                            onClick={() => { 
                                setErrorMessage("");
                                showInviteModalFunc();
                            }}
                            justify={"center"}
                            textColor={"#FFFFFF"}
                            gap={"10px"}
                            icon={<HiOutlineUserPlus size={16} />}
                        />
                    </div>
                </div>
                <div className="subSectionContainer">
                    {subSection === "users" && <Users/>}
                    {subSection === "permissions" && <Permissions/>}
                </div>
            </div>
        </div>
        </>
    );
};

export default UsersAndPermissions;