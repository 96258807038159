import React, { useState, useEffect } from "react";
import {
  Typography,
  Stack,
  Divider,
  Box,
  Button,
  TextField,
  InputAdornment,
  Autocomplete,
  MenuItem,
  Chip,
} from "@mui/material";

import { FormInputCheckbox } from "../FormInput";
import { SearchRounded, HighlightOffRounded } from "@mui/icons-material";
import { ChipText } from "./ChipText";

// import { convertSxToArray } from "@utils";
import { useForm } from "react-hook-form";

import { ChipStatus } from "../../enums/components/Chip";
import { AccordionWidget } from "./AccordionWidget";
export const PopoverFilterForm = ({
  // filters,
  options,
  onSubmit,
  form,
  onClose,
}) => {
  // const { Status, Customer } = TableFilter;

  // const optionsList = [...Status, ...Customer];

  const { control, handleSubmit, setValue, getValues, reset, watch } = useForm({
    defaultValues: form,
    shouldFocusError: true,
    shouldUnregister: false,
  });

  // const test = formState.dirtyFields;

  const [columnList, setColumnList] = useState([]);
  const [stackHeight, setStackHeight] = useState("unset");
  const listToWatch = options.map((option) => {
    return watch(`${option.group}.${option.value}`);
  });
  useEffect(() => {
    // setAutocompleteValues(autocompleteValueFunc());
  }, listToWatch);

  //* < daniel task
  // apply meaningful const for new added object
  const groupByList = options.reduce((grouped, filter) => {
    const { group, label, value } = filter;
    let isGrouped = grouped[group];
    //if grouped[group] is not assigned key yet then assign the key then set value to new array list
    if (!isGrouped) {
      isGrouped = [];
    }
    // grouped[group] = grouped[group] ?? [];
    //then push new object into grouped[group]'s array
    isGrouped.push({ label: label, value: value });
    return { ...grouped, [group]: isGrouped };
  }, {});

  const handleInputChange = (event, inputValue, reason) => {
    if (reason == "selectOption") {
      //* add added value (last index) from autocomplete to checkbox
      const lastAddedFilter = inputValue.slice(-1)[0];
      setValue(`${lastAddedFilter.group}.${lastAddedFilter.value}`, true);
    }
    if (reason == "removeOption") {
      //* remove value that is remove from autocomplete by filter out the checkbox values
      const formValues = getValues();

      const groupedResults = inputValue.reduce((grouped, filter) => {
        const { group, label, value } = filter;
        grouped[group] = grouped[group] ?? [];
        grouped[group].push({ label: label, value: value });
        return grouped;
      }, {});

      Object.entries(formValues).forEach((entry) => {
        const key = entry[0];
        const value = entry[1];
        const filteredArray = Object.keys(value).filter((key) => value[key]);

        const currentGroupResult = groupedResults[key];
        if (currentGroupResult) {
          filteredArray.forEach((filtered) => {
            const isUndefined = currentGroupResult.find(
              (result) => result.value == filtered
            );

            if (isUndefined === undefined) {
              setValue(`${key}.${filtered}`, false);
              // setAutocompleteValues((prev) => {
              //   const removeValue = prev.filter((v) => {
              //     return v.value != filtered;
              //   });
              //   return removeValue;
              // });
            }
          });
        } else {
          if (filteredArray.length > 0) {
            setValue(`${key}.${filteredArray[0]}`, false);
            // setAutocompleteValues((prev) => {
            //   const removeValue = prev.filter((v) => {
            //     return v.value != filteredArray[0];
            //   });
            //   return removeValue;
            // });
          }
        }
      });
    }

    if (reason == "clear") {
      reset();
    }
  };
  //autocomplete values based on checkbox values
  const autocompleteValueFunc = () => {
    var autocompleteValues = [];
    const formValues = getValues();
    autocompleteValues = options.filter((option) => {
      const groupedOptionValues = formValues[option.group];
      if (groupedOptionValues && groupedOptionValues[option.value]) {
        return true;
      }
      return false;
    });
    return autocompleteValues;
  };

  //* if got group by the value will have spacing
  const renderMenuOptions = (item) => (
    <MenuItem {...item} sx={{ textIndent: "20px" }}>
      {item.key}
    </MenuItem>
  );

  const renderStartAd = (params) => {
    return (
      <TextField
        {...params}
        variant="standard"
        // placeholder={label}
        inputProps={{
          ...params.inputProps,
        }}
        InputProps={{
          ...params.InputProps,
          startAdornment: (
            <>
              <InputAdornment position="start">
                <SearchRounded />
              </InputAdornment>
              <Stack
                direction="row"
                sx={{
                  minWidth: "0px",
                  maxWidth: "40%",
                  "::-webkit-scrollbar": {
                    width: "0px",
                    height: "0px",
                  },
                }}
              >
                {params.InputProps.startAdornment}
              </Stack>
            </>
          ),
        }}
      />
    );
  };

  return (
    <Stack
      sx={{
        padding: "15px",
        gap: "10px",

        overflow: "auto",
        height: stackHeight,
      }}
    >
      <Stack
        direction="row"
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography>{"Filter"}</Typography>

        <Button
          variant="text"
          sx={({ palette }) => ({
            color: palette.primary.primary,
          })}
          onClick={() => {
            reset();
          }}
        >
          {"Clear All"}
        </Button>
      </Stack>
      <Divider />

      <Autocomplete
        name="autocompleteField"
        autoComplete={false}
        onOpen={() => {
          //todo need think a way to make it responsive
          setStackHeight("500px");
        }}
        onClose={() => {
          setStackHeight(`unset`);
        }}
        aria-autocomplete="none"
        // value={autocompleteValues}
        value={autocompleteValueFunc()}
        multiple
        disablePortal
        options={options}
        //* when it change to groupby it not get the row instead of value
        getOptionLabel={(option) => {
          return option.label;
        }}
        groupBy={(option) => option.group}
        onChange={handleInputChange}
        renderOption={renderMenuOptions}
        clearOnBlur
        clearIcon={
          <HighlightOffRounded sx={({ icons }) => ({ ...icons.standard })} />
        }
        forcePopupIcon={false}
        renderTags={(value, getTagProps) => {
          return value.map((option, index) => {
            return (
              <Chip
                key={`advance_filter_${option.value}`}
                sx={{
                  marginRight: "10px",
                }}
                label={`${option.group}: ${option.label}`}
                color={ChipStatus.Success}
                {...getTagProps({ index })}
              />
            );
          });
        }}
        renderInput={renderStartAd}
      />
      <Typography
        sx={({ typography }) => ({
          ...typography.remark,
        })}
      >
        {"I'm searching for"}
      </Typography>
      <Stack
        direction="row"
        sx={{
          gap: "10px",
        }}
      >
        {Object.keys(groupByList).map((grouped) => {
          const isIncluded = columnList.includes(grouped);
          return (
            <ChipText
              key={`${grouped}-group`}
              label={grouped}
              status={isIncluded ? ChipStatus.Success : ChipStatus.Info}
              onClick={() => {
                isIncluded;
                setColumnList((prev) => {
                  if (!isIncluded) {
                    return [...prev, grouped];
                  }
                  return prev.filter((prevGrouped) => prevGrouped != grouped);
                });
              }}
            />
          );
        })}
      </Stack>
      <Box>
        {Object.entries(groupByList).map((entry) => {
          const key = entry[0];
          const value = entry[1];
          const isVisible = columnList.includes(key) ? {} : { display: "none" };

          const uniqueValue = Array.from(new Set(value));
          return (
            <AccordionWidget
              key={`accordion-${key}`}
              title={key}
              sx={[isVisible]}
            >
              <Stack sx={{ gap: "10px" }}>
                {uniqueValue.map((filter) => {
                  return (
                    <FormInputCheckbox
                      key={filter.value}
                      control={control}
                      name={`${key}.${filter.value}`}
                      label={filter.label}
                    />
                  );
                })}
              </Stack>
            </AccordionWidget>
          );
        })}
      </Box>

      <Button
        sx={[
          { position: "sticky !important", bottom: "10px", paddingTop: "10px" },
        ]}
        variant="contained"
        fullWidth
        onClick={handleSubmit((data) => onSubmit(data, onClose))}
      >
        {"Apply"}
      </Button>
    </Stack>
  );
};

