import { call, delay, put, takeLatest } from "redux-saga/effects";
import {
    setFormSubmissionID,
    setFormSubmissionIDSuccess,
    setFormSubmissionIDError,
} from "../stores/slices/formSubmissionIDSlices"

function* setFormSubmissionIDSaga({payload}){
    try{
        yield put(setFormSubmissionIDSuccess(payload))
    } catch (error){
        yield put(setFormSubmissionIDError(error));
        console.error(error);
    }
}

export function* formSubmissionIDSagaWatcher(){
    yield takeLatest(setFormSubmissionID.type, setFormSubmissionIDSaga);
}
