import React, {
    useState,
    useEffect,
    useRef,
} from "react";
import moment from "moment";
import C_Button from "../../components/C_Button";
import "./Kpi.scss";
import { useDispatch} from "react-redux";
import { createSearchParams, useNavigate } from "react-router-dom";
import C_Search from "../../components/C_Search";
import { MdAnalytics} from "react-icons/md";
import { Modal } from "bootstrap";
import { BiBarChartSquare } from "react-icons/bi";
import { FaWpforms } from "react-icons/fa";
import { BsCalendarRange } from "react-icons/bs";
import { AiFillSetting, AiOutlineClose } from "react-icons/ai";

import C_Loading from "../../components/C_Loading";
import { RiTeamFill, RiUser2Fill } from "react-icons/ri";
import PersonalPerformance from "./Kpi_Analytics/PersonalPerformance";
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import { easeQuadInOut } from 'd3-ease';
import AnimatedProgressProvider from "../../components/AnimatedProgressProvider";
import C_IconButton from "../../components/C_IconButton";

//slices
import { selectKpiContext, selectKpi, setKpiContext } from "../../stores/slices/kpiSlices";
import { selectFormSubmission } from "../../stores/slices/formSubmissionSlices";
import { selectCurrUserContext } from "../../stores/slices/companySlices";
import { selectUser } from "../../stores/slices/userAuthSlices";
  
  function KpiDashboard(props) {

    const isSuperAdmin = props.isSuperAdmin;
    const Kpi_Manage = props.Kpi_Manage;

    const {
        analyticsGrouping,
        analyticsData,
    } = selectKpiContext();

    const { currUserSA } = selectCurrUserContext();

    const allUser = currUserSA;

    const kpiData = selectKpi();
    const submission = selectFormSubmission();

    const modalRefAnalytics = useRef();
    
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = selectUser();
    const [searchText, setSearchText] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const [currKpiData, setCurrKpiData] = useState([]);

    useEffect(() => {

        const filteredKPIs = kpiData.filter(kpi => {
            return kpi.targetMembers.some(member => member.uid === user?.uid);
        });

        const searchedData = filteredKPIs.filter(item => {
            if(item.title.toString().toLocaleLowerCase().includes(searchText.toString().toLocaleLowerCase())){
                return item
            }else if(moment(item.startDate).toString().toLocaleLowerCase().includes(searchText.toString().toLocaleLowerCase())){
                return item
            }else if(moment(item.dueDate).toString().toLocaleLowerCase().includes(searchText.toString().toLocaleLowerCase())){
                return item
            }else if(item.target.toString().toLocaleLowerCase().includes(searchText.toString().toLocaleLowerCase())){
                return item
            }else if(moment(item.createdAt).toString().toLocaleLowerCase().includes(searchText.toString().toLocaleLowerCase())){
                return item
            }
        });
        const sortedData = searchedData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setCurrKpiData(sortedData);

    },[kpiData, searchText]);

    const showModalFunc = () => {
        const modalEle = modalRefAnalytics.current;
        const bsModal = new Modal(modalEle, {
        //   backdrop: "static",
        //   keyboard: false,
        });
        bsModal.show();
    };
    
    const hideModalFunc = () => {
        const modalEle = modalRefAnalytics.current;
        const bsModal = Modal.getInstance(modalEle);
        bsModal.hide();
    };

    function handleGoSetting(){
        setIsLoading(true)
        setTimeout(() => {
            setIsLoading(false);
            dispatch(setKpiContext({showKpiSetting : true}));
            // dispatchShowKpiSetting({type: "SHOW_KPI_SETTING", payload: true})
        },1000);
    };

    function findMetricValue(item, allSubmission) {
        let targetMembers = item.targetMembers;
        let metricValue = item.metricValue;
        let results = [];
        
        for (let member of targetMembers) {
          let sum = 0;
          for (let submission of allSubmission) {
            if (member?.uid === submission.UserID) {
              for (let data of submission.SubmissionData) {
                if (data.questionID === metricValue) {
                  sum += parseFloat(data.answer);
                  break;
                }
              }
            }
          }
          results.push({
            displayName: member?.displayName,
            uid: member?.uid,
            teamContribution: item?.teamContribution,
            target: item?.target,
            unit: item?.unitOfMeasurement,
            total: sum
          });
        }

        const achiever = results.filter(data => data.total >= parseInt(item.target));
        const totalValue = achiever.length;
        const percentage = Math.round((achiever.length / item?.targetMembers?.length) * 100);

        return { totalValue, percentage, results };
    };

    function SelectedAnalytics(item){
        showModalFunc();
        dispatch(setKpiContext({analyticsData : item}));
        // dispatchAnalyticsData({type: "CHANGE_DATA", payload: item})
    }

    function findSubmissionValue(item, allSubmission) {
        let targetMembers = item.targetMembers;
        let results = [];
        
        for (let member of targetMembers) {
          let count = 0;
          for (let submission of allSubmission) {
            if (member?.uid === submission.UserID) {
              count++;
            }
          }
          results.push({
            displayName: member?.displayName,
            uid: member?.uid,
            teamContribution: item?.teamContribution,
            target: item?.target,
            unit: item?.unitOfMeasurement,
            total: count
          });
        };

        const achiever = results.filter(data => data.total >= parseInt(item.target));
        const totalValue = achiever.length;
        const percentage = Math.round((achiever.length / item?.targetMembers?.length) * 100);

        return { totalValue, percentage, results };
    };

    const handleOptionChangeGrouping = (event) => {
        dispatch(setKpiContext({analyticsGrouping : event.target.value}));
        // dispatchAnalyticsGrouping({type:"CHANGE_GROUPING", payload: event.target.value});
    };

    return(
        <>
        <C_Loading isLoading={isLoading}/>

        <div className="modal fade" tabIndex="-1" ref={modalRefAnalytics} style={{}}>
            <div className="modal-dialog modal-dialog-centered modal-xl" style={{}}>
                <div className="modal-content px-3 analyticsModalMainContainer" style={{ minHeight: "556px", maxHeight: "750px", overflowY: "scroll" }}>
                    <div className="modal-header modalHeader">
                        <div className="title">
                            <div className="info">
                                <MdAnalytics size={20}/>
                                <span>Analytics</span>
                            </div>
                            <div className="selection">
                                <select value={analyticsGrouping} onChange={handleOptionChangeGrouping}>
                                    <option value="Days">Days</option>
                                    <option value="Weeks">Weeks</option>
                                    <option value="Months">Months</option>
                                    <option value="Years">Years</option>
                                </select>
                            </div>
                            <div className='kpiDetailsContainerAnalytics'>
                                <div className='title'>
                                    <span>{analyticsData?.item?.title}</span>
                                </div>
                                <div className='duration'>
                                    <span>{moment(analyticsData?.item?.startDate).format("DD-MM-YYYY")}</span>
                                    <span>{" ~ "}</span>
                                    <span>{moment(analyticsData?.item?.dueDate).format("DD-MM-YYYY")}</span>
                                </div>
                                <div className='type'>
                                    <span>{analyticsData?.item?.teamContribution ? "Team KPI" : "Individual KPI"}</span>
                                </div>
                            </div>
                        </div>
                        <AiOutlineClose cursor='pointer' size={16} onClick={() => { hideModalFunc() }}/>
                    </div>
                    <div className="modal-body d-flex row modalBodyContainer">
                        <PersonalPerformance/>
                    </div>
                </div>
            </div>
        </div>

        <div className="kpiMainContainer">
            <div className="headerContent">
                <div className="search">
                    <C_Search
                        placeholder="Search by title, start date, due date, KPI's target, or KPI's created date"
                        onChange={(e) => {
                            setSearchText(e.target.value);
                        }}
                        value={searchText}
                        searchText={searchText}
                        closeFunction={() => {
                            setSearchText("");
                        }}
                    />
                </div>
                {(isSuperAdmin || Kpi_Manage) ?
                <div className="toWorkSpace">
                    <C_Button
                        marginTop={"0.5rem"}
                        marginRight={"0.5rem"}
                        width="140px"
                        buttonText="KPI Settings"
                        justify={"space-evenly"}
                        background={"#f8fafa"}
                        color={"#000000"}
                        border={"1px solid"}
                        borderColor={"#BCBCBC"}
                        icon={<AiFillSetting size={16}/>}
                        onClick={() => {
                            handleGoSetting();
                        }}
                        textColor={"#FFFFFF"}
                    />
                </div>
                    :<></>
                }
            </div>
            {currKpiData && currKpiData.length > 0 ?
            <div className="myKpiMainContainer">
                {currKpiData.map((item, index) => {
                    //KPI Calculation Formula//
                    //Filter to get all approved submission belongs to each KPI's
                    const betweenDatesSubmission = submission.filter(submission => {
                        const createdAt = new Date(submission.CreatedAt);
                        const startDate = new Date(item.startDate);
                        startDate.setHours(0, 0, 0, 0);
                        const dueDate = new Date(item.dueDate);
                        dueDate.setHours(23, 59, 59, 999);

                        return createdAt >= startDate && createdAt <= dueDate;
                    });
                    const allSubmission = betweenDatesSubmission.filter(sub => sub.FormID === item.sourceOfData && sub.SubmissionStatus === "1");
                    //If it is team contribution, sum up total value
                    //Else check total completion for this KPI
                    
                    var totalValue = 0;
                    var percentage = 0;
                    var memberContribution = [];

                    /////////Analytics///////

                    //Table
                    //by Data looped out

                    ////////////////////////
                    
                    if(item?.teamContribution){
                        if(item.measuredBy !== "Submission"){
                            memberContribution = findMetricValue(item, allSubmission).results;
                            var total = memberContribution.reduce((sum, item) => sum + parseInt(item.total), 0);
                            totalValue = total;
                            percentage = Math.round((total / item.target) * 100)
                        }else{
                            memberContribution = findSubmissionValue(item, allSubmission).results;
                            var total = memberContribution.reduce((sum, item) => sum + parseInt(item.total), 0);
                            totalValue = total;
                            percentage = Math.round(( total / item.target) * 100)
                        }
                    }else{
                        if(item.measuredBy !== "Submission"){
                            memberContribution = findMetricValue(item, allSubmission).results;
                            var total = memberContribution?.find(users => users.uid === user?.uid).total;
                            totalValue = total;
                            percentage = total === 0 ? total : Math.round(( total / item.target) * 100)
                        }else{
                            memberContribution = findSubmissionValue(item, allSubmission).results
                            var total = memberContribution?.find(users => users.uid === user?.uid).total;
                            totalValue = total;
                            percentage = total === 0 ? total : Math.round(( total / item.target) * 100)
                        }
                    }
                    
                    const member = memberContribution.find(x => x.uid === user?.uid)
                    const myContribution = member ? member.total : 0;
                    const myPercentage = totalValue === 0 ? totalValue : Math.round(( myContribution / totalValue) * 100);
                    
                    const targetMemberDetails = item?.targetMembers.map((member) => {
                        const user = allUser.find((user) => user?.uid === member.uid)
                        return user ? 
                            {   
                                uid: user?.uid,
                                displayName: user.displayName,
                                userProfile: user.photoURL
                            } 
                            : 
                            {   
                                uid: "User Not Existed",
                                displayName: "User Not Existed",
                                userProfile: "https://firebasestorage.googleapis.com/v0/b/rexsoft-crm.appspot.com/o/memberProfile%2FdeletedUser.jpeg?alt=media&token=bf97ce76-b439-4c51-aae0-9db0cc0d5be9"
                            };
                    })

                    const displayNames = targetMemberDetails.map(name => name.displayName);
                    const displayNamesString = displayNames.join(", ");
                    ///////////////////////////
                    return(
                        <div key={item.KpiID} className="myKpiContainer">

                            <div className="titleContainer">
                                <div className="titleSubContainer">
                                    <div className="title">
                                        {item?.teamContribution ?
                                            <RiTeamFill size={16}/> : <RiUser2Fill size={16}/>
                                        }
                                        <span title={item?.title}>{item?.title}</span>
                                    </div>
                                    <div className="durationContainer">
                                        <BsCalendarRange size={12}/>
                                        <div className="duration">
                                            <span>{moment(item?.startDate).format("DD-MM-YYYY")}</span>
                                            <span>{"~"}</span>
                                            <span>{moment(item?.dueDate).format("DD-MM-YYYY")}</span>
                                        </div>
                                    </div>
                                    <div className="descriptionContainer">
                                        <span title={item?.definition}>{item?.definition}</span>
                                    </div>
                                </div>
                            </div>

                            <div className="totalProgression">
                                <div className="progressMainContainer">
                                    <AnimatedProgressProvider
                                        valueStart={0}
                                        valueEnd={percentage}
                                        duration={1.5}
                                        easingFunction={easeQuadInOut}
                                    >
                                        {(value) => {
                                            const roundedValue = Math.round(value);
                                            return (
                                            <CircularProgressbar
                                                value={value}
                                                strokeWidth={10}
                                                text={`${roundedValue}%`}
                                                styles={buildStyles({ 
                                                    rotation: 0,
                                                    strokeLinecap: 'butt',
                                                    textSize: '25px',
                                                    pathColor: percentage > 80 ? "green" : 
                                                        percentage > 60 ? "blue" : 
                                                        percentage > 40 ? "brown" : 
                                                        percentage > 20 ? "red" : "purple",
                                                    textColor: '#f88',
                                                    trailColor: '5px solid #ccc',
                                                    backgroundColor: 'transparent',
                                                    pathTransition: 'none'
                                                })}
                                            />
                                            );
                                        }}
                                    </AnimatedProgressProvider>
                                </div>
                                <div className="progressDetails">
                                    <div className="title">
                                        <span>Overall Progress</span>
                                    </div>
                                    <div className="details">
                                        <AnimatedProgressProvider
                                            valueStart={0}
                                            valueEnd={totalValue}
                                            duration={1.5}
                                            easingFunction={easeQuadInOut}
                                        >
                                            {(value) => {
                                                const roundedValue = Math.round(value);
                                                return (
                                                    <span>{roundedValue} / {item?.target}</span>
                                                );
                                            }}
                                        </AnimatedProgressProvider>
                                    </div>
                                    <div className="unit">
                                        <span>{item?.unitOfMeasurement !== "" && '('}{item?.unitOfMeasurement}{item?.unitOfMeasurement !== "" && ')'}</span>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="myProgression">
                                <div className="graphContainer">
                                    <img src={require('../../assets/img/graphUp.png')} alt=""/>
                                </div>
                                <div className="myProgressDetails">
                                    <div className="title">
                                        <span>My Contribution</span>
                                    </div>
                                    <div className="details">
                                        <AnimatedProgressProvider
                                                valueStart={0}
                                                valueEnd={myPercentage}
                                                duration={1.5}
                                                easingFunction={easeQuadInOut}
                                            >
                                                {(value) => {
                                                    const roundedValue = Math.round(value);
                                                    return (
                                                        <span>{roundedValue}%</span>
                                                    );
                                                }}
                                        </AnimatedProgressProvider>
                                    </div>
                                </div>
                            </div>

                            <div className="kpiTypeContainer">
                                <div className="contributionTypeContainer">
                                    <span>{item?.teamContribution ? "TEAM" : "INDIVIDUAL"}</span>
                                </div>
                                <div className="contributorContainer">
                                    {targetMemberDetails && targetMemberDetails?.slice(0, 5).map((target, index) => {
                                        return(
                                            <img key={index} src={target.userProfile} alt=""/>
                                        )
                                    })}
                                </div>
                                <div className="totalContainer">
                                    <span title={displayNamesString}>{targetMemberDetails && targetMemberDetails.length} people</span>
                                </div>
                            </div>

                            <div className="actionContainer">
                                <C_IconButton
                                    onClick={() => { 
                                        navigate({ pathname: "/FormList/FormSubmission", search: createSearchParams({ formID: item?.sourceOfData, from: 'kpi' }).toString() }) 
                                    }}
                                    size={40}
                                    color="#CDCDCD"
                                    backgroundImage="linear-gradient(to right top, #1207a9, #332fbf, #4c4dd5, #646bea, #7c88fe)"
                                    icon={<FaWpforms color="#FFF" size={15}/>}
                                />
                                <C_IconButton
                                    onClick={() => {
                                        SelectedAnalytics({
                                            item,
                                            uid: user?.uid,
                                            allSubmission: allSubmission
                                        });
                                    }}
                                    size={40}
                                    color="#CDCDCD"
                                    backgroundImage="linear-gradient(to right top, #0a8121, #2e9f44, #49be66, #62de89, #7cfead)"
                                    icon={<BiBarChartSquare color="#FFF" size={18}/>}
                                />
                            </div>

                        </div>
                    )
                })}
            </div>
            :
            <div className="userEmpty">
                <img src={require("../../assets/img/userEmptyKPI.gif")} alt=""/>
                <span>NO KPI AVAILABLE</span>
            </div>
            }
        </div>

        </>
    );
  }
  
  export default KpiDashboard;
  