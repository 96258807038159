import { Route } from "react-router-dom"
import { crmRoutes } from "../enums/Route"
import ProtectedRoute from "./ProtectedRoute"
import { Fragment } from "react"
import FormSubmission from "../containers/form/FormSubmission"

export const FormSubmissionRoute = () => (
    <Fragment>
        <Route 
            path={crmRoutes.form.formSubmission}
            element={
                <ProtectedRoute>
                    <FormSubmission />
                </ProtectedRoute>
            }
        />
    </Fragment> 
)

