import { call, put, takeLatest, delay, select } from "redux-saga/effects";
import Papa from "papaparse";
import moment from "moment";

import {
  retrieveAllTransactions,
  retrieveAllTransactionsSuccess,
  createTransaction,
  createTransactionSuccess,
  retrieveTransactionOptions,
  retrieveTransactionOptionsSuccess,
  retrieveTransaction,
  retrieveTransactionSuccess,
  removeTransaction,
  removeTransactionSuccess,
  voidTransaction,
  voidTransactionSuccess,
  updateTransaction,
  updateTransactionSuccess,
  retrieveTransactionPaymentOptions,
  retrieveTransactionPaymentOptionsSuccess,
  createTransactionPayment,
  createTransactionPaymentSuccess,
  retrieveTransactionPayment,
  retrieveTransactionPaymentSuccess,
  updateTransactionPayment,
  updateTransactionPaymentSuccess,
  retrieveCreditNote,
  retrieveCreditNoteSuccess,
  retrieveCreditNoteOptions,
  retrieveCreditNoteOptionsSuccess,
  updateCreditNote,
  updateCreditNoteSuccess,
  createCreditNote,
  createCreditNoteSuccess,
  retrieveBankTransaction,
  retrieveBankTransactionSuccess,
  retrieveBankTransactionError,
  createBankTransaction,
  createBankTransactionSuccess,
  createBankTransactionError,
  updateBankTransaction,
  updateBankTransactionSuccess,
  updateBankTransactionError,
  retrieveBankTransfer,
  retrieveBankTransferSuccess,
  retrieveBankTransferError,
  updateBankTransfer,
  updateBankTransferSuccess,
  updateBankTransferError,
  retrieveBankTransactionOptions,
  retrieveBankTransactionOptionsSuccess,
  retrieveBankTransactionOptionsError,
  retrieveBankTransferOptions,
  retrieveBankTransferOptionsSuccess,
  retrieveBankTransferOptionsError,
  bulkCreateTransactions,
  bulkCreateTransactionsSuccess,
  bulkCreateTransactionsError,
  getShareTransaction,
  getShareTransactionError,
  getShareTransactionSuccess,
  sendEmailTransaction,
  sendEmailTransactionSuccess,
  sendEmailTransactionError,
  importTransactions,
  importTransactionsError,
  importTransactionsSuccess,
  bulkCreatePayments,
  bulkCreatePaymentsError,
  bulkCreatePaymentsSuccess,
  openShareTransactionDialog,
  openShareTransactionDialogError,
  openShareTransactionDialogSuccess,
} from "../stores/slices/transactionSlices";
import { SnackType } from "../enums/Snacks";
import { ApiRoute } from "../enums/ApiRoute";
import { ACC_API } from "../http-common";
import { addSnack } from "../stores/slices/snackSlice";
import { toggleDialogBox, toggleDrawerEditor } from "../stores/slices/toggleSlice";

function* handleRetrieveTransactions({ payload }) {
  try {
    const {transactionType, currRoles, currSa} = payload;
    const query = `${ApiRoute.transaction.retrieveAll}?type=${transactionType}`;

    // console.log(currRoles)
    const { data } = yield call(ACC_API.get, query);
    yield put(retrieveAllTransactionsSuccess({...data, currRoles, currSa}));
  } catch (error) {
    const errorMessage = error.response.data.description;
    const errorCode = error.response.data.code;
    yield put(
      addSnack({
        type: SnackType.Error,
        message: errorMessage,
        code: errorCode,
        clear: true,
      })
    );
    console.error(error.response.data);
  }
}

function* handleCreateTransaction({ payload }) {
  const { replaceNav, ...otherPayload } = payload;
  try {
    const { data } = yield call(
      ACC_API.post,
      ApiRoute.transaction.create,
      otherPayload
    );
    yield delay(1000);
    yield put(createTransactionSuccess(data));
    yield put(
      addSnack({
        type: SnackType.Success,
        message: "Successfully create transaction",
        clear: true,
      })
    );
    window.location.replace(`/${replaceNav}`);
  } catch (error) {
    const errorMessage = error.response.data.description;
    yield put(
      addSnack({ type: SnackType.Error, message: errorMessage, clear: true })
    );
    console.error(error.response.data);
  }
}

function* handleRetrieveTransactionOptions({ payload }) {
  try {
    const query = `${ApiRoute.transaction.retrieveOptions}?type=${payload}`;
    const { data } = yield call(ACC_API.get, query);

    yield put(retrieveTransactionOptionsSuccess(data));
  } catch (error) {
    const errorMessage = error.response.data.description;
    const errorCode = error.response.data.code;
    yield put(
      addSnack({
        type: SnackType.Error,
        message: errorMessage,
        code: errorCode,
        clear: true,
      })
    );
  }
}

function* handleRetrieveTransaction({ payload }) {
  try {
    const query = `${ApiRoute.transaction.retrieve}?transaction_id=${payload}`;
    const { data } = yield call(ACC_API.get, query);

    yield put(retrieveTransactionSuccess(data));
  } catch (error) {
    const errorMessage = error.response.data.description;
    const errorCode = error.response.data.code;
    yield put(
      addSnack({
        type: SnackType.Error,
        message: errorMessage,
        code: errorCode,
        clear: true,
      })
    );
  }
}

function* handleRemoveTransaction({ payload }) {
  try {
    yield put(toggleDialogBox({ delete: { data: null, isOpen: false } }));

    const query = `${ApiRoute.transaction.remove}?transaction_id=${payload}`;
    const { data } = yield call(ACC_API.delete, query);

    yield put(removeTransactionSuccess(data));
  } catch (error) {
    const errorMessage = error.response.data.description;
    const errorCode = error.response.data.code;
    yield put(
      addSnack({
        type: SnackType.Error,
        message: errorMessage,
        code: errorCode,
        clear: true,
      })
    );
  }
}

function* handleVoidTransaction({ payload }) {
  try {
    yield put(toggleDialogBox({ update: { data: null, isOpen: false } }));

    const query = `${ApiRoute.transaction.updateStatus}`;

    const { data } = yield call(ACC_API.put, query, payload);

    yield put(voidTransactionSuccess(data));
  } catch (error) {
    const errorMessage = error.response.data.description;
    const errorCode = error.response.data.code;
    yield put(
      addSnack({
        type: SnackType.Error,
        message: errorMessage,
        code: errorCode,
        clear: true,
      })
    );
  }
}

function* handleUpdateTransaction({ payload }) {
  
  const { type, removeSearchParams,...otherPayload } = payload;
  try {
    const query = `${ApiRoute.transaction.update}`;

    const { data } = yield call(ACC_API.put, query, otherPayload);

    yield put(updateTransactionSuccess(data));
    yield put(
      addSnack({
        type: SnackType.Success,
        message: "Successfully update transaction",
        clear: true,
      })
    );
    // window.location.replace(`/${type}`);
    removeSearchParams();
  } catch (error) {
    const errorMessage = error.response.data.description;
    const errorCode = error.response.data.code;
    yield put(
      addSnack({
        type: SnackType.Error,
        message: errorMessage,
        code: errorCode,
        clear: true,
      })
    );
  }
}

function* handleUpdateTransactionPayment({ payload }) {
  const { type, ...otherPayload } = payload;
  try {
    yield put(toggleDialogBox({ update: { data: null, isOpen: false } }));
    const query = `${ApiRoute.transaction.updatePayment}`;
    const { data } = yield call(ACC_API.put, query, otherPayload);

    yield put(updateTransactionPaymentSuccess(data));
    yield put(
      addSnack({
        type: SnackType.Success,
        message: "Successfully update transaction",
        clear: true,
      })
    );
    window.location.replace(`/${type}`);
  } catch (error) {
    const errorMessage = error.response.data.description;
    const errorCode = error.response.data.code;
    yield put(
      addSnack({
        type: SnackType.Error,
        message: errorMessage,
        code: errorCode,
        clear: true,
      })
    );
  }
}

function* handleUpdateCreditNote({ payload }) {
  const { type, ...otherPayload } = payload;
  try {
    yield put(toggleDialogBox({ update: { data: null, isOpen: false } }));
    const query = `${ApiRoute.transaction.updateCredit}`;
    const { data } = yield call(ACC_API.put, query, otherPayload);

    yield put(updateCreditNoteSuccess(data));
    yield put(
      addSnack({
        type: SnackType.Success,
        message: "Successfully update transaction",
        clear: true,
      })
    );
    window.location.replace(`/${type}`);
  } catch (error) {
    const errorMessage = error.response.data.description;
    const errorCode = error.response.data.code;
    yield put(
      addSnack({
        type: SnackType.Error,
        message: errorMessage,
        code: errorCode,
        clear: true,
      })
    );
  }
}

function* handleRetrieveTransactionPaymentOptions({ payload }) {
  try {
    const query = `${ApiRoute.transaction.retrievePaymentOptions}?type=${payload}`;
    const { data } = yield call(ACC_API.get, query);

    yield put(retrieveTransactionPaymentOptionsSuccess(data));
  } catch (error) {
    const errorMessage = error.response.data.description;
    const errorCode = error.response.data.code;
    yield put(
      addSnack({
        type: SnackType.Error,
        message: errorMessage,
        code: errorCode,
        clear: true,
      })
    );
  }
}

function* handleRetrieveCreditNoteOptions({ payload }) {
  try {
    const query = `${ApiRoute.transaction.retrieveCreditOptions}?type=${payload}`;
    const { data } = yield call(ACC_API.get, query);

    yield put(retrieveCreditNoteOptionsSuccess(data));
  } catch (error) {
    const errorMessage = error.response.data.description;
    const errorCode = error.response.data.code;
    yield put(
      addSnack({
        type: SnackType.Error,
        message: errorMessage,
        code: errorCode,
        clear: true,
      })
    );
  }
}
function* handleCreateTransactionPayment({ payload }) {
  const { replaceNav, ...otherPayload } = payload;
  try {
    yield put(toggleDialogBox({ update: { data: null, isOpen: false } }));

    const { data } = yield call(
      ACC_API.post,
      ApiRoute.transaction.createPayment,
      otherPayload
    );
    yield delay(1000);
    yield put(createTransactionPaymentSuccess(data));
    yield put(
      addSnack({
        type: SnackType.Success,
        message: "Successfully create transaction",
        clear: true,
      })
    );
    window.location.replace(`/${replaceNav}`);
  } catch (error) {
    const errorMessage = error.response.data.description;
    yield put(
      addSnack({ type: SnackType.Error, message: errorMessage, clear: true })
    );
    console.error(error.response.data);
  }
}

function* handleCreateCreditNote({ payload }) {
  const { replaceNav, ...otherPayload } = payload;

  try {
    yield put(toggleDialogBox({ update: { data: null, isOpen: false } }));

    const { data } = yield call(
      ACC_API.post,
      ApiRoute.transaction.createCredit,
      otherPayload
    );
    yield delay(1000);
    yield put(createCreditNoteSuccess(data));
    yield put(
      addSnack({
        type: SnackType.Success,
        message: "Successfully create transaction",
        clear: true,
      })
    );
    window.location.replace(`/${replaceNav}`);
  } catch (error) {
    const errorMessage = error.response.data.description;
    yield put(
      addSnack({ type: SnackType.Error, message: errorMessage, clear: true })
    );
    console.error(error.response.data);
  }
}

function* handleRetrieveTransactionPayment({ payload }) {
  try {
    const query = `${ApiRoute.transaction.retrievePayment}?transaction_id=${payload}`;
    const { data } = yield call(ACC_API.get, query);

    yield put(retrieveTransactionPaymentSuccess(data));
  } catch (error) {
    const errorMessage = error.response.data.description;
    const errorCode = error.response.data.code;
    yield put(
      addSnack({
        type: SnackType.Error,
        message: errorMessage,
        code: errorCode,
        clear: true,
      })
    );
  }
}

function* handleRetrieveCreditNote({ payload }) {
  try {
    const query = `${ApiRoute.transaction.retrieveCredit}?transaction_id=${payload}`;
    const { data } = yield call(ACC_API.get, query);

    yield put(retrieveCreditNoteSuccess(data));
  } catch (error) {
    const errorMessage = error.response.data.description;
    const errorCode = error.response.data.code;
    yield put(
      addSnack({
        type: SnackType.Error,
        message: errorMessage,
        code: errorCode,
        clear: true,
      })
    );
  }
}

function* handleRetrieveBankTransaction({ payload }) {
  try {
    const query = `${ApiRoute.bank.retrieve}?transaction_id=${payload}`;
    const { data } = yield call(ACC_API.get, query);

    yield put(retrieveBankTransactionSuccess(data));
  } catch (error) {
    yield put(retrieveBankTransactionError(error));

    const errorMessage = error.response.data.description;
    const errorCode = error.response.data.code;
    yield put(
      addSnack({
        type: SnackType.Error,
        message: errorMessage,
        code: errorCode,
        clear: true,
      })
    );
  }
}

function* handleCreateBankTransaction({ payload }) {
  try {
    const query = `${ApiRoute.bank.create}`;
    const { data } = yield call(ACC_API.post, query, payload);

    yield put(createBankTransactionSuccess(data));
    const bankTransactionType = payload.type;
    yield put(
      addSnack({
        type: SnackType.Success,
        message: `Successful create ${bankTransactionType}`,
        clear: true,
      })
    );
    window.location.replace(`/bank/${bankTransactionType}`);
  } catch (error) {
    yield put(createBankTransactionError(error));

    const errorMessage = error.response.data.description;
    const errorCode = error.response.data.code;
    yield put(
      addSnack({
        type: SnackType.Error,
        message: errorMessage,
        code: errorCode,
        clear: true,
      })
    );
  }
}

function* handleUpdateBankTransaction({ payload }) {
  try {
    const query = `${ApiRoute.bank.update}`;
    const { data } = yield call(ACC_API.put, query, payload);
    const bankTransactionType = payload.type;

    yield put(updateBankTransactionSuccess(data));
    yield put(
      addSnack({
        type: SnackType.Success,
        message: `Successful Updated ${bankTransactionType}`,
        clear: true,
      })
    );

    window.location.replace(`/bank/${bankTransactionType}`);
  } catch (error) {
    yield put(updateBankTransactionError(error));

    const errorMessage = error.response.data.description;
    const errorCode = error.response.data.code;
    yield put(
      addSnack({
        type: SnackType.Error,
        message: errorMessage,
        code: errorCode,
        clear: true,
      })
    );
  }
}

function* handleRetrieveBankTransfer() {
  try {
    const query = `${ApiRoute.bank.retrieveTransfer}`;
    const { data } = yield call(ACC_API.get, query);

    yield put(retrieveBankTransferSuccess(data));
  } catch (error) {
    yield put(retrieveBankTransferError(error));

    const errorMessage = error.response.data.description;
    const errorCode = error.response.data.code;
    yield put(
      addSnack({
        type: SnackType.Error,
        message: errorMessage,
        code: errorCode,
        clear: true,
      })
    );
  }
}

function* handleUpdateBankTransfer({ payload }) {
  try {
    const query = `${ApiRoute.bank.updateTransfer}`;
    const { data } = yield call(ACC_API.put, query, payload);

    yield put(updateBankTransferSuccess(data));
  } catch (error) {
    yield put(updateBankTransferError(error));

    const errorMessage = error.response.data.description;
    const errorCode = error.response.data.code;
    yield put(
      addSnack({
        type: SnackType.Error,
        message: errorMessage,
        code: errorCode,
        clear: true,
      })
    );
  }
}

function* handleBulkCreateTransactions({ payload }) {
  try {
    const { type, ...transactionPayload } = payload;
    const query = `${ApiRoute.transaction.bulkCreate}`;
    const { data } = yield call(ACC_API.post, query, transactionPayload);
    yield put(bulkCreateTransactionsSuccess(data));
    yield put(
      addSnack({
        type: SnackType.Success,
        message: `Successful bulk create`,
        clear: true,
      })
    );
    window.location.replace(type);
  } catch (error) {
    yield put(bulkCreateTransactionsError(error));

    const errorMessage = error.response.data.description;
    const errorCode = error.response.data.code;
    yield put(
      addSnack({
        type: SnackType.Error,
        message: errorMessage,
        code: errorCode,
        clear: true,
      })
    );
  }
}

// function* handleCreateBankTransfer({ payload }) {
//   try {
//     const query = `${ApiRoute.bank.createTransfer}`;
//     const { data } = yield call(ACC_API.post, query, payload);

//     yield put(createBankTransferSuccess(data));
//   } catch (error) {
//     console.log(error);
//     yield put(createBankTransferError(error));

//     const errorMessage = error.response.data.description;
//     const errorCode = error.response.data.code;
//     yield put(
//       addSnack({
//         type: SnackType.Error,
//         message: errorMessage,
//         code: errorCode,
//         clear: true,
//       })
//     );
//   }
// }

function* handleRetrieveBankTransactionOptions({ payload }) {
  try {
    const query = `${ApiRoute.bank.options}?type=${payload}`;
    const { data } = yield call(ACC_API.get, query);
    yield put(retrieveBankTransactionOptionsSuccess(data));
  } catch (error) {
    yield put(retrieveBankTransactionOptionsError(error));
    const errorMessage = error.response.data.description;
    const errorCode = error.response.data.code;
    yield put(
      addSnack({
        type: SnackType.Error,
        message: errorMessage,
        code: errorCode,
        clear: true,
      })
    );
    console.error(error.response.data);
  }
}

function* handleBulkCreatePayments({ payload }) {
  try {
    const { type, ...transactionPayload } = payload;

    const query = `${ApiRoute.transaction.bulkCreatePayments}`;
    const { data } = yield call(ACC_API.post, query, transactionPayload);
    yield put(bulkCreatePaymentsSuccess(data));
    yield put(
      addSnack({
        type: SnackType.Success,
        message: `Successful bulk create`,
        clear: true,
      })
    );
    window.location.replace(type);
  } catch (error) {
    yield put(bulkCreatePaymentsError(error));

    const errorMessage = error.response.data.description;
    const errorCode = error.response.data.code;
    yield put(
      addSnack({
        type: SnackType.Error,
        message: errorMessage,
        code: errorCode,
        clear: true,
      })
    );
    console.error(error.response.data);
  }
}

function* handleRetrieveBankTransferOptions() {
  try {
    const query = `${ApiRoute.bank.transferOptions}`;
    const { data } = yield call(ACC_API.get, query);

    yield put(retrieveBankTransferOptionsSuccess(data));
  } catch (error) {
    yield put(retrieveBankTransferOptionsError(error));

    const errorMessage = error.response.data.description;
    const errorCode = error.response.data.code;
    yield put(
      addSnack({
        type: SnackType.Error,
        message: errorMessage,
        code: errorCode,
        clear: true,
      })
    );
    console.error(error.response.data);
  }
}

function* handleGetShareTransaction({ payload }) {
  try {
    const { transaction_id, form_design_id = null } = payload;
    const formDesignObj = form_design_id
      ? `&form_design_id=${form_design_id}`
      : "";
    const query = `${ApiRoute.transaction.share}`;
    const { data } = yield call(
      ACC_API.get,
      `${query}?transaction_id=${transaction_id}${formDesignObj}`
    );
    yield put(getShareTransactionSuccess(data));
  } catch (error) {
    yield put(getShareTransactionError(error));

    const errorMessage = error.response.data.description;
    const errorCode = error.response.data.code;
    yield put(
      addSnack({
        type: SnackType.Error,
        message: errorMessage,
        code: errorCode,
        clear: true,
      })
    );
    console.error(error.response.data);
  }
}

function* handleSendEmailTransaction({ payload }) {
  try {
    const { data, resetField } = payload;

    const query = `${ApiRoute.transaction.send}`;
    const { result } = yield call(ACC_API.post, query, data);
    resetField();
    yield put(toggleDrawerEditor({ create: { data: null, isOpen: false } }));

    yield put(sendEmailTransactionSuccess(result));
  } catch (error) {
    yield put(sendEmailTransactionError(error));

    const errorMessage = error.response.data.description;
    const errorCode = error.response.data.code;
    yield put(
      addSnack({
        type: SnackType.Error,
        message: errorMessage,
        code: errorCode,
        clear: true,
      })
    );
    console.error(error.response.data);
  }
}

const formItemsTemplate = ["account_id", "descriptions", "amount", "tax"];

const transactionTemplate = (isInvoice) => [
  "customer_id",
  "transaction_no",
  "date",
  ...(isInvoice ? ["due_date"] : []),
  "system_currency_code",
  "effective_rate",
];

const paymentTemplate = [
  "customer_id",
  "transaction_no",
  "reference_no",
  "date",
  "system_currency_code",
  "effective_rate",
  "tag_ids",
  "descriptions",
];

const refundTemplate = [
  "customer_id",
  "transaction_no",
  "date",
  "system_currency_code",
  "effective_rate",
  "tag_ids",
  "descriptions",
];

const transactionPaymentsTemplate = [
  "amount",
  "account_id",
  "fee_amount",
  "fee_account_id",
];

const paymentItemsTemplate = ["transaction_id", "apply_amount"];

const convertDataToRefundTransaction = (data, options, defaultData) => {
  const result = data.reduce((obj, item, index) => {
    if (index < 5 || (index > 5 && index < 8)) {
      const updatedIndex = index > 5 ? index - 1 : index;
      const key = refundTemplate[updatedIndex];
      const itemOptions = options[key];

      if (itemOptions) {
        const getOption = itemOptions.find((option) => {
          if (item) {
            if (
              typeof item === "string" &&
              item?.toLowerCase() &&
              option.label.toLowerCase() === item.toLowerCase()
            ) {
              return true;
            }
            const splitLabel = option.label.split("");
            if (splitLabel[0] === item) {
              return true;
            }
            return false;
          }
        });

        const updatedItem = getOption ? getOption.value : defaultData[key];
        return {
          ...obj,
          [key]: updatedItem,
        };
      }
      if (key === "date") {
        return {
          ...obj,
          [key]: moment(new Date(item)),
        };
      }
      if (key === "effective_rate") {
        const value = item ? item : defaultData[key];
        return {
          ...obj,
          [key]: value,
        };
      }
      return {
        ...obj,
        [key]: item,
      };
    }
    if (index < 6) {
      const key = transactionPaymentsTemplate[index - 6];
      const itemOptions = options[key];

      const previousFormItems = obj.transaction_payments
        ? obj.transaction_payments[0]
        : {};
      if (itemOptions) {
        const getOption = itemOptions.find((option) => {
          const splitLabel =
            typeof option.label === "string"
              ? option.label.split(" ")
              : ["none1"];
          if (typeof item === "string" && splitLabel[0] === item?.toString()) {
            return true;
          }

          return false;
        });
        const updatedItem = getOption ? getOption.value : defaultData[key];
        return {
          ...obj,
          transaction_payments: [
            {
              ...previousFormItems,
              [key]: updatedItem,
              index: 0,
            },
          ],
        };
      }
    }

    if (index < 11) {
      const key = paymentItemsTemplate[index - 8];

      const previousFormItems = obj.form_payments ? obj.form_payments[0] : {};
      if (key === "transaction_id") {
        const itemOptions = options[key];

        const getOption = itemOptions.find((option) => {
          if (
            typeof item === "string" &&
            item?.toLowerCase() &&
            option.transaction_no.toLowerCase() === item.toLowerCase() &&
            previousFormItems.customer_id === option.customer_id
          ) {
            return true;
          }

          return false;
        });

        if (getOption) {
          const { balance, transaction_no, date, customer_id, value } =
            getOption;
          return {
            ...obj,

            //! in handle batch create refunds assign amount with total amount of form payments
            // transaction_payments: [
            //   {
            //     ...obj.transaction_payments[0],
            //     amount: balance,
            //   },
            // ],
            form_payments: [
              {
                ...previousFormItems,
                [key]: value,
                balance: balance,
                transaction_no: transaction_no,
                date: date,
                customer_id: customer_id,
                index: 0,
              },
            ],
          };
        } else {
          const updatedItem = defaultData[key];
          return {
            ...obj,
            form_payments: [
              {
                ...previousFormItems,
                [key]: updatedItem,
                index: 0,
              },
            ],
          };
        }
      }

      const { balance = null } = previousFormItems;
      if (balance !== null) {
        const applyAmountValue = balance < item ? balance : item;
        return {
          ...obj,
          form_payments: [
            {
              ...previousFormItems,
              [key]: applyAmountValue,
              index: 0,
            },
          ],
        };
      }
    }

    return obj;
  }, {});
  return result;
};

const convertDataToPaymentTransaction = (data, options, defaultData) => {
  const result = data.reduce((obj, item, index) => {
    if (index < 6 || (index > 9 && index < 12)) {
      const updatedIndex = index > 9 ? index - 4 : index;
      const key = paymentTemplate[updatedIndex];
      const itemOptions = options[key];

      if (itemOptions) {
        const getOption = itemOptions.find((option) => {
          if (item) {
            if (
              typeof item === "string" &&
              item?.toLowerCase() &&
              option.label.toLowerCase() === item.toLowerCase()
            ) {
              return true;
            }
            const splitLabel = option.label.split("");
            if (splitLabel[0] === item) {
              return true;
            }
          }

          return false;
        });

        const updatedItem = getOption ? getOption.value : defaultData[key];
        return {
          ...obj,
          [key]: updatedItem,
        };
      }
      if (key === "date") {
        return {
          ...obj,
          [key]: moment(new Date(item)),
        };
      }
      if (key === "effective_rate") {
        const value = item ? item : defaultData[key];
        return {
          ...obj,
          [key]: value,
        };
      }
      return {
        ...obj,
        [key]: item,
      };
    }
    if (index < 10) {
      const key = transactionPaymentsTemplate[index - 6];
      const itemOptions = options[key];

      const previousFormItems = obj.transaction_payments
        ? obj.transaction_payments[0]
        : {};
      if (itemOptions) {
        const getOption = itemOptions.find((option) => {
          if (
            typeof item === "string" &&
            item?.toLowerCase() &&
            option.label.toLowerCase() === item.toLowerCase()
          ) {
            return true;
          }
          const splitLabel =
            typeof option.label === "string"
              ? option.label.split(" ")
              : ["none1"];
          if (splitLabel[0] === item?.toString()) {
            return true;
          }
          if (option.value === item) {
            return true;
          }
          return false;
        });
        const updatedItem = getOption ? getOption.value : defaultData[key];
        return {
          ...obj,
          transaction_payments: [
            {
              ...previousFormItems,
              [key]: updatedItem,
              index: 0,
            },
          ],
        };
      }
      return {
        ...obj,
        transaction_payments: [
          {
            ...previousFormItems,
            [key]: item,
            index: 0,
          },
        ],
      };
    }

    if (index < 15) {
      const key = paymentItemsTemplate[index - 12];

      const previousFormItems = obj.form_payments ? obj.form_payments[0] : {};
      if (key === "transaction_id") {
        const itemOptions = options[key];

        const getOption = itemOptions.find((option) => {
          if (
            typeof item === "string" &&
            item?.toLowerCase() &&
            option.transaction_no.toLowerCase() === item.toLowerCase() &&
            previousFormItems.customer_id === option.customer_id
          ) {
            return true;
          }

          return false;
        });

        if (getOption) {
          const { balance, transaction_no, date, customer_id, value } =
            getOption;
          return {
            ...obj,
            form_payments: [
              {
                ...previousFormItems,
                [key]: value,
                balance: balance,
                transaction_no: transaction_no,
                date: date,
                customer_id: customer_id,
                index: 0,
              },
            ],
          };
        } else {
          const updatedItem = defaultData[key];
          return {
            ...obj,
            form_payments: [
              {
                ...previousFormItems,
                [key]: updatedItem,
                index: 0,
              },
            ],
          };
        }
      }

      const { balance = null } = previousFormItems;
      if (balance !== null) {
        const applyAmountValue = balance < item ? balance : item;
        return {
          ...obj,
          form_payments: [
            {
              ...previousFormItems,
              [key]: applyAmountValue,
              index: 0,
            },
          ],
        };
      }
    }

    return obj;
  }, {});
  return result;
};

const covertDataToTransaction = (
  data,
  options,
  defaultData,
  isInvoice = false,
  isBill = false
) => {
  const includedDueDate = isInvoice || isBill;
  const result = data.reduce((obj, item, index) => {
    if (index < (includedDueDate ? 6 : 5)) {
      const key = transactionTemplate(includedDueDate)[index];
      const itemOptions = options[key];

      if (itemOptions) {
        const getOption = itemOptions.find((option) => {
          if (
            typeof item === "string" &&
            item?.toLowerCase() &&
            option.label.toLowerCase() === item.toLowerCase()
          ) {
            return true;
          }
          const splitLabel = option.label.split("");
          if (splitLabel[0] === item) {
            return true;
          }
          return false;
        });

        const updatedItem = getOption ? getOption.value : defaultData[key];

        return {
          ...obj,
          [key]: updatedItem,
        };
      }

      if (key === "date" || key === "due_date") {
        const value = item ? moment(new Date(item)) : defaultData[key];

        if (isInvoice && key === "due_date") {
          return {
            ...obj,
            [key]: value,
            payment_terms: [
              {
                index: 0,
                due_on: value,
              },
            ],
          };
        }

        return {
          ...obj,
          [key]: value,
        };
      }

      if (key === "effective_rate") {
        const value = item ? item : defaultData[key];
        return {
          ...obj,
          [key]: value,
        };
      }
      return {
        ...obj,
        [key]: item,
      };
    }
    if (index < (includedDueDate ? 10 : 9)) {
      const key = formItemsTemplate[index - (includedDueDate ? 6 : 5)];
      const itemOptions = options[key];

      const previousFormItems = obj.form_items ? obj.form_items[0] : {};
      if (itemOptions) {
        const getOption = itemOptions.find((option) => {
          if (item) {
            if (
              typeof item === "string" &&
              item?.toLowerCase() &&
              option.label.toLowerCase() === item.toLowerCase()
            ) {
              return true;
            }
            const splitLabel =
              typeof option.label === "string"
                ? option.label.split(" ")
                : ["none1"];

            if (splitLabel[0] === item?.toString()) {
              return true;
            }
            if (option.value === item) {
              return true;
            }
          }

          return false;
        });
        const updatedItem = getOption ? getOption.value : defaultData[key];
        return {
          ...obj,
          form_items: [
            {
              ...previousFormItems,
              [key]: updatedItem,
              index: 0,
            },
          ],
        };
      }

      const transactionPaymentAmount =
        isInvoice && key === "amount"
          ? {
              payment_terms: [
                {
                  ...obj.payment_terms[0],
                  amount: item,
                },
              ],
            }
          : {};
      return {
        ...obj,
        ...transactionPaymentAmount,
        form_items: [
          {
            ...previousFormItems,
            [key]: item,
            index: 0,
          },
        ],
      };
    }
    return obj;
  }, {});

  return result;
};

const convertDataToFormPayments = (data, options, defaultData, customer_id) => {
  const result = data.reduce((obj, item, index) => {
    if (index > 11) {
      const key = paymentItemsTemplate[index - 12];

      if (key === "transaction_id") {
        const itemOptions = options[key];
        const getOption = itemOptions.find((option) => {
          if (
            typeof item === "string" &&
            item?.toLowerCase() &&
            option.transaction_no.toLowerCase() === item.toLowerCase() &&
            customer_id === option.customer_id
          ) {
            return true;
          }

          return false;
        });

        if (getOption) {
          const { balance, transaction_no, date, customer_id, value } =
            getOption;
          return {
            ...obj,
            [key]: value,
            balance: balance,
            transaction_no: transaction_no,
            date: date,
            customer_id: customer_id,
          };
        } else {
          const updatedItem = defaultData[key];
          return {
            ...obj,
            [key]: updatedItem,
          };
        }
      }

      const { balance = null } = obj;
      if (balance !== null) {
        const applyAmountValue = balance < item ? balance : item;
        return {
          ...obj,
          [key]: applyAmountValue,
        };
      }
    }
    return obj;
  }, {});
  return result;
};

const convertDataToFormItem = (
  data,
  options,
  defaultData,
  isInvoice = false,
  isBill = false
) => {
  const includedDueDate = isInvoice || isBill;
  const result = data.reduce((obj, item, index) => {
    if (index > (includedDueDate ? 5 : 4)) {
      const key = formItemsTemplate[index - (includedDueDate ? 6 : 5)];
      const itemOptions = options[key];
      if (itemOptions) {
        const getOption = itemOptions.find((option) => {
          if (item) {
            if (
              typeof item === "string" &&
              item?.toLowerCase() &&
              option.label.toLowerCase() === item.toLowerCase()
            ) {
              return true;
            }
            const splitLabel =
              typeof option.label === "string"
                ? option.label.split(" ")
                : ["none1"];

            if (splitLabel[0] === item.toString()) {
              return true;
            }
            if (option.value === item) {
              return true;
            }
          }

          return false;
        });
        const updatedItem = getOption ? getOption.value : defaultData[key];
        return {
          ...obj,
          [key]: updatedItem,
        };
      }
      return {
        ...obj,
        [key]: item,
      };
    }
    return obj;
  }, {});
  return result;
};

const convertFileData = (
  papa,
  options,
  defaultData,
  isConvertPayment = false,
  isConvertRefund = false,
  isBill = false,
  isInvoice = false
) => {
  const fileData = papa.data;

  const result = fileData.reduce((array, data) => {
    if (data[0] !== "#") {
      const removedFirstIndexData = data.slice(1);
      if (data[1] !== null) {
        const result = isConvertPayment
          ? convertDataToPaymentTransaction(
              removedFirstIndexData,
              options,
              defaultData
            )
          : isConvertRefund
          ? convertDataToRefundTransaction(
              removedFirstIndexData,
              options,
              defaultData
            )
          : covertDataToTransaction(
              removedFirstIndexData,
              options,
              defaultData,
              isInvoice,
              isBill
            );

        return [...array, result];
      } else {
        const lastTransaction = array[array.length - 1];
        const restOfTransaction = array.slice(0, -1);
        const formItem =
          isConvertPayment || isConvertRefund
            ? convertDataToFormPayments(
                removedFirstIndexData,
                options,
                defaultData,
                lastTransaction.customer_id
              )
            : convertDataToFormItem(
                removedFirstIndexData,
                options,
                defaultData,
                isInvoice,
                isBill
              );

        if (isConvertPayment || isConvertRefund) {
          lastTransaction.form_payments.push({
            ...formItem,
            index: lastTransaction.form_payments.length,
          });
        }

        if (!isConvertPayment && !isConvertRefund) {
          lastTransaction.form_items.push({
            ...formItem,
            index: lastTransaction.form_items.length,
          });
        }
        return [...restOfTransaction, lastTransaction];
      }
    }
    return array;
  }, []);
  return result;
};

function* handleImportTransactions({ payload }) {
  try {
    const {
      csvFile,
      setValue,
      options,
      defaultData,
      isConvertPayment = false,
      isConvertRefund = false,
      isInvoice = false,
      isBill = false,
    } = payload;

    const convertingData = yield call(
      () =>
        new Promise((resolve, reject) => {
          Papa.parse(csvFile, {
            header: false,
            dynamicTyping: true,
            skipEmptyLines: true,
            complete: function (results) {
              const result = convertFileData(
                results,
                options,
                defaultData,
                isConvertPayment,
                isConvertRefund,
                isBill,
                isInvoice
              );
              resolve(result);
            },
            error: function (err) {
              reject(err);
            },
          });
        })
    );

    if (convertingData) {
      setValue(convertingData);
      yield put(toggleDrawerEditor({ create: { data: null, isOpen: false } }));
      yield put(importTransactionsSuccess(convertingData));
    }

    // const { result } = yield call(ACC_API.post, query, data);
    // yield put(toggleDrawerEditor({ create: { data: null, isOpen: false } }));

    // yield put(sendEmailTransactionSuccess(result));
  } catch (error) {
    yield put(importTransactionsError(error));

    const errorMessage = error.response.data.description;
    const errorCode = error.response.data.code;
    yield put(
      addSnack({
        type: SnackType.Error,
        message: errorMessage,
        code: errorCode,
        clear: true,
      })
    );
    console.error(error.response.data);
  }
}

export function* handleImportRefunds({ payload }) {
  try {
    const { csvFile, setValue, options, defaultData } = payload;

    const convertingData = yield call(
      () =>
        new Promise((resolve, reject) => {
          Papa.parse(csvFile, {
            header: false,
            dynamicTyping: true,
            skipEmptyLines: true,
            complete: function (results) {
              const result = convertFileData(results, options, defaultData);
              resolve(result);
            },
            error: function (err) {
              reject(err);
            },
          });
        })
    );

    if (convertingData) {
      setValue(convertingData);
      yield put(toggleDrawerEditor({ create: { data: null, isOpen: false } }));
      yield put(importTransactionsSuccess(convertingData));
    }

    // const { result } = yield call(ACC_API.post, query, data);
    // yield put(toggleDrawerEditor({ create: { data: null, isOpen: false } }));

    // yield put(sendEmailTransactionSuccess(result));
  } catch (error) {
    yield put(importTransactionsError(error));

    const errorMessage = error.response.data.description;
    const errorCode = error.response.data.code;
    yield put(
      addSnack({
        type: SnackType.Error,
        message: errorMessage,
        code: errorCode,
        clear: true,
      })
    );
    console.error(error.response.data);
  }
}

export function* handleOpenShareDialog({ payload }) {
  try {
    const { share, resetForm } = payload;
    yield put(toggleDialogBox({ share: share }));
    resetForm();
    yield put(openShareTransactionDialogSuccess());
  } catch (error) {
    yield put(openShareTransactionDialogError(error));
  }
}

export function* transactionSagaWatcher() {
  yield takeLatest(retrieveAllTransactions.type, handleRetrieveTransactions);
  yield takeLatest(createTransaction.type, handleCreateTransaction);
  yield takeLatest(
    retrieveTransactionOptions.type,
    handleRetrieveTransactionOptions
  );
  yield takeLatest(retrieveTransaction.type, handleRetrieveTransaction);
  yield takeLatest(removeTransaction.type, handleRemoveTransaction);
  yield takeLatest(voidTransaction.type, handleVoidTransaction);

  yield takeLatest(updateTransaction.type, handleUpdateTransaction);
  yield takeLatest(
    retrieveTransactionPaymentOptions.type,
    handleRetrieveTransactionPaymentOptions
  );

  yield takeLatest(
    createTransactionPayment.type,
    handleCreateTransactionPayment
  );

  yield takeLatest(
    retrieveTransactionPayment.type,
    handleRetrieveTransactionPayment
  );

  yield takeLatest(retrieveCreditNote.type, handleRetrieveCreditNote);
  yield takeLatest(
    retrieveCreditNoteOptions.type,
    handleRetrieveCreditNoteOptions
  );

  yield takeLatest(updateCreditNote.type, handleUpdateCreditNote);

  yield takeLatest(createCreditNote.type, handleCreateCreditNote);

  yield takeLatest(
    updateTransactionPayment.type,
    handleUpdateTransactionPayment
  );

  yield takeLatest(retrieveBankTransaction.type, handleRetrieveBankTransaction);

  yield takeLatest(createBankTransaction.type, handleCreateBankTransaction);

  yield takeLatest(updateBankTransaction.type, handleUpdateBankTransaction);

  yield takeLatest(retrieveBankTransfer.type, handleRetrieveBankTransfer);

  // yield takeLatest(createBankTransfer.type, handleCreateBankTransfer);

  yield takeLatest(updateBankTransfer.type, handleUpdateBankTransfer);
  yield takeLatest(
    retrieveBankTransactionOptions,
    handleRetrieveBankTransactionOptions
  );
  yield takeLatest(
    retrieveBankTransferOptions,
    handleRetrieveBankTransferOptions
  );
  yield takeLatest(bulkCreateTransactions, handleBulkCreateTransactions);
  yield takeLatest(getShareTransaction, handleGetShareTransaction);
  yield takeLatest(sendEmailTransaction, handleSendEmailTransaction);

  yield takeLatest(importTransactions.type, handleImportTransactions);

  yield takeLatest(bulkCreatePayments.type, handleBulkCreatePayments);

  yield takeLatest(openShareTransactionDialog, handleOpenShareDialog);
}
