import React from "react";
import './C_Loading_Login.scss'

const C_Loading_Login = (props) => {
    return (
        <div className="loading-container-login" style={{ display: props.isLoading ? "flex" : "none" }}>
            <img src={require("../assets/img/loginLoadingGif.gif")} alt=''/>
            <span>Loading...</span>
        </div>
    )
}

export default C_Loading_Login;