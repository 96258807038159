import React, {
    Component,
    useState,
    useEffect,
    useRef,
    useCallback,
} from "react";
import './EmailVerification.scss';
import moment from "moment";

import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { get, ref, remove, set, update } from 'firebase/database'
import { db } from '../firebaseInit';
import { v4 } from "uuid";
import { BsCheck2Circle, BsFillArrowRightCircleFill } from "react-icons/bs";
//slices
import { sendSignupConfirmationMail, sendTenantNotificationMail, sendVerificationMail } from "../stores/slices/tenantSlices";
import { createTenantAgents } from "../stores/slices/tenantAgentSlices";
import { createCompanyAgent } from "../stores/slices/companyAgentSlices";
import { selectUser } from "../stores/slices/userAuthSlices";

function EmailVerification() {

    const user = selectUser();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isSend,setIsSend] = useState(false);
    const [isEmailVerified,setIsEmailVerified] = useState(false);
    const [errMsg,setErrMsg] = useState("");
    const [verificationCode, setVerificationCode] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [uid, setUid] = useState("");

    // const [curr]
    const dbRef = ref(db);

    useEffect(()=>{
        if(Array.from(searchParams).length < 1){
            navigate("/Auth");
        } else {
            if(searchParams.get("code") !== null && searchParams.get("code") !== undefined){

                setVerificationCode(searchParams.get("code"));
            } 
            
            if(searchParams.get("email") !== null && searchParams.get("email") !== undefined){
                setEmail(searchParams.get("email"));
            }

            if(searchParams.get("name") !== null && searchParams.get("name") !== undefined){
                setName(searchParams.get("name"));
            }

            if(searchParams.get("uid") !== null && searchParams.get("uid") !== undefined){
                setUid(searchParams.get("uid"));
            }
        }
    }, [searchParams])

    const verifyEmail = async () => {
        if(verificationCode === ""){
            setErrMsg("Code must not be empty");
            return;
        }

        setErrMsg("")
        setIsSend(true);
        let isInvited;
        let newUserObj;
        let invitationData;
        const userID = uid;
        await get(ref(db, 'unverifiedUsers/' + userID)).then(async (snapshot) => {
            //check if data already created in db
            if (snapshot.exists()) {
                // Here empty
                let userObj = snapshot.val();
                let displayName = userObj.displayName;
                let email = userObj.email;
                let createdDate;
                let code = userObj.code;
                newUserObj = Object.keys(userObj).filter(key =>
                    key !== 'invitedData' && key !== 'isInvited').reduce((obj, key) =>
                    {
                        obj[key] = userObj[key];
                        return obj;
                    }, {}
                );
                isInvited = userObj.isInvited
                // "SQL company agent data"
                if(isInvited){
                    invitationData = userObj.invitedData;
                }
                setTimeout(async ()=>{
                    if(code.toString() === verificationCode.toString()){
                        console.log("verified");
                        await set(ref(db, 'users/' + `${userID}`), newUserObj)
                        .then(async ()=>{

                            await remove(ref(db,'unverifiedUsers/' + `${userID}`))

                            if(isInvited){
                                const TenantID = invitationData.TenantID;
                                const UserID = userID;
                                const CompanyID = invitationData.CompanyID;
                                const MailID = invitationData.MailID;
                                const RoleID = invitationData.RoleID;
                                const CompanyName = invitationData.CompanyName
                                const createdAt = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
                                const hostEmail = invitationData.hostEmail;
                                const userEmail = invitationData.userEmail;

                                dispatch(createTenantAgents({TenantAgentID:v4(), TenantID:TenantID, UserID:UserID, CreatedAt:createdAt}))
                                await remove(ref(db, 'invitationMail/' + `${TenantID}/` + `${CompanyID}/` + MailID))
                                .then(()=>{
                                    const companyAgentData = {
                                        CompanyAgentID: v4(),
                                        CompanyID: CompanyID,
                                        UserID: UserID,
                                        RoleID: RoleID,
                                        CreatedAt: createdAt
                                    }

                                    //these dispatch can work or not
                                    dispatch(createCompanyAgent({TenantID:TenantID, data:companyAgentData}))
                                    dispatch(sendTenantNotificationMail({hostEmail:hostEmail, userEmail:userEmail, companyName:CompanyName, createdAt:createdAt}))

                                    dispatch(sendSignupConfirmationMail({ requestTenant:false, displayName, email:userEmail, createdAt })); 
                                    setIsEmailVerified(true);
                                    setIsSend(false);
                                    
                            })
                            } else {
                                createdDate = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
                                dispatch(sendSignupConfirmationMail({ requestTenant:false, displayName, email, createdAt: createdDate }));
                                setIsEmailVerified(true);
                                setIsSend(false);
                            }
                            
                        })
                        .catch((err)=>{
                            console.log(err)
                        })
                    } else {
                        console.log("not verified");
                        setIsEmailVerified(false);
                        setIsSend(false)
                        setErrMsg("Invalid code, please try again.")
                    }
                }, 3000)
            }

        }).catch((error) => {
            console.error(error);
        });

    }
    const resendVerifyEmail = () => {
        setErrMsg("")
        setIsSend(true);
        const code = Math.floor(100000 + Math.random() * 900000)
        const userEmail = user === null ? email : user.email;
        const displayName = user === null ? name : user.displayName;
        const userId =  uid;

        dispatch(sendVerificationMail({uid: userId, email: userEmail, name: displayName, code: code}));
        update(ref(db, 'unverifiedUsers/' + userId), {
            code: code
        })
        setTimeout(()=>{
            setIsSend(false);
        }, 2000)
    }

    return (
        <div className='send-verification-main-container'>
            <img className="zumax" src={require('../assets/img/zumax-logo-black.webp')} alt=""/>
            <div className="send-verification-container">
                <div className="header">
                    <img src={require('../assets/img/verifyemail.png')} alt=""/>
                    {errMsg === "" ?
                    <>
                    {(!isSend && !isEmailVerified) &&
                    <> 
                        <span style={{width:"50%"}}>A verification code is sent to {email}. <br/>Please verify your email before you proceed to CRM.</span>
                    </>
                    }
                    {(isSend && !isEmailVerified)  && <span style={{width:"50%"}}>Processing ...</span>}
                    {(!isSend && isEmailVerified)  && <span style={{width:"50%"}}>Email verification successful! You may now proceed to the CRM. <BsCheck2Circle size={25} style={{color:'green'}}/></span>}
                    </>
                    :
                    <span style={{width:"50%"}}>{errMsg}</span>
                    }
                </div>
                <div className="send-container">
                    {errMsg === "" ?
                    <>
                    {(!isSend && !isEmailVerified) && <div className="verify-container">
                        <div className="input-verification">
                            <input className="input-text" type="text" value={verificationCode} onChange={(e)=>{setVerificationCode(e.target.value)}} />
                        </div>
                        <div className="button" style={{width:"100%"}} onClick={() => {verifyEmail();}}>
                            Verify Your Email <BsFillArrowRightCircleFill/>
                        </div>
                    </div>}
                    {(isSend && !isEmailVerified) && <div className={`py-1 loadingContainer ${!isSend && 'hideLoadingContainer'}`}>
                        <div className="loading-spinner">
                        </div>
                    </div>
                    }
                    {(!isSend && isEmailVerified) && <div className="button" style={{width:"30%"}} onClick={() => {navigate("/Auth")}}>Continue <BsFillArrowRightCircleFill/></div>}
                    </>
                    :
                    <>
                    {(!isSend && !isEmailVerified) && <div className="verify-container">
                        <div className="input-verification">
                            <input className="input-text" type="text" value={verificationCode} onChange={(e)=>{setVerificationCode(e.target.value)}} />
                        </div>
                        <div className="button" style={{width:"100%"}} onClick={() => {verifyEmail();}}>
                            Verify Your Email <BsFillArrowRightCircleFill/>
                        </div>
                    </div>}
                    </>
                    }
                </div>
                {(!isSend && !isEmailVerified) &&
                    <div className="header"> 
                        <span style={{width:"50%"}}>
                        Haven't received any emails yet? Click <span style={{textDecoration:'underline',cursor:'pointer',color:'blue'}} onClick={()=>{resendVerifyEmail();}}>here</span> to resend.
                        <br/>
                        Please ensure that you have also checked your "junk mail" and "spam".</span>
                    </div>
                }
            </div>
        </div>
    )
}

export default EmailVerification;