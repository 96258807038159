//react
import React, { useEffect, useState, useRef } from 'react';

//redux
import { useDispatch } from 'react-redux';

//components
import Colors from '../../constants/Colors';
import C_Button from '../../components/C_Button';

//libraries
import { v4 } from "uuid";
import { Modal } from "bootstrap";

//react icon
import { AiOutlineDelete, AiOutlineClose, AiFillPlusCircle, AiFillSave } from "react-icons/ai";
import { BiDuplicate } from "react-icons/bi";
import { MdArrowBack } from "react-icons/md";

import C_DialogButton from '../../components/C_DialogButton';
import { HiOutlineTrash } from 'react-icons/hi2';
import C_PopoutNoti from '../../components/C_PopoutNoti';

import './ClientGroup.scss'
import { FaUsers } from 'react-icons/fa';
import ClientGroupDisplay from './ClientGroup.display';
import ClientGroupManageTable from './ClientGroup.ManageTable';
import ClientGroupIntegration from './ClientGroup.integration';

//slices
import { retrieveCurrUsage } from '../../stores/slices/usageSlices';
import { retrieveAllGroup, selectAllClientsGroup, selectClientContext, selectRetrieveAllGroupLoading, setClientContext } from '../../stores/slices/clientSlices';
import { createClientGroups, deleteClientGroups, retrieveClientGroups, selectClientGroups, selectCreateClientGroupsLoading, updateClientGroups } from '../../stores/slices/clientGroupSlices';
import { selectTenant } from '../../stores/slices/tenantSlices';
import { selectCompanyID } from '../../stores/slices/companyIDSlices';
import { selectCurrTenant } from '../../stores/slices/persistRolesSlices';
import moment from 'moment';
import { selectCurrUserContext } from '../../stores/slices/companySlices';
import ClientGroupPermission from './ClientGroup.permission';

function ClientGroup(props) {

  const handleBack = props.handleBack;
  const reminderRef = useRef();

  const dispatch = useDispatch();

  const {
    selectedGroup, 
  } = selectClientContext();
  const { currUsage, currTotalUsage, currUserInvitation } = selectCurrUserContext();
  
  const tenant = selectCurrTenant();
  const companyID = selectCompanyID();
  
  const [loadData, setLoadData] = useState(true);
  const [triggerSaved, setTriggerSaved] = useState("");
  const [selectedTab, setSelectedTab] = useState("DISPLAY");
  const [groupID,setGroupID] = useState("");
  const [group, setGroup] = useState(null);
  const [groupTab,setGroupTab] = useState("");
  const [action,setAction] = useState("");

  const [clientGroupQuota, setClientGroupQuota] = useState(0);
  const [clientGroupUsage, setClientGroupUsage] = useState(0);

  const retrieveAllGroupLoading = selectRetrieveAllGroupLoading();
  // const allClientsGroup = selectAllClientsGroup();
  const allClientsGroup = selectClientGroups();

  const createClientGroupsLoading = selectCreateClientGroupsLoading();

  useEffect(() => {
    if(currUsage && currTotalUsage !== undefined && currTotalUsage && currUserInvitation){
      const filterQuota = currUsage?.find(item => item.SectionID === "s5")?.Quota;
      const filterUsage = currTotalUsage?.find(item => item.SectionID === "s5")?.Usage;
      setClientGroupQuota(filterQuota);
      setClientGroupUsage(filterUsage);
    }else{
      setClientGroupQuota(0);
      setClientGroupUsage(0);
    }
  },[currUsage, currTotalUsage, currUserInvitation])

  // useEffect(()=>{
  //   if(!createClientGroupsLoading && allClientsGroup){
  //     const lastIndex = allClientsGroup.length - 1;
  //     setSelectedGroup({type: "SELECT_CLIENT_GROUP", payload: allClientsGroups[lastIndex]});
  //   }
  // },[createClientGroupsLoading, allClientsGroup])

  async function refreshUsage(){
    dispatch(retrieveCurrUsage({TenantID:tenant,CompanyID:companyID}));
  };

  const showModalFunc = () => {
      const modalEle = reminderRef.current;
      const bsModal = new Modal(modalEle, {
        backdrop: "static",
        keyboard: false,
      });
      bsModal.show();
  };

  const hideModalFunc = () => {
      const modalEle = reminderRef.current;
      const bsModal = Modal.getInstance(modalEle);
      bsModal.hide();
  };

  function notify(noti){
    setTriggerSaved(noti)
    setTimeout(() => {
      setTriggerSaved("")
    }, 2000)
  }

  useEffect(() => {

    if(tenant){
      dispatch(retrieveClientGroups({ TenantID:tenant,CompanyID: companyID }));
      setLoadData(false);
    }
  }, [companyID]);

  const deleteModalRef = useRef();

  const showDeleteModalFunc = () => {
    const modalEle = deleteModalRef.current;
    const bsModal = new Modal(modalEle, {
      backdrop: "static",
      keyboard: false,
    });
    bsModal.show();
  };

  const hideDeleteModalFunc = () => {
    const modalEle = deleteModalRef.current;
    const bsModal = Modal.getInstance(modalEle);
    bsModal.hide();
  };

  function handleSelectTab(tab){
    if(selectedTab === "MANAGE_TABLE" && tab !== "MANAGE_TABLE") {
      setAction("tab")
      setGroupTab(tab);
      // showModalFunc()
    } else {
      setSelectedTab(tab)
    }
  };

  function handleSelectDisplayTab(tab){
      setAction("tab")
      setSelectedTab(tab)
  }

  function handleSelectTabAtColumn(tab){
      setSelectedTab(tab)
  };

  function handleSelectGroup(item){
    if(selectedTab === "MANAGE_TABLE")
    {
      showModalFunc();
      setAction("group")
      setGroupID(item.GroupID)
      setGroup(item);
    }
    else
    {
      setSelectedTab("DISPLAY")
      dispatch(setClientContext({
        selectedGroup : item, 
        selectedGroupID : item.GroupID,
        
      }));
      
    }

  }

  function handleAddGroup(){
    
    const defaultColumn = [{
      "columnID":"eeff6fec-d8ff-47ea-9c59-21603d808014",
      "columnName":"ClientName",
      "columnType":"Short Text",
      "width":"25%",
      "required":false,
      "duplicate":true,
      "privilege":"Editable",
      "columnIndex":0,
      "isDefault":true
    }]

    const clientGroupData = {
      GroupID: v4(),
      CompanyID: companyID,
      GroupName: "New Group",
      GroupColor: "#919191",
      ColumnData: JSON.stringify(defaultColumn)
    }

      dispatch(createClientGroups({TenantID:tenant,data:clientGroupData}))
      refreshUsage();
      dispatch(retrieveClientGroups({ TenantID:tenant,CompanyID: companyID }));
   
  }

  function cloneGroup(clone){

    const clientGroupData = {
      GroupID: v4(),
      CompanyID: clone.CompanyID,
      GroupName: `${clone.GroupName} (copy)`,
      GroupColor: clone.GroupColor,
      ColumnData: clone.ColumnData
    }

    dispatch(createClientGroups({TenantID:tenant,data:clientGroupData}))
      refreshUsage();
      dispatch(retrieveClientGroups({ TenantID:tenant,CompanyID: companyID }))

  }

  function handleUpdateGroup(){
    const data = {
      ...selectedGroup, 
      CreatedAt : moment(selectedGroup.CreatedAt).format("YYYY-MM-DD HH:mm:ss"),
    }
    dispatch(updateClientGroups({TenantID:tenant,data:data}))
    dispatch(setClientContext({selectedGroup : selectedGroup}));
    setTimeout(()=>{
      notify("UPDATED GROUP");
    },500)
    
  };

  function handleDeleteGroup(){
    const GroupID = selectedGroup?.GroupID;
    dispatch(deleteClientGroups({ TenantID:tenant, GroupID:GroupID}));
    hideDeleteModalFunc();
    refreshUsage();
    dispatch(setClientContext({selectedGroup : null, selectedGroupID : null}));
    // setSelectedGroupID({type: "SELECT_CLIENT_GROUP_ID", payload: null});
    // setSelectedGroup({type: "SELECT_CLIENT_GROUP", payload: null});
 
  };

  // console.log(clientGroupUsage)
  // console.log(clientGroupQuota)

  return (
    <>
      <div className="modal fade" tabIndex="-1" ref={deleteModalRef}>
        <div className="modal-dialog modal-dialog-centered" style={{}}>
          <div className="modal-content px-3" style={{overflowY: "scroll" }}>
              <div className="modal-header modalHeader">
                  <div className="title">
                    <AiOutlineDelete size={17} color={"#ca4254"}/>
                    <span className="font-s">Delete Group</span>
                  </div>
                  <AiOutlineClose cursor='pointer' size={16} onClick={()=>{
                    hideDeleteModalFunc();
                  }}/>
              </div>
              <div className="modal-body">
                <div className="" style={{ display: 'flex', flexDirection: 'column' }}>
                  <span className="font-xs" style={{ fontWeight: "bold" }}>
                      Are you sure to delete this group? This action can not be reverted. 
                  </span>
                  <span
                    className="font-xs"
                    style={{
                      fontWeight: "bold",
                      color: Colors.redButton,
                    }}>
                    Clients data of this group will be removed by deleting this group.
                  </span>
                </div>
                
                <div
                  style={{ display: "flex", justifyContent: "flex-end",columnGap:'20px' }}
                  className="mt-3 modalshit"
                >
                  <C_DialogButton 
                    color={'#ca4254'}
                    background={'white'}
                    borderColor={'#ca4254'}
                    buttonText={"DISCARD"}
                    onClick={() => { hideDeleteModalFunc() }}
                  />
                  <C_DialogButton 
                    color={"white"}
                    background={'#ca4254'}
                    borderColor={'#ca4254'}
                    buttonText={"DELETE"}
                    onClick={() => { handleDeleteGroup() }}
                  />
                </div>
            </div>
          </div>
        </div>
      </div>

      <div className='clientGroupMainContainer'>

      <C_PopoutNoti triggerSaved={triggerSaved}/>

      <div className="headerContainer">
        <div className="backContainer" onClick={handleBack}>
          <MdArrowBack className="backIcon" size={25}/>
        </div>
        <div className="titleContainer">
          <FaUsers size={16}/>
          <span>Manage Client Group & Table</span>
        </div>
      </div>

      <div className="ClientGroupSubContainer">
        <div className="clientGroupListMainContainer">
          <div className="newClientGroupActionContainer">
            {clientGroupUsage < clientGroupQuota ?
              <C_Button
                paddingLeft={"5px"}
                paddingRight={"5px"}
                buttonText={"New Group"}
                background={"#3eb986"}
                onClick={() => {
                  handleAddGroup();
                }}
                justify={"center"}
                textColor={"#FFFFFF"}
                gap={"10px"}
                icon={<AiFillPlusCircle size={16} />}
              />
              :
              <div className="quotaRestrictedContainerNoPadding">
                <span>You've reached the limit, <br/><a href="https://zumaxdigital.com/crm-v1/" target="_blank" className="link">upgrade now!</a></span>
              </div>
            }
          </div>

          {loadData &&
            <div className='loadingClientGroupList'>
              <div className='blinkblink'>

              </div>
            </div>
          }
            
          {!loadData &&
            <div className="clientGroupListContainer">
              {allClientsGroup && allClientsGroup?.length > 0 ?
               allClientsGroup?.map((item, index) => {
                return(
                  <div key={index} className={`currClientGroupContainer ${selectedGroup?.GroupID === item?.GroupID && 'selectedClientGroup'}`}
                    onClick={() => {
                      handleSelectGroup(item);
                  }}>
                    <div className="currClientGroupInfoContainer">
                      <div className="color" style={{ backgroundColor: item?.GroupColor }}></div>
                      <span title={item?.GroupName}>{item?.GroupName}</span>
                    </div>
                    {selectedGroup?.GroupID === item?.GroupID && clientGroupUsage < clientGroupQuota &&
                      <div className='IconContainer'>
                        <BiDuplicate className="icon" size={16} color={"#3eb986"} onClick={() => {
                            cloneGroup(item);
                          }}/>
                      </div>
                    }
                    {selectedGroup?.GroupID === item?.GroupID &&
                      <div className='IconContainer'>
                        <HiOutlineTrash className="icon" size={16} color={"#ca4254"} onClick={() => {
                          showDeleteModalFunc();
                        }}/>
                      </div>
                    }
                  </div>
                )
              }) 
              :
              <div className='emptyClientGroup'>
                <div className='emptyContent'>
                  <span><strong>0 Group</strong></span>
                  <span><small>Get started by creating group</small></span>
                </div>
              </div>
              }
            </div>
          }

        </div>

        <div className="pageSelectionMainContainer">
          {selectedGroup ?
          <div className="pageSelectionSubContainer">
            <div className="pageSelectionHeaderContainer">
              <div className={`tab ${selectedTab === "DISPLAY" && 'selectedTab'}`} onClick={() => {
                // handleSelectTab("DISPLAY");
                handleSelectDisplayTab("DISPLAY")
              }}>
                <span>Display</span>
              </div>
              <div className={`tab ${selectedTab === "PERMISSION" && 'selectedTab'}`} onClick={() => {
                // handleSelectTab("DISPLAY");
                handleSelectDisplayTab("PERMISSION")
              }}>
                <span>Permission</span>
              </div>
              <div className={`tab ${selectedTab === "MANAGE_TABLE" && 'selectedTab'}`} onClick={() => {
                handleSelectTab("MANAGE_TABLE");
              }}>
                <span>Manage Table</span>
              </div>
              {/* <div className={`tab ${selectedTab === "INTEGRATION" && 'selectedTab'}`} onClick={() => {
                handleSelectTab("INTEGRATION");
              }}>
                <span>Integration</span>
              </div> */}
            </div>
            
            {selectedTab === "DISPLAY" && <ClientGroupDisplay/>}
            {selectedTab === "MANAGE_TABLE" && <ClientGroupManageTable action={action} reminderRef={reminderRef} groupID={groupID} group={group} hideModal={hideModalFunc} groupTab={groupTab} handleSelectTab={handleSelectTabAtColumn}/>}
            {selectedTab === "INTEGRATION" && <ClientGroupIntegration groupID={selectedGroup?.GroupID} companyID={companyID} tenantID={tenant}/>}
            {selectedTab === "PERMISSION" && <ClientGroupPermission groupID={selectedGroup?.GroupID} companyID={companyID} tenantID={tenant}/>}
            {(selectedTab !== "MANAGE_TABLE" && selectedTab !== "INTEGRATION") &&
              <div className={`clientGroupMainActionContainer ${selectedTab === "DISPLAY" && 'showActionContainer'}`}>
                <div className="saveContainer">
                  <span className="text">Remember to save your changes!</span>
                    <C_Button
                      buttonText={"Save Changes"}
                      background={"#6d45b9"}
                      width="150px"
                      onClick={() => {
                        handleUpdateGroup()
                      }}
                      justify={"center"}
                      textColor={"#FFFFFF"}
                      gap={"10px"}
                      icon={<AiFillSave size={16} />}
                    />
                </div>
              </div>
            }
          </div>
      
          :
          <div className="pageSelectionEmptyContainer">
              <img src={require('../../assets/analyticsGif.gif')} alt=''/>
              <span></span>
          </div>
          }
        </div>
      </div>
    </div>

    </>
  )

}

export default ClientGroup