import { call, delay, put, takeLatest } from "redux-saga/effects";
import { 
    setCurrUser, 
    setCurrUserError, 
    setCurrUserSuccess, 
    setHeaderTitle, 
    setHeaderTitleError, 
    setHeaderTitleSuccess, 
    setLoginState, 
    setLoginStateSuccess, 
    setUserID, 
    setUserIDError, 
    setUserIDSuccess, 
    setUserName, 
    setUserNameError, 
    setUserNameSuccess 
} from "../stores/slices/authSlices";

function* setHeaderTitleSaga({payload}) {
    try{
        yield put(setHeaderTitleSuccess(payload));
        console.log("Retrieve Test Success data:", data);
    } catch (error){
        yield put(setHeaderTitleError(error));
        console.error(error);
    }
}

function* setCurrUserSaga({payload}) {
    try{
        yield put(setCurrUserSuccess(payload));
        console.log("Retrieve Test Success data:", data);
    } catch (error){
        yield put(setCurrUserError(error));
        console.error(error);
    }
}

function* setUserIDSaga({payload}) {
    try{
        yield put(setUserIDSuccess(payload));
        console.log("Retrieve Test Success data:", data);
    } catch (error){
        yield put(setUserIDError(error));
        console.error(error);
    }
}

function* setUserNameSaga({payload}) {
    try{
        yield put(setUserNameSuccess(payload));
        console.log("Retrieve Test Success data:", data);
    } catch (error){
        yield put(setUserNameError(error));
        console.error(error);
    }
}

function* setLoginStateSaga({payload}) {
    try{
        yield put(setLoginStateSuccess(payload));
        console.log("Retrieve Test Success data:", data);
    } catch (error){
        yield put(setLoginStateSuccess(error));
        console.error(error);
    }
}

export function* authSagaWatcher(){
    yield takeLatest(setHeaderTitle.type, setHeaderTitleSaga);
    yield takeLatest(setCurrUser.type, setCurrUserSaga);
    yield takeLatest(setUserID.type, setUserIDSaga);
    yield takeLatest(setUserName.type, setUserNameSaga);
    yield takeLatest(setLoginState.type, setLoginStateSaga);
}