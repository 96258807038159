import React, {
    useEffect,
} from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import './Preload.scss'
import { addToLocalStorage, getFromLocalStorage } from "../../util/LocalStorage";

//slices
import { setCompanyID } from "../../stores/slices/companyIDSlices";
import { retrieveCurrRoles } from "../../stores/slices/roleSlices";
import { selectCurrUserContext, selectSetCompanyDataAndUsersLoading, selectSetCompanyUsageLoading, setCompanyDataAndUsers, setCompanyUsage } from "../../stores/slices/companySlices";
import { selectCurrTenant } from "../../stores/slices/persistRolesSlices";
import { getInitialClientData } from "../../stores/slices/clientSlices";
import { selectUser } from "../../stores/slices/userAuthSlices";

function Preload() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const tenant = selectCurrTenant();
    const setCompanyDataAndUsersLoading = selectSetCompanyDataAndUsersLoading();
    const setCompanyUsageLoading = selectSetCompanyUsageLoading();
    
    const user = selectUser();

    const { currUserSA, currUserInvitation } = selectCurrUserContext();
    const currSa = getFromLocalStorage("IS_SA");

    useEffect(()=>{
        if(!setCompanyDataAndUsersLoading){
            const params = searchParams.get("CompanyID");
            const roleParams = searchParams.get("RoleID");
            dispatch(setCompanyUsage({
                currUserSA : currUserSA, 
                currUserInvitation : currUserInvitation,
                TenantID : tenant,
                CompanyID : params,
            }))
            dispatch(getInitialClientData({
                TenantID : tenant,
                UserID : user?.uid,
                CompanyID : params,
                isSA : currSa,
                user : user,
            }))
        }

    }, [setCompanyDataAndUsersLoading])

    useEffect(()=>{
        if(!setCompanyUsageLoading){
            navigate("/Dashboard")
        }
    }, [setCompanyUsageLoading])

    useEffect(() => {
        
        const params = searchParams.get("CompanyID");
        const roleParams = searchParams.get("RoleID");

        //storage
        const companyID = getFromLocalStorage("CURR_COMPANY");
        const roleID = getFromLocalStorage("CURR_ROLES");
        const currTenant = getFromLocalStorage("CURR_TENANT");

        if(params && roleParams && user){

            addToLocalStorage("CURR_COMPANY", params);
            addToLocalStorage("CURR_ROLE", roleParams);
            dispatch(setCompanyDataAndUsers({
                TenantID : currTenant, 
                isSA: roleParams === "SA" ? true : false, 
                UserID : user?.uid,
                RoleID : roleParams,
                CompanyID : params
            }));
            dispatch(setCompanyID(params));
            dispatch(retrieveCurrRoles({TenantID : tenant, RoleID : roleParams}));

            // if(roleParams === "SA"){
            //     addToLocalStorage("CURR_ROLE", roleParams);
            //     addToLocalStorage("CURR_COMPANY", params);

            //     if(currTenant){
            //         dispatch(retrieveCompany({TenantID:currTenant, isSA: true, UserID: user?.uid})),
            //         dispatch(retrieveAllAgent({TenantID:currTenant}))
            //     }
            //     dispatch(setCompanyID(params));
            //     setTimeout(() => {
            //         navigate('/Dashboard');
            //     },2000)
            // }else{
            //     addToLocalStorage("CURR_ROLE", roleParams);
            //     addToLocalStorage("CURR_COMPANY", params);
            //     dispatch(setCompanyID(params));
            //     dispatch(retrieveCurrRoles({TenantID:tenant, RoleID:roleParams}))
            //     if(currTenant){
            //         dispatch(retrieveCompany({TenantID:currTenant, isSA: false, UserID: user?.uid})),
            //         dispatch(retrieveAllAgent({TenantID:currTenant}))
            //     }
            //     setTimeout(() => {
            //         navigate('/Dashboard');
            //     },2000)
            // }
            
        }
        
    },[user])

  return (
    <div className="preloadMainContainer">
        <img src={require("../../assets/img/preload.gif")}/>
        <span>Preparing your workspace...</span>
    </div>
  )
};

export default Preload;