import React from 'react'
import './C_Button.scss'

const C_Button = (props) => {
    return (
        <div className='btn-style' 
         tabIndex='0'
         title={props.title}
         style={{
                padding:props.padding,
                minWidth:props.minWidth,
                width: props.width,
                fontWeight: props.fontWeight,
                height: props.height,
                justifyContent: props.justify,
                background: props.background,
                color: props.color,
                border: props.border,
                gap: props.gap,
                marginTop: props.marginTop,
                marginLeft: props.marginLeft,
                marginRight: props.marginRight,
                borderRadius: props.borderRadius,
                borderColor: props.borderColor,
                paddingLeft:props.paddingLeft,
                paddingRight:props.paddingRight,
                fontWeight:props.fontWeight,
                letterSpacing:props.letterSpacing,
            }}
            onKeyDown={props.onKeyDown}
            disabled={props.disabled}
            onClick={props.onClick}
            data-bs-target={'#'+props.targetID} data-bs-toggle={props.toggleAction} data-bs-dismiss={props.dismissAction}>
                <div className='btn-style-span'>{props.icon}</div>
                <span className={`btn-style-span ${props.fontSize !== undefined ? props.fontSize : "font-xxs"}`}>{props.buttonText}</span>
                {props.exportLink}
        </div>
    );
}

export default C_Button;