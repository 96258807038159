import { all } from "redux-saga/effects";
import { analyticsSagaWatcher } from "./analyticsSaga";
import { appointmentsSagaWatcher } from "./appointmentsSaga";
import { authSagaWatcher } from "./authSaga";
import { clientAgentSagaWatcher } from "./clientAgentSaga";
import { clientGroupLeaderSagaWatcher } from "./clientGroupLeaderSaga";
import { usageSagaWatcher } from "./usageSaga"
import { tenantSagaWatcher } from "./tenantSaga"
import { tenantAgentSagaWatcher } from "./tenantAgentSaga"
import { systemSagaWatcher } from "./systemSaga"
import { rolesSagaWatcher } from "./rolesSaga"
import { persistRolesSagaWatcher } from "./persistRolesSaga"
import { logsSagaWatcher } from "./logSaga"
import { clientHandlerSagaWatcher } from "./clientGroupHandlerSaga";
import { clientGroupSagaWatcher } from "./clientGroupSaga";
import { clientSelectorSagaWatcher } from "./clientSelectorSaga";
import { clientSagaWatcher } from "./clientSaga";
import { companyAgentSagaWatcher } from "./companyAgentSaga";
import { companySagaWatcher } from "./companySaga";
import { companyIDSagaWatcher } from "./companyIDSaga";
import { firebaseAuthSagaWatcher } from "./firebaseAuthSaga";
import { formSubmissionSagaWatcher } from "./formSubmissionSaga";
import { kpiSagaWatcher } from "./kpiSaga";
import { formIDSagaWatcher } from "./formIDSaga";
import { formTemplateIDSagaWatcher } from "./formTemplateIDSaga";
import { formSubmissionIDSagaWatcher } from "./formSubmissionIDSaga";
import { formTemplateSagaWatcher } from "./formTemplateSaga";
import { gaPortalSagaWatcher } from "./gaPortalSaga";
import { userAuthSagaWatcher } from "./userAuthSaga";
import { clientGroupRolesSagaWatcher } from "./clientGroupRolesSaga";
import { clientGroupRoleAgentsSagaWatcher } from "./clientGroupRoleAgentsSaga";
import { transactionSagaWatcher } from "./transactionSaga";

export default function* rootSaga() {
    yield all([
        analyticsSagaWatcher(),
        appointmentsSagaWatcher(),
        authSagaWatcher(),
        clientAgentSagaWatcher(),
        clientGroupLeaderSagaWatcher(), 
        clientGroupLeaderSagaWatcher(),
        clientHandlerSagaWatcher(),
        clientGroupSagaWatcher(),
        clientSelectorSagaWatcher(),
        clientSagaWatcher(),
        companyAgentSagaWatcher(),
        companySagaWatcher(),
        companyIDSagaWatcher(),
        firebaseAuthSagaWatcher(),
        formSubmissionSagaWatcher(),
        formIDSagaWatcher(),
        formSubmissionIDSagaWatcher(),
        formTemplateIDSagaWatcher(),
        formTemplateSagaWatcher(),
        kpiSagaWatcher(),
        logsSagaWatcher(),
        persistRolesSagaWatcher(),
        rolesSagaWatcher(), 
        systemSagaWatcher(), 
        tenantAgentSagaWatcher(),     
        tenantSagaWatcher(),
        usageSagaWatcher(),
        gaPortalSagaWatcher(),
        userAuthSagaWatcher(),
        clientGroupRolesSagaWatcher(),
        clientGroupRoleAgentsSagaWatcher(),
        transactionSagaWatcher(),
    ])
}