import React, {
    useState,
    useEffect,
    useRef,
    Fragment,
} from "react";
import './GaPortal.scss'
import moment from "moment";
import { Modal } from "bootstrap";
import { useDispatch } from "react-redux";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import C_Loading from "../../components/C_Loading";
import { child, get, query, ref, set, update } from 'firebase/database'
import { db } from '../../firebaseInit';
import { Table, Column } from "react-virtualized";
import Draggable from "react-draggable";
import { BiSearch, BiSortAlt2 } from "react-icons/bi";
import { AiOutlineClose} from "react-icons/ai";
import C_DialogButton from "../../components/C_DialogButton";
import { v4 } from "uuid";
import { MdFilterList, MdOutlineKeyboardDoubleArrowUp, MdOutlineViewColumn } from "react-icons/md";
import { BsArrowDown, BsArrowUp, BsBoxFill, BsCheck2Circle, BsPlusSlashMinus } from "react-icons/bs";
import { TbChecklist } from "react-icons/tb";
import C_PopoutNoti from "../../components/C_PopoutNoti";
import usageObject from '../../enums/json/plan.json';
import { country_and_states } from "../../enums/json/country-states";

//slices
import { createTenantDatabase, sendTenantApprovalMail, updateTenantDetails } from "../../stores/slices/tenantSlices";
import { createCompany } from "../../stores/slices/companySlices";
import { createRole } from "../../stores/slices/roleSlices";
import { selectGaPortalContext } from "../../stores/slices/gaPortalSlices";
import { selectUser } from "../../stores/slices/userAuthSlices";

function GaTenantList(props) {

  const user = selectUser();
  const dispatch = useDispatch();
  const { currTenantList, currAllUserList } = selectGaPortalContext();
  const dbRef = ref(db);

  const [searchValue,setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currTenantIndex,setCurrTenantIndex] = useState("");
  const [notifyMessage,setNotifyMessage] = useState("");
  const [tenantList,setTenantList] = useState([]);
  const [consTenantList,setConsTenantList] = useState([]);
  const [isSort,setIsSort] = useState(false);
  const [isFilter,setIsFilter] = useState(false);
  const [isSearch,setIsSearch] = useState(false);
  const [isColumn,setIsColumn] = useState(false);
  const statusFilter = ["active", "pending","rejected","resubmission"];
  const planFilter = ["Trial","Standard","Pro","Enterprise"];
  const countryFilter = currTenantList.length > 0 ? [...new Set(currTenantList.map(item=>item?.country))] : [];
  const workspaceFilter = currTenantList.length > 0 ? [...new Set(currTenantList.map(item=>item?.TenantID))] : [];

  const [selectedWorkspace,setSelectedWorkspace] = useState([]);
  const [selectedStatus,setSelectedStatus] = useState([]);
  const [selectedCountry,setSelectedCountry] = useState([]);
  const [selectedPlan,setSelectedPlan] = useState([]);
  const [selectedColumn,setSelectedColumn] = useState(["index","action"]);
  const [sortValue,setSortValue] = useState("Newest");
  const [approvingText,setApprovingText] = useState("");
  const [isApprove,setIsApprove] = useState(false);
  const [isApproving,setIsApproving] = useState(false);
  const [selectedAction,setSelectedAction] = useState("");

  const MAX_WIDTH = props.TOTAL_WIDTH * 0.95;
  const TABLE_TOTAL_WIDTH = props.TOTAL_WIDTH;
  const TABLE_TOTAL_HEIGHT = window.innerHeight * 0.8;
  
  const inputRef = useRef(null);
  const searchRef = useRef(null);
  const searchBtn = useRef(null);
  const columnRef = useRef(null);
  const columnContainerRef = useRef(null);
  const filterRef = useRef(null);
  const filterContainerRef = useRef(null);
  const sortRef = useRef(null);
  const sortContainerRef = useRef(null);
  const actionRef = useRef(null);

  const navigate = useNavigate();

  const showActionModal = () => {
    const modalEle = actionRef.current;
    const bsModal = new Modal(modalEle, {
      backdrop: "static",
      keyboard: false,
    });
    bsModal.show();
  };

  const hideActionModal = () => {
    const modalEle = actionRef.current;
    const bsModal = Modal.getInstance(modalEle);
    bsModal.hide();
  };

  const countryIcon = country_and_states.icon;

  const tableHeaderList = [
    {
      columnKey: "action",
      columnName: "Action"
    },{
      columnKey: "workspaceName",
      columnName: "Workspace Name"
    },    
    {
      columnKey: "companyName",
      columnName: "Company Name"
    },
    {
      columnKey: "email",
      columnName: "Email Address"
    },
    {
      columnKey: "plan",
      columnName: "Plan"
    },
    {
      columnKey: "status",
      columnName: "Status"
    },
    {
      columnKey: "displayName",
      columnName: "Name"
    },
    
    {
      columnKey: "phoneNum",
      columnName: "Phone Number"
    },
    {
      columnKey: "country",
      columnName: "Country"
    },
    {
      columnKey: "approvalDate",
      columnName: "Approval Date"
    },
    {
      columnKey: "createdAt",
      columnName: "Date Joined"
    },
  ];
  const [column,setColumn] = useState(tableHeaderList);
  
  const [tableState, setTableState] = useState();

  // {
  //   width: {
  //     index:0.2,
  //     plan:0.3,
  //     status:0.3,
  //     companyName: 0.65,
  //     workspaceName:0.65,
  //     displayName: 0.45,
  //     email: 0.45,
  //     phoneNum: 0.45,
  //     // companySize: 0.45,
  //     country: 0.3,
  //     createdAt: 0.45,
  //     approvalDate:0.45,
  //     action:0.4,
  //   }
  // }

  useEffect(() => {
    let widths = {}
    for (var x = 0; x < column.length; x++) {
        widths = {
            ...widths,
            index: 0.05,
            [column[x].columnKey]: column.length < 4 ? 0.2 : 
              (column[x].columnKey === "plan" || 
              column[x].columnKey === "status" || 
              column[x].columnKey === "action" || 
              column[x].columnKey === "country") ? 0.09 :0.16,
        }
    }

    setTableState({ width: widths })
}, [column]);

  useEffect(() => {
      function handleClickOutside(event) {
          if (searchRef.current && !searchRef.current.contains(event.target)) {
              setIsSearch(false);
          } else {
              searchRef.current.style.backgroundColor = "none";
              searchRef.current.style.cursor = "pointer";
              searchRef.current.classList.remove("hover-effect");
          }

          if (filterRef.current && filterContainerRef.current && !filterRef.current.contains(event.target) && !filterContainerRef.current.contains(event.target)) {
            setIsFilter(false);
          }

          if (sortRef.current && sortContainerRef.current && !sortRef.current.contains(event.target) && !sortContainerRef.current.contains(event.target)) {
            setIsSort(false);
          }

          if (columnRef.current && columnContainerRef.current && !columnRef.current.contains(event.target) && !columnContainerRef.current.contains(event.target)) {
            setIsColumn(false);
          }

      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      
      // Unbind the event listener on cleanup
      return () => {
          document.removeEventListener("mousedown", handleClickOutside)
      };
  }, [searchRef,filterRef,filterContainerRef,sortRef,columnRef,columnContainerRef]);

  useEffect(() => {
      if(selectedPlan.length === 0 && selectedCountry.length === 0 && selectedStatus.length === 0 && selectedWorkspace.length === 0) {
          setTenantList(currTenantList);
          setConsTenantList(currTenantList);
      } else {
        const usersRef = child(dbRef, 'planRequest');
        const queries = [];
        const matchingValues = [];
        const uniqueID = new Set();

        get(query(usersRef)).then((snapshot) => {
          snapshot.forEach((userSnapshot) => {
            userSnapshot.forEach((finalSnapshot)=>{
              const childValue = finalSnapshot.val();
              const { plan, country, status, TenantID, companyID } = childValue;
              if (
                (!selectedPlan.length || selectedPlan.includes(plan)) &&
                (!selectedCountry.length || selectedCountry.includes(country)) &&
                (!selectedStatus.length|| selectedStatus.includes(status)) &&
                (!selectedWorkspace.length || selectedWorkspace.includes(TenantID)) &&
                !uniqueID.has(companyID)
              ) {
                matchingValues.push(childValue);
                uniqueID.add(companyID);
              }
            });
            })
            
        });

        
        setTenantList(matchingValues)
        setConsTenantList(matchingValues);
      }
        
  },[selectedPlan,selectedCountry,selectedStatus,selectedWorkspace])

  useEffect(() => {
    if (isSearch) {
      // Focus the input when switching to the search mode
      inputRef.current.focus();
    }
  }, [isSearch]);

  useEffect(()=>{
    let newList = []
    let currentList = [...tenantList]
    if(searchValue !== ""){
       newList = currentList.filter(item => {

        const matches = tableHeaderList.map((col,index)=>{
            if(item[col.columnKey] !== undefined && item[col.columnKey].toString().toLocaleLowerCase().includes(searchValue.toString().toLocaleLowerCase())){
              return true
            }
          })
          if(matches.includes(true)){
            return item
          }
      })
      setTenantList(newList)
    } else {
      setTenantList(consTenantList)
    }

  },[searchValue])

  useEffect(()=>{
    const columnArray = tableHeaderList.filter((col,index)=>{
      if(selectedColumn.length > 2){
        if(selectedColumn.includes(col.columnKey)){
            return col
        }
      } else if(selectedColumn.length === 2){
            return col
      }
    })
    
    setColumn(columnArray)
  },[selectedColumn])

  useEffect(()=>{
    const list = [...tenantList];
    
    list?.sort((a,b)=>{
        if(sortValue === "Newest"){
            return moment(b.createdAt) - moment(a.createdAt)
        } else if(sortValue === "Oldest") {
            return moment(a.createdAt) - moment(b.createdAt)
        }
    })
    .map(data=>{
      return data
    })

    setTenantList(list);
    setConsTenantList(list);

},[sortValue])

  useEffect(()=>{

    const tenantList = [...currTenantList];
    // const newTenantList = currTenantList.filter((data) => data.requestTenant === true)
    const newTenantList = tenantList.sort((a,b)=>
      b?.status?.localeCompare(a?.status) ||  
      b?.approvalDate?.localeCompare(a?.approvalDate) || 
      a?.workspaceName?.localeCompare(b?.workspaceName) || 
      a?.companyName?.localeCompare(b?.companyName))
    setTenantList(newTenantList);
    setConsTenantList(newTenantList);
    // setCurrTenantList({type:"CURR_TENANT_LIST",payload:newTenantList})

  },[currTenantList])

  const handleSelectedWorkspace = (isSelected,tenantID) => {
    if(isSelected){
      const newList = selectedWorkspace.filter(data => data !== tenantID);
      setSelectedWorkspace(newList)
    }else{
      const newList = [...selectedWorkspace, tenantID];
      setSelectedWorkspace(newList)
    }
  }

  const handleSelectedStatus = (isSelected,status) => {
    if(isSelected){
      const newList = selectedStatus.filter(data => data !== status);
      setSelectedStatus(newList)
    }else{
      const newList = [...selectedStatus, status];
      setSelectedStatus(newList)
    }
  }

  const handleSelectedCountry = (isSelected,country) => {
    if(isSelected){
      const newList = selectedCountry.filter(data => data !== country);
      setSelectedCountry(newList)
    }else{
      const newList = [...selectedCountry, country];
      setSelectedCountry(newList)
    }
  }

  const handleSelectedPlan = (isSelected,plan) => {
    if(isSelected){
      const newList = selectedPlan.filter(data => data !== plan);
      setSelectedPlan(newList)
    }else{
      const newList = [...selectedPlan, plan];
      setSelectedPlan(newList)
    }
  }

  const handleSelectedColumn = (isSelected,col) => {
    if(isSelected){
      const newList = selectedColumn.filter(data => data !== col);
      setSelectedColumn(newList)
    }else{
      const newList = [...selectedColumn, col];
      setSelectedColumn(newList)
    }
  }

  //standby for changing General admin, update it's profile
  const updateGaProfile = () => {
    var body = {
      UserID: user.uid,
      TenantID: null
    }
    dispatch(updateTenantDetails(body))
  }

  function retrieveTenantFunction(retrieveFunc){
    // setLoadData(true);
    return Promise.all(
      retrieveFunc
    );
  }

  const _indexRenderer = ({ columnData, dataKey, disableSort, label, sortBy, sortDirection }) => { 
    return (
        <label 
          style={{  
            alignItems: 'center', display: 'flex',
            width:tableState && tableState.width["index"] * TABLE_TOTAL_WIDTH 
          }}
        >
           No.
        </label>
    );
  }

  function _rowRenderer({
    style,
    key,
    index
  }) {
    return (
        <div
          key={key}
          className={`ReactVirtualized__Table__row ${index % 2 && ''} tenant-table-row`}
          role="row"
          style={style}
        >
          <div
              className="ReactVirtualized__Table__rowColumn row-text"
              role="gridcell"
              style={{ overflow: "hidden", justifyContent: 'center' }}
          >
              <label 
                style={{
                  overflow: "hidden", 
                  width: tableState && tableState.width["index"] * TABLE_TOTAL_WIDTH,
                  gap:"0.5rem"
                }}>
                  <span>
                    {index + 1}
                  </span>
              </label>
          </div>
          {column && column.map((item, colIndex) => {
            return(
              <div
                className={`ReactVirtualized__Table__rowColumn row-text 
                ${item.columnKey === "createdAt"} `}
                role="gridcell"
                key={colIndex}
                style={
                  item.columnKey === "country" 
                  ? 
                  {overflow: "hidden", 
                  width: tableState && tableState.width[item.columnKey] * TABLE_TOTAL_WIDTH,
                  gap:"0.5rem"} 
                  : 
                  {overflow: "hidden", 
                  width: tableState && tableState.width[item.columnKey] * TABLE_TOTAL_WIDTH}
                }
                onClick={()=>item.columnKey === "action" ? setCurrTenantIndex(index) : "" }
                title={tenantList[index][item.columnKey]}
              >
                {item.columnKey === "index" &&
                  <span style={{display:'flex',alignItems:'center'}}>{index + 1}.</span>
                }

                {item.columnKey === "plan" &&
                  <span style={{display:'flex',alignItems:'center'}}>{tenantList[index][item.columnKey]}</span>
                }

                {item.columnKey === "status" &&
                  <div className={`
                    ${tenantList[index][item.columnKey] === "active" && "active-btn"}
                    ${tenantList[index][item.columnKey] === "pending" && "pending-btn"}
                    ${tenantList[index][item.columnKey] === "resubmission" && "resubmission-btn"}
                    ${tenantList[index][item.columnKey] === "rejected" && "rejected-btn"}
                    `
                  }>
                      <div className="status-legend"></div>
                      <span style={{display:'flex',alignItems:'center'}}>{tenantList[index][item.columnKey]}</span>
                  </div>
                  // <span style={{display:'flex',alignItems:'center'}}>{tenantList[index][item.columnKey] ? "Active" : "Pending"}</span>
                }

                {item.columnKey === "displayName" &&
                  <span style={{display:'flex',alignItems:'center'}}>{tenantList[index][item.columnKey]}</span>
                }

                {item.columnKey === "email" &&
                  <>
                    <span style={{display:'flex',alignItems:'center'}}>{tenantList[index][item.columnKey]}</span>
                  </>
                }

                {item.columnKey === "phoneNum" &&
                   <>
                    <span style={{display:'flex',alignItems:'center'}}>{tenantList[index][item.columnKey]}</span>
                  </>
                }

                {item.columnKey === "companyName" &&
                  <>
                    <span style={{display:'flex',alignItems:'center'}}>{tenantList[index][item.columnKey]}</span>
                  </>
                }

                {item.columnKey === "workspaceName" &&
                  <span style={{display:'flex',alignItems:'center'}}>{tenantList[index][item.columnKey]}</span>
                }

                {item.columnKey === "companySize" &&
                  <>
                    <span style={{display:'flex',alignItems:'center'}}>{tenantList[index][item.columnKey]}</span>
                  </>
                }

                {item.columnKey === "country" &&
                
                <>
                  <div>
                      <img className="country-icon" src={countryIcon[tenantList[index][item.columnKey]].image} alt="country flag"/>
                  </div>
                  <span style={{display:'flex',alignItems:'center'}}>{tenantList[index][item.columnKey]}</span>
                </>
                }

                {item.columnKey === "createdAt" &&
                  <span style={{display:'flex',alignItems:'center'}}>{moment(tenantList[index][item.columnKey]).format("YYYY-MM-DD")}</span>
                }

                {item.columnKey === "approvalDate" &&
                  <span style={{display:'flex',alignItems:'center'}}>{tenantList[index][item.columnKey] === "" ? "N/A" : moment(tenantList[index][item.columnKey]).format("YYYY-MM-DD")}</span>
                }
                
                {item.columnKey === "action" &&
                  <div className="d-flex w-100 h-100">
                    <div className={`${tenantList[index]["status"] === "rejected" ? "disabled-approval-btn" : "approval-btn"}`} 
                      onClick={()=>{
                        if(tenantList[index]["status"] !== "rejected"){
                          setIsApproving(false);
                          setIsApprove(false);
                          showActionModal();
                          setSelectedAction("");
                        }
                          
                      }}> 
                      <div className="approval-icon"><BsBoxFill size={16}/></div>
                      <span>Action</span>
                    </div>
                  </div>
                }
              </div>
            )
          })}
          
        </div>
    );
  };

  const headerRenderer = ({ columnData, dataKey, disableSort, label, sortBy, sortDirection }) => {
    return (
        <Fragment key={dataKey}>
          <div className="header-text">
            <div className="ReactVirtualized__Table__headerTruncatedText" 
              style={{  
                alignItems: 'center', display: 'flex',
                width: tableState && tableState.width[dataKey] * TABLE_TOTAL_WIDTH 
              }}>
                <span>{label}</span>
            </div>
            <Draggable
                  axis="x"
                  defaultClassName="DragHandle"
                  defaultClassNameDragging="DragHandleActive"
                  onDrag={(event, { deltaX }) =>
                      resizeRow({
                          dataKey,
                          deltaX
                      })
                  }
                  position={{ x: 0 }}
                  zIndex={999}
            >
                <span className="DragHandleIcon" style={{cursor:'pointer'}}>⋮</span>
            </Draggable>
          </div>
          
        </Fragment>
    );
  };

  const resizeRow = ({ dataKey, deltaX }) => {
    setTableState(prevState => {
        const prevWidths = prevState.width;
        const percentDelta = deltaX / TABLE_TOTAL_WIDTH;

        let nextDataKey = ""
        column?.map((item, index, { length }) => {
            if (item.columnName === dataKey) {
                if (index + 1 === length) {
                    nextDataKey = "IsLastIndex"
                }
                else if (index + 1 <= length) {
                    nextDataKey = column[index + 1].columnName
                }
            }
        })

        if (nextDataKey === "IsLastIndex") {
            return {
                width: {
                    ...prevWidths,
                    [dataKey]: prevWidths[dataKey] + percentDelta,
                }
            };
        }
        else {
            return {
                width: {
                    ...prevWidths,
                    [dataKey]: prevWidths[dataKey] + percentDelta,
                    [nextDataKey]: prevWidths[nextDataKey] - percentDelta
                }
            };
        }

    });
  };

  const rejectTenantRequest = async () => {
    setIsApproving(true);
    setIsApprove(true);
    setApprovingText("Rejecting..")
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setApprovingText("Sending Email..")
    const date = moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
    const index = currTenantIndex;
    await update(ref(db, 'planRequest/' + tenantList[index].uid + '/' + tenantList[index].companyID + '/'), {
      status:"rejected",
      approvalDate:date,
    })
    .then(async (res)=>{
      await new Promise((resolve) => setTimeout(resolve, 2000));
      dispatch(sendTenantApprovalMail(
        { displayName:tenantList[index].displayName,
          email: tenantList[index].email,
          companyName:tenantList[index].companyName,
          plan:tenantList[index].plan,
          approvalDate:date,
          approval:"reject",password:"" }));
      })
      setTimeout(()=>{
        setApprovingText("Tenant request has been rejected");
        setIsApproving(false)
      }, 2000)
  }

  const updateTenantStatus = async () => {
    setIsApproving(true);
    setIsApprove(true);
    setApprovingText("Creating Database..")
    await new Promise((resolve) => setTimeout(resolve, 2000));
    const index = currTenantIndex;
    const uniqueID = v4();
    const roleID = v4();
    const isTenant = tenantList[index].isTenant;
    const tenantID = isTenant ? tenantList[index].TenantID : "crm_"+uniqueID.replace(/-/g, "");
    const companyID = tenantList[index].companyID; //plan request
    const approvalDate = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");

    let usage;

    switch(tenantList[index].plan){
      case "Trial" :
        usage = usageObject.Trial
        break;
      case "Standard" :
        usage = usageObject.Standard
        break;
      case "Pro" :
        usage = usageObject.Pro
        break;
      case "Enterprise" :
        usage = usageObject.Enterprise
        break;
      default:
        break;
    }

    const TenantData = {
      TenantID:tenantID,
      firstName:tenantList[index].firstName,
      lastName:tenantList[index].lastName,
      email:tenantList[index].email,
      phoneNum:tenantList[index].phoneNum,
      workspaceName:tenantList[index].workspaceName,
      companyName:tenantList[index].companyName,
      companySize:tenantList[index].companySize,
      country:tenantList[index].country,
      state:tenantList[index].state,
      UserID:tenantList[index].uid,
    }

    const UsageData = {
        companyID : companyID,
        plan:tenantList[index].plan,
        startDate: "",
        expiryDate:"",
        activation: false,
        usage:usage
    }

    const RoleData = {
        RoleID: roleID,
        CompanyID: companyID,
        DefaultRole: true,
        RoleName: "Default",
        RoleColor: "#919191"
    }

    const CompanyData = {
        CompanyProfile: "",
        CompanyName: tenantList[index].companyName,
        CompanyLocation: "",
        CompanyEmail: "",
        CompanyID: companyID,
        CreatedAt: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        ModifiedAt: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        DefaultRole: roleID
    }

    //workspaceName "" / null
    async function runFirebaseQueries() {
      await update(ref(db, 'users/' + tenantList[index].uid), {
        isTenant: true,
        TenantID: tenantID,
        plan : tenantList[index].plan,
        companyID: companyID,
        workspaceName: tenantList[index].workspaceName,
        tenantCreatedAt:moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      });
      await update(ref(db, 'planRequest/' + tenantList[index].uid + '/' + companyID), {
        TenantID: tenantID,
        status: "active",
        workspaceName: tenantList[index].workspaceName,
        companyID: companyID,
        isTenant: true,
        approvalDate:approvalDate
      });
      await set(ref(db, 'usage/' + `${tenantID}/` + `${companyID}/`), UsageData);
    }
    
    async function runSQLQueries() {
      //need a status to determine whether the tenant is created or not
      if (!isTenant) {
  
        dispatch(createTenantDatabase(TenantData))
        setTimeout(async () => {
            setApprovingText("Creating Default Role..");
            await new Promise((resolve) => setTimeout(resolve, 2000));
            dispatch(createRole({ TenantID: tenantID, data: RoleData }));

            setApprovingText("Creating Default Company..");
            await new Promise((resolve) => setTimeout(resolve, 2000));
            dispatch(createCompany({ TenantID: tenantID, data: CompanyData }));

            setApprovingText("Sending Email..");
            await new Promise((resolve) => setTimeout(resolve, 2000));
            dispatch(sendTenantApprovalMail({
                displayName: tenantList[index].displayName,
                email: tenantList[index].email,
                companyName: tenantList[index].companyName,
                plan: tenantList[index].plan,
                approvalDate: approvalDate,
                approval: "approved",
                password: "",
            }));
            await new Promise((resolve) => setTimeout(resolve, 3000));
            setApprovingText("Tenant request has been processed");
            setIsApproving(false);
        }, 3000);
      } else {
          setApprovingText("Creating Default Role..");
          await new Promise((resolve) => setTimeout(resolve, 2000));
          dispatch(createRole({ TenantID: tenantID, data: RoleData }));
        
          setApprovingText("Creating Default Company..");
          await new Promise((resolve) => setTimeout(resolve, 2000));
          dispatch(createCompany({ TenantID: tenantID, data: CompanyData }));
        
          setApprovingText("Sending Email..");
          await new Promise((resolve) => setTimeout(resolve, 2000));
          dispatch(sendTenantApprovalMail({
              displayName: tenantList[index].displayName,
              email: tenantList[index].email,
              companyName: tenantList[index].companyName,
              plan: tenantList[index].plan,
              approvalDate: approvalDate,
              approval: "approved",
              password: "",
          }));
          await new Promise((resolve) => setTimeout(resolve, 3000));
          setApprovingText("Tenant request has been processed");
          setIsApproving(false);
      }
    
      
    }
    
    async function runPromisesAccordingly() {
      try {
        await runSQLQueries().then(
          await runFirebaseQueries()
          .then(console.log("All promises completed successfully."))
        )
      } catch (error) {
        console.error('Error:', error);
      }
    }
    runPromisesAccordingly();
  }

  const tenantApprovalInterface = () => {
    return (
      <div className="modal-body action-body">
            <div className="">
              <span className="font-xs" style={{ fontWeight: "bold" }}>
                  {isApproving && isApprove ? "Please wait while we are processing the data." : 
                  <>
                  {isApprove && !isApproving ? "The tenant request has been processed. A notification email has been sent to the user to notify them." : "Do you want to approve or reject this request? This action cannot be reverted"}
                  </>}
              </span>
            </div>
          <div
            style={{ display: "flex", justifyContent: "flex-end",columnGap:'20px',alignItems:"center" }}
            className="mt-2"
          >
            <div className={`py-1 loadingContainer ${!isApprove && "hide-loading-container"}`}>
                <div className={`${isApproving ? "loading-spinner" : "hide-loading-spinner"}`}>
                </div>
                {isApproving ?
                <>
                  <span>{approvingText}</span>
                </>
                :
                <div className="finished-loading-container">
                  <span>{approvingText}</span> 
                  <div><BsCheck2Circle size={18}  style={{fontWeight:"600",color:"green"}}/></div>
                </div>}
            </div>
            {!isApprove ?
              <>
                <C_DialogButton 
                // 707070
                  background={'#ca4254'}
                  borderColor={'#ca4254'}
                  buttonText={"REJECT"}
                  onClick={() => {rejectTenantRequest();}}
                />
                <C_DialogButton 
                  color={"white"}
                  background={'#3eb986'}
                  borderColor={'#3eb986'}
                  buttonText={"APPROVE"}
                  onClick={() => updateTenantStatus()}
                />
              </>
              :
              <>
                {isApproving ? 
                  <C_DialogButton 
                    background={'#eeeeee'}
                    borderColor={'#eeeeee'}
                    buttonText={"CLOSE"}
                    disabled
                  />
                :
                  <C_DialogButton 
                    background={'#6f7df7'}
                    borderColor={'#6f7df7'}
                    buttonText={"CLOSE"}
                    onClick={()=>{hideActionModal();}}
                  />
                }
              </>
            }
          </div>
      </div>
    );
  }

  const actionSelectionInterface = () => {
    const status = tenantList[currTenantIndex]?.status;
    return (
      <div className="modal-body action-body">
        <div className="action-card-container"> 
          <div className={`${status !== "active"? "action-card":"disabled-action-card"}`} onClick={()=>{status !== "active" && setSelectedAction("approval")}}>
              <div className="action-icon">
                <TbChecklist size={25}/>
              </div>
              <span>Approval</span>
          </div>
          <div className={`${(status !== "pending" && status !== "resubmission")? "action-card":"disabled-action-card"}`} onClick={() => {
            if (status !== "pending" && status !== "resubmission") {
              navigate({
                pathname: "/GaPortal/GaTenantQuota",
                search: createSearchParams({
                  tenantID: tenantList[currTenantIndex].TenantID,
                  companyID: tenantList[currTenantIndex].companyID,
                  plan: tenantList[currTenantIndex].plan,
                  companyName: tenantList[currTenantIndex].companyName,
                  hostEmail: tenantList[currTenantIndex].email,
                  userName: tenantList[currTenantIndex].displayName,
                }).toString()
              });
              hideActionModal();
            }
          }}>
            <div className="action-icon">
              <BsPlusSlashMinus size={25}/>
            </div>
            <span>Quota</span>
          </div>
          <div className={`${(status !== "pending" && status !== "resubmission")? "action-card":"disabled-action-card"}`} onClick={()=>{
            if (status !== "pending" && status !== "resubmission") {
              navigate({
                pathname: "/GaPortal/GaTenantUpgrade",
                search: createSearchParams({
                  tenantID: tenantList[currTenantIndex].TenantID,
                  companyID: tenantList[currTenantIndex].companyID,
                  plan: tenantList[currTenantIndex].plan,
                  companyName: tenantList[currTenantIndex].companyName,
                  hostEmail: tenantList[currTenantIndex].email,
                  userName: tenantList[currTenantIndex].displayName,
                  userID : tenantList[currTenantIndex].uid
                }).toString()
              });
              hideActionModal();
            }
          }}>
              <div className="action-icon">
                <MdOutlineKeyboardDoubleArrowUp size={25}/>
              </div>
              <span>Upgrade</span>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
    <C_Loading isLoading={isLoading}/>
    <div className='tenantListMainContainer'>
    <C_PopoutNoti triggerSaved={notifyMessage}/>
    {/* Modal start */}
      <div className="modal fade" tabIndex="-1" id="tenant-confirmation" ref={actionRef} style={{}}>
        <div className="modal-dialog modal-dialog-centered" style={{}}>
            <div className="modal-content px-3" style={{overflowY: "scroll" }}>
                <div className="modal-header modalHeader">
                    <div className="title">
                        {selectedAction === "approval" &&  <TbChecklist size={20} color={"rgb(67,67,67)"}/>}
                        {/* {selectedAction === "addons" && "Add ons"}
                        {selectedAction === "" && "Actions"} */}
                        <span className="font-s">
                          {selectedAction === "approval" && "Tenant Approval"}
                          {selectedAction === "addons" && "Add ons"}
                          {selectedAction === "" && "Choose an Action"}
                        </span>
                    </div>
                    <AiOutlineClose cursor='pointer' size={16} onClick={()=>{!isApproving && hideActionModal();}}/>
                </div>
                {selectedAction === "approval" && tenantApprovalInterface()}
                {selectedAction === "" && actionSelectionInterface()}
            </div>
        </div>
      </div>
      {/* Modal end */}
      <div className="tenantHeader">
          <span>Tenant List</span>
      </div>
      <div className="tenant-table-outer-container">
        <div className="tenantActionContainer">
          <div className="tenantListAction">
            {isFilter &&
              <div className="filter-action-container" ref={filterContainerRef}>
                <div className="filter-list-container">
                <div className="tag-outer-container">
                    <span>Workspace</span>
                    <div className="tag-container">
                      {workspaceFilter.map((tenantID,index)=>{
                        let tenantObj = currAllUserList.find(item=>item?.TenantID === tenantID)
                        
                        let isSelected = selectedWorkspace.includes(tenantID)
                        return(
                          <div className={`${isSelected ? "tag-clicked" : "tag-ori"} tag-default`} 
                          onClick={()=>{handleSelectedWorkspace(isSelected,tenantID)}}>
                            {tenantObj?.workspaceName}
                          </div>
                        )
                        })}
                    </div>
                  </div>
                  <div className="tag-outer-container">
                    <span>Status</span>
                    <div className="tag-container">
                      {statusFilter.map((status,index)=>{
                        let isSelected = selectedStatus.includes(status)
                        return(
                          <div className={`${isSelected ? "tag-clicked" : "tag-ori"} tag-default`} 
                          onClick={()=>{handleSelectedStatus(isSelected,status)}}>
                            {status}
                          </div>
                        )})}
                    </div>
                  </div>
                  <div className="tag-outer-container">
                    <span>Plan</span>
                    <div className="tag-container">
                      {planFilter.map((plan,index)=>{
                        let isSelected = selectedPlan.includes(plan)
                        return(
                          <div className={`${isSelected ? "tag-clicked" : "tag-ori"} tag-default`} 
                          onClick={()=>{handleSelectedPlan(isSelected,plan)}}>
                            {plan}
                          </div>
                        )})}
                    </div>
                  </div>
                  <div className="tag-outer-container">
                    <span>Country</span>
                    <div className="tag-container">
                      {countryFilter.map((country,index)=>{
                        let isSelected = selectedCountry.includes(country)
                        return(
                          <div className={`${isSelected ? "tag-clicked" : "tag-ori"} tag-default`} 
                          onClick={()=>{handleSelectedCountry(isSelected,country)}}>
                            {country}
                          </div>
                        )})}
                    </div>
                  </div>
                </div>
              </div>
            }
            {isSort &&
              <div className="sort-action-container" ref={sortContainerRef}>
                  <div className={`sort-container sort-newest-container-border-bottom`} onClick={()=>{setSortValue("Newest");setIsSort(false);}}>
                      <div><BsArrowDown size={16}/></div>
                      <span>Newest {`(Date Joined)`}</span>
                  </div>
                  <div className={`sort-container`} onClick={()=>{setSortValue("Oldest");setIsSort(false);}}>
                      <div><BsArrowUp size={16}/></div>
                      <span>Oldest {`(Date Joined)`}</span>
                  </div>
              </div>
            }

            {isColumn &&
              <div className="column-action-container" ref={columnContainerRef}>
                  <div className="column-inner-container">
                    {tableHeaderList.map((col,index)=>{
                      if(col.columnKey !== "index" && col.columnKey !== "action"){
                        let isSelected = selectedColumn.includes(col.columnKey)
                        return(
                            <label className="column-content">
                                <input type='checkbox' style={{ width: '12px', height: '12px' }} 
                                checked={isSelected} value={col.columnKey} onChange={(e)=>{handleSelectedColumn(isSelected,e.target.value)}}/>
                                <span className="checkmark"></span>
                                <span>{col.columnName}</span>
                            </label>
                        );}
                      })}
                  </div>
              </div>
            }
            
            <div className={` ${isSearch ? "search-action-btn selected-search-action-btn" : "action-btn"}`} ref={searchRef} onClick={()=>{setIsSearch(true)}}>
              <div className="action-icon"><BiSearch size={15}/></div>
              {!isSearch && <span>Search</span>}
              {isSearch && <input ref={inputRef} value={searchValue} className="search-action" placeholder="Search for everything" onChange={(e)=>{setSearchValue(e.target.value);}}/>}
            </div>

            {/* Column */}
            <div className={`action-btn ${isColumn ? "selected-action-btn" : ""}`} ref={columnRef} onClick={()=>{setIsColumn(true)}}>
              <div className="action-icon"><MdOutlineViewColumn size={17}/></div>
              <span>Column</span>
            </div>
            
            {/* Filter */}
            <div className={`action-btn ${isFilter ? "selected-action-btn" : ""}`} ref={filterRef} onClick={()=>{setIsFilter(true)}}>
              <div className="action-icon"><MdFilterList size={17}/></div>
              <span>Filter</span>
            </div>

            {/* Sort */}
            <div className={`action-btn ${isSort ? "selected-action-btn" : ""}`} ref={sortRef} onClick={()=>{setIsSort(true)}}>
              <div className="action-icon"><BiSortAlt2 size={15}/></div>
              <span>{`Sort`}</span>
            </div>
          </div>
        </div>
        <div className="tenantTable" >
          <div className="tenantTableContainer">
            <Table
                width={TABLE_TOTAL_WIDTH}
                height={TABLE_TOTAL_HEIGHT}
                headerHeight={50}
                rowHeight={50}
                rowCount={tenantList?.length}
                rowGetter={({ index }) => tenantList[index]}
                rowRenderer={({ key, index, style }) => _rowRenderer({ key, index, style })}>
                <Column
                  dataKey="index"
                  headerRenderer={_indexRenderer}
                />
                {column && column.map((item, index) => {
                  return (
                    <Column key={index} headerRenderer={headerRenderer} label={item.columnName} dataKey={item.columnKey} width={tableState && tableState.width[item.columnKey] * TABLE_TOTAL_WIDTH} />
                  )})}
            </Table>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default GaTenantList;