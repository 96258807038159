import { createSlice, current } from "@reduxjs/toolkit"

import { useAppSelector } from "../../hooks/storeHooks";

const initialState = {
    //loading

    setFormSubmissionIDLoading:false,

    //error
    setFormSubmissionIDError:undefined,

    //data
    formSubmissionID:[]
}

const formSubmissionIDSlice = createSlice({
    name:'formSubmissionID',
    initialState,
    reducers: {
        setFormSubmissionID(state){
            state.setFormSubmissionIDLoading = true
        },

        setFormSubmissionIDSuccess(state,{payload}){
            const currentState = payload
            state.formSubmissionID = currentState
            state.setFormSubmissionIDLoading = false
        },

        setFormSubmissionIDError(state, {payload}){
            const errorState = payload
            state.setFormSubmissionIDError = errorState
            state.setFormSubmissionIDLoading = false
        }
    }
})

export const {
    setFormSubmissionID,
    setFormSubmissionIDSuccess,
    setFormSubmissionIDError,
} = formSubmissionIDSlice.actions

//export selector

// data
export const selectFormSubmissionID = () =>
  useAppSelector((state) => state.formSubmissionID.formSubmissionID);

// loading
export const selectSetFormSubmissionIDLoading = () =>
  useAppSelector((state) => state.formSubmissionID.setFormSubmissionIDLoading);

// error
export const selectSetFormSubmissionIDError = () =>
  useAppSelector((state) => state.formSubmissionID.setFormSubmissionIDError);

const formSubmissionIDReducer = formSubmissionIDSlice.reducer

export default formSubmissionIDReducer
