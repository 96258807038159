import { createSlice, current } from "@reduxjs/toolkit";
import { useAppSelector } from "../../hooks/storeHooks";

const initialState = {
  isOpenDrawerEditor: {
    create: {
      data: null,
      isOpen: false,
    },
    edit: {
      data: null,
      isOpen: false,
    },
  },
  isOpenDialogBox: {
    update: {
      data: null,
      isOpen: false,
    },
    delete: {
      data: null,
      isOpen: false,
    },
    transfer: {
      data: null,
      isOpen: false,
    },
    upload: {
      data: null,
      isOpen: false,
    },
    general: {
      data: null,
      isOpen: false,
    },
    share: {
      data: null,
      isOpen: false,
    },
  },
  isOpenExpirationModal: false,
  isOpenFileManagerModal: false,
  isOpenImagePreview: {
    url: null,
    isOpen: false,
  },
  isOpenNewCompanyModal: false,
};

export const toggleSlice = createSlice({
  name: "toggle",
  initialState,
  reducers: {
    toggleDrawerEditor(state, { payload }) {
      const currentState = current(state.isOpenDrawerEditor);
      const updatedState = {
        ...currentState,
        ...payload,
      };
      state.isOpenDrawerEditor = updatedState;
    },
    toggleDialogBox(state, { payload }) {
      const currentState = current(state.isOpenDialogBox);
      const updatedState = {
        ...currentState,
        ...payload,
      };
      state.isOpenDialogBox = updatedState;
    },
    toggleExpirationModal(state, { payload }) {
      state.isOpenExpirationModal = payload;
    },
    toggleFileManagerModal(state, { payload }) {
      state.isOpenFileManagerModal = payload;
    },
    toggleImagePreview(state, { payload }) {
      state.isOpenImagePreview = payload;
    },
    toggleNewCompanyModal(state, { payload }) {
      state.isOpenNewCompanyModal = payload;
    },
  },
});

export const {
  toggleDrawerEditor,
  toggleDialogBox,
  toggleExpirationModal,
  toggleFileManagerModal,
  toggleImagePreview,
  toggleNewCompanyModal,
} = toggleSlice.actions;

export const selectDrawerEditor = () =>
  useAppSelector((state) => state.toggle.isOpenDrawerEditor);
export const selectDialogBox = () =>
  useAppSelector((state) => state.toggle.isOpenDialogBox);
export const selectExpirationModal = () =>
  useAppSelector((state) => state.toggle.isOpenExpirationModal);
export const selectFileManagerModal = () =>
  useAppSelector((state) => state.toggle.isOpenFileManagerModal);
export const selectImagePreview = () =>
  useAppSelector((state) => state.toggle.isOpenImagePreview);
export const selectNewCompanyModal = () =>
  useAppSelector((state) => state.toggle.isOpenNewCompanyModal);

const toggleReducer = toggleSlice.reducer;

export default toggleReducer;
