import { Route } from "react-router-dom"
import { crmRoutes } from "../enums/Route"
import ProtectedRoute from "./ProtectedRoute"
import { Fragment } from "react"
import AcceptInvitation from "../containers/invitation/AcceptInvitation"

export const AcceptInvitationRoute = () => (
    <Fragment>
        <Route 
            path={crmRoutes.other.acceptInvitation}
            element={
                <ProtectedRoute>
                    <AcceptInvitation />
                </ProtectedRoute>
            }
        />
    </Fragment> 
)

