import React from "react";
import { useForm } from "react-hook-form";

import { useDispatch } from "react-redux";
import { GeneralForm } from "../../components/AccComponents/GeneralForm";
import { sendEmailTransaction } from "../../stores/slices/transactionSlices";
import { transactionTypeNameEnum } from "../../enums/Transaction";

export const SendTransactionForm = ({
  handleClose,
  sendEmailLoading,
  transaction,
  company,
  user,
  options,
  formDesign,
}) => {
  const dispatch = useDispatch();
  const defaultValues = {
    emailTo: "",
    cc: null,
    replyTo: null,
    subject: `${transactionTypeNameEnum?.[transaction?.type] || ""} ${
      transaction?.transaction_no || ""
    } received from ${company?.legal_name || ""}`,
    personalMessage: "",
    formDesign: formDesign,
  };
  const {
    control,
    handleSubmit,
    resetField,
    setValue,
    watch,
    formState: { isDirty },
  } = useForm({
    defaultValues: defaultValues,
    shouldFocusError: true,
    shouldUnregister: false,
  });

  const handleSendEmail = async (data) => {
    dispatch(
      sendEmailTransaction({
        data: {
          ...data,
          transaction_id: transaction.transaction_id,
        },
        resetField: () => {
          resetField();
        },
      })
    );
  };
  return (
    <GeneralForm
      control={control}
      handleClose={handleClose}
      handleSubmit={handleSubmit(handleSendEmail)}
      setValue={setValue}
      watch={watch}
      isLoading={sendEmailLoading}
      transaction={transaction}
      company={company}
      isDirty={isDirty}
      user={user}
      options={options}
    />
  );
};

