import React from 'react'
import { MdOutlineTipsAndUpdates } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'

function ImportErrorMessages() {

    const navigate = useNavigate();

  return (
    <div className='content'>

        <span>There are rules and formats need to be followed while importing data You may encounter errors under certain situations 
            Therefore, the table below is created that indicate all the errors that you may encounter in the future</span>

        <ul>
            <li>Error Message List</li>
            <div className='tableInfo'>
                <table>
                    <tr>
                        <th>Trigger Condition</th>
                        <th>Error Message</th>
                    </tr>
                    <tr>
                        <td>CSV File is empty</td>
                        <td>Empty CSV File.</td>
                    </tr>
                    <tr>
                        <td>Columns are not tally (column name from the CSV file does not exist in the system)</td>
                        <td>Columns are not tally Please make sure that all the columns are correct before import.</td>
                    </tr>
                    <tr>
                        <td>{`Import Data Limit > 100k (default: 100k data per import)`}</td>
                        <td>Exceed Import data limit Only 100k data per import.</td>
                    </tr>
                    <tr>
                        <td>Required columns' fields are empty (field/cell that is set to "required" is empty)</td>
                        <td>Make sure all the required field are not empty in the CSV.</td>
                    </tr>
                    <tr>
                        <td>Incorrect Email format (johnny.com instead of johnny@gmail.com)</td>
                        <td>Invalid email format in '<strong><em>columnName</em></strong>'. E.g. 'abc123@gmail.com'</td>
                    </tr>
                    <tr>
                        <td>Incorrect Number format (entered with string of characters instead)</td>
                        <td>Invalid value in '<strong><em>columnName</em></strong>'. Only number are allowed</td>
                    </tr>
                    <tr>
                        <td>Incorrect Phone format (does not match with International format / Malaysia format)</td>
                        <td>Invalid value in '<strong><em>columnName</em></strong>'. E.g. '0162345678'</td>
                    </tr>
                    <tr>
                        <td>Incorrect Status format (value other than "active / inactive" is strictly prohibited)</td>
                        <td>Invalid value in '<strong><em>columnName</em></strong>'. Only number are allowed.</td>
                    </tr>
                    <tr>
                        <td>Invalid Checkbox answer (value given does not exist as an answer, user may want to refer back to what option is set)</td>
                        <td>Checkbox answer does not exist in option under '<strong><em>columnName</em></strong>'
                        If you have two value, it is required to put comma (,) in between. E,g, 'A,B'.</td>
                    </tr>
                    <tr>
                        <td>Invalid Dropdown answer (value given does not exist as an answer, user may want to refer back to what option is set)</td>
                        <td>Dropdown answer does not exist in option under '<strong><em>columnName</em></strong>'.</td>
                    </tr>
                    <tr>
                        <td>Invalid Multiple Choice answer (value given does not exist as an answer, user may want to refer back to what option is set)</td>
                        <td>Multiple Choice answer does not exist in option under '<strong><em>columnName</em></strong>'.</td>
                    </tr>
                    <tr>
                        <td>Invalid UserID (this is under Member Dropdown components and user may want to refer back to member list and copy the UID)</td>
                        <td>Invalid User ID or User is not found in '<strong><em>columnName</em></strong>'.</td>
                    </tr>
                    <tr>
                        <td>Invalid HandlerID (user may want to refer back to member list and copy the UID)</td>
                        <td>Invalid Handler ID or User is not found in this company.</td>
                    </tr>
                </table>
            </div>
        </ul>
    </div>
  )
}

export default ImportErrorMessages