const importErrorMessage = (columnName) => {
    return {
        "invalid-import-duplicate" : `${columnName + ': duplicate value detected'}`,
        "invalid-import-required" : `${columnName + ' is a required field and cannot be empty'}`,
        "invalid-import-handler" : "Handler/HandlerID is not found",
        "invalid-import-member" : "User/UID is not found",
        "invalid-import-checkbox" : "Checkbox answer does not exist",
        "invalid-import-dropdown" : "Dropdown answer does not exist",
        "invalid-import-choice" : "Multiple Choice answer does not exist",
        "invalid-import-date" : "Invalid date format. E.g. '2020-02-07 15:43:22'",
        "invalid-import-email" : "Invalid email format E.g. 'abc123@gmail.com'",
        "invalid-import-number" : "Invalid value. Only number are allowed.",
        "invalid-import-phone" : "Invalid phone format. E.g. '0162345678'",
        "invalid-import-switch" : "Invalid status. E.g. 'active' or 'inactive'",
    }
   
}

export default importErrorMessage