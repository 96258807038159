const component = {
  MuiButton: {
    styleOverrides: {
      root: ({ theme }) => ({
        ...theme.typography.bodyText,
        color: theme.palette.primary.white,
        minWidth: "100px",
        boxShadow: "none",
        height: "40px",
        "&.MuiButton-contained": {
          backgroundColor: theme.palette.primary.primary,
        },
        "&.MuiButton-root.MuiButton-contained .MuiButton-startIcon svg": {
          backgroundColor: theme.palette.primary.primary,
        },
        "&.MuiButton-root.MuiButton-contained": {
          color: theme.palette.primary.white,
          backgroundColor: theme.palette.primary.primary,
          border: `1px solid ${theme.palette.primary.primary}`,
          "&:hover": {
            backgroundColor: theme.palette.background.primary,
          },
          "&:active": {
            color: theme.palette.primary.white,
            "& .MuiButton-endIcon svg ": {
              color: theme.palette.background.white,
            },
          },
        },
        "&.MuiButton-contained .MuiButton-startIcon": {
          "& svg": {
            color: theme.palette.primary.white,
          },
        },
        "&.MuiButton-endIcon": {
          color: theme.palette.primary.white,
        },
        "&.MuiButton-contained:hover": {
          // "& .MuiButton-endIcon svg ": {
          //   color: theme.palette.action.main,
          // },
          boxShadow: "unset",
          // border: `1px solid ${theme.palette.action.main}`,
          // color: theme.palette.action.main,
          // backgroundColor: theme.palette.primary.white,
        },
        "&.MuiButton-contained:active": {
          "& .MuiButton-endIcon svg ": {
            color: theme.palette.action.default,
          },
          boxShadow: "unset",
          border: `1px solid ${theme.palette.action.main}`,
          color: theme.palette.action.main,
          backgroundColor: theme.palette.primary.white,
        },
        "&.MuiButton-text:hover": {
          backgroundColor: "unset",
        },
        "&.MuiButton-text.Mui-disabled": {
          color: theme.palette.text.disabled,
          backgroundColor: theme.palette.action.disabled,
          // opacity: 0.5,
        },
        "&.MuiButton-text.popover-children-button:hover ": {
          backgroundColor: theme.palette.chip.infoBg,
        },
        "&.MuiButton-text:active": {
          backgroundColor: "unset",
        },
        "&.MuiButton-text-plain:hover": {
          backgroundColor: theme.palette.action.disabled,
        },
        "&.MuiButton-text-plain:active": {
          backgroundColor: "unset",
        },
        "&.MuiButton-text-plain::after": {
          content: '""',
          position: "absolute",
          width: "100%",
          transform: "scaleX(0)",
          height: "1px",
          borderRadius: "10px",
          bottom: "0",
          left: "0",
          backgroundColor: theme.palette.action.main,
          transformOrigin: "bottom right",
          transition: "transform 0.25s ease-out",
        },
        "&.MuiButton-text-plain:hover::after": {
          transform: "scaleX(1)",
          transformOrigin: "bottom left",
        },
        "&.MuiButton-cancel": {
          backgroundColor: "unset",
          color: theme.palette.primary.main,
          border: `1px solid ${theme.palette.primary.main}`,
        },
        "&.MuiButton-cancel:hover": {
          backgroundColor: "unset",
        },
        "&.MuiButton-cancel:active": {
          backgroundColor: "unset",
        },
        "&.Mui-disabled": {
          // backgroundColor: theme.palette.action.disabled,
          // color: theme.palette.text.disabled,
          "& .MuiButton-endIcon svg ": {
            // color: theme.palette.text.disabled,
          },
        },

        "&.btn-primary": {
          ...theme.button.btn_primary,
        },
        "&.btn-secondary": {
          ...theme.button.btn_secondary,
        },
        "&.btn-delete": {
          ...theme.button.btn_delete,
        },
        "&.btn-disabled": {
          ...theme.button.btn_disabled,
        },
      }),
    },
  },
};

export default component;
