import { createSlice, current } from "@reduxjs/toolkit"

import { useAppSelector } from "../../hooks/storeHooks";

//initial state
const initialState = {
    
    //loading
    setHeaderTitleLoading : false,
    setCurrUserLoading : false,
    setUserIDLoading : false,
    setUserNameLoading : false,
    setLoginStateLoading : false,

    //error
    setHeaderTitleError : undefined,
    setCurrUserError : undefined,
    setUserIDError : undefined,
    setUserNameError : undefined,
    setLoginStateError : undefined,

    //data
    loginState : "",
    headerTitle : "",
    currUser : null,
    userName : "",
    userID : ""

}

//slices
const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers : {
        setHeaderTitle(state){
            state.setHeaderTitleLoading = true;
        },
        setHeaderTitleSuccess(state, {payload}){
            const currentState = payload;
            state.headerTitle = currentState;

            state.setHeaderTitleLoading = false;
        },
        setHeaderTitleError(state, {payload}){
            const errorState = payload;
            state.setHeaderTitleError = errorState;

            state.setHeaderTitleLoading = false;
        },

        setCurrUser(state){
            state.setCurrUserLoading= true;
        },
        setCurrUserSuccess(state, {payload}){
            const currentState = payload;
            state.currUser = currentState;

            state.setCurrUserLoading= false;
        },
        setCurrUserError(state, {payload}){
            const errorState = payload;
            state.setCurrUserError = errorState;

            state.setCurrUserLoading= false;
        },

        setUserID(state){
            state.setUserIDLoading = true;
        },
        setUserIDSuccess(state, {payload}){
            const currentState = payload;
            state.userID = currentState;

            state.setUserIDLoading = false;
        },
        setUserIDError(state, {payload}){
            const errorState = payload;
            state.setUserIDError = errorState;

            state.setUserIDLoading = false;
        },

        setUserName(state){
            state.setUserNameLoading = true;
        },
        setUserNameSuccess(state, {payload}){
            const currentState = payload;
            state.userName = currentState;

            state.setUserNameLoading = false;
        },
        setUserNameError(state, {payload}){
            const errorState = payload;
            state.setUserNameError = errorState;

            state.setUserNameLoading = false;
        },
        
        setLoginState(state){
            state.setLoginStateLoading = true;
        },
        setLoginStateSuccess(state, {payload}){
            const currentState = payload;
            state.loginState = currentState;

            state.setLoginStateLoading = false;
        },
        setLoginStateError(state, {payload}){
            const errorState = payload;
            state.setLoginStateError = errorState;

            state.setLoginStateLoading = false;
        },
    }
})

//export function
export const {
    setHeaderTitle,
    setHeaderTitleSuccess,
    setHeaderTitleError,
    setCurrUser,
    setCurrUserSuccess,
    setCurrUserError,
    setUserID,
    setUserIDSuccess,
    setUserIDError,
    setUserName,
    setUserNameSuccess,
    setUserNameError,
    setLoginState,
    setLoginStateSuccess,
    setLoginStateError
} = authSlice.actions

//export selector

// data
export const selectLoginState = () =>
  useAppSelector((state) => state.auth.loginState);

export const selectHeaderTitle = () =>
  useAppSelector((state) => state.auth.headerTitle);

export const selectCurrUser = () =>
  useAppSelector((state) => state.auth.currUser);

export const selectUserName = () =>
  useAppSelector((state) => state.auth.userName);

export const selectUserID = () =>
  useAppSelector((state) => state.auth.userID);

// loading
export const selectSetHeaderTitleLoading = () =>
  useAppSelector((state) => state.auth.setHeaderTitleLoading);

export const selectSetCurrUserLoading = () =>
  useAppSelector((state) => state.auth.setCurrUserLoading);

export const selectSetUserIDLoading = () =>
  useAppSelector((state) => state.auth.setUserIDLoading);

export const selectSetUserNameLoading = () =>
  useAppSelector((state) => state.auth.setUserNameLoading);

export const selectSetLoginStateLoading = () =>
  useAppSelector((state) => state.auth.setLoginStateLoading);

// error
export const selectSetHeaderTitleError = () =>
  useAppSelector((state) => state.auth.setHeaderTitleError);

export const selectSetCurrUserError = () =>
  useAppSelector((state) => state.auth.setCurrUserError);

export const selectSetUserIDError = () =>
  useAppSelector((state) => state.auth.setUserIDError);

export const selectSetUserNameError = () =>
  useAppSelector((state) => state.auth.setUserNameError);

export const selectSetLoginStateError = () =>
  useAppSelector((state) => state.auth.setLoginStateError);

//export reducer
const authReducer = authSlice.reducer;

export default authReducer;
