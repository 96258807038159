import React, {useEffect, useState, useRef} from 'react'
import { PieChart, Pie, Cell, Legend } from 'recharts';

import { selectAnalyticsContext } from '../../../stores/slices/analyticsSlices';
import { selectCurrUserContext } from '../../../stores/slices/companySlices';

function Graph_Pie(props) {

  const {
    analytics,
    graphData,
  } = selectAnalyticsContext();

const { currUsers } = selectCurrUserContext();

  const editorState = props.editorState;
  const graphRef = useRef(null);
  const [graphWidth, setGraphWidth] = useState(0);
  const [graphHeight, setGraphHeight] = useState(0);

  const yAxis = analytics.yAxis;
  const xAxis = analytics.xAxis;
  const pieProperty = analytics.PieProperty;

  useEffect(() => {
    setGraphWidth(graphRef.current?.clientWidth * 0.9);
    setGraphHeight(graphRef.current?.clientHeight * 0.85);
  },[analytics, graphData, editorState, pieProperty]);

  const RADIAN = Math.PI / 180;
  const DEFAULT = ['#3b85c0','#fb7b8b','#294679','#575bac','#cfaca4','#623337']
  const COLORS_THEME_1 = ['#d45087','#f95d6a','#ff7c43','#ffa600','#003f5c','#2f4b7c','#665191','#a05195'];
  const COLORS_THEME_2 = ['#00876c','#5fa176','#97ba87','#cbd4a1','#faeec2','#f0c892','#e99f6c','#e17257','#d43d51'];
  const COLORS_THEME_3 = ['#004c6d','#006083','#007598','#008bac','#00b8d2','#00cfe3','#03e7f2','#32ffff']
  const COLORS_THEME_4 = ['#de425b','#dd757d','#d69fa1','#c6c6c6','#b8da99','#a2ed66','#7fff00']
  const COLORS_THEME_5 = ['#2085ec','#72b4eb','#0a417a','#8464a0','#cea9bc','#323232']

  const colorObj = {
    default: DEFAULT,
    "palette 1": COLORS_THEME_1,
    "palette 2": COLORS_THEME_2,
    "palette 3": COLORS_THEME_3,
    "palette 4": COLORS_THEME_4,
    "palette 5": COLORS_THEME_5
  }

  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {

    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

      return (
        <text x={x} y={y} fontSize={pieProperty?.textSize} fill={pieProperty?.textColor} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
          {pieProperty?.LabelKey ? graphData[index][xAxis[0]?.key] : ""} {pieProperty?.Percentage ? `${(percent * 100).toFixed(0)}%` : graphData[index][yAxis[0]?.questionName]}
        </text>
      );
      
  }
  
  const renderPie = () => {
    if(Object.values(pieProperty).length !== 0) {
      const piepie = analytics.yAxis.map((value, index) => {
        if(value.key === "Handler"){
          const userObj = currUsers.find(user => user.uid === value.id);
          if(userObj){
            return (
              <Pie dataKey={userObj.displayName} data={graphData} innerRadius={pieProperty?.innerRadius} label={pieProperty?.LabelPosition === "Inner" ? renderCustomizedLabel : true} labelLine={pieProperty?.LabelPosition === "Outer" && pieProperty?.LabelLine ? true : false }>
            {graphData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={colorObj[pieProperty?.colorPalette][index % colorObj[pieProperty?.colorPalette]?.length]} stroke={pieProperty?.strokeColor} strokeWidth={pieProperty?.strokeWidth} />
            ))}
          </Pie>
            )
          }
        } else {
          return (
            <Pie dataKey={value.key} data={graphData} innerRadius={pieProperty?.innerRadius} label={pieProperty?.LabelPosition === "Inner" ? renderCustomizedLabel : true} labelLine={pieProperty?.LabelPosition === "Outer" && pieProperty?.LabelLine ? true : false }>
            {graphData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={colorObj[pieProperty?.colorPalette][index % colorObj[pieProperty?.colorPalette]?.length]} stroke={pieProperty?.strokeColor} strokeWidth={pieProperty?.strokeWidth} />
            ))}
          </Pie>
          )
        }
      })
     
      return piepie;
    }
  };
  
  return (
    <div className='graphContainer' ref={graphRef}>
      <PieChart
        width={graphWidth} 
        height={graphHeight}
      >
      <Legend
        payload={
          graphData.map(
            (item, index) => ({
              id: item[analytics.xAxis[0]?.key],
              type: "square",
              value: `${item[analytics.xAxis[0]?.key]}`,
              color: Object.values(pieProperty).length !== 0 ? colorObj[pieProperty?.colorPalette][index % colorObj[pieProperty?.colorPalette]?.length] : "#000000"
            })
          )
        }
      />
      
        {renderPie()}
      </PieChart>
    </div>
  )
}

export default Graph_Pie