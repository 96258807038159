import { Route } from "react-router-dom"
import { crmRoutes } from "../enums/Route"
import { Fragment } from "react"
import Login from "../containers/Login"

export const AuthRoute = () => (
    <Fragment>
        <Route 
            path={crmRoutes.auth.auth}
            element={
                <Login />
            }
        />
    </Fragment> 
)

