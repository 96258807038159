import React, {
    useState,
    useEffect,
    Fragment,
  } from "react";
import moment from "moment";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "./Appointments.css";
import "./Appointment.scss";
import { FaUser, FaUsers } from "react-icons/fa";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { MdDescription } from "react-icons/md";
import { HiLocationMarker } from "react-icons/hi";
import { selectAppointments } from "../../stores/slices/appointmentsSlices";

function AppointmentAll(props) {

    const searchText = props.searchText;

    const appointments = selectAppointments();

    const [allAppointment, setAllAppointment] = useState([]);
    const [eventFilter, setEventFilter] = useState("ALL");
    const [allPassEvent, setAllPassEvent] = useState(0);
    const [allUpcomingEvent, setAllUpComingEvent] = useState(0);
    const [openExtension, setOpenExtension] = useState("");

    const isJSON = (str) => {

        if(str !== undefined)
        {
          try{

            JSON.parse(str)
          }
          catch(e)
          {
            return false;
          }
      
          return true;
        }
    }
      
    useEffect(() => {

        const filteredData = appointments.filter(item => {
            if(eventFilter === "ALL"){
                return item
            }else if(eventFilter === "PASS"){
                if(moment(item.end).format("YYYY-MM-DD") < moment().format("YYYY-MM-DD")){
                    return item
                }
            }else if(eventFilter === "UPCOMING"){
                if(moment(item.end).format("YYYY-MM-DD") >= moment().format("YYYY-MM-DD")){
                    return item
                }
            }
        })

        const PassEvent = appointments?.filter(item => {
            if(moment(item.end).format("YYYY-MM-DD") < moment().format("YYYY-MM-DD")){
                return item
            }
        })

        setAllPassEvent(PassEvent?.length)

        const UpcomingEvent = appointments?.filter(item => {
            if(moment(item.end).format("YYYY-MM-DD") >= moment().format("YYYY-MM-DD")){
                return item
            }
        })

        setAllUpComingEvent(UpcomingEvent?.length)

        const searchedData = filteredData.filter(item => {
            if(item.title.toString().toLocaleLowerCase().includes(searchText.toString().toLocaleLowerCase())){
                return item
            }else if(item.start.toString().toLocaleLowerCase().includes(searchText.toString().toLocaleLowerCase())){
                return item
            }else if(item.end.toString().toLocaleLowerCase().includes(searchText.toString().toLocaleLowerCase())){
                return item
            }else if(item.description.toString().toLocaleLowerCase().includes(searchText.toString().toLocaleLowerCase())){
                return item
            }else if(item.AppointmentVenue.toString().toLocaleLowerCase().includes(searchText.toString().toLocaleLowerCase())){
                return item
            }
        });
        
        setAllAppointment(searchedData)

    },[appointments, searchText, eventFilter]);

    function getContrastColor(hexColor) {
        // Remove the # from the beginning of the color string
        const cleanHex = hexColor.replace("#", "");
      
        // Convert the color string to RGB values
        const red = parseInt(cleanHex.substr(0, 2), 16);
        const green = parseInt(cleanHex.substr(2, 2), 16);
        const blue = parseInt(cleanHex.substr(4, 2), 16);
      
        // Convert RGB to HSL
        const hslValues = rgbToHsl(red, green, blue);
      
        // Get the lightness value from the HSL representation
        const lightness = hslValues[2];
      
        // Use white font color if the lightness is less than or equal to 0.5, and gray font color otherwise
        return lightness <= 0.5 ? "#fff" : "#000";
    }

    // Utility function to convert RGB to HSL
    function rgbToHsl(r, g, b) {
        r /= 255;
        g /= 255;
        b /= 255;
        const max = Math.max(r, g, b);
        const min = Math.min(r, g, b);
        let h, s, l = (max + min) / 2;
    
        if (max == min) {
        h = s = 0;
        } else {
        const d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
            case r:
            h = (g - b) / d + (g < b ? 6 : 0);
            break;
            case g:
            h = (b - r) / d + 2;
            break;
            case b:
            h = (r - g) / d + 4;
            break;
        }
        h /= 6;
        }
    
        return [h, s, l];
    }

  return (
    <div className="allAppointmentMainContainer">
        <div className="summaryContainer">
            <div className="filterSubContainer">
                <div className={`filterSelection ${eventFilter === "ALL" && 'selected'}`} onClick={() => { setEventFilter("ALL") }}>
                    <span>All ({appointments.length})</span>
                </div>
                <div className={`filterSelection ${eventFilter === "PASS" && 'selected'}`} onClick={() => { setEventFilter("PASS") }}>
                    <span>Pass ({allPassEvent})</span>
                </div>
                <div className={`filterSelection ${eventFilter === "UPCOMING" && 'selected'}`} onClick={() => { setEventFilter("UPCOMING") }}>
                    <span>Upcoming ({allUpcomingEvent})</span>
                </div>
            </div>
        </div>
        <div className="allAppointmentListMainContainer">
            {allAppointment.length > 0 && allAppointment?.sort((a, b) => new Date(b.start) - new Date(a.start)).map((item, index) => {
   
                return(
                    <Fragment key={index}>
                        <div className={`allAppointmentList ${item?.AppointmentID === openExtension && 'removeBorder'}`}
                            onClick={() => { setOpenExtension(item?.AppointmentID === openExtension ? "" : item?.AppointmentID) }}
                        >
                            <div className="appointmentDate" style={{ backgroundColor: item?.AppointmentColor }}>
                                <span style={{ color: getContrastColor(item?.AppointmentColor) }}>{moment(item.start).format("DD MMM YYYY")}</span>
                            </div>
                            <div className="appointmentDetails">
                                <span>{item?.title}</span>
                            </div>
                            <div className="moreDetailActionContainer">
                                {item?.AppointmentID === openExtension ?
                                    <FiChevronUp size={20}/>
                                    :
                                    <FiChevronDown size={20}/>
                                }
                            </div>
                        </div>
                        {item?.AppointmentID === openExtension &&
                            <div className="detailsExtensionMainContainer">
                                <div className="sections">
                                    <div className="title">
                                        <HiLocationMarker className="icon" size={16}/>
                                        <span>Venue</span>
                                    </div>
                                    <div className="value">
                                        <span>{item?.AppointmentVenue !== "" ? item?.AppointmentVenue : "N/A"}</span>
                                    </div>
                                </div>
                                <div className="sections">
                                    <div className="title">
                                        <MdDescription className="icon" size={16}/>
                                        <span>Description</span>
                                    </div>
                                    {item?.description !== "" ?
                                        // <div className="description" dangerouslySetInnerHTML={{ __html: item?.description }}></div>
                                        <textarea disabled>{item?.description}</textarea>
                                        :
                                        <div className="value">
                                            <span>N/A</span>
                                        </div>
                                    }
                                </div>
                                <div className="sections">
                                    <div className="title">
                                        <FaUser className="icon" size={16}/>
                                        <span>Member</span>
                                    </div>
                                    {!isJSON(item?.memberAttendees) && item?.memberAttendees?.length > 0 ?
                                        <div className="listContainer">
                                            {item?.memberAttendees?.map((item, index) => {
                                                return(
                                                    <div key={index} className="listing">
                                                        <span>{item.displayName}</span>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        :
                                        <div className="value">
                                            <span>N/A</span>
                                        </div>
                                    }
                                </div>
                                <div className="sections">
                                    <div className="title">
                                        <FaUsers className="icon" size={16}/>
                                        <span>Client</span>
                                    </div>
                                    {!isJSON(item?.clientAttendees) && item?.clientAttendees?.length > 0 ?
                                        <div className="listContainer">
                                            {item?.clientAttendees?.map((item, index) => {
                                                return(
                                                    <div key={index} className="listing">
                                                        <span>{item}</span>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        :
                                        <div className="value">
                                            <span>N/A</span>
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                    </Fragment>
                )
            })}
        </div>
        
    </div>
  )
}

export default AppointmentAll