import { Route } from "react-router-dom"
import { crmRoutes } from "../enums/Route"
import ProtectedRoute from "./ProtectedRoute"
import { Fragment } from "react"
import GaTenantQuota from "../containers/gaPortal/GaTenantQuota"
import ProtectedRoute_GA from "./ProtectedRoute_GA"

export const GaTenantQuotaRoute = ({currGaState}) => (
    <Fragment>
        {currGaState &&
            <Route 
                path={crmRoutes.ga.gaTenantQuota}
                element={
                    <ProtectedRoute_GA>
                        <GaTenantQuota />
                    </ProtectedRoute_GA>
                }
            />
        }
    </Fragment> 
)

