import palette from "./palette";

const icons = {
  standard: {
    // color: palette.icon.primary,
    default: {
      width: 20,
      height: 20,
      color: palette.icon.default,
      "&:hover": {
        color: palette.icon.primary,
      },
    },
    primary: {
      width: 20,
      height: 20,
      color: palette.icon.primary,
    },
    white: {
      width: 20,
      height: 20,
      color: palette.icon.white,
    },
    delete: {
      width: 20,
      height: 20,
      color: palette.icon.delete,
    },
    disabled: {
      width: 20,
      height: 20,
      color: palette.icon.disabled,
    },
  },
  medium: {
    default: {
      width: 24,
      height: 24,
      color: palette.icon.default,
      "&:hover": {
        color: palette.icon.primary,
      },
    },
    primary: {
      width: 24,
      height: 24,
      color: palette.icon.primary,
    },
    delete: {
      width: 24,
      height: 24,
      color: palette.icon.delete,
    },
    disabled: {
      width: 24,
      height: 24,
      color: palette.icon.disabled,
    },
  },
  large: {
    width: 28,
    height: 28,
    color: palette.icon.primary,
  },
  profile: {
    width: 35,
    height: 35,
    color: palette.primary.secondary,
  },
  iconButton: {
    width: 35,
    height: 35,
    color: palette.primary.secondary,
  },
};

export default icons;
