import { Route } from "react-router-dom"
import { crmRoutes } from "../enums/Route"
import ProtectedRoute from "./ProtectedRoute"
import { Fragment } from "react"
import Analytics from "../containers/analytics/Analytics"

export const AnalyticsRoute = ({currRolesState, currSaState}) => (
    <Fragment>
        <Route 
            path={crmRoutes.analytics.analytics}
            element={
                <ProtectedRoute>
                    <Analytics isSuperAdmin={currSaState} Analytics_Manage={currRolesState !== undefined ? currRolesState?.Analytics_Manage: false}/>
                </ProtectedRoute>
            }
        />
    </Fragment> 
)

