const component = {
  MuiCheckbox: {
    styleOverrides: {
      root: ({ theme }) => ({
        "&.Mui-disabled.table_column_checkbox": {
          display: "none",
        },
        ".MuiSvgIcon-root": {
          width: 20,
          height: 20,
          fontSize: 20,
          color: theme.palette.border.main,
          borderRadius: "5px",
          border: `1px solid ${theme.palette.border.border_disabled}`,
        },
        "&.Mui-selected": {
          backgroundColor: theme.palette.primary.light,
        },
        "&.Mui-checked .MuiSvgIcon-root": {
          backgroundColor: theme.palette.background.white,
          border: `1px solid ${theme.palette.border.active}`,
          color: theme.palette.primary.success,
          padding: "2px",
          width: 20,
          height: 20,
          fontSize: 20,
          borderRadius: "5px",
          strokeWidth: "2px",
        },
      }),
    },
  },
};

export default component;
