import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import C_Button from "../components/C_Button";
import './404.scss'
import ga from '../enums/json/generalAdmin.json';

import { selectUser } from "../stores/slices/userAuthSlices";
import { selectCurrGa } from "../stores/slices/persistRolesSlices";
import { getFromLocalStorage } from "../util/LocalStorage";

function PageNotFound(props) {

	const navigate = useNavigate();

	const user = selectUser();

	const location = useLocation();

	const refresh_token = getFromLocalStorage("REFRESH_TOKEN");
	const access_token = getFromLocalStorage("ACCESS_TOKEN");
	const isGA = getFromLocalStorage("IS_GA");

	useEffect(() => {
		setTimeout(() => {
			const targetPath = (isGA == "true" && location.pathname === "/GaPortal") ? "/GaPortal" : "/Tenant";
			if (refresh_token && access_token) {
				navigate(targetPath);
			} else {
				navigate('/Auth');
			}
			
		}, 1000)
		
	},[user])

   //                    阿弥陀佛
    //
	//                    _ooOoo_
	//                   o8888888o
	//                   88" . "88
	//                   (| -_- |)
	//                   O\  =  /O
	//                ____/`---'\____
	//              .'  \\|     |//  `.
	//             /  \\|||  :  |||//  \
	//            /  _||||| -:- |||||-  \
	//            |   | \\\  -  /// |   |
	//            | \_|  ''\-/''  |   | |
	//            \  .-\__  `-`  ___/-. /
	//          ___`. .'  /-.-\  `. . _/_
	//       ."" '<  `.___\_<|>_/___.'  >'"".
	//      | | :  `- \`.;`\ _ /`;.`/ - ` : | |
	//      \  \ `-.   \_ __\ /__ _/   .-` /  /
	// ======`-.____`-.___\_____/___.-`____.-'======
	//                    `=-='
    //
    //               佛祖保佑，永无bug
  return (
    <div className="preloadMainContainer">
        <img src={require("../assets/img/preload.gif")}/>
        <span>404 Page Not Found. Redirecting...</span>
    </div>
  );
}

export default PageNotFound;
