import React, {
    useState,
    useEffect,
    useRef,
} from "react";
import moment from "moment";
import C_Button from "../../components/C_Button";
import "./Kpi.scss";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import C_Search from "../../components/C_Search";
import { Modal } from "bootstrap";
import NewKpi from "./NewKpi";
import { RiTeamFill, RiUser2Fill } from "react-icons/ri";
import { BsCalendarRange } from "react-icons/bs";
import EditKpi from "./EditKpi";

import { AiOutlineClose } from "react-icons/ai";
import { FiAlertTriangle } from "react-icons/fi";
import C_DialogButton from "../../components/C_DialogButton";
import { BiArrowBack } from "react-icons/bi";

//slices
import { retrieveCurrUsage } from "../../stores/slices/usageSlices";
import { selectCompanyID } from "../../stores/slices/companyIDSlices";
import { selectCurrTenant } from "../../stores/slices/persistRolesSlices";
import { selectFormTemplate } from "../../stores/slices/formTemplateSlices";
import { selectKpi, selectKpiContext, setKpiContext } from "../../stores/slices/kpiSlices";
import { selectFormSubmission } from "../../stores/slices/formSubmissionSlices";
import { selectCurrUserContext } from "../../stores/slices/companySlices";
import { selectUser } from "../../stores/slices/userAuthSlices";
  
  function KpiSettings(props) {

    const isSuperAdmin = props.isSuperAdmin;
    const Kpi_Delete = props.Kpi_Delete;

    const {
        // triggerAction,
        // dispatchTriggerAction,
        // showKpiSetting,
        // viewKpiData,
        // dispatchShowKpiSetting,
        // dispatchNewKpi,
        // dispatchViewKpi,
        // dispatchViewKpiData,
        newKpi,
        viewKpi,
        // allUser
        // kpiData,
        // submission,
    } = selectKpiContext();

    const forms = selectFormTemplate();

    const { currUserSA, currUsage, currTotalUsage, currUserInvitation} = selectCurrUserContext();
    
    const allUser = currUserSA
    const kpiData = selectKpi();
    const submission = selectFormSubmission();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = selectUser();

    const companyID = selectCompanyID();
    const tenant = selectCurrTenant();
    
    const [searchText, setSearchText] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [currKpiData, setCurrKpiData] = useState([]);

    const [kpiQuota, setKpiQuota] = useState(0);
    const [kpiUsage, setKpiUsage] = useState(0);
    
    useEffect(() => {
        if(currUsage && currTotalUsage !== undefined && currTotalUsage && currUserInvitation){
            const filterQuota = currUsage?.find(item => item.SectionID === "s4")?.Quota;
            const filterUsage = currTotalUsage?.find(item => item.SectionID === "s4")?.Usage;
            setKpiQuota(filterQuota);
            setKpiUsage(filterUsage);
        }else{
            setKpiQuota(0);
            setKpiUsage(0);
        }
    },[currUsage, currTotalUsage, currUserInvitation])

    async function refreshUsage(){
        await Promise.resolve(dispatch(retrieveCurrUsage({TenantID:tenant,CompanyID:companyID})));
    };

    useEffect(() => {

        const searchedData = kpiData.filter(item => {
            if(item.title.toString().toLocaleLowerCase().includes(searchText.toString().toLocaleLowerCase())){
                return item
            }else if(item.startDate.toString().toLocaleLowerCase().includes(searchText.toString().toLocaleLowerCase())){
                return item
            }else if(item.dueDate.toString().toLocaleLowerCase().includes(searchText.toString().toLocaleLowerCase())){
                return item
            }else if(item.target.toString().toLocaleLowerCase().includes(searchText.toString().toLocaleLowerCase())){
                return item
            }else if(item.createdAt.toString().toLocaleLowerCase().includes(searchText.toString().toLocaleLowerCase())){
                return item
            }
        });
        setCurrKpiData(searchedData)

    },[kpiData, searchText]);

    const modalRef = useRef();

    const showModalFunc = () => {
        const modalEle = modalRef.current;
        const bsModal = new Modal(modalEle, {
        backdrop: "static",
        keyboard: false,
        });
        bsModal.show();
    };

    const hideModalFunc = () => {
        const modalEle = modalRef.current;
        const bsModal = Modal.getInstance(modalEle);
        bsModal.hide();
    };

    function findMetricValue(item, allSubmission) {
        let targetMembers = item.targetMembers;
        let metricValue = item.metricValue;
        let results = [];
        
        for (let member of targetMembers) {
          let sum = 0;
          for (let submission of allSubmission) {
            if (member?.uid === submission.UserID) {
              for (let data of submission.SubmissionData) {
                if (data.questionID === metricValue) {
                  sum += parseFloat(data.answer);
                  break;
                }
              }
            }
          }
          results.push({
            displayName: member?.displayName,
            uid: member?.uid,
            teamContribution: item?.teamContribution,
            target: item?.target,
            unit: item?.unitOfMeasurement,
            total: sum
          });
        }

        const achiever = results.filter(data => data.total >= parseInt(item.target));
        const totalValue = achiever.length;
        const percentage = Math.round((achiever.length / item?.targetMembers?.length) * 100);

        return { totalValue, percentage, results };
    };

    function findSubmissionValue(item, allSubmission) {
        let targetMembers = item.targetMembers;
        let results = [];
        
        for (let member of targetMembers) {
          let count = 0;
          for (let submission of allSubmission) {
            if (member?.uid === submission.UserID) {
              count++;
            }
          }
          results.push({
            displayName: member?.displayName,
            uid: member?.uid,
            teamContribution: item?.teamContribution,
            target: item?.target,
            unit: item?.unitOfMeasurement,
            total: count
          });
        };

        const achiever = results.filter(data => data.total >= parseInt(item.target));
        const totalValue = achiever.length;
        const percentage = Math.round((achiever.length / item?.targetMembers?.length) * 100);

        return { totalValue, percentage, results };
    };

    function handleViewKPI({item, percentageWidth, totalValue, percentage, memberContribution}){

        dispatch(setKpiContext({
            viewKpiData : {
                item,
                percentageWidth,
                totalValue,
                percentage,
                memberContribution
            },
            viewKpi : true
        }))
        // dispatchViewKpiData({type: "VIEW_KPI_DATA", payload: {
        //     item,
        //     percentageWidth,
        //     totalValue,
        //     percentage,
        //     memberContribution
        // }})

        // dispatchViewKpi({type: "SHOW_VIEW_KPI_FORM", payload: true})
        
    };

    async function handleOpenNewKpi(){
        await Promise.resolve(refreshUsage());
        if(kpiUsage < kpiQuota){
            dispatch(setKpiContext({newKpi : true}));
            // dispatchNewKpi({ type: "SHOW_NEW_KPI_FORM", payload: true });
        }else{
            alert("You've reached the limit");
        }
    }

    return (
        <>
        <div className="modal fade" ref={modalRef} tabIndex="-1">
            <div className="modal-dialog modal-dialog-centered" style={{}}>
                <div className="modal-content px-3" style={{overflowY: "scroll" }}>
                    <div className="modal-header modalHeader">
                        <div className="title">
                            <FiAlertTriangle size={17} color={"#ca4254"}/>
                            <span className="font-s">No form existed</span>
                        </div>
                        <AiOutlineClose cursor='pointer' size={16} onClick={()=>{hideModalFunc()}} />
                    </div>
                    <div className="modal-body">
                        
                        <div className="" style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                            <span className="font-xs" style={{ fontWeight: "bold" }}>
                                You're required to have at least one form as data source to generate KPI
                            </span>
                        </div>

                        <div style={{ display: "flex", justifyContent: "flex-end",columnGap:'20px' }} className="mt-3 modalshit">
                            <C_DialogButton
                                color={'#4287f5'}
                                background={'white'}
                                borderColor={'#4287f5'}
                                buttonText={"BACK"}
                                onClick={(e) => {
                                    hideModalFunc()
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className={`kpiSettingsMainContainer ${(newKpi || viewKpi) && 'hideMainSettingPage'}`}>
            <div className="headerContent">
                <div className="search">
                    <C_Search
                        placeholder="Search by title, start date, due date, KPI's target, or KPI's created date"
                        onChange={(e) => {
                            setSearchText(e.target.value);
                        }}
                        value={searchText}
                        searchText={searchText}
                        closeFunction={() => {
                            setSearchText("");
                        }}
                    />
                </div>
                <div className="toWorkSpace">
                    {kpiUsage < kpiQuota ?
                        <C_Button
                            backgroundColor={"#381256"}
                            width={"110px"}
                            buttonText={"New KPI"}
                            justify={"center"}
                            background={"#f8fafa"}
                            color={"#000000"}
                            border={"1px solid"}
                            borderColor={"#BCBCBC"}
                            onClick={() => {
                                if(forms.length > 0){
                                    handleOpenNewKpi();
                                }else{
                                    showModalFunc();
                                }
                            }}
                        />
                        :
                        <div className="quotaRestrictedContainerNoPadding">
                            <span>You've reached the limit, <a href="https://zumaxdigital.com/crm-v1/" target="_blank" className="link" >upgrade now!</a></span>
                        </div>
                    }
                    <C_Button
                        gap={"10px"}
                        width={"110px"}
                        buttonText={"Back"}
                        justify={"center"}
                        background={"#f8fafa"}
                        color={"#000000"}
                        border={"1px solid"}
                        borderColor={"#BCBCBC"}
                        icon={<BiArrowBack size={16}/>}
                        onClick={() => {
                            dispatch(setKpiContext({showKpiSetting : false}));
                            // dispatchShowKpiSetting({type: "SHOW_KPI_SETTING", payload: false})
                        }}
                        textColor={"#FFFFFF"}
                    />
                </div>
            </div>
            <div className="mainSettingContainer">
                {currKpiData && currKpiData.length > 0 ?
                    <div className="kpiListMainContainer">
                    {currKpiData && [...currKpiData]?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map(item => {
                        //KPI Calculation Formula//
                        //Filter to get all approved submission belongs to each KPI's
                        const betweenDatesSubmission = submission.filter(submission => {
                            const createdAt = new Date(submission.CreatedAt);
                            const startDate = new Date(item.startDate);
                            startDate.setHours(0, 0, 0, 0);
                            const dueDate = new Date(item.dueDate);
                            dueDate.setHours(23, 59, 59, 999);

                            return createdAt >= startDate && createdAt <= dueDate;
                        });
                        const allSubmission = betweenDatesSubmission.filter(sub => sub.FormID === item.sourceOfData && sub.SubmissionStatus === "1");
                        //If it is team contribution, sum up total value
                        //Else check total completion for this KPI
                        
                        var totalValue = 0;
                        var percentage = 0;
                        var memberContribution = [];
                        
                        if(item.teamContribution){
                            if(item.measuredBy !== "Submission"){
                                const metricValueAnswer = allSubmission.map(sub => sub.SubmissionData.find(data => data.questionID === item.metricValue));
                                memberContribution = findMetricValue(item, allSubmission).results;
                                var total = memberContribution.reduce((sum, item) => sum + parseInt(item.total), 0);
                                totalValue = total;
                                percentage = Math.round((total / item.target) * 100)
                            }else{
                                memberContribution = findSubmissionValue(item, allSubmission).results;
                                var total = memberContribution.reduce((sum, item) => sum + parseInt(item.total), 0);
                                totalValue = total;
                                percentage = Math.round(( total / item.target) * 100)
                            }
                        }else{
                            if(item.measuredBy !== "Submission"){
                                totalValue = findMetricValue(item, allSubmission).totalValue;
                                percentage = findSubmissionValue(item, allSubmission).results.length > 0 ?
                                                findSubmissionValue(item, allSubmission).percentage : 0;
                                memberContribution = findMetricValue(item, allSubmission).results;
                            }else{
                                totalValue = findSubmissionValue(item, allSubmission).totalValue;
                                percentage = findSubmissionValue(item, allSubmission).results.length > 0 ?
                                                findSubmissionValue(item, allSubmission).percentage : 0;
                                memberContribution = findSubmissionValue(item, allSubmission).results;
                            }
                        }
                        
                        var percentageWidth = (percentage > 100 ? 100 : percentage) + "%";
                        var unitOfMeasurement = "(" + item.unitOfMeasurement + ")"
                        ///////////////////////////
                        const targetMemberDetails = item?.targetMembers.map((member) => {
                        const user = allUser.find((user) => user.uid === member.uid)
                        return user ? 
                                {   
                                    uid: user.uid,
                                    displayName: user.displayName,
                                    userProfile: user.photoURL
                                } 
                                : 
                                {   
                                    uid: "User Not Existed",
                                    displayName: "User Not Existed",
                                    userProfile: "https://firebasestorage.googleapis.com/v0/b/rexsoft-crm.appspot.com/o/memberProfile%2FdeletedUser.jpeg?alt=media&token=bf97ce76-b439-4c51-aae0-9db0cc0d5be9"
                                };
                        })

                        const displayNames = targetMemberDetails.map(name => name.displayName);
                        const displayNamesString = displayNames.join(", ");

                        return(
                            <div key={item.KpiID} className="kpiMainContainer" onClick={() => {
                                handleViewKPI(
                                    {
                                        item: item,
                                        percentageWidth: percentageWidth,
                                        totalValue: totalValue,
                                        percentage: percentage,
                                        memberContribution: memberContribution
                                    }
                                ) 
                            }}>
                                <div className="kpiDetailsContainer1">
                                    <span>{item?.title}</span>
                                    <div className="contributionTypeContainer">
                                        {item?.teamContribution ?
                                           <RiTeamFill size={20}/> : <RiUser2Fill size={20}/>
                                        }
                                    </div>
                                </div>
                                <div className="kpiDetailsContainer2">
                                    <div className="contributorMainContainer">
                                        <div className="contributorContainer">
                                            {targetMemberDetails && targetMemberDetails?.slice(0, 5).map((target, index) => {
                                                return(
                                                    <img key={index} src={target.userProfile} alt=""/>
                                                )
                                            })}
                                        </div>
                                        <div className="totalContainer">
                                            <span title={displayNamesString}>{targetMemberDetails && targetMemberDetails.length} people</span>
                                        </div>
                                    </div>
                                    <div className="progress-container">
                                        <div className="progress-bar" style={{
                                            width: percentageWidth,
                                            backgroundImage: percentage > 80 ? "linear-gradient(to right top, #138829, #32a44a, #4bc26a, #63df8b, #7cfead)" : 
                                                percentage > 60 ? "linear-gradient(to right top, #004cb2, #185dc5, #286ed8, #367fec, #4391ff)" : 
                                                percentage > 40 ? "linear-gradient(to right top, #634c4c, #725a5b, #81686b, #91767b, #a0858c)" : 
                                                percentage > 20 ? "linear-gradient(to right top, #a80000, #c02f26, #d74e46, #ec6b67, #ff8888)" : "linear-gradient(to right top, #762398, #962bb0, #b732c7, #da38de, #ff3df3)",
                                        }}>
                                            <div className="progress-value">
                                                <span>{percentage}% {!item?.teamContribution && "Achieved"}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="kpiDetailsContainer3">
                                    <div className="dateContainer">
                                        <BsCalendarRange size={12}/>
                                        <span>{moment(item.startDate).format("DD-MM-YYYY")}</span>
                                        <span>~</span>
                                        <span>{moment(item.dueDate).format("DD-MM-YYYY")}</span>
                                    </div>
                                    <div className="targetContainer">
                                        <span>Target {item.unitOfMeasurement !== "" && unitOfMeasurement}: </span>
                                        <span><strong><em>{item?.teamContribution && totalValue}{item?.teamContribution && " / "}{item.target}</em></strong></span>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
                    :
                    <div className="adminEmpty">
                        <img src={require("../../assets/img/adminEmptyKPI.gif")} alt=""/>
                        <span>NO KPI AVAILABLE</span>
                    </div>
                }
                </div>
        </div>

        <div className={`newKpiMainContainer ${newKpi && 'showNewKpi'}`}>
            <NewKpi/>
        </div>
        <div className={`viewKpiMainContainer ${viewKpi && 'showViewKpi'}`}>
            <EditKpi isSuperAdmin={isSuperAdmin} Kpi_Delete={Kpi_Delete}/>
        </div>
        </>
    );
  }
  
  export default KpiSettings;
  