import { call, delay, put, takeLatest } from "redux-saga/effects";
import { 
    setCompanyID,
    setCompanyIDError, 
    setCompanyIDForRole, 
    setCompanyIDForRoleError, 
    setCompanyIDForRoleSuccess, 
    setCompanyIDSuccess 
} from "../stores/slices/companyIDSlices";

function* setCompanyIDSaga({payload}) {
    try{
        yield put(setCompanyIDSuccess(payload));
    } catch (error){
        yield put(setCompanyIDError(error));
        console.error(error);
    }
}

function* setCompanyIDForRoleSaga({payload}) {
    try{
       yield put(setCompanyIDForRoleSuccess(payload));
    } catch (error){
        yield put(setCompanyIDForRoleError(error));
        console.error(error);
    }
}

export function* companyIDSagaWatcher(){
    yield takeLatest(setCompanyID.type, setCompanyIDSaga);
    yield takeLatest(setCompanyIDForRole.type, setCompanyIDForRoleSaga);
}