import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
  Popper,
  Stack,
  Box,
  Fade,
  Chip,
} from "@mui/material";
import { HelpOutlineRounded, SearchRounded } from "@mui/icons-material";
import { ChipStatus } from "../../enums/components/Chip";


export const InputTextPopover = ({
  isDisabled,
  startAdornment,
  endAdornment = (
    <SearchRounded
      sx={({ icons }) => ({
        ...icons.standard,
        overflow: "hidden",
        textOverflow: "ellipsis",
        right: "22px",
      })}
    />
  ),
  tooltips,
  multiline = false,
  minWidth,
  maxWidth,
  fullWidth = true,
  onChange,
  label,
  popoverOptions = [],
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const startAdornmentComponent = startAdornment ? (
    <InputAdornment position="start" sx={{ marginLeft: "5px" }}>
      {startAdornment}
    </InputAdornment>
  ) : null;

  const endAdornmentComponent = endAdornment ? (
    <InputAdornment position="end" sx={{ marginRight: "5px" }}>
      {endAdornment}
    </InputAdornment>
  ) : null;

  const tooltipsAdornmentComponent = tooltips ? (
    <InputAdornment position="end">
      <Tooltip title={tooltips}>
        <IconButton
          sx={{ position: "absolute", right: 0, marginRight: "-40px" }}
        >
          <HelpOutlineRounded sx={({ icons }) => ({ ...icons.standard })} />
        </IconButton>
      </Tooltip>
    </InputAdornment>
  ) : null;

  const endAdornmentCombinationComponent = (
    <>
      {endAdornmentComponent}
      {tooltipsAdornmentComponent}
    </>
  );

  const handleTextFieldFocus = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const isOpen = Boolean(anchorEl);

  return (
    <>
      <TextField
        sx={{ minWidth: minWidth, maxWidth: maxWidth }}
        autoComplete="off"
        aria-autocomplete="none"
        multiline={multiline}
        fullWidth={fullWidth}
        label={label}
        variant="filled"
        disabled={isDisabled}
        onChange={onChange}
        onFocus={handleTextFieldFocus}
        onBlur={() => {
          setAnchorEl(null);
        }}
        InputProps={{
          style: multiline ? { height: "125px" } : { height: "55px" },
          startAdornment: startAdornmentComponent,
          endAdornment: endAdornmentCombinationComponent,
        }}
      />
      <Popper
        open={isOpen ? true : false}
        placement={"bottom-start"}
        anchorEl={anchorEl}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={anchorEl ? 350 : 0}>
            <Stack
              sx={{
                bgcolor: "#FFFFFF",
                boxShadow:
                  "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
                gap: "5px",
                padding: "15px",
                borderRadius: "4px",
                width: `${anchorEl ? anchorEl.clientWidth + 48 : 0}px`,
              }}
            >
              <Typography
                sx={({ typography }) => ({
                  ...typography.remark,
                })}
              >
                {"You can search for"}
              </Typography>
              <Box>
                {popoverOptions.map((option) => {
                  return (
                    <Chip
                      key={`search-chip-${option}`}
                      sx={{
                        marginRight: "5px",
                      }}
                      label={option}
                      color={ChipStatus.Info}
                    />
                  );
                })}
              </Box>
            </Stack>
          </Fade>
        )}
      </Popper>
    </>
  );
};

InputTextPopover.propTypes = {
  isDisabled: PropTypes.any,
  startAdornment: PropTypes.any,
  endAdornment: PropTypes.any,
  tooltips: PropTypes.any,
  multiline: PropTypes.any,
  minWidth: PropTypes.any,
  maxWidth: PropTypes.any,
  fullWidth: PropTypes.any,
  label: PropTypes.any,
  onChange: PropTypes.func,
  popoverOptions: PropTypes.any,
};
