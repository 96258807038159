import React, { useEffect, useState } from 'react'
import Sidebar_Guide from '../components/Sidebar_Guide'
import { useSearchParams } from "react-router-dom";
import { BiChevronRight } from 'react-icons/bi';
import GettingStartedWithClients from './GettingStartedWithClients';
import ManagingData from './ManagingData';
import ImportAndExport from './ImportAndExport';
import ImportDataPractices from './ImportDataPractices';
import ImportDataFormat from './ImportDataFormat';
import ImportPrivilege from './ImportPrivilege';
import ImportErrorMessages from './ImportErrorMessages';


function MainClients_Guide() {

    const subTopic = [
        // {
        //     id: 1,
        //     title: 'Getting Started with Clients',
        // },
        // {
        //     id: 2,
        //     title: 'Managing Data',
        // },
        {
            id: 3,
            title: 'Import & Export',
        },
        {
            id: 4,
            title: 'Import Data Practices',
        },
        {
            id: 5,
            title: 'Import Privilege Comparison: Admin vs User',
        },
        {
            id: 6,
            title: 'Ideal Import Data Format',
        },
        {
            id: 7,
            title: 'List of Import Error Messages',
        }

    ]

    const [selectedSubTopic, setSelectedSubTopic] = useState(subTopic[0]);

    function handleSelectSubTopic(item){
        setSelectedSubTopic(item);
    }

  return (
    <div className="holyContainer">
      {/* <div>
        <Sidebar_Guide/>
      </div> */}
      <div className="holyContentContainer">
        <div className='mainSubTopicContainer'>
            <div className='subTopicContainer'> 
                {subTopic.map(item => {
                    return(
                        <div key={item.id} className='subTopic' onClick={() => { handleSelectSubTopic(item) }}>
                            <BiChevronRight className={`icon ${selectedSubTopic.id === item.id && 'iconSelected'}`} size={13}/>
                            <span className={`${selectedSubTopic.id === item.id && 'selectedSpan'}`}>{item.title}</span>
                        </div>
                    )
                })}
            </div>
        </div>
        <div className='mainContentContainer'>
            <div className='contentHeader'>
                <span>{selectedSubTopic.title}</span>
            </div>
            <div className='contentBody'>
                {/* {selectedSubTopic.id === 1 && <GettingStartedWithClients/>}
                {selectedSubTopic.id === 2 && <ManagingData/>} */}
                {selectedSubTopic.id === 3 && <ImportAndExport/>}
                {selectedSubTopic.id === 4 && <ImportDataPractices/>}
                {selectedSubTopic.id === 5 && <ImportPrivilege/>}
                {selectedSubTopic.id === 6 && <ImportDataFormat/>}
                {selectedSubTopic.id === 7 && <ImportErrorMessages/>}
            </div>
        </div>
      </div>
    </div>
  )
}

export default MainClients_Guide