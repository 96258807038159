import React, {
  useState,
  useEffect,
  useRef,
  Fragment,
} from "react";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import { useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import C_Button from "../../components/C_Button";
import { useNavigate, useSearchParams, createSearchParams } from "react-router-dom";
import moment from "moment";
import { FiRotateCw } from "react-icons/fi";
import { HiOutlinePencilAlt } from "react-icons/hi";
import { HiOutlineMagnifyingGlass, HiOutlineTrash } from "react-icons/hi2";
import Colors from "../../constants/Colors";
import ".././form_template/Form.scss";
import "./FormList.scss"
import { BiArrowBack, BiQuestionMark } from "react-icons/bi";
import { RiTimer2Line } from "react-icons/ri";
import { AiOutlineCheckCircle, AiOutlineClose, AiOutlineCloseCircle } from "react-icons/ai";
import { MdNavigateBefore, MdNavigateNext, MdOutlineContentCopy } from "react-icons/md";

import C_IconButton from "../../components/C_IconButton";
import C_PopoutNoti from "../../components/C_PopoutNoti";
import ReactPaginate from "react-paginate";
import { Modal } from "bootstrap";

//slices
import { deleteFormSubmission, retrieveAllFormsCount, retrieveFormSubmission, selectFormsCount } from "../../stores/slices/formSubmissionSlices";
import { retrieveFormTemplate, selectFormContext } from "../../stores/slices/formTemplateSlices";
import { setFormSubmissionID } from "../../stores/slices/formSubmissionIDSlices";
import { retrieveClientSelected, selectClientSelected } from "../../stores/slices/clientSelectorSlices";
import { retrieveCurrUsage } from "../../stores/slices/usageSlices";
import { retrieveCompanyAgent } from "../../stores/slices/companyAgentSlices";
import { selectCompanyID } from "../../stores/slices/companyIDSlices";
import { retrieveAvailableGroup } from "../../stores/slices/clientSlices";
import { selectTenant } from "../../stores/slices/tenantSlices";
import { selectCurrSa, selectCurrTenant } from "../../stores/slices/persistRolesSlices";
import { selectCurrUserContext, setCompanyUsage } from "../../stores/slices/companySlices";
import { selectUser } from "../../stores/slices/userAuthSlices";

function FormList() {

  const {
    memberSubmissions,
    memberFormTemplates,
  } = selectFormContext();

  const clientSelectedList = selectClientSelected();

  const { currUsage, currTotalUsage, currUserSA, currUserInvitation } = selectCurrUserContext();

  const [searchParams, setSearchParams] = useSearchParams();
  const [viewAllSubmittedForm, setViewAllSubmittedForm] = useState(false);
  const [viewFormID, setViewFormID] = useState("");
  const navigate = useNavigate()
  const dispatch = useDispatch();

  const companyID = selectCompanyID();
  const formsCount = selectFormsCount();
  const tenant = selectCurrTenant();
  const isSA = selectCurrSa();

  const [viewApprovedForm,setViewApprovedForm] = useState(false);
  const [isPreview,setIsPreview] = useState(false);
  const [currFormPreviewID,setCurrFormPreviewID] = useState("");
  const [checkboxArray, setCheckboxArray] = useState([]);
  const [radioArray, setRadioArray] = useState([]);
  const [previousReason,setPreviousReason] = useState("");
  const [isEmptyForm,setIsEmptyForm] = useState(false);
  const [notifyMessage,setNotifyMessage] = useState("");

  const user = selectUser();
  const modalRef = useRef();

  const [submissionQuota, setSubmissionQuota] = useState(0);
  const [submissionUsage, setSubmissionUsage] = useState(0);

  const [formStatus,setFormStatus] = useState("0");
  const [submissionArr,setSubmissionArr] = useState([]);

  useEffect(()=>{
    setSubmissionArr(memberSubmissions.filter(form=>form.FormID === viewFormID && form.SubmissionStatus === formStatus));
  },[viewFormID, formStatus, memberSubmissions])
 
  useEffect(() => {
      if(currUsage && currTotalUsage !== undefined && currTotalUsage && currUserInvitation){
          const filterQuota = currUsage?.find(item => item.SectionID === "s9")?.Quota;
          const filterUsage = currTotalUsage?.find(item => item.SectionID === "s9")?.Usage;
          setSubmissionQuota(filterQuota);
          setSubmissionUsage(filterUsage);
      }else{
          setSubmissionQuota(0);
          setSubmissionUsage(0);
      }
  },[currUsage, currTotalUsage, currUserSA, currUserInvitation])

  async function refreshUsage(){
    dispatch(retrieveCurrUsage({TenantID:tenant,CompanyID:companyID}));
  };

  useEffect(() => {
      if(companyID && tenant){
        refreshUsage()
      }
  },[companyID, tenant])

  const showModalFunc = () => {
    const modalEle = modalRef.current;
    const bsModal = new Modal(modalEle, {
      backdrop: "static",
      keyboard: false,
    });
    bsModal.show();
  };

  const hideModalFunc = () => {
    const modalEle = modalRef.current;
    const bsModal = Modal.getInstance(modalEle);
    bsModal.hide();
  };

  useEffect(() => {
    if(tenant && companyID){
      // dispatch(setCompanyUsage({
      //   currUserSA : currUserSA, 
      //   currUserInvitation : currUserInvitation,
      //   TenantID : tenant,
      //   CompanyID : companyID,
      // }))
      dispatch(retrieveAvailableGroup({ TenantID:tenant,CompanyID: companyID, UserID: user?.uid, isSA: isSA }));
      dispatch(retrieveFormTemplate({TenantID:tenant,CompanyID:companyID, isSA: isSA }));
      dispatch(retrieveCompanyAgent({TenantID:tenant,CompanyID:companyID}));

      if(viewAllSubmittedForm)
      {
        dispatch(retrieveAllFormsCount({ TenantID:tenant,UserID: user?.uid,Type:"all"}))
      }
    }

    if(tenant){
      dispatch(retrieveFormSubmission({TenantID:tenant,UserID: user?.uid,Type:'personal'}));
    }
      
  }, [tenant, companyID, user])

  useEffect(() => {
    function receiveCode() {
      // console.log(searchParams.get("code"));
      // console.log(searchParams.get("codeSubmitted"));
      if (searchParams.get("code") !== null && searchParams.get("code") === "201") {
        notify("update")
        setSearchParams("")
        //await new Promise(()=>{setTimeout(, 100)})
      } else if (searchParams.get("code") !== null && searchParams.get("code") === "200") {
        notify("submit")
        setSearchParams("");
      } else if (searchParams.get("code") !== null && searchParams.get("code") === "202") {
        notify("submit")
        setSearchParams("");
      }
      searchParams.delete("code");
    }

    return () => {
      receiveCode();
    }
  })

  useEffect(() => {

    if(currFormPreviewID !== undefined)
    {
    var checkbox = [];
    var radio = [];
    var question = [];
    var answer = [];
    var clientList = [];
    var reason = "";
    var formID = "";

    //question
    memberSubmissions.map((item) => {
      if (item.SubmissionID === currFormPreviewID) {
        formID = item.FormID;
        reason = item.ReasonRejected;
      }
    })

    memberFormTemplates.map((element, index) => {
      if (element.FormID === formID) {
        question = element.FormData;
      }
    })

    memberSubmissions.map(item=>{
      if(currFormPreviewID === item.SubmissionID)
      {
        answer = item.SubmissionData;
      }
    
    })

    question.map((ques, index) => {
      checkbox.push([]);
      radio.push([]);
    })

    question.map((ques, index) => {
      answer.map((answer, answerIndex) => {
        if (answer.questionID === ques.questionID) {

          if (ques.questionType === "Checkboxes") {
            ques.option.map(option => {
              if (answer.answer.includes(option)) {
                checkbox[index].push(true);
              }
              else {
                checkbox[index].push(false);
              }

            })
          }
          if (ques.questionType === "Multiple Choice") {
            ques.option.map(option => {
              if (answer.answer.includes(option)) {
                radio[index].push(true);
              }
              else {
                radio[index].push(false);
              }

            })
          }
          if (ques.questionType === "Client"){
            
            clientList.push(answer.answer)
            
          }
        }

      })
    })
    dispatch(retrieveClientSelected({ TenantID:tenant,selectedClient: clientList }))
    setCheckboxArray(checkbox);
    setRadioArray(radio);
    setPreviousReason(reason)
    }
  }, [currFormPreviewID])

  useEffect(()=>{
    var empty = true;
    memberSubmissions.map((form)=>{
      if(form.UserID === user?.uid)
      {
        if(form.FormID === viewFormID)
        {
          if(formStatus === "1")
          {
            if(form.SubmissionStatus === "1")
            {
              empty=false;
            }
          }
          else
          {
            if(form.SubmissionStatus !== "1")
            {
              empty = false;
            }
          }
         
        }
      }
    })

    setIsEmptyForm(empty);
    
    const CurrPage = 0;

    if(tenant){
      if(viewFormID !== "")
      {
        if(formStatus === "1")
        {
          dispatch(retrieveAllFormsCount({ TenantID:tenant,UserID: user?.uid,Type:"approved",FormID:viewFormID }))
          dispatch(retrieveFormSubmission({TenantID:tenant,CurrPage:CurrPage,UserID:user?.uid,Type:"approved",FormID:viewFormID}))
        }
        else
        {
          dispatch(retrieveAllFormsCount({ TenantID:tenant,UserID: user?.uid,Type:"submission",FormID:viewFormID }))
          dispatch(retrieveFormSubmission({TenantID:tenant,CurrPage:CurrPage,UserID:user?.uid,Type:"submission",FormID:viewFormID}))
        }
      }
      else
      {
        dispatch(retrieveFormSubmission({TenantID:tenant,UserID:user?.uid,Type:'personal'}))
      }
    }

  },[viewFormID,formStatus,tenant])


  const onHandlePageChange = (CurrPage,type) =>{

    switch(type)
    {
      case "all" :
        dispatch(retrieveAllFormsCount({ TenantID:tenant,UserID: user?.uid }))
        dispatch(retrieveFormSubmission({TenantID:tenant,CurrPage:CurrPage,UserID:user?.uid}))
        break;
      case "approved" :
        dispatch(retrieveAllFormsCount({ TenantID:tenant,UserID: user?.uid,Type:"approved",FormID:viewFormID }))
        dispatch(retrieveFormSubmission({TenantID:tenant,CurrPage:CurrPage,UserID:user?.uid,Type:"approved",FormID:viewFormID}))
        break;
      case "submission" :
        dispatch(retrieveAllFormsCount({ TenantID:tenant,UserID: user?.uid,Type:"submission",FormID:viewFormID }))
        dispatch(retrieveFormSubmission({TenantID:tenant,CurrPage:CurrPage,UserID:user?.uid,Type:"submission",FormID:viewFormID}))
        break;
      default:
        dispatch(retrieveAllFormsCount({ TenantID:tenant,UserID:"" }))
        dispatch(retrieveFormSubmission({TenantID:tenant,CurrPage:"",UserID:""}))
        break;
    }
   

  }

  //function
  const isRejectedFormExpired = (status,date) => {
    // moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
    var timeDiff = "";
    var dayDiff = "";
    var current = new Date();
    var approvalDate = new Date(date);
    if(status === "2" || status === "3")
    {
      timeDiff = current - approvalDate
      dayDiff = timeDiff / (1000 * 3600 * 24)
      if(dayDiff > 2)
      {
        return true;
      }
      else
      {
        return false;
      }
    }

  }

  const submissionCount = (formID,status) =>{
    var i = 0;

    memberSubmissions.map((form)=>{
      if(user?.uid === form.UserID)
      {
        if(form.SubmissionStatus === status)
        {
          if(form.FormID === formID)
          {
            i++;
          }
        }
      }
      
    })

    return i;
  }

  const removeForm = (SubmissionID) =>{
    dispatch(deleteFormSubmission({ TenantID:tenant, SubmissionID:SubmissionID }));
  }

  function notify(type){
    switch(type)
    {
      case "submit":
        setNotifyMessage("SUBMITTED")
        break;
      case "update":
        setNotifyMessage("UPDATED")
        break;
      case "copy":
        setNotifyMessage("COPIED")
        break;
      default:
        setNotifyMessage("SAVED")
        break;
    }
    setTimeout(() => {
      setNotifyMessage("");
    }, 1500)
  };

  return (
    <Fragment>
      <ToastContainer />
      <div className="sidebar-main-container">
          <Sidebar />
        </div>
        <div className="main-container">
        <Header title={"Form"} />
        <div
          className="px-5 py-3"
          style={{
            height:'100%',
            position: "relative",
            overflow: "hidden"
            //backgroundColor: "white",
          }}
        >
          <C_PopoutNoti triggerSaved={notifyMessage}/>
          <div className="" style={{ height:'100%'}} >

            <div className="row d-flex">
              <div className="col-8 d-flex" style={viewAllSubmittedForm ?{paddingLeft:'80px',justifyContent: 'start', alignItems: 'center' }:{paddingLeft:'40px',justifyContent: 'start', alignItems: 'center' }}>
              {viewAllSubmittedForm ? <>
                <BiArrowBack className="mt-1" style={{marginBottom: '10px', cursor: 'pointer',alignItems:'center' }} size={20} onClick={()=>{setViewAllSubmittedForm(false);}}  />
                <span className="font-xl" style={{ marginLeft:'30px', marginRight:'10px',whiteSpace:'nowrap' }}>
                      Submitted Form
                  </span><div className="d-flex form-expiration-alert">
                          <BiQuestionMark  size={15} />
                          <span className="expiration-span">Rejected Form without reapplication will be expired in 3 days</span>
                      </div></> : <><span className="font-xl" style={{marginLeft:'30px',marginRight:'10px'}}>
                      List of Applicable Forms
                  </span></>}
              </div>
             
            </div>
            <div className="form-list-container">

              {viewAllSubmittedForm ?
                <>
                  {memberFormTemplates.length > 0 ? memberFormTemplates.map((item, index) => {
                    if(memberSubmissions && memberSubmissions.some((form) => form.FormID === item.FormID))
                    {
                    return (
                      <>
                        {memberSubmissions && memberSubmissions.map((form) => {
                          if (form.FormID === item.FormID) {
                            return (
                              <div className="px-4 py-4 mt-3" style={{ border: 'none', background: '#fcffff', boxShadow: '2px 2px 4px 1px rgba(0,0,0,0.1)', borderRadius: '10px',position: 'relative' }}>
                                <div className="d-flex remark-text" style={{opacity:'0.8',paddingBottom:'5px',fontWeight:'bold',alignItems:'center'}} ><span style={{cursor:'pointer',color:'#4895cf'}} 
                                onClick={()=>{ notify("copy");navigator.clipboard.writeText(form.FormTrackingID);}}>Ref ID: {form.FormTrackingID}</span>
                                <MdOutlineContentCopy size={14} onClick={()=>{notify("copy"); navigator.clipboard.writeText(form.FormTrackingID);}} style={{cursor:'pointer',marginLeft:'5px'}}/>
                            </div>
                                <div className="d-flex flex-row py-3 px-3" style={{ background: 'rgb(95, 94, 175,0.1)', borderRadius: '10px' }}>
                                  <div className="col-md-6 d-flex flex-column">
                                    <span className="font-m" style={{ fontWeight: 'bold', whiteSpace: 'nowrap', textOverflow: 'ellipsis', marginBottom: '5px' }}>{item.FormName}</span>
                                    <span className="clamp-text-formlist font-xs" style={{ lineHeight: "15px", height: "15px", overflow: "hidden", textOverflow: "ellipsis" }}>{item.FormDescription !== "" ? item.FormDescription : "No description available."}</span>
                                  </div>
                                  <div className="col-md-6 d-flex" style={{ justifyContent: 'end' }}>
                                    <div className={
                                      form.SubmissionStatus === "0" ? `px-1 py-2 mx-1 my-1 form-status-pending` :

                                        form.SubmissionStatus === "1" ? "px-1 py-2 mx-1 my-1 form-status-approved" :

                                          form.SubmissionStatus === "3" ? "px-1 py-2 mx-1 my-1 form-status-reapplication" :
                                            "px-1 py-2 mx-1 my-1 form-status-rejected"} >

                                      <div className="d-flex" style={{ height: '100%', alignItems: 'center' }}>
                                        <div className="col form-status-span">
                                          <span>{form.SubmissionStatus === "0" ? <>Pending</> : form.SubmissionStatus === "1" ? <>Approved</> : form.SubmissionStatus === "3" ? <>Reapplication</> : <>Rejected</>}</span>
                                        </div>
                                        <div className="col">
                                          {form.SubmissionStatus === "0" ? <><RiTimer2Line size={20} /></> : form.SubmissionStatus === "1" ? <><AiOutlineCheckCircle size={20} /></> : form.SubmissionStatus === "3" ? <><FiRotateCw size={16} /></> : <><AiOutlineCloseCircle size={20} /></>}

                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <hr className="" style={{ margin: "10px" }} />

                                <div className="d-flex flex-row">
                                  <div className="col-md-6 d-flex flex-column justify-content-center">
                                    <span className="remark-text" style={{ opacity:'0.8' }}>Submitted On: {moment(form.CreatedAt).format("YYYY-MM-DD | HH:mm")}</span>
                                    <span className="remark-text" style={{ opacity:'0.8'  }} >{form.ModifiedAt !== "" ? `Modified On: ${moment(form.ModifiedAt).format("YYYY-MM-DD | HH:mm")}` : ""}</span>
                                  </div>
                                  <div className="col-md-6 d-flex" style={{ justifyContent: 'end', alignItems: 'center',columnGap:'10px' }}>
                                    {form.SubmissionStatus !== "2" &&
                                          <>
                                        <C_Button
                                          width={"110px"}
                                          
                                          gap={"10px"}
                                          buttonText={"Preview"}
                                          background={"#463EB0"}
                                          justify={"center"}
                                          onClick={() => { setCurrFormPreviewID(form.SubmissionID); }}
                                          paddingLeft={"10px"}
                                          paddingRight={"10px"}
                                          icon={<HiOutlineMagnifyingGlass size={17} />}
                                          targetID={"preview-all-submission"}
                                          toggleAction={"modal"}
                                        />
                                        </>
                                    }

                                    {form.SubmissionStatus === "2" ?
                                      <>
                                      {isRejectedFormExpired(form.SubmissionStatus,form.ApprovalDate) ? 
                                      <>
                                          <C_IconButton
                                              onClick={() => { 
                                                removeForm(form.SubmissionID);
                                              }}
                                              size={37}
                                              title={"Remove Form"}
                                              color="#CDCDCD"
                                              backgroundImage="linear-gradient(to right top, #c65261, #d94453, #e83440, #f52029, #ff0000)"
                                              dismissAction ="modal"
                                              icon={<HiOutlineTrash color="#FFF" size={17}/>}
                                          />
                                     
                                         <C_IconButton
                                            onClick={() => { 
                                              setCurrFormPreviewID(form.SubmissionID);
                                            }}
                                            size={37}
                                            title={"Preview"}
                                            color="#CDCDCD"
                                            backgroundImage="linear-gradient(to right top, #1207a9, #332fbf, #4c4dd5, #646bea, #7c88fe)"
                                            icon={<HiOutlineMagnifyingGlass color="#FFF" size={17}/>}
                                            targetID={"preview-all-submission"}
                                            toggleAction={"modal"}
                                          />
                                      </>
                                      :
                                      <C_IconButton
                                          onClick={() => { dispatch(setFormSubmissionID(form.SubmissionID)); navigate({ pathname: "/FormList/FormSubmission", search: createSearchParams({ formSubmissionID: form.SubmissionID }).toString() }); }}
                                          size={37}
                                          title={"Edit"}
                                          color="#CDCDCD"
                                          backgroundImage="linear-gradient(to right top, #8165b9, #7c5db9, #7755b9, #724db9, #6d45b9)"
                                          icon={<HiOutlinePencilAlt color="#FFF" size={17}/>}
                                        />
                                       }
                                      
                                    </>
                                    :
                                    <>
                                    </>
                                    }
                                  </div>
                                </div>
                              </div>
                            );
                        }
                        
                        })}
                      </>
                    )
                  }
                  else
                  {
                    if(memberSubmissions.length === 0)
                    {
                      return (
                          
                        <div className="empty-form-container">
                          <img src={require("../../assets/img/no_data.png")}/>
                          <span>NO FORM HAS SUBMITTED</span>
                      </div>
                     );
                    }
                  }
                  })
                  :
                  <>
                     <div className="empty-form-container">
                        <img src={require("../../assets/img/no_data.png")}/>
                        <span>NO FORM HAS CREATED</span>
                    </div>
                  </>
                }
                </>
                :
                <>
                  {memberFormTemplates.length > 0 ? memberFormTemplates.map((item, index) => {
                    if(item.Active === 1)
                    {
                      const pendingCount = memberSubmissions?.filter(form=>form.FormID === item.FormID && form.SubmissionStatus === "0").length;
                      const approvedCount = memberSubmissions?.filter(form=>form.FormID === item.FormID && form.SubmissionStatus === "1").length;
                      const rejectedCount = memberSubmissions?.filter(form=>form.FormID === item.FormID && form.SubmissionStatus === "2").length;
                      const reapplicationCount = memberSubmissions?.filter(form=>form.FormID === item.FormID && form.SubmissionStatus === "3").length;
                    return (
                      <>
                        <div className="px-4 py-4 mt-1" style={{ border: 'none', background: '#fcffff', boxShadow: '2px 2px 4px 1px rgba(0,0,0,0.1)', borderRadius: '10px',position:'relative' }}>
                          <div className="d-flex flex-column py-3 px-3" style={{ background: 'rgb(95, 94, 175,0.1)', borderRadius: '10px' }}>
                            <span className="font-m" style={{ fontWeight: 'bold', whiteSpace: 'nowrap', textOverflow: 'ellipsis', marginBottom: '5px' }}>{item.FormName}</span>
                            <span className="clamp-text-formlist font-xs" style={{ lineHeight: "15px", height: "15px", overflow: "hidden", textOverflow: "ellipsis" }}>{item.FormDescription !== "" ? item.FormDescription : "No description available."}</span>
                          </div>
                          <hr className="" style={{ margin: "10px" }} />
                          <div className="d-flex flex-row">
                            <div className="col-9 d-flex" style={{ justifyContent: 'start',columnGap:'10px' }}>
                            {pendingCount > 0 &&
                              <C_Button
                                background={"#ebb551"}
                                width={window.innerWidth > 1500 ? "140px" : "120px"}
                                buttonText={`Pending (${pendingCount})`}
                                justify={"center"}
                                onClick={() => { setViewFormID(item.FormID); setFormStatus("0"); setViewApprovedForm(false);showModalFunc(); }}
                                paddingLeft={"10px"}
                                paddingRight={"10px"}
                              />
                            }

                            {reapplicationCount > 0 && 
                              <C_Button
                                background={"#5461d2"}
                                width={window.innerWidth > 1500 ? "160px" : "140px"}
                                buttonText={`Reapplication (${reapplicationCount})`}
                                justify={"center"}
                                onClick={() => { setViewFormID(item.FormID); setFormStatus("3"); setViewApprovedForm(false);showModalFunc(); }}
                                paddingLeft={"10px"}
                                paddingRight={"10px"}
                              />
                            }

                            {rejectedCount > 0 &&
                             <C_Button
                             background={"#e25959"}
                                width={window.innerWidth > 1500 ? "140px" : "120px"}
                                buttonText={`Rejected (${rejectedCount})`}
                                justify={"center"}
                                onClick={() => { setViewFormID(item.FormID); setFormStatus("2"); setViewApprovedForm(false);showModalFunc(); }}
                                paddingLeft={"10px"}
                                paddingRight={"10px"}
                             />
                            }
                            {approvedCount > 0 && 
                                <C_Button
                                background={"#45af85"}
                                  width={window.innerWidth > 1500 ? "160px" : "140px"}
                                  buttonText={`Approved (${approvedCount})`}
                                  justify={"center"}
                                  paddingLeft={"10px"}
                                  paddingRight={"10px"}
                                  color={"#ffffff"}
                                  onClick={() => { setViewFormID(item.FormID); setFormStatus("1"); setViewApprovedForm(false);showModalFunc(); }}
                                /> 
                            }
                            </div>
                            <div className="col-3 d-flex" style={{ justifyContent: 'end' }}>
                              {((submissionUsage && submissionQuota) && submissionUsage < submissionQuota) ? 
                                <C_Button
                                  width={window.innerWidth > 1500 ? "150px" : "120px"}
                                  gap={"10px"}
                                  background ={Colors.generalButtonColor}
                                  buttonText={"Apply Form"}
                                  justify={"center"}
                                  onClick={() => { 
                                    refreshUsage();
                                    if(submissionUsage < submissionQuota){
                                      navigate({ pathname: "/FormList/FormSubmission", search: createSearchParams({ formID: item.FormID }).toString() }); 
                                    }else{
                                      alert("Submission limit has been reached");
                                    }
                                    
                                  }}
                                  paddingLeft={"10px"}
                                  paddingRight={"10px"}
                                  icon={<HiOutlinePencilAlt size={15} />}
                                />
                                :
                                <div className="quotaRestrictedContainerNoPaddingWidth">
                                  <span>Submission limit has been reached</span>
                                </div>
                              }
                            </div>
                          </div>
                        </div>
                        
                      </>
                    )
                  }
                  }) 
                  :
                  <>
                     <div className="empty-form-container">
                        <img src={require("../../assets/img/no_data.png")}/>
                        <span>NO FORM HAS CREATED</span>
                    </div>
                  </>
                  }
                </>
              }
            </div>
          </div>
          {/** Modal */}
          
          <div
            className="modal fade modal-xl"
            id="preview-submission"
            ref={modalRef}
            tabIndex="-1"
            style={{}}
          >
            <div className="modal-dialog modal-dialog-centered"  style={{height:"60vh"}}>
              <div className="modal-content" style={{ background: '#f8fafa' }}>
                <div className="modal-body " style={{paddingTop:'0px'}}>
                    <div className="modal-header modalHeader">
                      <div className="title font-s">
                          {isPreview && <BiArrowBack className="mt-1" style={{marginBottom: '10px', cursor: 'pointer',alignItems:'center' }} size={20} onClick={()=>{setIsPreview(false)}}  />}
                          <h6 className="mt-2 mx-2">Submitted Form</h6>
                          <div className="d-flex form-expiration-alert">
                            <BiQuestionMark  size={15} />
                            <span className="expiration-span">Rejected Form without reapplication will be expired in 3 days</span>
                          </div>
                      </div>
                      <AiOutlineClose cursor='pointer' size={18} onClick={() => { 
                        setIsPreview(false);
                        setViewFormID(""); 
                        hideModalFunc(); 
                      }}/>
                    </div>
                  {isPreview ? 
                      <div className="" style={{height:'70vh'}}>
                      {memberSubmissions && memberSubmissions.map((form,formIndex) => {
                        if (form.SubmissionID === currFormPreviewID) {
                          return (
                            <>
                              {memberFormTemplates && memberFormTemplates.map((item, index) => {
                                if (item.FormID === form.FormID) {
                                  return (
                                    <>
                                      <div className="card"  style={{ marginTop:'10px', border: 'none', boxShadow: '5px 5px 5px -2px rgba(0,0,0,0.1)', background: 'rgb(95, 94, 175,0.1)' }}>
                                        <div className="card-body d-flex flex-column" >
                                          <span className="font-m">{item.FormName}</span>
                                          <span className="font-xs">{item.FormDescription !== "" ? item.FormDescription : "No description available."}</span>
                                          {form.SubmissionStatus === "2" || form.SubmissionStatus === "3" ? 
                                          <span className="mt-2 font-xxs" style={{color:Colors.redButton}}>Reason Rejected : {previousReason}</span>
                                        : <></>}
                                        </div>
                                      </div>
                                      <div className="px-2 py-2" style={{}}>
                                        <div style={{  height: "60vh", overflow: 'scroll',paddingBlock:'0.5rem'}}>
                                        {item.FormData.map((data, questionIndex) => {
                                          return (
                                            <>
                                              {form.SubmissionData.map((answer, answerIndex) => {
                                                if (data.questionID === answer.questionID) {
                                                  return (
                                                    <div className="card mt-3 mx-2" style={{ border: 'none', boxShadow: '2px 2px 4px 1px rgba(0,0,0,0.1)' }}>
                                                      <div className="card-body d-flex flex-column">
                                                        <span className="font-s">{data.questionName}</span>
                                                        {data.option === undefined
                                                          ?
                                                          <>
                                                            {data.questionType === "Multiline" ?
                                                              <>
                                                                <textarea style={{ border: 'none', borderBottom: '1px solid' }} value={answer.answer} disabled></textarea>
                                                                
                                                              </>
                                                              :
                                                              data.questionType === "Date Range" ? 
                                                              <>
                                                              <div className="mt-2 " style={{width:'100%'}}>
                                                                <span className="mx-1 font-xxs" style={{opacity:'0.8'}}>Start Date :</span>
                                                                <input type="date" value={answer.answer.start} name={data.questionType} placeholder="DateRange1" className="mx-1 font-xs" style={{marginBottom:'5px',border: 'none', width: '100%', background: 'none',borderBottom: '1px solid',marginBottom:'10px'}} disabled />
                                                                
                                                                <span className="mx-1 font-xxs" style={{opacity:'0.8'}}>End Date :</span>
                                                                <input type="date" value={answer.answer.end} name={data.questionType} placeholder="DateRange2" className="mx-1 font-xs" style={{marginBottom:'5px',border: 'none', width: '100%', background: 'none',borderBottom: '1px solid'}} disabled />
                                                              </div>
                                                              </>
                                                              :
                                                              <>
                                                                <div className="mt-2" style={{ borderBottom: '1px solid' }}>
                                                                    <input className="py-1" type={data.questionType === "ShortText" ? "text" : data.questionType === "Date" ? "date" : data.questionType === "Time" ? "time" : data.questionType === "Phone" ? "phone" : data.questionType === "Email" ? "email" : data.questionType === "Decimal" ? "number" :
                                                                    data.questionType === "Number" ? "number" : "text"} value={answer.answer} style={{ border: 'none', width: '100%', background: 'none' }} disabled />
                                                                  </div>
                                                              </>
                                                            }
                                                          </>
                                                          :
                                                          <>
                                                            {data.questionType === "Multiple Choice" ?
                                                              <>
                                                                <label className="mt-2" >
                                                                  {data.option.map((options, optionIndex) => {
                                                                    return (
                                                                      <>
                                                                        <div className="d-flex" style={{ alignItems: 'center', textAlign: 'center' }}>
                                                                          <input className="py-1" type="radio" value={options} checked={radioArray.length !== 0 ? radioArray[questionIndex][optionIndex] : ""} style={{ width: '13px', height: '13px' }} disabled />
                                                                          <span className="mx-1 font-xxs">{options}</span>
                                                                        </div>
                                                                      </>
                                                                    );
                                                                  })}
                                                                </label>
                                                              </>
                                                              :
                                                              data.questionType === "Checkboxes" ?
                                                                <>
                                                                  <div className="mt-2">
                                                                    {data?.option.map((options, optionIndex) => {
                                                                      return (
                                                                        <label style={{ display: 'flex', alignItems: 'center' }}>
                                                                          <input type='checkbox' style={{ width: '15px', height: '15px' }} checked={checkboxArray.length !== 0 ? checkboxArray[questionIndex][optionIndex] : ""} value={options} className='col-md-3' disabled />
                                                                          <span className="disabled-checkmark" style={{ marginRight: '10px' }}></span>
                                                                          <span className="font-xs" style={{ marginRight: '10px' }}>{options}</span>
                                                                        </label>
                                                                      );
                                                                    })}
                                                                  </div>
                                                                </>
                                                                :
                                                                data.questionType === "Dropdown" ?
                                                                  <>
                                                                    <div className="mt-2" >
                                                                      <select className="form-select font-xs" style={{ minWidth: '130px', width: '20%' }} disabled>
                                                                        <option className="" selected value={answer.answer}>{answer.answer}</option>
                                                                      </select>
                                                                    </div>
                                                                  </>
                                                                :
                                                                data.questionType === "Member" ?
                                                                <>
                                                                  <div className="mt-2" >
                                                                    <select className="form-select font-xs" style={{ minWidth: '130px', width: '20%' }} disabled>
                                                                          <option className="" value={answer.answer}>{answer.answer}</option>
                                                                    </select>
                                                                  </div>
                                                                </>
                                                                :
                                                                data.questionType === "Client" ?
                                                                <>
                                                                  <div className="mt-2" >
                                                                    <select className="form-select font-xs" style={{ minWidth: '130px', width: '20%' }} disabled>
                                                                     
                                                                        <option className="" value={answer.answer}>{clientSelectedList?.find(item => item?.ClientID === answer.answer) !== undefined ? clientSelectedList?.find(item => item?.ClientID === answer.answer)?.RowValue : "N/A"}</option>
                                                                          
                                                                    </select>
                                                                  </div>
                                                                </>
                                                                :
                                                                  <>
                                                                  </>
                                                            }
                                                          </>
                                                        }
                                                      </div>
                                                    </div>
                                                  );
                                                }
                                              })}
                                            </>
                                          )
                                        })}
                                          
                                        </div>
                                      </div>
                                    </>
                                  )
                                }
                              })}
                            </>
                          )
                        }
                      }//end if
                      )}
                    </div>
                    :
                    <div style={{height:"60vh",overflow:'scroll'}}>
                    <div className="form-submission-container">
                    
                    {submissionArr && submissionArr.map((form, index) => {
                        return (
                          <>
                            {memberFormTemplates && memberFormTemplates.map((item) => {
                              if (form.FormID === item.FormID) {
                                return (
                                  <div className="form-submission-card">
                                    <div className="d-flex remark-text" style={{opacity:'0.8',paddingBottom:'5px',fontWeight:'bold',alignItems:'center'}} ><span style={{cursor:'pointer',color:'#4895cf'}} 
                                    onClick={()=>{ notify("copy");navigator.clipboard.writeText(form.FormTrackingID);}}>Ref ID: {form.FormTrackingID}</span>
                                    <MdOutlineContentCopy size={14} onClick={()=>{notify("copy"); navigator.clipboard.writeText(form.FormTrackingID);}} style={{cursor:'pointer',marginLeft:'5px'}}/>
                                  </div>
                                    <div className="d-flex flex-column py-3 px-3" style={{ background: 'rgb(95, 94, 175,0.1)', borderRadius: '10px' }}>
                                      
                                      <div className="col-md-6 d-flex flex-column">
                                        <span className="font-s" style={{ fontWeight: 'bold', whiteSpace: 'nowrap', textOverflow: 'ellipsis', marginBottom: '5px' }}>{item.FormName}</span>
                                        <span className="clamp-text-formlist font-xxs" style={{ lineHeight: "15px", height: "15px", overflow: "hidden", textOverflow: "ellipsis",opacity:'0.8'  }}>{item.FormDescription !== "" ? item.FormDescription : "No description available."}</span>
                                      </div>
                                      <div className="col-md-6 d-flex" style={{ justifyContent: 'end' }}>
                                        <div className={

                                          form.SubmissionStatus === "0" ? `px-1 py-2 my-1 form-status-pending` :

                                            form.SubmissionStatus === "1" ? "px-1 py-2 my-1 form-status-approved" :

                                              form.SubmissionStatus === "3" ? "px-1 py-2 my-1 form-status-reapplication" :
                                                "px-1 py-2 my-1 form-status-rejected"} >

                                          <div className="d-flex" style={{ height: '100%', alignItems: 'center' }}>
                                            <div className="col form-status-span">
                                              <span>{form.SubmissionStatus === "0" ? <>Pending</> : form.SubmissionStatus === "1" ? <>Approved</> : form.SubmissionStatus === "3" ? <>Reapplication</> : <>Rejected</>}</span>
                                            </div>
                                            <div className="col">
                                              {form.SubmissionStatus === "0" ? <><RiTimer2Line size={20} /></> : form.SubmissionStatus === "1" ? <><AiOutlineCheckCircle size={20} /></> : form.SubmissionStatus === "3" ? <><FiRotateCw size={20} /></> : <><AiOutlineCloseCircle size={20} /></>}

                                            </div>
                                          </div>
                                        </div>

                                      </div>
                                    </div>
                                    <hr className="" style={{ margin: "10px" }} />

                                    <div className="d-flex flex-row">
                                      <div className="col-md-7 d-flex flex-column justify-content-center">
                                        <span className="remark-text" style={{ opacity:'0.8'  }}>Submitted On: {moment(form.CreatedAt).format("YYYY-MM-DD | HH:mm")}</span>
                                        <span className="remark-text" style={{ opacity:'0.8'  }} >{form.ModifiedAt !== "" ? `Modified On: ${moment(form.ModifiedAt).format("YYYY-MM-DD | HH:mm")}` : ""}</span>
                                      </div>
                                      <div className="col-md-5 d-flex" style={{ justifyContent: 'end', alignItems: 'center' }}>
                                        <>
                                        {form.SubmissionStatus === "2" ?
                                          <>
                                            {isRejectedFormExpired(form.SubmissionStatus,form.ApprovalDate) 
                                            ? 
                                            <div className="d-flex" style={{columnGap:'8px'}}>
                                              {/* <RiDeleteBin5Line title="Remove Form" size={19} style={{ color: "#eb6565", cursor: 'pointer' }} onClick={() => {removeForm(form.SubmissionID);}} /> */}

                                              <C_IconButton
                                                onClick={() => { 
                                                  removeForm(form.SubmissionID);
                                                }}
                                                size={30}
                                                title={"Remove Form"}
                                                color="#CDCDCD"
                                                backgroundImage="linear-gradient(to right top, #c65261, #d94453, #e83440, #f52029, #ff0000)"
                                                dismissAction ="modal"
                                                icon={<HiOutlineTrash color="#FFF" size={16}/>}
                                              />
                                              <C_IconButton
                                                onClick={() => { 
                                                  setIsPreview(true);setCurrFormPreviewID(form.SubmissionID)
                                                }}
                                                size={30}
                                                title={"Preview"}
                                                color="#CDCDCD"
                                                backgroundImage="linear-gradient(to right top, #1207a9, #332fbf, #4c4dd5, #646bea, #7c88fe)"
                                                icon={<HiOutlineMagnifyingGlass color="#FFF" size={16}/>}
                                              />
                                              {/* <FaRegEye size={18} title="View Form" onClick={() => {setIsPreview(true);setCurrFormPreviewID(form.SubmissionID)}} color={"#6f7df7"} style={{ cursor: 'pointer' }}/> */}
                                            </div>
                                            : 
                                            <C_IconButton
                                                onClick={() => { 
                                                  dispatch(setFormSubmissionID(form.SubmissionID)); navigate({ pathname: "/FormList/FormSubmission", search: createSearchParams({ formSubmissionID: form.SubmissionID }).toString()} ); hideModalFunc();
                                                }}
                                                size={30}
                                                title={"Edit Form"}
                                                color="#CDCDCD"
                                                backgroundImage="linear-gradient(to right top, #8165b9, #7c5db9, #7755b9, #724db9, #6d45b9)"
                                                
                                                icon={<HiOutlinePencilAlt color="#FFF" size={16}/>}
                                            />
                                            // <BiEditAlt size={20} style={{ color: "#45af85", cursor: 'pointer' }} onClick={() => { dispatch(setFormSubmissionID(form.SubmissionID)); navigate({ pathname: "/FormList/FormSubmission", search: createSearchParams({ formSubmissionID: form.SubmissionID }).toString() }); }} data-bs-dismiss="modal" />
                                            }
                                            </>
                                            :
                                            <C_IconButton
                                                onClick={() => { 
                                                  setIsPreview(true);setCurrFormPreviewID(form.SubmissionID)
                                                }}
                                                size={30}
                                                title={"Preview"}
                                                color="#CDCDCD"
                                                backgroundImage="linear-gradient(to right top, #1207a9, #332fbf, #4c4dd5, #646bea, #7c88fe)"
                                                icon={<HiOutlineMagnifyingGlass color="#FFF" size={16}/>}
                                              />
                                            // <FaRegEye size={18} title="View Form" onClick={() => {setIsPreview(true);setCurrFormPreviewID(form.SubmissionID)}} color={"#6f7df7"} style={{ cursor: 'pointer' }}/> 
                                          }
                                          </>
                                        
                                      </div>
                                    </div>
                                  </div>
                                );

                              }
                            })}
                          </>
                        )
                    })}

                    {isEmptyForm &&
                    <>

                    <div className="empty-form-container">
                      <img src={require("../../assets/img/no_data.png")}/>
                      {viewApprovedForm ? 
                      <>
                        <span>NO FORM HAS BEEN APPROVED YET</span>
                      </>
                      :
                      <>
                        <span>NO FORM HAS SUBMITTED</span>
                      </>}
                    </div>
                    </>}
                    
                    
                  </div>
                  </div>
                  }
                  <div className="" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '1rem' }}>
                      <div style={{ display: 'flex',justifyContent: 'center', alignItems: 'center', overflow: 'scroll' }}>
                        <ReactPaginate
                          containerClassName="paginationStyle"
                          activeClassName="activePage"
                          pageClassName="paginationElementStyle"
                          breakLabel="..."
                          nextLabel={<MdNavigateNext size={20}/>}
                          onPageChange={(page) => { viewApprovedForm ?  onHandlePageChange(page.selected,"approved") : onHandlePageChange(page.selected,"submission")  }}
                          pageRangeDisplayed={5}
                          // pageCount={10}
                          pageCount={(formsCount && formsCount.length > 0) && Math.ceil(formsCount[0].Total_submission/20)}
                          previousLabel={<MdNavigateBefore size={20}/>}
                          renderOnZeroPageCount={null}
                        />
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default FormList;
