import { createSlice, current } from "@reduxjs/toolkit"

import { useAppSelector } from "../../hooks/storeHooks";

//initial state
const initialState = {

    //loading
    getClientGroupRolesLoading : false,
    getCurrUserClientGroupRolesLoading : false,
    createClientGroupRolesLoading : false,
    setClientGroupRolesLoading : false,
    deleteClientGroupRolesLoading : false,

    //error
    getClientGroupRolesError : false,
    getCurrUserClientGroupRolesError : false,
    createClientGroupRolesError : false,
    setClientGroupRolesError : false,
    deleteClientGroupRolesError : false,

    //data
    clientGroupRoles : [],
    currUserGroupRole : null,

}

//slices
const clientGroupRolesSlices = createSlice({
    name: "clientGroupRoles",
    initialState,
    reducers : {

        getCurrUserClientGroupRoles(state){
            state.getCurrUserClientGroupRolesLoading = true;
        },
        getCurrUserClientGroupRolesSuccess(state, {payload}){
            const currentState = payload;
            state.currUserGroupRole = currentState;

            state.getCurrUserClientGroupRolesLoading = false;
        },
        getCurrUserClientGroupRolesError(state, {payload}){
            const errorState = payload;
            state.getCurrUserClientGroupRolesError = errorState;

            state.getCurrUserClientGroupRolesLoading = false;
        },

        getClientGroupRoles(state){
            state.getClientGroupRolesLoading = true;
        },
        getClientGroupRolesSuccess(state, {payload}){
            const currentState = payload;
            state.clientGroupRoles = currentState;

            state.getClientGroupRolesLoading = false;
        },
        getClientGroupRolesError(state, {payload}){
            const errorState = payload;
            state.getClientGroupRolesError = errorState;

            state.getClientGroupRolesLoading = false;
        },

        createClientGroupRoles(state){
            state.createClientGroupRolesLoading = true;
        },
        createClientGroupRolesSuccess(state, {payload}){
            // const currentState = current(state.clientGroupRoles);
            // const updatedState = [...currentState, payload]
            // state.clientGroupRoles = updatedState;
            const currentState = payload;
            state.clientGroupRoles = currentState;

            state.createClientGroupRolesLoading = false;
        },
        createClientGroupRolesError(state, {payload}){
            const errorState = payload;
            state.createClientGroupRolesError = errorState;

            state.createClientGroupRolesLoading = false;
        },

        setClientGroupRoles(state){
            state.setClientGroupRolesLoading = true;
        },
        setClientGroupRolesSuccess(state, {payload}){
            const currentState = payload;
            state.clientGroupRoles = currentState;
            // const currentState = current(state.clientGroupRoles);
            // const id = payload.ClientGroupRoleID;
            // const roleIndex = currentState.findIndex(role => role.ClientGroupRoleID === id);
            // if(roleIndex !== -1){
            //     const updatedState = [...currentState];
            //     updatedState[roleIndex] = { ...currentState[roleIndex], ...payload };
            //     state.clientGroupRoles = updatedState;
            // }
            
            state.setClientGroupRolesLoading = false;
        },
        setClientGroupRolesError(state, {payload}){
            const errorState = payload;
            state.setClientGroupRolesError = errorState;

            state.setClientGroupRolesLoading = false;
        },

        deleteClientGroupRoles(state){
            state.deleteClientGroupRolesLoading = true;
        },
        deleteClientGroupRolesSuccess(state, {payload}){
            const currentState = current(state.clientGroupRoles);
            const updatedState = currentState.filter(role => role.ClientGroupRoleID !== payload);
            state.clientGroupRoles = updatedState;

            state.deleteClientGroupRolesLoading = false;
        },
        deleteClientGroupRolesError(state, {payload}){
            const errorState = payload;
            state.deleteClientGroupRolesError = errorState;

            state.deleteClientGroupRolesLoading = false;
        },
        
    }
})

//export function
export const {
    createClientGroupRoles,
    createClientGroupRolesError,
    createClientGroupRolesSuccess,
    getClientGroupRoles,
    getClientGroupRolesError,
    getClientGroupRolesSuccess,
    setClientGroupRoles,
    setClientGroupRolesError,
    setClientGroupRolesSuccess,
    deleteClientGroupRoles,
    deleteClientGroupRolesError,
    deleteClientGroupRolesSuccess,
    getCurrUserClientGroupRoles,
    getCurrUserClientGroupRolesError,
    getCurrUserClientGroupRolesSuccess
} = clientGroupRolesSlices.actions;

//export selector

// data
export const selectClientGroupRoles = () =>
  useAppSelector((state) => state.clientGroupRoles.clientGroupRoles);

export const selectCurrUserClientGroupRoles = () =>
    useAppSelector((state) => state.clientGroupRoles.clientGroupRoles);

// loading
export const selectGetClientGroupRolesLoading = () =>
  useAppSelector((state) => state.clientGroupRoles.getClientGroupRolesLoading);

export const selectCreateClientGroupRolesLoading = () =>
    useAppSelector((state) => state.clientGroupRoles.createClientGroupRolesLoading);

export const selectSetClientGroupRolesLoading = () =>
    useAppSelector((state) => state.clientGroupRoles.setClientGroupRolesLoading);

export const selectDeleteClientGroupRolesLoading = () =>
    useAppSelector((state) => state.clientGroupRoles.deleteClientGroupRolesLoading);

export const selectGetCurrUserClientGroupRolesLoading = () =>
    useAppSelector((state) => state.clientGroupRoles.getCurrUserClientGroupRolesLoading);


// error
export const selectGetClientGroupRolesError = () =>
    useAppSelector((state) => state.clientGroupRoles.getClientGroupRolesError);

export const selectCreateClientGroupRolesError = () =>
    useAppSelector((state) => state.clientGroupRoles.createClientGroupRolesError);

export const selectSetClientGroupRolesError = () =>
    useAppSelector((state) => state.clientGroupRoles.setClientGroupRolesError);

export const selectDeleteClientGroupRolesError = () =>
    useAppSelector((state) => state.clientGroupRoles.deleteClientGroupRolesError);

export const selectGetCurrUserClientGroupRolesError = () =>
    useAppSelector((state) => state.clientGroupRoles.getCurrUserClientGroupRolesError);

//export reducer
const clientGroupRolesReducer = clientGroupRolesSlices.reducer;

export default clientGroupRolesReducer;
