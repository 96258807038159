import { createTheme } from "@mui/material/styles";
import palette from "./palette";
import typography from "./typography";
import components from "./overrides";
import settings from "./settings";
import sizing from "./sizing";
import icons from "./icons";
import position from "./position";
import forms from "./forms";
import borders from "./borders";
import button from "./button";

const themeOptions = {
  ...settings,
  palette,
  typography,
  sizing,
  icons,
  position,
  components,
  forms,
  borders,
  button,

};
const theme = createTheme(themeOptions);

export { palette, typography, settings, forms, borders, button };
export default theme;
