import React, { Fragment, useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import './InvoicingMain.scss';
import { Invoicing } from "../../enums/Invoicing";
import { FormControlLabel, TextField } from "@mui/material";
import { InvoicingTable } from "./InvoicingTable";
import { TableRouteConverter } from "../../constants/Invoicing/TableRouteConverter";
import { useSearchParams } from "react-router-dom";
import { ViewTransaction } from "./ViewTransaction";
import { addToLocalStorage, getFromLocalStorage, removeFromLocalStorage, updateLocalStorage } from "../../util/LocalStorage";
import { NewTransaction } from "./NewTransaction";

function InvoicingMain() {

  const [selectedTab, setSelectedTab] = useState("sales");
  const [selectedSubTab, setSelectedSubTab] = useState("");
  const [selectedFormID, setSelectedFormID] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const storageCat = getFromLocalStorage("INV_CAT");
  const storageSubCat = getFromLocalStorage("INV_SUB_CAT");

  useEffect(()=>{
    if(Array.from(searchParams).length > 0 && searchParams.get("category")){
      const category = searchParams.get("category");
      setSelectedTab(category)
    } else {
      const params = searchParams;
      params.set("category", "sales");
      setSearchParams(params);
    }

    if(Array.from(searchParams).length > 0 && searchParams.get("sub_category")){
      const subCategory = searchParams.get("sub_category");
      setSelectedSubTab(subCategory)
    }
  },[searchParams])

  function removeSearchParams(){
    // setSearchParams("");
    const params = searchParams;
    params.delete("action");
    params.delete("transaction_id");
    params.delete("duplicate_id");
    setSearchParams(params);
  }

  const updateUrl = (newParams) => {
    const params = searchParams;

    // Update the search params with new values
    Object.keys(newParams).forEach(key => {
      if (newParams[key] !== null && newParams[key] !== undefined) {
        searchParams.set(key, newParams[key]);
      } else {
        searchParams.delete(key); // Remove the param if null or undefined
      }
    });
    setSearchParams(params);

  }

  const selectCategory = (title) => {
    setSelectedTab(title);
    updateLocalStorage("INV_SUB_CAT", "")
    setSelectedSubTab("");
    updateUrl({category: title});
    addToLocalStorage("INV_CAT", title);
    const params = searchParams;
    searchParams.delete("sub_category");
    setSearchParams(params);
    // removeFromLocalStorage("INV_SUB_CAT")
  }

  const selectSubCategory = (keys) => {
    setSelectedSubTab(keys);
    addToLocalStorage("INV_SUB_CAT", keys);
    updateUrl({sub_category: keys});
  }

  return (
      <Fragment>
        <div className="sidebar-main-container">
          <Sidebar/>
        </div>
        <div className="main-container">
          <Header title="Invoicing"/>
          {(Array.from(searchParams).length > 0 && searchParams.get("action") === "view") && 
              <div className="content-box" >
                <ViewTransaction 
                  category={selectedTab}
                  subCategory={selectedSubTab}
                  removeSearchParams={removeSearchParams}
                />
              </div>
          }

          {(Array.from(searchParams).length > 0 && searchParams.get("action") === "new") && 
              <div className="content-box" >
                <NewTransaction 
                  category={selectedTab}
                  subCategory={selectedSubTab}
                  removeSearchParams={removeSearchParams}
                />
              </div>
          }

          {(Array.from(searchParams).length === 0 || 
           (Array.from(searchParams).length > 0 && 
            searchParams.get("action") !== "view" && 
            searchParams.get("action") !== "new")) &&
            <div className="content-box" >
              <div className="tab-container">
                {Object.keys(Invoicing).map((title, index)=>{
                  return (
                    <span 
                      key={index}
                      className={`tab-header ${selectedTab === title ? "tab-header-selected" : ""}`}
                      onClick={()=>{
                       selectCategory(title)
                      }}
                    >
                      {title}
                    </span>
                  )
                })}
              </div>
              <div className="sub-tab-container">
                {selectedTab !== "" && Object.entries(Invoicing[selectedTab]).map(([keys,values], index) =>{
                  return(
                    <span 
                      key={index}
                      className={`sub-tab-header ${selectedSubTab === keys ? "sub-tab-header-selected" : ""}`}
                      onClick={()=>{selectSubCategory(keys)}}
                    >
                      {values}
                    </span>
                  )
                })}
              </div>
              <div className="invoicing-table-container">
                {(selectedTab !== ""&& selectedSubTab !== "") ?
                 <InvoicingTable 
                  category={selectedTab}
                  subCategory={selectedSubTab}
                  selectedFormID={selectedFormID}
                  setSelectedFormID={setSelectedFormID}
                />
                :
                <></>
                }
               
              </div>
            </div>
          }
          
        </div>
        </Fragment>
  );
}

export default InvoicingMain;
