import React, { useState, useEffect, Fragment } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import C_Loading from "../../components/C_Loading";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import './ManageCompany.scss'
import CompanySettings from "./CompanySettings";
import UsersAndPermissions from "./UsersAndPermissions";
import UsageAndBillings from "./UsageAndBillings";
import { selectCompanyID } from "../../stores/slices/companyIDSlices";
import { selectTenant } from "../../stores/slices/tenantSlices";
import { selectCurrTenant } from "../../stores/slices/persistRolesSlices";
import { selectUser } from "../../stores/slices/userAuthSlices";

function ManageCompany(props) {

    const location = useLocation();
    const navigate = useNavigate();
    const user = selectUser();
    const companyID = selectCompanyID();
    const tenant = selectCurrTenant();
    const [searchParams, setSearchParams] = useSearchParams();

    const [sectionTab, setSectionTab] = useState("company");

    useEffect(() => {
    
        const tab = searchParams.get("tab");
        if(tab){
            setSectionTab(tab)
        }else{
            navigate('/companySetting?tab=company');
        };
    
    },[location.search]);

    function handleChangeTab(tab){
        navigate(`/companySetting?tab=${tab}`);
    }

    return (
        <Fragment>
             <div className="sidebar-main-container">
                <Sidebar />
            </div>
                <div className="main-container">
                <Header title={"Company Settings"}/>
                <div className="content-box">
                    <div className="sectionTabContainer">
                        <div className={`tabContainer ${sectionTab === "company" && 'selectedTab'}`} onClick={() => { handleChangeTab("company") }}>
                            <span>Company Overview</span>
                        </div>
                        <div className={`tabContainer ${sectionTab === "usersandpermissions" && 'selectedTab'}`} onClick={() => { handleChangeTab("usersandpermissions") }}>
                            <span>Users and Permissions</span>
                        </div>
                        <div className={`tabContainer ${sectionTab === "usageandbillings" && 'selectedTab'}`} onClick={() => { handleChangeTab("usageandbillings") }}>
                            <span>Usage and Billing</span>
                        </div>
                    </div>
                    <div className="sectionContainer">
                        {sectionTab === "company" && <CompanySettings section={sectionTab}/>}
                        {sectionTab === "usersandpermissions" && <UsersAndPermissions section={sectionTab}/>}
                        {sectionTab === "usageandbillings" && <UsageAndBillings section={sectionTab}/>}
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default ManageCompany;