import { Route } from "react-router-dom"
import { crmRoutes } from "../enums/Route"
import ProtectedRoute from "./ProtectedRoute"
import TenantPortal from "../containers/tenant/TenantPortal"
import { Fragment } from "react"
import CompanySettings from "../containers/company_management/CompanySettings"
import ManageCompany from "../containers/company_management/ManageCompany"

export const CompanySettingRoute = ({currSaState}) => (
    <Fragment>
        {currSaState &&
            <Route 
                path={crmRoutes.company.companySetting}
                element={
                    <ProtectedRoute>
                        <ManageCompany />
                    </ProtectedRoute>
                }
            />
        }
    </Fragment> 
)

