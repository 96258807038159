import React from 'react';

import { selectAnalyticsContext, setAnalyticsContext } from '../../../stores/slices/analyticsSlices';
import { useDispatch } from 'react-redux';
import { selectCurrUserContext } from '../../../stores/slices/companySlices';

function GraphXAxisDropdown(props) {

    const { 
        dispatchAnalytics,
        dispatchXAxisTarget,
    } = selectAnalyticsContext();
    const dispatch = useDispatch();

    function handleChangeTarget(value){
      dispatch(setAnalyticsContext({xAxisTarget : value}));
      // dispatchXAxisTarget({ type:"CHANGE_X_AXIS_TARGET", payload: value })
    }

    const { currUsers } = selectCurrUserContext();

  return (
    <select className={props.className} onChange={(e) => { 
        handleChangeTarget(e.target.value)
      }}>
        <option value={""} selected></option>
        {props.data?.map((item, index) => {
         if(item.key === "Handler"){
          const userObj = currUsers.find(user => user.uid === item.id);
          if(userObj){
            return(
              <option key={index} className="" value={item?.["id"]}>
                {userObj.displayName}
              </option>
            )
          }
          
        } else {
          return(
            <option key={index} className="" value={item?.["id"]}>
              {item?.["key"]}
            </option>
          )
        }
        }) 
      }
      </select>
  )
}

export default GraphXAxisDropdown