import React, { useState, useEffect, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import './Analytics.scss'
import C_Loading from "../../components/C_Loading";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import C_Button from "../../components/C_Button";
import { MdWorkspaces } from 'react-icons/md'
import PublishedContent from "./graphGenerator/PublishedContent";
import C_Search from "../../components/C_Search";
import { useDispatch } from "react-redux";
import C_PopoutNoti from "../../components/C_PopoutNoti";


//slices
import { getAnalyticsDataSet, getPublishAnalytics, selectAnalyticsContext } from "../../stores/slices/analyticsSlices";
import { retrieveFormTemplate } from "../../stores/slices/formTemplateSlices";
import { retrieveCompanyAgent } from "../../stores/slices/companyAgentSlices";
import { retrieveFormSubmission } from "../../stores/slices/formSubmissionSlices";
import { selectCompanyID } from "../../stores/slices/companyIDSlices";
import { selectTenant } from "../../stores/slices/tenantSlices";
import { selectCurrTenant } from "../../stores/slices/persistRolesSlices";
import { selectCurrUserContext } from "../../stores/slices/companySlices";


function Analytics(props) {

  const isSuperAdmin = props.isSuperAdmin;
  const Analytics_Manage = props.Analytics_Manage;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { triggerAction, publishedGraph, publishedGraphData  } = selectAnalyticsContext();
  const [isLoading, setIsLoading] = useState(false);
  const [showSidebar, setShowSidebar] = useState(true);
  const [searchText, setSearchText] = useState("");
  
  const companyID = selectCompanyID();
  const tenant = selectCurrTenant();

  const { currUsers } = selectCurrUserContext();

  useEffect(()=>{
    if(companyID && tenant){
      dispatch(retrieveFormTemplate({TenantID:tenant,CompanyID:companyID}));
      dispatch(retrieveFormSubmission({TenantID:tenant,CurrPage:"",UserID: ""}));
      dispatch(getPublishAnalytics({TenantID:tenant,CompanyID:companyID, MemberData: currUsers}));
      dispatch(retrieveCompanyAgent({TenantID:tenant,CompanyID:companyID}))
    }

  },[companyID, tenant])

  const handleNavigateWorkspace = () => {
    dispatch(getAnalyticsDataSet({TenantID: tenant, CompanyID : companyID }))
  }

  return (
    <Fragment>

    <C_Loading isLoading={isLoading}/>
      {/* <div className={`sidebarSize ${showSidebar && 'sidebar-Active'}`}>
        <Sidebar/>
      </div> */}
      <div className={`sidebar-main-container ${showSidebar && 'sidebar-Active'}`}>
          <Sidebar />
        </div>
        <div className="main-container">
        <Header title={"Analytics"}/>
        <div className="content-box analyticsMainContent">

          <C_PopoutNoti triggerSaved={triggerAction}/>

          <div className="headerContent">
            {}
            <div className="search">
              <C_Search
                placeholder="Search by Title or Graph Type (e.g Bar, Line, Pie and Area)"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                value={searchText}
                searchText={searchText}
                closeFunction={() => {
                  setSearchText("");
                }}
              />
            </div>
            {(isSuperAdmin || Analytics_Manage) ?
              <div className="toWorkSpace">
                <C_Button
                  width={"120px"}
                  gap={"10px"}
                  buttonText={"Workspace"}
                  justify={"center"}
                  background={"#f8fafa"}
                  color={"#000000"}
                  border={"1px solid"}
                  borderColor={"#BCBCBC"}
                  icon={<MdWorkspaces size={16}/>}
                  onClick={() => {
                    handleNavigateWorkspace();
                   
                  }}
                  textColor={"#FFFFFF"}
                />
              </div>
              :<></>
            }
          </div>
          <div className="published-content">
              <PublishedContent sidebarCollapse={showSidebar} searchText={searchText}/>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Analytics;
