import { createSlice, current } from "@reduxjs/toolkit"

import { useAppSelector } from "../../hooks/storeHooks";


//initial state
const initialState = {

    //loading
    createClientGroupLeaderLoading : false,
    retrieveAllClientGroupLeaderLoading : false,
    deleteClientGroupLeaderLoading : false,
    retrieveClientGroupLeaderLoading : false,

    //error
    createClientGroupLeaderError : undefined,
    retrieveAllClientGroupLeaderError : undefined,
    deleteClientGroupLeaderError : undefined,
    retrieveClientGroupLeaderError : undefined,

    //data
    allClientGroupLeader : [],
    clientGroupLeader : [],

}

//slices
const clientGroupLeaderSlice = createSlice({
    name: "clientGroupLeaders",
    initialState,
    reducers : {
        createClientGroupLeader(state){
            state.createClientGroupLeaderLoading = true;
        },
        createClientGroupLeaderSuccess(state, {payload}){
            const currentState = current(state.allClientGroupLeader);
            const updatedState = [...currentState, payload];
            state.allClientGroupLeader = updatedState;

            state.createClientGroupLeaderLoading = false;
        },
        createClientGroupLeaderError(state, {payload}){
            const errorState = payload;
            state.createClientGroupLeaderError = errorState;

            state.createClientGroupLeaderLoading = false;
        },

        retrieveAllClientGroupLeader(state){
            state.retrieveAllClientGroupLeaderLoading = true;
        },
        retrieveAllClientGroupLeaderSuccess(state, {payload}){
            const currentState = payload;
            state.allClientGroupLeader = currentState;

            state.retrieveAllClientGroupLeaderLoading = false;
        },
        retrieveAllClientGroupLeaderError(state, {payload}){
            const errorState = payload;
            state.retrieveAllClientGroupLeaderError = errorState;

            state.retrieveAllClientGroupLeaderLoading = false;
        },

        deleteClientGroupLeader(state){
            state.deleteClientGroupLeaderLoading = true;
        },
        deleteClientGroupLeaderSuccess(state, {payload}){
            const currentState = current(state.allClientGroupLeader);
            const updatedState = currentState.filter((item) => item.GroupLeaderID !== payload.GroupLeaderID);
            state.allClientGroupLeader = updatedState;

            state.deleteClientGroupLeaderLoading = false;
        },
        deleteClientGroupLeaderError(state, {payload}){
            const errorState = payload;
            state.deleteClientGroupLeaderError = errorState;

            state.deleteClientGroupLeaderLoading = false;
        },


        retrieveClientGroupLeader(state){
            state.retrieveClientGroupLeaderLoading = true;
        },
        retrieveClientGroupLeaderSuccess(state, {payload}){
            const currentState = payload;
            state.clientGroupLeader = currentState;

            state.retrieveClientGroupLeaderLoading = false;
        },
        retrieveClientGroupLeaderError(state, {payload}){
            const errorState = payload;
            state.retrieveClientGroupLeaderError = errorState;

            state.retrieveClientGroupLeaderLoading = false;
        },

      
        
    }
})

//export function
export const {
    createClientGroupLeader,
    createClientGroupLeaderSuccess,
    createClientGroupLeaderError,
    retrieveAllClientGroupLeader,
    retrieveAllClientGroupLeaderSuccess,
    retrieveAllClientGroupLeaderError,
    deleteClientGroupLeader,
    deleteClientGroupLeaderSuccess,
    deleteClientGroupLeaderError,
    retrieveClientGroupLeader,
    retrieveClientGroupLeaderSuccess,
    retrieveClientGroupLeaderError
} = clientGroupLeaderSlice.actions

//export selector
// data
export const selectAllClientGroupLeader = () =>
  useAppSelector((state) => state.clientGroupLeaders.allClientGroupLeader);

export const selectClientGroupLeader = () =>
  useAppSelector((state) => state.clientGroupLeaders.clientGroupLeader);

// loading
export const selectCreateClientGroupLeaderLoading = () =>
  useAppSelector((state) => state.clientGroupLeaders.createClientGroupLeaderLoading);

export const selectRetrieveAllClientGroupLeaderLoading = () =>
  useAppSelector((state) => state.clientGroupLeaders.retrieveAllClientGroupLeaderLoading);

export const selectDeleteClientGroupLeaderLoading = () =>
  useAppSelector((state) => state.clientGroupLeaders.deleteClientGroupLeaderLoading);

export const selectRetrieveClientGroupLeaderLoading = () =>
  useAppSelector((state) => state.clientGroupLeaders.retrieveClientGroupLeaderLoading);

// error
export const selectCreateClientGroupLeaderError = () =>
  useAppSelector((state) => state.clientGroupLeaders.createClientGroupLeaderError);

export const selectRetrieveAllClientGroupLeaderError = () =>
  useAppSelector((state) => state.clientGroupLeaders.retrieveAllClientGroupLeaderError);

export const selectDeleteClientGroupLeaderError = () =>
  useAppSelector((state) => state.clientGroupLeaders.deleteClientGroupLeaderError);

export const selectRetrieveClientGroupLeaderError = () =>
  useAppSelector((state) => state.clientGroupLeaders.retrieveClientGroupLeaderError);

//export reducer
const clientGroupLeaderReducer = clientGroupLeaderSlice.reducer;

export default clientGroupLeaderReducer;
