export const Invoicing = {
    sales: {
        quotations : "Quotations",
        "sale-orders" : "Sale Orders",
        "delivery-orders" : "Delivery Orders",
        invoices : "Invoices",
        "credit-notes" : "Credit Notes",
        payments : "Payments",
        refunds : "Refunds",
    },

    purchases: {
        "purchase-orders" : "Purchase Orders",
        "goods-received-notes" : "Goods Received Notes",
        bills : "Bills",
        "credit-notes" : "Credit Notes",
        payments : "Payments",
        refunds : "Refunds",
    }
  };
  