import React, { useEffect, useState } from 'react';
import RefreshLoading from '../containers/RefreshLoading';
import { selectCurrGa } from '../stores/slices/persistRolesSlices';
import { selectUser } from '../stores/slices/userAuthSlices';

const ProtectedRoute_GA = ({ children }) => {

  const user = selectUser();
  const [loading, setLoading] = useState(true);
  const isGA = selectCurrGa();

  useEffect(() => {
    const waitAndSetLoading = async () => {
      await new Promise(resolve => setTimeout(resolve, 1500));
      setLoading(false);
    };
    waitAndSetLoading();
  }, [user]);
  
  if(loading){
    return <RefreshLoading/>;
  }
  
  // if(!user && !isGA){
  //   return null;
  // }

  return children;

};

export default ProtectedRoute_GA