
import moment from "moment";

function isObject(value){

    return typeof value === "object" && value !== null && !Array.isArray(value);
      
}

export const TableFilterOperator = {
  PARTIALLY_PAY: {
    label: "Partially",
    value: "<",
    getApplyFilterFn: () => {
      return ({ value, row }) => {
        return value > 0.0 && row.amount < value;
      };
    },
  },
  PAYMENT_OUSTANDING: {
    label: "Outstanding",
    value: ">",
    getApplyFilterFn: () => {
      return ({ value }) => {
        return value > 0;
      };
    },
  },

  BETWEEN_DATE: {
    label: "Between",
    value: "between",
    getApplyFilterFn: (filterItem) => {
      if (filterItem.value != null && isObject(filterItem.value)) {
        const startDate = filterItem.value.startDate
          ? moment(filterItem.value.startDate)
          : null;
        const endDate = filterItem.value.endDate
          ? moment(filterItem.value.endDate)
          : null;

        return ({ value }) => {
          const dateValue = moment(value);

          const minDate = startDate ? dateValue.isSameOrAfter(startDate) : true;
          const maxDate = endDate ? dateValue.isSameOrBefore(endDate) : true;
          return minDate && maxDate;
        };
      }

      return null;
    },
  },
  INCLUDE_ITEM: {
    label: "In",
    value: "in",
    getApplyFilterFn: (filterItem) => {
      if (
        filterItem.value != null &&
        Array.isArray(filterItem.value) &&
        filterItem.value.length > 0
      ) {
        return ({ value }) => {
          return filterItem.value.includes(value);
        };
      }

      return null;
    },
  },
  COLUMN_INCLUDE_ITEM: {
    label: "Include",
    value: "include",
    getApplyFilterFn: (filterItem) => {
      if (filterItem.value !== null || filterItem.value !== "") {
        return null;
      }
      return ({ value }) => {
        return value.includes(filterItem.value);
      };
    },
  },

  TRANSACTION_INCLUDE_ITEM: {
    label: "In",
    value: "in",
    getApplyFilterFn: (filterItem) => {
      const isArray =
        filterItem.value != null && Array.isArray(filterItem.value);
      //if more got value to filter
      if (isArray && filterItem.value.length > 0) {
        return ({ value }) => {
          return filterItem.value.includes(value);
        };
      }
      //if no value to filter then show all except void
      if (
        isArray &&
        filterItem.value.length == 0 &&
        filterItem.field == "status"
      ) {
        return ({ value }) => {
          return value != "VOID";
        };
      }

      return null;
    },
  },

  CUSTOM_FIELD_FILTER: {
    label: "In",
    value: "in",
    getApplyFilterFn: (filterItem) => {
      const filterValue = filterItem.value;
      if (filterValue.filter && filterValue.availableField) {
        const availableCustomField = filterValue.availableField.map(
          (field) => field.name
        );
        const inputValue = filterValue.filter;
        return (value) => {
          if (value) {
            const customFieldIncluded = Object.keys(value).some((key) => {
              if (availableCustomField.includes(key)) {
                return value[key].toLowerCase().includes(inputValue);
              }
              return false;
            });
            return customFieldIncluded;
          }
          return false;
        };
      }

      return null;
    },
  },
  EXCLUDE_VOID: {
    label: "Equals",
    value: "equals",
    getApplyFilterFn: (filterItem) => {
      if (filterItem.value == "") {
        return ({ value }) => {
          return value != "VOID";
        };
      } else {
        return ({ value }) => {
          return value == filterItem.value;
        };
      }
    },
  },
};
