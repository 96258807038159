//convert table properties that convert underscore to space and first letter uppercase
const lowerCaseSnakeCase = (str) => {
    const splittedString = str.split(" ");
    const lowercaseStringList = splittedString.map((word) => word.toLowerCase());
    const result = lowercaseStringList.join("_");
    return result;
  };
  
  export const tablePropertiesConverter = (str) => {
    const result = lowerCaseSnakeCase(str);
    return result;
  };
  
  export const tablePropertiesIdConverter = (str) => {
    const tableProperties = lowerCaseSnakeCase(str);
    return `${tableProperties}_id`;
  };
  
  //convert enum value to PascalCase
  const convertEnumToPascalCase = (str) => {
    const splittedString = str.split("_");
    const firstUpperCaseStringList = splittedString.map(
      (word) => word.charAt(0) + word.slice(1).toLowerCase()
    );
    const result = firstUpperCaseStringList.join(" ");
    return result;
  };
  
  // use for react hook form example object form account.account_id
  // const getFormProperty = (obj, path) =>
  //   path.split(".").reduce((acc, key) => acc[key], obj);
  
  //convert the raw data by using columnFields specificed by us into advanced filter options
  export const advanceFilterOptionsConverter = (columnFields) => {
    const listOfRow = Object.keys(columnFields).reduce((acc, group) => {
      const columnConfig = columnFields[group];
      const resultField = columnConfig.field;
      const result = columnConfig.data.map((rawData) => ({
        label: rawData[resultField.label],
        value: resultField.pascalCase
          ? convertEnumToPascalCase(rawData[resultField.value])
          : rawData[resultField.value],
        group: group,
      }));
  
      return [...acc, ...result];
    }, []);
    return listOfRow;
  };
  
  //! used to get value from row data
  // export const advanceFilterOptionsConverter = (listOfData, columnFields) => {
  //   const filterOptions = listOfData.reduce((result, data) => {
  //     const listOfRow = Object.keys(columnFields).reduce((acc, column) => {
  //       const valueField = columnFields[column].value;
  //       const rowValue = getFormProperty(data, valueField);
  //       const convertToPascalCase = columnFields[column].pascalCase;
  //       const optionIsExist = result.find((value) => value.value == rowValue);
  //       if (!optionIsExist) {
  //         const labelField = columnFields[column].label;
  //         const labelValue = getFormProperty(data, labelField);
  //         const optionValue = {
  //           label: convertToPascalCase
  //             ? convertEnumToPascalCase(labelValue)
  //             : labelValue,
  //           value: rowValue,
  //           group: tablePropertiesConverter(column),
  //         };
  //         return [...acc, optionValue];
  //       }
  //       return acc;
  //     }, []);
  
  //     return [...result, ...listOfRow];
  //   }, []);
  
  //   return filterOptions.sort((a, b) => (a.group > b.group ? 1 : -1));
  // };
  