import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { InputCheckbox } from "../Input/InputCheckbox";

export const FormInputCheckbox = ({
  label,
  tooltips,
  isDisabled,
  required,
  helperText,
  formOnChange,
  ...props
}) => {
  return (
    <Controller
      {...props}
      shouldUnregister
      render={({
        field: { onChange, onBlur, value, ref },
        fieldState: { error },
      }) => {
        return (
          <InputCheckbox
            value={value || false}
            label={label}
            tooltips={tooltips}
            onChange={
              formOnChange
                ? (event) => {
                    const data = event.target.checked;
                    formOnChange(data);
                    onChange(data);
                  }
                : onChange
            }
            onBlur={onBlur}
            error={error?.message}
            helperText={helperText}
            name={props.name}
            isDisabled={isDisabled}
            required={required}
            fieldRef={ref}
          />
        );
      }}
    />
  );
};

FormInputCheckbox.propTypes = {
  label: PropTypes.any,
  tooltips: PropTypes.any,
  isDisabled: PropTypes.any,
  required: PropTypes.any,
  name: PropTypes.string,
  helperText: PropTypes.any,
  formOnChange: PropTypes.any,
};
