export const TableActionEnum = {
    VIEW: "VIEW",
    EDIT: "EDIT",
    DELETE: "DELETE",
    OPTION: "OPTION",
    DEACTIVE: "DEACTIVE",
    DUPLICATE: "DUPLICATE",
    EMAIL: "EMAIL",
    TRANSFER: "TRANSFER",
    FILES: "FILES",
    INVITATION: "INVITATION",
    NUMBER_INPUT: "NUMBER_INPUT",
    VOID: "VOID",
    CURRENCIES: "CURRENCIES",
    SHARE: "SHARE",
    SEND: "SEND",
  };
  