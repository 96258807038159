import React, {
    useState,
} from "react";
import './GaPortal.scss'
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { signOut, updateProfile } from 'firebase/auth';
import { auth } from '../../firebaseInit';
import { GoSignOut } from "react-icons/go";
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarFooter,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
} from 'cdbreact';
import { removeFromLocalStorage } from "../../util/LocalStorage";
import { selectCurrGa, setIsGA } from "../../stores/slices/persistRolesSlices";
import { useEffect } from "react";
import { useRef } from "react";
import { selectGaPortalContext, setGaPortalContext } from "../../stores/slices/gaPortalSlices";
import { selectUser } from "../../stores/slices/userAuthSlices";
import { BiArrowBack } from "react-icons/bi";

function GaHeader() {

  const user = selectUser();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { gaCurrPage } = selectGaPortalContext();
  const currGa = selectCurrGa();

  const [showSetting,setShowSetting] = useState(false);
  
  const location = useLocation();
  const logoutRef = useRef();

  useEffect(() => {
    function handleClickOutside(event) {
        if (logoutRef.current && !logoutRef.current.contains(event.target)) {
            setShowSetting(false);
        }
    }
    
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    
    // Unbind the event listener on cleanup
    return () => {
        document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [logoutRef]);


  function handleChangePage(page){
    if(location.pathname !== "/GaPortal"){
      navigate("/GaPortal")
    }
    dispatch(setGaPortalContext({gaCurrPage : page}));
    // dispatchGaCurrPage({type: "CHANGE_PAGE", payload: page})
    
  }

  const handleLogout = async () => {
    if(user && user.uid){
      try {
        await signOut(auth)
        removeFromLocalStorage("ACCESS_TOKEN");
        removeFromLocalStorage("REFRESH_TOKEN");
        removeFromLocalStorage("CURR_TENANT");
        removeFromLocalStorage("CURR_COMPANY");
        removeFromLocalStorage("CURR_ROLE");
        removeFromLocalStorage("ACCESS_TOKEN_EXPIRY");
        removeFromLocalStorage("LOGIN_EXPIRATION");
        removeFromLocalStorage("IS_GA");
        removeFromLocalStorage("IS_SA");
        dispatch(setIsGA(false));
        navigate("/Auth")
      } catch (error) {
          console.log(error.message)
      }
    }
  };

  return (
    <div style={{ position: 'relative',height:"100%",width:"15%" }}>
      <div ref={logoutRef} className={`gaPortalSettingsContainer ${showSetting && 'gaPortalshowSetting'}`}>
        <div  className={`tabContainer`} onClick={() => { handleLogout(); }}>
          <span>Logout</span>
          <GoSignOut size={20} />
        </div>
      </div>
      <CDBSidebar className="gaPortal-sidebar-container gaPortalcETVre" textColor="rgb(103, 116, 142)" backgroundColor="#0a0e3c" maxWidth='240px' breakpoint={770}>
       
        <div className='sidebarHeaderActions'>
          <div className='logoMainContainer'>
            <img src={require("../../assets/img/zumax-logo.webp")} alt=''/>
          </div>
          <div className='iconContainer' onClick={() => { setShowSetting(!showSetting); }}>
            <div className="profileContainer">
              <img src={user?.photoURL} alt=""/>
            </div>
          </div>
        </div>
        <CDBSidebarContent className="sidebar-content">

          <CDBSidebarMenu className="menu-container">
            <div id='Dashboard' className={`selection ${gaCurrPage === "DASHBOARD" && 'selectedSection'}`} onClick={() => {handleChangePage("DASHBOARD") }}>
              <CDBSidebarMenuItem className='menu-item-container' iconClassName="icon-style" icon="th-large">Dashboard</CDBSidebarMenuItem>
            </div>
            <div id='Analytics' className={`selection ${gaCurrPage === "TENANTLIST" && 'selectedSection'}`} onClick={() => { handleChangePage("TENANTLIST") }} style={{ display: "flex" }}>
              <CDBSidebarMenuItem className='menu-item-container' iconClassName="icon-style" icon="briefcase">Tenant List</CDBSidebarMenuItem>
            </div>
            <div id='Conversation' className={`selection ${gaCurrPage === "MEMBERLIST" && 'selectedSection'}`} onClick={() => {handleChangePage("MEMBERLIST")}}>
              <CDBSidebarMenuItem className='menu-item-container' iconClassName="icon-style" icon='user-tie'>User List</CDBSidebarMenuItem>
            </div>
          </CDBSidebarMenu>
          
        </CDBSidebarContent>
        <CDBSidebarFooter style={{ textAlign: 'center' }}>
        {currGa ? 
           <div className="back-to-tenant-container" onClick={() => { navigate("/Tenant") }}>
              <div><BiArrowBack size={20}/></div><span>Back to Workspace</span>
           </div>
          :
          <></>
        }
        </CDBSidebarFooter>

      </CDBSidebar>
    </div>
  )
}

export default GaHeader;