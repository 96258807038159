import React, {
    useState,
    useEffect,
    useRef,
} from "react";
import moment from "moment";
import { Modal } from "bootstrap";
import { useDispatch } from "react-redux";
import { createSearchParams, useNavigate } from "react-router-dom";
import C_Loading from "../../components/C_Loading";
import './Company.scss'
import { MdEditDocument } from "react-icons/md";
import { FaCameraRetro } from "react-icons/fa";
import { TbArrowBigLeftFilled, TbBusinessplan, TbRefresh } from "react-icons/tb";
import { HiOutlineLogout } from "react-icons/hi";
import { signOut } from 'firebase/auth';
import { ref, remove, set, update } from 'firebase/database'
import { db, auth, storage } from '../../firebaseInit';
import { AiFillCheckCircle, AiFillPlusSquare, AiOutlineClose, AiOutlinePlusCircle } from "react-icons/ai";
import { getDownloadURL, uploadBytesResumable  } from "firebase/storage";
import C_DialogButton from "../../components/C_DialogButton";
import { BsCheck2Circle, BsFillCaretDownFill } from "react-icons/bs";
import C_PopoutNoti from "../../components/C_PopoutNoti";
import { v4 } from "uuid";
// import permission from '../roles/permission.json';
import permission from '../../enums/json/permission.json';

import plan from '../../enums/json/plan.json';
import { addToLocalStorage, getFromLocalStorage, removeFromLocalStorage, updateLocalStorage } from "../../util/LocalStorage";

//slices
import { setCompanyID, setCompanyIDForRole } from "../../stores/slices/companyIDSlices";
import { createRole, retrieveCurrRoles } from "../../stores/slices/roleSlices";
import { selectCurrSa, selectCurrTenant, setIsSA, setTenant } from "../../stores/slices/persistRolesSlices";
import { createCompany, retrieveCompany, selectCompanyContext, selectCurrUserContext, setCompanyContext } from "../../stores/slices/companySlices";
import { retrieveAllAgent, retrieveCompanyAgent, selectAllCompanyAgent, selectCompanyAgent, selectRetrieveCompanyAgentLoading } from "../../stores/slices/companyAgentSlices";
import { selectTenantLoading, retrieveAvailableTenant, selectAvailableTenant, selectTenant, handleSelectTenant } from "../../stores/slices/tenantSlices";
import { selectUser } from "../../stores/slices/userAuthSlices";


function Company() {
    const dbRef = ref(db);

    const permissions = permission.permissions

    const planModal = useRef(null);
    const tenantRef = useRef(null);
    const profileRef = useRef(null);
    const newCompanyaModalRef = useRef(null);
    const resubmitRef = useRef(null);
    
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { companyList, availableCompany } = selectCompanyContext();
    const user = selectUser();
    const { allUsage, currUserData, requestData } = selectCurrUserContext();

    const currSa = selectCurrSa();
    const tenant = selectCurrTenant();
    const availableTenant = selectAvailableTenant();
    const allCompanyAgent = selectAllCompanyAgent();
    const [showTenant, setShowTenant] = useState(false);
    const [showProfile, setShowProfile] = useState(false);
    const [notifyMessage, setNotifyMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [companyDetails, setCompanyDetails] = useState({
        CompanyProfile: '',
        CompanyName: '',
        CompanyLocation: '',
        CompanyEmail: ''
    });

    const [resubmitID,setResubmitID] = useState("");
    const [resubmitCompanyID,setResubmitCompanyID] = useState("");
    const [selectedPlan,setSelectedPlan] = useState("");
    const [isRequestPlan,setIsRequestPlan] = useState(false);
    const [companyName,setCompanyName] = useState("");
    const [isConfirm,setIsConfirm] = useState(false);
    const [isProcessing,setIsProcessing] = useState(false);
    const [isVoid,setIsVoid] = useState(false);
    const [userDetails, setUserDetails] = useState({});

    const IS_SA = getFromLocalStorage("IS_SA");
    
    // Protected Layer
    useEffect(() => {
       const currTenant = getFromLocalStorage("CURR_TENANT");
        if(!tenant && currTenant === null){
            navigate("/Tenant")
        } else {
            if(tenant && user){
                dispatch(retrieveCompany({TenantID:tenant ? tenant : currTenant, isSA: currSa, UserID: user?.uid}));
            }
        }

    },[tenant, user])

    useEffect(()=>{
        if(user){
            const getCurrCompanyAgent = allCompanyAgent.filter(item => item.UserID === user.uid);

            const companyDetails = getCurrCompanyAgent.map(agent => {
                const matchedCompany = companyList.filter(c => c.CompanyID === agent.CompanyID);
                return matchedCompany[0] ? matchedCompany[0] : {};
            });

            dispatch(setCompanyContext({availableCompany : companyDetails}))
            
        }
    }, [user, companyList, allCompanyAgent])

    useEffect(()=>{
        if(tenant && user && IS_SA){
            dispatch(retrieveCompany({TenantID:tenant, isSA: IS_SA, UserID: user?.uid}));
            dispatch(retrieveAvailableTenant({ UserID: user?.uid }));
            dispatch(retrieveAllAgent({TenantID:tenant}));
        }
    }, [tenant, user, IS_SA])

    const showPlanModalFunc = () => {
        const modalEle = planModal.current;
        const bsModal = new Modal(modalEle, {
          backdrop: "static",
          keyboard: false,
        });
        bsModal.show();
    };
    
    const hidePlanModalFunc = () => {
        const modalEle = planModal.current;
        const bsModal = Modal.getInstance(modalEle);
        bsModal.hide();
    };

    const showResubmitModal = () => {
        const modalEle = resubmitRef.current;
        const bsModal = new Modal(modalEle, {
            backdrop: "static",
            keyboard: false,
        });
        bsModal.show();
    };

    const hideResubmitModal = () => {
        const modalEle = resubmitRef.current;
        const bsModal = Modal.getInstance(modalEle);
        bsModal.hide();
    };

    const showNewCompanyModalFunc = () => {
        const modalEle = newCompanyaModalRef.current;
        const bsModal = new Modal(modalEle, {
          backdrop: "static",
          keyboard: false,
        });
        bsModal.show();
    };
    
    const hideNewCompanyModalFunc = () => {
        const modalEle = newCompanyaModalRef.current;
        const bsModal = Modal.getInstance(modalEle);
        bsModal.hide();
        setCompanyDetails({
            CompanyProfile: '',
            CompanyName: '',
            CompanyLocation: '',
            CompanyEmail: ''
        });
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (tenantRef.current && !tenantRef.current.contains(event.target)) {
                setShowTenant(false);
            }
        }
      
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
      
        // Unbind the event listener on cleanup
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [tenantRef]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (profileRef.current && !profileRef.current.contains(event.target)) {
                setShowProfile(false);
            }
        }
        
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        
        // Unbind the event listener on cleanup
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [profileRef]);

    function notify(type){
        switch(type)
        {
          case "create":
            setNotifyMessage("CREATED COMPANY")
            break;
          case "edit":
            setNotifyMessage("UPDATED COMPANY")
            break;
          case "delete":
            setNotifyMessage("DELETED COMPANY")
            break;
          case "removed_member":
            setNotifyMessage("MEMBER REMOVED")
            break;
          case "added_member":
            setNotifyMessage("MEMBER ADDED")
            break;
          default:
            break;
        }
    
        setTimeout(() => {
          setNotifyMessage("");
        }, 1500)
    };
    
    const handleLogout = async () => {
        if(user && user.uid){
            try {
              dispatch(setCompanyIDForRole(null));
              dispatch(setCompanyID(null));
              dispatch(setIsSA(false));
              dispatch(retrieveCurrRoles({TenantID:tenant,RoleID:""}))
              await set(ref(db, 'users/' + user.uid + '/fcm'), '')
                .then((result) => {
                    console.log("REMOVED NOTIFICATION TOKEN")
                })
                .catch(err => {
                    console.log(err)
                })
              await signOut(auth).then(()=>{ navigate("/Auth")})
              removeFromLocalStorage("ACCESS_TOKEN");
              removeFromLocalStorage("REFRESH_TOKEN");
              removeFromLocalStorage("CURR_TENANT");
              removeFromLocalStorage("CURR_COMPANY");
              removeFromLocalStorage("CURR_ROLE");
              removeFromLocalStorage("ACCESS_TOKEN_EXPIRY");
              removeFromLocalStorage("LOGIN_EXPIRATION");
              removeFromLocalStorage("IS_GA");
              removeFromLocalStorage("IS_SA");
              navigate("/Auth");
            } catch (error) {
                console.log(error.message)
            }
        }
    };

    async function handleBackToTenant(){

        removeFromLocalStorage("CURR_TENANT");
        dispatch(setTenant(null)),
        dispatch(setIsSA(false))

        setTimeout(()=>{
            navigate("/Tenant");
        }, 500)
            
    };

    async function selectTenant(item){

        const { HostID, TenantID } = item;
        const isSA = HostID === user.uid;

        addToLocalStorage("IS_SA", isSA);
        const tenantLocalStorage = getFromLocalStorage("CURR_TENANT")
    
        tenantLocalStorage ? updateLocalStorage("CURR_TENANT", TenantID) : addToLocalStorage("CURR_TENANT", TenantID),

        dispatch(handleSelectTenant({TenantID:TenantID, isSA: isSA, UserID: user.uid, navigation : true}))
        
    };

    async function handleNavigateCompany(item){
        
        setUserDetails(item);
        if(currSa){
            await Promise.resolve(navigate({ pathname: "/Preload", search: createSearchParams({ CompanyID: item?.CompanyID, RoleID: "SA" }).toString() }));
        }else{
            dispatch(retrieveCompanyAgent({TenantID:tenant,CompanyID:item?.CompanyID, Navigation : true, user : user}));
        }
    }

    async function handleRefreshCompanyList(){

        dispatch(handleSelectTenant({TenantID : tenant, isSA : currSa, UserID : user?.uid, navigation:false}));
        // dispatch(retrieveCompany({TenantID:tenant, isSA: currSa,UserID: user?.uid}));
    }

    function handleInput({type, value}){
        switch(type){
            case "CompanyProfile":
                uploadCompanyImage({file: value}).then(url => {
                    setCompanyDetails({...companyDetails, CompanyProfile: url})
                });
                break;
            case "CompanyName":
                setCompanyDetails({...companyDetails, CompanyName: value})
                break;
            case "CompanyLocation":
                setCompanyDetails({...companyDetails, CompanyLocation: value})
                break;
            case "CompanyEmail":
                setCompanyDetails({...companyDetails, CompanyEmail: value})
                break;
            default:
                break;
        }
    };

    async function uploadCompanyImage({file}) {
        const profileID = v4();
        return new Promise((resolve, reject) => {
          const storageRef = ref(storage, "/companyProfileImg/" + profileID);
          const uploadTask = uploadBytesResumable(storageRef, file);
      
          uploadTask.on('state_changed',
            (snapshot) => {
              // Observe state change events such as progress, pause, and resume
              // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log('Upload is ' + progress + '% done');
              switch (snapshot.state) {
                case 'paused':
                  console.log('Upload is paused');
                  break;
                case 'running':
                  console.log('Upload is running');
                  break;
              }
            },
            (error) => {
              // Handle unsuccessful uploads
              reject(error);
            },
            () => {
              getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                resolve(downloadURL);
              }).catch((error) => {
                reject(error);
              });
            }
          )
        });
    }

    async function createNewCompany(){
        if(companyDetails.CompanyName !== ""){
            const CompanyID = v4();
            const RoleID = v4();
            const GroupID = v4();
            const data = {
                RoleID: RoleID,
                CompanyID: CompanyID,
                DefaultRole: true,
                RoleName: "Default",
                RoleColor: "#919191"
            }
            const CompanyData = {
                ...companyDetails,
                CompanyID: CompanyID,
                CreatedAt: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                ModifiedAt: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                DefaultRole: RoleID
            }

            for (let i = 0; i < permissions.length; i++) {
                const key = permissions[i].key;
                if (key !== null) {
                    data[key] = false;
                }
            }

            setIsLoading(true);
            dispatch(createRole({TenantID:tenant,data:data}))
            dispatch(createCompany({TenantID:tenant,data:CompanyData}));
    
            setTimeout(()=>{
                dispatch(retrieveCompany({TenantID:tenant, isSA: currSa,UserID: user.uid}));
                setIsLoading(false);
                setError(false)
                hideNewCompanyModalFunc();
                notify("create");
            }, 2000)
        }else{
            setError(true)
        }
    }

    async function handleActivateCompany({Company, Usage}){
        const createdAt = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
        try{
            await set(ref(db, 'usage/' + tenant + "/" + Company?.CompanyID + "/" ), {
                ...Usage,
                activation: true,
                startDate: createdAt,
                expiryDate: Usage.plan === "Trial" ? moment(createdAt).add(7,'d').format('YYYY-MM-DD HH:mm:ss') : moment(createdAt).add(1,'year').format('YYYY-MM-DD HH:mm:ss')
            })
        }catch(err){
            alert(err);
        }
    };

    const handleRequestCompany = async (plan) => {
        setSelectedPlan(plan);
        setIsRequestPlan(true);
    }

    const sendCompanyRequest = async () => {
        setIsConfirm(true);
        setIsProcessing(true);
        const companyID = v4();
        await new Promise((resolve) => setTimeout(resolve, 2000));
        await set(ref(db, 'planRequest/' + user.uid + "/" + companyID + "/"), {
            TenantID: currUserData.TenantID,
            companyID: companyID,
            companyName: companyName,
            companySize: currUserData?.companySize,
            displayName: currUserData?.displayName,
            firstName: currUserData?.firstName,
            lastName: currUserData?.lastName,
            state: currUserData?.state,
            country: currUserData?.country,
            email: currUserData?.email,
            phoneNum: currUserData?.phoneNum,
            plan: selectedPlan,
            status: "pending",
            uid: currUserData?.uid,
            workspaceName: currUserData.workspaceName,
            isTenant:currUserData.isTenant,
            createdAt:moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            approvalDate:""
        })
        .then(()=>{
            setTimeout(()=>{setIsProcessing(false)},2000)
        })
    }

    const handleResubmissionCompany = async () => {
        const uid = resubmitID;
        const companyID = resubmitCompanyID;
        await update(ref(db, 'planRequest/' + uid + '/' + companyID + '/'), {
            status:"resubmission",
        }).then(()=>{
            hideResubmitModal();
            setResubmitCompanyID("");
            setResubmitID("");
        })
    }

    const handleVoidRequest = async () => {
        const uid = resubmitID;
        const companyID = resubmitCompanyID;
        await remove(ref(db, 'planRequest/' + uid + '/' + companyID)).then(()=>{
            hideResubmitModal();
            setResubmitCompanyID("");
            setResubmitID("");
            setIsVoid(false);
        })
    }

  return (
    <div className="main-company-container">
        <C_PopoutNoti triggerSaved={notifyMessage}/>
        <C_Loading isLoading={isLoading}/>
        {/* Modal Start */}
        <div className="modal fade" tabIndex="-1" ref={resubmitRef} style={{}}>
            <div className="modal-dialog modal-dialog-centered" style={{}}>
                <div className="modal-content px-3" style={{overflowY: "scroll" }}>
                    <div className="modal-header modalHeader">
                        <div className="title">
                            <span className="font-s">
                                Confirmation
                            </span>
                        </div>
                        <AiOutlineClose cursor='pointer' size={16} onClick={()=>{hideResubmitModal();}}/>
                    </div>
                    <div className="modal-body action-body">
                        <div className="">
                            {isVoid && <><span className="font-xs" style={{ fontWeight: "bold",color:'#b61e1e' }}>
                                Are you sure you want to void this request? This action cannot be reverted.
                            </span></>}
                            {!isVoid &&<span className="font-xs" style={{ fontWeight: "bold" }}>
                                Are you sure you want to resubmit this request ? This action cannot be reverted. 
                                {` (Or do you want to void this request. `}<span onClick={()=>{setIsVoid(true);}} className="void-span">Click here</span>{` to void the request)`}.
                            </span>}
                        </div>
                        <div
                            style={{ display: "flex", justifyContent: "flex-end",columnGap:'20px',alignItems:"center" }}
                            className="mt-2"
                        >
                            <C_DialogButton 
                            // 707070
                            background={'#ffffff'}
                            color={'#acacac'}
                            borderColor={'#acacac'}
                            buttonText={isVoid ? "BACK":"CANCEL"}
                            onClick={() => {isVoid ? setIsVoid(false) :hideResubmitModal();}}
                            />
                            <C_DialogButton 
                            color={"white"}
                            background={isVoid?'#b61e1e':'#3eb986'}
                            borderColor={isVoid?'#b61e1e':'#3eb986'}
                            buttonText={isVoid ? "CONFIRM" : "CONTINUE"}
                            onClick={() => {isVoid ? handleVoidRequest() :handleResubmissionCompany();}}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* Modal End */}
        <div className="modal fade" tabIndex="-1" ref={planModal} style={{ zIndex: 100000 }}>
            <div className={`modal-dialog modal-dialog-centered ${isRequestPlan ? "modal" : "modal-xl"}`} style={{}}>
                <div className="modal-content px-3" style={{overflowY: "scroll" }}>
                    <div className="modal-header modalHeader">
                        <div className="title">
                            <TbBusinessplan size={17}/>
                            {!isRequestPlan && <span className="font-s">Plans</span>}
                            {isRequestPlan && <span className="font-s">{`Request Plan (${selectedPlan})`}</span>}
                        </div>
                        <AiOutlineClose cursor='pointer' size={16} onClick={() => {
                            hidePlanModalFunc();
                        }}/>
                    </div>
                    {isRequestPlan && 
                        <div className="modal-body request-plan-body">
                            <div className="input-list-container">
                                <div className="company-name-input">
                                    <span>Company Name</span>
                                    <div className="input-container">
                                        <input type="text" value={companyName} onChange={(e)=>{setCompanyName(e.target.value)}}/>
                                    </div>
                                </div>
                                <div className="company-name-input">
                                    <span>Email Address</span>
                                    <div className="input-container">
                                        <input type="text" value={currUserData?.email} onChange={(e)=>{}} disabled/>
                                    </div>
                                </div>
                                <div className="company-name-input">
                                    <span>Contact Number</span>
                                    <div className="input-container">
                                        <input type="text" value={currUserData?.phoneNum} onChange={(e)=>{}} disabled/>
                                    </div>
                                </div>
                            </div>
                            <div className="action-button-container">
                                <div className={`py-1 loadingContainer ${!isConfirm && "hide-loading-container"}`}>
                                    <div className={`${isProcessing ? "loading-spinner" : "hide-loading-spinner"}`}>
                                    </div>
                                    {isProcessing ?
                                    <>
                                    <span>Processing your request.</span>
                                    </>
                                    :
                                    <div className="finished-loading-container">
                                    <span>Your request has been sent. Our sales team will contact you shortly.</span> 
                                    <div><BsCheck2Circle size={18}  style={{fontWeight:"600",color:"green"}}/></div>
                                    </div>}
                                </div>
                                <div className="btn-container">
                                    {isConfirm ?
                                        <>
                                        {isProcessing ? 
                                            <div className="disabled-back-btn" disabled>
                                                
                                                <span>Continue</span>
                                            </div>
                                            :
                                            <div className="request-btn" onClick={()=>{hidePlanModalFunc();}}>
                                              
                                                <span>Continue</span>
                                            </div>
                                        }
                                        </>
                                    :
                                    <>
                                        <div className="back-btn" onClick={()=>{setIsRequestPlan(false);}}>
                                            <div className="back-icon"><TbArrowBigLeftFilled size={17}/></div>
                                            <span>Back</span>
                                        </div>
                                    
                                        <div className="request-btn" onClick={()=>{sendCompanyRequest();}}>
                                            <div className="request-icon"><MdEditDocument size={16}/></div>
                                            <span>Request</span>
                                        </div> 
                                    </>}
                                </div>
                                
                            </div>
                        </div>
                    }
                    {!isRequestPlan && 
                    <div className="modal-body planBody">
                        <div className="plan">
                            <div className="planTitle">
                                <span className="title">Standard</span>
                                <span className="subtitle">Perfect Plan for Starters.</span>
                            </div>
                            <div className="planPrice">
                                <span><span className="unit">RM</span><span className="price">18</span> / User / Month</span>
                            </div>
                            <div className="planFeatures">
                                {Object.values(plan.Standard).map((item, planIndex) => {
                                return(
                                    <div key={planIndex} className="feature">
                                        <div className="dot"><div className="white-dot"></div></div>
                                        <span>{item?.Quota}{" "}{item?.SectionName}{" "}{item?.Unit}</span>
                                    </div>
                                )
                                })}
                            </div>
                            <div className="planAction">
                                <button onClick={()=>{handleRequestCompany("Standard");}}><span>SELECT PLAN</span></button>
                            </div>
                        </div>
                        <div className="plan current">
                            <div className="planTitle">
                                <span className="title">Pro</span>
                                <span className="subtitle">True Power of Marketing.</span>
                            </div>
                            <div className="planPrice">
                                <span><span className="unit">RM</span><span className="price">38</span> / User / Month</span>
                            </div>
                            <div className="planFeatures">
                                {Object.values(plan.Pro).map((item, planIndex) => {
                                return(
                                    <div key={planIndex} className="feature">
                                        <div className="dot"><div className="white-dot"></div></div>
                                        <span>{item?.Quota}{" "}{item?.SectionName}{" "}{item?.Unit}</span>
                                    </div>
                                )
                                })}
                            </div>
                            <div className="planAction">
                                <button onClick={()=>{handleRequestCompany("Pro");}}><span>SELECT PLAN</span></button>
                            </div>
                        </div>
                        <div className="plan">
                            <div className="planTitle">
                                <span className="title">Enterprise</span>
                                <span className="subtitle">Collaborate Professionally.</span>
                            </div>
                            <div className="planPrice">
                                <span><span className="unit">RM</span><span className="price">66</span> / User / Month</span>
                            </div>
                            <div className="planFeatures">
                                {Object.values(plan.Enterprise).map((item, planIndex) => {
                                    return(
                                        <div key={planIndex} className="feature">
                                            <div className="dot"><div className="white-dot"></div></div>
                                            <span>{item?.Quota}{" "}{item?.SectionName}{" "}{item?.Unit}</span>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className="planAction">
                                <button onClick={()=>{handleRequestCompany("Enterprise");}}><span>SELECT PLAN</span></button>
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
        </div>
        <div className="modal fade new_member modal-lg" id="new-member" tabIndex="-1" ref={newCompanyaModalRef}>
            <div className="modal-dialog modal-dialog-centered" style={{}}>
                <div className="modal-content px-3" style={{}}>
                    <div className="modal-header modalHeader">
                        <div className="title">
                            <AiOutlinePlusCircle size={17} color={"#4287f5"}/>
                            <span className="font-s">New Company</span>
                        </div>
                        <AiOutlineClose cursor='pointer' size={16} onClick={() => { hideNewCompanyModalFunc();  }}/>
                    </div>

                    <div className="modal-body-main modal-body d-flex row">
                        <div className="modal-body-container"> 
                            <div className="profileMainContainer">
                                <div className="inputContainer">
                                    <span className="font-m">Company Profile</span>
                                    <input type="file" style={{ display: 'none' }} id="file" onChange={e => {
                                            const fileSize = e.target.files[0].size;
                                            const fileMB = Math.round((fileSize / 1024));
                                            if(fileMB >= 4096){
                                                alert("File too Big, please select a file less than 4mb")
                                            }else{
                                                handleInput({ type: 'CompanyProfile', value: e.target.files[0]})
                                            }
                                        }}
                                        accept="image/*"
                                    />
                                    <label htmlFor="file">
                                        <div className='addImageContainer'>
                                            {companyDetails.CompanyProfile !== "" ?
                                                <img src={companyDetails.CompanyProfile} alt=""/>
                                                :
                                                companyDetails.CompanyName !== "" ? <span className="profile-font">{companyDetails.CompanyName[0]}</span> : <span className="definedSpan">A</span>
                                            }
                                            <div className="changeImage">
                                                <FaCameraRetro className='addImageIcon' size={30} color="rgb(217, 217, 217)"/>
                                                <span className="font-xxs">Change Profile</span>
                                                <span className="font-xxs">Size Limit 4MB*</span>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className="inputMainContainer">
                                <div className="new-company-body">
                                    <div className="" style={{ flexDirection: "column", display: "flex" }}>
                                        <span className="font-xs">Company Name <span className="font-xxs" style={{color :"#f54d4d",paddingLeft:'3px'}}>*</span>{error && <span className="error">Please fill in company name!</span>}</span>
                                        <input
                                            className="new-company-input font-s"
                                            placeholder="company name"
                                            value={companyDetails.CompanyName}
                                            onChange={(e) => {
                                                handleInput({ type: 'CompanyName', value: e.target.value})
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="new-company-body">
                                    <div className="" style={{ flexDirection: "column", display: "flex" }}>
                                        <span className="font-xs">Location</span>
                                        <input
                                            className="new-company-input font-s"
                                            placeholder="company location"
                                            value={companyDetails.CompanyLocation}
                                            onChange={(e) => {
                                                handleInput({ type: 'CompanyLocation', value: e.target.value})
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="new-company-body">
                                    <div className="" style={{ flexDirection: "column", display: "flex" }}>
                                        <span className="font-xs">Email</span>
                                        <input
                                            className="new-company-input font-s"
                                            placeholder="company email"
                                            value={companyDetails.CompanyEmail}
                                            onChange={(e) => {
                                                handleInput({ type: 'CompanyEmail', value: e.target.value})
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='new-button-container' >
                            <div className='button-container'>
                                <C_DialogButton
                                    onClick={()=>{ hideNewCompanyModalFunc() }}
                                    color={"#3eb986"}
                                    background={'white'}
                                    borderColor={"#3eb986"}
                                    buttonText={"DISCARD"}
                                />
                                <C_DialogButton
                                    onClick={()=>{ createNewCompany() }}
                                    color={"#FFF"}
                                    background={"#3eb986"}
                                    borderColor={"#3eb986"}
                                    buttonText={"CREATE"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div ref={tenantRef} className={`tenant-dropdown-container ${!showTenant && 'hide-tenant-dropdown'}`}>
            <div className="title-container">
                <span className="title">Workspaces</span>
                <span className="see-all" onClick={() => { handleBackToTenant() }}>See All</span>
            </div>
            <div className="tenant-listing-container">
                {availableTenant && [...availableTenant]?.sort((a, b) => {
                    if (a.TenantID === tenant) {
                        return -1;
                    } else if (b.TenantID === tenant) {
                        return 1;
                    } else {
                        return 0;
                    }
                }).map((item, index) => {
                    const selectedTenant = item?.TenantID === tenant;
                    const tenantID = item.TenantID
                    const email = item.EmailAddress;
                    const username = email.split('@')[0]; 
                    const slicedID = tenantID.slice(3, 10);
                    const mixedString = username + slicedID;
                    return(
                        <div key={index} className={`tenant ${!selectedTenant && 'availableTenant'}`} onClick={() => { selectTenant(item) }}>
                            {selectedTenant && <div><AiFillCheckCircle size={20} color="#36ea39"/></div>}
                            <span className="name">{item?.WorkspaceName}</span>
                            <span className="id">{` (${mixedString})`}</span>
                        </div>
                    )
                })}
            </div>
        </div>
        <div ref={profileRef} className={`user-dropdown-container ${!showProfile && 'hide-user-dropdown'}`}>
            <div className="user-profile-detail">
                <img src={user?.photoURL}/>
                <div className="detail-container">
                    <span className="name">{user?.displayName}</span>
                    <span className="email">{user?.email}</span>
                </div>
            </div>
            <div className="profile-action-container" onClick={() => { handleLogout() }}>
                <HiOutlineLogout size={20}/>
                <span>Sign out</span>
            </div>
        </div>
        <div className="company-header-container">
            <div className="zumax-title-container">
                <span>Zumax CRM</span>
            </div>
            <div className="control-panel-container">   
                <div className="tenant-selection-container" onClick={() => { setShowTenant(true); setShowProfile(false); }}>
                    <span>{availableTenant?.find(item => item?.TenantID === tenant)?.CompanyName ?? "Tenant"}</span>
                    <BsFillCaretDownFill size={14}/>
                </div>
                <img src={user?.photoURL} alt="" onClick={() => { setShowProfile(true); setShowTenant(false); }}/>
            </div>
        </div>
        <div className="company-list-container">
            <div className="title-container">
                <span>All Company</span>
                <span className="refresh-span" onClick={()=>{handleRefreshCompanyList();}}><TbRefresh size={30}/></span>
                
            </div>
            <div className="list-container">
                {currSa &&
                    <div className="company-container new-company" onClick={() => { showPlanModalFunc();setIsConfirm(false);setIsRequestPlan(false); }}>
                        <div className="new-company-action">
                            <AiFillPlusSquare size={40}/>
                            <span>Add Company</span>
                        </div>
                    </div>
                }
                {!currSa && availableCompany?.map((item, index) => {
                    const usage = allUsage?.find(usg => usg?.companyID === item?.CompanyID);
                    const currentDate = moment(new Date());  // Get the current date
                    const startDate = moment(usage?.startDate ?? new Date());
                    const duration = moment.duration(startDate.diff(currentDate));
                    const daysDiff = duration.asDays();
                    const daysLeft = 7 - daysDiff;
                    // if trial end then not allow to click in
                    return(
                        <div key={index} className="company-container" onClick={() => { 
                            !usage?.activation && handleActivateCompany({Company: item, Usage: usage});
                            handleNavigateCompany(item);
                        }}>
                            <div className="company-detail">
                                <span>{item?.CompanyName}</span>
                                {item?.CompanyProfile !== "" ?
                                    <img src={item?.CompanyProfile} alt=""/>
                                    :
                                    <span className="image">{item?.CompanyName[0]}</span>
                                }
                            </div>
                            {usage?.activation && 
                                <div className="activation-true-detail">
                                    {usage?.plan === "Trial" ?
                                     <span>Trial {Math.floor(daysLeft)} {Math.floor(daysLeft) < 2 ? "Day" : "Days"} Left</span>
                                     :
                                     <span>{usage?.plan}</span>
                                    }
                                </div>
                            }
                            {!usage?.activation && 
                                <div className="activation-false-detail">
                                    <span>{usage?.plan === "Trial" ? "Click to start your 7 days free trial" : `Click to activate your company (${usage?.plan})`}</span>
                                </div>
                            }
                        </div>
                    )
                })}
                {currSa && companyList?.map((item, index) => {
                    const usage = allUsage?.find(usg => usg?.companyID === item?.CompanyID);
                    const currentDate = moment(new Date());  // Get the current date
                    const startDate = moment(usage?.startDate ?? new Date());
                    const duration = moment.duration(startDate.diff(currentDate));
                    const daysDiff = duration.asDays();
                    const daysLeft = 7 - daysDiff;
                    // if trial end then not allow to click in
                    return(
                        <div key={index} className="company-container" onClick={() => { 
                            !usage?.activation && handleActivateCompany({Company: item, Usage: usage});
                            handleNavigateCompany(item);
                        }}>
                            <div className="company-detail">
                                <span>{item?.CompanyName}</span>
                                {item?.CompanyProfile !== "" ?
                                    <img src={item?.CompanyProfile} alt=""/>
                                    :
                                    <span className="image">{item?.CompanyName[0]}</span>
                                }
                            </div>
                            {usage?.activation && 
                                <div className="activation-true-detail">
                                    {usage?.plan === "Trial" ?
                                     <span>Trial {Math.floor(daysLeft)} {Math.floor(daysLeft) < 2 ? "Day" : "Days"} Left</span>
                                     :
                                     <span>{usage?.plan}</span>
                                    }
                                </div>
                            }
                            {!usage?.activation && 
                                <div className="activation-false-detail">
                                    <span>{usage?.plan === "Trial" ? "Click to start your 7 days free trial" : `Click to activate your company (${usage?.plan})`}</span>
                                </div>
                            }
                        </div>
                    )
                })}
                {requestData && requestData?.map((item,index)=>{
                    if(item?.status !== "active" && item?.TenantID === tenant){
                        return(
                            <div key={index} className="company-container" onClick={() => { 
                                item?.status === "rejected" && showResubmitModal();
                                item?.status === "rejected" && setResubmitCompanyID(item?.companyID);
                                item?.status === "rejected" && setResubmitID(item?.uid);
                                // handleActivateCompany({Company: item, Usage: usage});
                                // handleNavigateCompany(item);
                            }}>
                                <div className="request-detail">
                                    <div className="company-detail">
                                        <span>{`${item?.companyName}`}</span>
                                        <span className="image">{item?.companyName[0]}</span>
                                    </div>
                                    
                                    <div className="plan-detail">
                                        <span className="plan-span">{`(${item?.plan})`}</span>
                                        <span className="date-span">{`Date Requested: ${moment(item.createdAt).format("YYYY-MM-DD")}`}</span>
                                    </div>
                                </div>
                                
                                {item?.status === "pending" &&
                                 <div className={`activation-pending-detail`}>
                                    <span>{item?.status}</span>
                                 </div>
                                }
                                {item?.status === "rejected" &&
                                 <div className={`activation-rejected-detail`}>
                                    <span>Request {item?.status}. Click to resubmit.</span>
                                 </div>
                                }
                                {item?.status === "resubmission" &&
                                 <div className={`activation-resubmission-detail`}>
                                    <span>{item?.status}</span>
                                 </div>
                                }
                            </div>
                        );
                    }
                    
                })}
            </div>
        </div>
    </div>
  )
};

export default Company;