const component = {
  styleOverrides: {
    MuiDatePicker: {
      root: () => ({
        // position: "fixed",
        // height: theme.sizing.headerHeight,
        // backgroundColor: theme.palette.primary.primary,
        // boxShadow: "unset",
        // borderBottom: `1px solid ${theme.palette.border.main}`,
        // display: "flex",
        // justifyContent: "center",
        // color: theme.palette.text.main,
        // "&.MuiPaper ": {
        //   color: "red",
        // },
        // "&.MuiPickersLayout .MuiDateCalendar .MuiPickersCalendarHeader": {
        //   backgroundColor: theme.palette.primary.primary,
        // },
      }),
      // paper: ({ theme }) => ({
      //   "& .MuiPickersPopper": {
      //     color: theme.palette.text.warning,
      //   },
      // }),
      // day: ({ theme }) => ({
      //   "&.Mui-selected": {
      //     color: theme.palette.text.warning,
      //     backgroundColor: theme.palette.background.warning,
      //   },
      // }),
    },
  },
};

export default component;
