import React, { useState } from 'react';
import "./C_HandlerSearchDropdown.scss";
import { useEffect } from 'react';
import { useRef } from 'react';
import { HiOutlineXMark } from 'react-icons/hi2';

const C_HandlerSearchDropdown = (props) => {

    const handlerList = props.handlerList;
    const selectedHandlerList = props.selectedHandlerList;
    const manageHandler = props.manageHandler;
    const action = props.action;
    const type = props.type;

    const [searchTerm, setSearchTerm] = useState('');
    const [toggleDropdown, setToggleDropdown] = useState(false);

    const chipsRef = useRef();
    const searchRef = useRef();
    const dropdownRef = useRef();

    //handle click outside
    useEffect(() => {
        function handleClickOutside(event) {

            if ((chipsRef.current && !chipsRef.current.contains(event.target)) && 
            (searchRef.current && !searchRef.current.contains(event.target)) && 
            (dropdownRef.current && !dropdownRef.current.contains(event.target))) {
                setToggleDropdown(false);
                setSearchTerm("");
            }
        }
        
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        
        // Unbind the event listener on cleanup
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [chipsRef, searchRef, dropdownRef, selectedHandlerList]);

    const filteredOptions = handlerList.filter((option) =>
        option.displayName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const singleActionPlaceholder = selectedHandlerList.length > 0 ? `${selectedHandlerList.length} selected` : 'Search...';
    const bulkActionPlaceholder = selectedHandlerList.filter(list => list.value === action).length > 0 ? 
        `${selectedHandlerList.filter(list => list.value === action).length} selected` : 'Search...';
        
    return (
        <div className='search-dropdown-container'>
        <div className='chips-and-search-container'>
            <div ref={chipsRef} className='selected-list-container'>
                {/* single action chips */}
                {(type !== "bulk" && selectedHandlerList.length > 0) && selectedHandlerList.map((handler => {
                    return (
                        <div className='chips-container'>
                            <div className='chips-details'>{handler.displayName}</div>
                            <div className="delete-btn">
                                <HiOutlineXMark color="#8D8D8D" size={13} cursor={"pointer"} onClick={() => {manageHandler(handler)}}/>
                            </div>
                        </div>
                    );
                }))}
                {/* bulk action chips */}
                {(type === "bulk" && selectedHandlerList.length > 0) && selectedHandlerList.map((handler => {
                    if(handler.value !== null && handler.value === action){
                        return (
                            <div className='chips-container'>
                                <div className='chips-details'>{handler?.data?.displayName}</div>
                                <div className="delete-btn">
                                    <HiOutlineXMark color="#8D8D8D" size={13} cursor={"pointer"} onClick={() => {manageHandler(handler, action)}}/>
                                </div>
                            </div>
                        );
                    }
                }))}
            </div>
            <input
                ref={searchRef}
                className={`search-input ${selectedHandlerList.length > 0 ? "search-width-50" : "search-width-100"}`}
                type="text"
                placeholder={type === "bulk" ? bulkActionPlaceholder : singleActionPlaceholder}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onClick={() => setToggleDropdown(true)}
            />
        </div>
        {(toggleDropdown && filteredOptions.length > 0) && 
            <div 
                ref={dropdownRef}
                className='dropdown-container'
            >
                {filteredOptions.map((option) => {
                    const singleActionChecker = selectedHandlerList.some(handler => handler.uid === option.uid);
                    const bulkActionChecker = selectedHandlerList.some(handler => handler.uid === option.uid && handler.value === action);
                    const bulkActionNullChecker = selectedHandlerList.some(handler => handler.uid === option.uid && handler.value === null);
                    const isRemovedHandler = action === "bulk_add" && !bulkActionChecker && !bulkActionNullChecker;
                    const isAddedHandler = action === "bulk_remove" && !bulkActionChecker && !bulkActionNullChecker
                    //action bulk_add, value false && !== null === (removed)
                    return (
                        <div className="options-container">
                            <label style={{ display: 'flex', alignItems: 'center' }}>
                                <input 
                                    type={"checkbox"} 
                                    value={option.uid} 
                                    checked={type === "bulk" ? bulkActionChecker : singleActionChecker}
                                    onChange={() => type === "bulk" ? manageHandler(option, action) : manageHandler(option)}
                                />
                                <span className="checkmark" style={{ marginRight: '10px' }}></span>
                                <span className={`label font-xs ${isRemovedHandler ? "removed-label" : isAddedHandler ? "added-label" : ""}`} title={option.displayName}>{option.displayName} {type === 'bulk' && 
                                (isRemovedHandler) ? `(removed)` : 
                                (isAddedHandler) ? `(added)` : "" }
                                </span>
                            </label>
                        </div>
                    )
                })}
            </div>
        }
        
        </div>
    );
};

export default C_HandlerSearchDropdown;
