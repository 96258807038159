const component = {
  MuiTabs: {
    styleOverrides: {
      root: ({ theme }) => ({
        color: theme.palette.text.primary,
        overflow: "visible",
        minHeight: "40px",
      }),
      indicator: ({ theme }) => ({
        backgroundColor: theme.palette.text.primary,
        height: "2px",
        marginBottom: "-2px",
      }),
      flexContainer: {
        gap: "15px",
        overflow: "visible",
      },
      scroller: {
        overflow: "visible !important",
      },
    },
  },
};

export default component;
