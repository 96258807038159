import { call, delay, put, takeLatest } from "redux-saga/effects";
import { 
    checkUserExists,
    checkUserExistsError,
    checkUserExistsSuccess,
    createFirebaseUser,
    createFirebaseUserError, 
    createFirebaseUserSuccess, 
    deleteFirebaseUser, 
    deleteFirebaseUserError, 
    deleteFirebaseUserSuccess,
    loginWithEmailAndPassword,
    loginWithEmailAndPasswordError,
    loginWithEmailAndPasswordGASuccess,
    loginWithEmailAndPasswordSuccess,
    loginWithEmailAndPasswordTenantSuccess,
    loginWithEmailAndPasswordVerifySuccess,
    refreshUserAuth,
    refreshUserAuthError,
    refreshUserAuthSuccess,
    updateFirebaseUser,
    updateFirebaseUserError,
    updateFirebaseUserSuccess
} from "../stores/slices/firebaseAuthSlices";
import { API, setHeaders } from "../http-common";
import { ApiRoute } from "../enums/ApiRoute";
import { addToLocalStorage, removeFromLocalStorage } from "../util/LocalStorage";
import { child, get, ref } from "firebase/database";
import { auth, db } from "../firebaseInit";
import ga from '../enums/json/generalAdmin.json';
import { setIsGASuccess, setIsSASuccess } from "../stores/slices/persistRolesSlices";
import { signInWithEmailAndPassword } from "firebase/auth";
const dbRef = ref(db);

function* createFirebaseUserSaga({payload}) {
    try{
        const { data } = yield call(API.post, ApiRoute.firebaseAuth.createFirebaseUser, payload);
        yield put(createFirebaseUserSuccess(data));
    } catch (error){
        yield put(createFirebaseUserError(error));
        console.error(error);
    }
}

function* deleteFirebaseUserSaga({payload}) {
    try{
        const { data } = yield call(API.post, ApiRoute.firebaseAuth.deleteFirebaseUser, payload);
        yield put(deleteFirebaseUserSuccess(data));
    } catch (error){
        yield put(deleteFirebaseUserError(error));
        console.error(error);
    }
}

function* updateFirebaseUserSaga({payload}) {
    try{
        const { data } = yield call(API.post, ApiRoute.firebaseAuth.updateFirebaseUser, payload);
        yield put(updateFirebaseUserSuccess(data));
    } catch (error){
        yield put(updateFirebaseUserError(error));
        console.error(error);
    }
}

function* checkUserExistsSaga({payload}) {
    try{
        const { data } = yield call(API.post, ApiRoute.firebaseAuth.checkUserExists, payload);
        //return true if exist
        yield put(checkUserExistsSuccess(data));
    } catch (error){
        yield put(checkUserExistsError(error));
        console.error(error);
    }
}

function* loginWithEmailAndPasswordSaga({payload}) {
    const { Email, Password } = payload;
    try{
        const { data }  = yield call(API.post, ApiRoute.firebaseAuth.loginWithEmailAndPassword, payload);
        const user = data?.user;
        const token = data?.tokens;
        const uid = user?.uid;
        const refreshToken = token?.refresh.token;
        const accessToken = token?.access.token;
        const accessTokenExpiry = token?.access.expires;
        const isSystemGA = ga.includes(uid);
        // setUser({ type: "DISPATCH_USER", payload: user });

        setHeaders({
          [Headers.Authorization]: `Bearer ${accessToken}`,
        });

        if(isSystemGA){
          removeFromLocalStorage("LOGIN_EXPIRATION");
          addToLocalStorage("REFRESH_TOKEN", refreshToken);
          addToLocalStorage("ACCESS_TOKEN", accessToken);
          addToLocalStorage("ACCESS_TOKEN_EXPIRY", accessTokenExpiry);
          addToLocalStorage("IS_GA", true);
          yield put(setIsGASuccess(true));
          signInWithEmailAndPassword(auth, Email, Password);
          yield put(loginWithEmailAndPasswordGASuccess(data));
          
        }else{

          //old auth
          
          const snapshot = yield get(child(dbRef, `users/${uid}`));
          // Check if data already exists in the database
        
          if (snapshot.exists()) {
            const value = snapshot.val();
            const isGa = value.isGa;

            if(isGa){
                addToLocalStorage("IS_GA", true);
            } else {
                addToLocalStorage("IS_GA", false);
            }
            addToLocalStorage("REFRESH_TOKEN", refreshToken);
            addToLocalStorage("ACCESS_TOKEN", accessToken);
            addToLocalStorage("ACCESS_TOKEN_EXPIRY", accessTokenExpiry);
            removeFromLocalStorage("LOGIN_EXPIRATION");
            yield put(setIsGASuccess(false));
            signInWithEmailAndPassword(auth, Email, Password)
            yield put(loginWithEmailAndPasswordTenantSuccess(data));
          } else {
            //doesn't exist go to verification page
            signInWithEmailAndPassword(auth, Email, Password)
            yield put(loginWithEmailAndPasswordVerifySuccess(data));
          }
        }
        
    } catch (error){
        yield put(loginWithEmailAndPasswordError(error));
        console.error(error);
    }
}

function* refreshUserAuthSaga({payload}) {
    try{
        const { data } = yield call(API.post, ApiRoute.firebaseAuth.refreshUserAuth, payload);
        yield put(refreshUserAuthSuccess(data));
    } catch (error){
        yield put(refreshUserAuthError(error));
        console.error(error);
    }
}

export function* firebaseAuthSagaWatcher(){
    yield takeLatest(createFirebaseUser.type, createFirebaseUserSaga);
    yield takeLatest(deleteFirebaseUser.type, deleteFirebaseUserSaga);
    yield takeLatest(updateFirebaseUser.type, updateFirebaseUserSaga);
    yield takeLatest(checkUserExists.type, checkUserExistsSaga);
    yield takeLatest(loginWithEmailAndPassword.type, loginWithEmailAndPasswordSaga);
    yield takeLatest(refreshUserAuth.type, refreshUserAuthSaga);
}