const component = {
  MuiAppBar: {
    styleOverrides: {
      root: ({ theme }) => ({
        position: "fixed",
        height: theme.sizing.headerHeight,
        background: theme.palette.primary.white,
        boxShadow: "unset",
        borderBottom: `1px solid ${theme.palette.border.main}`,
        display: "flex",
        justifyContent: "center",
        color: theme.palette.text.main,
      }),
    },
  },
};

export default component;
