import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import C_Loading from "../../components/C_Loading";
import './UsersAndPermissions.scss'
import { db } from "../../firebaseInit";
import moment from "moment";
import { AiFillRightCircle, AiOutlineBulb, AiOutlineClose, AiOutlineCopy, AiOutlineMore } from "react-icons/ai";
import C_PopoutNoti from "../../components/C_PopoutNoti";
import { Modal } from "bootstrap";
import C_Search from '../../components/C_Search';
import { remove, ref } from "firebase/database";

import C_DialogButton from "../../components/C_DialogButton";

//slices
import { 
    deleteCompanyAgent, 
    retrieveCompanyAgent,
    selectCheckAgentExistLoading,
    selectCompanyAgent,
    selectCompanyAgentCheck,
    sendAgentInvitation, 
    updateCompanyAgentMemberRole
} from "../../stores/slices/companyAgentSlices";
import { selectCompanyContext, selectCurrUserContext, selectSetCompanyDataAndUsersLoading, setCompanyDataAndUsers } from "../../stores/slices/companySlices";
import { checkUserExists, selectCreateFirebaseUserLoading, selectFirebaseUserExist } from "../../stores/slices/firebaseAuthSlices";
import { retrieveCurrUsage } from "../../stores/slices/usageSlices";
import { selectCompanyID } from "../../stores/slices/companyIDSlices";
import { selectAvailableTenant } from "../../stores/slices/tenantSlices";
import { selectCurrSa, selectCurrTenant } from "../../stores/slices/persistRolesSlices";
import { selectCurrRoles, selectRoles } from "../../stores/slices/roleSlices";
import { selectUser } from "../../stores/slices/userAuthSlices";

function Users(props) {

    const UserNameRef = useRef();
    const resendModalRef = useRef();
    const cancelModalRef = useRef();
    const removeModalRef = useRef();

    const dispatch = useDispatch();
    const user = selectUser();
    const { currUsers, currUserSA, currUserInvitation } = selectCurrUserContext();
    const { companyList } = selectCompanyContext();
    const companyAgent = selectCompanyAgent();

    const companyID = selectCompanyID();
    const tenant = selectCurrTenant();
    const currSa = selectCurrSa();
    const currRoles = selectCurrRoles();
    const roles = selectRoles();
    const availableTenant = selectAvailableTenant();
    const companyAgentCheck = selectCompanyAgentCheck();
    const firebaseUserExist = selectFirebaseUserExist();
    const firebaseUserExistLoading = selectCreateFirebaseUserLoading();
    const companyAgentCheckLoading = selectCheckAgentExistLoading();
    const companyDataAndUsersLoading = selectSetCompanyDataAndUsersLoading();
    const [isLoading, setIsLoading] = useState(false);
    const [notifyMessage, setNotifyMessage] = useState("");

    const [filterOptions, setFilterOptions] = useState("All");
    const [searchText, setSearchText] = useState("");
    const [companyRolesList, setCompanyRolesList] = useState([]);
    const [expandUser, setExpandUser] = useState("");
    const [targetAction, setTargetAction] = useState(null);

    const [currUserList, setCurrUserList] = useState([]);
    const [isRemoveLoading, setIsRemoveLoading] = useState(false);
    const [isResend, setIsResend] = useState(false);

    useEffect(()=>{
        dispatch(setCompanyDataAndUsers({
            TenantID : tenant, 
            isSA: currSa, 
            UserID : user?.uid,
            RoleID : roles,
            CompanyID : companyID
        }));
    },[companyAgent])

    useEffect(()=>{
        if(firebaseUserExist && isResend){
            const mailID = targetAction?.mailID;
            const TenantID = tenant;
            const CompanyID = companyID; //companyID that users invited to
            const UserID = user.uid; 
            const RoleID = targetAction?.roleID;
            const displayName = user.displayName;
            const email = targetAction?.email;
            const hostEmail = user.email;
            const tenantName = availableTenant?.find(item => item?.TenantID === tenant)?.CompanyName ?? "Tenant";
            const companyName = companyList?.find(item => item?.CompanyID === companyID)?.CompanyName ?? "Company"; //companyName from tenant table
            const isExist = firebaseUserExist !== "ERROR" && firebaseUserExist; //existing member UserID or false

            setTimeout(()=>{
                dispatch(sendAgentInvitation({mailID,TenantID,CompanyID,UserID,RoleID,displayName,email,hostEmail,tenantName,companyName,isExist}));
                setTargetAction(null);
                setExpandUser("");
                notify("resend")    
                setIsResend(false);
            }, 2000)
        }
       
    }, [firebaseUserExist])

    function refreshUsage(){
       dispatch(retrieveCurrUsage({TenantID:tenant,CompanyID:companyID}));
    };
    useEffect(()=>{
        if(companyAgentCheckLoading){
            if (companyAgentCheckLoading !== true) {
                setTimeout(() => {
                    notify("remove");
                    setIsRemoveLoading(false);
                    setTargetAction(null);
                    refreshUsage();
                    setExpandUser("");
                }, 2000);
       
            } else {
                setTimeout(() => {
                    notify("remove");
                    setIsRemoveLoading(false);
                    setTargetAction(null);
                    refreshUsage();
                    setExpandUser("");
                }, 2000);
            }
        }
    }, [companyAgentCheckLoading])

    useEffect(() => {
        if(Array.isArray(currUserInvitation) && Array.isArray(currUserSA)){
            const newUsersSet = [...currUserInvitation, ...currUserSA];
            setCurrUserList(newUsersSet);
        }
    },[currUserInvitation, currUserSA, companyAgent]);

    useEffect(() => {
        if(tenant && companyID){
            dispatch(retrieveCompanyAgent({TenantID:tenant,CompanyID:companyID}));
        };
    },[tenant, companyID]);

    useEffect(() => {
        // setSelectedRole(null);
        if(Array.isArray(roles) && roles && roles.length > 0){
          setCompanyRolesList(roles);
        }
    },[roles]);

    const showResendModalFunc = (item) => {
        const modalEle = resendModalRef.current;
        const bsModal = new Modal(modalEle, {
          backdrop: "static",
          keyboard: false,
        });
        setTargetAction(item);
        bsModal.show();
    };

    const hideResendModalFunc = () => {
        const modalEle = resendModalRef.current;
        const bsModal = Modal.getInstance(modalEle);
        bsModal.hide();
        setTargetAction(null);
    };

    const showCancelModalFunc = (item) => {
        const modalEle = cancelModalRef.current;
        const bsModal = new Modal(modalEle, {
          backdrop: "static",
          keyboard: false,
        });
        setTargetAction(item);
        bsModal.show();
    };

    const hideCancelModalFunc = () => {
        const modalEle = cancelModalRef.current;
        const bsModal = Modal.getInstance(modalEle);
        bsModal.hide();
        setTargetAction(null);
    };

    const showRemoveModalFunc = (item) => {
        const modalEle = removeModalRef.current;
        const bsModal = new Modal(modalEle, {
          backdrop: "static",
          keyboard: false,
        });
        setTargetAction(item);
        bsModal.show();
    };

    const hideRemoveModalFunc = () => {
        const modalEle = removeModalRef.current;
        const bsModal = Modal.getInstance(modalEle);
        bsModal.hide();
        setTargetAction(null);
    };

    function notify(type){
        switch(type){
            case "edit":
                setNotifyMessage("UPDATED COMPANY")
                break;
            case "delete":
                setNotifyMessage("DELETED COMPANY")
                break;
            case "copied":
                setNotifyMessage("COPIED TEXT")
                break;
            case "resend":
                setNotifyMessage("INVITATION SENT")
                break;
            case "cancel":
                setNotifyMessage("INVITATION CANCELED")
                break;
            case "remove":
                setNotifyMessage("USER REMOVED")
                break;
            case "copy-uid":
                setNotifyMessage("UID copied to clipboard.")
                break;
          default:
            break;
        }
    
        setTimeout(() => {
          setNotifyMessage("");
        }, 1500)
    };

    async function copyToClipboard(text){
        switch(text){
            case "CompanyID":
                try {
                    await navigator.clipboard.writeText(UserNameRef.current.innerText);
                    notify("copied")
                  } catch (err) {
                    console.error('Failed to copy text: ', err);
                  }
                break;
            default:
                break;
        }
    }

    async function handleUpdateMember({RoleID, NewRoleID, UserID}){

        dispatch(updateCompanyAgentMemberRole({
            TenantID:tenant,
            RoleID: RoleID, 
            NewRoleID: NewRoleID,
            UserID: UserID,
            companyData : {
                TenantID : tenant, 
                isSA: true, 
                UserID : user?.uid,
                RoleID : "SA",
                CompanyID : companyID
            }
        }));
    
    };

    async function handleResendInvitation(){

        setIsResend(true);
        dispatch(checkUserExists({email: targetAction?.email}));
        setTimeout(()=>{
            if(!firebaseUserExistLoading){
                hideResendModalFunc();
            }
        }, 2000)
       
    };

    async function handleCancelInvitation(){
        const mailID = targetAction?.mailID;
        const tenantID = targetAction?.tenantID;
        const companyID = targetAction?.companyID;
        await Promise.resolve(remove(ref(db, 'invitationMail/' + `${tenantID}/` + `${companyID}/` + mailID ))).then(() => {
            setTargetAction(null);
            setExpandUser("");
            hideCancelModalFunc();
            refreshUsage();
            notify("cancel")
        })
        dispatch(setCompanyDataAndUsers({
            TenantID : tenant, 
            isSA: true, 
            UserID : user?.uid,
            RoleID : "SA",
            CompanyID : companyID
        }));
    };

    async function handleRemoveAccount(){ //consideration of deleting other data
        hideRemoveModalFunc();
        setIsRemoveLoading(true);
        dispatch(deleteCompanyAgent({
            TenantID: tenant, 
            CompanyAgentID: targetAction?.CompanyAgentID, 
            UserID : targetAction?.uid, 
            CompanyID : targetAction.companyID
        }));

    };

    return (
        <>
        <C_Loading isLoading={isLoading}/>
        <C_PopoutNoti triggerSaved={notifyMessage}/>
        <div className="modal fade" tabIndex="-1" ref={resendModalRef}>
            <div className="modal-dialog modal-dialog-centered" style={{}}>
                <div className="modal-content px-3" style={{overflowY: "scroll" }}>
                    <div className="modal-header modalHeader">
                        <div className="title">
                            <AiOutlineBulb size={17}/>
                            <span className="font-s">CONFIRMATION</span>
                        </div>
                        <AiOutlineClose cursor='pointer' size={16} onClick={() => {
                            hideResendModalFunc();
                        }}/>
                    </div>
                    <div className="modal-body">
                        <div className="confirmation">
                            <span>Are you sure to resend invitation?</span>
                        </div>
                        <div style={{ display: "flex", justifyContent: "flex-end", columnGap:'20px', alignItems: "center" }} className="mt-3 modalshit">
                            <C_DialogButton 
                                color={'#ca4254'}
                                background={'white'}
                                borderColor={'#ca4254'}
                                buttonText={"DISCARD"}
                                onClick={() => { hideResendModalFunc(); }}
                            />
                            <C_DialogButton
                                color={"white"}
                                background={'#3eb986'}
                                borderColor={'#3eb986'}
                                buttonText={"SEND"}
                                onClick={() => { 
                                    handleResendInvitation();
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="modal fade" tabIndex="-1" ref={cancelModalRef}>
            <div className="modal-dialog modal-dialog-centered" style={{}}>
                <div className="modal-content px-3" style={{overflowY: "scroll" }}>
                    <div className="modal-header modalHeader">
                        <div className="title">
                            <AiOutlineBulb size={17}/>
                            <span className="font-s">CONFIRMATION</span>
                        </div>
                        <AiOutlineClose cursor='pointer' size={16} onClick={() => {
                            hideCancelModalFunc();
                        }}/>
                    </div>
                    <div className="modal-body">
                        <div className="confirmation">
                            <span>Are you sure to cancel the invitation?</span>
                        </div>
                        <div style={{ display: "flex", justifyContent: "flex-end", columnGap:'20px', alignItems: "center" }} className="mt-3 modalshit">
                            <C_DialogButton 
                                color={'#3eb986'}
                                background={'white'}
                                borderColor={'#3eb986'}
                                buttonText={"DISCARD"}
                                onClick={() => { hideCancelModalFunc() }}
                            />
                            <C_DialogButton
                                color={"white"}
                                background={'#3eb986'}
                                borderColor={'#3eb986'}
                                buttonText={"CONFIRM"}
                                onClick={() => { 
                                    handleCancelInvitation();
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="modal fade" tabIndex="-1" ref={removeModalRef}>
            <div className="modal-dialog modal-dialog-centered" style={{}}>
                <div className="modal-content px-3" style={{overflowY: "scroll" }}>
                    <div className="modal-header modalHeader">
                        <div className="title">
                            <AiOutlineBulb size={17}/>
                            <span className="font-s">CONFIRMATION</span>
                        </div>
                        <AiOutlineClose cursor='pointer' size={16} onClick={() => {
                            hideRemoveModalFunc();
                        }}/>
                    </div>
                    <div className="modal-body">
                        <div className="confirmation">
                            <span>Are you sure to remove member from this company?</span>
                        </div>
                        <div style={{ display: "flex", justifyContent: "flex-end", columnGap:'20px', alignItems: "center" }} className="mt-3 modalshit">
                            <C_DialogButton 
                                color={'#ca4254'}
                                background={'white'}
                                borderColor={'#ca4254'}
                                buttonText={"DISCARD"}
                                onClick={() => { hideRemoveModalFunc() }}
                            />
                            <C_DialogButton
                                color={"white"}
                                background={'#3eb986'}
                                borderColor={'#3eb986'}
                                buttonText={"CONFIRM"}
                                onClick={() => { 
                                    handleRemoveAccount();
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="UsersMainContainer">
            <div className="UserListHeaderContainer">
                <div className="searchUserContainer">
                    <C_Search
                        placeholder="Search user by name or email"
                        onChange={(e) => {
                            setSearchText(e.target.value);
                        }}
                        value={searchText}
                        searchText={searchText}
                        closeFunction={() => {
                            setSearchText("");
                        }}
                        containerWidth="100%"
                    />
                </div>
                <div className="filterContainer">
                    <div className={`filterOptionsContainer ${filterOptions === "All" && "selectedOptions"}`} onClick={() => { setFilterOptions("All") }}>
                        <span className="option">All Users</span>
                        <span className="calculation">{currUserSA?.length ?? 0}</span>
                    </div>
                    <div className={`filterOptionsContainer ${filterOptions === "Invitation" && "selectedOptions"}`} onClick={() => { setFilterOptions("Invitation") }}>
                        <span className="option">Invitation</span>
                        <span className="calculation">{currUserInvitation?.length ?? 0}</span>
                    </div>
                    {/* <div className={`filterOptionsContainer ${filterOptions === "Approval" && "selectedOptions"}`} onClick={() => { setFilterOptions("Approval") }}>
                        <span className="option">Approval</span>
                    </div> */}
                </div>
            </div>
            <div className="UserListContainer">
                <div className="UserListColumns">
                    <div className="ColumnContainer Account">
                        <span>Account</span>
                    </div>
                    <div className="ColumnContainer Activity">
                        <span>UID</span>
                    </div>
                    <div className="ColumnContainer Role">
                        <span>Role</span>
                    </div>
                    <div className="ColumnContainer Activity">
                        <span>Activity</span>
                    </div>
                    <div className="ColumnContainer Status">
                        <span>Status</span>
                    </div>
                </div>
                <div className="UserListRows">
                    {(currUserList && Array.isArray(currUserList) && currUserList?.length > 0) &&
                        [...currUserList]?.sort((a, b) => {
                            if (a.TenantID === tenant && b.TenantID === tenant) {
                                return new Date(b.CreatedAt) - new Date(a.CreatedAt);
                            } else if (a.TenantID === tenant) {
                                return -1;
                            } else if (b.TenantID === tenant) {
                                return 1;
                            }
                            return new Date(b.CreatedAt) - new Date(a.CreatedAt);
                        }).filter(filterItem => {
                            const findByName = filterItem?.displayName?.toString().toLocaleLowerCase().includes(searchText.toString().toLocaleLowerCase())
                            const findByEmail = filterItem?.email?.toString().toLocaleLowerCase().includes(searchText.toString().toLocaleLowerCase())
                            if(filterOptions === "Invitation"){
                                if("mailID" in filterItem){
                                    return filterItem && (findByName || findByEmail);
                                }
                            }else{
                                return filterItem && (findByName || findByEmail);
                            }
                        }).map((item, index) => {
                            const isCurrentSA = item.isTenant && item.TenantID === tenant
                            const pendingUser = "mailID" in item;
                            const agentObj = companyAgent.find(agent => agent.UserID === item.UserID);
                            return(
                                <div key={index} className="UserList">
                                    <div className="Account">
                                        <div className="profilePic">
                                            <img src={item?.photoURL ?? "https://firebasestorage.googleapis.com/v0/b/rexsoft-crm.appspot.com/o/default-profile.webp?alt=media&token=65d9adcd-657c-4566-8058-f45f74b13d4a"} alt=""/>
                                        </div>
                                        <div className="profileDetails">
                                            <span className="name" title={item?.displayName ?? "-"}>{item?.displayName ?? "-"}</span>
                                            <span className="email" title={item?.email}>{item?.email}</span>
                                        </div>
                                    </div>
                                    <div className="UID">
                                        <div className="Owner"
                                             onClick={() => {
                                                navigator.clipboard.writeText(item?.uid);
                                                notify("copy-uid")
                                            }}
                                        >
                                            <span className="uid" title={item?.uid ?? "-"}>{item?.uid ?? "-"}</span>
                                            {(item?.uid !== "" && item?.uid !== undefined) &&
                                                <div>
                                                    <AiOutlineCopy color="#5424D3" size={15}/>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="Role">
                                        {isCurrentSA ?
                                            <div className="Owner">
                                                <span>Owner</span>
                                            </div>
                                            :
                                            <div className="RoleSelection">
                                                <select value={pendingUser ? item?.roleID : agentObj ? agentObj.RoleID : item?.RoleID} onChange={(e) => {
                                                    handleUpdateMember({RoleID: item?.RoleID, NewRoleID: e.target.value, UserID: item?.uid});
                                                }}
                                                disabled={pendingUser}
                                                >
                                                <option selected disabled value="">Select a role</option>
                                                {companyRolesList?.map((role, index) => {
                                                    return (
                                                    <option key={index} value={role?.RoleID}>{role?.RoleName}</option>
                                                    );
                                                })}
                                                </select>
                                            </div>
                                        }
                                    </div>
                                    <div className="Activity">
                                        {isCurrentSA ?
                                            <div className="Owner">
                                                <span>-</span>
                                            </div>
                                            :
                                            <div className="createdAt">
                                                <span className="title">{pendingUser ? "Invitation Sent" : "Access Granted"}</span>
                                                <span className="value">{moment(item?.CreatedAt).format("DD-MM-YYYY HH:mm")}</span>
                                            </div>
                                        }
                                    </div>
                                    <div className="Status">
                                        <div className="CurrentStatus">
                                            {pendingUser ?
                                                <span className="pending">Pending</span>
                                                :
                                                <span className="active">Active</span>
                                            }
                                        </div>
                                        {!isCurrentSA &&
                                            <div className="actionContainer" onClick={() => { setExpandUser(pendingUser ? item?.mailID : item?.uid) }}>
                                                <AiOutlineMore size={23}/>
                                            </div>
                                        }
                                    </div>
                                    <div className={`expandOptions ${expandUser === (pendingUser ? item?.mailID : item?.uid) && "showExpand"}`}>
                                        {!isRemoveLoading && <AiFillRightCircle size={20} className="icon" onClick={() => { setExpandUser("") }}/>}
                                        {pendingUser && <span className="resend" onClick={() => { showResendModalFunc(item) }}>Resend Invitation</span>}
                                        {pendingUser && <span className="cancel" onClick={() => { showCancelModalFunc(item) }}>Cancel Invitation</span>}
                                        {!pendingUser && ( 
                                            isRemoveLoading ?
                                            <div className={`loadingContainer ${!isRemoveLoading && 'hideLoadingContainer'}`}>
                                                <div className="loading-spinner">
                                                </div>
                                            </div>
                                            :
                                            <span className="remove" onClick={() => { showRemoveModalFunc(item) }}>Remove Account</span>
                                        )}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
        </>
    );
};

export default Users;