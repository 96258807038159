const component = {
  // Change to component name e.g MuiButton, MuiFilledInput. Please read MUI documentation
  MuiAccordionDetails: {
    styleOverrides: {
      root: () => ({
        padding: 0,
      }),
    },
  },
};

export default component;
