import React, { Fragment } from "react";
import { Box, Button, CircularProgress, Stack } from "@mui/material";
import { ArrowDropDownRounded } from "@mui/icons-material";

import { FormInputText } from "../FormInput/FormInputText";
import { FormInputDropdownSearch } from "../FormInput/FormInputDropdownSearch";
import { validatorRequired } from "../../util/validators/validatorRequired";
import { PopoverButton } from "../Utils/PopoverButton";
import { PopoverChildrenButton } from "../Utils/PopoverChildrenButton";
import forms from "../../styles/theme/forms";
import { transactionTypeNameEnum } from "../../enums/Transaction";

export const GeneralForm = ({
  control,
  handleClose,
  handleSubmit,
  setValue,
  watch,
  isLoading,
  transaction,
  company,
  isDirty = false,
  user,
  options = null,
}) => {
  const formInputWidth = { ...forms.oneCol };
  const subjectPlaceholders = [
    "Transaction Title",
    "Transaction Type",
    "Total Amount",
    "Balance",

    "Next Due Date",
    "Next Due Amount",

    "Transaction No",
    "Transaction Date",
    "Company Friendly Name",
  ];

  const subjectPlaceholdersEnum = {
    "Transaction Title": transaction?.title || "",
    "Transaction Type": transactionTypeNameEnum?.[transaction?.type] || "",
    "Total Amount": transaction?.amount || "",
    Balance: transaction?.balance || "",
    "Next Due Date": "",
    "Next Due Amount": "",
    "Transaction No": transaction?.transaction_no || "",
    "Transaction Date": transaction?.date || "",
    "Company Friendly Name": company?.legal_name || "",
  };

  const handleSelectPlaceholder = (placeholder, isSubject = false) => {
    const placeholderValue = subjectPlaceholdersEnum[placeholder];
    const formName = isSubject ? "subject" : "personalMessage";
    const watchSubjectValue = watch(formName);
    const subjectValue = watchSubjectValue ? watchSubjectValue : "";
    const updatedSubject = `${subjectValue}${placeholderValue}`;
    setValue(formName, updatedSubject, { shouldDirty: true });
  };

  const placeHolderButton = (isSubject = false) => {
    return (
      <Box width={1} sx={{ justifyContent: "flex-end", display: "flex" }}>
        <PopoverButton
          variant="contained"
          buttonChild={"Placeholders"}
          sxButton={{
            padding: "2px 0px 2px 5px",
            height: "auto",

            fontSize: "12px",
          }}
          sxPopover={{
            width: "16%",
          }}
          anchor={{
            vertical: "bottom",
            horizontal: "left",
          }}
          // hoverMode={true}
          endIcon={
            <ArrowDropDownRounded
              sx={({ icons }) => ({
                ...icons.standard,
              })}
            />
          }
          popoverClassName={"manage_transfer_item"}
        >
          {subjectPlaceholders.map((type) => {
            return (
              <PopoverChildrenButton
                key={`child_${type}`}
                fullWidth={true}
                label={type}
                // endAdornment={shouldRenderEndAdornment(item.company_id)}
                //   sx={{ paddingInline: "20px" }}
                onClick={() => {
                  handleSelectPlaceholder(type, isSubject);
                }}
              />
            );
          })}
        </PopoverButton>
      </Box>
    );
  };

  const renderActionProps = (
    <Fragment>
      <Button
        variant="contained"
        className="MuiButton-cancel"
        onClick={handleClose}
      >
        {"Cancel"}
      </Button>
      <Button
        variant="contained"
        onClick={handleSubmit}
        disabled={isLoading || !isDirty}
      >
        {isLoading ? (
          <CircularProgress
            size="20px"
            sx={({ palette }) => ({ color: palette.primary.white })}
            data-testid="invite-user-progressbar"
          />
        ) : (
          "Save"
        )}
      </Button>
    </Fragment>
  );
  const { form_designs = [] } = options || {};
  const formDesignsOptions = form_designs.map((form) => {
    return {
      label: form.name,
      value: form.form_design_id,
    };
  });
  return (
    <Fragment>
      <Box component="div" padding={2.5} flexGrow={1}>
        <Stack
          component="div"
          rowGap={2}
          width={1}
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <FormInputText
            control={control}
            name="emailTo"
            label="Email To"
            minWidth={formInputWidth}
            maxWidth={formInputWidth}
            required
            rules={{
              required: validatorRequired(),
            }}
            isDisabled={isLoading}
          />

          <FormInputText
            control={control}
            name="cc"
            label="Cc To"
            minWidth={formInputWidth}
            maxWidth={formInputWidth}
            isDisabled={isLoading}
          />

          <FormInputText
            control={control}
            name="replyTo"
            label="Reply To"
            minWidth={formInputWidth}
            maxWidth={formInputWidth}
            isDisabled={isLoading}
          />

          {/* <FormInputDropdownSearch
            control={control}
            name="cc"
            label="CC"
            minWidth={formInputWidth}
            maxWidth={formInputWidth}
            options={[
              {
                label: user.email,
                value: user.email,
              },
            ]}
            isDisabled={isLoading}
          />
          <FormInputDropdownSearch
            control={control}
            name="replyTo"
            label="Reply To"
            minWidth={formInputWidth}
            maxWidth={formInputWidth}
            options={[
              {
                label: user.email,
                value: user.email,
              },
            ]}
            isDisabled={isLoading}
          /> */}
          {placeHolderButton(true)}
          <FormInputText
            control={control}
            name="subject"
            label="Subject"
            minWidth={formInputWidth}
            maxWidth={formInputWidth}
            required
            rules={{
              required: validatorRequired(),
            }}
            isDisabled={isLoading}
          />
          {placeHolderButton()}

          <FormInputText
            control={control}
            name="personalMessage"
            label="Personal Message"
            minWidth={formInputWidth}
            maxWidth={formInputWidth}
            multiline={true}
            isDisabled={isLoading}
          />

          <FormInputDropdownSearch
            control={control}
            name="formDesign"
            label="Form Design"
            minWidth={formInputWidth}
            maxWidth={formInputWidth}
            options={formDesignsOptions}
            isDisabled={isLoading}
          />
        </Stack>

        {/* {renderNumberFormatContent} */}
      </Box>
      <Stack
        direction="row"
        width={1}
        spacing={1}
        sx={({ palette, sizing }) => ({
          paddingInline: 2.5,
          paddingBlock: 2,
          minHeight: sizing.headerHeight,
          justifyContent: "flex-end",
          alignItems: "center",
          borderTop: `1px solid ${palette.border.main}`,
        })}
      >
        {renderActionProps}
      </Stack>
    </Fragment>
  );
};
