import { easeQuadInOut } from 'd3-ease';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AnimatedProgressProvider from '../../components/AnimatedProgressProvider';
import { selectKpi, selectKpiContext } from '../../stores/slices/kpiSlices';
import { selectFormSubmission } from '../../stores/slices/formSubmissionSlices';
import { selectCurrUserContext } from '../../stores/slices/companySlices';
import { selectUser } from '../../stores/slices/userAuthSlices';

function KpiSync() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = selectUser();
    const [searchText, setSearchText] = useState("");

    // const {
    //     kpiData,
    //     submission,
    //     allUser
    // } = selectKpiContext();

    const { currUserSA } = selectCurrUserContext();

    const allUser = currUserSA;
    const kpiData = selectKpi();
    const submission = selectFormSubmission();

    const [currKpiData, setCurrKpiData] = useState([]);

    useEffect(() => {

        const filteredKPIs = kpiData.filter(kpi => {
            return kpi.targetMembers.some(member => member?.uid === user?.uid);
        });

        const searchedData = filteredKPIs.filter(item => {
            if(item.title.toString().toLocaleLowerCase().includes(searchText.toString().toLocaleLowerCase())){
                return item
            }else if(item.startDate.toString().toLocaleLowerCase().includes(searchText.toString().toLocaleLowerCase())){
                return item
            }else if(item.dueDate.toString().toLocaleLowerCase().includes(searchText.toString().toLocaleLowerCase())){
                return item
            }else if(item.target.toString().toLocaleLowerCase().includes(searchText.toString().toLocaleLowerCase())){
                return item
            }else if(item.createdAt.toString().toLocaleLowerCase().includes(searchText.toString().toLocaleLowerCase())){
                return item
            }
        });
        const sortedData = searchedData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setCurrKpiData(sortedData);

    },[kpiData]);

    function findMetricValue(item, allSubmission) {
        let targetMembers = item.targetMembers;
        let metricValue = item.metricValue;
        let results = [];
        for (let member of targetMembers) {
          let sum = 0;
          for (let submission of allSubmission) {
            if (member?.uid === submission.UserID) {
              for (let data of submission.SubmissionData) {
                if (data.questionID === metricValue) {
                  sum += parseFloat(data.answer);
                  break;
                }
              }
            }
          }
          results.push({
            displayName: member?.displayName,
            uid: member?.uid,
            teamContribution: item?.teamContribution,
            target: item?.target,
            unit: item?.unitOfMeasurement,
            total: sum
          });
        }

        const achiever = results.filter(data => data.total >= parseInt(item.target));
        const totalValue = achiever.length;
        const percentage = Math.round((achiever.length / item?.targetMembers?.length) * 100);

        return { totalValue, percentage, results };
    };

    function findSubmissionValue(item, allSubmission) {
        let targetMembers = item.targetMembers;
        let results = [];
        
        for (let member of targetMembers) {
          let count = 0;
          for (let submission of allSubmission) {
            if (member?.uid === submission.UserID) {
              count++;
            }
          }
          results.push({
            displayName: member?.displayName,
            uid: member?.uid,
            teamContribution: item?.teamContribution,
            target: item?.target,
            unit: item?.unitOfMeasurement,
            total: count
          });
        };

        const achiever = results.filter(data => data.total >= parseInt(item.target));
        const totalValue = achiever.length;
        const percentage = Math.round((achiever.length / item?.targetMembers?.length) * 100);

        return { totalValue, percentage, results };
    };

  return (
    <div className='myKpiSummaryContainer'>
        <div className='titleContainer'>
            <span>My KPIs</span>
            <div className='actionContainer' onClick={() => {
                navigate('/Kpi');
            }}>
                <span>View all</span>
                <AiOutlineArrowRight size={10} color="#bcbcbc"/>
            </div>
        </div>
        {currKpiData && currKpiData.length > 0 ?
            <div className="myKpiMainContainer">
                {currKpiData && currKpiData.map((item, index) => {
                    //KPI Calculation Formula//
                    //Filter to get all approved submission belongs to each KPI's
                    const betweenDatesSubmission = submission.filter(submission => {
                        const createdAt = new Date(submission.CreatedAt);
                        const startDate = new Date(item.startDate);
                        startDate.setHours(0, 0, 0, 0);
                        const dueDate = new Date(item.dueDate);
                        dueDate.setHours(23, 59, 59, 999);

                        return createdAt >= startDate && createdAt <= dueDate;
                    });
                    const allSubmission = betweenDatesSubmission.filter(sub => sub.FormID === item.sourceOfData && sub.SubmissionStatus === "1");
                    //If it is team contribution, sum up total value
                    //Else check total completion for this KPI
                    
                    var totalValue = 0;
                    var percentage = 0;
                    var memberContribution = [];

                    /////////Analytics///////

                    //Table
                    //by Data looped out

                    ////////////////////////
                    
                    if(item?.teamContribution){
                        if(item.measuredBy !== "Submission"){
                            memberContribution = findMetricValue(item, allSubmission).results;
                            var total = memberContribution.reduce((sum, item) => sum + parseInt(item.total), 0);
                            totalValue = total;
                            percentage = Math.round((total / item.target) * 100)
                        }else{
                            memberContribution = findSubmissionValue(item, allSubmission).results;
                            var total = memberContribution.reduce((sum, item) => sum + parseInt(item.total), 0);
                            totalValue = total;
                            percentage = Math.round(( total / item.target) * 100)
                        }
                    }else{
                        if(item.measuredBy !== "Submission"){
                            memberContribution = findMetricValue(item, allSubmission).results;
                            var total = memberContribution?.find(users => users?.uid === user?.uid).total;
                            totalValue = total;
                            percentage = total === 0 ? total : Math.round(( total / item.target) * 100)
                        }else{
                            memberContribution = findSubmissionValue(item, allSubmission).results
                            var total = memberContribution?.find(users => users?.uid === user?.uid).total;
                            totalValue = total;
                            percentage = total === 0 ? total : Math.round(( total / item.target) * 100)
                        }
                    }
                    
                    const member = memberContribution.find(x => x?.uid === user?.uid)
                    const myContribution = member ? member.total : 0;

                    const myPercentage = Math.round(( myContribution / totalValue) * 100);
                    
                    const targetMemberDetails = item?.targetMembers.map((member) => {
                        const user = allUser.find((user) => user?.uid === member?.uid)
                        return user ? 
                            {   
                                UserID: user?.uid,
                                userName: user.displayName,
                                userProfile: user.photoURL
                            } 
                            : 
                            {   
                                UserID: "User Not Existed",
                                userName: "User Not Existed",
                                userProfile: "https://firebasestorage.googleapis.com/v0/b/rexsoft-crm.appspot.com/o/memberProfile%2FdeletedUser.jpeg?alt=media&token=bf97ce76-b439-4c51-aae0-9db0cc0d5be9"
                            };
                    })

                    const userNames = targetMemberDetails.map(name => name.displayName);
                    const userNamesString = userNames.join(", ");
                    ///////////////////////////
                    
                    return(
                        <div key={item.KpiID} className="myKpiContainer">
                            <div className='titleContainer'>
                                <span>{item.title}</span>
                            </div>
                            <div className='detailsContainer'>
                                <div className='subDetailsContainer'>
                                    <div className='target'>
                                        <span className='title'>Target</span>
                                        <span>{item.target}</span>
                                    </div>
                                    <div className='endDate'>
                                        <span className='title'>End Date</span>
                                        <span>{moment(item?.dueDate).format("DD-MM-YYYY")}</span>
                                    </div>
                                </div>
                                <div className="progressMainContainer">
                                    <AnimatedProgressProvider
                                        valueStart={0}
                                        valueEnd={percentage}
                                        duration={1.5}
                                        easingFunction={easeQuadInOut}
                                    >
                                        {(value) => {
                                            const roundedValue = Math.round(value);
                                            return (
                                                <CircularProgressbar
                                                    value={value}
                                                    strokeWidth={10}
                                                    text={`${roundedValue}%`}
                                                    styles={buildStyles({ 
                                                        rotation: 0,
                                                        strokeLinecap: 'butt',
                                                        textSize: '16px',
                                                        pathColor: percentage > 80 ? "green" : 
                                                            percentage > 60 ? "blue" : 
                                                            percentage > 40 ? "brown" : 
                                                            percentage > 20 ? "red" : "purple",
                                                        textColor: '#f88',
                                                        trailColor: '5px solid #ccc',
                                                        backgroundColor: 'transparent',
                                                        pathTransition: 'none'
                                                    })}
                                                />
                                            );
                                        }}
                                    </AnimatedProgressProvider>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
            :
            <div className="emptyKpi">
                <span>NO KPI AVAILABLE</span>
            </div>
        }
    </div>
  )
}

export default KpiSync;