// export const apiUrl = 'http://localhost:8080/api';
export const apiUrl = 'https://cqyambqcrdzvmje8gm.zumaxdigital.com/api';
// export const accUrl = 'http://localhost:8008/v1';
export const accUrl = 'https://a54e6461ed357c.myacc.cc/v1';


// https://zumax-crm-server.herokuapp.com/api
// http://localhost:8080/api
// http://178.128.21.222/api

// https://zumax-crm-server-9nf8w3wm.com/api
// export const apiUrl = 'https://cqyambqcrdzvmje8gm.zumaxdigital.com/api';
