import { createSlice, current } from "@reduxjs/toolkit"

import { useAppSelector } from "../../hooks/storeHooks";
import moment from "moment";

const initialState = {
    //loading
    setUserLoading : false,
    setCurrUserLoading : false,

    //error
    setUserError : undefined,
    setCurrUserError : undefined,

    user: null,
    currUser: null,

}

const userAuthSlice = createSlice({
    name:"user",
    initialState,
    reducers: {
        setUser(state){
            state.setUserLoading = false;
        },
        setUserSuccess(state,{payload}){
            state.user = payload;
            state.setUserLoading = false;
        },

        setUserError(state,{payload}){
            const errorState = payload
            state.setUserError = errorState
            state.setUserLoading = false
        },
        setCurrUser(state){
            state.setCurrUserLoading = false;
        },
        setCurrUserSuccess(state,{payload}){
           
            state.setCurrUserLoading = false;
        },

        setCurrUserError(state,{payload}){
            const errorState = payload
            state.setCurrUserError = errorState
            state.setCurrUserLoading = false
        },
    }
})

export const {
    setUser,
    setUserError,
    setUserSuccess,
    setCurrUser,
    setCurrUserError,
    setCurrUserSuccess
} = userAuthSlice.actions

// data
export const selectUser = () => 
  useAppSelector((state) => state.user.user);

export const selectCurrUser = () => 
  useAppSelector((state) => state.user.currUser);

// loading
export const selectUserLoading = () =>
  useAppSelector((state) => state.user.setUserLoading);

export const selectCurrUserLoading = () =>
  useAppSelector((state) => state.user.setCurrUserLoading);

// error
export const selectUserError = () =>
  useAppSelector((state) => state.user.setUserError);

export const selectCurrUserError = () =>
  useAppSelector((state) => state.user.setCurrUserError);

//export reducer
const userReducer = userAuthSlice.reducer

export default userReducer