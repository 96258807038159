import { call, delay, put, takeLatest } from "redux-saga/effects";
import {
    setFormTemplateID,
    setFormTemplateIDSuccess,
    setFormTemplateIDError,
} from "../stores/slices/formTemplateIDSlices"

function* setFormTemplateIDSaga({payload}){
    try{
        yield put(setFormTemplateIDSuccess(payload))
    }catch (error){
        yield put(setFormTemplateIDError(error));
        console.error(error);
    }
}

export function* formTemplateIDSagaWatcher(){
    yield takeLatest(setFormTemplateID.type, setFormTemplateIDSaga);
}
