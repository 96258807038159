import "./ClientImportErrorTable.scss";
import { AiOutlineClose, AiOutlineCopy } from "react-icons/ai";
import { Column, Table } from "react-virtualized";
import { useRef, useEffect, useState} from "react";
import importErrorMessage from "../../constants/importErrorMessage";
import C_DialogButton from "../../components/C_DialogButton";
import { RiArrowDownSFill, RiDatabase2Line, RiTable2 } from "react-icons/ri";
import CommonFunc from "../../util/CommonFunc";
import C_PopoutNoti from "../../components/C_PopoutNoti";
import { processImportData, selectClientContext } from "../../stores/slices/clientSlices";
import { useDispatch } from "react-redux";

function ClientImportErrorTable(props) {

    //props from Client.js
    const isSa = props.isSa;
    const isGroupLeader = props.isGroupLeader;
    const importErrorRef = props.importErrorRef;
    const hideImportErrorModal = props.hideImportErrorModal
    const errorImportArr  = props.errorImportArr;
    const processedImportedRow = props.processedImportedRow;
    const errorIndexArr = props.errorIndexArr;
    const errorCSVDuplicatedArr = props.errorCSVDuplicatedArr;
    const errorDBDuplicatedArr = props.errorDBDuplicatedArr;
    const resetCSVFileInput = props.resetCSVFileInput;
    const setErrorImportArr = props.setErrorImportArr;
    const setProcessedImportedRow = props.setProcessedImportedRow;
    const setErrorIndexArr = props.setErrorIndexArr;
    const setErrorCSVDuplicatedArr = props.setErrorCSVDuplicatedArr;
    const setErrorDBDuplicatedArr = props.setErrorDBDuplicatedArr;

    const {
       clientColArray, clientColData
    } = selectClientContext();

    const dispatch = useDispatch();

    //hooks
    const errorImportTableRef = useRef();
    const [mergedImportedData, setMergedImportedData] = useState([]);
    const [errorImportMessages, setErrorImportMessages] = useState([]);
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [tableState, setTableState] = useState();
    const [toggleDuplicatedModal, setToggleDuplicatedModal] = useState(false);
    const [duplicatedModalIndex, setDuplicatedModalIndex] = useState(null);
    const [notifyMessage, setNotifyMessage] = useState("");

    useEffect(()=>{
        if(notifyMessage){
          setTimeout(() => {
            setNotifyMessage("");
          },2500)
        }
    }, [notifyMessage])

    useEffect(() => {
        let widths = {}
        for (var x = 0; x < clientColArray.length; x++) {
            widths = {
                ...widths,
                error: 0.4,
                index: 0.05,
                handler: 0.13,
                [clientColArray[x].columnName]: 0.13
            }
        }
        setTableState({ width: widths })
    }, [clientColArray]);

    //get width and height from container
    useEffect(()=>{
        if(errorImportTableRef?.current?.clientWidth !== 0){
            setTimeout(()=>{
                setWidth(errorImportTableRef?.current?.clientWidth);
                setHeight(errorImportTableRef?.current?.clientHeight);
            },2000)
        }
        
    },[errorImportTableRef,errorIndexArr])

    //processed row and column array and merge into one
    useEffect(()=>{
        var mergeData = processedImportedRow?.filter((data,index)=>{
            return errorIndexArr.includes(index);
        }).map((item) => {
            const results = item.reduce((acc, value) => {
           
                const objKey = clientColData.length > 0 && clientColData[0]?.ColumnData.filter(key => key?.columnID === value?.columnID ? key.columnName : null );
                const columnName = objKey[0]?.columnID === value?.columnID ? objKey[0].columnName : "Handler";
                return {...acc, [columnName]: value.rowValue};

            }, {})
            return results;
        });

        setMergedImportedData(mergeData);
    },[clientColArray,processedImportedRow])

    //groupErrorMessage with the same rowIndex
    useEffect(()=>{
       const errorObj = errorImportArr.reduce((acc, value)=>{
            const index = value.rowIndex;

            if(!acc[index]){
                acc[index] = [];
            }

            acc[index].push(value);

            return acc
        }, {})
        const errorArr = Object.values(errorObj);
        setErrorImportMessages(errorArr)

    }, [errorImportArr, errorIndexArr])

    function _rowRenderer({
        style,
        key, //Unique key within array of rows
        index //Index of row within collection
    }) {
        const errorIndex = errorIndexArr[index] + 2;
        const filteredErrors = errorImportMessages[index]; //kena error
        const isErrorHandler = errorImportArr.some(data => data.columnName === "Handler");
        return (
            <div
                key={key}
                className={`ReactVirtualized__Table__row ${index % 2 && 'import-table-row-divider'} `}
                role="row"
                style={{
                    ...style,
                }}
            >
                <div
                    className={`ReactVirtualized__Table__rowColumn import-table-cell import-index-cell`}
                    role="gridcell"
                    style={{ width:tableState && tableState.width["index"] * width }}
                    >
                    <span>{errorIndex}</span>
                    </div>
                <div
                    className={`ReactVirtualized__Table__rowColumn import-table-cell error-import-message-cell`}
                    role="gridcell"
                    style={{ width:tableState && tableState.width["error"] * width }}
                    >
                    {errorImportMessages.length > 0 && filteredErrors.map((err,errIndex) => {
                        return( 
                            <span title={importErrorMessage(err.columnName)[err.message]}>{errIndex+1}. {importErrorMessage(err.columnName)[err.message]}</span>
                        )
                    })}
                
                </div>
                {clientColArray.map((col,colIndex)=>{
                    const isErrorColumnData = errorImportArr.some(data => (data.colIndex === col.columnIndex && data.rowIndex === errorIndexArr[index]) && data.columnName !== "Handler");
                    return (
                        <div
                            className={`ReactVirtualized__Table__rowColumn ${isErrorColumnData && "error-import-table-cell"} import-table-cell`}
                            role="gridcell"
                            style={{ width:tableState && tableState.width[col.columnName] * width }}
                        >
                            {col.columnType === "Checkboxes" ?
                                <span title={mergedImportedData[index][col.columnName].join()}>{mergedImportedData[index][col.columnName].join()}</span>
                            :
                                <span title={mergedImportedData[index][col.columnName]}>{mergedImportedData[index][col.columnName]}</span>
                            }
                        </div>
                    );
                })}
                {(isSa || isGroupLeader) &&
                <div
                    className={`ReactVirtualized__Table__rowColumn 
                    ${(isErrorHandler && mergedImportedData[index]["Handler"].length > 1) && "error-import-table-cell"} 
                    ${(mergedImportedData[index]["Handler"].length === 1 && mergedImportedData[index]["Handler"].some(handler => handler !== "")) && "error-import-table-cell"} 
                    import-table-cell`}
                    role="gridcell"
                    style={{ width:tableState && tableState.width["handler"] * width }}
                >
                    <span title={mergedImportedData[index]["Handler"].join()}>{mergedImportedData[index]["Handler"].join()}</span>
                </div>
                }
            </div>
        );
    };

    function indexHeaderRenderer({ dataKey, label }) {
        return (
            <div className={`ReactVirtualized__Table__headerTruncatedText import-table-header`} style={{ width:tableState && tableState.width[dataKey] * width }}>
                <span>{label}</span>
            </div>
        );
    }

    function errorHeaderRenderer({ dataKey, label }) {
        return (
            <div className={`ReactVirtualized__Table__headerTruncatedText import-table-header`} style={{ width:tableState && tableState.width[dataKey] * width }}>
                <span>{label}</span>
            </div>
        );
    }

    function headerRenderer({ dataKey, label }) {
        let required;
        let duplicate;
        let modalIndex;
        let columnName;

        [...clientColArray].sort((a, b) => a.columnIndex - b.columnIndex).map((item, index) => {
            if (item.columnName === dataKey) {
                required = item.required;  
                duplicate = item.duplicate;
                modalIndex = index;
                columnName = item.columnName
            }
        })

        const csvDupArr = errorCSVDuplicatedArr.filter(dup => dup.columnName === columnName);
        const dbDupArr = errorDBDuplicatedArr.filter(dup => dup.columnName === columnName && dup.rowValue !== "");
        const hasDbDupColumn = dbDupArr.some(db => db.columnName === columnName);
        
        return (
            <div className={`ReactVirtualized__Table__headerTruncatedText import-table-header ${(csvDupArr.length > 0 || dbDupArr.length > 0) ? "duplicate-table-header" :""}`} 
            style={{ width:tableState && tableState.width[dataKey] * width, cursor: "pointer" }} title={`${label} ${csvDupArr.length > 0 || dbDupArr.length > 0 ? `(DUPLICATED)` : ""}`}>
                <div className="table-header-container">
                    <span className="table-header-span">{label} {(csvDupArr.length > 0 || dbDupArr.length > 0) && <>{`(DUPLICATED)`}</>}
                        
                    </span>
                </div>
                {(!duplicate && hasDbDupColumn) && 
                    <div>
                        <RiArrowDownSFill style={{ marginLeft: '10px', cursor: 'pointer' }} size={15} onClick={() => openDuplicatedModal(modalIndex)} />
                    </div>} 
                
                {required && <span className="required-asterisk">*</span>}
                {(toggleDuplicatedModal && modalIndex === duplicatedModalIndex) &&
                    <div className="duplicated-modal-container" style={{width:width * 0.5, height:height * 0.7}}>
                        <div className="notify-message"><C_PopoutNoti triggerSaved={notifyMessage}/></div>
                        {(errorCSVDuplicatedArr.length > 0 || errorDBDuplicatedArr.length > 0) ?
                            <>
                             <div className="header">
                                <span className="title">Repeated or Duplicated Values 
                                    <div><AiOutlineClose cursor='pointer' size={16} 
                                        onClick={()=>{
                                            setToggleDuplicatedModal(false);
                                            setDuplicatedModalIndex(null);
                                        }} 
                                    /></div></span>
                                <span className="description">Values that are repeated in the .csv file or existed in the system</span>
                                <span className="description">Search and replace the value in CSV by using the value provided below</span>
                            </div>
                            {csvDupArr?.length > 0 && 
                                <div className="duplicated-container">
                                    <span className="sub-title"><div className="icon"><RiTable2 color="#82AB4E" size={17}/></div>CSV {`(${csvDupArr.length} found)`}</span>
                                    <div className="dup-list">
                                        {csvDupArr?.map((dup, index) => {
                                            if(columnName === dup.columnName){  
                                                return (
                                                    <div className="dup-item">
                                                        <span key={index}>{dup.rowValue}</span>
                                                        <div className="copy-container csv-copy" onClick={()=>{
                                                            navigator.clipboard.writeText(dup.rowValue);
                                                            setNotifyMessage(CommonFunc.notify("copied-value"));
                                                            }}>
                                                            <AiOutlineCopy color="#82AB4E" size={15}/>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })}
                                    </div>
                                </div>
                            }
                            {dbDupArr?.length > 0 &&
                                <div className="duplicated-container">
                                    <span className="sub-title"><div className="icon"><RiDatabase2Line color="#7b6fde" size={17}/></div>System {`(${dbDupArr.length} found)`}</span>
                                    <div className="dup-list">
                                        {dbDupArr?.map((dup, index) => {
                                            if(columnName === dup.columnName){
                                                return (
                                                    <div className="dup-item">
                                                        <span key={index}>{dup.rowValue}</span>
                                                        <div className="copy-container db-copy" onClick={()=>{
                                                            navigator.clipboard.writeText(dup.rowValue);
                                                            setNotifyMessage(CommonFunc.notify("copied-value"));
                                                            }}>
                                                            <AiOutlineCopy color="#7b6fde" size={15}/>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })}
                                    </div>
                                </div>
                            }
                            </>
                            :
                            <div className="empty-dup-list-container">
                                <span>NO DATA</span>
                            </div>
                        }
                    </div>
                }
            </div>
        );
    }

    function handlerHeaderRenderer({ dataKey, label }) {
        return (
            <div className={`ReactVirtualized__Table__headerTruncatedText import-table-header`} style={{ width:tableState && tableState.width[dataKey] * width }}>
                <span>{label}</span>
            </div>
        );
    }

    const openDuplicatedModal = (index) => {
        setToggleDuplicatedModal(!toggleDuplicatedModal);
        setDuplicatedModalIndex(index)
    }

    return (
        <>
        <div className="modal fade error-import-modal" ref={importErrorRef} tabIndex="-1" style={{overflow:'hidden'}}>
            <div className="modal-dialog modal-dialog-centered modal-xl" style={{height:"100%"}}>
                <div className="modal-content px-3" style={{height:"90%"}}>
                  <div className="error-import-header">
                    <div className="title-legend">
                        <div className="title">
                            {/* <BiFilter size={25} color={"#4d4d4d"}/> */}
                            <span className="font-s">Error Imported Data {`(${mergedImportedData.length} rows)`}</span>
                        </div>
                        {mergedImportedData?.length > 0 &&
                            <div className="legend-container">
                                <div className="legend required-legend"></div>
                                <label className="legend-label">Incorrect data format or required field</label>
                            </div>
                        } 

                        {(errorDBDuplicatedArr.length > 0 || errorCSVDuplicatedArr.length > 0) &&
                            <div className="legend-container">
                                <div className="legend duplicate-legend"></div>
                                <label className="legend-label">Repeated or duplicated data</label>
                            </div>
                        }
                    </div>
                    
                    <AiOutlineClose cursor='pointer' size={16}  onClick={()=>{
                            hideImportErrorModal();
                            resetCSVFileInput();
                            setWidth(0);
                            setHeight(0);
                            setToggleDuplicatedModal(false);
                            setDuplicatedModalIndex(null);
                            dispatch(processImportData())
                            setErrorImportArr ([])
                            setProcessedImportedRow ([])
                            setErrorIndexArr ([])
                            setErrorCSVDuplicatedArr ([])
                            setErrorDBDuplicatedArr ([])
                        }} 
                    />
                  </div>
                  <div className="modal-body import-table-body">
                      <div className="import-table-container" ref={errorImportTableRef}>
                        {/* virtualized table */}
                        {(width !== 0 && height !== 0) ?
                            <Table 
                                width={width}
                                height={height}
                                headerHeight={50}
                                rowHeight={80}
                                rowCount={mergedImportedData.length}
                                rowGetter={({ index }) => mergedImportedData[index] !== undefined && mergedImportedData[index]}
                                rowRenderer={({ key, index, style }) => _rowRenderer({ key, index, style })}
                                >
                                <Column key={"index"} headerRenderer={indexHeaderRenderer} label={"Row"} dataKey={"index"}/>
                                <Column key={"error"} headerRenderer={errorHeaderRenderer} label={"Error Message"} dataKey={"error"}/>
                                {clientColArray.map((item, index) => {
                                    return (
                                        <Column key={index} headerRenderer={headerRenderer} label={item.columnName} dataKey={item.columnName}/>
                                    ) 
                                })}
                                {(isSa || isGroupLeader) &&
                                    <Column key={"handler"} headerRenderer={handlerHeaderRenderer} label={"Handler"} dataKey={"handler"}/>
                                }
                                
                            </Table>
                            :
                            <div className="loading-container">
                                <img src={require('../../assets/retrieveDataGif.gif')} alt=''/>
                                <span>Retrieving data...</span>
                            </div>
                        }
                      </div>
                      <div className="import-table-action-container">
                        <C_DialogButton 
                        onClick={()=>{
                            hideImportErrorModal();
                            resetCSVFileInput();
                            setWidth(0);
                            setHeight(0);
                            setToggleDuplicatedModal(false);
                            setDuplicatedModalIndex(null);
                            dispatch(processImportData())
                            setErrorImportArr ([])
                            setProcessedImportedRow ([])
                            setErrorIndexArr ([])
                            setErrorCSVDuplicatedArr ([])
                            setErrorDBDuplicatedArr ([])

                        }}
                        color={"white"}
                        background={'#434343'}
                        borderColor={'#434343'}
                        width={'85px'}
                        buttonText={"CLOSE"}
                        />
                      </div>
                  </div>
                </div>
            </div>
          </div>
        </>
    )
}

export default ClientImportErrorTable;