import React from "react";
import { IconButton, Box, Tooltip } from "@mui/material";
import {
  PictureAsPdfRounded,
  DeleteOutlineRounded,
  InventoryRounded,
  MailOutlineRounded,
  KeyboardBackspaceRounded,
} from "@mui/icons-material";
import { TableBulkActionEnum } from "../../enums/TableBulkActionEnum";

export const TableBulkAction = {
  BACK: (bulkActions) => (
    <Tooltip key={"BACK"} title="Back">
      <IconButton onClick={bulkActions[TableBulkActionEnum.BACK].function}>
        <KeyboardBackspaceRounded sx={({ icons }) => ({ ...icons.medium })} />
      </IconButton>
    </Tooltip>
  ),
  PDF: () => (
    <Tooltip key={"PDF"} title={"Bulk Print / PDF "}>
      <Box>
        <IconButton
          variant="outlined"
          onClick={() => {
            alert("bulk pdf");
          }}
        >
          {/* <PictureAsPdfRounded
            sx={({ icons }) => ({
              ...icons.standard.default,
            })}
          /> */}
        </IconButton>
      </Box>
    </Tooltip>
  ),
  EMAIL: () => (
    <Tooltip key={"EMAIL"} title={"Bulk send email"}>
      <Box>
        <IconButton
          variant="outlined"
          onClick={() => {
            alert("bulk email");
          }}
        >
          {/* <MailOutlineRounded
            sx={({ icons }) => ({
              ...icons.standard.default,
            })}
          /> */}
        </IconButton>
      </Box>
    </Tooltip>
  ),

  DELETE: () => (
    <Tooltip key={"DELETE"} title={"Bulk delete"}>
      <Box>
        <IconButton
          variant="outlined"
          onClick={() => {
            alert("bulk delete");
          }}
        >
          {/* <DeleteOutlineRounded
            sx={({ icons }) => ({
              ...icons.standard.default,
            })}
          /> */}
        </IconButton>
      </Box>
    </Tooltip>
  ),

  UPDATE: () => (
    <Tooltip key={"UPDATE"} title={"Bulk update status"}>
      <Box>
        <IconButton
          variant="outlined"
          onClick={() => {
            alert("bulk update");
          }}
        >
          {/* <InventoryRounded
            sx={({ icons }) => ({
              ...icons.standard.default,
            })}
          /> */}
        </IconButton>
      </Box>
    </Tooltip>
  ),
};
