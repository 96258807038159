export const TransactionPaymentsColumn = (isDepositTo = false) => [
  {
    field: "payment_method_id",
    flex: 2,
    headerName: "Payment Method",
    sortable: false,
    type: "string",
    minWidth: 200,
    disableColumnMenu: true,
  },

  {
    field: "account_id",
    flex: 2,
    headerName: isDepositTo ? "Deposit To" : "Transfer From ",
    sortable: false,
    type: "string",
    minWidth: 200,
    disableColumnMenu: true,
  },
  {
    field: "amount",
    flex: 0,
    headerName: "Amount",
    sortable: false,
    type: "string",
    minWidth: 100,
    disableColumnMenu: true,
  },
  {
    field: "reference_no",
    flex: 2,
    headerName: "Reference No.",
    sortable: false,
    type: "string",
    minWidth: 100,
    disableColumnMenu: true,
  },
  {
    field: "fee_amount",
    flex: 0,
    headerName: "Fee Amount",
    sortable: false,
    type: "string",
    minWidth: 100,
    disableColumnMenu: true,
  },
  {
    field: "fee_account_id",
    flex: 2,
    headerName: "Fee Account",
    sortable: false,
    type: "string",
    minWidth: 100,
    disableColumnMenu: true,
  },
];

//!mui

// export const TransactionPaymentsColumn = (onClick) => [
//   {
//     field: "form_item_id",
//     headerName: "",
//     sortable: false,
//   },
//   {
//     field: "type",
//     headerName: "",
//     sortable: false,
//   },
//   {
//     field: "item_index",
//     headerName: "",
//     sortable: false,
//     // type: "string",
//     width: 10,
//     disableColumnMenu: true,
//     renderCell: (params) => {
//       return TableCell.STRING(params);
//     },
//   },

//   {
//     field: "product_id",
//     flex: 2,

//     headerName: "Item",
//     sortable: false,
//     type: "singleSelect",
//     minWidth: 200,
//     editable: true,
//     disableColumnMenu: true,
//     valueOptions: [],
//     preProcessEditCellProps: (params) => {
//       console.log(params);
//       console.log("is [proict od ");
//       const hasError = params.props.value === null;
//       return { ...params.props, error: hasError };
//     },
//     renderCell: (params) => TableCell.STRING(params),
//     // renderEditCell: (params) => onClick.editCell(params),
//   },
//   {
//     field: "description",
//     flex: 1,

//     headerName: "Description",
//     sortable: false,
//     type: "string",
//     editable: true,
//     minWidth: 180,
//     maxWidth: 250,
//     preProcessEditCellProps: (params) => {
//       console.log(params.props.value);
//       console.log(" is descripyion");
//       const hasError = params.props.value.length === 0;
//       return { ...params.props, error: hasError };
//     },
//     disableColumnMenu: true,

//     renderCell: (params) => TableCell.STRING(params),
//   },
//   {
//     field: "account_id",
//     flex: 2,
//     headerName: "Account",
//     sortable: false,
//     type: "singleSelect",
//     valueOptions: [],
//     editable: true,
//     minWidth: 200,
//     disableColumnMenu: true,
//     renderCell: (params) => TableCell.STRING(params),
//   },
//   {
//     field: "quantity",
//     flex: 1,
//     headerName: "Quantity",
//     sortable: false,
//     type: "number",
//     editable: true,
//     minWidth: 100,
//     disableColumnMenu: true,
//     renderCell: (params) => TableCell.STRING(params),
//   },
//   {
//     field: "amount",
//     flex: 1,
//     headerName: "Unit Price",
//     sortable: false,
//     type: "string",
//     editable: true,
//     minWidth: 100,
//     disableColumnMenu: true,
//     renderCell: (params) => TableCell.STRING(params),
//   },
//   {
//     field: "total_amount",
//     flex: 1,
//     headerName: "Amount",
//     sortable: false,
//     type: "string",
//     minWidth: 100,
//     disableColumnMenu: true,
//     renderCell: (params) => {
//       const { amount = "0.00", quantity = 1 } = params.row;
//       const totalAmount = parseFloat(amount).toFixed(2) * quantity;
//       console.log(totalAmount);
//       return TableCell.STRING({ value: totalAmount.toFixed(2).toString() });
//     },
//   },
//   {
//     field: "discount",
//     flex: 1,
//     headerName: "Discount",
//     sortable: false,
//     editable: true,
//     type: "string",
//     minWidth: 100,
//     disableColumnMenu: true,
//     renderCell: (params) => TableCell.STRING(params),
//   },
//   {
//     field: "tax",
//     flex: 1,
//     headerName: "Tax",
//     sortable: false,
//     type: "singleSelect",
//     editable: true,
//     valueOptions: [],
//     minWidth: 100,
//     disableColumnMenu: true,
//     renderCell: (params) => TableCell.STRING(params),
//   },
//   {
//     field: "action",
//     headerName: "",
//     sortable: false,
//     width: 20,
//     disableColumnMenu: true,
//     renderCell: (params) => {
//       return (
//         <Stack sx={{ alignItems: "center" }} direction="row" spacing={1}>
//           {TableAction.DELETE(params, onClick)}
//         </Stack>
//       );
//     },
//   },
// ];
