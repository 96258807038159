import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import C_Loading from "../../components/C_Loading";
import './CompanySettings.scss'
import { v4 } from "uuid";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { db, storage } from "../../firebaseInit";
import { BiEditAlt } from "react-icons/bi";
import moment from "moment";
import C_Button from "../../components/C_Button";
import { AiOutlineClose, AiOutlineCopy, AiOutlineDelete, AiOutlineSave } from "react-icons/ai";
import { HiUser } from "react-icons/hi";
import C_PopoutNoti from "../../components/C_PopoutNoti";
import { Modal } from "bootstrap";
import C_DialogButton from "../../components/C_DialogButton";

//slices
import { selectCompanyContext, updateCompany } from "../../stores/slices/companySlices";
import { selectCompanyID } from "../../stores/slices/companyIDSlices";
import { selectCurrSa, selectCurrTenant } from "../../stores/slices/persistRolesSlices";
import { selectCurrRoles } from "../../stores/slices/roleSlices";
import { selectUser } from "../../stores/slices/userAuthSlices";

function CompanySettings(props) {

    const deleteModalRef = useRef();
    const companyIDRef = useRef(null);
    const defaultRoleRef = useRef(null);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = selectUser();
    const { companyList } = selectCompanyContext();

    const companyID = selectCompanyID();
    const tenant = selectCurrTenant();
    const currSa = selectCurrSa();
    const currRoles = selectCurrRoles();

    const [isLoading, setIsLoading] = useState(false);
    const [updateError, setUpdateError] = useState(false);
    const [notifyMessage, setNotifyMessage] = useState("");
    const [confirmationKeyword ,setConfirmationKeyword] = useState("");

    const [currCompanyDetails, setCurrCompanyDetails] = useState({
        CompanyID: '',
        CompanyProfile: '',
        CompanyName: '',
        CompanyLocation: '',
        CompanyEmail: '',
        DefaultRole: '',
        CreatedAt: moment().format(),
    });

    useEffect(() => {

        if(companyID && companyList){
            const currCompany = companyList?.find(item => item?.CompanyID === companyID);
            setCurrCompanyDetails(currCompany)
        }
        
    },[companyList, companyID]);

    const showDeleteCompanyModalFunc = () => {
        const modalEle = deleteModalRef.current;
        const bsModal = new Modal(modalEle, {
          backdrop: "static",
          keyboard: false,
        });
        bsModal.show();
    };
    
    const hideDeleteCompanyModalFunc = () => {
        const modalEle = deleteModalRef.current;
        const bsModal = Modal.getInstance(modalEle);
        bsModal.hide();
    };

    function notify(type){
        switch(type)
        {
          case "edit":
            setNotifyMessage("UPDATED COMPANY")
            break;
          case "delete":
            setNotifyMessage("DELETED COMPANY")
            break;
          case "copied":
            setNotifyMessage("COPIED TEXT")
          default:
            break;
        }
    
        setTimeout(() => {
          setNotifyMessage("");
        }, 1500)
    };

    async function copyToClipboard(text){
        switch(text){
            case "CompanyID":
                try {
                    await navigator.clipboard.writeText(companyIDRef.current.innerText);
                    notify("copied")
                  } catch (err) {
                    console.error('Failed to copy text: ', err);
                  }
                break;
            case "DefaultRole":
                try {
                    await navigator.clipboard.writeText(defaultRoleRef.current.innerText);
                    notify("copied")
                  } catch (err) {
                    console.error('Failed to copy text: ', err);
                  }
                break;
            default:
                break;
        }
    }

    function handleInput({type, value}){
        switch(type){
            case "CompanyProfile":
                uploadCompanyImage({file: value}).then(url => {
                    setCurrCompanyDetails({...currCompanyDetails, CompanyProfile: url})
                });
                break;
            case "CompanyName":
                setCurrCompanyDetails({...currCompanyDetails, CompanyName: value})
                break;
            case "CompanyLocation":
                setCurrCompanyDetails({...currCompanyDetails, CompanyLocation: value})
                break;
            case "CompanyEmail":
                setCurrCompanyDetails({...currCompanyDetails, CompanyEmail: value})
                break;
            default:
                break;
        }
    };

    async function uploadCompanyImage({file}) {
        const profileID = v4();
        return new Promise((resolve, reject) => {
          const storageRef = ref(storage, "/companyProfileImg/" + profileID);
          const uploadTask = uploadBytesResumable(storageRef, file);
      
          uploadTask.on('state_changed',
            (snapshot) => {
              // Observe state change events such as progress, pause, and resume
              // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log('Upload is ' + progress + '% done');
              switch (snapshot.state) {
                case 'paused':
                  console.log('Upload is paused');
                  break;
                case 'running':
                  console.log('Upload is running');
                  break;
              }
            },
            (error) => {
              // Handle unsuccessful uploads
              reject(error);
            },
            () => {
              getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                resolve(downloadURL);
              }).catch((error) => {
                reject(error);
              });
            }
          )
        });
    };

    async function handleUpdateCompany(){
        if(currCompanyDetails.CompanyName !== ""){
            notify("edit");
            setIsLoading(true);
            // delete currCompanyDetails.CreatedAt;
            // delete currCompanyDetails.ModifiedAt;
           
            const {CreatedAt, ModifiedAt, ...others} = currCompanyDetails;
        
            const createdDate = moment(CreatedAt).format("YYYY-MM-DD HH:mm:ss");
            const modifiedDate = moment(ModifiedAt).format("YYYY-MM-DD HH:mm:ss")
            
            const payload = {CreatedAt : createdDate, ModifiedAt: modifiedDate, ...others}
            dispatch(updateCompany({TenantID:tenant,data:payload}))
            setIsLoading(false);
            setUpdateError(false);
            notify("edit");
             
        }else{
            setUpdateError(true)
        }
    };

    function generateRandomString(length) {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let randomString = '';
      
        for (let i = 0; i < length; i++) {
          const randomIndex = Math.floor(Math.random() * characters.length);
          randomString += characters[randomIndex];
        }
      
        return randomString;
    };

    async function handleDeleteCompany(){

    }

    return (
        <>
        <C_Loading isLoading={isLoading}/>
        <C_PopoutNoti triggerSaved={notifyMessage}/>
        <div className="modal fade" tabIndex="-1" ref={deleteModalRef}>
            <div className="modal-dialog modal-dialog-centered" style={{}}>
            <div className="modal-content px-3" style={{overflowY: "scroll" }}>
                <div className="modal-header modalHeader">
                    <div className="title">
                        <AiOutlineDelete size={17} color={"#ca4254"}/>
                        <span className="font-s">Delete Company</span>
                    </div>
                    <AiOutlineClose cursor='pointer' size={16} onClick={() => {
                        hideDeleteCompanyModalFunc();
                    }}/>
                </div>
                <div className="modal-body">
                    <div className="deleteCompanyBody">
                        {/* <span className="font-s" style={{}}>
                            Are you sure you want to delete this company permanently ? <br/>This action cannot be undone.
                        </span> */}

                        <span className="font-s" style={{fontWeight:'bold'}}>Delete company  will :</span>

                        <div className="deleteInfoMainContainer">
                            <div className="deleteInfoContainer">
                                <HiUser size={16}/>
                                <span className="font-xs">Remove all members associated with the company along with its data.</span>
                            </div>
                        </div>
                        
                        <div className="confirmation">
                            <div className="keywordMainContainer">
                                <span className="keyword">{generateRandomString(8)}</span>
                            </div>
                        </div>
                    </div>
                    
                    <div
                        style={{ display: "flex", justifyContent: "flex-end", columnGap:'20px' }}
                        className="mt-3 keywordContainer"
                    >
                    <input
                        className="keywordInput"
                        placeholder="Enter the keyword above to proceed"
                        value={confirmationKeyword}
                        onChange={(e) => {
                            setConfirmationKeyword("");
                        }}
                    />
                    <C_DialogButton 
                        color={"white"}
                        background={'#ca4254'}
                        borderColor={'#ca4254'}
                        buttonText={"DELETE"}
                        onClick={() => { handleDeleteCompany() }}
                    />
                    </div>
                </div>
            </div>
            </div>
        </div>
        <div className="CompanySettingsMainContainer">
            <div className="CompanySettingsContainer">
                <div className="CompanyDetailsContainer">
                    <div className="inputContainer">
                        <span className="title">Company No</span>
                        <span className="valueCopyable" ref={companyIDRef}>{currCompanyDetails?.CompanyID} <AiOutlineCopy className="copyIcon" size={16} onClick={() => { copyToClipboard("CompanyID"); }}/></span>
                    </div>
                    <div className="inputContainer">
                        <span className="title">Company Name {currSa && <BiEditAlt size={16}/>}</span>
                        {currSa ?
                            <input
                                placeholder="company name"
                                value={currCompanyDetails?.CompanyName}
                                onChange={(e) => {
                                    handleInput({ type: 'CompanyName', value: e.target.value})
                                }}
                            />
                            :
                            <span className="value"></span>
                        }
                    </div>
                    <div className="inputContainer">
                        <span className="title">Company Email {currSa && <BiEditAlt size={16}/>}</span>
                        {currSa ?
                            <input
                                placeholder="company email"
                                value={currCompanyDetails?.CompanyEmail}
                                onChange={(e) => {
                                    handleInput({ type: 'CompanyEmail', value: e.target.value})
                                }}
                            />
                            :
                            <span className="value"></span>
                        }
                    </div>
                    <div className="inputContainer">
                        <span className="title">Company Location {currSa && <BiEditAlt size={16}/>}</span>
                        {currSa ?
                            <input
                                placeholder="company location"
                                value={currCompanyDetails?.CompanyLocation}
                                onChange={(e) => {
                                    handleInput({ type: 'CompanyLocation', value: e.target.value})
                                }}
                            />
                            :
                            <span className="value"></span>
                        }
                    </div>
                    <div className="inputContainer">
                        <span className="title">Default Role</span>
                        <span className="valueCopyable" ref={defaultRoleRef}>{currCompanyDetails?.DefaultRole} <AiOutlineCopy className="copyIcon" size={16} onClick={() => { copyToClipboard("DefaultRole"); }}/></span>
                    </div>
                    <div className="inputContainer">
                        <span className="title">Created At</span>
                        <span className="value">{moment(currCompanyDetails?.CreatedAt).format('DD-MM-YYYY HH:MM dddd')}</span>
                    </div>
                </div>
                <div className="CompanyActionContainer">
                    <C_Button
                        background={"#3eb986"}
                        width={"110px"}
                        gap={"10px"}
                        buttonText={"Save"}
                        justify={"center"}
                        icon={<AiOutlineSave color="#FFF" size={12}/>}
                        onClick={() => {
                            handleUpdateCompany()
                        }}
                    />
                </div>
            </div>
        </div>
        </>
    );
};

export default CompanySettings;