import palette from "./palette";

const systemDefaultFontFamilySansSerif = ["Inter", "sans-serif"];

const typography = {
  htmlFontSize: 16,
  fontSize: 16,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  fontFamily: [...systemDefaultFontFamilySansSerif].join(","),
  displayH1: {
    fontFamily: [...systemDefaultFontFamilySansSerif].join(","),
    fontWeight: 700,
    fontSize: 36,
    color: palette.text.default,
    textTransform: "unset",
  },
  displayH2: {
    fontFamily: [...systemDefaultFontFamilySansSerif].join(","),
    fontWeight: 400,
    fontSize: 20,
    color: palette.text.default,
    textTransform: "unset",
  },
  displayH3: {
    fontFamily: [...systemDefaultFontFamilySansSerif].join(","),
    fontWeight: 600,
    fontSize: 18,
    color: palette.text.default,
    textTransform: "unset",
  },
  displayNewFormTitle: {
    fontFamily: [...systemDefaultFontFamilySansSerif].join(","),
    fontWeight: 400,
    fontSize: 22,
    color: palette.text.default,
    textTransform: "unset",
  },
  bodyTextTitle: {
    fontFamily: [...systemDefaultFontFamilySansSerif].join(","),
    fontWeight: 400,
    fontSize: 16,
    color: palette.text.default,
    textTransform: "unset",
  },
  bodyText: {
    fontFamily: [...systemDefaultFontFamilySansSerif].join(","),
    fontWeight: 400,
    fontSize: 14,
    color: palette.text.default,
    textTransform: "unset",
  },
  bodyTextLight: {
    fontFamily: [...systemDefaultFontFamilySansSerif].join(","),
    fontWeight: 400,
    fontSize: 14,
    color: palette.text.secondary,
    textTransform: "unset",
  },
  bodyTextSemiBold: {
    fontFamily: [...systemDefaultFontFamilySansSerif].join(","),
    fontWeight: 600,
    fontSize: 14,
    color: palette.text.primary,
    textTransform: "unset",
  },
  bodyTextBold: {
    fontFamily: [...systemDefaultFontFamilySansSerif].join(","),
    fontWeight: 700,
    fontSize: 14,
    color: palette.text.default,
    textTransform: "unset",
  },
  bodyTextUpper: {
    fontFamily: [...systemDefaultFontFamilySansSerif].join(","),
    fontWeight: 400,
    fontSize: 14,
    textTransform: "capitalize",
    color: palette.text.main,
  },
  bodyTextBoldUpper: {
    fontFamily: [...systemDefaultFontFamilySansSerif].join(","),
    fontWeight: 700,
    fontSize: 14,
    color: palette.text.main,
    textTransform: "capitalize",
  },
  remark: {
    fontFamily: [...systemDefaultFontFamilySansSerif].join(","),
    fontWeight: 400,
    fontSize: 12,
    color: palette.text.label,
    textTransform: "unset",
  },
  remarkSemiBold: {
    fontFamily: [...systemDefaultFontFamilySansSerif].join(","),
    fontWeight: 700,
    fontSize: 12,
    color: palette.text.label,
    textTransform: "unset",
  },
  copyRight: {
    fontFamily: [...systemDefaultFontFamilySansSerif].join(","),
    fontWeight: 400,
    fontStyle: "italic",
    fontSize: 14,
    color: palette.text.label,
    textTransform: "unset",
  },
  infoText: {
    fontFamily: [...systemDefaultFontFamilySansSerif].join(","),
    fontWeight: 400,
    fontSize: 14,
    color: palette.text.info,
    textTransform: "unset",
  },
  textOverflow: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  textLink: {
    fontFamily: [...systemDefaultFontFamilySansSerif].join(","),
    fontWeight: 400,
    fontSize: 14,
    color: palette.text.primary,
    textTransform: "unset",
  },
};

export default typography;
