import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import './UsageAndBillings.scss'
import { AiOutlineClose } from "react-icons/ai";
import { HiOutlineUserGroup } from "react-icons/hi";
import C_PopoutNoti from "../../components/C_PopoutNoti";
import { Modal } from "bootstrap";

import { MdOutlineAnalytics, MdOutlineManageAccounts, MdOutlineOtherHouses } from "react-icons/md";
import { easeQuadInOut } from "d3-ease";
import AnimatedProgressProvider from "../../components/AnimatedProgressProvider";
import { TbBusinessplan } from "react-icons/tb";
import plan from '../../enums/json/plan.json';


//slices
import { retrieveCurrUsage } from "../../stores/slices/usageSlices";
import { selectCompanyID } from "../../stores/slices/companyIDSlices";
import { selectAvailableTenant, selectTenant } from "../../stores/slices/tenantSlices";
import { selectCurrSa, selectCurrTenant } from "../../stores/slices/persistRolesSlices";
import { selectCurrRoles, selectRoles } from "../../stores/slices/roleSlices";
import { selectCurrUserContext } from "../../stores/slices/companySlices";
import { selectUser } from "../../stores/slices/userAuthSlices";

function UsageAndBillings(props) {

    const planModal = useRef(null);

    const dispatch = useDispatch();
    const { currPlan, currUsage, currTotalUsage } = selectCurrUserContext();
    
    const companyID = selectCompanyID();
    const tenant = selectCurrTenant();
    const currSa = selectCurrSa();
    const currRoles = selectCurrRoles();
    const roles = selectRoles();
    const availableTenant = selectAvailableTenant();

    const [isLoading, setIsLoading] = useState(false);
    const [updateError, setUpdateError] = useState(false);
    const [notifyMessage, setNotifyMessage] = useState("");

    const [subSection, setSubSection] = useState("users");

    const showPlanModalFunc = () => {
        const modalEle = planModal.current;
        const bsModal = new Modal(modalEle, {
          backdrop: "static",
          keyboard: false,
        });
        bsModal.show();
    };

    const hidePlanModalFunc = () => {
        const modalEle = planModal.current;
        const bsModal = Modal.getInstance(modalEle);
        bsModal.hide();
    };

    async function fetchData(){
       dispatch(retrieveCurrUsage({TenantID:tenant,CompanyID:companyID}));
    }

    useEffect(() => {
        setIsLoading(true)
        fetchData()
        setTimeout(() => {
            setIsLoading(false);
        },1500);
        
    },[]);

    function notify(type){
        switch(type){
          case "edit":
            setNotifyMessage("UPDATED COMPANY")
            break;
          case "delete":
            setNotifyMessage("DELETED COMPANY")
            break;
          case "copied":
            setNotifyMessage("COPIED TEXT")
            break;
          case "sent":
            setNotifyMessage("INVITATION SENT")
            break;
          default:
            break;
        }
    
        setTimeout(() => {
          setNotifyMessage("");
        }, 1500);
    };

    function handleChangeSubSection(tab){
        setSubSection(tab);
    };


    const category = [
        {
            CategoryName: "User Management",
            CategoryID: "c1"
        },
        {
            CategoryName: "Analytics & Data",
            CategoryID: "c2"
        },
        {
            CategoryName: "Client Management",
            CategoryID: "c3"
        },
        {
            CategoryName: "Others",
            CategoryID: "c4"
        }
    ];
    
    return (
        <>
        <C_PopoutNoti triggerSaved={notifyMessage}/>

        <div className="modal fade" tabIndex="-1" ref={planModal} style={{ zIndex: 100000 }}>
            <div className="modal-dialog modal-dialog-centered modal-xl" style={{}}>
                <div className="modal-content px-3" style={{overflowY: "scroll", backgroundColor: "#f6f6ff" }}>
                    <div className="modal-header modalHeader">
                        <div className="title">
                            <TbBusinessplan size={17}/>
                            <span className="font-s">Plans</span>
                        </div>
                        <div className="currentPlanContainer">
                            <span>Your current plan: <span className="currentPlan">{currPlan === "Trial" ? "Free Trial" : currPlan}</span></span>
                        </div>
                        <AiOutlineClose cursor='pointer' size={16} onClick={() => {
                            hidePlanModalFunc();
                        }}/>
                    </div>
                    <div className="modal-body planBody">
                        <div className="plan">
                            <div className="planTitle">
                                <span className="title">Standard</span>
                                <span className="subtitle">Perfect Plan for Starters.</span>
                            </div>
                            <div className="planPrice">
                                <span><span className="unit">RM</span><span className="price">18</span> / User / Month</span>
                            </div>
                            <div className="planFeatures">
                                {Object.values(plan.Standard).map(item => {
                                    return(
                                        <div className="feature">
                                            <div className="dot"><div className="white-dot"></div></div>
                                            <span>{`${item?.Quota} ${item?.SectionName} ${item?.CategoryID !== "c4" ? item?.Unit : ""}`}</span>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className="planAction">
                                <a href="https://zumaxdigital.com/crm-v1/" target="_blank" style={{textDecoration:'none',cursor:'pointer'}}><button><span>CONTACT US</span></button></a>
                            </div>
                        </div>
                        <div className="plan current">
                            <div className="planTitle">
                                <span className="title">Pro</span>
                                <span className="subtitle">True Power of Marketing.</span>
                            </div>
                            <div className="planPrice">
                                <span><span className="unit">RM</span><span className="price">38</span> / User / Month</span>
                            </div>
                            <div className="planFeatures">
                                {Object.values(plan.Pro).map(item => {
                                    return(
                                        <div className="feature">
                                            <div className="dot"><div className="white-dot"></div></div>
                                            <span>{`${item?.Quota} ${item?.SectionName} ${item?.CategoryID !== "c4" ? item?.Unit : ""}`}</span>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className="planAction">
                                <a href="https://zumaxdigital.com/crm-v1/" target="_blank" style={{textDecoration:'none',cursor:'pointer'}}><button><span>CONTACT US</span></button></a>
                            </div>
                        </div>
                        <div className="plan">
                            <div className="planTitle">
                                <span className="title">Enterprise</span>
                                <span className="subtitle">Collaborate Professionally.</span>
                            </div>
                            <div className="planPrice">
                                <span><span className="unit">RM</span><span className="price">66</span> / User / Month</span>
                            </div>
                            <div className="planFeatures">
                                {Object.values(plan.Enterprise).map(item => {
                                    return(
                                        <div className="feature">
                                            <div className="dot"><div className="white-dot"></div></div>
                                            <span>{`${item?.Quota} ${item?.SectionName} ${item?.CategoryID !== "c4" ? item?.Unit : ""}`}</span>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className="planAction">
                                <a href="https://zumaxdigital.com/crm-v1/" target="_blank" style={{textDecoration:'none',cursor:'pointer'}}><button><span>CONTACT US</span></button></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="UsageAndBillingsMainContainer">
            <div className="UsageAndBillingsContainer">
                <div className="SubSectionTabContainer">
                    <div className="mainActionContainer">
                        <div className='subTabContainer'>
                            <span>Usage Overview</span>
                        </div>
                        <div className='subTabContainer plans' onClick={() => { showPlanModalFunc() }}>
                            <span>{currPlan === "Trial" ? "7 days Trial" : currPlan}</span>
                            <TbBusinessplan size={20}/>
                        </div>
                    </div>
                </div>
                <div className="subSectionContainer">
                    {category?.map(cat => {
                        return(
                            <div key={cat.CategoryID} className="usageMainContainer">
                                <div className="categoryContainer">
                                    {cat.CategoryID === "c1" && <MdOutlineManageAccounts size={18}/>}
                                    {cat.CategoryID === "c2" && <MdOutlineAnalytics size={18}/>}
                                    {cat.CategoryID === "c3" && <HiOutlineUserGroup size={18}/>}
                                    {cat.CategoryID === "c4" && <MdOutlineOtherHouses size={18}/>}
                                    <span>{cat.CategoryName}</span>
                                </div>
                                <div className={`loadingContainer ${!isLoading && 'hideLoadingContainer'}`}>
                                    <div className="loading-spinner">
                                    </div>
                                </div>
                                <div className={`usageContainer ${isLoading && 'hideUsageContainer'}`}>
                                    {(Array.isArray(currUsage) && currUsage !== undefined && currUsage) && [...currUsage]?.sort((a, b) => a?.SectionName?.localeCompare(b?.SectionName)).map((item, index) => {
                                        if(item.CategoryID === cat.CategoryID){
                                            if(item?.SectionID === "s6"){
                                                const quota = item?.Quota;
                                                const ClientTableColumn = currTotalUsage?.find(currQuota => currQuota?.SectionID === "s6" && item?.SectionID === "s6")?.Usage;
                                                return(
                                                    <div key={index} className="sectionContainer">
                                                        <div className="tableColumnSection">
                                                            <div className="section">
                                                                <span>{item.SectionName}</span>
                                                                <span>(per group)</span>
                                                            </div>
                                                        </div>
                                                        <div className="tableColumnUsage">
                                                            {[...ClientTableColumn]?.sort((a, b) => a.GroupName.localeCompare(b.GroupName)).map(prog => {
                                                                return(
                                                                    <div className="usage">
                                                                        <span><span className="calculation">{prog?.columnCount}/{quota}</span> of Usage ({prog?.GroupName})</span>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                        <div className="tableColumnProgression">
                                                            {[...ClientTableColumn]?.sort((a, b) => a.GroupName.localeCompare(b.GroupName)).map(prog => {
                                                                const percentage = (prog?.columnCount / quota) * 100;
                                                                return(
                                                                    <div className="progression">
                                                                        <AnimatedProgressProvider
                                                                            valueStart={0}
                                                                            valueEnd={percentage}
                                                                            duration={1.5}
                                                                            easingFunction={easeQuadInOut}
                                                                        >
                                                                            {(value) => {
                                                                                const roundedValue = Math.round(value);
                                                                                return (
                                                                                    <div className="progressbar">
                                                                                        <div className="fill" style={{ 
                                                                                            width: `${roundedValue < 101 ? roundedValue : 100}%`,
                                                                                            backgroundColor: `${roundedValue < 51 ? "#4171e1" : "#173887"}`,
                                                                                        }}></div>
                                                                                        <span style={{ color: `${roundedValue < 51 ? "#000" : "#FFF"}` }}>{roundedValue}%</span>
                                                                                    </div>
                                                                                );
                                                                            }}
                                                                        </AnimatedProgressProvider>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                )
                                            }else if(item?.SectionID !== "s6"){
                                                const currentUsageValue = currTotalUsage?.find(currQuota => currQuota?.SectionID === item?.SectionID && currQuota?.SectionID !== "s6")?.Usage;
                                                const quota = item?.Quota;
                                                const percentage = quota === "unlimited" ? 100 : (currentUsageValue / quota) * 100;
                                                return(
                                                    <div key={index} className="sectionContainer">
                                                        <div className="section">
                                                            <span>{item.SectionName}</span>
                                                        </div>
                                                        <div className="usage">
                                                            <span><span className="calculation">{currentUsageValue}/{quota}</span> of Usage</span>
                                                        </div>
                                                        <div className="progression">
                                                            <AnimatedProgressProvider
                                                                valueStart={0}
                                                                valueEnd={percentage}
                                                                duration={1.5}
                                                                easingFunction={easeQuadInOut}
                                                            >
                                                                {(value) => {
                                                                    const roundedValue = Math.round(value);
                                                                    return (
                                                                        <div className="progressbar">
                                                                            <div className="fill" style={{ 
                                                                                width: `${roundedValue < 101 ? roundedValue : 100}%`,
                                                                                backgroundColor: `${roundedValue < 51 ? "#4171e1" : "#173887"}`,
                                                                            }}></div>
                                                                            {quota === "unlimited" && <span style={{ color: `${roundedValue < 51 ? "#000" : "#FFF"}` }}>{quota}</span>}
                                                                            {quota !== "unlimited" && <span style={{ color: `${roundedValue < 51 ? "#000" : "#FFF"}` }}>{roundedValue}%</span>}
                                                                            {/* <span style={{ color: `${roundedValue < 51 ? "#000" : "#FFF"}` }}>{roundedValue}%</span> */}
                                                                        </div>
                                                                    );
                                                                }}
                                                            </AnimatedProgressProvider>
                                                        </div>
                                                    </div>
                                                )
                                            } 
                                        }
                                        
                                    })}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
        </>
    );
};

export default UsageAndBillings;