//! with item children form in this usecase not suitable to use
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  IconButton,
  Stack,
  Button,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import {
  FormInputText,
  FormInputDropdownSearch,
  FormInputSwitch,
  FormInputSelect,
} from "../../../components/FormInput";
import {
  PopoverButton,
  PopoverChildrenButton,
  ChipText,
} from "../../../components/Utils";
import { ChipStatus } from "../../../enums/components/Chip";
import { transactionTypeNameEnum } from "../../../enums/Transaction";
// import { ChipStatus, transactionTypeNameEnum } from "@enum";
import { validatorRequired, validatorDiscount } from "../../../util/validators";
import { useFieldArray, useWatch, useForm } from "react-hook-form";
import { TableContent } from "../../../constants/Invoicing/TableContent";
import { TABLE } from "../../../constants/Invoicing/Table";
import { useAppDispatch } from "../../../hooks/storeHooks";
import { FormLayoutTitle } from "../../AccContainers/Layout";
import { DeleteOutlineRounded, AddRounded } from "@mui/icons-material";
import useSnack from "../../../hooks/useSnack";
import { PaymentTermForm } from "./PaymentTermForm";
import { ItemChildrenForm } from "./ItemChildrenForm";
import { toggleDialogBox } from "../../../stores/slices/toggleSlice";
import { SnackType } from "../../../enums/Snacks";
import { transactionTax } from "../../../enums/TransactionTax";
import { TableNoItemsOverlay } from "../../../assets/svg/table-no-items-overlay";
export const ItemsForm = ({
  control,
  watch,
  setValue,
  getValues,
  options,
  companySetting = {},
  activeTaxKey,
  is_invoice,
  isPurchase = false,
  transferTransactionTypeList,
}) => {
  //* const function section
  const [formItemFocus, setFormItemFocus] = useState(null);
  const [removeItem, setRemoveItem] = useState(null);
  const dispatch = useAppDispatch();

  const { callSnack } = useSnack();

  const {
    products = [],
    accounts = [],
    payment_terms = [],
    transferable_transactions = [],
  } = options;
  const productOptions = products.map((product) => {
    return {
      value: product.product_id,
      label: product.name,
    };
  });
  const accountOptions = accounts.map((account) => {
    return {
      value: account.account_id,
      label: account.name,
      group: account.type,
    };
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "form_items",
  });

  const watchActiveTax = watch("active_tax");

  const watchTaxInclusive = useWatch({
    control,
    name: `tax_inclusive`,
  });

  const transferableTransactionsWatcher = useWatch({
    control,
    name: `TransferredFormItems`,
  });

  const taxList = transactionTax[activeTaxKey];
  const productAmountKey = isPurchase ? "purchase_price" : "sale_price";
  const defaultValues = {
    form_item_id: null,
    index: 0,
    type: null,
    product_id: null,
    descriptions: "",
    account_id: companySetting.account ? companySetting.account : null,
    quantity: 1,
    product_uom_id: null,
    amount: "0.00",
    total_amount: "0.00",
    discount: "0.00",
    tax: null,
    FormItemChildren: [],
    // tax_amount: "0.00",
  };
  const { handleSubmit } = useForm({
    defaultValues: defaultValues,
    shouldFocusError: true,
    shouldUnregister: false,
  });

  const formItemsWatcher = useWatch({
    control,
    name: `form_items`,
  });

  const handleSubmitSubtitle = (data) => {
    const subtitleData = {
      ...data,
      type: "subtitle",
    };
    handleSubmitNewOptions(subtitleData);
  };
  const handleSubmitSubtotal = (data) => {
    const subtotalData = {
      ...data,
      tax: data.tax === null || data.tax === "" ? 0.0 : data.tax,
      type: "subtotal",
    };
    handleSubmitNewOptions(subtotalData);
  };
  const handleSubmitNewOptions = (data) => {
    const formItemsLength =
      formItemsWatcher !== undefined ? formItemsWatcher.length : 0;
    const index = data.index + formItemsLength;

    const result = {
      ...data,
      tax: data.tax === null || data.tax === "" ? 0.0 : data.tax,
      index: index,
    };
    append(result);
  };

  const handleRemoveNewOptions = (indexToRemove, isTransferItem) => {
    // if (formItemsWatcher.length !== indexToRemove + 1) {
    //   move(indexToRemove + 1, indexToRemove);
    //   remove(indexToRemove + 1);
    // } else {
    //   remove(indexToRemove);
    // }

    if (isTransferItem) {
      const transferFormItemId =
        formItemsWatcher[indexToRemove].transfer_form_item_id;

      const filteredTransferItems = transferableTransactionsWatcher.filter(
        (item) => {
          item.form_id !== transferFormItemId;
        }
      );

      setValue("TransferredFormItems", filteredTransferItems, {
        shouldDirty: true,
      });

      remove(indexToRemove);
    } else {
      remove(indexToRemove);
    }
  };

  const formItemFocusedValue = (index) => {
    if (
      formItemsWatcher !== undefined &&
      formItemsWatcher[index] !== undefined
    ) {
      const matchedIndex = formItemFocus !== null && formItemFocus === index;

      const isDiscountPercent = formItemsWatcher[index].discount
        ? formItemsWatcher[index].discount.endsWith("%")
        : false;

      if (matchedIndex) {
        return null;
      }
      if (isDiscountPercent) {
        const discountAmount =
          (parseFloat(formItemsWatcher[index].total_amount) *
            parseFloat(formItemsWatcher[index].discount.replace(/%$/, ""))) /
          100;
        return discountAmount.toFixed(2);
      }

      return null;
    }
    return null;
  };

  // const ItemChildrenFormComponent = memo((index) => {
  //   return (
  //     <ItemChildrenForm
  //       control={control}
  //       watch={watch}
  //       setValue={setValue}
  //       companySetting={companySetting}
  //       // productList={
  //       //   productList
  //       // }
  //       productAmountKey={productAmountKey}
  //       options={{
  //         productOptions,
  //         accountOptions,
  //       }}
  //       products={products}
  //       activeTaxKey={activeTaxKey}
  //       formItemIndex={index}
  //       removeParentFunction={(i) => handleRemoveNewOptions(i)}
  //     />
  //   );
  // });

  const ItemChildrenFormComponent = (index, removeItemIndex, formItemChild) => {
    return (
      <ItemChildrenForm
        control={control}
        watch={watch}
        setValue={setValue}
        companySetting={companySetting}
        // productList={
        //   productList
        // }
        productAmountKey={productAmountKey}
        options={{
          productOptions,
          accountOptions,
        }}
        formItemChild={formItemChild}
        products={products}
        activeTaxKey={activeTaxKey}
        formItemIndex={index}
        removeItem={removeItemIndex}
        setRemoveItem={setRemoveItem}
        handleSubmit={handleSubmit}
        removeParentFunction={(i) => handleRemoveNewOptions(i)}
      />
    );
  };
  //* component section

  const overallTotalText = (text, isBold = false) => {
    return (
      <Typography
        sx={({ typography, palette }) => ({
          ...typography[isBold ? "bodyTextBold" : "bodyText"],
          fontSize: 20,
          color: palette.text.primary,
          // marginTop: "10px",
        })}
      >
        {text}
      </Typography>
    );
  };

  const plainText = (text, isBold = false) => {
    return (
      <Typography
        sx={({ typography }) => ({
          ...typography[isBold ? "bodyTextBold" : "bodyText"],
        })}
      >
        {text}
      </Typography>
    );
  };
  const priceText = (text, currency, isBold = false) => {
    return (
      <Stack direction={"row"} sx={{ justifyContent: "space-between" }}>
        {plainText(currency, isBold)}
        {plainText(text, isBold)}
      </Stack>
    );
  };

  const discountValueConverter = (productAmount, productDiscount) => {
    if (
      productDiscount === null ||
      productDiscount === "" ||
      productDiscount === undefined
    ) {
      return 0;
    }
    if (productDiscount.endsWith("%")) {
      const discountAmount =
        (parseFloat(productAmount) *
          parseFloat(productDiscount.replace(/%$/, ""))) /
        100;
      return discountAmount;
    }

    return parseFloat(productDiscount);
  };

  const taxValueConverter = (tax, totalAmount) => {
    if (watchTaxInclusive) {
      if (tax) {
        const taxValue = parseInt(tax) ? parseInt(tax) : tax;
        const taxPercentage = (taxValue + 100) / 100;
        const inputAmount = totalAmount;
        const subAmount = inputAmount / taxPercentage;
        return parseFloat(subAmount);
      }
    }
    return totalAmount;
  };

  const totalTaxValueConverter = (tax, totalAmount) => {
    if (tax === null || tax === "" || tax === undefined) {
      return 0;
    }

    const taxNumerator = watchTaxInclusive ? 100 : 100 + parseFloat(tax);

    const taxPercentage =
      taxNumerator / (watchTaxInclusive ? 100 + parseFloat(tax) : 100);

    const amountWithTax = totalAmount * taxPercentage;

    const taxAmount = watchTaxInclusive
      ? totalAmount - amountWithTax
      : amountWithTax - totalAmount;

    return parseFloat(taxAmount);
  };

  const productBundleChildrenTotalAmountText = (
    index,
    isTotalAmount = false
  ) => {
    const productList = formItemsWatcher[index].FormItemChildren;
    if (isTotalAmount) {
      const overallTotalAmount = productList.reduce((acc, item) => {
        const totalAmount = parseFloat(item.amount) * parseFloat(item.quantity);
        return acc + totalAmount;
      }, 0);
      return plainText(overallTotalAmount.toFixed(2));
    }
    const overallUnitPrice = productList.reduce((acc, item) => {
      return acc + parseFloat(item.amount);
    }, 0);
    return plainText(overallUnitPrice.toFixed(2));
  };

  const productBundleChildrenTotalDiscount = (productList) => {
    const totalDiscountAmount = productList.reduce((total, product) => {
      if (product) {
        if (product.discount) {
          if (product.discount.endsWith("%")) {
            const discountAmount =
              (parseFloat(product.amount) *
                parseFloat(product.discount.replace(/%$/, ""))) /
              100;
            return total + discountAmount * product.quantity;
          } else {
            return total + parseFloat(product.discount) * product.quantity;
          }
        }
      }
      return total;
    }, 0);
    return plainText(totalDiscountAmount.toFixed(2));
  };

  const productBundleChildrenTotalTax = (productList) => {
    const totalTaxAmount = productList.reduce((total, product) => {
      if (product) {
        const totalAmount =
          parseFloat(product.quantity) * parseFloat(product.amount);

        return total + totalTaxValueConverter(product.tax, totalAmount);
      }
      return total;
    }, 0);
    return totalTaxAmount;
  };

  const deleteButton = (index) => {
    return (
      <Box sx={{ alignSelf: "center", textAlign: "center" }} width={"35px"}>
        <IconButton
          onClick={() => {
            handleRemoveNewOptions(index);
          }}
          sx={({ icons, palette }) => ({
            ...icons.standard.default,
            color: palette.icon.delete,
            "&:hover": {
              color: palette.icon.delete,
            },
            marginTop: "20px",
          })}
        >
          <DeleteOutlineRounded fontSize="inherit" />
        </IconButton>
      </Box>
    );
  };

  const addButton = (buttonText, onClick) => {
    return (
      <Button
        variant="contained"
        onClick={handleSubmit(onClick)}
        sx={{
          backgroundColor:
            buttonText !== "Item" ? "#FFFFFF !important" : "#5D45FE !important",
          color:
            buttonText !== "Item"
              ? "#788077 !important"
              : "#FFFFFF  !important",
          borderColor:
            buttonText !== "Item"
              ? "#959C94 !important"
              : "#F5F6FA  !important",
        }}
        startIcon={
          <AddRounded
            sx={({ icons }) => ({
              ...icons.standard,
              backgroundColor:
                buttonText !== "Item"
                  ? "transparent !important"
                  : "#5D45FE !important",
              color:
                buttonText !== "Item"
                  ? "#788077 !important"
                  : "#FFFFFF !important",
            })}
          />
        }
      >
        {buttonText}
      </Button>
    );
  };

  const handleManageTransferItems = (transferArray, type) => {
    const transferableTransaction = transferable_transactions.map((item) => {
      const { FormItems = [] } = item;

      const form_item = FormItems.map((formItem) => {
        const { tax } = formItem;
        return {
          ...formItem,
          tax: tax === "0" || tax === "" || tax === null ? 0 : parseFloat(tax),
        };
      });

      return {
        ...item,
        FormItems: form_item,
      };
    });

    if (transferTransactionTypeList && transferTransactionTypeList.length > 1) {
      console.log("manage tansagertanscitokjn");
    } else {
      const dialogParams = {
        data: type,
        isOpen: true,
      };
      const transferObj =
        transferArray.length > 0
          ? {
              transfer: {
                data: {
                  title: type,
                  list: transferableTransaction,
                },
                isOpen: false,
              },
            }
          : {
              transfer: {
                data: {
                  title: type,
                  list: transferableTransaction,
                },
                isOpen: true,
              },
            };
      dispatch(
        toggleDialogBox({
          update: dialogParams,
          ...transferObj,
        })
      );
    }
  };

  const handleTransferTransaction = (type) => {
    const getSelectedTransactions = transferable_transactions.filter((item) => {
      return type === item.type;
    });

    dispatch(
      toggleDialogBox({
        update: {
          data: type,
          isOpen: true,
        },
        transfer: {
          data: {
            title: type,
            list: getSelectedTransactions,
          },
          isOpen: true,
        },
      })
    );
  };

  const manageTransferButton = () => {
    const transferArray = transferableTransactionsWatcher
      ? transferableTransactionsWatcher
      : [];
    const transactionTypeTitle =
      transferTransactionTypeList.length > 1
        ? " ..."
        : ` ${
            transferTransactionTypeList[0].charAt(0).toUpperCase() +
            transferTransactionTypeList[0].slice(1)
          }`;
    return transferTransactionTypeList.length > 1 ? (
      <PopoverButton
        variant="contained"
        buttonChild={"Transfer items from..."}
        anchor={{
          vertical: "bottom",
          horizontal: "left",
        }}
        popoverClassName={"manage_transfer_item"}
      >
        {transferTransactionTypeList.map((type) => {
          return (
            <PopoverChildrenButton
              key={`child_${type}`}
              fullWidth
              label={transactionTypeNameEnum[type]}
              // endAdornment={shouldRenderEndAdornment(item.company_id)}
              sx={{ paddingInline: "20px" }}
              onClick={() => {
                handleTransferTransaction(type);
              }}
            />
          );
        })}
      </PopoverButton>
    ) : (
      <Button
        className={"MuiButton-cancel"}
        sx={{
          width: "1",
          borderColor: "#5D45FE !important",
          color: "#5D45FE !important",
        }}
        onClick={() =>
          handleManageTransferItems(
            transferArray,
            transferTransactionTypeList[0]
          )
        }
        // startIcon={

        // }
      >
        {transferArray.length > 0
          ? "Manage Transfer Items"
          : `Transfer Items From ${transactionTypeTitle}`}
      </Button>
    );
  };

  const numberingCell = (index) => {
    return (
      <Box
        sx={{ alignSelf: "center", textAlign: "center", marginTop: "20px" }}
        width={"35px"}
      >
        {plainText((index + 1).toString())}
      </Box>
    );
  };

  const gridHeaderRenderer = (headers) => {
    return (
      <Box sx={{ borderBottom: "1px solid #E6E6E6" }}>
        <Grid container>
          <Box width={"35px"}></Box>
          {headers.map((header) => {
            const gridFlex = header.flex > 1 ? header.flex : true;
            return (
              <Grid
                key={`header_${header.headerName}`}
                item
                xs={gridFlex}
                sm={gridFlex}
              >
                <Box padding={"10px 0"}>
                  <Typography
                    sx={({ typography }) => ({
                      ...typography.bodyTextTitle,
                    })}
                  >
                    {header.headerName}
                  </Typography>
                </Box>
              </Grid>
            );
          })}
          <Box width={"35px"}></Box>
        </Grid>
      </Box>
    );
  };

  const onChangeParseFloat = (
    value,
    totalAmount,
    index,
    isInt = false,
    childIndex = null
  ) => {
    const priceRegex = new RegExp(/^-?(0(\.\d+)?|[1-9]\d*(\.\d+)?)$/);
    if (priceRegex.test(value)) {
      const result = isInt ? value : parseFloat(value).toFixed(2);
      if (childIndex) {
        setValue(
          `form_items[${index}].FormItemChildren[${childIndex}].total_amount`,
          parseFloat(totalAmount).toFixed(2),
          { shouldDirty: false, shouldValidate: true }
        );
      } else {
        setValue(
          `form_items[${index}].total_amount`,
          parseFloat(totalAmount).toFixed(2),
          { shouldDirty: false, shouldValidate: true }
        );
      }
      return result;
    }
    callSnack({
      type: SnackType.Error,
      message: "Invalid Format",
      timeout: 3000,
    });

    return isInt ? "1" : "0.00";
  };

  const onChangeDiscount = (value, index, childIndex = null) => {
    const priceRegex = new RegExp(/^\d+(\.\d{2})?%?$|^[0-9]+%?$/);
    if (priceRegex.test(value)) {
      if (value.endsWith("%")) {
        if (childIndex != null) {
          setValue(
            `form_items[${index}].FormItemChildren[${childIndex}].discount`,
            value,
            {
              shouldDirty: true,
            }
          );
        } else {
          setValue(`form_items[${index}].discount`, value, {
            shouldDirty: true,
          });
        }
        return value;
      }

      const result = parseFloat(value) ? parseFloat(value).toFixed(2) : value;
      if (childIndex != null) {
        setValue(
          `form_items[${index}].FormItemChildren[${childIndex}].discount`,
          result,
          {
            shouldDirty: true,
          }
        );
      } else {
        setValue(`form_items[${index}].discount`, result, {
          shouldDirty: true,
        });
      }
      return result;
    }
    callSnack({
      type: SnackType.Error,
      message: "Invalid Format",
      timeout: 3000,
    });
    return "0.00";
  };

  const onFocusDiscount = (index) => {
    setFormItemFocus(index);
  };

  const combineFloatValue = (a, b) => {
    const value1 = parseFloat(a !== null && a !== "" ? a : "0.00");
    const value2 = parseFloat(b);

    return (value1 + value2).toFixed(2);
  };

  const getProduct = (value) => {
    const product = products.find((product) => product.product_id === value);
    return product ? product : null;
  };

  // const defaultAppendItem = (appendChild, newItemChild) => {
  //   appendChild(newItemChild);
  // };

  const productOnChange = (payload, i) => {
    if (payload.target) {
      const onChangedProduct = getProduct(payload.target.value);
      const product = onChangedProduct ? onChangedProduct.name : "";
      const productUomList = onChangedProduct
        ? onChangedProduct.ProductUomList
        : [];
      const setProductUomId = (productUom) => {
        const gotDefaultUom = productUom.find((item) => {
          if (productAmountKey === "purchase_price") {
            return item.active_purchase;
          } else {
            return item.active_sale;
          }
        });
        if (gotDefaultUom) {
          return gotDefaultUom;
        }
        return productUom[0];
      };

      const currentProductUom =
        productUomList.length > 0 ? setProductUomId(productUomList) : null;

      setValue(`form_items[${i}].descriptions`, product, {
        shouldDirty: true,
      });
      if (currentProductUom) {
        setValue(
          `form_items[${i}].product_uom_id`,
          currentProductUom.product_uom_id,
          {
            shouldDirty: true,
          }
        );
        setValue(
          `form_items[${i}].amount`,
          currentProductUom[productAmountKey],
          {
            shouldDirty: true,
          }
        );
        setValue(
          `form_items[${i}].total_amount`,
          currentProductUom[productAmountKey],
          {
            shouldDirty: true,
          }
        );
        // setValue(`form_items[${i}].product_uom_id`, productUomId, {
        //   shouldDirty: true,
        // });
      }
    }
  };

  const gridRowsRenderer =
    fields.length === 0 ? (
      <TableNoItemsOverlay />
    ) : (
      fields.map((data, index) => {
        const {
          type = null,
          form_item_id = null,
          transferred_transaction_name = null,
        } = data;
        const itemTotalAmount =
          formItemsWatcher && formItemsWatcher[index]?.total_amount;
        const productList =
          formItemsWatcher[index] && formItemsWatcher[index]?.FormItemChildren;
        const isBundleType = productList ? productList.length > 0 : false;

        const formItemProduct = getProduct(formItemsWatcher[index]?.product_id);
        const formItemUom = formItemProduct
          ? formItemProduct.ProductUomList.map((item) => {
              const {
                uom,
                product_uom_id,
                sale_price,
                purchase_price,
                active_sale,
                active_purchase,
              } = item;
              return {
                label: uom,
                value: product_uom_id,
                sale_price,
                purchase_price,
                active_sale,
                active_purchase,
              };
            })
          : [];

        const isTransferredItem =
          transferred_transaction_name !== null ? true : false;
        const transferredBackgroundColor = isTransferredItem
          ? {
              backgroundColor: "rgba(223, 255, 243, 0.5)",
            }
          : {};
        if (type === null) {
          return (
            <Stack key={data.id} sx={transferredBackgroundColor}>
              <Grid container>
                {numberingCell(index)}
                <Grid
                  item
                  xs={3}
                  sm={3}
                  sx={{ paddingRight: "10px", marginTop: "20px" }}
                >
                  <FormInputDropdownSearch
                    control={control}
                    options={productOptions}
                    name={`form_items[${index}].product_id`}
                    label={"Select Product"}
                    variant={"filled"}
                    placeholder={"Select product"}
                    isDisabled={isTransferredItem}
                    formOnChange={(payload) => {
                      productOnChange(payload, index);
                    }}
                    getOptionDisabled={() => form_item_id !== null}
                  />
                </Grid>
                <Grid
                  item
                  xs={2}
                  sm={2}
                  sx={{ paddingRight: "10px", marginTop: "20px" }}
                >
                  {isBundleType ? (
                    <></>
                  ) : (
                    <FormInputDropdownSearch
                      control={control}
                      options={accountOptions}
                      name={`form_items[${index}].account_id`}
                      label={"Select Account"}
                      required
                      groupBy={(option) => option.group}
                      rules={{
                        required: validatorRequired(),
                      }}
                      disabledErrorText={true}
                      variant={"filled"}
                    />
                  )}
                </Grid>

                <Grid
                  item
                  xs={true}
                  sm={true}
                  sx={{ paddingRight: "10px", marginTop: "20px" }}
                >
                  <Stack direction="row">
                    {isBundleType || formItemUom.length === 0 ? (
                      <></>
                    ) : (
                      <FormInputSelect
                        control={control}
                        name={`form_items[${index}].product_uom_id`}
                        isDisabled={isTransferredItem}
                        menuItems={formItemUom}
                        // formOnChange={(change) => {
                        // }}
                      />
                    )}
                    <FormInputText
                      control={control}
                      type={"number"}
                      name={`form_items[${index}].quantity`}
                      label={"Quantity"}
                      variant={"filled"}
                      isDisabled={isBundleType || isTransferredItem}
                      formOnBlur={(value) => {
                        const amount = parseFloat(
                          formItemsWatcher[index].amount
                            ? formItemsWatcher[index].amount
                            : "0.00"
                        );

                        const totalAmount = value * amount;
                        return onChangeParseFloat(
                          value,
                          totalAmount,
                          index,
                          true
                        );
                      }}
                    />
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={true}
                  sm={true}
                  sx={{
                    paddingRight: "10px",
                    alignItems: "center",
                    display: "flex",
                    textAlign: "center",
                    marginTop: "20px",
                  }}
                >
                  {isBundleType ? (
                    productBundleChildrenTotalAmountText(index)
                  ) : (
                    <FormInputText
                      control={control}
                      type={"number"}
                      name={`form_items[${index}].amount`}
                      isDisabled={isBundleType || isTransferredItem}
                      required
                      rules={{
                        required: validatorRequired(),
                      }}
                      label={"Unit Price"}
                      variant={"filled"}
                      formOnBlur={(value) => {
                        const quantity = formItemsWatcher[index].quantity
                          ? formItemsWatcher[index].quantity
                          : 1;

                        const totalAmount = value * quantity;
                        return onChangeParseFloat(value, totalAmount, index);
                      }}
                    />
                  )}
                </Grid>

                <Grid
                  item
                  xs={true}
                  sm={true}
                  sx={{ paddingRight: "10px", marginTop: "20px" }}
                >
                  <Box
                    sx={{
                      paddingRight: "10px",
                      alignItems: "center",
                      textAlign: "center",

                      display: "flex",
                      height: "100%",
                    }}
                  >
                    {isBundleType
                      ? productBundleChildrenTotalAmountText(index, true)
                      : plainText(
                          itemTotalAmount ? itemTotalAmount : "0.00",
                          true
                        )}
                  </Box>
                </Grid>

                <Grid
                  item
                  xs={true}
                  sm={true}
                  sx={{
                    paddingRight: "10px",
                    alignItems: "center",
                    display: "flex",
                    textAlign: "center",
                    marginTop: "20px",
                  }}
                >
                  {isBundleType ? (
                    productBundleChildrenTotalDiscount(productList)
                  ) : (
                    <FormInputText
                      control={control}
                      type={"string"}
                      name={`form_items[${index}].discount`}
                      isDisabled={isBundleType}
                      // required
                      rules={{
                        pattern: validatorDiscount(),
                      }}
                      placeholder={"Amt or %"}
                      variant={"filled"}
                      formOnBlur={(value) => {
                        setFormItemFocus(null);
                        return onChangeDiscount(value, index);
                      }}
                      formOnFocus={() => {
                        onFocusDiscount(
                          index
                          // formItemsWatcher[index].discount_percent,
                        );
                      }}
                      formValue={formItemFocusedValue(index)}
                    />
                  )}
                </Grid>
                <Grid
                  item
                  xs={true}
                  sm={true}
                  sx={{
                    paddingRight: "10px",
                    alignItems: "center",
                    display: "flex",
                    textAlign: "center",
                    marginTop: "20px",
                  }}
                >
                  {isBundleType ? (
                    plainText(
                      productBundleChildrenTotalTax(productList).toFixed(2)
                    )
                  ) : (
                    <FormInputDropdownSearch
                      control={control}
                      options={taxList}
                      name={`form_items[${index}].tax`}
                      // required
                      // rules={{
                      //   required: validatorRequired(),
                      // }}
                      getOptionDisabled={() => !watchActiveTax}
                      placeholder={"Select Tax"}
                      variant={"filled"}
                    />
                  )}
                </Grid>

                <Box
                  sx={{
                    alignSelf: "center",
                    textAlign: "center",
                    marginTop: "20px",
                  }}
                  width={"35px"}
                >
                  <IconButton
                    onClick={() => {
                      handleRemoveNewOptions(index, isTransferredItem);
                    }}
                    sx={({ icons, palette }) => ({
                      ...icons.standard.default,
                      color: palette.icon.delete,
                      "&:hover": {
                        color: palette.icon.delete,
                      },
                    })}
                  >
                    <DeleteOutlineRounded fontSize="inherit" />
                  </IconButton>
                </Box>
              </Grid>
              <Grid key={`description_${data.id}`} container>
                <Box width={"35px"}></Box>
                <Grid
                  item
                  xs={3}
                  sm={3}
                  sx={{
                    paddingRight: "10px",
                    marginTop: "20px",
                    marginBottom: "30px",
                  }}
                >
                  <FormInputText
                    control={control}
                    type={"string"}
                    name={`form_items[${index}].descriptions`}
                    required
                    rules={{
                      required: validatorRequired(),
                    }}
                    disabledErrorText={true}
                    label={"Description"}
                    placeholder={"Enter Description"}
                    variant={"filled"}
                  />
                </Grid>
                {isTransferredItem ? (
                  <Grid
                    item
                    xs={true}
                    sm={true}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      margin: "0px 10px",
                    }}
                  >
                    <ChipText
                      label={transferred_transaction_name}
                      status={ChipStatus.Success}
                    />
                  </Grid>
                ) : (
                  <></>
                )}
              </Grid>
              {ItemChildrenFormComponent(index, removeItem, data)}
            </Stack>
          );
        }

        if (type === "subtitle") {
          return (
            <Grid sx={{ marginBottom: "30px" }} key={data.id} container>
              {numberingCell(index)}
              <Grid
                item
                xs={11}
                sm={11}
                sx={{
                  paddingRight: "10px",
                  marginTop: "15px",
                }}
              >
                <FormInputText
                  control={control}
                  type={"string"}
                  name={`form_items[${index}].descriptions`}
                  // required
                  // rules={{
                  //   required: validatorRequired(),
                  // }}
                  variant={"filled"}
                  placeholder={"Enter Description"}
                  label={"Description"}
                  required
                  // formOnChange={onChangeParseFloat}
                />
              </Grid>
              <Grid item xs={true} sm={true}></Grid>
              {deleteButton(index)}
            </Grid>
          );
        }

        if (type === "subtotal") {
          const slicedFormItems = formItemsWatcher.slice(0, index);
          let result = {
            quantity: "0.00",
            total_amount: "0.00",
            discount: "0.00",
            tax: null,
            tax_amount: "0.00",
          };
          for (let i = slicedFormItems.length - 1; i >= 0; i--) {
            if (slicedFormItems[i].type !== null) {
              break;
            }
            const { quantity, total_amount, discount, tax_amount } = result;
            const value = slicedFormItems[i];
            const tax =
              value.tax === "" || value.tax === null ? 0 : parseInt(value.tax);
            const totalAmount = parseFloat(value.total_amount);
            const taxNumerator = watchTaxInclusive ? 100 : 100 + tax;
            const taxPercentage =
              taxNumerator / (watchTaxInclusive ? 100 + tax : 100);
            const amountWithTax = totalAmount * taxPercentage;
            const taxAmount = watchTaxInclusive
              ? totalAmount - amountWithTax
              : amountWithTax - totalAmount;
            result = {
              quantity: combineFloatValue(value.quantity, quantity),
              total_amount: combineFloatValue(value.total_amount, total_amount),
              discount: combineFloatValue(value.discount, discount),
              tax_amount: combineFloatValue(taxAmount, tax_amount),
            };
          }

          return (
            <Grid
              key={data.id}
              container
              sx={{
                height: "35px",
                alignItems: "center",
                marginBottom: "30px",
              }}
            >
              {numberingCell(index)}
              <Grid
                item
                xs={3}
                sm={3}
                sx={{ paddingRight: "10px", marginTop: "20px" }}
              >
                {plainText("Subtotal", true)}
              </Grid>
              <Grid item xs={2} sm={2}></Grid>
              <Grid
                item
                xs={true}
                sm={true}
                sx={{ paddingLeft: "15px", marginTop: "20px" }}
              >
                {plainText(result.quantity)}
              </Grid>
              <Grid item xs={true} sm={true}></Grid>
              <Grid
                item
                xs={true}
                sm={true}
                sx={{ paddingLeft: "15px", marginTop: "20px" }}
              >
                {plainText(result.total_amount)}
              </Grid>
              <Grid
                item
                xs={true}
                sm={true}
                sx={{ paddingLeft: "15px", marginTop: "20px" }}
              >
                {plainText(result.discount)}
              </Grid>
              <Grid
                item
                xs={true}
                sm={true}
                sx={{ paddingLeft: "15px", marginTop: "20px" }}
              >
                {plainText(result.tax_amount)}
              </Grid>
              {deleteButton(index)}
            </Grid>
          );
        }
        return <></>;
      })
    );

  const overallSubTotal = formItemsWatcher
    ? formItemsWatcher.reduce((total, value) => {
        if (value.FormItemChildren.length > 0) {
          const productListSubTotal = value.FormItemChildren.reduce(
            (acc, item) => {
              const totalAmount =
                parseFloat(item.amount) * parseFloat(item.quantity);
              return acc + taxValueConverter(item.tax, totalAmount);
            },
            0
          );

          return total + productListSubTotal;
        } else {
          const totalAmount =
            parseFloat(value.amount) * parseFloat(value.quantity);
          if (watchTaxInclusive) {
            const taxValue = parseInt(value.tax)
              ? parseInt(value.tax)
              : value.tax;

            const taxPercentage = (taxValue + 100) / 100;
            const inputAmount = totalAmount;
            const subAmount = inputAmount / taxPercentage;
            return total + parseFloat(subAmount);
          }
          return total + totalAmount;
        }
      }, 0.0)
    : 0.0;

  const overallDiscountTotal = formItemsWatcher
    ? formItemsWatcher.reduce((total, value) => {
        if (value.FormItemChildren.length > 0) {
          const productListTotalDiscount = value.FormItemChildren.reduce(
            (total, item) => {
              return (
                total +
                discountValueConverter(item.amount, item.discount) *
                  item.quantity
              );
            },
            0
          );
          return total + productListTotalDiscount;
        } else {
          return total + discountValueConverter(value.amount, value.discount);

          // if (value.discount === null) {
          //   return total;
          // }
          // if (value.discount.endsWith("%")) {
          //   const discountAmount =
          //     (parseFloat(value.total_amount) *
          //       parseFloat(value.discount.replace(/%$/, ""))) /
          //     100;
          //   return total + discountAmount;
          // }

          // return total + parseFloat(value.discount);
        }
      }, 0.0)
    : 0.0;

  const overallTaxTotal = formItemsWatcher
    ? formItemsWatcher.reduce((total, value) => {
        if (value.FormItemChildren.length > 0) {
          const productListOVerallTax = value.FormItemChildren.reduce(
            (total, item) => {
              const tax =
                item.tax === null || item.tax === "" ? 0 : parseFloat(item.tax);
              const totalAmount = item.quantity * item.amount;
              return total + totalTaxValueConverter(tax, totalAmount);
            },
            0
          );
          return total + productListOVerallTax;
        } else {
          const tax =
            value.tax === null || value.tax === "" ? 0 : parseFloat(value.tax);
          const totalAmount = value.quantity * value.amount;
          return total + totalTaxValueConverter(tax, totalAmount);
        }
        // if (value.tax === null || value.tax === "") {
        //   return total;
        // }

        // const taxNumerator = watchTaxInclusive
        //   ? 100
        //   : 100 + parseFloat(value.tax);
        // const taxPercentage =
        //   taxNumerator /
        //   (watchTaxInclusive ? 100 + parseFloat(value.tax) : 100);
        // const inputAmount = value.total_amount;

        // const amountWithTax = inputAmount * taxPercentage;

        // const taxAmount = watchTaxInclusive
        //   ? inputAmount - amountWithTax
        //   : amountWithTax - inputAmount;

        // return total + parseFloat(taxAmount);
      }, 0.0)
    : 0.0;

  const overallTotal = overallSubTotal - overallDiscountTotal + overallTaxTotal;

  useEffect(() => {
    setValue(`form_items_total`, overallTotal, {
      shouldDirty: false,
    });
  }, [overallTotal]);
  // const overallValueObject = useMemo(()=>{

  // },[formItemsWatcher]);
  return (
    <Stack component={"div"}>
      <Stack
        sx={{
          marginTop: "20px",
          paddingLeft: "30px"
        }}
        spacing={2}
      >
        <Stack
          direction={"row"}
          sx={{
            justifyContent: "end",
            gap: "10px",
          }}
        >
          {plainText("Tax inclusive")}
          <FormInputSwitch control={control} name={"tax_inclusive"} />
        </Stack>

        <Box sx={{ borderBottom: "1px solid #E6E6E6" }}>
          {gridHeaderRenderer(
            TableContent[TABLE.TRANSACTIONS.FORM_ITEMS].columns()
          )}
          {gridRowsRenderer}
        </Box>
        <Stack direction={"row"} sx={{ justifyContent: "space-between" }}>
          <Stack
            sx={{
              gap: "10px",
              marginTop: "20px",
            }}
          >
            <Stack direction={"row"} spacing={2}>
              {addButton("Item", handleSubmitNewOptions)}
              {addButton("Subtitle", handleSubmitSubtitle)}
              {addButton("Subtotal", handleSubmitSubtotal)}
            </Stack>
            {transferTransactionTypeList ? manageTransferButton() : <></>}
          </Stack>

          {/* {addTransferButton()} */}
          <Box
            width={0.4}
            sx={{
              border: 1,
              borderColor: "#1C253414",
              boxShadow: 2,
              marginTop: "20px",
            }}
          >
            <Grid
              container
              spacing={2}
              width={1}
              sx={{ justifyContent: "end", marginTop: "10px" }}
            >
              <Box
                width={0.9}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding: "20px",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      flexGrow: 1,
                      // gap: "5.75rem",
                    }}
                  >
                    <Stack
                      sx={{
                        flexDirection: "row",
                        alignItems: "center",
                        minWidth: "10rem",
                        justifyContent: "flex-start",
                      }}
                      component="div"
                    >
                      {plainText("Sub Total")}
                    </Stack>
                    <Stack
                      sx={{
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                      component="div"
                    >
                      {plainText("RM")}
                    </Stack>
                  </Box>
                  <Stack
                    component="div"
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    {priceText(overallSubTotal.toFixed(2))}
                  </Stack>
                </Box>
                {/* Discount Given Row */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding: "20px",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      flexGrow: 1,
                      // gap: "5.75rem",
                    }}
                  >
                    <Stack
                      sx={{
                        flexDirection: "row",
                        alignItems: "center",
                        minWidth: "10rem",
                        justifyContent: "flex-start",
                      }}
                      component="div"
                    >
                      {plainText("Discount Given")}
                    </Stack>
                    <Stack
                      sx={{
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                      component="div"
                    >
                      {plainText("RM")}
                    </Stack>
                  </Box>
                  <Stack
                    component="div"
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    {priceText(`-${overallDiscountTotal.toFixed(2)}`)}
                  </Stack>
                </Box>
                {/* Sales Tax Row */}
                {overallTaxTotal > 0 && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      padding: "20px",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        flexGrow: 1,
                        // gap: "5.75rem",
                      }}
                    >
                      <Stack
                        sx={{
                          flexDirection: "row",
                          alignItems: "center",
                          minWidth: "10rem",
                          justifyContent: "flex-start",
                        }}
                        component="div"
                      >
                        {plainText("Sales Tax")}
                      </Stack>
                      <Stack
                        sx={{
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                        component="div"
                      >
                        {plainText("RM")}
                      </Stack>
                    </Box>
                    <Stack
                      component="div"
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                      }}
                    >
                      {priceText(overallTaxTotal.toFixed(2))}
                    </Stack>
                  </Box>
                )}
                {/* Total Row */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding: "20px",
                    alignItems: "center",
                    borderTop: 1,
                    borderColor: "#E6E8E6",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      flexGrow: 1,
                      // gap: "5.75rem",
                    }}
                  >
                    <Stack
                      sx={{
                        flexDirection: "row",
                        alignItems: "center",
                        minWidth: "10rem",
                        justifyContent: "flex-start",
                      }}
                      component="div"
                    >
                      {overallTotalText("Total")}
                    </Stack>
                    <Stack
                      sx={{
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                      component="div"
                    >
                      {overallTotalText("RM")}
                    </Stack>
                  </Box>
                  <Stack
                    component="div"
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    {overallTotalText(overallTotal.toFixed(2))}
                  </Stack>
                </Box>
              </Box>
            </Grid>
          </Box>
        </Stack>
        </Stack>
        {is_invoice ? <FormLayoutTitle title={"Payment Terms"} /> : null}
        <Stack paddingLeft={"30px"}>
        {is_invoice ? (
          <Box component="div" width={1}>
            <FormLayoutTitle title={"Payment Terms"} />
            {
              <PaymentTermForm
                control={control}
                watch={watch}
                setValue={setValue}
                getValues={getValues}
                options={payment_terms}
                activeTaxKey={activeTaxKey}
                totalValue={overallTotal}
              />
            }
          </Box>
        ) : null}
      </Stack>
    </Stack>
  );
};

ItemsForm.propTypes = {
  control: PropTypes.any,
  watch: PropTypes.any,
  setValue: PropTypes.any,
  activeTaxKey: PropTypes.any,
  is_invoice: PropTypes.any,
  getValues: PropTypes.any,
  options: PropTypes.any,
  companySetting: PropTypes.any,
  isPurchase: PropTypes.any,
  transferTransactionTypeList: PropTypes.any,
};
