import { addSnack, clearSnack } from "../stores/slices/snackSlice";
import { useAppDispatch } from "./storeHooks";

const useSnack = () => {
  const dispatch = useAppDispatch();

  const callSnack = (snack) => {
    dispatch(addSnack(snack));
  };

  const hideSnack = () => {
    dispatch(clearSnack());
  };

  return { callSnack, hideSnack };
};

export default useSnack;
