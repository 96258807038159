import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { IconButton, Box, Grid, Typography } from "@mui/material";
import { FormInputText, FormInputDropdownSearch } from "../../../components/FormInput";
import { validatorRequired, validatorDiscount } from "../../../util/validators";
// import { forms } from "@styles/theme";
import { useFieldArray, useWatch } from "react-hook-form";

import { DeleteOutlineRounded } from "@mui/icons-material";
import { SnackType } from "../../../enums/Snacks";
import { transactionTax } from "../../../enums/TransactionTax";
import useSnack from "../../../hooks/useSnack";

export const ItemChildrenForm = ({
  control,
  watch,
  setValue,
  options,
  companySetting = {},
  formItemIndex,
  products,
  activeTaxKey,
  removeParentFunction,
  productAmountKey,
  // removeItem,
  // setRemoveItem,
  // handleSubmit,
  // formItemChild,
}) => {
  //* const function section

  const { callSnack } = useSnack();

  const { productOptions = [], accountOptions = [] } = options;

  const { fields, append, remove } = useFieldArray({
    control,
    name: `form_items[${formItemIndex}].FormItemChildren`,
  });

  const watchActiveTax = watch("active_tax");

  const taxList = transactionTax[activeTaxKey];

  // const defaultValues = {
  //   index: 0,
  //   type: null,
  //   product_id: null,
  //   descriptions: "",
  //   account_id: companySetting.account ? companySetting.account : null,
  //   quantity: 1,
  //   amount: "0.00",
  //   total_amount: "0.00",
  //   discount: "0.00",
  //   tax: null,
  // };
  // const { handleSubmit } = useForm({
  //   defaultValues: defaultValues,
  //   shouldFocusError: true,
  //   shouldUnregister: false,
  // });

  const formItemChildrenWatcher = useWatch({
    control,
    name: `form_items[${formItemIndex}].FormItemChildren`,
  });

  const formItemProductWatcher = useWatch({
    control,
    name: `form_items[${formItemIndex}].product_id`,
  });
  const getProduct = (value) => {
    const product = products.find((product) => product.product_id === value);
    return product ? product : null;
  };
  const watchFormItemProductId = watch(
    `form_items[${formItemIndex}].form_item_id`
  );

  useEffect(() => {
    if (
      formItemProductWatcher !== undefined &&
      (watchFormItemProductId == undefined || watchFormItemProductId == null)
    ) {
      const onChangedProduct = getProduct(formItemProductWatcher);
      const watchFormItemChildren = watch(
        `form_items[${formItemIndex}].FormItemChildren`
      );
      if (
        onChangedProduct !== null &&
        onChangedProduct.type === "Bundle" &&
        watchFormItemChildren.length === 0
      ) {
        onChangedProduct.product_ids.forEach((id, index) => {
          const productBundleChild = products.find((p) => p.product_id === id);
          const { [productAmountKey]: amount, name } = productBundleChild;
          const defaultAccountId = companySetting.account;
          const newItemChild = {
            product_id: id,
            account_id: defaultAccountId,
            quantity: 1,
            amount: amount,
            descriptions: name,
            index: index,
          };
          append(newItemChild);

          // handleSubmit(handleAddBundleChild);
        });
      }
    }
  }, [formItemProductWatcher]);

  // useEffect(() => {
  //   console.log("removeitem", removeItem);
  //   if (removeItem != null && removeItem === formItemIndex) {
  //     remove();
  //     setRemoveItem(null);
  //     console.log("after reove item", formItemChildrenWatcher);
  //   }
  // }, [removeItem]);
  const handleRemoveProductBundleChild = (index) => {
    if (formItemChildrenWatcher.length == 1) {
      remove(index);
      removeParentFunction(formItemIndex);
    } else {
      remove(index);
    }
  };

  //* component section

  const plainText = (text, isBold = false) => {
    return (
      <Typography
        sx={({ typography }) => ({
          ...typography[isBold ? "bodyTextBold" : "bodyText"],
        })}
      >
        {text}
      </Typography>
    );
  };

  const onChangeParseFloat = (
    value,

    isInt = false
  ) => {
    const priceRegex = new RegExp(/^-?(0(\.\d+)?|[1-9]\d*(\.\d+)?)$/);
    if (priceRegex.test(value)) {
      const result = isInt ? value : parseFloat(value).toFixed(2);
      // if (childIndex) {
      //   setValue(
      //     `form_items[${index}].FormItemChildren[${childIndex}].total_amount`,
      //     parseFloat(totalAmount).toFixed(2),
      //     { shouldDirty: true, shouldValidate: true }
      //   );
      // } else {
      //   setValue(
      //     `form_items[${index}].total_amount`,
      //     parseFloat(totalAmount).toFixed(2),
      //     { shouldDirty: true, shouldValidate: true }
      //   );
      // }

      return result;
    }
    callSnack({
      type: SnackType.Error,
      message: "Invalid Format",
      timeout: 3000,
    });

    return isInt ? "1" : "0.00";
  };

  const onChangeDiscount = (value, index, childIndex = null) => {
    const priceRegex = new RegExp(/^\d+(\.\d{2})?%?$|^[0-9]+%?$/);
    if (priceRegex.test(value)) {
      if (value.endsWith("%")) {
        if (childIndex != null) {
          setValue(
            `form_items[${index}].FormItemChildren[${childIndex}].discount`,
            value,
            {
              shouldDirty: true,
            }
          );
        } else {
          // setValue(`form_items[${index}].discount`, value, {
          //   shouldDirty: true,
          // });
        }
        return value;
      }

      const result = parseFloat(value) ? parseFloat(value).toFixed(2) : value;
      if (childIndex != null) {
        setValue(
          `form_items[${index}].FormItemChildren[${childIndex}].discount`,
          result,
          {
            shouldDirty: true,
          }
        );
      } else {
        // setValue(`form_items[${index}].discount`, result, {
        //   shouldDirty: true,
        // });
      }

      return result;
    }
    callSnack({
      type: SnackType.Error,
      message: "Invalid Format",
      timeout: 3000,
    });
    return "0.00";
  };

  const onChangeProductBundleChild = (payload, formItemIndex, index) => {
    if (payload.target) {
      const onChangedProduct = getProduct(payload.target.value);
      const product = onChangedProduct ? onChangedProduct.name : "";
      setValue(
        `form_items[${formItemIndex}].FormItemChildren[${index}].descriptions`,
        product,
        {
          shouldDirty: true,
        }
      );
    }
  };

  return fields.length === 0 ? (
    <></>
  ) : (
    fields.map((data, index) => {
      // const productList =
      //   formItemChildrenWatcher[index] && formItemChildrenWatcher[index]?.FormItemChildren;
      // const isBundleType = productList ? productList.length > 0 : false;
      const currentProductBundleChild =
        formItemChildrenWatcher && formItemChildrenWatcher[index];
      const itemTotalAmount = currentProductBundleChild
        ? parseFloat(currentProductBundleChild.quantity) *
          parseFloat(currentProductBundleChild.amount)
        : 0;
      return (
        <div key={data.id}>
          <Grid sx={{ borderLeft: "5px solid green" }} container>
            <Box width={"35px"}></Box>
            <Grid item xs={3} sm={3} sx={{ paddingRight: "10px" }}>
              <FormInputDropdownSearch
                control={control}
                options={productOptions}
                name={`form_items[${formItemIndex}].FormItemChildren[${index}].product_id`}
                variant={"standard"}
                placeholder={"Select product"}
                formOnChange={(payload) => {
                  onChangeProductBundleChild(payload, formItemIndex, index);
                }}
                getOptionDisabled={() => true}
              />
            </Grid>

            <Grid item xs={2} sm={2} sx={{ paddingRight: "10px" }}>
              <FormInputDropdownSearch
                control={control}
                options={accountOptions}
                name={`form_items[${formItemIndex}].FormItemChildren[${index}].account_id`}
                required
                groupBy={(option) => option.group}
                rules={{
                  required: validatorRequired(),
                }}
                disabledErrorText={true}
                variant={"standard"}
              />
            </Grid>

            <Grid item xs={true} sm={true} sx={{ paddingRight: "10px" }}>
              <FormInputText
                control={control}
                type={"number"}
                name={`form_items[${formItemIndex}].FormItemChildren[${index}].quantity`}
                variant={"standard"}
                formOnBlur={(value) => {
                  const amount = parseFloat(
                    formItemChildrenWatcher[formItemIndex].FormItemChildren[
                      index
                    ]
                      ? formItemChildrenWatcher[formItemIndex].FormItemChildren[
                          index
                        ].amount
                      : "0.00"
                  );

                  const totalAmount = value * amount;
                  null;
                  return onChangeParseFloat(
                    value,
                    totalAmount,
                    formItemIndex,
                    true,
                    index
                  );
                }}
              />
            </Grid>
            <Grid item xs={true} sm={true} sx={{ paddingRight: "10px" }}>
              <FormInputText
                control={control}
                type={"number"}
                name={`form_items[${formItemIndex}].FormItemChildren[${index}].amount`}
                required
                rules={{
                  required: validatorRequired(),
                }}
                placeholder={"Unit Price"}
                variant={"standard"}
                formOnBlur={(value) => {
                  const quantity = formItemChildrenWatcher[formItemIndex]
                    .FormItemChildren[index]
                    ? formItemChildrenWatcher[formItemIndex].FormItemChildren[
                        index
                      ].quantity
                    : 1;

                  const totalAmount = value * quantity;
                  return onChangeParseFloat(
                    value,
                    totalAmount,
                    formItemIndex,
                    false,
                    index
                  );
                }}
              />
            </Grid>

            <Grid item xs={true} sm={true} sx={{ paddingRight: "10px" }}>
              <Box
                sx={{
                  paddingBottom: "10px",
                  alignItems: "center",
                  display: "flex",
                  height: "100%",
                }}
              >
                {plainText(
                  itemTotalAmount ? itemTotalAmount.toFixed(2) : "0.00",
                  true
                )}
              </Box>
            </Grid>

            <Grid item xs={true} sm={true} sx={{ paddingRight: "10px" }}>
              <FormInputText
                control={control}
                type={"string"}
                name={`form_items[${formItemIndex}].FormItemChildren[${index}].discount`}
                // required
                rules={{
                  pattern: validatorDiscount(),
                }}
                placeholder={"Amt or %"}
                variant={"standard"}
                formOnBlur={(value) => {
                  return onChangeDiscount(value, formItemIndex, index);
                }}
              />
            </Grid>
            <Grid item xs={true} sm={true} sx={{ paddingRight: "10px" }}>
              <FormInputDropdownSearch
                control={control}
                options={taxList}
                name={`form_items[${formItemIndex}].FormItemChildren[${index}].tax`}
                // required
                // rules={{
                //   required: validatorRequired(),
                // }}
                getOptionDisabled={() => !watchActiveTax}
                placeholder={"Select Tax"}
                variant={"standard"}
              />
            </Grid>

            <Box
              sx={{ alignSelf: "center", textAlign: "center" }}
              width={"35px"}
            >
              <IconButton
                onClick={() => {
                  handleRemoveProductBundleChild(index);
                }}
                sx={({ icons }) => ({
                  ...icons.standard.default,
                })}
              >
                <DeleteOutlineRounded fontSize="inherit" />
              </IconButton>
            </Box>
          </Grid>

          <Grid key={`product_bundle_child_descriptions${index}`} container>
            <Box width={"35px"}></Box>
            <Grid item xs={3} sm={3} sx={{ paddingRight: "10px" }}>
              <FormInputText
                control={control}
                type={"string"}
                name={`form_items[${formItemIndex}].FormItemChildren[${index}].descriptions`}
                required
                rules={{
                  required: validatorRequired(),
                }}
                disabledErrorText={true}
                placeholder={"descriptions"}
                variant={"standard"}
              />
            </Grid>
          </Grid>
        </div>
      );
    })
  );
};

ItemChildrenForm.propTypes = {
  control: PropTypes.any,
  watch: PropTypes.any,
  setValue: PropTypes.any,
  activeTaxKey: PropTypes.any,
  is_invoice: PropTypes.any,
  getValues: PropTypes.any,
  options: PropTypes.any,
  companySetting: PropTypes.any,
  isPurchase: PropTypes.any,
  removeParentFunction: PropTypes.any,
  products: PropTypes.any,
  removeItem: PropTypes.any,
  productAmountKey: PropTypes.any,
  handleSubmit: PropTypes.any,
  formItemChild: PropTypes.any,
  formItemIndex: PropTypes.any,
};
