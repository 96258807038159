import React, { useState, useEffect, useRef } from 'react';
import './Sidebar.scss';
import {
  useNavigate,
  useSearchParams,
  useLocation
} from "react-router-dom";
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
} from 'cdbreact';
import { MdArrowDownward, MdArrowDropDown, MdArrowRight, MdOutlineArrowDownward, MdTimelapse } from "react-icons/md"
import { AiFillCaretRight, AiFillSetting, AiOutlineClose  } from 'react-icons/ai'
import { TbBusinessplan } from 'react-icons/tb';
import { useDispatch } from 'react-redux';

import { Modal } from "bootstrap";
import plan from '../enums/json/plan.json';
import moment from 'moment';
import { removeFromLocalStorage } from '../util/LocalStorage';

//slices
import { selectCurrSelectedTab, setCurrSelectedTab } from '../stores/slices/systemSlices';
import { selectCompanyID, setCompanyID } from '../stores/slices/companyIDSlices';
import { retrieveCurrRoles, selectCurrRoles } from '../stores/slices/roleSlices';
import { selectCurrSa, selectCurrTenant } from '../stores/slices/persistRolesSlices';
import { selectTenant } from '../stores/slices/tenantSlices';
import { selectCompanyContext, selectCurrUserContext } from '../stores/slices/companySlices';
import { HiArrowTrendingUp, HiCalendar, HiChartBar, HiCurrencyDollar, HiDocument, HiDocumentCheck, HiHome, HiOutlineCalendar, HiOutlineChartBar, HiOutlineDocument, HiOutlineDocumentCheck, HiOutlineHome, HiOutlineUser, HiUser } from "react-icons/hi2";
import { Invoicing } from '../enums/Invoicing';
import { onValue, ref } from 'firebase/database';
import { db } from '../firebaseInit';
import { selectUser } from '../stores/slices/userAuthSlices';

function Sidebar() {

  const planModal = useRef(null);
  const trialEndModal = useRef(null);
  const settingsRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = selectUser();
  const { companyList } = selectCompanyContext();
  const { currPlan, currUsageDetails } = selectCurrUserContext();
  const [zumaxUsers, setZumaxUsers] = useState([]);
  
  const showTrialEndModalFunc = () => {
    const modalEle = trialEndModal.current;
    const bsModal = new Modal(modalEle, {
      backdrop: "static",
      keyboard: false,
    });
    bsModal.show();
  };

  const hideTrialEndModalFunc = () => {
      const modalEle = trialEndModal.current;
      const bsModal = Modal.getInstance(modalEle);
      bsModal.hide();
  };

  useEffect(()=>{
    const zumaxUsersRef = ref(db, 'zumaxUsers');
    onValue(zumaxUsersRef, async (snapshot) => {

        if(snapshot.exists()){
            const zumaxUsersList = snapshot.val();
            setZumaxUsers(zumaxUsersList);
          
        }
    });
  },[])
  
  useEffect(() => {
    
    if(currUsageDetails?.plan !== ""){
      const currentDate = moment(new Date()); // Get the current date
      const endDate = currUsageDetails?.expiryDate;
      const duration = moment.duration(moment(endDate).diff(currentDate));
      if (duration.asMilliseconds() <= 0 && currUsageDetails !== null) {
        // Trial is finished
        showTrialEndModalFunc();
      }
    }

  },[currUsageDetails])

  const currSelectedTab = selectCurrSelectedTab();
  const currSa = selectCurrSa();
  const currRoles = selectCurrRoles();
  const companyID = selectCompanyID();
  const tenant = selectCurrTenant();

  const [currSaState, setCurrSaState] = useState(false);
  const [currRolesState, setCurrRolesState] = useState(null);
  const [showSetting, setShowSetting] = useState(false);

  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [sectionTab, setSectionTab] = useState("");

  useEffect(() => {
      
      const tab = searchParams.get("tab");
      if(tab){
          setSectionTab(tab)
      }else{
        setSectionTab("");
      }
  
  },[location.search]);

  useEffect(() => {
    function handleClickOutside(event) {
        if (settingsRef.current && !settingsRef.current.contains(event.target)) {
            setShowSetting(false);
        }
    }
  
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
  
    // Unbind the event listener on cleanup
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [settingsRef]);

  useEffect(() => {
    setCurrSaState(currSa)
  },[currSa])

  useEffect(() => {
    if(currRoles){
      setCurrRolesState(currRoles[0])
    }
  },[currRoles]);

  useEffect(() => {
    const rawHash = window.location.hash;
    const hash = rawHash.replace("#/", "");
    dispatch(setCurrSelectedTab(hash));
  },[]);

  const showPlanModalFunc = () => {
    const modalEle = planModal.current;
    const bsModal = new Modal(modalEle, {
      backdrop: "static",
      keyboard: false,
    });
    bsModal.show();
  };

  const hidePlanModalFunc = () => {
      const modalEle = planModal.current;
      const bsModal = Modal.getInstance(modalEle);
      bsModal.hide();
  };

  function navigateTo(param){
    navigate(`/${param}`);
  };

  async function handleBacktoCompany(){

    await Promise.resolve(hideTrialEndModalFunc());
    removeFromLocalStorage("CURR_COMPANY");
    removeFromLocalStorage("CURR_ROLE");
    dispatch(setCompanyID(null)),
    dispatch(retrieveCurrRoles({TenantID:tenant,RoleID:""}))

    navigate("/Company");

  }

  const handleChangeTab = (route) => {
    // const rawHash = window.location.hash;
    // const hash = rawHash.replace("#/", "");
    dispatch(setCurrSelectedTab(route));
  }

  return (
    <>

    <div className="modal fade" tabIndex="-1" ref={trialEndModal} style={{ zIndex: 100000 }}>
      <div className="modal-dialog modal-dialog-centered modal-xl" style={{}}>
        <div className="modal-content px-3" style={{overflowY: "scroll", backgroundColor: "#f6f6ff" }}>
          <div className="modal-body trialEndBody">
            <div className='iconContainer'>
              <MdTimelapse size={30} color='#FFF'/>
            </div>
            <span className='title'>
              {currUsageDetails?.plan === "Trial" ? `Your free trial has ended` : `Your ${currUsageDetails?.plan} plan has ended`}
            </span>
            <span className='description'>
              Thank you for choosing Zumax CRM for your business needs. 
              <br/>To continue enjoying the full benefits of Zumax CRM and access higher quota limits, we invite you to upgrade to our premium plans.
            </span>
            <div className='button'>
              <a href="https://zumaxdigital.com/crm-v1/" target="_blank" style={{textDecoration:'none',cursor:'pointer'}}><span>UPGRADE NOW</span></a>
            </div>
            <img src={require('../assets/img/zumax-logo-black.webp')} alt=''/>
            <span className='back' onClick={() => { handleBacktoCompany() }}>Back</span>
          </div>
        </div>
      </div>
    </div>

    <div className="modal fade" tabIndex="-1" ref={planModal} style={{ zIndex: 100000 }}>
      <div className="modal-dialog modal-dialog-centered modal-xl" style={{}}>
        <div className="modal-content px-3" style={{overflowY: "scroll", backgroundColor: "#f6f6ff" }}>
          <div className="modal-header modalHeader">
            <div className="title">
              <TbBusinessplan size={17}/>
              <span className="font-s">Plans</span>
            </div>
            <div className="currentPlanContainer">
              <span>Your current plan: <span className="currentPlan">{currPlan === "Trial" ? "Free Trial" : currPlan}</span></span>
            </div>
            <AiOutlineClose cursor='pointer' size={16} onClick={() => {
              hidePlanModalFunc();
            }}/>
          </div>
          <div className="modal-body planBody">
            <div className="plan">
              <div className="planTitle">
                <span className="title">Standard</span>
                <span className="subtitle">Perfect Plan for Starters.</span>
              </div>
              <div className="planPrice">
                <span><span className="unit">RM</span><span className="price">18</span> / User / Month</span>
              </div>
              <div className="planFeatures">
                {Object.values(plan.Standard).map((item, planIndex) => {
                  return(
                    <div key={planIndex} className="feature">
                      <div className="dot"><div className="white-dot"></div></div>
                      <span>{`${item?.Quota} ${item?.SectionName} ${item?.CategoryID !== "c4" ? item?.Unit : ""}`}</span>
                    </div>
                  )
                })}
              </div>
              <div className="planAction">
                   <a href="https://zumaxdigital.com/crm-v1/" target="_blank" style={{textDecoration:'none',cursor:'pointer'}}><button><span>CONTACT US</span></button></a>
              </div>
            </div>
            <div className="plan current">
              <div className="planTitle">
                <span className="title">Pro</span>
                <span className="subtitle">True Power of Marketing.</span>
              </div>
              <div className="planPrice">
                <span><span className="unit">RM</span><span className="price">38</span> / User / Month</span>
              </div>
              <div className="planFeatures">
                {Object.values(plan.Pro).map((item, planIndex) => {
                  return(
                    <div key={planIndex} className="feature">
                        <div className="dot"><div className="white-dot"></div></div>
                        <span>{`${item?.Quota} ${item?.SectionName} ${item?.CategoryID !== "c4" ? item?.Unit : ""}`}</span>
                    </div>
                  )
                })}
              </div>
              <div className="planAction">
                 <a href="https://zumaxdigital.com/crm-v1/" target="_blank" style={{textDecoration:'none',cursor:'pointer'}}><button><span>CONTACT US</span></button></a>
              </div>
            </div>
            <div className="plan">
              <div className="planTitle">
                <span className="title">Enterprise</span>
                <span className="subtitle">Collaborate Professionally.</span>
              </div>
              <div className="planPrice">
                <span><span className="unit">RM</span><span className="price">66</span> / User / Month</span>
              </div>
              <div className="planFeatures">
                {Object.values(plan.Enterprise).map((item, planIndex) => {
                  return(
                    <div key={planIndex} className="feature">
                      <div className="dot"><div className="white-dot"></div></div>
                      <span>{`${item?.Quota} ${item?.SectionName} ${item?.CategoryID !== "c4" ? item?.Unit : ""}`}</span>
                    </div>
                  )
                })}
              </div>
              <div className="planAction">
                   <a href="https://zumaxdigital.com/crm-v1/" target="_blank" style={{textDecoration:'none',cursor:'pointer'}}><button><span>CONTACT US</span></button></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div style={{ position: 'relative' }}>
      <div className={`settingsContainer ${showSetting && 'showSetting'}`} ref={settingsRef}>
        <div className={`tabContainer ${sectionTab === "company" && 'selectedTab'}`} onClick={() => { handleChangeTab("companySetting?tab=company") }}>
          <span>Company settings</span>
        </div>
        <div className={`tabContainer ${sectionTab === "usersandpermissions" && 'selectedTab'}`} onClick={() => { handleChangeTab("companySetting?tab=usersandpermissions") }}>
          <span>Users and permissions</span>
        </div>
        <div className={`tabContainer ${sectionTab === "usage" && 'selectedTab'}`} onClick={() => { handleChangeTab("companySetting?tab=usageandbillings") }}>
          <span>Usage and billing</span>
        </div>
      </div>

      {/* <div className='sidebar-container sidebar-invoicing'>
        <div className="sidebar-content">
          <div className='menu-container'>
              {Object.keys(Invoicing).map(item => {
                return (
                  <div id='Dashboard' className={`selection ${currSelectedTab === "Dashboard" && 'selectedSection'}`} onClick={() => { handleChangeTab("Dashboard") }}>
                    <div className='menu-item-container' iconClassName="icon-style" icon="th-large">
                      <div className='item'>
                        {item}
                      </div>
                      <div><MdArrowDropDown size={20}/></div>
                    </div>
                  </div>
                )
              })}
          </div>
        </div>
      </div> */}

      <div className='sidebar-container'>
        <div className='sidebar-header-container'>
          <div className='logoMainContainer'>
            <img src={require("../assets/img/zumax-logo.webp")} alt=''/>
          </div>
          <div className='sidebarHeaderActions'>
            <div className='textContainer'>
              <span>{Array.isArray(companyList) && companyList?.find(item => item?.CompanyID === companyID)?.CompanyName}</span>
            </div>
            {currSa && <div className='iconContainer' onClick={() => { setShowSetting(true); }}>
              <AiFillSetting className={`icon ${sectionTab !== "" && 'selectedSection'}`} size={20}/>
              <AiFillCaretRight className={`openIcon ${sectionTab !== "" && 'selectedSection'}`} size={15}/>
            </div>}
          </div> 
        </div>
       
        <div className="sidebar-content">
          <div className="menu-container">
            {/* <div className='menu-category-title'>WORKSPACE</div> */}  
            <div id='Dashboard' className={`selection ${currSelectedTab === "Dashboard" && 'selectedSection'}`} onClick={() => { handleChangeTab("Dashboard") }}>
              <div className='menu-item-container' iconClassName="icon-style" icon="th-large">
                <div className='item'>
                  <div className='icon'><HiHome size={18}/></div>Dashboard
                </div>
              </div>
            </div>
            <div id='Analytics' className={`selection ${currSelectedTab === "Analytics" && 'selectedSection'}`} onClick={() => { handleChangeTab("Analytics") }} style={{ display: "flex" }}>
              <div className='menu-item-container' iconClassName="icon-style" icon="poll">
                <div className='item'>
                  <div className='icon'><HiChartBar size={18}/></div>Analytics
                </div>
              </div>
            </div>
            <div id='Kpi' className={`selection ${currSelectedTab === "Kpi" && 'selectedSection'}`} onClick={() => { handleChangeTab("Kpi") }} style={{ display: "flex" }}>
              <div className='menu-item-container' iconClassName="icon-style" icon="anchor">
                <div className='item'>
                  <div className='icon'><HiArrowTrendingUp size={18}/></div>KPI
                </div>
              </div>
            </div>
            {(currSa || (zumaxUsers.includes(user?.uid) && currRolesState?.Invoice_View)) ? 
              <div id='Invoicing' className={`selection ${currSelectedTab === "Invoicing" && 'selectedSection'}`} onClick={() => { handleChangeTab("Invoicing") }} style={{ display: "flex" }}>
                <div className='menu-item-container' iconClassName="icon-style" icon="users">
                  <div className='item'>
                    <div className='icon'><HiCurrencyDollar size={18}/></div>Invoicing
                  </div>
                  {/* <div><MdArrowRight size={20}/></div> */}
                </div>
              </div>
              : null
            }
            
            <div id='Clients' className={`selection ${currSelectedTab === "Clients" && 'selectedSection'}`} onClick={() => { handleChangeTab("Clients") }} style={{ display: "flex" }}>
              <div className='menu-item-container' iconClassName="icon-style" icon="users">
              <div className='item'>
                <div className='icon'><HiUser size={18}/></div>Clients
              </div>
              </div>
            </div>
            <div id='Appointment' className={`selection ${currSelectedTab === "Appointment" && 'selectedSection'}`} onClick={() => { handleChangeTab("Appointment") }}>
              <div className='menu-item-container' iconClassName="icon-style" icon="calendar-alt">
                <div className='item'>
                  <div className='icon'><HiCalendar size={18}/></div>Appointments
                </div>
              </div>
            </div>
            <div id='FormList' className={`selection ${currSelectedTab === "FormList" && 'selectedSection'}`} onClick={() => { handleChangeTab("FormList") }} style={{ display: "flex" }}>
              <div className='menu-item-container' iconClassName="icon-style" icon="scroll">
                <div className='item'>
                  <div className='icon'><HiDocumentCheck size={18}/></div>Form Submission
                </div>
              </div>
            </div>
            <div id='FormTemplate' className={`selection ${(currSaState || currRolesState?.Forms_View || currRolesState?.Forms_Manage) ? "" : "hideMenu"} ${currSelectedTab === "FormTemplate" && 'selectedSection'}`} onClick={() => { handleChangeTab("FormTemplate") }} style={{ display: "flex" }}>
              <div className='menu-item-container' iconClassName="icon-style" icon="file">
                <div className='item'>
                  <div className='icon'><HiDocument size={18}/></div>Form Template
                </div>
              </div>
            </div>
          </div>
          
        </div>
        <div className='sidebarFooterContainer'>
            <div className='plan'>
              <span>{currPlan === "Trial" ? `Free Trial` : currPlan}</span>

            </div>
            {currSa && <span className='action' onClick={() => { showPlanModalFunc(); }}>UPGRADE</span>}
        </div>
      </div>
      
    </div>
    </>
  )
}

export default Sidebar