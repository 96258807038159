import React from 'react'
import { MdOutlineTipsAndUpdates } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'

function ImportAndExport() {

    const navigate = useNavigate();

  return (
    <div className='content'>

        <span>Inserting data one by one is frustrating. Well used of import feature will save your time!</span>

        <span><strong><em>Before importing data, kindly read the rules and guides below.</em></strong></span>

        <ul>
            <li>Choose Client Group</li>
                <span>Select a group you wish to import</span>
                <div className='imgContainer'>
                    <img src={require("./assets/choose-group.png")} alt=""/>
                </div>
            <li>Get your template</li>
                <span>While importing client, you are required to follow the template!</span>
                <span>Each client group will have different template, you should download the respective template!</span>
                <div className='hintsContainer'>
                    <MdOutlineTipsAndUpdates className='icon' size={18}/>
                    <span>Fields will change based on Admin's configuration. You're advised to download a new Template whenever you need to import data! </span>
                </div>
                <div className='imgContainer'>
                    <img src={require("./assets/get-template.png")} alt=""/>
                </div>
            <li>Edit the template</li>
                <span>Edit the csv template with the data you need to import!</span>
                <div className='imgContainer'>
                    <img src={require("./assets/edit-template.png")} alt=""/>
                </div>
            <li>Check duplicatable field</li>
                <span>In some case, fields are not allowed to have duplicate value, check before you import!</span>
                <span>You will see a label (unique) for the particular field that are not allowed to have duplicate value</span>
                <strong><span>- Duplicate Data will be checked in your CSV file and existing data.</span></strong>

                <div className='imgContainer'>
                    <img src={require("./assets/duplicate-check.png")} alt=""/>
                </div>
            <li>Import your data</li>
                <span>Once you have done the steps above, you are ready to import.</span>
                <span>Please make sure that your file format is .csv before import.</span>
                <span>You can also assign handler while importing data. Selected handler and clients will be automatically assign into new sub group.</span>
                {/* <div className='hintsContainer'>
                    <MdOutlineTipsAndUpdates className='icon' size={18}/>
                    <span>Subgroup is like some sort of permission which allows you to define which handler/user can view the specific clients. By adding them into the subgroup along with the client through import, it allows them to view and make some actions to the client.</span>
                </div> */}
                <div className='imgContainer'>
                    <img src={require("./assets/import-data.png")} alt=""/>
                </div>
            {/* <li>Import your data</li>
                <span>Once complete the above step, you are good to import!</span>
                <div className='imgContainer'>
                    <img src={require("./assets/import.gif")} alt=""/>
                </div>
                <span>If you're importing duplicate data in not duplicatable field. You will get the result as below!</span>
                <div className='imgContainer'>
                    <img src={require("./assets/import_fail.gif")} alt=""/>
                </div> */}
        </ul>
    </div>
  )
}

export default ImportAndExport