import React from "react";
import PropTypes from "prop-types";
import {
  //   IconButton,
  //   InputAdornment,
  //   TextField,
  //   Tooltip,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";

export const InputToggleButton = ({
  onChange,
  value,
  list,
  size = "small",
  buttonSx = null,
  disabled,
}) => {
  const handleOnChange = (event, newValue) => {
    onChange(newValue);
  };
  return (
    <ToggleButtonGroup
      color="primary"
      value={value}
      exclusive
      onChange={handleOnChange}
      aria-label="Platform"
    >
      {list.map((button) => {
        return (
          <ToggleButton
            key={`TOGGLE_${button.value}`}
            value={button.value}
            size={size}
            sx={[
              ({ borders }) => ({
                textTransform: "none",
                "& .Mui-selected": {
                  border: borders.default,
                },
                ...(buttonSx ? buttonSx : {}),
              }),
            ]}
            disabled={disabled}
          >
            <Typography
              sx={({ typography }) => ({
                ...typography.bodyText,
              })}
            >
              {button.label}
            </Typography>
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
};

InputToggleButton.propTypes = {
  onChange: PropTypes.any,
  value: PropTypes.any,
  list: PropTypes.any,
  buttonSx: PropTypes.any,
  size: PropTypes.any,
  disabled: PropTypes.any,
};
