/* eslint-disable no-unused-vars */
import { createSlice, current } from "@reduxjs/toolkit";
import { useAppSelector } from "../../hooks/storeHooks";

const initialState = {
  allFiles: [],
  retrieveAllFilesLoading: false,
  retrieveAllFilesError: undefined,
  createFileLoading: false,
  createFileError: undefined,
  removeFileLoading: false,
  removeFileError: undefined,
};

const fileManagerSlice = createSlice({
  name: "fileManager",
  initialState,
  reducers: {
    retrieveAllFiles(state) {
      state.retrieveAllFilesLoading = true;
    },
    retrieveAllFilesSuccess(state, { payload }) {
      state.allFiles = payload;
      state.retrieveAllFilesLoading = false;
    },
    retrieveAllFilesError(state, { payload }) {
      state.retrieveAllFilesError = payload;
      state.retrieveAllFilesLoading = false;
    },
    createFile(state) {
      state.createFileLoading = true;
    },
    createFileSuccess(state, { payload }) {
      const currentState = current(state.allFiles);
      const updatedData = [...currentState, ...payload].sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );

      state.allFiles = updatedData;
      state.createFileLoading = false;
    },
    createFileError(state, { payload }) {
      state.createFileError = payload;
      state.createFileLoading = false;
    },
    removeFile(state) {
      state.removeFileLoading = true;
    },
    removeFileSuccess(state, { payload }) {
      const currentState = current(state.allFiles);
      const updatedData = currentState
        .filter(
          (file) => !payload.find((item) => item.file_id === file.file_id)
        )
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

      state.allFiles = updatedData;
      state.removeFileLoading = false;
    },
    removeFileError(state, { payload }) {
      state.removeFileError = payload;
      state.removeFileLoading = false;
    },
  },
});

export const {
  retrieveAllFiles,
  retrieveAllFilesSuccess,
  retrieveAllFilesError,
  createFile,
  createFileSuccess,
  createFileError,
  removeFile,
  removeFileSuccess,
  removeFileError,
} = fileManagerSlice.actions;

export const selectAllFiles = () =>
  useAppSelector((state) => state.fileManager.allFiles);
export const selectRetrieveAllFilesLoading = () =>
  useAppSelector((state) => state.fileManager.retrieveAllFilesLoading);
export const selectRetrieveAllFilesError = () =>
  useAppSelector((state) => state.fileManager.retrieveAllFilesError);
export const selectCreateFileLoading = () =>
  useAppSelector((state) => state.fileManager.createFileLoading);
export const selectCreateFileError = () =>
  useAppSelector((state) => state.fileManager.createFileError);
export const selectRemoveFileLoading = () =>
  useAppSelector((state) => state.fileManager.removeFileLoading);
export const selectRemoveFileError = () =>
  useAppSelector((state) => state.fileManager.removeFileError);

const fileManagerReducer = fileManagerSlice.reducer;

export default fileManagerReducer;
