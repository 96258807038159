const component = {
  MuiAccordionSummary: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: 0,
        ".MuiAccordionSummary-content": {
          margin: 0,
          alignItems: "center",
          justifyContent: "space-between",
        },
        ".MuiTypography-bodyText":{
          color: theme.palette.primary.main,
        },
      }),
    },
  },
};

export default component;
