import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Stack, Box } from "@mui/material";
import {
  FormInputText,
  FormInputDropdownSearch,
  FormInputDropdownMultipleSearch,
  FormInputDate,
  FormInputToggleButton,
  FormInputCurrency,
} from "../../../components/FormInput";

import { FormInputContainer } from "../../AccContainers/Global";
import { useWatch } from "react-hook-form";
import moment from "moment";
import { FormLayoutTitle } from "../../AccContainers/Layout";
import forms from "../../../styles/theme/forms";
import { validatorRequired } from "../../../util/validators";

export const GeneralForm = ({
  watch,
  setValue,
  control,
  options,
  transactionNo,
  isInvoice = false,
  isBill = false,
  isPayments = false,
  isPurchase = false,
  isCreditNote = false,
}) => {
  const formInputWidthFull = { ...forms.oneCol };
  const formInputWidth = { ...forms.twoCol };
  const contacts = options.contacts
    ? options.contacts.map((contact) => {
        return {
          label: contact.display_name,
          value: contact.contact_id,
        };
      })
    : [];
  const paymentTerms = options.payment_terms
    ? options.payment_terms.map((term) => {
        return {
          label: term.name,
          days: term.value,
          value: term.payment_term_id,
        };
      })
    : [];

  const currencies = options.currencies
    ? options.currencies.map((currency) => {
        return {
          label: currency.code,
          value: currency.code,
        };
      })
    : [];
  const tags = options.tag_groups ? options.tag_groups : [];
  const numberFormats = options.number_formats
    ? options.number_formats.map((nF) => {
        return {
          value: nF.number_format_id,
          label: nF.format,
        };
      })
    : [];

  const dateWatcher =
    isInvoice || isBill
      ? useWatch({
          control,
          name: "date",
        })
      : null;

  const getPaymentTerm = (value) => {
    const { payment_terms = [] } = options;
    const paymentTerm = payment_terms.find(
      (term) => term.payment_term_id === value
    );

    return paymentTerm ? paymentTerm : null;
  };

  const convertDueOnDate = (termId, date) => {
    const paymentTermObject = getPaymentTerm(termId);

    const defaultDate = date ? new Date(date.format("YYYY-MM-DD")) : new Date();

    const currentDate = moment(defaultDate);

    const paymentTermAddedValue = currentDate.add(
      paymentTermObject.value,
      paymentTermObject.name === "EOFM" ? "months" : "days"
    );

    const paymentTermEndOf =
      paymentTermObject.name === "EOFM" || paymentTermObject.name === "EOM"
        ? paymentTermAddedValue.endOf("month")
        : paymentTermAddedValue;
    const paymentTermDate = paymentTermEndOf.format("YYYY-MM-DD");

    const result = moment(new Date(paymentTermDate));
    return result;
  };

  const paymentTermOnChange = (payload) => {
    const isObject = typeof payload === "object";

    const paymentTermObject = isObject
      ? getPaymentTerm(payload.target.value)
      : getPaymentTerm(payload);

    // const todayDate = new Date();
    const watchDateValue = watch("date");

    const momentTodayDate = moment(watchDateValue);
    const paymentTermAddedValue = momentTodayDate.add(
      paymentTermObject.value,
      paymentTermObject.name === "EOFM" ? "months" : "days"
    );

    const paymentTermEndOf =
      paymentTermObject.name === "EOFM" || paymentTermObject.name === "EOM"
        ? paymentTermAddedValue.endOf("month")
        : paymentTermAddedValue;
    const paymentTermDate = paymentTermEndOf.format("YYYY-MM-DD");
    setValue(`due_date`, paymentTermDate, {
      shouldDirty: true,
    });
  };

  useEffect(() => {
    if (isBill) {
      const paymentTermId = watch("payment_term_id");
      if (dateWatcher !== undefined && paymentTermId) {
        const dueDateValue = convertDueOnDate(paymentTermId, dateWatcher);
        setValue("due_date", dueDateValue.format("YYYY-MM-DD"), {
          shouldDirty: true,
        });
      }
    }

    if (isInvoice) {
      const paymentTermsList = watch("payment_terms");
      if (dateWatcher !== undefined && paymentTermsList !== undefined) {
        const updatedPaymentTermList = paymentTermsList.map((paymentTerm) => {
          const paymentTermId = paymentTerm.payment_term_id;
          if (paymentTermId) {
            const dueOnValue = convertDueOnDate(paymentTermId, dateWatcher);
            return {
              ...paymentTerm,
              due_on: dueOnValue,
            };
          }
          return paymentTerm;
        });
        setValue("payment_terms", updatedPaymentTermList, {
          shouldDirty: true,
        });
      }
    }
  }, [dateWatcher]);

  return (
    <Stack>
      {isInvoice || isBill ? (
        <Box
          sx={{
            marginTop: 2,
            paddingLeft: "30px",
          }}
        >
          <FormInputToggleButton
            name={"expenses_type"}
            control={control}
            list={
              isBill
                ? [
                    {
                      label: "Credit Purchase",
                      value: "CREDIT_PURCHASE",
                    },
                    {
                      label: "Cash Purchase",
                      value: "CASH PURCHASE",
                    },
                    {
                      label: "Expense Claim",
                      value: "EXPENSE_CLAim",
                    },
                  ]
                : [
                    {
                      label: "Credit Sales",
                      value: "CREDIT_SALES",
                    },
                    {
                      label: "Cash Sales",
                      value: "CASH_SALES",
                    },
                  ]
            }
          />
        </Box>
      ) : null}
      <Stack
        component="div"
        rowGap="30px"
        paddingY={3}
        width={0.9}paddingLeft={"30px"}
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <FormInputContainer
          label={isPurchase ? "Supplier" : "Customer"}
          minWidth={formInputWidth}
          maxWidth={formInputWidth}
          renderInput={
            <FormInputDropdownSearch
              control={control}
              options={contacts}
              // tooltips={renderTypeTooltips}
              name="customer_id"
              label={isPurchase ? "Supplier" : "Customer"}
              required
              rules={{
                required: validatorRequired(),
              }}
            />
          }
        />
        <FormInputContainer
          label="Date"
          minWidth={formInputWidth}
          maxWidth={formInputWidth}
          renderInput={
            <FormInputDate
              control={control}
              name={`date`}
              label={"Date"}
              required
              rules={{
                required: validatorRequired(),
              }}
            />
          }
        />

        <FormInputContainer
          label="No."
          minWidth={formInputWidth}
          maxWidth={formInputWidth}
          renderInput={
            <FormInputDropdownSearch
              control={control}
              options={
                transactionNo
                  ? [
                      {
                        label: transactionNo,
                        value: transactionNo,
                      },
                    ]
                  : numberFormats
              }
              // tooltips={renderTypeTooltips}
              name={transactionNo ? "transaction_no" : "number_format_id"}
              isDisabled={transactionNo ? true : false}
              label="No."
              // defaultValue={companySetting ? companySetting.numberFormat : null}

              required
              rules={{
                required: validatorRequired(),
              }}
            />
          }
        />

        <FormInputContainer
          label="Reference No"
          minWidth={formInputWidth}
          maxWidth={formInputWidth}
          renderInput={
            <FormInputText
              control={control}
              type="text"
              name="reference_no"
              label="Reference No"
              // helperText="Example: Example Sdn Bhd"
            />
          }
        />
        {isPayments || isBill || isCreditNote ? null : (
          <FormInputContainer
            label="Title"
            minWidth={formInputWidth}
            maxWidth={formInputWidth}
            renderInput={
              <FormInputText
                control={control}
                type="text"
                name="title"
                label="Title"
              />
            }
          />
        )}

        {isInvoice || isPayments || isCreditNote ? null : (
          <FormInputContainer
            label="Payment Term"
            minWidth={formInputWidth}
            maxWidth={formInputWidth}
            renderInput={
              <FormInputDropdownSearch
                control={control}
                options={paymentTerms}
                // tooltips={renderTypeTooltips}
                name="payment_term_id"
                label="Payment Term"
                formOnChange={paymentTermOnChange}
              />
            }
          />
        )}

        <FormInputContainer
          label="Tags"
          // description="The default tags assigned to transactions that can be overridden."
          minWidth={formInputWidth}
          maxWidth={formInputWidth}
          renderInput={
            <FormInputDropdownMultipleSearch
              control={control}
              options={tags}
              groupBy={(option) => option.tag_group}
              labelField={"name"}
              isOptionEqualToValue={(option, value) => {
                return (
                  option.tag_id === value.value ||
                  option.tag_id === value ||
                  option.tag_id === value.tag_id
                );
              }}
              name="tag_ids"
              label="Select Tags"
            />
          }
        />

        <FormInputContainer
          label="Currency"
          minWidth={formInputWidth}
          maxWidth={formInputWidth}
          renderInput={
            <Stack
              component="div"
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                alignItems: "flex-start",
              }}
            >
              <Stack component="div" width={0.20}>
                <FormInputCurrency
                  control={control}
                  options={currencies}
                  name="system_currency_code"
                  required
                  rules={{
                    required: validatorRequired(),
                  }}
                />
              </Stack>
              <Stack component="div" width={0.80}>
                <FormInputText
                  control={control}
                  type="text"
                  name="effective_rate"
                  label="Rate"
                  isDisabled={true}
                  // helperText="Example: Example Sdn Bhd"
                />
              </Stack>
            </Stack>
          }
        />

        {isBill ? (
          // <InputDate
          //   value={getDueDate(paymentTermWatcher)}
          //   label={"Due Date"}
          //   isDisabled={true}
          // />
          <FormInputContainer
            minWidth={formInputWidth}
            maxWidth={formInputWidth}
            label="Due Date"
            renderInput={
              <FormInputText
                isDisabled={true}
                control={control}
                name={`due_date`}
                label="Due Date"
              />
            }
          />
        ) : null}
      </Stack>

      {isPayments ? null : (
        <FormLayoutTitle title={"Billing & Shipping Information"} />
      )}
      <Stack
        component="div"
        direction="row"
        spacing={1}
        width={0.9}
        paddingLeft={"30px"}
        gap={"130px"}
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Stack
          component="div"
          paddingY={3}
          width={0.9}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          {isPayments ? null : (
            <FormInputContainer
              label="Billing Attention"
              minWidth={formInputWidthFull}
              maxWidth={formInputWidthFull}
              renderInput={
                <FormInputText
                  control={control}
                  type="text"
                  name="billing_attention"
                  label="Billing Attention"
                />
              }
            />
          )}
          <Stack
            component="div"
            width={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "flex-start",
              marginTop: isBill ? "20px" : "0px"
            }}
          >
            {isPayments ? null : (
              <FormInputContainer
                label="Billing Party"
                minWidth={formInputWidthFull}
                maxWidth={formInputWidthFull}
                renderInput={
                  <FormInputText
                    control={control}
                    multiline={true}
                    type="text"
                    label="Billing Party"
                    name="billing_party"
                  />
                }
              />
            )}
          </Stack>
        </Stack>

        {isPayments || isBill ? null : (
          <Stack
            width={0.9}
            spacing={1}
            paddingY={3}
            rowGap="30px"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <FormInputContainer
              label="Shipping Info"
              minWidth={formInputWidthFull}
              maxWidth={formInputWidthFull}
              renderInput={
                <FormInputText
                  control={control}
                  type="text"
                  name="shipping_info"
                  label="Shipping Info"
                />
              }
            />
            <FormInputContainer
              label="Shipping Attention"
              minWidth={formInputWidthFull}
              maxWidth={formInputWidthFull}
              renderInput={
                <FormInputText
                  control={control}
                  type="text"
                  name="shipping_attention"
                  label="Shipping Attention"
                />
              }
            />
            <FormInputContainer
              label="Shipping Party"
              minWidth={formInputWidthFull}
              maxWidth={formInputWidthFull}
              renderInput={
                <FormInputText
                  control={control}
                  multiline={true}
                  type="text"
                  label="Shipping Party"
                  name="shipping_party"
                />
              }
            />
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

GeneralForm.propTypes = {
  control: PropTypes.any,
  watch: PropTypes.func,
  setValue: PropTypes.any,
  options: PropTypes.any,
  transactionNo: PropTypes.any,
  isCreditNote: PropTypes.any,
  isPayments: PropTypes.any,
  isBill: PropTypes.any,
  isInvoice: PropTypes.any,
  isPurchase: PropTypes.any,
};
