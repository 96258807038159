import React from "react";

import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { ExpandMoreRounded } from "@mui/icons-material";
import { convertSxToArray } from "../../util/mui/convertSxToArray";

export const AccordionWidget = ({ title, children, disabled = false, sx }) => {
  return (
    <Accordion
      disabled={disabled}
      sx={[
        {
          padding: "unset",
        },
        ...convertSxToArray(sx),
      ]}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreRounded />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography
          sx={({ typography }) => ({
            ...typography.bodyText,
          })}
        >
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

