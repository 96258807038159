import React from 'react'
import './C_IconButton.scss'

const C_IconButton = (props) => {
    return (
        <div className='iconButtonContainer' title={props.title} style={{
                width: props.size,
                height: props.size,
                backgroundColor: props.color,
                backgroundImage: props.backgroundImage
            }}
            onClick={props.onClick}
            data-bs-target={'#'+props.targetID} data-bs-toggle={props.toggleAction} data-bs-dismiss={props.dismissAction}
        >
            {props.icon}
        </div>
    );
}

export default C_IconButton;