import React, { useMemo } from "react";
import PropTypes from "prop-types";
import {
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  HelpOutlineRounded,
  KeyboardArrowDownRounded,
  WarningRounded,
} from "@mui/icons-material";
import { InputTextType } from "../../enums/components/InputTextType";

export const InputDropdown = ({
  type,
  options,
  textType = InputTextType.Normal,
  name,
  isDisabled,
  value,
  transform,
  onChange,
  onBlur,
  inputProps,
  tooltips,
  minWidth,
  maxWidth,
  fullWidth = true,
  label,
  required,
  fieldRef,
  error,
  helperText,
}) => {
  const handleChange = (event) => {
    transform ? onChange(transform(event)) : onChange(event);
  };

  const handleBlur = () => {
    const trimmedValue = value && value?.trim ? value.trim() : value;
    onChange({ target: { value: trimmedValue } });
    onBlur && onBlur();
  };

  // Handle jest test ID and text transformation
  const setTextTransform = useMemo(() => {
    if (textType === InputTextType.Uppercase) return "uppercase";
    if (textType === InputTextType.Capitalize) return "capitalize";
    return "unset";
  }, [textType]);

  const inputTextProps = {
    style: { textTransform: setTextTransform },
    "data-testid": `${name}InputDropdown`,
    ...inputProps,
  };

  // Handle render helper text and error text
  const helperHandling = () => {
    if (helperText && !error)
      return (
        <Typography
          className="helper"
          variant="remark"
          color={({ palette }) => palette.text.light}
          sx={{ marginLeft: "10px" }}
        >
          {helperText}
        </Typography>
      );
    if (error)
      return (
        <Typography
          variant="bodyText"
          color={({ palette }) => palette.snack.error}
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <WarningRounded
            sx={({ palette, icons }) => ({
              ...icons.standard,
              color: palette.snack.error,
              pointerEvents: "none",
            })}
          />
          {error}
        </Typography>
      );
    return null;
  };

  const tooltipsAdornmentComponent = tooltips ? (
    <InputAdornment position="end">
      <Tooltip title={tooltips}>
        <IconButton
          sx={{ position: "absolute", right: 0, marginRight: "-40px" }}
        >
          <HelpOutlineRounded sx={({ icons }) => ({ ...icons.standard })} />
        </IconButton>
      </Tooltip>
    </InputAdornment>
  ) : null;

  const renderMenuOptions = options.map((item) => (
    <MenuItem key={item.value} value={item.value}>
      {item.label}
    </MenuItem>
  ));

  return (
    <TextField
      select
      SelectProps={{
        IconComponent: (_props) => (
          <KeyboardArrowDownRounded
            sx={({ palette, icons }) => ({
              ...icons.standard,
              color: `${palette.icon.default} !important`,
              top: "initial !important",
            })}
            {..._props}
            className={_props.className}
          />
        ),
      }}
      sx={{ minWidth: minWidth, maxWidth: maxWidth }}
      autoComplete="off"
      aria-autocomplete="none"
      type={type}
      error={!!error}
      fullWidth={fullWidth}
      label={label}
      variant="filled"
      required={required}
      inputRef={fieldRef}
      disabled={isDisabled}
      value={value}
      onChange={handleChange}
      inputProps={inputTextProps}
      helperText={helperHandling()}
      onBlur={handleBlur}
      InputProps={{
        style: { height: "55px" },
        endAdornment: tooltipsAdornmentComponent,
      }}
    >
      {renderMenuOptions}
    </TextField>
  );
};

InputDropdown.propTypes = {
  type: PropTypes.any,
  options: PropTypes.array,
  textType: PropTypes.any,
  name: PropTypes.any,
  isDisabled: PropTypes.any,
  value: PropTypes.any,
  onChange: PropTypes.any,
  onBlur: PropTypes.any,
  transform: PropTypes.any,
  inputProps: PropTypes.any,
  tooltips: PropTypes.any,
  minWidth: PropTypes.any,
  maxWidth: PropTypes.any,
  fullWidth: PropTypes.any,
  label: PropTypes.any,
  required: PropTypes.any,
  fieldRef: PropTypes.any,
  error: PropTypes.any,
  helperText: PropTypes.any,
  InputProps: PropTypes.any,
};
