import { createSlice} from "@reduxjs/toolkit"

import { useAppSelector } from "../../hooks/storeHooks";

const initialState = {
    //loading
    setNewSystemIconLoading: false,
    setCurrSelectedTabLoading:false,

    //error
    setNewSystemIconError:undefined,
    setCurrSelectedTabError:undefined,

    //data
    currSystemIcon:[],
    currSelectedTab: window.location.hash.replace("#/", ""),
}

const systemSlice = createSlice({
    name:"system",
    initialState,
    reducers: {
        setNewSystemIcon(state){
            state.setNewSystemIconLoading = true
        },

        setNewSystemIconSuccess(state,{payload}){
            const currentState = payload
            state.currSystemIcon = currentState
            state.setNewSystemIconLoading = false
        },

        setNewSystemIconError(state,{payload}){
            const errorState = payload
            state.setNewSystemIconError = errorState
            state.setNewSystemIconLoading = false
        },

        setCurrSelectedTab(state){
            state.setCurrSelectedTabLoading = true
        },

        setCurrSelectedTabSuccess(state,{payload}){
            const currentState = payload
            state.currSelectedTab = currentState
            state.setCurrSelectedTabLoading = false
        },

        setCurrSelectedTabError(state,{payload}){
            const errorState = payload
            state.setCurrSelectedTabError = errorState
            state.setCurrSelectedTabLoading = false
        },


    }
})

export const {
    setNewSystemIcon,
    setNewSystemIconSuccess,
    setNewSystemIconError,
    setCurrSelectedTab,
    setCurrSelectedTabSuccess,
    setCurrSelectedTabError,
} = systemSlice.actions

//export selector

// data
export const selectCurrSystemIcon = () =>
  useAppSelector((state) => state.system.currSystemIcon);

export const selectCurrSelectedTab = () =>
  useAppSelector((state) => state.system.currSelectedTab);

// loading
export const selectSetNewSystemIconLoading = () =>
  useAppSelector((state) => state.system.setNewSystemIconLoading);

export const selectSetCurrSelectedTabLoading = () =>
  useAppSelector((state) => state.system.setCurrSelectedTabLoading);

// error
export const selectSetNewSystemIconError = () =>
  useAppSelector((state) => state.system.setNewSystemIconError);

export const selectSetCurrSelectedTabError = () =>
  useAppSelector((state) => state.system.setCurrSelectedTabError);

const systemReducer = systemSlice.reducer

export default systemReducer