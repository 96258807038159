const component = {
  MuiInput: {
    styleOverrides: {
      root: ({ theme }) => ({
        ...theme.typography.bodyText,
        color: theme.palette.text.main,
        background: "none",
        "&.Mui-focused": {
          "&::before": {
            borderBottom: `1px solid ${theme.palette.primary.primary} !important`,
          },
          "&::after": {
            borderBottom: `1px solid ${theme.palette.primary.primary} !important`,
          },
          ".MuiInputAdornment-positionStart": {
            svg: {
              "&::before": {
                color: theme.palette.primary.primary,
              },
              "&::after": {
                color: theme.palette.primary.primary,
              },
              color: theme.palette.primary.primary,
            },
          },
        },
      }),
      input: ({ theme }) => ({
        paddingLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(0.5),
        "&::placeholder": {
          ...theme.typography.bodyText,
        },

        "placeholder-black": {
          color: "black",
        },
      }),
    },
  },
};

export default component;
