import { createSlice, current } from "@reduxjs/toolkit"

import { useAppSelector } from "../../hooks/storeHooks";


//initial state
const initialState = {

    //loading
    createCompanyLoading : false,
    retrieveCompanyLoading : false,
    updateCompanyLoading : false,
    deleteCompanyLoading : false,
    setCompanyContextLoading : false,
    setCurrUserContextLoading : false,
    setCompanyDataAndUsersLoading : false,
    setCompanyPlanRequestDataLoading : false,
    setCompanyUsersLoading : false,
    setCompanyUserInvitationLoading : false,
    setCompanyUsageLoading : false,

    //error
    createCompanyError : undefined,
    retrieveCompanyError : undefined,
    updateCompanyError : undefined,
    deleteCompanyError : undefined,
    setCompanyContextError : undefined,
    setCurrUserContextError : undefined,
    setCompanyDataAndUsersError : undefined,
    setCompanyPlanRequestDataError : false,
    setCompanyUsersError : false,
    setCompanyUserInvitationError : false,
    setCompanyUsageError : false,

    //data
    company : [],

    companyContext : {
        companyList : [],
        userList : [],
        availableCompany : [],
    },
    defaultCompanyContext : {
        companyList : [],
        userList : [],
        availableCompany : [],
    },

    currUserContext : {
      currAllUser : [],
      currUsers : [],
      currUserSA : [],
      currUserInvitation : [],
      currPlan : [],
      currUsage : [],
      allUsage : [],
      currTotalUsage : null,
      currUserData : null,
      requestData : [],
      currUsageDetails : null,
    },

    defaultCurrUserContext : {
      currAllUser : [],
      currUsers : [],
      currUserSA : [],
      currUserInvitation : [],
      currPlan : [],
      currUsage : [],
      allUsage : [],
      currTotalUsage : null,
      currUserData : null,
      requestData : [],
      currUsageDetails : null,
    }
}

//slices
const companySlice = createSlice({
    name: "companies",
    initialState,
    reducers : {
        createCompany(state){
            state.createCompanyLoading = true;
        },
        createCompanySuccess(state, {payload}){
            const currentState = payload;
            state.company = currentState;

            state.createCompanyLoading = false;
        },
        createCompanyError(state, {payload}){
            const errorState = payload;
            state.createCompanyError = errorState;

            state.createCompanyLoading = false;
        },

        retrieveCompany(state){
            state.retrieveCompanyLoading = true;
        },
        retrieveCompanySuccess(state, {payload}){
            const currentState = payload;
            state.company = currentState;

            state.retrieveCompanyLoading = false;
        },
        retrieveCompanyError(state, {payload}){
            const errorState = payload;
            state.retrieveCompanyError = errorState;

            state.retrieveCompanyLoading = false;
        },

        updateCompany(state){
            state.updateCompanyLoading = true;
        },
        updateCompanySuccess(state, {payload}){
            const data = payload.data;
            const currentState = current(state.company);
            const updatedState = currentState.map((item) => {
                if (item.CompanyID === data.CompanyID) {
                    return {
                        ...item,
                        ...data,
                    };
                }
                return item;
            });
            state.company = updatedState;

            state.updateCompanyLoading = false;
        },
        updateCompanyError(state, {payload}){
            const errorState = payload;
            state.updateCompanyError = errorState;

            state.updateCompanyLoading = false;
        },

        deleteCompany(state){
            state.deleteCompanyLoading = true;
        },
        deleteCompanySuccess(state, {payload}){
            const currentState = current(state.company);
            const updatedState = currentState.filter((item) => item.CompanyID !== payload.CompanyID);
            state.company = updatedState;

            state.deleteCompanyLoading = false;
        },
        deleteCompanyError(state, {payload}){
            const errorState = payload;
            state.deleteCompanyError = errorState;

            state.deleteCompanyLoading = false;
        },
        setCompanyContext(state){
            state.setCompanyContextLoading = true;
        },
        setCompanyContextSuccess(state, {payload}){
            const currentState = current(state.companyContext);
                if(Object.values(payload).length > 0){
                const updatedState = {
                    ...currentState,
                    ...payload,
                }
                state.companyContext = updatedState;
                } else {
                    state.companyContext = state.defaultCompanyContext;
                }
                
                state.setCompanyContextLoading = false;

        },
        setCompanyContextError(state){
            state.setCompanyContextLoading = false;
        },
        setCurrUserContext(state){
          state.setCurrUserContextLoading = true;
        },
        setCurrUserContextSuccess(state, {payload}){
            const currentState = current(state.currUserContext);
                if(Object.values(payload).length > 0){
                const updatedState = {
                    ...currentState,
                    ...payload,
                }
                state.currUserContext = updatedState;
                } else {
                    state.currUserContext = state.defaultCurrUserContext;
                }
                
                state.setCurrUserContextLoading = false;

        },
        setCurrUserContextError(state){
          const errorState = payload;
          state.setCurrUserContextError = errorState;

          state.setCurrUserContextLoading = false;
        },
        setCompanyDataAndUsers(state){
          state.setCompanyDataAndUsersLoading = true;
        },
        setCompanyDataAndUsersSuccess(state, {payload}){
            state.setCompanyDataAndUsersLoading = false;
        },
        setCompanyDataAndUsersError(state, {payload}){
            const errorState = payload;
            state.setCompanyDataAndUsersError = errorState;

            state.setCompanyDataAndUsersLoading = false;
        },
        
        setCompanyPlanRequestData(state){
          state.setCompanyPlanRequestDataLoading = true;
        },
        setCompanyPlanRequestDataSuccess(state, {payload}){
            state.setCompanyPlanRequestDataLoading = false;
        },
        setCompanyPlanRequestDataError(state, {payload}){
            const errorState = payload;
            state.setCompanyPlanRequestDataError = errorState;

            state.setCompanyPlanRequestDataLoading = false;
        },

        setCompanyUsers(state){
          state.setCompanyUsersLoading = true;
        },
        setCompanyUsersSuccess(state, {payload}){
            state.setCompanyUsersLoading = false;
        },
        setCompanyUsersError(state, {payload}){
            const errorState = payload;
            state.setCompanyUsersError = errorState;

            state.setCompanyUsersLoading = false;
        },

        setCompanyUserInvitation(state){
          state.setCompanyUserInvitationLoading = true;
        },
        setCompanyUserInvitationSuccess(state, {payload}){
            state.setCompanyUserInvitationLoading = false;
        },
        setCompanyUserInvitationError(state, {payload}){
            const errorState = payload;
            state.setCompanyUserInvitationError = errorState;

            state.setCompanyUserInvitationLoading = false;
        },

        setCompanyUsage(state){
          state.setCompanyUsageLoading = true;
        },
        setCompanyUsageSuccess(state, {payload}){
            state.setCompanyUsageLoading = false;
        },
        setCompanyUsageError(state, {payload}){
            const errorState = payload;
            state.setCompanyUsageError = errorState;

            state.setCompanyUsageLoading = false;
        },
    }
})

//export function
export const {
    createCompany,
    createCompanySuccess,
    createCompanyError,
    updateCompany,
    updateCompanySuccess,
    updateCompanyError,
    deleteCompany,
    deleteCompanySuccess,
    deleteCompanyError,
    retrieveCompany,
    retrieveCompanySuccess,
    retrieveCompanyError,
    setCompanyContext,
    setCompanyContextError,
    setCompanyContextSuccess,
    setCurrUserContext,
    setCurrUserContextError,
    setCurrUserContextSuccess,
    setCompanyDataAndUsers,
    setCompanyDataAndUsersError,
    setCompanyDataAndUsersSuccess,
    setCompanyPlanRequestData,
    setCompanyPlanRequestDataError,
    setCompanyPlanRequestDataSuccess,
    setCompanyUsage,
    setCompanyUsageError,
    setCompanyUsageSuccess,
    setCompanyUserInvitation,
    setCompanyUserInvitationError,
    setCompanyUserInvitationSuccess,
    setCompanyUsers,
    setCompanyUsersError,
    setCompanyUsersSuccess
    
} = companySlice.actions

//export selector

// data
export const selectCompany = () =>
  useAppSelector((state) => state.companies.company);

export const selectCompanyContext = () =>
  useAppSelector((state) => state.companies.companyContext);

export const selectCurrUserContext = () =>
  useAppSelector((state) => state.companies.currUserContext);

// loading
export const selectCreateCompanyLoading = () =>
  useAppSelector((state) => state.companies.createCompanyLoading);

export const selectRetrieveCompanyLoading = () =>
  useAppSelector((state) => state.companies.retrieveCompanyLoading);

export const selectUpdateCompanyLoading = () =>
  useAppSelector((state) => state.companies.updateCompanyLoading);

export const selectDeleteCompanyLoading = () =>
  useAppSelector((state) => state.companies.deleteCompanyLoading);

export const selectSetCompanyContextLoading = () =>
  useAppSelector((state) => state.companies.setCompanyContextLoading);

export const selectSetCurrUserContextLoading = () =>
  useAppSelector((state) => state.companies.setCurrUserContextLoading);

export const selectSetCompanyDataAndUsersLoading = () =>
  useAppSelector((state) => state.companies.setCompanyDataAndUsersLoading);

export const selectSetCompanyPlanRequestDataLoading = () =>
  useAppSelector((state) => state.companies.setCompanyPlanRequestDataLoading);

export const selectSetCompanyUsersLoading = () =>
  useAppSelector((state) => state.companies.setCompanyUsersLoading);

export const selectSetCompanyUserInvitationLoading = () =>
  useAppSelector((state) => state.companies.setCompanyUserInvitationLoading);

export const selectSetCompanyUsageLoading = () =>
  useAppSelector((state) => state.companies.setCompanyUsageLoading);

// error
export const selectCreateCompanyError = () =>
  useAppSelector((state) => state.companies.createCompanyError);

export const selectRetrieveCompanyError = () =>
  useAppSelector((state) => state.companies.retrieveCompanyError);

export const selectUpdateCompanyError = () =>
  useAppSelector((state) => state.companies.updateCompanyError);

export const selectDeleteCompanyError = () =>
  useAppSelector((state) => state.companies.deleteCompanyError);

export const selectSetCompanyContextError = () =>
  useAppSelector((state) => state.companies.setCompanyContextError);

export const selectSetCurrUserContextError = () =>
  useAppSelector((state) => state.companies.setCurrUserContextError);

export const selectSetCompanyDataAndUsersError = () =>
  useAppSelector((state) => state.companies.selectSetCompanyDataAndUsersError);

export const selectSetCompanyPlanRequestDataError = () =>
  useAppSelector((state) => state.companies.setCompanyPlanRequestDataError);

export const selectSetCompanyUsersError = () =>
  useAppSelector((state) => state.companies.setCompanyUsersError);

export const selectSetCompanyUserInvitationError = () =>
  useAppSelector((state) => state.companies.setCompanyUserInvitationError);

export const selectSetCompanyUsageError = () =>
  useAppSelector((state) => state.companies.setCompanyUsageError);
 
//export reducer
const companyReducer = companySlice.reducer;

export default companyReducer;
