import { createSlice, current } from "@reduxjs/toolkit"

import { useAppSelector } from "../../hooks/storeHooks";

const initialState = {
    //loading

    createTenantAgentsLoading: false,
    deleteTenantAgentsLoading: false,

    //error

    createTenantAgentsError: undefined,
    deleteTenantAgentsError: undefined,

    //data
    tenant_agents:[]
}

const tenantAgentSlice = createSlice({
    name:"tenantAgents",
    initialState,
    reducers:{
        createTenantAgents(state){
            state.createTenantAgentsLoading = true
        },

        createTenantAgentsSuccess(state,{payload}){
            const currentState = current(state.tenant_agents)
            const updatedState = [...currentState, payload]
            state.tenant_agents = updatedState
            state.createTenantAgentsLoading = false
        },

        createTenantAgentsError(state,{payload}){
            const errorState = payload
            state.createTenantAgentsError = errorState
            state.createTenantAgentsLoading = false
        },

        deleteTenantAgents(state){
            state.deleteTenantAgentsLoading = true
        },

        deleteTenantAgentsSuccess(state,{payload}){
            const currentState = current(state.tenant_agents)
            const updatedState = currentState.filter((item) => item.UserID !== payload.UserID && item.TenantID !== payload.TenantID )
            state.tenant_agents = updatedState
            state.deleteTenantAgentsLoading = false
        },

        deleteTenantAgentsError(state,{payload}){
            const errorState = payload
            state.deleteTenantAgentsError = errorState
            state.deleteTenantAgentsLoading = false
        },
    }
})

export const {
    createTenantAgents,
    createTenantAgentsSuccess,
    createTenantAgentsError,
    deleteTenantAgents,
    deleteTenantAgentsSuccess,
    deleteTenantAgentsError,
} = tenantAgentSlice.actions

//export selector

// data
export const selectTenantAgents = () =>
  useAppSelector((state) => state.tenantAgents.tenant_agents);

// loading
export const selectCreateTenantAgentsLoading = () =>
  useAppSelector((state) => state.tenantAgents.createTenantAgentsLoading);

export const selectDeleteTenantAgentsLoading = () =>
  useAppSelector((state) => state.tenantAgents.deleteTenantAgentsLoading);

// error
export const selectCreateTenantAgentsError = () =>
  useAppSelector((state) => state.tenantAgents.createTenantAgentsError);

export const selectDeleteTenantAgentsError = () =>
  useAppSelector((state) => state.tenantAgents.deleteTenantAgentsError);

const tenantAgentReducer = tenantAgentSlice.reducer

export default tenantAgentReducer

