import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Backdrop,
  Box,
  Modal,
  Typography,
  Stack,
  IconButton,
  Fade,
} from "@mui/material";
import { Close, CloseRounded, SearchRounded } from "@mui/icons-material";
import { InputUnderline } from "../Input/InputUnderline";
import { FileManagerContent } from "./FileManagerContent";
import { UploadConfirmationDialog } from "../Utils/UploadConfirmationDialog";
import { ImagePreview } from "../Utils/ImagePreview";
import { InputImageUpload } from "../Utils/InputImageUpload";
import { retrieveAllFiles, selectAllFiles } from "../../stores/slices/fileManagerSlice";
import { selectFileManagerModal, selectImagePreview, toggleFileManagerModal, toggleImagePreview } from "../../stores/slices/toggleSlice";
import { TableNoItemsOverlay } from "../../assets/svg/table-no-items-overlay";

const FileManagerModal = ({ defaultValue, handleChange }) => {
  const dispatch = useDispatch();
  const allFiles = selectAllFiles();
  const imagePreview = selectImagePreview();
  const isOpenFileManagerModal = selectFileManagerModal();

  const [searchFileText, setSearchFileText] = useState("");
  const [selectedFiles, setSelectedFiles] = useState(null);

  useEffect(() => {
    dispatch(retrieveAllFiles());
  }, []);

  const handleSearchFileInput = (event) => {
    const value = event.target.value;
    setSearchFileText(value);
  };

  const handleClearSearchInput = () => setSearchFileText("");

  const renderClearSearchInputButton =
    searchFileText !== "" ? (
      <CloseRounded
        sx={({ icons, palette }) => ({
          ...icons.standard,
          color: palette.icon.default,
          cursor: "pointer",
        })}
        onClick={handleClearSearchInput}
      />
    ) : null;

  const handleCloseFileManagerModal = () =>
    dispatch(toggleFileManagerModal(false));

  const handleClosePreviewImage = () =>
    dispatch(toggleImagePreview({ url: null, isOpen: false }));

  return (
    <Fragment>
      <ImagePreview
        open={imagePreview.isOpen}
        handleClose={handleClosePreviewImage}
        url={imagePreview.url}
      />
      <UploadConfirmationDialog />
      <Modal
        closeAfterTransition
        open={isOpenFileManagerModal}
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={isOpenFileManagerModal}>
          <Box
            sx={({ palette }) => ({
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 0.8,
              height: 0.8,
              backgroundColor: palette.background.default,
              paddingY: 2,
              paddingX: 4,
              borderRadius: "5px",
              outline: "none",
            })}
          >
            <Stack spacing={2} width={1} height={1}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="bodyTextSemiBold">Select Image</Typography>
                <IconButton
                  aria-label="close-file-manager-modal"
                  onClick={handleCloseFileManagerModal}
                  edge="end"
                >
                  <Close
                    sx={({ icons }) => ({
                      ...icons.standard,
                      color: "#989CA0",
                    })}
                  />
                </IconButton>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box width={{ sx: 1, md: 0.4 }}>
                  <InputUnderline
                    name="search-file"
                    value={searchFileText}
                    label="Search files by name"
                    onChange={handleSearchFileInput}
                    startAdornment={
                      <SearchRounded
                        sx={({ icons }) => ({ ...icons.standard })}
                      />
                    }
                    endAdornment={renderClearSearchInputButton}
                  />
                </Box>
                <InputImageUpload />
              </Stack>
              <Stack
                sx={{
                  width: 1,
                  flexGrow: 1,
                  overflowY: "scroll",
                }}
              >
                <Stack
                  sx={{
                    borderRadius: "10px",
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    width: 1,
                    height: allFiles.length > 0 ? "unset" : 1,
                  }}
                >
                  {allFiles.length > 0 ? (
                    <FileManagerContent
                      selectedFiles={selectedFiles}
                      setSelectedFiles={setSelectedFiles}
                      searchFileText={searchFileText}
                      data={allFiles}
                      defaultValue={defaultValue}
                      handleChange={handleChange}
                      handleCloseFileManagerModal={handleCloseFileManagerModal}
                    />
                  ) : (
                    <Stack
                      height={1}
                      flexGrow={1}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <TableNoItemsOverlay />
                    </Stack>
                  )}
                </Stack>
              </Stack>
            </Stack>
          </Box>
        </Fade>
      </Modal>
    </Fragment>
  );
};


export default FileManagerModal;
