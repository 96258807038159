const component = {
  MuiFormHelperText: {
    styleOverrides: {
      root: ({ theme }) => ({
        ...theme.typography.bodyText,
        lineHeight: "unset",
        marginTop: "5px",
        marginLeft: "0px",
        padding: "unset",
        paddingLeft: "10px",
        span: {
          marginLeft: "0px",
        },
      }),
    },
  },
};

export default component;
