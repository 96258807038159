import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { InputImage } from "../Input/InputImage";

export const FormInputImage = ({
  label,
  tooltips,
  isDisabled,
  minWidth,
  maxWidth,
  fullWidth,
  required,
  maxImageSize,
  helperText,
  resetField,
  ...props
}) => {
  return (
    <Controller
      {...props}
      shouldUnregister
      render={({
        field: { onChange, onBlur, value, ref },
        fieldState: { error },
      }) => {
        return (
          <InputImage
            value={value || ""}
            label={label}
            tooltips={tooltips}
            onBlur={onBlur}
            onChange={onChange}
            error={error?.message}
            helperText={helperText}
            name={props.name}
            isDisabled={isDisabled}
            minWidth={minWidth}
            maxWidth={maxWidth}
            fullWidth={fullWidth}
            required={required}
            maxImageSize={maxImageSize}
            fieldRef={ref}
            resetField={resetField}
          />
        );
      }}
    />
  );
};

FormInputImage.propTypes = {
  label: PropTypes.any,
  tooltips: PropTypes.any,
  isDisabled: PropTypes.any,
  minWidth: PropTypes.any,
  fullWidth: PropTypes.any,
  maxWidth: PropTypes.any,
  required: PropTypes.any,
  maxImageSize: PropTypes.number,
  name: PropTypes.string,
  helperText: PropTypes.any,
  resetField: PropTypes.any,
};
