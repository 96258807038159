import { call, delay, put, takeLatest } from "redux-saga/effects";
import {  
    retrieveCurrUsage,
    retrieveCurrUsageSuccess,
    retrieveCurrUsageError
} from "../stores/slices/usageSlices"
import { API } from "../http-common";
import { ApiRoute } from "../enums/ApiRoute";


function* retrieveCurrUsageSaga({payload}) {
    try {
        const {data} = yield call(API.post, ApiRoute.usage.retrieveCurrentUsage, payload)
        yield put(retrieveCurrUsageSuccess(data))
    } catch (error){
        yield put(retrieveCurrUsageError(error));
        console.error(error);
    }
} 

export function* usageSagaWatcher() {
    yield takeLatest(retrieveCurrUsage.type, retrieveCurrUsageSaga)
}