import React, { useState, useEffect, useRef } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';

import { selectAnalyticsContext } from '../../../stores/slices/analyticsSlices';
import { selectCurrUserContext } from '../../../stores/slices/companySlices';

function Graph_Line(props) {

  const {
    analytics,
    graphData,
  } = selectAnalyticsContext();
  const { currUsers } = selectCurrUserContext();

  const editorState = props.editorState;
  const graphRef = useRef(null);
  const [graphWidth, setGraphWidth] = useState(0);
  const [graphHeight, setGraphHeight] = useState(0);
  
  const yAxis = analytics.yAxis;
  const xAxis = analytics.xAxis;
  const Unit = analytics.Unit;
  const Label = analytics.Label;

  useEffect(() => {
    setGraphWidth(graphRef.current?.clientWidth * 0.9);
    setGraphHeight(graphRef.current?.clientHeight * 0.85);
  },[xAxis, yAxis, editorState]);

  const renderYAxis = () => {
      const y = yAxis.map((value, index) => {
        if(value.key === "Handler"){
          const userObj = currUsers.find(user => user.uid === value.id);
          if(userObj){
            return <Line key={index} type={value.lineType} dataKey={userObj.displayName} stroke={value.color} strokeWidth={value.lineWidth} activeDot={{ r: value.activeDotRadius }}/>
          }
        } else {
          return (
            <Line key={index} type={value.lineType} dataKey={value.key} stroke={value.color} strokeWidth={value.lineWidth} activeDot={{ r: value.activeDotRadius }}/>
          );
        }
      })
      
      return y
  };
  const renderXAxis = () => {

    const x = xAxis.map((value, index) => (
      <XAxis key={index} 
        dataKey={value.key} 
        angle={value.angle} 
        tickSize={value.tickSize} 
        minTickGap={value.minTickGap} 
        padding={{ left: value.paddingLeft, right: value.paddingRight }} 
      />
    ))
  
      return x
    
  };
  
  return (
    <div className='graphContainer' ref={graphRef}>
      <LineChart
        width={graphWidth}
        height={graphHeight}
        data={graphData}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <YAxis unit={Unit ? Unit : ""} label={{ value: Label ? Label : "", angle: -90, position: 'insideLeft' }}/>
        <Tooltip />
        <Legend />
        {renderXAxis()}
        {renderYAxis()}
      </LineChart>
    </div>
  )
}

export default Graph_Line;

