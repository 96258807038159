import { createSlice, current } from "@reduxjs/toolkit";

import { useAppSelector } from "../../hooks/storeHooks";

const initialState = {
  //loading
  createFormTemplateLoading: false,
  retrieveFormTemplateLoading: false,
  updateFormTemplateLoading: false,
  deleteFormTemplateLoading: false,
  deleteAllSubmissionLoading: false,
  deleteAnalyticsByFormIDLoading: false,
  setFormContextLoading: false,

  //error
  createFormTemplateError: undefined,
  retrieveFormTemplateError: undefined,
  updateFormTemplateError: undefined,
  deleteFormTemplateError: undefined,
  deleteAllSubmissionError: undefined,
  deleteAnalyticsByFormIDError: undefined,
  setFormContextError : undefined,

  //data
  formTemplate: [],

  context : {
    memberSubmissions : [],
    memberFormTemplates : [],
    selectedGroupValue : null,
  },

  defaultContext : {
    memberSubmissions : [],
    memberFormTemplates : [],
    selectedGroupValue : null,
  }
};

const formTemplateSlice = createSlice({
  name: "formTemplates",
  initialState,
  reducers: {
    createFormTemplate(state) {
      state.createFormTemplateLoading = true;
    },

    createFormTemplateSuccess(state, { payload }) {
      const currentState = payload;
      state.formTemplate = currentState;
      state.createFormTemplateLoading = false;
    },

    createFormTemplateError(state, { payload }) {
      const errorState = payload;
      state.createFormTemplateError = errorState;
      state.createFormTemplateLoading = false;
    },

    retrieveFormTemplate(state) {
      state.retrieveFormTemplateLoading = true;
    },

    retrieveFormTemplateSuccess(state, { payload }) {
      const currentState = payload;
      state.formTemplate = currentState;
      state.retrieveFormTemplateLoading = false;
    },

    retrieveFormTemplateError(state, { payload }) {
      const errorState = payload;
      state.retrieveFormTemplateError = errorState;
      state.retrieveFormTemplateLoading = false;
    },

    updateFormTemplate(state) {
      state.updateFormTemplateLoading = true;
    },

    updateFormTemplateSuccess(state, { payload }) {
      const data = payload.data;
      const currentState = current(state.formTemplate);
      const updatedState = currentState.map((item) => {
        if (item.FormID === data.FormID) {
          return {
            ...data,
            targetMember : JSON.parse(data.targetMember),
            FormData : JSON.parse(data.FormData)
          };
        } else {
          return item
        }
      });

      state.formTemplate = updatedState;
      state.updateFormTemplateLoading = false;
    },

    updateFormTemplateError(state,{payload}){
        const errorState = payload
        state.updateFormTemplateError = errorState
        state.updateFormTemplateLoading = false;
    },

    deleteFormTemplate(state){
        state.deleteFormTemplateLoading = true
    },

    deleteFormTemplateSuccess(state, {payload}){
        const currentState = current(state.formTemplate)
        const updatedState = currentState.filter((item) => 
            item.FormID !== payload.FormID
        )
        state.formTemplate = updatedState
        state.deleteFormTemplateLoading = false
    },

    deleteFormTemplateError(state,{payload}){
        const errorState = payload
        state.deleteFormTemplateError = errorState
        state.deleteFormTemplateLoading = false
    },

    deleteAllSubmission(state){
        state.deleteAllSubmissionLoading = true
    },

    deleteAllSubmissionSuccess(state,{payload}){
        // const currentState = current(state.formTemplate)
        // const updatedState = currentState.filter((item) => {
        //     item.FormID !== payload.FormID
        // })
        // state.formTemplate = updatedState
        state.deleteAllSubmissionLoading = false
    },

    deleteAllSubmissionError(state,{payload}){
        const errorState = payload
        state.deleteAllSubmissionError = errorState
        state.deleteAllSubmissionLoading = false
    },

    deleteAnalyticsByFormID(state){
        state.deleteAnalyticsByFormIDLoading = true
    },

    deleteAnalyticsByFormIDSuccess(state,{payload}){
        // const currentState = current(state.formTemplate)
        // const updatedState = currentState.filter((item) => {
        //     item.FormID !== payload.FormID
        // })
        // state.formTemplate = updatedState
        state.deleteAnalyticsByFormIDLoading = false
    },

    deleteAnalyticsByFormIDError(state,{payload}){
        const errorState = payload
        state.deleteAnalyticsByFormIDError = errorState
        state.deleteAnalyticsByFormIDLoading = false
    },
    setFormContext(state, {payload}) {
      state.setFormContextLoading = true;
    },
    setFormContextSuccess(state, {payload}){
      const currentState = current(state.context);

      if(Object.values(payload).length > 0){
        const updatedState = {
          ...currentState,
          ...payload,
        }
        state.context = updatedState;
        
      } else {
        state.context = state.defaultContext;
      }
     
      state.setFormContextLoading = false;
    },
    setFormContextError(state, {payload}){
      state.setFormContextLoading = false;
    }
  },
});

export const {
    createFormTemplate,
    createFormTemplateSuccess,
    createFormTemplateError,
    retrieveFormTemplate,
    retrieveFormTemplateSuccess,
    retrieveFormTemplateError,
    updateFormTemplate,
    updateFormTemplateSuccess,
    updateFormTemplateError,
    deleteFormTemplate,
    deleteFormTemplateSuccess,
    deleteFormTemplateError,
    deleteAllSubmission,
    deleteAllSubmissionSuccess,
    deleteAllSubmissionError,
    deleteAnalyticsByFormID,
    deleteAnalyticsByFormIDSuccess,
    deleteAnalyticsByFormIDError,
    setFormContext,
    setFormContextError,
    setFormContextSuccess
} = formTemplateSlice.actions

//export selector
// data
export const selectFormTemplate = () =>
  useAppSelector((state) => state.formTemplates.formTemplate);

export const selectFormContext = () =>
  useAppSelector((state) => state.formTemplates.context);

// loading
export const selectCreateFormTemplateLoading = () =>
  useAppSelector((state) => state.formTemplates.createFormTemplateLoading);

export const selectRetrieveFormTemplateLoading = () =>
  useAppSelector((state) => state.formTemplates.retrieveFormTemplateLoading);

export const selectUpdateFormTemplateLoading = () =>
  useAppSelector((state) => state.formTemplates.updateFormTemplateLoading);

export const selectDeleteFormTemplateLoading = () =>
  useAppSelector((state) => state.formTemplates.deleteFormTemplateLoading);

export const selectDeleteAllSubmissionLoading = () =>
  useAppSelector((state) => state.formTemplates.deleteAllSubmissionLoading);

export const selectDeleteAnalyticsByFormIDLoading = () =>
  useAppSelector((state) => state.formTemplates.deleteAnalyticsByFormIDLoading);

// error
export const selectCreateFormTemplateError = () =>
  useAppSelector((state) => state.formTemplates.createFormTemplateError);

export const selectRetrieveFormTemplateError = () =>
  useAppSelector((state) => state.formTemplates.retrieveFormTemplateError);

export const selectUpdateFormTemplateError = () =>
  useAppSelector((state) => state.formTemplates.updateFormTemplateError);

export const selectDeleteFormTemplateError = () =>
  useAppSelector((state) => state.formTemplates.deleteFormTemplateError);

export const selectDeleteAllSubmissionError = () =>
  useAppSelector((state) => state.formTemplates.deleteAllSubmissionError);

export const selectDeleteAnalyticsByFormIDError = () =>
  useAppSelector((state) => state.formTemplates.deleteAnalyticsByFormIDError);

const formTemplateReducer = formTemplateSlice.reducer

export default formTemplateReducer
