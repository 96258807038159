import { TABLE } from "./Table";
import { TableBulkAction } from "./TableBulkAction";

import {
  SalesOrdersColumn,
  QuotationsColumn,
  SaleCreditNotedColumn,
  DeliveryOrdersColumn,
  InvoicesColumn,
  SalePaymentsColumn,
  SaleRefundsColumn,
} from "./TableColumn/Sale/";

import {
  BillsColumn,
  GoodsReceivedNotesColumn,
  PurchaseCreditNotedColumn,
  PurchaseOrdersColumn,
  PurchasePaymentsColumn,
  PurchaseRefundsColumn,
} from "./TableColumn/Purchase/";
import { FormItemsColumn, FormPaymentsColumn, TransactionPaymentsColumn, TransactionPaymentTermsColumn } from "./TableColumn/Transaction";

const initPaginationModel = (pageSize) => {
  return {
    pagination: {
      paginationModel: {
        page: 0,
        pageSize: pageSize,
      },
      aggregation: {
        model: {
          amount: "sum",
        },
      },
    },
  };
};
const defaultPagePerSize = 30;
const defaultPageSizeOptions = [10, 30, 50, 100];

export const TableContent = {

  //sales
  [TABLE.SALES.QUOTATIONS]: {
    property: {
      checkboxSelection: false,
      initialState: initPaginationModel(defaultPagePerSize),
      pageSizeOptions: defaultPageSizeOptions,
    },
    columns: QuotationsColumn,
    bulkActions: (onClickBulk) => [
      TableBulkAction.PDF(onClickBulk),
      TableBulkAction.EMAIL(onClickBulk),
      TableBulkAction.UPDATE(onClickBulk),
      TableBulkAction.DELETE(onClickBulk),
    ],
    showSubTotal: false,

    getRowId: (row) => {
      return row.transaction_id;
    },
  },
  [TABLE.SALES.SALE_ORDERS]: {
    property: {
      checkboxSelection: false,
      initialState: initPaginationModel(defaultPagePerSize),
      pageSizeOptions: defaultPageSizeOptions,
    },
    columns: SalesOrdersColumn,
    showSubTotal: false,

    getRowId: (row) => {
      return row.transaction_id;
    },
  },
  [TABLE.SALES.CREDIT_NOTES]: {
    property: {
      checkboxSelection: false,
      initialState: initPaginationModel(defaultPagePerSize),
      pageSizeOptions: defaultPageSizeOptions,
    },
    columns: SaleCreditNotedColumn,
    showSubTotal: false,

    bulkActions: (onClickBulk) => [
      TableBulkAction.PDF(onClickBulk),
      TableBulkAction.EMAIL(onClickBulk),
      TableBulkAction.UPDATE(onClickBulk),
      TableBulkAction.DELETE(onClickBulk),
    ],
    getRowId: (row) => {
      return row.transaction_id;
    },
  },
  [TABLE.SALES.DELIVERY_ORDERS]: {
    property: {
      checkboxSelection: false,
      initialState: initPaginationModel(defaultPagePerSize),
      pageSizeOptions: defaultPageSizeOptions,
    },
    columns: DeliveryOrdersColumn,
    showSubTotal: false,

    bulkActions: (onClickBulk) => [
      TableBulkAction.PDF(onClickBulk),
      TableBulkAction.EMAIL(onClickBulk),
      TableBulkAction.UPDATE(onClickBulk),
      TableBulkAction.DELETE(onClickBulk),
    ],
    getRowId: (row) => {
      return row.transaction_id;
    },
  },
  [TABLE.SALES.INVOICES]: {
    property: {
      checkboxSelection: false,
      initialState: initPaginationModel(defaultPagePerSize),
      pageSizeOptions: defaultPageSizeOptions,
    },
    columns: InvoicesColumn,
    showSubTotal: false,

    bulkActions: (onClickBulk) => [
      TableBulkAction.PDF(onClickBulk),
      TableBulkAction.EMAIL(onClickBulk),
      TableBulkAction.UPDATE(onClickBulk),
      TableBulkAction.DELETE(onClickBulk),
    ],
    getRowId: (row) => {
      return row.transaction_id;
    },
  },
  [TABLE.SALES.PAYMENTS]: {
    property: {
      checkboxSelection: false,
      initialState: initPaginationModel(defaultPagePerSize),
      pageSizeOptions: defaultPageSizeOptions,
    },
    columns: SalePaymentsColumn,
    showSubTotal: false,

    bulkActions: (onClickBulk) => [
      TableBulkAction.PDF(onClickBulk),
      TableBulkAction.EMAIL(onClickBulk),
      TableBulkAction.UPDATE(onClickBulk),
      TableBulkAction.DELETE(onClickBulk),
    ],
    getRowId: (row) => {
      return row.transaction_id;
    },
  },
  [TABLE.SALES.REFUNDS]: {
    property: {
      checkboxSelection: false,
      initialState: initPaginationModel(defaultPagePerSize),
      pageSizeOptions: defaultPageSizeOptions,
    },
    columns: SaleRefundsColumn,
    showSubTotal: false,

    getRowId: (row) => {
      return row.transaction_id;
    },
    bulkActions: (onClickBulk) => [
      TableBulkAction.PDF(onClickBulk),
      TableBulkAction.EMAIL(onClickBulk),
      TableBulkAction.UPDATE(onClickBulk),
      TableBulkAction.DELETE(onClickBulk),
    ],
  },

  //purchase
  [TABLE.PURCHASES.BILLS]: {
    property: {
      checkboxSelection: false,
      initialState: initPaginationModel(defaultPagePerSize),
      pageSizeOptions: defaultPageSizeOptions,
    },
    columns: BillsColumn,
    bulkActions: (onClickBulk) => [
      TableBulkAction.PDF(onClickBulk),
      TableBulkAction.UPDATE(onClickBulk),
      TableBulkAction.DELETE(onClickBulk),
    ],
    getRowId: (row) => {
      return row.transaction_id;
    },
  },
  [TABLE.PURCHASES.CREDIT_NOTES]: {
    property: {
      checkboxSelection: false,
      initialState: initPaginationModel(defaultPagePerSize),
      pageSizeOptions: defaultPageSizeOptions,
    },
    columns: PurchaseCreditNotedColumn,
    bulkActions: (onClickBulk) => [
      TableBulkAction.PDF(onClickBulk),
      TableBulkAction.UPDATE(onClickBulk),
      TableBulkAction.DELETE(onClickBulk),
    ],
    getRowId: (row) => {
      return row.transaction_id;
    },
  },
  [TABLE.PURCHASES.GOODS_RECEIVED_NOTES]: {
    property: {
      checkboxSelection: false,
      initialState: initPaginationModel(defaultPagePerSize),
      pageSizeOptions: defaultPageSizeOptions,
    },
    columns: GoodsReceivedNotesColumn,
    bulkActions: (onClickBulk) => [
      TableBulkAction.PDF(onClickBulk),
      TableBulkAction.EMAIL(onClickBulk),
      TableBulkAction.UPDATE(onClickBulk),
      TableBulkAction.DELETE(onClickBulk),
    ],
    getRowId: (row) => {
      return row.transaction_id;
    },
  },
  [TABLE.PURCHASES.PAYMENTS]: {
    property: {
      checkboxSelection: false,
      initialState: initPaginationModel(defaultPagePerSize),
      pageSizeOptions: defaultPageSizeOptions,
    },
    columns: PurchasePaymentsColumn,
    bulkActions: (onClickBulk) => [
      TableBulkAction.PDF(onClickBulk),
      TableBulkAction.EMAIL(onClickBulk),
      TableBulkAction.UPDATE(onClickBulk),
      TableBulkAction.DELETE(onClickBulk),
    ],
    getRowId: (row) => {
      return row.transaction_id;
    },
  },
  [TABLE.PURCHASES.PURCHASE_ORDERS]: {
    property: {
      checkboxSelection: false,
      initialState: initPaginationModel(defaultPagePerSize),
      pageSizeOptions: defaultPageSizeOptions,
    },
    columns: PurchaseOrdersColumn,
    bulkActions: (onClickBulk) => [
      TableBulkAction.PDF(onClickBulk),
      TableBulkAction.EMAIL(onClickBulk),
      TableBulkAction.UPDATE(onClickBulk),
      TableBulkAction.DELETE(onClickBulk),
    ],
    getRowId: (row) => {
      return row.transaction_id;
    },
  },
  [TABLE.PURCHASES.REFUNDS]: {
    property: {
      checkboxSelection: false,
      initialState: initPaginationModel(defaultPagePerSize),
      pageSizeOptions: defaultPageSizeOptions,
    },
    columns: PurchaseRefundsColumn,
    bulkActions: (onClickBulk) => [
      TableBulkAction.PDF(onClickBulk),
      TableBulkAction.EMAIL(onClickBulk),
      TableBulkAction.UPDATE(onClickBulk),
      TableBulkAction.DELETE(onClickBulk),
    ],
    getRowId: (row) => {
      return row.transaction_id;
    },
  },

   //Transactions table
   [TABLE.TRANSACTIONS.FORM_ITEMS]: {
      columns: FormItemsColumn,
    },

    [TABLE.TRANSACTIONS.TRANSACTION_PAYMENT_TERMS]: {
      columns: TransactionPaymentTermsColumn,
    },

    [TABLE.TRANSACTIONS.TRANSACTION_PAYMENTS]: {
      columns: TransactionPaymentsColumn,
    },
    [TABLE.TRANSACTIONS.FORM_PAYMENTS]: {
      columns: FormPaymentsColumn,
    },

};
