const component = {
  MuiSelect: {
    styleOverrides: {
      root: ({ theme }) => ({
        ":hover": {
          background: theme.palette.background.grey,
        },
        "&.Mui-focused fieldset": {
          borderColor: "#5D45FE !important",
        },
        "& .MuiSelect-select": {
          padding: "15px !important",
        },
        "& .MuiSelect-icon": {
          width: "24px !important",
          height: "24px !important",
          top: "25% !important",
          right: "5% !important",
        },
        "&.Mui-focused": {
          //   border: "2px solid #E6E6E6 !important",
          paddingTop: "20px",
          border: "none",
          // backgroundColor: "#E6E6E6 !important",
        },
      }),
    },
  },
};

export default component;
