import { createTheme } from "@mui/material/styles";
import { deepmerge } from "@mui/utils";

const primary = {
  0: "#DFFFF3",
  100: "#5D45FE",
  color_primary: "#5D45FE",
  color_secondary: "#757D8A",
  color_white: "#ffffff",
  color_success: "#0CC582",
  color_info: "#487BFF",
  color_warning: "#FFD300",
  color_danger: "#FF6B6B",
  color_disabled: "#AAB0B7",
  bg_default: "#F5F6FA",
  bg_white: "#ffffff",
  bg_primary: "#5D45FE",
  bg_secondary: "tranparent",
  bg_disabled: "#F8F8F8",
  bg_success: "#C0F0DF",
  bg_info: "#E4ECFF",
  bg_warning: "#FFF9DC",
  bg_danger: "#FFD4D4",
  bg_breadCrumb: "#DFFFF3",
  bg_contained: " #E6E6E6",
  txt_white: "#ffffff",
  txt_default: "#1C2534",
  txt_primary: "#5D45FE",
  txt_secondary: "#757D8A",
  txt_label: "#757D8A",
  txt_disabled: "#AAB0B7",
  txt_success: "#0CC582",
  txt_info: "#487BFF",
  txt_warning: "#FFD300",
  txt_danger: "#FF6B6B",
  txt_asterisk: "#FF6B6B",
  border_default: "#E1E6E4",
  border_active: "#5D45FE",
  border_hover: "#5D45FE",
  border_info: "#487BFF",
  border_warning: "#FFD300",
  border_danger: "#FF6B6B",
  border_delete: "#FF6B6B",
  border_disabled: "#E1E6E4",
};

const neutral = {
  0: "#FFFFFF",
  25: "#E1E6E4",
  50: "#F8F8F8",
  75: "#757D8A",
  100: "#1C2534",
  125: "#AAB0B7",
  150: "#A9A9A9",
};

const neutralVariant = {
  0: "#487BFF",
  50: "#FFE66D",
  100: "#FF6B6B",
  150: "#C0F0DF", //success
  200: "#d48806", //warning
  250: "#E4ECFF", //pending
  300: "#E6E8E6", //info
  350: "#989CA0",
  400: "#FFD4D4", //error
  450: "#ffe58f", //warning bg
};

const loader = {
  0: "rgb(237, 237, 237, 40%)",
};

const disabled = {
  0: "rgba(248, 248, 248, 1)",
};

const { palette: defaultMuiPalette } = createTheme();

const palette = deepmerge(defaultMuiPalette, {
  primary: {
    // main: primary[100],
    // light: primary[0],
    // white: neutral[0],
    white: primary.color_white,
    primary: primary.color_primary,
    secondary: primary.color_secondary,
    success: primary.color_success,
    info: primary.color_info,
    warning: primary.color_warning,
    danger: primary.color_danger,
    disabled: primary.color_disabled,
  },
  text: {
    main: neutral[100],
    // info: neutral[50],
    light: neutral[75],
    white: primary.txt_white,
    default: primary.txt_default,
    primary: primary.txt_primary,
    secondary: primary.txt_secondary,
    label: primary.txt_label,
    disabled: primary.txt_disabled,
    success: primary.txt_success,
    info: primary.txt_info,
    warning: primary.txt_warning,
    danger: primary.txt_danger,
    asterisk: primary.txt_asterisk,
  },
  snack: {
    success: primary[100],
    warning: neutralVariant[50],
    error: neutralVariant[100],
  },
  surface: {
    default: neutral[50],
    main: primary[0],
  },
  background: {
    // default: neutral[0],
    // secondary: neutral[25],
    grey: neutral[50],
    containedBg: primary.bg_contained,
    breadcrumbBg: primary.bg_breadCrumb,
    default: primary.bg_default,
    white: primary.bg_white,
    primary: primary.bg_primary,
    secondary: primary.bg_secondary,
    disabled: primary.bg_disabled,
    success: primary.bg_success,
    info: primary.bg_info,
    warning: primary.bg_warning,
    danger: primary.bg_danger,
  },
  border: {
    main: neutral[25],
    light: neutral[125],
    grey: neutral[150],
    // active: primary[100],

    default: primary.border_default,
    active: primary.border_active,
    delete: primary.border_delete,
    hover: primary.border_hover,
    disabled: primary.border_disabled,
  },
  input: {
    selected: neutralVariant[150],
  },
  fileInput: {
    light: neutral[50],
  },
  icon: {
    // main: neutral[75],
    // active: primary[100],
    default: primary.color_secondary,
    primary: primary.color_primary,
    white: primary.color_white,
    delete: primary.color_danger,
    disabled: primary.color_disabled,
  },
  action: {
    default: neutral[100],
    delete: neutralVariant[100],
    disabled: disabled[0],
    main: primary[100],
  },
  tooltips: {
    main: neutral[100],
  },
  label: {
    warn: neutralVariant[100],
  },
  progress: {
    ongoing: neutralVariant[0],
    complete: primary[100],
  },
  chip: {
    // success: primary[100],
    // warning: neutralVariant[200],
    // pending: neutralVariant[0],
    // info: neutralVariant[350],
    // error: neutralVariant[100],
    // successBg: neutralVariant[150],
    // warningBg: neutralVariant[450],
    // pendingBg: neutralVariant[250],
    // infoBg: neutralVariant[300],
    // errorBg: neutralVariant[400],
    primaryBg: primary.bg_primary,

    success: primary.txt_success,
    successBg: primary.bg_success,

    warning: primary.txt_warning,
    warningBg: primary.bg_warning,

    pending: primary.txt_info,
    pendingBg: primary.bg_info,

    error: primary.txt_danger,
    errorBg: primary.bg_danger,

    info: primary.txt_secondary,
    infoBg: primary.bg_disabled,
  },
  table: {
    hover: primary.bg_disabled,
  },
  loader: {
    backdrop: loader[0],
  },
});

export default palette;
