
import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import C_Loading from "../components/C_Loading";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import { MdNavigateBefore, MdNavigateNext} from "react-icons/md";
import moment from "moment";
import * as Icons from "react-icons/fa";
import C_Search from "../components/C_Search";
import C_Button from "../components/C_Button";
import { BiArrowBack, BiSortAlt2 } from "react-icons/bi";

import { ArrowUp } from "react-feather";
import ReactPaginate from 'react-paginate';
import { AiOutlineClose } from "react-icons/ai";
import { Modal } from "bootstrap";
import "./ActivityLogs.scss"
import ReactQuill from "react-quill";
import Colors from "../constants/Colors";
import { CgArrowLongRightR } from "react-icons/cg";
import { BsArrowDown, BsArrowUp } from "react-icons/bs";

// import { retrieveAllLogsCount, retrieveLogs } from '../redux/actions/logs.actions';
// import { retrieveClientSelected } from "../redux/actions/client_selector.actions";
// import { retrieveClientGroups } from "../redux/actions/client_groups_actions";

//slices
import { retrieveAllLogsCount, retrieveLogs, selectLogs, selectLogsCount } from "../stores/slices/logSlices";
import { retrieveClientSelected, selectClientSelected } from "../stores/slices/clientSelectorSlices";
import { retrieveClientGroups, selectClientGroups } from "../stores/slices/clientGroupSlices";
import { selectCompanyID } from "../stores/slices/companyIDSlices";
import { selectToggleTabState } from "../stores/slices/clientSlices";
import { selectCurrTenant } from "../stores/slices/persistRolesSlices";
import { selectCurrUserContext } from "../stores/slices/companySlices";
import { selectUser } from "../stores/slices/userAuthSlices";

function ActivityLogs(props) {

  const isSuperAdmin = props.isSuperAdmin;
  const Activity_ViewAll = props.Activity_ViewAll;
  const logSection = [
    {
      Section: 'Roles',
      Color: '#32a85a',
      Icon: 'FaUnlockAlt',
    },
    {
      Section: 'Members',
      Color: '#327da8',
      Icon: 'FaAddressBook',
    },
    {
      Section: 'Clients',
      Color: '#a832a6',
      Icon: 'FaUsers',
    },
    {
      Section: 'Appointments',
      Color: '#f54272',
      Icon: 'FaCalendarAlt',
    },
  ]


  const personalRef = useRef(null)
  const allRef = useRef(null)
  const user = selectUser();
  const { currUserSA } = selectCurrUserContext();
  const dispatch = useDispatch();
  const HEIGHT = window.innerHeight * 0.8 

  const companyID = selectCompanyID();
  const logs = selectLogs();
  const logsCount = selectLogsCount();
  const toggleTabState = selectToggleTabState();
  const client_selected = selectClientSelected();
  const client_groups = selectClientGroups();
  const tenant = selectCurrTenant();

  const [userLogs, setUserLogs] = useState(logs);
  const [isLoading, setIsLoading] = useState(false);

  const [searchMember,setSearchMember] = useState("");
  const [selectedMember,setSelectedMember] = useState([]);
  const [selectedAll,setSelectedAll] = useState(false);
  const [sortValue,setSortValue] = useState("Newest");
  const [sortModal,setSortModal] = useState(false);
  const [loadData, setLoadData] = useState(true);

  const sectionFilter = [
    "Clients",
    "Appointments"
  ]

  const actionFilter = [
    {
        actionName:"Create",
        actionValue:"create"
    },
    {
        actionName:"Update",
        actionValue:"update"
    },
    {
        actionName:"Bulk Update",
        actionValue:"bulk_update"
    },
    {
        actionName:"Delete",
        actionValue:"delete"
    },
    {
        actionName:"Bulk Delete",
        actionValue:"bulk_delete"
    },
    {
        actionName:"Import",
        actionValue:"import"
    }
]

  const [isAll,setIsAll] = useState(true);
  const [isPersonal,setIsPersonal] = useState(false);
  const [currIndex,setCurrIndex]= useState("");
  const [uniqueColumns,setUniqueColumns] = useState([]);
  const [isAttendees,setIsAttendees] = useState("");
  const [existingAttendees,setExistingAttendees] = useState([]);
  const [addedAttendees,setAddedAttendees] = useState([]);
  const [removedAttendees,setRemovedAttendees] = useState([]);
  const [existingClientAttendees,setExistingClientAttendees] = useState([]);
  const [addedClientAttendees,setAddedClientAttendees] = useState([]);
  const [removedClientAttendees,setRemovedClientAttendees] = useState([]);
  const [clientSelectedList,setClientSelectedList] = useState([]);
  const [selectedSection,setSelectedSection] = useState([]);
  const [selectedAction,setSelectedAction] = useState([]);
  const [toggleDropdown,setToggleDropdown] = useState(false);
  const [currentPage,setCurrentPage] = useState(0);

  const modalRef = useRef();

  const showModalFunc = () => {
    const modalEle = modalRef.current;
    const bsModal = new Modal(modalEle, {
      backdrop: "static",
      keyboard: false,
    });
    bsModal.show();
  };

  const hideModalFunc = () => {
    const modalEle = modalRef.current;
    const bsModal = Modal.getInstance(modalEle);
    bsModal.hide();
  };

  useEffect(() => {
    if(client_selected !== undefined && client_selected !== null && Array.isArray(client_selected)){
        
        setClientSelectedList(client_selected)
    }

  },[client_selected])

  useEffect(()=>{

    if(tenant){
      if(currIndex !== "")
      {
        if(userLogs[currIndex]?.Section === "Clients")
        {
          let uniqueCol = [];
    
          //create uniqueColumns for rendering table purposes
          isJSON(userLogs[currIndex]?.Keyword) && JSON.parse(userLogs[currIndex]?.Keyword).length > 0 && (
       
              JSON.parse(userLogs[currIndex]?.Keyword).map((data, index) => {
                const columns = Array.isArray(data) ? data.map(item => item.columnName) : data.columnName;
                Array.isArray(data) ? uniqueCol = [...new Set(columns)] : uniqueCol.push(columns)
               
              })
           
          )
          setUniqueColumns(uniqueCol);
        }
        else
        {
          let oriMemberAttendees = isJSON(userLogs[currIndex]?.Keyword) ? JSON.parse(userLogs[currIndex]?.Keyword)[0].oriValue.memberAttendees : []; //Array object
          let changedMemberAttendees = isJSON(userLogs[currIndex]?.Keyword) ? JSON.parse(userLogs[currIndex]?.Keyword)[0].changedValue.memberAttendees : [];  //Array object
          let oriClientAttendees = isJSON(userLogs[currIndex]?.Keyword) ? JSON.parse(userLogs[currIndex]?.Keyword)[0].oriValue.clientAttendees : []; //Array object
          let changedClientAttendees = isJSON(userLogs[currIndex]?.Keyword) ? JSON.parse(userLogs[currIndex]?.Keyword)[0].changedValue.clientAttendees : []; //Array 
          let mergedArray = oriClientAttendees.concat(changedClientAttendees?.filter(value => !oriClientAttendees.includes(value)));
          dispatch(retrieveClientSelected({ TenantID:tenant, selectedClient: mergedArray }));
  
          // data processing into "Added", "Removed", "Existing"
          const existingMemberAttendees = changedMemberAttendees;
          const removedMemberAttendees = oriMemberAttendees.length > 0 ? oriMemberAttendees?.filter(item => !changedMemberAttendees?.some(obj => obj.uid === item.uid)) : [];
          const addedMemberAttendees = changedMemberAttendees.length > 0 ? changedMemberAttendees?.filter(item => !oriMemberAttendees?.some(obj => obj.uid === item.uid)) : [];
          
          const existingClientAttendees = changedClientAttendees;
          const removedClientAttendees = oriClientAttendees.length > 0 ? oriClientAttendees?.filter(item => !changedClientAttendees.includes(item)) : [];
          const addedClientAttendees = changedClientAttendees.length > 0 ? changedClientAttendees?.filter(item => !oriClientAttendees.includes(item)) : [];
          
          setExistingAttendees(existingMemberAttendees);
          setRemovedAttendees(removedMemberAttendees);
          setAddedAttendees(addedMemberAttendees);
          
          setExistingClientAttendees(existingClientAttendees);
          setRemovedClientAttendees(removedClientAttendees);
          setAddedClientAttendees(addedClientAttendees);
    
        }
      }
    }
    
  },[currIndex, isAttendees, tenant])

  useEffect(()=>{
    if(logs !== undefined && logs !== null && Array.isArray(logs)){
        setUserLogs(logs)
    }
    
  },[logs])

  useEffect(() => {
    if(isSuperAdmin || Activity_ViewAll){
      setIsAll(true);
      setIsPersonal(false);
    }else{
      setIsAll(false);
      setIsPersonal(true);
    }
  },[isSuperAdmin, Activity_ViewAll])

  useEffect(() => {
  

    const CurrPage = 0;

    if(tenant && companyID){
      if(isAll){
          dispatch(retrieveClientGroups({TenantID:tenant,CompanyID:companyID})),
          dispatch(retrieveAllLogsCount({TenantID:tenant,CompanyID: companyID, GroupID:"",UserID: "", MemberID: selectedMember, Action:selectedAction, Section: selectedSection })),
          dispatch(retrieveLogs({ TenantID:tenant,CompanyID: companyID, GroupID:"",UserID: "", CurrPage, MemberID: selectedMember, Action:selectedAction, Section: selectedSection })),
          //SAGA Timeout
          setTimeout(() => {
            setLoadData(false);
            
          },1000)
      
      }

      if(isPersonal){
            dispatch(retrieveClientGroups({ TenantID:tenant, CompanyID:companyID})),
            dispatch(retrieveAllLogsCount({ TenantID:tenant, CompanyID: companyID, GroupID:"", UserID: "", MemberID: [], Action:selectedAction, Section: selectedSection })),
            dispatch(retrieveLogs({ TenantID:tenant, CompanyID: companyID, GroupID:"", UserID: "", CurrPage, MemberID: [], Action:selectedAction, Section: selectedSection }))
            //SAGA Timeout
            setTimeout(() => {
              setLoadData(false);
              
            },1000)
      }
    }


  }, [isAll,isPersonal]);

  useEffect(()=>{
    
    const updatedArray = [...logs]?.sort((a,b)=>{

        if(sortValue === "Newest")
        {
            return moment(b.CreatedAt) - moment(a.CreatedAt)
        }
        else if(sortValue === "Oldest")
        {
            return moment(a.CreatedAt) - moment(b.CreatedAt)
        }

    })
    .map((data)=>{
        return data
    })

    setUserLogs(updatedArray)

},[sortValue])

  function retrieveLogsFunction(retrieveFunc) {
    setLoadData(true);
    return Promise.all(
      retrieveFunc
    );
  }

  const applyFilter = (type) =>{
    const CurrPage = 0;
    
    async function typeChecker(){
      let member = [];
      let action = [];
      let section = [];

      if(type === "clear"){
        setSelectedAction([]);
        setSelectedSection([]);
        setSelectedMember([]);
        setSelectedAll(false);
      } else {
        member = selectedMember;
        action = selectedAction;
        section = selectedSection
      }

      return {member, action, section}
    }
    
    typeChecker().then(({ member, action, section }) => {
      if(isAll){
        retrieveLogsFunction([
          dispatch(retrieveAllLogsCount({ TenantID:tenant, CompanyID: companyID, GroupID:"", UserID: "", MemberID: member, Action: action, Section: section })),
          dispatch(retrieveLogs({ TenantID:tenant, CompanyID: companyID, GroupID:"", UserID: "", CurrPage, MemberID: member, Action: action, Section: section })),
        ])
        .then(() => {
          setTimeout(() => {
            setLoadData(false);
            setCurrentPage(0);
            
          },1000)
        })
       
      }
  
      if(isPersonal){
        retrieveLogsFunction([
          dispatch(retrieveAllLogsCount({ TenantID:tenant, CompanyID: companyID, GroupID:"", UserID: user.uid, MemberID: [], Action: action, Section: section })),
          dispatch(retrieveLogs({ TenantID:tenant, CompanyID: companyID, GroupID:"",  UserID: user.uid, CurrPage, MemberID: [], Action: action, Section: section }))
        ])
        .then(() => {
          setTimeout(() => {
            setLoadData(false);
            setCurrentPage(0);
            
          },1000)
        })
      }
      setCurrIndex("");
      setSortValue("Newest")
    })

  }

  function onHandlePageChange(currPage){

    const CurrPage = currPage;

    if(isAll){
      retrieveLogsFunction([
        dispatch(retrieveAllLogsCount({ TenantID:tenant, CompanyID: companyID, GroupID:"", UserID: "", MemberID: selectedMember, Action:selectedAction, Section: selectedSection })),
        dispatch(retrieveLogs({ TenantID:tenant, CompanyID: companyID, GroupID:"", UserID: "", CurrPage, MemberID: selectedMember, Action:selectedAction, Section: selectedSection })),
      ])
      .then(() => {
        setTimeout(() => {
          setLoadData(false);
          setCurrentPage(CurrPage);
          
        },1000)
      })
     
    }

    if(isPersonal){
      retrieveLogsFunction([
        dispatch(retrieveAllLogsCount({ TenantID:tenant, CompanyID: companyID, GroupID:"", UserID: user.uid, MemberID: [], Action:selectedAction, Section: selectedSection })),
        dispatch(retrieveLogs({ TenantID:tenant, CompanyID: companyID, GroupID:"", UserID: user.uid, CurrPage, MemberID: [], Action:selectedAction, Section: selectedSection }))
      ])
      .then(() => {
        setTimeout(() => {
          setLoadData(false);
          setCurrentPage(CurrPage);
          
        },1000)
      }) 
    }
    
  }
  
  function onHandleAllLogs(){

    const CurrPage = 0;

    setIsAll(true);
    setIsPersonal(false);

    retrieveLogsFunction([
      dispatch(retrieveAllLogsCount({ TenantID:tenant, CompanyID: companyID,  GroupID:"", UserID: "", MemberID: selectedMember, Action:selectedAction, Section: selectedSection })),
      dispatch(retrieveLogs({ TenantID:tenant, CompanyID: companyID, GroupID:"", UserID: "", CurrPage, MemberID: selectedMember, Action:selectedAction, Section: selectedSection })),
    ])
    .then(() => {
      setTimeout(() => {
        setLoadData(false);
        setCurrentPage(CurrPage);
        
      },1000)
    })

  }

  function onHandlePersonalLogs(){

    const CurrPage = 0;

    setIsAll(false);
    setIsPersonal(true);

    retrieveLogsFunction([
      dispatch(retrieveAllLogsCount({ TenantID:tenant, CompanyID: companyID,  GroupID:"", UserID: user.uid, MemberID: [], Action:selectedAction, Section: selectedSection })),
      dispatch(retrieveLogs({ TenantID:tenant, CompanyID: companyID, GroupID:"",  UserID: user.uid, CurrPage, MemberID: [], Action:selectedAction, Section: selectedSection }))
    ])
    .then(() => {
      setTimeout(() => {
        setLoadData(false);
        setCurrentPage(CurrPage);
        
      },1000)
    })

  }
  
  const isJSON = (str) => {
    if (str !== undefined) {
      // Check if the string is not numeric
      if (!isNaN(str)) {
        return false;
      }
  
      // Check if the string is a valid JSON
      try {
        JSON.parse(str);
        return true;
      } catch (e) {
        return false;
      }
    }
  };

  const is2DArray = (str) => {
    if (str !== undefined) {
      try {
        const parsedArray = JSON.parse(str);
        if (Array.isArray(parsedArray) && parsedArray.every(item => Array.isArray(item))) {
          return true;
        } else {
          return false;
        }
      } catch (e) {
        return false;
      }
    }
    return false;
  };

  const handleSelectedSection = (isSelected,section) => {

    if(isSelected){
        const newList = selectedSection.filter(data => data !== section);
        setSelectedSection(newList)
    }else{
        const newList = [...selectedSection, section];
        setSelectedSection(newList)
    }
  }

  const handleSelectedAction = (isSelected,actionValue) => {

    if(isSelected){
        const newList = selectedAction.filter(data => data !== actionValue);
        setSelectedAction(newList)
    }else{
        const newList = [...selectedAction, actionValue];
        setSelectedAction(newList)
    }
  }

  const handleSelectedAllMember = () =>{
   
    if(!selectedAll)
    {
        setSelectedAll(true)
        let updatedSelectedMember = currUserSA.map(item=>{return {uid:item.uid}})
        setSelectedMember(updatedSelectedMember);
    }
    else
    {
        setSelectedAll(false)
        setSelectedMember([]);
    }
   
  }

  const handleSelectedMember = (e) =>{
    setSelectedAll(false);
    let updatedSelectedMember = [...selectedMember];

    if(e.target.checked)
    {
        updatedSelectedMember.push({uid:e.target.value})
    }
    else
    {   
        updatedSelectedMember = updatedSelectedMember.filter(data=>data.uid !== e.target.value);
    }

    setSelectedMember(updatedSelectedMember);
   
  }
  
  return (
    <>
      <C_Loading isLoading={isLoading} />
      
        <div className="sidebar-main-container">
          <Sidebar toggle={toggleTabState} />
        </div>
        <div className="main-container">
          <Header title={"ActivityLogs"} />
          <div className="d-flex p-4" style={{ }}>
            <div className="col-4" style={{ position: 'relative', height: HEIGHT, background: 'white', border: 'none', borderRadius: '8px 0px 0px 8px', borderRight: 'solid 1px', borderColor: '#f2f2f2',overflow:'scroll' }}>
               <div className="filter-footer">
                  <span className="clear-filter" onClick={()=>{applyFilter("clear");}}>Clear All</span>
                  <C_Button
                    background={"#5f5eaf"}
                    width={"120px"}
                    buttonText={"Apply Filter"}
                    justify={"center"}
                    onClick={() => {
                      applyFilter("apply");
                    }}
                    textColor={"#FFFFFF"}
                  />
                </div>
             
              <div className="card" style={{ background: 'white', paddingBlock: '20px', paddingInline: '30px', border: 'none' }}>
                <h6 style={{ whiteSpace: 'nowrap',marginBottom:'10px',display:'flex',alignItems:'center' }}>Filters</h6>
                {userLogs.length > 0 ?  
                <div className="card-body">
                  <div className="filter-inner-container">
                    <div className="filter-section-container">
                    <span>Section</span>
                      <div className="filter-section">
                        {sectionFilter.map((section,index)=>{
                          let isSelected = selectedSection.includes(section)
                          return(
                            <div className={`${isSelected ? "tag-clicked" : "tag-default"} filter-section-tag`} 
                            onClick={()=>{handleSelectedSection(isSelected,section)}}>
                              {section}
                            </div>
                          )
                        })}
                      </div>
                    </div>
                    <div className="filter-action-container">
                      <span>Action</span>
                      <div className="filter-action">
                        {actionFilter.map((action,index)=>{
                          let isSelected = selectedAction.includes(action.actionValue)
                          return(
                            <div className={`${isSelected ? "tag-clicked" : "tag-default"} filter-action-tag`} 
                            onClick={()=>{handleSelectedAction(isSelected,action.actionValue)}}>
                              {action.actionName}
                            </div>
                          );
                          
                        })}
                      </div>
                    </div>
                    {/* {isAll &&
                    <div className="filter-member-container">
                      <div className="filter-member-title">
                        <span>Members</span>
                      </div>
                      <div className="filter-member-list-search-container">
                          <C_Search
                              placeholder="Search"
                              onChange={(e) => {
                                  setSearchMember(e.target.value);
                              }}
                              borderRadius={"7px"}
                              height={"33px"}
                              containerWidth={"97%"}
                              padding={"10px"}
                              value={searchMember}
                              searchText={searchMember}
                              closeFunction={() => {
                                  setSearchMember("");
                              }}
                          />
                      </div>
                      <div className="filter-member-list-container">
                          <div className={`filter-member-list-inner-container`}>
                              <label>
                                  <input type='checkbox' style={{ width: '13px', height: '13px' }} className='col-md-3' 
                                  checked={selectedAll} onChange={(e)=>{handleSelectedAllMember(e);}}/>
                                  <span className="checkmark"></span>
                                  <span className='font-xxs'>Select All</span>
                              </label>
                              {[...currUserSA]?.sort().map(item => {
                                  return (
                                      <label className="" style={{width:'50%'}}>
                                          <input type='checkbox' style={{ width: '13px', height: '13px' }} className='col-md-3' 
                                          checked={selectedMember.some(data=>data.uid === item.uid)} name={item.uid} value={item.uid} onChange={(e)=>{handleSelectedMember(e)}}/>
                                          <span className="checkmark"></span>
                                          <span className={`${item.uid === user.uid ? "member-list-inner-span" : "checkbox-default-span"} font-xxs`}>{item.uid === user.uid ? "You" : item.displayName}</span>
                                      </label>
                                  )
                              })}
                          </div>
                      </div>
                    </div>
                    } */}
                  </div>
                </div>
                :
                <></>
                }
              </div>
            </div>

            <div className="col-8" style={{}}>
              <div className="card" style={{ background: '#fffffe', paddingBlock: '20px', paddingInline: '30px', border: 'none', borderRadius: '0px 8px 8px 0px', height: HEIGHT,position:'relative' }}>
                  <div className="logs-header">
                    <h6 style={{display:'flex',alignItems:'center'}}>Logs</h6>
                    {((isSuperAdmin || Activity_ViewAll) && userLogs.length > 0) &&
                        <div className="logs-tab-container">
                          <div className={`${isAll && "logs-tab-button-clicked"} logs-tab-button`} onClick={()=>{onHandleAllLogs();}}>
                            <span>All</span>
                          </div>
                          <div className={`${isPersonal && "logs-tab-button-clicked"} logs-tab-button`} onClick={()=>{onHandlePersonalLogs();}}>
                            <span>Personal</span>
                          </div>
                        </div>
                      }
                  </div>
                  <div className="logs-sub-header">
                      <div className="legend-container">
                          <div className="legend-content">
                              <div className="legend-box"><div className="legend client-legend-color"></div></div><span className="">Clients</span>
                          </div>
                          <div className="legend-content">
                              <div className="legend-box"><div className="legend appointment-legend-color"></div></div><span className="">Appointments</span>
                          </div>
                      </div>
                      {userLogs.length > 0 && 
                       <div className="sort-container" onClick={()=>{setSortModal(!sortModal)}}>
                        <div className="sort-icon">
                          <div><BiSortAlt2 size={15}/></div>
                          <span>{sortValue !== "" ? sortValue : "Sort"}</span>
                          {sortModal && 
                              <div className="sort-modal">
                                  <div className={`sort-container sort-container-border-bottom`} onClick={()=>{setSortValue("Newest")}}>
                                      <div><BsArrowDown size={16}/></div>
                                      <span>Newest</span>
                                  </div>
                                  <div className={`sort-container`} onClick={()=>{setSortValue("Oldest")}} >
                                      <div><BsArrowUp size={16}/></div>
                                      <span>Oldest</span>
                                  </div>
                                  
                              </div>
                          }
                        </div>
                       </div>
                      }
                  </div>
                  <div className={`appointmentloadingTable ${loadData && 'showLoadingTable'}`} style={{height: "100%"}}>
                    <img src={require('../assets/retrieveDataGif.gif')} alt=''/>
                    <span>Retrieving data...</span>
                  </div>
                  {userLogs.length < 1 && 
                    <div className={`empty-logs-container`}>
                      <img src={require('../assets/img/no_data.png')} alt=''/>
                      <span>No data</span>
                    </div>
                  }
                  <div className="logs-body" style={loadData ? {display:'none',flexDirection:'column',overflow:'scroll'} : {display:'flex',flexDirection:'column',overflow:'scroll'}}>
                    {userLogs.length > 0 && userLogs?.map((log,index) => {
                      let groupObj = client_groups.find(element=>element.GroupID === log.GroupID)
                      let userObj = currUserSA.find(element=>element.uid === log.UserID);
                      // let firebaseUserObj = firebaseUser.find(element=>element.uid === log.UserID);
                      let defaultImg = "https://firebasestorage.googleapis.com/v0/b/rexsoft-crm.appspot.com/o/default-profile.webp?alt=media&token=65d9adcd-657c-4566-8058-f45f74b13d4a";
                      return (
                        <>
                          {logSection.map(((section, idx) => {
                            if (section.Section === log?.Section){
                              const IconComponent = Icons[section.Icon];
                                  // index !0
                                  return (
                                    <>
                                        <div className="logs-card"  
                                        ref={index === 0 ? (isAll ? allRef : personalRef) : null}
                                        onClick={()=>{ (log.Section === "Appointments" && isJSON(log?.Keyword)) && showModalFunc(); 
                                        isJSON(log?.Keyword) && setCurrIndex(index); 
                                        isJSON(log?.Keyword) && ((currIndex === index) ? setToggleDropdown(!toggleDropdown) : setToggleDropdown(true)) 
                                        }}>
                                          <div className={`
                                          ${log.Section === "Clients" && "logs-section-client"}
                                          ${log.Section === "Appointments" && "logs-section-appointment"}
                                          logs-section-tag`}>
                                          </div>
                                          <div className="card-inner-container">
                                            <div className="action-container">
                                                  {log.Action === "create" &&
                                                      <img className="img-20" src={require('../assets/logsAction/create.png')} alt='' />
                                                  }

                                                  {log.Action === "update" &&
                                                      <img className="img-24" src={require('../assets/logsAction/updated.png')} alt=''/>
                                                  }

                                                  {log.Action === "bulk_update" &&
                                                      <img className="img-27" src={require('../assets/logsAction/bulk_updated.png')} alt=''/>
                                                  }

                                                  {log.Action === "delete" &&
                                                      <img className="img-20" src={require('../assets/logsAction/delete.png')} alt=''/>
                                                  }

                                                  {log.Action === "bulk_delete" &&
                                                      <img className="img-22" src={require('../assets/logsAction/bulk_delete.png')} alt=''/>
                                                  }

                                                  {log.Action === "import" &&
                                                      <img className="img-24" src={require('../assets/logsAction/import.png')} alt=''/>
                                                  }
                                              </div>
                                            <div className="user-profile-container">
                                              {/* <img src={firebaseUserObj !== undefined ? firebaseUserObj?.photoURL : defaultImg} alt=''/> */}
                                            </div>
                                            <div className="logs-container">
                                              <div className="logs-details">
                                                <div className="logs-info">
                                                  <span>
                                                      <>
                                                      {userObj !== undefined ? 
                                                          (userObj.uid === user.uid ? 
                                                          <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} title={userObj.displayName}>You have </span> 
                                                          : 
                                                          <span className={``} 
                                                          style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} title={userObj.displayName}>
                                                              {userObj.displayName} </span>
                                                          )
                                                          :
                                                          <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',color:'red',opacity:'0.7' }}>Deleted User </span>
                                                      }
                                                      </>{log.Activity} in {log.Section === "Clients" ? groupObj !== undefined ? `${groupObj.GroupName}` : <span style={{opacity:'0.7',color:'red'}}>{`(Deleted Group)`}</span>:""}</span>
                                                  <span className="logs-date">{moment(log.CreatedAt).format("YYYY-MM-DD | HH:mm | dddd")}</span>
                                                </div>
                                                {isJSON(log?.Keyword) &&
                                                      <div className={(currIndex === index && toggleDropdown === true && log.Section === "Clients") ? "logs-details-extend-clicked" : "logs-details-extend"}>
                                                          <Icons.FaAngleRight size={15} cursor={"pointer"}/>
                                                      </div>
                                                }
                                              </div>
                                            </div>
                                          </div>
                                          {(isJSON(log?.Keyword) && log.Section === "Clients" && currIndex === index && toggleDropdown === true) &&
                                           <div className="logs-details-extend-card-outer-body">
                                            <div className="logs-details-extend-card-body">
                                                {(isJSON(log?.Keyword) && is2DArray(log?.Keyword)) ? 
                                                <>
                                                {JSON.parse(log?.Keyword).map((data, dataIndex)=>{
                                                    return(
                                                        <>
                                                            {uniqueColumns.length > 0 && uniqueColumns.map((col,index)=>{
                                                                // let jsonObj = isJSON(log?.Keyword) ? JSON.parse(log?.Keyword) : [];
                                                                const item = data.find(element=>element.columnName === col)
                                                                
                                                                if(log.Action !== "bulk_delete" && log.Action !== "import" && item !== undefined && data.length !== 0 && dataIndex < 3)
                                                                {
                                                                    return (
                                                                        <>
                                                                            <div className="card-content">
                                                                                <span className="indexing-dash"><CgArrowLongRightR size={17}/></span> 
                                                                                {(Array.isArray(item?.changedValue) && Array.isArray(item?.oriValue)) ?
                                                                                <>
                                                                                <div className="d-flex">
                                                                                    <span> From {""}
                                                                                    {item?.oriValue.length === 0 ? "-" :item?.oriValue.map(ori=>{
                                                                                        return(
                                                                                            <span className="ori-span"> {ori === "" ? "-": (moment(ori,'YYYY-MM-DDTHH:mm',true).isValid() ? moment(ori).format("YYYY-MM-DD HH:mm") :ori) }</span>
                                                                                        );
                                                                                    })}  to {item?.changedValue.length === 0 ? "-" : item?.changedValue.map(change=>{
                                                                                        return(
                                                                                            <span className="changed-span" > {change === "" ? "-" : (moment(change,'YYYY-MM-DDTHH:mm',true).isValid() ? moment(change).format("YYYY-MM-DD HH:mm") :change)}</span>
                                                                                        );
                                                                                    })}
                                                                                    </span>
                                                                                </div>
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    <span>From <span className="ori-span">{(item?.oriValue === "" || item?.oriValue.length === 0) ? "-": 
                                                                                        (moment(item?.oriValue,'YYYY-MM-DDTHH:mm',true).isValid() ? moment(item?.oriValue).format("YYYY-MM-DD HH:mm") :item?.oriValue) }</span> to <span className="changed-span" >{(item?.changedValue === "" || item?.changedValue.length === 0) ? "-" : 
                                                                                        (moment(item?.changedValue,'YYYY-MM-DDTHH:mm',true).isValid() ? moment(item?.changedValue).format("YYYY-MM-DD HH:mm") :item?.changedValue)}  
                                                                                        </span> on {`${col}`}
                                                                                    </span>
                                                                                </>
                                                                                }
                                                                            </div>     
                                                                        </>
                                                                    )
                                                                }
                                                                else if((log.Action === "bulk_delete" || log.Action === "import") && item !== undefined && dataIndex < 5)
                                                                {
                                                                    return (
                                                                        <>
                                                                            <div className="card-content">
                                                                                <span className="indexing-dash"><CgArrowLongRightR size={17}/></span> 
                                                                                {(Array.isArray(item?.changedValue) && Array.isArray(item?.oriValue)) ?
                                                                                <>
                                                                                <div className="d-flex">
                                                                                    <span> From
                                                                                    {item?.oriValue.length === 0 ? "-" :item?.oriValue.map(ori=>{
                                                                                        return(
                                                                                            <span className="ori-span"> {ori === "" ? "-": (moment(ori,'YYYY-MM-DDTHH:mm',true).isValid() ? moment(ori).format("YYYY-MM-DD HH:mm") :ori) }</span>
                                                                                        );
                                                                                    })}  to {item?.changedValue.length === 0 ? "-" :item?.changedValue.map(change=>{
                                                                                        return(
                                                                                            <span className="changed-span" > {change === "" ? "-" : (moment(change,'YYYY-MM-DDTHH:mm',true).isValid() ? moment(change).format("YYYY-MM-DD HH:mm") :change)}</span>
                                                                                        );
                                                                                    })}
                                                                                    </span>
                                                                                </div>
                                                                                </>
                                                                                :
                                                                                <>
                                                                                <span><span className={`${log.Action === "bulk_delete" ? "ori-span" : "changed-span"}`}>{(item?.name === "" || item?.name.length === 0 )? "-" : item?.name}</span>
                                                                                {log.Action === "bulk_delete" ? " is removed" : " is added"}</span>
                                                                                </>
                                                                                }
                                                                            </div>     
                                                                        </>
                                                                    )
                                                                }
                                                            })}
                                                            
                                                        </>
                                                    )
                                                })}
                                                <div className="view-more-container" >
                                                    <span className="see-all-span" onClick={()=>{showModalFunc();}}>See All</span>
                                                </div>
                                                </> 
                                                : 
                                                <>
                                                    {uniqueColumns.length > 0 && uniqueColumns.map((col,index)=>{
                                                        let jsonObj = isJSON(log?.Keyword) ? JSON.parse(log?.Keyword) : [];
                                                        const item = jsonObj.find(element=>element.columnName === col)
                                                        if(item !== undefined)
                                                        {
                                                          return (
                                                              <>
                                                                  <div className="card-content">
                                                                      <span className="indexing-dash"><CgArrowLongRightR size={17}/></span> 
                                                                      {(Array.isArray(item?.changedValue) && Array.isArray(item?.oriValue)) ?
                                                                      <>
                                                                      <div className="d-flex">
                                                                          <span> Updated from 
                                                                          {item?.oriValue.length === 0 ? "-" :item?.oriValue.map(ori=>{
                                                                              return(
                                                                                  <span className="ori-span"> {ori === "" ? "-": (moment(ori,'YYYY-MM-DDTHH:mm',true).isValid() ? moment(ori).format("YYYY-MM-DD HH:mm") :ori) }</span>
                                                                              );
                                                                          })}  to {item?.changedValue.length === 0 ? "-" : item?.changedValue.map(change=>{
                                                                              return(
                                                                                  <span className="changed-span" > {change === "" ? "-" : (moment(change,'YYYY-MM-DDTHH:mm',true).isValid() ? moment(change).format("YYYY-MM-DD HH:mm") :change)}</span>
                                                                              );
                                                                          })}
                                                                          </span>
                                                                      </div>
                                                                      </>
                                                                      :
                                                                      <>
                                                                          <span>From <span className="ori-span">{(item?.oriValue === "" || item?.oriValue.length === 0) ? "-": 
                                                                              (moment(item?.oriValue,'YYYY-MM-DDTHH:mm',true).isValid() ? moment(item?.oriValue).format("YYYY-MM-DD HH:mm") :item?.oriValue) }</span> to <span className="changed-span" >{(item?.changedValue === "" || item?.changedValue.length === 0) ? "-" : 
                                                                              (moment(item?.changedValue,'YYYY-MM-DDTHH:mm',true).isValid() ? moment(item?.changedValue).format("YYYY-MM-DD HH:mm") :item?.changedValue)}  
                                                                              </span> on {`${col}`}
                                                                          </span>
                                                                      </>
                                                                      }
                                                                  </div>    
                                                              </>
                                                          )
                                                        }
                                                    })}
                                                </>}
                                            </div>
                                          </div>
                                          }
                                        </div>
                                    </>
                                  );
                                }
                          }))}
                        </>
                        );
                    })}
                </div>
                {userLogs.length > 0 ? 
                  <>
                    <div className="" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingTop: '1rem', width: '100%' }}>
                      <div style={{ display: 'flex', width: '90%', justifyContent: 'center', alignItems: 'center', overflow: 'scroll' }}>
                        <ReactPaginate
                          containerClassName="paginationStyle"
                          activeClassName="activePage"
                          pageClassName="paginationElementStyle"
                          breakLabel="..."
                          nextLabel={<MdNavigateNext size={20}/>}
                          onPageChange={(page) => { onHandlePageChange(page.selected) }}
                          pageRangeDisplayed={5}
                          pageCount={(logsCount && logsCount.length > 0) && Math.ceil(logsCount[0].Total_Logs/50)}
                          previousLabel={<MdNavigateBefore size={20}/>}
                          renderOnZeroPageCount={null}
                          forcePage={currentPage}
                        />
                      </div>
                    </div>
                    <div className="scroll-button">
                        <C_Button
                          color={"#f8fafa"}
                          background={"#5F5EAF"}
                          justify={"center"}
                          width={"35px"}
                          height={"35px"}
                          onClick={() => {
                            {isAll? allRef.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' }) : personalRef.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' })}
                          }}
                          textColor={"#FFFFFF"}
                          icon={<ArrowUp size={20} />}
                          marginLeft={"0.5rem"}
                          marginRight={"0.5rem"}
                          borderRadius={"100px"}
                        />
                    </div>  
                  </>
                  :<></>
                }
              </div>
            </div>
          </div>

          <div className="modal fade" ref={modalRef} tabIndex="-1" style={{}}>
                <div className="modal-dialog modal-dialog-centered modal-lg" style={{}}>
                    <div className="modal-content px-3">
                        <div className="modal-header modalHeader">
                            <div className="title">
                                <Icons.FaCogs size={17}/>
                                <span className="font-s">Changes</span>
                            </div>
                            <AiOutlineClose cursor='pointer' size={16} onClick={()=>{hideModalFunc();setIsAttendees("");}} />
                        </div>
                        <div className="modal-body">
                          {userLogs[currIndex]?.Section === "Clients" && 
                          <>
                          {(userLogs[currIndex]?.Action !== "delete" && userLogs[currIndex]?.Action !== "bulk_delete" && userLogs[currIndex]?.Action !== "import") &&
                            <div className="activity-logs-legend-container">
                                <div className="logs-legend-content">
                                    <div className="changed-legend logs-legend"><span className="changed-legend-span">Updated</span></div>
                                </div>
                                <div className="logs-legend-content">
                                    <div className="ori-legend logs-legend"><span className="ori-legend-span">Previous</span></div>
                                </div>
                            </div>
                            }
                            <div className="activity-logs-modal-container">
                                <div className="" style={{rowGap:'1px'}}>
                                <table>
                                    <thead>
                                    <tr>
                                        <th>No.</th>
                                        {(logs[currIndex]?.Action !== "bulk_delete" && logs[currIndex]?.Action !== "import") && <th>Client</th>}
                                        {logs[currIndex]?.Action === "bulk_delete" && <th>Deleted Client</th>}
                                        {logs[currIndex]?.Action === "import" && <th>Imported Client</th>}

                                        {(logs[currIndex]?.Action !== "bulk_delete" && logs[currIndex]?.Action !== "import") && (uniqueColumns.length > 0) && 
                                        uniqueColumns?.map((columnName, index) => (
                                            <th key={index}>{columnName}</th>
                                        ))
                                        }
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {(isJSON(logs[currIndex]?.Keyword) && is2DArray(logs[currIndex]?.Keyword)) &&
                                            JSON.parse(logs[currIndex]?.Keyword).map((data, index) => {
                                                return(
                                                <>
                                                    <tr>
                                                        <td>{index+1}</td>
                                                        <td>{data.length === 0 ? "No Changes" : ((data[0]?.name === "" || data[0]?.name.length === 0) ? "-" : data[0]?.name)}</td>
                                                        {uniqueColumns.length > 0 && uniqueColumns.map((col,index)=>{
                                                            const item = data.find(element=>element.columnName === col)
                                                            return(
                                                                <>
                                                                {data.length === 0 ? <td>No Changes</td> : 
                                                                  <>
                                                                    {(logs[currIndex].Action !== "bulk_delete" && logs[currIndex].Action !== "import") &&
                                                                        <td className="">
                                                                            {item !== undefined ?(Array.isArray(item?.changedValue) && Array.isArray(item?.oriValue)) ?
                                                                            <>
                                                                            <div className="d-flex">
                                                                                <div style={{display:'flex',gap:'1px'}}>
                                                                                {item?.changedValue.length === 0 ? "-" :item?.changedValue.map(change=>{
                                                                                    return(
                                                                                    <span className="changed-span" >{change === "" ? "-" : (moment(change,'YYYY-MM-DDTHH:mm',true).isValid() ? moment(change).format("YYYY-MM-DD HH:mm") :change)}</span>
                                                                                    );
                                                                                })}
                                                                                </div>
                                                                                <div style={{display:'flex',gap:'1px'}}>
                                                                                {item?.oriValue.length === 0 ? "-" :item?.oriValue.map(ori=>{
                                                                                    return(
                                                                                    <span className="ori-span">{ori === "" ? "-": (moment(ori,'YYYY-MM-DDTHH:mm',true).isValid() ? moment(ori).format("YYYY-MM-DD HH:mm") :ori) }</span>
                                                                                    );
                                                                                })}
                                                                                </div>
                                                                            </div>
                                                                            </>
                                                                            :
                                                                            <>
                                                                            <span className="changed-span" >{(item?.changedValue === "" || item?.changedValue.length === 0) ? "-" : 
                                                                            (moment(item?.changedValue,'YYYY-MM-DDTHH:mm',true).isValid() ? moment(item?.changedValue).format("YYYY-MM-DD HH:mm") :item?.changedValue)}</span>
                                                                            <span className="ori-span">{(item?.oriValue === "" || item?.oriValue.length === 0) ? "-": 
                                                                            (moment(item?.oriValue,'YYYY-MM-DDTHH:mm',true).isValid() ? moment(item?.oriValue).format("YYYY-MM-DD HH:mm") :item?.oriValue) }</span>
                                                                            </>
                                                                            :
                                                                            <>
                                                                              No Changes
                                                                            </>
                                                                            }
                                                                        </td>
                                                                      }
                                                                  </>
                                                                }
                                                                
                                                                </>
                                                            )
                                                            
                                                        })}
                                                    </tr>
                                                </>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                                </div>
                            </div>
                            </>
                            }

                            {userLogs[currIndex]?.Section === "Appointments" && 
                            <>
                            <div className="activity-logs-legend-container">
                              {(isAttendees === "Members" || isAttendees === "Clients") && <div><BiArrowBack size={18} onClick={()=>{setIsAttendees("");}} cursor={"pointer"} /></div>}
                              <div className="logs-legend-content">
                                <div className="changed-legend logs-legend">
                                  <span className="changed-legend-span">Updated</span>
                                </div>
                              </div>
                              <div className="logs-legend-content">
                                <div className="ori-legend logs-legend">
                                  <span className="ori-legend-span">Previous</span>
                                </div>
                              </div>
                            </div>
                            <div className="activity-logs-modal-container">
                                  <div className="" style={{rowGap:'1px'}}>
                                      <div className='fields'>
                                      {isAttendees !== "Clients" && isAttendees !== "Members" &&
                                      <>
                                        <div className='inputField'>
                                            <div className='inputTitle'>
                                                <span>Title</span>
                                            </div>
                                            <input 
                                                className={`${JSON.parse(userLogs[currIndex]?.Keyword)[0].changedValue.title !== JSON.parse(userLogs[currIndex]?.Keyword)[0].oriValue.title && 'changes-value'}`}
                                                placeholder='Add title'
                                                value={JSON.parse(userLogs[currIndex]?.Keyword)[0].changedValue.title}  
                                                disabled 
                                            />
                                            {JSON.parse(userLogs[currIndex]?.Keyword)[0].changedValue.title !== JSON.parse(userLogs[currIndex]?.Keyword)[0].oriValue.title &&
                                            <span className="fields-span">{JSON.parse(userLogs[currIndex]?.Keyword)[0].oriValue.title}</span>
                                            }
                                        </div>
                                        <div className='inputFieldDateTime'>
                                            <div className='inputTitle'>
                                                <span>Date</span>
                                            </div>
                                            <div className="inputOriContainer">
                                              <input
                                                  className={`${JSON.parse(userLogs[currIndex]?.Keyword)[0].changedValue.start !== JSON.parse(userLogs[currIndex]?.Keyword)[0].oriValue.start && 'changes-value'}`}
                                                  type="datetime-local"value={JSON.parse(userLogs[currIndex]?.Keyword)[0].changedValue.start}
                                                  disabled
                                              />
                                                <span>TO</span>
                                              <input
                                                  className={`${JSON.parse(userLogs[currIndex]?.Keyword)[0].changedValue.end !== JSON.parse(userLogs[currIndex]?.Keyword)[0].oriValue.end && 'changes-value'}`}
                                                  type="datetime-local"value={JSON.parse(userLogs[currIndex]?.Keyword)[0].changedValue.end}
                                                  disabled
                                              />
                                            </div>
                                            <div className="inputEditContainer">
                                              {JSON.parse(userLogs[currIndex]?.Keyword)[0].changedValue.start !== JSON.parse(userLogs[currIndex]?.Keyword)[0].oriValue.start &&
                                              <span className="fields-span">{moment(JSON.parse(userLogs[currIndex]?.Keyword)[0].oriValue.start).format("YYYY-MM-DD HH:mm:ss")}</span>
                                              }
                                                <span></span>
                                              { JSON.parse(userLogs[currIndex]?.Keyword)[0].changedValue.end !== JSON.parse(userLogs[currIndex]?.Keyword)[0].oriValue.end &&
                                              <span className="fields-span">{moment(JSON.parse(userLogs[currIndex]?.Keyword)[0].oriValue.end).format("YYYY-MM-DD HH:mm:ss")}</span>
                                              }
                                            </div>
                                        </div>
                                        <div className='inputField'>
                                            <div className='inputTitle'>
                                                <span>Venue</span>
                                            </div>
                                            <input 
                                                className={`${JSON.parse(userLogs[currIndex]?.Keyword)[0].changedValue.AppointmentVenue !== JSON.parse(userLogs[currIndex]?.Keyword)[0].oriValue.AppointmentVenue && 'changes-value'}`}
                                                placeholder='Add venue'
                                                value={JSON.parse(userLogs[currIndex]?.Keyword)[0].changedValue.AppointmentVenue}
                                                disabled
                                            />
                                            { JSON.parse(userLogs[currIndex]?.Keyword)[0].changedValue.AppointmentVenue !== JSON.parse(userLogs[currIndex]?.Keyword)[0].oriValue.AppointmentVenue &&
                                            <span className="fields-span">{JSON.parse(userLogs[currIndex]?.Keyword)[0].oriValue.AppointmentVenue}</span>
                                            }
                                        </div>
                                        <div className='inputField'>
                                            <div className='inputTitle'>
                                                {/* <TbFileDescription size={16}/> */}
                                                <span>Description</span>
                                            </div>
                                            <ReactQuill
                                                className={`${JSON.parse(userLogs[currIndex]?.Keyword)[0].changedValue.description !== JSON.parse(userLogs[currIndex]?.Keyword)[0].oriValue.description && 'changes-value'} rte`}
                                                theme="snow"
                                                value={JSON.parse(userLogs[currIndex]?.Keyword)[0].changedValue.description}
                                            />
                                            { JSON.parse(userLogs[currIndex]?.Keyword)[0].changedValue.description !== JSON.parse(userLogs[currIndex]?.Keyword)[0].oriValue.description &&
                                            <div dangerouslySetInnerHTML={{ __html: JSON.parse(userLogs[currIndex]?.Keyword)[0].oriValue.description }} className="fields-span"></div>
                                            }
                                        </div>
                                        </>
                                        }

                                        {isAttendees === "Clients" &&
                                        <>
                                          <table>
                                            <thead>
                                              <tr>
                                                <th>Added</th>
                                                <th>Removed</th>
                                                <th>Current</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr>
                                              <td><div className="td-container">{addedClientAttendees.length > 0 && addedClientAttendees.map(item => <span className="td-span-style changes-value">+ {clientSelectedList?.find(client => client?.ClientID === item) !== undefined ? 
                                              clientSelectedList?.find(client => client?.ClientID === item).RowValue : 'N/A'}</span>)}</div></td>

                                              <td ><div className="td-container">{removedClientAttendees.length > 0 && removedClientAttendees.map(item => <span className="td-span-style ori-value">- {clientSelectedList?.find(client => client?.ClientID === item) !== undefined ? 
                                              clientSelectedList?.find(client => client?.ClientID === item).RowValue : 'N/A'}</span>)}</div></td>

                                              <td ><div className="td-container">{existingClientAttendees.length > 0 && existingClientAttendees.map(item => <span className={`td-span-style ${addedClientAttendees.includes(item)? 'changes-value' : ''}`}>{addedClientAttendees.includes(item)? '+ ' : ''}{clientSelectedList?.find(client => client?.ClientID === item) !== undefined ? 
                                              clientSelectedList?.find(client => client?.ClientID === item).RowValue : 'N/A'}</span>)}</div></td>

                                              </tr>
                                            </tbody>
                                          </table>
                                        </>
                                        }
                                        {isAttendees === "Members" &&
                                          <>
                                            <table>
                                              <thead>
                                                <tr>
                                                  <th>Added</th>
                                                  <th>Removed</th>
                                                  <th>Current</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                <tr>
                                                <td><div className="td-container">{addedAttendees.length > 0 && addedAttendees.map(item => <span key={item.uid} className="td-span-style changes-value">+ {item.displayName}</span>)}</div></td>
                                                <td ><div className="td-container">{removedAttendees.length > 0 && removedAttendees.map(item => <span key={item.uid} className="td-span-style ori-value">- {item.displayName}</span>)}</div></td>
                                                <td ><div className="td-container">{existingAttendees.length > 0 && existingAttendees.map(item => <span key={item.uid} className={`td-span-style ${addedAttendees.includes(item)? 'changes-value' : ''}`}>{addedAttendees.includes(item)? '+ ' : ''} {item.displayName}</span>)}</div></td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </>
                                        }
                                        
                                      </div>
                                  
                                    <div className='actions'>
                                        <div className="actions-container">
                                          <C_Button
                                                background={isAttendees ==="Members" ? 'rgb(67,67,67,0.6)' : Colors.generalButtonColor}
                                                width={"170px"}
                                                gap={"10px"}
                                                buttonText={"Member Attendees"}
                                                onClick={() => {
                                                    setIsAttendees("Members")
                                                }}
                                                justify={"center"}
                                                textColor={"#FFFFFF"}
                                                icon={<Icons.FaUserTie size={12} />}
                                            />
                                            {(removedAttendees.length > 0 || addedAttendees.length > 0 && isAttendees !== "Members") &&
                                              <div className="actions-noti">
                                                !
                                              </div>
                                            }
                                            
                                        </div>
                                        <div className="actions-container">
                                          <C_Button
                                                background={isAttendees ==="Clients" ? 'rgb(67,67,67,0.6)' : Colors.generalButtonColor}
                                                width={"170px"}
                                                gap={"10px"}
                                                buttonText={"Client Attendees"}
                                                onClick={() => {
                                                  setIsAttendees("Clients");
                                                }}
                                                justify={"center"}
                                                textColor={"#FFFFFF"}
                                                icon={<Icons.FaUserFriends size={15} />}
                                            />
                                            {(removedClientAttendees.length > 0 || addedClientAttendees.length > 0 && isAttendees !== "Clients") &&
                                              <div className="actions-noti">
                                                !
                                              </div>
                                            }
                                        </div>
                                        
                                          
                                    </div>
                                  </div>
                            </div>
                            </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
}

export default ActivityLogs;
