import React, { useEffect, useState } from 'react';
import { Navigate } from "react-router-dom";
import RefreshLoading from '../containers/RefreshLoading';
import { getFromLocalStorage } from '../util/LocalStorage';
import { selectCompanyID } from '../stores/slices/companyIDSlices';
import { selectCurrTenant } from '../stores/slices/persistRolesSlices';
import { selectUser } from '../stores/slices/userAuthSlices';
import { selectSetCompanyUsageLoading } from '../stores/slices/companySlices';

const ProtectedRoute = ({ children }) => {

  const user = selectUser();
  const tenant = selectCurrTenant();
  const companyID = selectCompanyID();
  
  const [loading, setLoading] = useState(true);

  const refreshToken = getFromLocalStorage("REFRESH_TOKEN");
  const IS_GA = getFromLocalStorage("IS_GA");

  
  useEffect(() => {
    if(refreshToken && user){
      const waitAndSetLoading = async () => {
        await new Promise(resolve => setTimeout(resolve, 2500));
          setLoading(false);
      };
      waitAndSetLoading();
    } else {
      setLoading(false)
    }
  }, []);
  
  if(loading){
    return <RefreshLoading/>;
  }

  // if(refreshToken === null){
  //   return <Navigate to="/Auth" />;
  // }

  // if(IS_GA == "true"){
  //   return <Navigate to="/GaPortal" />;
  // }
 
  return children;

};

export default ProtectedRoute