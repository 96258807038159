import moment from "moment";
import React, { useState, useEffect, useRef, Fragment } from "react";
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from "react-icons/ai";
import { BiArrowBack } from "react-icons/bi";
import { CgLink } from "react-icons/cg";
import { FaScroll, FaStamp } from "react-icons/fa";
import { FiRotateCw } from "react-icons/fi";
import { HiMagnifyingGlass } from "react-icons/hi2";
import { MdOutlineContentCopy } from "react-icons/md";
import { RiTimer2Line } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import C_Button from "../../components/C_Button";
import C_DialogButton from "../../components/C_DialogButton";
import C_Search from "../../components/C_Search";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import Colors from "../../constants/Colors";

import "./FormSearch.scss";
import C_PopoutNoti from "../../components/C_PopoutNoti";


//slices
import { retrieveFormSubmission, selectFormSubmission, updateFormSubmission } from "../../stores/slices/formSubmissionSlices";
import { retrieveFormTemplate, selectFormTemplate } from "../../stores/slices/formTemplateSlices";
import { selectTenant } from "../../stores/slices/tenantSlices";
import { selectCompanyID } from "../../stores/slices/companyIDSlices";
import { selectCurrTenant } from "../../stores/slices/persistRolesSlices";
import { Modal } from "bootstrap";
import { selectCurrUserContext } from "../../stores/slices/companySlices";
import { selectUser } from "../../stores/slices/userAuthSlices";

function FormSearch() {

  const user = selectUser();
  const tenant = selectCurrTenant();
  const companyID = selectCompanyID();
  const forms = selectFormTemplate();
  const submissions = selectFormSubmission();

  const { currUserSA } = selectCurrUserContext();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchText,setSearchText] = useState("");
  const [form,setForm] = useState(submissions);
  const [formTimeline,setFormTimeline] = useState(submissions);
  const [trackingID,setTrackingID] = useState("");
  const [timeline,setTimeline] = useState(false);
  const [isReject,setIsReject] = useState(false);
  const [checkboxArray, setCheckboxArray] = useState([]);
  const [radioArray, setRadioArray] = useState([]);
  const [previousReason,setPreviousReason] = useState("");
  const [reason,setReason] = useState("");
  const [emptyReason,setEmptyReason] = useState(false);
  const [relatedForm,setRelatedForm] = useState([]);
  const [formName,setFormName] = useState("");
  const inner_height = window.innerHeight * 0.65;
  const [notifyMessage,setNotifyMessage] = useState("");

  const viewFormActionRef = useRef();

  const showViewFormActionModal = () => {
    const modalEle = viewFormActionRef.current;
    const bsModal = new Modal(modalEle, {
        backdrop: "static",
        keyboard: false,
    });
    bsModal.show();
  };

  const closeViewFormActionModal = () => {
        const modalEle = viewFormActionRef.current;
        const bsModal = Modal.getInstance(modalEle);
        bsModal.hide();
  };

  useEffect(()=>{
   
  if(timeline === false && tenant)
  {
    dispatch(retrieveFormSubmission({TenantID:tenant,CurrPage:"",UserID: ""}));
  }
 
  dispatch(retrieveFormTemplate({TenantID:tenant,CompanyID:companyID}));
  // dispatch(retrieveRoles(companyID));

  },[timeline, tenant])


  useEffect(()=>{
    const formArray = [];
    submissions.filter((item, index) => {
        if (searchText !== "") {
            if (item.FormTrackingID.toString().toLocaleLowerCase().includes(searchText.toString().toLocaleLowerCase())) 
            {
              return item;
            }
        }
    }).map(item=>{
        formArray.push(item);
    })

    setForm(formArray);
    
  },[searchText])

  useEffect(()=>{
    const array = [];
    var relatedArray = [];
    var name = "";

    submissions.map((form)=>{
        if(form.FormTrackingID === trackingID)
        {
            array.push(form);

            forms.map((item)=>{
                if(item.FormID === form.FormID)
                {
                    name = item.FormName;
                }
            })

        }
    })

    submissions.map((form)=>{
        forms.map((item)=>{
            if(form.FormID === item.FormID)
            {
                if(item.FormName === name)
                {
                    
                    relatedArray.push(form);
                    
                }
            }
            
        })
    })

    setFormName(name);
    setFormTimeline(array);
    setRelatedForm(relatedArray);

    var checkbox = [];
    var radio = [];
    var question = [];
    var answer = [];
    var reason = "";
    var formID = "";

    //question
    submissions.map((item) => {
      if (item.FormTrackingID === trackingID) {
        formID = item.FormID;
        reason = item.ReasonRejected;
      }
    })

    forms.map((element, index) => {
      if (element.FormID === formID) {
        question = element.FormData;
      }
    })

    submissions.map(item=>{
      if(item.FormTrackingID === trackingID)
      {
        answer = item.SubmissionData
      }
    })

    question.map((ques, index) => {
      checkbox.push([]);
      radio.push([]);
    })

    question.map((ques, index) => {
      answer.map((answer, answerIndex) => {
        if (answer.questionID === ques.questionID) {

          if (ques.questionType === "Checkboxes") {
            ques.option.map(option => {
              if (answer.answer.includes(option)) {
                checkbox[index].push(true);
              }
              else {
                checkbox[index].push(false);
              }

            })
          }
          if (ques.questionType === "Multiple Choice") {
            ques.option.map(option => {
              if (answer.answer.includes(option)) {
                radio[index].push(true);
              }
              else {
                radio[index].push(false);
              }

            })
          }
        }

      })
    })
    setCheckboxArray(checkbox);
    setRadioArray(radio);
    setPreviousReason(reason)

  },[trackingID])

const onHandleForm = () =>{
  dispatch(retrieveFormSubmission({TenantID:tenant,CurrPage:"",UserID: "",TrackingID:trackingID}));
  setTimeline(true);
}

const isBothArrayEqual = (previous,answer) => {

    if(JSON.stringify(previous) === JSON.stringify(answer))
    {
        return false
    }
    else
    {
        return true
    }
}

const approveForm = (id) => {
    var user_id = "";
    var form_id = "";
    var created_at = "";
    var modified_at = "";
    var data = "";

    submissions.map(form=>{
      if(form.SubmissionID === id)
      {
        user_id = form.UserID;
        form_id = form.FormID;
        created_at = form.CreatedAt;
        modified_at = form.ModifiedAt;
        data = form.SubmissionData;
      }
    })

    let body = {
      SubmissionID: id,
      UserID : user_id,
      FormID : form_id,
      CreatedAt : created_at,
      ModifiedAt : modified_at,
      ApprovalDate: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      ApprovalUserID: user?.uid,
      SubmissionStatus: '1',
      ReasonRejected: '',
      SubmissionData: JSON.stringify(data)
    };

    dispatch(updateFormSubmission({TenantID:tenant,data:body}))
    setTimeline(false);
    setSearchText("");
    setTrackingID("");

    notifyApprovedForm();
  }

  const rejectForm = (id) => {
    var user_id = "";
    var form_id = "";
    var created_at = "";
    var modified_at = "";
    var data = "";

    submissions.map(form=>{
      if(form.SubmissionID === id)
      {
        user_id = form.UserID;
        form_id = form.FormID;
        created_at = form.CreatedAt;
        modified_at = form.ModifiedAt;
        data = JSON.stringify(form.SubmissionData);
      }
    })

   
    let body = {
      SubmissionID: id,
      UserID : user_id,
      FormID : form_id,
      CreatedAt : created_at,
      ModifiedAt : modified_at,
      ApprovalDate: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      ApprovalUserID: user?.uid,
      SubmissionStatus: '2',
      ReasonRejected: reason,
      SubmissionData: data
    };

      dispatch(updateFormSubmission({TenantID:tenant,data:body}))
      setEmptyReason(false);
      setTimeline(false);
      setReason("");
      notifyRejectedForm();
    
  }


  const notifyApprovedForm = () =>
  toast("Form has been approved successfully !", {
    position: "top-right",
    autoClose: 4000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

  const notifyRejectedForm = () =>
  toast("Form has been rejected successfully !", {
    position: "top-right",
    autoClose: 4000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

  function notify(type){
    switch(type)
    {
      case "copy":
        setNotifyMessage("COPIED")
        break;
      default:
        setNotifyMessage("SAVED")
        break;
    }
    setTimeout(() => {
      setNotifyMessage("");
    }, 1500)
  };
  
  return (
    <Fragment>
        
        <div className="sidebar-main-container">
          <Sidebar />
        </div>
        <div className="main-container">
            <Header title={"Search Form"}/>
            <C_PopoutNoti triggerSaved={notifyMessage}/>
            <div className="px-5 py-4 ">
                <div className="col-6 d-flex" style={{paddingLeft:'40px',justifyContent: 'start', alignItems: 'center' }}>
                    {<BiArrowBack className="mt-1" style={{marginBottom:'10px',cursor:'pointer'}} size = {23} onClick={()=>{!timeline ? navigate("/FormTemplate") : setTimeline(false);setSearchText("");setTrackingID(""); }}/>}
                    <span className="my-3 font-xl" style={{ marginLeft:'30px', marginRight:'10px',whiteSpace:'nowrap'  }}>Track & Trace</span> 
                </div>
                {!timeline ? 
                <div className="d-flex" style={{position:'absolute', top:'40%', left:'25%',width:'70%', height:'500px'}}>
                    
                    <div className="d-flex flex-column" style={{width:'90%'}}>
                    {!timeline ? 
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '1rem' }}>
                        {trackingID !== "" &&
                        <C_Button
                          onClick={()=>{
                            onHandleForm();
                            
                          }}
                          width={"120px"}
                          buttonText={"Search"}
                          marginLeft={"5px"}
                        />
                        }
                      </div>
                      : 
                      <></>
                    }
                    <C_Search
                      placeholder="Search your form with e.g. MD800123456..."
                      onChange={(e) => {
                          setSearchText(e.target.value);
                      }}
                      value={searchText}
                      searchText={searchText}
                      closeFunction={() => {
                          setSearchText("");
                      }}
                      height={"10%"}
                      iconSize={25}
                    />
                    {searchText !== "" ? 
                        <div className="card" style={{border:'none',boxShadow: '1px 1px 3px 1px rgba(0,0,0,0.1)',height:'300px'}}>
                            <div className="card-body" style={{overflow:'scroll',position:'relative'}}>
                                {/* map */}
                                {form.map((form,index)=>{
                                    return(<div className="py-2 px-2 form-search-list" onClick={()=>{setTrackingID(form.FormTrackingID);setSearchText(form.FormTrackingID);}}>{form.FormTrackingID}</div>)
                                })}
                                {form.length === 0 && <>
                                <div className="py-2 px-2" style={{}}>NO DATA</div></>}
                            </div>
                        </div>
                    :<></>}
                    </div>
                </div>
                :
                <>
                <ToastContainer />
                    <div className="py-2 px-5">
                        <div className="py-2">
                            <span className="font-xl" style={{marginBottom:'10px',letterSpacing:'1px'}}>Form Name - {formName}</span>
                        </div>
                        <div className=" d-flex" style={{columnGap:'10px',height:inner_height}}>
                            <div className="col-md-8" style={{height:'100%',gap:'1rem',display:'flex',flexDirection:'column'}}>
                                <div className="card cards-border" style={{height:'20%'}}>
                                    <div className="card-body d-flex">
                                        <div className="d-flex col-10" style={{justifyContent:'start',columnGap:'60px',alignItems:'center',letterSpacing:'1px'}}  >
                                            {formTimeline.map(form=>{
                                                if(form.SubmissionStatus === "0")
                                                {
                                                    return (
                                                    <>
                                                    <div className="">
                                                        <RiTimer2Line size={35} style={{color:'#eec16e'}} />
                                                    </div>
                                                    <div className=" d-flex" style={{alignItems:'center'}} >
                                                        <span className="font-xl">Pending</span>
                                                    </div>
                                                    </>
                                                    )
                                                }
                                                else if(form.SubmissionStatus === "1")
                                                {
                                                    return(
                                                    <>
                                                    <div className="">
                                                        <AiOutlineCheckCircle size={35} style={{color:'#61cfa3'}} />
                                                    </div>
                                                    <div className="d-flex" style={{alignItems:'center'}} >
                                                        <span className="font-xl">Approved</span>
                                                    </div>
                                                    </>
                                                    )
                                                }
                                                else if(form.SubmissionStatus === "2")
                                                {
                                                    return(
                                                    <>
                                                    <div className="">
                                                        <AiOutlineCloseCircle size={35} style={{color:'#eb6565'}} />
                                                    </div>
                                                    <div className="d-flex" style={{alignItems:'center'}} >
                                                        <span className="font-xl">Rejected</span>
                                                    </div>
                                                    </>
                                                    )
                                                }
                                                else if(form.SubmissionStatus === "3")
                                                {
                                                    return(
                                                    <>
                                                    <div className="">
                                                        <FiRotateCw size={35} style={{color:'#6f7df7'}} />
                                                    </div>
                                                    <div className="d-flex" style={{alignItems:'center'}} >
                                                        <span className="font-xl">Reapplication</span>
                                                    </div>
                                                    </>
                                                    )
                                                }
                                            })}
                                        </div>
                                        <div className="col-2 d-flex" style={{justifyContent:'end',alignItems:'center'}}>
                                            {formTimeline.map((form)=>{
                                                return(
                                                    <C_Button
                                                      width={"110px"}
                                                      height={"30px"}
                                                      gap={"10px"}
                                                      buttonText={`
                                                      ${form.SubmissionStatus === "0" || form.SubmissionStatus === "3" ? "Action" : "Preview"}`
                                                      }
                                                      background={`
                                                      ${form.SubmissionStatus === "0" || form.SubmissionStatus === "3" ? "" : "#463EB0"}`
                                                      }
                                                      justify={"center"}
                                                      paddingLeft={"10px"}
                                                      paddingRight={"10px"}
                                                      icon={form.SubmissionStatus === "0" || form.SubmissionStatus === "3" ?<FaStamp size={14}/> : <HiMagnifyingGlass size={14} />}
                                                      onClick={() => {setIsReject(""); showViewFormActionModal();}}
                                                       
                                                    />
                                                )
                                            })}
                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="card cards-border" style={{position:'relative',height:'80%'}}>
                                    <div className="card-body py-5 px-5">
                                        <div className="py-5">
                                            <div className="d-flex" style={{position:'absolute',top:'10%',left:'3%'}}>
                                                <span className="font-xl" style={{marginBottom:'10px'}}>Timeline</span>
                                            </div>
                                        {formTimeline.map((form)=>{
                                          let userFound = false;
                                            return(
                                                <div className="timeline mx-3 my-5" >
                                                    <div className={`timeline-progress
                                                    ${form.trackingID === "" && 'progress-no-width' }
                                                    ${form.SubmissionStatus === "0" && 'progress-0'}
                                                    ${form.SubmissionStatus === "1" && 'progress-1'}
                                                    ${form.SubmissionStatus === "2" && 'progress-2'}
                                                    ${form.SubmissionStatus === "3" && 'progress-3'}
                                                    `}>
                                                        {/* 0% */}
                                                        <div className={`timeline-item
                                                            ${form.SubmissionStatus === "0" && 'background-0'}
                                                            ${form.SubmissionStatus === "1" && 'background-1'}
                                                            ${form.SubmissionStatus === "2" && 'background-2'}
                                                            ${form.SubmissionStatus === "3" && 'background-3'}`
                                                            } style={{left:'0%'}}>
                                                            <div className="timeline-item-child">
                                                                <FaStamp size={20} style={{marginLeft:'5px',marginTop:'3px'}}/>
                                                                
                                                                <div className={`timeline-content
                                                                    ${form.SubmissionStatus === "0" && 'background-0'}
                                                                    ${form.SubmissionStatus === "1" && 'background-1'}
                                                                    ${form.SubmissionStatus === "2" && 'background-2'}
                                                                    ${form.SubmissionStatus === "3" && 'background-3'}`
                                                                    } style={{left:'0%',marginTop:'20px'}}>
                                                                        <span>- {moment(form.CreatedAt).format("llll")}</span>
                                                                </div>
                                                            </div>
                                                            <span style={{position:'absolute',top:'100%',left:'0%',zIndex:'0',marginTop:'7px'}}>Date Applied</span>
                                                        </div>

                                                        {/* 33% */}
                                                        <div className={`timeline-item
                                                            ${form.SubmissionStatus === "0" && 'background-0'}
                                                            ${form.SubmissionStatus === "1" && 'background-1'}
                                                            ${form.SubmissionStatus === "2" && 'background-2'}
                                                            ${form.SubmissionStatus === "3" && 'background-3'}`
                                                            } style={{left:'33.3%'}}>

                                                            
                                                            <div className="timeline-item-child">
                                                                <RiTimer2Line size={28} style={{marginLeft:'1px',marginTop:'1px'}}/>
                                                                <div className={`timeline-content
                                                                ${form.SubmissionStatus === "0" && 'background-0'}
                                                                ${form.SubmissionStatus === "1" && 'background-1'}
                                                                ${form.SubmissionStatus === "2" && 'background-2'}
                                                                ${form.SubmissionStatus === "3" && 'background-3'}`
                                                            } style={{left:'0%',marginTop:'-90px'}}>
                                                                    <span>- Form was waiting to be approved</span>
                                                                </div>
                                                            </div>
                                                            {form.SubmissionStatus === "0" && <span style={{position:'absolute',top:'100%',left:'0%',zIndex:'0',marginTop:'7px'}}>Pending</span>}
                                                        </div>

                                                        {/* 66% */}
                                                        <div className={`timeline-item
                                                            ${form.SubmissionStatus === "0" && 'background-grey'}
                                                            ${form.SubmissionStatus === "1" && 'background-1'}
                                                            ${form.SubmissionStatus === "2" && 'background-2'}
                                                            ${form.SubmissionStatus === "3" && 'background-3'}`
                                                            } style={{left:'66.6%'}}>
                                                            <div className="timeline-item-child">
                                                                {form.SubmissionStatus === "3" ? <FiRotateCw size={23} style={{marginLeft:'3px',marginTop:'3px'}}/> : <></>}
                                                                {form.SubmissionStatus === "2" ? <AiOutlineCloseCircle size={28} style={{marginLeft:'1px',marginTop:'1px'}}/> : <></>}
                                                                {form.SubmissionStatus === "1" ? 
                                                                    <>{form.ModifiedAt !== "" ? 
                                                                        <><FiRotateCw size={23} style={{marginLeft:'3px',marginTop:'3px'}}/></>
                                                                        :
                                                                       <>
                                                                       {form.ReasonRejected !== "" && form.ReasonRejected !== null ?
                                                                        <><AiOutlineCloseCircle size={28} style={{marginLeft:'1px',marginTop:'1px'}}/></> :<>
                                                                        <AiOutlineCheckCircle size={28} style={{marginLeft:'1px',marginTop:'1px'}} /></>
                                                                        }
                                                                        
                                                                        </>
                                                                        }
                                                                        
                                                                    </>
                                                                    :
                                                                     <></>
                                                                    }

                                                                {form.SubmissionStatus !== "0" &&
                                                                <div className={`timeline-content
                                                                    ${form.SubmissionStatus === "0" && 'background-grey'}
                                                                    ${form.SubmissionStatus === "1" && 'background-1'}
                                                                    ${form.SubmissionStatus === "2" && 'background-2'}
                                                                    ${form.SubmissionStatus === "3" && 'background-3'}`
                                                                    } style={{left:'0%',marginTop:'40px'}}>

                                                                    {form.SubmissionStatus === "3" && 
                                                                    <div className="d-flex flex-column">
                                                                        <span>- {moment(form.ModifiedAt).format("llll")}</span>
                                                                        <span>- {formTimeline.length !== 0 ?
                                                                         <>
                                                                         {currUserSA.map((user)=>{
                                                                         if(user?.uid === form.ApprovalUserID)
                                                                         {
                                                                           userFound = true;
                                                                           return(
                                                                             <>
                                                                             {user.displayName !== "" && user.displayName}
                                                                             
                                                                             </>
                                                                             
                                                                           );;
                                                                         }
                                                                         })}
                                                                         </>
                                                                        :
                                                                        <></>} has resubmitted the form</span>
                                                                        
                                                                    </div>
                                                                    }
                                                                    {form.SubmissionStatus === "2" &&
                                                                    <div className="d-flex flex-column">
                                                                        <span>- {moment(form.ApprovalDate).format("llll")}</span>
                                                                        <span>- {formTimeline.length !== 0 ? 
                                                                        <>
                                                                          {currUserSA.map((user)=>{
                                                                          if(user?.uid === form.ApprovalUserID)
                                                                          {
                                                                            userFound = true;
                                                                            return(
                                                                              <>
                                                                              {user.displayName !== "" && user.displayName}
                                                                              
                                                                              </>
                                                                              
                                                                            );;
                                                                          }
                                                                          })}
                                                                        </>
                                                                        :
                                                                        <></>} has rejected the form</span>
                                                                        <span>- Reason : {form.ReasonRejected}</span>
                                                                    </div>
                                                                    }

                                                                    {form.SubmissionStatus === "1" ? 
                                                                    <>{form.ModifiedAt !== "" ? 
                                                                        <div className="d-flex flex-column">
                                                                            <span>- Reapplication Date : {moment(form.ModifiedAt).format("llll")}</span>
                                                                            <span>- {formTimeline.length !== 0 ? 
                                                                            <>
                                                                              {currUserSA.map((user)=>{
                                                                              if(user?.uid === form.ApprovalUserID)
                                                                              {
                                                                                userFound = true;
                                                                                return(
                                                                                  <>
                                                                                  {user.displayName !== "" && user.displayName}
                                                                                  
                                                                                  </>
                                                                                  
                                                                                );
                                                                              }
                                                                              })}
                                                                            </>
                                                                            :
                                                                            <></>} has resubmitted the form</span>
                                                                            <span>- {formTimeline.length !== 0 ? 
                                                                            <>
                                                                              {currUserSA.map((user)=>{
                                                                              if(user?.uid === form.ApprovalUserID)
                                                                              {
                                                                                userFound = true;
                                                                                return(
                                                                                  <>
                                                                                  {user.displayName !== "" && user.displayName}
                                                                                  
                                                                                  </>
                                                                                  
                                                                                );;
                                                                              }
                                                                              })}
                                                                            </>
                                                                            :
                                                                            <></>} has rejected the form</span>
                                                                            <span>- Reason : {form.ReasonRejected}</span>
                                                                        </div> 
                                                                        :
                                                                       <>
                                                                       {form.ReasonRejected !== "" && form.ReasonRejected !== null ?
                                                                        <div className="d-flex flex-column">
                                                                             
                                                                            <span>- Rejected On : {moment(form.ApprovalDate).format("llll")}</span>
                                                                            <span>- {formTimeline.length !== 0 ? 
                                                                            <>
                                                                              {currUserSA.map((user)=>{
                                                                              if(user?.uid === form.ApprovalUserID)
                                                                              {
                                                                                userFound = true;
                                                                                return(
                                                                                  <>
                                                                                  {user.displayName !== "" && user.displayName}
                                                                                  
                                                                                  </>
                                                                                  
                                                                                );;
                                                                              }
                                                                              })}
                                                                              </>
                                                                            :<></>} has rejected the form</span>
                                                                            <span>- Reason : {form.ReasonRejected}</span>
                                                                        </div> :<>
                                                                        <div className="d-flex flex-column">
                                                                            <span>Form was good, it did not get rejected.</span>
                                                                        </div></>
                                                                        }
                                                                        
                                                                        </>
                                                                        }
                                                                        
                                                                    </>
                                                                    :
                                                                     <></>
                                                                    }
                                                                </div>
                                                                }
                                                            </div>
                                                            {form.SubmissionStatus === "2" && <span style={{position:'absolute',top:'100%',left:'0%',zIndex:'0',marginTop:'7px'}}>Rejected</span>}

                                                            {form.SubmissionStatus === "3" && <span style={{position:'absolute',top:'100%',left:'0%',zIndex:'0',marginTop:'7px'}}>Reapplication</span>}
                                                            
                                                        </div>

                                                        {/* 100% */}
                                                        <div className={`timeline-item
                                                            ${form.SubmissionStatus === "0" && 'background-grey'}
                                                            ${form.SubmissionStatus === "1" && 'background-1'}
                                                            ${form.SubmissionStatus === "2" && 'background-grey'}
                                                            ${form.SubmissionStatus === "3" && 'background-grey'}`
                                                            } style={{left:'100%'}}>
                                                            <div className="timeline-item-child">
                                                            {form.SubmissionStatus === "1" && <AiOutlineCheckCircle size={28} style={{marginLeft:'1px',marginTop:'1px'}} />}
                                                            
                                                                {form.SubmissionStatus === "1" &&
                                                                <div className={`timeline-content
                                                                ${form.SubmissionStatus === "0" && 'background-grey'}
                                                                ${form.SubmissionStatus === "1" && 'background-1'}
                                                                ${form.SubmissionStatus === "2" && 'background-grey'}
                                                                ${form.SubmissionStatus === "3" && 'background-grey'}`
                                                                } style={{right:'0%',marginTop:'15px',marginLeft:'10px'}}>
                                                                {form.SubmissionStatus === "1" && 
                                                                <div className="d-flex flex-column">
                                                                    <span>- {moment(form.ApprovalDate).format("llll")}</span>
                                                                    <span>{formTimeline.length !== 0 ? 
                                                                    <>
                                                                    {currUserSA.map((user)=>{
                                                                        if(user?.uid === form.ApprovalUserID)
                                                                        {
                                                                          userFound = true;
                                                                          return(
                                                                            <>
                                                                            - {user.displayName !== "" && user.displayName}
                                                                            
                                                                            </>
                                                                          );
                                                                        }
                                                                    })}
                                                                     </>
                                                                    :<></>} has approved the form</span>
                                                                </div>
                                                                }
                                                                </div>}
                                                            </div>
                                                            {form.SubmissionStatus === "1" && <span style={{position:'absolute',top:'100%',left:'0%',zIndex:'0',marginTop:'7px'}}>Approved</span>}
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4" style={{height:'100%',gap:'0.5rem',display:'flex',flexDirection:'column'}}>
                              
                                <div className="card submission-card" style={{height:'25%'}}>
                                    <div className="submission-icon">
                                        <FaScroll size={35}/>
                                    </div>
                                    <div className="submission-info">
                                        {formTimeline.map((form)=>{
                                            let userFound = false;
                                              return(
                                                  <>
                                                  <span className="font-s">Submission Date : {form.CreatedAt} </span>
                                                  <span className="font-xs" style={{opacity:'0.8'}}>By : 
                                                    {currUserSA.map((user)=>{
                                                        if(user?.uid === form.UserID)
                                                        {
                                                          userFound = true;
                                                          return(
                                                            <>
                                                            {user.displayName !== "" && user.displayName}
                                                            
                                                            </>
                                                            
                                                          );
                                                        }
                                                    })}
                                                  </span>
                                                  </>
                                              );
                                        })}
                                    </div>
                                </div>
                                <div className="card approval-card" style={{height:'25%'}}>
                                        <div className="approval-icon" style={{alignItems:'center'}}>
                                            <FaStamp size={35} style={{alignItems:'center'}} />
                                        </div>
                                        <div className="approval-info">
                                            {formTimeline.map((form)=>{
                                              let userFound = false;

                                                if(form.SubmissionStatus === '2' || form.SubmissionStatus === '3')
                                                {
                                                    return(
                                                        <>
                                                        <span className="font-s">Approval Date : {form.ApprovalDate} </span>
                                                        <span className="font-xs" style={{color:'red'}}>Reason Rejected : {form.ReasonRejected} </span>
                                                        <span className="font-xs" style={{opacity:'0.8'}}>By : 
                                                        {currUserSA.map((user)=>{
                                                            if(user?.uid === form.ApprovalUserID)
                                                            {
                                                              userFound = true;
                                                              return(
                                                                <>
                                                                 {user.displayName !== "" && user.displayName}
                                                                
                                                                </>
                                                                
                                                              );
                                                            }
                                                        })}
                                                        </span>
                                                        </>
                                                    );
                                                }
                                                else if(form.SubmissionStatus === '1')
                                                {
                                                    return(
                                                        <>
                                                        <span className="font-s">Approval Date : {form.ApprovalDate} </span>
                                                        <span className="font-xs" style={{color:'#61cfa3'}}>Form has been approved</span>
                                                        <span className="font-xs" style={{opacity:'0.8'}}>By :  
                                                        {currUserSA.map((user)=>{
                                                            if(user?.uid === form.ApprovalUserID)
                                                            {
                                                              userFound = true;
                                                              return(
                                                                <>
                                                                 { user.displayName !== "" && user.displayName}
                                                                
                                                                </>
                                                                
                                                              );
                                                            }
                                                        })}
                                                        </span>
                                                        </>
                                                    );
                                                }
                                                else
                                                {
                                                    return(
                                                        <>
                                                        <span> &nbsp; </span>
                                                        <span className="font-s" style={{color:'#eec16e'}}>Form is in process</span>
                                                        <span> &nbsp; </span>
                                                        </>
                                                    )
                                                }
                                            })}
                                        </div>
                                </div>
                                {/* another card */}
                                <div className="card cards-border" style={{height:'50%'}}>
                                    <div className="card-body d-flex flex-column" style={{height:'100%'}}>
                                        <div className="d-flex flex-row w-100">
                                            <div className="col-6 d-flex" style={{justifyContent:'start',columnGap:'10px'}}>
                                            <CgLink size={20} />
                                              <span className="font-s" style={{opacity:'0.8'}}> 
                                                Related Forms
                                              </span>
                                            </div>
                                        </div>
                                        <hr style={{margin:'0px',color:'#000',width:'100%'}}/>
                                        <div className="d-flex my-1" style={{height:'100%'}}>
                                            <div className="related-form py-1">
                                                <div className="w-100 px-2 my-1">
                                                    <div className="d-flex py-1 font-xxs" style={{borderBottom:'1px solid #ccc'}}>
                                                        <div className="col-md-3">
                                                            <span>Date Applied</span>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <span>Status</span>
                                                            
                                                        </div>
                                                        <div className="col-md-3">
                                                            <span>Name</span>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <span>Ref ID</span>
                                                        </div>
                                                    </div>
                                                    <div style={{height:'60%',overflow:'scroll'}}> 
                                                    {relatedForm.map(related=>{
                                          
                                                      let userFound = false;
                                                        return(
                                                            <div className="d-flex py-1 related-card" style={{borderBottom:'1px solid #ccc'}} >
                                                                <div className="col-md-3 related-span">
                                                                    <span>{moment(related.CreatedAt).format("DD MMM")}</span>
                                                                </div>
                                                                <div className="col-md-3 related-span">
                                                                    {related.SubmissionStatus === "0" && <span className="text-0"><RiTimer2Line size={12}/> Pending</span>}
                                                                    {related.SubmissionStatus === "1" && <span className="text-1"><AiOutlineCheckCircle size={12}/> Approved</span>}
                                                                    {related.SubmissionStatus === "2" && <span className="text-2"><AiOutlineCloseCircle size={12}/> Rejected</span>}
                                                                    {related.SubmissionStatus === "3" && <span className="text-3"><FiRotateCw size={12}/> Reapplication</span>}
                                                                    
                                                                </div>
                                                                <div className="col-md-3 related-span">
                                                                    <span>{currUserSA.map((user)=>{
                                                                        if(user?.uid === related.UserID)
                                                                        {
                                                                          userFound = true;
                                                                          return(
                                                                            <>
                                                                            {user.displayName !== "" && user.displayName}
                                                                            </>
                                                                          );
                                                                        }
                                                                    })}</span>
                                                                  </div>
                                                                <div className="col-md-3 related-span" style={{cursor:'pointer',color:'#4895cf'}} onClick={()=>{notify("copy"); navigator.clipboard.writeText(related.FormTrackingID);}}>
                                                                    <MdOutlineContentCopy size={14}/>
                                                                    <span>{related.FormTrackingID}</span>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </>}
                {/**responses */}
        <div
          className="modal fade modal-xl"
          id="form-action"
          tabIndex="-1"
          style={{overflow: "hidden"}}
          ref={viewFormActionRef}
        >
          <div className="modal-dialog modal-dialog-centered" >
            <div className="modal-content" style={{ background: '#f8fafa' }}>
              <div className="modal-body mx-3 my-3" style={{}} >
                <div className="d-flex" style={{ alignItems: 'center' }}>
                  <BiArrowBack className="mt-1" style={{ marginBottom: '10px', cursor: 'pointer' }} size={20} data-bs-dismiss="modal" onClick={()=>{}}/>
                  <h6 className="mt-2 mx-2">Approval</h6>
                </div>
                    <>
                    <div className="" style={{}}>
                      {formTimeline && formTimeline.map((form,formIndex) => {
                          return (
                            <>
                              {forms && forms.map((item, index) => {

                                if (item.FormID === form.FormID) {
                                  return (
                                    <>
                                      <div className="card"  style={{ marginTop:'10px', border: 'none', boxShadow: '5px 5px 5px -2px rgba(0,0,0,0.1)', background: 'rgb(95, 94, 175,0.1)' }}>
                                        <div className="card-body d-flex flex-column" >
                                          <span className="font-m">{item.FormName}</span>
                                          <span className="font-xs">{item.FormDescription}</span>
                                          {form.SubmissionStatus === "2" || form.SubmissionStatus === "3" ? 
                                          <span className="mt-2 font-xxs" style={{color:Colors.redButton}}>Reason Rejected : {previousReason}</span>
                                        : <></>}
                                        </div>
                                      </div>
                                      <div className="px-2 py-2" style={{}}>
                                        <div className="px-1 py-1" style={{  height: inner_height, overflow: 'scroll'}}>
                                        {item.FormData.map((data, questionIndex) => {
                                          return (
                                            <>
                                              {form.SubmissionData.map((answer, answerIndex) => {
                                                if (data.questionID === answer.questionID) {
                                                  return (
                                                    <div className="card mt-3" style={{ border: 'none', boxShadow: '2px 2px 4px 1px rgba(0,0,0,0.1)' }}>
                                                      <div className="card-body d-flex flex-column">
                                                        <span className="font-s">{data.questionName}</span>
                                                        {data.option === undefined
                                                          ?
                                                          <>
                                                            {data.questionType === "Multiline" ?
                                                              <>
                                                                <textarea style={{ border: 'none', borderBottom: '1px solid' }} value={answer.answer} disabled></textarea>
                                                                {form.SubmissionStatus === "3" ? answer.previousAnswer !== answer.answer ? <><div className="mt-1" style={{color:Colors.redButton}}><span className="font-xxs">Previous Answer : {answer.previousAnswer}</span></div></> : <></> : <></>}
                                                              </>
                                                              :
                                                              data.questionType === "Date Range" ? 
                                                              <>
                                                              <div className="mt-2 " style={{width:'100%'}}>
                                                                <span className="mx-1 font-xxs" style={{opacity:'0.8'}}>Start Date :</span>
                                                                <input type="date" value={answer.answer.start} name={data.questionType} placeholder="DateRange1" className="mx-1 font-xs" style={{marginBottom:'5px',border: 'none', width: '100%', background: 'none',borderBottom: '1px solid',marginBottom:'10px'}} disabled />
                                                                
                                                                <span className="mx-1 font-xxs" style={{opacity:'0.8'}}>End Date :</span>
                                                                <input type="date" value={answer.answer.end} name={data.questionType} placeholder="DateRange2" className="mx-1 font-xs" style={{marginBottom:'5px',border: 'none', width: '100%', background: 'none',borderBottom: '1px solid'}} disabled />
                                                              </div>
                                                              {form.SubmissionStatus === "3" ? isBothArrayEqual(answer.previousAnswer,answer.answer)
                                                                  ? <><div className="mt-1" style={{color:Colors.redButton}}><span className="font-xxs">Previous Answer : {moment(answer.previousAnswer.start).format("DD-MM-YYYY HH:mm")} | {moment(answer.previousAnswer.end).format("DD-MM-YYYY HH:mm")}</span></div></> : <></> : <></>}
                                                              </>
                                                              
                                                              :
                                                              <>
                                                                <div className="mt-2" style={{ borderBottom: '1px solid' }}>
                                                                    <input className="py-1" type={data.questionType === "ShortText" ? "text" : data.questionType === "Date" ? "date" : data.questionType === "Time" ? "time" : data.questionType === "Phone" ? "phone" : data.questionType === "Email" ? "email" : data.questionType === "Decimal" ? "number" :data.questionType === "Number" ? "number" : "text"} value={answer.answer} style={{ border: 'none', width: '100%', background: 'none' }} disabled />
                                                                  </div>
                                                                  {form.SubmissionStatus === "3" ? answer.previousAnswer !== answer.answer ? <><div className="mt-1" style={{color:Colors.redButton}}><span className="font-xxs">Previous Answer : {answer.previousAnswer}</span></div></> : <></> : <></>}
                                                              
                                                              </>
                                                            }
                                                          </>
                                                          :
                                                          <>
                                                            {data.questionType === "Multiple Choice" ?
                                                              <>
                                                                <label className="mt-2" >
                                                                  {data.option.map((options, optionIndex) => {
                                                                    return (
                                                                      <>
                                                                        <div className="d-flex" style={{ alignItems: 'center', textAlign: 'center' }}>
                                                                          <input className="py-1" type="radio" value={options} checked={radioArray.length !== 0 ? radioArray[questionIndex][optionIndex] : ""} style={{ width: '13px', height: '13px' }} disabled />
                                                                          <span className="mx-1 font-xxs">{options}</span>
                                                                        </div>
                                                                      </>
                                                                    );

                                                                  })}
                                                                  {form.SubmissionStatus === "3" ?
                                                                  answer.previousAnswer !== "" ?
                                                                  answer.previousAnswer.map(prev => {
                                                                    return (
                                                                      <>
                                                                      {answer.answer.map(ans=>{
                                                                        if(ans !== prev)
                                                                        {
                                                                          return(<><div className="mt-1" style={{color:Colors.redButton}}><span className="font-xxs">Previous Answer : {prev}</span></div></>);
                                                                        }
                                                                      })}
                                                                      </>
                                                                    )
                                                                  }):<></>:<></>}
                                                                 </label>
                                                              </>
                                                              :
                                                              data.questionType === "Checkboxes" ?
                                                                <>
                                                                  <div className="mt-2">
                                                                    {data?.option.map((options, optionIndex) => {
                                                                      return (
                                                                        <label style={{ display: 'flex', alignItems: 'center' }}>
                                                                          <input type='checkbox' style={{ width: '15px', height: '15px' }} checked={checkboxArray.length !== 0 ? checkboxArray[questionIndex][optionIndex] : ""} value={options} className='col-md-3' disabled />
                                                                          <span className="checkmark" style={{ marginRight: '10px' }}></span>
                                                                          <span className="font-xs" style={{ marginRight: '10px'}}>{options}</span>
                                                                        </label>
                                                                      );
                                                                    })}
                                                                  </div>
                                                                  {form.SubmissionStatus === "3" ?
                                                                  answer.previousAnswer !== "" ?
                                                                  isBothArrayEqual(answer.previousAnswer,answer.answer)
                                                                  ?
                                                                  <><div className="mt-1 mx-1" style={{color:Colors.redButton}}><span className="font-xxs">Previous Answer : {answer.previousAnswer.map(previous=>{return(<>{previous} </>)})}</span></div></>
                                                                  :<></>:<></>:<></>}
                                                                </>
                                                                :
                                                                data.questionType === "Dropdown" ?
                                                                  <>
                                                                    <div className="mt-2" >
                                                                      <select className="form-select font-xs" style={{ minWidth: '130px', width: '20%' }} disabled>

                                                                        <option className="" selected value={answer.answer}>{answer.answer}</option>
                                                                      </select>

                                                                    </div>
                                                                    {form.SubmissionStatus === "3" ? answer.previousAnswer !== answer.answer ? <><div className="mt-1" style={{color:Colors.redButton}}><span className="font-xxs">Previous Answer : {answer.previousAnswer}</span></div></> : <></> : <></>}
                                                                  </>
                                                                  :
                                                                  <>
                                                                  </>
                                                            }
                                                          </>
                                                        }
                                                      </div>
                                                    </div>
                                                  );
                                                }
                                              })}
                                            </>
                                          )
                                        })}
                                          
                                        </div>
                                        {form.SubmissionStatus === "0" || form.SubmissionStatus === "3" ? 
                                        <>
                                            {isReject ? 
                                            <>
                                            
                                            <div className="d-flex mt-2 col" style={{justifyContent:'end',}}>
                                                <div className="d-flex px-2 mx-2" style={{justifyContent:'start',background:'white',boxShadow: 'inset 1px 1px 2px 1px rgba(0,0,0,0.1)',height:'40px',width:'100%',borderRadius:'7px' }}>
                                                <input className="px-2" type="text" style={{background:'none',border:'none',width:'100%'}} value={reason} onChange={(e)=>{setReason(e.target.value)}} placeholder="Reason (Optional)"/>
                                                </div>
                                                <div className="d-flex" style={{gap:'10px'}}>
                                                <C_DialogButton
                                                  onClick={() => {rejectForm(form.SubmissionID);}}
                                                  color={"white"}
                                                  background={'#ca4254'}
                                                  borderColor={'#ca4254'}
                                                  buttonText={"REJECT"}
                                                  dismissAction={"modal"}
                                                />
                                                <C_DialogButton
                                                  onClick={() => {setIsReject(false);setReason("");}}
                                                  color={'#ca4254'}
                                                  background={'white'}
                                                  borderColor={'#ca4254'}
                                                  buttonText={"DISCARD"}
                                                />
                                                </div>
                                            </div>
                                            {emptyReason ? <div className="px-1 mt-1"><span className="font-xxs" style={{color:Colors.redButton}}>Must not be empty !</span></div> : <></>}
                                            
                                            </>
                                            :
                                            <div className="d-flex" style={{justifyContent:'end',gap:'10px'}}>
                                              <C_DialogButton
                                                onClick={() => {setIsReject(true);}}
                                                color={"white"}
                                                background={'#ca4254'}
                                                borderColor={'#ca4254'}
                                                buttonText={"REJECT"}
                                              />

                                              <C_DialogButton
                                                onClick={() => {approveForm(form.SubmissionID);}}
                                                color={"white"}
                                                background={'#3eb986'}
                                                borderColor={'#3eb986'}
                                                buttonText={"APPROVE"}
                                                dismissAction={"modal"}
                                              />  
                                            </div>
                                            }
                                        </> : <></>}
                                        
                                       
                                        
                                      </div>
                                    </>
                                  )
                                }
                              })}
                            </>
                          )
                        
                      }//end if
                      )}
                    </div>
                    </>
              </div>
            </div>
          </div>
        </div>
            </div>
        </div>
    </Fragment>
  );
}

export default FormSearch;
