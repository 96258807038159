import { Route } from "react-router-dom"
import { crmRoutes } from "../enums/Route"
import ProtectedRoute from "./ProtectedRoute"
import { Fragment } from "react"
import EmailVerification from "../containers/EmailVerification"

export const EmailVerificationRoute = () => (
    <Fragment>
        <Route 
            path={crmRoutes.auth.emailVerification}
            element={
                <ProtectedRoute>
                    <EmailVerification />
                </ProtectedRoute>
            }
        />
    </Fragment> 
)

