import { createSlice, current } from "@reduxjs/toolkit"

import { useAppSelector } from "../../hooks/storeHooks";

//initial state
const initialState = {

    //loading
    importClientAgentLoading : false,
    createClientAgentLoading : false,
    getAllClientAgentLoading : false,
    deleteClientAgentLoading : false,
    bulkCreateClientAgentLoading : false,
    bulkRemoveClientAgentLoading : false,
    insertClientAgentsLoading : false,
    removeClientAgentsLoading : false,
    getClientAgentLoading : false,

    //error
    importClientAgentError : undefined,
    createClientAgentError : undefined,
    getAllClientAgentError : undefined,
    deleteClientAgentError : undefined,
    bulkCreateClientAgentError : undefined,
    bulkRemoveClientAgentError : undefined,
    insertClientAgentsError : undefined,
    removeClientAgentsError : undefined,
    getClientAgentError : undefined,

    //data
    allAgentHandler : [],
    allSelectedAgentHandler : 
        {
            nodes: [],
            edges: [],
            handler: [],
        }
    ,

}

//slices
const clientAgentSlice = createSlice({
    name: "clientAgents",
    initialState,
    reducers : {
        importClientAgent(state){
            state.importClientAgentLoading = true;
        },
        importClientAgentSuccess(state, {payload}){
            const currentState = payload;
            state.allAgentHandler = currentState;

            state.importClientAgentLoading = false;
        },
        importClientAgentError(state, {payload}){
            const errorState = payload;
            state.importClientAgentError = errorState;

            state.importClientAgentLoading = false;
        },

        createClientAgent(state){
            state.createClientAgentLoading = true;
        },
        createClientAgentSuccess(state, {payload}){
            // const currentState = payload;
            // state.allAgentHandler = currentState;

            state.createClientAgentLoading = false;
        },
        createClientAgentError(state, {payload}){
            const errorState = payload;
            state.createClientAgentError = errorState;

            state.createClientAgentLoading = false;
        },

        getAllClientAgent(state){
            state.getAllClientAgentLoading = true;
        },
        getAllClientAgentSuccess(state, {payload}){
            const currentState = payload;
            state.allAgentHandler = currentState;

            state.getAllClientAgentLoading = false;
        },
        getAllClientAgentError(state, {payload}){
            const errorState = payload;
            state.getAllClientAgentError = errorState;

            state.getAllClientAgentLoading = false;
        },

        deleteClientAgent(state){
            state.deleteClientAgentLoading = true;
        },
        deleteClientAgentSuccess(state, {payload}){
            const currentState = current(state.allAgentHandler);
            const updatedState = state.allAgentHandler = currentState;
            state.allAgentHandler = updatedState;

            state.deleteClientAgentLoading = false;
        },
        deleteClientAgentError(state, {payload}){
            const errorState = payload;
            state.deleteClientAgentError = errorState;

            state.deleteClientAgentLoading = false;
        },

        bulkCreateClientAgent(state){
            state.bulkCreateClientAgentLoading = true;
        },
        bulkCreateClientAgentSuccess(state, {payload}){
            const currentState = payload;
            state.allAgentHandler = currentState;

            state.bulkCreateClientAgentLoading = false;
        },
        bulkCreateClientAgentError(state, {payload}){
            const errorState = payload;
            state.bulkCreateClientAgentError = errorState;

            state.bulkCreateClientAgentLoading = false;
        },

        bulkRemoveClientAgent(state){
            state.bulkRemoveClientAgentLoading = true;
        },
        bulkRemoveClientAgentSuccess(state, {payload}){
            const currentState = payload;
            state.allAgentHandler = currentState;

            state.bulkRemoveClientAgentLoading = false;
        },
        bulkRemoveClientAgentError(state, {payload}){
            const errorState = payload;
            state.bulkRemoveClientAgentError = errorState;

            state.bulkRemoveClientAgentLoading = false;
        },

        insertClientAgents(state){
            state.insertClientAgentsLoading = true;
        },
        insertClientAgentsSuccess(state, {payload}){

            // const addHandlerList = payload.addHandlerList;
            // const currentState = current(state.allAgentHandler);
            // let updatedArray = [...currentState];
            // addHandlerList.forEach(handlerItem => {
            //     const clientIdToUpdate = handlerItem.ClientID;

            //     const clientToUpdate = currentState.find(item => item.ClientID === clientIdToUpdate);

            //     if (clientToUpdate) {
            //         clientToUpdate.Handler = [...clientToUpdate.Handler, handlerItem];
            //     } else {
            //         updatedArray.push({ ClientID: clientIdToUpdate, Handler: [handlerItem] });
            //     }
            // });
            const addHandlerList = payload.addHandlerList;
            const currentState = current(state.allAgentHandler);
            let updatedArray = [...currentState]; 

            addHandlerList.forEach(handlerItem => {
              const clientIdToUpdate = handlerItem.ClientID;

              const clientIndex = updatedArray.findIndex(item => item.ClientID === clientIdToUpdate);

              if (clientIndex !== -1) {
                // Client found, update the Handler list in an immutable way
                const updatedClient = {
                  ...updatedArray[clientIndex],
                  Handler: [...updatedArray[clientIndex].Handler, handlerItem]
                };
                // Create a new array with the updated client
                updatedArray = [
                  ...updatedArray.slice(0, clientIndex),
                  updatedClient,
                  ...updatedArray.slice(clientIndex + 1)
                ];
              } else {
                // Client not found, add a new client entry
                updatedArray.push({ ClientID: clientIdToUpdate, Handler: [handlerItem] });
              }
            });

            // Update state
            state.allAgentHandler = updatedArray;

            state.insertClientAgentsLoading = false;
        },
        insertClientAgentsError(state, {payload}){
            const errorState = payload;
            state.insertClientAgentsError = errorState;

            state.insertClientAgentsLoading = false;
        },

        removeClientAgents(state){
            state.removeClientAgentsLoading = true;
        },
        removeClientAgentsSuccess(state, {payload}){
         
          const { removeHandlerList, clientList } = payload;
          const currentState = current(state.allAgentHandler);
          
          const updatedState = currentState.map(client => {
           
            if (clientList.includes(client.ClientID)) {
                return {
                    ...client,
                    Handler: client.Handler.filter(handler => !removeHandlerList.includes(handler.UserID))
                };
            }
            return client;
          });

          state.allAgentHandler = updatedState;
          state.removeClientAgentsLoading = false;
    
        },
        removeClientAgentsError(state, {payload}){
            const errorState = payload;
            state.removeClientAgentsError = errorState;

            state.removeClientAgentsLoading = false;
        },

        getClientAgent(state){
            state.getClientAgentLoading = true;
        },
        getClientAgentsSuccess(state, {payload}){
            const currentState = payload;
            state.allSelectedAgentHandler = currentState;

            state.getClientAgentLoading = false;
        },
        getClientAgentError(state, {payload}){
            const errorState = payload;
            state.getClientAgentError = errorState;

            state.getClientAgentLoading = false;
        },
    }
})

//export function
export const {
    importClientAgent,
    importClientAgentSuccess,
    importClientAgentError,
    createClientAgent,
    createClientAgentSuccess,
    createClientAgentError,
    getAllClientAgent,
    getAllClientAgentSuccess,
    getAllClientAgentError,
    deleteClientAgent,
    deleteClientAgentSuccess,
    deleteClientAgentError,
    bulkCreateClientAgent,
    bulkCreateClientAgentSuccess,
    bulkCreateClientAgentError,
    bulkRemoveClientAgent,
    bulkRemoveClientAgentSuccess,
    bulkRemoveClientAgentError,
    insertClientAgents,
    insertClientAgentsSuccess,
    insertClientAgentsError,
    removeClientAgents,
    removeClientAgentsSuccess,
    removeClientAgentsError,
    getClientAgent,
    getClientAgentsSuccess,
    getClientAgentError
} = clientAgentSlice.actions

//export selector

// data
export const selectAllAgentHandler = () =>
  useAppSelector((state) => state.clientAgents.allAgentHandler);

export const selectAllSelectedAgentHandler = () =>
  useAppSelector((state) => state.clientAgents.allSelectedAgentHandler);

// loading
export const selectImportClientAgentLoading = () =>
  useAppSelector((state) => state.clientAgents.importClientAgentLoading);

export const selectCreateClientAgentLoading = () =>
  useAppSelector((state) => state.clientAgents.createClientAgentLoading);

export const selectGetAllClientAgentLoading = () =>
  useAppSelector((state) => state.clientAgents.getAllClientAgentLoading);

export const selectDeleteClientAgentLoading = () =>
  useAppSelector((state) => state.clientAgents.deleteClientAgentLoading);

export const selectBulkCreateClientAgentLoading = () =>
  useAppSelector((state) => state.clientAgents.bulkCreateClientAgentLoading);

export const selectBulkRemoveClientAgentLoading = () =>
  useAppSelector((state) => state.clientAgents.bulkRemoveClientAgentLoading);

export const selectInsertClientAgentsLoading = () =>
  useAppSelector((state) => state.clientAgents.insertClientAgentsLoading);

export const selectRemoveClientAgentsLoading = () =>
  useAppSelector((state) => state.clientAgents.removeClientAgentsLoading);

export const selectGetClientAgentLoading = () =>
  useAppSelector((state) => state.clientAgents.getClientAgentLoading);

// error
export const selectImportClientAgentError = () =>
  useAppSelector((state) => state.clientAgents.importClientAgentError);

export const selectCreateClientAgentError = () =>
  useAppSelector((state) => state.clientAgents.createClientAgentError);

export const selectGetAllClientAgentError = () =>
  useAppSelector((state) => state.clientAgents.getAllClientAgentError);

export const selectDeleteClientAgentError = () =>
  useAppSelector((state) => state.clientAgents.deleteClientAgentError);

export const selectBulkCreateClientAgentError = () =>
  useAppSelector((state) => state.clientAgents.bulkCreateClientAgentError);

export const selectBulkRemoveClientAgentError = () =>
  useAppSelector((state) => state.clientAgents.bulkRemoveClientAgentError);

export const selectInsertClientAgentsError = () =>
  useAppSelector((state) => state.clientAgents.insertClientAgentsError);

export const selectRemoveClientAgentsError = () =>
  useAppSelector((state) => state.clientAgents.removeClientAgentsError);

export const selectGetClientAgentError = () =>
  useAppSelector((state) => state.clientAgents.getClientAgentError);

//export reducer
const clientAgentReducer = clientAgentSlice.reducer;

export default clientAgentReducer;
