import React, {
    useState,
    useEffect,
    useRef,
} from "react";
import {
    useSearchParams,
  } from "react-router-dom";
import './GaPortal.scss'
import GaSidebar from "./GaSidebar";
import { BiArrowBack } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import AnimatedProgressProvider from "../../components/AnimatedProgressProvider";
import { MdCancel, MdEditSquare, MdOutlineAnalytics, MdOutlineManageAccounts, MdOutlineOtherHouses } from "react-icons/md";
import { HiOutlineUserGroup } from "react-icons/hi";
import { off, onValue, ref, update } from "firebase/database";
import { db } from "../../firebaseInit"; 
import usageObject from '../../enums/json/plan.json';
import { LucideAlertCircle } from "lucide-react";
import { BsCheck2Circle, BsFillBoxSeamFill } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import { RiEditFill } from "react-icons/ri";
import { Modal } from "bootstrap";
import C_DialogButton from "../../components/C_DialogButton";
import { FaInfinity } from "react-icons/fa";

//slices
import { retrieveCurrUsage, selectRetrieveCurrUsageLoading, selectUsageData } from "../../stores/slices/usageSlices";
import { sendTenantQuotaNotificationMail } from "../../stores/slices/tenantSlices";
import { selectCurrUserContext } from "../../stores/slices/companySlices";

function GaTenantQuota() {
    const modalRef = useRef();
    const divRef = useRef();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [divWidth,setDivWidth] = useState(0);
    const [searchParams, setSearchParams] = useSearchParams();
    const [usage,setUsage] = useState([]);
    const [editArr,setEditArr] = useState([]);
    const [isEdit,setIsEdit] = useState(false);
    const [mainUsageArr,setMainUsageArr] = useState([]);
    const [notifyMessage,setNotifyMessage] = useState([]);
    const [companyName,setCompanyName] = useState("");
    const [plan,setPlan] = useState("");
    const [viewInfo,setViewInfo] = useState(false);
    const [viewIndex,setViewIndex] = useState(0);
    const [isProcessing,setIsProcessing] = useState(false);
    const [isConfirm,setIsConfirm] = useState(false);
    const [changes,setChanges] = useState(false);
    const [processingMsg,setProcessingMsg] = useState("");
    const { currTotalUsage } = selectCurrUserContext();
    const usageDataLoading = selectRetrieveCurrUsageLoading();

    const showModal = () => {
        const modalEle = modalRef.current;
        const bsModal = new Modal(modalEle, {
            backdrop: "static",
            keyboard: false,
        });
        bsModal.show();
    };

    const hideModal = () => {
        const modalEle = modalRef.current;
        const bsModal = Modal.getInstance(modalEle);
        bsModal.hide();
    };

    useEffect(()=>{
        if(JSON.stringify(editArr) !== JSON.stringify(usage)){
            setChanges(true);
        } else {
            setChanges(false);
            setNotifyMessage([]);
        }
        
    },[editArr])

    // console.log(searchParams.get("code"))
    useEffect(() => {
        if (divRef.current) {
            const divWidth = divRef.current.offsetWidth;
            setDivWidth(divWidth)
        }
    }, []);

    useEffect(()=>{
        if(Array.from(searchParams).length > 0){
            if(searchParams.get("tenantID") !== null && searchParams.get("companyID") !== null && searchParams.get("plan") !== null && searchParams.get("companyName") !== null) {
                const tenantID = searchParams.get("tenantID");
                const companyID = searchParams.get("companyID");
                const plan = searchParams.get("plan");
                const companyName = searchParams.get("companyName");
                dispatch(retrieveCurrUsage({TenantID:tenantID,CompanyID:companyID}));

                const usageRef = ref(db, 'usage/' + tenantID + '/' + companyID + '/');
                onValue(usageRef, async (snapshot) => {
        
                    if(snapshot.exists()){
                        const usageObj = snapshot.val();
                        const usages = Object.keys(usageObj.usage).map((key) => ({ ...usageObj.usage[key] }));
                        setUsage(usages);
                        setMainUsageArr(usageObj.usage)
                    }else{
                        setUsage([]);
                        setMainUsageArr([]);
                    }
                });
                //useState
                setPlan(plan);
                setCompanyName(companyName)

            }
        }
    },[searchParams])

    const category = [
        {
            CategoryName: "User Management",
            CategoryID: "c1"
        },
        {
            CategoryName: "Analytics & Data",
            CategoryID: "c2"
        },
        {
            CategoryName: "Client Management",
            CategoryID: "c3"
        },
        {
            CategoryName: "Others",
            CategoryID: "c4"
        }
    ];

    const onHandleSetEdit = () =>{
        const newList = JSON.stringify(usage);
      

        setEditArr(JSON.parse(newList));
    }

    const handleQuotaInput = (value,index) => {
        const newList = [...editArr];
        newList[index].Quota = parseInt(value);

        setEditArr(newList);
    }

    const savePlan = async () => {
        const editList = [...editArr];
        const mainList = mainUsageArr;
        const errArr = [];
        const currPlan = plan; //useState
        let isTrue = true;

        editList.forEach((arr,index)=>{
            let minQuota = usageObject[currPlan][arr.SectionID]
            if(arr.Quota < minQuota){
                isTrue = false;
                errArr.push({errIdx:index,err:`${arr.SectionName} quota must not be less than ${minQuota}`})
            } else if(arr.Quota !=="unlimited" && (arr.Quota === "" || isNaN(arr.Quota) || arr.Quota === undefined || arr.Quota === null)){
                isTrue = false;
                errArr.push({errIdx:index,err:`${arr.SectionName} quota must not be empty`})
            } else {
                Object.keys(mainList).map(key=>{
                    if(key === arr.SectionID){
                        if(arr.Quota !== "unlimited"){
                            mainList[key].Quota = parseInt(arr.Quota);
                        } else {
                            mainList[key].Quota = arr.Quota;
                        }
                    }
                })
            }
        })

        setNotifyMessage(errArr);

        if(isTrue){
            setIsConfirm(false);
            setIsProcessing(false);
            showModal();
            setMainUsageArr(mainList);
        }
        
    }   

    const confirmPlan = async () => {
        setProcessingMsg("Processing..")
        setIsConfirm(true);
        setIsProcessing(true);
        const tenantID = searchParams.get("tenantID");
        const companyID = searchParams.get("companyID");
        await new Promise((resolve) => setTimeout(resolve, 2000));
        setProcessingMsg("Updating Quota..");

        setTimeout(()=>{
            update(ref(db, 'usage/' + tenantID + '/' + companyID + '/'), {
                usage:mainUsageArr
            })
            .then(async (res)=>{
                console.log("success")
                setProcessingMsg("Sending Email..");
                await new Promise((resolve) => setTimeout(resolve, 2000));
                dispatch(
                    sendTenantQuotaNotificationMail({
                        hostEmail:searchParams.get("hostEmail"),
                        userName:searchParams.get("userName"),
                        companyName:searchParams.get("companyName"),
                        quotaArray:editArr
                    })
                  )
                await new Promise((resolve) => setTimeout(resolve, 3000));
                setProcessingMsg("Process done");
                setIsProcessing(false)
            })
            .catch((err)=>{
                console.log(err)
            })
        },3000)
    }
    
    return (
        <div className='gaPortalMainContainer'>
            <GaSidebar/>
            <div className="contentContainer" ref={divRef}>

                {/* modal start*/}
                <div className="modal fade" tabIndex="-1" id="tenant-confirmation" ref={modalRef} style={{}}>
                    <div className="modal-dialog modal-dialog-centered" style={{}}>
                        <div className="modal-content px-3" style={{overflowY: "scroll" }}>
                            <div className="modal-header modalHeader">
                                <div className="title">
                                    <span className="font-s">
                                        Confirmation
                                    </span>
                                </div>
                                <AiOutlineClose cursor='pointer' size={16} onClick={()=>{!isProcessing && hideModal();}}/>
                            </div>
                            <div className="modal-body quota-confirmation-body">
                                <div className="quota-confirmation-container">
                                    <span className="font-xs" style={{ fontWeight: "bold" }} >
                                        {isProcessing && isConfirm ? "Please wait while we are processing the data." : 
                                        <>
                                        {isConfirm && !isProcessing ? "The quota value has been changed. A notification email has been sent to the user to notify them." : "Are you sure you want to proceed? This action cannot be reverted"}
                                        </>}
                                    </span>
                                    <div className="confirmation-action-container">
                                        <div className={`py-1 loadingContainer ${!isConfirm && "hide-loading-container"}`}>
                                            <div className={`${isProcessing ? "loading-spinner" : "hide-loading-spinner"}`}>
                                            </div>
                                            {isProcessing ?
                                            <>
                                            <span>{processingMsg}</span>
                                            </>
                                            :
                                            <div className="finished-loading-container">
                                            <span>{processingMsg}</span> 
                                            <div><BsCheck2Circle size={18}  style={{fontWeight:"600",color:"green"}}/></div>
                                            </div>}
                                        </div>
                                        <div className="btn-container">
                                        {!isConfirm ?
                                        <>
                                            <C_DialogButton
                                            onClick={()=>{hideModal();}}
                                            color={'#4287f5'}
                                            background={'white'}
                                            borderColor={'#4287f5'}
                                            buttonText={"DISCARD"}
                                            
                                            />
                                            <C_DialogButton
                                                onClick={()=>{confirmPlan();}}
                                                color={"white"}
                                                background={'#4287f5'}
                                                borderColor={'#4287f5'}
                                                buttonText={"CONTINUE"}
                                            />
                                        </>
                                        :
                                        <>
                                            {isProcessing ? 
                                            <C_DialogButton 
                                                background={'#eeeeee'}
                                                borderColor={'#eeeeee'}
                                                buttonText={"CLOSE"}
                                                disabled
                                            />
                                            :
                                            <C_DialogButton 
                                                background={'#6f7df7'}
                                                borderColor={'#6f7df7'}
                                                buttonText={"CLOSE"}
                                                onClick={()=>{hideModal();setIsEdit(false);}}
                                            />
                                            }
                                        </>
                                        }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* modal end */}
                <div className='tenantListMainContainer'style={{width:divWidth}}>
                    <div className="tenantHeader">
                        <div className="tenant-header-back-action" onClick={()=>{navigate("/GaPortal")}}><BiArrowBack size={30}/></div>
                        <span>Usage & Quota</span>
                    </div>
                    <div className="tenant-quota-container">
                        <div className="company-details">
                            <span>{`${companyName} (${plan})`}</span>
                            {isEdit ? 
                            <>
                            <div className="edit-btn-container">
                                <div className="cancel-btn" onClick={()=>{
                                    setIsEdit(false);
                                    setNotifyMessage([]);
                                }}>
                                    <div className="cancel-icon"><MdCancel size={18}/></div>
                                    <span>Cancel</span>
                                </div>
                                {changes ? 
                                <div className="save-btn" onClick={()=>{
                                    savePlan();
                                }}>
                                    <div className="save-icon"><BsFillBoxSeamFill size={16}/></div>
                                    <span>Save</span>
                                </div> 
                                :
                                <div className="disabled-save-btn" onClick={()=>{
                                    
                                }} disabled>
                                    <div className="save-icon"><BsFillBoxSeamFill size={16}/></div>
                                    <span>Save</span>
                                </div>}
                            </div>
                            </>
                            :
                            <div className="edit-btn-container">
                                <div className="edit-btn" onClick={()=>{
                                    setIsEdit(true);
                                    onHandleSetEdit();
                                }}>
                                    <div className="edit-icon"><RiEditFill size={16}/></div>
                                    <span>Customize</span>
                                </div>
                            </div>
                            }
                        </div>
                        <div className="tenant-quota-inner-container">
                        {category.map((cat,idx)=>{
                            const planArr = plan !== undefined && plan !== "" ? Object.keys(usageObject[plan]).map(key=>usageObject[plan][key]) : [];
                            const filteredPlan = planArr?.filter(element=>element.CategoryID === cat.CategoryID);
                            return (
                            <div className="quota-category-container">
                                <div className="quota-category-header">
                                    <div className="quota-category-name">
                                        <div>{cat.CategoryID === "c1" && <MdOutlineManageAccounts size={18}/>}
                                        {cat.CategoryID === "c2" && <MdOutlineAnalytics size={18}/>}
                                        {cat.CategoryID === "c3" && <HiOutlineUserGroup size={18}/>}
                                        {cat.CategoryID === "c4" && <MdOutlineOtherHouses size={18}/>}</div>
                                        <span className="category-span">{cat.CategoryName}</span>
                                    </div>
                                    <div className="plan-quota-info">
                                        <div className="plan-quota-info-icon" 
                                        onMouseOver={()=>{setViewInfo(true);setViewIndex(idx);}} 
                                        onMouseLeave={()=>{setViewInfo(false);}}><LucideAlertCircle size={20}/></div>

                                        <div className={`${viewInfo && viewIndex === idx ? "info-box" : "hide-info-box"}`}>
                                            <span className="title">Min quota for {plan}</span>
                                            <div className="info-container">
                                            {filteredPlan.length > 0 && filteredPlan.sort((a,b)=>a.SectionName.localeCompare(b.SectionName)).map((plan,index)=>{
                                                return(
                                                    <span>{`${plan.SectionName}: ${plan.Quota} (${plan.Unit})`}</span>
                                                );
                                            })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="usage-outer-container">
                                    {(Array.isArray(usage) && usage.length > 0) && usage.sort((a,b)=>a.SectionName.localeCompare(b.SectionName)).map((usg,index)=>{
                                        const err = notifyMessage.find(element=>element.errIdx === index);
                                        if(usg.CategoryID === cat.CategoryID){
                                            if(usg.SectionID !== "s6"){
                                                const currentUsage = currTotalUsage?.find(currQuota => currQuota?.SectionID === usg?.SectionID && currQuota?.SectionID !== "s6")?.Usage;
                                                if(currentUsage !== undefined){
                                                    const percentage = usg?.Quota === "unlimited" ? 100 : (currentUsage / usg?.Quota) * 100;
                                                    return (
                                                        <div key={usg.SectionID} className="usage-inner-container">
                                                            <div className="section-container">
                                                               <span>{usg.SectionName}</span>
                                                                {(isEdit && usg?.Quota !== "unlimited") ? <>
                                                                    <div className="section-name-err">
                                                                        {err !== undefined && <span className="err-message">{err.err}</span>} 
                                                                        <span className="usage-count">
                                                                            {currentUsage + " of "}
                                                                            <input className="usage-count-input" type="number" value={editArr[index]?.Quota} 
                                                                            onChange={(e)=>{handleQuotaInput(e.target.value, index);}}/>
                                                                            {" (" + usg?.Unit+ ")"}
                                                                        </span>
                                                                    </div>
                                                                </>:
                                                                <>
                                                                {usg?.Quota === "unlimited" && <span className="usage-count">{usg?.Quota + " (" + usg?.Unit+ ")" }</span>}
                                                                {usg?.Quota !== "unlimited" && <span className="usage-count">{currentUsage + " of " + usg?.Quota + " (" + usg?.Unit+ ")" }</span>}
                                                                </>
                                                                }
                                                            </div>
                                                            
                                                            {/* <input className="" /> */}
                                                            <div className="usage-progression">
                                                                <AnimatedProgressProvider
                                                                    valueStart={0}
                                                                    valueEnd={percentage}
                                                                    duration={1}
                                                                    easingFunction={(t) => t}
                                                                >
                                                                    {(value) => {
                                                                        const roundedValue = Math.round(value);
                                                                        return (
                                                                            <div className="usage-progress-bar">
                                                                                <div className="fill" style={{ 
                                                                                    width: `${roundedValue < 101 ? roundedValue : 100}%`,
                                                                                    backgroundColor: `${(roundedValue < 65 || usg?.Quota === "unlimited" ) ? "#4171e1" : roundedValue < 85 ? "#FFAD23" : "#FF5733"}`,
                                                                                }}></div>
                                                                                {usg?.Quota === "unlimited" && <span style={{ color: "#ffffff",display:"flex",alignItems:"center" }}><FaInfinity size={14}/></span> }
                                                                                {usg?.Quota !== "unlimited" && <span style={{ color: `${roundedValue < 51 ? "#000000" : "#ffffff"}` }}>{roundedValue + "%"}</span>}
                                                                            </div>
                                                                        );
                                                                    }}
                                                                </AnimatedProgressProvider>
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                              
                                            } else {
                                                const currentUsage = currTotalUsage?.find(currQuota => currQuota?.SectionID === usg?.SectionID && currQuota?.SectionID === "s6")?.Usage;
                                                return (
                                                    <div key={usg.SectionID} className="usage-inner-container">
                                                        <div className="section-container">
                                                            <span>{usg.SectionName}</span>
                                                            <div className="section-name-err">
                                                                {err !== undefined && <span className="err-message">{err.err}</span>} 
                                                            </div>
                                                        </div>
                                                        {Array.isArray(currentUsage) && currentUsage.map((group)=>{
                                                            const percentage = (group.columnCount / usg?.Quota) * 100;
                                                            return(
                                                                <>
                                                                <div className="group-quota-container">
                                                                    <div className="group-section-container">
                                                                        <span>{group.GroupName}</span>
                                                                        {isEdit ? <>
                                                                            <span className="usage-count">
                                                                                {group.columnCount + " of "}
                                                                                <input className="usage-count-input" type="number" value={editArr[index]?.Quota} onChange={(e)=>{handleQuotaInput(e.target.value, index);}}/>
                                                                                {" (" + usg?.Unit+ ")"}
                                                                            </span>
                                                                        </>:<span className="usage-count">{group.columnCount + " of " + usg?.Quota + " (" + usg?.Unit+ ")" }</span>}
                                                                    </div>
                                                                    
                                                                    <div className="usage-progression">
                                                                        <AnimatedProgressProvider
                                                                            valueStart={0}
                                                                            valueEnd={percentage}
                                                                            duration={1.5}
                                                                            easingFunction={(t) => t}
                                                                        >
                                                                            {(value) => {
                                                                                const roundedValue = Math.round(value);
                                                                                return (
                                                                                    <div className="usage-progress-bar">
                                                                                        <div className="fill" style={{ 
                                                                                            width: `${roundedValue < 101 ? roundedValue : 100}%`,
                                                                                            backgroundColor: `${roundedValue < 65 ? "#4171e1" : roundedValue < 85 ? "#FFAD23" : "#FF5733"}`,
                                                                                        }}></div>
                                                                                        <span style={{ color: `${roundedValue < 51 ? "#000000" : "#ffffff"}` }}>{roundedValue}%</span>
                                                                                    </div>
                                                                                );
                                                                            }}
                                                                        </AnimatedProgressProvider>
                                                                    </div>
                                                                </div>
                                                                </>
                                                            );//end return
                                                        })}
                                                    </div>
                                                ); //end return
                                            } //end else
                                        }//end if
                                    })}
                                </div>
                            </div>
                            );// end return
                        })}
                       </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GaTenantQuota;