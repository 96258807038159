const component = {
  MuiTab: {
    styleOverrides: {
      root: ({ theme }) => ({
        ...theme.typography.bodyTextSemiBold,
        color: theme.palette.text.disabled,
        "&:focus": {
          color: theme.palette.text.primary,
        },
        "&.Mui-selected": {
          color: theme.palette.text.primary,
        },
        overflow: "visible",
        lineHeight: "unset",
        padding: "5px 16px",
        minHeight: "40px",
      }),
    },
  },
};

export default component;
