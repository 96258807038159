import React from "react";
import PropTypes from "prop-types";;
import { Dialog } from "@mui/material";
import { Image } from "../DataDisplay/Image";

export const ImagePreview = ({ open, handleClose, url }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      maxWidth="xs"
      fullWidth
      PaperProps={{
        sx: {},
      }}
    >
      <Image src={url} width={1} height={1} alt={url} />
    </Dialog>
  );
};

ImagePreview.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  url: PropTypes.any,
};
