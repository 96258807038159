const position = {
  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  flexStart: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  flexEnd: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
};

export default position;
