import React, { useMemo, useState } from "react";
import { Box, Stack, Tooltip, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { ImageOutlined, SearchRounded } from "@mui/icons-material";
import { convertDate } from "../../util/converter/dateConverter";
import { toggleImagePreview } from "../../stores/slices/toggleSlice";

export const FileManagerContent = ({
  searchFileText,
  data,
  defaultValue,
  selectedFiles,
  setSelectedFiles,
  handleChange,
  handleCloseFileManagerModal,
}) => {
  const dispatch = useDispatch();
  const [hoverTarget, setHoverTarget] = useState(null);
  function bytesToMB(bytes) {
    if (bytes <= 10240) {
      return (bytes / 1024).toFixed(2) + " KB"; // Convert to KB
    } else {
      return (bytes / (1024 * 1024)).toFixed(2) + " MB"; // Convert to MB
    }
  }

  const handleSelectFiles = (payload) => {
    setSelectedFiles((prevValue) => {
      if (prevValue === payload) {
        handleChange(payload);
        handleCloseFileManagerModal();
        return payload;
      } else {
        return payload;
      }
    });
  };

  const handlePreviewImage = (url) =>
    dispatch(
      toggleImagePreview({
        url: url,
        isOpen: true,
      })
    );

  const renderImages = useMemo(
    () =>
      data
        .filter((item) =>
          searchFileText !== "" ? item.name.includes(searchFileText) : item
        )
        .map((item) => {
          const fileSizeInMB = bytesToMB(item.size);
          const date = convertDate(item.created_at);
          const isSelected =
            selectedFiles && selectedFiles.file_id === item.file_id;
          const isDefaultValue =
            defaultValue && defaultValue.file_id === item.file_id;
          const isHover = hoverTarget === item.file_id;
          return (
            <Box
              key={item.file_id}
              component="div"
              sx={{
                width: { sm: 0.5, md: 0.25 },
                height: "80px",
                padding: "5px",
              }}
            >
              <Stack
                direction="row"
                sx={({ palette }) => ({
                  width: 1,
                  height: 1,
                  borderRadius: "5px",
                  border: isSelected
                    ? `2px solid ${palette.border.active}`
                    : `1px solid ${palette.border.light}`,
                  backgroundColor: isDefaultValue
                    ? palette.action.disabled
                    : "unset",
                  alignItems: "center",
                  padding: "5px",
                  cursor: isDefaultValue ? "default" : "pointer",
                })}
              >
                <Stack
                  justifyContent="center"
                  alignItems="center"
                  height={1}
                  sx={{ aspectRatio: 1 }}
                  onClick={() => {
                    handlePreviewImage(item.url);
                  }}
                  onMouseEnter={() => setHoverTarget(item.file_id)}
                  onMouseLeave={() => setHoverTarget(null)}
                >
                  <SearchRounded
                    sx={({ palette, icons }) => ({
                      ...icons.large,
                      opacity: !isHover ? 0 : 1,
                      position: !isHover ? "initial" : "absolute",
                      marginBottom: !isHover ? "-50px" : "none",
                      transition: "all 0.5s ease",
                      color: palette.text.main,
                    })}
                  />
                  <ImageOutlined
                    sx={({ palette, icons }) => ({
                      ...icons.large,
                      opacity: isHover ? 0 : 1,
                      position: isHover ? "initial" : "absolute",
                      marginBottom: isHover ? "-50px" : "none",
                      transition: "all 0.5s ease",
                      color: palette.text.main,
                    })}
                  />
                </Stack>
                <Stack
                  direction="column"
                  flexGrow={1}
                  overflow="hidden"
                  onClick={() => {
                    if (!isDefaultValue) {
                      handleSelectFiles(item);
                    }
                  }}
                >
                  <Tooltip title={item.name} placement="top">
                    <Typography
                      variant="bodyTextSemiBold"
                      sx={({ typography }) => ({ ...typography.textOverflow })}
                    >
                      {item.name}
                    </Typography>
                  </Tooltip>
                  <Typography variant="remark">{fileSizeInMB}</Typography>
                  <Stack direction="row" alignItems="center">
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Typography variant="remark">{date}</Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </Box>
          );
        }),
    [data, defaultValue, searchFileText, selectedFiles, hoverTarget]
  );

  return renderImages;
};

