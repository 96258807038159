import { Fragment, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { selectDialogBox, selectDrawerEditor, toggleDrawerEditor, toggleDialogBox } from "../../stores/slices/toggleSlice.js";
import { openShareTransactionDialog, removeTransaction, retrieveAllTransactions, selectAllTransactions, selectRemoveTransactionLoading, selectretrieveAllTransactionsLoading, selectSendEmailTransactionLoading, selectTransactionCustomFields, selectTransactionsOptions, selectVoidTransactionLoading, voidTransaction } from "../../stores/slices/transactionSlices";
import { DataTable } from "../../components/DataTableRenderer/DataTable";
import { useNavigation } from "../../hooks/useNavigation";
import { transactionTypeNameEnum } from "../../enums/Transaction";
import { TableRouteConverter } from "../../constants/Invoicing/TableRouteConverter";
import { TableContent } from "../../constants/Invoicing/TableContent";
import { TABLE } from "../../constants/Invoicing/Table";
import { GridLogicOperator } from "@mui/x-data-grid";
import { ConfirmationDialog } from "../../components/Utils/ConfirmationDialog";
import { DrawerEditor } from "../../components/AccComponents/DrawerEditor";
import { TableActionEnum } from "../../enums/TableActionEnum";
import { SendTransactionForm } from "./SendTransactionForm.js";
import { ShareTransactionDialog } from "./ShareTransactionDialog.js";
import { TransactionEnum } from "../../enums/components/Transaction.js";
import { advanceFilterOptionsConverter, tablePropertiesConverter } from "../../util/converter/tablePropertiesConverter.js";
import { useNavigate, useSearchParams } from "react-router-dom";
import { TableTools } from "../AccContainers/Global/TableTools.js";
import { selectCurrSa } from "../../stores/slices/persistRolesSlices.js";
import { selectCurrRoles } from "../../stores/slices/roleSlices.js";
import { Button, Stack } from "@mui/material";
import { AddRounded } from "@mui/icons-material";
import { globalAccCompanyID } from "../../http-common.js";

export const InvoicingTable = (props) => {

  const [searchParams, setSearchParams] = useSearchParams();
  const category = props.category;
  const subCategory = props.subCategory;
  // const category = searchParams.get("category");
  // const subCategory = searchParams.get("sub_category");
  const selectedFormID = props.selectedFormID;
  const setSelectedFormID = props.setSelectedFormID;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currSa = selectCurrSa();
  const currRoles = selectCurrRoles();

  const dialogFormDefaultData = { void_reason: "", form_design: null };
  const { control, watch, reset } = useForm({
    defaultValues: dialogFormDefaultData,
    shouldUnregister: false,
  });

  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const { handleNavigate } = useNavigation();
  // const transactionTypeName = transactionTypeNameEnum[subLocation];

  useEffect(() => {
    if(globalAccCompanyID && subCategory !== ""){
      setCurrentTabIndex(0);
      const transactionType =
        subCategory !== "payments" &&
        subCategory !== "refunds" &&
        subCategory !== "credit-notes"
          ? subCategory
          : `${category}-${subCategory}`;
      dispatch(retrieveAllTransactions({transactionType, currRoles, currSa}));
    }
   
  }, [subCategory, currRoles, currSa, globalAccCompanyID]);

  const [filter, setFilter] = useState({
    payment_status: null,
    outstanding: false,
    partial: false,
    data: null,
    search: "",
    advance: [
      {
        id: `advanced_search_status`,
        field: "status",
        operator: "in",
        value: [],
      },
    ],
    advanceSelected: {},
    customField: null,
    startDate: null,
    endDate: null,
  });
  const dialogBox = selectDialogBox();
  const drawerEditor = selectDrawerEditor();

  const allTransactions = selectAllTransactions();

  const transactionCustomFields = selectTransactionCustomFields();
//   const currentUser = selectCurrentUserDetails();
  const transactionOption = selectTransactionsOptions();
  // const currentCompany = selectCurrentCompany();
  const retrieveTransactionsLoading = selectretrieveAllTransactionsLoading();
  const removeTransactionLoading = selectRemoveTransactionLoading();
  const voidTransactionLoading = selectVoidTransactionLoading();
  const sendEmailLoading = selectSendEmailTransactionLoading();
  const transactionSubRoutes = TableRouteConverter[subCategory];
  const paymentStatusTabEnum = {
    2: "Coming Due",
    3: "Overdue",
    5: "Paid",
  };
  const transactionRoutes = subCategory.toUpperCase();
//   const tableTab = TableTabList[transactionRoutes][transactionSubRoutes];
  const handleOnNavigate = (duplicateId = false) => {
    if(duplicateId){
      updateUrl({action:"new",duplicate_id:duplicateId});
    } else {
      updateUrl({action:"new"});
    }
    // handleNavigate(
    //   `${ROUTES[transactionRoutes][transactionSubRoutes].ADD.ROUTES}${duplicateParams}`
    // );
  };
  // const handleOnNavigateBatch = () => {
  //   handleNavigate(
  //     ROUTES[transactionRoutes][transactionSubRoutes].BATCH.ROUTES
  //   );
  // };

  const advanceFilterOptions = useMemo(() => {
    const columnFields = {
      Customer: {
        data: transactionOption.contacts ? transactionOption.contacts : [],
        field: {
          label: "display_name",
          value: "contact_id",
        },
      },
    };
    return advanceFilterOptionsConverter(columnFields);
  }, [transactionOption]);

  const handleCloseShareDialog = () => {
    dispatch(
      toggleDialogBox({
        share: {
          data: null,
          isOpen: false,
        },
      })
    );
  };

  const handleOpenDrawerEditor = (params) => {
    dispatch(
      toggleDrawerEditor({
        create: {
          isOpen: true,
          data: params,
        },
      })
    );
  };

  const handleCloseDrawerEditor = () => {
    dispatch(
      toggleDrawerEditor({
        create: {
          isOpen: false,
          data: null,
        },
      })
    );
  };

  //* to handle open dialog
  const handleToggleOpenDialog = (params, isVoid = false) => {
    const dialogParams = {
      data: params,
      isOpen: true,
    };
    // console.log(toggleDialogBox)
    // dispatch(
    //   toggleDialogBox(
    //     isVoid
    //       ? {
    //           update: dialogParams,
    //         }
    //       : {
    //           delete: dialogParams,
    //         }
    //   )
    // );
  };

  //* to handle close dialog
  const handleToggleCloseDialog = (isVoid = false) => {
    const dialogParams = {
      data: null,
      isOpen: false,
    };
    dispatch(
      toggleDialogBox(
        isVoid
          ? {
              update: dialogParams,
            }
          : {
              delete: dialogParams,
            }
      )
    );
  };
  //* to handle delete contact
  const handleDeleteTransaction = () => {
    dispatch(removeTransaction(dialogBox.delete.data.transaction_id));
  };

  //* to handle void contact
  const handleVoidTransaction = () => {
    const voidReason = watch("void_reason");
    const status = dialogBox.update.data.status !== "VOID" ? "VOID" : "READY";
    dispatch(
      voidTransaction({
        transaction_id: dialogBox.update.data.transaction_id,
        status: status,
        void_reason: voidReason,
      })
    );
  };
  const isInvoiceBills = subCategory == "invoices" || subCategory == "bills";
  const isCreditNote = subCategory == "credit-notes";
  const isPayment = subCategory == "payments";
  const isPaymentRefund = subCategory == "refunds" || isPayment;

  const subTotalColumnsObject = useMemo(() => {
    const overpaymentColumn = isPayment ? { Overpayment: "overpayment" } : {};
    const balanceColumn =
      isCreditNote || isInvoiceBills ? { Balance: "balance" } : {};

    return {
      "Sub Total": "amount",
      ...overpaymentColumn,
      ...balanceColumn,
    };
  }, [subCategory]);

  const onQuickFilter = (value) => {
    setFilter((prev) => {
      return {
        ...prev,
        search: value,
      };
    });
  };
  const onAdvanceFilter = (data, onClose) => {
    const objectEntries = Object.entries(data);
    const filteredValues = objectEntries.reduce((result, [group, option]) => {
      if (option) {
        const values = Object.keys(option).filter((key) => option[key]);

        const groupField =
          group == "Customer" ? "customer_id" : tablePropertiesConverter(group);
        // return
        // const listOfFilter = values.map((v) => {

        // });
        return [
          ...result,
          {
            id: `advanced_search_${groupField}`,
            field: groupField,
            operator: "in",
            value: values.length > 0 ? values : [],
          },
        ];
      }
      return result;
    }, []);

    setFilter((prev) => {
      return {
        ...prev,
        advance: filteredValues,
        advanceSelected: data,
      };
    });
    onClose();
  };

  const voidInput = () => {
    return (
      <FormInputText control={control} type={"string"} name={"void_reason"} />
    );
  };

  function onCustomFieldFilterFunction(v) {
    setFilter((prev) => {
      return {
        ...prev,
        customField: v,
      };
    });
  }
  function onStartDateFilterFunctions(v) {
    setFilter((prev) => {
      return {
        ...prev,
        startDate: v,
      };
    });
  }

  function onEndDateFilterFunctions(v) {
    setFilter((prev) => {
      return {
        ...prev,
        endDate: v,
      };
    });
  }

  const firstTabIndex = () => {
    if (isInvoiceBills) {
      return [
        {
          id: "filter_outstanding",
          field: "balance",
          operator: ">",
          value: 0,
        },
      ];
    }
    if (isCreditNote || isPaymentRefund) {
      return [
        {
          id: "filter_applied",
          field: "payment_status",
          operator: "equals",
          value: "Applied",
        },
      ];
    }
    return [];
  };

  const secondTabIndex = () => {
    if (isCreditNote || isPaymentRefund) {
      return [
        {
          id: "filter_outstanding",
          field: "payment_status",
          operator: "equals",
          value: "Outstanding",
        },
      ];
    }
    if (isInvoiceBills) {
      return [
        {
          id: "filter_payment_status",
          field: "payment_status",
          operator: "equals",
          value: paymentStatusTabEnum[currentTabIndex.toString()],
        },
      ];
    }
    return [];
  };

  const updateUrl = (newParams) => {
    // const searchParams = new URLSearchParams(location.search);

    // Update the search params with new values
    Object.keys(newParams).forEach(key => {
      if (newParams[key] !== null && newParams[key] !== undefined) {
        searchParams.set(key, newParams[key]);
      } else {
        searchParams.delete(key); // Remove the param if null or undefined
      }
    });

    setSearchParams(searchParams);

    const [hashPath, queryString] = location.hash.split('?');
    const formattedPathname = hashPath.slice(1); // Remove the '#' to get the pathname
    const formattedSearch = `?${queryString}`; 
    // Navigate to the new URL
    navigate({
      pathname: formattedPathname, // "/Invoicing"
      search: formattedSearch, //?sub_category=quota
    });
  };

  const onClickFunctions = {
    [TableActionEnum.VIEW]: {
      function: (params) => {
        updateUrl({action:"view",transaction_id:params.id});
        // navigate(
        //   `/${location}/${subLocation}/view?transaction_id=${params.id}`
        // );
      },
    },
    [TableActionEnum.DELETE]: {
      function: (params) => {
        handleToggleOpenDialog({ ...params.row });
      },
      disabled: () => false,
      disabledTooltip: "",
    },
    [TableActionEnum.VOID]: {
      function: (params) => {
        handleToggleOpenDialog({ ...params.row }, true);
      },
      disabled: () => false,
      disabledTooltip: "",
    },
    [TableActionEnum.DUPLICATE]: {
      function: (params) => {
        handleOnNavigate(params.id);
      },
      disabled: () => false,
      disabledTooltip: "",
    },
    [TableActionEnum.SHARE]: {
      function: (params) => {
        handleOpenShareDialog({ ...params.row });
      },
      disabled: () => false,
      disabledTooltip: "",
    },

    [TableActionEnum.SEND]: {
      function: (params) => {
        handleOpenDrawerEditor({ ...params.row });
      },
      disabled: () => false,
      disabledTooltip: "",
    },
  };

  const { form_designs = [] } = transactionOption || {};

  const formDesignByType = (transactionOption = {}) => {
    const { form_design_setting = [] } = transactionOption;
    const formDesignSetting = form_design_setting[0]
      ? form_design_setting[0]
      : null;

    const settingBasedOnType = formDesignSetting
      ? Object.values(formDesignSetting)[0]
      : null;

    return settingBasedOnType;
  };

  const handleOpenShareDialog = (params) => {
    // const formDesignSetting = form_design_setting[0]
    //   ? form_design_setting[0]
    //   : null;
    // const settingBasedOnType = formDesignSetting
    //   ? Object.values(formDesignSetting)[0]
    //   : null;

    dispatch(
      openShareTransactionDialog({
        share: {
          data: params.transaction_id,
          isOpen: true,
        },

        resetForm: () => {
          // setValue("form_design", formDesignByType(transactionOption));
          setTimeout(() => {
            reset({
              ...dialogFormDefaultData,
              form_design: formDesignByType(transactionOption),
            });
          }, 500);
        },
      })
    );

  };

  const formDesignsOptions = form_designs.map((form) => {
    return {
      label: form.name,
      value: form.form_design_id,
    };
  });

  return (
    <Fragment>
      {/* {tableTab ? (
        <TableTab
          tabList={tableTab}
          currentTab={currentTabIndex}
          onChangeTab={setCurrentTabIndex}
        />
      ) : null} */}
      {(subCategory !== "" && (currSa || currRoles[0]?.Invoice_Create)) ?
        <Stack
          direction="row"
          sx={{
            display: "flex",
            justifyContent: "end",
            gap: "10px",
          }}
        >
          <Button
            variant="contained"
            className="btn-primary"
            onClick={() => handleOnNavigate()}
            //   disabled={loginLoading}
            endIcon={<AddRounded />}
          >
            {"New"}
          </Button>
        </Stack>
        :null
      }

      {subCategory !== "" ? 
        <TableTools
          onQuickFilter={onQuickFilter}
          quickFilterColumns={["test", "test2"]}
          onStartDateFilter={onStartDateFilterFunctions}
          startDate={filter.startDate}
          onEndDateFilter={onEndDateFilterFunctions}
          endDate={filter.endDate}
          onAdvanceFilter={onAdvanceFilter}
          advanceFilterForm={filter.advanceSelected}
          advanceFilterOptions={[
            ...advanceFilterOptions,
            ...TransactionEnum.STATUS,
          ]}
          onCustomFieldFilter={onCustomFieldFilterFunction} 
        />
        : null
      }
      {(category !== "" && subCategory !== "" ) && 
        <DataTable
          table={TableContent[TABLE[category.toUpperCase()][TableRouteConverter[subCategory]]]}
          tableData={allTransactions}
          isLoading={
            retrieveTransactionsLoading ||
            removeTransactionLoading ||
            voidTransactionLoading
          }
          totalSumColumns={subTotalColumnsObject}
          columnVisibilityModel={{
            custom_fields: false,
          }}
          filter={{
            items: [
              ...(filter.advance ? filter.advance : []),
              {
                id: `filter_date`,
                field: "date",
                operator: "between",
                value: {
                  startDate: filter.startDate,
                  endDate: filter.endDate,
                },
              },
              ...(currentTabIndex == 1 ? firstTabIndex() : []),
              ...(currentTabIndex == 2 ? secondTabIndex() : []),
              ...(currentTabIndex == 3 || currentTabIndex == 5
                ? [
                    {
                      id: "filter_payment_status",
                      field: "payment_status",
                      operator: "equals",
                      value: paymentStatusTabEnum[currentTabIndex.toString()],
                    },
                  ]
                : []),
              ...(currentTabIndex == 4
                ? [
                    {
                      id: "filter_partially",
                      field: "balance",
                      operator: "<",
                      value: 0,
                    },
                  ]
                : []),
              ...(filter.customField
                ? [
                    {
                      id: "filter_custom_field",
                      field: "custom_fields",
                      operator: "in",
                      value: {
                        filter: filter.customField,
                        availableField: transactionCustomFields,
                      },
                    },
                  ]
                : []),
              ...(filter.byType
                ? Object.keys(filter.byType).map((type) => ({
                    id: `filter_by_${type}_column`,
                    field: type,
                    operator: "equals",
                    value: filter.byType[type] ? filter.byType[type] : "",
                  }))
                : []),
            ],

            quickFilterExcludeHiddenColumns: true,
            logicOperator: GridLogicOperator.And,
            quickFilterValues: [filter.search],
          }}
          onClick={onClickFunctions}
        />
      }
      
      {/* <ConfirmationDialog
        open={dialogBox.delete.isOpen}
        title={`Delete ${transactionTypeName}`}
        content={`Do you really want to delete ${transactionTypeName}`}
        handleClose={() => {
          handleToggleCloseDialog();
        }}
        handleConfirm={handleDeleteTransaction}
        type="delete"
      /> */}

      {/* <ConfirmationDialog
        open={dialogBox.update.isOpen}
        title={`Mark as ${
          dialogBox.update.data !== null &&
          dialogBox.update.data.status === "READY"
            ? "Void"
            : " Ready"
        }`}
        content={`Do you really want to mark the ${transactionTypeName} as ${
          dialogBox.update.data !== null &&
          dialogBox.update.data.status === "READY"
            ? "Void"
            : " Ready"
        }?`}
        stackChildren={
          dialogBox.update.data !== null &&
          dialogBox.update.data.status === "READY"
            ? voidInput()
            : null
        }
        handleClose={() => {
          handleToggleCloseDialog(true);
        }}
        handleConfirm={handleVoidTransaction}
        type="delete"
      /> */}

      <ShareTransactionDialog
        title={`Share Transaction...`}
        handleClose={() => {
          handleCloseShareDialog();
        }}
        formDesignsOptions={formDesignsOptions}
        isPaymentRefund={isPaymentRefund}
        control={control}
        // handleConfirm={handleVoidTransaction}
      />

      <DrawerEditor
        isOpen={drawerEditor.create.isOpen}
        handleClose={handleCloseDrawerEditor}
        title={`Send Email`}
      >
        <SendTransactionForm
          handleClose={handleCloseDrawerEditor}
          sendEmailLoading={sendEmailLoading}
          transaction={drawerEditor.create.data}
          company={"5a8d23e2ead32d6a547bb3800ad43fa918ba3b77de1"}
          user={""}
          options={transactionOption}
          formDesign={formDesignByType(transactionOption)}
        />
      </DrawerEditor>
    </Fragment>
  );
};
