import { createSlice, current } from "@reduxjs/toolkit"

import { useAppSelector } from "../../hooks/storeHooks";


const initialState = {
    //loading
    createRoleLoading:false,
    retrieveRolesLoading:false,
    retrieveCurrRolesLoading:false,
    updateRoleLoading:false,
    deleteRoleLoading:false,

    //error
    createRoleError:undefined,
    retrieveRolesError:undefined,
    retrieveCurrRolesError:undefined,
    updateRoleError:undefined,
    deleteRoleError:undefined,

    //data
    roles:[],
    currRoles:[]
}

const rolesSlice = createSlice({
    name:"roles",
    initialState,
    reducers:{
        createRole(state){
            state.createRoleLoading = true
        },

        createRoleSuccess(state, {payload}){
            const currentState = payload
            state.roles = currentState
            state.createRoleLoading = false
        },

        createRoleError(state, {payload}){
            const errorState = payload
            state.createRoleError = errorState
            state.createRoleLoading = false
        },

        retrieveRoles(state){
            state.retrieveRolesLoading = true
        },

        retrieveRolesSuccess(state,{payload}){
            const currentState = payload
            state.roles = currentState
            state.retrieveRolesLoading = false
        },

        retrieveRolesError(state,{payload}){
            const errorState = payload
            state.retrieveRolesError = errorState
            state.retrieveRolesLoading = false
        },

        retrieveCurrRoles(state){
            state.retrieveCurrRolesLoading = true
        },

        retrieveCurrRolesSuccess(state,{payload}){
            const currentState = payload
            state.currRoles = currentState
            state.retrieveCurrRolesLoading = false
        },

        retrieveCurrRolesError(state,{payload}){
            const errorState = payload
            state.retrieveCurrRolesError = errorState
            state.retrieveCurrRolesLoading = false
        },

        updateRole(state){
            state.updateRoleLoading = true
        },

        updateRoleSuccess(state,{payload}){
            const data = payload.data;
            const currentState = current(state.roles)
            const updatedState = currentState.map((item) => {
              if (item.RoleID === data.RoleID) {
                return {
                  ...item,
                  ...data,
                };
              }
              return item;
            });
            state.roles = updatedState
            state.updateRoleLoading = false
        },

        updateRoleError(state,{payload}){
            const errorState = payload
            state.updateRoleError = errorState
            state.updateRoleLoading = false
        },

        deleteRole(state){
            state.deleteRoleLoading = true
        },

        deleteRoleSuccess(state,{payload}){
            const data = payload.data;
            const currentState = current(state.roles);
            const updatedState = currentState.filter((item) => item.RoleID !== data.RoleID)
            state.roles = updatedState
            state.deleteRoleLoading = false
        },

        deleteRoleError(state,{payload}){
            const errorState = payload
            state.deleteRoleError = errorState
            state.deleteRoleLoading = false
        },

    }
})

export const {
    createRole,
    createRoleSuccess,
    createRoleError,
    retrieveRoles,
    retrieveRolesSuccess,
    retrieveRolesError,
    retrieveCurrRoles,
    retrieveCurrRolesSuccess,
    retrieveCurrRolesError,
    updateRole,
    updateRoleSuccess,
    updateRoleError,
    deleteRole,
    deleteRoleSuccess,
    deleteRoleError,
} = rolesSlice.actions

//export selector

// data
export const selectRoles = () =>
  useAppSelector((state) => state.roles.roles);

export const selectCurrRoles = () =>
  useAppSelector((state) => state.roles.currRoles);

// loading
export const selectCreateRoleLoading = () =>
  useAppSelector((state) => state.roles.createRoleLoading);

export const selectRetrieveRolesLoading = () =>
  useAppSelector((state) => state.roles.retrieveRolesLoading);

export const selectRetrieveCurrRolesLoading = () =>
  useAppSelector((state) => state.roles.retrieveCurrRolesLoading);

export const selectUpdateRoleLoading = () =>
  useAppSelector((state) => state.roles.updateRoleLoading);

export const selectDeleteRoleLoading = () =>
  useAppSelector((state) => state.roles.deleteRoleLoading);

// error
export const selectCreateRoleError = () =>
  useAppSelector((state) => state.roles.createRoleError);

export const selectRetrieveRolesError = () =>
  useAppSelector((state) => state.roles.retrieveRolesError);

export const selectRetrieveCurrRolesError = () =>
  useAppSelector((state) => state.roles.retrieveCurrRolesError);

export const selectUpdateRoleError = () =>
  useAppSelector((state) => state.roles.updateRoleError);

export const selectDeleteRoleError = () =>
  useAppSelector((state) => state.roles.deleteRoleError);

const rolesReducer = rolesSlice.reducer

export default rolesReducer
