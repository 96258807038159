import React from "react";
import { Dialog, Stack, Button, Typography, IconButton } from "@mui/material";


import { useWatch } from "react-hook-form";

import { ContentCopyRounded, VisibilityRounded } from "@mui/icons-material";
import { convertSxToArray } from "../../util/mui/convertSxToArray";
import { FormInputDropdown } from "../../components/FormInput/FormInputDropdown";
import { selectDialogBox } from "../../stores/slices/toggleSlice";
import useSnack from "../../hooks/useSnack";
import { SnackType } from "../../enums/Snacks";
import forms from "../../styles/theme/forms";
import { useNavigate } from "react-router-dom";
export const ShareTransactionDialog = ({
  title,
  handleClose,
  maxWidth = "xs",
  childrenSx,
  formDesignsOptions,
  isPaymentRefund,
  control,
}) => {
  const formInputWidthSingle = { ...forms.oneCol };
  const dialogBox = selectDialogBox();
  const { callSnack } = useSnack();

  //   const { control, watch, reset, setValue } = useForm({
  //     defaultValues: dialogFormDefaultData,
  //     shouldUnregister: false,
  //   });

  const formDesignWatcher = useWatch({ control, name: "form_design" });
  const formDesignParams = formDesignWatcher
    ? `&form_design_id=${formDesignWatcher}`
    : "";

  return (
    <Dialog
      open={dialogBox.share.isOpen}
      onClose={handleClose}
      scroll="paper"
      maxWidth={maxWidth}
      fullWidth
      PaperProps={{
        sx: { padding: 2, gap: 2 },
      }}
    >
      <Stack direction="row" spacing={1.5} alignItems="center">
        <Typography
          variant="displayH3"
          sx={({ palette }) => ({
            color: palette.text.main,
          })}
        >
          {title}
        </Typography>
      </Stack>
      <Stack sx={[...convertSxToArray(childrenSx)]}>
        <Stack sx={{ gap: "15px" }}>
          <FormInputDropdown
            control={control}
            options={formDesignsOptions}
            name="form_design"
            label="Form Design"
            minWidth={formInputWidthSingle}
            maxWidth={formInputWidthSingle}
          />
          <Stack direction="row">
            <Typography
              sx={{
                width: "100%",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                alignContent: "center",
                padding: "10px",
                border: "1px solid lightgrey",
                borderRadius: "10px  0px 0px 10px",
              }}
              variant="bodyText"
            >{`myacc.cc/${isPaymentRefund ? "sp" : "st"}/${
              dialogBox.share.data
            }${formDesignParams}`}</Typography>
            <IconButton
              sx={{
                border: "1px solid lightgrey",
                borderRadius: "0px  10px 10px 0px",
              }}
              onClick={async () => {
                try {
                  await navigator.clipboard.writeText(
                    `myacc.cc/${isPaymentRefund ? "sp" : "st"}/${
                      dialogBox.share.data
                    }${formDesignParams}`
                  );
                  callSnack({
                    type: SnackType.Success,
                    message: "Copied to clipboard",
                    timeout: 3000,
                  });
                } catch (err) {
                  callSnack({
                    type: SnackType.Error,
                    message: "Failed to copy",
                    timeout: 3000,
                  });
                }
              }}
            >
              <ContentCopyRounded
                sx={({ icons }) => ({
                  ...icons.standard,
                })}
              />
            </IconButton>
          </Stack>

          <Stack direction="row">
            <Button
              variant="contained"
              className="btn-primary"
              onClick={() => {
                window.open(
                  `https://myacc.cc/${isPaymentRefund ? "sp" : "st"}/${
                    dialogBox.share.data
                  }${formDesignParams}`,
                  "_blank"
                );
              }}
              //   disabled={loginLoading}
              startIcon={
                <VisibilityRounded
                  sx={({ icons }) => ({ ...icons.standard })}
                />
              }
            >
              {"View"}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Dialog>
  );
};
