import { Route } from "react-router-dom"
import { crmRoutes } from "../enums/Route"
import ProtectedRoute from "./ProtectedRoute"
import { Fragment } from "react"
import FormTemplate from "../containers/form_template/FormTemplate"

export const FormTemplateRoute = ({currRolesState, currSaState}) => (
    <Fragment>
        <Route 
            path={crmRoutes.form.formTemplate}
            element={
                <ProtectedRoute>
                    <FormTemplate 
                        isSuperAdmin={currSaState} 
                        Forms_Manage={currRolesState !== undefined ? currRolesState?.Forms_Manage: false} 
                        Forms_Delete={currRolesState !== undefined ? currRolesState?.Forms_Delete : false} 
                        Forms_Approval={currRolesState !== undefined ? currRolesState?.Forms_Approval : false}
                    />
                </ProtectedRoute>
            }
        />
    </Fragment> 
)

