import React from 'react'
import { MdOutlineTipsAndUpdates } from 'react-icons/md'
import { TiTick, TiTimes } from 'react-icons/ti';
import { useNavigate } from 'react-router-dom'

function ImportPrivilege() {

    const navigate = useNavigate();

  return (
    <div className='content'>

        <span>Admin and User have different privilege even in importing data with certain table components. Therefore, we provide a summarized table of importable components and not importable components for your reference.</span>

        <div className='tableInfo'>
            <table>
                <tr>
                    <th>Components & Handler</th>
                    <th>Admin</th>
                    <th>User</th>
                </tr>
                <tr>
                    <td>Short Text</td>
                    <td><TiTick className="icon" size={20}/></td>
                    <td><TiTick className="icon" size={20}/></td>
                </tr>
                <tr>
                    <td>Multiline</td>
                    <td><TiTick className="icon" size={20}/></td>
                    <td><TiTick className="icon" size={20}/></td>
                </tr>
                <tr>
                    <td>Number</td>
                    <td><TiTick className="icon" size={20}/></td>
                    <td><TiTick className="icon" size={20}/></td>
                </tr>
                <tr>
                    <td>Date</td>
                    <td><TiTick className="icon" size={20}/></td>
                    <td><TiTick className="icon" size={20}/></td>
                </tr>
                <tr>
                    <td>Email</td>
                    <td><TiTick className="icon" size={20}/></td>
                    <td><TiTick className="icon" size={20}/></td>
                </tr>
                <tr>
                    <td>Phone</td>
                    <td><TiTick className="icon" size={20}/></td>
                    <td><TiTick className="icon" size={20}/></td>
                </tr>
                <tr>
                    <td>Status</td>
                    <td><TiTick className="icon" size={20}/></td>
                    <td><TiTick className="icon" size={20}/></td>
                </tr>
                <tr>
                    <td>Rich Text Editor</td>
                    <td><TiTick className="icon" size={20}/></td>
                    <td><TiTick className="icon" size={20}/></td>
                </tr>
                <tr>
                    <td>Link</td>
                    <td><TiTick className="icon" size={20}/></td>
                    <td><TiTick className="icon" size={20}/></td>
                </tr>
                <tr>
                    <td>Google Map</td>
                    <td><TiTick className="icon" size={20}/></td>
                    <td><TiTick className="icon" size={20}/></td>
                </tr>
                <tr>
                    <td>Multiple Choice</td>
                    <td><TiTick className="icon" size={20}/></td>
                    <td><TiTick className="icon" size={20}/></td>
                </tr>
                <tr>
                    <td>Checkbox</td>
                    <td><TiTick className="icon" size={20}/></td>
                    <td><TiTick className="icon" size={20}/></td>
                </tr>
                <tr>
                    <td>Dropdown</td>
                    <td><TiTick className="icon" size={20}/></td>
                    <td><TiTick className="icon" size={20}/></td>
                </tr>
                <tr>
                    <td>Member Dropdown</td>
                    <td><TiTick className="icon" size={20}/></td>
                    <td><TiTimes className="red-icon" size={20}/></td>
                </tr>
                <tr>
                    <td>Form</td>
                    <td><TiTimes className="red-icon" size={20}/></td>
                    <td><TiTimes className="red-icon" size={20}/></td>
                </tr>
                <tr>
                    <td>Handler</td>
                    <td><TiTick className="icon" size={20}/></td>
                    <td><TiTimes className="red-icon" size={20}/></td>
                </tr>
            </table>
        </div>

    </div>
  )
}

export default ImportPrivilege