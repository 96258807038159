import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { convertSxToArray } from "../../../util/mui/convertSxToArray";
import { SecondaryHeader } from "./Header/SecondaryHeader";

export const MainContentContainer = ({
  removeSearchParams,
  children,
  customComponent,
  hideSecondaryHeader = false,
  hideBreadCrumbs = false,
  justifyEnd = false,
  actionChildren,
  fullHeight = false,
  component = "main",
  testId = "",
  sx = [],
}) => {
  return (
    <Box
      component={component}
      data-testid={`${testId}MainContentContainer`}
      sx={[
        ({ sizing, spacing }) => ({
          width: 1,
          maxWidth: 1920,
          // marginTop: `${sizing.headerHeight}px`,
          height: fullHeight
            ? `calc(100vh - ${sizing.headerHeight}px)`
            : "initial",
          // padding: spacing(4, 6),
          padding: testId === "new_contact" ? "0px" : spacing(1, 1),
          overflowY: "auto",
        }),
        ...convertSxToArray(sx),
      ]}
      testId={testId}
    >
      {!hideSecondaryHeader ? (
        <SecondaryHeader
          removeSearchParams={removeSearchParams}
          hideBreadCrumbs={hideBreadCrumbs}
          customComponent={customComponent}
          justifyEnd={true}
          actionChildren={actionChildren}
        />
      ) : null}
      {children}
    </Box>
  );
};

MainContentContainer.propTypes = {
  children: PropTypes.any,
  customComponent: PropTypes.node,
  hideSecondaryHeader: PropTypes.bool,
  hideBreadCrumbs: PropTypes.bool,
  justifyEnd: PropTypes.bool,
  actionChildren: PropTypes.any,
  fullHeight: PropTypes.any,
  component: PropTypes.any,
  testId: PropTypes.any,
  sx: PropTypes.object,
  scrollable: PropTypes.bool,
};
