import React, { useState, useEffect, useRef} from "react";
import "./App.css";
import {
  Routes,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Notifications from "./components/Notifications/Notifications";
import ReactNotificationComponent from "./components/Notifications/ReactNotification";
import moment from 'moment';
import 'moment-timezone';
import './_UserGuide/_Holy_Styles/guide.scss'
import { getFromLocalStorage, removeFromLocalStorage } from "./util/LocalStorage";
import { useDispatch } from "react-redux";

import { useIdleTimer } from "react-idle-timer";
import LoginExpirationModal from "./containers/LoginExpirationModal";

//slices
import { refreshUserAuth, selectFirebaseAuth } from "./stores/slices/firebaseAuthSlices";
import { selectCurrGa, selectCurrSa, setIsGA, setIsSA } from "./stores/slices/persistRolesSlices";
import { selectCurrRoles } from "./stores/slices/roleSlices";
import { selectCurrUserContext, setCompanyDataAndUsers, setCompanyPlanRequestData, setCompanyUsage, setCompanyUserInvitation } from "./stores/slices/companySlices";
import { handleSelectTenant } from "./stores/slices/tenantSlices";

//routes
import { TenantRoute } from "./routes/TenantRoute";
import { GaPortalRoute } from "./routes/GaPortalRoute";
import { AuthRoute } from "./routes/AuthRoute";
import { GaTenantUpgradeRoute } from "./routes/GaTenantUpgrade";
import { GaTenantQuotaRoute } from "./routes/GaTenantQuotaRoute";
import { RegisterRoute } from "./routes/RegisterRoute";
import { EmailInvitationRoute } from "./routes/EmailInvitationRoute";
import { AcceptInvitationRoute } from "./routes/AcceptInvitationRoute";
import { ExpiredInvitationRoute } from "./routes/ExpiredInvitationRoute";
import { EmailVerificationRoute } from "./routes/EmailVerificationRoute";
import { CompanySettingRoute } from "./routes/CompanySettingRoute";
import { CompanyRoute } from "./routes/CompanyRoute";
import { CompanyPreloadRoute } from "./routes/CompanyPreloadRoute";
import { AnalyticsRoute } from "./routes/AnalyticsRoute";
import { AnalyticsGraphGeneratorRoute } from "./routes/AnalyticsGraphGeneratorRoute";
import { AnalyticsWorkspaceRoute } from "./routes/AnalyticsWorkspaceRoute";
import { DashboardRoute } from "./routes/DashboardRoute";
import { ActivityLogsRoute } from "./routes/ActivityLogsRoute";
import { ClientsRoute } from "./routes/ClientsRoute";
import { ClientGroupRoute } from "./routes/ClientGroupRoute";
import { KpiRoute } from "./routes/KpiRoute";
import { AppointmentRoute } from "./routes/AppointmentRoute";
import { FormListRoute } from "./routes/FormListRoute";
import { FormSubmissionRoute } from "./routes/FormSubmissionRoute";
import { FormTemplateRoute } from "./routes/FormTemplateRoute";
import { FormsSettingsRoute } from "./routes/FormsSettingsRoute";
import { FormSearchRoute } from "./routes/FormSearchRoute";
import { PageNotFoundRoute } from "./routes/PageNotFoundRoute";
import { GuideClientRoute } from "./routes/GuideClientRoute";
import { setUser } from "./stores/slices/userAuthSlices";
import { getInitialClientData } from "./stores/slices/clientSlices";
import { InvoicingRoute } from "./routes/InvoicingRoute";
import { Snacks } from "./components/_Global/GlobalSnack";

function App() {
  moment.tz.setDefault('Asia/Kuala_Lumpur');
  const currentTime = moment().format('MMMM Do YYYY, h:mm:ss a');

  const loginExpiration = getFromLocalStorage("LOGIN_EXPIRATION");
  const refreshToken = getFromLocalStorage("REFRESH_TOKEN");
  const isSA = getFromLocalStorage("IS_SA");
  const tenant = getFromLocalStorage("CURR_TENANT");
  const roles = getFromLocalStorage("CURR_ROLE");
  const company = getFromLocalStorage("CURR_COMPANY");

  const accessTokenExpiry = getFromLocalStorage("ACCESS_TOKEN_EXPIRY");
  const dispatch = useDispatch();
  
  const [currSaState, setCurrSaState] = useState(false);
  const [currGaState, setCurrGaState] = useState(false);
  const [currRolesState, setCurrRolesState] = useState(null);
  const navigate = useNavigate();

  const currGa = selectCurrGa();
  const currSa = selectCurrSa();
  const currRoles = selectCurrRoles();
  const {user} = selectFirebaseAuth();
  const { currUserSA, currUserInvitation } = selectCurrUserContext();
  const firebaseAuth = selectFirebaseAuth();
  const location = useLocation();
  const pathname = location.pathname.split('/')[1]; 

  const [idleState, setIdleState] = useState("Active");

  const onIdle = () => {
    setIdleState("Idle");
  };

  const onActive = () => {
    setIdleState("Active");
  };

  useIdleTimer({
    onIdle,
    onActive,
    timeout: 10000,
    throttle: 500,
    events: [
      "mousemove",
      "keydown",
      "wheel",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "MSPointerDown",
      "MSPointerMove",
      "visibilitychange",
    ],
  });

  //refresh user authentication
  useEffect(()=>{
    dispatch(refreshUserAuth({refreshToken}));
  }, [])

  //setcompanyDataAndUsers and will return currUserSA and currUserInvitation
  useEffect(()=>{
    if(company && user && tenant){
      dispatch(setCompanyDataAndUsers({
        TenantID : tenant, 
        isSA: isSA, 
        UserID : user?.uid,
        RoleID : roles,
        CompanyID : company
      }));
      dispatch(setCompanyUserInvitation({CompanyID : company, TenantID : tenant}));
    }
  }, [user, tenant, company])


  //refresh usage after currUserSA and currUserInvitation is set
  useEffect(()=>{
    if(tenant && company){
      dispatch(setCompanyUsage({
          currUserSA : currUserSA, 
          currUserInvitation : currUserInvitation,
          TenantID : tenant,
          CompanyID : company,
      }))
      dispatch(getInitialClientData({
          TenantID : tenant,
          UserID : user?.uid,
          CompanyID : company,
          isSA : currSa,
          user : user
      }))
    }
    
  },[currUserInvitation, currUserSA])

  //refresh the token
  useEffect(()=>{
    if(pathname !== "Register" && pathname !== "AcceptInvitation" && pathname !=="ExpiredInvitation"){
        if(refreshToken){
            dispatch(refreshUserAuth({refreshToken}));
        } else {
            console.log("empty refresh token");
            removeFromLocalStorage("LOGIN_EXPIRATION");
            navigate('/Auth');
        }
    }
  }, [refreshToken])

  //set user data
  useEffect(()=>{
    dispatch(setUser({firebaseAuth, tenant, roles, company}))
  },[firebaseAuth])

  //setCompanyData
  useEffect(()=>{
    if(user){
      dispatch(setCompanyPlanRequestData({UserID : user?.uid}));
      if(tenant){
        dispatch(handleSelectTenant({TenantID : tenant, isSA : isSA === "true", UserID : user?.uid, navigation:false }));
      }
      
    }
  },[user, tenant])

  //checktokenexpiry
  useEffect(() => {
    if (accessTokenExpiry && loginExpiration !== "EXPIRED") {
      const countDuration = idleState !== "Idle" ? 1000 * 5 : 1000 * 60;
      const interval = setInterval(() => {
        const currentDate = new Date();
        const accessTokenExpireDate = new Date(accessTokenExpiry);
        if (currentDate > accessTokenExpireDate) {
          console.log("Token Expired, Getting a new one...");
          clearInterval(interval); // Stop the interval
          dispatch(refreshUserAuth({refreshToken}))
        }
      }, countDuration);

      return () => clearInterval(interval);
    }
  }, [idleState, accessTokenExpiry]);

  //set GA state
  useEffect(() => {
    const isGA = currGa === true;
    dispatch(setIsGA(isGA));
    setCurrGaState(isGA);
  },[currGa])
  
  //set SA state
  useEffect(() => {
    const isSA = currSa === true;
    dispatch(setIsSA(isSA));
    setCurrSaState(isSA);
  },[currSa])

  //set Role state
  useEffect(() => {
    if(currRoles){
      setCurrRolesState(currRoles[0] === undefined ? null : currRoles[0])
    }
  },[currRoles]);

  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({
    title: "",
    body: "",
    profileImg: "",
    imgContent: "",
  });

  document.addEventListener("keydown", function (event) {
    if (event.ctrlKey && event.key === "0") {
      // alert("BOOM!");
    }
  });


  return (
    <div className="super-container" style={{ position: 'relative', overflow: 'hidden'}}>
          <LoginExpirationModal/>
              <ReactNotificationComponent
                title={notification.title}
                body={notification.body}
                profileImg={notification.profileImg}
                imgContent={notification.imgContent}
                show={show}
              />
              <Notifications />
              <Snacks/>
              <Routes>
                {/* Tenant */}
                {TenantRoute()}
                {CompanyRoute()}
                {CompanyPreloadRoute()}

                {/* Other */}
                {AuthRoute()}
                {RegisterRoute()}
                {EmailInvitationRoute()}
                {AcceptInvitationRoute()}
                {ExpiredInvitationRoute()}
                {EmailVerificationRoute()}
                {PageNotFoundRoute()}

                {/* GA */}
                {GaPortalRoute({currGaState})}
                {GaTenantQuotaRoute({currGaState})}
                {GaTenantUpgradeRoute({currGaState})}

                {/* Within Company */}
                {CompanySettingRoute({currSaState})}
                {AnalyticsRoute({currRolesState, currSaState})}
                {AnalyticsGraphGeneratorRoute({currRolesState, currSaState})}
                {AnalyticsWorkspaceRoute({currRolesState, currSaState})}
                {ClientsRoute({currRolesState, currSaState})}
                {ClientGroupRoute({currSaState})}
                {DashboardRoute()}
                {ActivityLogsRoute()}
                {KpiRoute({currRolesState, currSaState})}
                {AppointmentRoute({currRolesState, currSaState})}
                {FormListRoute()}
                {FormSubmissionRoute()}
                {FormTemplateRoute({currRolesState, currSaState})}
                {FormsSettingsRoute()}
                {FormSearchRoute()}
                {InvoicingRoute({currRolesState})}

                {/* Guide */}
                {GuideClientRoute()}
                {/* <Route path="/Guide/Members" element={<MainMembers_Guide/>} />
                <Route path="/Guide/Analytics" element={<MainAnalytics_Guide/>} /> 
                <Route path="/Guide/Clients" element={<MainClients_Guide/>} />
                <Route path="/Guide/Appointments" element={<MainAppointments_Guide/>} />
                <Route path="/Guide/Submission" element={<MainFormApplication_Guide/>} />
                <Route path="/Guide/Forms" element={<MainForms_Guide/>} /> */}
            </Routes>
    </div>
  );
}

export default App;
