//react
import React, { useEffect, useState } from 'react';
import { CodeBlock, CopyBlock, dracula } from 'react-code-blocks';
import './ClientGroup.scss'
import { TbAlertCircle, TbCircleCheck, TbCopy } from "react-icons/tb"
import { apiUrl } from '../../constants/env';

function ClientGroupIntegration({ groupID, companyID, tenantID }) {
  const [cf7CodeSnippet, setCf7CodeSnippet] = useState("");
  const [textCopiedCf7, setTextCopiedCf7] = useState(false);

  //Get data for code generator 
  // * TenantID, CompanyID, GroupID

  useEffect(() => {
    setCf7CodeSnippet(`
<script type="text/javascript">
  document.addEventListener("DOMContentLoaded", (event) => {
    document.addEventListener('wpcf7submit', function(event) {
      const data = event.detail.inputs;
      const apiUrl = '${apiUrl}/submitForm';

      const requestOptions = {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json',
        'tenant': '${tenantID}',
        'company': '${companyID}',
        'group': '${groupID}'
        },
        body: JSON.stringify(data),
      };

      fetch(apiUrl, requestOptions)
        .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return console.log(response);
        })
        .catch(error => {
        console.error

      ('Error:', error);
        });
    },false);
  });
</script>`);
  },[groupID, companyID, tenantID]);

  const renderCodeBlock = (codeblock) => {
    return(
      <div className='integration-code-container'>
        <CodeBlock
          text={codeblock}
          language={"js"}
          theme={dracula}
          showLineNumbers
          wrapLines
        />
      </div>
    )
  }

  function copyCode({ code, type }) {
    navigator.clipboard.writeText(code);
    switch(type){
      case "cf7":
        setTextCopiedCf7(true);
        setTimeout(() => {
          setTextCopiedCf7(false);
        },[2000])
        break;
      default: 
        break;
    }

  }

  return (
    <div className='client-group-integration-main-container'>
      <div className='integration-section-container'>
        <div className='integration-title-container'>
          <span>Start automate client data from your website</span>
        </div>
        <div className='integration-content-container'>
          <span>We provide API that allows our client to integrate their Wordpress form submissions to our client list. This helps reduce extra data entry work. Use below plugin for Wordpress and follow our guidelines below for integration.</span>
          <ul>
            <li>Contact Form 7 <small>(version 5.8.7 and above)</small></li>
            {/* <li>Custom Development</li> */}
          </ul>
        </div>
        <div className='divider'/>
        {/* <div className='integration-tips-container'>

        </div> */}
      </div>
      <div className='integration-section-container step'>
        <div className='integration-topic-container'>
          <span>Contact Form 7 Integration</span>
        </div>
        <div className='integration-stepper-container'>
          <span><span className='stepper'>Step 1: </span>Copy <strong>code</strong> below:</span>
          <div className='copy-btn' onClick={() => { copyCode({ code: cf7CodeSnippet, type: "cf7" }) }}>
            <span>{textCopiedCf7 ? "Copied" : "Copy Code"}</span>
            <TbCopy size={20}/>
          </div>
        </div>
        <div className='integration-code-container'>
          {renderCodeBlock(cf7CodeSnippet)}
        </div>
        <div className='integration-tips-container'>
          <TbAlertCircle className="icon" size={24}/>
          <span>Regenerate the code if you have any changes with your table column!</span>
        </div>
      </div>
      <div className='integration-section-container step'>
        <div className='divider'/>
        <div className='integration-stepper-container'>
          <span><span className='stepper'>Step 2: </span>In Wordpress, install plugin <strong>WPCode</strong></span>
        </div>
        <div className='integration-image-container'>
          <img src={require('./IntegrationAssets/cf7-1.png')} alt=''/>
        </div>
      </div>
      <div className='integration-section-container step'>
        <div className='divider'/>
        <div className='integration-stepper-container'>
          <span><span className='stepper'>Step 3: </span>Go to <strong>Header & Footer</strong>, paste your code copy from above to <strong>Footer</strong> section and click update!</span>
        </div>
        <div className='integration-image-container'>
          <img src={require('./IntegrationAssets/cf7-2.png')} alt=''/>
        </div>
      </div>
      <div className='integration-section-container step'>
        <div className='integration-topic-container'>
          <span>Setting up Contact Form 7</span>
        </div>
        <div className='integration-stepper2-container'>
          <span><span className='stepper'>Step 1: </span>Fields setup</span>
          <span>Fields setting is important to allow our system to receive the input from user.</span>
        </div>
        <div className='integration-section-container step'>
          <div className='integration-stepper-container'>
            <span><strong>First</strong>, check or create columns for your table columns.</span>
          </div>
          <div className='integration-image-container'>
            <img src={require('./IntegrationAssets/cf7-4.png')} alt=''/>
          </div>
        </div>
        <div className='integration-section-container step'>
          <div className='integration-stepper-container'>
            <span><strong>Next</strong>, create field in Contact Form 7 and set the Name value similar with your CRM table column.</span>
          </div>
          <div className='integration-image-container'>
            <img src={require('./IntegrationAssets/cf7-3.png')} alt=''/>
          </div>
        </div>
        <div className='integration-tips-container'>
          <TbAlertCircle className="icon" size={24}/>
          <span>Field name as ClientName is <strong>compulsory</strong>. Missing field with Name of ClientName will not be able to insert the data to your client table in our CRM.</span>
        </div>
        <div style={{ marginTop: "20px" }} className='integration-stepper-container'>
          <span style={{ display: 'flex', alignItems: 'center', gap: "10px" }}><TbCircleCheck color='green' size={30}/><span className='stepper'>Save your changes in both CRM and Wrodpress and you're now all set to go!</span></span>
        </div>
      </div>
      <div className='integration-section-container step'>
        <div className='divider'/>
        <div className='divider'/>
        <div className='integration-topic-container'>
          <span>Notes for integration</span>
        </div>
        <div className='integration-stepper2-container'>
          <span><strong>Please take note</strong> that some of our components are not allowed for form input. Below list are components that were allowed for integration:</span>
          <ul>
            <li>Short Text</li>
            <li>Multiline</li>
            <li>Numbers</li>
            <li>Email</li>
            <li>Phone</li>
            <li>Link</li>
            <li>Google Map</li>
            <li>Multiple Choices</li>
            <li>Checkboxes</li>
            <li>Dropdown</li>
          </ul>
          <span><strong>Each columns and fields should have a unique name as well!</strong></span>
        </div>
      </div>
    </div>
  )
}

export default ClientGroupIntegration