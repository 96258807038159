import { Route } from "react-router-dom"
import { crmRoutes } from "../enums/Route"
import ProtectedRoute from "./ProtectedRoute"
import { Fragment } from "react"
import AcceptInvitation from "../containers/invitation/AcceptInvitation"
import ActivityLogs from "../containers/ActivityLogs"

export const ActivityLogsRoute = () => (
    <Fragment>
        <Route 
            path={crmRoutes.dashboard.activityLogs}
            element={
                <ProtectedRoute>
                    <ActivityLogs />
                </ProtectedRoute>
            }
        />
    </Fragment> 
)

