import React from "react";
import PropTypes from "prop-types";
import { Stack } from "@mui/material";

import { FormInputText, FormInputImage } from "../../../components/FormInput";
import { FormInputContainer } from "../../AccContainers/Global";
import forms from "../../../styles/theme/forms";

export const OtherInformationsForm = ({ control, resetField }) => {
  const formInputWidth = { ...forms.twoCol };

  return (
    <Stack
      component="div"
      rowGap="30px"
      paddingY={3}
      width={0.9}
      paddingLeft={"30px"}
      sx={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-between",
        alignItems: "flex-start",
      }}
    >
      <FormInputContainer
        label="Remarks"
        minWidth={formInputWidth}
        maxWidth={formInputWidth}
        renderInput={
          <FormInputText
            control={control}
            multiline={true}
            type="text"
            helperText="Some notes about your contact"
            name="remarks"
            label="Remarks"
          />
        }
      />
      <FormInputContainer
        label="Attachment"
        minWidth={formInputWidth}
        maxWidth={formInputWidth}
        renderInput={
          <FormInputImage
            control={control}
            resetField={resetField}
            name="file_id"
            label="Drop files here..."
            helperText={
              "* 200px x 200px and higher resolution in JPG/PNG format"
            }
          />
        }
      />
      <Stack
        component="div"
        rowGap="30px"
        paddingY={3}
        width={1}
        sx={{
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <FormInputContainer
          label="Description"
          minWidth={formInputWidth}
          maxWidth={formInputWidth}
          renderInput={
            <FormInputText
              label="Description"
              control={control}
              type="text"
              name="descriptions"
            />
          }
        />
        <FormInputContainer
          label="Internal Note"
          minWidth={formInputWidth}
          maxWidth={formInputWidth}
          renderInput={
            <FormInputText
              label="Internal Note"
              control={control}
              type="text"
              name="internal_note"
            />
          }
        />
      </Stack>
    </Stack>
  );
};

OtherInformationsForm.propTypes = {
  control: PropTypes.any,
  resetField: PropTypes.func,
};
