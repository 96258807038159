import React, { useState, useEffect, useRef } from "react";
import "./Register.scss";
import Colors from "../constants/Colors";
import C_Button from "../components/C_Button";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import "./Login.scss";
import { signInWithEmailAndPassword, getAdditionalUserInfo, browserSessionPersistence, setPersistence, browserLocalPersistence, updateProfile, createUserWithEmailAndPassword, signOut } from "firebase/auth";
import { Modal } from "bootstrap";
import ga from '../enums/json/generalAdmin.json';
import { country_and_states } from "../enums/json/country-states";
import C_DialogButton from "../components/C_DialogButton";
import { BiArrowBack } from "react-icons/bi";
import C_PopoutNoti from "../components/C_PopoutNoti";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import { get, ref, set } from "firebase/database";
import { db, auth } from "../firebaseInit";
import moment from "moment";
import { v4 } from "uuid";
import { BsCheck2Circle } from "react-icons/bs";

//slices
import { setIdleTime, setIsFirstLogin, setIsGA } from "../stores/slices/persistRolesSlices";
import { createTenantUserAuth, selectTenant, sendVerificationMail } from "../stores/slices/tenantSlices";

function Register() {

  const dispatch = useDispatch();

  const [isCreate,setIsCreate]= useState(false);
  const [isLoading,setIsLoading] = useState(false);
  const [emailExist,setEmailExist] = useState(false);

  const navigate = useNavigate();
  const [notifyMessage,setNotifyMessage] = useState("");

  const createdTenant = selectTenant();

  //Form data
  const [email,setEmail] = useState("");
  const [firstName,setFirstName] = useState("");
  const [lastName,setLastName] = useState(""); 
  const [password,setPassword] = useState("");
  const [confirmPassword,setConfirmPassword] = useState("");
  const [company,setCompany] = useState("");
  const [companySize,setCompanySize] = useState("");
  const [country,setCountry] = useState("MY");
  const [state,setState] = useState("");
  const [phoneNum,setPhoneNum] = useState("");
  const [stateArray,setStateArray] = useState([]);
  const [passwordMismatch,setPasswordMismatch] = useState(false);
  const [incorrectPhoneFormat,setIncorrectPhoneFormat] = useState(false);
  const [incorrectEmailFormat,setIncorrectEmailFormat] = useState(false);
  const [incorrectPasswordFormat,setIncorrectPasswordFormat] = useState(false);
  const [viewPassword,setViewPassword] = useState(false);
  const [viewConfirmPassword,setViewConfirmPassword] = useState(false);
  const [searchParams,setSearchParams] = useSearchParams();

  const modalRef = useRef();

  const showModalFunc = () => {
    const modalEle = modalRef.current;
    const bsModal = new Modal(modalEle, {
      backdrop: "static",
      keyboard: false,
    });
    bsModal.show();
  };

  const hideModalFunc = () => {
    const modalEle = modalRef.current;
    const bsModal = Modal.getInstance(modalEle);
    bsModal.hide();
  };

  useEffect(()=>{
    // set(ref(db, 'notification/' + `${HostID}/` + notificationID  )
    const TenantID = searchParams.get("TenantID");
    const CompanyID = searchParams.get("CompanyID");
    const mailID = searchParams.get("MailID");
    if(Array.from(searchParams).length > 0) {
      get(ref(db, 'invitationMail/' + `${TenantID}/` + `${CompanyID}/` + mailID )).then((snapshot) => {
        if (snapshot.exists()) {
          setEmail(searchParams.get("Email"))
        } else {
          showModalFunc();  
        }
      }).catch((error) => {
        console.error(error);
      });
    }
    
  },[searchParams])

  useEffect(()=>{
    console.log(createdTenant)
    if(createdTenant && Object.values(createdTenant).length > 0){
      // Signed in 
      const user = createdTenant;
      const displayName = firstName + " " + lastName;
      const code = Math.floor(100000 + Math.random() * 900000);
      const createdAt = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
         createFirebaseTenantUser(displayName, user, createdAt, code).then(async (res)=>{
          dispatch(sendVerificationMail({uid: user?.uid, email: email, name: displayName, code: code}))
          
          await setPersistence(auth, browserSessionPersistence)
          .then(async () => {

            return signInWithEmailAndPassword(auth, email, password).then( async (res) => {

              const isGa = ga.includes(res.user?.uid);

              dispatch(setIsGA(isGa));
              const additionalInfo = getAdditionalUserInfo(res);
              
              dispatch(setIsFirstLogin(additionalInfo.isNewUser));
              dispatch(setIdleTime(86400));

              setIsLoading(false);

              navigate({ pathname: "/EmailVerification", search: createSearchParams({uid: res.user?.uid, email: res.user.email}).toString()});
              console.log("No data available");

            })
          })
          .catch((err) => {
            // Handle Errors here.
            var error = err?.code?.replace("auth/", "");
            var errorMessage = error?.replace("-", " ");
            // setIsLoading(false);
          });
        });
      // })
    }
 
  }, [createdTenant])

  useEffect(()=>{
    let array = [];

    const states = country_and_states.states
    array = states[country]
    setStateArray(array);

    if(country === "MY") {
      setPhoneNum("+60")
    } else {
      setPhoneNum("+");
    } 

  },[country])

  const handlePhoneChange = (value) => {
    const phoneRegex = /^\+\d{0,14}$/; // matches up to 14 digits
    if (phoneRegex.test(value)) {
      setPhoneNum(value);
    }
  };

  const phoneValidation = (country,phoneNum) => {
    let regexPhone = /^(\+?6?01)[02-46-9]-*[0-9]{7}$|^(\+?6?01)[1]-*[0-9]{8}$/;
    let internationRegexPhone = /^(?:\+)?(?:[0-9]●?){6,14}[0-9]$/;
    
    return country === "MY" ? regexPhone.test(phoneNum) : internationRegexPhone.test(phoneNum);
  }

  const emailValidation = (email) => {
    let regexEmail = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
    // let regexEmailLength = /^[^\s@]{6,}@[^\s@]+\.[^\s@]+$/;

    return regexEmail.test(email);
  }

  const passwordValidation = (password) =>{
    let regexPassword = /^(?=.*[A-Z])(?=.*[!@#$%^&*()-_+=,.?;':"`~])(?=.{8,16})/;

    return regexPassword.test(password);
  }

  const isPasswordMismatch = (password,confirmPassword) => {
    return password !== confirmPassword;
  }

  //realtime database
  const createFirebaseTenantUser = async (displayName, user, createdAt, code) =>{
    console.log(user)
    if(user){
      let invited = false;
      let TenantID;
      let hostEmail;
      let userEmail;
      let MailID;
      let CompanyID;
      let CompanyName;
      let RoleID;
      let invitedData = {};
      let companyID = v4();

      if(Array.from(searchParams).length > 0){
        invited = true;
        TenantID = searchParams.get("TenantID");
        hostEmail = searchParams.get("HostEmail");
        userEmail = searchParams.get("Email");
        MailID = searchParams.get("MailID");
        CompanyID = searchParams.get("CompanyID");
        CompanyName = searchParams.get("CompanyName");
        RoleID = searchParams.get("RoleID");

        invitedData = {TenantID,hostEmail,userEmail,MailID,CompanyID,CompanyName,RoleID};
      }

 
        await set(ref(db, 'unverifiedUsers/' + user?.uid), {
          TenantID:"",
          displayName: displayName,
          firstName:firstName,
          lastName:lastName,
          email: email,
          fcm: "",
          photoURL: "https://firebasestorage.googleapis.com/v0/b/rexsoft-crm.appspot.com/o/default-profile.webp?alt=media&token=65d9adcd-657c-4566-8058-f45f74b13d4a",
          uid: user?.uid,
          isTenant:false,
          country : country,
          state : state,
          phoneNum: phoneNum,
          companyName : company,
          companySize : companySize,
          createdAt:createdAt,
          tenantCreatedAt:"",
          upgradeCreatedAt:"",
          workspaceName:"",
          plan:"",
          isInvited:invited,
          invitedData:invitedData,
          companyID,
          code:code,
        })
      }
      
  }

  //create Authentication profile
  const createFirebaseData = async () => {
    setIsLoading(true);
    setIsCreate(true);
    const displayName = firstName + " " + lastName;
    const createdAt = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
    dispatch(createTenantUserAuth({TenantID:"",email:email, name:displayName, password}))
    //moved to useEffect
  }

  const createUser = () =>{
    setEmailExist(false);
    if(!firstName || !lastName || !email || !password || !country || !state || !phoneNum || !company || !companySize) {
      notify("field-empty")
      return;
    } else {
      if(isPasswordMismatch(password,confirmPassword)) {
        notify("password-mismatch")
        setPasswordMismatch(true);
        return;
      } else {
        setPasswordMismatch(false);
      }

      // if(requestTenant ? (emailValidation(email) && passwordValidation(password) && phoneValidation(country,phoneNum)) : (emailValidation(email) && passwordValidation(password)))
      if(emailValidation(email) && passwordValidation(password) && phoneValidation(country,phoneNum)) {
        createFirebaseData().then(()=>{
          setPasswordMismatch(false);
          setIncorrectEmailFormat(false);
          setIncorrectPasswordFormat(false);
          setIncorrectPhoneFormat(false);
          setTimeout(()=>{
            setIsLoading(false);
          },3000)
          
          // navigate("/Auth");
        })
       
      } 
      else if(!emailValidation(email)) {
        notify("incorrect-email");
        setIncorrectEmailFormat(true);

      } 
      else if(!passwordValidation(password)) {
        notify("incorrect-password");
        setIncorrectPasswordFormat(true);

      } else if(!phoneValidation(country,phoneNum)) {
        notify("incorrect-phone");
        setIncorrectPhoneFormat(true);
      }

    }
    
  }

  function notify(type){
    switch(type) {
      case "create":
        setNotifyMessage("CREATED")
        break;
      case "existed-email":
        setNotifyMessage("Email already in use.")
        break;
      case "password-mismatch":
        setNotifyMessage("Passwords do not match. Try again.")
        break;
      case "incorrect-email":
        setNotifyMessage("Incorrect email format. E.g. johnny@gmail.com.")
        break;
      case "incorrect-password":
        setNotifyMessage("Password must consist of at least 1 capital letter and 1 symbol within the length between 8-16.")
        break;
      case "incorrect-phone":
        setNotifyMessage("Incorrect phone format. E.g 0164334444.")
        break;
      case "field-empty":
        setNotifyMessage("Please make sure all the fields are not empty.")
        break;
      default:
        setNotifyMessage("SAVED")
        break;
    }

    setTimeout(() => {
      setNotifyMessage("");
    }, 4000)
  };

  return (
    <div className="register-main-container">
      <div ref={modalRef} className="modal fade modal" id="activation" tabIndex="-1" style={{}}>
            <div className="modal-dialog modal-dialog-centered" style={{}}>
                <div className="modal-content">
                    <div className="modal-body d-flex flex-column">
                        <div className="d-flex flex-column px-2 py-2" style={{justifyContent:'center',textAlign:'left'}}>
                            
                            {/* <img src={require('../assets/img/partner.png')} alt=""/> */}
                            <p className="font-xl" style={{fontWeight:'600',textAlign:'center'}}>Important: Invitation Expired</p>
                            <p className="font-m" style={{opacity:'0.8'}}>Expired invitation. Please contact the host for more information.</p>
                            <div className="d-flex justify-content-end">
                              <C_Button
                                  backgroundColor={Colors.generalButtonColor}
                                  buttonText={"Continue"}
                                  width={"125px"}
                                  height={"40px"}
                                  justify={"center"}
                                  textColor={"#FFFFFF"}
                                  onClick={()=>{
                                    hideModalFunc();
                                    navigate("/Auth");}}
                              />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

      <div className="register-container-left left-container-width">
        <div className="sub_left_container" style={{ }}/>
          <div className="d-flex" style={{ alignItems: "center" }}>
            <span className="font-s" style={{ color: "#FFFFFF" }}>Powered By</span>
            <a target="_blank" href="https://zumaxdigital.com/">
              <img
                style={{ width: "auto", height: "60px", marginLeft: "10px", cursor: 'pointer' }}
                src={require("../assets/img/zumax-logo.webp")}
              />
            </a>
          </div>
          <div style={{ marginTop: '20px' }}> <small>Copyright &copy; 2023 Zumax Digital. All Rights Reserved</small> </div>
      </div>
      <div className="register-container-right right-container-width">
        <div className="registration-container">
          <C_PopoutNoti triggerSaved={notifyMessage}/>
          {Array.from(searchParams).length === 0 && <BiArrowBack className="mt-1 registration-arrow-back" style={{marginBottom:'10px',cursor:'pointer'}} size = {35} onClick={()=>{navigate("/Auth");}}/>}
            <div className="registration-title">
                <div className="registration-banner">
                  <img
                      src={require('../assets/img/zumax-logo-black.webp')}
                      alt=""
                  />
                </div>
                <span className="super-title">Let's Get Started</span>
                <span>By Register an Account</span>
            </div>
            <div className="registration-form-container">
                <div className="form-row">
                    <div className="input-container">
                        <span>First Name</span>
                        <input className={`${firstName === "" ? "error-input" : ""}`} type="text" placeholder="First Name" value={firstName} onChange={(e)=>{setFirstName(e.target.value)}}/>
                    </div>
                    <div className="input-container">
                        <span>Last Name</span>
                        <input className={`${lastName === "" ? "error-input" : ""}`} type="text" placeholder="Last Name" value={lastName} onChange={(e)=>{setLastName(e.target.value)}}/>
                    </div>
                </div>
                <div className="form-row password-form-row">
                    <div className="input-container">
                      {(incorrectEmailFormat || emailExist) ? 
                          <div className="error-input-container">
                            <span>Email</span>
                            <div className="error-input-icon"><HiOutlineExclamationCircle size={17}/>
                              <div className="error-input-dialog">
                                {incorrectEmailFormat ? "Email address must consist of at least 6 characters username. E.g. johnny@gmail.com." : "Email address is already in use by other account, please try again."}
                              </div>
                            </div>
                          </div> 
                          : 
                          <span>Email</span>}
                        <input className={`${(incorrectEmailFormat||emailExist) ? "error-input" : email === "" ? "error-input" : ""}`} type="text" placeholder="Enter your business email address" value={email} onChange={(e)=>{setEmail(e.target.value)}}/>
                    </div>
                    <span className="format-span">E.g. johnny@gmail.com.</span>
                </div>
                <div className="form-row password-form-row">
                    <div className="input-container">
                      {incorrectPasswordFormat ? 
                          <div className="error-input-container">
                            <span>Password</span>
                            <div className="error-input-icon"><HiOutlineExclamationCircle size={17}/>
                              <div className="error-input-dialog">
                                Password must consist of at least 1 uppercase letter and 1 symbol within the length between 8-16.
                              </div>
                            </div>
                          </div> 
                          : 
                          <span>Password</span>}
                          <div className="password-input-container">
                            <input className={`${incorrectPasswordFormat ? "error-input" : password === "" ? "error-input" : ""}`} type={viewPassword ? "text" : "password"} placeholder="Your password" value={password} onChange={(e)=>{setPassword(e.target.value)}}/>
                            <span onClick={()=>{setViewPassword(!viewPassword)}}>
                              {viewPassword ? 
                                <FaRegEye/> 
                                : 
                                <FaRegEyeSlash/>
                              }
                            </span>
                          </div>
                    </div>
                    <span className="format-span">Requirements : 8-16 characters, 1 uppercase letter, and 1 symbol (! @ # $ % ^ & * _ + - =)</span>
                </div>
                <div className="form-row">
                    <div className="input-container">
                        {passwordMismatch ? 
                        <div className="error-input-container">
                          <span>Confirm Password</span>
                          <div className="error-input-icon"><HiOutlineExclamationCircle size={17}/>
                            <div className="error-input-dialog">
                              Passwords do not match. Please try again.
                            </div>
                          </div>
                        </div> 
                        : 
                        <span>Confirm Password</span>}
                        <div className="password-input-container">
                          <input className={`${passwordMismatch ? "error-input" : confirmPassword === "" ? "error-input" : ""}`} type={viewConfirmPassword ? "text" : "password"} placeholder="Confirm password" value={confirmPassword} onChange={(e)=>{setConfirmPassword(e.target.value)}}/>
                          <span onClick={()=>{setViewConfirmPassword(!viewConfirmPassword)}}>
                            {viewConfirmPassword ? 
                              <FaRegEye/> 
                              : 
                              <FaRegEyeSlash/>
                            }
                          </span>
                        </div>
                        
                    </div>
                </div>
                
                <div className="form-row">
                    <div className="input-container">
                      <span>Country</span>
                      <select className={`${country === "" ? "error-input" : ""} font-xxs`} value={country} onChange={(e) => {setCountry(e.target.value)}}>
                        <option value= "">Select Country</option>
                        {Object.keys(country_and_states.country).map((key) => {
                          const item = country_and_states.country[key];
                          return (
                            <option key={key} value={key}>
                              {item}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="input-container">
                        <span>State</span>
                        <select className={`${state === "" ? "error-input" : ""} font-xxs`} value={state} onChange={(e) => {setState(e.target.value)}}>
                        <option value= "">Select State</option>
                        {stateArray?.map((item)=>{
                          return (
                            <option value={item.name}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                </div>
                <div className="form-row">
                    <div className="input-container">
                        {incorrectPhoneFormat ? 
                          <div className="error-input-container">
                            <span>Phone No.</span>
                            <div className="error-input-icon"><HiOutlineExclamationCircle size={17}/>
                              <div className="error-input-dialog">
                                {country === "MY" ? "Incorrect Malaysia phone format. E.g 016 433 4444 / 011 9999 8888." : "Incorrect phone format."}
                              </div>
                            </div>
                          </div> 
                          : 
                          <span>Phone No.</span>}
                          <div className="phone-input-container">
                            <span>{country}</span>
                            <input className={`${incorrectPhoneFormat ? "error-input" : ""}`} type="tel" placeholder="e.g. 60163334444" value={phoneNum} onChange={(e) => {handlePhoneChange(e.target.value)}}/>
                          </div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="input-container">
                        <span>Company Name</span>
                        <input className={`${company === "" ? "error-input" : ""}`} type="text" placeholder="Enter company name" value={company} onChange={(e)=>{setCompany(e.target.value)}}/>
                    </div>
                </div>
                <div className="form-row">
                    <div className="input-container">
                        <span>Company Size</span>
                        <select className={`${companySize === "" ? "error-input" : ""} font-xxs`} value={companySize} onChange={(e) => {setCompanySize(e.target.value)}}>
                          <option value="">Choose your company size</option>
                          <option value="1-49">1-49</option>
                          <option value="50-900">50-900</option>
                          <option value="1000-4999">1000-4999</option>
                          <option value="5000 or more">5000 or more</option>
                        </select>
                    </div>
                </div>
            </div>
            
            <div className="registration-button" style={
              isCreate ? {justifyContent:'space-between'} : {justifyContent:'end'}
            }>
                <div className={`py-1 loadingContainer ${!isCreate && "hide-loading-container"}`}>
                    <div className={`${isLoading ? "loading-spinner" : "hide-loading-spinner"}`}>
                    </div>
                    {isLoading ?
                    <>
                      <span>Please wait while we are processing the data ...</span>
                    </>
                    :
                    <div className="finished-loading-container">
                      <span>Registration successfully, you may proceed to login.</span> 
                      <div><BsCheck2Circle size={18}  style={{fontWeight:"600",color:"green"}}/></div>
                    </div>}
                </div>
                {!isCreate &&
                  <C_DialogButton
                    onClick={()=>{createUser();}}
                    color={"white"}
                    background={'#3eb986'}
                    borderColor={'#3eb986'}
                    buttonText={"REGISTER"}
                  />
                }
                
            </div>
        </div>
        
      </div>
    </div>
  );
}

export default Register;
