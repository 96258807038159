import React, { Fragment, useState, useRef } from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";
import './GraphGenerator.css';
import { v4 } from "uuid";
import { TbDatabase, TbPresentationAnalytics } from 'react-icons/tb'
import { BiCustomize } from 'react-icons/bi'
import { AiOutlineCalendar, AiOutlineClose, AiOutlineNumber, AiOutlineUser } from 'react-icons/ai'
import { BiFont } from 'react-icons/bi'
import { MdDelete, MdOutlineDragIndicator } from 'react-icons/md'
import { BsFillGearFill, BsPlusSquareFill } from "react-icons/bs";
import { Modal } from "bootstrap";
import C_DialogButton from "../../../components/C_DialogButton";
import { selectAnalyticsContext, setAnalyticsContext } from "../../../stores/slices/analyticsSlices";
import { useDispatch } from "react-redux";

function VariableSets(props) {

  const setAnalytics = props.setAnalytics;

  const { 
    analyticsData, 
    analytics,
    dispatchAnalytics,
    mergedDataSet,
  } = selectAnalyticsContext();

  const dispatch = useDispatch();


  const advanceVariable = analytics?.CustomVariables;

  const [customData, setCustomData] = useState(
    {
      variableName: "",
      variableType: "Integer",
      targetField: "",
      value: ""
    }
  );
  const [targetValueOptions, setTargetValueOptions] = useState([]);
  
  const modalRef = useRef();

  const showModalFunc = () => {
    const modalEle = modalRef.current;
    const bsModal = new Modal(modalEle, {
      backdrop: "static",
      keyboard: false,
    });
    bsModal.show();
  };

  const hideModalFunc = () => {
      const modalEle = modalRef.current;
      const bsModal = Modal.getInstance(modalEle);
      bsModal.hide();
  };

  const modalManageRef = useRef();

  const showManageModalFunc = () => {
    const modalEle = modalManageRef.current;
    const bsModal = new Modal(modalEle, {
      backdrop: "static",
      keyboard: false,
    });
    bsModal.show();
  };

  const hideManageModalFunc = () => {
      const modalEle = modalManageRef.current;
      const bsModal = Modal.getInstance(modalEle);
      bsModal.hide();
  };

  // This method is needed for rendering clones of draggables
  const getRenderItem = (items, className) => (provided, snapshot, rubric) => {
    const data = items[rubric.source.index];
    return (
      <Fragment>
        <div
          key={data.id}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          style={provided.draggableProps.style}
          className={`variable ${snapshot.isDragging && "dragging"}`}
        >
          {data.type === "Date" && <AiOutlineCalendar size={12}/>}
          {data.type === "Time" && <AiOutlineCalendar size={12}/>}
          {data.type === "String" && <BiFont size={12}/>}
          {data.type === "Integer" && <AiOutlineNumber size={12}/>}
          {data.type === "Float" && <AiOutlineNumber size={12}/>}
          <span>{data.key}</span>
        </div>
      </Fragment>
    );
  };

  const getRenderItemForClient = (items, className) => (provided, snapshot, rubric) => {
    const data = items[rubric.source.index];
    return (
      <Fragment>
        <div
          key={data.id}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          style={provided.draggableProps.style}
          className={`variable ${snapshot.isDragging && "dragging"}`}
        >
          {data.type === "Short Text" && <BiFont size={12}/>}
          {data.type === "Numbers" && <AiOutlineNumber size={12}/>}
          {data.type === "Datetime" && <AiOutlineCalendar size={12}/>}
          {data.type === "Date" && <AiOutlineCalendar size={12}/>}
          {data.type === "Email" && <BiFont size={12}/>}
          {data.type === "Phone" && <BiFont size={12}/>}
          {data.type === "Switch" && <BiFont size={12}/>}
          {data.type === "Multiple Choices" && <BiFont size={12}/>}
          {data.type === "Dropdown" && <BiFont size={12}/>}
          {data.type === "Member" && <AiOutlineUser size={12}/>}
          <span>{data.key}</span>
        </div>
      </Fragment>
    );
  };

  const getRenderItemAdvanced = (items, className) => (provided, snapshot, rubric) => {
    const data = items[rubric.source.index];
    return (
      <Fragment>
        <div
          key={data.variableID}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          style={provided.draggableProps.style}
          className={`variable ${snapshot.isDragging && "draggingAdvance"}`}
        >
          {data.variableType === "Integer" && <AiOutlineNumber size={12}/>}
          <span>{data.variableName}</span>
        </div>
      </Fragment>
    );
  };
  
  function Copyable(props) {
    //item
    //className
    if(props.type === "Form Templates"){
      if(props.items){
        const dataItems = [...props?.items];
        return (
          <Droppable
            renderClone={getRenderItem(props.items, props.className)}
            droppableId={props.droppableId}
            isDropDisabled={true}
          >
            {(provided, snapshot) => (
              <div ref={provided.innerRef} className={props.className}>
                {dataItems?.map((data, index) => {
                    const shouldRenderClone = data.id === snapshot.draggingFromThisWith
                    return (
                      <Fragment key={data.id}>
                        {shouldRenderClone ? (
                          <div className="react-beautiful-dnd-copy" key={data.id}>
                            <div className="name">
                              {data.type === "Date" && <AiOutlineCalendar size={12}/>}
                              {data.type === "Time" && <AiOutlineCalendar size={12}/>}
                              {data.type === "String" && <BiFont size={12}/>}
                              {data.type === "Integer" && <AiOutlineNumber size={12}/>}
                              {data.type === "Float" && <AiOutlineNumber size={12}/>}
                              <span>{data.key}</span>
                            </div>
                            <div className="drag">
                              <MdOutlineDragIndicator size={12}/>
                            </div>
                          </div>
                        ) : (
                          <Draggable draggableId={data.id} index={index}>
                            {(provided, snapshot) => (
                              <Fragment>
                                <div
                                  key={data.id}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className={`variable ${snapshot.isDragging ? "dragging" : ""}`}
                                >
                                  <div className="name">
                                    {data.type === "Date" && <AiOutlineCalendar size={12}/>}
                                    {data.type === "Time" && <AiOutlineCalendar size={12}/>}
                                    {data.type === "String" && <BiFont size={12}/>}
                                    {data.type === "Integer" && <AiOutlineNumber size={12}/>}
                                    {data.type === "Float" && <AiOutlineNumber size={12}/>}
                                    <span>{data.key}</span>
                                  </div>
                                  <div className="drag">
                                    <MdOutlineDragIndicator size={12}/>
                                  </div>
                                </div>
                              </Fragment>
                            )}
                          </Draggable>
                        )}
                      </Fragment>
                    );
                  })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        );
      }
 
    }else{
      if(props.items){
        const dataItems = [...props?.items].filter(
          (type) => 
            type.type !== "Multiline" && 
            type.type !== "Rich Text" && 
            type.type !== "Link" &&
            type.type !== "Google Map" && 
            type.type !== "Checkboxes" &&
            type.type !== "Form" );
        return (
          <Droppable
            renderClone={getRenderItemForClient(dataItems, props.className)}
            droppableId={props.droppableId}
            isDropDisabled={true}
          >
            {(provided, snapshot) => (
              <div ref={provided.innerRef} className={props.className}>
                {dataItems
                    .map((data, index) => {
                    const shouldRenderClone = data.id === snapshot.draggingFromThisWith
                    return (
                      <Fragment key={data.id}>
                        {shouldRenderClone ? (
                          <div className="react-beautiful-dnd-copy" key={data.id}>
                            <div className="name">
                              {data.type === "Handler" && <AiOutlineUser size={12}/>}
                              {data.type === "Short Text" && <BiFont size={12}/>}
                              {data.type === "Numbers" && <AiOutlineNumber size={12}/>}
                              {data.type === "Datetime" && <AiOutlineCalendar size={12}/>}
                              {data.type === "Date" && <AiOutlineCalendar size={12}/>}
                              {data.type === "Email" && <BiFont size={12}/>}
                              {data.type === "Phone" && <BiFont size={12}/>}
                              {data.type === "Switch" && <BiFont size={12}/>}
                              {data.type === "Multiple Choices" && <BiFont size={12}/>}
                              {data.type === "Dropdown" && <BiFont size={12}/>}
                              {data.type === "Member" && <AiOutlineUser size={12}/>}
                              <span>{data.key}</span>
                            </div>
                            <div className="drag">
                              <MdOutlineDragIndicator size={12}/>
                            </div>
                          </div>
                        ) : (
                          <Draggable draggableId={data.id} index={index}>
                            {(provided, snapshot) => (
                              <Fragment>
                                <div
                                  key={data.id}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className={`variable ${snapshot.isDragging ? "dragging" : ""}`}
                                >
                                  <div className="name">
                                    {data.type === "Handler" && <AiOutlineUser size={12}/>}
                                    {data.type === "Short Text" && <BiFont size={12}/>}
                                    {data.type === "Numbers" && <AiOutlineNumber size={12}/>}
                                    {data.type === "Datetime" && <AiOutlineCalendar size={12}/>}
                                    {data.type === "Date" && <AiOutlineCalendar size={12}/>}
                                    {data.type === "Email" && <BiFont size={12}/>}
                                    {data.type === "Phone" && <BiFont size={12}/>}
                                    {data.type === "Switch" && <BiFont size={12}/>}
                                    {data.type === "Multiple Choices" && <BiFont size={12}/>}
                                    {data.type === "Dropdown" && <BiFont size={12}/>}
                                    {data.type === "Member" && <AiOutlineUser size={12}/>}
                                    <span>{data.key}</span>
                                  </div>
                                  <div className="drag">
                                    <MdOutlineDragIndicator size={12}/>
                                  </div>
                                </div>
                              </Fragment>
                            )}
                          </Draggable>
                        )}
                      </Fragment>
                    );
                  })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        );
      }
  
    }
  };

  function CopyableAdvance(props) {
      return (
        <Droppable
          renderClone={getRenderItemAdvanced(props.items, props.className)}
          droppableId={props.droppableId}
          isDropDisabled={true}
        >
          {(provided, snapshot) => (
            <div ref={provided.innerRef} className={props.className}>
              {props?.items?.map((data, index) => {
                  const shouldRenderClone = data.variableID === snapshot.draggingFromThisWith
                  return (
                    <Fragment key={data.variableID}>
                      {shouldRenderClone ? (
                        <div className="react-beautiful-dnd-copy" key={data.variableID}>
                          <div className="name">
                            {data.variableType === "Integer" && <AiOutlineNumber size={12}/>}
                            <span>{data.variableName}</span>
                          </div>
                          <div className="drag">
                            <MdOutlineDragIndicator size={12}/>
                          </div>
                        </div>
                      ) : (
                        <Draggable draggableId={data.variableID} index={index}>
                          {(provided, snapshot) => (
                            <Fragment>
                              <div
                                key={data.variableID}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className={`variable ${snapshot.isDragging ? "dragging" : ""}`}
                              >
                                <div className="name">
                                  {data.variableType === "Integer" && <AiOutlineNumber size={12}/>}
                                  <span>{data.variableName}</span>
                                </div>
                                <div className="drag">
                                  <MdOutlineDragIndicator size={12}/>
                                </div>
                              </div>
                            </Fragment>
                          )}
                        </Draggable>
                      )}
                    </Fragment>
                  );
                })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      );
  };

  function handleInputCustomVariable({ type, value }){
    switch(type){
      case "variableName":
        setCustomData({...customData, variableName: value})
        break;
      case "targetField":
        setCustomData({...customData, targetField: value});
        const statusArray = [...new Set(mergedDataSet.map(obj => obj[value]))];
        if(statusArray.length === 1 && statusArray[0] === undefined){
          setTargetValueOptions([]);
        }else{
          setTargetValueOptions(statusArray);
        }
        break;
      case "value":
        setCustomData({...customData, value: value})
        break;
      default:
        break;
    };
  };

  function CreateCustomVariable(){
    const customDataResult = {...customData, variableID: v4()}
    const CustomVariables = [...advanceVariable, customDataResult]
    let data = {...analytics};

    data = {...data, CustomVariables:  CustomVariables};
    setAnalytics(data)
    // dispatchAdvanceVariable({ type:"CHANGE_ADVANCE_VARIABLE", payload: CustomVariables })
    setCustomData({
      variableName: "",
      variableType: "Integer",
      targetField: "",
      value: ""
    });
    setTargetValueOptions([]);
    hideModalFunc();
  };

  function deleteVariables(targetID){
    const newVariables = advanceVariable.filter(item => item?.variableID !== targetID);
    let data = {...analytics};

    data = {...data,CustomVariables:  newVariables};
    dispatch(setAnalyticsContext({analytics : data}));
    // dispatchAnalytics({ type : "ANALYTICS", payload: data })
    // dispatchAdvanceVariable({ type:"CHANGE_ADVANCE_VARIABLE", payload: newVariables });
  }

  return (
    <div className="variableSetsContainer">
      
      <div className="modal fade new_member" id="new-member" tabIndex="-1" ref={modalRef}>
        <div className="modal-dialog modal-dialog-centered" style={{}}>
            <div className="modal-content px-3" style={{}}>
                <div className="modal-header modalHeader">
                    <div className="title">
                      <BiCustomize size={17} color={"#4287f5"}/>
                      <span className="font-s">Custom Variables</span>
                    </div>
                    <AiOutlineClose cursor='pointer' size={16} onClick={() => { hideModalFunc(); }}/>
                </div>

                <div className={`customVariablesBody row modal-body`}>
                
                  <div className="newCustomVariable">
                      <div className="selection">
                        <span>Custom Variable Name</span>
                        <input
                          className="new-company-input font-s"
                          placeholder="variable name"
                          value={customData.variableName}
                          onChange={(e) => {
                            handleInputCustomVariable({ type: 'variableName', value: e.target.value});
                          }}
                        />
                      </div>

                      <div className="selection">
                        <span>Field</span>
                        <select
                          value={customData.targetField}
                          onChange={(e) => {
                            handleInputCustomVariable({ type: 'targetField', value: e.target.value})
                          }}
                        >
                          <option value="" disabled>{"Choose an option"}</option>
                          {(analyticsData && analyticsData.Dataset) ? (analyticsData.Source === "Form Templates") ? 
                            analyticsData.Dataset?.filter(
                              (data) => 
                                data.type !== "Integer" && 
                                data.type !== "Float" 
                            ).map(item => {
                              return(
                                <option key={item.id} value={item.key}>{item.key}</option>
                              )
                            })
                            :
                            analyticsData.Dataset.filter(
                            (data) => 
                              data.type !== "Numbers" && 
                              data.type !== "Multiline" && 
                              data.type !== "Rich Text" && 
                              data.type !== "Link" &&
                              data.type !== "Google Map" && 
                              data.type !== "Checkboxes" &&
                              data.type !== "Form" ).map(item => {
                            return(
                              <option key={item.id} value={item.key}>{item.key}</option>
                            )
                          }) : <></>}
                        </select>
                      </div>

                      <div className="selection">
                        <span>Value</span>
                        <select
                          disabled={targetValueOptions.length > 0 ? false : true}
                          value={targetValueOptions.length > 0 ? customData.value : ""}
                          onChange={(e) => {
                            handleInputCustomVariable({ type: 'value', value: e.target.value})
                        }}>
                          <option value="" disabled>{"Choose an option"}</option>
                          {targetValueOptions.map((item, index) => {
                            return(
                              <option key={index} value={item}>{item}</option>
                            )
                          })}
                        </select>
                      </div>

                      <div className="action">
                        <div className='button-container'>
                          <C_DialogButton
                              onClick={()=>{ hideModalFunc() }}
                              color={'#4287f5'}
                              background={'white'}
                              borderColor={'#4287f5'}
                              buttonText={"BACK"}
                          />
                          <C_DialogButton
                              onClick={()=>{ CreateCustomVariable() }}
                              color={"#FFF"}
                              background={"#4287f5"}
                              borderColor={"#4287f5"}
                              buttonText={"CONFIRM"}
                          />
                        </div>
                      </div>

                    </div>

                  </div>
            </div>
        </div>
      </div>

      <div className="modal fade new_member" id="new-member" tabIndex="-1" ref={modalManageRef}>
        <div className="modal-dialog modal-dialog-centered" style={{}}>
            <div className="modal-content px-3" style={{}}>

                <div className="modal-header modalHeader">
                    <div className="title">
                      <BiCustomize size={17} color={"#4287f5"}/>
                      <span className="font-s">Manage Variables</span>
                    </div>
                    <AiOutlineClose cursor='pointer' size={16} onClick={() => { hideManageModalFunc();  }}/>
                </div>

                <div className={`editVariablesBody row modal-body`}>
                
                  <div className="newCustomVariable">
                      <div className="selection">
                        <span>Custom Variable Name</span>
                        <input
                          className="new-company-input font-s"
                          placeholder="variable name"
                          value={customData.variableName}
                          onChange={(e) => {
                            handleInputCustomVariable({ type: 'variableName', value: e.target.value});
                          }}
                        />
                      </div>

                      <div className="selection">
                        <span>Field</span>
                        <select
                          value={customData.targetField}
                          onChange={(e) => {
                            handleInputCustomVariable({ type: 'targetField', value: e.target.value})
                          }}
                        >
                          <option value="" disabled>{"Choose an option"}</option>
                          {(analyticsData && analyticsData.Dataset ) ?(analyticsData.Source === "Form Templates") ? 
                            analyticsData.Dataset?.filter(
                              (data) => 
                                data.type !== "Integer" && 
                                data.type !== "Float" 
                            ).map(item => {
                              return(
                                <option key={item.id} value={item.key}>{item.key}</option>
                              )
                            })
                            :
                            analyticsData.Dataset.filter(
                            (data) => 
                              data.type !== "Numbers" && 
                              data.type !== "Multiline" && 
                              data.type !== "Rich Text" && 
                              data.type !== "Link" &&
                              data.type !== "Google Map" && 
                              data.type !== "Checkboxes" &&
                              data.type !== "Form" ).map(item => {
                            return(
                              <option key={item.id} value={item.key}>{item.key}</option>
                            )
                          }) : <></>}
                        </select>
                      </div>

                      <div className="selection">
                        <span>Value</span>
                        <select
                          disabled={targetValueOptions.length > 0 ? false : true}
                          value={targetValueOptions.length > 0 ? customData.value : ""}
                          onChange={(e) => {
                            handleInputCustomVariable({ type: 'value', value: e.target.value})
                        }}>
                          <option value="" disabled>{"Choose an option"}</option>
                          {targetValueOptions.map((item, index) => {
                            return(
                              <option key={index} value={item}>{item}</option>
                            )
                          })}
                        </select>
                      </div>

                      <div className="action">
                        <div className='button-container'>
                          <C_DialogButton
                              onClick={()=>{ hideModalFunc() }}
                              color={'#4287f5'}
                              background={'white'}
                              borderColor={'#4287f5'}
                              buttonText={"BACK"}
                          />
                          <C_DialogButton
                              onClick={()=>{ CreateCustomVariable() }}
                              color={"#FFF"}
                              background={"#4287f5"}
                              borderColor={"#4287f5"}
                              buttonText={"CONFIRM"}
                          />
                        </div>
                      </div>

                    </div>

                  </div>

                  <div className="manageVariablesBody">
                    <table>
                      <thead>
                        <tr>
                          <th>Variable Name</th>
                          <th>Target Field</th>
                          <th>Value</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {advanceVariable?.map((item, index) => {
                          return(
                            <tr key={index}>
                              <td>{item?.variableName}</td>
                              <td>{item?.targetField}</td>
                              <td>{item?.value}</td>
                              <td><MdDelete className="icon" size={18} color="#ca4254" onClick={() => { deleteVariables(item?.variableID) }}/></td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
            </div>
        </div>
      </div>

      <div className="variableSetsHeader">
        <TbPresentationAnalytics size={18}/>
        <span className=''>Analytics</span>
      </div>
      <div className={`analytics-visible`}>
          <div className="selected">
            <div className="selectedTitle">
              <TbDatabase size={12}/>
              <span>
                  {analyticsData && analyticsData.DataName}
              </span>
            </div>
            <span>Total Variables: {analyticsData && analyticsData.DataLength}</span>
            <span>Last Update: {analyticsData && analyticsData.LastModified}</span>
          </div>
          <Copyable 
            droppableId="DATASETS"
            className="variableSet" 
            items={analyticsData && analyticsData?.Dataset} 
            type={(analyticsData && analyticsData.Source)} 
          />
          <div className="advancedTools">
              <div className="advancedToolsAction">
                  <span>Custom Variables</span>
                  <div className="tools">
                    <div>
                      <BsPlusSquareFill className="icon" size={18} onClick={() => { showModalFunc(); }}/>
                    </div>
                    <div>
                      <BsFillGearFill className="icon" size={18} onClick={() => { showManageModalFunc(); }}/>
                    </div>
                  </div>
              </div>
              <div className="customCalculationsContainer">
                <CopyableAdvance 
                  droppableId="ADVANCE_DATASETS"
                  className="variableSet" 
                  items={advanceVariable}
                />
              </div>
          </div>
      </div>
    </div>
  );
}

export default VariableSets