import React from "react";
import PropTypes from "prop-types";
import {
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { HelpOutlineRounded, WarningRounded } from "@mui/icons-material";

export const InputUnderline = ({
  type = "text",
  name,
  isDisabled,
  value,
  onChange,
  onBlur,
  startAdornment,
  endAdornment,
  tooltips,
  minWidth,
  maxWidth,
  fullWidth = true,
  label,
  error,
  helperText,
}) => {
  const handleChange = (event) => {
    onChange(event);
  };

  const handleBlur = () => {
    const trimmedValue = value && value?.trim ? value.trim() : value;
    onChange({ target: { value: trimmedValue } });
    onBlur && onBlur();
  };

  const inputTextProps = {
    "data-testid": `${name}TextInputUnderline`,
  };

  // Handle render helper text and error text
  const helperHandling = () => {
    if (helperText && !error)
      return (
        <Typography
          className="helper"
          variant="remark"
          color={({ palette }) => palette.text.light}
          sx={{ marginLeft: "10px" }}
        >
          {helperText}
        </Typography>
      );
    if (error)
      return (
        <Typography
          variant="bodyText"
          color={({ palette }) => palette.snack.error}
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <WarningRounded
            sx={({ palette, icons }) => ({
              ...icons.standard,
              color: palette.snack.error,
              pointerEvents: "none",
            })}
          />
          {error}
        </Typography>
      );
    return null;
  };

  const startAdornmentComponent = startAdornment ? (
    <InputAdornment position="start">{startAdornment}</InputAdornment>
  ) : null;

  const endAdornmentComponent = endAdornment ? (
    <InputAdornment position="end" sx={{ marginRight: "5px" }}>
      {endAdornment}
    </InputAdornment>
  ) : null;

  const tooltipsAdornmentComponent = tooltips ? (
    <InputAdornment position="end">
      <Tooltip title={tooltips}>
        <IconButton
          sx={{ position: "absolute", right: 0, marginRight: "-40px" }}
        >
          <HelpOutlineRounded sx={({ icons }) => ({ ...icons.standard })} />
        </IconButton>
      </Tooltip>
    </InputAdornment>
  ) : null;

  const endAdornmentCombinationComponent = (
    <>
      {endAdornmentComponent}
      {tooltipsAdornmentComponent}
    </>
  );

  return (
    <TextField
      sx={{ minWidth: minWidth, maxWidth: maxWidth }}
      autoComplete="off"
      aria-autocomplete="none"
      type={type}
      fullWidth={fullWidth}
      placeholder={label}
      variant="standard"
      disabled={isDisabled}
      value={value}
      onChange={handleChange}
      inputProps={inputTextProps}
      helperText={helperHandling()}
      onBlur={handleBlur}
      InputProps={{
        startAdornment: startAdornmentComponent,
        endAdornment: endAdornmentCombinationComponent,
      }}
    />
  );
};

InputUnderline.propTypes = {
  type: PropTypes.any,
  textType: PropTypes.any,
  name: PropTypes.any,
  isDisabled: PropTypes.any,
  value: PropTypes.any,
  onChange: PropTypes.any,
  onBlur: PropTypes.any,
  startAdornment: PropTypes.any,
  endAdornment: PropTypes.any,
  tooltips: PropTypes.any,
  minWidth: PropTypes.any,
  maxWidth: PropTypes.any,
  fullWidth: PropTypes.any,
  label: PropTypes.any,
  error: PropTypes.any,
  helperText: PropTypes.any,
};
