import React from 'react'
import { MdOutlineTipsAndUpdates } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'

function ImportDataFormat() {

    const navigate = useNavigate();

  return (
    <div className='content'>

<div className='tableInfo'>
            <table>
                <tr>
                    <th>Components & Handler</th>
                    <th>Ideal Format</th>
                </tr>
                <tr>
                    <td>Short Text</td>
                    <td>Any text.</td>
                </tr>
                <tr>
                    <td>Multiline</td>
                    <td>Any text..</td>
                </tr>
                <tr>
                    <td>Number</td>
                    <td>1234 as longs as numeric number</td>
                </tr>
                <tr>
                    <td>Date</td>
                    <td>09-12-2023 or 09-12-2023 16:42:33</td>
                </tr>
                <tr>
                    <td>Email</td>
                    <td>abc123@gmail.com.</td>
                </tr>
                <tr>
                    <td>Phone</td>
                    <td>0162345678</td>
                </tr>
                <tr>
                    <td>Status</td>
                    <td>active or inactive</td>
                </tr>
                <tr>
                    <td>Rich Text Editor</td>
                    <td>Maybe a short note....</td>
                </tr>
                <tr>
                    <td>Link</td>
                    <td>www.google.com</td>
                </tr>
                <tr>
                    <td>Google Map</td>
                    <td>Location or Place link that you can get on top of your browser under Google Map https://www.google.com/maps/place/Gurney+Plaza/@5.4369915,100.3072279,17z/........</td>
                </tr>
                <tr>
                    <td>Multiple Choice</td>
                    <td>Answer 1</td>
                </tr>
                <tr>
                    <td>Checkbox</td>
                    <td>Answer 1, Answer 2</td>
                </tr>
                <tr>
                    <td>Dropdown</td>
                    <td>Answer 1</td>
                </tr>
                <tr>
                    <td>Member Dropdown</td>
                    <td>UID (You may refer from <a href='https://rexsoft.info/#/Company' target='_blank'>Company</a> page and copy Member's UID)</td>
                </tr>
                <tr>
                    <td>Handler</td>
                    <td>UID (You may refer from <a href='https://rexsoft.info/#/Company' target='_blank'>Company</a> page and copy Member's UID)</td>
                </tr>
            </table>
        </div>
    </div>
  )
}

export default ImportDataFormat