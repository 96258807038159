import { Route } from "react-router-dom"
import { crmRoutes } from "../enums/Route"
import ProtectedRoute from "./ProtectedRoute"
import TenantPortal from "../containers/tenant/TenantPortal"
import { Fragment } from "react"
import GaPortal from "../containers/gaPortal/GaPortal"
import ProtectedRoute_GA from "./ProtectedRoute_GA"

export const GaPortalRoute = ({currGaState}) => (
    <Fragment>
        {currGaState &&
            <Route 
                path={crmRoutes.ga.gaPortal}
                element={
                    <ProtectedRoute_GA>
                        <GaPortal />
                    </ProtectedRoute_GA>
                }
            />
        }
    </Fragment> 
)

