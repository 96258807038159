import React, { useState, useEffect } from 'react'
import { MdArrowBack } from 'react-icons/md';
import { BsTable } from 'react-icons/bs';
import { useNavigate, createSearchParams } from 'react-router-dom';
import { CgArrowRightR } from 'react-icons/cg';
import { IoIosAddCircleOutline } from 'react-icons/io'
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { FiChevronDown, FiChevronRight } from 'react-icons/fi';

//slices
import { getAnalytics, getAvailableHandler, getSingleAnalytics, selectAnalyticsContext, selectAnalyticsData, selectAnalyticsDataset, selectAvailableHandler, selectGetSingleAnalyticsLoading, selectSingleAnalytics, setAnalyticsContext } from '../../stores/slices/analyticsSlices';
import { retrieveAvailableGroup } from '../../stores/slices/clientSlices';
import { selectCompanyID } from '../../stores/slices/companyIDSlices';
import { selectCurrTenant } from '../../stores/slices/persistRolesSlices';
import { selectUser } from '../../stores/slices/userAuthSlices';

function DataSets(props) {

    const user = selectUser();
    const companyID = selectCompanyID();
    const tenant = selectCurrTenant();
    const analyticsData = selectAnalyticsData();
    const availableHandler = selectAvailableHandler();
    const singleAnalytics = selectSingleAnalytics();
    const singleAnalyticsLoading = selectGetSingleAnalyticsLoading();
    const {
        dispatchAnalyticsData,
        dispatchAnalytics,
        mergedDataSet,
        dispatchFilterList,
        dispatchSortList,
        dispatchSortState,
        dispatchFilterState,
        dispatchAvailableHandlerIDs,
    } = selectAnalyticsContext();

    const analyticsDataset = selectAnalyticsDataset();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    // const analyticsDataset = props.analyticsDataset;

    const [analyticsDoc, setAnalyticsDoc] = useState([]);
    const [clientDropDown, setClientDropDown] = useState(true);
    const [formDropDown, setFormDropDown] = useState(true);
    const [selectedDataset, setSelectedDataset] = useState("");
    const [analyticsParam, setAnalyticsParam] = useState("");

    const [isAnalyticsOpen, setIsAnalyticsOpen] = useState(false);

    useEffect(()=>{
        if(analyticsData){
            setAnalyticsDoc(analyticsData)
        }
    }, [analyticsData])

    useEffect(()=>{
        if(singleAnalytics && isAnalyticsOpen){
            if(analyticsParam !== "NEW"){

                if(!singleAnalyticsLoading){
                    dispatch(setAnalyticsContext(
                        {
                            analytics : singleAnalytics, 
                            filterList : singleAnalytics.filterList,
                            sortList : singleAnalytics.sortList,
                            sortState : false,
                            filterState : false
                        },
                    ))
                    
                    // dispatchAnalytics({ type : "ANALYTICS", payload: singleAnalytics })
                    // dispatchFilterList({type: "CHANGE_FILTER_LIST", payload: singleAnalytics.filterList})
                    // dispatchSortList({ type: 'CHANGE_SORT_LIST', payload: singleAnalytics.sortList})
                    // dispatchSortState({type: "CHANGE_SORT_STATE", payload: false})
                    // dispatchFilterState({type: "CHANGE_FILTER_STATE", payload: false})
                    if(availableHandler){
                      navigate({ pathname: "/WorkSpace/GraphGenerator", search: createSearchParams({ 
                          params : analyticsParam, //NEW // OR ID 
                          DataID: selectedDataset
                      }).toString()}) 
                    }
                }
                
            } else {
                // dispatch(getAvailableHandler({TenantID: tenant, DataID: selectedDataset})).then(res => {
                //     dispatchAvailableHandlerIDs({ type : "AVAILABLE_HANDLER", payload: res })
                // })
                var sortData = analyticsParam.sortList ? JSON.parse(analyticsParam.sortList) : null;
    
                var data = {
                    target: sortData ? sortData.target : "",
                    type: sortData ? sortData.type : ""
                }
                
                dispatch(setAnalyticsContext({ 
                        analytics: 
                        {
                            AnalyticsID: "",
                            CompanyID: companyID,
                            UserID: user.uid,
                            DataID: selectedDataset,
                            Publish: false,
                            Cover: "",
                            GraphTitle: "",
                            GraphType: "Line",
                            yAxis: [],
                            xAxis: [],
                            PieProperty: analyticsParam === "NEW" ? {
                                innerRadius: 0,
                                colorPalette: "default",
                                textColor: "#FFFFFF",
                                textSize: 12,
                                strokeColor: "#000000",
                                strokeWidth: 1,
                                LabelPosition: 'inner',
                                LabelKey: false,
                                LabelLine: false,
                                Percentage: true
                            } : analyticsParam.PieProperty,
                            Unit: "",
                            Label: "",
                            filterList: [],
                            sortList: data,
                            GraphData: [],
                            CreatedAt: moment(new Date()).format("YYYY-MM-DD"),
                            Dashboard: false,
                            DataSource:"",
                            CustomVariables: [],
                            ViewableBy: [],
                            dateSettings : {dateColumn: "", dateRange: ""}
                        }, 
                        filterList : [],
                        sortList : data,
                        sortState : false,
                        filterState : false
                    })
                )
                // dispatchSortState({type: "CHANGE_SORT_STATE", payload: false})
                // dispatchFilterState({type: "CHANGE_FILTER_STATE", payload: false})
                // dispatchFilterList({type: "CHANGE_FILTER_LIST", payload: []})
                // dispatchSortList({ type: 'CHANGE_SORT_LIST', payload: data})
    
                if(availableHandler){
                    navigate({ pathname: "/WorkSpace/GraphGenerator", search: createSearchParams({ 
                        params : analyticsParam, //NEW // OR ID
                        DataID: selectedDataset
                    }).toString()})
                }
                
            }    
        }
    }, [singleAnalytics, isAnalyticsOpen])

    useEffect(()=>{
        if(availableHandler){
            dispatch(setAnalyticsContext({availableHandlerIDs: availableHandler}));
            // dispatchAvailableHandlerIDs({ type : "AVAILABLE_HANDLER", payload: availableHandler })
        }
    }, [availableHandler])

    useEffect(() => {
        if(tenant && companyID && user){
            dispatch(retrieveAvailableGroup({ TenantID:tenant,CompanyID: companyID, UserID: user?.uid }))
        }
       
    },[tenant, companyID]);

    function handleSelectedSource({item, type}){
        let datasetID = item.DataID;
        setSelectedDataset(datasetID);
        // dispatchAnalyticsData({ type:"SELECTED_DATASET", payload: item })
        dispatch(setAnalyticsContext({analyticsData: item}));

        dispatch(getAnalytics({TenantID:tenant, DataID: datasetID, CompanyID:companyID}))

    }

    function handleOpenAnalytics(param){
        setIsAnalyticsOpen(true);
        setAnalyticsParam(param);
        dispatch(getAvailableHandler({TenantID: tenant, DataID: selectedDataset}));
        if(param !== "NEW"){
            dispatch(getSingleAnalytics({ TenantID: tenant, AnalyticsID: param }));
        }
    }

    function handleBackHome(){
        navigate('/Analytics');
    };

    const sourceOrigin = ["Clients", "Form Templates"]

    return (
    <div className='dataSet'>
        <div className='datasetSidebar'>
            <div className='header'>
                <MdArrowBack className="backIcon" size={18} onClick={() => { handleBackHome() }}/>
                <span>Data Sources</span>
            </div>
            <div className='data'>
                {sourceOrigin.map((source, index) => {
                    return (
                        <div key={index} className='sourceOriginSectionContainer'>
                            <div className='origin' onClick={() => { source === "Form Templates" ? setFormDropDown(!formDropDown) : setClientDropDown(!clientDropDown); }}>
                                <span>{source}</span>
                                {source === "Form Templates" ? (formDropDown ? <FiChevronDown size={18}/> : <FiChevronRight size={18}/>) 
                                : (clientDropDown ? <FiChevronDown size={18}/> : <FiChevronRight size={18}/>)}
                            </div>
                            {(formDropDown) && analyticsDataset.map((item) => {
                                if(source === "Form Templates" && source === item.Source){
                                    return(
                                        <div key={item.DataID} className={`sourceContainer ${selectedDataset && selectedDataset === item.DataID && 'highlight'}`} 
                                        onClick={() => { handleSelectedSource({item, type: source}) }}>
                                            <div className={`sourceTitle`}>
                                                <div className='title'>
                                                    <BsTable size={12}/>
                                                    <span>{item.DataName}</span>
                                                </div>
                                                <CgArrowRightR size={14}/>
                                            </div>
                                            <div className='sourceDetails'>
                                                <span>Total Field: {item.DataLength}</span>
                                                <span>Last Modified: {moment(item.LastModified).format("DD MMM YYYY hh:mma")}</span>
                                            </div>
                                        </div>
                                    )
                                }
                            })}
                            {(clientDropDown) && analyticsDataset.map((item) => {
                                if(source === "Clients" && source === item.Source){
                                    return(
                                        <div key={item.DataID} className={`sourceContainer ${selectedDataset && selectedDataset === item.DataID && 'highlight'}`} 
                                        onClick={() => { handleSelectedSource({item, type: source}) }}>
                                            <div className={`sourceTitle`}>
                                                <div className='title'>
                                                    <BsTable size={12}/>
                                                    <span>{item.DataName}</span>
                                                </div>
                                                <CgArrowRightR size={14}/>
                                            </div>
                                            <div className='sourceDetails'>
                                                <span>Total Field: {item.DataLength}</span>
                                                <span>Last Modified: {moment(item.LastModified).format("DD MMM YYYY hh:mma")}</span>
                                            </div>
                                        </div>
                                    )
                                }
                            })}
                        </div>
                    )
                })}
            </div>
        </div>
        {selectedDataset &&
        <div className={`datasetContainer`}>
            <div className='dataSourceDetails'>
                <div className='sourceInformation'>
                    <div className='newAnalytics'>
                        <div className='newContainer' onClick={() => { handleOpenAnalytics('NEW') }}>
                            <IoIosAddCircleOutline className='icon' size={35}/>
                        </div>
                        <span>ADD</span>
                    </div>
                    {analyticsDoc && [...analyticsDoc].sort((a ,b) => b.CreatedAt?.localeCompare(a.CreatedAt)).map((item, index) => {
                        if(user){
                            if(item?.UserID === user?.uid){
                                return(
                                    <div className='draftAnalytics' key={index} onClick={() => { handleOpenAnalytics(item.AnalyticsID) }}>
                                        <div className='draftContainer'>
                                            <img src={item.Cover} alt=""/>
                                        </div>
                                        <span>{item.GraphTitle}</span>
                                    </div>
                                )
                            }
                        }
                    })
                    }
                </div>
            </div>
        </div>
        }
        {!selectedDataset &&
            <div className='emptySet'>
                <img src={require('../../assets/analyticsGif.gif')} alt=''/>
                <span>CHOOSE SOURCE TO START</span>
            </div>
        }
    </div>
  )
}

export default DataSets