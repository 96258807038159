export const convertDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Month is zero-based, so add 1
    const year = date.getFullYear();
  
    // Pad single-digit day and month with leading zeros if needed
    const formattedDay = String(day).padStart(2, "0");
    const formattedMonth = String(month).padStart(2, "0");
  
    // Create the DD-MM-YYYY format
    const formattedDate = `${formattedDay}-${formattedMonth}-${year}`;
  
    return formattedDate;
  };
  