const component = {
  MuiFormControlLabel: {
    styleOverrides: {
      root: () => ({
        marginLeft: "0px !important",
        marginRight: "0px !important",
        "&.Mui-disabled": {
          opacity: 0.3,
        },
      }),
    },
  },
};

export default component;
