import React, { useState, useMemo, forwardRef, useEffect } from "react";

import PropTypes from "prop-types";
import { Box, Stack, Checkbox, Pagination, Typography } from "@mui/material";
import {
  DataGrid,
  GridPagination,
  useGridApiContext,
  gridPageCountSelector,
  useGridSelector,
} from "@mui/x-data-grid";
// import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { DataTableManageColumn } from "./DataTableManageColumn";
import {
  CheckBoxOutlineBlankRounded,
  DoneRounded,
  ArrowDropUpRounded,
  ArrowDropDownRounded,
  ViewListRounded,
} from "@mui/icons-material";
import { TableNoItemsOverlay } from "../../assets/svg/table-no-items-overlay";
import { UnsortedIcon } from "../../assets/svg/unsorted-icon";

//todo resizable
// import { Resizable } from "re-resizable";

export const DataTable = ({
  table,
  tableData,
  isLoading = false,
  onClick,
  onSelectRow = null,
  rowSelection = [],
  isRowSelectable = null,
  getApplyQuickFilterFn = null,
  filter = {
    items: [],
  },
  customFields = [],
  insertCustomFieldsIndex,
  //! is used for pagination
  // totalCount = 0,
  // onChangePage = () => {},
  onClickBulk,
  totalSumColumns = null,
  columnVisibilityModel = {},
  sx,
}) => {
  //* init state
  var getPage = 0;
  var getRowsPerPage = 30;
  //* check the returned table property got initialState object or not
  const hasInitState = table ? table.property && table.property.initialState : [];
  //* check the returned table property got initialState object or not
  const paginationModel = hasInitState
    ? table.property.initialState.pagination.paginationModel
    : null;
  if (paginationModel) {
    getPage = paginationModel.page;
    getRowsPerPage = paginationModel.pageSize;
  }

  //* Functions
  const footerItemsSelectedLabel = (count) => {
    const moreThan1 = count > 1;
    const label = moreThan1 ? "items" : "item";
    return `${count.toLocaleString()} ${label} selected`;
  };

  //* get current page rows for calculate total price
  const getCurrentPageRows = (page, pageSize) => {
    const startIndex = page * pageSize;
    const totalEndIndex = startIndex + pageSize;
    const endIndex = Math.min(totalEndIndex, tableData.length);
    const currentPageData = tableData.slice(startIndex, startIndex + endIndex);
    return currentPageData;
  };
  //! is used for pagination
  // const getTotalPageCount = () => {
  //   const { pageSize } = tableObjects;
  //   if (totalCount <= 0 || pageSize <= 0) {
  //     return 1; // At least one page, even for empty or invalid input
  //   }

  //   return Math.ceil(totalCount / pageSize);
  // };

  const initRows = getCurrentPageRows(getPage, getRowsPerPage);

  const customFieldColumns = customFields.map((custom_field) => {
    const customFieldName = custom_field.name;
    return {
      field: `custom_${customFieldName}`,
      headerName: customFieldName,
      type: "string",
      sortable: true,
      flex: 1,
      minWidth: 150,
      disableColumnMenu: true,

      renderCell: (params) => {
        const customFieldValue = params.row.custom_fields[`${customFieldName}`];
        return (
          <Typography
            variant="bodyText"
            sx={({ typography }) => ({
              ...typography.bodyText,
              ...typography.textOverflow,
            })}
          >
            {customFieldValue ? customFieldValue : "-"}
          </Typography>
        );
      },
    };
  });

  const [tableObjects, setTableObjects] = useState({
    rows: initRows,
    columns: table.columns(onClick),
    hiddenColumns: {},
    page: getPage,
    pageSize: getRowsPerPage,
  });

  useEffect(() => {
    setTableObjects((prev) => ({
      ...prev,
      columns: table.columns(onClick),
    }));
  }, [table]);

  //* Components
  const CustomPaginationActions = ({ page, onPageChange, className }) => {
    const apiRef = useGridApiContext();
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
    return (
      <Pagination
        color="primary"
        className={className}
        shape="rounded"
        count={pageCount}
        variant="outlined"
        page={page + 1}
        onChange={(event, newPage) => {
          onPageChange(event, newPage - 1);
        }}
      />
    );
    //! is used for pagination
    // const totalPages = getTotalPageCount();
    // return (
    //   <Pagination
    //     color="primary"
    //     className={className}
    //     shape="rounded"
    //     count={totalPages}
    //     variant="outlined"
    //     page={tableObjects.page + 1}
    //     onChange={(event, newPage) => {
    //       setTableObjects((prev) => ({
    //         ...prev,
    //         page: newPage - 1,
    //       }));
    //       onChangePage(newPage - 1, tableObjects.pageSize);
    //     }}
    //   />
    // );
  };

  CustomPaginationActions.propTypes = {
    page: PropTypes.any,
    onPageChange: PropTypes.any,
    className: PropTypes.any,
  };
  const CustomNoRowsOverlay = () => <TableNoItemsOverlay />;

  const CustomAscIcon = () => <ArrowDropUpRounded />;
  const CustomColumnActionIcon = () => (
    <ViewListRounded
      sx={({ icons }) => ({
        ...icons.standard,
      })}
    />
  );
  const CustomColumnFilterIcon = () => <></>;

  const CustomDscIcon = () => <ArrowDropDownRounded />;

  const CustomUnsortedIcon = () => <UnsortedIcon />;

  const CustomPagination = (props) => (
    <GridPagination ActionsComponent={CustomPaginationActions} {...props} />
  );

  const manageColumnFunction = (selectedColumn) => {
    const hiddenColumns = tableObjects.hiddenColumns;

    //if hiddenColumns not include then push in
    // else check if hiddenColumns is true or false

    // if selected column is visible then hide
    if (hiddenColumns[selectedColumn.field] !== undefined) {
      setTableObjects((prev) => ({
        ...prev,
        hiddenColumns: {
          ...prev.hiddenColumns,
          [`${selectedColumn.field}`]: !hiddenColumns[selectedColumn.field],
        },
      }));
    } else {
      setTableObjects((prev) => ({
        ...prev,
        hiddenColumns: {
          ...prev.hiddenColumns,
          [selectedColumn.field]: false,
        },
      }));
    }
  };

  const hideAllColumn = (hiddenColumns) => {
    setTableObjects((prev) => ({
      ...prev,
      hiddenColumns: hiddenColumns,
    }));
  };

  const showAllColumn = (shownColumns) => {
    setTableObjects((prev) => ({
      ...prev,
      hiddenColumns: shownColumns,
    }));
  };

  const customColumnMenu = () => {
    return (
      <DataTableManageColumn
        columns={tableObjects.columns}
        hiddenColumns={tableObjects.hiddenColumns}
        onClick={manageColumnFunction}
        showAllColumn={showAllColumn}
        hideAllColumn={hideAllColumn}
      />
    );
  };

  const columnIncludeCustomFields = useMemo(() => {
    const index = insertCustomFieldsIndex
      ? insertCustomFieldsIndex
      : tableObjects.columns.length;
    const result = [
      ...tableObjects.columns.slice(0, index),
      ...customFieldColumns,
      ...tableObjects.columns.slice(index),
    ];
    return result;
  }, [tableObjects.columns]);

  const sumTotalColumnsValue = useMemo(() => {
    if (totalSumColumns === null || tableData.length == 0) {
      return {};
    }
    const subTotal = tableData.reduce((sum, currentObject) => {
      const totalSumColumnEntries = Object.entries(totalSumColumns);
      const result = sum;
      totalSumColumnEntries.forEach(([key, property]) => {
        const amount = parseFloat(currentObject[property]);

        if (result[key]) {
          result[key] = (parseFloat(result[key]) + amount).toFixed(2);
        } else {
          result[key] = amount.toFixed(2);
        }
      });
      return result;
    }, {});

    return subTotal;
  }, [tableData]);

  //* custom footer with sub total price
  const customFooter = () => {
    const currencyCode = "RM";

    return (
      <Stack>
        {sumTotalColumnsValue ? (
          <Stack
            direction="row"
            spacing={4}
            sx={({ borders }) => ({
              borderRight: borders.default,
              borderLeft: borders.default,
              borderBottom: borders.default,
              marginTop: "2vh",
              alignSelf: "flex-end",
            })}
          >
            {Object.entries(sumTotalColumnsValue).map(([key, value]) => (
              <Box key={`${key}${value}`} sx={{ alignSelf: "end" }}>
                <Typography
                  variant="bodyText"
                  sx={({ typography }) => ({
                    ...typography.displayH3,
                    alignItems: "end",
                  })}
                >
                  {`${key} : ${currencyCode} ${value}`}
                </Typography>
              </Box>
            ))}
          </Stack>
        ) : (
          <></>
        )}
        {/* <GridFooter {...props} /> */}
      </Stack>
    );
  };

  const CustomCheckbox = forwardRef((props, ref) => {
    return (
      <Checkbox
        {...props}
        className="table_column_checkbox"
        inputRef={ref}
        checkedIcon={<DoneRounded />}
        icon={<CheckBoxOutlineBlankRounded />}
      />
    );
  });
  CustomCheckbox.displayName = "CustomCheckbox";

  const CustomToolbar = (props) => {
    //* can put component at here or maybe put filter at here
    return <Stack>{PaginationForToolbar(props)}</Stack>;
  };

  const PaginationForToolbar = (props) => (
    <Stack sx={{ alignItems: "center" }} direction="row">
      {table.bulkActions ? table.bulkActions(onClickBulk) : null}
      {table.property.hideFooter ? null : (
        <Box sx={{ flex: 1 }}>{CustomPagination(props)}</Box>
      )}
    </Stack>
  );

  return (
    <Box sx={[{ width: 1 }]}>
      <DataGrid
        density="standard"
        // autoHeight={true}
        sx={[
          ({ borders }) => ({
            "& .MuiDataGrid-main": {
              borderBottom: table.showSubTotal ? "none" : borders.default,
              height: "100%"
            },
            ...(sx ? sx : {}),
          }),
        ]}
        {...table.property}
        loading={isLoading}
        rows={tableData}
        columnVisibilityModel={{
          ...tableObjects.hiddenColumns,
          ...columnVisibilityModel,
        }}
        scrollbarSize={3}
        getApplyQuickFilterFn={getApplyQuickFilterFn}
        //! is used for pagination
        // rowCount={totalCount}
        columns={columnIncludeCustomFields.filter((column) => {
          return column.isVisible === undefined ? true : column.isVisible;
        })}
        disableRowSelectionOnClick={
          table.property.checkboxSelection ? false : true
        }
        disableMultipleColumnsFiltering={false}
        //! this is used for filter but it got limitation
        filterModel={filter}
        page={tableObjects.page}
        pageSize={tableObjects.pageSize}
        getRowId={table.getRowId != null ? table.getRowId : null}
        disableColumnMenu={false}
        onRowSelectionModelChange={(rowModel) => {
          onSelectRow ? onSelectRow(rowModel) : null;
        }}
        rowSelectionModel={rowSelection}
        isRowSelectable={
          isRowSelectable
            ? isRowSelectable
            : table.property.isRowSelectable
            ? table.property.isRowSelectable
            : null
        }
        onPaginationModelChange={(model) => {
          const page = model.page;
          const pageSize = model.pageSize;
          setTableObjects((prev) => ({
            ...prev,
            rows: getCurrentPageRows(page, pageSize),
            pageSize: pageSize,
            page: page,
          }));
        }}
        // onFilterModelChange={(model, details) => {
        //   console.log("is filter model");
        //   console.log(model);
        // }}
        //! is used for pagination

        // onPageChange={(newPage) =>
        //   setTableObjects((prev) => ({ ...prev, page: newPage }))
        // }
        // onPageSizeChange={(newPageSize) =>
        //   setTableObjects((prev) => ({ ...prev, pageSize: newPageSize }))
        // }

        localeText={{
          MuiTablePagination: {
            labelRowsPerPage: "Items per page",
          },
          footerRowSelected: footerItemsSelectedLabel,
        }}
        slots={{
          pagination:
            table.property.hideFooter != null ? null : CustomPagination,
          toolbar: CustomToolbar,
          columnSortedAscendingIcon: CustomAscIcon,
          columnSortedDescendingIcon: CustomDscIcon,
          columnUnsortedIcon: CustomUnsortedIcon,
          noRowsOverlay: CustomNoRowsOverlay,
          baseCheckbox: CustomCheckbox,
          // row: customRowRenderer,
          footer: customFooter,
          columnMenu: customColumnMenu,
          columnMenuIcon: CustomColumnActionIcon,
          columnMenuFilterIcon: <></>,
          columnHeaderFilterIconButton: CustomColumnFilterIcon,
        }}
      />
    </Box>
  );
};

// DataTable.propTypes = {
//   table: PropTypes.object.isRequired,
//   tableData: PropTypes.array.isRequired,
//   isLoading: PropTypes.bool,
//   onClick: PropTypes.object,
//   bulkActions: PropTypes.func,
//   onSelectRow: PropTypes.func,
//   //! is used for pagination
//   // onChangePage: PropTypes.func,
//   filter: PropTypes.object,
//   onClickBulk: PropTypes.any,
//   rowSelection: PropTypes.any,
//   isRowSelectable: PropTypes.any,
//   getApplyQuickFilterFn: PropTypes.any,
//   customFields: PropTypes.any,
//   insertCustomFieldsIndex: PropTypes.any,
//   control: PropTypes.any,
//   sx: PropTypes.any,
//   totalSumColumns: PropTypes.any,
//   columnVisibilityModel: PropTypes.object,
// };
