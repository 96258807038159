import React, { useEffect, useState } from 'react'
import moment from 'moment';
import * as Icons from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { AiOutlineArrowRight } from 'react-icons/ai';

//slices
import { retrieveLogs, selectLogs } from '../../stores/slices/logSlices';
import { selectCompanyID } from '../../stores/slices/companyIDSlices';
import { selectCurrTenant } from '../../stores/slices/persistRolesSlices';
import { selectUser } from '../../stores/slices/userAuthSlices';

function ActivityLogsSync() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = selectUser();

    const companyID = selectCompanyID();
    const tenant = selectCurrTenant();
    const logs = selectLogs();
    
    const [userLogs, setUserLogs] = useState(logs);

    const logSection = [
        {
          Section: 'Roles',
          Color: '#32a85a',
          Icon: 'FaUnlockAlt',
        },
        {
          Section: 'Members',
          Color: '#327da8',
          Icon: 'FaAddressBook',
        },
        {
          Section: 'Clients',
          Color: '#a832a6',
          Icon: 'FaUsers',
        },
        {
          Section: 'Appointments',
          Color: '#f54272',
          Icon: 'FaCalendarAlt',
        }
      ]

    useEffect(() => {
      
      const CurrPage = 0;
      if(companyID && tenant){
        dispatch(retrieveLogs({ TenantID:tenant,CompanyID: companyID, GroupID:"",UserID: user?.uid, CurrPage, Action:[],MemberID: [], Section: [] }));
  
      }
      
    }, [])
    
    useEffect(() => {

        const array = [];

        if(logs){
          logs?.map((item) => {
            if(item.UserID === user?.uid){
              array.push(item)
            }
          })
      
          setUserLogs(array)
        }
        

    }, [logs])

  return (
    <div className='activityLogsContainer'>
      <div className='titleContainer'>
        <span>Recent Activity</span>
        <div className='actionContainer' onClick={() => {
          navigate('/ActivityLogs');
        }}>
          <span>View all</span>
          <AiOutlineArrowRight size={10} color="#bcbcbc"/>
        </div>
      </div>
      <div className='logMainContainer'>
        {userLogs && userLogs.length > 0 ?
          userLogs?.map((item,index) => {
              return (
                logSection.map(sec => {
                    if(sec.Section === item.Section){
                      const IconComponent = Icons[sec.Icon];
                      return(
                        <div className='logContainer' key={index}>
                            <div className='informationContainer'>
                                <div className='informationSubContainer'>
                                  <IconComponent size={18} style={{ color: sec.Color }} />
                                  <div className='information'>
                                      <span className='title'><span>You</span> have {item.Activity} <span style={{ color: sec.Color }}></span> In {item.Section}</span>
                                      <span>{moment(item.CreatedAt).format("YYYY-MM-DD | dddd")}</span>
                                  </div>
                                </div>
                                <span>{moment(item.CreatedAt).format("hh:mm")}</span>
                            </div>
                        </div>
                      )
                    }
                })
              )
            }
          )
          :
          <div className='emptyActivity'>
            <span>No Activty Found</span>
          </div>
          }
        </div>
    </div>
  )
}

export default ActivityLogsSync