import { createSlice, current } from "@reduxjs/toolkit";

import { useAppSelector } from "../../hooks/storeHooks";

const initialState = {
  //loading
  createTenantUserAuthLoading: false,
  createTenantDatabaseLoading: false,
  retrieveTenantLoading: false,
  retrieveAllTenantCountLoading: false,
  retrieveAvailableTenantLoading: false,
  updateTenantLoading: false,
  deleteTenantLoading: false,
  updateTenantDetailsLoading: false,
  sendSignupConfirmationMailLoading: false,
  sendTenantApprovalMailLoading: false,
  sendTenantNotificationMailLoading: false,
  sendTenantQuotaNotificationMailLoading: false,
  sendTenantUpgradeNotificationMailLoading: false,
  sendTenantExtendNotificationMailLoading: false,
  sendVerificationMailLoading: false,
  selectTenantLoading : false,

  //error
  createTenantUserAuthError: undefined,
  createTenantDatabaseError: undefined,
  retrieveTenantError: undefined,
  retrieveAllTenantCountError: undefined,
  retrieveAvailableTenantError: undefined,
  updateTenantError: undefined,
  deleteTenantError: undefined,
  updateTenantDetailsError: undefined,
  sendSignupConfirmationMailError: undefined,
  sendTenantApprovalMailError: undefined,
  sendTenantNotificationMailError: undefined,
  sendTenantQuotaNotificationMailError: undefined,
  sendTenantUpgradeNotificationMailError: undefined,
  sendTenantExtendNotificationMailError: undefined,
  sendVerificationMailError: undefined,
  handleSelectTenantError : undefined,

  //data
  tenant: {},
  tenantMail : "",
  tenantsCount: [],
  // tenant_agents: [], not used
  availableTenant: [],
  // currTenant: [], not used
};

const tenantSlice = createSlice({
  name: "tenants",
  initialState,
  reducers: {
    createTenantUserAuth(state) {
      state.createTenantUserAuthLoading = true;
    },

    createTenantUserAuthSuccess(state, { payload }) {
      const currentState = payload;
      state.tenant = currentState;
      state.createTenantUserAuthLoading = false;
    },

    createTenantUserAuthError(state, { payload }) {
      const errorState = payload;
      state.createTenantUserAuthError = errorState;
      state.createTenantUserAuthLoading = false;
    },

    createTenantDatabase(state) {
      state.createTenantDatabaseLoading = true;
    },

    createTenantDatabaseSuccess(state, { payload }) {
      const currentState = payload;
      state.tenant = currentState;
      state.createTenantDatabaseLoading = false;
    },

    createTenantDatabaseError(state, { payload }) {
      const errorState = payload;
      state.createTenantDatabaseError = errorState;
      state.createTenantDatabaseLoading = false;
    },

    retrieveTenant(state) {
      state.retrieveTenantLoading = true;
    },

    retrieveTenantSuccess(state, { payload }) {
      const currentState = payload;
      state.tenant = currentState;
      state.retrieveTenantLoading = false;
    },

    retrieveTenantError(state, { payload }) {
      const errorState = payload;
      state.retrieveTenantError = errorState;
      state.retrieveTenantLoading = false;
    },

    retrieveAllTenantCount(state) {
      state.retrieveAllTenantCountLoading = true;
    },

    retrieveAllTenantCountSuccess(state, { payload }) {
      const currentState = payload;
      state.tenantsCount = currentState;
      state.retrieveAllTenantCountLoading = false;
    },

    retrieveAllTenantCountError(state, { payload }) {
      const errorState = payload;
      state.retrieveAllTenantCountError = errorState;
      state.retrieveAllTenantCountLoading = false;
    },

    retrieveAvailableTenant(state) {
      state.retrieveAvailableTenantLoading = true;
    },

    retrieveAvailableTenantSuccess(state, { payload }) {
      const currentState = payload;
      state.availableTenant = currentState;
      state.retrieveAvailableTenantLoading = false;
    },

    retrieveAvailableTenantError(state, { payload }) {
      const errorState = payload;
      state.retrieveAvailableTenantError = errorState;
      state.retrieveAvailableTenantLoading = false;
    },

    updateTenant(state) {
      state.updateTenantLoading = true;
    },

    updateTenantSuccess(state, { payload }) {
      const currentState = current(state.tenant)
      const updatedState = currentState.map((item) => {
       if (item.TenantID === payload.TenantID){
        return {
          ...item,
          ...payload
        }
       }
       return item
      })
      state.tenant = updatedState;
      state.updateTenantLoading = false;
    },

    updateTenantError(state, { payload }) {
      const errorState = payload;
      state.updateTenantError = errorState;
      state.updateTenantLoading = false;
    },

    deleteTenant(state) {
      state.deleteTenantLoading = true;
    },

    deleteTenantSuccess(state, { payload }) {
      const currentState = current(state.tenant);
      const updatedState = currentState.filter((item) => TenantID !== payload.TenantID)
      state.tenant = updatedState;
      state.deleteTenantLoading = false;
    },

    deleteTenantError(state, { payload }) {
      const errorState = payload;
      state.deleteTenantError = errorState;
      state.deleteTenantLoading = false;
    },

    updateTenantDetails(state) {
      state.updateTenantDetailsLoading = true;
    },

    updateTenantDetailsSuccess(state,{payload}){
        const currentState = payload
        state.tenant = currentState
        state.updateTenantDetailsLoading = false;
    },

    updateTenantDetailsError(state,{payload}){
        const errorState = payload
        state.updateTenantDetailsError = errorState,
        state.updateTenantDetailsLoading = false;
    },

    sendSignupConfirmationMail(state) {
      state.sendSignupConfirmationMailLoading = true;
    },

    sendSignupConfirmationMailSuccess(state, { payload }) {
      const currentState = payload;
      state.tenantMail = currentState;
      state.sendSignupConfirmationMailLoading = false;
    },

    sendSignupConfirmationMailError(state, { payload }) {
      const errorState = payload;
      state.sendSignupConfirmationMailError = errorState;
      state.sendSignupConfirmationMailLoading = false;
    },

    sendTenantApprovalMail(state) {
      state.sendTenantApprovalMailLoading = true;
    },

    sendTenantApprovalMailSuccess(state, { payload }) {
      const currentState = payload;
      state.tenantMail = currentState;
      state.sendTenantApprovalMailLoading = false;
    },

    sendTenantApprovalMailError(state, { payload }) {
      const errorState = payload;
      state.sendTenantApprovalMailError = errorState;
      state.sendTenantApprovalMailLoading = false;
    },

    sendTenantNotificationMail(state) {
      state.sendTenantNotificationMailLoading = true;
    },

    sendTenantNotificationMailSuccess(state, { payload }) {
      const currentState = payload;
      state.tenantMail = currentState;
      state.sendTenantNotificationMailLoading = false;
    },

    sendTenantNotificationMailError(state, { payload }) {
      const errorState = payload;
      state.sendTenantNotificationMailError = errorState;
      state.sendTenantNotificationMailLoading = false;
    },

    sendTenantQuotaNotificationMail(state) {
      state.sendTenantQuotaNotificationMailLoading = true;
    },

    sendTenantQuotaNotificationMailSuccess(state, { payload }) {
      const currentState = payload;
      state.tenantMail = currentState;
      state.sendTenantQuotaNotificationMailLoading = false;
    },

    sendTenantQuotaNotificationMailError(state, { payload }) {
      const errorState = payload;
      state.sendTenantQuotaNotificationMailError = errorState;
      state.sendTenantQuotaNotificationMailLoading = false;
    },

    sendTenantUpgradeNotificationMail(state) {
      state.sendTenantUpgradeNotificationMailLoading = true;
    },

    sendTenantUpgradeNotificationMailSuccess(state, { payload }) {
      const currentState = payload;
      state.tenantMail = currentState;
      state.sendTenantUpgradeNotificationMailLoading = false;
    },

    sendTenantUpgradeNotificationMailError(state, { payload }) {
      const errorState = payload;
      state.sendTenantUpgradeNotificationMailError = errorState;
      state.sendTenantUpgradeNotificationMailLoading = false;
    },

    sendTenantExtendNotificationMail(state) {
      state.sendTenantExtendNotificationMailLoading = true;
    },

    sendTenantExtendNotificationMailSuccess(state, { payload }) {
      const currentState = payload;
      state.tenantMail = currentState;
      state.sendTenantExtendNotificationMailLoading = false;
    },

    sendTenantExtendNotificationMailError(state, { payload }) {
      const errorState = payload;
      state.sendTenantExtendNotificationMailError = errorState;
      state.sendTenantExtendNotificationMailLoading = false;
    },

    sendVerificationMail(state) {
      state.sendVerificationMailLoading = true;
    },

    sendVerificationMailSuccess(state, { payload }) {
      const currentState = payload;
      state.tenant = currentState;
      state.sendVerificationMailLoading = false;
    },

    sendVerificationMailError(state, { payload }) {
      const errorState = payload;
      state.sendVerificationMailError = errorState;
      state.sendVerificationMailLoading = false;
    },

    handleSelectTenant(state) {
      state.selectTenantLoading = true;
    },

    handleSelectTenantSuccess(state, { payload }) {
      state.selectTenantLoading = false;
    },

    handleSelectTenantError(state, { payload }) {
      const errorState = payload;
      state.handleSelectTenantError = errorState;
      state.selectTenantLoading = false;
    },
  },
});

export const {
  createTenantUserAuth,
  createTenantUserAuthSuccess,
  createTenantUserAuthError,
  createTenantDatabase,
  createTenantDatabaseSuccess,
  createTenantDatabaseError,
  retrieveTenant,
  retrieveTenantSuccess,
  retrieveTenantError,
  retrieveAllTenantCount,
  retrieveAllTenantCountSuccess,
  retrieveAllTenantCountError,
  retrieveAvailableTenant,
  retrieveAvailableTenantSuccess,
  retrieveAvailableTenantError,
  updateTenant,
  updateTenantSuccess,
  updateTenantError,
  deleteTenant,
  deleteTenantSuccess,
  deleteTenantError,
  updateTenantDetails,
  updateTenantDetailsSuccess,
  updateTenantDetailsError,
  sendSignupConfirmationMail,
  sendSignupConfirmationMailSuccess,
  sendSignupConfirmationMailError,
  sendTenantApprovalMail,
  sendTenantApprovalMailSuccess,
  sendTenantApprovalMailError,
  sendTenantNotificationMail,
  sendTenantNotificationMailSuccess,
  sendTenantNotificationMailError,
  sendTenantQuotaNotificationMail,
  sendTenantQuotaNotificationMailSuccess,
  sendTenantQuotaNotificationMailError,
  sendTenantUpgradeNotificationMail,
  sendTenantUpgradeNotificationMailSuccess,
  sendTenantUpgradeNotificationMailError,
  sendTenantExtendNotificationMail,
  sendTenantExtendNotificationMailSuccess,
  sendTenantExtendNotificationMailError,
  sendVerificationMail,
  sendVerificationMailSuccess,
  sendVerificationMailError,
  handleSelectTenant,
  handleSelectTenantError,
  handleSelectTenantSuccess,
} = tenantSlice.actions;

//export selector

// data
export const selectTenant = () =>
  useAppSelector((state) => state.tenants.tenant);

export const selectTenantsCount = () =>
  useAppSelector((state) => state.tenants.tenantsCount);

export const selectAvailableTenant = () =>
  useAppSelector((state) => state.tenants.availableTenant);

export const selectTenantMail = () =>
  useAppSelector((state) => state.tenantMail.tenant);

// loading
export const selectCreateTenantUserAuthLoading = () =>
  useAppSelector((state) => state.tenants.createTenantUserAuthLoading);

export const selectCreateTenantDatabaseLoading = () =>
  useAppSelector((state) => state.tenants.createTenantDatabaseLoading);

export const selectRetrieveTenantLoading = () =>
  useAppSelector((state) => state.tenants.retrieveTenantLoading);

export const selectRetrieveAllTenantCountLoading = () =>
  useAppSelector((state) => state.tenants.retrieveAllTenantCountLoading);

export const selectRetrieveAvailableTenantLoading = () =>
  useAppSelector((state) => state.tenants.retrieveAvailableTenantLoading);

export const selectUpdateTenantLoading = () =>
  useAppSelector((state) => state.tenants.updateTenantLoading);

export const selectDeleteTenantLoading = () =>
  useAppSelector((state) => state.tenants.deleteTenantLoading);

export const selectUpdateTenantDetailsLoading = () =>
  useAppSelector((state) => state.tenants.updateTenantDetailsLoading);

export const selectSendSignupConfirmationMailLoading = () =>
  useAppSelector((state) => state.tenants.sendSignupConfirmationMailLoading);

export const selectSendTenantApprovalMailLoading = () =>
  useAppSelector((state) => state.tenants.sendTenantApprovalMailLoading);

export const selectSendTenantNotificationMailLoading = () =>
  useAppSelector((state) => state.tenants.sendTenantNotificationMailLoading);

export const selectSendTenantQuotaNotificationMailLoading = () =>
  useAppSelector((state) => state.tenants.sendTenantQuotaNotificationMailLoading);

export const selectSendTenantUpgradeNotificationMailLoading = () =>
  useAppSelector((state) => state.tenants.sendTenantUpgradeNotificationMailLoading);

export const selectSendTenantExtendNotificationMailLoading = () =>
  useAppSelector((state) => state.tenants.sendTenantExtendNotificationMailLoading);

export const selectSendVerificationMailLoading = () =>
  useAppSelector((state) => state.tenants.sendVerificationMailLoading);

export const selectTenantLoading = () => 
  useAppSelector((state) => state.tenants.selectTenantLoading);

// error
export const selectCreateTenantUserAuthError = () =>
  useAppSelector((state) => state.tenants.createTenantUserAuthError);

export const selectCreateTenantDatabaseError = () =>
  useAppSelector((state) => state.tenants.createTenantDatabaseError);

export const selectRetrieveTenantError = () =>
  useAppSelector((state) => state.tenants.retrieveTenantError);

export const selectRetrieveAllTenantCountError = () =>
  useAppSelector((state) => state.tenants.retrieveAllTenantCountError);

export const selectRetrieveAvailableTenantError = () =>
  useAppSelector((state) => state.tenants.retrieveAvailableTenantError);

export const selectUpdateTenantError = () =>
  useAppSelector((state) => state.tenants.updateTenantError);

export const selectDeleteTenantError = () =>
  useAppSelector((state) => state.tenants.deleteTenantError);

export const selectUpdateTenantDetailsError = () =>
  useAppSelector((state) => state.tenants.updateTenantDetailsError);

export const selectSendSignupConfirmationMailError = () =>
  useAppSelector((state) => state.tenants.sendSignupConfirmationMailError);

export const selectSendTenantApprovalMailError = () =>
  useAppSelector((state) => state.tenants.sendTenantApprovalMailError);

export const selectSendTenantNotificationMailError = () =>
  useAppSelector((state) => state.tenants.sendTenantNotificationMailError);

export const selectSendTenantQuotaNotificationMailError = () =>
  useAppSelector((state) => state.tenants.sendTenantQuotaNotificationMailError);

export const selectSendTenantUpgradeNotificationMailError = () =>
  useAppSelector((state) => state.tenants.sendTenantUpgradeNotificationMailError);

export const selectSendTenantExtendNotificationMailError = () =>
  useAppSelector((state) => state.tenants.sendTenantExtendNotificationMailError);

export const selectSendVerificationMailError = () =>
  useAppSelector((state) => state.tenants.sendVerificationMailError);

//export reducer
const tenantReducer = tenantSlice.reducer

export default tenantReducer
