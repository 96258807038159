/* eslint-disable react/no-children-prop */
//react
import React, { useState, useEffect, useRef, Fragment } from "react";

//redux
import { useDispatch } from "react-redux";

//components or css
import Colors from "../../constants/Colors";
import C_Button from "../../components/C_Button";
import C_Search from "../../components/C_Search";
import C_Loading from "../../components/C_Loading";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import "./Clients.scss";

//libraries
import { ToastContainer } from "react-toastify";
import { Modal } from "bootstrap";
import { CSVLink } from "react-csv";
import { v4 } from "uuid";
import moment from "moment";
import Papa from "papaparse";
import "react-phone-input-2/lib/style.css";
import "react-quill/dist/quill.snow.css";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

//react icon
import {
  AiOutlineClose,
  AiFillCloseCircle,
  AiFillPlusSquare,
  AiFillCaretDown,
  AiFillCaretRight,
} from "react-icons/ai";
import {
  BiEditAlt,
  BiSort,
  BiChevronUp,
  BiChevronDown,
  BiDownload,
  BiSortAlt2,
} from "react-icons/bi";
import { FiDownload, FiUpload } from "react-icons/fi";
import { BsFonts, BsToggles } from "react-icons/bs";
import { RiListSettingsLine, RiUser2Line } from "react-icons/ri";
import { FaUserFriends } from "react-icons/fa";

// import { getCountryCallingCode } from "react-phone-number-input";

import {
  HiOutlineArrowsRightLeft,
  HiOutlineMagnifyingGlass,
  HiOutlineTrash,
  HiOutlineUserPlus,
  HiOutlineXMark,
} from "react-icons/hi2";
import {
  MdNavigateBefore,
  MdNavigateNext,
  MdOutlineTipsAndUpdates,
} from "react-icons/md";
import { child, get, ref, set } from "firebase/database";
import { db } from "../../firebaseInit";

import { toPng } from "html-to-image";
import { TbTableOptions } from "react-icons/tb";
import ReactFlow, {
  isEdge,
  Background,
  Controls,
  ControlButton,
} from "reactflow";
import C_DialogButton from "../../components/C_DialogButton";
import C_PopoutNoti from "../../components/C_PopoutNoti";
import ReactPaginate from "react-paginate";
import ClientTable from "./ClientTable";
import ClientGroup from "./ClientGroup";
import CommonFunc from "../../util/CommonFunc";
import ClientImportErrorTable from "./ClientImportErrorTable";


//slices
import { createLogs } from "../../stores/slices/logSlices";
import { retrieveCurrUsage } from "../../stores/slices/usageSlices";
import {  
  checkExistingClientData, 
  deleteClient, 
  getInitialClientData, 
  getProcessedClientData, 
  processImportData, 
  retrieveAllClientsCount, 
  retrieveClient,
  retrieveClientFilterData, 
  selectAvailableClientsGroup, 
  selectClient, 
  selectClientContext, 
  selectClientFilterData,
  selectClientsCount,
  selectDuplicatedClientData,
  selectGetInitialClientDataLoading,
  selectProcessedImportData,
  selectProcessedImportDataLoading,
  selectRetrieveClientLoading,
  selectUpdateClientDataLoading,
  setClientContext,
} from "../../stores/slices/clientSlices";
import { 
  createClientGroupLeader,
  deleteClientGroupLeader,
  retrieveAllClientGroupLeader, 
  selectAllClientGroupLeader
} from "../../stores/slices/clientGroupLeaderSlices";
import { 
  bulkCreateClientAgent,
  bulkRemoveClientAgent,
  createClientAgent,
  deleteClientAgent,
  getClientAgent,
  insertClientAgents,
  removeClientAgents,
  selectAllAgentHandler,
  selectAllSelectedAgentHandler
} from "../../stores/slices/clientAgentSlices";
import { selectCompanyID } from "../../stores/slices/companyIDSlices";
import { selectCurrSa, selectCurrTenant } from "../../stores/slices/persistRolesSlices";
import { selectCurrUserContext } from "../../stores/slices/companySlices";
import { selectUser } from "../../stores/slices/userAuthSlices";
import { addToLocalStorage, getFromLocalStorage } from "../../util/LocalStorage";
import { selectCurrUserClientGroupRoles } from "../../stores/slices/clientGroupRolesSlices";
import { ClientTransferModal } from "./ClientModal/ClientTransferModal";
import { ClientManageModal } from "./ClientModal/ClientManageModal";


function Clients(props) {
  const dbRef = ref(db);
  const assignLeaderModalRef = useRef();
  const assignHandlerModalRef = useRef();
  const exceedModalRef = useRef();
  const deleteClientRef = useRef();

  const dispatch = useDispatch();
  const isSuperAdmin = props.isSuperAdmin;
  const user = selectUser();

  const companyID = selectCompanyID();
  const tenant = selectCurrTenant();
  const currSa = selectCurrSa();
  const clientFilterData = selectClientFilterData();
  const clientsCount = selectClientsCount();
  const allClientGroupLeader = selectAllClientGroupLeader();
  const allSelectedAgentHandler = selectAllSelectedAgentHandler();
  const duplicatedClientData = selectDuplicatedClientData();
  const clientAgents = selectAllAgentHandler();
  const processedImportData = selectProcessedImportData();
  const processedImportDataLoading = selectProcessedImportDataLoading();
  const availableClientsGroup = selectAvailableClientsGroup();
  const client = selectClient();
  const currUserClientGroupRole = selectCurrUserClientGroupRoles();
  const Client_Manage = currUserClientGroupRole ? currUserClientGroupRole?.Client_Manage : false;
  const Client_Delete = currUserClientGroupRole ? currUserClientGroupRole?.Client_Delete : false;
  const Client_Export = currUserClientGroupRole ? currUserClientGroupRole?.Client_Export : false;
  const Client_Transfer = currUserClientGroupRole ? currUserClientGroupRole?.Client_Transfer : false;
  
  const [loadData, setLoadData] = useState(true);
  const [goManageTable, setGoManageTable] = useState(false);

  const {
    filteredClientData,
    filteredRowData,
    clientColumnArray,
    checkedClientState,
    clientRteIndex,
    tempClientMergedData,
    clientColArray,
    clientCsvTemplateHeader,
    clientCsvData,
    clientCsvHeader,
    selectedGroupValue,
    clientColData,
    exportHandlerType,
    filteredClientColumnArray
  } = selectClientContext();

  const rawCurrClientData = selectClient();

  const { currUsers, currUsage, currTotalUsage, currUserSA, currUserInvitation } = selectCurrUserContext();
  // const data = selectCurrUserContext();
  const [tempClient, setTempClient] = useState(filteredRowData);

  //boolstate
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [containsTrue, setContainsTrue] = useState(false);
  const [sortModal, setSortModal] = useState(false);
  const [filterColumn, setFilterColumn] = useState(false);
  const [isEmailFormat, setIsEmailFormat] = useState(true);
  const [isRequiredEmpty, setIsRequiredEmpty] = useState(false);
  const [isEditEmailFormat, setIsEditEmailFormat] = useState(true);
  const [isEditRequiredEmpty, setIsEditRequiredEmpty] = useState(false);

  //userData
  const [mixedClients, setMixedClients] = useState(0);
  const [selectedClient, setSelectedClient] = useState([]);
  const [mixedSelectedClients, setMixedSelectedClients] = useState([]);
  const [tempSelectedClient, setTempSelectedClient] = useState([]);
  const [tempMixedClient, setTempMixedClient] = useState([]);

  //3 of these was under a function previously  but got removed after
  const selectedDateColumn = "";
  const startDate = new Date();
  const endDate = new Date();

  //CSV
  const [file, setFile] = useState();
  const [importedData, setImportedData] = useState([]);
  const [errorImportArr, setErrorImportArr] = useState([]);
  const [processedImportedRow, setProcessedImportedRow] = useState([]);
  const [errorIndexArr, setErrorIndexArr] = useState([]);
  const [errorDBDuplicatedArr, setErrorDBDuplicatedArr] = useState([]);
  const [errorCSVDuplicatedArr, setErrorCSVDuplicatedArr] = useState([]);

  //Pagination
  const [currentPage, setCurrentPage] = useState(0);

  //sort filter search
  const [searchBy, setSearchBy] = useState("");
  const orderArray = [
    {
      id: "ASC",
      name: "Ascending",
    },
    {
      id: "DESC",
      name: "Descending",
    },
  ];

  const conditionArr = ["All", "Filled in", "Unfilled"]
  const [sortType, setSortType] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [selectedCol, setSelectedCol] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [notifyMessage, setNotifyMessage] = useState("");

  const [selectedLeader, setSelectedLeader] = useState([]);
  const [currentLeader, setCurrentLeader] = useState([]);
  const [checkSelectedLeader, setCheckSelectedLeader] = useState(false);
  const [searchLeaderText, setSearchLeaderText] = useState("");
  const [selectedMemberList, setSelectedMemberList] = useState([]);
  const [subgroupName, setSubgroupName] = useState("");
  const [showHandlerOptions, setShowHandlerOptions] = useState(false);

  const [selectedAgentHandler, setSelectedAgentHandler] = useState(null);
  const [loadFlowChart, setLoadFlowChart] = useState(false);
  const [expandClientSection, setExpandClientSection] = useState([]);
  const [expandMemberSection, setExpandMemberSection] = useState("");

  const [searchTextAssignee, setSearchTextAssignee] = useState("");
  const [showBulkAssignContainer, setShowBulkAssignContainer] = useState(false);
  const [showBulkRemoveContainer, setShowBulkRemoveContainer] = useState(false);

  const [clientGroupLeader, setClientGroupLeader] = useState([]);

  //from context to props
  const [dataFilterArr, setDataFilterArr] = useState([]);
  const [filterConditionArr, setFilterConditionArr] = useState([]);
  const [selectAllFilter, setSelectAllFilter] = useState([]);
  const [tempSelectedFilter, setTempSelectedFilter] = useState([]);
  const [rowFilterData, setRowFilterData] = useState([]);
  const [filterRow, setFilterRow] = useState(false);
  const [handlerFilterRow, setHandlerFilterRow] = useState(false);
  const [isCreatedByFilterOpen, setIsCreatedByFilterOpen] = useState(false);
  const [filterHandlerList, setFilterHandlerList] = useState([]);
  const [sharedHandlerList, setSharedHandlerList] = useState([]);
  const [tempSelectedMemberList, setTempSelectedMemberList] = useState([]);
  const [serialNumberFilterRow, setSerialNumberFilterRow] = useState(false);
  const [createdDateFilterRow, setCreatedDateFilterRow] = useState(false);

  const [isDupCheckValid, setIsDupCheckValid] = useState(true);

  //client transfer


  const manageClientModalRef = useRef();
  const bulkAssignRef = useRef();
  const bulkRemoveRef = useRef();
  const flowRef = useRef(null);
  // const newClientRef = useRef();
  const modalCsvRef = useRef();
  const filterModalRef = useRef();
  const importErrorRef = useRef();
  const csvInputRef = useRef();
  const tableScrollRef = useRef();
  const sortModalRef = useRef();
  const columnsModalRef = useRef();
  const exportModalRef = useRef();
  const clientTransferRef = useRef();
  const isEditLoading = selectUpdateClientDataLoading();

  const getInitialClientDataLoading = selectGetInitialClientDataLoading();

  useEffect(()=>{
      // dispatch(getProcessedClientData({client, availableClientsGroup, currUserSA, selectedGroupValue, isSA : isSuperAdmin, user}));
      retrieveClient({
        TenantID: tenant,
        CompanyID: companyID,
        CurrPage: 0,
        SortType: sortType,
        SortOrder: sortOrder,
        SearchText: searchText,
        SearchBy: searchBy,
        CurrGroup: selectedGroupValue?.GroupID,
        SelectedDateColumn: selectedDateColumn,
        StartDate: startDate,
        EndDate: endDate,
        DataFilterArr: dataFilterArr,
        UserID: user?.uid,
        isSA: currSa,
        availableClientsGroup,
        currUserSA,
        selectedGroupValue,
        user,
      })
      dispatch(retrieveAllClientsCount({
        TenantID: tenant,
        CompanyID: companyID,
        SearchText: searchText,
        SearchBy: searchBy,
        UserID: user?.uid,
        CurrGroup: selectedGroupValue?.GroupID,
        isSA: currSa,
        SelectedDateColumn: selectedDateColumn,
        StartDate: moment(startDate).format("YYYY-MM-DD"),
        EndDate: moment(endDate).format("YYYY-MM-DD"),
        DataFilterArr: dataFilterArr,
      }))
  }, [])

  useEffect(()=>{
   dispatch(retrieveAllClientsCount({
      TenantID: tenant,
      CompanyID: companyID,
      SearchText: searchText,
      SearchBy: searchBy,
      UserID: user?.uid,
      CurrGroup: selectedGroupValue?.GroupID,
      isSA: currSa,
      SelectedDateColumn: selectedDateColumn,
      StartDate: moment(startDate).format("YYYY-MM-DD"),
      EndDate: moment(endDate).format("YYYY-MM-DD"),
      DataFilterArr: dataFilterArr,
    }))
  },[client])

  useEffect(()=>{
    // console.log("clientData :", client.map(item => JSON.parse(item.ClientData)));
    dispatch(getProcessedClientData({client, availableClientsGroup, currUserSA, selectedGroupValue, isSA : isSuperAdmin, user}));
  }, [client, clientsCount])

  useEffect(()=>{
    let tempRowUser = [];
    if(checkedClientState.length > 0){
        checkedClientState.forEach((checked,index)=>{
            for (var y = 0; y < tempClientMergedData.length; y++) {
                if(tempClientMergedData[y].ClientID === checked){
                    if (tempClientMergedData[y] !== "Successfully update client" && tempClientMergedData[y] !== "Successfully import client" ) {
                        const alert = clientColumnArray.find(item => item.columnType === "Alert");
                        tempRowUser = [...tempRowUser, {
                            ...tempClientMergedData[y],
                            ...alert ? { [alert.columnName]: tempClientMergedData[y][alert.columnName]?.status ? "Completed" : "Pending" } : {}
                        }]
                      }
                }
              }
        })
        dispatch(setClientContext(({
          clientCsvData : tempRowUser
        })))
    }else{
        for (var y = 0; y < tempClientMergedData.length; y++) {
            if (tempClientMergedData[y] !== "Successfully update client" && tempClientMergedData[y] !== "Successfully import client" ) {
                    const alert = clientColumnArray.find(item => item.columnType === "Alert");
                    tempRowUser = [...tempRowUser, {
                        ...tempClientMergedData[y],
                        ...alert ? { [alert.columnName]: tempClientMergedData[y][alert.columnName]?.status ? "Completed" : "Pending" } : {}
                    }]
                }
          }
          dispatch(setClientContext(({
            clientCsvData : tempRowUser
          })))
    }
  }, [checkedClientState, clientCsvHeader])

  useEffect(()=>{

    if(clientCsvData.length > 0){
      if(exportHandlerType === "handlerName"){
        const updatedClientCsvData = clientCsvData.map((item, index) => {
          if (item.handlerIDs !== undefined) {
            // filteredClientData[index]?.handlerName.toString()
            const handlerObj = filteredClientData.find((client) => item.ClientID === client.ClientID);
  
            if(handlerObj){
              return {
                ...item,
                handlerIDs : handlerObj.handlerName
              }
            }
          }
          return item;
        });
        dispatch(setClientContext({
          clientCsvData : updatedClientCsvData
        }))
      } else {
        const updatedClientCsvData = clientCsvData.map((item, index) => {
          if (item.handlerIDs !== undefined) {
            // filteredClientData[index]?.handlerName.toString()
            const handlerName = filteredClientData.find((client) => item.ClientID === client.ClientID);
  
            if(handlerName){
              return {
                ...item,
                handlerIDs : item.handlerID
              }
            }
          }
          return item;
        });
  
       dispatch(setClientContext({
          clientCsvData : updatedClientCsvData
        }))
      }
    }
   

  }, [exportHandlerType, clientCsvHeader])

  useEffect(()=>{
    if(!processedImportDataLoading && Object.values(processedImportData).length > 0){
      setIsLoading(false);

      const errorImportArr = processedImportData.errorImportArray;
      const processedImportRow = processedImportData.processedImportedRows;
      const errorIndexArr = processedImportData.errorIndexArray;
      const errorCSVDuplicatedArr = processedImportData.errorCSVDuplicatedArray;
      const errorDBDuplicatedArr = processedImportData.errorDBDuplicatedArray;
      const notifyMessage = processedImportData.notifyMessage;

      if(errorImportArr.length > 0 || 
        errorIndexArr.length > 0 || 
        errorCSVDuplicatedArr.length > 0 || 
        errorDBDuplicatedArr.length > 0){
          // console.log(errorImportArr)
          // console.log(errorIndexArr)
          // console.log(errorCSVDuplicatedArr)
          // console.log(errorDBDuplicatedArr)
          showImportErrorModal();
          setTimeout(()=>{
            setErrorImportArr(errorImportArr);
            setProcessedImportedRow(processedImportRow);
            setErrorIndexArr(errorIndexArr);
            setErrorCSVDuplicatedArr(errorCSVDuplicatedArr);
            setErrorDBDuplicatedArr(errorDBDuplicatedArr);
          }, 2000)
      }

      if(notifyMessage !== ""){
        setNotifyMessage(notifyMessage);
      } 
    }
  },[processedImportDataLoading, processedImportData])

  useEffect(()=>{
    if(selectedGroupValue !== null && selectedGroupValue !== undefined){
      const path = `preferenceSettings/${user?.uid}/${tenant}/${companyID}/${selectedGroupValue?.GroupID}/columnDisplay`;

      const dbRef = child(ref(db), path);
      
      get(dbRef)
        .then((snapshot) => {
          if (snapshot.exists()) {
            let availableColumn = [];
            const savedColumn = snapshot.val();
            const existingColumn = clientColumnArray.map(col => col.columnID);

            savedColumn.forEach(col => {
              if(existingColumn.includes(col)){
                availableColumn.push(col);
              }
            })
            
            setSelectedCol(availableColumn);
          } else {
            setSelectedCol([]);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
      
    }
}, [selectedGroupValue, clientColumnArray, user])

  useEffect(() => {
    function handleClickOutside(event) {
        if (sortModalRef.current && !sortModalRef.current.contains(event.target)) {
            setSortModal(false);
        }
    }
    
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    
    // Unbind the event listener on cleanup
    return () => {
        document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [sortModalRef]);

  useEffect(() => {
      function handleClickOutside(event) {
          if (columnsModalRef.current && !columnsModalRef.current.contains(event.target)) {
              setFilterColumn(false);
          }
      }
      
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      
      // Unbind the event listener on cleanup
      return () => {
          document.removeEventListener("mousedown", handleClickOutside);
      };
  }, [columnsModalRef]);

  useEffect(()=>{
    if(!getInitialClientDataLoading){
      setTimeout(()=>{
        setLoadData(false);
      }, 2000)
    }
  }, [getInitialClientDataLoading])

  useEffect(()=>{
    dispatch(getInitialClientData({
      TenantID : tenant,
      UserID : user?.uid,
      CompanyID : companyID,
      isSA : currSa,
      user : user,
    }))
  },[goManageTable, tenant, user, companyID, currUserSA])

  useEffect(() => {
    if (notifyMessage) {
      setTimeout(() => {
        setNotifyMessage("");
      }, 2500);
    }
  }, [notifyMessage]);

  useEffect(() => {
    if (
      allClientGroupLeader !== undefined &&
      allClientGroupLeader !== null &&
      Array.isArray(allClientGroupLeader)
    ) {
      const newArray = allClientGroupLeader.filter((element) =>
        availableClientsGroup
          .map((clientGroup) => clientGroup.GroupID)
          .includes(element.GroupID)
      );
      setClientGroupLeader(newArray);
      setCurrentLeader(newArray);
      setSelectedLeader(newArray.map((item) => item.UserID));
    }
  }, [allClientGroupLeader, availableClientsGroup, companyID]);

  const [clientQuota, setClientQuota] = useState(0);
  const [clientUsage, setClientUsage] = useState(0);

  useEffect(() => {
    let array = [];

    clientColumnArray.map((item) => {
      array.push(item);
    });

    setSelectedCol(new Array(array.length).fill(false));
  }, [clientColumnArray]);

  useEffect(() => {
    if (currUsage && currTotalUsage !== undefined && currTotalUsage && currUserInvitation) {
      const filterQuota = currUsage?.find(
        (item) => item.SectionID === "s7"
      )?.Quota;
      const filterUsage = currTotalUsage?.find(
        (item) => item.SectionID === "s7"
      )?.Usage;
      setClientQuota(filterQuota);
      setClientUsage(filterUsage);
    } else {
      setClientQuota(0);
      setClientUsage(0);
    }
  }, [currUsage, currTotalUsage, currUserInvitation]);

  function refreshUsage() {
      dispatch(retrieveCurrUsage({ TenantID: tenant, CompanyID: companyID }))
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        bulkAssignRef.current &&
        !bulkAssignRef.current.contains(event.target)
      ) {
        setShowBulkAssignContainer(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Unbind the event listener on cleanup
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [bulkAssignRef]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        bulkRemoveRef.current &&
        !bulkRemoveRef.current.contains(event.target)
      ) {
        setShowBulkRemoveContainer(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Unbind the event listener on cleanup
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [bulkRemoveRef]);

  //modal
  const showTransferModal = () => {
    const modalEle = clientTransferRef.current;
    const bsModal = new Modal(modalEle, {
      backdrop: "static",
      keyboard: false,
    });
    bsModal.show();
  };

  const hideTransferModal = () => {
    const modalEle = clientTransferRef.current;
    const bsModal = Modal.getInstance(modalEle);
    bsModal.hide();
  };

  const showDeleteModal = () => {
    const modalEle = deleteClientRef.current;
    const bsModal = new Modal(modalEle, {
      backdrop: "static",
      keyboard: false,
    });
    bsModal.show();
  };

  const hideDeleteModal = () => {
    const modalEle = deleteClientRef.current;
    const bsModal = Modal.getInstance(modalEle);
    bsModal.hide();
  };

  const showExportModal = () => {
    const modalEle = exportModalRef.current;
    const bsModal = new Modal(modalEle, {
      backdrop: "static",
      keyboard: false,
    });
    bsModal.show();
  };

  const hideExportModal = () => {
    const modalEle = exportModalRef.current;
    const bsModal = Modal.getInstance(modalEle);
    bsModal.hide();
  };

  const showFilterModal = () => {
    const modalEle = filterModalRef.current;
    const bsModal = new Modal(modalEle, {
      backdrop: "static",
      keyboard: false,
    });
    bsModal.show();
  };

  const hideFilterModal = () => {
    const modalEle = filterModalRef.current;
    const bsModal = Modal.getInstance(modalEle);
    bsModal.hide();
  };

  const showExceedModalFunc = () => {
    const modalEle = exceedModalRef.current;
    const bsModal = new Modal(modalEle, {});
    bsModal.show();
  };

  const showManageClientModal = () => {
    const modalEle = manageClientModalRef.current;
    const bsModal = new Modal(modalEle, {
        backdrop: "static",
        keyboard: false,
    });
    bsModal.show();
    // setShowHandlerOptions(false);
    setSelectedMemberList([]);
};

const hideManageClientModal = () => {
    const modalEle = manageClientModalRef.current;
    const bsModal = Modal.getInstance(modalEle);
    bsModal.hide();
};

  const showImportErrorModal = () => {
    const modalEle = importErrorRef.current;
    const bsModal = new Modal(modalEle, {
      backdrop: "static",
      keyboard: false,
    });
    bsModal.show();
  };

  const hideImportErrorModal = () => {
      const modalEle = importErrorRef.current;
      const bsModal = Modal.getInstance(modalEle);
      bsModal.hide();
  };

  const resetCSVFileInput = () => {
    setFile(null);
    setSubgroupName("");
    setErrorImportArr([])
    setProcessedImportedRow([])
    setErrorIndexArr([])

    if(csvInputRef.current){
      csvInputRef.current.value = null;
    }
  }

  const showCsvModal = () => {
    const modalEle = modalCsvRef.current;
    const bsModal = new Modal(modalEle, {
      backdrop: "static",
      keyboard: false,
    });
    bsModal.show();
    setSelectedMemberList([]);
    resetCSVFileInput();
    setErrorImportArr([]);
    setProcessedImportedRow([]);
    setErrorIndexArr([]);
    setErrorDBDuplicatedArr([]);
    setErrorCSVDuplicatedArr([]);
  };

  const hideCsvModal = () => {
    const modalEle = modalCsvRef.current;
    const bsModal = Modal.getInstance(modalEle);
    bsModal.hide();
    resetCSVFileInput();
  };

  const showAssignLeaderModal = () => {
    const modalEle = assignLeaderModalRef.current;
    const bsModal = new Modal(modalEle, {
      backdrop: "static",
      keyboard: false,
    });
    bsModal.show();
  };

  const hideAssignLeaderModal = () => {
    const modalEle = assignLeaderModalRef.current;
    const bsModal = Modal.getInstance(modalEle);
    bsModal.hide();
  };

  async function retrieveHandlers() {
    if (
      Array.isArray(checkedClientState) &&
      checkedClientState &&
      checkedClientState.length > 0
    ) {
      dispatch(getClientAgent({ TenantID: tenant, ClientID: checkedClientState }))
    }

  }

  async function showAssignHandlerModal() {
    setLoadFlowChart(true);
    const modalEle = assignHandlerModalRef.current;
    const bsModal = new Modal(modalEle, {
      backdrop: "static",
      keyboard: false,
    });
    bsModal.show();
    try {
          
        retrieveHandlers()
        setTimeout(() => {
          setLoadFlowChart(false);
        }, 2000);
      
    } catch (err) {
      console.log(err);
    }
  }

  async function handleInsertClientAgent(addHandlerList) {
    dispatch(insertClientAgents({TenantID: tenant, addHandlerList : addHandlerList}));
  }

  async function handleDeleteClientAgent(removeHandlerList, clientList) {
    const removeList = removeHandlerList.map(data => data.uid)
    dispatch(removeClientAgents({TenantID: tenant, removeHandlerList : removeList, clientList : clientList}));
  }

  const hideAssignHandlerModal = () => {
    const modalEle = assignHandlerModalRef.current;
    const bsModal = Modal.getInstance(modalEle);
    bsModal.hide();
  };

  useEffect(() => {
    const newNodes = allSelectedAgentHandler?.nodes?.map((node) => {
      const getName =
        node?.typeOfData === "CLIENT"
          ? tempClient?.find((client) => client?.ClientID === node?.id)
              ?.ClientData[0].rowValue ?? "Invalid Data"
          : currUsers.find((member) => member?.uid === node?.id)?.displayName ??
            "Invalid Data";

      return {
        ...node,
        sourcePosition: node?.typeOfData === "CLIENT" ? "right" : "left",
        targetPosition: node?.typeOfData === "CLIENT" ? "right" : "left",
        data: {
          label: getName,
        },
        name: getName,
      };
    });

    const newHandler = allSelectedAgentHandler?.handler?.map((handle) => {
      const members = handle.Handler.map((handlerItem) => {
        const member = currUsers.find(
          (member) => member.uid === handlerItem?.UserID
        );
        return {
          uid: handlerItem?.UserID,
          displayName: member?.displayName ?? "Invalid Member",
          ClientAgentID: handlerItem?.ClientAgentID,
        };
      });
      return {
        ClientName:
          tempClient?.find((client) => client?.ClientID === handle?.Client)
            ?.ClientData[0].rowValue ?? "Invalid Data",
        ClientID: handle?.Client,
        Handler: members,
      };
    });

    const newEdges = allSelectedAgentHandler?.edges?.map((edge) => {
      return {
        ...edge,
        source: edge?.source,
        sourceName:
          currUsers.find((member) => member?.uid === edge?.source)
            ?.displayName ?? "Invalid Data",
        target: edge?.target,
        targetName:
          tempClient?.find((client) => client?.ClientID === edge?.target)
            ?.ClientData[0].rowValue ?? "Invalid Data",
      };
    });

    const newData = {
      nodes: newNodes,
      handler: newHandler,
      edges: newEdges,
    };

    setSelectedAgentHandler(newData);
  }, [allSelectedAgentHandler, tempClient, currUsers, clientAgents]);

  useEffect(() => {
    var array = [];

    filteredRowData.map((item) => {
      array.push(item);
    });

    setTempClient(array);
  }, [filteredRowData]);

  //END GROUP

  useEffect(() => {
    var array = [];

    checkedClientState.map((item) => {
      if (item) {
        array.push(item);
      }
    });

    if (array.length !== 0) {
      setContainsTrue(true);
    } else {
      setContainsTrue(false);
    }
  }, [checkedClientState]);

  useEffect(() => {
    let tempHeaderList = [];
    let data = [];
    clientColumnArray
      // .filter((item)=>{
      //   if(!isSuperAdmin){
      //     const ViewableColumn = currUserClientGroupRole ? currUserClientGroupRole.ViewableColumn : [];
      //     const isViewable = ViewableColumn.includes(item.columnID);
      //     if(isViewable){
      //       return item;
      //     }
      //   } else {
      //     return item
      //   }
       
       
      // })
      .filter((item, index) => {
        const isMatch = selectedCol.includes(item.columnID)
 
        if(selectedCol.length > 0){
          if(isMatch){
            return item
          }
        } 

        if(selectedCol.length < 1){
          return item
        }
      })
      .map((item) => {
        if (item.columnName !== undefined || item.columnType !== undefined) {
          if (item?.option !== undefined) {
            if (item.columnType === "Dropdown") {
              tempHeaderList.push({
                columnID: item.columnID,
                columnName: item.columnName,
                columnType: item.columnType,
                width: item.width,
                required: item.required,
                duplicate: item.duplicate,
                privilege: item.privilege,
                columnIndex: item.columnIndex,
                option: item.option,
                otherOption:
                  item.otherOption === undefined ? false : item.otherOption,
                optionChoices:
                  item.optionChoices === undefined
                    ? "Default"
                    : item.optionChoices,
              });
            } else {
              tempHeaderList.push({
                columnID: item.columnID,
                columnName: item.columnName,
                columnType: item.columnType,
                width: item.width,
                required: item.required,
                duplicate: item.duplicate,
                privilege: item.privilege,
                columnIndex: item.columnIndex,
                option: item.option,
              });
            }
          } else if (item.columnType === "Date") {
            tempHeaderList.push({
              columnID: item.columnID,
              columnName: item.columnName,
              columnType: item.columnType,
              width: item.width,
              required: item.required,
              duplicate: item.duplicate,
              privilege: item.privilege,
              columnIndex: item.columnIndex,
              dateType: item.dateType,
            });
          } else {
            tempHeaderList.push({
              columnID: item.columnID,
              columnName: item.columnName,
              columnType: item.columnType,
              width: item.width,
              required: item.required,
              duplicate: item.duplicate,
              privilege: item.privilege,
              columnIndex: item.columnIndex,
            });
          }
        }
      });

    // setClientColArray({ type: "COL_ARRAY", payload: tempHeaderList });
    dispatch(setClientContext({clientColArray : tempHeaderList}));
  }, [selectedCol]);

  useEffect(() => {
    if (file) {
      Papa.parse(file, {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        complete: function (results) {
          printPapaObject(results);
        },
      });
    }
  }, [file]);

  const handleOnChangeFile = (e) => {
    setFile(e.target.files[0]);
  };

  function printPapaObject(papa) {
    let tempdata = [];
    for (var i = 0; i < papa.data.length; i++) {
      tempdata.push(papa.data[i]);
    }
    setImportedData(tempdata);
  }

  // useEffect(() => {
  //   // setTempClientMergedData({ type: "MERGED_CLIENT", payload: filteredClientData });
  //   dispatch(setClientContext({tempClientMergedData : filteredClientData}));
  // }, [filteredClientData, client])

  // useEffect(()=>{
  //   //conditionArr
  //   const conditionArr = Object.keys(rowFilterData).map(keys => {
  //     return {[keys] : "All"}
  //   })

  //   const reducedData = conditionArr.reduce((result, item) => {
  //     const key = Object.keys(item)[0];
  //     const value = item[key];
  //     if (!result[key]) {
  //         result[key] = [];
  //     }
  //     result[key] = value;
  //     return result;
  // }, {});

  //   setFilterConditionArr(reducedData);

  //   //select all
  //   const keysArray = Object.keys(reducedData);
  //   const newArray = keysArray.map(key => ({ [key]: false }));
  //   const reducedSelectAllFilter = newArray.reduce((result, item) => {
  //     const key = Object.keys(item)[0];
  //     const value = item[key];
  //     if (!result[key]) {
  //         result[key] = [];
  //     }
  //     result[key] = value;
  //     return result;
  // }, {});
  // console.log(reducedSelectAllFilter)
  //   setSelectAllFilter(reducedSelectAllFilter);

  // },[rowFilterData])

  // useEffect(() => {
  //   const selectFilter = {...selectAllFilter};
  //   Object.keys(rowFilterData).map(keys => {
  //     const columnIndex = clientColumnArray?.find(data => data.columnName === keys)?.columnIndex;
  //     const filterArr = dataFilterArr.filter(data => data.filterType === columnIndex && (data.filterValue !== "Unfilled" && data.filterValue !== "Filled in"));

  //     if(rowFilterData[keys].length === filterArr.length){
  //       selectFilter[keys] = true;
  //     } else {
  //       selectFilter[keys] = false;
  //     }
  //   })

  //   setSelectAllFilter(selectFilter);
  // }, [dataFilterArr])

  useEffect(()=>{
    if(clientFilterData !== undefined && clientFilterData !== null){
      let newData = {};
      for (const key in clientFilterData) {
          // Check if the value is an array and if it contains objects
          if (Array.isArray(clientFilterData[key]) && clientFilterData[key].some(item => typeof item === 'object' && item !== null)) {
              // If it contains objects, skip adding it to the new object
              continue;
          }
          // Add the key-value pair to the new object
          newData[key] = clientFilterData[key];
      }
      setRowFilterData(newData)
    }
  }, [clientFilterData])

  function retrieveClientFunction(retrieveFunc) {
    setLoadData(true);
    return Promise.all(retrieveFunc);
  }

  function onHandlePageChange(currPage) {    
      dispatch(
        retrieveClient({
          TenantID: tenant,
          CompanyID: companyID,
          CurrPage: currPage,
          SortType: sortType,
          SortOrder: sortOrder,
          SearchText: searchText,
          SearchBy: searchBy,
          CurrGroup: selectedGroupValue?.GroupID,
          SelectedDateColumn: selectedDateColumn,
          StartDate: startDate,
          EndDate: endDate,
          DataFilterArr: dataFilterArr,
          UserID: user?.uid,
          isSA: currSa,
        })
      )
        setTimeout(() => {
          setCurrentPage(currPage);
          setLoadData(false);
        }, 1000);
  }

  function handleSortClientData() {
    dispatch(
      retrieveClient({
        TenantID: tenant,
        CompanyID: companyID,
        CurrPage: 0,
        SortType: sortType,
        SortOrder: sortOrder,
        SearchText: searchText,
        SearchBy: searchBy,
        UserID: user?.uid,
        CurrGroup: selectedGroupValue?.GroupID,
        isSA: currSa,
        SelectedDateColumn: selectedDateColumn,
        StartDate: startDate,
        EndDate: endDate,
        DataFilterArr: dataFilterArr,
      })
    )
    setTimeout(() => {
      setCurrentPage(0);
      setLoadData(false);
    }, 1000);
  }

  function handleSearchQuery(){

      dispatch(
        retrieveAllClientsCount({
          TenantID: tenant,
          CompanyID: companyID,
          SearchText: searchText,
          SearchBy: "",
          UserID: user?.uid,
          CurrGroup: selectedGroupValue?.GroupID,
          isSA: currSa,
          SelectedDateColumn: "",
          StartDate: "",
          EndDate: "",
          DataFilterArr: dataFilterArr,
        })
      )

      dispatch(
        retrieveClient({
          TenantID: tenant,
          CompanyID: companyID,
          CurrPage: 0,
          SortType: sortType,
          SortOrder: sortOrder,
          SearchText: searchText,
          SearchBy: "",
          UserID: user?.uid,
          CurrGroup: selectedGroupValue?.GroupID,
          isSA: currSa,
          SelectedDateColumn: "",
          StartDate: "",
          EndDate: "",
          DataFilterArr: dataFilterArr,
        })
      )
   
      setTimeout(() => {
        setCurrentPage(0);
        setLoadData(false);
      },1000)

  }

  function handleClearQuery(){
    dispatch(
      retrieveAllClientsCount({
        TenantID: tenant,
        CompanyID: companyID,
        SearchText: "",
        SearchBy: "",
        UserID: user?.uid,
        CurrGroup: selectedGroupValue?.GroupID,
        isSA: currSa,
        SelectedDateColumn: "",
        StartDate: "",
        EndDate: "",
        DataFilterArr: dataFilterArr,
      })
    )

    dispatch(
      retrieveClient({
        TenantID: tenant,
        CompanyID: companyID,
        CurrPage: 0,
        SortType: sortType,
        SortOrder: sortOrder,
        SearchText: "",
        SearchBy: "",
        UserID: user?.uid,
        CurrGroup: selectedGroupValue?.GroupID,
        isSA: currSa,
        SelectedDateColumn: "",
        StartDate: "",
        EndDate: "",
        DataFilterArr: dataFilterArr,
      })
    )
    
    setTimeout(() => {
      setSearchText("");
      setSearchBy("");
      setCurrentPage(0);
      setLoadData(false)
    },1000)

  }

  function handleClearSortClientData() {

      dispatch(
        retrieveClient({
          TenantID: tenant,
          CompanyID: companyID,
          CurrPage: 0,
          SortType: "",
          SortOrder: "",
          SearchText: searchText,
          SearchBy: searchBy,
          UserID: user?.uid,
          CurrGroup: selectedGroupValue?.GroupID,
          isSA: currSa,
          SelectedDateColumn: selectedDateColumn,
          StartDate: startDate,
          EndDate: endDate,
          DataFilterArr: dataFilterArr,
        })
      ),
      setTimeout(() => {
        setSortOrder("");
        setSortType("");
        setCurrentPage(0);
        setLoadData(false);
      }, 1000);
     
  }

  async function handleCreateClientAgentSingle({ ClientData }) {
    const promises = selectedMemberList.map((handler) => {
      const body = {
        ClientAgentID: v4(),
        GroupID: selectedGroupValue?.GroupID,
        UserID: handler?.uid,
        ClientID: ClientData?.ClientID,
      };
      return dispatch(createClientAgent({ TenantID: tenant, data: body }));
    });
    await Promise.resolve(promises);
  }

  //GROUP FUNCTION
  const selectedGroup = (groupValue) => {

    setDataFilterArr([]);
    setTempSelectedFilter([]);
    const selectedGroupData = availableClientsGroup?.find(
      (item) => item.GroupID === groupValue
    );
    dispatch(setClientContext({
      selectedGroupValue : selectedGroupData
    }))
    dispatch(getInitialClientData({
      TenantID : tenant,
      CompanyID : companyID,
      UserID : user?.uid,
      isSA : currSa,
      user : user,
      CurrGroup: selectedGroupData?.GroupID,
      CurrGroupData : selectedGroupData
    }))

    addToLocalStorage("GROUP_ID", groupValue)

    setLoadData(true);
    setSearchText("");
    setSearchBy("");
    
  };

  async function processCSVData(){

    hideCsvModal();
    setIsLoading(true);
    const {filteredNullImportData, allObjectsValid} = importedData.reduce((acc, curr) => {

      const hasAllNull = Object.values(curr).every(value => value === null)
      if(!hasAllNull){
        acc.filteredNullImportData.push(curr);
        const hasData = Object.values(curr).some(value => typeof value === 'string' && value.trim() !== '');
        const hasEmptyString =  Object.values(curr).every(value => (typeof value === "string" && value.trim() === ''))
        acc.allObjectsValid = !hasAllNull && !hasEmptyString && hasData;
      }

      return acc;
    }, {filteredNullImportData : [], allObjectsValid : true})

    if (filteredNullImportData.length > 0 && allObjectsValid) {
      dispatch(processImportData({
        clientColumnArray : clientColumnArray,
        currUsers : currUsers,
        importedData : filteredNullImportData,
        isAuthorized : isSuperAdmin || clientGroupLeader.some((element) => element.UserID === user.uid),
        tenant : tenant,
        companyID : companyID,
        groupID : selectedGroupValue?.GroupID,
        currSa : currSa,
        user : user,
        isSuperAdmin : isSuperAdmin,
        searchText : searchText,
        sortOrder : sortOrder,
        sortType : sortType,
        dataFilterArr : dataFilterArr,
        clientGroupLeader : clientGroupLeader,
        setNotifyMessage : setNotifyMessage
      }))
    };
  }
    

  const uploadCsv = async () => { 
    let data = [];
    if(file) {
      processCSVData()
    }
  };

  //CRUD

  const handleNewClient = () => {
    setMixedClients(0); 
    setIsEdit(false); 
    setIsRequiredEmpty(false);
    setIsEmailFormat(true);
    const tempColumn = [...clientColumnArray];
    const selectedData =  tempColumn.sort((a,b)=>a.columnIndex-b.columnIndex).map(item => {
      let rowValue = "";
      switch (item.columnType) {
        case "Form":
          rowValue = "-";
          break;
        case "Checkboxes":
          rowValue = [];
          break;
        case "Rich Text Editor":
          rowValue = "";
          break;
        case "Switch":
          rowValue = true;
          break;
        case "ClientPassword":
          rowValue = "abc123";
          break;
        case "Alert":
          rowValue = { status: false, expiry: null };
          break;
        case "CreatedAt":
          rowValue = moment().format("YYYY-MM-DD HH:mm:ss");
          break;
      }
      return { rowID: v4(), columnID: item.columnID, rowValue };
    });
    setSelectedClient(selectedData);
  };

  const onHandleSetEdit = async () => {

    let checked = [];
    let tempTableData = [];
    let selectedHandlerData = [];
    const tempColumn = [...clientColumnArray];

    const isAuthorized = isSuperAdmin || clientGroupLeader.some(element=>element.UserID === user.uid);
    setIsEdit(true);
    setIsEditRequiredEmpty(false);
    setIsEditEmailFormat(true);
    setIsEmailFormat(true);

    checkedClientState.map((item, index) => {
      if (item) {
        checked.push(item);
      }
    })

    if (checked.length > 1) {
      setMixedClients(checked.length);

      let array = [];
      let currClient = [];
      let ClientID = [];
      let sharedHandler = [];

      checkedClientState.map((item, index) => {
        currClient.push([]);
      })

      checkedClientState.map((item, index) => {

        let clientObj = tempClient.find(element=>element.ClientID === item)
        const rawData = rawCurrClientData.filter((raw) => raw.ClientID === item);
        const handlerData = rawData.length > 0 ? rawData[0].handlerIDs : [];
        selectedHandlerData = isAuthorized ? currUsers.filter((member) =>
          handlerData?.includes(member.uid)
        ) : [];

        if (clientObj !== undefined) 
        {
          tempColumn.sort((a,b)=>a.columnIndex-b.columnIndex).map((col, colIndex) => {
            clientObj.ClientData.map((row, rowIndex) => {
              if (col.columnID === row.columnID) 
              {
                currClient[index].push(row)
              }
            })
          })

          ClientID.push(clientObj.ClientID)
        }
      })

        //bulk update handler shared list (add + remove)
      sharedHandler = currUsers.map(member => {
        return {uid: member.uid, value: null, data: member}
      });

      setSharedHandlerList(sharedHandler)

      tempColumn.sort((a,b)=>a.columnIndex-b.columnIndex).map((item) => {
        if (item.columnType === "Form") {
          array.push({ rowID: v4(), columnID: item.columnID, rowValue: "-" })
        } else if (item.columnType === "Alert") {
          array.push({ rowID: v4(), columnID: item.columnID, rowValue: null })
        } else if (item.columnType === "Checkboxes") {
          array.push({ rowID: v4(), columnID: item.columnID, rowValue: [] })
        } else if (item.columnType === "Rich Text Editor") {
          array.push({ rowID: v4(), columnID: item.columnID, rowValue: "" })
        } else if (item.columnType === "Switch") {
          array.push({ rowID: v4(), columnID: item.columnID, rowValue: true })
        } else if (item.columnName === "ClientPassword") {
          array.push({ rowID: v4(), columnID: item.columnID, rowValue: "abc123" })
        } else if (item.columnName === "ClientEmail") {
          array.push({ rowID: v4(), columnID: item.columnID, rowValue: "-" })
        } else if (item.columnName === "CreatedAt") {
          array.push({ rowID: v4(), columnID: item.columnID, rowValue: moment(new Date()).format("YYYY-MM-DD HH:mm:ss") })
        } else {
          array.push({ rowID: v4(), columnID: item.columnID, rowValue: "" })
        }
      })
      // console.log(array)
      // console.log(currClient)
      setSelectedMemberList(selectedHandlerData);
      setSelectedClient(array)
      setMixedSelectedClients(currClient)
      
      const originalData = JSON.stringify(currClient);
      setTempMixedClient(originalData)

      const originalHandlerData = JSON.stringify(selectedHandlerData);
      setTempSelectedMemberList(originalHandlerData);
    }
    else {
      const tempTableData = [];
      await Promise.all(
        checkedClientState.map(async (item) => {
          const clientObj = tempClient.find((element) => element.ClientID === item);
          const rawData = rawCurrClientData.filter((raw) => raw.ClientID === item);
          const handlerData = rawData.length > 0 ? rawData[0].handlerIDs : [];
          if(handlerData && currUsers){
            selectedHandlerData = isAuthorized ? currUsers.filter((member) =>
            handlerData.includes(member.uid)
            ) : [];
            if (clientObj) {
              await Promise.all(
                tempColumn
                  .sort((a, b) => a.columnIndex - b.columnIndex)
                  .map(async (col) => {
                    await Promise.all(
                      clientObj.ClientData.map((row) => {
                        if (col.columnID === row.columnID) {
                          tempTableData.push(row);
                        }
                      })
                    );
                  })
              );
            }
          }
        })
      );

      // console.log(tempTableData)
      setSelectedMemberList(selectedHandlerData);
      setSelectedClient(tempTableData);

      const originalData = JSON.stringify(tempTableData);
      setTempSelectedClient(originalData)

      const originalHandlerData = JSON.stringify(selectedHandlerData);
      setTempSelectedMemberList(originalHandlerData);
    }
  };


  const removeClient = () => {
    setIsLoading(true);

    let body = [];
    let logsNameList = [];

    checkedClientState.map((item) => {
      if (item) {
        body.push(item);
      }
    });

    filteredClientData.map((client) => {
      if (checkedClientState.includes(client.ClientID)) {
        logsNameList.push([{ name: client.ClientName }]);
      }
    });

    if (checkedClientState.length === 1) {
      const clientObj = filteredClientData.find(
        (element) => element.ClientID === checkedClientState[0]
      );
      if (clientObj !== undefined) {
        const targetHandler = clientAgents.filter(
          (element) => element.ClientID === clientObj.ClientID
        ).map((item)=> item.Handler.map((handler)=> handler.UserID)).flat();

        dispatch(deleteClient({ 
          TenantID: tenant, 
          data: body, 
          removeHandlerList : targetHandler, 
          clientList : checkedClientState,
          GroupID: selectedGroupValue ? 
          selectedGroupValue?.GroupID 
          : availableClientsGroup[0]?.GroupID, 
          UserID : user?.uid, 
          isSA : currSa,
        }));
          const ActivityLogsID = v4();
          const UserID = user?.uid;
          const CompanyID = companyID;
          const GroupID = selectedGroupValue?.GroupID;
          const Title = "A client has been deleted";
          const Activity = `deleted a client (${clientObj.ClientName})`;
          const Action = "delete";
          const Section = "Clients";
          const Keyword = clientObj.ClientName;
          const CreatedAt = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
          const data = {
            ActivityLogsID,
            UserID,
            CompanyID,
            GroupID,
            Title,
            Activity,
            Action,
            Section,
            Keyword,
            CreatedAt,
          };

          dispatch(createLogs({ TenantID: tenant, data: data }));
          
            setTimeout(() => {
              refreshUsage();
              dispatch(setClientContext({
                checkedClientState : [],
              }))
              setIsLoading(false);
              setNotifyMessage(CommonFunc.notify("delete"));
              setLoadData(false);
            }, 1000);
      }
    } else {
      const targetHandler = clientAgents.filter(
        (element) => checkedClientState.includes(element.ClientID)
      ).map((item)=> item.Handler.map((handler)=> handler.UserID)).flat();

      dispatch(deleteClient({ 
        TenantID: tenant, 
        data: body, 
        removeHandlerList: targetHandler, 
        clientList: checkedClientState,
        GroupID: selectedGroupValue ? 
          selectedGroupValue?.GroupID 
          : availableClientsGroup[0]?.GroupID, 
          UserID : user?.uid, 
          isSA : currSa,
      }))
        const ActivityLogsID = v4();
        const UserID = user?.uid;
        const CompanyID = companyID;
        const GroupID = selectedGroupValue?.GroupID;
        const Title = "List of clients have been deleted";
        const Activity = `removed ${logsNameList.length} clients`;
        const Action = "bulk_delete";
        const Section = "Clients";
        const Keyword = JSON.stringify(logsNameList);
        const CreatedAt = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
        const data = {
          ActivityLogsID,
          UserID,
          CompanyID,
          GroupID,
          Title,
          Activity,
          Action,
          Section,
          Keyword,
          CreatedAt,
        };
        dispatch(createLogs({ TenantID: tenant, data: data }))

        setTimeout(() => {
          setLoadData(false);
          dispatch(setClientContext({
            checkedClientState : [],
          }))
          setIsLoading(false);
          setNotifyMessage(CommonFunc.notify("delete"));
        }, 1000);
       
    }
  };

  //FILTER, SORT
  const openFilterColModal = () => {
    setFilterColumn(!filterColumn);
    setSortModal(false);
  };

  const openSortModal = () => {
    setSortModal(!sortModal);
    setFilterColumn(false);
  };

  function deselectAllColumns() {
    set(ref(db, 'preferenceSettings/' + user.uid + '/' + tenant + '/' + companyID + '/' + selectedGroupValue.GroupID+ '/columnDisplay'), []);
    setSelectedCol([]);
  }

  function handleSelectedCol(e, columnID) {
    const colArr = [...selectedCol];

    if(e.target.checked) {
        colArr.push(columnID);
    } else {  
        const index = colArr.findIndex(data => data === columnID);
        colArr.splice(index, 1);
    }

    const settings = {
      columnDisplay : colArr,
    }
    set(ref(db, 'preferenceSettings/' + user.uid + '/' + tenant + '/' + companyID + '/' + selectedGroupValue.GroupID), settings);
    setSelectedCol(colArr)
  }

  async function getCurrCompanyID() {

    dispatch(setClientContext({selectedGroupID : selectedGroupValue?.GroupID}));
      // setSelectedGroupID({
      //   type: "SELECT_CLIENT_GROUP_ID",
      //   payload: selectedGroupValue?.GroupID,
      // })
    
  }

  function handleSelectHandler(param) {
    const newSelectedArray = [...selectedMemberList, param];
    setSelectedMemberList(newSelectedArray);
  }

  function handleDeselectHandler(param) {
    const newSelectedArray = selectedMemberList.filter(
      (item) => item?.uid !== param?.uid
    );
    setSelectedMemberList(newSelectedArray);
  }

  async function handleManageClient() {
    await getCurrCompanyID().then(() => {
      setGoManageTable(!goManageTable);
    });
  }
  function handleSelectLeader(param) {
    const newSelectedArray = [...selectedLeader, param];
    setSelectedLeader(newSelectedArray);
  }

  function handleDeSelectLeader(param) {
    const newSelectedArray = selectedLeader.filter((item) => item !== param);
    setSelectedLeader(newSelectedArray);
  }

  async function handleUpdateLeaderList(originalArray) {
    const promisesDelete = originalArray
      .filter((item) => !selectedLeader.includes(item.UserID))
      ?.map((lead) => {
        return dispatch(
          deleteClientGroupLeader({
            TenantID: tenant,
            GroupLeaderID: lead.GroupLeaderID,
          })
        );
      });

    const promisesCreate = selectedLeader
      .filter(
        (item) => !originalArray.some((selected) => selected.UserID === item)
      )
      ?.map((lead) => {
        const LeaderBody = {
          GroupLeaderID: v4(),
          GroupID: selectedGroupValue?.GroupID,
          UserID: lead,
        };
        return dispatch(
          createClientGroupLeader({ TenantID: tenant, data: LeaderBody })
        );
      });

    await Promise.all(promisesDelete, promisesCreate);
  }

  async function handleAssignLeader() {
    try {
      await handleUpdateLeaderList(currentLeader);
      await Promise.resolve(
        dispatch(
          retrieveAllClientGroupLeader({
            TenantID: tenant,
            GroupID: selectedGroupValue?.GroupID,
          })
        )
      );
      hideAssignLeaderModal();
      setNotifyMessage(CommonFunc.notify("update-leader"));
    } catch (error) {
      console.log(error);
    }
  }

  function handleReloadFlowChart() {
    const formattedStartDate = moment(startDate).format("YYYY-MM-DD");
    const formattedEndDate = moment(endDate).format("YYYY-MM-DD");
    try {
     
        retrieveHandlers();
        dispatch(
          retrieveClient({
            TenantID: tenant,
            CompanyID: companyID,
            CurrPage: currentPage,
            SortType: sortType,
            SortOrder: sortOrder,
            SearchText: searchText,
            SearchBy: searchBy,
            UserID: user?.uid,
            CurrGroup: selectedGroupValue?.GroupID,
            isSA: currSa,
            SelectedDateColumn: selectedDateColumn,
            StartDate: formattedStartDate,
            EndDate: formattedEndDate,
            DataFilterArr: dataFilterArr,
          })
        );
        dispatch(
          retrieveAllClientsCount({
            TenantID: tenant,
            CompanyID: companyID,
            SearchText: searchText,
            SearchBy: searchBy,
            UserID: user?.uid,
            CurrGroup: selectedGroupValue?.GroupID,
            isSA: currSa,
            SelectedDateColumn: selectedDateColumn,
            StartDate: formattedStartDate,
            EndDate: formattedEndDate,
            DataFilterArr: dataFilterArr,
          })
        );
        setTimeout(() => {
          setLoadFlowChart(false);
        }, 2000);
       
    
    } catch (err) {
      console.log(err);
    }
  }

  async function handleAssignHandler(item) {
    const { UserID, ClientID } = item;
    const body = {
      ClientAgentID: v4(),
      GroupID: selectedGroupValue?.GroupID,
      UserID: UserID,
      ClientID: ClientID,
    };
    setLoadFlowChart(true);

    dispatch(createClientAgent({ TenantID: tenant, data: body, ClientID: checkedClientState }));
    // dispatch(getClientAgent({ TenantID: tenant, ClientID: checkedClientState }));

    handleReloadFlowChart();

  }

  async function handleRemoveHandler(item) {
    setLoadFlowChart(true);

    dispatch(deleteClientAgent({ TenantID: tenant, ClientAgentID: item, ClientID: checkedClientState}));
    // dispatch(getClientAgent({ TenantID: tenant, ClientID: checkedClientState }));

    handleReloadFlowChart();
    
  }

  async function handleBulkCreateClientAgent(UserID) {
    setLoadFlowChart(true);
    dispatch(
      bulkCreateClientAgent({
        TenantID: tenant,
        UserID: UserID,
        GroupID: selectedGroupValue?.GroupID,
        ClientID: checkedClientState,
      })
    )
    handleReloadFlowChart();
    
  }

  async function handleBulkRemoveClientAgent(UserID) {
    setLoadFlowChart(true);
    dispatch(
      bulkRemoveClientAgent({
        TenantID: tenant,
        UserID: UserID,
        GroupID: selectedGroupValue?.GroupID,
        ClientID: checkedClientState,
      })
    )
    handleReloadFlowChart();
  }

  const onLoadFlowChart = (reactFlowInstance) => {
    reactFlowInstance.getElements().forEach((element) => {
      if (!isEdge(element)) {
        reactFlowInstance.setElementEditable(element.id, false);
      }
    });
  };

  const proOptions = { hideAttribution: true };

  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: "selection",
  };

  const onHandleDataFilter = (array) => {
    // setSelectedClientRow(array);
      setDataFilterArr(array)
  }

  const onHandleRowFilterData = (array) => {
    setRowFilterData(array);
  }

  const onHandleTempSelectedRowFilter = (array) => {
    setTempSelectedFilter(array);
  }

  const onHandleFilterConditionArr = (cond, name, columnType) => {
    const condObj = {...filterConditionArr};
    const filterArr = [...dataFilterArr];
    const tempSelectedArr = [...tempSelectedFilter];

    condObj[name] = cond;

    const type = (name !== "Handler" && name !=="Creator" &&  name !== "SerialNumber" && name !== "CreatedAt") ? clientColumnArray.find(col => col.columnName === name).columnIndex : name
    if(cond === "Unfilled"){

      const newFilterArr = filterArr.filter(data => data.filterType !== type);
      const newTempFilterArr = tempSelectedArr.filter(data => data.filterType !== type)

      newFilterArr.push({filterType:type, filterValue: "Unfilled", action : "Filter", columnType: columnType})
      newTempFilterArr.push({filterType:type, filterValue: "Unfilled",  action : "Filter", columnType: columnType})

      setDataFilterArr(newFilterArr);
      setTempSelectedFilter(newTempFilterArr);
    } else if(cond === "Filled in") {
      
        const existingFilterIndex = filterArr.findIndex(data => data.filterType === type && (data.filterValue === "Filled in" || data.filterValue === "Unfilled"));
        if (existingFilterIndex !== -1) {

          filterArr.splice(existingFilterIndex, 1);
          tempSelectedArr.splice(existingFilterIndex, 1);
        }    

        const isDuplicate = filterArr.some(data => data.filterType === type && data.filterValue === cond && data.action === "Filter");
      
        if (!isDuplicate) {
          filterArr.push({ filterType: type, filterValue: cond, action : "Filter", columnType: columnType });
          tempSelectedArr.push({ filterType: type, filterValue: cond, action : "Filter", columnType: columnType });
        }

        setDataFilterArr(filterArr);
        setTempSelectedFilter(tempSelectedArr);

    } else {
      const existingFilterIndex = filterArr.findIndex(data => data.filterType === type && (data.filterValue === "Filled in" || data.filterValue === "Unfilled"));
      if (existingFilterIndex !== -1) {

        filterArr.splice(existingFilterIndex, 1);
        tempSelectedArr.splice(existingFilterIndex, 1);
      }   

      setDataFilterArr(filterArr);
      setTempSelectedFilter(tempSelectedArr);
    }
    
    setFilterConditionArr(condObj);

  }

  const onHandleRemoveFilterList = (value) => {

      const filterArr = [...dataFilterArr];
      const tempFilterArr = [...tempSelectedFilter];
    
      const index = filterArr.map(data => data.filterValue).indexOf(value);
      filterArr.splice(index, 1)
      tempFilterArr.splice(index, 1)
      
      setDataFilterArr(filterArr);
      setTempSelectedFilter(tempFilterArr)

  }

  const onHandleNumberRangeInput = (numValue, columnIndex, rangeType, columnType) => {
    //columnIndex === columnType
    const filterArr = [...dataFilterArr];
    const tempFilterArr = [...tempSelectedFilter];
    const hasNumberFilter = filterArr.some(data => data.filterType === columnIndex && data.action === "Filter"
    && data.filterValue !== "Unfilled" && data.filterValue !== "Filled in");
    const value = parseInt(numValue);
    if(hasNumberFilter){
        const index = filterArr.findIndex(data => data.filterType === columnIndex && data.action === "Filter"
        && data.filterValue !== "Unfilled" && data.filterValue !== "Filled in");
        filterArr[index].filterValue[rangeType] = value;
        tempFilterArr[index].filterValue[rangeType] = value;
    } else {

        if(rangeType === "minimum") {
            filterArr.push({
                filterType: columnIndex, 
                filterValue: {
                    minimum: value,
                    maximum: "",
                    type: "number"
                },
                action: "Filter",
                columnType: columnType,
            });
            tempFilterArr.push({
                filterType: columnIndex, 
                filterValue: {
                    minimum: value,
                    maximum: "",
                    type: "number"
                }, 
                action: "Filter",
                rowIndex: null,
                columnType: columnType,
            });
        } else {
            filterArr.push({
                filterType: columnIndex, 
                filterValue: {
                    minimum: "",
                    maximum: value,
                    type: "number"
                },
                action: "Filter",
                columnType: columnType,
            });
            tempFilterArr.push({
                filterType: columnIndex, 
                filterValue: {
                    minimum: "",
                    maximum: value,
                    type: "number"
                }, 
                action: "Filter",
                rowIndex: null,
                columnType: columnType,
            });
        }
    }
    setDataFilterArr(filterArr);
    setTempSelectedFilter(tempFilterArr)
  }

  const onHandleSelectedDateFilter = (value, columnIndex, type, columnType) => {
      const filterArr = [...dataFilterArr];
      const tempFilterArr = [...tempSelectedFilter];
      const hasDateFilter = filterArr.some(data => data.filterType === columnIndex && data.action === "Filter" 
      && data.filterValue !== "Unfilled" && data.filterValue !== "Filled in");
      if(hasDateFilter){
          const index = filterArr.findIndex(data => data.filterType === columnIndex && data.action === "Filter"
          && data.filterValue !== "Unfilled" && data.filterValue !== "Filled in");
          filterArr[index].filterValue[type] = value;
          tempFilterArr[index].filterValue[type] = value;
      } else {
          if(type === "startDate") {
              filterArr.push({
                  filterType: columnIndex, 
                  filterValue: {
                      startDate: value,
                      endDate: "",
                      type: "date"
                  },
                  action : "Filter",
                  columnType: columnType,
              });
              tempFilterArr.push({
                  filterType: columnIndex, 
                  filterValue: {
                      startDate: value,
                      endDate: "",
                      type: "date"
                  }, 
                  action: "Filter",
                  rowIndex: null,
                  columnType: columnType,
              });
          } else {
              filterArr.push({
                  filterType: columnIndex, 
                  filterValue: {
                      startDate: "",
                      endDate: value,
                      type: "date"
                  },
                  action: "Filter",
                  columnType: columnType,
              });
              tempFilterArr.push({
                  filterType: columnIndex, 
                  filterValue: {
                      startDate: "",
                      endDate: value,
                      type: "date"
                  }, 
                  action: "Filter",
                  rowIndex: null,
                  columnType: columnType,
              });
          }
      }
      setDataFilterArr(filterArr);
      setTempSelectedFilter(tempFilterArr)
  }

  
  const onHandleClearSearchFilter = (columnIndex, type) => {
    const filterArr = [...dataFilterArr];
    const newFilterArr = filterArr.filter(data => !(data.filterType === columnIndex && data.action === "Search"));
    // dispatch(retrieveAllClientsCount(retrievalDataCount));
    // dispatch(retrieveClient(retrievalData));

    setDataFilterArr(newFilterArr);
    setTempSelectedFilter(newFilterArr)
  }

  const onHandleSearchFilter = (e, columnIndex, columnType) => {
      const filterArr = [...dataFilterArr];
      const tempFilterArr = [...tempSelectedFilter];
      const hasSearchFilter = filterArr.some(data => data.filterType === columnIndex && data.action === "Search");
      const value = e.target.value

      if(hasSearchFilter){
          const index = filterArr.findIndex(data => data.filterType === columnIndex && data.action === "Search");
          filterArr[index].filterValue = value;
          tempFilterArr[index].filterValue = value;
      } else {
          filterArr.push({
              filterType: columnIndex,
              filterValue: value,
              action: "Search",
              columnType: columnType,
          });

          tempFilterArr.push({
              filterType: columnIndex,
              filterValue: value,
              action: "Search",
              rowIndex: null,
              columnType: columnType,
          });
      }
      setDataFilterArr(filterArr);
      setTempSelectedFilter(tempFilterArr)
  }
  // //fucking row checkboxes
  const handleSelectedCreator = (e) => {
    const value = e.target.value;
    const filterArr = [...dataFilterArr];
    const tempFilterArr = [...tempSelectedFilter];

    if(e.target.checked){
        filterArr.push({filterType: "Creator", filterValue: value, action: "Filter", columnType:"Creator"});
        tempFilterArr.push({filterType: "Creator", filterValue: value, action: "Filter", rowIndex : null, columnType:"Creator"});
    } else {
        const index = filterArr.findIndex(data => data.filterType === "Creator" && data.action === "Filter" && data.filterValue === value);
        filterArr.splice(index, 1);
        tempFilterArr.splice(index, 1);
    }

    setDataFilterArr(filterArr);
    setTempSelectedFilter(tempFilterArr);
  }

  const handleSelectedFilterHandler = (e) => {
      const value = e.target.value;
      const filterArr = [...dataFilterArr];
      const tempFilterArr = [...tempSelectedFilter];

      if(e.target.checked){
          filterArr.push({filterType: "Handler", filterValue: value, action: "Filter", columnType:"Handler"});
          tempFilterArr.push({filterType: "Handler", filterValue: value, action: "Filter", rowIndex : null, columnType:"Handler"});
      } else {
          const index = filterArr.findIndex(data => data.filterType === "Handler" && data.action === "Filter" && data.filterValue === value);
          filterArr.splice(index, 1);
          tempFilterArr.splice(index, 1);
      }

      setDataFilterArr(filterArr);
      setTempSelectedFilter(tempFilterArr);
  }

  const handleSelectedRow = (e, columnIndex, rowIndex, columnType) => {
    const filterArr = [...dataFilterArr];
    const tempFilterArr = [...tempSelectedFilter];
    const value = e.target.value;

    if(e.target.checked)
    {
        filterArr.push({filterType: columnIndex, filterValue: value, action: "Filter",
        columnType: columnType});
        tempFilterArr.push({filterType: columnIndex, filterValue: value, action: "Filter", rowIndex : rowIndex,
        columnType: columnType});
    }
    else
    {  
        const index = filterArr.findIndex(data => data.filterType === columnIndex && data.filterValue === value && data.action === "Filter");
        filterArr.splice(index, 1);
        tempFilterArr.splice(index, 1);
    }

    setDataFilterArr(filterArr);
    setTempSelectedFilter(tempFilterArr);
}

const onHandleSelectAllRowFilter = (e, name, columnIndex, rowFilterData, columnType) => {
    const updatedAllFilter = {...selectAllFilter};
    let filterArr = [...dataFilterArr];
    let tempFilterArr = [...tempSelectedFilter];
    
    if(e.target.checked){
        updatedAllFilter[name] = true;
        if(rowFilterData[name] !== undefined){
            rowFilterData[name].map((row, rowIndex)=>{
              const filterRow = columnType !== "Alert" ? row : row.status ? "Completed" : "Pending";
                const hasFilterData = filterArr.some(data => data.filterType === columnIndex && data.filterValue === filterRow  && data.action === "Filter");
                const hasTempFilterArr = tempFilterArr.some(data => data.filterType === columnIndex && data.filterValue === filterRow  && data.action === "Filter");
                if(!hasFilterData){
                    filterArr.push({filterType:columnIndex, filterValue: row, action: "Filter", columnType: columnType});
                } 
                if(!hasTempFilterArr){
                    tempFilterArr.push({filterType:columnIndex, filterValue: row, rowIndex : rowIndex, action: "Filter", columnType: columnType})
                }
            })
        }
        
    } else {
        updatedAllFilter[name] = false;
        filterArr = dataFilterArr.filter(data => data.filterType !== columnIndex && data.action === "Filter" &&  data.filterType === "Handler" || data.filterValue === "Unfilled" || data.filterValue === "Filled in");
        tempFilterArr = tempSelectedFilter.filter(data => data.filterType !== columnIndex  && data.action === "Filter" && data.filterType === "Handler" || data.filterValue === "Unfilled" || data.filterValue === "Filled in");
    }
    setDataFilterArr(filterArr);
    setTempSelectedFilter(tempFilterArr);
    setSelectAllFilter(updatedAllFilter);
}

const onHandleClearFilter = () => {
  setLoadData(true);
  const filterObj = {...filterConditionArr}
  Object.keys(filterObj).forEach(keys => {
    filterObj[keys] = "All"
  })

  setFilterRow(false);
  setHandlerFilterRow(false);
  setFilterConditionArr(filterObj);
  setDataFilterArr([]);
  setTempSelectedFilter([]);
  
    dispatch(
      retrieveAllClientsCount({
        TenantID: tenant,
        CompanyID: companyID,
        SearchText: searchText,
        SearchBy: "",
        UserID: user?.uid,
        CurrGroup: selectedGroupValue?.GroupID,
        isSA: currSa,
        SelectedDateColumn: "",
        StartDate: "",
        EndDate: "",
        DataFilterArr: [],
      })
    ),
    dispatch(
      retrieveClient({
        TenantID: tenant,
        CompanyID: companyID,
        CurrPage: 0,
        SortType: sortType,
        SortOrder: sortOrder,
        SearchText: searchText,
        SearchBy: "",
        UserID: user?.uid,
        CurrGroup: selectedGroupValue?.GroupID,
        isSA: currSa,
        SelectedDateColumn: "",
        StartDate: "",
        EndDate: "",
        DataFilterArr: [],
      })
    ),
    dispatch(retrieveAllClientGroupLeader({ TenantID: tenant, GroupID: selectedGroupValue?.GroupID })),
    dispatch(retrieveClientFilterData({ TenantID: tenant, SearchText: searchText, DataFilterArr: [], CompanyID: companyID, GroupID: selectedGroupValue ? selectedGroupValue?.GroupID : availableClientsGroup[0]?.GroupID, UserID: user.uid }))
  
    setTimeout(() => {
      setLoadData(false);
    },1000)
}

const onHandleApplyFilter = () => {
  setLoadData(true);
  setFilterRow(false);
  setHandlerFilterRow(false);
    dispatch(
      retrieveAllClientsCount({
        TenantID: tenant,
        CompanyID: companyID,
        SearchText: searchText,
        SearchBy: "",
        UserID: user?.uid,
        CurrGroup: selectedGroupValue?.GroupID,
        isSA: currSa,
        SelectedDateColumn: "",
        StartDate: "",
        EndDate: "",
        DataFilterArr: dataFilterArr,
      })
    )
    dispatch(
      retrieveClient({
        TenantID: tenant,
        CompanyID: companyID,
        CurrPage: 0,
        SortType: sortType,
        SortOrder: sortOrder,
        SearchText: searchText,
        SearchBy: "",
        UserID: user?.uid,
        CurrGroup: selectedGroupValue?.GroupID,
        isSA: currSa,
        SelectedDateColumn: "",
        StartDate: "",
        EndDate: "",
        DataFilterArr: dataFilterArr,
      })
    )
    dispatch(retrieveAllClientGroupLeader({ TenantID: tenant, GroupID: selectedGroupValue?.GroupID }))
    dispatch(retrieveClientFilterData({ TenantID: tenant, SearchText: searchText, DataFilterArr: dataFilterArr, CompanyID: companyID, GroupID: selectedGroupValue ? selectedGroupValue?.GroupID : availableClientsGroup[0]?.GroupID, UserID: user.uid }))

    setTimeout(() => {
      setLoadData(false);
    },1000)
}

const onHandleToggleFilterRow = (res) => {
  setFilterRow(res);
}

  return (
    <Fragment>
      {currSa && (
        <div
          className={`manageClientButton ${
            goManageTable && "hideManageClientButton"
          }`}
          onClick={() => {
            handleManageClient();
          }}
        >
          <RiListSettingsLine size={20} className="icon" />
          <span>Manage Group</span>
        </div>
      )}
      <C_Loading isLoading={isLoading} />
      <div className="sidebar-main-container">
          <Sidebar />
        </div>
        <div className="main-container">
        <Header title={"Clients"} />
        <div
          className={`content-box manageTable ${
            !goManageTable && "hideManageTable"
          }`}
         
        >
          <ClientGroup handleBack={handleManageClient} />
        </div>
        {/* Error Imported Modal */}
        <ClientImportErrorTable 
              isSa={isSuperAdmin}
              isGroupLeader = {clientGroupLeader.some(element=>element.UserID === user?.uid)}
              importErrorRef = {importErrorRef}
              hideImportErrorModal = {hideImportErrorModal}
              errorImportArr = {errorImportArr}
              processedImportedRow = {processedImportedRow}
              errorIndexArr = {errorIndexArr}
              errorDBDuplicatedArr = {errorDBDuplicatedArr}
              errorCSVDuplicatedArr = {errorCSVDuplicatedArr}
              setErrorImportArr = {setErrorImportArr}
              setProcessedImportedRow = {setProcessedImportedRow}
              setErrorIndexArr = {setErrorIndexArr}
              setErrorCSVDuplicatedArr = {setErrorCSVDuplicatedArr}
              setErrorDBDuplicatedArr = {setErrorDBDuplicatedArr}
              resetCSVFileInput = {resetCSVFileInput}
            />
        {/* End Error Imported Modal */}

        {/* Start Filter Modal */}
        <div className="modal fade filter-modal" ref={filterModalRef} tabIndex="-1">
          <div className="modal-dialog modal-dialog-centered modal" style={{}}>
              <div className="modal-content px-3" style={{}}>
                <div className="modal-header modalHeader">
                  <div className="title">
                      <BsToggles size={18} color={"#4d4d4d"}/>
                      <span className="font-l">Filters</span>
                  </div> 
                  <AiOutlineClose cursor='pointer' size={16} onClick={()=>{hideFilterModal()}} />
                </div>
                <div className="modal-body filter-modal-body">
                  <div className="filter-container">
                    <div className="filter-item-container">
                      <div className="filter-item-header">
                            <span className="item-title">Created By</span>
                      </div>
                      <div className="filter-list-item">
                        <ul className="list-group">
                        {currUserSA?.map(user => {
                                const isSelected = tempSelectedFilter.some(data => data.filterValue === user.uid && data.filterType === "Creator");
                                const UserID = user.uid;
                                const UserName = user.displayName;
                                return(
                                      <li className={` ${isSelected && "selected-items"} list-group-items`} style={{ listStyleType: 'none' }}>
                                            <label style={{ cursor: 'pointer',display: 'flex', alignItems: 'center' }}>
                                            <input type='checkbox' style={{ width: '13px', height: '13px' }} className='col-md-3' name={UserID} value={UserID} checked={isSelected} onChange={(e) => handleSelectedCreator(e)} />
                                            <span className="checkmark"></span>
                                            <span className='col-md-9 font-xxs list-items' style={{paddingLeft: '10px', fontWeight: 'normal'}}>{UserName}</span>
                                            </label>
                                      </li>
                                )
                          })}
                        </ul>
                      </div>
                    </div>

                    {filterHandlerList.length > 0 && 
                      <div className="filter-item-container">
                          <div className="filter-item-header">
                                <span className="item-title">Handler</span>
                                <select className="filter-condition-dropdown" onChange={(e)=>{onHandleFilterConditionArr(e.target.value, "Handler", "Handler")}}>
                                {conditionArr.map((cond, index) => {
                                  const isSelected = filterConditionArr["Handler"] === cond;
                                  return (
                                    <option key={index} name={cond} value={cond} selected={isSelected}>{cond}</option>
                                  );
                                })}
                                </select>
                          </div>
                          <div className="filter-list-item">
                            <ul className="list-group">
                            {filterHandlerList?.map(handler => {
                                    const isSelected = tempSelectedFilter.some(data => data.filterValue === handler && data.filterType === "Handler");
                                    const handlerObj = currUsers.find(member => member.uid === handler);
                                    return(
                                          <li className={` ${isSelected && "selected-items"} list-group-items`} style={{ listStyleType: 'none' }}>
                                                <label style={{ cursor: 'pointer',display: 'flex', alignItems: 'center' }}>
                                                <input type='checkbox' style={{ width: '13px', height: '13px' }} className='col-md-3' name={handler} value={handler} checked={isSelected} onChange={(e) => handleSelectedFilterHandler(e)} />
                                                <span className="checkmark"></span>
                                                <span className='col-md-9 font-xxs list-items' style={{paddingLeft: '10px', fontWeight: 'normal'}}>{handlerObj !== undefined ? handlerObj.displayName : ""}</span>
                                                </label>
                                          </li>
                                    )
                              })}
                            </ul>
                          </div>
                        </div>
                      }
                        
                      <div className="filter-item-container">
                          <div className="filter-item-header">
                            <span className="item-title">{"Creation Date"}</span>
                            
                            <select className="filter-condition-dropdown" onChange={(e)=>{onHandleFilterConditionArr(e.target.value, "CreatedAt", "CreatedAt")}}>
                            {conditionArr.map((cond, index) => {
                              const isSelected = filterConditionArr["CreatedAt"] === cond;
                              return (
                                <option key={index} name={cond} value={cond} selected={isSelected}>{cond}</option>
                              );
                            })}
                            </select>
                          </div>
                          <div className="filter-list-item">
                            <ul className="list-group" style={{ width: '235px', borderRadius: '10px',rowGap:'5px', position:'relative' }}>                     
                                <div className="date-filter">
                                    <label>
                                        <input type={"datetime-local"} className="date-input" 
                                        value={(dataFilterArr.length > 0 && dataFilterArr.find(data => data.filterType === "CreatedAt") !== undefined) 
                                        ? dataFilterArr.find(data => data.filterType === "CreatedAt")?.filterValue.startDate 
                                        : ""} onChange={(e) => {onHandleSelectedDateFilter(e.target.value, "CreatedAt", "startDate", "CreatedAt")}}/>
                                    </label>
                                    <span> - </span>
                                    <label>
                                        <input type={"datetime-local"} className="date-input" 
                                          value={(dataFilterArr.length > 0  && dataFilterArr.find(data => data.filterType === "CreatedAt") !== undefined) 
                                          ? dataFilterArr.find(data => data.filterType === "CreatedAt").filterValue.endDate 
                                          : ""} onChange={(e) => {onHandleSelectedDateFilter(e.target.value, "CreatedAt", "endDate", "CreatedAt")}}/>
                                    </label>
                                </div>
                              </ul>
                          </div>
                      </div>
                      {Object.keys(rowFilterData).map((keys, keyIndex) => {
                        const columnName = keys
                        const columnIndex = clientColumnArray.find(col => col.columnName === keys) !== undefined 
                        ? clientColumnArray.find(col => col.columnName === keys).columnIndex : "";

                        const type = clientColumnArray.find(col => col.columnName === keys) !== undefined 
                        ? clientColumnArray.find(col => col.columnName === keys).columnType : "";
                      
                        const filterData = rowFilterData[keys];
                        
                        const isUnfilled = filterConditionArr[columnName] === "Unfilled";

                        const matchingDateColumn = clientColumnArray.find(col => col.columnType === "Date" && col.columnName === keys);
                        const dateType = matchingDateColumn !== undefined ? matchingDateColumn.dateType : "YYYY-MM-DD";
                        if(type !== "Switch" && type !== "Form"){
                          const searchFilterText = (dataFilterArr.length > 0 && dataFilterArr.find(data => data.filterType === columnIndex && data.action === "Search") !== undefined) 
                          ? dataFilterArr.find(data => data.filterType === columnIndex && data.action === "Search")?.filterValue 
                          : "";
                          return(
                          <div className="filter-item-container" key={keyIndex}>
                              <div className="filter-item-header">
                                <span className="item-title">{columnName}</span>
                                
                                <select className="filter-condition-dropdown" onChange={(e)=>{onHandleFilterConditionArr(e.target.value, columnName, type)}}>
                                {conditionArr.map((cond, index) => {
                                  const isSelected = filterConditionArr[columnName] === cond;
                                  return (
                                    <option key={index} name={cond} value={cond} selected={isSelected}>{cond}</option>
                                  );
                                })}
                                </select>
                              </div>
                              {filterData?.length > 0 && !isUnfilled && type !== "Alert" &&
                                    <div className="filter-search-client-side">
                                        <C_Search
                                            height={"25px"}
                                            placeholder={`Search`}
                                            onChange={(e) => {
                                                onHandleSearchFilter(e, columnIndex, type)
                                            }}
                                            value={searchFilterText}
                                            searchText={searchFilterText}
                                            closeFunction={() => {
                                                onHandleClearSearchFilter(columnIndex, type);
                                            }}
                                            borderRadius={"5px"}
                                            containerWidth={"100%"}
                                        />
                                    </div>
                              } 
                              <div className="filter-list-item">
                                <ul className="list-group" style={{ width: '235px', borderRadius: '10px',rowGap:'5px', position:'relative' }}>
                                  {filterData?.length === 0 && <span className="font-xxs" style={{color:'#019f9f'}}>No data can be filtered.</span>}
                                    
                                  {type === "Date" &&
                                      <div className="date-filter">
                                          <label>
                                              <input type={"datetime-local"} className="date-input" 
                                              value={(dataFilterArr.length > 0 && dataFilterArr.find(data => data.filterType === columnIndex) !== undefined) 
                                              ? dataFilterArr.find(data => data.filterType === columnIndex)?.filterValue.startDate 
                                              : ""} onChange={(e) => {onHandleSelectedDateFilter(e.target.value, columnIndex, "startDate", type)}}/>
                                          </label>
                                          <span> - </span>
                                          <label>
                                              <input type={"datetime-local"} className="date-input" 
                                                value={(dataFilterArr.length > 0  && dataFilterArr.find(data => data.filterType === columnIndex) !== undefined) 
                                                ? dataFilterArr.find(data => data.filterType === columnIndex).filterValue.endDate 
                                                : ""} onChange={(e) => {onHandleSelectedDateFilter(e.target.value, columnIndex, "endDate", type)}}/>
                                          </label>
                                      </div>
                                  }

                                  {type === "Google Map" &&
                                  <>
                                      <li className='list-group-items' style={{ listStyleType: 'none' }}>
                                            <label title={"Select All"} style={{ cursor: 'pointer',display: 'flex', alignItems: 'center', justifyContent: "space-around"  }}>
                                              <input type='checkbox' style={{ width: '13px', height: '13px' }} className='col-md-3' 
                                              onChange={(e)=> {onHandleSelectAllRowFilter(e, columnName, columnIndex, rowFilterData, type)}}  checked={selectAllFilter[columnName]}/>
                                              <span className="checkmark"></span>
                                              <span className='col-md-9 font-xs' style={{paddingLeft: '10px', fontWeight: 'normal', width: "85%", textTransform: 'capitalize' }}>Select All</span>
                                            </label>
                                        </li>
                                      {filterData?.map((row, rowIndex) => {
                                          if(row !== "" || row !== "-")
                                          {
                                              const isSelected = tempSelectedFilter.some(data => data.filterType === columnIndex && data.rowIndex === rowIndex);
                                              var regexResult = /\bplace([^@]*)/.exec(row);
                                              if (regexResult !== undefined && regexResult !== null && regexResult.length !== 0) 
                                              {
                                                  var extraction = regexResult[1].toString().replace(/\+/g, " ")
                                                  var result = extraction.replace(/\//g, "")
                                                  return (
              
                                                    //row[item.columnName]
                                                    <li className={` ${isSelected && "selected-items"} list-group-items`} key={rowIndex}  style={{ listStyleType: 'none' }}>
                                                        <label title={row} style={{ cursor: 'pointer',display: 'flex', alignItems: 'center', justifyContent: "space-around"  }}>
                                                        <input type='checkbox' style={{ width: '13px', height: '13px' }} className='col-md-3' name={row} value={row} checked={isSelected} onChange={(e) => handleSelectedRow(e, columnIndex, rowIndex, type)} />
                                                        <span className="checkmark"></span>
                                                        <span className='col-md-9 font-xxs list-items' style={{paddingLeft: '10px', fontWeight: 'normal', width: "85%", textTransform: 'capitalize' }}>{decodeURIComponent(result)}</span>
                                                        </label>
                                                    </li>
                                                    )
                                              }
                                          }
                                      })}
                                  </>
                                  }
                                  {type === "Member" &&
                                      <>
                                          {filterData?.map((row, rowIndex) => {
                                              const isSelected = tempSelectedFilter.some(data => data.filterType === columnIndex && data.rowIndex === rowIndex);
                                              var columnObj = clientColumnArray.find(element => element.columnName === "UserName");
                                              return (
                                              //row[item.columnName]
                                              <li className={` ${isSelected && "selected-items"} list-group-items`} key={rowIndex} style={{ listStyleType: 'none' }}>
                                                  <label title={row} style={{ cursor: 'pointer',display: 'flex', alignItems: 'center', justifyContent: "space-around"   }}>
                                                  <input type='checkbox' style={{ width: '13px', height: '13px' }} className='col-md-3' name={row} value={row} checked={isSelected} onChange={(e) => handleSelectedRow(e, columnIndex, rowIndex, type)} />
                                                  <span className="checkmark"></span>
                                                  <span className='col-md-9 font-xxs list-items' style={{paddingLeft: '10px', fontWeight: 'normal', width: "85%"}}>
                                                      {/* {row} */}
                                                      {
                                                      columnObj !== undefined &&   
                                                      rowData.map((item,index)=>{
                                                          if(item.UserID === row)
                                                          {
                                                          return (
                                                              item.MemberData.map((data,idx)=>{
                                                                      if(columnObj.columnID === data.columnID)
                                                                      {
                                                                          return (data.rowValue);
                                                                      }
                                                              })  
                                                          )
                                                          }
                                                      })
                                                      }
                                                  </span>
                                                  </label>
                                              </li>
                                              )
                                          })}
                                      </>
                                  }

                                  {type === "Alert" &&
                                    <>
                                      <li className={` ${selectAllFilter[columnName] === true && "selected-items"} list-group-items`} style={{ listStyleType: 'none' }}>
                                        <label title={"Select All"} style={{ cursor: 'pointer',display: 'flex', alignItems: 'center', justifyContent: "space-around"  }}>
                                          <input type='checkbox' style={{ width: '13px', height: '13px' }} className='col-md-3' 
                                          onChange={(e)=> {onHandleSelectAllRowFilter(e, columnName, columnIndex, rowFilterData, type)}}  checked={selectAllFilter[columnName]}/>
                                          <span className="checkmark"></span>
                                          <span className='col-md-9 font-xs' style={{paddingLeft: '10px', fontWeight: 'normal', width: "85%", textTransform: 'capitalize' }}>Select All</span>
                                        </label>
                                      </li>
                                      {filterData?.map((row, rowIndex) => {
                                          const isSelected = tempSelectedFilter.some(data => data.filterType === columnIndex && data.rowIndex === rowIndex);
                                          const status = row ? "Completed" : "Pending";
                                          const columnType = type;
                                          return (
                                          <li className={` ${isSelected && "selected-items"} list-group-items`} key={rowIndex} style={{ listStyleType: 'none' }}>
                                              <label title={row} style={{ cursor: 'pointer',display: 'flex', alignItems: 'center', justifyContent: "space-around" }}>
                                              <input type='checkbox' style={{ width: '13px', height: '13px' }} className='col-md-3' name={status} value={status} checked={isSelected} onChange={(e) => {handleSelectedRow(e, columnIndex, rowIndex, columnType)}} />
                                              <span className="checkmark"></span>
                                              <span className='col-md-9 font-xxs list-items' style={{paddingLeft: '10px', fontWeight: 'normal', width: "85%"}}>{status}</span>
                                              </label>
                                          </li>
                                          )
                                      })}
                                    </>
                                  }
                                  
                                  {type === "Numbers" && 
                                      <li className="number-range-container">
                                          <div className="number-range-filter">
                                              <label>
                                                  <input type={"number"} className="number-input" placeholder="Minimum"
                                                  value={(dataFilterArr.length > 0  && dataFilterArr.find(data => data.filterType === columnIndex) !== undefined) 
                                                    ? dataFilterArr.find(data => data.filterType === columnIndex).filterValue.minimum 
                                                    : ""} onChange={(e) => {onHandleNumberRangeInput(e.target.value, columnIndex, "minimum", type)}}/>
                                              </label>
                                              <span className="number-range-symbol"> ~ </span>
                                              <label>
                                                  <input type={"number"} className="number-input" placeholder="Maximum"
                                                  value={(dataFilterArr.length > 0  && dataFilterArr.find(data => data.filterType === columnIndex) !== undefined) 
                                                    ? dataFilterArr.find(data => data.filterType === columnIndex).filterValue.maximum 
                                                    : ""} onChange={(e) => {onHandleNumberRangeInput(e.target.value, columnIndex, "maximum", type)}}/>
                                              </label>
                                          </div>
                                      </li>
                                  }
                                  {(type !== "Date" && type !== "Google Map" && type !== "Member" && type !== "Switch" && type !== "Alert"
                                  && type !== "Short Text" && type !== "Multiline" && type !== "Rich Text Editor" && type !== "Numbers" && type !== "Email") &&
                                      <>
                                          <li className={` ${selectAllFilter[columnName] === true && "selected-items"} list-group-items`} style={{ listStyleType: 'none' }}>
                                            <label title={"Select All"} style={{ cursor: 'pointer',display: 'flex', alignItems: 'center', justifyContent: "space-around"  }}>
                                              <input type='checkbox' style={{ width: '13px', height: '13px' }} className='col-md-3' 
                                              onChange={(e)=> {onHandleSelectAllRowFilter(e, columnName, columnIndex, rowFilterData, type)}}  checked={selectAllFilter[columnName]}/>
                                              <span className="checkmark"></span>
                                              <span className='col-md-9 font-xs' style={{paddingLeft: '10px', fontWeight: 'normal', width: "85%", textTransform: 'capitalize' }}>Select All</span>
                                            </label>
                                          </li>
                                          {filterData?.map((row, rowIndex) => {
                                              const isSelected = tempSelectedFilter.some(data => data.filterType === columnIndex && data.rowIndex === rowIndex);
                                              return (
                                              <li className={` ${isSelected && "selected-items"} list-group-items`} key={rowIndex} style={{ listStyleType: 'none' }}>
                                                  <label title={row} style={{ cursor: 'pointer',display: 'flex', alignItems: 'center', justifyContent: "space-around" }}>
                                                  <input type='checkbox' style={{ width: '13px', height: '13px' }} className='col-md-3' name={row} value={row} checked={isSelected} onChange={(e) => handleSelectedRow(e, columnIndex, rowIndex, type)} />
                                                  <span className="checkmark"></span>
                                                  <span className='col-md-9 font-xxs list-items' style={{paddingLeft: '10px', fontWeight: 'normal', width: "85%"}}>{row}</span>
                                                  </label>
                                              </li>
                                              )
                                          })}
                                      </>
                                  }
                                  </ul>
                              </div>
                          </div>
                          );
                        }
                      })}
                    </div>
                    <div className="apply-filter-container">
                      <C_Button
                        buttonText={"Apply Filter"}
                        background={Colors.generalButtonColor}
                        width={window.innerWidth > 1500 ? "100px" : "80px"}
                        justify={"center"}
                        textColor={"#FFFFFF"}
                        onClick={() => {onHandleApplyFilter();  hideFilterModal();}}
                      />
                      <C_Button
                        buttonText={"Clear"}
                        background={Colors.generalButtonColor }
                        width={window.innerWidth > 1500 ? "100px" : "80px"}
                        justify={"center"}
                        textColor={"#FFFFFF"}
                        marginRight={"0.4rem"}
                        onClick={() => {onHandleClearFilter();  hideFilterModal();}}
                      />
                    </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Filter Modal */}
        <div
          className={`content-box ${goManageTable && "hideClient"}`}
         
        >
          <C_PopoutNoti triggerSaved={notifyMessage} />

          {/* Start Quota Limit Modal */}
          <div className="modal fade" tabIndex="-1" ref={exceedModalRef}>
            <div className="modal-dialog modal-dialog-centered" style={{}}>
              <div
                className="modal-content px-3"
                style={{ overflowY: "scroll" }}
              >
                <div className="modal-body">
                  {/* <AiOutlineClose cursor='pointer' size={16} onClick={() => {
                          hideExceedModalFunc();
                        }}/> */}
                  <div className="quotaRestrictedContainer">
                    <span>
                      {`You've reached the limit `}
                      <a
                        href="https://zumaxdigital.com/crm-v1/"
                        rel="noopener noreferrer"
                        className="link"
                      >
                        upgrade now!
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Quota Limit Modal */}

          {/* Start CSV Modal */}
          <div
            className="modal fade"
            ref={modalCsvRef}
            id="import-client"
            tabIndex="-1"
            style={{}}
          >
            <div
              className="modal-dialog modal-dialog-centered modal-lg"
              style={{}}
            >
              <div
                className="modal-content px-3"
                style={{ overflowY: "scroll" }}
              >
                <div className="modal-header modalHeader">
                  <div className="title">
                    <FiUpload size={17} color={"#4287f5"} />
                    <span className="font-s">Import Client</span>
                  </div>
                  <AiOutlineClose
                    cursor="pointer"
                    size={16}
                    onClick={() => {
                      hideCsvModal();
                    }}
                  />
                </div>
                <div className="modal-body">
                  <div className="">
                    <span className="font-xs" style={{ fontWeight: "bold" }}>
                      {`To import data, one must use the template served as
                      HEADER. The template was designed based on user's
                      customization. You may refer to this `}
                      <a rel="noopener noreferrer" href="/#/Guide/Clients" onClick={()=>{
                        hideCsvModal();
                      }}>
                        import guide
                      </a>
                      {` before import. `}
                      <span
                        style={{
                          fontWeight: "bold",
                          color: Colors.redButton,
                        }}
                      >
                        {`Please note that only file with .csv format will be
                        accepted.`}
                      </span>
                    </span>
                  </div>

                  <div
                    className="font-xs"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      textAlign: "center",
                      width: "100%",
                      marginTop: "10px",
                    }}
                  >
                    <input
                      ref={csvInputRef}
                      style={{
                        width: "100%",
                        boxShadow: "0 1px 4px rgb(0 0 0 / 20%)",
                        border: "none",
                      }}
                      type={"file"}
                      accept={".csv"}
                      onChange={handleOnChangeFile}
                    />
                  </div>
              
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      columnGap: "20px",
                    }}
                    className="mt-3 modalshit"
                  >
                    <C_DialogButton
                      color={"#4287f5"}
                      background={"white"}
                      borderColor={"#4287f5"}
                      buttonText={"UPLOAD"}
                      onClick={() => {
                        uploadCsv();
                      }}
                    />
                    <CSVLink
                      className="dialog-btn export-button"
                      style={{ width: "140px" }}
                      data={[]}
                      headers={clientCsvTemplateHeader}
                      filename={`${
                        selectedGroupValue?.GroupName +
                        "'s Template - " +
                        moment(new Date()).format("Do MMMM YYYY")
                      }.csv`}
                    >
                      <span style={{}}>GET TEMPLATE</span>
                    </CSVLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End CSV Modal */}

          {/* Start Assign Leader Modal */}
          <div className="modal fade" tabIndex="-1" ref={assignLeaderModalRef}>
            <div className="modal-dialog modal-dialog-centered" style={{}}>
              <div
                className="modal-content px-3"
                style={{ overflowY: "scroll" }}
              >
                <div className="modal-header modalHeader">
                  <div className="title">
                    <RiUser2Line size={17} color={"#584C78"} />
                    <span className="font-s">
                      Manage Leader ({selectedGroupValue?.GroupName})
                    </span>
                  </div>
                  <AiOutlineClose
                    cursor="pointer"
                    size={16}
                    onClick={() => {
                      hideAssignLeaderModal();
                    }}
                  />
                </div>
                <div className="modal-body assignLeaderMainContainer">
                  <div className="tipsContainer">
                    <MdOutlineTipsAndUpdates size={16} color="#FFF" />
                    <span>
                      {`Assigned leader will have accessibility to all client in
                      this group.`}
                    </span>
                  </div>
                  <div className="searchContainer">
                    <C_Search
                      placeholder="Search member"
                      onChange={(e) => {
                        setSearchLeaderText(e.target.value);
                      }}
                      value={searchLeaderText}
                      searchText={searchLeaderText}
                      closeFunction={() => {
                        setSearchLeaderText("");
                      }}
                      containerWidth="60%"
                    />
                    <div className="selectedContainer">
                      <div
                        className="selectedAmount"
                        onClick={() => {
                          setCheckSelectedLeader(!checkSelectedLeader);
                        }}
                      >
                        <span>{selectedLeader?.length + " leader"}</span>
                        {checkSelectedLeader ? (
                          <BiChevronUp size={20} />
                        ) : (
                          <BiChevronDown size={20} />
                        )}
                      </div>
                      <div
                        className="selectedDropdownContainer"
                        style={{
                          display: checkSelectedLeader ? "flex" : "none",
                          background: "white",
                        }}
                      >
                        {Array.isArray(currUsers) &&
                        currUsers &&
                        currUsers.length > 0 ? (
                          [...currUsers]
                            ?.sort((a, b) =>
                              a?.displayName?.localeCompare(b?.displayName)
                            )
                            .filter((member) =>
                              selectedLeader.includes(member.uid)
                            )
                            .map((item, index) => {
                              return (
                                <div
                                  key={index}
                                  className="selectedHandlerList"
                                >
                                  <span>{item?.displayName}</span>
                                  <div
                                    className="deselect"
                                    onClick={() => {
                                      handleDeSelectLeader(item.uid);
                                    }}
                                  >
                                    <AiFillCloseCircle size={16} />
                                  </div>
                                </div>
                              );
                            })
                        ) : (
                          <div className="emptySelection">
                            <span>No leader</span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="memberListContainer">
                    {Array.isArray(currUsers) &&
                    currUsers &&
                    currUsers.length > 0 ? (
                      currUsers
                        ?.filter((item) => {
                          if (
                            item.displayName
                              .toString()
                              .toLocaleLowerCase()
                              .includes(
                                searchLeaderText.toString().toLocaleLowerCase()
                              )
                          ) {
                            return item;
                          }
                        })
                        .map((item, index) => {
                          const selected = selectedLeader.includes(item.uid);
                          return (
                            <div
                              key={index}
                              className="assignLeaderMemberContainer"
                            >
                              <label
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <input
                                  className=""
                                  type="checkbox"
                                  onChange={() => {
                                    if (selected) {
                                      handleDeSelectLeader(item.uid);
                                    } else {
                                      handleSelectLeader(item.uid);
                                    }
                                  }}
                                  value={selected}
                                  checked={selected}
                                />
                                <span className="checkmark"></span>
                              </label>
                              <img src={item?.photoURL} alt="" />
                              <span>{item?.displayName}</span>
                            </div>
                          );
                        })
                    ) : (
                      <div className="noMemberContainer">
                        <FaUserFriends size={16} />
                        <span>No member were found.</span>
                      </div>
                    )}
                  </div>
                  <div className="actionContainer">
                    <C_DialogButton
                      onClick={() => {
                        hideAssignLeaderModal();
                        dispatch(setClientContext({
                          checkedClientState : [],
                        }))
                      }}
                      color={"#6d45b9"}
                      background={"white"}
                      borderColor={"#6d45b9"}
                      buttonText={"DISCARD"}
                    />
                    <C_DialogButton
                      onClick={() => {
                        handleAssignLeader();
                      }}
                      color={"#FFF"}
                      background={"#3eb986"}
                      borderColor={"#3eb986"}
                      buttonText={"CONFIRM"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Assign Leader Modal */}

          {/* Start Assign Handler Modal */}
          <div className="modal fade" tabIndex="-1" ref={assignHandlerModalRef}>
            <div
              className={`modal-dialog modal-dialog-centered ${
                containsTrue && "modal-xl"
              }`}
              style={{}}
            >
              <div
                className="modal-content px-3"
                style={{ overflowY: "scroll" }}
              >
                <div className="modal-header modalHeader">
                  <div className="title">
                    <RiUser2Line size={17} color={"#584C78"} />
                    <span className="font-s">Assign Handler</span>
                  </div>
                  <AiOutlineClose
                    cursor="pointer"
                    size={16}
                    onClick={() => {
                      hideAssignHandlerModal();
                      dispatch(setClientContext({
                        checkedClientState : [],
                        checkedAllClientState : false
                      }))
                      setSharedHandlerList([]);
                    }}
                  />
                </div>
                <div
                  className={`modal-body ${
                    containsTrue && "assignHandlerMainContainer"
                  }`}
                >
                  {containsTrue ? (
                    <div className="handlerManagementContainer">
                      <div className="assignActionContainer">
                        <div className="assignBulkActionContainer">
                          <div className="searchContainer">
                            <C_Search
                              placeholder={`Search client by name`}
                              onChange={(e) => {
                                setSearchTextAssignee(e.target.value);
                              }}
                              value={searchTextAssignee}
                              searchText={searchTextAssignee}
                              closeFunction={() => {
                                setSearchTextAssignee("");
                              }}
                              containerWidth={"100%"}
                            />
                          </div>
                          <div className="bulkContainer">
                            <span
                              className={`bulkAssign ${
                                showBulkAssignContainer && "selected"
                              }`}
                              onClick={() => {
                                setShowBulkAssignContainer(true);
                                setShowBulkRemoveContainer(false);
                              }}
                            >
                              Bulk Assign
                            </span>
                            <span
                              className={`bulkRemove ${
                                showBulkRemoveContainer && "selected"
                              }`}
                              onClick={() => {
                                setShowBulkRemoveContainer(true);
                                setShowBulkAssignContainer(false);
                              }}
                            >
                              Bulk Remove
                            </span>
                            {showBulkAssignContainer && (
                              <div
                                ref={bulkAssignRef}
                                className="bulkAssignContainer"
                              >
                                {Array.isArray(currUsers) &&
                                currUsers &&
                                currUsers?.length > 0 ? (
                                  currUsers?.map((member, index) => {
                                    return (
                                      <div key={index} className="handler">
                                        <div className="profile">
                                          <img
                                            src={member?.photoURL}
                                            alt={member?.displayName}
                                          />
                                          <span title={member?.displayName}>
                                            {member?.displayName}
                                          </span>
                                        </div>
                                        <span
                                          className="add"
                                          onClick={() => {
                                            handleBulkCreateClientAgent(
                                              member?.uid
                                            );
                                          }}
                                        >
                                          Assign All
                                        </span>
                                      </div>
                                    );
                                  })
                                ) : (
                                  <div className="emptyAvailableMember">
                                    <span>No Member Available</span>
                                  </div>
                                )}
                              </div>
                            )}
                            {showBulkRemoveContainer && (
                              <div
                                ref={bulkRemoveRef}
                                className="bulkRemoveContainer"
                              >
                                {Array.isArray(currUsers) &&
                                currUsers &&
                                currUsers?.length > 0 ? (
                                  currUsers?.map((member, index) => {
                                    return (
                                      <div key={index} className="handler">
                                        <div className="profile">
                                          <img
                                            src={member?.photoURL}
                                            alt={member?.displayName}
                                          />
                                          <span>{member?.displayName}</span>
                                        </div>
                                        <span
                                          className="remove"
                                          onClick={() => {
                                            handleBulkRemoveClientAgent(
                                              member?.uid
                                            );
                                          }}
                                        >
                                          Remove All
                                        </span>
                                      </div>
                                    );
                                  })
                                ) : (
                                  <div className="emptyAvailableMember">
                                    <span>No Member Available</span>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="assignSingleActionContainer">
                          {Array.isArray(selectedAgentHandler?.handler) &&
                          selectedAgentHandler?.handler &&
                          selectedAgentHandler?.handler.length > 0 ? (
                            selectedAgentHandler?.handler
                              ?.filter((filterData) => {
                                if (
                                  filterData?.ClientName?.toString()
                                    .toLocaleLowerCase()
                                    .includes(
                                      searchTextAssignee
                                        .toString()
                                        .toLocaleLowerCase()
                                    )
                                ) {
                                  return filterData;
                                }
                              })
                              ?.map((item, index) => {
                                const isExpand = expandClientSection?.some(
                                  (expand) => expand === item?.ClientID
                                );
                                const isExpandMember =
                                  expandMemberSection === item?.ClientID;
                                const newHandlerList = currUsers
                                  ?.filter((member) =>
                                    item?.Handler?.some(
                                      (filterHandler) =>
                                        filterHandler?.uid === member?.uid
                                    )
                                  )
                                  .map((data) => {
                                    const handlerData = item?.Handler?.find(
                                      (filterHandler) =>
                                        filterHandler?.uid === data?.uid
                                    );
                                    return {
                                      ...data,
                                      ...handlerData,
                                    };
                                  });

                                const newcurrUsers = currUsers?.filter(
                                  (member) =>
                                    !item?.Handler?.some(
                                      (filterHandler) =>
                                        filterHandler?.uid === member?.uid
                                    )
                                );
                                return (
                                  <div
                                    key={index}
                                    className="assigneesManagementContainer"
                                  >
                                    <div
                                      className={`assigneesContainer ${
                                        isExpand && "expanding"
                                      }`}
                                    >
                                      <span>
                                        {item?.ClientName}
                                        <span className="handlerCount">
                                          ({item?.Handler?.length ?? 0}{" "}
                                          {item?.Handler?.length > 1
                                            ? "Handlers"
                                            : "Handler"}
                                          )
                                        </span>
                                      </span>
                                      <div className="actionHandlerContainer">
                                        {isExpand && (
                                          <AiFillPlusSquare
                                            className="iconPlus"
                                            size={22}
                                            onClick={() => {
                                              if (isExpandMember) {
                                                setExpandMemberSection("");
                                              } else {
                                                setExpandMemberSection(
                                                  item?.ClientID
                                                );
                                              }
                                            }}
                                          />
                                        )}
                                        {isExpand ? (
                                          <AiFillCaretDown
                                            className="iconCaret"
                                            size={22}
                                            onClick={() => {
                                              setExpandMemberSection("");
                                              setExpandClientSection(
                                                expandClientSection.filter(
                                                  (filterExpand) =>
                                                    filterExpand !==
                                                    item?.ClientID
                                                )
                                              );
                                            }}
                                          />
                                        ) : (
                                          <AiFillCaretRight
                                            className="iconCaret"
                                            size={22}
                                            onClick={() => {
                                              setExpandMemberSection("");
                                              setExpandClientSection([
                                                ...expandClientSection,
                                                item?.ClientID,
                                              ]);
                                            }}
                                          />
                                        )}
                                      </div>
                                    </div>
                                    {isExpandMember && (
                                      <div className="expendMemberListContainer">
                                        {Array.isArray(newcurrUsers) &&
                                        newcurrUsers &&
                                        newcurrUsers?.length > 0 ? (
                                          newcurrUsers?.map(
                                            (handler, indexA) => {
                                              return (
                                                <div
                                                  key={indexA}
                                                  className="handler"
                                                >
                                                  <div className="profile">
                                                    <img
                                                      src={handler?.photoURL}
                                                      alt={handler?.displayName}
                                                    />
                                                    <span>
                                                      {handler?.displayName}
                                                    </span>
                                                  </div>
                                                  <span
                                                    className="add"
                                                    onClick={() => {
                                                      handleAssignHandler({
                                                        ClientID:
                                                          item?.ClientID,
                                                        UserID: handler?.uid,
                                                      });
                                                    }}
                                                  >
                                                    Add
                                                  </span>
                                                </div>
                                              );
                                            }
                                          )
                                        ) : (
                                          <div className="emptyAvailableMember">
                                            <span>No Member Available</span>
                                          </div>
                                        )}
                                      </div>
                                    )}
                                    {isExpand && (
                                      <div className="handlersContainer">
                                        {Array.isArray(newHandlerList) &&
                                        newHandlerList &&
                                        newHandlerList?.length > 0 ? (
                                          newHandlerList?.map(
                                            (handler, indexB) => {
                                              return (
                                                <div
                                                  key={indexB}
                                                  className="handler"
                                                >
                                                  <div className="profile">
                                                    <img
                                                      src={handler?.photoURL}
                                                      alt={handler?.displayName}
                                                    />
                                                    <span>
                                                      {handler?.displayName}
                                                    </span>
                                                  </div>
                                                  {currSa ? (
                                                    <span
                                                      className="remove"
                                                      onClick={() => {
                                                        handleRemoveHandler(
                                                          handler?.ClientAgentID
                                                        );
                                                      }}
                                                    >
                                                      Remove
                                                    </span>
                                                  ) : (
                                                    <></>
                                                  )}
                                                </div>
                                              );
                                            }
                                          )
                                        ) : (
                                          <div className="emptyHandler">
                                            <span>No Handler Assigned</span>
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                );
                              })
                          ) : (
                            <div></div>
                          )}
                        </div>
                      </div>
                      <div className="flowMapContainer">
                        {loadFlowChart &&
                        Array.isArray(selectedAgentHandler?.handler) &&
                        selectedAgentHandler?.handler &&
                        selectedAgentHandler?.handler.length > 0 ? (
                          <div
                            className={`loading-container ${
                              !loadFlowChart && "hide-loading-container"
                            }`}
                          >
                            <div className="loading-spinner"></div>
                          </div>
                        ) : (
                          <ReactFlow
                            nodes={selectedAgentHandler?.nodes ?? []}
                            edges={selectedAgentHandler?.edges ?? []}
                            // nodes={initialNodes}
                            // edges={initialEdges}
                            onLoad={onLoadFlowChart}
                            proOptions={proOptions}
                            fitView
                            ref={flowRef}
                          >
                            <Background />
                            <Controls>
                              <ControlButton
                                onClick={() => {
                                  if (flowRef.current === null) return;
                                  toPng(flowRef.current, {
                                    filter: (node) =>
                                      !(
                                        node?.classList?.contains(
                                          "react-flow__minimap"
                                        ) ||
                                        node?.classList?.contains(
                                          "react-flow__controls"
                                        )
                                      ),
                                    backgroundColor: "white",
                                  }).then((dataUrl) => {
                                    const a = document.createElement("a");
                                    a.setAttribute(
                                      "download",
                                      "Client_Handler_Flowchart.png"
                                    );
                                    a.setAttribute("href", dataUrl);
                                    a.click();
                                  });
                                }}
                              >
                                <BiDownload size={20} />
                              </ControlButton>
                            </Controls>
                          </ReactFlow>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div>
                      <span className="font-xs">
                        Please make sure that you check a client before you
                        assigning handler !
                      </span>
                    </div>
                  )}

                  {containsTrue ? (
                    <div className="actionContainer">
                      <C_DialogButton
                        onClick={() => {
                          hideAssignHandlerModal();
                          dispatch(setClientContext({
                            checkedClientState : [],
                            checkedAllClientState : false
                          }))
                          setSharedHandlerList([]);
                        }}
                        color={"#6d45b9"}
                        background={"white"}
                        borderColor={"#6d45b9"}
                        buttonText={"DISCARD"}
                      />
                      <C_DialogButton
                        onClick={() => {
                          hideAssignHandlerModal();
                          dispatch(setClientContext({
                            checkedClientState : [],
                            checkedAllClientState : false
                          }))
                          setSharedHandlerList([]);
                        }}
                        color={"#FFF"}
                        background={"#3eb986"}
                        borderColor={"#3eb986"}
                        buttonText={"COMPLETED"}
                      />
                    </div>
                  ) : (
                    <div className="d-flex justify-content-end mt-3">
                      <C_DialogButton
                        onClick={() => {
                          dispatch(setClientContext({
                            checkedClientState : [],
                            checkedAllClientState : false
                          }))
                          setSharedHandlerList([]);
                        }}
                        buttonText={"CONTINUE"}
                        dismissAction={"modal"}
                        background={"#4287f5 "}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* End Assign Handler Modal */}

          <ClientTransferModal 
            clientTransferRef={clientTransferRef}
            selectedClient={selectedClient}
            setSelectedClient={setSelectedClient}
            tempClient={tempClient}
            setNotifyMessage={setNotifyMessage}
          />

          <ClientManageModal
            availableMemberList={currUsers}
            currSa={currSa}
            // newClientRef={newClientRef}
            manageClientModalRef={manageClientModalRef}
            showHandlerOptions={showHandlerOptions}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            setMixedClients={setMixedClients}
            setSelectedClient={setSelectedClient}
            selectedClient={selectedClient}
            mixedClients={mixedClients}
            containsTrue={containsTrue}
            refreshUsage={refreshUsage}
            clientUsage={clientUsage}
            clientQuota={clientQuota}
            checkExistingClientData={checkExistingClientData}
            tenant={tenant}
            companyID={companyID}
            selectedGroupValue={selectedGroupValue}
            setIsLoading={setIsLoading}
            // hideModalFunc={hideModalFunc}
            hideManageClientModal={hideManageClientModal}
            setNotifyMessage={setNotifyMessage}
            selectedMemberList={selectedMemberList}
            setSelectedMemberList={setSelectedMemberList}
            // handleCreateSubGroupSingle={handleCreateSubGroupSingle}
            handleCreateClientAgentSingle={handleCreateClientAgentSingle}
            setLoadData={setLoadData}
            isSuperAdmin={isSuperAdmin}
            clientGroupLeader={clientGroupLeader}
            setShowHandlerOptions={setShowHandlerOptions}
            currUsers={currUsers}
            handleDeselectHandler={handleDeselectHandler}
            handleSelectHandler={handleSelectHandler}
            retrieveClientFunction={retrieveClientFunction}
            mixedSelectedClients={mixedSelectedClients}
            tempClient={tempClient}
            tempMixedClient={tempMixedClient}
            tempSelectedClient={tempSelectedClient}
            isEmailFormat={isEmailFormat}
            isRequiredEmpty={isRequiredEmpty}
            setIsEmailFormat={setIsEmailFormat}
            setIsRequiredEmpty={setIsRequiredEmpty}
            isEditEmailFormat={isEditEmailFormat}
            isEditRequiredEmpty={isEditRequiredEmpty}
            setIsEditEmailFormat={setIsEditEmailFormat}
            setIsEditRequiredEmpty={setIsEditRequiredEmpty}
            sharedHandlerList={sharedHandlerList}
            setSharedHandlerList={setSharedHandlerList}
            tempSelectedMemberList={tempSelectedMemberList}
            setTempSelectedMemberList={setTempSelectedMemberList}
            handleInsertClientAgent={handleInsertClientAgent}
            handleDeleteClientAgent={handleDeleteClientAgent}
            setLoadFlowChart={setLoadFlowChart}
            searchText={searchText}
            sortType={sortType}
            sortOrder={sortOrder}
            dataFilterArr={dataFilterArr}
          />
          {/* Start Delete Client Modal */}
          <div
            className="modal fade"
            tabIndex="-1"
            id="delete-client"
            ref={deleteClientRef}
            style={{}}
          >
            <div className="modal-dialog modal-dialog-centered" style={{}}>
              <div
                className="modal-content px-3"
                style={{ overflowY: "scroll" }}
              >
                <div className="modal-header modalHeader">
                  <div className="title">
                    <HiOutlineTrash size={16} color={"#ca4254"} />
                    <span className="font-s">Delete Client</span>
                  </div>
                  <AiOutlineClose
                    cursor="pointer"
                    size={16}
                    data-bs-dismiss="modal"
                    onClick={() => {
                      dispatch(setClientContext({
                        checkedClientState : [],
                      }))
                    }}
                  />
                </div>
                <div className="modal-body">
                  {containsTrue ? (
                    <>
                      <div className="">
                        <span
                          className="font-xs"
                          style={{ fontWeight: "bold" }}
                        >
                          Are you sure you want to delete this? This action can
                          not be reverted.
                        </span>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="">
                        <span className="font-xs">
                          Please make sure that you check a client before you
                          delete !
                        </span>
                      </div>
                    </>
                  )}

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      columnGap: "20px",
                    }}
                    className="mt-3 modalshit"
                  >
                    {containsTrue ? (
                      <>
                        <C_DialogButton
                          color={"#ca4254"}
                          background={"white"}
                          borderColor={"#ca4254"}
                          buttonText={"DISCARD"}
                          onClick={() => {
                            dispatch(setClientContext({
                              checkedClientState : [],
                              checkedAllClientState : false
                            }))
                            setSharedHandlerList([]);
                          }}
                          dismissAction={"modal"}
                        />
                        <C_DialogButton
                          color={"white"}
                          background={"#ca4254"}
                          borderColor={"#ca4254"}
                          buttonText={"DELETE"}
                          onClick={() => removeClient()}
                          dismissAction={"modal"}
                        />
                      </>
                    ) : (
                      <>
                        <C_DialogButton
                          buttonText={"CONTINUE"}
                          dismissAction={"modal"}
                          background={"#4287f5 "}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Delete Client Modal */}

          {/* Start Export Client Modal */}
          <div
            className="modal fade"
            tabIndex="-1"
            id="export-client"
            ref={exportModalRef}
            style={{}}
          >
            <div className="modal-dialog modal-dialog-centered" style={{}}>
              <div
                className="modal-content px-3"
                style={{ overflowY: "scroll" }}
              >
                <div className="modal-header modalHeader">
                  <div className="title">
                    <FiDownload size={17} color={"#4287f5"} />
                    <span className="font-s">Export Client</span>
                  </div>
                  <AiOutlineClose
                    cursor="pointer"
                    size={16}
                    data-bs-dismiss="modal"
                  />
                </div>
                <div className="modal-body">
                  <div className="">
                    <span className="font-xs" style={{ fontWeight: "bold" }}>
                      This action will export the data from current page. Are
                      you sure you want to continue? 
                      {!isSuperAdmin && !clientGroupLeader.some(
                          (element) => element.UserID === user?.uid
                      ) && 
                      <span className="font-xs" style={{ fontWeight: "bold", color:'red' }}>
                        {(() => {
                        const ViewableColumn = currUserClientGroupRole ? currUserClientGroupRole?.ViewableColumn : [];
                        const viewableColumnName = clientColumnArray?.filter((col) =>{
                          const notViewable = !ViewableColumn?.includes(col.columnID);
                          if(notViewable){
                            return col;
                          }
                        })
                        .map((col) => col.columnName);

                        return ` ${ViewableColumn?.length > 0 ? `You are restricted in exporting [${viewableColumnName?.join(', ')}]. It will be excluded in your exported CSV file.` : ""}`;
                        })()}
                      </span>
                      }
                    </span>
                  </div>
                  <div className="mt-3 export-client-modal">
                    <div className="export-handler-selection">
                      {(isSuperAdmin ||
                        clientGroupLeader.some(
                          (element) => element.UserID === user?.uid
                        )) && (
                        <select
                          onChange={(e) => {
                            dispatch(setClientContext({exportHandlerType : e.target.value}));
                            // setExportHandlerType({
                            //   type: "EXPORT_HANDLER_TYPE",
                            //   payload: e.target.value,
                            // });
                          }}
                        >
                          <option disabled selected>
                            Export handler as{" "}
                          </option>
                          <option value={"handlerName"}>Handler Name</option>
                          <option value={"handlerID"}>Handler ID</option>
                        </select>
                      )}
                    </div>
                    <div className="export-action">
                      <C_DialogButton
                        color={"#4287f5"}
                        background={"white"}
                        borderColor={"#4287f5"}
                        buttonText={"DISCARD"}
                        dismissAction="modal"
                      />
                      <CSVLink
                        className="dialog-btn export-button"
                        data={clientCsvData}
                        headers={clientCsvHeader}
                        filename={
                          moment(new Date()).format("YYYY-MM-DD HH:mm:ss") +
                          "-Client.csv"
                        }
                      >
                        <span style={{}}>EXPORT</span>
                      </CSVLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Export Client Modal */}

          {/**Rich Text */}
          <div
            className="modal fade modal-lg"
            tabIndex="-1"
            id="rte-modal"
            style={{}}
          >
            <div className="modal-dialog modal-dialog-centered" style={{}}>
              <div
                className="modal-content px-3"
                style={{ overflowY: "scroll" }}
              >
                <div className="modal-header modalHeader">
                  <div className="title">
                    <BsFonts size={17} color={"rgb(95, 94, 175)"} />
                    <span className="font-s">Preview</span>
                  </div>
                  <AiOutlineClose
                    cursor="pointer"
                    size={16}
                    data-bs-dismiss="modal"
                  />
                </div>
                <div className="modal-body">
                  <div className="card">
                    <div className="card-body">
                      {clientColArray.map((item, fragmentIdx) => {
                        if (
                          clientRteIndex !== "" ||
                          clientRteIndex !== undefined
                        ) {
                          return (
                            <Fragment key={fragmentIdx}>
                              {tempClient[clientRteIndex]?.ClientData.map(
                                (data, idx) => {
                                  if (item.columnID === data.columnID) {
                                    if (
                                      item.columnType === "Rich Text Editor"
                                    ) {
                                      return (
                                          <div
                                            key={idx}
                                            dangerouslySetInnerHTML={{
                                              __html: data.rowValue,
                                            }}
                                            style={{
                                              overflow: "scroll",
                                              height: "300px",
                                            }}
                                          />
                                      );
                                    }
                                  }
                                }
                              )}
                            </Fragment>
                          );
                        }
                      })}
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      columnGap: "20px",
                    }}
                    className="mt-3 modalshit"
                  >
                    <C_DialogButton
                      buttonText={"CONTINUE"}
                      dismissAction={"modal"}
                      background={"#4287f5 "}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/**End Text */}
          <ToastContainer />

          {/* Top Container */}
          {availableClientsGroup?.length > 0 && (
            <div className="client-top-container">
              <div className="count-container">
                <span
                  className="font-xs"
                  style={{ fontWeight: "bold", letterSpacing: "1px" }}
                >
                  {tempClient.length > 1 ? "Total Results:" : "Total Result:"}
                  {clientsCount[0]?.Total_Clients}
                </span>
              </div>

              {availableClientsGroup?.length > 0 && (
                <div className="group-section">
                  {currSa ? (
                    <C_Button
                      background={"#f8fafa"}
                      buttonText={"Assign Handler"}
                      width={window.innerWidth > 1500 ? "150px" : "130px"}
                      onClick={() => {
                        showAssignHandlerModal();
                      }}
                      justify={"center"}
                      color={"#000000"}
                      border={"1px solid"}
                      borderColor={"#BCBCBC"}
                    />
                  ) : (
                    <></>
                  )}

                  {currSa && (
                    <C_Button
                      background={"#f8fafa"}
                      buttonText={"Assign Leader"}
                      width={window.innerWidth > 1500 ? "140px" : "120px"}
                      onClick={() => {
                        showAssignLeaderModal();
                        dispatch(
                          retrieveAllClientGroupLeader({
                            TenantID: tenant,
                            GroupID: selectedGroupValue?.GroupID,
                          })
                        );
                      }}
                      justify={"center"}
                      color={"#000000"}
                      border={"1px solid"}
                      borderColor={"#BCBCBC"}
                    />
                  )}
                  <select
                    defaultValue={""}
                    value={selectedGroupValue?.GroupID}
                    className="form-select font-xs"
                    onChange={(e) => {
                      selectedGroup(e.target.value);
                      setSelectedClient([]);
                      dispatch(setClientContext({
                        checkedClientState : [],
                      }))
                    }}
                    style={{
                      alignItems: "center",
                      backgroundColor: Colors.standardBackground,
                      color: "#000000",
                      width: "180px",
                      marginRight: "5px",
                      borderRadius: "8px",
                      borderColor: "#BCBCBC",
                      letterSpacing: "0.75px",
                      height: "36px",
                    }}
                  >
                    <option value="" disabled>
                      Select a group
                    </option>
                    {Array.isArray(availableClientsGroup) &&
                      availableClientsGroup?.map((item, groupIndex) => {
                        return (
                          <>
                            <option key={groupIndex} value={item?.GroupID}>
                              {item?.GroupName}
                            </option>
                          </>
                        );
                      })}
                  </select>
                </div>
              )}
            </div>
          )}
          {/* Main Container */}
          <div
            className="client-mid-container"
            style={{ width: "100%", overflow: "hidden" }}
          >
            <div className="client-mid-action-container">
              {availableClientsGroup?.length > 0 && (
                <>
                  <div className="action-filter-container">
                    <div className="search-client-container">
                      <C_Search
                        height={"35px"}
                        placeholder={`Search from ${clientsCount[0]?.Total_Clients} results`}
                        onChange={(e) => {
                          setSearchText(e.target.value);
                        }}
                        value={searchText}
                        searchText={searchText}
                        closeFunction={() => {
                          setSearchText("");
                          handleClearQuery();
                        }}
                        borderRadius={"5px"}
                        containerWidth={"100%"}
                      />
                      <C_Button
                        title={"Search Client"}
                        // background={Colors.generalButtonColor}
                        width={"45px"}
                        justify={"center"}
                        onClick={() => {
                          handleSearchQuery()
                        }}
                        marginLeft={"0.5rem"}
                        paddingLeft={"0.5rem"}
                        paddingRight={"0.5rem"}
                        color={"#FFF"}
                        // textColor={"#FFFFFF"}
                        border={"0px"}
                        background={"#434343"}
                        icon={<HiOutlineMagnifyingGlass size={16} />}
                      />
                    </div>
                    <div className="sort-client-container">
                      <C_Button
                        title={"Sort"}
                        marginLeft={"0.5rem"}
                        onClick={() => openSortModal()}
                        width={"45px"}
                        background={"#f8fafa"}
                        justify={"center"}
                        color={"#000000"}
                        icon={<BiSortAlt2 size={14} />}
                        border={"1px solid"}
                        borderColor={"#BCBCBC"}
                        padding={"0.8rem"}
                      />
                      {sortModal === true ? (
                        <>
                          <div
                            ref={sortModalRef}
                            className="sort-card"
                            style={{
                              position: "absolute",
                              borderRadius: "7px",
                              width: "250px",
                              zIndex: "10",
                            }}
                          >
                            <div className="sort-card-body">
                              <span className="font-m">Sort</span>
                              <hr
                                style={{ margin: "0px", marginBottom: "8px" }}
                              ></hr>
                              <select
                                value={sortType}
                                className="form-select sort-card-input font-xxs"
                                onChange={(e) => {
                                  setSortType(e.target.value);
                                }}
                                style={{ marginBottom: "10px" }}
                              >
                                <option selected disabled value="">
                                  Type
                                </option>

                                {clientColArray.map((col, colIndex) => {
                                  if (col.columnName !== "ClientID")
                                    return (
                                      <option
                                        key={colIndex}
                                        value={col.columnIndex}
                                      >
                                        {col.columnName}
                                      </option>
                                    );
                                })}
                              </select>

                              <select
                                value={sortOrder}
                                className="form-select sort-card-input font-xxs"
                                onChange={(e) => {
                                  setSortOrder(e.target.value);
                                }}
                                style={{ marginBottom: "20px" }}
                              >
                                <option selected disabled value="">
                                  Order
                                </option>

                                {orderArray.map((arr, arrIndex) => {
                                  return (
                                    <option key={arrIndex} value={arr.id}>
                                      {arr.name}
                                    </option>
                                  );
                                })}
                              </select>

                              <div className="sort-button">
                                <C_Button
                                  buttonText={"Sort"}
                                  background={Colors.generalButtonColor}
                                  width={"40%"}
                                  onClick={() => {
                                    handleSortClientData();
                                  }}
                                  justify={"center"}
                                  textColor={"#FFFFFF"}
                                  gap={"10px"}
                                  icon={<BiSort size={16} />}
                                />
                                <C_Button
                                  title={"Clear"}
                                  buttonText={"Clear"}
                                  background={"#FFF"}
                                  width={"40%"}
                                  onClick={() => {
                                    handleClearSortClientData();
                                  }}
                                  justify={"center"}
                                  color={"#000"}
                                  border={"1px solid #000"}
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="multiple-filter-button">
                      {dataFilterArr.length > 0 && 
                       <div className="total-filter-count">
                        {dataFilterArr.length}
                       </div>
                      }
                     
                      <C_Button
                        title={"Filter"}
                        background={"#f8fafa"}
                        width={"45px"}
                        justify={"center"}
                        color={"#000000"}
                        icon={<BsToggles size={14} />}
                        marginLeft={"0.5rem"}
                        border={"1px solid"}
                        borderColor={"#BCBCBC"}
                        onClick={() =>{
                            showFilterModal();
                            onHandleToggleFilterRow(false);
                          }
                        }
                      />
                    </div>
                  </div>
                </>
              )}
              {availableClientsGroup?.length > 0 && (
                <div className="action-container">
                  <div className="column-button-container">
                    <C_Button
                      title={"Column Filter"}
                      buttonText={"Column"}
                      background={"#f8fafa"}
                      width={"100px"}
                      justify={"center"}
                      color={"#000000"}
                      gap={"10px"}
                      icon={<TbTableOptions size={18} />}
                      onClick={() => openFilterColModal()}
                    />
                    {filterColumn === true && (
                      <>
                        <div ref={columnsModalRef} className="column-filters">
                          <div className="filters-title">
                            <span
                              className="font-m"
                              style={{ padding: "0px", margin: "0px" }}
                            >
                              Column
                            </span>
                            <span
                              className="remark-text deselect"
                              style={{ padding: "0px", margin: "0px" }}
                              onClick={() => {
                                deselectAllColumns();
                              }}
                            >
                              Deselect All
                            </span>
                          </div>

                          <hr
                            style={{
                              padding: "0px",
                              margin: "0px",
                              marginBottom: "3px",
                            }}
                          ></hr>
                          <ul
                            className="list-group filter-group"
                            style={{
                              borderRadius: "10px",
                              gap: "5px",
                              textTransform: "capitalize",
                            }}
                          >
                            {clientColumnArray
                            // .filter((col) => currUserClientGroupRole ? 
                            // currUserClientGroupRole.ViewableColumn.includes(col.columnID) : 
                            // col)
                            .map((col, colIndex) => {
                              if (
                                col.columnName !== "ClientID" &&
                                col.columnName !== "DepartmentID" &&
                                col.columnName !== "PosID"
                              ) {
                                return (
                                  //row[item.columnName]
                                  <li
                                    key={colIndex}
                                    className="list-group-items"
                                    style={{ listStyleType: "none" }}
                                  >
                                    <label
                                      style={{
                                        cursor: "pointer",
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <input
                                        type="checkbox"
                                        style={{
                                          width: "15px",
                                          height: "15px",
                                        }}
                                        className="col-md-3"
                                        value={col.columnName}
                                        checked={
                                          selectedCol.includes(col.columnID)
                                        }
                                        onChange={(e) => {
                                          handleSelectedCol(e, col.columnID);
                                        }}
                                      />
                                      <span className="checkmark"></span>
                                      <span
                                        className="col-md-9 font-xxs"
                                        style={{
                                          paddingLeft: "10px",
                                          textOverflow: "ellipsis",
                                        }}
                                      >
                                        {col.columnName}
                                      </span>
                                    </label>
                                  </li>
                                );
                              }
                            })}
                          </ul>
                        </div>
                      </>
                    )}
                  </div>

                  <div className="separate-line">&nbsp;</div>
                  {(isSuperAdmin || Client_Manage) && selectedGroupValue ? (
                    <C_Button
                      title={"Create"}
                      width={"45px"}
                      background={"#f8fafa"}
                      onClick={async () => {
                        refreshUsage();
                        if (clientUsage < clientQuota) {
                          handleNewClient();
                          showManageClientModal();
                        } else {
                          showExceedModalFunc();
                        }
                      }}
                      justify={"center"}
                      color={"#000000"}
                      icon={<HiOutlineUserPlus size={18} />}
                      border={"1px solid"}
                      borderColor={"#BCBCBC"}
                      padding={"0.8rem"}
                    />
                  ) : (
                    <></>
                  )}

                  {isSuperAdmin || Client_Manage ? (
                    <C_Button
                      title={"Update"}
                      width={"45px"}
                      background={"#f8fafa"}
                      border={"1px solid"}
                      borderColor={"#BCBCBC"}
                      onClick={() => {
                        onHandleSetEdit();
                        showManageClientModal();
                      }}
                      justify={"center"}
                      color={"#000000"}
                      icon={<BiEditAlt size={16} />}
                      padding={"0.8rem"}
                    />
                  ) : (
                    <></>
                  )}

                  {isSuperAdmin || Client_Delete ? (
                    <C_Button
                      title={"Delete"}
                      width={"45px"}
                      background={"#f8fafa"}
                      border={"1px solid"}
                      borderColor={"#BCBCBC"}
                      justify={"center"}
                      color={"#000000"}
                      icon={<HiOutlineTrash size={17} />}
                      onClick={()=>{showDeleteModal()}}
                      // targetID={"delete-client"}
                      // toggleAction={"modal"}
                      padding={"0.8rem"}
                    />
                  ) : (
                    <></>
                  )}
                   {isSuperAdmin || Client_Transfer ? (
                    <C_Button
                        title={"Transfer"}
                        width={"45px"}
                        background={"#f8fafa"}
                        border={"1px solid"}
                        borderColor={"#BCBCBC"}
                        justify={"center"}
                        color={"#000000"}
                        icon={<HiOutlineArrowsRightLeft size={17} />}
                        onClick={()=>{
                          showTransferModal();
                        }}
                        // targetID={"delete-client"}
                        // toggleAction={"modal"}
                        padding={"0.8rem"}
                        marginRight={"0.3rem"}
                      />
                   ): <></>}
                </div>
              )}
            </div>
            <div className="filter-chips-container">
              <div className="chips-outer-container">
                <div className="chips-inner-container">
                  {dataFilterArr.map((data, idx) => {
                    const columnObj = clientColumnArray?.find(item => item.columnIndex === data.filterType);
                    if(columnObj !== undefined){
                      const columnName = columnObj.columnName;
                      const columnType = columnObj.columnType;
                      if(columnType === "Date") {
                        const typeObj = typeof data.filterValue === "object";
                        return (
                          <div key={idx} className="chips-box" title={typeObj ? `${columnName} : ${`${data.filterValue.startDate} - ${data.filterValue.endDate}`}` : `${columnName} : ${data.filterValue}`}>
                            <div className="chips-details">
                              {typeObj ? `${columnName}: ${`${data.filterValue.startDate} - ${data.filterValue.endDate}`}` : `${columnName}: ${`${data.filterValue}`}`}
                            </div>
                            <div className="delete-btn">
                              <HiOutlineXMark color="#8D8D8D" size={15} cursor={"pointer"} onClick={() => {onHandleRemoveFilterList(data.filterValue)}}/>
                            </div>
                          </div>
                        )
                      } else if(typeof data.filterValue === "object" && columnType === "Numbers"){
                        const action = data.action;
                        const typeObj = typeof data.filterValue === "object";
                        return (
                          <div className="chips-box" title={
                            action === "Search"
                            ? `${columnName} : ${data.filterValue}`
                            : action === "Filter"
                            ? typeObj ? `${columnName} : ${data.filterValue.minimum} ~ ${data.filterValue.maximum}` : `${columnName}: ${`${data.filterValue}`}`
                            : ""
                          }>
                            <div className="chips-details">
                              {action === "Search" && `${columnName} : ${data.filterValue}`}
                              {(action === "Filter" && typeObj) && `${columnName} : ${data.filterValue.minimum} ~ ${data.filterValue.maximum}`}
                              {(action === "Filter" && !typeObj) && `${columnName} : ${data.filterValue}`}
                            </div>
                            <div className="delete-btn">
                              <HiOutlineXMark color="#8D8D8D" size={15} cursor={"pointer"} onClick={() => {onHandleRemoveFilterList(data.filterValue)}}/>
                            </div>
                          </div>
                        )
                      } 
                      else if(columnType === "Alert") {
                        return (
                          <div className="chips-box" title={`${columnName !== undefined ? columnName : ""}: ${data.filterValue}`}>
                            <div className="chips-details">
                              {`${columnName}: ${data.filterValue}`}
                            </div>
                            <div className="delete-btn">
                              <HiOutlineXMark color="#8D8D8D" size={15} cursor={"pointer"} onClick={() => {onHandleRemoveFilterList(data.filterValue)}}/>
                            </div>
                          </div>
                        )
                      }
                      else {
                        return (
                          <div className="chips-box" title={`${columnName !== undefined ? columnName : ""}: ${data.filterValue}`}>
                            <div className="chips-details">
                              {`${columnName}: ${data.filterValue}`}
                            </div>
                            <div className="delete-btn">
                              <HiOutlineXMark color="#8D8D8D" size={15} cursor={"pointer"} onClick={() => {onHandleRemoveFilterList(data.filterValue)}}/>
                            </div>
                          </div>
                        )
                      }
                    }

                    if(data.filterType === "Creator"){
                      const userObj = currUserSA.find(member => member.uid === data.filterValue);
                      const action = data.action;
                      return (
                        <div className="chips-box" title={
                          action === "Search"
                          ? `Creator : ${data.filterValue}`
                          : action === "Filter"
                          ? `Creator : ${userObj !== undefined ? userObj.displayName : data.filterValue}`
                          : ""
                        }>
                          <div className="chips-details">
                            {action === "Search" && `Creator : ${data.filterValue}`}
                            {action === "Filter" &&  `Creator : ${userObj !== undefined ? userObj.displayName : data.filterValue}`}
                          </div>
                          <div className="delete-btn">
                            <HiOutlineXMark color="#8D8D8D" size={15} cursor={"pointer"} onClick={() => {onHandleRemoveFilterList(data.filterValue)}}/>
                          </div>
                        </div>
                      )
                    }

                    if(data.filterType === "Handler"){
                      const handlerObj = currUsers.find(member => member.uid === data.filterValue);
                      const action = data.action;
                      return (
                        <div className="chips-box" title={
                          action === "Search"
                          ? `Handler : ${data.filterValue}`
                          : action === "Filter"
                          ? `Handler : ${handlerObj !== undefined ? handlerObj.displayName : data.filterValue}`
                          : ""
                        }>
                          <div className="chips-details">
                            {action === "Search" && `Handler : ${data.filterValue}`}
                            {action === "Filter" &&  `Handler : ${handlerObj !== undefined ? handlerObj.displayName : data.filterValue}`}
                          </div>
                          <div className="delete-btn">
                            <HiOutlineXMark color="#8D8D8D" size={15} cursor={"pointer"} onClick={() => {onHandleRemoveFilterList(data.filterValue)}}/>
                          </div>
                        </div>
                      )
                    }
                    
                    if(data.filterType === "CreatedAt"){
                      const typeObj = typeof data.filterValue === "object";
                      const startDate = typeObj ? moment(data.filterValue.startDate).format("YYYY-MM-DD HH:mm:ss") : "";
                      const endDate = typeObj ? moment(data.filterValue.endDate).format("YYYY-MM-DD HH:mm:ss") : "";
                      return (
                        <div className="chips-box" title={typeObj ? `CreatedAt : ${`${startDate} - ${endDate}`}` : `CreatedAt : ${data.filterValue}`}>
                          <div className="chips-details">
                            {typeObj ? `CreatedAt : ${`${startDate} - ${endDate}`}` : `CreatedAt : ${data.filterValue}`}
                          </div>
                          <div className="delete-btn">
                            <HiOutlineXMark color="#8D8D8D" size={15} cursor={"pointer"} onClick={() => {onHandleRemoveFilterList(data.filterValue)}}/>
                          </div>
                        </div>
                      )
                    }

                  })}
                </div>
              </div>
              <div className="button-container">
              {/* "#CACACA */}
              {availableClientsGroup?.length > 0 &&
                <>
                  <C_Button
                    buttonText={"Apply Filter"}
                    background={Colors.generalButtonColor}
                    width={window.innerWidth > 1500 ? "100px" : "80px"}
                    justify={"center"}
                    textColor={"#FFFFFF"}
                    onClick={onHandleApplyFilter}
                  />
                  <C_Button
                    buttonText={"Clear"}
                    background={Colors.generalButtonColor}
                    width={window.innerWidth > 1500 ? "100px" : "80px"}
                    justify={"center"}
                    textColor={"#FFFFFF"}
                    marginRight={"0.4rem"}
                    onClick={onHandleClearFilter}
                  />
                </>
                
              }
              </div>
            </div>
            <ClientTable
              handleManageClient={handleManageClient}
              isSuperAdmin={isSuperAdmin}
              Client_Manage={Client_Manage}
              Client_Delete={Client_Delete}
              companyID={companyID}
              selectedGroup={selectedGroupValue?.GroupID} 
              availableMemberList={currUsers} 
              isSa={isSuperAdmin} 
              tableLoader={loadData} 
              CurrPage={currentPage}
              dataFilterArr={dataFilterArr}
              showTable={availableClientsGroup?.length > 0 ? true : false}
              retrievalDataCount={{
                TenantID: tenant,
                CompanyID: companyID,
                SearchText: searchText,
                SearchBy: searchBy,
                UserID: user?.uid,
                CurrGroup: selectedGroupValue?.GroupID,
                isSA: currSa,
                SelectedDateColumn: selectedDateColumn,
                StartDate: moment(startDate).format("YYYY-MM-DD"),
                EndDate: moment(endDate).format("YYYY-MM-DD"),
                DataFilterArr: dataFilterArr,
              }}
              retrievalData={{
                TenantID: tenant,
                CompanyID: companyID,
                CurrPage: 0,
                SortType: sortType,
                SortOrder: sortOrder,
                SearchText: searchText,
                SearchBy: searchBy,
                UserID: user?.uid,
                CurrGroup: selectedGroupValue?.GroupID,
                isSA: currSa,
                SelectedDateColumn: selectedDateColumn,
                StartDate: moment(startDate).format("YYYY-MM-DD"),
                EndDate: moment(endDate).format("YYYY-MM-DD"),
                DataFilterArr: dataFilterArr,
              }}
              rowFilterData = {rowFilterData}
              setRowFilterData = {onHandleRowFilterData}
              setDataFilterArr = {onHandleDataFilter}
              tempSelectedFilter = {tempSelectedFilter}
              setTempSelectedFilter = {onHandleTempSelectedRowFilter}
              filterRow = {filterRow}
              setFilterRow = {onHandleToggleFilterRow}
              selectAllFilter = {selectAllFilter}
              setSelectAllFilter = {setSelectAllFilter}
              serialNumberFilterRow = {serialNumberFilterRow}
              setSerialNumberFilterRow = {setSerialNumberFilterRow}
              createdDateFilterRow = {createdDateFilterRow}
              setCreatedDateFilterRow = {setCreatedDateFilterRow}
              handlerFilterRow = {handlerFilterRow}
              setHandlerFilterRow = {setHandlerFilterRow}
              filterHandlerList = {filterHandlerList}
              setFilterHandlerList = {setFilterHandlerList}
              tempClientMergedData = {tempClientMergedData}
              tableScrollRef = {tableScrollRef}
              filterConditionArr = {filterConditionArr}
              onHandleFilterConditionArr = {onHandleFilterConditionArr}
              onHandleNumberRangeInput = {onHandleNumberRangeInput}
              onHandleSelectedDateFilter = {onHandleSelectedDateFilter}
              onHandleSearchFilter = {onHandleSearchFilter}
              onHandleClearSearchFilter = {onHandleClearSearchFilter}
              onHandleSelectAllRowFilter = {onHandleSelectAllRowFilter}
              handleSelectedRow = {handleSelectedRow}
              handleSelectedFilterHandler = {handleSelectedFilterHandler}
              isCreatedByFilterOpen = {isCreatedByFilterOpen}
              setIsCreatedByFilterOpen = {setIsCreatedByFilterOpen}
              handleSelectedCreator = {handleSelectedCreator}
            />
          </div>

          {/* Bottom Container */}
          <div
            className="client-bottom-container"
            style={{ flex: 1, overflow: "hidden" }}
          >
            {availableClientsGroup?.length > 0 && (
              <div
                className="paginate-container"
              >
                <ReactPaginate
                  containerClassName="paginationStyle"
                  activeClassName="activePage"
                  pageClassName="paginationElementStyle"
                  breakLabel="..."
                  nextLabel={<MdNavigateNext size={20} />}
                  onPageChange={(page) => {
                    onHandlePageChange(page.selected);
                  }}
                  pageRangeDisplayed={5}
                  pageCount={
                    clientsCount &&
                    clientsCount.length > 0 &&
                    Math.ceil(clientsCount[0].Total_Clients / 500)
                  }
                  previousLabel={<MdNavigateBefore size={20} />}
                  renderOnZeroPageCount={null}
                  forcePage={currentPage}
                />
              </div>
            )}
            <div className="import-export-container">
              {availableClientsGroup?.length > 0 &&
              (isSuperAdmin || Client_Manage) &&
              selectedGroupValue ? (
                <C_Button
                  border={"1px solid #000"}
                  width={"120px"}
                  gap={"10px"}
                  buttonText={"Import"}
                  justify={"center"}
                  onClick={() => {
                    showCsvModal();
                  }}
                  icon={<FiUpload size={16} />}
                  marginRight={"0.5rem"}
                />
              ) : (
                <></>
              )}
              {availableClientsGroup?.length > 0 &&
              (isSuperAdmin || Client_Export) ? (
                <C_Button
                  border={"1px solid #000"}
                  width={"120px"}
                  gap={"10px"}
                  buttonText={"Export"}
                  justify={"center"}
                  onClick={() => {showExportModal()}}
                  textColor={"#FFFFFF"}
                  icon={<FiDownload size={16} />}
                  marginRight={"0.5rem"}
                />
              ) : (
                <></>
              )}
            </div>
          </div>
          {/* End Bottom Container */}
        </div>
        {/* End Main Container */}
      </div>
    </Fragment>
  );
}

export default Clients;
