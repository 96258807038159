import React from "react";
import { Grid, Badge } from "@mui/material";
import {
  InputTextPopover,
  InputDate,
  InputSwitch,
  InputDropdownSearch,
} from "../../../components/Input";
import PropTypes from "prop-types";
import { PopoverFilterForm, PopoverButton } from "../../../components/Utils";
export const TableTools = ({
  onStartDateFilter,
  onEndDateFilter,
  onQuickFilter,
  onAdvanceFilter,
  onInactiveFilter,
  isInactive = false,
  quickFilterColumns = [],
  advanceFilterOptions = [],
  advanceFilterForm = {},
  startDate,
  endDate,
  onCustomFieldFilter = null,
  typeFilterOptions = null,
  onTypesFilter = null,
}) => {
  let debounceTimer;

  const accumulatedForm = Object.keys(advanceFilterForm).reduce(
    (result, formKey) => {
      const form = advanceFilterForm[formKey];
      const formAppliedLength = Object.keys(form).filter((key) => {
        return form[key] !== undefined && form[key];
      }).length;

      return result + formAppliedLength;
    },
    0
  );

  return (
    <Grid
      container
      alignItems="center"
      // marginBottom={1}
      spacing={2}
      width={0.6}
    >
      {onStartDateFilter ? (
        <Grid key={"date_filter_start"} item xs={4}>
          <InputDate
            name="date_filter_start"
            label={"Start Date"}
            value={startDate}
            maxDate={endDate}
            onChange={onStartDateFilter}
          />
        </Grid>
      ) : null}

      {onEndDateFilter ? (
        <Grid key={"date_filter_end"} item xs={4}>
          <InputDate
            value={endDate}
            minDate={startDate}
            name="date_filter_end"
            label={"End Date"}
            onChange={onEndDateFilter}
          />
        </Grid>
      ) : null}

      {onAdvanceFilter ? (
        <Grid key={"advanced_filter"} item xs={2}>
          <Badge badgeContent={accumulatedForm} color="primary">
            {
              <PopoverButton
                variant="contained"
                buttonChild={"Filter"}
                anchor={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                popoverClassName={"table_filter"}
              >
                {
                  <PopoverFilterForm
                    options={advanceFilterOptions}
                    onSubmit={onAdvanceFilter}
                    form={advanceFilterForm}
                  />
                }
              </PopoverButton>
            }
          </Badge>
        </Grid>
      ) : null}

      {onQuickFilter ? (
        <Grid key={"quick_filter"} item xs={4}>
          <InputTextPopover
            name="testsearchinput"
            label={"Search in "}
            popoverOptions={quickFilterColumns}
            onChange={(event) => {
              clearTimeout(debounceTimer);
              debounceTimer = setTimeout(() => {
                const value = event.target.value;
                onQuickFilter(value);
              }, 700);
            }}
          />
        </Grid>
      ) : null}

      {typeFilterOptions !== null && onTypesFilter !== null
        ? Object.keys(typeFilterOptions).map((type) => {
            const inputOptions = typeFilterOptions[type];
            return (
              <Grid key={`filter_by_${type}`} item xs={4}>
                <InputDropdownSearch
                  name={`filter_by_${type}`}
                  label={inputOptions.label}
                  options={inputOptions.options}
                  groupBy={(option) =>
                    inputOptions.group ? option[inputOptions.group] : null
                  }
                  onChange={(event) => {
                    const value = typeof event === "string" ? event : "";

                    onTypesFilter(value, type);
                  }}
                />
              </Grid>
            );
          })
        : null}

      {onCustomFieldFilter ? (
        <Grid key={"custom_fields_filter"} item xs={4}>
          <InputTextPopover
            name="customFieldInput"
            label={"Custom Field"}
            popoverOptions={[]}
            onChange={(event) => {
              clearTimeout(debounceTimer);
              debounceTimer = setTimeout(() => {
                const value = event.target.value;
                onCustomFieldFilter(value);
              }, 700);
            }}
          />
        </Grid>
      ) : null}

      {onInactiveFilter ? (
        <Grid key={"inactive_filter"} item xs={4}>
          <InputSwitch
            value={isInactive}
            type="active-only"
            sx={{ marginLeft: "8px !important" }}
            onChange={onInactiveFilter}
          />
        </Grid>
      ) : null}
    </Grid>
  );
};

TableTools.propTypes = {
  onStartDateFilter: PropTypes.any,
  onEndDateFilter: PropTypes.any,
  onAdvanceFilter: PropTypes.any,
  onInactiveFilter: PropTypes.any,
  isInactive: PropTypes.any,
  quickFilterColumns: PropTypes.any,
  advanceFilterOptions: PropTypes.any,
  advanceFilterForm: PropTypes.any,
  startDate: PropTypes.any,
  endDate: PropTypes.any,
  onCustomFieldFilter: PropTypes.any,
  typeFilterOptions: PropTypes.any,
  onTypesFilter: PropTypes.any,
  onQuickFilter: PropTypes.func,
  onDateFilter: PropTypes.func,
};
