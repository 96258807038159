const component = {
  MuiFilledInput: {
    styleOverrides: {
      root: ({ theme }) => ({
        ...theme.typography.bodyText,
        color: theme.palette.text.main,
        background: "none",
        border: `1px solid ${theme.palette.border.main}`,
        borderRadius: "6px",
        paddingBottom: "0px",
        // height: "55px",
        "&:hover": {
          background: "none",
          border: `1px solid ${theme.palette.border.main}`, // Rewrite default effect with current border
        },
        "&.Mui-focused": {
          background: "none",
          border: `2px solid ${theme.palette.border.active}`,
        },
        "&::before": {
          border: "none",
          content: "unset",
        },
        "&::after": {
          border: "none",
        },
        "&.Mui-error": {
          border: `1px solid ${theme.palette.snack.error}`,
        },
        "&.MuiFilledInput-root.Mui-disabled":{
          backgroundColor: `${theme.palette.action.disabled}`,
        },
        "&.MuiFilledInput-root.Mui-disabled:hover":{
          backgroundColor: `${theme.palette.action.disabled}`,
        },
        "&.Mui-disabled .MuiInputAdornment-positionEnd": {
          color: theme.palette.text.disabled,
        },
      }),
      input: ({ theme }) => ({
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: "0px",
        "&:focus": {
          background: "none",
        },
      }),
    },
  },
};

export default component;
