import React, { useMemo } from "react";
import { InputAdornment, Typography } from "@mui/material";
import { CalendarMonthOutlined, WarningRounded } from "@mui/icons-material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { InputTextType } from "../../enums/components/InputTextType";

export const InputDate = ({
  type,
  textType = InputTextType.Normal,
  name,
  isDisabled,
  value = new Date(),
  onChange,
  inputProps,
  startAdornment,
  multiline = false,
  minWidth,
  maxWidth,
  fullWidth = true,
  label,
  required,
  fieldRef,
  error,
  helperText,
  hidden,
  maxDate,
  minDate,
  disableDateIcon = false,
  variant = null,
  disabledErrorText,
}) => {
  const handleChange = (event) => {
    onChange(event);
  };

  // Handle jest test ID and text transformation
  const setTextTransform = useMemo(() => {
    if (textType === InputTextType.Uppercase) return "uppercase";
    if (textType === InputTextType.Capitalize) return "capitalize";
    return "unset";
  }, [textType]);

  const inputTextProps = {
    style: {
      textTransform: setTextTransform,
      paddingTop: label === "Date" || label === "Due Date" || label === "Start Date" || label === "End Date" ? "20px" : "0px",
    },
    "data-testid": `${name}TextInput`,
    ...inputProps,
  };

  // Handle render helper text and error text
  const helperHandling = () => {
    if (helperText && !error)
      return (
        <Typography
          className="helper"
          variant="remark"
          color={({ palette }) => palette.text.light}
          sx={{ marginLeft: "10px" }}
        >
          {helperText}
        </Typography>
      );
    if (error && !disabledErrorText)
      return (
        <Typography
          variant="bodyText"
          color={({ palette }) => palette.snack.error}
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <WarningRounded
            sx={({ palette, icons }) => ({
              ...icons.standard,
              color: palette.snack.error,
              pointerEvents: "none",
            })}
          />
          {error}
        </Typography>
      );
    return null;
  };

  const startAdornmentComponent = startAdornment ? (
    <InputAdornment position="start" sx={{ marginLeft: "5px" }}>
      {startAdornment}
    </InputAdornment>
  ) : null;

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        sx={({ icons }) => ({
          minWidth: minWidth,
          maxWidth: maxWidth,
          button: { marginRight: "-5px" },
          svg: { ...icons.standard },
        })}
        maxDate={maxDate}
        minDate={minDate}
        format="DD/MM/YYYY"
        slots={
          disableDateIcon
            ? {}
            : {
                openPickerIcon: CalendarMonthOutlined,
              }
        }
        closeOnSelect={true}
        value={value}
        onChange={handleChange}
        slotProps={{
          textField: {
            helperText: helperHandling(),
            variant: variant ? variant : "filled",
            size: "medium",
            InputProps: {
              style: { height: "55px" },
              startAdornment: startAdornmentComponent,
            },
            autoComplete: "off",
            hidden: hidden,
            type: type,
            error: !!error,
            multiline: multiline,
            fullWidth: fullWidth,
            label: label,
            required: required,
            inputRef: fieldRef,
            disabled: isDisabled,
            onChange: handleChange,
            inputProps: inputTextProps,
          },
          field: {
            clearable: false,
          },
        }}
      />
    </LocalizationProvider>
  );
};

