//react
import React from 'react';

import './ClientGroup.scss'
import ClientColumn from './ClientColumn';
import { selectClientContext } from '../../stores/slices/clientSlices';

function ClientGroupManageTable(props) {
    
    const {
        selectedGroup, 
    } = selectClientContext();

    function isJSON(str) {
        try {
          JSON.parse(str);
        } catch (e) {
          return false;
        }
        return true;
    }

    const ColumnData = isJSON(selectedGroup?.ColumnData) ? JSON.parse(selectedGroup?.ColumnData) : selectedGroup?.ColumnData;

  return (
    <>
    <div className='clientGroupTableMainContainer'>
        <ClientColumn group={props.group} CurrentGroup={selectedGroup} ColumnData={ColumnData} action={props.action} reminderRef={props.reminderRef} groupID={props.groupID} hideModal={props.hideModal} groupTab={props.groupTab} handleSelectTab={props.handleSelectTab}/>
    </div>
    </>
  )

}

export default ClientGroupManageTable