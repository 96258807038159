import React from "react";

import { TransactionsColumn } from "../Transaction/transactions";
import { TableCell } from "../../TableCell";
import { TableFilterOperator } from "../../TableFilterOperator";

import { Stack } from "@mui/material";
import { TableAction } from "../../TableAction";
import { TableActionEnum } from "../../../../enums/TableActionEnum";

export const SalesOrdersColumn = (onClick) => [
  ...TransactionsColumn.GENERAL_INFO,
  ...TransactionsColumn.BILLING_INFO,

  ...TransactionsColumn.TRANSACTION_INFO,
  {
    field: "amount",
    headerName: "Amount",
    type: "string",
    sortable: true,
    flex: 1,
    minWidth: 150,
    disableColumnMenu: true,
    isVisible: true,

    renderCell: (params) => {
      return TableCell.AMOUNT(params);
    },
  },
  {
    field: "status",
    headerName: "Status",
    type: "string",
    sortable: true,
    flex: 1,
    minWidth: 120,
    disableColumnMenu: true,
    isVisible: true,

    renderCell: (params) => TableCell.TAG(params),
    filterOperators: [
      TableFilterOperator.EXCLUDE_VOID,
      TableFilterOperator.TRANSACTION_INCLUDE_ITEM,
    ],
  },
  {
    field: "custom_fields",
    headerName: "custom_fields",
    type: "string",
    renderCell: (params) => TableCell.STRING(params),
    filterOperators: [
      TableFilterOperator.INCLUDE_ITEM,
      TableFilterOperator.CUSTOM_FIELD_FILTER,
    ],
  },
  {
    field: "action",
    headerName: "Action",
    sortable: false,
    disableHide: true,
    flex: 1,
    minWidth: 220,
    renderCell: (params) => {
      const transactionStatus = params.row.status;
      const currSa = params.row.currSa;
      const currRoles = params.row.currRoles[0];
      
      return (
        <Stack
          sx={{
            alignItems: "center",

            // justifyContent: "start",
            // padding: "0",
          }}
          direction="row"
          spacing={1}
        >
          {/* {TableAction.EMAIL(params, onClick)}
          {TableAction.TRANSFER(params, onClick)}
          {TableAction.FILES(params, onClick)} */}
           {(currSa || currRoles?.Invoice_Edit) ? TableAction.VIEW(params, onClick): null}
           {(currSa || currRoles?.Invoice_Send || currRoles?.Invoice_Create) ? TableAction.OPTIONS([
              currRoles?.Invoice_Create &&
              TableAction.OPTION(
                "Duplicate",
                () => onClick[TableActionEnum.DUPLICATE].function(params),
                onClick
                  ? onClick[TableActionEnum.DUPLICATE].disabled
                    ? onClick[TableActionEnum.DUPLICATE].disabled(params)
                    : false
                  : false
              ),
              currRoles?.Invoice_Send &&
              TableAction.OPTION(
                "Share",
                () => onClick[TableActionEnum.SHARE].function(params),
                onClick
                  ? onClick[TableActionEnum.SHARE].disabled
                    ? onClick[TableActionEnum.SHARE].disabled(params)
                    : false
                  : false
              ),
              currRoles?.Invoice_Send &&
              TableAction.OPTION(
                "Send Email",
                () => onClick[TableActionEnum.SEND].function(params),
                onClick
                  ? onClick[TableActionEnum.SEND].disabled
                    ? onClick[TableActionEnum.SEND].disabled(params)
                    : false
                  : false
              ),
            ].filter(Boolean)) : null
          }

        </Stack>
      );
    },
  },
];
