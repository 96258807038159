import React from "react";
import './C_Loading.scss'

const C_Loading = (props) => {
    return (
        <div className="loading-container" style={{ display: props.isLoading ? "flex" : "none" }}>
            <div className="flex-column ">
                <img src={require("../assets/img/loadingGif.gif")} alt=''/>
            </div>
            
        </div>
    )
}

export default C_Loading;