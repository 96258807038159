import React, {useEffect, useState, useRef} from 'react'
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import { selectAnalyticsContext } from '../../../stores/slices/analyticsSlices';
import { selectCurrUserContext } from '../../../stores/slices/companySlices';

function Graph_Bar(props) {

  const {
    analytics,
    graphData,
  } = selectAnalyticsContext();

  const editorState = props.editorState;
  const graphRef = useRef(null);
  const [graphWidth, setGraphWidth] = useState(0);
  const [graphHeight, setGraphHeight] = useState(0);

  const yAxis = analytics.yAxis;
  const xAxis = analytics.xAxis;
  const Unit = analytics.Unit;
  const Label = analytics.Label;

  useEffect(() => {
    setGraphWidth(graphRef.current?.clientWidth * 0.9);
    setGraphHeight(graphRef.current?.clientHeight * 0.85);
  },[xAxis, yAxis, graphData, editorState]);

const { currUsers } = selectCurrUserContext();

  const renderBar = () => {

    const bar = yAxis.map((value, index) => {
      if(value.key === "Handler"){
        const userObj = currUsers.find(user => user.uid === value.id);
        if(userObj){
          return  <Bar key={index} dataKey={userObj.displayName} fill={value?.color} barSize={value?.barWidth} stackId={value?.barStack ? "stacked" : null}/>
        }
      } else {
        return (
          <Bar key={index} dataKey={value?.key} fill={value?.color} barSize={value?.barWidth} stackId={value?.barStack ? "stacked" : null}/>
      );
      }
    })
    
    return bar;
  };

  const renderXAxis = () => {
      const x = xAxis?.map((value, index) => {
        if(value){
          return(
            <XAxis key={index} 
              dataKey={value?.key} 
              angle={value?.angle} 
              tickSize={value?.tickSize} 
              minTickGap={value?.minTickGap}
              padding={{ left: value?.paddingLeft, right: value?.paddingRight }}
            />
          )
        }
      })
  
      return x
    
  };

  return (
    <div className='graphContainer' ref={graphRef}>
      <BarChart
          width={graphWidth}
          height={graphHeight}
          data={graphData}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <YAxis unit={Unit ? Unit : ""} label={{ value: Label ? Label : "", angle: -90, position: 'insideLeft' }}/>
          <Tooltip />
          <Legend />
          {renderXAxis()}
          {renderBar()}
        </BarChart>
    </div>
  )
}

export default Graph_Bar