import React, {
    useState,
    useEffect,
  } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import C_Button from "../../components/C_Button";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "./Appointments.css";
import "./Appointment.scss";
import { useDispatch} from "react-redux";
import Colors from "../../constants/Colors";
import {
  BsFillCaretRightFill,
} from "react-icons/bs";
import { v4 } from "uuid";
import { BsFillCaretLeftFill } from "react-icons/bs"
import "./Appointment.scss";
import C_Loading from "../../components/C_Loading";

//slices
import { retrieveAppointment, selectAppointments, setAppointmentContext, updateAppointment } from "../../stores/slices/appointmentsSlices";
import { createLogs } from "../../stores/slices/logSlices";
import { retrieveClientSelected } from "../../stores/slices/clientSelectorSlices";
import { selectCompanyID } from "../../stores/slices/companyIDSlices";
import { selectTenant } from "../../stores/slices/tenantSlices";
import { selectCurrTenant } from "../../stores/slices/persistRolesSlices";
import { selectUser } from "../../stores/slices/userAuthSlices";

function AppointmentCalendar(props) {

  const isSuperAdmin = props.isSuperAdmin;
  const Appointment_Manage = props.Appointment_Manage;

  const DnDCalendar = withDragAndDrop(Calendar);
  const localizer = momentLocalizer(moment);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const user = selectUser();

  const companyID = selectCompanyID();
  const tenant = selectCurrTenant();
  const appointments = selectAppointments();

  const [currPage, setCurrPage] = useState(moment().format('DD/MM/YYYY'));

  const [allAppointment, setAllAppointment] = useState([]);

  useEffect(() => {

      const filterAppointment = appointments?.filter(item => {
        let isAttendees;
        const formatedArray = typeof item?.memberAttendees === "string" ? JSON.parse(item?.memberAttendees) : item?.memberAttendees;
        
        if(Array.isArray(formatedArray))
        {
          isAttendees = formatedArray?.find(item => item.uid === user?.uid);
        }
        
        if(isAttendees || item.organizer === user?.uid){
            return item
        }

    })
    setAllAppointment(filterAppointment)
  },[appointments])

    function notify(data){
      dispatch(setAppointmentContext({
        triggerAction : data
      }))
      // dispatchTriggerAction({type: "TRIGGER", payload: data})
      setTimeout(() => {
        dispatch(setAppointmentContext({
          triggerAction : ''
        }))
          // dispatchTriggerAction({type: "TRIGGER", payload: ''})
      }, 1500)
    };

    function getContrastColor(hexColor) {
      // Remove the # from the beginning of the color string
      const cleanHex = hexColor.replace("#", "");
    
      // Convert the color string to RGB values
      const red = parseInt(cleanHex.substr(0, 2), 16);
      const green = parseInt(cleanHex.substr(2, 2), 16);
      const blue = parseInt(cleanHex.substr(4, 2), 16);
    
      // Convert RGB to HSL
      const hslValues = rgbToHsl(red, green, blue);
    
      // Get the lightness value from the HSL representation
      const lightness = hslValues[2];
    
      // Use white font color if the lightness is less than or equal to 0.5, and gray font color otherwise
      return lightness <= 0.5 ? "#fff" : "#000";
  }

  // Utility function to convert RGB to HSL
  function rgbToHsl(r, g, b) {
      r /= 255;
      g /= 255;
      b /= 255;
      const max = Math.max(r, g, b);
      const min = Math.min(r, g, b);
      let h, s, l = (max + min) / 2;
  
      if (max == min) {
      h = s = 0;
      } else {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
          case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
          case g:
          h = (b - r) / d + 2;
          break;
          case b:
          h = (r - g) / d + 4;
          break;
      }
      h /= 6;
      }
  
      return [h, s, l];
    }

    const onSelectedEvent = (event) => {
      setCurrPage(moment(event?.start).format('DD/MM/YYYY'));
      dispatch(retrieveClientSelected({ TenantID:tenant,selectedClient: event.clientAttendees }));
      dispatch(setAppointmentContext({
        edit : true,
        currAppointment : {
          AppointmentID: event.AppointmentID,
          title: event.title,
          description: event.description,
          start: moment(event.start).format('YYYY-MM-DDTHH:mm'),
          end: moment(event.end).format('YYYY-MM-DDTHH:mm'),
          organizer: event.organizer,
          memberAttendees: JSON.parse(event.memberAttendees),
          clientAttendees: JSON.parse(event.clientAttendees),
          AppointmentVenue: event.AppointmentVenue,
          AppointmentColor: event.AppointmentColor,
          AppointmentStatus: event.AppointmentStatus
        },
        tempCurrAppointment : 
         JSON.stringify({
            AppointmentID: event.AppointmentID,
            title: event.title,
            description: event.description,
            start: moment(event.start).format('YYYY-MM-DDTHH:mm'),
            end: moment(event.end).format('YYYY-MM-DDTHH:mm'),
            organizer: event.organizer,
            memberAttendees: JSON.parse(event.memberAttendees),
            clientAttendees: JSON.parse(event.clientAttendees),
            AppointmentVenue: event.AppointmentVenue,
            AppointmentColor: event.AppointmentColor,
            AppointmentStatus: event.AppointmentStatus,
        })
      }))
    
      // dispatchEdit({type: 'EDIT', payload: true});
      // dispatchCurrAppointment({type: "CHANGE_CURR_APPOINTMENT", payload: {
      //   AppointmentID: event.AppointmentID,
      //   title: event.title,
      //   description: event.description,
      //   start: moment(event.start).format('YYYY-MM-DDTHH:mm'),
      //   end: moment(event.end).format('YYYY-MM-DDTHH:mm'),
      //   organizer: event.organizer,
      //   memberAttendees: JSON.parse(event.memberAttendees),
      //   clientAttendees: JSON.parse(event.clientAttendees),
      //   AppointmentVenue: event.AppointmentVenue,
      //   AppointmentColor: event.AppointmentColor,
      //   AppointmentStatus: event.AppointmentStatus
      // }})

      // setTempCurrAppointment({
      //   type: "CHANGE_TEMP_CURR_APPOINTMENT", payload: JSON.stringify({
      //   AppointmentID: event.AppointmentID,
      //   title: event.title,
      //   description: event.description,
      //   start: moment(event.start).format('YYYY-MM-DDTHH:mm'),
      //   end: moment(event.end).format('YYYY-MM-DDTHH:mm'),
      //   organizer: event.organizer,
      //   memberAttendees: JSON.parse(event.memberAttendees),
      //   clientAttendees: JSON.parse(event.clientAttendees),
      //   AppointmentVenue: event.AppointmentVenue,
      //   AppointmentColor: event.AppointmentColor,
      //   AppointmentStatus: event.AppointmentStatus
      // })})
    
    };

    const eventStyleGetter = (data) => {
        return {
            style: {
            margin: '2px 0px 2px 0px',
            padding: '5px 10px 5px 10px',
            background: 'rgb(255,255,255)',
            background: data.AppointmentColor,
            color: getContrastColor(data.AppointmentColor),
            fontSize: window.innerWidth > 1500 ? '15px' : '13px',
            fontWeight: '400',
            display: "flex",
            alignItems: "center",
            borderRadius: "3px",
            overflow: 'scroll'
            },
        };
    };

    const onEventResize = (params) => {
      setIsLoading(true)
      const { title } = params.event;
      const { AppointmentID } = params.event;
      const { start, end } = params;
      setCurrPage(moment(start).format('DD/MM/YYYY'));

      var body = {
        AppointmentID: AppointmentID,
        start: moment(start).format("YYYY-MM-DD HH:mm:ss"),
        end: moment(end).format("YYYY-MM-DD HH:mm:ss")
      }

      dispatch(updateAppointment({TenantID:tenant,data:body}))
        const ActivityLogsID = v4();
        const UserID = user?.uid;
        const CompanyID = companyID;
        const GroupID = "";
        const Title = "Appointment duration has been extended";
        const Activity = `extended the duration of the event (${title})`;
        const Action = "update";
        const Section = "Appointments";
        const Keyword = title;
        const CreatedAt = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");

        const data = {ActivityLogsID,UserID,CompanyID,GroupID,Title,Activity,Action,Section,Keyword,CreatedAt}
        
        dispatch(createLogs({TenantID:tenant,data:data}))
       
      setTimeout(() => {
        notify("UPDATED")
        setIsLoading(false);
        dispatch(retrieveAppointment({TenantID:tenant,CompanyID:companyID}));
      }, 1500)

    };
    
    const onEventDrop = (params) => {

      setIsLoading(true)
      const { title } = params.event;
      const { AppointmentID } = params.event;
      const { start, end } = params;
      
      setCurrPage(moment(start).format('DD/MM/YYYY'));
  
      var body = {
        AppointmentID: AppointmentID,
        start: moment(start).format("YYYY-MM-DD HH:mm:ss"),
        end: moment(end).format("YYYY-MM-DD HH:mm:ss")
      }

      dispatch(updateAppointment({TenantID:tenant,data:body}))
        const ActivityLogsID = v4();
        const UserID = user?.uid;
        const CompanyID = companyID;
        const GroupID = "";
        const Title = "Appointment has been reschedule";
        const Activity = `rescheduled the event (${title})`;
        const Action = "update";
        const Section = "Appointments";
        const Keyword = title;
        const CreatedAt = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");

        const data = {ActivityLogsID,UserID,CompanyID,GroupID,Title,Activity,Action,Section,Keyword,CreatedAt}

      dispatch(createLogs({TenantID:tenant,data:data}))

      setTimeout(() => {
        notify("UPDATED");
        setIsLoading(false);
        dispatch(retrieveAppointment({TenantID:tenant,CompanyID:companyID}));
      }, 1500)

    };

    const onSelectNewEventBySlot = (date) => {
  
      // setIsEditingEvent(false)
      // setHeader("New Event")
      // setTitle('');
      // setAppointmentColor('');
      // setAppointmentStatus('');
      // setStart(moment(date.start).format("YYYY-MM-DD HH:mm"));
      // setEnd(moment(date.end).format("YYYY-MM-DD HH:mm"));
      // setAppointmentVenue('');
      // setAppointmentDescription(RichTextEditor.createEmptyValue());
  
      // showModalFunc();
    
    };
    
      const CustomToolbar = (toolbar) => {
        const goToBack = () => { toolbar.onNavigate('PREV'); };
        const goToNext = () => { toolbar.onNavigate('NEXT'); };
        const goToCurrent = () => { toolbar.onNavigate('TODAY'); };
      
        const label = () => {
          const date = moment(toolbar.date);
          return (
            <span><b>{date.format('MMMM')}</b><span> {date.format('YYYY')}</span></span>
          );
        };
        return (
          <div className={'custom-toolbar-container'}>
            <label className={'custom-label-date'}>{label()}</label>
      
            <div className={'custom-toolbar-btn-container'}>
              <C_Button
                background={Colors.generalButtonColor}
                width={"35px"}
                justify={"center"}
                textColor={"#FFFFFF"}
                icon={<BsFillCaretLeftFill size={14}/>}
                marginLeft={"0.5rem"}
                onClick={goToBack}
              />
              <C_Button
                background={Colors.generalButtonColor}
                buttonText={"TODAY"}
                width={"120px"}
                justify={"center"}
                textColor={"#FFFFFF"}
                marginLeft={"0.5rem"}
                onClick={goToCurrent}
              />
              <C_Button
                background={Colors.generalButtonColor}
                width={"35px"}
                justify={"center"}
                textColor={"#FFFFFF"}
                icon={<BsFillCaretRightFill size={14}/>}
                marginLeft={"0.5rem"}
                onClick={goToNext}
              />
            </div>
          </div>
        );
      };
    
      const AppointmentDateHeader = ({ label }) => {
        return (
          <div className="custom-date-header">
              <span>{label}</span>
          </div>
        );
      };
    
      const MonthHeader = ({ label }) => {
        return (
          <div className="custom-month-header">
              <span>{label}</span>
          </div>
        )
      }

  return (
    <>
    <C_Loading isLoading={isLoading}/>
    <DnDCalendar
      views={['month']}
      components={{
          toolbar: CustomToolbar,
          month: {
          dateHeader: AppointmentDateHeader,
          header: MonthHeader
          }
      }}
      onSelectEvent={(data) => {
      
          onSelectedEvent(data);
        
      }}
      defaultDate={moment(currPage, 'DD/MM/YYYY').toDate()}
      defaultView="month"
      events={allAppointment}
      localizer={localizer}
      onSelectSlot={(date) => {
        if(isSuperAdmin || Appointment_Manage){
          onSelectNewEventBySlot(date);
        }
      }}
      onEventDrop={(data) => {
        if(isSuperAdmin || Appointment_Manage){
          if(data.event.organizer === user?.uid){
            onEventDrop(data);
          }
        }
      }}
      onEventResize={(data) => {
        if(isSuperAdmin || Appointment_Manage){
          if(data.event.organizer === user?.uid){
            onEventResize(data);
          }
        }
      }}
      popup={true}
      onShowMore={(events, date) => console.log(events)}
      selectable={isSuperAdmin || Appointment_Manage}
      resizable={isSuperAdmin || Appointment_Manage}
      style={{ height: "80vh", width: "100%" }}
      className="calendar-container"
      eventPropGetter={(params) => eventStyleGetter(params)}
    />
    </>
  )
}

export default AppointmentCalendar