import {
    IconButton,
    Stack,
    Tooltip,
    Divider,
    Typography,
    styled,
    tooltipClasses,
  } from "@mui/material";
  import {
    VisibilityOutlined,
    DeleteOutlineRounded,
    MoreVert,
    EditOutlined,
    ContentCopyRounded,
    MailOutlineRounded,
    SyncAltRounded,
    AttachFileRounded,
    ForwardToInboxRounded,
  } from "@mui/icons-material";
  import * as React from "react";
//   import { PopoverIconButton, PopoverChildrenButton } from "@components/Utils";
//   import { InputSwitch } from "@components/Input";
//   import { FormInputOpeningBalance } from "@components/FormInput";
import { TableActionEnum } from "../../enums/TableActionEnum";
import { PopoverChildrenButton } from "../../components/Utils/PopoverChildrenButton";
import { PopoverIconButton } from "../../components/Utils/PopoverIconButton";
  
  export const TableAction = {
    //! onclick ? validation is just for temporary only
    VIEW: (params, onClick) => (
      <Tooltip title={"View"} placement="top" arrow>
        <IconButton
          onClick={() => {
            onClick ? onClick[TableActionEnum.VIEW].function(params) : () => {};
          }}
          disabled={
            onClick
              ? onClick[TableActionEnum.VIEW].disabled
                ? onClick[TableActionEnum.VIEW].disabled(params)
                : false
              : false
          }
          sx={({ icons }) => ({
            ...icons.standard.default,
          })}
          size="small"
        >
          <VisibilityOutlined fontSize="inherit" />
        </IconButton>
      </Tooltip>
    ),
    EDIT: (params, onClick) => (
      <Tooltip title={"Edit"} placement="top" arrow>
        <IconButton
          onClick={() => {
            onClick ? onClick[TableActionEnum.EDIT].function(params) : () => {};
          }}
          disabled={
            onClick
              ? onClick[TableActionEnum.EDIT].disabled
                ? onClick[TableActionEnum.EDIT].disabled(params)
                : false
              : false
          }
          sx={({ icons }) => ({
            ...icons.standard.default,
          })}
          size="small"
        >
          <EditOutlined fontSize="inherit" />
        </IconButton>
      </Tooltip>
    ),
    DELETE: (params, onClick) => {
      return (
        <Tooltip
          title={
            onClick[TableActionEnum.DELETE].disabled(params)
              ? onClick[TableActionEnum.DELETE].disabledTooltip
              : "Delete"
          }
          placement="top"
          arrow
        >
          {/* <Tooltip title={"Delete"} placement="top" arrow> */}
          <span>
            <IconButton
              onClick={() =>
                onClick
                  ? onClick[TableActionEnum.DELETE].function(params)
                  : () => {}
              }
              disabled={
                onClick
                  ? onClick[TableActionEnum.DELETE].disabled
                    ? onClick[TableActionEnum.DELETE].disabled(params)
                    : false
                  : false
              }
              sx={({ icons }) => ({
                ...icons.standard.default,
              })}
              size="small"
            >
              <DeleteOutlineRounded fontSize="inherit" />
            </IconButton>
          </span>
        </Tooltip>
      );
    },
    OPTIONS: (options) => (
      <Tooltip title={"More Options"} placement="top" arrow>
        <div>
          <PopoverIconButton
            buttonChild={
              <MoreVert
                sx={({ icons }) => ({
                  ...icons.standard.default,
                })}
              />
            }
          >
            {options}
          </PopoverIconButton>
        </div>
      </Tooltip>
    ),
  
    OPTION: (label, onClick, isDisabled) => (
      <PopoverChildrenButton
        key={`popover_children_button_${label}`}
        label={label}
        onClick={onClick}
        disabled={isDisabled}
      />
    ),

  
    DEACTIVE: (params, onClick, is_active) => (
      <Stack spacing={1}>
        {/* <InputSwitch
          value={is_active}
          type="active"
          onChange={(event) => {
            const value = event.target.checked;
            onClick
              ? onClick[TableActionEnum.DEACTIVE].function(params, value)
              : () => {};
          }}
          disabled={
            onClick
              ? onClick[TableActionEnum.DEACTIVE].disabled
                ? onClick[TableActionEnum.DEACTIVE].disabled(params)
                : false
              : false
          }
        /> */}
      </Stack>
    ),
    DUPLICATE: (params, onClick) => (
      <Tooltip title={"Duplicate"} placement="top" arrow>
        <IconButton
          onClick={
            onClick
              ? onClick[TableActionEnum.DUPLICATE].function(params)
              : () => {}
          }
          disabled={
            onClick
              ? onClick[TableActionEnum.DUPLICATE].disabled
                ? onClick[TableActionEnum.DUPLICATE].disabled(params)
                : false
              : false
          }
          sx={({ icons }) => ({
            ...icons.standard.default,
          })}
          size="small"
        >
          <ContentCopyRounded fontSize="inherit" />
        </IconButton>
      </Tooltip>
    ),
    //todo style override
    EMAIL: (params, onClick) => {
      const LightTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
      ))(({ theme }) => ({
        //todo put it in override
        [`& .${tooltipClasses.tooltip}`]: {
          minWidth: "100px",
          backgroundColor: theme.palette.primary.white,
          boxShadow:
            " rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px",
        },
        [`& .${tooltipClasses.arrow}`]: {
          color: theme.palette.primary.white,
        },
      }));
      return (
        <LightTooltip
          className="email_tooltip"
          title={
            <Stack>
              <Typography
                variant="bodyText"
                sx={({ typography }) => ({
                  ...typography.bodyText,
                  fontWeight: "bold",
                  margin: "5px",
                })}
              >
                {"Not Sent"}
              </Typography>
              <Divider />
              <Typography
                variant="bodyText"
                sx={({ typography }) => ({
                  ...typography.bodyText,
                  margin: "10px",
                })}
              >
                {"No Data"}
              </Typography>
            </Stack>
          }
          placement="top"
          arrow
        >
          <IconButton
            onClick={() => {
              onClick
                ? onClick[TableActionEnum.EMAIL].function(params)
                : () => {};
              // handleTooltipOpen();
            }}
          >
            <MailOutlineRounded
              sx={({ icons }) => ({
                ...icons.standard.default,
              })}
            />
          </IconButton>
        </LightTooltip>
      );
    },
    TRANSFER: (params, onClick) => (
      <Tooltip title={"Not transfer"} placement="top" arrow>
        <IconButton
          onClick={() => {
            onClick
              ? onClick[TableActionEnum.TRANSFER].function(params)
              : () => {};
          }}
        >
          <SyncAltRounded
            sx={({ icons }) => ({
              ...icons.standard.default,
            })}
          />
        </IconButton>
      </Tooltip>
    ),
    FILES: (params, onClick) => (
      <Tooltip title={"0 Files"} placement="top" arrow>
        <IconButton
          onClick={() => {
            onClick ? onClick[TableActionEnum.FILES].function(params) : () => {};
          }}
        >
          <AttachFileRounded
            sx={({ icons }) => ({
              ...icons.standard.default,
              transform: "rotate(45deg)",
            })}
          />
        </IconButton>
      </Tooltip>
    ),
    INVITATION: (params, onClick) => (
      <Tooltip title={"Resend Invitation"} placement="top" arrow>
        <IconButton
          onClick={() => {
            onClick[TableActionEnum.INVITATION].function(params);
          }}
        >
          <ForwardToInboxRounded
            sx={({ icons }) => ({
              ...icons.standard.default,
            })}
          />
        </IconButton>
      </Tooltip>
    ),
    NUMBER_INPUT: (params, control, setValue, totalValue, setTotalValue) => {
      return (
        <Stack direction="row" spacing={1} alignItems="end">
          <Typography variant="remark">{"RM"}</Typography>
          {/* <FormInputOpeningBalance
            control={control}
            type="number"
            name={`${params.field}___${params.row.account_id}`}
            setValue={setValue}
            totalValue={totalValue}
            setTotalValue={setTotalValue}
          /> */}
        </Stack>
      );
    },
  };
  