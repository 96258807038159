const component = {
  MuiAccordion: {
    styleOverrides: {
      root: ({ theme }) => ({
        color: "unset",
        backgroundColor: theme.palette.primary.white,
        boxShadow: "unset",
        paddingBlock: "10px",
        borderBottom: `2px solid ${theme.palette.border.light}`,
        "&:before": {
          display: "none",
        },
        "& .MuiAccordion-contained": {
          border: `1px solid ${theme.palette.border.main}`,
          borderRadius: "5px",
          paddingInline: "20px",
          backgroundColor: theme.palette.background.containedBg,
        },
        "& .disable-touch": {
          cursor: "default !important",
          // backgroundColor: "transparent",
        },
      }),
    },
  },
};

export default component;
