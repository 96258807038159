const standardGrey = '#EEEEEE'
const standardBackground = '#FBFBFB'
const generalButtonColor = '#434343'
const calendarButtonColor = '#089FA9'
const purpleButton = '#4F3FB1'
const greenButton = '#3F9421'
const redButton = '#A10606'

const Colors = {
    standardGrey,
    standardBackground,
    generalButtonColor,
    calendarButtonColor,
    purpleButton,
    greenButton,
    redButton
}

export default Colors;
