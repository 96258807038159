import React, {
    Component,
    useState,
    useEffect,
    useRef,
    useCallback,
} from "react";
import moment from "moment";
import C_Button from "../../components/C_Button";
import "./Kpi.scss";
import { useDispatch} from "react-redux";
import { useNavigate } from "react-router-dom";
import C_Search from "../../components/C_Search";
import { MdRemoveCircleOutline } from "react-icons/md";
import { Modal } from "bootstrap";
import { BiArrowBack } from "react-icons/bi";
import { TbListDetails } from "react-icons/tb";
import { BsClipboardData, BsFillInfoCircleFill } from "react-icons/bs";
import { AiFillCheckCircle, AiOutlineCheckCircle, AiOutlineClose, AiOutlineStock } from "react-icons/ai";
import { CgUserList } from "react-icons/cg";

import Switch from "react-switch";
import C_Loading from "../../components/C_Loading";
import C_DialogButton from "../../components/C_DialogButton";
import { HiOutlineTrash } from "react-icons/hi2";
import { HiOutlinePencilAlt } from "react-icons/hi";

//slices
import { updateKpi, deleteKpi, selectKpiContext, setKpiContext } from "../../stores/slices/kpiSlices";
import { retrieveCurrUsage } from "../../stores/slices/usageSlices";
import { selectCompanyID } from "../../stores/slices/companyIDSlices";
import { selectCurrTenant } from "../../stores/slices/persistRolesSlices";
import { selectFormTemplate } from "../../stores/slices/formTemplateSlices";
import { selectCurrUserContext } from "../../stores/slices/companySlices";
import { selectUser } from "../../stores/slices/userAuthSlices";
  
  function EditKpi(props) {

    const isSuperAdmin = props.isSuperAdmin;
    const Kpi_Delete = props.Kpi_Delete;

    const {
        viewKpi,
        viewKpiData,
    } = selectKpiContext();

    const sourceData = selectFormTemplate();

    const modalRef = useRef();
    const deleteModalRef = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = selectUser();
    const [searchText, setSearchText] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const companyID = selectCompanyID();
    const tenant = selectCurrTenant();

    const { currUsers } = selectCurrUserContext();
    const [memberList, setMemberList] = useState([]);
    const [fixedMemberList, setFixedMemberList] = useState([]);
    const [selectedMemberID, setSelectedMemberID] = useState([]);
    const [selectedMemberList, setSelectedMemberList] = useState([]);
    const [validationError, setValidationError] = useState(false);
    const [metricValueOptions, setMetricValueOptions] = useState(null);
    const [showInfoMetricValue, setShowInfoMetricValue] = useState(false);
    const [showInfoDueDate, setShowInfoDueDate] = useState(false);
    const [showInfoTeamContribution, setShowInfoTeamContribution] = useState(false);

    async function refreshUsage(){
        await Promise.resolve(dispatch(retrieveCurrUsage({TenantID:tenant,CompanyID:companyID})));
    };

    useEffect(() => {
        const selectedSourceData = sourceData.find(obj => obj.FormID === viewKpiData?.item?.sourceOfData);
        setMetricValueOptions(selectedSourceData)
        
    },[viewKpiData?.item])

    useEffect(() => {
        const uid = viewKpiData?.item?.targetMembers?.map(item => { return item.uid })
        setSelectedMemberID(uid);
    },[viewKpi])

    useEffect(() => {
        const filteredData = currUsers
        const searchedData = filteredData.filter(item => {
            if(item.displayName.toString().toLocaleLowerCase().includes(searchText.toString().toLocaleLowerCase())){
                return item
            }
        })

        const sortedData = searchedData.sort((a, b) => { 
            if(a.uid === user?.uid) return -1;
            if(b.uid === user?.uid) return 1;
            return a.displayName?.localeCompare(b.displayName);
        })

        setFixedMemberList(filteredData);
        setMemberList(sortedData);

    }, [currUsers, selectedMemberID, searchText]);

    useEffect(() => {

        const filteredSelectedData = fixedMemberList.filter(obj => selectedMemberID.includes(obj.uid));
        setSelectedMemberList(filteredSelectedData)

    },[fixedMemberList, selectedMemberID]);

    useEffect(() => {
        dispatch(setKpiContext({viewKpiData : 
            {...viewKpiData, item: { ...viewKpiData.item, targetMembers: selectedMemberList }}
        }));
    },[selectedMemberList])

    const showModalFunc = () => {
        const modalEle = modalRef.current;
        const bsModal = new Modal(modalEle, {
          backdrop: "static",
          keyboard: false,
        });
        bsModal.show();
    };
    
    const hideModalFunc = () => {
        const modalEle = modalRef.current;
        const bsModal = Modal.getInstance(modalEle);
        bsModal.hide();
    };

    const showDeleteModalFunc = () => {
        const modalEle = deleteModalRef.current;
        const bsModal = new Modal(modalEle, {
          backdrop: "static",
          keyboard: false,
        });
        bsModal.show();
    };

    const hideDeleteModalFunc = () => {
        const modalEle = deleteModalRef.current;
        const bsModal = Modal.getInstance(modalEle);
        bsModal.hide();
    };

    function notify(param){
        dispatch(setKpiContext({triggerAction : param}));
        setTimeout(() => {
            dispatch(setKpiContext({triggerAction : ''}));
        }, 1500)
    };

    function validation(){
        if(
            viewKpiData.item.title !== "" &&
            viewKpiData.item.startDate !== "" &&
            viewKpiData.item.dueDate !== "" &&
            viewKpiData.item.sourceOfData !== "" &&
            viewKpiData.item.measuredBy !== "" &&
            viewKpiData.item.target !== ""
        ){
            if(viewKpiData.item.measuredBy !== "Submission"){
                if(viewKpiData.item.metricValue !== ""){
                    setValidationError(false);
                    return true
                }else{
                    setValidationError(true);
                    return false
                }
            }else{
                setValidationError(false);
                return true
            }
        }else{
            setValidationError(true);
            return false
        }
    }

    function handleUpdateKPI(){

        const proceed = validation();
        if(proceed){
            setIsLoading(true);

            const finalizedData = {
                ...viewKpiData.item,
                startDate : moment(viewKpiData.item.startDate).format("YYYY-MM-DD HH:mm:ss"),
                dueDate : moment(viewKpiData.item.dueDate).format("YYYY-MM-DD HH:mm:ss"),
                createdAt : moment(viewKpiData.item.dueDate).format("YYYY-MM-DD HH:mm:ss"),
                targetMembers: JSON.stringify(viewKpiData.item.targetMembers),
            }

            dispatch(updateKpi({TenantID:tenant,data:finalizedData}))

            setTimeout(() => {
                notify("UPDATED KPI");
                dispatch(setKpiContext(
                    {viewKpiData : 
                        {
                            item: {
                                KpiID: "",
                                title: "",
                                startDate: moment().format("YYYY-MM-DD"),
                                dueDate: moment().format("YYYY-MM-DD"),
                                definition: "",
                                sourceOfData: "",
                                measuredBy: "",
                                metricValue: "",
                                target: "",
                                unitOfMeasurement: "",
                                targetMembers: [],
                                teamContribution: false,
                                createdAt: "",
                                assignedBy: "",
                                percentageWidth: "",
                                totalValue: "",
                                percentage: ""
                            },
                            percentage: "",
                            percentageWidth: "",
                            totalValue: ""
                        },
                        viewKpi : false,
                    }
                ))
                setIsLoading(false);
            }, 2000)
        }

    }

    function handleInput({type, value}){
        switch(type){
            case "title":
                dispatch(setKpiContext({viewKpiData : 
                    {...viewKpiData, item: { ...viewKpiData.item, title: value }}
                }))
                break;
            case "startDate":
                dispatch(setKpiContext({viewKpiData : 
                    {...viewKpiData, item: { ...viewKpiData.item, startDate: value }}
                }))
                break;
            case "dueDate":
                dispatch(setKpiContext({viewKpiData : 
                    {...viewKpiData, item: { ...viewKpiData.item, dueDate: value }}
                }))
                break;
            case "definition":
                dispatch(setKpiContext({viewKpiData : 
                    {...viewKpiData, item: { ...viewKpiData.item, definition: value }}
                }))
                break;
            case "sourceOfData":
                dispatch(setKpiContext({viewKpiData : 
                    {...viewKpiData, item: { ...viewKpiData.item, sourceOfData: value, measuredBy: "", metricValue: "" }}
                }))
                const selectedSourceData = sourceData.find(obj => obj.FormID === value);
                setMetricValueOptions(selectedSourceData)
                break;
            case "measuredBy":
                dispatch(setKpiContext({viewKpiData : 
                    {...viewKpiData, item: { ...viewKpiData.item, measuredBy: value, metricValue: "" }}
                }))
                break;
            case "metricValue":
                dispatch(setKpiContext({viewKpiData : 
                    {...viewKpiData, item: { ...viewKpiData.item, metricValue: value }}
                }))
                break;
            case "target":
                dispatch(setKpiContext({viewKpiData : 
                    {...viewKpiData, item: { ...viewKpiData.item, target: value }}
                }))
                break;
            case "unitOfMeasurement":
                dispatch(setKpiContext({viewKpiData : 
                    {...viewKpiData, item: { ...viewKpiData.item, unitOfMeasurement: value }}
                }))
                break;
            case "teamContribution":
                dispatch(setKpiContext({viewKpiData : 
                    {...viewKpiData, item: { ...viewKpiData.item, teamContribution: value }}
                }))
                break;
            default:
                break;
        }
    };

    function handleSelectMember(uid){
        setSelectedMemberID([...selectedMemberID, uid])
    };

    function removeSelectedMember(uid){
        setSelectedMemberID(selectedMemberID.filter(member => member !== uid));
    };

    function deleteKPI(){
        const KpiID = viewKpiData.item.KpiID

        setIsLoading(true);
        hideDeleteModalFunc();

        dispatch(deleteKpi({TenantID:tenant,KpiID:KpiID}))
        setTimeout(() => {
            notify("DELETED KPI");
            dispatch(setKpiContext(
                {viewKpiData : 
                    {
                        item: {
                            KpiID: "",
                            title: "",
                            startDate: moment().format("YYYY-MM-DD"),
                            dueDate: moment().format("YYYY-MM-DD"),
                            definition: "",
                            sourceOfData: "",
                            measuredBy: "",
                            metricValue: "",
                            target: "",
                            unitOfMeasurement: "",
                            targetMembers: [],
                            teamContribution: false,
                            createdAt: "",
                            assignedBy: "",
                            percentageWidth: "",
                            totalValue: "",
                            percentage: ""
                        },
                        percentage: "",
                        percentageWidth: "",
                        totalValue: ""
                    },
                    viewKpi : false
                }
            ))
            setIsLoading(false);
            refreshUsage();
        }, 2000)
       
    };

    return(
        <>
        <C_Loading isLoading={isLoading}/>
        <div className="modal fade" tabIndex="-1" ref={deleteModalRef} style={{}}>
                <div className="modal-dialog modal-dialog-centered" style={{}}>
                    <div className="modal-content px-3" style={{overflowY: "scroll" }}>
                        <div className="modal-header modalHeader">
                            <div className="title">
                                <span className="font-s">Delete Analytics Report</span>
                            </div>
                            <AiOutlineClose cursor='pointer' size={16} data-bs-dismiss="modal"/>
                        </div>
                        
                        <div className="modal-body">
                            <span className="font-xs">
                            Are you sure you want to delete these members? This action cannot be reverted.
                            </span>
                           
                            
                            <div className="new-button-container" >
                              <C_DialogButton
                                onClick={()=>{hideDeleteModalFunc()}}
                                color={'#ca4254'}
                                background={'white'}
                                borderColor={'#ca4254'}
                                buttonText={"DISCARD"}
                                dismissAction="modal"
                              />
                              <C_DialogButton
                                onClick={()=>{deleteKPI() }}
                                color={"white"}
                                background={'#ca4254'}
                                borderColor={'#ca4254'}
                                buttonText={"DELETE"}
                                dismissAction="modal"
                              />
                            </div>
                        </div>
                    </div>
                </div>
          </div>
        
        <div className="modal fade" tabIndex="-1" ref={modalRef} style={{}}>
            <div className="modal-dialog modal-dialog-centered" style={{}}>
                <div className="modal-content px-3" style={{ minHeight: "556px", overflowY: "scroll" }}>
                    <div className="modal-header modalHeader">
                        <div className="title">
                            <CgUserList size={16}/>
                            <span>Target Members</span>
                        </div>
                        <AiOutlineClose cursor='pointer' size={16} onClick={() => { hideModalFunc() }}/>
                    </div>
                    <div className="modal-body d-flex row">
                        <div className={`teamContributionContainer ${selectedMemberList.length > 1 && 'showSettings'}`}>
                            <span className={`info ${showInfoTeamContribution && 'showInfo'}`}>Check this to combine members submission as <strong><em>TOTAL</em></strong> KPI</span>
                            <BsFillInfoCircleFill cursor="pointer" size={14}
                                onMouseEnter={() => { setShowInfoTeamContribution(true) }}
                                onMouseLeave={() => { setShowInfoTeamContribution(false) }}
                            />
                            <span>Team Contribution</span>
                            <Switch
                                checked={viewKpiData?.item?.teamContribution}
                                onChange={(e) => { handleInput({value: e, type: "teamContribution"}) }}
                                onColor="#4BAB00"
                                onHandleColor="#FFFFFF"
                                handleDiameter={20}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={15}
                                width={35}
                            />
                        </div>
                        <div className="selectedListContainer">
                            {selectedMemberList.length === 0 && <span>Select target member for this KPI or do it later</span>}
                            {selectedMemberList.length > 0 && [...selectedMemberList]?.sort((a, b) => { 
                                    if(a.uid === user?.uid) return -1;
                                    if(b.uid === user?.uid) return 1;
                                    return a.UserName?.localeCompare(b.UserName);
                                }).map(item => {
                                return(
                                    <div key={item.uid} className="selectedMembers">
                                        <span>{item.displayName}{item.uid === user?.uid && <span>{" ("}You{")"}</span> }</span>
                                        <MdRemoveCircleOutline size={14} color="red" cursor="pointer" onClick={() => { removeSelectedMember(item.uid) }}/>
                                    </div>
                                )
                            })
                            }
                        </div>
                        <C_Search
                            placeholder="Search"
                            onChange={(e) => {
                                setSearchText(e.target.value);
                            }}
                            value={searchText}
                            searchText={searchText}
                            closeFunction={() => {
                                setSearchText("");
                            }}
                        />
                        <div className="memberListContainer">
                            {memberList.map(item => {
                                const isSelected = selectedMemberID.includes(item.uid);
                                return(
                                    <div key={item.uid}
                                        className='memberContainer'
                                        onClick={() => { 
                                            if(isSelected){
                                                removeSelectedMember(item.uid)
                                            }else{
                                                handleSelectMember(item.uid)
                                            }
                                    }}>
                                        <span>{item.displayName}{item.uid === user?.uid && <span>{" ("}You{")"}</span> }</span>
                                        {isSelected ? <AiFillCheckCircle size={18} color="green"/> : <AiOutlineCheckCircle size={18}/>}
                                    </div>
                                )
                            })}
                        </div>
                        <div className="actionContainer">
                            <C_Button
                                width={"100%"}
                                buttonText={"COMPLETE"}
                                justify={"center"}
                                onClick={() => {
                                    hideModalFunc();
                                }}
                                textColor={"#FFFFFF"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="viewKpiContainer">
            <div className="headerContainer">
                <div className="headerLeft">
                    <BiArrowBack style={{ cursor: 'pointer' }} size={22} onClick={() => { 
                            dispatch(setKpiContext({viewKpi: false}));
                    }}/>
                </div>
                <div className="headerRight">
                    {validationError && <span className="validationError">Please complete required columns to continue!</span>}
                    <C_Button
                        width={"110px"}
                        buttonText={"Update"}
                        justify={"center"}
                        background={"#6d45b9"}
                        gap={"10px"}
                        icon={<HiOutlinePencilAlt size={12} />}
                        onClick={() => {
                            handleUpdateKPI();
                        }}
                        textColor={"#FFFFFF"}
                    />
                    {(isSuperAdmin || Kpi_Delete) &&
                    <C_Button
                        background={"#ca4254"}
                        width={"130px"}
                        gap={"10px"}
                        buttonText={"Delete"}
                        justify={"center"}
                        icon={<HiOutlineTrash size={12} />}
                        onClick={() => {
                            showDeleteModalFunc();
                        }}
                        textColor={"#FFFFFF"}
                    />
                    }
                </div>
            </div>

            <div className={`viewKpiFormContainer`}>
                <div className={`kpiProgressionContainer`}>
                    <div className="header">
                        <span>KPI Progress - {moment().format("DD MMM YYYY")}</span>
                        <span>({viewKpiData?.item?.teamContribution ? "Team" : "Individual"}) Target: {viewKpiData?.item?.target} {viewKpiData?.item?.unitOfMeasurement}</span>
                    </div>
                    <div className="progressMainContainer">
                        <div className="progress-container">
                            <div className="progress-bar" style={{
                                width: viewKpiData?.percentageWidth,
                                backgroundImage: viewKpiData?.percentage > 80 ? "linear-gradient(to right top, #138829, #32a44a, #4bc26a, #63df8b, #7cfead)" : 
                                    viewKpiData?.percentage > 60 ? "linear-gradient(to right top, #004cb2, #185dc5, #286ed8, #367fec, #4391ff)" : 
                                    viewKpiData?.percentage > 40 ? "linear-gradient(to right top, #634c4c, #725a5b, #81686b, #91767b, #a0858c)" : 
                                    viewKpiData?.percentage > 20 ? "linear-gradient(to right top, #a80000, #c02f26, #d74e46, #ec6b67, #ff8888)" : "linear-gradient(to right top, #762398, #962bb0, #b732c7, #da38de, #ff3df3)",
                            }}>
                                <div className="progress-value">
                                    <span>{viewKpiData?.percentage}%</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <table className="targetMemberList">
                        <tbody>
                            <tr>
                                <th>No</th>
                                <th>Member Name</th>
                                <th>Total</th>
                                <th>Unit</th>
                            </tr>
                            {(selectedMemberList && viewKpiData.memberContribution) && [...selectedMemberList].sort(function(a, b) { return b.total - a.total }).map((item, index) => {
                                const result = currUsers.find(data => data.uid === item.uid);
                                const memberContribution = viewKpiData?.memberContribution.find(contribution => contribution.uid === item.uid);
                                const total = memberContribution ? memberContribution.total : 0;
                                const unit = memberContribution ? memberContribution.unit : "";
                                const target = memberContribution ? memberContribution.target : 0;
                                return(
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td >{result?.displayName ? result?.displayName : item?.UserName}</td>
                                        <td>{total}</td>
                                        <td>{unit !== "" ? item.unit : "-"}</td>
                                        {!item.teamContribution && (total >= target) && <AiFillCheckCircle className="achieved"/>}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <div className="actionTargetMember">
                        <div className="modifiedDetailsContainer">
                            
                        </div>
                        <C_Button
                            width={"120px"}
                            buttonText={"Modify Member"}
                            justify={"center"}
                            onClick={() => {
                                showModalFunc();
                            }}
                            textColor={"#FFFFFF"}
                        />
                    </div>
                </div>

                <div className="sectionMainContainer">
                    <div className="sectionTitle">
                        <div className="iconContainerStyle generalDetials">
                            <TbListDetails size={14}/>
                        </div>
                        <span>General Details</span>
                    </div>
                    <div className="inputSectionContainer">
                        <div className='inputField'>
                            <div className='inputTitle'>
                                <span>Title *</span>
                            </div>
                            <input
                                placeholder='Name this KPI'
                                value={viewKpiData?.item?.title}
                                onChange={(e) => {
                                    handleInput({value: e.target.value, type: "title"})
                                }}
                            />
                        </div>
                        <div className='inputField'>
                            <div className='inputTitle'>
                                <span>Start Date *</span>
                            </div>
                            <input
                                type="date"
                                max={moment(viewKpiData?.item?.dueDate).format("YYYY-MM-DD")}
                                value={moment(viewKpiData?.item?.startDate).format("YYYY-MM-DD")}
                                onChange={(e) => {
                                    handleInput({value: e.target.value, type: "startDate"})
                                }}
                            />
                        </div>
                        <div className='inputField'>
                            <div className='inputTitle'>
                                <span>Due Date *</span>
                                <span className={`info ${showInfoDueDate && 'showInfo'}`}>Calculation will be finalized until the defined <strong><em>Due Date</em></strong></span>
                                <BsFillInfoCircleFill cursor="pointer" size={14}
                                    onMouseEnter={() => { setShowInfoDueDate(true) }}
                                    onMouseLeave={() => { setShowInfoDueDate(false) }}
                                />
                            </div>
                            <input
                                type="date"
                                min={moment(viewKpiData?.item?.startDate).format("YYYY-MM-DD")}
                                value={moment(viewKpiData?.item?.dueDate).format("YYYY-MM-DD")}
                                onChange={(e) => {
                                    handleInput({value: e.target.value, type: "dueDate"})
                                }}
                            />
                        </div>
                    </div>
                    <div className="inputSectionContainer">
                        <div className='inputField'>
                            <div className='inputTitle'>
                                <span>Definition (Optional)</span>
                            </div>
                            <textarea 
                                placeholder="A clear and concise definition of the KPI, including what it measures and why it is important" 
                                value={viewKpiData?.item?.definition}
                                onChange={(e) => {
                                    handleInput({value: e.target.value, type: "definition"})
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className="sectionMainContainer">
                    <div className="sectionTitle">
                        <div className="iconContainerStyle dataSource">
                            <BsClipboardData size={14}/>
                        </div>
                        <span>Data Source</span>
                    </div>
                    <div className="inputSectionContainer">
                        <div className='inputField'>
                            <div className='inputTitle'>
                                <span>Source of data *</span>
                                <span className="desc">The source of the data used to calculate the KPI</span>
                            </div>
                            <select
                                value={viewKpiData?.item?.sourceOfData}
                                onChange={(e) => {
                                    handleInput({value: e.target.value, type: "sourceOfData"})
                                }}
                            >
                                <option value="" disabled>Choose an option to continue</option>
                                {sourceData?.map(source => {
                                    return(
                                        <option key={source.FormID} value={source.FormID}>{source.FormName}</option>
                                    )
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="inputSectionContainer">
                        <div className='inputField'>
                            <div className='inputTitle'>
                                <span>Measured by *</span>
                            </div>
                            <select
                                className={`${viewKpiData?.item?.sourceOfData === "" && "disableSelection"}`}
                                disabled={viewKpiData?.item?.sourceOfData === "" ? true : false}
                                value={viewKpiData?.item?.measuredBy}
                                onChange={(e) => {
                                    handleInput({value: e.target.value, type: "measuredBy"})
                                }}
                            >
                                <option value="" disabled>{viewKpiData?.item?.sourceOfData === "" ? "-" : "Choose an option"}</option>
                                <option value='Submission'>Submission</option>
                                <option value='Data Value'>Data value</option>
                            </select>
                        </div>
                        <div className='inputField'>
                            <div className='inputTitle'>
                                <span>Metric value *</span>
                                <span className={`info ${showInfoMetricValue && 'showInfo'}`}>Metric Value must be in type of <strong><em>Number</em></strong> or <strong><em>Decimal</em></strong> to perform calculation.</span>
                                <BsFillInfoCircleFill cursor="pointer" size={14}
                                    onMouseEnter={() => { setShowInfoMetricValue(true) }} 
                                    onMouseLeave={() => { setShowInfoMetricValue(false)  }}
                                />
                            </div>
                            <select
                                className={`${(viewKpiData?.item?.measuredBy === "Submission" || viewKpiData?.item?.measuredBy === "") && "disableSelection"}`}
                                disabled={(viewKpiData?.item?.measuredBy === "Submission" || viewKpiData?.item?.measuredBy === "") ? true : false}
                                value={viewKpiData?.item?.metricValue}
                                onChange={(e) => {
                                    handleInput({value: e.target.value, type: "metricValue"})
                                }}
                            >
                                <option value="" disabled>{(viewKpiData?.item?.measuredBy === "Submission" || viewKpiData?.item?.measuredBy === "") ? "-" : "Choose an option"}</option>
                                {metricValueOptions && metricValueOptions.FormData.map(value => {
                                    if(value.questionType === "Number" || value.questionType === "Decimal"){
                                        return(
                                            <option key={value.questionID} value={value.questionID}>{value.questionName}</option>
                                        )
                                    }
                                })
                                }
                            </select>
                        </div>
                    </div>
                </div>
                
                <div className="sectionMainContainer">
                    <div className="sectionTitle">
                        <div className="iconContainerStyle metrics">
                            <AiOutlineStock size={14}/>
                        </div>
                        <span>Metrics</span>
                    </div>
                    <div className="inputSectionContainer">
                        <div className='inputField'>
                            <div className='inputTitle'>
                                <span>Target *</span>
                            </div>
                            <input
                                type='number'
                                min="0"
                                placeholder='Target to achieve this KPI'
                                value={viewKpiData?.item?.target}
                                onChange={(e) => {
                                    handleInput({value: e.target.value, type: "target"})
                                }}
                            />
                        </div>
                        <div className='inputField'>
                            <div className='inputTitle'>
                                <span>Unit of measurement (Optional)</span>
                            </div>
                            <input 
                                placeholder='e.g. RM, USD, Hours, Days...'
                                value={viewKpiData?.item?.unitOfMeasurement}
                                onChange={(e) => {
                                    handleInput({value: e.target.value, type: "unitOfMeasurement"})
                                }}
                            />
                        </div>
                    </div>
                </div>

            </div>
        </div>
        </>
    );
  }
  
  export default EditKpi;
  