import { call, delay, put, takeLatest } from "redux-saga/effects";
import{
    setFormID,
    setFormIDSuccess,
    setFormIDError,
} from "../stores/slices/formIDSlices"

function* setFormIDSaga({payload}){
    try{
        yield put(setFormIDSuccess(payload))
    }  catch (error){
        yield put(setFormIDError(error));
        console.error(error);
    }
}

export function* formIDSagaWatcher(){
    yield takeLatest(setFormID.type, setFormIDSaga);
}
