const component = {
  MuiDataGrid: {
    styleOverrides: {
      root: ({ theme }) => ({
        borderWidth: "0",
        borderRadius: "6px",
        "& .MuiDataGrid-columnHeaders.MuiDataGrid-withBorderColor": {
          backgroundColor: theme.palette.background.default,
          border: theme.palette.border.danger,
          minHeight: "50px !important",
          maxHeight: "50px !important",
          lineHeight: "unset !important",
          // padding: "0 10px",
          ...theme.typography.bodyText,
        },
        "& .Mui-selected.MuiPaginationItem-page": {
          color: theme.palette.text.white,
          border: "none",
          backgroundColor: theme.palette.background.primary,
        },
        "& .MuiPaginationItem-page": {
          color: theme.palette.text.secondary,
        },
        "& .MuiPaginationItem-previousNext": {
          color: theme.palette.text.secondary,
        },
        "& .MuiTablePagination-input": {
          backgroundColor: theme.palette.background.white,
          border: `1px solid ${theme.palette.border.default}`,
          "&.MuiSelect-select": {
            lineHeight: "1.3em",
          },
          svg: {
            width: 20,
          },
        },
        "& .MuiDataGrid-footerContainer": {
          borderTop: "none !important",
        },
        "& .MuiDataGrid-cell": {
          borderBottom: "none",
        },
        "& .MuiDataGrid-iconButtonContainer": {
          // visibility: "visible",
          // width: "0 !important",
        },
        "& .css-6lb61k-MuiButtonBase-root-MuiIconButton-root": {
          padding: "0px",
          fontSize: 14,
        },
        "& .MuiDataGrid-columnHeader": {
          // height: "unset !important",
          height: "50px !important",
        },

        "& .MuiDataGrid-columnHeaderTitleContainer": {
          justifyContent: "start !important",
          color: theme.palette.text.default,
          fontSize: 14,
        },

        "& .MuiTablePagination-selectLabel": {
          margin: "0",
          color: theme.palette.text.secondary,
          fontSize: 14,
        },
        "& .MuiTablePagination-displayedRows": {
          display: "none",
        },
        "& .MuiInputBase-root": {
          marginRight: "0",
          // border: theme.borders.medium,
          borderRadius: "6px",
          color: theme.palette.text.primary,
        },

        "& .MuiDataGrid-main": {
          border: theme.borders.default,
          borderRadius: " 6px 6px 0 0",
        },

        "& .MuiDataGrid-cell--withRenderer.MuiDataGrid-cell": {
          justifyContent: "start",
        },

        "& .MuiDataGrid-cell:focus": {
          outline: "unset",
        },
        "& .MuiDataGrid-cell:focus-within": {
          outline: "unset",
        },
        "& .MuiDataGrid-row.Mui-selected": {
          backgroundColor: `${theme.palette.table.hover} !important`,
        },
        "& .MuiDataGrid-columnHeader:focus": {
          outline: "unset",
        },
        "& .MuiDataGrid-columnHeader:focus-within": {
          outline: "unset",
        },
        "& .MuiDataGrid-row:hover": {
          // backgroundColor: theme.palette.table.hover,
        },
        ".MuiDataGrid-row.MuiDataGrid-row--lastVisible": {
          borderBottom: "none",
        },
        ".MuiDataGrid-row": {
          borderBottom: theme.borders.default,
        },
        "& .MuiDataGrid-virtualScroller": {
          height:"35vh",
          overflow: "auto",
        },
        ".MuiDataGrid-virtualScroller::-webkit-scrollbar": {
          display: "initial",
        },
        "& .MuiDataGrid-virtualScrollerContent": {
          // maxHeight: "calc(100vh - 68px - 50px - 32px - 32px) !important",
          width: "fit-content !important",
        },
        "& .MuiDataGrid-virtualScrollerRenderZone": {
          position: "relative",
        },
        "& .MuiDataGrid-menuIcon": {
          width: "auto",
          visibility: "visible",
          "& svg": {
            width: 20,
          },
        },
        // todo column header sticky style
        // "&  .MuiDataGrid-cell[data-field='name'] ": {
        //   position: "sticky",
        //   left: "0",
        //   backgroundColor: "white",
        //   zIndex: "100 !important",
        //   overflow: "none",
        // },

        // "&  .MuiDataGrid-columnHeader[data-field='name'] ": {
        //   position: "sticky",
        //   left: "0",
        //   backgroundColor: "white",
        //   zIndex: "100 !important",
        // },

        // "& .MuiDataGrid-columnHeaderCheckbox": {
        // position: "sticky",
        // left: "0",
        // backgroundColor: "white",
        // zIndex: "100 !important",
        // },

        // "& .MuiDataGrid-cellCheckbox": {
        //   position: "sticky",
        //   left: "0",
        //   backgroundColor: "white",
        //   zIndex: "100 !important",
        // },
      }),
    },
  },
};

export default component;
