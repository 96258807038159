import React, {
    useState,
    useEffect,
    useRef,
} from "react";
import './GaPortal.scss'
import moment from "moment";
import { easeQuadInOut } from 'd3-ease';
import { MdWorkOutline } from "react-icons/md";
import { AiOutlineDisconnect, AiOutlineDollarCircle, AiOutlineFall, AiOutlineRise, AiOutlineUser } from "react-icons/ai";
import { LineChart, CartesianGrid, Legend, YAxis, XAxis, Line, ResponsiveContainer, Tooltip } from "recharts";
import AnimatedProgressProvider from "../../components/AnimatedProgressProvider";
import { selectGaPortalContext } from "../../stores/slices/gaPortalSlices";
import { selectUser } from "../../stores/slices/userAuthSlices";

function GaDashboard(props) {

  const graphContainerRef = useRef(null);

  const MAX_WIDTH = props.TOTAL_WIDTH * 0.95;
  const user = selectUser();
  const { currAllUserList } = selectGaPortalContext();
  
  const [graphWidth, setGraphWidth] = useState(0);
  const [graphHeight, setGraphHeight] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [filterMonth, setFilterMonth] = useState(moment(new Date()).format("MMM"));
  const [yearOptions, setYearOptions] = useState([]);
  const [filterYear, setFilterYear] = useState(moment(new Date()).year());

  const [totalUser, setTotalUser] = useState({
    total: 0,
    diff: 0,
    statistic: 0
  });

  const [totalTenant, setTotalTenant] = useState({
    total: 0,
    diff: 0,
    statistic: 0
  });

  const [conversionRate, setConversionRate] = useState({
    total: 0,
    diff: 0,
    statistic: 0
  });

  const [churnRate, setChurnRate] = useState({
    total: 0,
    diff: 0,
    statistic: 0
  });

  const [accountActivation, setAccountActivation] = useState([
    { month: 'Jan', account: 0 },
    { month: 'Feb', account: 0 },
    { month: 'Mar', account: 0 },
    { month: 'Apr', account: 0 },
    { month: 'May', account: 0 },
    { month: 'Jun', account: 0 },
    { month: 'Jul', account: 0 },
    { month: 'Aug', account: 0 },
    { month: 'Sep', account: 0 },
    { month: 'Oct', account: 0 },
    { month: 'Nov', account: 0 },
    { month: 'Dec', account: 0 }
  ]);

  const [tenantActivation, setTenantActivation] = useState([
    { month: 'Jan', tenant: 0, account: 0 },
    { month: 'Feb', tenant: 0, account: 0 },
    { month: 'Mar', tenant: 0, account: 0 },
    { month: 'Apr', tenant: 0, account: 0 },
    { month: 'May', tenant: 0, account: 0 },
    { month: 'Jun', tenant: 0, account: 0 },
    { month: 'Jul', tenant: 0, account: 0 },
    { month: 'Aug', tenant: 0, account: 0 },
    { month: 'Sep', tenant: 0, account: 0 },
    { month: 'Oct', tenant: 0, account: 0 },
    { month: 'Nov', tenant: 0, account: 0 },
    { month: 'Dec', tenant: 0, account: 0 }
  ]);

  async function countTotalUser({currentDate, yesterday, data, filterYear, filterMonth}){

    const tempUser = data.filter(item => 
      new Date(item.createdAt).getFullYear().toString() === filterYear &&
      (filterMonth !== "" ? new Date(item.createdAt).toLocaleString('default', { month: 'short' }) === filterMonth : true)
    )

    const tempTotalUser = tempUser.length;

    // Filter the user list based on the createdAt date for today
    const usersRegisteredToday = await data.filter(user => {
      const createdAtDate = moment(user.createdAt);
      return createdAtDate.isSame(currentDate, 'day');
    });

    // Filter the user list based on the createdAt date for yesterday
    const usersRegisteredYesterday = await data.filter(user => {
      const createdAtDate = moment(user.createdAt);
      return createdAtDate.isSame(yesterday, 'day');
    });

    const userCountToday = usersRegisteredToday.length;
    const userCountYesterday = usersRegisteredYesterday.length;

    const diffUserCount = userCountToday - userCountYesterday;

    return {
      total: tempTotalUser,
      diff: userCountYesterday,
      statistic: diffUserCount
    }

  };

  async function countTotalTenant({currentDate, yesterday, data, filterYear, filterMonth}){

    const tempTenant = data.filter(item => 
      item.isTenant &&
      new Date(item.tenantCreatedAt).getFullYear().toString() === filterYear &&
      (filterMonth !== "" ? new Date(item.tenantCreatedAt).toLocaleString('default', { month: 'short' }) === filterMonth : true)
    );

    const tempTotalTenant = tempTenant.length;

    // Filter the user list based on the createdAt date for today
    const usersRegisteredToday = await data.filter(user => {
      const createdAtDate = moment(user.tenantCreatedAt);
      return createdAtDate.isSame(currentDate, 'day');
    });

    // Filter the user list based on the createdAt date for yesterday
    const usersRegisteredYesterday = await data.filter(user => {
      const createdAtDate = moment(user.tenantCreatedAt);
      return createdAtDate.isSame(yesterday, 'day');
    });

    const userCountToday = usersRegisteredToday.length;
    const userCountYesterday = usersRegisteredYesterday.length;

    const diffUserCount = userCountToday - userCountYesterday;

    return {
      total: tempTotalTenant,
      diff: userCountYesterday,
      statistic: diffUserCount
    }

  };

  async function countConversionRate({currentDate, yesterday, data, filterYear, filterMonth}){

    const tempTenant = data.filter(item => 
      item.isTenant &&
      new Date(item.upgradeCreatedAt).getFullYear().toString() === filterYear &&
      (filterMonth !== "" ? new Date(item.upgradeCreatedAt).toLocaleString('default', { month: 'short' }) === filterMonth : true)
    );

    const tempTenantSubscribe = data.filter(item => 
      item.isTenant && item.plan !== "Trial" &&
      new Date(item.upgradeCreatedAt).getFullYear().toString() === filterYear &&
      (filterMonth !== "" ? new Date(item.upgradeCreatedAt).toLocaleString('default', { month: 'short' }) === filterMonth : true)
    );

    const tempConversionRate = tempTenantSubscribe.length === 0 && tempTenant.length === 0 ?
      0 : (tempTenantSubscribe.length / tempTenant.length) * 100;
    
    const tempTenantSubscribeCurr = data.filter(item => 
      item.isTenant && item.plan !== "Trial"
    );

    // Filter the user list based on the createdAt date for today
    const usersRegisteredToday = await tempTenantSubscribeCurr.filter(user => {
      const createdAtDate = moment(user.upgradeCreatedAt);
      return createdAtDate.isSame(currentDate, 'day');
    });

    // Filter the user list based on the createdAt date for yesterday
    const usersRegisteredYesterday = await tempTenantSubscribeCurr.filter(user => {
      const createdAtDate = moment(user.upgradeCreatedAt);
      return createdAtDate.isSame(yesterday, 'day');
    });

    const userCountToday = usersRegisteredToday.length;
    const userCountYesterday = usersRegisteredYesterday.length;

    const diffUserCount = userCountToday - userCountYesterday;

    return {
      total: tempConversionRate,
      diff: userCountYesterday,
      statistic: diffUserCount
    }

  };

  async function countChurnRate({currentDate, yesterday, data, filterYear, filterMonth}){

    const tempTenant = data.filter(
      item => item.isTenant &&
      new Date(item.tenantCreatedAt).getFullYear().toString() === filterYear &&
      (filterMonth !== "" ? new Date(item.tenantCreatedAt).toLocaleString('default', { month: 'short' }) === filterMonth : true)
    );

    const tempTenantSubscribe = data.filter(item => {
      if (item.isTenant && item.plan === "Trial" &&
        new Date(item.tenantCreatedAt).getFullYear().toString() === filterYear &&
        (filterMonth !== "" ? new Date(item.tenantCreatedAt).toLocaleString('default', { month: 'short' }) === filterMonth : true)
      ){
        const tenantCreatedAt = moment(item.tenantCreatedAt);
        const sevenDaysAgo = moment().subtract(7, 'days');
        return tenantCreatedAt.isBefore(sevenDaysAgo);
      };
      return false;
    });

    const tempTenantSubscribeCurr = data.filter(item => {
      if (item.isTenant && item.plan === "Trial"){
        const tenantCreatedAt = moment(item.tenantCreatedAt);
        const sevenDaysAgo = moment().subtract(7, 'days');
        return tenantCreatedAt.isBefore(sevenDaysAgo);
      };
      return false;
    });

    const tempConversionRate = tempTenantSubscribe.length === 0 && tempTenant.length === 0 ?
      0 : (tempTenantSubscribe.length / tempTenant.length) * 100;

    // Filter the user list based on the createdAt date for today
    const usersRegisteredToday = await tempTenantSubscribeCurr.filter(user => {
      const createdAtDate = moment(user.tenantCreatedAt);
      return createdAtDate.isSame(currentDate, 'day');
    });

    // Filter the user list based on the createdAt date for yesterday
    const usersRegisteredYesterday = await tempTenantSubscribeCurr.filter(user => {
      const createdAtDate = moment(user.tenantCreatedAt);
      return createdAtDate.isSame(yesterday, 'day');
    });

    const userCountToday = usersRegisteredToday.length;
    const userCountYesterday = usersRegisteredYesterday.length;

    const diffUserCount = userCountToday - userCountYesterday;

    return {
      total: tempConversionRate,
      diff: userCountYesterday,
      statistic: diffUserCount
    }

  };

  async function countAccountActivation({data, filterYear, filterMonth}){

    const tempFilterData = data.filter(item => 
      new Date(item.createdAt).getFullYear().toString() === filterYear &&
      (filterMonth !== "" ? new Date(item.createdAt).toLocaleString('default', { month: 'short' }) === filterMonth : true)
    );

    const monthlyCounts = [
      { month: 'Jan', account: 0 },
      { month: 'Feb', account: 0 },
      { month: 'Mar', account: 0 },
      { month: 'Apr', account: 0 },
      { month: 'May', account: 0 },
      { month: 'Jun', account: 0 },
      { month: 'Jul', account: 0 },
      { month: 'Aug', account: 0 },
      { month: 'Sep', account: 0 },
      { month: 'Oct', account: 0 },
      { month: 'Nov', account: 0 },
      { month: 'Dec', account: 0 }
    ];

    const daysInMonth = moment(`${filterYear}-${filterMonth}`, 'YYYY-MMM').daysInMonth();

    // Create an array of day-value pairs
    const dailyCounts = [];

    for (let day = 1; day <= daysInMonth; day++) {
      dailyCounts.push({ day: day.toString(), account: 0 });
    }
  
    for (const user of tempFilterData) {
      if(filterMonth !== ""){
        const createdAt = moment(user.createdAt);
        const dayOfMonth = createdAt.format('D');
        const dayIndex = parseInt(dayOfMonth) - 1; // Subtract 1 to get the 0-based index

        if (dayIndex >= 0 && dayIndex < daysInMonth) {
          dailyCounts[dayIndex].account++;
        }
      }else{
        const createdAt = moment(user.createdAt);
        const month = createdAt.format('MMM');
        const monthIndex = moment.monthsShort().indexOf(month);
    
        if (monthIndex >= 0) {
          monthlyCounts[monthIndex].account++;
        }
      }
    }
  
    return filterMonth !== "" ? dailyCounts : monthlyCounts;

  };

  async function countTenantActivation({data, filterYear, filterMonth}){

    const tempFilterDataUser = data.filter(item => 
      new Date(item.createdAt).getFullYear().toString() === filterYear &&
      (filterMonth !== "" ? new Date(item.createdAt).toLocaleString('default', { month: 'short' }) === filterMonth : true)
    );

    const tempFilterData = data.filter(item => 
      item.isTenant &&
      new Date(item.tenantCreatedAt).getFullYear().toString() === filterYear &&
      (filterMonth !== "" ? new Date(item.tenantCreatedAt).toLocaleString('default', { month: 'short' }) === filterMonth : true)
    );

    const monthlyCounts = [
      { month: 'Jan', tenant: 0, account: 0 },
      { month: 'Feb', tenant: 0, account: 0 },
      { month: 'Mar', tenant: 0, account: 0 },
      { month: 'Apr', tenant: 0, account: 0 },
      { month: 'May', tenant: 0, account: 0 },
      { month: 'Jun', tenant: 0, account: 0 },
      { month: 'Jul', tenant: 0, account: 0 },
      { month: 'Aug', tenant: 0, account: 0 },
      { month: 'Sep', tenant: 0, account: 0 },
      { month: 'Oct', tenant: 0, account: 0 },
      { month: 'Nov', tenant: 0, account: 0 },
      { month: 'Dec', tenant: 0, account: 0 }
    ];

    const daysInMonth = moment(`${filterYear}-${filterMonth}`, 'YYYY-MMM').daysInMonth();

    // Create an array of day-value pairs
    const dailyCounts = [];

    for (let day = 1; day <= daysInMonth; day++) {
      dailyCounts.push({ day: day.toString(), tenant: 0, account: 0 });
    }

    for (const user of tempFilterDataUser) {
      if(filterMonth !== ""){
        const createdAt = moment(user.createdAt);
        const dayOfMonth = createdAt.format('D');
        const dayIndex = parseInt(dayOfMonth) - 1; // Subtract 1 to get the 0-based index

        if (dayIndex >= 0 && dayIndex < daysInMonth) {
          dailyCounts[dayIndex].account++;
        }
      }else{
        const createdAt = moment(user.createdAt);
        const month = createdAt.format('MMM');
        const monthIndex = moment.monthsShort().indexOf(month);
    
        if (monthIndex >= 0) {
          monthlyCounts[monthIndex].account++;
        }
      }
      
    }
  
    for (const user of tempFilterData) {
      if(filterMonth !== ""){
        const createdAt = moment(user.tenantCreatedAt);
        const dayOfMonth = createdAt.format('D');
        const dayIndex = parseInt(dayOfMonth) - 1; // Subtract 1 to get the 0-based index

        if (dayIndex >= 0 && dayIndex < daysInMonth) {
          dailyCounts[dayIndex].tenant++;
        }
      }else{
        const createdAt = moment(user.tenantCreatedAt);
        const month = createdAt.format('MMM');
        const monthIndex = moment.monthsShort().indexOf(month);
    
        if (monthIndex >= 0) {
          monthlyCounts[monthIndex].tenant++;
        }
      }
    }
  
    return filterMonth !== "" ? dailyCounts : monthlyCounts;

  };
  
  useEffect(() => {

    if(currAllUserList && currAllUserList.length > 0){

      const currentDate = moment(new Date()); // Get the current date
      const yesterday = moment(new Date()).subtract(1, 'day'); // Get the date of yesterday
      const filterYearString = filterYear.toString()

      countTotalUser({currentDate, yesterday, data: currAllUserList, filterYear: filterYearString, filterMonth}).then(result => {
        setTotalUser(result);
      });

      countTotalTenant({currentDate, yesterday, data: currAllUserList, filterYear: filterYearString, filterMonth}).then(result => {
        setTotalTenant(result);
      });

      countConversionRate({currentDate, yesterday, data: currAllUserList, filterYear: filterYearString, filterMonth}).then(result => {
        setConversionRate(result);
      });

      countChurnRate({currentDate, yesterday, data: currAllUserList, filterYear: filterYearString, filterMonth}).then(result => {
        setChurnRate(result);
      });

      countAccountActivation({data:currAllUserList, filterYear: filterYearString, filterMonth}).then(result => {
        setAccountActivation(result)
      })

      countTenantActivation({data:currAllUserList, filterYear: filterYearString, filterMonth}).then(result => {
        setTenantActivation(result)
      })

      const years = Array.from(new Set(currAllUserList.map(item => new Date(item.createdAt).getFullYear())));
      setYearOptions(years)


    }else{

      setTotalUser({
        total: 0,
        diff: 0,
        statistic: 0
      });

      setTotalTenant({
        total: 0,
        diff: 0,
        statistic: 0
      });

      setConversionRate({
        total: 0,
        diff: 0,
        statistic: 0
      });

      setChurnRate({
        total: 0,
        diff: 0,
        statistic: 0
      });

      setAccountActivation([
        { month: 'Jan', value: 0 },
        { month: 'Feb', value: 0 },
        { month: 'Mar', value: 0 },
        { month: 'Apr', value: 0 },
        { month: 'May', value: 0 },
        { month: 'Jun', value: 0 },
        { month: 'Jul', value: 0 },
        { month: 'Aug', value: 0 },
        { month: 'Sep', value: 0 },
        { month: 'Oct', value: 0 },
        { month: 'Nov', value: 0 },
        { month: 'Dec', value: 0 }
      ])

      setTenantActivation([
        { month: 'Jan', value: 0, totalValue: 0 },
        { month: 'Feb', value: 0, totalValue: 0 },
        { month: 'Mar', value: 0, totalValue: 0 },
        { month: 'Apr', value: 0, totalValue: 0 },
        { month: 'May', value: 0, totalValue: 0 },
        { month: 'Jun', value: 0, totalValue: 0 },
        { month: 'Jul', value: 0, totalValue: 0 },
        { month: 'Aug', value: 0, totalValue: 0 },
        { month: 'Sep', value: 0, totalValue: 0 },
        { month: 'Oct', value: 0, totalValue: 0 },
        { month: 'Nov', value: 0, totalValue: 0 },
        { month: 'Dec', value: 0, totalValue: 0 }
      ])

    };

  },[currAllUserList, filterYear, filterMonth]);

  useEffect(() => {
    setIsLoading(true);
    if(graphContainerRef.current){
      setTimeout(() => {
        setGraphWidth(graphContainerRef.current?.clientWidth * 0.95);
        setGraphHeight(graphContainerRef.current?.clientHeight * 0.95);
        setIsLoading(false);
      },1500)
    }
  },[graphContainerRef])

  return (
    <div className='dashboardMainContainer' style={{ maxWidth: MAX_WIDTH }}>
      <div className="dashboardHeader">
        <div className="greetContainer">
          <span className="title">Hi {user?.displayName}</span>
          <span className="desc">Welcome back to zumax crm admin portal</span>
        </div>
        <div className="actionContainer">
          <select className="monthFilter" value={filterMonth} onChange={(e) => {
            setFilterMonth(e.target.value);
          }}>
            <option value= "" disabled>Month</option>
            {filterMonth !== "" && <option value= "">Clear Filter</option>}
            <option value={"Jan"}>January</option>
            <option value={"Feb"}>February</option>
            <option value={"Mar"}>March</option>
            <option value={"Apr"}>April</option>
            <option value={"May"}>May</option>
            <option value={"Jun"}>June</option>
            <option value={"Jul"}>July</option>
            <option value={"Aug"}>August</option>
            <option value={"Sep"}>September</option>
            <option value={"Oct"}>October</option>
            <option value={"Nov"}>November</option>
            <option value={"Dec"}>December</option>
          </select>
          <select className="yearFilter" value={filterYear} onChange={(e) => {
            setFilterYear(e.target.value);
          }}>
            {yearOptions.map((item, index) => {
              return(
                <option key={index} value={item}>{item}</option>
              )
            })}
          </select>
        </div>
      </div>
      <div className="analyticsContentContainer">
        <div className="summarizeCardContainer">
          <div className="analyticsCard">
            <div className="cardSection">
              <div className="cardContent">
                <div className="icon totalUser">
                  <AiOutlineUser size={18} color="#333"/>
                </div>
                <span className="description">Total user</span>
              </div>
            </div>
            <div className="cardSectionSec">
              <AnimatedProgressProvider
                  valueStart={0}
                  valueEnd={totalUser?.total}
                  duration={1}
                  easingFunction={easeQuadInOut}
              >
                {(value) => {
                  const roundedValue = Math.round(value);
                  return (
                    <span className="calculation">{roundedValue}</span>
                  );
                }}
              </AnimatedProgressProvider>
              <div className={`comparison ${totalUser?.statistic < 0 ? 'fall' : 'increase'}`}>
                <div className="comparisonContent">
                  {totalUser?.statistic < 0 ? <AiOutlineFall size={20} className="iconColor"/> : <AiOutlineRise size={20} className="iconColor"/>}
                  <span className="percent">{totalUser?.statistic}</span>
                </div>
                <span className="remark">vs yesterday ({totalUser?.diff})</span>
              </div>
            </div>
          </div>
          <div className="analyticsCard">
            <div className="cardSection">
              <div className="cardContent">
                <div className="icon totalTenant">
                  <MdWorkOutline size={18} color="#333"/>
                </div>
                <span className="description">Total tenant</span>
              </div>
            </div>
            <div className="cardSectionSec">
              <AnimatedProgressProvider
                valueStart={0}
                valueEnd={totalTenant?.total}
                duration={1}
                easingFunction={easeQuadInOut}
              >
                {(value) => {
                  const roundedValue = Math.round(value);
                  return (
                    <span className="calculation">{roundedValue}</span>
                  );
                }}
              </AnimatedProgressProvider>
              <div className={`comparison ${totalTenant?.statistic < 0 ? 'fall' : 'increase'}`}>
                <div className="comparisonContent">
                  {totalTenant?.statistic < 0 ? <AiOutlineFall size={20} className="iconColor"/> : <AiOutlineRise size={20} className="iconColor"/>}
                  <span className="percent">{totalTenant?.statistic}</span>
                </div>
                <span className="remark">vs yesterday ({totalTenant?.diff})</span>
              </div>
            </div>
          </div>
          <div className="analyticsCard">
            <div className="cardSection">
              <div className="cardContent">
                <div className="icon conversionRate">
                  <AiOutlineDollarCircle size={18} color="#333"/>
                </div>
                <span className="description">Conversion rate</span>
              </div>
            </div>
            <div className="cardSectionSec">
              <AnimatedProgressProvider
                valueStart={0}
                valueEnd={conversionRate?.total}
                duration={1}
                easingFunction={easeQuadInOut}
              >
                {(value) => {
                  const roundedValue = Math.round(value);
                  return (
                    <span className="calculation">{roundedValue}%</span>
                  );
                }}
              </AnimatedProgressProvider>
              <div className={`comparison ${conversionRate?.statistic < 0 ? 'fall' : 'increase'}`}>
                <div className="comparisonContent">
                  {conversionRate?.statistic < 0 ? <AiOutlineFall size={20} className="iconColor"/> : <AiOutlineRise size={20} className="iconColor"/>}
                  <span className="percent">{conversionRate?.statistic}</span>
                </div>
                <span className="remark">vs yesterday ({conversionRate?.diff})</span>
              </div>
            </div>
          </div>
          <div className="analyticsCard">
            <div className="cardSection">
              <div className="cardContent">
                <div className="icon churnRate">
                  <AiOutlineDisconnect size={18} color="#333"/>
                </div>
                <span className="description">Churn rate</span>
              </div>
            </div>
            <div className="cardSectionSec">
              <AnimatedProgressProvider
                valueStart={0}
                valueEnd={churnRate?.total}
                duration={1}
                easingFunction={easeQuadInOut}
              >
                {(value) => {
                  const roundedValue = Math.round(value);
                  return (
                    <span className="calculation">{roundedValue}%</span>
                  );
                }}
              </AnimatedProgressProvider>
              <div className={`comparison ${churnRate?.statistic < 0 ? 'fall' : 'increase'}`}>
                <div className="comparisonContent">
                  {churnRate?.statistic < 0 ? <AiOutlineFall size={20} className="iconColor"/> : <AiOutlineRise size={20} className="iconColor"/>}
                  <span className="percent">{churnRate?.statistic}</span>
                </div>
                <span className="remark">vs yesterday ({churnRate?.diff})</span>
              </div>
            </div>
          </div>

        </div>

        <div className="generalCardContainer">
          <div className="graphContainer">
            <div className="graphHeader">
              <span className="title">Account activation in {filterMonth} {filterYear}</span>
              <div className="legendContainer">
                <div className="legend">
                  <div className="color activeUser"></div>
                  <span>Active User</span>
                </div>
              </div>
            </div>
            <div className="graph" ref={graphContainerRef}>
              {isLoading ?
                <div className={`loadingContainer ${!isLoading && 'hideLoadingContainer'}`}>
                  <div className="loading-spinner">
                  </div>
                </div>
                :
                <LineChart
                  width={graphWidth}
                  height={graphHeight}
                  data={accountActivation}
                  margin={{
                    top: 5,
                    right: 10,
                    left: 0,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid 
                    strokeDasharray="1 0 1" 
                    vertical={false}
                  />
                  <Tooltip />
                  <XAxis 
                    dataKey={`${filterMonth !== "" ? "day" : "month"}`}
                    axisLine={false}
                    padding={{ left: 20, right: 20 }}
                    tickLine={false}
                    tickSize={15}
                  />
                  <YAxis 
                    axisLine={false}
                    tickSize={0}
                  />
                  <Line type="monotone" dataKey="account" stroke="#82ca9d" activeDot={{ r: 8 }}/>
                </LineChart>
              }
            </div>
          </div>
        </div>
        <div className="generalCardContainer">
          <div className="graphContainer">
            <div className="graphHeader">
              <span className="title">Tenant activation in {filterMonth} {filterYear}</span>
              <div className="legendContainer">
                <div className="legend">
                  <div className="color activeUser"></div>
                  <span>Active User</span>
                </div>
                <div className="legend">
                  <div className="color Subscriber"></div>
                  <span>Active Tenant</span>
                </div>
              </div>
            </div>
            <div className="graph">
              {isLoading ?
                <div className={`loadingContainer ${!isLoading && 'hideLoadingContainer'}`}>
                  <div className="loading-spinner">
                  </div>
                </div>
                :
                <LineChart
                  width={graphWidth}
                  height={graphHeight}
                  data={tenantActivation}
                  margin={{
                    top: 5,
                    right: 10,
                    left: 0,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid 
                    strokeDasharray="1 0 1" 
                    vertical={false}
                  />
                  <Tooltip />
                  <XAxis 
                    dataKey={`${filterMonth !== "" ? "day" : "month"}`}
                    axisLine={false}
                    padding={{ left: 20, right: 20 }}
                    tickLine={false}
                    tickSize={15}
                  />
                  <YAxis 
                    axisLine={false}
                    tickSize={0}
                  />
                  <Line type="monotone" dataKey="tenant" stroke="#8884d8" activeDot={{ r: 8 }} />
                  <Line type="monotone" dataKey="account" stroke="#82ca9d" activeDot={{ r: 8 }}/>
                </LineChart>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GaDashboard;