import { call, delay, put, takeLatest } from "redux-saga/effects";
import { 
    retrieveClientSelected, 
    retrieveClientSelectedError, 
    retrieveClientSelectedSuccess,

    retrieveClientSelectorCount, 
    retrieveClientSelectorCountError, 
    retrieveClientSelectorCountSuccess, 
    
    retrieveClientSelector, 
    retrieveClientSelectorError, 
    retrieveClientSelectorSuccess 
} from "../stores/slices/clientSelectorSlices";
import { ApiRoute } from "../enums/ApiRoute";
import { API } from "../http-common";
import { setAppointmentContext } from "../stores/slices/appointmentsSlices";

function* retrieveClientSelectorSaga({payload}) {
    const { isAppointment = null, clientSelectedList = [], ...otherPayload } = payload;
    try{
        const { data } = yield call(API.post, ApiRoute.clientSelector.retrieveClientSelector, otherPayload);
        if(isAppointment && clientSelectedList){
            const updatedList = [...clientSelectedList, ...data];
            const finalArray = updatedList.filter((elem, index, self) => {
                return self.findIndex((t) => (
                  t.ClientID === elem.ClientID
                )) === index;
            });
            yield put(setAppointmentContext({
                clientSelectedList : finalArray
            }))
            yield put(retrieveClientSelectorSuccess(data));
        } else {
            yield put(retrieveClientSelectorSuccess(data));
        }
    } catch (error){
        yield put(retrieveClientSelectorError(error));
        console.error(error);
    }
}

function* retrieveClientSelectorCountSaga({payload}) {
    try{
        const { data } = yield call(API.post, ApiRoute.clientSelector.retrieveClientSelectorCount, payload);
        yield put(retrieveClientSelectorCountSuccess(data));
    } catch (error){
        yield put(retrieveClientSelectorCountError(error));
        console.error(error);
    }
}

function* retrieveClientSelectedSaga({payload}) {
    try{
        const { data } = yield call(API.post, ApiRoute.clientSelector.retrieveClientSelected, payload);
        yield put(retrieveClientSelectedSuccess(data));
    } catch (error){
        yield put(retrieveClientSelectedError(error));
        console.error(error);
    }
}

export function* clientSelectorSagaWatcher(){
    yield takeLatest(retrieveClientSelector.type, retrieveClientSelectorSaga);
    yield takeLatest(retrieveClientSelectorCount.type, retrieveClientSelectorCountSaga);
    yield takeLatest(retrieveClientSelected.type, retrieveClientSelectedSaga);
}