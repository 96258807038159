import { call, delay, put, takeLatest } from "redux-saga/effects";
import {
    createKpi,
    createKpiSuccess,
    createKpiError,
    retrieveKpi,
    retrieveKpiSuccess,
    retrieveKpiError,
    updateKpi,
    updateKpiSuccess,
    updateKpiError,
    deleteKpi,
    deleteKpiSuccess,
    deleteKpiError,
    setKpiContextSuccess,
    setKpiContextError,
    setKpiContext,
} from "../stores/slices/kpiSlices"
import { API } from "../http-common";
import { ApiRoute } from "../enums/ApiRoute";

function* createKpiSaga({payload}){
    try{
        yield call(API.post, ApiRoute.kpi.createKpi, payload);
        yield put(createKpiSuccess(payload))
    } catch (error){
        yield put(createKpiError(error));
        console.error(error);
    }
}

function* retrieveKpiSaga({payload}){
    try{
        const { data } = yield call(API.post, ApiRoute.kpi.retrieveAllKpi, payload);
        const processedData = data.map((kpi) => {
            const parsedMembers = JSON.parse(kpi.targetMembers);
            return {
                ...kpi,
                targetMembers : parsedMembers
            }
        })

        yield put(retrieveKpiSuccess(processedData))
    } catch (error){
        yield put(retrieveKpiError(error));
        console.error(error);
    }
}

function* updateKpiSaga({payload}){
    try{
        yield call(API.post, ApiRoute.kpi.updateKpi, payload);
        yield put(updateKpiSuccess(payload))
    } catch (error){
        yield put(updateKpiError(error));
        console.error(error);
    }
}

function* deleteKpiSaga({payload}){
    try{
        yield call(API.post, ApiRoute.kpi.deleteKpi, payload);
        yield put(deleteKpiSuccess(payload))
    } catch (error){
        yield put(deleteKpiError(error));
        console.error(error);
    }
}

function* setKpiContextSaga({payload}){
    try{
        yield put(setKpiContextSuccess(payload));
    } catch (error){
        
        yield put(setKpiContextError(error));
        console.error(error);
    }
}

export function* kpiSagaWatcher(){
    yield takeLatest(createKpi.type, createKpiSaga);
    yield takeLatest(retrieveKpi.type, retrieveKpiSaga);
    yield takeLatest(updateKpi.type, updateKpiSaga);
    yield takeLatest(deleteKpi.type, deleteKpiSaga);
    yield takeLatest(setKpiContext.type, setKpiContextSaga);
}