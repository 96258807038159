import React, {useEffect, useState, useRef} from 'react'
import { PieChart, Pie, Cell, Legend } from 'recharts';
import { TbFileExport } from 'react-icons/tb';
import { FaUserAlt } from "react-icons/fa";
import { BsClipboardData } from 'react-icons/bs';
import { AiTwotoneCalendar } from 'react-icons/ai';
import { BiUser } from 'react-icons/bi';

import { Modal } from 'bootstrap';
import jsPDF from 'jspdf';
import download from 'downloadjs'
import moment from "moment";
import * as htmlToImage from 'html-to-image'
import { AiFillCloseCircle, AiFillFilePdf, AiOutlineFileJpg, AiFillPicture } from 'react-icons/ai';
import C_Button from '../../../components/C_Button';
import C_Loading from '../../../components/C_Loading';

import { selectAnalyticsContext } from '../../../stores/slices/analyticsSlices';
import { selectCurrUserContext } from '../../../stores/slices/companySlices';

function Graph_Pie_LIVE(props) {

  const screenshotRef = useRef(null);
  const exportModalRef = useRef(null);

  const sidebarCollapse = props.sidebarCollapse
  const author = props.author;

  const graphData = props.GraphData;
  const analytics = props.analytics;
  const yAxis = analytics.yAxis;
  const xAxis = analytics.xAxis;
  const dataOrigin = analytics.DataSource;
  const CreatedAt = analytics.CreatedAt;
  const GraphTitle = analytics.GraphTitle;
  const PieProperty = analytics.PieProperty;

  const graphRef = useRef(null);
  const [graphWidth, setGraphWidth] = useState(0);
  const [graphHeight, setGraphHeight] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const { dispatchTriggerAction } = selectAnalyticsContext();
  const { currUsers } = selectCurrUserContext();

  useEffect(() => {
    setGraphWidth(graphRef.current?.clientWidth * 0.9);
    setGraphHeight(graphRef.current?.clientWidth * 0.9 * 0.5);
  },[xAxis, yAxis, graphData, PieProperty, sidebarCollapse]);

  function notify(data){
    dispatchTriggerAction({type: "TRIGGER", payload: data})
    setTimeout(() => {
      dispatchTriggerAction({type: "TRIGGER", payload: ''})
    }, 1500)
  };

  const RADIAN = Math.PI / 180;
  const DEFAULT = ['#3b85c0','#fb7b8b','#294679','#575bac','#cfaca4','#623337']
  const COLORS_THEME_1 = ['#d45087','#f95d6a','#ff7c43','#ffa600','#003f5c','#2f4b7c','#665191','#a05195'];
  const COLORS_THEME_2 = ['#00876c','#5fa176','#97ba87','#cbd4a1','#faeec2','#f0c892','#e99f6c','#e17257','#d43d51'];
  const COLORS_THEME_3 = ['#004c6d','#006083','#007598','#008bac','#00b8d2','#00cfe3','#03e7f2','#32ffff']
  const COLORS_THEME_4 = ['#de425b','#dd757d','#d69fa1','#c6c6c6','#b8da99','#a2ed66','#7fff00']
  const COLORS_THEME_5 = ['#2085ec','#72b4eb','#0a417a','#8464a0','#cea9bc','#323232']

  const colorObj = {
    default: DEFAULT,
    "palette 1": COLORS_THEME_1,
    "palette 2": COLORS_THEME_2,
    "palette 3": COLORS_THEME_3,
    "palette 4": COLORS_THEME_4,
    "palette 5": COLORS_THEME_5
  }

  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
  
    return (
      <text x={x} y={y} fontSize={PieProperty?.textSize} fill={PieProperty?.textColor} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {PieProperty?.LabelKey ? graphData[index][xAxis[0]?.["key"]] : ""} {PieProperty?.Percentage ? `${(percent * 100).toFixed(0)}%` : graphData[index][yAxis[0]?.["key"]]}
      </text>
    );
  };

  const renderPie = () => {
    if(Object.values(PieProperty).length !== 0) {
      const piepie = analytics.yAxis.map((value, index) => {
        if(value.key === "Handler"){
          const userObj = currUsers.find(user => user.uid === value.id);
          if(userObj){
            return (
              <Pie dataKey={userObj.displayName} data={graphData} innerRadius={PieProperty?.innerRadius} label={PieProperty?.LabelPosition === "Inner" ? renderCustomizedLabel : true} labelLine={PieProperty?.LabelPosition === "Outer" && PieProperty?.LabelLine ? true : false }>
            {graphData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={colorObj[PieProperty?.colorPalette][index % colorObj[PieProperty?.colorPalette]?.length]} stroke={PieProperty?.strokeColor} strokeWidth={PieProperty?.strokeWidth} />
            ))}
          </Pie>
            )
          }
        } else {
          return (
            <Pie dataKey={value.key} data={graphData} innerRadius={PieProperty?.innerRadius} label={PieProperty?.LabelPosition === "Inner" ? renderCustomizedLabel : true} labelLine={PieProperty?.LabelPosition === "Outer" && PieProperty?.LabelLine ? true : false }>
            {graphData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={colorObj[PieProperty?.colorPalette][index % colorObj[PieProperty?.colorPalette]?.length]} stroke={PieProperty?.strokeColor} strokeWidth={PieProperty?.strokeWidth} />
            ))}
          </Pie>
          )
        }
      })
     
      return piepie;
    }
  };


  const showExportModalFunc = () => {
    const modalEle = exportModalRef.current;
    const bsModal = new Modal(modalEle, {
      backdrop: "static",
      keyboard: false,
    });
    bsModal.show();
  };

  const hideExportModalFunc = () => {
    const modalEle = exportModalRef.current;
    const bsModal = Modal.getInstance(modalEle);
    bsModal.hide();
  };

  const generatePDF = async () => {
    const dataUrl = await htmlToImage.toPng(screenshotRef.current);
    var image = new Image();
    image.src = dataUrl
    var doc = new jsPDF('p', 'px', 'letter'); //orientation, unit, format
    var width = doc.internal.pageSize.getWidth();
    var height = doc.internal.pageSize.getHeight() * 0.6;

    doc.addImage(image, 'PNG', 0, 0, width, height); //base64 image, format, x-coordinate, y-coordinate, width, height

    doc.save(`${GraphTitle !== "" ? GraphTitle : moment().format("DDMMYYYYHHMMS")}.pdf`);
  }

  const generatePNG = async () => {
    await htmlToImage.toPng(screenshotRef.current, { quality: 0.95, backgroundColor: '#FFFFFF'})
      .then(function (dataUrl) {
        download(dataUrl, `${GraphTitle !== "" ? GraphTitle : moment().format("DDMMYYYYHHMMS")}.png`);
      });
  }

  const generateJPEG = async () => {
    await htmlToImage.toPng(screenshotRef.current, { quality: 0.95, backgroundColor: '#FFFFFF'})
      .then(function (dataUrl) {
        download(dataUrl, `${GraphTitle !== "" ? GraphTitle : moment().format("DDMMYYYYHHMMS")}.jpeg`);
      });
  }

  const handleOnChange = (position) => {
    const updatedCheckedState = userCheckState.map((item, index) =>
        index === position ? !item : item
    );

    setUserCheckedState(updatedCheckedState);

  };

  function dataURLtoFile(dataurl, filename) {

    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
        
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    
    return new File([u8arr], filename, {type:mime});
  };

  return (
    <div className='graphContainer' ref={graphRef}>
      <C_Loading isLoading={isLoading}/>
      <div
        className="modal fade"
        tabIndex="-1"
        ref={exportModalRef}
      >
        <div className="modal-dialog modal-dialog-centered" style={{ width: "15%" }}>
          <div className="modal-content">
            <div className="modal-body d-flex flex-column" style={{  }}>
              <div className="exportHeader">
                <span>EXPORT</span>
                <AiFillCloseCircle className="close" size={16} onClick={() => { hideExportModalFunc() }}/>
              </div>
              <div className="exportOptions">
                <C_Button
                  width={"80%"}
                  buttonText={"PDF"}
                  justify={"space-evenly"}
                  onClick={() => { generatePDF() }}
                  icon={<AiFillFilePdf size={16} />}
                  textColor={"#000000"}
                  marginLeft={"0.5rem"}
                  dismissAction="modal"
                />
                <C_Button
                  width={"80%"}
                  buttonText={"PNG"}
                  justify={"space-evenly"}
                  onClick={() => { generatePNG() }}
                  icon={<AiFillPicture size={16} />}
                  textColor={"#000000"}
                  marginLeft={"0.5rem"}
                  dismissAction="modal"
                />
                <C_Button
                  width={"80%"}
                  buttonText={"JPG"}
                  justify={"space-evenly"}
                  onClick={() => { generateJPEG() }}
                  icon={<AiOutlineFileJpg size={16} />}
                  textColor={"#000000"}
                  marginLeft={"0.5rem"}
                  dismissAction="modal"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='user'>
        <div className='userDetails'>
          <FaUserAlt className='icon' size={13}/>
        </div>
        <div className='authorDetails'>
          <div className='data'>
            <BiUser size={12}/>
            <span>{author?.displayName ? author?.displayName : "N/A"}</span>
          </div>
          <div className='data'>
            <BsClipboardData size={12}/>
            <span>{dataOrigin}</span>
          </div>
          <div className='data'>
            <AiTwotoneCalendar size={12}/>
            <span>{CreatedAt}</span>
          </div>
        </div>
      </div>
      <div className='tools'>
        <div className='export' onClick={() => { showExportModalFunc() }}>
          <TbFileExport size={18}/>
        </div>
      </div>
      <div className='graph' ref={screenshotRef}>
        <div className='headerTools'>
          <div className='title'>
            <span>{GraphTitle}</span>
          </div>
        </div>
        <PieChart
          width={graphWidth}
          height={graphHeight}
        >
        <Legend
          payload={
            graphData.map(
              (item, index) => ({
                id: item[xAxis[0]?.["key"]],
                type: "square",
                value: `${item[xAxis[0]?.["key"]]}`,
                color: colorObj[PieProperty?.colorPalette][index % colorObj[PieProperty?.colorPalette].length]
              })
            )
          }
        />
          {renderPie()}
        </PieChart>
      </div>
    </div>
  )
}

export default Graph_Pie_LIVE