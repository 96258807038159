import React from 'react'
import './C_PopoutNoti.scss'

const C_PopoutNoti = (props) => {
    return (
        <div className={`saved-noti ${props.triggerSaved !== "" && 'save-noti-triggered'}`}>
            <span>{props.triggerSaved}</span>
        </div>
    );
}

export default C_PopoutNoti;