/* eslint-disable react/no-children-prop */
//react
import React, { Fragment, useEffect } from "react";

//libraries
import moment from "moment";
import PhoneInput from "react-phone-input-2";
import ReactQuill from "react-quill";
import "react-phone-input-2/lib/style.css";
import "react-quill/dist/quill.snow.css";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

//react icon
import { AiOutlinePlusCircle, AiOutlineClose } from "react-icons/ai";
import { BiEditAlt } from "react-icons/bi";

import { MdAssignmentInd } from "react-icons/md";

import C_DialogButton from "../../../components/C_DialogButton";

import dateFormat from "../../../enums/json/dateType.json";
import { useDispatch } from "react-redux";
import { v4 } from "uuid";
import CommonFunc from "../../../util/CommonFunc";
import { useState } from "react";
import C_HandlerSearchDropdown from "../../../components/C_HandlerSearchDropdown";

//slices
import { createClient, retrieveAllClientsCount, retrieveClient, selectAvailableClientsGroup, selectClient, selectClientContext, selectExistingClientData, setClientContext, updateClient } from "../../../stores/slices/clientSlices";
import { createLogs } from "../../../stores/slices/logSlices";
import { createClientAgent } from "../../../stores/slices/clientAgentSlices";
import { selectUser } from "../../../stores/slices/userAuthSlices";
import { selectCurrUserClientGroupRoles } from "../../../stores/slices/clientGroupRolesSlices";

import "./ClientManageModal.scss"
import { selectCurrUserContext } from "../../../stores/slices/companySlices";

export const ClientManageModal = (props) => {
  const currSa = props.currSa;
  // const newClientRef = props.newClientRef;
  const manageClientModalRef = props.manageClientModalRef;
  const showHandlerOptions = props.showHandlerOptions;
  const isEdit = props.isEdit;
  const setIsEdit = props.setIsEdit;
  const setMixedClients = props.setMixedClients;
  const setSelectedClient = props.setSelectedClient;

  const mixedClients = props.mixedClients;
  const selectedClient = props.selectedClient;
  const containsTrue = props.containsTrue;
  const refreshUsage = props.refreshUsage;
  const clientUsage = props.clientUsage;
  const clientQuota = props.clientQuota;
  const checkExistingClientData = props.checkExistingClientData;
  const tenant = props.tenant;
  const companyID = props.companyID;
  const selectedGroupValue = props.selectedGroupValue;
  const setIsLoading = props.setIsLoading;
  const hideManageClientModal = props.hideManageClientModal
  const setNotifyMessage = props.setNotifyMessage;
  const selectedMemberList = props.selectedMemberList;
  const setSelectedMemberList = props.setSelectedMemberList;
  // const handleCreateSubGroupSingle = props.handleCreateSubGroupSingle;
  const handleCreateClientAgentSingle = props.handleCreateClientAgentSingle;
  const setLoadData = props.setLoadData;
  const isSuperAdmin = props.isSuperAdmin;
  const clientGroupLeader = props.clientGroupLeader;
  const setShowHandlerOptions = props.setShowHandlerOptions;
  const retrieveClientFunction = props.retrieveClientFunction;

  const mixedSelectedClients = props.mixedSelectedClients;
  const tempClient = props.tempClient;
  const tempMixedClient = props.tempMixedClient;
  const tempSelectedClient = props.tempSelectedClient;

  const isEmailFormat = props.isEmailFormat;
  const isRequiredEmpty = props.isRequiredEmpty;
  const setIsEmailFormat = props.setIsEmailFormat;
  const setIsRequiredEmpty = props.setIsRequiredEmpty;

  const isEditEmailFormat = props.isEditEmailFormat;
  const isEditRequiredEmpty = props.isEditRequiredEmpty;
  const setIsEditEmailFormat = props.setIsEditEmailFormat;
  const setIsEditRequiredEmpty = props.setIsEditRequiredEmpty;
  const availableMemberList = props.availableMemberList;
  const sharedHandlerList = props.sharedHandlerList;
  const setSharedHandlerList = props.setSharedHandlerList;
  const tempSelectedMemberList = props.tempSelectedMemberList;
  const handleDeleteClientAgent = props.handleDeleteClientAgent;
  const handleInsertClientAgent = props.handleInsertClientAgent;
  const setLoadFlowChart = props.setLoadFlowChart;
  const searchText = props.searchText;
  const sortType = props.sortType;
  const sortOrder = props.sortOrder;
  const dataFilterArr = props.dataFilterArr;

  const { currUsers, currUsage, currTotalUsage, currUserSA, currUserInvitation } = selectCurrUserContext();
  const availableClientsGroup = selectAvailableClientsGroup();

  const [existingDataMsg, setExistingDataMsg] = useState("");
  const [isPhoneFormat, setIsPhoneFormat] = useState(true);
  const [isEditPhoneFormat, setIsEditPhoneFormat] = useState(true);
  const [isLocationFormat, setIsLocationFormat] = useState(true);
  const [isEditLocationFormat, setIsEditLocationFormat] = useState(true);
  const [isUrlFormat, setIsUrlFormat] = useState(true);
  const [isEditUrlFormat, setIsEditUrlFormat] = useState(true);
  const [isDataExist, setIsDataExist] = useState(false);
  const [shouldEditContinue, setShouldEditContinue] = useState(true);
  const [shouldCreateContinue, setShouldCreateContinue] = useState(true);
  const [dataExistedColumn, setDataExistedColumn] = useState("");

  const existingClientData = selectExistingClientData();
  const currUserClientGroupRole = selectCurrUserClientGroupRoles();

  const user = selectUser();
  const dispatch = useDispatch();
  const {
    filteredClientData,
    clientColumnArray,
    clientColArray,
    checkedClientState,
    filteredMergedData,

    // setCheckedClientState,
    // setCheckedAllClientState,
  } = selectClientContext();

  useEffect(()=>{
    if(existingClientData && existingClientData === "Data exists"){
      if(isEdit){
        setIsDataExist(true);
        setShouldEditContinue(false);
        setExistingDataMsg(`${dataExistedColumn} Existed!`)
      } else {
        setIsDataExist(true);
        setShouldCreateContinue(false);
        setExistingDataMsg(`${dataExistedColumn} Existed!`)
      }
    }
  }, [existingClientData])

  function bulkManageHandler(param, action){
    let sharedArray = [...sharedHandlerList];

    sharedArray = sharedArray.map(item => {
      if (item.uid === param.uid) {
        if (action === item.value) {
            return { ...item, value: null }; 
        } else {
            return { ...item, value: action }; 
        }
      }
      return item;
    });

    setSharedHandlerList(sharedArray);
    
  }

  function manageHandler(param){
    if (selectedMemberList.some(handler => handler.uid === param.uid)) {
      setSelectedMemberList(selectedMemberList.filter((item) => item !== param));
    } else {
        setSelectedMemberList([...selectedMemberList, param]);
    }
  }

  const clearClientRowData = (index, columnType) => {
    let selected = [...selectedClient];
    if (columnType === "Checkboxes") {
      selected[index].rowValue = [];
    } else {
      selected[index].rowValue = "null";
    }

    setSelectedClient(selected);
  };

  const handleRteInput = (e, index) => {
    const userData = [...selectedClient];
    const value = e.target.value;
    userData[index] = { ...userData[index], rowValue: value };
    setSelectedClient(userData);

  };

  const handleDropdownChoices = (e, index) => {
    const userData = [...selectedClient];
    const value = e.target.value;
    userData[index] = { ...userData[index], optionChoices: value };
    setSelectedClient(userData);
  };

  const handleInput = (e, index, type, alertType, otherValue) => {
    const userData = [...selectedClient];
    const newRowData = { ...userData[index] };
  
    if (type === "Phone") {
      newRowData.rowValue = e;
    } else if (type === "Google Map") {
      let value = e.target.value;
      let location = clientColumnArray.find(element => element.columnType === "Google Map");

      if (location !== undefined) {
        newRowData.rowValue = decodeURIComponent(value);
      }
    } else if (type === "Multiple Choices") {
      newRowData.rowValue = e.target.value;
    } else if (type === "Checkboxes") {
      if(otherValue){
        let value = e.target.value;
        newRowData.otherValue = value;
      } else {
        if (Array.isArray(newRowData.rowValue)) {
          if (e.target.checked) {
            newRowData.rowValue = [...newRowData.rowValue, e.target.value];
          } else {
            newRowData.rowValue = newRowData.rowValue.filter(val => val !== e.target.value);
          }
        }
      }
    } else if (type === "Alert") {
      newRowData.rowValue = { ...newRowData.rowValue, [alertType]: e.target.value };
    } else {
      let value = e.target.value;
      let rte = clientColumnArray.find(element => element.columnType === "Rich Text Editor");

      newRowData.rowValue = value;
    }
  
    userData[index] = newRowData;
    setSelectedClient(userData);
  };
  

  const editClient = async () => {

    setIsLoading(true);
    const clientArray = [];
    let changedValues = [];
    let emailFormat = true;
    let phoneFormat = true;
    let locationFormat = true;
    let urlFormat = true;
    let dataIsExist = false;
    setShouldEditContinue(true);
    // let shouldContinue = true;
    let empty = false;

    ////////bulk edit//////
    if (mixedClients) {
      let tempClients = JSON.parse(JSON.stringify(mixedSelectedClients));
      let selected = [...selectedClient];

      async function processUpdateData() {
        // Loop through each item in tempClients
        tempClients.forEach(tempItem => {
          // Loop through each cell in the item
          tempItem.forEach(cell => {
              // Find the corresponding selected item based on columnID
              const selectedItem = selected.find(selectedItem => selectedItem.columnID === cell.columnID);

              const columnObj = clientColArray.find(col => col.columnID === cell.columnID);
              // If a corresponding selected item is found, update the rowValue
              if (selectedItem && columnObj !== undefined) {
                const columnType = columnObj.columnType;
                const required = columnObj.required;
                
                switch(columnType){
                  case "Alert":
                    const isQualified = isSuperAdmin || clientGroupLeader.some(element=>element.UserID === user.uid);
                    if(selectedItem.rowValue === "null"){
                      if(isQualified){
                        cell.rowValue = { 
                          ...cell.rowValue,
                          expiry: null
                        }
                      }else{
                        return;
                      }
                    }else if(selectedItem.rowValue === null){
                      return;
                    }else{
                      cell.rowValue = {
                        ...cell.rowValue,
                        ...isQualified ? { expiry: selectedItem.rowValue?.expiry } : { status: selectedItem.rowValue?.status }
                      }
                    }
                    break;
                  case "Checkboxes":
                    if(selectedItem.rowValue === "null"){
                      cell.rowValue = [];
                    }else if(selectedItem.rowValue?.length <= 0){
                      return;
                    }else{
                      cell.rowValue = selectedItem.rowValue;
                    }
                    break;
                  default:
                    if(selectedItem.rowValue === "null"){
                      cell.rowValue = "";
                    }else if(selectedItem.rowValue === ""){
                      return;
                    }else{
                      cell.rowValue = selectedItem.rowValue;
                    }
                    break;
                }
                //Check require
                if (currSa) {
                  if(selectedItem.rowValue !== "" && selectedItem.rowValue !== "null" && columnType === "Email") {
                      emailFormat = CommonFunc.emailValidation(selectedItem.rowValue)
                  }
                } else {
                  if(required && (selectedItem.rowValue === "" || !selectedItem.rowValue || selectedItem.rowValue === "null")) {
                    empty = true
                  }
                  if(selectedItem.rowValue !== "" && selectedItem.rowValue !== "null" && selectedItem.rowValue && columnType === "Email") {
                    emailFormat = CommonFunc.emailValidation(selectedItem.rowValue)
                  }
                }
              }
          });
        });

        return tempClients;
      };

      await processUpdateData();

      setIsEmailFormat(emailFormat)
      setIsPhoneFormat(phoneFormat)
      setIsLocationFormat(locationFormat)
      setIsUrlFormat(urlFormat)

      if (phoneFormat === true && locationFormat === true && urlFormat === true && emailFormat === true ) 
      {
        //////row////////
        const changedValues = [];
        const arrayClientID = [];
        let changes = false;
        for (var x = 0; x < checkedClientState.length; x++) {
            const clientObj = tempClient.find(element => element.ClientID === checkedClientState[x])
            if (clientObj !== undefined) {
              arrayClientID.push(clientObj.ClientID);
              clientArray.push({
                ClientID: clientObj.ClientID,
                TableID: clientObj.TableID,
                ClientData: JSON.stringify(tempClients[x]),
              })
            }
        }

        //check updated values
        let jsonClient = JSON.parse(tempMixedClient);
        for (let i = 0; i < tempClients.length; i++) {
          const row = [];
          for (let j = 0; j < tempClients[i].length; j++) {
            const data = tempClients[i][j];
            const jsonClientData = jsonClient[i][j];
            const clientNameIndex = clientColumnArray.findIndex(element=>element.columnIndex === 0);
            if (data.rowValue !== jsonClientData.rowValue && !Array.isArray(data.rowValue)) {
              changes = true;
              const columnObj = clientColumnArray.find(element => element.columnID === data.columnID);
              if(columnObj !== undefined){
                const type = columnObj.columnType;
                const oriValue = type !== "Alert" ? jsonClientData.rowValue : jsonClientData.rowValue.status === true ? "Completed" : "Pending";
                const changedValue = type !== "Alert" ? data.rowValue : data.rowValue.status === true ? "Completed" : "Pending";
                row.push({
                  name : tempClients[i][clientNameIndex]?.rowValue, //clientName
                  oriValue: oriValue,
                  changedValue: changedValue,
                  columnName: columnObj.columnName
                });
              }
            }
            
            //checkboxes
            if(Array.isArray(data.rowValue)) {
              let columnObj = clientColumnArray.find(element => element.columnID === data.columnID)
              const isNotEqual = !jsonClientData?.rowValue?.every((value, index) => value === data?.rowValue[index]);
              if (isNotEqual) {
                changes = true;
                row.push({
                  name : tempClients[i][clientNameIndex]?.rowValue,
                  oriValue: jsonClientData.rowValue,
                  changedValue: data.rowValue,
                  columnName: columnObj.columnName
                });
              }
            } 
          }
          changedValues.push(row);
        }
        
        let addHandlerList = [];
        const selectedRemoveHandlerList = sharedHandlerList.filter(list => {
            if(list.value === "bulk_remove"){
              return list.data
            }
        })

        const selectedAddHandlerList = sharedHandlerList.filter(list => {
          if(list.value === "bulk_add"){
            return list.data
          }
      })

        arrayClientID.forEach((clientID) => {
          selectedAddHandlerList.forEach((handler) => {
            addHandlerList.push({
              ClientAgentID: v4(),
              GroupID: selectedGroupValue?.GroupID,
              UserID: handler?.uid,
              ClientID: clientID
            })
          })
        })

        try {
            handleDeleteClientAgent(selectedRemoveHandlerList, arrayClientID)
            handleInsertClientAgent(addHandlerList);
            
            setSharedHandlerList([]);
        } catch (error) {
            console.error(error);
        }

        dispatch(updateClient({ TenantID: tenant, data: clientArray, GroupID: selectedGroupValue ? 
          selectedGroupValue?.GroupID 
          : availableClientsGroup[0]?.GroupID, UserID : user?.uid, isSA : currSa,
          setIsLoading, setLoadData, setNotifyMessage, setMixedClients, setSharedHandlerList
        }))
            hideManageClientModal();

            if(changes)
            {
              const ActivityLogsID = v4();
              const UserID = user.uid;
              const CompanyID = companyID;
              const GroupID = selectedGroupValue?.GroupID;
              const ClientID = JSON.stringify(arrayClientID);
              const Title = "List of clients have been updated";
              const Activity = `updated ${changedValues.length} clients`;
              const Action = "bulk_update";
              const Section = "Clients";
              const Keyword = JSON.stringify(changedValues);
              const CreatedAt = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
  
              const data = {
                  ActivityLogsID,
                  UserID,
                  CompanyID,
                  GroupID,
                  Title,
                  Activity,
                  Action,
                  Section,
                  Keyword,
                  CreatedAt,
              };

              dispatch(createLogs({ TenantID: tenant, data: data }));
            }
      } else {
        setIsLoading(false)
        setHasEmpty(false)
        setIsEmailFormat(emailFormat)
        setIsPhoneFormat(phoneFormat)
      }

    } else {
      //single edit
      let empty = false;
      for(let colIndex = 0; colIndex < clientColArray.length && shouldEditContinue; colIndex ++){
      const item = clientColArray[colIndex];
      if (item?.columnID === selectedClient[item.columnIndex]?.columnID && selectedClient[item.columnIndex] !== undefined && item !== undefined) {
        if (selectedClient[item.columnIndex].rowValue !== "ClientPassword" && item.columnType !== "Form") {

          //SA bypass required and duplicates
          if (currSa) {
            if(selectedClient[item.columnIndex].rowValue !== "" ) {
              if (item.columnType === "Email") {
                emailFormat = CommonFunc.emailValidation(selectedClient[item.columnIndex].rowValue)
              }
            }
          } else {
            if(item.required === true) {
              if (selectedClient[item.columnIndex].rowValue === "" || selectedClient[item.columnIndex].rowValue === undefined) {
                empty = true
              }
            }

            if(selectedClient[item.columnIndex].rowValue !== "" ) {
              if(JSON.parse(tempSelectedClient)[item.columnIndex].rowValue !== selectedClient[item.columnIndex].rowValue) {
                if(item.duplicate === false) {
                  setDataExistedColumn(item.columnName);
                  dispatch(checkExistingClientData({
                    TenantID: tenant,
                    RowValue: selectedClient[item.columnIndex].rowValue,
                    ColumnIndex: item.columnIndex,
                    CompanyID: companyID,
                    GroupID: selectedGroupValue?.GroupID,
                  }))
                }
              }
              if (!empty && item.columnType === "Email") {
                emailFormat = CommonFunc.emailValidation(selectedClient[item.columnIndex].rowValue)
              }
            }
          }
        }
      }
    }
    
      setIsDataExist(dataIsExist)
      setIsRequiredEmpty(empty)
      setIsEmailFormat(emailFormat)
      setIsPhoneFormat(phoneFormat)
      setIsLocationFormat(locationFormat)
      setIsUrlFormat(urlFormat)
      if ( 
        empty === false &&
        dataIsExist === false &&
        emailFormat === true &&
        phoneFormat === true &&
        locationFormat === true &&
        urlFormat === true) {
        for (var x = 0; x < checkedClientState.length; x++) {
          const clientObj = tempClient.find(element => element.ClientID === checkedClientState[x])
          if (clientObj !== undefined) {
            clientArray.push({
              ClientID: clientObj.ClientID,
              TableID: clientObj.TableID,
              ClientData: JSON.stringify(selectedClient),
            })
          }

        }

        if((JSON.stringify(selectedMemberList) !== JSON.stringify(tempSelectedMemberList))){
          const oldHandlerList = JSON.parse(tempSelectedMemberList);
          const newHandlerList = selectedMemberList;
          const ClientID = tempClient.find(element => checkedClientState.includes(element.ClientID)) !== undefined 
          ? tempClient.find(element => checkedClientState.includes(element.ClientID)).ClientID 
          : "";

          //function for comparign both ori and new arrays
          function compareArrays(originalArray, newArray) {
          
            const removedArray = originalArray
              .filter(old => !newArray.some(newItem => newItem.uid === old.uid))
              .map(removed => removed);

            const addedArray = newArray
              .filter(newItem => !originalArray.some(oldItem => oldItem.uid === newItem.uid));
          
            return { removedArray, addedArray };
          }
          
          const { removedArray, addedArray } = compareArrays(oldHandlerList, newHandlerList);

          let addHandlerList = [];

          addedArray.map((handler) => {
            addHandlerList.push({
              ClientAgentID: v4(),
              GroupID: selectedGroupValue?.GroupID,
              UserID: handler?.uid,
              ClientID: ClientID
            });
          });

          try {
              handleDeleteClientAgent(removedArray, [ClientID]);
              handleInsertClientAgent(addHandlerList);
          } catch (error) {
              console.error(error);
          }
        
        }
        setLoadFlowChart(true);

        dispatch(updateClient({ TenantID: tenant, data: clientArray, GroupID: selectedGroupValue ? 
          selectedGroupValue?.GroupID 
          : availableClientsGroup[0]?.GroupID, UserID : user?.uid, isSA : currSa,
          setIsLoading, setLoadData, setNotifyMessage  
        }))
            hideManageClientModal();
            const clientObj = filteredClientData.find(element=>element.ClientID === checkedClientState[0]);

            if(clientObj !== undefined) {
              
              let changes = false;
              if(selectedClient){
                for (let i = 0; i < selectedClient.length; i++) {
                  let jsonClient = JSON.parse(tempSelectedClient)
  
                  if ((selectedClient[i].rowValue !== jsonClient[i].rowValue) && !Array.isArray(selectedClient[i].rowValue)) {
                    changes = true;
                    let columnObj = clientColumnArray.find(element => element.columnID === selectedClient[i].columnID)
                    if(columnObj !== undefined){
                      const type = columnObj.columnType;
                      const oriValue = type !== "Alert" ? jsonClient[i].rowValue : jsonClient[i].rowValue.status === true ? "Completed" : "Pending";
                      const changedValue = type !== "Alert" ? selectedClient[i].rowValue : selectedClient[i].rowValue.status === true ? "Completed" : "Pending";
                      changedValues.push({
                        oriValue:oriValue,
                        changedValue:changedValue,
                        columnName:columnObj.columnName
                      });
                    }
                    
                  }
                  
                  if(Array.isArray(selectedClient[i].rowValue))
                  {
                    let columnObj = clientColumnArray.find(element => element.columnID === selectedClient[i].columnID)
                    const isNotEqual = !jsonClient[i]?.rowValue?.every((value, index) => value === selectedClient[i]?.rowValue[index]);
                    if (isNotEqual) {
                      changes = true
                      changedValues.push({
                        oriValue: jsonClient[i].rowValue,
                        changedValue: selectedClient[i].rowValue,
                        columnName: columnObj.columnName
                      });
                    }
                  }
                }
              }
              
              if(changes) {
                const ActivityLogsID = v4();
                const UserID = user.uid;
                const CompanyID = companyID;
                const GroupID = selectedGroupValue?.GroupID;
                const ClientID = JSON.stringify([checkedClientState[0]]);
                const Title = "A client has been updated";
                const Activity = `updated a client (${selectedClient[0].rowValue})`;
                const Action = "update";
                const Section = "Clients";
                const Keyword = JSON.stringify(changedValues);
                const CreatedAt = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");

                const data = {
                  ActivityLogsID,
                  UserID,
                  CompanyID,
                  GroupID,
                  Title,
                  Activity,
                  Action,
                  Section,
                  Keyword,
                  CreatedAt,
                };

                dispatch(createLogs({ TenantID: tenant, data: data }))
              }
            }
      } else {
        setIsLoading(false)
        setHasEmpty(empty)
        setIsEmailFormat(emailFormat)
        setIsPhoneFormat(phoneFormat)
      }
    }
  };

  const createNewClient = async () => {
    await refreshUsage();

    if (clientUsage < clientQuota) {
      let empty = false;
      let emailFormat = true;
      let phoneFormat = true;
      let locationFormat = true;
      let urlFormat = true;
      let dataIsExist = false;
      // let shouldContinue = true;
      setShouldCreateContinue(true);

      const nameColumn = clientColumnArray.find(
        (item) => item.columnName === "ClientName"
      );
      const name = selectedClient.find((item) => item.columnID === nameColumn.columnID)?.rowValue || "";

      for (let colIndex = 0; colIndex < clientColArray.length && shouldCreateContinue; colIndex++) {
        const item = clientColArray[colIndex];
        const selectedObj = selectedClient.find(data => data.columnID === item.columnID)
        if (item?.columnID === selectedObj?.columnID) {
          if (selectedObj.rowValue !== "ClientPassword" && item.columnType !== "Form") {
            if (item.required === true) {
              if (selectedObj.rowValue === "" || selectedObj.rowValue === undefined) {
                empty = true;
              }
            }

            if (selectedObj.rowValue !== "") {
              if (item.duplicate === false) {
                setDataExistedColumn(item.columnName);
                dispatch(
                  checkExistingClientData({
                    TenantID: tenant,
                    RowValue: selectedObj.rowValue,
                    ColumnIndex: item.columnIndex,
                    CompanyID: companyID,
                    GroupID: selectedGroupValue?.GroupID,
                  })
                )
                // .then((res) => {
                //   if (res === "Data exists") {
                //     dataIsExist = true;
                //     // shouldContinue = false;
                //     setShouldCreateContinue(false);
                //     setExistingDataMsg(`${item.columnName} Existed !`);
                //   }
                // });
              }

              if (item.columnType === "Email") {
                emailFormat = CommonFunc.emailValidation(
                  selectedObj.rowValue
                );
              }
            }
          }
        }
      }

      setIsDataExist(dataIsExist);
      setIsRequiredEmpty(empty);
      setIsEmailFormat(emailFormat);
      setIsPhoneFormat(phoneFormat);
      setIsLocationFormat(locationFormat);
      setIsUrlFormat(urlFormat);

      if (
        empty === false &&
        dataIsExist === false &&
        emailFormat === true &&
        phoneFormat === true &&
        locationFormat === true &&
        urlFormat === true
      ) {
        //////row////////
        setIsLoading(true);
        hideManageClientModal();
        const client_id = v4();
        let clientArray = {
          ClientID: client_id,
          UserID : user.uid,
          GroupID: selectedGroupValue?.GroupID,
          CompanyID: companyID,
          ClientData: JSON.stringify(selectedClient),
          Status: 1,
          DeletedAt : null,
          CreatedAt: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        };
        refreshUsage();
        dispatch(setClientContext({
          checkedClientState : [],
        }));
        setIsLoading(false);
        setNotifyMessage(CommonFunc.notify("create"));
        setLoadData(false);

        dispatch(createClient({ TenantID: tenant, data: clientArray, GroupID: selectedGroupValue ? 
          selectedGroupValue?.GroupID 
          : availableClientsGroup[0]?.GroupID, UserID : user?.uid, isSA : currSa,
          refreshUsage, setIsLoading, setNotifyMessage, setLoadData
        }))
            const agentBody = {
              ClientAgentID: v4(),
              GroupID: selectedGroupValue?.GroupID,
              UserID: user.uid,
              ClientID: client_id
            }
  
            if(!currSa){
              dispatch(createClientAgent({ TenantID: tenant, data: agentBody }));
            }

            const ActivityLogsID = v4();
            const UserID = user?.uid;
            const CompanyID = companyID;
            const GroupID = selectedGroupValue?.GroupID;
            const ClientID = JSON.stringify([]);
            const Title = "A client has been created";
            const Activity = `created a client (${name})`;
            const Action = "create";
            const Section = "Clients";
            const Keyword = name;
            const CreatedAt = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");

            const data = {
              ActivityLogsID,
              UserID,
              CompanyID,
              GroupID,
              Title,
              Activity,
              Action,
              Section,
              Keyword,
              CreatedAt,
            };

            dispatch(createLogs({ TenantID: tenant, data: data }))

              if(selectedMemberList.length > 0){
                const ClientName = selectedClient[0].rowValue;
                // handleCreateSubGroupSingle({ClientList: clientArray, ClientName});
                handleCreateClientAgentSingle({ClientData: clientArray});
              }

              // retrieveClientFunction([
              //   dispatch(
              //     retrieveAllClientsCount({
              //       TenantID: tenant,
              //       CompanyID: companyID,
              //       SearchText: searchText,
              //       SearchBy: "",
              //       UserID: user?.uid,
              //       CurrGroup: selectedGroupValue?.GroupID,
              //       isSA: currSa,
              //       SelectedDateColumn: "",
              //       StartDate: "",
              //       EndDate: "",
              //       DataFilterArr: dataFilterArr,
              //     })
              //   ),
              //   dispatch(
              //     retrieveClient({
              //       TenantID: tenant,
              //       CompanyID: companyID,
              //       CurrPage: 0,
              //       SortType: sortType,
              //       SortOrder: sortOrder,
              //       SearchText: searchText,
              //       SearchBy: "",
              //       UserID: user?.uid,
              //       CurrGroup: selectedGroupValue?.GroupID,
              //       isSA: currSa,
              //       SelectedDateColumn: "",
              //       StartDate: "",
              //       EndDate: "",
              //       DataFilterArr: dataFilterArr,
              //       availableClientsGroup,
              //       currUserSA,
              //       selectedGroupValue,
              //       user,
              //     })
              //   ),
              // ])
              //   .then(() => {
                  // setTimeout(() => {
                    refreshUsage();
                    dispatch(setClientContext({
                      checkedClientState : [],
                    }));
                    setIsLoading(false);
                    setNotifyMessage(CommonFunc.notify("create"));
                    setLoadData(false);
                  // }, 1500);
                // })
                // .catch((error) => console.log(error));
          
      } else {
        setIsLoading(false);
        setIsEmailFormat(emailFormat);
        setIsPhoneFormat(phoneFormat);
        return;
      }
    } else {
      alert("You've reached the limit");
      hideManageClientModal();
    }
  };

  const manageClientBodyRenderer = () =>{
    const isAuthorized = isSuperAdmin || clientGroupLeader.some(element=>element.UserID === user.uid);
    return (
        <Fragment>
         <div className="modal-body">
            <div className="new-member-modal-body">
                <div className="new-member-body">
                {(availableMemberList.length > 0 && (mixedClients < 2) && (isAuthorized)) &&
                    <div className="new-member-content" style={{ width: "100%" }}>
                    <div className="new-member-content-container">
                        <span>Handler</span>
                    </div>
                        <C_HandlerSearchDropdown 
                        handlerList={availableMemberList} 
                        selectedHandlerList={selectedMemberList} 
                        manageHandler={manageHandler} 
                        type={"single"}
                        action={"update"}
                        />
                    </div>
                }
                {/* ADD HANDLER */}
                {(availableMemberList.length > 0 && (mixedClients > 1) && (isAuthorized)) &&
                    <div className="new-member-content" style={{ width: "50%" }}>
                    <div className="new-member-content-container">
                        <span>Add Handler</span>
                    </div>
                    <C_HandlerSearchDropdown 
                        handlerList={availableMemberList} 
                        selectedHandlerList={sharedHandlerList} 
                        manageHandler={bulkManageHandler} 
                        type={"bulk"}
                        action={"bulk_add"}
                    />
                    </div>
                }
                {/* REMOVE HANDLER */}
                {(availableMemberList.length > 0 && (mixedClients > 1) && (isAuthorized)) &&
                    <div className="new-member-content" style={{ width: "50%" }}>
                    <div className="new-member-content-container">
                        <span>Remove Handler</span>
                    </div>
                    <C_HandlerSearchDropdown 
                        handlerList={availableMemberList} 
                        selectedHandlerList={sharedHandlerList} 
                        manageHandler={bulkManageHandler} 
                        type={"bulk"}
                        action={"bulk_remove"}
                    />
                    </div>
                }
                {clientColumnArray.map((item, index) => {
                const columnWidth = item.width || "25%";
                const isAlert = item.columnType === "Alert";
                const checkerAlert = isEdit ? (isAuthorized ? (!isAlert || isAuthorized) : true) : (!isAlert || isAuthorized);
                const checkerColumn = item.columnName !== "DepartmentID" && item.columnName !== "PosID" && item.columnName !== "ClientID" && item.columnName !== "UpdateAt" && item.columnType !== "Switch" && item.columnType !== "Form" && item.columnName !== "ClientPassword"  && item.columnName !== "CreatedAt";
                // Check isSA or isLeader then show date input for expiry

                if (checkerAlert && checkerColumn) {
                    return (
                        <Fragment>
                        <div className="new-member-content" style={{ width: columnWidth }}>
                            <div className="new-member-content-container">
                            <div className="d-flex">
                                <span>{item.columnName}
                                {item.duplicate === false && <><span className="dup-span">{`(unique)`} </span></>}
                                {item.privilege === "Hidden" && <><span className="dup-span">{`(hidden)`} </span></>}
                                {item.privilege === "View" && <><span className="dup-span">{`(view)`} </span></>}
                                {item.required === true && <><span className="font-xxs" style={{color :"#f54d4d"}}>*</span></>}
                                {(item.columnType === "Dropdown" || item.columnType === "Multiple Choices") && <>{selectedClient[item.columnIndex]?.rowValue === "null" ? ` (${selectedClient[item.columnIndex]?.rowValue})`: <></>}</>}
                                {(item.columnType === "Checkboxes") && <>{selectedClient[item.columnIndex]?.rowValue === "null" ? ` (null)`: <></>}</>}
                                {item.columnType === "Alert" && (isAuthorized) ? <span className="font-xxs"> - Set Expiration</span> : <></>}
                                {item.columnType === "Alert" && (isAuthorized) && selectedClient[item.columnIndex]?.rowValue === "null" ? ` (null)` : <></> }
                                {item.columnType === "Alert" && (!isAuthorized) && selectedClient[item.columnIndex]?.rowValue === "null" ? <span className="font-xxs"> (Default)</span> : <></>}
                                </span>
                            </div>
                            {mixedClients ?
                                <>
                                {!item.required && item.duplicate || isSuperAdmin ?
                                <div className="clear-all-span" onClick={()=>{clearClientRowData(item.columnIndex, item.columnType);}}>
                                    {item.columnType === "Alert" ?
                                    isAuthorized ? 
                                    <span>Clear All</span> 
                                    : 
                                    <span>Revert</span>
                                    :
                                    <span>Clear All</span>
                                    }
                                </div>
                                :
                                null
                                }
                                </>
                                :
                                <></>
                            }
                            </div>
                            <Fragment>
                            {(!isAuthorized && item.privilege === "View")
                                ? <div className="new-member-input d-flex" style={{alignItems:'center',background:'#eeeeee'}}>{selectedClient[item.columnIndex]?.rowValue !== "" ? selectedClient[item.columnIndex]?.rowValue  : "-"}</div>
                                : 
                            <Fragment>
                            {((checkedClientState.length > 1 && item.duplicate === false)) ? 
                                <div className="new-member-input d-flex" style={{alignItems:'center',background:'#eeeeee'}}>{selectedClient[item.columnIndex]?.rowValue !== "" ? selectedClient[item.columnIndex]?.rowValue  : "-"}</div>
                                :
                                <Fragment>
                                    {item.columnType === "Alert" ?
                                    (isAuthorized) ?
                                        <input
                                        className={`new-member-input`}
                                        type={"date"}
                                        value={selectedClient && selectedClient[item.columnIndex]?.rowValue?.expiry && mixedClients === 0 && selectedClient[item.columnIndex]?.rowValue !== "null" ? selectedClient[item.columnIndex]?.rowValue?.expiry : null}
                                        name={item.columnName}
                                        onChange={(e) => { handleInput(e, item.columnIndex, item.columnType, 'expiry'); }}
                                        />
                                        :
                                        <div className="new-alert">
                                        <label style={{ display: 'flex', alignItems: 'center' }}>
                                            <input 
                                            type={"checkbox"} 
                                            checked={selectedClient && selectedClient[item.columnIndex]?.rowValue?.status && mixedClients === 0 && selectedClient[item.columnIndex]?.rowValue !== "null" ? selectedClient[item.columnIndex]?.rowValue?.status : null} 
                                            onChange={(e) => {handleInput(e, item.columnIndex, item.columnType, 'status'); }}
                                            />
                                            <span className="checkmark" style={{ marginRight: '10px' }}></span>
                                            <span className="new-member-mcq-span font-xs" title="Mark as completed">Mark as completed</span>
                                        </label>
                                        </div>
                                        :
                                        null
                                    }

                                    {item.columnType === "Date" &&
                                    <input
                                        className={`new-member-input ${item.privilege === "Hidden" && "sa-hidden-input"}`}
                                        type={dateFormat[item.dateType]}
                                        value={selectedClient  && selectedClient[item.columnIndex]?.rowValue !== "null"  ? item.dateType === "YYYY" ? selectedClient[item.columnIndex]?.rowValue : moment(selectedClient[item.columnIndex]?.rowValue).format(item.dateType) : ""}
                                        name={item.columnName}
                                        onChange={(e) => { handleInput(e, item.columnIndex, item.columnType, null, null, item.dateType); }}
                                    />
                                    }

                                    {item.columnType === "Member" &&

                                    <>
                                    <select
                                        className={`new-member-input ${item.privilege === "Hidden" && "sa-hidden-input"}`}
                                        name={item.columnName}
                                        value={selectedClient && selectedClient[item.columnIndex]?.rowValue !== "null"  ? selectedClient[item.columnIndex]?.rowValue : mixedClients + "selected"}
                                        onChange={(e) => { handleInput(e, item.columnIndex, item.columnType); }}
                                        >
                                        <option selected value={"-"}>{selectedClient[item.columnIndex]?.rowValue === "null" ? selectedClient[item.columnIndex]?.rowValue : mixedClients !== 0 ? mixedClients + " selected" : "Select a Member"}</option>
                                        {filteredMergedData.map(user => {
                                        return <option value={user.UserID}>{user.UserName}</option>
                                        })}
                                    </select>
                                    </>
                                    }

                                    {item.columnType === "Short Text" &&

                                    <input
                                        className={`new-member-input ${item.privilege === "Hidden" && "sa-hidden-input"}`}
                                        placeholder={selectedClient[item.columnIndex]?.rowValue === "null" ? selectedClient[item.columnIndex]?.rowValue : mixedClients !== 0 ? mixedClients + " selected" : "Enter " + item.columnName}
                                        name={item.columnName}
                                        value={selectedClient  && selectedClient[item.columnIndex]?.rowValue !== "null"  ? selectedClient[item.columnIndex]?.rowValue : ""}
                                        onChange={(e) => {
                                        handleInput(e, item.columnIndex, item.columnType);
                                        }}
                                    />
                                    }

                                    {item.columnType === "Multiline" &&
                                    <textarea
                                        className={`new-member-input ${item.privilege === "Hidden" && "sa-hidden-input"}`}
                                        placeholder={selectedClient[item.columnIndex]?.rowValue === "null" ? selectedClient[item.columnIndex]?.rowValue : mixedClients !== 0 ? mixedClients + " selected" : "Enter " + item.columnName}
                                        name={item.columnName}
                                        value={selectedClient  && selectedClient[item.columnIndex]?.rowValue !== "null"  ? selectedClient[item.columnIndex]?.rowValue : ""}
                                        onChange={(e) => {
                                        handleInput(e, item.columnIndex, item.columnType);
                                        }}
                                    />
                                    }


                                    {item.columnType === "Email" &&
                                    <input
                                        className={`new-member-input ${item.privilege === "Hidden" && "sa-hidden-input"}`}
                                        placeholder={selectedClient[item.columnIndex]?.rowValue === "null" ? selectedClient[item.columnIndex]?.rowValue : mixedClients !== 0 ? mixedClients + " selected" : "Enter " + item.columnName}
                                        name={item.columnName}
                                        value={selectedClient  && selectedClient[item.columnIndex]?.rowValue !== "null"  ? selectedClient[item.columnIndex]?.rowValue : ""}
                                        onChange={(e) => {
                                        handleInput(e, item.columnIndex, item.columnType);
                                        }}
                                    />

                                    }

                                    {item.columnType === "Phone" &&
                                    <PhoneInput
                                        className={`new-member-phone-input ${item.privilege === "Hidden" && "sa-hidden-input"}`}
                                        country={'my'}
                                        preferredCountries={['my']}
                                        value={selectedClient  && selectedClient[item.columnIndex]?.rowValue !== "null"  ? selectedClient[item.columnIndex]?.rowValue : ""}
                                        onChange={(e)=>{handleInput(e, item.columnIndex, item.columnType);}}
                                    />
                                    }

                                    {item.columnType === "Link" &&
                                    <input
                                        className={`new-member-input ${item.privilege === "Hidden" && "sa-hidden-input"}`}
                                        placeholder={selectedClient[item.columnIndex]?.rowValue === "null" ? selectedClient[item.columnIndex]?.rowValue : mixedClients !== 0 ? mixedClients + " selected" : "Enter Your Link Here"}
                                        name={item.columnName}
                                        value={selectedClient  && selectedClient[item.columnIndex]?.rowValue !== "null"  ? selectedClient[item.columnIndex]?.rowValue : ""}
                                        onChange={(e) => {
                                        handleInput(e, item.columnIndex, item.columnType);
                                        }}
                                    />
                                    }

                                    {item.columnType === "Google Map" &&
                                    <input
                                        className={`new-member-input ${item.privilege === "Hidden" && "sa-hidden-input"}`}
                                        placeholder={selectedClient[item.columnIndex]?.rowValue === "null" ? selectedClient[item.columnIndex]?.rowValue : mixedClients !== 0 ? mixedClients + " selected" : "Enter Your Location Link"}
                                        name={item.columnName}
                                        value={selectedClient  && selectedClient[item.columnIndex]?.rowValue !== "null"  ? selectedClient[item.columnIndex]?.rowValue : ""}
                                        onChange={(e) => {
                                        handleInput(e, item.columnIndex, item.columnType);
                                        }}
                                    />
                                    }


                                    {item.columnType === "Rich Text Editor" &&
                                        <ReactQuill
                                            className={`member-rte ${item.privilege === "Hidden" && "sa-hidden-input"}`}
                                            theme="snow"
                                            placeholder={selectedClient[item.columnIndex]?.rowValue === "null" ? selectedClient[item.columnIndex]?.rowValue : mixedClients !== 0 ? mixedClients !== undefined ? mixedClients + " selected" : "Enter your Description" : "Enter Your Description"}
                                            value={selectedClient  && selectedClient[item.columnIndex]?.rowValue !== "null"  ? selectedClient[item.columnIndex]?.rowValue : ""}
                                            onChange={(e) => {
                                            e !== undefined && handleRteInput(e, item.columnIndex);
                                            
                                        }} />
                                    }

                                    {item.columnType === "Dropdown" &&
                                    <div className="dropdown-input-container">
                                        {item.isMultiSelect ? 
                                        <MultiSelectDropdown list={item.option} handleInput={handleInput} columnIndex={item.columnIndex} columnType={item.columnType} selectedClient={selectedClient[item.columnIndex]} otherOption={item.otherOption}/>
                                        :
                                        <Fragment>
                                        {selectedClient[item.columnIndex]?.optionChoices === undefined ?
                                            <select value={selectedClient  && selectedClient[item.columnIndex]?.rowValue !== "null"  ? selectedClient[item.columnIndex]?.rowValue : ""} className={`form-select filter-card-input font-xxs ${item.privilege === "Hidden" && "sa-hidden-input"}`} onChange={(e) => {
                                            handleInput(e, item.columnIndex, item.columnType);
                                            }}
                                            style={(item.otherOption !== undefined && item.otherOption) ? { height:"37px", width:"75%" } : { height:"37px", width:"100%" }}>
                                            <option selected disabled value=""></option>
                                            {item.option?.map((opt, optIndex) => {
                                                return (
                                                    <option value={opt}>{opt}</option>
                                                );
                                            })}
                                            </select>
                                            
                                            :
                                            <>
                                            {(selectedClient[item.columnIndex]?.optionChoices === "Default") ?
                                                <select value={selectedClient  && selectedClient[item.columnIndex]?.rowValue !== "null"  ? selectedClient[item.columnIndex]?.rowValue : ""} className={`form-select filter-card-input font-xxs ${item.privilege === "Hidden" && "sa-hidden-input"}`} onChange={(e) => {
                                                handleInput(e, item.columnIndex, item.columnType);
                                                }}
                                                style={(item.otherOption !== undefined && item.otherOption) ? { height:"37px", width:"75%" } : { height:"37px", width:"100%" }}>
                                                <option selected disabled value=""></option>
                                                {item.option?.map((opt, optIndex) => {
                                                    return (
                                                        <option value={opt}>{opt}</option>
                                                    );
                                                })}
                                                </select>
                                            
                                            :
                                                <input
                                                className={`new-member-input ${item.privilege === "Hidden" && "sa-hidden-input"}`}
                                                placeholder={selectedClient[item.columnIndex]?.rowValue === "null" ? selectedClient[item.columnIndex]?.rowValue : mixedClients !== 0 ? mixedClients + " selected" : "Enter " + item.columnName}
                                                name={item.columnName}
                                                value={selectedClient  && selectedClient[item.columnIndex]?.rowValue !== "null"  ? selectedClient[item.columnIndex]?.rowValue : ""}
                                                onChange={(e) => {
                                                    handleInput(e, item.columnIndex, item.columnType);
                                                }}
                                                />
                                            }
                                            </>
                                            
                                        }
                                        </Fragment>
                                        
                                        }
                                        
                                        
                                        {(item.otherOption !== undefined && item.otherOption && !item.isMultiSelect) &&
                                        <select value={selectedClient ? selectedClient[item.columnIndex]?.optionChoices : ""} className={`form-select filter-card-input font-xxs ${item.privilege === "Hidden" && "sa-hidden-input"}`} onChange={(e) => {
                                            handleDropdownChoices(e, item.columnIndex, item.columnType);
                                        }}
                                        style={{ height:"37px", width:"25%" }}>
                                            <option value={"Default"} selected={selectedClient[item.columnIndex]?.optionChoices === "Default" ? true : false}>Default</option>
                                            <option value={"Others"} selected={selectedClient[item.columnIndex]?.optionChoices === "Others" ? true : false}>Others</option>
                                        </select>
                                        }
                                    </div>
                                    }

                                    {item.columnType === "Multiple Choices" &&
                                    <div className={`new-member-mcq-container ${item.privilege === "Hidden" && "sa-hidden-mcq-container"}`}>
                                    {item.option?.map((opt, optIndex) => {
                                        return (
                                            <div className="new-member-mcq">
                                                <input type={"radio"} value={opt} checked={selectedClient ? selectedClient[item.columnIndex]?.rowValue === opt : ""} onChange={(e) => {handleInput(e, item.columnIndex, item.columnType); }} />
                                                <label className="new-member-mcq-span" title={opt}>{opt}</label>
                                            </div>
                                        );
                                    })}
                                    </div>
                                    }

                                    {item.columnType === "Checkboxes" &&
                                    <div className={`new-member-mcq-container ${item.privilege === "Hidden" && "sa-hidden-mcq-container"}`}>
                                    {item.option?.map((opt, optIndex) => {
                                        return (
                                            <div className="new-member-mcq">
                                                <label style={{ display: 'flex', alignItems: 'center' }}>
                                                    <input type={"checkbox"} value={opt} checked={selectedClient ? selectedClient[item.columnIndex]?.rowValue.includes(opt) : ""} onChange={(e) => {handleInput(e, item.columnIndex, item.columnType); }} />
                                                    <span className="checkmark" style={{ marginRight: '10px' }}></span>
                                                    <span className="new-member-mcq-span font-xs" title={opt}>{opt}</span>
                                                </label> 
                                            </div>
                                        );
                                    })}
                                    </div>
                                    }

                                    {item.columnType === "Numbers" &&
                                    <input
                                        className={`new-member-input ${item.privilege === "Hidden" && "sa-hidden-input"}`}
                                        type={"number"}
                                        placeholder={selectedClient[item.columnIndex]?.rowValue === "null" ? selectedClient[item.columnIndex]?.rowValue : mixedClients !== 0 ? mixedClients + " selected" : "Enter " + item.columnName}
                                        name={item.columnName}
                                        value={selectedClient  && selectedClient[item.columnIndex]?.rowValue !== "null"  ? selectedClient[item.columnIndex]?.rowValue : ""}
                                        onChange={(e) => {
                                        handleInput(e, item.columnIndex, item.columnType);
                                        }}
                                    />

                                    }
                                </Fragment>
                            }
                            </Fragment>

                            }
                         </Fragment>
                        </div>
                    </Fragment>
                    )
                };
                
                })}
                </div>
            </div>
         </div>
        </Fragment>
    );

}

  return (
    <div
      className="modal fade new_member modal-lg client-modal"
      id="new-client"
      tabIndex="-1"
      ref={manageClientModalRef}
    >
      <div className="client-manage-modal-dialog modal-dialog modal-dialog-centered" style={{}}>
        {<div className="modal-content client-manage-modal-content" style={{}}>
            <div className="modal-header modalHeader client-manage-header">
              <div className="title">
                {!isEdit ? (
                  <AiOutlinePlusCircle size={17} color={"#3eb986 "} />
                ) : (
                  <BiEditAlt size={17} color={"#6d45b9"} />
                )}
                <span className="font-s">
                  {!isEdit ? "New Client" : "Update Client"}
                </span>
              </div>
              <AiOutlineClose
                cursor="pointer"
                size={16}
                onClick={() => {
                  setIsDataExist(false);
                  setIsRequiredEmpty(false);
                  setIsEditRequiredEmpty(false);
                  setIsEditEmailFormat(true);
                  setIsEditPhoneFormat(true);
                  setIsEditLocationFormat(true);
                  setIsEditUrlFormat(true);
                  setIsEmailFormat(true);
                  setIsPhoneFormat(true);
                  setIsLocationFormat(true);
                  setIsUrlFormat(true);
                  setIsEdit(false);
                  setMixedClients(0);
                  setSelectedClient([]);
                  dispatch(setClientContext({
                    checkedClientState : [],
                    checkedAllClientState : false
                  }));
                  // setCheckedClientState({
                  //   type: "CHECKED_CLIENT",
                  //   payload: [],
                  // });
                  // setCheckedAllClientState({
                  //   type: "CHECKED_ALL_CLIENT",
                  //   payload: false,
                  // });
                }}
                data-bs-dismiss="modal"
              />
            </div>

            <span
              className="font-xxs"
              style={{
                fontWeight: "800",
                color: "red",
                paddingLeft: "16px",
              }}
            >
              {isRequiredEmpty ? (
                "Make sure all the required fields are not empty !"
              ) : (
                <>
                  {isDataExist && existingDataMsg}
                  {!isPhoneFormat}
                  {!isEmailFormat &&
                    "Make sure your email format is correct ! e.g. xxxx@domain.com."}
                  {!isLocationFormat && "Invalid Google Map URL !"}
                  {!isUrlFormat && "Invalid URL !"}
                </>
              )}

              {isEditRequiredEmpty ? (
                "Make sure all the required fields are not empty !"
              ) : (
                <>
                  {!isEditPhoneFormat}
                  {!isEditEmailFormat &&
                    "Make sure your email format is correct ! e.g. xxxx@domain.com."}
                  {!isEditLocationFormat && "Invalid Google Map URL !"}
                  {!isEditUrlFormat && "Invalid URL !"}
                </>
              )}
            </span>
            {/* EDIT CLIENT */}
            {manageClientBodyRenderer()}
            <div className="client-manage-footer" >
                <C_DialogButton
                onClick={()=>{
                    hideManageClientModal();
                    // setCheckedClientState({ type: "CHECKED_CLIENT", payload: [] });
                    // setCheckedAllClientState({type:"CHECKED_ALL_CLIENT",payload:false});
                    // setLogsClientID([]);
                    dispatch(setClientContext({
                      checkedClientState : [],
                      checkedAllClientState : false
                    }));
                    setSharedHandlerList([]);
                    setSelectedClient([]);
                }}
                color={isEdit ?  '#6d45b9' : "#3eb986"}
                background={'white'}
                borderColor={isEdit ?  '#6d45b9' : "#3eb986"}
                buttonText={"DISCARD"}
                />
                <C_DialogButton
                    onClick={()=>{
                        isEdit ? editClient() : createNewClient();
                    }}
                    color={"white"}
                    background={isEdit ?  '#6d45b9' : "#3eb986"}
                    borderColor={isEdit ?  '#6d45b9' : "#3eb986"}
                    buttonText={isEdit ?  "UPDATE" : "CREATE"}
                />
            </div>
          </div>
        }
      </div>
    </div>
  );
};
/* eslint-enable */
