//react
import React, { useEffect, useRef, useState } from 'react';

import './ClientGroup.scss';
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai';
import { selectClientContext, setClientContext } from '../../stores/slices/clientSlices';
import { useDispatch } from 'react-redux';
import { Modal } from 'bootstrap';
import { HiMiniUser, HiMiniUserGroup, HiOutlinePlusCircle } from "react-icons/hi2";
import { selectCurrUserContext } from '../../stores/slices/companySlices';
import { selectFirebaseAuth } from '../../stores/slices/firebaseAuthSlices';
import { selectClientGroupRoles } from '../../stores/slices/clientGroupRolesSlices';
import { v4 } from 'uuid';

function ClientGroupDisplay(props) {

    const {
        selectedGroup,
    } = selectClientContext();

    const dispatch = useDispatch();

    function isJSON(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }


    const colorPalette = [
        "#1f7a8c","#f7ac6d","#3e3d3d","#fc8d62","#615c5c","#fcbba1","#0077c0","#5e6d5d","#f6c5c5",
        "#007f5f","#ffedb3","#525252","#d5b5a4","#000000","#d9d9d9","#ff7f00","#0b4f6c","#4d4d4d",
        "#e9a3c9","#008080","#fdd49e","#535353","#b3e2cd","#f1b6da","#007f3f","#fdb462",
        "#8c8c8c","#fed98e","#003c59","#afafaf","#fdae61","#404040","#e78ac3","#008000"
    ];

    function handleInput({value, type}){
        switch(type){
            case "GroupName":
                dispatch(setClientContext({selectedGroup : {...selectedGroup, GroupName: value}}));
                // setSelectedGroup({type: "SELECT_CLIENT_GROUP", payload: {...selectedGroup, GroupName: value}});
                break;
            case "GroupColor":
                dispatch(setClientContext({selectedGroup : {...selectedGroup, GroupColor: value}}));
                // setSelectedGroup({type: "SELECT_CLIENT_GROUP", payload: {...selectedGroup, GroupColor: value}});
                break;
            default:
                break;
        }
    }



    return (
        <div className="clientGroupDetailsMainContainer">
            <div className="detailsInputContainer">
                <span>Group Name *</span>
                <input 
                    placeholder='Give this role a name'
                    value={selectedGroup?.GroupName}
                    onChange={(e) => {
                        handleInput({value: e.target.value, type: "GroupName"})
                    }}
                />
            </div>
            <div className="detailsInputContainer">
                <span>Group Color *</span>
                <span className="describe">Representation for each roles</span>
                <div className="colorSelectionContainer">
                <div className="defaultColor" onClick={() => {
                        handleInput({value: "#919191", type: "GroupColor"})
                }}>
                    {selectedGroup?.GroupColor === "#919191" && <AiOutlineCheck size={35} color="white"/>}
                </div>
                    <div className="colorSelectionSubContainer">
                            {colorPalette.map((item, index) => {
                                return(
                                    <div key={index} className="colorSelection" style={{ backgroundColor: item }} onClick={() => {
                                        handleInput({value: item, type: "GroupColor"})
                                    }}>
                                        {selectedGroup?.GroupColor === item && <AiOutlineCheck size={15} color="white"/>}
                                    </div>
                                )
                            })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ClientGroupDisplay