import { createSlice, current } from "@reduxjs/toolkit"

import { useAppSelector } from "../../hooks/storeHooks";

const initialState = {
    //loading

    setFormTemplateIDLoading: false,

    //error
    setFormTemplateIDError: undefined,

    //data
    formTemplateID:[]
}

const formTemplateIDSlice = createSlice({
    name: "formTemplateID",
    initialState,
    reducers: {
        setFormTemplateID(state){
            state.setFormTemplateIDLoading = true
        },

        setFormTemplateIDSuccess(state,{payload}){
            const currentState = payload
            state.formTemplateID = currentState
            state.setFormTemplateIDLoading = false
        },

        setFormTemplateIDError(state,{payload}){
            const errorState = payload
            state.setFormTemplateIDError = errorState
            state.setFormTemplateIDLoading = false
        }
    }
})

export const {
    setFormTemplateID,
    setFormTemplateIDSuccess,
    setFormTemplateIDError,
} = formTemplateIDSlice.actions

//export selector

// data
export const selectFormTemplateID = () =>
  useAppSelector((state) => state.formTemplateID.formTemplateID);

// loading
export const selectSetFormTemplateIDLoading = () =>
  useAppSelector((state) => state.formTemplateID.setFormTemplateIDLoading);

// error
export const selectSetFormTemplateIDError = () =>
  useAppSelector((state) => state.formTemplateID.setFormTemplateIDError);

const formTemplateIDReducer = formTemplateIDSlice.reducer

export default formTemplateIDReducer
