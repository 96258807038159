const component = {
  MuiInputBase: {
    styleOverrides: {
      root: () => ({
        ".MuiInputBase-inputMultiline": {
          padding: "0px 4px",
          resize: "none",
          height: "100% !important",
          overflow: "auto !important",
        },
      }),
    },
  },
};

export default component;
