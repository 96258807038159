import { call, delay, put, takeLatest } from "redux-saga/effects";
import {
  createTenantUserAuth,
  createTenantUserAuthSuccess,
  createTenantUserAuthError,
  createTenantDatabase,
  createTenantDatabaseSuccess,
  createTenantDatabaseError,
  retrieveTenant,
  retrieveTenantSuccess,
  retrieveTenantError,
  retrieveAllTenantCount,
  retrieveAllTenantCountSuccess,
  retrieveAllTenantCountError,
  retrieveAvailableTenant,
  retrieveAvailableTenantSuccess,
  retrieveAvailableTenantError,
  updateTenant,
  updateTenantSuccess,
  updateTenantError,
  deleteTenant,
  deleteTenantSuccess,
  deleteTenantError,
  updateTenantDetails,
  updateTenantDetailsSuccess,
  updateTenantDetailsError,
  sendSignupConfirmationMail,
  sendSignupConfirmationMailSuccess,
  sendSignupConfirmationMailError,
  sendTenantApprovalMail,
  sendTenantApprovalMailSuccess,
  sendTenantApprovalMailError,
  sendTenantNotificationMail,
  sendTenantNotificationMailSuccess,
  sendTenantNotificationMailError,
  sendTenantQuotaNotificationMail,
  sendTenantQuotaNotificationMailSuccess,
  sendTenantQuotaNotificationMailError,
  sendTenantUpgradeNotificationMail,
  sendTenantUpgradeNotificationMailSuccess,
  sendTenantUpgradeNotificationMailError,
  sendTenantExtendNotificationMail,
  sendTenantExtendNotificationMailSuccess,
  sendTenantExtendNotificationMailError,
  sendVerificationMail,
  sendVerificationMailSuccess,
  sendVerificationMailError,
  handleSelectTenant,
  handleSelectTenantSuccess,
} from "../stores/slices/tenantSlices";
import { API } from "../http-common";
import { ApiRoute } from "../enums/ApiRoute";
import { setIsSA, setTenant } from "../stores/slices/persistRolesSlices";
import { retrieveCompany, setCompanyPlanRequestData, setCompanyUsage } from "../stores/slices/companySlices";
import { retrieveAllAgent } from "../stores/slices/companyAgentSlices";

function* createTenantUserAuthSaga({ payload }) {
  try {
    const {data} = yield call (API.post, ApiRoute.tenant.createTenantUserAuth, payload)
    yield put(createTenantUserAuthSuccess(data));
  } catch (error) {
    yield put(createTenantUserAuthError(error));
    console.error(error);
  }
}

function* createTenantDatabaseSaga({ payload }) {
  try {
    const {data} = yield call (API.post, ApiRoute.tenant.createTenantDatabase, payload)
    yield put(createTenantDatabaseSuccess(data));
  } catch (error) {
    yield put(createTenantDatabaseError(error));
    console.error(error);
  }
}

function* retrieveTenantSaga({ payload }) {
  try {
    const {data} = yield call (API.post, ApiRoute.tenant.retrieveAllTenant, payload)
    yield put(retrieveTenantSuccess(data));
  } catch (error) {
    yield put(retrieveTenantError(error));
    console.error(error);
  }
}

function* retrieveAllTenantCountSaga({ payload }) {
  try {
    const {data} = yield call (API.post, ApiRoute.tenant.retrieveAllTenantCount, payload)
    yield put(retrieveAllTenantCountSuccess(data));
  } catch (error) {
    yield put(retrieveAllTenantCountError(error));
    console.error(error);
  }
}

function* retrieveAvailableTenantSaga({ payload }) {
  try {
    const {data} = yield call (API.post, ApiRoute.tenant.retrieveAvailableTenant, payload)
    yield put(retrieveAvailableTenantSuccess(data));
  } catch (error) {
    yield put(retrieveAvailableTenantError(error));
    console.error(error);
  }
}

function* updateTenantSaga({ payload }) {
  try {
    const {data} = yield call (API.post, ApiRoute.tenant.updateTenant, payload)
    yield put(updateTenantSuccess(data));
  } catch (error) {
    yield put(updateTenantError(error));
    console.error(error);
  }
}

function* deleteTenantSaga({ payload }) {
  try {
    const {data} = yield call (API.post, ApiRoute.tenant.deleteTenant, payload)
    yield put(deleteTenantSuccess(data));
  } catch (error) {
    yield put(deleteTenantError(error));
    console.error(error);
  }
}

function* updateTenantDetailsSaga({ payload }) {
  try {
    const {data} = yield call (API.post, ApiRoute.tenant.updateGaTenantDetails, payload)
    yield put(updateTenantDetailsSuccess(data));
  } catch (error) {
    yield put(updateTenantDetailsError(error));
    console.error(error);
  }
}

function* sendSignupConfirmationMailSaga({ payload }) {
  try {
    yield call (API.post, ApiRoute.tenant.sendSignupConfirmationMail, payload)
    // yield put(sendSignupConfirmationMailSuccess(data));
  } catch (error) {
    yield put(sendSignupConfirmationMailError(error));
    console.error(error);
  }
}

function* sendTenantApprovalMailSaga({ payload }) {
  try {
    yield call (API.post, ApiRoute.tenant.sendTenantApprovalMail, payload)
    // yield put(sendTenantApprovalMailSuccess(data));
  } catch (error) {
    yield put(sendTenantApprovalMailError(error));
    console.error(error);
  }
}

function* sendTenantNotificationMailSaga({ payload }) {
  try {
    yield call (API.post, ApiRoute.tenant.sendTenantNotificationMail, payload)
    // yield put(sendTenantNotificationMailSuccess(data));
  } catch (error) {
    yield put(sendTenantNotificationMailError(error));
    console.error(error);
  }
}

function* sendTenantQuotaNotificationMailSaga({ payload }) {
  try {
    yield call (API.post, ApiRoute.tenant.sendTenantQuotaNotificationMail, payload)
    // yield put(sendTenantQuotaNotificationMailSuccess(data));
  } catch (error) {
    yield put(sendTenantQuotaNotificationMailError(error));
    console.error(error);
  }
}

function* sendTenantUpgradeNotificationMailSaga({ payload }) {
  try {
    yield call (API.post, ApiRoute.tenant.sendTenantUpgradeNotificationMail, payload)
    // yield put(sendTenantUpgradeNotificationMailSuccess(data));
  } catch (error) {
    yield put(sendTenantUpgradeNotificationMailError(error));
    console.error(error);
  }
}

function* sendTenantExtendNotificationMailSaga({ payload }) {
  try {
    yield call (API.post, ApiRoute.tenant.sendTenantExtendNotificationMail, payload)
    // yield put(sendTenantExtendNotificationMailSuccess(data));
  } catch (error) {
    yield put(sendTenantExtendNotificationMailError(error));
    console.error(error);
  }
}

function* sendVerificationMailSaga({ payload }) {
  try {
    yield call (API.post, ApiRoute.tenant.sendVerificationMail, payload)
    // yield put(sendVerificationMailSuccess(data));
  } catch (error) {
    yield put(sendVerificationMailError(error));
    console.error(error);
  }
}

function* handleSelectTenantSaga({ payload }) {
  const { TenantID = null, isSA = false, UserID = null, navigation = false } = payload
  try {
    yield put(setTenant(TenantID));
    yield put(setIsSA(isSA));
    yield put(setCompanyUsage({TenantID : TenantID}))
    yield put(setCompanyPlanRequestData({UserID : UserID}));
    yield put(retrieveCompany({TenantID : TenantID, isSA : isSA, UserID : UserID}));
    yield put(retrieveAllAgent({TenantID : TenantID}));
    yield put(handleSelectTenantSuccess());
    if(navigation){
      window.location.replace("/#/Company");
    }
   
  } catch (error) {
    console.error(error);
  }
}

export function* tenantSagaWatcher() {
  yield takeLatest(createTenantUserAuth.type, createTenantUserAuthSaga);
  yield takeLatest(createTenantDatabase.type, createTenantDatabaseSaga);
  yield takeLatest(retrieveTenant.type, retrieveTenantSaga);
  yield takeLatest(retrieveAllTenantCount.type, retrieveAllTenantCountSaga);
  yield takeLatest(retrieveAvailableTenant.type, retrieveAvailableTenantSaga);
  yield takeLatest(updateTenant.type, updateTenantSaga);
  yield takeLatest(deleteTenant.type, deleteTenantSaga);
  yield takeLatest(updateTenantDetails.type, updateTenantDetailsSaga);
  yield takeLatest(
    sendSignupConfirmationMail.type,
    sendSignupConfirmationMailSaga
  );
  yield takeLatest(sendTenantApprovalMail.type, sendTenantApprovalMailSaga);
  yield takeLatest(
    sendTenantNotificationMail.type,
    sendTenantNotificationMailSaga
  );
  yield takeLatest(
    sendTenantQuotaNotificationMail.type,
    sendTenantQuotaNotificationMailSaga
  );
  yield takeLatest(
    sendTenantUpgradeNotificationMail.type,
    sendTenantUpgradeNotificationMailSaga
  );
  yield takeLatest(
    sendTenantExtendNotificationMail.type,
    sendTenantExtendNotificationMailSaga
  );
  yield takeLatest(sendVerificationMail.type, sendVerificationMailSaga);
  yield takeLatest(handleSelectTenant.type, handleSelectTenantSaga);
}
