import React, {
    useState
} from "react";
import './ExpiredInvitation.scss'
import { useNavigate } from "react-router-dom";
import { BsFillArrowRightCircleFill } from "react-icons/bs";

function ExpiredInvitation() {

    const navigate = useNavigate();;

  return (
    <div className='expired-invitation-main-container'>
        <div className='accept-invitation-main-container'>
            <img className="zumax" src={require('../../assets/img/zumax-logo-black.webp')} alt=""/>
            <div className="accept-invitation-container">
                <div className="header">
                    <img src={require('../../assets/img/expiredInvitation.png')} alt=""/>
                    <span>Too bad, your invitation link has been expired due to some reason!</span>
                </div>
                <div className="accept-container">
                    <div className="button" onClick={() => { navigate("/Auth"); }}>BRING ME BACK <BsFillArrowRightCircleFill/></div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ExpiredInvitation;