import React from 'react'
import { selectAnalyticsContext, setAnalyticsContext } from '../../../stores/slices/analyticsSlices';
import { useDispatch } from 'react-redux';

function GraphTypeDropdown(props) {

  const { analytics, dispatchAnalytics } = selectAnalyticsContext();

  function handleChangeGraph(value){
    let data = {...analytics};

    data = {...data, GraphType : value}
    const dispatch = useDispatch();
    
    dispatch(setAnalyticsContext({analytics : data}));
    // dispatchAnalytics({ type : "ANALYTICS", payload: data })
  }
  
  return (
    <select className={props.className} onChange={(e) => { 
      handleChangeGraph(e.target.value)
     }} value={analytics?.GraphType}>
        <option className="" value={'Line'}>
            Line
        </option>
        <option className="" value={'Bar'}>
            Bar
        </option>
        <option className="" value={'Area'}>
            Area
        </option>
        <option className="" value={'Pie'}>
            Pie
        </option>
    </select>
  )
}

export default GraphTypeDropdown