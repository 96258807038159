import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { InputSwitch } from "../Input/InputSwitch";

export const FormInputSwitch = ({
  label,
  type,
  tooltips,
  isDisabled,
  required,
  helperText,
  ...props
}) => {
  return (
    <Controller
      shouldUnregister
      {...props}
      render={({
        field: { onChange, onBlur, value, ref },
        fieldState: { error },
      }) => {
        return (
          <InputSwitch
            value={value || false}
            type={type}
            label={label}
            tooltips={tooltips}
            onChange={onChange}
            onBlur={onBlur}
            error={error?.message}
            helperText={helperText}
            name={props.name}
            disabled={isDisabled}
            required={required}
            fieldRef={ref}
          />
        );
      }}
    />
  );
};

FormInputSwitch.propTypes = {
  label: PropTypes.any,
  type: PropTypes.string,
  tooltips: PropTypes.any,
  isDisabled: PropTypes.any,
  required: PropTypes.any,
  name: PropTypes.string,
  helperText: PropTypes.any,
};
