import React, {
    useState,
    useEffect,
} from "react";
import './AcceptInvitation.scss'
import moment from "moment";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { get, ref, remove } from 'firebase/database'
import { db} from '../../firebaseInit';
import { v4 } from "uuid";
import { BsFillArrowRightCircleFill } from "react-icons/bs";

//slices
import { sendTenantNotificationMail } from "../../stores/slices/tenantSlices";
import { createTenantAgents } from "../../stores/slices/tenantAgentSlices";
import { createCompanyAgent } from "../../stores/slices/companyAgentSlices";

function AcceptInvitation() {

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const [searchParams,setSearchParams] = useSearchParams();

    useEffect(()=>{
        // set(ref(db, 'notification/' + `${HostID}/` + notificationID  )
        setIsLoading(true);
        const TenantID = searchParams.get("TenantID");
        const CompanyID = searchParams.get("CompanyID");
        const mailID = searchParams.get("MailID");
        if(Array.from(searchParams).length > 0)
        {
          console.log("Hi")
          get(ref(db, 'invitationMail/' + `${TenantID}/` + `${CompanyID}/` + mailID )).then((snapshot) => {
            if (snapshot.exists()) {
            //   setEmail(searchParams.get("Email"))
                setTimeout(() => {
                    setIsLoading(false)
                },3000)
            } else {
            //   showModalFunc(); 
              setTimeout(() => {
                setIsLoading(false)
                navigate("/ExpiredInvitation");
              },3000) 
            }
          }).catch((error) => {
            console.error(error);
          });
        }
        
   },[searchParams])

    const acceptInvitation = async () => {
        const createdAt = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
        if(Array.from(searchParams).length > 0)
        {
        //  const displayName = user.displayName;
        //  const email = user.email;
         const userEmail = searchParams.get("Email");
         const hostEmail = searchParams.get("HostEmail");
         const TenantAgentID = v4();
         const TenantID = searchParams.get("TenantID");
         const UserID = searchParams.get("UserID");
         const HostID = searchParams.get("HostID");
         const MailID = searchParams.get("MailID");
         const CompanyID = searchParams.get("CompanyID");
         const CompanyName = searchParams.get("CompanyName");
         const RoleID = searchParams.get("RoleID");

          dispatch(createTenantAgents({TenantAgentID:TenantAgentID,TenantID:TenantID,UserID:UserID,CreatedAt:createdAt}))
        
          await remove(ref(db, 'invitationMail/' + `${TenantID}/` + `${CompanyID}/` + MailID)).then(() => {
              const companyAgentData = {
                  CompanyAgentID: v4(),
                  CompanyID: CompanyID,
                  UserID: UserID,
                  RoleID: RoleID,
                  CreatedAt: createdAt
              }
              dispatch(createCompanyAgent({TenantID:TenantID,data:companyAgentData}))
              //send successful email to host
              dispatch(sendTenantNotificationMail({hostEmail:hostEmail,userEmail:userEmail,companyName:CompanyName,createdAt:createdAt}));
              navigate("/Auth");
              
          })
          
        }
    }

  return (
    <div className='accept-invitation-main-container'>
        <img className="zumax" src={require('../../assets/img/zumax-logo-black.webp')} alt=""/>
        <div className="accept-invitation-container">
            <div className="header">
                <img src={require('../../assets/img/invitation.png')} alt=""/>
                <span>Welcome to Zumax CRM, Click the button below to start your journey with {searchParams.get("CompanyName")}</span>
            </div>
            <div className="accept-container">
                {!isLoading && <div className="button" onClick={() => { acceptInvitation(); }}>ACCEPT INVITATION <BsFillArrowRightCircleFill/></div>}
                <div className={`py-1 inviteMemberloadingContainer ${!isLoading && 'hideLoadingMemberContainer'}`}>
                    <div className="loading-spinner">
                    </div>
                </div>
            </div>
            
        </div>
    </div>
  )
}

export default AcceptInvitation;