import palette from "./palette";

const borders = {
  default: {
    border: 1,
    borderColor: palette.border.default,
  },
  medium: "2px solid #F5F6FA",
  row: `1px solid ${palette.border.light}`,
};

export default borders;
