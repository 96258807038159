import React from "react";
import {
  Dialog,
  Stack,
  Button,
  Typography,
  CircularProgress,
} from "@mui/material";
import {
  CheckCircleRounded,
  ComputerRounded,
  ErrorRounded,
} from "@mui/icons-material";
import { useDispatch } from "react-redux";
// import {
//   selectDialogBox,
//   toggleDialogBox,
//   createFile,
//   selectCreateFileLoading,
// } from "@store/slices";
import { validatorImageSize } from "../../util/validators/validatorImageSize";
import { selectDialogBox } from "../../stores/slices/toggleSlice";
import { createFile, selectCreateFileLoading } from "../../stores/slices/fileManagerSlice";

export const UploadConfirmationDialog = () => {
  const dispatch = useDispatch();
  const dialogBox = selectDialogBox();
  const createFileloading = selectCreateFileLoading();

  const handleCloseDialogBox = (type) =>
    dispatch(toggleDialogBox({ [type]: { data: null, isOpen: false } }));

  const handleUploadConfirmed = () => {
    const data = [];
    for (const file of dialogBox.upload.data) {
      if (validatorImageSize(file, 3)) {
        data.push(file);
      }
    }

    dispatch(createFile(data));
  };

  function bytesToMB(bytes) {
    if (bytes <= 10240) {
      return (bytes / 1024).toFixed(2) + " KB"; // Convert to KB
    } else {
      return (bytes / (1024 * 1024)).toFixed(2) + " MB"; // Convert to MB
    }
  }

  return (
    <Dialog
      open={dialogBox.upload.isOpen}
      onClose={() => {
        handleCloseDialogBox("upload");
      }}
      scroll="paper"
      maxWidth="xs"
      fullWidth
      PaperProps={{
        sx: {
          padding: 2,
          gap: 2,
          height: 0.4,
        },
      }}
    >
      <Stack direction="row" spacing={1.5} alignItems="center">
        <ComputerRounded
          sx={({ icons }) => ({
            ...icons.large,
          })}
        />
        <Typography
          variant="displayH3"
          // sx={({ palette }) => ({
          //   color: palette.text.main,
          // })}
        >
          {"Upload File"}
        </Typography>
      </Stack>
      <Stack spacing={1} flexGrow={1} overflow="scroll">
        {Array.from(dialogBox.upload.data || []).map((item, index) => {
          const isValidImageSize = validatorImageSize(item, 3);

          return (
            <Stack
              key={index}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              width={1}
            >
              <Stack
                direction="row"
                alignItems="center"
                spacing={1}
                width={0.8}
              >
                {isValidImageSize !== true ? (
                  <ErrorRounded
                    sx={({ icons, palette }) => ({
                      width: "18px",
                      height: "18px",
                      ...icons.standards,
                      color: palette.chip.error,
                    })}
                  />
                ) : (
                  <CheckCircleRounded
                    sx={({ icons, palette }) => ({
                      width: "18px",
                      height: "18px",
                      ...icons.standards,
                      color: palette.chip.success,
                    })}
                  />
                )}
                <Typography
                  variant="bodyText"
                  sx={({ palette, typography }) => ({
                    ...(isValidImageSize !== true
                      ? { color: palette.chip.error }
                      : {}),
                    ...typography.textOverflow,
                  })}
                >
                  {item.name}
                </Typography>
              </Stack>
              <Stack>
                <Typography
                  variant="bodyText"
                  sx={({ palette }) => ({
                    ...(isValidImageSize !== true
                      ? { color: palette.chip.error }
                      : {}),
                  })}
                >
                  {bytesToMB(item.size)}
                </Typography>
              </Stack>
            </Stack>
          );
        })}
      </Stack>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="remarkSemiBold">
          {"File size limit to 3MB"}
        </Typography>
        <Stack direction="row" spacing={1.5}>
          <Button
            className="btn-secondary"
            variant="contained"
            onClick={() => {
              handleCloseDialogBox("upload");
            }}
            disabled={createFileloading}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            className="btn-primary"
            onClick={handleUploadConfirmed}
            disabled={createFileloading}
          >
            {createFileloading ? (
              <CircularProgress
                size="20px"
                sx={({ palette }) => ({ color: palette.primary.white })}
                data-testid="create-file-progressbar"
              />
            ) : (
              "Confirm"
            )}
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};
