import React, {
    useState,
    useEffect,
    Fragment,
  } from "react";
import "./Kpi.scss";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import KpiSettings from "./KpiSettings";
import C_Loading from "../../components/C_Loading";
import KpiDashboard from "./KpiDashboard";
import C_PopoutNoti from "../../components/C_PopoutNoti";


//slices
import { retrieveKpi, selectKpiContext, setKpiContext } from "../../stores/slices/kpiSlices";
import { retrieveFormTemplate } from "../../stores/slices/formTemplateSlices";
import { retrieveFormSubmission } from "../../stores/slices/formSubmissionSlices";
import { retrieveCurrUsage } from "../../stores/slices/usageSlices";
import { retrieveCurrRoles } from "../../stores/slices/roleSlices";
import { selectCompanyID } from "../../stores/slices/companyIDSlices";
import { selectTenant } from "../../stores/slices/tenantSlices";
import { selectCurrTenant } from "../../stores/slices/persistRolesSlices";
import { retrieveCompanyAgent } from "../../stores/slices/companyAgentSlices";
import { selectUser } from "../../stores/slices/userAuthSlices";
import { selectCurrUserContext, setCompanyUsage } from "../../stores/slices/companySlices";
  
  function Kpi(props) {

    const isSuperAdmin = props.isSuperAdmin;
    const Kpi_Manage = props.Kpi_Manage;
    const Kpi_Delete = props.Kpi_Delete;

    const {
      triggerAction,
      showKpiSetting,
    } = selectKpiContext();

    useEffect(() => {
      dispatch(setKpiContext({
        showKpiSetting : false,
        viewKpi : false,
        newKpi : false
      }))
    },[]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const companyID = selectCompanyID();
    const tenant = selectCurrTenant();

    const user = selectUser();
    const [searchText, setSearchText] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const { currUserSA, currUserInvitation } = selectCurrUserContext();

    useEffect(() => {
      if(companyID && tenant){
          dispatch(retrieveCurrUsage({TenantID:tenant,CompanyID:companyID}));
          dispatch(retrieveCompanyAgent({TenantID:tenant,CompanyID:companyID}));
          dispatch(retrieveKpi({TenantID:tenant,CompanyID:companyID}));
          dispatch(retrieveFormTemplate({TenantID:tenant,CompanyID:companyID}));
          dispatch(retrieveFormSubmission({TenantID:tenant,CurrPage:"",UserID: ""}));
      }
    },[companyID, tenant]);

    return (
      <Fragment>
      <div className="sidebar-main-container">
          <Sidebar />
        </div>
        <div className="main-container">
        <C_Loading isLoading={isLoading}/>
        <Header title={showKpiSetting ? "KPI Settings" : "KPI"}/>
          <div className="content-box">
            
            <C_PopoutNoti triggerSaved={triggerAction}/>

            {!showKpiSetting &&
                <KpiDashboard isSuperAdmin={isSuperAdmin} Kpi_Manage={Kpi_Manage}/>
            }

            {showKpiSetting &&
                <KpiSettings isSuperAdmin={isSuperAdmin} Kpi_Delete={Kpi_Delete}/>
            }

          </div>
        </div>
      </Fragment>
    );
  }
  
  export default Kpi;
  