import React from "react";
import PropTypes from "prop-types";
import { FormControlLabel, Select, MenuItem } from "@mui/material";

export const InputSelect = ({
  disabled,
  value,
  onChange,
  label,
  menuItems = [],
  required,
  fieldRef,
  sx,
  name,
  // type = "plain",
}) => {
  const handleChange = (event) => {
    onChange(event);
  };

  //   const switchClassName = () => {
  //     switch (type) {
  //       case "show":
  //         return "Show-Switch Switch-sm";
  //       case "active":
  //         return "Activation-Switch Switch-lg";
  //       case "combine":
  //         return "Combine-Switch Switch-md";
  //       case "active-only":
  //         return "Active-Only-Switch Switch-xl";
  //       default:
  //         return "";
  //     }
  //   };

  // const renderLabel = (
  //   <Typography
  //     variant="bodyText"
  //     sx={{ display: "flex", alignItems: "center", gap: 1 }}
  //   >
  //     {label}
  //     {tooltipsAdornmentComponent}
  //   </Typography>
  // );

  return (
    <FormControlLabel
      control={
        <Select
          //className={switchClassName()}
          sx={{
            fontSize: "12px",
            width: name === "graph_filter_select" ? "78px" : "5.4375em",
              height: name === "graph_filter_select" ? "27px" : "4.4375em",
            display: "flex",
            justifyContent: "center",
            backgroundColor:
              name === "graph_filter_select" ? "#FFFFFF" : "#E6E6E6",
            color: name === "graph_filter_select" ? "#019A65" : "black",
            // "& .MuiInputBase-root": {
            //   padding: 0,
            // },
            "& .MuiSelect-select": {
              padding: "5px",
            },
            "& .MuiInputBase-root": {
              "& .MuiSelect-select.Mui-Focus": {
                padding: "5px !important",
              },
            },
            "&.Mui-focused": {
              padding: "0px", // Reset paddingTop to its initial value
            },
            "& .MuiSelect-icon": {
              color: "#019A65",
              top: "8% !important",
            },
          }}
          onChange={handleChange}
          value={value}
          label={label}
          // inputProps={inputTextProps}
          autoWidth
          inputProps={
            name !== "graph_filter_select" ? { IconComponent: () => null } : {}
          }
        >
          {menuItems.length > 0
            ? menuItems.map(({ value: menuValue, label: menuLabel }) => {
                return (
                  <MenuItem key={menuValue} value={menuValue}>
                    {menuLabel}
                  </MenuItem>
                );
              })
            : null}
        </Select>
      }
      //   label={renderLabel}
      required={required}
      inputRef={fieldRef}
      disabled={disabled}
    />
  );
};

InputSelect.propTypes = {
  name: PropTypes.any,
  disabled: PropTypes.any,
  value: PropTypes.any,
  onChange: PropTypes.any,
  tooltips: PropTypes.any,
  label: PropTypes.any,
  required: PropTypes.any,
  fieldRef: PropTypes.any,
  error: PropTypes.any,
  helperText: PropTypes.any,
  type: PropTypes.string,
  menuItems: PropTypes.any,
};
