import { Route } from "react-router-dom"
import { crmRoutes } from "../enums/Route"
import ProtectedRoute from "./ProtectedRoute"
import { Fragment } from "react"
import FormSearch from "../containers/form_template/FormSearch"

export const FormSearchRoute = () => (
    <Fragment>
        <Route 
            path={crmRoutes.form.formSearch}
            element={
                <ProtectedRoute>
                    <FormSearch />
                </ProtectedRoute>
            }
        />
    </Fragment> 
)

