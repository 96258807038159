import React, { useRef } from "react";
import { Box, Button, TextField } from "@mui/material";
import { ComputerRounded } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { toggleDialogBox } from "../../stores/slices/toggleSlice";

export const InputImageUpload = () => {
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);

  const handleToggleDialogBox = (type, data) =>
    dispatch(toggleDialogBox({ [type]: { data: data, isOpen: true } }));

  const handleFileInputClick = (event) => {
    event.preventDefault();
    fileInputRef.current.value = null; // clean up input value to prevent unable to selecting same file
    return fileInputRef.current.click();
  };

  const inputTextProps = {
    multiple: true,
    accept: "image/*",
  };

  const handleChange = (event) => {
    const selectedFile = event.target.files;
    if (selectedFile.length > 0) {
      handleToggleDialogBox("upload", selectedFile);
    }
  };

  return (
    <Box>
      <TextField
        type="file"
        hidden
        inputRef={fileInputRef}
        onChange={handleChange}
        inputProps={inputTextProps}
      />
      <Button
        variant="contained"
        className="btn-primary"
        onClick={handleFileInputClick}
        endIcon={
          <ComputerRounded sx={({ icons }) => ({ ...icons.standard })} />
        }
      >
        {"Upload"}
      </Button>
    </Box>
  );
};
