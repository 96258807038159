export const getFormDirtyValues = (dirtyFields, values) => {
    const dirtyResults = Object.keys(values).reduce((result, key) => {
      const fieldValue = dirtyFields[`${key}`];
      //* <daniel task
      // shorten condition true
      if (fieldValue === true) {
        return {
          ...result,
          [key]: values[`${key}`],
        };
      }
      //* >
  
      if (Array.isArray(fieldValue)) {
        const currentFieldValue = values[`${key}`];
        const dirtyFieldValue = currentFieldValue.reduce((acc, item, index) => {
          const dirtyItem = Object.keys(item).reduce((obj, itemKey) => {
            const currentDirtyField = dirtyFields[key][index];
            if (currentDirtyField && currentDirtyField[itemKey]) {
              const currentItemValue = item[itemKey];
              //! if currentItemValue is nested array
              if (Array.isArray(currentItemValue)) {
                const nestedArrayValue = currentItemValue.reduce(
                  (nestedAcc, nestedItem, nestedIndex) => {
                    const nestedDirtyField = Object.keys(item).reduce(
                      (nestedObj, nestedItemKey) => {
                        const currentNestedDirtyField =
                          currentDirtyField[itemKey][nestedIndex];
                        if (
                          currentNestedDirtyField &&
                          currentNestedDirtyField[nestedItemKey]
                        ) {
                          const currentNestedItemValue =
                            nestedItem[nestedItemKey];
                          return {
                            ...nestedObj,
                            [nestedItemKey]: currentNestedItemValue,
                          };
                        }
                        return nestedObj;
                      },
                      {}
                    );
                    return [...nestedAcc, nestedDirtyField];
                  },
                  []
                );
  
                if (nestedArrayValue.length > 0) {
                  return {
                    ...obj,
                    [itemKey]: nestedArrayValue,
                  };
                }
              }
              return {
                ...obj,
                [itemKey]: currentItemValue,
              };
            }
            return obj;
          }, {});
  
          return [...acc, dirtyItem];
        }, []);
        if (dirtyFieldValue.length > 0) {
          return {
            ...result,
            [key]: dirtyFieldValue,
          };
        }
        return result;
      }
  
      if (typeof fieldValue === "object") {
        const fieldObjectValues = Object.keys(fieldValue);
        if (fieldObjectValues.length > 0) {
          const originalObject = values[`${key}`];
          const filteredObject = Object.keys(originalObject).reduce(
            (obj, objectKey) => {
              if (fieldObjectValues.includes(objectKey)) {
                return {
                  ...obj,
                  [objectKey]: originalObject[objectKey],
                };
              }
              return obj;
            },
            {}
          );
  
          return {
            ...result,
            [key]: filteredObject,
          };
        }
        // if (Object.values(fieldValue).includes(true)) {
        //   return {
        //     ...result,
        //     [key]: values[`${key}`],
        //   };
        // }
      }
  
      return result;
    }, {});
  
    return dirtyResults;
  };
  