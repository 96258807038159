import analyticsReducer from "./analyticsSlices";

import tenantReducer from "./tenantSlices";

import appointmentReducer from "./appointmentsSlices";
import clientAgentReducer from "./clientAgentSlices";
import clientGroupLeaderReducer from "./clientGroupLeaderSlices";

import usageReducer from "./usageSlices";
import tenantAgentReducer from "./tenantAgentSlices";
import systemReducer from "./systemSlices";
import rolesReducer from "./roleSlices";
import persistRolesReducer from "./persistRolesSlices";
import logReducer from "./logSlices";

import clientGroupsReducer from "./clientGroupSlices";
import clientSelectorReducer from "./clientSelectorSlices";
import clientHandlerReducer from "./clientGroupHandlerSlices";
import authReducer from "./authSlices";
import clientReducer from "./clientSlices";
import companyAgentReducer from "./companyAgentSlices";
import companyReducer from "./companySlices";
import companyIDReducer from "./companyIDSlices";
import firebaseAuthReducer from "./firebaseAuthSlices";
import kpiReducer from "./kpiSlices";
import formTemplateReducer from "./formTemplateSlices";
import formTemplateIDReducer from "./formTemplateIDSlices";
import formSubmissionIDReducer from "./formSubmissionIDSlices";
import formSubmissionReducer from "./formSubmissionSlices";
import formIDReducer from "./formIDSlices"
import gaPortalReducer from "./gaPortalSlices";
import userReducer from "./userAuthSlices";
import clientGroupRolesReducer from "./clientGroupRolesSlices";
import clientGroupRoleAgentsReducer from "./clientGroupRoleAgentsSlices";
import transactionReducer from "./transactionSlices";
import snackReducer from "./snackSlice";
import toggleReducer from "./toggleSlice";
import fileManagerReducer from "./fileManagerSlice";

export const rootReducers = {
    analytics : analyticsReducer,
    appointments : appointmentReducer,
    auth : authReducer,
    clientAgents : clientAgentReducer,
    clientHandlers : clientHandlerReducer,
    clientGroupLeaders : clientGroupLeaderReducer,
    clientGroups : clientGroupsReducer,
    clientSelectors : clientSelectorReducer,
    clients : clientReducer,
    companyAgents : companyAgentReducer,
    companies : companyReducer,
    companyID : companyIDReducer,
    firebaseAuth : firebaseAuthReducer,
    formID : formIDReducer,
    formSubmissionID : formSubmissionIDReducer,
    formSubmissions: formSubmissionReducer,
    formTemplateID : formTemplateIDReducer,
    formTemplates : formTemplateReducer,
    kpi : kpiReducer,
    logs :logReducer,
    persistRoles: persistRolesReducer,
    roles : rolesReducer,
    system : systemReducer,
    tenantAgents : tenantAgentReducer,
    tenants : tenantReducer,
    usage : usageReducer,
    gaPortal : gaPortalReducer,
    user : userReducer,
    clientGroupRoles : clientGroupRolesReducer,
    clientGroupRoleAgents : clientGroupRoleAgentsReducer,
    transaction: transactionReducer,
    snack: snackReducer,
    toggle: toggleReducer,
    fileManager: fileManagerReducer,
};