import React, { useState, useEffect } from 'react'
import { AiFillCheckCircle, AiFillCloseCircle, AiOutlineCheckCircle, AiOutlineClose, AiOutlineDoubleLeft, AiOutlinePlusCircle, AiOutlineSearch } from 'react-icons/ai'
import { Modal } from "bootstrap";
import { BiReset } from 'react-icons/bi';
import { TbFileDescription } from 'react-icons/tb';
import C_Button from '../../components/C_Button';
import { FaUserFriends, FaUserTie } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { v4 } from 'uuid';
import C_Loading from '../../components/C_Loading';
import moment from 'moment';
import SendNotification from '../../components/Notifications/SendNotification';
import Colors from '../../constants/Colors';
import { CgUserList } from 'react-icons/cg';
import { MdNavigateBefore, MdNavigateNext, MdRemoveCircleOutline } from 'react-icons/md';
import C_Search from '../../components/C_Search';
import ReactPaginate from 'react-paginate';
import C_DialogButton from '../../components/C_DialogButton';

//slices
import { createAppointment, retrieveAppointment, selectAppointmentContext, setAppointmentContext } from '../../stores/slices/appointmentsSlices';
import { retrieveCurrUsage } from '../../stores/slices/usageSlices';
import { retrieveClientSelected, retrieveClientSelector, retrieveClientSelectorCount, selectClientSelector, selectClientSelectorCount } from '../../stores/slices/clientSelectorSlices';
import { createLogs } from '../../stores/slices/logSlices';
import { selectTenant } from '../../stores/slices/tenantSlices';
import { selectCurrSa, selectCurrTenant } from '../../stores/slices/persistRolesSlices';
import { selectCompanyID } from '../../stores/slices/companyIDSlices';
import { selectCurrUserContext } from '../../stores/slices/companySlices';
import { getProcessedClientData, selectAvailableClientsGroup, selectClient, selectClientContext } from '../../stores/slices/clientSlices';
import { selectUser } from '../../stores/slices/userAuthSlices';

function CreateAppointment(props) {

    const user = selectUser();

    const tenant = selectCurrTenant();
    const currSa = selectCurrSa();
    const companyID = selectCompanyID();
    
    const { filteredClientData } = selectClientContext();

    const { currUsers, currUserSA, currPlan, currUsage, currTotalUsage } = selectCurrUserContext();
    const [isLoading, setIsLoading] = useState(false);

    const [loadData, setLoadData] = useState(true);

    const dispatch = useDispatch();

    const modalRef = props.modalRef;

    const {
        currAppointment,
        selectedGroupValue,
        clientSelectedList,
        // dispatchCurrAppointment,
        // dispatchTriggerAction,
        // dispatchCurrGroup,
    } = selectAppointmentContext();

    const client_selector = selectClientSelector();
    const client_selector_count = selectClientSelectorCount();

    const availableClientsGroup = selectAvailableClientsGroup();

    const client = selectClient();

    useEffect(()=>{
        dispatch(getProcessedClientData({client, availableClientsGroup, currUserSA, selectedGroupValue, isSA : currSa, user}))
    }, [client, availableClientsGroup])
    
    const [showAttendees, setShowAttendees] = useState(null);
    const [memberCheckState, setMemberCheckedState] = useState([]);
    const [clientCheckState, setClientCheckedState] = useState([]);
    const [error, setError] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchText,setSearchText] = useState("");
    const [currClientList,setCurrClientList] = useState([]);
    const [manageClientAttendees,setManageClientAttendees] = useState(false);

    function retrieveClientFunction(retrieveFunc) {
        setLoadData(true);
        return Promise.all(
          retrieveFunc
        );
    }

    async function refreshUsage(){
       dispatch(retrieveCurrUsage({TenantID:tenant,CompanyID:companyID}));
    };

    useEffect(() => {
        if(availableClientsGroup && availableClientsGroup?.length > 0 && tenant && companyID !== null){

          dispatch(setAppointmentContext({availableClientsGroup: availableClientsGroup[0] }));
          retrieveClientFunction([
            dispatch(retrieveClientSelector({ TenantID:tenant,CompanyID: companyID, CurrPage: 0, SearchText: "", CurrGroup: availableClientsGroup[0]?.GroupID, isSA: currSa })),
            dispatch(retrieveClientSelectorCount({ TenantID:tenant,CompanyID: companyID, SearchText: "", CurrGroup: availableClientsGroup[0]?.GroupID, isSA: currSa }))
          ])
            .then(() => {
              setTimeout(() => {
                setLoadData(false);
                setSearchText("");
              },1000)
            })
            .catch((error) => console.log(error));
        }
      },[availableClientsGroup, tenant, companyID])

      useEffect(()=>{
        if(!selectedGroupValue){
            dispatch(setAppointmentContext({
                selectedGroupValue : availableClientsGroup[0]
            }))
        }
      }, [])

    useEffect(() => {
        
        const newMergedData = currUsers.filter(item => item.uid !== user?.uid);

        setMemberCheckedState(new Array(newMergedData.length).fill(false))
        setClientCheckedState(new Array(filteredClientData.length).fill(false))

    },[currUsers, filteredClientData])


    const hideModal = () => {
        const modalEle = modalRef.current;
        const bsModal = Modal.getInstance(modalEle);
        bsModal.hide();
        dispatch(setAppointmentContext({currAppointment : {}}));
        // dispatchCurrAppointment({ type:"CHANGE_CURR_APPOINTMENT", payload: {} });
    };

    function notify(){
        dispatch(setAppointmentContext({triggerAction : 'CREATED'}));
        // dispatchTriggerAction({type: "TRIGGER", payload: 'CREATED'})
        setTimeout(() => {
            dispatch(setAppointmentContext({triggerAction : ''}));
            // dispatchTriggerAction({type: "TRIGGER", payload: ''})
        }, 1500)
      };

    function handleChangeInput({value, type}){
        switch(type){
            case "title":
                dispatch(setAppointmentContext({currAppointment : {...currAppointment, title: value}}));
                // dispatchCurrAppointment({ type:"CHANGE_CURR_APPOINTMENT", payload: {...currAppointment, title: value} });
                break;
            case "description":
                dispatch(setAppointmentContext({currAppointment : {...currAppointment, description: value}}));
                // dispatchCurrAppointment({ type:"CHANGE_CURR_APPOINTMENT", payload: {...currAppointment, description: value} });
                break;
            case "start":
                dispatch(setAppointmentContext({currAppointment : {...currAppointment, start: value}}));
                // dispatchCurrAppointment({ type:"CHANGE_CURR_APPOINTMENT", payload: {...currAppointment, start: value} });
                break;
            case "end":
                dispatch(setAppointmentContext({currAppointment : {...currAppointment, end: value}}));
                // dispatchCurrAppointment({ type:"CHANGE_CURR_APPOINTMENT", payload: {...currAppointment, end: value} });
                break;
            case "venue":
                dispatch(setAppointmentContext({currAppointment : {...currAppointment, AppointmentVenue: value}}));
                // dispatchCurrAppointment({ type:"CHANGE_CURR_APPOINTMENT", payload: {...currAppointment, AppointmentVenue: value} });
                break;
            case "color":
                dispatch(setAppointmentContext({currAppointment : {...currAppointment, AppointmentColor: value}}));
                // dispatchCurrAppointment({ type:"CHANGE_CURR_APPOINTMENT", payload: {...currAppointment, AppointmentColor: value} });
                break;
            case "description":
                dispatch(setAppointmentContext({currAppointment : {...currAppointment, description: value}}));
                // dispatchCurrAppointment({ type:"CHANGE_CURR_APPOINTMENT", payload: {...currAppointment, description: value} });
                break;
            default:
                return;
        }
    };

    const handleOnChange = ({position, type}) => {

        switch(type){
            case "member":
                const updatedMemberCheckedState = memberCheckState.map((item, index) =>
                    index === position ? !item : item
                );

                setMemberCheckedState(updatedMemberCheckedState);

                const newMemberAttendees = [];
                const newMergedData = currUsers.filter(item => item.uid !== user?.uid);

                updatedMemberCheckedState?.map((item, index) => {
                    if(item === true){
                        newMemberAttendees?.push(newMergedData[index])
                    }
                })
                setMemberCheckedState(updatedMemberCheckedState);
                dispatch(setAppointmentContext({currAppointment : {...currAppointment, memberAttendees: newMemberAttendees}}));
                // dispatchCurrAppointment({ type:"CHANGE_CURR_APPOINTMENT", payload: {...currAppointment, memberAttendees: newMemberAttendees} });

                break;
            case "client":
                const updatedClientCheckedState = clientCheckState.map((item, index) =>
                    index === position ? !item : item
                );

                const newClientAttendees = [];

                updatedClientCheckedState?.map((item, index) => {
                    if(item === true){
                        newClientAttendees?.push(filteredClientData[index])
                    }
                })

                setClientCheckedState(updatedClientCheckedState);
                dispatch(setAppointmentContext({currAppointment : {...currAppointment, clientAttendees: newClientAttendees}}));
                // dispatchCurrAppointment({ type:"CHANGE_CURR_APPOINTMENT", payload: {...currAppointment, clientAttendees: newClientAttendees} });
                break;
            default:
                break;
            
        }
    };

    async function createNewAppointment() {

        if(currAppointment.title !== "" 
            && currAppointment.start !== "" 
            && currAppointment.end !== ""
        ){
            setError(false);
            setIsLoading(true);
            
            var body = {...currAppointment, 
                AppointmentID: v4(),
                CompanyID: companyID,
                AppointmentStatus: "CREATED",
                memberAttendees: JSON.stringify(currAppointment?.memberAttendees),
                clientAttendees: JSON.stringify(currClientList)
            }

            dispatch(createAppointment({TenantID:tenant,data:body}))
            const ActivityLogsID = v4();
            const UserID = user?.uid;
            const CompanyID = companyID;
            const GroupID = "";
            const Title = "Appointment has been created";
            const Activity = `created an event (${currAppointment.title})`;
            const Action = "create";
            const Section = "Appointments";
            const Keyword = currAppointment.title;
            const CreatedAt = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");

            const data = {ActivityLogsID,UserID,CompanyID,GroupID,Title,Activity,Action,Section,Keyword,CreatedAt}
            
            dispatch(createLogs({TenantID:tenant,data:data}))
            dispatch(retrieveAppointment({TenantID:tenant,CompanyID:companyID}));

            setTimeout(() => {
                notify();
                hideModal();
                setIsLoading(false);
                //notify user
                for(var x = 0; x < currAppointment?.memberAttendees?.length; x++){
                    const targetUser = currUserSA.find(e => ( e.uid === currAppointment?.memberAttendees[x]?.uid ));
                    if(targetUser.fcm !== ""){
                        const notiInfo = `${user.displayName} has invite you to an appointment`
                        SendNotification({ fcm: targetUser.fcm, title: user.displayName, body: notiInfo, profileImg: user.photoURL, imgContent: null })
                    }
                }
                dispatch(setAppointmentContext({currAppointment : {}}));
                // dispatchCurrAppointment({ type:"CHANGE_CURR_APPOINTMENT", payload: {} });
                const newMergedData = currUsers.filter(item => item.uid !== user?.uid);

                setMemberCheckedState(new Array(newMergedData.length).fill(false))
                setClientCheckedState(new Array(filteredClientData.length).fill(false))

                dispatch(retrieveAppointment({TenantID:tenant,CompanyID:companyID}));

                refreshUsage();

             }, 2000)
        }else{
            setError(true);
        }
        
    };

    const onHandlePageChange = (CurrPage) =>{

        retrieveClientFunction([
            dispatch(retrieveClientSelector({ TenantID:tenant,CompanyID: companyID, CurrPage: CurrPage, SearchText: searchText, CurrGroup: selectedGroupValue?.GroupID, isSA: currSa})),
            dispatch(retrieveClientSelectorCount({ TenantID:tenant,CompanyID: companyID, SearchText: searchText, CurrGroup: selectedGroupValue?.GroupID, isSA: currSa}))
          ])
            .then(() => { 
                setLoadData(false);
                setCurrentPage(CurrPage);
            })
            .catch((error) => console.log(error));
    }
    
    function handleSearchQuery(){

        retrieveClientFunction([
            dispatch(retrieveClientSelector({ TenantID:tenant,CompanyID: companyID, CurrPage: 0, SearchText: searchText, CurrGroup: selectedGroupValue?.GroupID, isSA: currSa})),
            dispatch(retrieveClientSelectorCount({ TenantID:tenant,CompanyID: companyID, SearchText: searchText, CurrGroup: selectedGroupValue?.GroupID, isSA: currSa}))
          ])
            .then(() => { 
                setLoadData(false);
                setCurrentPage(0);
            })
            .catch((error) => console.log(error));

    }

    function handleClearQuery(){

        retrieveClientFunction([
            dispatch(retrieveClientSelector({ TenantID:tenant,CompanyID: companyID, CurrPage: 0, SearchText: "", CurrGroup: selectedGroupValue?.GroupID, isSA: currSa})),
            dispatch(retrieveClientSelectorCount({ TenantID:tenant,CompanyID: companyID, SearchText: "", CurrGroup: selectedGroupValue?.GroupID, isSA: currSa}))
          ])
            .then(() => { 
                setLoadData(false);
                setCurrentPage(0)
                setSearchText("");
            })
            .catch((error) => console.log(error));
    
    }

    const selectedGroup = (groupValue) => {

        const selectedGroupData = availableClientsGroup?.find(item => item.GroupID === groupValue);
        setCurrClientList([]);
        dispatch(setAppointmentContext({selectedGroupValue: selectedGroupData }));
        // dispatchCurrGroup({type: 'SELECT_GROUP', payload: selectedGroupData }),
        retrieveClientFunction([
          dispatch(retrieveClientSelector({ TenantID:tenant,CompanyID: companyID, CurrPage: 0, SearchText: "", CurrGroup: groupValue, isSA: currSa, isAppointment : true, clientSelectedList })),
          dispatch(retrieveClientSelectorCount({ TenantID:tenant,CompanyID: companyID, SearchText: "", CurrGroup: groupValue, isSA: currSa }))
        ])
          .then(() => {
            setTimeout(() => {
              setLoadData(false);
              setSearchText("");
            },1000)
          })
          .catch((error) => console.log(error));
    }

  return (
    <div className="modal fade" tabIndex="-1" ref={modalRef}>
        <C_Loading isLoading={isLoading}/>
        <div className="modal-dialog modal-dialog-centered modal-l">
            <div className="modal-content">

            {!manageClientAttendees
            ?
            <>
            <div className="modal-body">
                <div className='appointmentModalContainer'>

                    <div className={`AttendeesContainer ${showAttendees === "member" && 'activeContainerMember'}`}>
                        <div className='attendeesHeader'>
                            <span>Attendees in Members</span>
                            <AiOutlineDoubleLeft className='icon' size={20} onClick={() => {setShowAttendees(null)}}/>
                        </div>
                        <div className='attendeesList'>
                            {currUsers && currUsers.filter(item => {
                                if(item.uid !== user?.uid){ 
                                    return item
                                }
                                }).map((item, index) => {
                                    return(
                                        <div className='list' key={index}>
                                            <div className='checkbox'>
                                                <label style={{ display: 'flex', alignItems: 'center' }}>
                                                    <input
                                                        className=""
                                                        value={item.uid}
                                                        checked={memberCheckState[index]}
                                                        type="checkbox"
                                                        onChange={() => handleOnChange({type: 'member', position: index})}
                                                    />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>
                                            <div className='name'>
                                                <span>{item.displayName}</span>
                                            </div>
                                        </div>
                                    )
                            })}
                        </div>
                    </div>
                    <div className={`AttendeesContainer ${showAttendees === "client" && 'activeContainerClient'}`}>
                        <div className='attendeesHeader'>
                            <span>Attendees in Clients</span>
                            <AiOutlineDoubleLeft className='icon' size={20} onClick={() => {setShowAttendees(null)}}/>
                        </div>
                        <div className='attendeesList'>
                            {filteredClientData && filteredClientData.map((item, index) => {
                                return(
                                    <div className='list' key={index}>
                                        <div className='checkbox'>
                                            <label style={{ display: 'flex', alignItems: 'center' }}>
                                                <input
                                                    className=""
                                                    value={item?.ClientID}
                                                    checked={clientCheckState[index]}
                                                    type="checkbox"
                                                    onChange={() => handleOnChange({type: 'client', position: index})}
                                                />
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>
                                        <div className='name'>
                                            <span>{item?.ClientName}</span>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>

                    <div className='header'>
                        <div className='subject'>
                            <input
                                type="color"
                                onChange={(e) => {
                                    handleChangeInput({value: e.target.value, type: "color"})
                                }}
                                value={currAppointment?.AppointmentColor}
                            />
                            <span>New Appointment</span>
                            <span className={`error ${error && 'showError'}`}>Please complete the field.</span>
                        </div>
                        <AiFillCloseCircle className="closeIcon" onClick={() => {hideModal(); setCurrClientList([]);}} size={20}/>
                    </div>
                    <div className='fields'>
                        <div className='inputField'>
                            <input 
                                placeholder='Add title'
                                value={currAppointment?.title}
                                onChange={(e) => {
                                    handleChangeInput({value: e.target.value, type: "title"})
                                }}   
                            />
                        </div>
                        <div className='inputFieldDateTime'>
                            <input
                                type="datetime-local"
                                value={currAppointment?.start}
                                min={moment(new Date).format("YYYY-MM-DDThh:mm")}
                                max={currAppointment?.end}
                                onChange={(e) => {
                                    handleChangeInput({value: e.target.value, type: "start"})
                                }}
                            />
                            <span>TO</span>
                            <input
                                type="datetime-local"
                                value={currAppointment?.end}
                                min={currAppointment?.start}
                                onChange={(e) => {
                                    handleChangeInput({value: e.target.value, type: "end"})
                                }}
                            />
                        </div>
                        <div className='inputField'>
                            <input 
                                placeholder='Add venue'
                                value={currAppointment?.AppointmentVenue}
                                onChange={(e) => {
                                    handleChangeInput({value: e.target.value, type: "venue"})
                                }}
                            />
                        </div>
                        <div className='inputField'>
                            <div className='inputTitle'>
                                <TbFileDescription size={16}/>
                                <span>Description</span>
                            </div>
                            <textarea
                                className='textarea-input'
                                placeholder='Description'
                                value={currAppointment?.description}
                                onChange={(e) => {
                                    handleChangeInput({value: e.target.value, type: "description"})
                                }}
                            >{currAppointment?.description}</textarea>
                  
                        </div>
                    </div>

                    <div className='appointment-actions'>
                        <C_Button
                            background={Colors.generalButtonColor}
                            gap={"10px"}
                            buttonText={"Manage Member"}
                            onClick={() => {
                                setShowAttendees("member")
                            }}
                            textColor={"#FFFFFF"}
                            icon={<FaUserTie size={12} />}
                        />
                        <C_Button
                            background={Colors.generalButtonColor}
                            gap={"10px"}
                            buttonText={"Manage Client"}
                            onClick={() => {
                                // setShowAttendees("client")
                                setManageClientAttendees(true);
                                setSearchText("");
                            }}
                            textColor={"#FFFFFF"}
                            icon={<FaUserFriends size={15} />}
                        />
                        <C_Button
                            background={"#3eb986"}
                            gap={"10px"}
                            buttonText={"Create Event"}
                            onClick={() => {
                                createNewAppointment(); setCurrClientList([]);
                            }}
                            textColor={"#FFFFFF"}
                            icon={<AiOutlinePlusCircle size={15} />}
                        />
                    </div>
                </div>
            </div>
            </>
            :
            <>
            {Array.isArray(availableClientsGroup) && availableClientsGroup?.length > 0 ?
            <>
                <div className="modal-header modalHeader">
                    <div className="title">
                        <CgUserList size={16}/>
                        <span>Client List</span>
                        <select defaultValue={""} value={selectedGroupValue?.GroupID} className="form-select font-xs" onChange={(e) => {
                            
                            selectedGroup(e.target.value)
                        }}
                        style={{ 
                            alignItems: 'center', 
                            backgroundColor: Colors.standardBackground, 
                            color: '#000000', 
                            width: '180px', 
                            marginLeft: '10px', 
                            borderRadius: '8px', 
                            height: '36px' }}>
                            <option value="" disabled>Select a group</option>
                            {availableClientsGroup?.map((item, index) => {
                                return (
                                <>
                                    <option key={index} value={item?.GroupID}>{item?.GroupName}</option>
                                </>
                                );
                            })}
                        </select>
                    </div>
                    <AiOutlineClose cursor='pointer' size={16} onClick={() => { setManageClientAttendees(false);}}/>
                </div>
                <div className="modal-body d-flex row" style={{paddingTop: '5px'}}>
                    {currClientList.length > 0 &&
                        <div className="appointmentSelectedListcontainer">
                            {currClientList?.map((item, idx) => {
                                // const matchingClient = clientSelectedList.find(client => client.ClientID === item);
                                const matchingClient = clientSelectedList.find(client => client.ClientID === item);
                                const rowValue = matchingClient ? matchingClient.RowValue : null;
                                return(
                                    <div key={idx} className="selectedClients">
                                        <span>{rowValue}</span>
                                        <MdRemoveCircleOutline size={14} color="red" cursor="pointer" onClick={() => { 
                                            const newList = currClientList.filter(client => client !== item);
                                            dispatch(retrieveClientSelected({ TenantID:tenant,selectedClient: newList }))
                                            setCurrClientList(newList) 
                                        }}/>
                                    </div>
                                )
                            })}
                        </div>
                    }
                    <div className="appointmentClientListSearchContainer">
                        <C_Search
                            placeholder="Search"
                            onChange={(e) => {
                                setSearchText(e.target.value);
                            }}
                            height={"35px"}
                            containerWidth={"80%"}
                            padding={"10px"}
                            value={searchText}
                            searchText={searchText}
                            closeFunction={() => {
                                setSearchText("");
                            }}
                        />
                        <C_Button
                            background={Colors.generalButtonColor}
                            width={"120px"}
                            height={"30px"}
                            buttonText={"Search"}
                            justify={"center"}
                            onClick={() => {
                                handleSearchQuery()
                            }}
                            color={"#FFF"}
                            textColor={"#FFFFFF"}
                            border={"0px"}
                            gap={"10px"}
                            icon={<AiOutlineSearch size={16} />}
                        />
                        <C_Button
                            background={"#FFF"}
                            width={"120px"}
                            buttonText={"Reset"}
                            justify={"center"}
                            onClick={() => {
                                handleClearQuery()
                            }}
                            color={"#000"}
                            height={"30px"}
                            border={"1px solid #000"}
                            gap={"10px"}
                            icon={<BiReset size={15} />}
                        />
                    
                    </div>
                    <div className={`appointmentloadingTable ${loadData && 'showLoadingTable'}`} style={{height: "300px"}}>
                        <img src={require('../../assets/retrieveDataGif.gif')} alt=''/>
                        <span>Retrieving data...</span>
                    </div>
                    <div className="appointmentClientListContainer" style={{ display: loadData ? 'none' : 'flex' }}>
                        {client_selector?.map(item => {

                            let isSelected = currClientList.includes(item.ClientID)
                            return(
                                <div
                                    className='clientContainer'
                                    style={isSelected ? {background:'#e6e6e6' } : {background:'#fff'}}
                                    onClick={() => { 
                                        if(isSelected){
                                            const newList = currClientList.filter(client => client !== item.ClientID);
                                            setCurrClientList(newList)
                                            dispatch(setAppointmentContext({currAppointment : {...currAppointment, clientAttendees: currClientList}}));
                                            // dispatchCurrAppointment({ type:"CHANGE_CURR_APPOINTMENT", payload: {...currAppointment, clientAttendees: currClientList}});
                                            dispatch(retrieveClientSelected({ TenantID:tenant,selectedClient: newList }))
                                        }else{
                                            const newList = [...currClientList, item.ClientID];
                                            setCurrClientList(newList)
                                            dispatch(setAppointmentContext({currAppointment : {...currAppointment, clientAttendees: currClientList}}));
                                            // dispatchCurrAppointment({ type:"CHANGE_CURR_APPOINTMENT", payload: {...currAppointment, clientAttendees: currClientList}});
                                            dispatch(retrieveClientSelected({ TenantID:tenant,selectedClient: newList }))
                                        }
                                }}>
                                    <span>{item.RowValue}</span>
                                    {isSelected ? <AiFillCheckCircle size={18} color="green"/> : <AiOutlineCheckCircle size={18}/>}
                                </div>
                            )
                        })}
                    </div>
                    <div>
                    <ReactPaginate
                        containerClassName="paginationStyle"
                        activeClassName="activePage"
                        pageClassName="paginationElementStyle"
                        breakLabel="..."
                        nextLabel={<MdNavigateNext size={20}/>}
                        onPageChange={(page) => { onHandlePageChange(page.selected) }}
                        pageRangeDisplayed={5}
                        pageCount={(client_selector_count && client_selector_count.length > 0) && Math.ceil(client_selector_count[0].Total_Clients/500)}
                        previousLabel={<MdNavigateBefore size={20}/>}
                        renderOnZeroPageCount={null}
                        forcePage={currentPage}
                    />  
                    </div>
                    <div className="appointmentClientActionContainer mt-2">
                        <span className='counts'>Total {currClientList.length} clients selected</span>
                        <div className='buttonsContainer'>
                            <C_DialogButton
                                onClick={()=>{ setManageClientAttendees(false); }}
                                color={'#4287f5'}
                                background={'white'}
                                borderColor={'#4287f5'}
                                buttonText={"DISCARD"}
                                />
                            <C_DialogButton
                                onClick={()=>{setManageClientAttendees(false);}}
                                color={"white"}
                                background={'#4287f5'}
                                borderColor={'#4287f5'}
                                buttonText={"CONFIRM"}
                            />
                        </div>
                    </div>
                </div>
                </>
                :
                <div className="modal-content">
                    <div className="modal-header modalHeader">
                        <div className="title">
                            <CgUserList size={16}/>
                            <span>Client List</span>
                        </div>
                        <AiOutlineClose cursor='pointer' size={16} onClick={() => { setManageClientAttendees(false);}}/>
                    </div>
                    <div className="modal-body empty-client-selector">
                        <span>No Available List</span>
                    </div>
                </div>
            }
            </>
            }
            </div>
        </div>
    </div>
  )
}

export default CreateAppointment