import React, { useState, useEffect, useRef } from "react";
import { FiPlusCircle, FiSave } from "react-icons/fi";
import { MdLockOutline, MdOutlineDragIndicator, MdRemoveCircleOutline, MdRestartAlt } from "react-icons/md";
import C_Button from "../../components/C_Button";
import C_Loading from "../../components/C_Loading";
import Colors from "../../constants/Colors";
import { useDispatch } from "react-redux";
import Switch from "react-switch";
import moment from "moment";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import * as Icons from "react-icons/bi";
import { v4 } from "uuid";

import { AiOutlineClose } from "react-icons/ai";
import C_DialogButton from "../../components/C_DialogButton";
import C_PopoutNoti from "../../components/C_PopoutNoti";
import { HiOutlinePencilAlt } from "react-icons/hi";
import C_IconButton from "../../components/C_IconButton";
import { HiOutlineXMark } from "react-icons/hi2";
import { Modal } from "bootstrap";
import dateFormat from '../../enums/json/dateType.json';
import './ClientColumn.scss'

//slices
import { retrieveFormTemplate } from "../../stores/slices/formTemplateSlices";
import { retrieveClient, retrieveAllGroup, updateClientData, setDndAction, selectAllClientsGroup, selectClientContext, setClientContext } from "../../stores/slices/clientSlices";
import { selectClientGroups, updateClientGroups } from "../../stores/slices/clientGroupSlices";
import { retrieveCurrUsage } from "../../stores/slices/usageSlices";
import { selectCompanyID } from "../../stores/slices/companyIDSlices";
import { selectTenant } from "../../stores/slices/tenantSlices";
import { selectCurrSa, selectCurrTenant } from "../../stores/slices/persistRolesSlices";
import { selectCurrUserContext } from "../../stores/slices/companySlices";
import { selectUser } from "../../stores/slices/userAuthSlices";

function ClientColumn(props) {
	const exceedModalRef = useRef();
	const componentsRef = useRef();

	//All columns data (Array)
	const ColumnData = props.ColumnData;

	//Current Group Data (Object)
	const CurrentGroup = props.CurrentGroup; //current group before anychanges
	const groupID = props.groupID;
	const CurrGroup = props.group; //newly selected group

	const { currUsage, currTotalUsage, currUserSA, currUserInvitation } = selectCurrUserContext();
	const user = selectUser();
	
	const companyID = selectCompanyID();
	const tenant = selectCurrTenant();
	const currSa = selectCurrSa();
	const allClientsGroup = selectAllClientsGroup();
	const clientGroups = selectClientGroups();

	const dispatch = useDispatch();

	//components
	const [componentText, setComponentText] = useState("");
	const [requiredInput,setRequiredInput] = useState(false);
	const [privilege,setPrivilege] = useState("Editable");
	const [colIndex,setColIndex] = useState("");

	const [tempColHeader, setTempColHeader] = useState([]);
	const [tempDeletedHeader, setTempDeletedHeader] = useState([]);
	const [tempArrayColHeader, setTempArrayColHeader] = useState([]);
	const [colArray, setColArray] = useState([]);
	const [dndArray, setDndArray] = useState([])
	const [tempHeader, setTempHeader] = useState([CurrentGroup]);
	const [tempClient, setTempClient] = useState([]);
	const [currColID,setCurrColID] = useState("");
	const [isEditColumn,setIsEditColumn] = useState(false);
	const [isDefaultComponent, setIsDefaultComponent] = useState(false);

	const [isLoading, setIsLoading] = useState(false);
	const [dndState, setDndState] = useState(false);
	const [componentType, setComponentType] = useState('');
	const [componentName, setComponentName] = useState('');
	const [componentWidth, setComponentWidth] = useState(null);
	const [notifyMessage,setNotifyMessage] = useState("");
	const [options,setOptions] = useState([]);
	const [duplicateInput,setDuplicateInput] = useState(true);
	const [isSetting,setIsSetting] = useState(false);
	const [tableColumnQuota, setTableColumnQuota] = useState(0);
    const [tableColumnUsage, setTableColumnUsage] = useState(0);
	const [dateType,setDateType] = useState("YYYY");
	const [toggleOtherOption, setToggleOtherOption] = useState(true);
	const [optionChoices, setOptionChoices] = useState("Default");
	const [isArrangingColumn, setIsArrangingColumn] = useState(false);

	useEffect(()=>{
		setPrivilege("Editable");
	}, [componentType])

	useEffect(()=>{
		if(Array.isArray(ColumnData)){
			setDndArray(ColumnData);
		}
	},[ColumnData])

	const showExceedModalFunc = () => {
		const modalEle = exceedModalRef.current;
		const bsModal = new Modal(modalEle, {
			
		});
		bsModal.show();
	};

	const hideExceedModalFunc = () => {
		const modalEle = exceedModalRef.current;
		const bsModal = Modal.getInstance(modalEle);
		bsModal.hide();
	};

	const showComponentModal = () => {
		const modalEle = componentsRef.current;
		const bsModal = new Modal(modalEle, {
			
		});
		bsModal.show();
	};

	const hideComponentModal = () => {
		const modalEle = componentsRef.current;
		const bsModal = Modal.getInstance(modalEle);
		bsModal.hide();
	};

    useEffect(() => {
        if(currUsage && currTotalUsage !== undefined && currTotalUsage && currUserInvitation){
            const filterQuota = currUsage?.find(item => item?.SectionID === "s6")?.Quota;
            const filterUsage = currTotalUsage?.find(item => item?.SectionID === "s6")?.Usage;
			const filterUsageByGroup = filterUsage?.find(item => item?.GroupID === CurrentGroup?.GroupID)?.columnCount
            setTableColumnQuota(filterQuota);
            setTableColumnUsage(filterUsageByGroup);
        }else{
            setTableColumnQuota(0);
            setTableColumnUsage(0);
        }
    },[currUsage, currTotalUsage, CurrentGroup, currUserInvitation])

    async function refreshUsage(){
       dispatch(retrieveCurrUsage({TenantID:tenant,CompanyID:companyID}));
    };

	useEffect(() => {
		if(tenant && companyID){
			refreshUsage();
			// dispatch(setCompanyUsage({
			// 	currUserSA : currUserSA, 
			// 	currUserInvitation : currUserInvitation,
			// 	TenantID : tenant,
			// 	CompanyID : companyID,
			// }))
		}
		
	},[CurrentGroup, tenant, companyID])

	const TableInputWidthSelection = [
		{
			label: '25',
			value: '25%'
		},
		{
			label: '50',
			value: '50%'
		},
		{
			label: '75',
			value: '75%'
		},
		{
			label: '100',
			value: '100%'
		},
	]

	const TableComponents = [
		{
			ColumnName: 'Short Text',
			ColumnType: 'Short Text',
			Icon: 'BiText',
		},
		{
			ColumnName: 'Multiline',
			ColumnType: 'Multiline',
			Icon: 'BiDetail',
		},
		{
			ColumnName: 'Numbers',
			ColumnType: 'Numbers',
			Icon: 'BiHealth',
		},
		{
			ColumnName: 'Date',
			ColumnType: 'Date',
			Icon: 'BiCalendarWeek',
		},
		{
			ColumnName: 'Email',
			ColumnType: 'Email',
			Icon: 'BiEnvelope',
		},
		{
			ColumnName: 'Phone',
			ColumnType: 'Phone',
			Icon: 'BiPhoneCall',
		},
		{
			ColumnName: 'Status',
			ColumnType: 'Switch',
			Icon: 'BiToggleLeft',
		},
		{
			ColumnName: 'Link',
			ColumnType: 'Link',
			Icon: 'BiLinkAlt',
		},
		{
			ColumnName: 'Google Map',
			ColumnType: 'Google Map',
			Icon: 'BiMap',
		},
		{
			ColumnName: 'Multiple Choices',
			ColumnType: 'Multiple Choices',
			Icon: 'BiRadioCircleMarked',
		},
		{
			ColumnName: 'Checkboxes',
			ColumnType: 'Checkboxes',
			Icon: 'BiCheckboxChecked',
		},
		{
			ColumnName: 'Dropdown',
			ColumnType: 'Dropdown',
			Icon: 'BiListMinus',
		},
		// {
		// 	ColumnName: 'Member',
		// 	ColumnType: 'Member',
		// 	Icon: 'BiGroup',
		// },
		{
			ColumnName: 'Form',
			ColumnType: 'Form',
			Icon: 'BiFileBlank',
		},
		{
			ColumnName: 'Countdown',
			ColumnType: 'Alert',
			Icon: 'BiTimer',
		},

	];

	const privileges = ["View","Editable","Hidden"];

	useEffect(() => {
		if(privilege === "Hidden" || privilege === "View")
		{
			setRequiredInput(false);
			setDuplicateInput(true);
		}
	},[privilege])

	useEffect(() => {
		var col = [];
		col.push({
			GroupID: CurrentGroup.GroupID,
			CompanyID : CurrentGroup.CompanyID,
			GroupName : CurrentGroup.GroupName,
			GroupColor : CurrentGroup.GroupColor,
			ColumnData : JSON.parse(CurrentGroup.ColumnData),
			CreatedAt : CurrentGroup.CreatedAt,
		})

		setTempHeader(col);
		setColArray(ColumnData);

	}, [CurrentGroup, allClientsGroup, clientGroups])

	useEffect(() => {

		let data = tempHeader[0].ColumnData

		if(isEditColumn)
		{
			let currColumnObj = data.find(element=>element.columnID === currColID)
			if(currColumnObj !== undefined)
			{
				setComponentName(currColumnObj.columnType); //components title
				setComponentText(currColumnObj.columnName);
				setComponentType(currColumnObj.columnType);
				setComponentWidth(currColumnObj.width);
				setIsDefaultComponent(currColumnObj.isDefault);
				setRequiredInput(currColumnObj.required);
				setDuplicateInput(currColumnObj.duplicate)
				setPrivilege(currColumnObj.privilege);
				setColIndex(currColumnObj.columnIndex);

				if(currColumnObj.option !== undefined){
					setOptions(currColumnObj.option);
				}
				if(currColumnObj.dateType !== undefined){
					setDateType(currColumnObj.dateType);
				}
				if(currColumnObj.otherOption !== undefined){
					setToggleOtherOption(currColumnObj.otherOption);
				}
				if(currColumnObj.optionChoices !== undefined){
					setOptionChoices(currColumnObj.optionChoices);
				}
			}
		}

	},[isEditColumn,currColID])

	useEffect(() => {
		dispatch(retrieveFormTemplate({TenantID:tenant,CompanyID:companyID}));
		dispatch(retrieveClient({TenantID:tenant, CompanyID: companyID, CurrPage: 0, SortType: "", SortOrder: "", SearchText: "", SearchBy: "", UserID: user.uid, CurrGroup: "",isSA: currSa,SelectedDateColumn: "", StartDate: "", EndDate: "", DataFilterArr: [] }))
	}, []);

	//COMPONENTS
	//save column input text value
	const addColumn = () => {

		const duplicate = colArray.some(item => item.columnName === componentText);
		const duplicateAlert = colArray.some(item => item.columnType === "Alert" && componentType === "Alert");

		if (!componentText.length) {
			notify("empty");
			return;
		}

		if (duplicate) {
			notify("duplicate");
			return;
		}

		if(duplicateAlert){
			notify("duplicate-alert");
			return;
		}

		if (componentType === "Alert") {
			if(options.length < 1)
			{
				notify("empty-options");
				return;
			}
		}

		if(componentType === "Multiple Choices" || componentType === "Dropdown" || componentType === "Checkboxes")
		{
			if(options.length < 1)
			{
				notify("empty-options");
				return;
			}
			else
			{
				const hasEmptyOption = options.some(option => option.trim() === "");
	
				if (hasEmptyOption) {
					notify("empty-options");
					return;
				}
			}
		}

		const id = v4();
		let value = "";
		const newRow = [...tempClient];
		let newColumn = {};

		switch (componentType) {
			case "Switch":
				value = true;
				break;
			case "Date":
				value = moment(new Date()).format(dateType);
				break;
			case "Checkboxes":
				value = [];
				break;
			default:
				value = "";
		}

		if(componentType === "Multiple Choices" || componentType === "Checkboxes" || componentType === "Alert")
		{
			newColumn = {
				columnID: id,
				columnName: componentText,
				columnType: componentType,
				width: componentWidth,
				required: requiredInput,
				duplicate: duplicateInput,
				privilege : privilege === "" ? "View" : privilege,
				columnIndex : colArray.length,
				option: options
			};
		}
		else if(componentType === "Dropdown"){
			newColumn = {
				columnID: id,
				columnName: componentText,
				columnType: componentType,
				width: componentWidth,
				required: requiredInput,
				duplicate: duplicateInput,
				privilege : privilege === "" ? "View" : privilege,
				columnIndex : colArray.length,
				option: options,
				otherOption: toggleOtherOption,
				optionChoices: "Default"
			};
		}
		else if(componentType === "Date"){
			newColumn = {
				columnID: id,
				columnName: componentText,
				columnType: componentType,
				width: componentWidth,
				required: requiredInput,
				duplicate: duplicateInput,
				privilege : privilege === "" ? "View" : privilege,
				columnIndex : colArray.length,
				dateType: dateType,
			};
		}
		else
		{
			newColumn = {
				columnID: id,
				columnName: componentText,
				columnType: componentType,
				width: componentWidth,
				required: requiredInput,
				duplicate: duplicateInput,
				privilege : privilege === "" ? "View" : privilege,
				columnIndex : colArray.length,
			};
	
		}
		
		newRow.push({ rowID: v4(), columnID: id, rowValue: value })

		const updatedHeaderData = tempHeader?.map(header => {
			return {	
				...header,
				ColumnData: [...header.ColumnData, newColumn]
			};
		});

		setColArray([...colArray, newColumn]);
		setTempColHeader([...tempColHeader, newColumn]);
		setTempArrayColHeader([...tempArrayColHeader, componentText]);
		setTempClient(newRow);
		setTempHeader(updatedHeaderData);
		setComponentText("");
		notify("created");
		setRequiredInput(false);
		setDuplicateInput(true);
		setPrivilege("Editable");
		setColIndex("");

	}

	const editColumn = () => {

		const col = [...tempHeader];
		const column = [...colArray];

		let updatedColumnObj;

		if(componentType === "Multiple Choices" || componentType === "Checkboxes" || componentType === "Alert"){
			updatedColumnObj = {
				columnID : currColID,
				columnName : componentText,
				columnType : componentType,
				width: componentWidth,
				required : requiredInput,
				duplicate: duplicateInput,
				privilege : privilege,
				columnIndex : colIndex,
				option: options,
			}
		}
		else if(componentType === "Dropdown"){
			updatedColumnObj = {
				columnID : currColID,
				columnName : componentText,
				columnType : componentType,
				width: componentWidth,
				required : requiredInput,
				duplicate: duplicateInput,
				privilege : privilege,
				columnIndex : colIndex,
				option: options,
				otherOption: toggleOtherOption,
				optionChoices: optionChoices
			}
		}
		else if(componentType === "Date"){
			updatedColumnObj = {
				columnID : currColID,
				columnName : componentText,
				columnType : componentType,
				width: componentWidth,
				required : requiredInput,
				duplicate: duplicateInput,
				privilege : privilege,
				columnIndex : colIndex,
				dateType:dateType
			}
		}
		else{
			updatedColumnObj = {
				columnID : currColID,
				columnName : componentText,
				columnType : componentType,
				width: componentWidth,
				required : requiredInput,
				duplicate: duplicateInput,
				privilege : privilege,
				columnIndex : colIndex,
			}
		}

		let index = col[0].ColumnData.findIndex((element=>element.columnID === currColID))
		col[0].ColumnData[index] = updatedColumnObj
		column[index] = updatedColumnObj

		setColArray(column);
		setTempHeader(col)
		setCurrColID("");
		setComponentText("");
		setComponentType("");
		setIsEditColumn(false);
		
	}

	// save entire customizable table
	const saveTable = async () => {
		refreshUsage();
		// tempHeader[0]?.ColumnData?.length < tableColumnQuota
		if(tempHeader[0]?.ColumnData?.length < tableColumnQuota){

			let tableArray = {
				GroupID:tempHeader[0].GroupID,
				CompanyID : tempHeader[0].CompanyID,
				GroupName : tempHeader[0].GroupName,
				GroupColor : tempHeader[0].GroupColor,
				ColumnData: JSON.stringify(tempHeader[0].ColumnData),
				CreatedAt : moment(tempHeader[0].CreatedAt).format("YYYY-MM-DD HH:mm:ss"),
			}

			dispatch(setClientContext({selectedGroup : tableArray}))

			dispatch(updateClientGroups({TenantID:tenant,data:tableArray}));
			notify('save-table');
			dispatch(retrieveAllGroup({TenantID:tenant,CompanyID : tempHeader[0].CompanyID}));
			
			// await refreshUsage();
			if(tempClient.length > 0 || tempDeletedHeader.length > 0)
			{
				const updatedTempDeletedHeader = tempDeletedHeader.filter(headerItem => !tempClient.some(rowItem => rowItem.columnID === headerItem.columnID));
				const updatedTempClient = tempClient.filter(rowItem => !tempDeletedHeader.some(headerItem => headerItem.columnID === rowItem.columnID));
				const body = {
					DeletedHeader:updatedTempDeletedHeader,
					ClientData:updatedTempClient,
					GroupID:tempHeader[0].GroupID,
					CompanyID : tempHeader[0].CompanyID
				}
				
				dispatch(updateClientData({TenantID:tenant,data:body}))

				setTempDeletedHeader([]);
				setTempClient([]);
				dispatch(retrieveClient({ TenantID:tenant, CompanyID: companyID, CurrPage: 0, SortType: "", SortOrder: "", SearchText: "", SearchBy: "", UserID: user.uid, CurrGroup: "", isSA: currSa,SelectedDateColumn: "", StartDate: "", EndDate: "", DataFilterArr: [] }))

			}
		} else {
			showExceedModalFunc();
		}

	};

	const saveTableReminder = () => {

		let tableArray = {
			GroupID:tempHeader[0].GroupID,
			CompanyID : tempHeader[0].CompanyID,
			GroupName : tempHeader[0].GroupName,
			GroupColor : tempHeader[0].GroupColor,
			ColumnData: JSON.stringify(tempHeader[0].ColumnData),
			CreatedAt : moment(tempHeader[0].CreatedAt).format("YYYY-MM-DD HH:mm:ss"),
		}

		dispatch(updateClientGroups({TenantID:tenant,data:tableArray}))

				notify('save-table');

				if(tempClient.length > 0 || tempDeletedHeader.length > 0)
				{
					const updatedTempDeletedHeader = tempDeletedHeader.filter(headerItem => !tempClient.some(rowItem => rowItem.columnID === headerItem.columnID));
					const updatedTempClient = tempClient.filter(rowItem => !tempDeletedHeader.some(headerItem => headerItem.columnID === rowItem.columnID));
					const body = {
						DeletedHeader:updatedTempDeletedHeader,
						ClientData:updatedTempClient,
						GroupID:tempHeader[0].GroupID,
						CompanyID : tempHeader[0].CompanyID
					}
					dispatch(retrieveAllGroup({TenantID:tenant,CompanyID : tempHeader[0].CompanyID}));
					dispatch(updateClientData({TenantID:tenant,data:body}))
			
					if(props.action === "tab")
					{
						props.hideModal();
						props.handleSelectTab(props.groupTab)
					}
					else
					{
						props.hideModal();
						dispatch(setClientContext({
							selectedGroupID : groupID,
							selectedGroup : CurrGroup,
						}));
						// setSelectedGroupID({type: "SELECT_CLIENT_GROUP_ID", payload: groupID});
					}
					setTempDeletedHeader([]);
					setTempClient([]);
					dispatch(retrieveClient({ TenantID:tenant, CompanyID: companyID, CurrPage: 0, SortType: "", SortOrder: "", SearchText: "", SearchBy: "", UserID: user.uid, isSA: currSa,CurrGroup: "", SelectedDateColumn: "", StartDate: "", EndDate: "", DataFilterArr: [] }))
				}
				else
				{
					if(props.action === "tab")
					{
						props.hideModal();
						props.handleSelectTab(props.groupTab)
					}
					else
					{
						props.hideModal();
						dispatch(setClientContext({
							selectedGroupID : groupID,
							selectedGroup : CurrGroup,
						}));
						// setSelectedGroupID({type: "SELECT_CLIENT_GROUP_ID", payload: groupID});
					}
					
					dispatch(retrieveAllGroup({TenantID:tenant,CompanyID : tempHeader[0].CompanyID}));
				}


	};

	//delete column header
	const removeColumn = (targetIndex,columnIndex) => {

		setTempHeader(prevHeader => {
			let col = [...prevHeader];
			let columnData = col[0].ColumnData;

			if (targetIndex !== -1) {

				//delete column before resetting the index
				columnData.splice(targetIndex, 1);
				
				const updatedColArray = columnData.map((item,index)=>{
					
					if(item.columnIndex > columnIndex)
					{
						item.columnIndex = item.columnIndex - 1;
					} 

					return item
				})
				col[0].ColumnData = updatedColArray;
	
				return col;
			}
		})

		let colObj = colArray.find((element,index)=>targetIndex === index)
		if(colObj)
		{
			const columnID = colObj.columnID
			
			setTempDeletedHeader(prevTempDeletedHeader => [
				...prevTempDeletedHeader,
				{ columnID },
			]);
		}

		setColArray(prevColArray => {
			let updatedArray = [...prevColArray];

			if (targetIndex !== -1) {

				//delete column before resetting the index
				updatedArray.splice(targetIndex, 1);
				
				const updatedColArray = updatedArray.map((item,index)=>{
					if(item.columnIndex > columnIndex) {
						item.columnIndex = item.columnIndex - 1;
					}

					return item;
				})

				return updatedColArray;
			}
		})
	};

	const resetTable = () => {
		setTempHeader([CurrentGroup])
		setTempDeletedHeader([]);
		setTempColHeader([]);
		setTempArrayColHeader([]);
		setTempClient([]);
		dispatch(retrieveFormTemplate({TenantID:tenant,CompanyID:companyID}));
		dispatch(retrieveAllGroup({TenantID:tenant,CompanyID : tempHeader[0].CompanyID}));
		dispatch(retrieveClient({ TenantID:tenant, CompanyID: companyID, CurrPage: 0, SortType: "", SortOrder: "", SearchText: "", SearchBy: "", UserID: user.uid, CurrGroup: "", isSA: currSa, SelectedDateColumn: "", StartDate: "", EndDate: "", DataFilterArr: [] }))

	};

	const editHeader = () => {
		saveTable();
		setDndState(true);
		dispatch(setDndAction(true))
	};


	// Define SortableItem as a separate component
	const SortableItem = SortableElement(({ value }) => {
		return (
				<div className="column-container" style={{width: value.width}}>
					<span className="columns client-header">
						<MdOutlineDragIndicator
							size={15}
							style={{ textAlign: "center", alignItems: "center",marginRight:'3px' }}
						/>
						<span className="client-header">
							{value.columnName}
						</span>
					</span>
				</div>
		);
	});

	// Define SortableList outside the component function
	const SortableList = SortableContainer(({ items }) => {
	return (
		<div className="sortable-container">
			{items.map((value, index) => (
				<SortableItem key={index} index={index} value={value} />
			))}
		</div>
	);
	});

	// Your main component
	const GetSortableList = ({ items }) => {	
		const onSortEnd = ({ oldIndex, newIndex }) => {
			// const dndArr = [...dndArray];
			let arr = arrayMoveImmutable(items, oldIndex, newIndex);
			setColArray(arr)
			setDndArray(arr);
		};

		return (
			<SortableList items={items} onSortEnd={onSortEnd} axis="xy" />
		);
	};

	const SortableItemOption = SortableElement(({ value, index }) => {
		return (
			<div className="option-child">
				<div style={{ display:'flex', alignItems:'center' }}>
					{/* For alert */}

					{componentType === "Checkboxes" &&
						<label style={{display:'flex',alignItems:'center' }}>
							<input type='checkbox' className='option-radio' disabled/>
							<span className="checkmark" style={{marginRight:'10px'}}></span>
						</label>
					}
					
					{componentType === "Multiple Choices" &&
						<>
							<input type='radio' className="option-radio" disabled/>
						</>
					}

					<input disabled type='text' className="option-input" value={value} placeholder={"Options"}/>
					
				</div>
				<span className="sortable-span-option">
					<MdOutlineDragIndicator
						size={15}
						style={{ textAlign: "center", alignItems: "center", marginRight:'3px' }}
					/>
				</span>
			</div>
		);
	});
	
	const SortableListOption = SortableContainer(({ items }) => {
		return (
			<div className="components-option-setting">
				<div className="components-option-list">
					{items.map((value, index) => {
						return(
							<SortableItemOption
								index={index}
								value={value}
							/>
						)
					})}
					{items.length === 0 &&
						<>
							<li>{"No option has added to the list"}</li>
						</>
					}
				</div>
			</div>
		)}
	);

	const onSortEndOption = ({ oldIndex, newIndex }) => {
		let arr = arrayMoveImmutable(options, oldIndex, newIndex);
		setOptions(arr)
	};


	const saveColumnPosition = () => {

		let body = {
			GroupID:CurrentGroup.GroupID,
			CompanyID : CurrentGroup.CompanyID,
			GroupName : CurrentGroup.GroupName,
			GroupColor : CurrentGroup.GroupColor,
			ColumnData: JSON.stringify(dndArray),
			CreatedAt : moment(CurrentGroup.CreatedAt).format("YYYY-MM-DD HH:mm:ss"),
		}
		
		dispatch(setClientContext({
			selectedGroup : body
		}))

		dispatch(updateClientGroups({TenantID:tenant,data:body}))
		// console.log(data);
		dispatch(retrieveAllGroup({TenantID:tenant,CompanyID : tempHeader[0].CompanyID}));
		dispatch(retrieveClient({ TenantID:tenant, CompanyID: companyID, CurrPage: 0, SortType: "", SortOrder: "", SearchText: "", SearchBy: "", UserID: user.uid, CurrGroup: "", isSA: currSa,SelectedDateColumn: "", StartDate: "", EndDate: "", DataFilterArr: [] }))
		// dispatch(retrieveClient(companyID));
		notify("saved")
	}

	function notify(type){
		switch(type)
		{
		  case "duplicate-alert":
			setNotifyMessage("CANNOT HAVE MORE THAN ONE COUNTDOWN COLUMN");
			break;
		  case "duplicate":
			setNotifyMessage("DUPLICATE COLUMN")
			break;
		  case "empty":
			setNotifyMessage("EMPTY COLUMN NAME")
			break;
		  case "created":
			setNotifyMessage("COLUMN CREATED")
			break;
		  case "reset":
			setNotifyMessage("COLUMN RESET")
			break;
		  case "saved":
			setNotifyMessage("COLUMN POSITION SAVED")
			break;
		  case "saved column":
			setNotifyMessage("COLUMN SAVED")
			break;
		  case "save-table":
			setNotifyMessage("TABLE SAVED")
			break;
		  case "empty-options":
			setNotifyMessage("OPTIONS MUST NOT BE EMPTY")
			break;
		  default:
			setNotifyMessage("SAVED")
			break;
		}

		setTimeout(() => {
			setNotifyMessage("");
		}, 1500)
	  };

	  const addOptions = (type) => {
		let option = [...options];
		
		if(type === "Alert"){
			option.push({
				color: "#feffd6",
				period: "2",
				unit: "day",
			})
		}else{
			option.push('')
		};
		setOptions(option);
	  }

	  const removeOptions = (optionIndex) => {
		let option = [...options];
  
		option.splice(optionIndex,1);
		setOptions(option);
  
	  }

	  const handleOptionInput = (e,optionIndex) => {

		let option = [...options];
  
		option[optionIndex]= e.target.value
		setOptions(option);
	  }

	  const handleOptionInputObj = (e,optionIndex,key) => {
		let option = [...options];
		option[optionIndex] = {
			...option[optionIndex],
			[key]: e.target.value
		};
		setOptions(option);
	  }

	//                    阿弥陀佛
	//
	//                    _ooOoo_
	//                   o8888888o
	//                   88" . "88
	//                   (| -_- |)
	//                   O\  =  /O
	//                ____/`---'\____
	//              .'  \\|     |//  `.
	//             /  \\|||  :  |||//  \
	//            /  _||||| -:- |||||-  \
	//            |   | \\\  -  /// |   |
	//            | \_|  ''\-/''  |   | |
	//            \  .-\__  `-`  ___/-. /
	//          ___`. .'  /-.-\  `. . _/_
	//       ."" '<  `.___\_<|>_/___.'  >'"".
	//      | | :  `- \`.;`\ _ /`;.`/ - ` : | |
	//      \  \ `-.   \_ __\ /__ _/   .-` /  /
	// ======`-.____`-.___\_____/___.-`____.-'======
	//                    `=-='
	//
	//               佛祖保佑，永无bug

	return (

		<div className="column-detail-main-container">
			<C_Loading isLoading={isLoading} />
			<C_PopoutNoti triggerSaved={notifyMessage}/>
			<div className="modal fade" tabIndex="-1" ref={exceedModalRef}>
				<div className="modal-dialog modal-dialog-centered" style={{}}>
					<div className="modal-content px-3" style={{overflowY: "scroll" }}>
					<div className="modal-body">
						{/* <AiOutlineClose cursor='pointer' size={16} onClick={() => {
						hideExceedModalFunc();
						}}/> */}
						<div className="quotaRestrictedContainer">
						<span>You've reached the limit, <a href="https://zumaxdigital.com/crm-v1/" target="_blank" className="link">upgrade now!</a></span>
						</div>
					</div>
				</div>
				</div>
			</div>
			<div className="column-action-container" style={{height:'10%'}}>
				{dndState ? (
					<>
						<div className="d-flex" style={{ alignItems: 'center' }}>
							<MdOutlineDragIndicator
							size={20}
							style={{ color: "#636dbf", textAlign: "center",display:'flex',justifyContent:'center',alignContent:'center',marginRight:'10px' }}
							/>
							<p
								className="m-0 d-flex justify-content-end font-xl"
								style={{
									color: "#636dbf",
									paddingRight: "10px",
									marginBottom: "0px",
								}}
							>
								Update Column Position
							</p>
							
						</div>
					</>
				) : null}
			
				<div
				className="col d-flex justify-content-end"
				style={{ justifyContent: "end" }}>
				{dndState ? (
					<>
					<div className="d-flex" style={{columnGap:'20px'}}>
						<C_Button
							width={window.innerWidth > 1500 ? "130px" : "110px"}
							buttonText={"Discard"}
							onClick={() => { 
								setDndState(false); 
								dispatch(setDndAction(false)); 
								dispatch(retrieveClient({ TenantID:tenant, CompanyID: companyID, CurrPage: 0, SortType: "", SortOrder: "", SearchText: "", SearchBy: "", UserID: user.uid, CurrGroup: "",isSA: currSa, SelectedDateColumn: "", StartDate: "", EndDate: "", DataFilterArr: [] }))
			
							}}
							justify={"center"}
							color={"#000000"}
							border={"1px solid"}
							borderColor={"#BCBCBC"}
							background={"#f8fafa"}
						/>
						<C_Button
							width={window.innerWidth > 1500 ? "140px" : "120px"}
							buttonText={"Save Position"}
							gap={"10px"}
							onClick={() => {setDndState(false); dispatch(setDndAction(false)); saveColumnPosition();}}
							icon={<FiSave size={17} />}
							justify={"center"}
							color={"#ffffff"}
							border={"1px solid"}
							borderColor={"#6d45b9"}
							background={"#6d45b9"}
						/>
					</div>
					</>
				) : (
					<>
						<C_Button
							backgroundColor={Colors.calendarButtonColor}
							width={"110px"}
							buttonText={"Reset"}
							gap={"10px"}
							onClick={() => { resetTable(); 	notify("reset");}}
							icon={<MdRestartAlt size={16} />}
							marginRight={"0.5rem"}
							justify={"center"}
							color={"#000000"}
							border={"1px solid"}
							borderColor={"#BCBCBC"}
							background={"#f8fafa"}
						/>
						<C_Button
							width={window.innerWidth > 1500 ? "160px" : "140px"}
							gap={"10px"}
							buttonText={"Column Position"}
							icon={<MdOutlineDragIndicator size={16} />}
							marginRight={"0.5rem"}
							justify={"center"}
							color={"#000000"}
							border={"1px solid"}
							borderColor={"#BCBCBC"}
							background={"#f8fafa"}
							onClick={() => {editHeader();}}
						/>
						<C_Button
							width={window.innerWidth > 1500 ? "130px" : "110px"}
							buttonText={"Save Table"}
							gap={"10px"}
							// targetID={"save-table"}
							// toggleAction={"modal"}
							onClick={() => { saveTable(); }}
							icon={<FiSave size={15} />}
							// dismissAction="modal"
							justify={"center"}
							color={"#000000"}
							border={"1px solid"}
							borderColor={"#BCBCBC"}
							background={"#f8fafa"}
						/>
					</>
				)}
				</div>
			</div>
			<div className="column-table-container">
				<div className="table-container" style={{background: '#FFFFFF', border: 'none', boxShadow: '1px 1px 1px 1px rgba(0,0,0,0.1)', height: "100%" }}>
					{/* HEADER PUTANGINA */}
					<div
						className="col-header-outer-container"
					>
						{dndState === true ? (
							<GetSortableList items={dndArray}/>
						) : (
							<div
								className="col-header-container"
							>
								{colArray.length > 0 && colArray.map((column, index) => {
									if (column.columnName !== "DepartmentID" && column.columnName !== "PosID" && column.columnName !== "ClientID") {
										return (
											<>
												<div className="column-container" style={{width: column.width}}>
													<div className="columns">
														
														<span
															className={`${column.privilege === "Hidden" ? "client-header-hidden" : "client-header" }`}
															// data-bs-target={'#components-confirmation'} 
															// data-bs-toggle={"modal"} 
															onClick={(e) => {
																showComponentModal();
																setCurrColID(column.columnID);
																setIsEditColumn(true);
																setIsSetting(false);
															}}>
																{column.columnName}
																{column.required && <span className="required-asterisk">*</span>}
																{!column.duplicate && <span className="dup-span">{`(unique)`}</span>}
														</span>
														
														{column.columnName !== "FullName" && column.columnName !== "UpdateAt" && column.columnName !== "UserPassword" && column.columnName !== "UserName" && column.columnName !== "CreatedAt" && column.columnName !== "UserEmail" && column.columnName !== "ClientName" ? (
															<div>
																<MdRemoveCircleOutline
																	onClick={(e) => {
																		removeColumn(index,column.columnIndex);

																	}}
																	size={15}
																	style={{
																		cursor: "pointer",
																		color: "red",
																		opacity: 0.5,
																	}}
																/>
															</div>
														) : (
															<div>
																<MdLockOutline
																	size={15}
																	style={{
																		cursor: "pointer",
																		color: "blue",
																		opacity: 0.5,
																	}}
																/>
															</div>
														)}
													</div>
												</div>
											</>
										);
									}
								})}
							</div>
						)}
					</div>
				</div>
				<div className="component-container" style={{ border: 'none', background: '#ffffff', boxShadow: '1px 1px 1px 1px rgba(0,0,0,0.1)', height: "100%" }}>
					<div className="card-body" style={{ height: "100%",overflow:'hidden' }}>
						<div className="p-2" style={{height: "100%" }}>
							<div className="py-2" style={{borderBottom:'solid 1px'}}>
								<span className="font-xs" style={{letterSpacing:'1px'}}>Column Components</span>
							</div>
							<div className="component-list">
							{/* tableColumnUsage < tableColumnQuota */}
								{tableColumnUsage < tableColumnQuota ? 
									TableComponents.map((component, index) => {
										const IconComponent = Icons[component.Icon];
										return (
											<div className="component-button">
												<C_Button
													background={"linear-gradient(to right top, #e6e1f8, #e0d9f6, #dad1f5, #d4c9f3, #cec1f1)"}
													height={"45px"}
													width={"100%"}
													fontSize={"remark-text"}
													buttonText={component.ColumnName}
													justify={"space-between"}
													onClick={() => 
														{ 
															showComponentModal();
															setComponentType(component.ColumnType); 
															setComponentName(component.ColumnName);
															setComponentWidth(null);
															setIsDefaultComponent(false);
															setOptions([]);
															setComponentText("");
															setDuplicateInput(true);
															setRequiredInput(false);
															setIsEditColumn(false);
															setIsSetting(false);
														}}
													color={"#474747"}
													paddingLeft={"10px"}
													paddingRight={"10px"}
													fontWeight={"light"}
													letterSpacing={"0.5px"}
													icon={<IconComponent size={18} />}
													// targetID={"components-confirmation"}
													// toggleAction={"modal"}
												/>
											</div>
										);
									})
									:
									<div className="quotaRestrictedContainerNoPadding">
										<span>You've reached the limit, <br/><a href="https://zumaxdigital.com/crm-v1/" target="_blank" className="link">upgrade now!</a></span>
									</div>
								}
								<div className="mt-2" style={{display:'flex',flexWrap:'wrap',flexDirection:'row',width:'160px',columnGap:'20px',pointerEvents:'none'}}>
									<C_Button
										background={"#fff"}
										width={"100%"}
										height={"45px"}
										justify={"space-between"}
										color={"#fff"}
										paddingLeft={"10px"}
										paddingRight={"10px"}
									/>
								</div>
							</div>
						</div>
					</div>
					{dndState &&
						<div style={{ position: 'absolute', width: '100%',left:'0', borderRadius:'10px', height: '100%', background: 'black', opacity: '0.3', bordeRadius: '10px', border: 'none',top:'0' }}></div>}
				</div>
			</div>
				
				<div className="modal fade" tabIndex="-1" id="save-table" style={{}}>
					<div className="modal-dialog modal-dialog-centered" style={{}}>
						<div className="modal-content px-3" style={{overflowY: "scroll" }}>
							<div className="modal-header modalHeader">
								<div className="title font-s">
									<FiSave size={16} color={"#3eb986"}/>
									<span>Save Table</span>
								</div>
								<AiOutlineClose cursor='pointer' size={16} data-bs-dismiss="modal"/>
							</div>
							<div className="modal-body">
								<span className="font-xs">
									Do you want to save the table? This action cannot be reverted.
								</span>
								<div className="new-button-container">
								<C_DialogButton
									color={'#3eb986'}
									background={'white'}
									borderColor={ '#3eb986'}
									buttonText={"DISCARD"}
									dismissAction="modal"
								/>
								<C_DialogButton
									color={"white"}
									onClick={() => { 
										saveTable();
									}}
									background={'#3eb986'}
									borderColor={'#3eb986'}
									buttonText={"SAVE"}
									dismissAction="modal"
								/>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="modal fade" ref={props.reminderRef} tabIndex="-1" id="save-reminder" style={{}}>
					<div className="modal-dialog modal-dialog-centered" style={{}}>
						<div className="modal-content px-3" style={{overflowY: "scroll" }}>
							<div className="modal-header modalHeader">
								<div className="title font-s">
									<FiSave size={16} color={"#4287f5"}/>
									<span>Save Table</span>
								</div>
								<AiOutlineClose cursor='pointer' size={16} data-bs-dismiss="modal"/>
							</div>
							<div className="modal-body">
								<span className="font-xs">
									Do you want to save the table before you leave? This action cannot be reverted.
								</span>
								<div className="new-button-container">
								<C_DialogButton
									onClick={() => { 
										setDndState(false);
										if(props.action === "tab")
										{
											props.handleSelectTab(props.groupTab)
											props.hideModal();
										}
										else
										{
											dispatch(setClientContext({
												selectedGroupID : groupID,
												selectedGroup : CurrGroup
											}));
											// setSelectedGroupID({type: "SELECT_CLIENT_GROUP_ID", payload: groupID});
											props.hideModal();
										}
										
								
									}}
									color={'#4287f5'}
									background={'white'}
									borderColor={ '#4287f5'}
									width={'140px'}
									buttonText={"IGNORE CHANGES"}
									dismissAction="modal"
								/>
								<C_DialogButton
									color={"white"}
									onClick={() => { 
										saveTableReminder();
										setDndState(false);
									}}
									background={'#4287f5'}
									borderColor={'#4287f5'}
									buttonText={"SAVE"}
									dismissAction="modal"
								/>
								</div>
							</div>
						</div>
					</div>
				</div>

				{/* diuuuuuuuuuuuuuuuuuuuuuuuuuuuu */}
				<div className="modal fade"
					id="components-confirmation"
					tabIndex="-1"
					ref={componentsRef}
					style={{}}>
					<div className="modal-dialog modal-dialog-centered" style={{}}>
						
						<div className="modal-content px-3  bs-modal" style={{position:'relative' }}>

							<div className="modal-header modalHeader">
								<div className="title">
									{/* <FiUpload size={17} color={"#3eb986 "}/> */}
									<span className="font-s">{componentName} Column</span>
								</div>
								<div>
								
								</div>
								<AiOutlineClose cursor='pointer' size={16} data-bs-dismiss="modal" 
								onClick={()=>{
										setComponentText("");
										setComponentType("");
										setComponentWidth(null);
										setIsDefaultComponent(false);
										setColIndex("");
										setDuplicateInput(true);
										setRequiredInput(false);
										setCurrColID("");
										setIsEditColumn(false);
										setOptions([]);
								}}/>
							</div>
							<div className="modal-body">
								
								<div className=" d-flex flex-column" style={{marginBottom:'5px'}}>
									<label htmlFor="name" className="font-xxs">
										Your Column Name
									</label>
									<div className="column-components-input">
										<input
											style={{...isDefaultComponent ? { backgroundColor: "#CCC" } : {}}}
											type="input"
											className="column-name-input"
											value={componentText}
											placeholder="Name"
											name="name"
											id="name"
											disabled={isDefaultComponent}
											onChange={(e) => {
												setComponentText(e.target.value);
											}}
										/>
										{componentType === "Date" && !isDefaultComponent && 
											<select className="column-components-dropdown" onChange={(e) => {setDateType(e.target.value)}}>
													
												<option disabled>Date type</option>
												
												{Object.keys(dateFormat).map((keys, index)=>{
													
													if(keys === dateType){
														return(<option key={index} value={keys} selected>{keys}</option>)
													}else{
														return(<option key={index} value={keys}>{keys}</option>)
													}
													
													
												})}
												
											</select>
										}
										{componentType !== "Form" && componentType !== "Switch" && !isDefaultComponent && 
											<select className="column-components-dropdown" onChange={(e) => {setPrivilege(e.target.value)}}>
												{/* <option value={privilege === "" ? "View" : privilege} selected>{privilege === "" ? <>Column Privilege</> : privilege}</option> */}
												{/* {privilege === "" && 
												<option value={"View"} selected>Column Privileges</option>
												
												} */}
												{privileges.map((item)=>{
													
													if(privilege === item)
													{
														return(<option value={item} selected>{item}</option>)
														
													}
													else
													{
														return(<option value={item}>{item}</option>)
													}
													
												})}
												
											</select>
											
										}
									</div>

									<div className=" d-flex flex-column" style={{marginBlock:'15px'}}>
										<label htmlFor="name" className="font-xxs">
											Column Input Width %
										</label>
										<div className="column-components-width-input">
											{TableInputWidthSelection.map(item => {
												const isSelected = item.value === componentWidth;
												return(
													<div className={`width-input-selection ${isSelected ? 'isSelectedWidthOption' : ''}`}
														onClick={() => {
															setComponentWidth(item.value);
														}}
													>
														<span className="font-s">{item.label}</span>
													</div>
												)
											})}
										</div>
									</div>


									{(componentType === "Alert" || componentType === "Multiple Choices" || componentType === "Dropdown" || componentType === "Checkboxes") &&
										<>
										
										<div className='components-option-container-after' >
											{isArrangingColumn ? 
												<SortableListOption 
													items={options}
													onSortEnd={onSortEndOption}
													axis="xy"
												/>
												:
												<div className="components-option-setting">
													<div className="components-option-list">
														{options.map(( item, optionIndex ) => {
															return(
																<div className="option-child">
																	<div style={{ display:'flex',alignItems:'center' }}>
																		{/* For alert */}
																		{componentType === "Alert" &&
																				<input type="color" value={item.color} className="option-color" onChange={((e)=>{handleOptionInputObj(e,optionIndex,'color')})}/>
																		}

																		{componentType === "Checkboxes" &&
																			<label style={{display:'flex',alignItems:'center' }}>
																				{optionIndex === 0 ?  <input type='checkbox' className='option-radio' checked/> : <input type='checkbox' className='option-radio' disabled /> }
																				<span className="checkmark" style={{marginRight:'10px'}}></span>
																			</label>
																		}
																		
																		{componentType === "Multiple Choices" &&
																			<>
																				{optionIndex === 0 ? <input type='radio' className="option-radio"  checked/> : <input type='radio' className="option-radio" disabled/> }
																			</>
																		}

																		{componentType === "Dropdown" &&
																			<>
																				<span className="option-span">{optionIndex + 1}.</span>
																			</>
																		}
																		{componentType === "Alert" ?
																			<>
																				<input type='number' className="option-input-alert" value={item.period} onChange={((e)=>{handleOptionInputObj(e,optionIndex,'period')})}/>
																				<select className="option-select-alert" value={item.unit} onChange={((e)=>{handleOptionInputObj(e,optionIndex,'unit')})}>
																					<option value="day" selected>Day</option>
																					<option value="week">Week</option>
																					<option value="month">Month</option>
																				</select>
																			</>
																			:
																			<input type='text' className="option-input" value={item} placeholder={"Options " + `${optionIndex+1}`} onChange={((e)=>{handleOptionInput(e,optionIndex)})}/>
																		}
																		
																	</div>
																	<div style={{ zIndex: 9999 }}>
																		<MdRemoveCircleOutline size={16} style={{cursor:'pointer',color:'rgb(250 114 114)'}} onClick={()=>{removeOptions(optionIndex)}}/>
																	</div>
																</div>
															)
														})}
														{options.length === 0 &&
															<>
															<li>{componentType === "Alert" ? "No alert has added to the list" : "No option has added to the list"}</li>
															</>
														}
													</div>
												</div>
											}
											{isArrangingColumn ?
												<div className="components-add-option">
													<C_Button 
														background={"rgb(84 34 219)"}
														width={window.innerWidth > 1500 ? "140px" : "120px"}
														height={"30px"}
														buttonText={"Back"}
														onClick={() => { setIsArrangingColumn(false) }}
														paddingLeft={"10px"}
														paddingRight={"10px"}
														gap={"10px"}
													/>
												</div>
												:
												<div className="components-add-option">
													<C_Button 
														background={"rgb(84 34 219)"}
														width={window.innerWidth > 1500 ? "140px" : "120px"}
														height={"30px"}
														buttonText={"Arrange"}
														onClick={() => { setIsArrangingColumn(true) }}
														paddingLeft={"10px"}
														paddingRight={"10px"}
														gap={"10px"}
														icon={<MdOutlineDragIndicator size={13}/>}
													/>
													<C_Button 
														background={"rgb(84 34 219)"}
														width={window.innerWidth > 1500 ? "140px" : "120px"}
														height={"30px"}
														buttonText={componentType === "Alert" ? "Add Alert" : "Add Option"}
														onClick={() => {addOptions(componentType)}}
														paddingLeft={"10px"}
														paddingRight={"10px"}
														gap={"10px"}
														icon={<FiPlusCircle size={13}/>}
													/>
												</div>
											}
										</div>
										</>
										}
								</div>
								
								<div className="d-flex">
									{componentType !== "Form" && componentType !== "Alert" && componentType !== "Switch" && !isDefaultComponent && 
									<div className="d-flex col" style={{paddingRight:'20px',textAlign:'center',alignItems:'center',alignContent:'center',justifyContent:'start',paddingTop:'15px',position:'relative'}}>
										{!isSetting ?
											<C_IconButton
											onClick={() => {setIsSetting(true)}}
											size={29}
											title={"More Settings"}
											color="#CDCDCD"
											backgroundImage="linear-gradient(to right top, #8165b9, #7c5db9, #7755b9, #724db9, #6d45b9)"
											icon={<HiOutlinePencilAlt color="#FFF" size={15}/>}
											/>
											:
											<C_IconButton
												onClick={() => {setIsSetting(false)}}
												size={29}
												title={"More Settings"}
												color="#CDCDCD"
												backgroundImage="linear-gradient(to right top, #8165b9, #7c5db9, #7755b9, #724db9, #6d45b9)"
												icon={<HiOutlineXMark color="#FFF" size={15}/>}
											/>
										}
										
										
										<span className="mx-1" ></span>
										<span className="font-xxs">More Settings</span>
										{isSetting &&
											<div className={`more-settings-container ${privilege === "View" || privilege === "Hidden" || privilege === "" ? "restricted-more-settings-container" : ""}`}>
												{privilege === "View" || privilege === "Hidden" || privilege === "" ? 
												<span className="privilege-setting-noti">Modifications to settings below are strictly limited to the 'Editable' privilege only.</span>:<></>}
												<div className="settings">
													{privilege === "View" || privilege === "Hidden" || privilege === "" ? 
														<Switch
														checked={false}
														onChange={(e) => {setRequiredInput(false)}}
														onColor="#4BAB00"
														onHandleColor="#FFFFFF"
														handleDiameter={15}
														uncheckedIcon={false}
														checkedIcon={false}
														boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
														activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
														height={15}
														width={35}
														disabled
														/>
													:
													<Switch
													checked={requiredInput}
													onChange={(e) => {setRequiredInput(!requiredInput)}}
													onColor="#4BAB00"
													onHandleColor="#FFFFFF"
													handleDiameter={15}
													uncheckedIcon={false}
													checkedIcon={false}
													boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
													activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
													height={15}
													width={35}
													/>
													}
													
													<span className="mx-1" ></span>
													<span className="font-xxs">Required Input</span>
												</div>

												<div className="settings">
													{(componentType !== "Multiple Choices" && componentType !== "Dropdown" && componentType !== "Checkboxes") &&
													<>
													{
														(privilege === "View" || privilege === "Hidden" || privilege === "") ? 
														<Switch
														checked={true}
														onChange={(e) => {setDuplicateInput(true);}}
														onColor="#4BAB00"
														onHandleColor="#FFFFFF"
														handleDiameter={15}
														uncheckedIcon={false}
														checkedIcon={false}
														boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
														activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
														height={15}
														width={35}
														disabled
														/> 
														:
														<Switch
														checked={duplicateInput}
														onChange={(e) => {setDuplicateInput(!duplicateInput)}}
														onColor="#4BAB00"
														onHandleColor="#FFFFFF"
														handleDiameter={15}
														uncheckedIcon={false}
														checkedIcon={false}
														boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
														activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
														height={15}
														width={35}
														/>
														
													}
														<span className="mx-1" ></span>
														<span className="font-xxs">Duplicate Input</span>
													</>
													}
													{(componentType === "Dropdown") &&
														<div className="settings">
															{
																	(privilege === "View" || privilege === "Hidden" || privilege === "") ? 
																	<Switch
																	checked={true}
																	onChange={(e) => {setToggleOtherOption(true);}}
																	onColor="#4BAB00"
																	onHandleColor="#FFFFFF"
																	handleDiameter={15}
																	uncheckedIcon={false}
																	checkedIcon={false}
																	boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
																	activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
																	height={15}
																	width={35}
																	disabled
																	/> 
																	:
																	<Switch
																	checked={toggleOtherOption}
																	onChange={(e) => {setToggleOtherOption(!toggleOtherOption);}}
																	onColor="#4BAB00"
																	onHandleColor="#FFFFFF"
																	handleDiameter={15}
																	uncheckedIcon={false}
																	checkedIcon={false}
																	boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
																	activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
																	height={15}
																	width={35}
																	/>
																	
																}
															<span className="mx-1" ></span>
															<span className="font-xxs">Others Input</span>
														</div>
														}
													
												</div>
											</div>
											}
									</div>
									}
										
										<div className="new-button-container col" >
											<C_DialogButton
												onClick={()=>{
														setComponentText("");
														setComponentType("");
														setRequiredInput(false);
														setDuplicateInput(true);
														setCurrColID("");
														setIsEditColumn(false);
														setOptions([]);
												}}
												color={isEditColumn ?"#6d45b9":"#3eb986 "}
												background={'white'}
												borderColor={isEditColumn ?"#6d45b9":'#3eb986 '}
												dismissAction={'modal'}
												buttonText={"DISCARD"}
											/>
											<C_DialogButton
												onClick={()=>{
													isEditColumn ? editColumn() : addColumn()
												}}
												color={'white'}
												background={isEditColumn ?"#6d45b9":'#3eb986 '}
												borderColor={isEditColumn ?"#6d45b9":'#3eb986 '}
												buttonText={isEditColumn ? "UPDATE" : "ADD"}
												dismissAction="modal"
											/>
											
										</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
	);
}

export default ClientColumn;
