import React, { useMemo, useState, useEffect, useRef } from "react";
import { v4 as uuid } from "uuid";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { MdInsertChart, MdOutlineSave, MdOutlineClear, MdFilterAlt, MdPublish } from 'react-icons/md';
import { TiArrowBack, TiDelete } from 'react-icons/ti';
import { TbFileExport, TbSortAscending2, TbAxisX, TbAxisY } from 'react-icons/tb';
import Graph_Line from "../graph/Graph.line";
import GraphTypeDropdown from "./GraphTypeDropdown";
import VariableSets from "./VariableSets";
import { AiOutlineCalendar, AiOutlineNumber, AiFillFilePdf, AiFillPicture, AiFillCloseCircle, AiOutlineFileJpg, AiOutlineClose, AiOutlineUser, AiFillEye } from 'react-icons/ai'
import { BiFont } from 'react-icons/bi'
import Graph_Bar from "../graph/Graph.bar";
import Graph_Area from "../graph/Graph.area";
import Graph_Pie from "../graph/Graph.pie";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";
import { getDownloadURL, uploadBytesResumable, ref  } from "firebase/storage";
import { storage } from "../../../firebaseInit";
import ToolsBox from "./ToolsBox";
import FilterComponent from "./FilterComponent";
import SortComponent from "./SortComponent";
import C_Button from "../../../components/C_Button";
import C_Loading from "../../../components/C_Loading";
import { Modal } from "bootstrap";
import jsPDF from 'jspdf';
import download from 'downloadjs'
import C_DialogButton from "../../../components/C_DialogButton";
import C_PopoutNoti from "../../../components/C_PopoutNoti";
import './GraphGenerator.css'
import '.././WorkSpace.scss'
import CommonFunc from "../../../util/CommonFunc";
import * as htmlToImage from 'html-to-image'

//slices
import { getPublishAnalytics, selectAnalyticsContext, selectNewGraphData, selectSingleAnalytics, setAnalyticsContext } from "../../../stores/slices/analyticsSlices";
import { createAnalytics, deleteAnalytics, getGraphData, updateAnalytics } from "../../../stores/slices/analyticsSlices";
import { selectCurrSa, selectCurrTenant } from "../../../stores/slices/persistRolesSlices";
import { selectCompanyID } from "../../../stores/slices/companyIDSlices";
import { selectCurrUserContext } from "../../../stores/slices/companySlices";
import { selectUser } from "../../../stores/slices/userAuthSlices";

function GraphGenerator(props) {

  const { currUsers } = selectCurrUserContext();
  const isSuperAdmin = props.isSuperAdmin;
  const Analytics_Publish = props.Analytics_Publish;

  const screenshotRef = useRef(null);
  const modalRef = useRef();
  const exportModalRef = useRef();
  const viewableModalRef = useRef();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [hoverBack, setHoverBack] = useState(false);
  const [triggerSaved, setTriggerSaved] = useState("");
  const [selectedDateColumn, setSelectedDateColumn] = useState("");
  const [dateRangeValue, setDateRangeValue] = useState("");
  const [availableDateColumn, setAvailableDateColumn] = useState([]);

  const user = selectUser();

  const currSa = selectCurrSa();
  const tenant = selectCurrTenant();
  const companyID = selectCompanyID();
  const newGraphData = selectNewGraphData();
  const singleAnalytics = selectSingleAnalytics();

  const graph = newGraphData.GraphData;

  const { 
    analytics,
    analyticsData,
    graphData,
    graphDetail,
    filterList,
    filterState,
    sortList,
    sortState,
    pieProperty,
    targetMember,
    selectedDateSettings,
    availableHandlerIDs,
  } = selectAnalyticsContext();

  const yAxis = analytics?.yAxis;
  const xAxis = analytics?.xAxis;
  const advanceVariable = analytics?.CustomVariables;

  const [editor, setEditor] = useState(yAxis?.length > 0 && xAxis?.length > 0);
  const [searchParams, setSearchParams] = useSearchParams();
  const params = searchParams.get("params");
  const DataID = searchParams.get("DataID");

  useEffect(() => {
    if(xAxis && yAxis){
      if(xAxis.length > 0 && yAxis.length > 0){
        const combinedAxis = xAxis.concat(yAxis);
        const dateOnlyArr = combinedAxis.filter(data => data.type === "Date");

        if(dateOnlyArr.length > 0){
          const availableDate = dateOnlyArr?.map(data => data.key);
          setAvailableDateColumn(availableDate);
        } else {
          setAvailableDateColumn([]);
        }
      }
    }
  }, [xAxis, yAxis])

  useEffect(()=>{
    //retrieve mergedDataset initially otherwise mergedData is empty
    if(tenant){
      dispatch(getGraphData({ 
        TenantID: tenant, 
        xAxis: analytics?.xAxis, 
        yAxis: analytics?.yAxis, 
        DataID: DataID, Dataset: 
        analyticsData?.Dataset,
        Source: analyticsData?.Source, 
        dateSettings: selectedDateSettings,
        MemberData : currUsers,
      }))
    }
   
  }, [])


  useEffect(()=>{
    if(newGraphData){
      const graphData = newGraphData.GraphData;
      const mergedDataSet = newGraphData.MergedDataSet;
      const availableHandler = newGraphData.availableHandlerIDs;
      if(params !== "NEW"){
        // if(sortedDataMemo !== null && sortedDataMemo?.length > 0){
          
        //   dispatchGraphData({ type : "GET_GRAPH_DATA", payload: sortedDataMemo });
        // } else {
        //   dispatchGraphData({ type : "GET_GRAPH_DATA", payload: graphData })
        // }
        // dispatchMergedDataSet({ type : "GET_MERGED_DATASET", payload: mergedDataSet })
        // dispatchAvailableHandlerIDs({ type : "AVAILABLE_HANDLER", payload: availableHandler })
        
        dispatch(setAnalyticsContext({
            graphData : (sortedDataMemo && sortedDataMemo?.length > 0) 
            ? sortedDataMemo : graphData,
            mergedDataSet : mergedDataSet,
            availableHandlerIDs : availableHandler
          }));
        setIsLoading(false);
      } else if(params === ""){
        navigate("/#/Workspace");
      } else {
        dispatch(setAnalyticsContext({
            graphData : graphData,
            mergedDataSet : mergedDataSet,
            availableHandlerIDs : availableHandler
        }));
        // dispatchGraphData({ type : "GET_GRAPH_DATA", payload: graphData });
        // dispatchMergedDataSet({ type : "GET_MERGED_DATASET", payload: mergedDataSet });
        // dispatchAvailableHandlerIDs({ type : "AVAILABLE_HANDLER", payload: availableHandler });
        setIsLoading(false);
      }
    }
  },[newGraphData])

  useEffect(()=>{
    if((analytics) && analyticsData){
      if(params !== "NEW"){
        dispatch(setAnalyticsContext({
            filterList : analytics.filterList,
            sortList : analytics.sortList,
            dateSettings : analytics.dateSettings
        }));
        // dispatchFilterList({type: "CHANGE_FILTER_LIST", payload: analytics.filterList})
        // dispatchSortList({ type: 'CHANGE_SORT_LIST', payload: analytics.sortList})
        // dispatchDateSettings({ type: 'CHANGE_DATE_SETTINGS', payload: analytics.dateSettings})
        dispatch(getGraphData({
          TenantID: tenant, 
          xAxis: analytics.xAxis, 
          yAxis: analytics.yAxis, 
          DataID: DataID, 
          Dataset: analyticsData?.Dataset, 
          Source: analyticsData?.Source,
          MemberData: currUsers
        }))        
        setIsLoading(true);
      }
    } else {
      navigate("/Analytics")
    }
    
  }, [searchParams, analytics])

  const sortedDataMemo = useMemo(() => {
    let filtered = [];
    let sorted = null;
    // const filterList = analytics.filterList;
    // const sortList = analytics.sortList;

    if(analytics){
      if (analytics?.xAxis.length > 0 && analytics?.yAxis.length > 0 && (filterList?.length > 0 || sortList)) {
        if (analyticsData?.Source === "Form Templates" ) {
          filtered = filterList.length > 0 ? graphData?.filter(item => !filterList.includes(item[analytics.xAxis[0]?.key])) : graph;
          sorted = filtered ? [...filtered].sort((a, b) => {
            if (sortList) {
              if (sortList.type === "ASCENDING") {
                if (typeof a[sortList.target] === "string") {
                  return a[sortList.target]?.localeCompare(b[sortList.target]);
                } else if (typeof a[sortList.target] === "number") {
                  return a[sortList.target] - b[sortList.target];
                }
              } else if (sortList.type === "DESCENDING") {
                if (typeof a[sortList.target] === "string") {
                  return b[sortList.target]?.localeCompare(a[sortList.target]);
                } else if (typeof a[sortList.target] === "number") {
                  return b[sortList.target] - a[sortList.target];
                }
              }
            }
          }) : filtered;
        } else {
          filtered = filterList.length > 0 ? graphData?.filter(item => !filterList.includes(item[analytics.xAxis[0]?.key])) : graph;
          sorted = filtered ? [...filtered].sort((a, b) => {
            if (sortList) {
              if (sortList.type === "ASCENDING") {
                if (typeof a[sortList.target] === "string") {
                  return a[sortList.target]?.localeCompare(b[sortList.target]);
                } else if (typeof a[sortList.target] === "number") {
                  return a[sortList.target] - b[sortList.target];
                }
              } else if (sortList.type === "DESCENDING") {
                if (typeof a[sortList.target] === "string") {
                  return b[sortList.target]?.localeCompare(a[sortList.target]);
                } else if (typeof a[sortList.target] === "number") {
                  return b[sortList.target] - a[sortList.target];
                }
              }
            }
          }) : filtered;
        }
      }
    }
   

    const dateColumn = selectedDateSettings?.dateColumn;
    const dateRange = selectedDateSettings?.dateRange;
    // const isValidSettings = dateColumn !== "" && dateRange !== "";

    return sorted;

  }, [filterList, sortList, selectedDateSettings]);

  // Use useEffect to set the sorted data in your component state
  useEffect(() => {
    if(sortedDataMemo !== null && sortedDataMemo?.length > 0){
        // console.log("hi")
        dispatch(setAnalyticsContext({graphData : sortedDataMemo}));
      // dispatchGraphData({ type: "GET_GRAPH_DATA", payload: sortedDataMemo });
    }

  }, [sortedDataMemo]);

  useEffect(() => {
    setEditor(yAxis?.length > 0 && xAxis?.length > 0)
  },[yAxis, xAxis])

  const setAnalytics = (obj) => {
    dispatch(setAnalyticsContext({analytics : obj}));
    // dispatchAnalytics({ type : "ANALYTICS", payload: obj })
  }

  function G_Column(props) {
    //Y Axis
    if(analyticsData?.Source === "Form Templates"){
      return (
        <Droppable droppableId="G-Column">
          {(provided, snapshot) => (
            <div ref={provided.innerRef} className="valueArea">
              {props.items?.map((item, index) => (
                <div key={item.id} className="value">
                  {item.type === "Date" && <AiOutlineCalendar size={12}/>}
                  {item.type === "Time" && <AiOutlineCalendar size={12}/>}
                  {item.type === "String" && <BiFont size={12}/>}
                  {item.type === "Integer" && <AiOutlineNumber size={12}/>}
                  {item.type === "Float" && <AiOutlineNumber size={12}/>}
                  <span>{item.key}</span>
                  <TiDelete onClick={() => { handleRemoveColAttr(index, item.key) }} className="remove" size={15}/>
                </div>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      );
    }else{
     
      return (
        <Droppable droppableId="G-Column">
          {(provided, snapshot) => {
            let existHandler = false;
            return (
            <div ref={provided.innerRef} className="valueArea">
              {props.items?.map((item, index) => {
                if(item.key === "Handler" && !existHandler){
                  existHandler = true;
                  return (
                    <div key={item.id} className="value">
                      {/* form */}
                      {/* {item.type === "Date" && <AiOutlineCalendar size={12}/>} */}
                      {item.type === "Time" && <AiOutlineCalendar size={12}/>}
                      {item.type === "String" && <BiFont size={12}/>}
                      {item.type === "Integer" && <AiOutlineNumber size={12}/>}
                      {item.type === "Float" && <AiOutlineNumber size={12}/>}
                      {/* client */}
                      {(item.type === "Handler" && <AiOutlineUser size={12} />)}
                      {item.type === "Short Text" && <BiFont size={12}/>}
                      {item.type === "Numbers" && <AiOutlineNumber size={12}/>}
                      {item.type === "Datetime" && <AiOutlineCalendar size={12}/>}
                      {item.type === "Date" && <AiOutlineCalendar size={12}/>}
                      {item.type === "Email" && <BiFont size={12}/>}
                      {item.type === "Phone" && <BiFont size={12}/>}
                      {item.type === "Switch" && <BiFont size={12}/>}
                      {item.type === "Multiple Choices" && <BiFont size={12}/>}
                      {item.type === "Dropdown" && <BiFont size={12}/>}
                      {item.type === "Member" && <AiOutlineUser size={12}/>}
                      <span>{item.key}</span>
                      <TiDelete onClick={() => { handleRemoveColAttr(index, item.key) }} className="remove" size={15}/>
                    </div>
                  );
                } 

                if(item.key !== "Handler"){
                  return (
                    <div key={item.id} className="value">
                      {(item.type === "Handler" && <AiOutlineUser size={12} />)}
                      {item.type === "Integer" && <AiOutlineNumber size={12}/>}
                      {item.type === "Short Text" && <BiFont size={12}/>}
                      {item.type === "Numbers" && <AiOutlineNumber size={12}/>}
                      {item.type === "Datetime" && <AiOutlineCalendar size={12}/>}
                      {item.type === "Date" && <AiOutlineCalendar size={12}/>}
                      {item.type === "Email" && <BiFont size={12}/>}
                      {item.type === "Phone" && <BiFont size={12}/>}
                      {item.type === "Switch" && <BiFont size={12}/>}
                      {item.type === "Multiple Choices" && <BiFont size={12}/>}
                      {item.type === "Dropdown" && <BiFont size={12}/>}
                      {item.type === "Member" && <AiOutlineUser size={12}/>}
                      <span>{item.key}</span>
                      <TiDelete onClick={() => { handleRemoveColAttr(index, item.key) }} className="remove" size={15}/>
                    </div>
                  );
                }
                
              })}
              {provided.placeholder}
            </div>
          )}}
        </Droppable>
      );
    }
  }
  
  function G_Row(props) {
    //X Axis

      return (
        <Droppable droppableId="G-Row">
          {(provided, snapshot) => {
            let existHandler = false;
            return (
            <div ref={provided.innerRef} className="valueArea">
              {props.items?.map((item, index) => {
                if(item.key === "Handler" && !existHandler){
                  existHandler = true;
                  return (
                    <div key={item.id} className="value">
                      {/* form */}
                      {/* {item.type === "Date" && <AiOutlineCalendar size={12}/>} */}
                      {item.type === "Time" && <AiOutlineCalendar size={12}/>}
                      {item.type === "String" && <BiFont size={12}/>}
                      {item.type === "Integer" && <AiOutlineNumber size={12}/>}
                      {item.type === "Float" && <AiOutlineNumber size={12}/>}
                      {/* client */}
                      {(item.type === "Handler" && <AiOutlineUser size={12} />)}
                      {item.type === "Short Text" && <BiFont size={12}/>}
                      {item.type === "Numbers" && <AiOutlineNumber size={12}/>}
                      {item.type === "Datetime" && <AiOutlineCalendar size={12}/>}
                      {item.type === "Date" && <AiOutlineCalendar size={12}/>}
                      {item.type === "Email" && <BiFont size={12}/>}
                      {item.type === "Phone" && <BiFont size={12}/>}
                      {item.type === "Switch" && <BiFont size={12}/>}
                      {item.type === "Multiple Choices" && <BiFont size={12}/>}
                      {item.type === "Dropdown" && <BiFont size={12}/>}
                      {item.type === "Member" && <AiOutlineUser size={12}/>}
                      <span>{item.key}</span>
                      <TiDelete onClick={() => { handleRemoveRowAttr(index, item.key) }} className="remove" size={15}/>
                    </div>
                  );
                }

                if(item.key !== "Handler"){
                  return (
                    <div key={item.id} className="value">
                      {(item.type === "Handler" && <AiOutlineUser size={12} />)}
                      {item.type === "Integer" && <AiOutlineNumber size={12}/>}
                      {item.type === "Short Text" && <BiFont size={12}/>}
                      {item.type === "Numbers" && <AiOutlineNumber size={12}/>}
                      {item.type === "Datetime" && <AiOutlineCalendar size={12}/>}
                      {item.type === "Date" && <AiOutlineCalendar size={12}/>}
                      {item.type === "Email" && <BiFont size={12}/>}
                      {item.type === "Phone" && <BiFont size={12}/>}
                      {item.type === "Switch" && <BiFont size={12}/>}
                      {item.type === "Multiple Choices" && <BiFont size={12}/>}
                      {item.type === "Dropdown" && <BiFont size={12}/>}
                      {item.type === "Member" && <AiOutlineUser size={12}/>}
                      <span>{item.key}</span>
                      <TiDelete onClick={() => { handleRemoveRowAttr(index, item.key) }} className="remove" size={15}/>
                    </div>
                  );
                }
              }
                
              )}
              {provided.placeholder}
            </div>
          )}}
        </Droppable>
      );
  }

  function handleRemoveColAttr(index, key){
    setIsLoading(true);
    setTimeout(()=>{
      let data = {...analytics}
      const newColumns = key === "Handler" ? yAxis.filter(item => item.key !== key) : yAxis.filter((item, pos) => { if(pos !== index) { return item } });
      data = {...data, yAxis: newColumns};
      dispatch(getGraphData({TenantID: tenant, 
        xAxis: data.xAxis, 
        yAxis: data.yAxis, 
        DataID: analyticsData.DataID, 
        Dataset: analyticsData.Dataset, 
        Source: analyticsData.Source,
        MemberData: currUsers
      }))
      // if(newColumns.length > 0){
      //   dispatchYAxisTarget({ type:"CHANGE_Y_AXIS_TARGET", payload: newColumns[0]?.id })
      // }
  
      // dispatchAnalytics({ type : "ANALYTICS", payload: data })

      dispatch(setAnalyticsContext({
        analytics : data,
        yAxisTarget : newColumns.length > 0 ? newColumns[0]?.id : null
      }));
      setIsLoading(false);
    }, 1000)

  }

  function handleRemoveRowAttr(index, key){
    setIsLoading(true);
    setTimeout(()=>{
      let data = {...analytics};
      const newRows = key === "Handler" ? xAxis.filter(item => item.key !== key) : xAxis.filter((item, pos) => { if(pos !== index) { return item } });
      data = {...data, xAxis: newRows};
      dispatch(getGraphData({
        TenantID: tenant, 
        xAxis: data.xAxis, 
        yAxis: data.yAxis, 
        DataID: analyticsData.DataID, 
        Dataset: analyticsData.Dataset,  
        Source: analyticsData.Source,
        MemberData: currUsers
      }))
      // if(newRows.length > 0){
      //   dispatchXAxisTarget({ type:"CHANGE_X_AXIS_TARGET", payload: newRows[0]?.id })
      // }
     
      // dispatchAnalytics({ type : "ANALYTICS", payload: data })
      dispatch(setAnalyticsContext({
        analytics : data,
        xAxisTarget : newRows.length > 0 ? newColumns[0]?.id : null
      }));
      setIsLoading(false);
    }, 1000)
  }

  const copy = (source, destination, droppableSource, droppableDestination) => {

     const item = source[droppableSource.index];
      const id = source[droppableSource.index].id;
  
      if(id === "Handler"){
        availableHandlerIDs && availableHandlerIDs?.forEach(handler => {
          destination.splice(droppableDestination.index, 0, { ...item, 
            id: handler,
            color: "#000000",
            activeDotRadius: 8,
            lineType: 'monotone',
            lineWidth: 1,
            areaLineWidth: 0,
            areaLineColor: "#CDCDCD",
            areaFillOpacity: 0.7,
            barWidth: 30,
            barStack: false,
            areaStack: false,
            paddingLeft: 0,
            paddingRight: 0,
            minTickGap: 5,
            tickSize: 6,
            unit: '',
            angle: 0
          });
        })
      } else {
        destination.splice(droppableDestination.index, 0, { ...item, 
          id: id,
          color: "#000000",
          activeDotRadius: 8,
          lineType: 'monotone',
          lineWidth: 1,
          areaLineWidth: 0,
          areaLineColor: "#CDCDCD",
          areaFillOpacity: 0.7,
          barWidth: 30,
          barStack: false,
          areaStack: false,
          paddingLeft: 0,
          paddingRight: 0,
          minTickGap: 5,
          tickSize: 6,
          unit: '',
          angle: 0
        });
      }
      
      return destination;
    
    
  };

  const copyAdvanced = (source, destination, droppableSource, droppableDestination) => {

      const item = source[droppableSource.index];
      const id = source[droppableSource.index].variableID;
      
      const newItem = {
        id: item?.variableID,
        key: item?.variableName,
        type: item?.variableType,
        targetField: item?.targetField,
        value: item?.value,
        customVariable: true
      }
  
      destination.splice(droppableDestination.index, 0, { ...newItem, 
        id: id,
        color: "#000000",
        activeDotRadius: 8,
        lineType: 'monotone',
        lineWidth: 1,
        areaLineWidth: 0,
        areaLineColor: "#CDCDCD",
        areaFillOpacity: 0.7,
        barWidth: 30,
        barStack: false,
        areaStack: false,
        paddingLeft: 0,
        paddingRight: 0,
        minTickGap: 5,
        tickSize: 6,
        unit: '',
        angle: 0
      });
      return destination;
    
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;
    let data = {...analytics};
    if(!destination) {
      return;
    };

    const filteredDataset = analyticsData.Dataset.filter((item) => 
        item.type !== "Multiline" && 
        item.type !== "Rich Text" && 
        item.type !== "Link" &&
        item.type !== "Google Map" && 
        item.type !== "Checkboxes" &&
        item.type !== "Form");
    switch (source.droppableId) {
      case "DATASETS":
        if(destination.droppableId === "G-Column"){
          data = {
            ...data,
            yAxis:  copy(filteredDataset, yAxis?.map(item => { return item }), source, destination),
          }
          dispatch(setAnalyticsContext({
            yAxisTarget : filteredDataset[source.index].id
          }))
            // dispatchYAxisTarget({ type:"CHANGE_Y_AXIS_TARGET", payload: filteredDataset[source.index].id });
        }else if(destination.droppableId === "G-Row"){
          data = {
            ...data,
            xAxis:  copy(filteredDataset, xAxis?.map(item => { return item }), source, destination),
            sortList: ""
          }
          dispatch(setAnalyticsContext({
            xAxisTarget : filteredDataset[source.index].id
          }))
            // dispatchXAxisTarget({ type:"CHANGE_X_AXIS_TARGET", payload: filteredDataset[source.index].id })
        }
        break;
      case "ADVANCE_DATASETS":
        if(destination.droppableId === "G-Column"){
          data = {
            ...data,
            yAxis:  copyAdvanced(advanceVariable, yAxis?.map(item => { return item }), source, destination),
          }
          dispatch(setAnalyticsContext({
            yAxisTarget : advanceVariable[source.index].variableID
          }))
            // dispatchYAxisTarget({ type:"CHANGE_Y_AXIS_TARGET", payload: advanceVariable[source.index].variableID })

        }else if(destination.droppableId === "G-Row"){
          data = {
            ...data,
            yAxis:  copyAdvanced(advanceVariable, xAxis?.map(item => { return item }), source, destination),
            sortList: ""
          }
          dispatch(setAnalyticsContext({
            xAxisTarget : advanceVariable[source.index].variableID
          }))
            // dispatchXAxisTarget({ type:"CHANGE_X_AXIS_TARGET", payload: advanceVariable[source.index].variableID })
        }
        break;
      default:
        break;
    }

    if(data.yAxis.length > 0 && data.xAxis.length > 0){
      setIsLoading(true);
      setTimeout(()=>{
        dispatch(getGraphData({
          TenantID: tenant, 
          xAxis: data.xAxis, 
          yAxis: data.yAxis, 
          DataID: analyticsData.DataID, 
          Dataset: analyticsData.Dataset, 
          Source: analyticsData.Source,
          MemberData: currUsers
        }))
      }, 1000)
    }

    setAnalytics(data);
  };

  function handleBackDataSource(){
    dispatch(setAnalyticsContext({}));
    navigate("/WorkSpace");
  };

  function dataURLtoFile(dataurl, filename) {

      var arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]), 
          n = bstr.length, 
          u8arr = new Uint8Array(n);
          
      while(n--){
          u8arr[n] = bstr.charCodeAt(n);
      }
      
      return new File([u8arr], filename, {type:mime});
  };

  function notify(){
    setTriggerSaved("SAVED");
    setTimeout(() => {
      setTriggerSaved("");
    }, 1000)
  };

  function handleGraphTitle(val){
    let data = {...analytics};
    data = {...data, GraphTitle: val};
    setAnalytics(data)
    // dispatchGraphDetail({ type:"CHANGE_GRAPH_DETAILS", payload: {...graphDetail, GraphTitle: val}})

  };

  async function handleSavePublish(){
    let data = {...analytics};
    data = {...data, Publish: !data.Publish};
    setAnalytics(data)
    // await dispatchGraphDetail({ type:"CHANGE_GRAPH_DETAILS", payload: {...graphDetail, Publish: !graphDetail?.Publish}})

  }

  const handleDeleteAnalytics = async () => {

    if(analytics.AnalyticsID){
      setIsLoading(true)

      dispatch(deleteAnalytics({TenantID:tenant,AnalyticsID:analytics?.AnalyticsID}))
        setIsLoading(false);
        dispatch(getPublishAnalytics({TenantID:tenant,CompanyID:companyID, MemberData: currUsers}));
        handleBackDataSource();
    }

  }

  const saveAnalytics = async () => {

    setIsLoading(true);

    const dataUrl = await htmlToImage.toPng(screenshotRef.current);

    var file = dataURLtoFile(dataUrl, 'file')

    var UID = uuid();
    console.timeLog(UID)
    var extend = params !== "NEW" ? analytics.AnalyticsID : UID;
    const storageRef = ref(storage, "/analytics/cover/" + extend);
    const uploadTask = uploadBytesResumable(storageRef, file);

    if(params === "NEW"){
      uploadTask.on('state_changed',
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
      },
      (error) => {
        // Handle unsuccessful uploads
      },  () => {
        
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          
        let body = {
          AnalyticsID: UID,
          CompanyID:companyID,
          UserID: user.uid,
          DataID: analyticsData.DataID,
          Publish: analytics?.Publish,
          Cover: downloadURL,
          GraphTitle: analytics.GraphTitle !== "" ? analytics.GraphTitle : moment().format("DD-MM-YYYY"),
          GraphType: analytics.GraphType === "" ? "Line" : analytics.GraphType,
          yAxis: yAxis.length > 0 ? JSON.stringify(yAxis) : JSON.stringify([]),
          xAxis: xAxis.length > 0 ? JSON.stringify(xAxis) : JSON.stringify([]),
          PieProperty: JSON.stringify(analytics.PieProperty),
          Unit: analytics.Unit !== "" ? analytics.Unit : "",
          Label: analytics.Label !== "" ? analytics.Label : "",
          filterList: JSON.stringify(filterList),
          sortList: sortList ? JSON.stringify(sortList) : "",
          // GraphData: graphData.length > 0 ? JSON.stringify(graphData) : JSON.stringify([]),
          CreatedAt: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
          DataSource: analyticsData.Source,
          CustomVariables: JSON.stringify(advanceVariable),
          ViewableBy: JSON.stringify(targetMember),
          dateSettings: selectedDateSettings ? JSON.stringify(selectedDateSettings) : JSON.stringify({dateColumn: "", dateRange: ""}),
        }
    
        dispatch(createAnalytics({TenantID:tenant,data:body}))
          setTimeout(()=>{
            notify();
            dispatch(getPublishAnalytics({TenantID:tenant, CompanyID:companyID, MemberData: currUsers}));
            setIsLoading(false);
          }, 500)
        })
        .catch(err => {
          console.log(err)
        })

      })
    } else {
      uploadTask.on('state_changed',
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
      },
      (error) => {
        // Handle unsuccessful uploads
      },  () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          
          let body = {
            AnalyticsID: analytics.AnalyticsID,
            UserID: analytics.uid,
            DataID: analyticsData.DataID,
            Publish: analytics.Publish,
            Cover: downloadURL,
            GraphTitle: analytics.GraphTitle !== "" ? analytics.GraphTitle : moment().format("DD-MM-YYYY"),
            GraphType: analytics.GraphType,
            yAxis: yAxis.length > 0 ? JSON.stringify(yAxis) : JSON.stringify([]),
            xAxis: xAxis.length > 0 ? JSON.stringify(xAxis) : JSON.stringify([]),
            PieProperty: JSON.stringify(pieProperty),
            Unit: analytics.Unit !== "" ? analytics.Unit : "",
            Label: analytics.Label !== "" ? analytics.Label : "",
            filterList: JSON.stringify(filterList),
            sortList: sortList ? JSON.stringify(sortList) : "",
            // GraphData: graphData.length > 0 ? JSON.stringify(graphData) : JSON.stringify([]),
            CreatedAt: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            DataSource: analytics.DataSource,
            CustomVariables: JSON.stringify(advanceVariable),
            ViewableBy: JSON.stringify(targetMember)
          }
      
          dispatch(updateAnalytics({TenantID:tenant,data:body}))
          setTimeout(()=>{
            notify();
            dispatch(getPublishAnalytics({TenantID:tenant, CompanyID:companyID, MemberData: currUsers}));
            setIsLoading(false);
          }, 500)
        })
        .catch(err => {

        })
      })
    }
  };

  function handleOpenFilter(){
    dispatch(setAnalyticsContext({filterState : !filterState}));
    // dispatchFilterState({type: "CHANGE_FILTER_STATE"  ,payload: !filterState})
  }

  function handleOpenSort(){
    dispatch(setAnalyticsContext({sortState : !sortState}));
    // dispatchSortState({type: "CHANGE_SORT_STATE"  ,payload: !sortState})
  }

  const showModalFunc = () => {
    const modalEle = modalRef.current;
    const bsModal = new Modal(modalEle, {
      backdrop: "static",
      keyboard: false,
    });
    bsModal.show();
  };

  const hideModalFunc = () => {
    const modalEle = modalRef.current;
    const bsModal = Modal.getInstance(modalEle);
    bsModal.hide();
  };

  const showExportModalFunc = () => {
    const modalEle = exportModalRef.current;
    const bsModal = new Modal(modalEle, {
      backdrop: "static",
      keyboard: false,
    });
    bsModal.show();
  };

  const hideExportModalFunc = () => {
    const modalEle = exportModalRef.current;
    const bsModal = Modal.getInstance(modalEle);
    bsModal.hide();
  };

  const showViewableModalFunc = () => {
    const modalEle = viewableModalRef.current;
    const bsModal = new Modal(modalEle, {
      backdrop: "static",
      keyboard: false,
    });
    bsModal.show();
  };

  const hideViewableModalFunc = () => {
    const modalEle = viewableModalRef.current;
    const bsModal = Modal.getInstance(modalEle);
    bsModal.hide();
  };

  const generatePDF = async () => {
    const dataUrl = await htmlToImage.toPng(screenshotRef.current);
    var image = new Image();
    image.src = dataUrl
    var doc = new jsPDF('p', 'px', 'letter'); //orientation, unit, format
    var width = doc.internal.pageSize.getWidth();
    var height = doc.internal.pageSize.getHeight() * 0.6;

    doc.addImage(image, 'PNG', 0, 0, width, height); //base64 image, format, x-coordinate, y-coordinate, width, height

    doc.save(`${analytics?.GraphTitle !== "" ? analytics?.GraphTitle : moment().format("DDMMYYYYHHMMS")}.pdf`);
  }

  const generatePNG = async () => {
    await htmlToImage.toPng(screenshotRef.current, { quality: 0.95, backgroundColor: '#FFFFFF'})
      .then(function (dataUrl) {
        download(dataUrl, `${analytics?.GraphTitle !== "" ? analytics?.GraphTitle : moment().format("DDMMYYYYHHMMS")}.png`);
      });
  }

  const generateJPEG = async () => {
    await htmlToImage.toPng(screenshotRef.current, { quality: 0.95, backgroundColor: '#FFFFFF'})
      .then(function (dataUrl) {
        download(dataUrl, `${analytics?.GraphTitle !== "" ? analytics?.GraphTitle : moment().format("DDMMYYYYHHMMS")}.jpeg`);
      });
  }
  
  function handleSelectMember(param){
    const body = {
      uid: param.uid,
      displayName: param.displayName
    }
    const newSelectedArray = [...targetMember, body];
    dispatch(setAnalyticsContext({targetMember : newSelectedArray}));
    // dispatchTargetMember({ type:"CHANGE_TARGET", payload: newSelectedArray })
  }

  function handleDeselectMember(param){
    const newSelectedArray = targetMember.filter(item => item?.uid !== param?.uid);
    dispatch(setAnalyticsContext({targetMember : newSelectedArray}));
    // dispatchTargetMember({ type:"CHANGE_TARGET", payload: newSelectedArray })
  }

  return (
    <div className={`workspace`}>
      <div className="" style={{ width: "100%", height: "100vh", overflow: "hidden" }}>
        <div className="generator">
            <C_Loading isLoading={isLoading}/>
            <FilterComponent setAnalytics={setAnalytics}/>
            <SortComponent setAnalytics={setAnalytics}/>

              <div className="modal fade" tabIndex="-1" ref={modalRef} style={{}}>
                    <div className="modal-dialog modal-dialog-centered" style={{}}>
                        <div className="modal-content px-3" style={{overflowY: "scroll" }}>
                            <div className="modal-header modalHeader">
                                <div className="title">
                                    <span className="font-s">Delete Analytics Report</span>
                                </div>
                                <AiOutlineClose cursor='pointer' size={16} data-bs-dismiss="modal"/>
                            </div>
                            
                            <div className="modal-body">
                                <span className="font-xs">
                                  By deleting this analytics report, this action cannot be undone. Are you sure you want to proceed?
                                </span>
                              
                                
                                <div className="new-button-container" >
                                  <C_DialogButton
                                    color={'#ca4254'}
                                    background={'white'}
                                    borderColor={'#ca4254'}
                                    buttonText={"DISCARD"}
                                    dismissAction="modal"
                                  />
                                  <C_DialogButton
                                    onClick={()=>{handleDeleteAnalytics() }}
                                    color={"white"}
                                    background={'#ca4254'}
                                    borderColor={'#ca4254'}
                                    buttonText={"DELETE"}
                                    dismissAction="modal"
                                  />
                                </div>
                            </div>
                        </div>
                    </div>
              </div>

              <div className="modal fade" tabIndex="-1" ref={viewableModalRef} style={{}}>
                    <div className="modal-dialog modal-dialog-centered" style={{}}>
                        <div className="modal-content px-3" style={{overflowY: "scroll" }}>
                            <div className="modal-header modalHeader">
                                <div className="title">
                                    <AiFillEye size={20}/>
                                    <span className="font-s">Viewable By</span>
                                </div>
                                <AiOutlineClose cursor='pointer' size={16} data-bs-dismiss="modal"/>
                            </div>
                            
                            <div className="modal-body">
                                <div className="viewableMemberListContainer">
                                  <div className="selectedMemberList">
                                    {Array.isArray(targetMember) && targetMember && targetMember.length > 0 ?
                                      targetMember.map(item => {
                                        return(
                                          <div className="selectedMember">
                                            <span>{item?.displayName}</span>
                                            <div className='deselect' onClick={() => { handleDeselectMember(item) }}>
                                                <AiFillCloseCircle size={16}/>
                                            </div>
                                          </div>
                                        )
                                      })
                                      :
                                      <span>0 Selected Member</span>
                                    }
                                  </div>
                                  <div className="availableMemberListContainer">
                                    {currUsers && currUsers.filter(item => {
                                      if(item.uid !== user.uid && item?.uid !== currSa){ 
                                        return item
                                      }
                                      }).map((item, index) => {
                                        const selected = targetMember?.some(member => member?.uid === item?.uid);
                                        return(
                                          <div key={index} className="availableMember">
                                            <label style={{ display: 'flex', alignItems: 'center' }}>
                                              <input
                                                className=""
                                                type="checkbox"
                                                onChange={(e) => {
                                                  if(selected){
                                                    handleDeselectMember(item);
                                                  }else{
                                                    handleSelectMember(item);
                                                  }
                                                }}
                                                value={selected}
                                                checked={selected}
                                              />
                                              <span className="checkmark"></span>
                                            </label>
                                            <span>{item?.displayName}</span>
                                          </div>
                                        )
                                    })}
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
              </div>

            <div
              className="modal fade"
              id="export-member"
              tabIndex="-1"
              ref={exportModalRef}
            >
              <div className="modal-dialog modal-dialog-centered" style={{ width: "15%" }}>
                <div className="modal-content">
                  <div className="modal-body d-flex flex-column" style={{}}>
                    <div className="exportHeader">
                      <span>EXPORT</span>
                      <AiFillCloseCircle className="close" size={16} onClick={() => { hideExportModalFunc() }}/>
                    </div>
                    <div className="exportOptions">
                      <C_Button
                        width={"80%"}
                        buttonText={"PDF"}
                        justify={"space-evenly"}
                        onClick={() => { generatePDF() }}
                        icon={<AiFillFilePdf size={16} />}
                        textColor={"#000000"}
                        marginLeft={"0.5rem"}
                        dismissAction="modal"
                      />
                      <C_Button
                        width={"80%"}
                        buttonText={"PNG"}
                        justify={"space-evenly"}
                        onClick={() => { generatePNG() }}
                        icon={<AiFillPicture size={16} />}
                        textColor={"#000000"}
                        marginLeft={"0.5rem"}
                        dismissAction="modal"
                      />
                      <C_Button
                        width={"80%"}
                        buttonText={"JPG"}
                        justify={"space-evenly"}
                        onClick={() => { generateJPEG() }}
                        icon={<AiOutlineFileJpg size={16} />}
                        textColor={"#000000"}
                        marginLeft={"0.5rem"}
                        dismissAction="modal"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <C_PopoutNoti triggerSaved={triggerSaved}/>
            
            <div className="headerMenu">
              <div className="toolsContainer">
                  <div className="backAnalytics" onClick={() => handleBackDataSource()} onMouseEnter={() => setHoverBack(true)} onMouseLeave={() => setHoverBack(false)}>
                      {!hoverBack && <MdInsertChart className="backIcon"/>}
                      {hoverBack && <TiArrowBack className="backIcon"/>}
                  </div>
                  <div className="save">
                      <C_Button
                        background={"#3eb986"}
                        width={"120px"}
                        gap={"10px"}
                        buttonText={"Save"}
                        justify={"space-evenly"}
                        icon={<MdOutlineSave size={16} />}
                        onClick={() => {
                          saveAnalytics()
                        }}
                        textColor={"#FFFFFF"}
                      />
                      <C_Button
                        background={"#4287f5"}
                        width={"120px"}
                        buttonText={"Export"}
                        justify={"space-evenly"}
                        gap={"10px"}
                        icon={<TbFileExport size={16} />}
                        onClick={() => {
                          showExportModalFunc()
                        }}
                        textColor={"#FFFFFF"}
                      />
                  </div>
                  {analytics?.AnalyticsID &&
                  <div className="export">
                      <C_Button
                        background={"#ca4254"}
                        width={"120px"}
                        buttonText={"Delete"}
                        justify={"space-evenly"}
                        gap={"10px"}
                        icon={<MdOutlineClear size={16} />}
                        onClick={() => {
                          showModalFunc()
                        }}
                        textColor={"#FFFFFF"}
                      />
                  </div>
                  }
                  <div className="graphType">
                      <GraphTypeDropdown className="graphTypeDropdown" setAnalytics={setAnalytics}/>
                  </div>
                  <div className={`sort ${sortState && 'sort-open'}`} onClick={() => { handleOpenSort() }}>
                      <TbSortAscending2 className="filterIcon"/>
                      <span>Sort</span>
                  </div>
                  <div className={`filter ${filterState && 'filter-open'}`} onClick={() => { handleOpenFilter() }}>
                      <MdFilterAlt className="filterIcon"/>
                      <span>Filter</span>
                  </div>

                  {(isSuperAdmin || Analytics_Publish) &&
                  <div className="publish">
                    <C_Button
                      background={analytics?.Publish ? "#ca4254" : "#4287f5"}
                      width={"120px"}
                      gap={"10px"}
                      buttonText={analytics?.Publish ? "Unpublish" : "Publish"}
                      justify={"center"}
                      icon={<MdPublish size={16} />}
                      onClick={() => {
                        handleSavePublish()
                      }}
                      textColor={"#FFFFFF"}
                    />
                  </div>
                  }

                  <div className="viewBy">
                    <C_Button
                      background={"#FFF"}
                      border={"1px solid #000"}
                      width={"150px"}
                      gap={"10px"}
                      buttonText={"Viewable By"}
                      justify={"center"}
                      icon={<AiFillEye size={16} />}
                      onClick={() => {
                        showViewableModalFunc();
                      }}
                      color={"#000"}
                    />
                  </div>

              </div>
              <div className="profile">
                  <span>Welcome, {user?.displayName}</span>
                  <img src={user?.photoURL} alt=""/>
              </div>
            </div>
            <div className="contentView">
                <DragDropContext onDragEnd={(result) => { onDragEnd(result) }}>
                {(analyticsData && !isLoading) && <VariableSets isLoading={isLoading} setAnalytics={setAnalytics}/>}
                <div className="infoContainer">
                    <div className="graphingTools">
                      <div className="row-column">
                        <div className="boxes">
                          <div className="axisContainer">
                              <TbAxisY className="axis"/>
                              <span>Columns</span>
                          </div>
                          <G_Column items={analytics?.yAxis} />
                        </div>
                        <div className="boxes">
                          <div className="axisContainer">
                              <TbAxisX className="axis"/>
                              <span>Rows</span>
                          </div>
                          <G_Row items={analytics?.xAxis} />
                        </div>
                      </div>
                    </div>

                    <div className="info" ref={screenshotRef}>
                      <div className="inputData">
                        <input
                          placeholder="GRAPH TITLE"
                          onChange={(e) => { 
                            handleGraphTitle(e.target.value)
                          }}
                          value={graphDetail?.GraphTitle}
                        />
                      </div>
                        {(analytics?.GraphType === "Line" && graphData) && <Graph_Line editorState={editor}/>}
                        {(analytics?.GraphType === "Bar" && graphData) && <Graph_Bar editorState={editor}/>}
                        {(analytics?.GraphType === "Area" && graphData) && <Graph_Area editorState={editor}/>}
                        {(analytics?.GraphType === "Pie" && graphData) && <Graph_Pie editorState={editor}/>}
                    </div>
                </div>

                <div className={`toolBoxContainer ${ editor && 'toolBoxContainer-active'}`}>
                  <ToolsBox 
                    setAnalytics={setAnalytics}
                    dateRangeValue={dateRangeValue} 
                    setDateRangeValue={setDateRangeValue}
                    availableDateColumn={availableDateColumn}
                    selectedDateColumn={selectedDateColumn}
                    setSelectedDateColumn={setSelectedDateColumn}
                  />
                </div>

              </DragDropContext>
            </div>
        </div>
      </div>
    </div>
  );
}

export default GraphGenerator;
