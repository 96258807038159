import { Route } from "react-router-dom"
import { crmRoutes } from "../enums/Route"
import ProtectedRoute from "./ProtectedRoute"
import { Fragment } from "react"
import Clients from "../containers/clients/Clients"

export const ClientsRoute = ({currSaState, currRolesState}) => (
    <Fragment>
        <Route 
            path={crmRoutes.client.clients}
            element={
                <ProtectedRoute>
                    <Clients 
                        isSuperAdmin={currSaState} 
                        Client_ManageGroup={currRolesState !== undefined ? currRolesState?.Client_ManageGroup : false} 
                        Client_Manage={currRolesState !== undefined ? currRolesState?.Client_Manage : false} 
                        Client_Export={currRolesState !== undefined ? currRolesState?.Client_Export : false} 
                        Client_Delete={currRolesState !== undefined ? currRolesState?.Client_Delete : false}
                    />
                </ProtectedRoute>
            }
        />
    </Fragment> 
)

