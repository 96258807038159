import { createSlice, current } from "@reduxjs/toolkit"

import { useAppSelector } from "../../hooks/storeHooks";


//initial state
const initialState = {

    //loading
    createAppointmentLoading : false,
    retrieveAppointmentLoading : false,
    updateAppointmentLoading : false,
    deleteAppointmentLoading : false,
    selectEventLoading : false,
    setAppointmentContextLoading : false,

    //error
    createAppointmentError : undefined,
    retrieveAppointmentError : undefined,
    updateAppointmentError : undefined,
    deleteAppointmentError : undefined,
    selectEventError : undefined, 
    setAppointmentContextError : undefined,

    //data
    selectedEvent : {},
    appointments : [],

    context : {
      tempCurrAppointment : [],
      allAppointment : [],
      currAppointment : {
        title: '',
        description: '',
        start: '',
        end: '',
        organizer: '',
        memberAttendees: [],
        clientAttendees: [],
        AppointmentVenue: '',
        AppointmentColor: '#180b6d',
        AppointmentStatus: ''
      },
      triggerAction : "",
      edit : false,
      client_selector : [],
      client_selector_count : [],
      availableClientsGroup : [],
      selectedGroupValue : null,
      clientSelectedList : [],
      //modalRef
      //editRef
    },

    defaultContext : {
      tempCurrAppointment : [],
      allAppointment : [],
      currAppointment : {
        title: '',
        description: '',
        start: '',
        end: '',
        organizer: '',
        memberAttendees: [],
        clientAttendees: [],
        AppointmentVenue: '',
        AppointmentColor: '#180b6d',
        AppointmentStatus: ''
      },
      triggerAction : "",
      edit : false,
      client_selector : [],
      client_selector_count : [],
      availableClientsGroup : [],
      selectedGroupValue : null,
      clientSelectedList : [],
      //modalRef
      //editRef
    }

}

//slices
const appointmentsSlice = createSlice({
    name: "appointments",
    initialState,
    reducers : {
        createAppointment(state){
            state.createAppointmentLoading = true;
        },
        createAppointmentSuccess(state, {payload}){
            const currentState = payload;
            state.appointments = currentState;

            state.createAppointmentLoading = false;
        },
        createAppointmentError(state, {payload}){
            const errorState = payload;
            state.createAppointmentError = errorState;

            state.createAppointmentLoading = false;
        },

        updateAppointment(state){
            state.updateAppointmentLoading = true;
        },
        updateAppointmentSuccess(state, {payload}){
            const currentState = current(state.appointments);
            const updatedState = currentState.map((item) => {
                if (item.AppointmentID === payload.AppointmentID) {
                    return {
                        ...item,
                        ...payload,
                    };
                }
                return item;
            });
            state.appointments = updatedState;

            state.updateAppointmentLoading = false;
        },
        updateAppointmentError(state, {payload}){
            const errorState = payload;
            state.updateAppointmentError = errorState;

            state.updateAppointmentLoading = false;
        },

        deleteAppointment(state){
            state.deleteAppointmentLoading = true;
        },
        deleteAppointmentSuccess(state, {payload}){
            const currentState = current(state.appointments);
            const updatedState = currentState.filter((item) => item.AppointmentID !== payload.AppointmentID);
            state.appointments = updatedState;

            state.deleteAppointmentLoading = false;
        },
        deleteAppointmentError(state, {payload}){
            const errorState = payload;
            state.deleteAppointmentError = errorState;

            state.deleteAppointmentLoading = false;
        },

        retrieveAppointment(state){
            state.retrieveAppointmentLoading = true;
        },
        retrieveAppointmentSuccess(state, {payload}){
            const currentState = payload;
            state.appointments = currentState;

            state.retrieveAppointmentLoading = false;
        },
        retrieveAppointmentError(state, {payload}){
            const errorState = payload;
            state.retrieveAppointmentError = errorState;

            state.retrieveAppointmentLoading = false;
        },

        selectEvent(state){
            state.selectEventLoading = true;
        },
        selectEventSuccess(state, {payload}){
            const currentState = payload;
            state.selectedEvent = currentState;

            state.selectEventLoading = false;
        },
        selectEventError(state, {payload}){
            const errorState = payload;
            state.selectEventError = errorState;

            state.selectEventLoading = false;
        },
        setAppointmentContext(state){
          state.setAnalyticsContextLoading = true;
        },
        setAppointmentContextSuccess(state, {payload}){
        
          const currentState = current(state.context);
            //loop
            //value => []
            //...payload
            if(Object.values(payload).length > 0){
              const updatedState = {
                ...currentState,
                ...payload,
              }
              state.context = updatedState;
              
            } else {
              state.context = state.defaultContext;
            }
           
          state.setAnalyticsContextLoading = false;
 
        },
        setAppointmentContextError(state){
          state.setAnalyticsContextLoading = false;
        }
    }
})

//export function
export const {
    createAppointment,
    createAppointmentSuccess,
    createAppointmentError,
    updateAppointment,
    updateAppointmentSuccess,
    updateAppointmentError,
    deleteAppointment,
    deleteAppointmentSuccess,
    deleteAppointmentError,
    retrieveAppointment,
    retrieveAppointmentSuccess,
    retrieveAppointmentError,
    selectEvent,
    selectEventSuccess,
    selectEventError,
    setAppointmentContext,
    setAppointmentContextError,
    setAppointmentContextSuccess
} = appointmentsSlice.actions

//export selector

// data
export const selectAppointments = () =>
  useAppSelector((state) => state.appointments.appointments);

export const selectSelectedEvent = () =>
  useAppSelector((state) => state.appointments.selectedEvent);

export const selectAppointmentContext = () =>
  useAppSelector((state) => state.appointments.context);

// loading
export const selectCreateAppointmentLoading = () =>
  useAppSelector((state) => state.appointments.createAppointmentLoading);

export const selectRetrieveAppointmentLoading = () =>
  useAppSelector((state) => state.appointments.retrieveAppointmentLoading);

export const selectUpdateAppointmentLoading = () =>
  useAppSelector((state) => state.appointments.updateAppointmentLoading);

export const selectDeleteAppointmentLoading = () =>
  useAppSelector((state) => state.appointments.deleteAppointmentLoading);

export const selectSelectEventLoading = () =>
  useAppSelector((state) => state.appointments.selectEventLoading);

export const selectSetAppointmentContextLoading = () =>
  useAppSelector((state) => state.appointments.setAppointmentContextLoading);

// error
export const selectCreateAppointmentError = () =>
  useAppSelector((state) => state.appointments.createAppointmentError);

export const selectRetrieveAppointmentError = () =>
  useAppSelector((state) => state.appointments.retrieveAppointmentError);

export const selectUpdateAppointmentError = () =>
  useAppSelector((state) => state.appointments.updateAppointmentError);

export const selectDeleteAppointmentError = () =>
  useAppSelector((state) => state.appointments.deleteAppointmentError);

export const selectSelectEventError = () =>
  useAppSelector((state) => state.appointments.selectEventError);

export const selectSetAppointmentContextError = () =>
  useAppSelector((state) => state.appointments.setAppointmentContextError);

//export reducer
const appointmentReducer = appointmentsSlice.reducer;

export default appointmentReducer;
