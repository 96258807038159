import React, {
    useState,
    useEffect,
    useRef,
} from "react";
import './GaPortal.scss'
import GaSidebar from "./GaSidebar";
import GaDashboard from "./GaDashboard";
import GaTenantList from "./GaTenantList";
import GaMemberList from "./GaMemberList";
import { updateProfile } from "firebase/auth";
import { getFromLocalStorage } from "../../util/LocalStorage";
import { useNavigate } from "react-router-dom";
import { selectGaPortalContext, setGaPortalContext } from "../../stores/slices/gaPortalSlices";
import { useDispatch } from "react-redux";
import { selectTenantsCount } from "../../stores/slices/tenantSlices";
import { child, off, onValue, ref } from "firebase/database";
import { db } from "../../firebaseInit";
import { selectUser } from "../../stores/slices/userAuthSlices";

function GaPortal() {

  const { gaCurrPage } = selectGaPortalContext();
  const divRef = useRef();
  const [divWidth,setDivWidth] = useState(0);
  const user = selectUser();
  const IS_GA = getFromLocalStorage("IS_GA");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dbRef = ref(db);

  const tenantsCount = selectTenantsCount();

//   useEffect(()=>{
//     if(IS_GA === false || IS_GA === "false"){
//         navigate("/Tenant");
//     }
// }, [IS_GA])
  
  function manualUpdateProfile(){
    updateProfile(user, {
      displayName: "Super Admin", photoURL: "https://firebasestorage.googleapis.com/v0/b/zumax-crm.appspot.com/o/DefaultProfile%2Fdefault-profile.webp?alt=media&token=1e765b3f-1a0b-4bc0-97c1-6a5a6cef7b0f"
    }).then(() => {
      // Profile updated!
      // ...
    }).catch((error) => {
      // An error occurred
      //
    });
  }

  // manualUpdateProfile();

  useEffect(() => {
    if (divRef.current) {
      const divWidth = divRef.current.offsetWidth;
      setDivWidth(divWidth)
    }
  }, []);

  useEffect(()=>{
    if(tenantsCount){
      dispatch(setGaPortalContext({currTotalTenants : tenantsCount}));
    }
  }, [tenantsCount])

  useEffect(() => {

      onValue(dbRef, (snapshot) => {
          const data = snapshot.val()["users"];
          if(data !== undefined){
              const userObj = data;
              const userArr = Object.keys(userObj) 
              .map((key) => ({ ...userObj[key] }));
              const filteredUserArr = userArr.filter(user => user.uid !== undefined && user.uid !== '');
              const adminList = filteredUserArr.filter(user => user.isGa !== undefined && user.isGa === true).sort((a,b)=>a?.createdAt?.localeCompare(b?.createdAt));
              const clientList = filteredUserArr.filter(user => !user.email.includes('@zumaxdigital.com')).sort((a,b)=>a?.createdAt?.localeCompare(b?.createdAt));
              const userList = filteredUserArr.filter(user => user.email.includes('@zumaxdigital.com') && (user.isGa === false || user.isGa === undefined)).sort((a,b)=>a?.createdAt?.localeCompare(b?.createdAt));
              dispatch(setGaPortalContext({
                currAllUserList : userList,
                currAdminList : adminList,
                currClientList : clientList
              }))

              // setCurrAllUserList(filteredUserArr);
          }
      });
        
  },[])

  useEffect(()=>{
      const planRequestRef = (child(dbRef,`planRequest`))
      //monitoring realtime db changes then return latest value after any action
      onValue(planRequestRef, (snapshot) => {
          if (snapshot.exists()) {
            const planObj = snapshot.val();
            const companyArray = Object.values(planObj) 
              .reduce((acc, user) => {
                  Object.values(user).forEach(company => {
                  acc.push(company);
                  });
                  return acc;
              }, []);
              dispatch(setGaPortalContext({currTenantList: companyArray}));
              // setCurrTenantList({type: "CURR_TENANT_LIST",payload:companyArray});
          }
        });

      
        // Clean up listener
        return () => off(planRequestRef);
  },[])

  return (
    <div className='gaPortalMainContainer' >
        <GaSidebar/>
        <div className="contentContainer" ref={divRef}>
          {gaCurrPage === "DASHBOARD" && <GaDashboard TOTAL_WIDTH={divWidth}/>}
          {gaCurrPage === "TENANTLIST" && <GaTenantList TOTAL_WIDTH={divWidth}/>}
          {gaCurrPage === "MEMBERLIST" && <GaMemberList TOTAL_WIDTH={divWidth}/>}
        </div>
    </div>
  )
}

export default GaPortal;