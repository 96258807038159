import React, { useState, useEffect } from "react";
import {
  Button,
  CircularProgress,
  Stack,
  Typography,
  Box,
  IconButton,
} from "@mui/material";

import { useNavigation, useSearchParams } from "react-router-dom";
import { useFieldArray, useForm, useWatch } from "react-hook-form";

import moment from "moment";
// import { TransferItemsTable } from "./Component/TransferItemsTable";
import { MainContentContainer, NewFormLayout } from "../AccContainers/Layout";
import { transactionIsPurchases, transferTransactionList } from "../../enums/Transaction";
import useSnack from "../../hooks/useSnack";
import { clearTransactionState, createTransaction, retrieveTransaction, retrieveTransactionOptions, selectCreateTransactionLoading, selectCurrentTransaction, selectRetrieveTransactionLoading, selectTransactionCustomFields, selectTransactionsOptions, selectTransactionsOptionsLoading } from "../../stores/slices/transactionSlices";
import { selectDialogBox } from "../../stores/slices/toggleSlice";
import { transactionActiveTax } from "../../enums/CompanySetting";
import { useAppDispatch } from "../../hooks/storeHooks";
import { FormInputToggleButton } from "../../components/FormInput";
import { MainForm } from "./MainForm";
import { ConfirmationDialog } from "../../components/Utils";
import { AddRounded } from "@mui/icons-material";
import { DataTable } from "../../components/DataTableRenderer/DataTable";
import { TableContent } from "../../constants/Invoicing/TableContent";
import { TABLE } from "../../constants/Invoicing/Table";
import { SnackType } from "../../enums/Snacks";
import { globalAccCompanyID } from "../../http-common";

export const NewTransaction = (props) => {

  const [searchParams, setSearchParams] = useSearchParams();

  const category = props.category || searchParams.get("category");
  const subCategory = props.subCategory || searchParams.get("sub_category");
  // const category = props.category;
  // const subCategory = props.subCategory;
  // const category = searchParams.get("category");
  // const subCategory = searchParams.get("sub_category");
  const removeSearchParams = props.removeSearchParams;
  const todayDate = new Date();
//   const { subCategory, category } = useNavigation();
  const transferTransactionTypeList =
    transferTransactionList[`${category}/${subCategory}`];
  const defaultData = {
    expenses_type:
      subCategory === "invoices"
        ? "CREDIT_SALES"
        : subCategory === "bills"
        ? "CREDIT_PURCHASE"
        : null,
    billing_attention: "",
    billing_party: "",
    shipping_info: "",
    shipping_attention: "",
    shipping_party: "",
    number_format_id: null,
    reference_no: "",
    date: moment(todayDate),
    due_date: null,
    system_currency_code: "",
    tag_ids: [],
    form_items: [],
    form_items_total: 0,
    tax_inclusive: false,
    title: "",
    remarks: "",
    descriptions: "",
    internal_note: "",
    attachment_id: null,
    status: "READY",
    TransferredFormItems: [],
  };
  const {
    control,
    watch,
    handleSubmit,
    formState: { isDirty },
    resetField,
    setValue,
    reset,
    getValues,
  } = useForm({
    defaultValues: defaultData,
    shouldFocusError: true,
    shouldUnregister: false,
  });

  const { append: transferAppend, remove: transferRemove } = useFieldArray({
    control,
    name: "TransferredFormItems",
  });

  const transferTransactionsWatcher = useWatch({
    control,
    name: "due_date",
  });

  const { callSnack } = useSnack();

  const dispatch = useAppDispatch();
  const customFields = selectTransactionCustomFields();
  const transactionOption = selectTransactionsOptions();
  const dialogBox = selectDialogBox();
  const [transferTransactionValue, setTransferTransaction] = useState([]);
  // const [searchParams] = useSearchParams();
  const currentTransaction = selectCurrentTransaction();
  const duplicateId = searchParams.get("duplicate_id");

  const getPaymentTerm = (value) => {
    const { payment_terms = [] } = transactionOption;
    const paymentTerm = payment_terms.find(
      (term) => term.payment_term_id === value
    );

    return paymentTerm ? paymentTerm : null;
  };
  const convertDueOnDate = (termId) => {
    const paymentTermObject = getPaymentTerm(termId);
    const watchDate = watch("date") ? watch("date") : moment(todayDate);

    const momentDateValue = moment(new Date(watchDate.format("YYYY-MM-DD")));
    const paymentTermAddedValue = momentDateValue.add(
      paymentTermObject.value,
      paymentTermObject.name === "EOFM" ? "months" : "days"
    );

    const paymentTermEndOf =
      paymentTermObject.name === "EOFM" || paymentTermObject.name === "EOM"
        ? paymentTermAddedValue.endOf("month")
        : paymentTermAddedValue;
    // const paymentTermDate = paymentTermEndOf.format("YYYY-MM-DD");
    return paymentTermEndOf;
  };
  // const [transferItemType ,setTransferItemType] = useState(null);

  const transactionDefaultData = () => {
    if (currentTransaction) {
      const {
        tags = [],
        FormItems: form_items = [],
        TransactionPaymentTerms,
        custom_fields,
        date,
        ...otherData
      } = currentTransaction;
      const companySetting = transactionOption.company_setting
        ? transactionOption.company_setting
        : null;
      const activeTaxKey = transactionActiveTax[transactionType];
      const activeTax =
        companySetting !== null && companySetting[activeTaxKey] !== null
          ? companySetting[activeTaxKey]
          : false;

      if (companySetting) {
        const customFields = custom_fields
          ? {
              custom_fields,
            }
          : {};

        const paymentTermObj = companySetting.default_payment_term
          ? {
              payment_term_id: companySetting.default_payment_term,
            }
          : {};

        const dueDateObj = companySetting.default_payment_term
          ? { due_date: convertDueOnDate(companySetting.default_payment_term) }
          : {};

        const paymentTermsListObj =
          subCategory === "invoices"
            ? {
                payment_terms: [
                  {
                    payment_term_id: companySetting.default_payment_term,
                    due_on: convertDueOnDate(
                      companySetting.default_payment_term
                    ),
                    amount: "100.00%",
                    index: 0,
                  },
                ],
              }
            : {};

        const filteredTransaction = Object.keys(otherData).reduce(
          (obj, key) => {
            if (
              key !== "transaction_id" &&
              key !== "transaction_no" &&
              key !== "void_date" &&
              key !== "void_reason" &&
              key !== "email_tracker" &&
              key !== "transfer_status"
            ) {
              return {
                ...obj,
                [key]: otherData[key],
              };
            }
            return obj;
          },
          {}
        );

        return {
          ...defaultData,
          ...filteredTransaction,
          ...customFields,
          ...paymentTermObj,
          ...dueDateObj,
          ...paymentTermsListObj,
          number_format_id: companySetting.numberFormat,
          date: moment(new Date(date)),
          payment_terms: TransactionPaymentTerms,

          tag_ids: tags.map((tag) => tag.tag_id),
          active_tax: activeTax,
          form_items: form_items.map((item) => {
            // const {  ...restItem } = item;
            // const existingTax = taxList.find((t) => t.value === tax);
            return {
              ...item,
              // total_amount: (item.quantity * parseFloat(item.amount)).toFixed(
              //   2
              // ),
            };
          }),
        };
      }
      return {};
    } else {
      if (transactionOption) {
        const companySetting = transactionOption.company_setting
          ? transactionOption.company_setting
          : null;
        const activeTaxKey = transactionActiveTax[transactionType];
        const activeTax =
          companySetting !== null && companySetting[activeTaxKey] !== null
            ? companySetting[activeTaxKey]
            : false;

        if (companySetting) {
          const paymentTermObj = companySetting.default_payment_term
            ? {
                payment_term_id: companySetting.default_payment_term,
              }
            : {};
          const dueDateObj = companySetting.default_payment_term
            ? {
                due_date: convertDueOnDate(companySetting.default_payment_term),
              }
            : {};
          const paymentTermsListObj =
            subCategory === "invoices"
              ? {
                  payment_terms: [
                    {
                      payment_term_id: companySetting.default_payment_term,
                      due_on: convertDueOnDate(
                        companySetting.default_payment_term
                      ),
                      amount: "100.00%",
                      index: 0,
                    },
                  ],
                }
              : {};
          return {
            ...defaultData,
            ...dueDateObj,
            number_format_id: companySetting.numberFormat,
            system_currency_code: "MYR",
            effective_rate: companySetting.effective_rate
              ? companySetting.effective_rate
              : "1",
            active_tax: activeTax,
            tax_inclusive: companySetting.default_include_tax,
            ...paymentTermObj,
            ...paymentTermsListObj,
          };
        }
        return {};
      }
      return {};
    }
  };
  useEffect(() => {
    if (transactionOption) {
      setTimeout(() => {
        reset(transactionDefaultData());
      }, 100);
    }
  }, [transactionOption]);
  const createTransactionLoading = selectCreateTransactionLoading();
  const retrieveOptionsLoading = selectTransactionsOptionsLoading();
  const retrieveTransactionLoading = selectRetrieveTransactionLoading();
  const transactionType =
    subCategory !== "payments" &&
    subCategory !== "refunds" &&
    subCategory !== "credit-notes"
      ? subCategory
      : `${category}-${subCategory}`;

      console.log(globalAccCompanyID)
  useEffect(() => {

    if(globalAccCompanyID){
      if (duplicateId) {
        dispatch(retrieveTransaction(duplicateId));
      } else {
          dispatch(retrieveTransactionOptions(transactionType));
      }
      return () => {
        dispatch(clearTransactionState());
      };

    }
  }, [globalAccCompanyID]);
  const handleCreateTransaction = (data) => {
    // delete data.transferTransactionList;
    const {
      number_format_id,
      form_items,
      payment_terms = [],
      form_items_total = 0,
      date,
      payment_term_id,
      due_date,
      ...otherData
    } = data;
    const otherPayload = Object.keys(otherData).reduce((obj, item) => {
      if (item !== "form_items_total" && item !== "active_tax") {
        return {
          ...obj,
          [item]: otherData[item],
        };
      }
      return obj;
    }, []);
    if (form_items.length > 0) {
      const numberFormat = transactionOption.number_formats.find(
        (nF) => nF.number_format_id === number_format_id
      ).format;
      const paymentTermObj = payment_terms
        ? { payment_terms: payment_terms }
        : {};

      const paymentTermObject = payment_term_id
        ? { payment_term_id }
        : due_date
        ? { due_date: due_date.format("YYYY-MM-DD") }
        : {};
      const dispatchPayload = {
        date: date.format("YYYY-MM-DD"),
        ...paymentTermObject,
        ...otherPayload,
        number_format: {
          number_format_id: number_format_id,
          format: numberFormat,
        },
        form_items: form_items.map((item) => {
          const { product_id, tax, ...otherItem } = item;
          const taxObject =
            tax != null || tax != "" || tax != "0"
              ? { tax: tax.toString() }
              : { tax: null };
          const formItemPayload = Object.keys(otherItem).reduce((obj, item) => {
            if (item !== "total_amount" && item !== "form_item_id") {
              return {
                ...obj,
                [item]: otherItem[item],
              };
            }
            return obj;
          }, {});
          return {
            ...formItemPayload,
            product_id: product_id === "" ? null : product_id,
            ...taxObject,
          };
        }),
        ...paymentTermObj,
        type: transactionType,
        replaceNav: `#/Invoicing/?category=${category}&sub_category=${subCategory}`,
      };
      if (subCategory === "invoices") {
        // const total = form_items.reduce((acc, item)=>{
        //   const
        // });

        const paymentTermTotal = payment_terms.reduce((total, term) => {
          if (term.amount.endsWith("%")) {
            const value =
              (form_items_total * parseFloat(term.amount.replace(/%$/, ""))) /
              100;
            return total + value;
          }
          return total + parseFloat(term.amount);
        }, 0);

        if (
          form_items_total > paymentTermTotal ||
          paymentTermTotal > form_items_total
        ) {
          callSnack({
            type: SnackType.Error,
            message: "Payment term total must be equal to invoice total",
            timeout: 3000,
          });
        } else {
          dispatch(createTransaction(dispatchPayload));
        }
      } else {
        dispatch(createTransaction(dispatchPayload));
      }
    } else {
      callSnack({
        type: SnackType.Error,
        message: "Please add at least one items",
        timeout: 3000,
      });
    }
  };
  // const transferFormItemDialogObject = useMemo(() => {
  //   const { update, transfer } = dialogBox;
  //   if (update.data !== null) {
  //     const transactionType = update.data.type;
  //     const itemList = update.data.list;
  //     return {
  //       title: transactionType,
  //       list: itemList,
  //     };
  //   }
  //   if (transfer.data !== null) {
  //     const transactionType = transfer.data.type;
  //     const transactionList = transfer.data.list;

  //     return {
  //       title: transactionType,
  //       list: transactionList,
  //     };
  //   }

  //   return {
  //     title: "",
  //     list: [],
  //   };
  // }, [dialogBox]);

  // //* to handle close dialog
  // const handleToggleCloseDialog = (isUpdate = false) => {
  //   const dialogParams = {
  //     data: null,
  //     isOpen: false,
  //   };
  //   dispatch(
  //     toggleDialogBox(
  //       isUpdate
  //         ? {
  //             update: dialogParams,
  //           }
  //         : {
  //             transfer: dialogParams,
  //           }
  //     )
  //   );
  // };

  const handleOpenTransferTransaction = () => {
    dispatch(
      toggleDialogBox({
        update: dialogBox.update,
        transfer: {
          data: dialogBox.transfer.data,
          isOpen: true,
        },
      })
    );
  };

  const handleCloseTransferTransaction = () => {
    dispatch(
      toggleDialogBox({
        update: dialogBox.update,
        transfer: {
          data: dialogBox.transfer.data,
          isOpen: false,
        },
      })
    );
  };

  const handleUpdateTransferItem = () => {
    dispatch(
      toggleDialogBox({
        update: {
          data: null,
          isOpen: false,
        },
      })
    );
    // if (transferTransactionValue.length > 0) {
    //   const transferredTransactionList = dialogBox.transfer.data.list;
    //   dispatch(
    //     toggleDialogBox({
    //       update: {
    //         data: null,
    //         isOpen: false,
    //       },
    //     })
    //   );

    //   const selectedFormItemList = transferTransactionsWatcher
    //     ? transferTransactionsWatcher.map((item) => item.form_item_id)
    //     : [];
    //   const confirmationList = transferredTransactionList.reduce(
    //     (list, tran) => {
    //       const { FormItems } = tran;
    //       const selectedFormItems = FormItems.reduce(
    //         (formList, item) => {
    //           const { form_item_id, ...otherItemObj } = item;
    //           if (
    //             selectedFormItemList.includes(form_item_id) &&
    //             !formWatcher.find(
    //               (watch) => watch.transfer_form_item_id === form_item_id
    //             )
    //           ) {
    //             return [
    //               ...formList,
    //               {
    //                 ...otherItemObj,
    //                 transfer_transaction: tran.transaction_no,
    //                 transfer_form_item_id: form_item_id,
    //                 FormItemChildren: [],
    //               },
    //             ];
    //           }
    //           return formList;
    //         },

    //         []
    //       );
    //       return [...list, ...selectedFormItems];
    //     },
    //     []
    //   );

    //   console.log("confirmationList", [...formWatcher, ...confirmationList]);
    //   if (confirmationList.length > 0) {
    //     setValue(`form_items`, [...formWatcher, ...confirmationList], {
    //       shouldDirty: true,
    //     });
    //   }
    // } else {
    //   dispatch(
    //     toggleDialogBox({
    //       update: {
    //         data: null,
    //         isOpen: false,
    //       },
    //     })
    //   );
    // }
  };

  const handleRemoveNewOptions = (indexToRemove) => {
    transferRemove(indexToRemove);
  };

  // const tableRowSelectionData = useMemo(() => {
  //   return transferTransactionsWatcher
  //     ? transferTransactionsWatcher.map((item) => item.transaction_id)
  //     : [];
  // }, [transferTransactionsWatcher]);
  const transactionTitle = (transaction) => {
    const { transaction_no, date, FormItems } = transaction;
    return (
      <Stack direction={"row"} sx={{ gap: "10px" }}>
        <Typography
          sx={({ typography }) => ({
            ...typography["bodyTextBold"],
            fontSize: "18px",
          })}
        >
          {`${transaction_no}: ${FormItems[0].descriptions}`}
        </Typography>
        <Typography
          sx={({ typography }) => ({
            ...typography["bodyTextBold"],
            alignContent: "center",
            fontSize: "12px",
            border: "1px solid grey",
            borderRadius: "3px",
            padding: "2px",
          })}
        >
          {date}
        </Typography>
      </Stack>
    );
  };
//   const newFormLayoutTitle = (type) => {
//     return type.charAt(0).toUpperCase() + type.slice(1);
//   };

  const deleteButton = (index) => {
    return (
      <Box sx={{ alignSelf: "center", textAlign: "center" }} width={"35px"}>
        <IconButton
          onClick={() => {
            handleRemoveNewOptions(index);
          }}
          sx={({ icons }) => ({
            ...icons.standard.default,
          })}
        >
          <DeleteOutlineRounded fontSize="inherit" />
        </IconButton>
      </Box>
    );
  };
  const formStatusList = [
    {
      label: "Draft",
      value: "DRAFT",
    },
    {
      label: "Pending Approval",
      value: "PENDING APPROVAL",
    },
    {
      label: "Ready",
      value: "READY",
    },
  ];
  return (
    <MainContentContainer
      removeSearchParams={removeSearchParams}
      fullHeight
      actionChildren={
        <Stack direction={"row"} spacing={2}>
          <FormInputToggleButton
            name={"status"}
            control={control}
            list={formStatusList}
          />
          <Button
            variant="contained"
            type="submit"
            onClick={handleSubmit(handleCreateTransaction)}
            disabled={
              retrieveOptionsLoading ||
              createTransactionLoading ||
              retrieveTransactionLoading
            }
          >
            {retrieveOptionsLoading ||
            createTransactionLoading ||
            retrieveTransactionLoading ? (
              <CircularProgress
                size="20px"
                sx={({ palette }) => ({ color: palette.primary.white })}
                data-testid="create-custom-field-progressbar"
              />
            ) : (
              "Save"
            )}
          </Button>
        </Stack>
      }
    >
      {retrieveOptionsLoading || retrieveTransactionLoading ? (
        <CircularProgress
          size="20px"
          sx={({ palette }) => ({ color: palette.primary.white })}
          data-testid="create-custom-field-progressbar"
        />
      ) : (
        // newFormLayoutTitle(transactionType)
        <NewFormLayout title={"New"}>
          {
            <MainForm
              control={control}
              isDirty={isDirty}
              watch={watch}
              resetField={resetField}
              setValue={setValue}
              getValues={getValues}
              options={transactionOption ? transactionOption : {}}
              customFields={customFields}
              activeTaxKey={transactionActiveTax[transactionType]}
              is_invoice={subCategory === "invoices"}
              isBill={subCategory === "bills"}
              isPurchase={transactionIsPurchases.includes(
                `${category}/${subCategory}`
              )}
              transferTransactionTypeList={transferTransactionTypeList}
            />
          }
        </NewFormLayout>
      )}
      <ConfirmationDialog
        open={dialogBox.update.isOpen}
        title={`Transfer Items from ${
          dialogBox.update.data
            ? transactionTypeNameEnum[dialogBox.update.data]
            : ""
        }`}
        disableCancel={true}
        stackChildren={
          <Stack>
            {dialogBox.transfer.data ? (
              dialogBox.transfer.data.list
                .filter((item) =>
                  transferTransactionValue.includes(item.transaction_id)
                )
                .map((item, index) => {
                  const { FormItems } = item;
                  const formattedFormItems = FormItems.map((item) => {
                    return {
                      ...item,
                      tax: item.tax === "0" ? "" : item.tax,
                    };
                  });
                  return (
                    <Stack key={item.transaction_id}>
                      <Stack
                        direction={"row"}
                        sx={{ justifyContent: "space-between" }}
                      >
                        {transactionTitle(item)}
                        {deleteButton(index)}
                      </Stack>
                      {/* <TransferItemsTable
                        transaction={item}
                        transactionItemList={formattedFormItems}
                        appendTransferItem={transferAppend}
                        removeTransferredItem={transferRemove}
                        control={control}
                        setValue={setValue}
                        watch={watch}
                      /> */}
                    </Stack>
                  );
                })
            ) : (
              <></>
            )}

            <Button
              variant="contained"
              onClick={handleOpenTransferTransaction}
              sx={{
                width: 0.2,
                margin: "10px 10px",
              }}
              startIcon={
                <AddRounded
                  sx={({ icons, palette }) => ({
                    ...icons.standard,
                    color: palette.primary.main,
                  })}
                />
              }
            >
              {dialogBox.update.data}
            </Button>
          </Stack>
        }
        maxWidth={"lg"}
        handleClose={handleUpdateTransferItem}
        childrenSx={[
          {
            height: "500px",
          },
        ]}
        handleConfirm={handleUpdateTransferItem}
      />

      <ConfirmationDialog
        open={dialogBox.transfer.isOpen}
        title={`Add ${
          dialogBox.transfer.data
            ? transactionTypeNameEnum[dialogBox.transfer.data.title]
            : ""
        }`}
        handleClose={handleCloseTransferTransaction}
        maxWidth={"lg"}
        disableCancel={true}
        stackChildren={
          <DataTable
            // isLoading={retrieveProductGroupsLoading || removeProductGroupLoading}
            table={TableContent[TABLE.TRANSACTIONS.TRANSFERS_TRANSACTION]}
            tableData={
              dialogBox.transfer.data && dialogBox.transfer.data.list
                ? dialogBox.transfer.data.list
                : []
            }
            rowSelection={transferTransactionValue}
            onSelectRow={(row) => {
              // const selectedRows = dialogBox.transfer.data.list.filter((item) =>
              //   row.includes(item.transaction_id)
              // );
              // console.log("selectedRow", selectedRows);
              // setValue("transferTransactionList", selectedRows);
              setTransferTransaction(row);
            }}
          />
        }
        childrenSx={[
          {
            height: "500px",
          },
        ]}
        handleConfirm={handleCloseTransferTransaction}
      />
    </MainContentContainer>
  );
};
