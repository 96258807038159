import { call, delay, put, takeLatest } from "redux-saga/effects";
import { 
    bulkCreateClientHandler, 
    bulkCreateClientHandlerError, 
    bulkCreateClientHandlerSuccess, 

    createClientHandler, 
    createClientHandlerError, 
    createClientHandlerSuccess, 

    deleteClientHandler, 
    deleteClientHandlerError, 
    deleteClientHandlerSuccess, 

    retrieveClientHandler, 
    retrieveClientHandlerError, 
    retrieveClientHandlerSuccess, 
    
    retrieveSpecificClientHandler, 
    retrieveSpecificClientHandlerError, 
    retrieveSpecificClientHandlerSuccess 
} from "../stores/slices/clientGroupHandlerSlices";

import { ApiRoute } from "../enums/ApiRoute";
import { API } from "../http-common";

function* createClientHandlerSaga({payload}) {
    try{
        const { data } = yield call(API.post, ApiRoute.clientGroupHandler.createClientHandler, payload);
        yield put(createClientHandlerSuccess(data));
    } catch (error){
        yield put(createClientHandlerError(error));
        console.error(error);
    }
}

function* bulkCreateClientHandlerSaga({payload}) {
    try{
        const { data } = yield call(API.post, ApiRoute.clientGroupHandler.bulkCreateClientHandler, payload);
        yield put(bulkCreateClientHandlerSuccess(data));
    } catch (error){
        yield put(bulkCreateClientHandlerError(error));
        console.error(error);
    }
}

function* retrieveClientHandlerSaga({payload}) {
    try{
        const { data } = yield call(API.post, ApiRoute.clientGroupHandler.retrieveClientHandler, payload);
        yield put(retrieveClientHandlerSuccess(data));
    } catch (error){
        yield put(retrieveClientHandlerError(error));
        console.error(error);
    }
}

function* retrieveSpecificClientHandlerSaga({payload}) {
    try{
        const { data } = yield call(API.post, ApiRoute.clientGroupHandler.retrieveSpecificClientHandler, payload);
        yield put(retrieveSpecificClientHandlerSuccess(data));
    } catch (error){
        yield put(retrieveSpecificClientHandlerError(error));
        console.error(error);
    }
}

function* deleteClientHandlerSaga({payload}) {
    try{
        const { data } = yield call(API.post, ApiRoute.clientGroupHandler.deleteClientHandler, payload);
        yield put(deleteClientHandlerSuccess(data));
    } catch (error){
        yield put(deleteClientHandlerError(error));
        console.error(error);
    }
}

export function* clientHandlerSagaWatcher(){
    yield takeLatest(createClientHandler.type, createClientHandlerSaga);
    yield takeLatest(bulkCreateClientHandler.type, bulkCreateClientHandlerSaga);
    yield takeLatest(retrieveClientHandler.type, retrieveClientHandlerSaga);
    yield takeLatest(retrieveSpecificClientHandler.type, retrieveSpecificClientHandlerSaga);
    yield takeLatest(deleteClientHandler.type, deleteClientHandlerSaga);
}