import React from "react";
import { Drawer, IconButton, Stack, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";

export const DrawerEditor = ({ children, isOpen, handleClose, title }) => {
  return (
    <Drawer
      anchor="right"
      open={isOpen}
      slotProps={{
        backdrop: {
          sx: { background: "none", zIndex: 1600 },
        },
      }}
      PaperProps={{
        sx: { width: "350px", zIndex: 2000 },
      }}
      onClose={handleClose}
    >
      <Stack direction="column" width={1} height={1}>
        <Stack
          direction="row"
          width={1}
          spacing={1}
          sx={({ palette, sizing }) => ({
            padding: 1,
            minHeight: sizing.headerHeight,
            alignItems: "center",
            borderBottom: `1px solid ${palette.border.main}`,
          })}
        >
          <IconButton onClick={handleClose}>
            <Close sx={({ icons }) => ({ ...icons.standard })} />
          </IconButton>
          <Typography variant="displayH3">{title}</Typography>
        </Stack>
        {children}
      </Stack>
    </Drawer>
  );
};
