import moment from "moment";
import { v4 } from "uuid";

const phoneValidation = (phoneNum) => {
    let regexPhone = /^(\+?6?01)[02-46-9]-*[0-9]{7}$|^(\+?6?01)[1]-*[0-9]{8}$/;
    let internationRegexPhone = /^(?:\+)?(?:[0-9]●?){6,14}[0-9]$/;
    
    return internationRegexPhone.test(phoneNum);
}


// let regexEmailLength = /^[^\s@]{6,}@[^\s@]+\.[^\s@]+$/;
const emailValidation = (email) => {
  // Added i after the regex will match both uppercase and lowercase
    let regexEmail = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/i;
    
    return regexEmail.test(email);
}

const numberValidation = (number) => {
    let regexAlphabet = /^[A-Za-z]+$/;

    return !regexAlphabet.test(number);
}

const datetimeValidation = (date) => {
  let regexDate = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])(?: (00|[0-9]|1[0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9]))?$/;

  return regexDate.test(date);
}

const csvImportChecker = (column, valueArray, newImportedRow, clientColumnArray, availableMemberList, isValid, isAuthorized) => {

    let errorImportArr = [];
    let errorIndexArr = [];
    let csvDuplicatedArr = [];
    let hasDuplicateCondition = false;

    const pushErrorIndex = (errorIndex) => {
      if(!errorIndexArr.includes(errorIndex)){
        errorIndexArr.push(errorIndex);
      }
    }
    column.forEach((keys, keyIndex) => {
        let valuesObj = valueArray[keyIndex];
        keys.forEach((dataKey, dataKeyIndex) =>{
          let dataValue = valuesObj[dataKeyIndex] === null ? "" : valuesObj[dataKeyIndex] ;
          //Handler
          if(dataKey === "Handler"){
              const handlerArr = dataValue.split(",").map(item=>item.trim());
              const isValidMember = dataValue !== "" 
              ? handlerArr.every(handler => availableMemberList.some(user => user.uid === handler))
              : true; 
              if(!isValidMember){
                  errorImportArr.push({message:"invalid-import-handler", columnName: "Handler", rowIndex:keyIndex, colIndex: null});
                  isValid = false;
                  console.log("Invalid handler")
                  pushErrorIndex(keyIndex);
              }
              newImportedRow[keyIndex][dataKeyIndex] = { rowID: v4(), columnID: "Handler", rowValue: handlerArr}

          } else {
            //Columns
            let columnObj = clientColumnArray.find(item=>item.columnID === dataKey);
            //required
            if(columnObj.required && dataValue === ""){
              errorImportArr.push({message:"invalid-import-required", columnName: columnObj.columnName, rowIndex: keyIndex, colIndex: columnObj.columnIndex});
              isValid = false;
              console.log("Invalid require")
              pushErrorIndex(keyIndex);
              newImportedRow[keyIndex][dataKeyIndex] = { rowID: v4(), columnID: dataKey, rowValue: dataValue}
            }

            //dups
            if(!columnObj.duplicate && dataValue !== ""){
              hasDuplicateCondition = true;
              const matchingProcessedRow = newImportedRow.some(data => data.some(item => item.rowValue === dataValue && item.columnID === dataKey))

              //if has dup then push into error Arr
              if(matchingProcessedRow){
                // errorImportArr.push({message:"invalid-import-duplicate", columnName: columnObj.columnName, rowIndex: keyIndex, colIndex: null});
                csvDuplicatedArr.push({ columnName: columnObj.columnName, rowValue: dataValue})
                isValid = false;
                console.log("Invalid csvdup")
                // pushErrorIndex(keyIndex);
              } 
              newImportedRow[keyIndex][dataKeyIndex] = { rowID: v4(), columnID: dataKey, rowValue: dataValue}

            }

            if(columnObj !== undefined && dataValue !== "" && !Array.isArray(dataValue)){
              switch(columnObj.columnType){
                case "Member" :
                  const isValidMember = availableMemberList.some(user=>user.uid.includes(dataValue));
                  if(!isValidMember) {
                    errorImportArr.push({message:"invalid-import-member", columnName: columnObj.columnName, rowIndex: keyIndex, colIndex: columnObj.columnIndex});
                    isValid = false;
                    console.log("Invalid member")
                    pushErrorIndex(keyIndex);
                  } 
                  newImportedRow[keyIndex][dataKeyIndex] = { rowID: v4(), columnID: dataKey, rowValue: dataValue}
                  break;

                case "Checkboxes" :
                  const answer = dataValue.toString().split(",").map(item=>item.trim());
                  const isValidAnswer = answer.every(item=>columnObj.option.includes(item))
                  if(!isValidAnswer){
                    errorImportArr.push({message:"invalid-import-checkbox", columnName: columnObj.columnName, rowIndex: keyIndex, colIndex: columnObj.columnIndex});
                    isValid = false;
                    console.log("Invalid checkbox")
                    pushErrorIndex(keyIndex);
                  }
                  newImportedRow[keyIndex][dataKeyIndex] = { rowID: v4(), columnID: dataKey, rowValue: answer}
                  break;

                // case "Dropdown" :
                //   console.log(columnObj.option)
                //   if(!columnObj.option.includes(dataValue)){
                //     errorImportArr.push({message:"invalid-import-dropdown", columnName: columnObj.columnName, rowIndex: keyIndex, colIndex: columnObj.columnIndex});
                //     isValid = false;
                //     pushErrorIndex(keyIndex);
                //   }
                //   newImportedRow[keyIndex][dataKeyIndex] = { rowID: v4(), columnID: dataKey, rowValue: dataValue.split(",")}
                //   break;

                case "Alert":
                  if(!moment(dataValue).isValid()){
                    errorImportArr.push({message:"invalid-import-date", columnName: columnObj.columnName, rowIndex: keyIndex, colIndex: columnObj.columnIndex});
                    isValid = false;
                    console.log("Invalid alert")
                    pushErrorIndex(keyIndex);
                  }
                  newImportedRow[keyIndex][dataKeyIndex] = { rowID: v4(), columnID: dataKey, rowValue: dataValue}
                  break;

                case "Multiple Choices" :
                  if(!columnObj.option.includes(dataValue)){
                    errorImportArr.push({message:"invalid-import-choice", columnName: columnObj.columnName, rowIndex: keyIndex, colIndex: columnObj.columnIndex});
                    isValid = false;
                    console.log("Invalid multiplechoice")
                    pushErrorIndex(keyIndex);
                  }
                  newImportedRow[keyIndex][dataKeyIndex] = { rowID: v4(), columnID: dataKey, rowValue: dataValue.split(",")}
                  break;

                case "Date" :
                  
                  if(!datetimeValidation(dataValue)){
                    errorImportArr.push({message:"invalid-import-date", columnName: columnObj.columnName, rowIndex: keyIndex, colIndex: columnObj.columnIndex});
                    isValid = false;
                    console.log("Invalid date")
                    pushErrorIndex(keyIndex);
                  }
                  newImportedRow[keyIndex][dataKeyIndex] = { rowID: v4(), columnID: dataKey, rowValue: dataValue}
                  break;

                case "Email" :
                  if(!emailValidation(dataValue)){
                    errorImportArr.push({message:"invalid-import-email", columnName: columnObj.columnName, rowIndex: keyIndex, colIndex: columnObj.columnIndex});
                    isValid = false;
                    console.log("Invalid email")
                    pushErrorIndex(keyIndex);
                  }
                  newImportedRow[keyIndex][dataKeyIndex] = { rowID: v4(), columnID: dataKey, rowValue: dataValue}
                  break;

                case "Numbers" :
                  if(!numberValidation(dataValue)){
                    errorImportArr.push({message:"invalid-import-number", columnName: columnObj.columnName, rowIndex: keyIndex, colIndex: columnObj.columnIndex});
                    isValid = false;
                    console.log("Invalid number")
                    pushErrorIndex(keyIndex);
                  }
                  newImportedRow[keyIndex][dataKeyIndex] = { rowID: v4(), columnID: dataKey, rowValue: dataValue}
                  break;

                case "Phone" :
                  if(!phoneValidation(dataValue.toString())){
                    errorImportArr.push({message:"invalid-import-phone", columnName: columnObj.columnName, rowIndex: keyIndex, colIndex: columnObj.columnIndex});
                    isValid = false;
                    console.log("Invalid phone")
                    pushErrorIndex(keyIndex);
                  }
                  newImportedRow[keyIndex][dataKeyIndex] = { rowID: v4(), columnID: dataKey, rowValue: dataValue.toString()}
                  break;

                case "Switch" :
                  if(typeof dataValue !== 'boolean'){
                    if((dataValue.toLocaleLowerCase().toString() === "active" || dataValue.toLocaleLowerCase().toString() === "inactive")){
                      const value = dataValue.toLocaleLowerCase().toString() === "active" ? true : false;
                      newImportedRow[keyIndex][dataKeyIndex] = { rowID: v4(), columnID: dataKey, rowValue: value}
                    } else {
                      if((dataValue.toLocaleLowerCase().toString() !== "active" || dataValue.toLocaleLowerCase().toString() !== "inactive")){
                        errorImportArr.push({message:"invalid-import-switch", columnName: columnObj.columnName, rowIndex: keyIndex, colIndex: columnObj.columnIndex});
                        isValid = false;
                        console.log("Invalid switch")
                        pushErrorIndex(keyIndex);
                        newImportedRow[keyIndex][dataKeyIndex] = { rowID: v4(), columnID: dataKey, rowValue: dataValue }
                      }
                    }
                    
                  } else {
                    newImportedRow[keyIndex][dataKeyIndex] = { rowID: v4(), columnID: dataKey, rowValue: dataValue}
                  }
                  break;

                default :
                  newImportedRow[keyIndex][dataKeyIndex] = { rowID: v4(), columnID: dataKey, rowValue: dataValue}
                  break;
              }
            } else {
              //Checkboxes are arrays
              if(columnObj?.columnType === "Checkboxes"){
                newImportedRow[keyIndex][dataKeyIndex] = { rowID: v4(), columnID: dataKey, rowValue: []}
              } else {
                newImportedRow[keyIndex][dataKeyIndex] = { rowID: v4(), columnID: dataKey, rowValue: dataValue}
              }
            }
          }
        });
      })

      const uniqueCSVDuplicatedArr = new Set();

      const errorCSVDuplicatedArr = csvDuplicatedArr.filter((item) => {
          if (!uniqueCSVDuplicatedArr.has(item.rowValue)) {
            uniqueCSVDuplicatedArr.add(item.rowValue);
              return true;
          }
          return false; 
      });

      return ({validData:isValid, processedImportedRow: newImportedRow, 
        errorImportArr:errorImportArr, errorIndexArr: errorIndexArr,
        hasDuplicateCondition: hasDuplicateCondition, errorCSVDuplicatedArr: errorCSVDuplicatedArr})
}

const notify = (type, targetTransferGroup) => {
  let notifyMessage = "";
  switch(type)
  {
    case "incompatible-template":
      notifyMessage = "Template version is different. Please get the latest template before import !";
      break;
    case "switch-updated":
      notifyMessage = "Status updated successfully !";
      break;
    case "copied-value":
      notifyMessage = "Value has been copied to clipboard !";
      break;
    case "create":
      notifyMessage = "Created successfully !"
      break;
    case "edit":
      notifyMessage = "Updated successfully !"
      break;
    case "delete":
      notifyMessage = "Permanently delete clients successfully !"
      break;
    case "import":
      notifyMessage = "Imported successfully !"
      break;
    case "import-empty":
      notifyMessage = "File must not be empty ! Please select a file to import."
      break;
    case "export":
      notifyMessage = "Exported successfully !"
      break;
    case "column-saved":
      notifyMessage = "Column is saved successfully !"
      break;
    case "column-width-saved":
      notifyMessage = "Column width is saved successfully !"
      break;
    case "column-position-saved":
      notifyMessage = "Column position is saved successfully !"
      break;
    case "empty-import":
      notifyMessage = "Data must not be empty ! Please make sure that your .CSV data is not empty."
      break;
    case "invalid-column":
      notifyMessage = "Invalid column. Please make sure that the columns are correct before import."
      break;
    case "import-limit":
      notifyMessage = "Data import limit: 100k per import."
      break;
    case "empty-required-import":
      notifyMessage = "Make sure all the required field are not empty in the CSV."
      break;
    case "invalid-import-handler":
      notifyMessage = "Invalid HandlerID / User is not in the company. Please try again."
      break;
    case "archive":
      notifyMessage = "Delete clients successfully"
      break;
    case "transfer":
      notifyMessage = `Clients are transfered successfully.`
      break;
    case "unarchive":
      notifyMessage = "Restore clients successfully"
      break;
    default:
      notifyMessage = "Action done"
      break;
  }

  return notifyMessage
};

const dateRangeFilter = (graphData, column, range) => {
  const today = moment(new Date()).format("YYYY-MM-DD");
  const startOfWeek = moment(new Date()).startOf('week').format("YYYY-MM-DD");
  const endOfWeek = moment(new Date()).endOf('week').format("YYYY-MM-DD");
  const startOfMonth = moment(new Date()).startOf('month').format("YYYY-MM-DD");
  const endOfMonth = moment(new Date()).endOf('month').format("YYYY-MM-DD");
  const startOfSixMonthsAgo = moment(new Date()).subtract(6, 'months').format("YYYY-MM-DD");
  const startOfYearAgo = moment(new Date()).subtract(1, 'year').format("YYYY-MM-DD");
  const startOfTwoYearsAgo = moment(new Date()).subtract(2, 'year').format("YYYY-MM-DD");
  const startOfThreeYearsAgo = moment(new Date()).subtract(3, 'year').format("YYYY-MM-DD");
  const startOfFiveYearsAgo = moment(new Date()).subtract(5, 'year').format("YYYY-MM-DD");

  let updatedGraphData = [];
  
  // validSettings.forEach(settings => {
  //   const column = settings.dateColumn;
  //   const range = settings.dateRange;

  if(graphData.length > 0){
    graphData?.forEach((graph) => {
      const dataDate = moment(graph[column]).format("YYYY-MM-DD");

      if(range === "Today" && dataDate === today){
        updatedGraphData.push(graph);
      }
      if(range === "This Week" && (moment(dataDate).isBetween(startOfWeek, endOfWeek, String))){
        updatedGraphData.push(graph);
      }
      if(range === "This Month" && (moment(dataDate).isBetween(startOfMonth, endOfMonth, String))){
        updatedGraphData.push(graph);
      }
      if(range === "6 Months" && (moment(dataDate).isBetween(startOfSixMonthsAgo, today, String))){
        updatedGraphData.push(graph);
      }
      if(range === "1 Year" && (moment(dataDate).isBetween(startOfYearAgo, today, String))){
        updatedGraphData.push(graph);
      }

      if(range === "2 Years" && (moment(dataDate).isBetween(startOfTwoYearsAgo, today, String))){
        updatedGraphData.push(graph);
      }

      if(range === "3 Years" && (moment(dataDate).isBetween(startOfThreeYearsAgo, today, String))){
        updatedGraphData.push(graph);
      }

      if(range === "5 Years" && (moment(dataDate).isBetween(startOfFiveYearsAgo, today, String))){
        updatedGraphData.push(graph);
      }
    })
  }
    
  // })

  return updatedGraphData
}

const isJSON = () => {

  try {
      //attempt to parse json
      JSON.parse(json);
      return true;

  } catch(error){
      //if it's not json
      return false;
  }

}

const CommonFunc = {
    phoneValidation,
    emailValidation,
    numberValidation,
    csvImportChecker,
    notify,
    dateRangeFilter,
    isJSON
}

export default CommonFunc