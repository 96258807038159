import React, { useEffect, useRef, useState } from 'react';
import {
  useNavigate,
  createSearchParams
} from "react-router-dom";
import { BsCheck2Circle } from 'react-icons/bs';
import './Header.scss';
import { useDispatch } from 'react-redux';
import { BiArrowBack } from 'react-icons/bi';
import { AiFillCaretDown, AiFillCheckCircle} from 'react-icons/ai';
import { signOut, updateProfile } from 'firebase/auth';
import { set, ref, onValue, update } from 'firebase/database'
import { db, auth,storage } from '../firebaseInit';
import { HiOutlineLogout } from 'react-icons/hi';
import C_DialogButton from './C_DialogButton';
import { ref as ref_storage, getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import { getFromLocalStorage, removeFromLocalStorage, updateLocalStorage } from '../util/LocalStorage';


//slices
import { retrieveCompanyAgent } from '../stores/slices/companyAgentSlices';
import { selectCompanyID, setCompanyID } from '../stores/slices/companyIDSlices';
import { retrieveCurrRoles } from '../stores/slices/roleSlices';
import { selectCurrSa, selectCurrTenant, setIsSA, setTenant } from '../stores/slices/persistRolesSlices';
import { selectTenant } from '../stores/slices/tenantSlices';
import { selectCompanyContext, selectCurrUserContext } from '../stores/slices/companySlices';
import { selectUser } from '../stores/slices/userAuthSlices';

function Header(props) {

  const profileRef = useRef(null);
  const companyRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currUsers} = selectCurrUserContext();
  const {
    companyList
  } = selectCompanyContext();

  const user = selectUser();

  const companyID = selectCompanyID();
  const currSa = selectCurrSa();
  const tenant = selectCurrTenant();

  const [showMenu, setShowMenu] = useState(false);
  const [showCompanyMenu, setShowCompanyMenu] = useState(false);
  const [loadBackCompany, setLoadBackCompany] = useState(false);
  const [loadBackTenant, setLoadBackTenant] = useState(false);

  //account settings
  const [profileImg,setProfileImg] = useState("");
  const [currUser,setCurrUser] = useState("");
  const [imgChanged,setImgChanged] = useState(false);
  const [isAccount,setIsAccount] = useState(false);
  const [isEmailFormat,setIsEmailFormat] = useState(false);
  const [isPhoneFormat,setIsPhoneFormat] = useState(false);
  const [isProcessing,setIsProcessing] = useState(false);
  const [isConfirm,setIsConfirm] = useState(false);

  useEffect(()=>{
    const currData = currUsers.find(item => item.UserID === user?.uid)?.RoleID;
    if(currData !== undefined && tenant){
      dispatch(retrieveCurrRoles({TenantID:tenant,RoleID:currData}))
    }
  },[tenant])
 
  useEffect(() => {
    function handleClickOutside(event) {
        if (profileRef.current && !profileRef.current.contains(event.target)) {
            setShowMenu(false);
        }
    }
  
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
  
    // Unbind the event listener on cleanup
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [profileRef]);

  useEffect(() => {
    function handleClickOutside(event) {
        if (companyRef.current && !companyRef.current.contains(event.target)) {
            setShowCompanyMenu(false);
        }
    }
  
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
  
    // Unbind the event listener on cleanup
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [companyRef]);

  const handleLogout = async () => {
      if(user && user.uid){
          try {

            dispatch(retrieveCurrRoles({TenantID : tenant, RoleID : ""}));
            await set(ref(db, 'users/' + user.uid + '/fcm'), '')
              .then((result) => {
                  console.log("REMOVED NOTIFICATION TOKEN")
              })
              .catch(err => {
                  console.log(err)
              })
              await signOut(auth).then(()=>{navigate("/Auth");})
              removeFromLocalStorage("ACCESS_TOKEN");
              removeFromLocalStorage("REFRESH_TOKEN");
              removeFromLocalStorage("CURR_TENANT");
              removeFromLocalStorage("CURR_COMPANY");
              removeFromLocalStorage("CURR_ROLE");
              removeFromLocalStorage("ACCESS_TOKEN_EXPIRY");
              removeFromLocalStorage("LOGIN_EXPIRATION");
              removeFromLocalStorage("IS_SA");
              removeFromLocalStorage("IS_GA");
              navigate("/Auth");
          } catch (error) {
              console.log(error.message)
          }
      }
  };

  async function handleNavigateCompany(item){
    updateLocalStorage("CURR_COMPANY", item?.CompanyID);
    if(currSa){
        await Promise.resolve(navigate({ pathname: "/Preload", search: createSearchParams({ CompanyID: item?.CompanyID, RoleID: "SA" }).toString() }));
    }else{
        dispatch(retrieveCompanyAgent({TenantID:tenant,CompanyID:item?.CompanyID}))
        //SAGA
        // const currData = result.find(item => item.UserID === user?.uid)?.RoleID;
        const currData = getFromLocalStorage("CURR_ROLE")
        await Promise.resolve(navigate({ pathname: "/Preload", search: createSearchParams({ CompanyID: item?.CompanyID, RoleID: currData }).toString() }));
    }
  }

  async function handleNavigateAllCompany(){

    removeFromLocalStorage("CURR_COMPANY")
    setLoadBackCompany(true);
    
    dispatch(setCompanyID(null)),
    dispatch(retrieveCurrRoles({TenantID:tenant,RoleID:""}))

    await Promise.resolve(setLoadBackCompany(false));
    navigate("/Company");

  };

  async function handleBackToTenant(){

    removeFromLocalStorage("CURR_TENANT");
    removeFromLocalStorage("CURR_COMPANY");

    setLoadBackTenant(true);

    dispatch(setTenant(null)),
    dispatch(setIsSA(false))

    await Promise.resolve(setLoadBackCompany(false));
    navigate("/Tenant");

  };

  function navigateTo(param){
    navigate(`/${param}`);
  };

  //account settings
  useEffect(()=>{
    setIsPhoneFormat(phoneValidation(currUser?.phoneNum))
    setIsEmailFormat(emailValidation(currUser?.email))
    
  },[currUser])

  useEffect(()=>{
    setProfileImg(currUser.photoURL)
  },[currUser])

  function user_handleSnapshot(snapshot) {

    snapshot.forEach(function(childSnapshot) {
        var item = childSnapshot.val();
        item.key = childSnapshot.key;
        if(item.uid === user?.uid){
          setCurrUser(item)
        }
    });
    
  };

  useEffect(() => {

    const userRef = ref(db, "users/");

    try{
      onValue(userRef, (snapshot) => {
        user_handleSnapshot(snapshot);
      });
    }catch(err){
      console.log(err)
    }

  },[user, isAccount])

  function reloadUser() {
    const userRef = ref(db, "users/");

    try{
      onValue(userRef, (snapshot) => {
        user_handleSnapshot(snapshot)
      });
    }catch(err){
      console.log(err)
    }
  }

  const handleOnChangeFile = (e) => {

    for(var i = 0; i < e.target.files.length;i++)
    {
      const fileSize = e.target.files[i].size;
      const fileMB = Math.round((fileSize / 1024));
      if(fileMB <= 5120){
        setImgChanged(true)
        setProfileImg(e.target.files[0]);
      }   
      else
      {
        // notify("over-filesize")
      }
    }
  }

  const phoneValidation = (phoneNum) => {
    // let regexPhone = /^(\+?6?01)[02-46-9]-*[0-9]{7}$|^(\+?6?01)[1]-*[0-9]{8}$/;
    let internationRegexPhone = /^(?:\+)?(?:[0-9]●?){6,14}[0-9]$/;
    
    return internationRegexPhone.test(phoneNum);
  }

  const emailValidation = (email) => {
    let regexEmail = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
    // let regexEmailLength = /^[^\s@]{6,}@[^\s@]+\.[^\s@]+$/;

    return regexEmail.test(email);
  }

  const handleAccountInput = (e,type) =>{
    let updatedUser;
    switch(type){
      case "email":
        updatedUser = { ...currUser, email: e };
        setCurrUser(updatedUser);
        break;
      case "phoneNum":
        updatedUser = { ...currUser, phoneNum: e };
        setCurrUser(updatedUser);
        break;
      case "firstName":
        updatedUser = { ...currUser, firstName: e };
        setCurrUser(updatedUser);
        break;
      case "lastName":
        updatedUser = { ...currUser, lastName: e };
        setCurrUser(updatedUser);
        break;
      default:
        break;
    }
  }

  async function processFirebaseStorage(){
    var imgID = user.uid
    const storageRef = ref_storage(storage, "/memberProfile/" + imgID);
    const uploadTask = uploadBytesResumable(storageRef, profileImg);
    
     uploadTask.on('state_changed',
    (snapshot) => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log('Upload is ' + progress + '% done');
      switch (snapshot.state) {
        case 'paused':
          console.log('Upload is paused');
          break;
        case 'running':
          console.log('Upload is running');
          break;
      }
    }, 
    (error) => {
      console.log(error)
    }, 
    () => {
      getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
        const authUser = auth.currentUser; // Get the auth instance

          if (authUser) {
            authUser.getIdToken().then((idToken) => {
   
              if(idToken){
                set(ref(db, 'users/' + user?.uid + '/photoURL'), downloadURL);
                updateProfile(authUser, {
                  photoURL: downloadURL
                })
                .then(()=>{
                  setProfileImg(downloadURL);
                  const updatedUser = {...currUser, photoURL : downloadURL};
                  setCurrUser(updatedUser);
                })
              }
            })
            .catch((error) => {
              console.error("Error getting ID token:", error);
            });
        }
      });
    })
  }

  const updateAccount = async () =>{
    setIsProcessing(true);
    setIsConfirm(true);
    if(profileImg){
      const displayName = currUser?.firstName + " " + currUser?.lastName;
      await update(ref(db, 'users/' + user?.uid),{
        displayName:displayName,
        firstName:currUser?.firstName,
        lastName:currUser?.lastName,
        phoneNum:currUser?.phoneNum,
        email:currUser?.email,
        photoURL:profileImg

      })

      if(profileImg !== currUser.photoURL)
      {
        setImgChanged(false);
        await processFirebaseStorage().then(()=>{
            setTimeout(async ()=>{
              reloadUser()
              auth.currentUser.reload();
              setIsProcessing(false);
              await new Promise((resolve) => setTimeout(resolve, 2000));
              setProfileImg("")
              await new Promise((resolve) => setTimeout(resolve, 1000));
              setIsAccount(false);
              // notify("update")
            },1500)
        })
      }
      else
      {
        setProfileImg("");
        await new Promise((resolve) => setTimeout(resolve, 1000));
        setIsProcessing(false);
        await new Promise((resolve) => setTimeout(resolve, 1000));
        setProfileImg("")
        await new Promise((resolve) => setTimeout(resolve, 1000));
        setIsAccount(false);
        // notify("update")
      }
    }
  }

  return (
    <div className="header-container">
      <div className='companyOptionsMainContainer'>
        <div className='selectionContainer' onClick={() => { setShowCompanyMenu(true); }}>
          <span>{Array.isArray(companyList) && companyList?.find(item => item?.CompanyID === companyID)?.CompanyName}</span>
          <AiFillCaretDown className='icon' size={16}/>
        </div>
      </div>

      <div ref={companyRef} className={`dropdownCompanyContainer ${!showCompanyMenu && 'hideDropdownCompanyContainer'}`}>
        <div className='companyMoreActionContainer'>
          <div className='selectedCompany'>
            <span>{Array.isArray(companyList) && companyList?.find(item => item?.CompanyID === companyID)?.CompanyName}</span>
            <AiFillCheckCircle size={16}/>
          </div>
          <div className='navigatorContainer'>
            <span onClick={() => { (!loadBackCompany && !loadBackTenant) && handleNavigateAllCompany(); }}>See All Company</span>
            <div className={`loading-container ${!loadBackCompany && 'hide-loading-container'}`}>
              <div className="loading-spinner">
              </div>
            </div>
          </div>
          <div className='navigatorContainer'>
            <span onClick={() => { (!loadBackCompany && !loadBackTenant) && handleBackToTenant(); }}>See All Workspace</span>
            <div className={`loading-container ${!loadBackTenant && 'hide-loading-container'}`}>
              <div className="loading-spinner">
              </div>
            </div>
          </div>
        </div>
        <div className='companyListContainer'>
          <span className='title'>All Company</span>
          {Array.isArray(companyList) && companyList.length > 0 && [...companyList]
            .sort((a, b) => new Date(b.CreatedAt) - new Date(a.CreatedAt))
            .map((item, index) => (
              <div key={index} className='companyContainer' 
                onClick={() => {
                  handleNavigateCompany(item);
                }}
              >
                <span>{item.CompanyName}</span>
              </div>
            ))
          }
        </div>
      </div>

      <span className='headerTitle'>
        {props.title}
      </span>

      <div className='profileSelectionContainer'>
        <img className={`profileSelection ${props.hideMenu && 'hideMenu'}`} src={user?.photoURL} alt="" onClick={() => { setShowMenu(true); }}/>
      </div>

      <div ref={profileRef} className={`dropdownUserContainer ${!showMenu && 'hideDropdownUserContainer'}`}>
        <div className="user-profile-detail">
          <img src={user?.photoURL}/>
          <div className="detail-container">
            <span className="name">{user?.displayName}</span>
            <span className="email">{user?.email}</span>
          </div>
        </div>
        <div className='navigation-container' onClick={() => { setIsAccount(true); setShowMenu(false); setIsProcessing(false);setIsConfirm(false); }}>
          <span>Account Setting</span>
        </div>
        <div className='navigation-container' onClick={() => { navigateTo("ActivityLogs") }}>
          <span>Activity Logs</span>
        </div>
        <div className="profile-action-container" onClick={() => { handleLogout() }}>
          <HiOutlineLogout size={20}/>
          <span>Sign out</span>
        </div>
      </div>
    
      <div className={`${isAccount ? "account-container" : "hide-account-container"}`} style={{height: window.innerHeight}}>
          <div className='upper-container'>
            <div className='title'>
              <div><BiArrowBack cursor={"pointer"} onClick={()=>{setIsAccount(false);}}/></div>
              <span>My Profile</span>
            </div>
            <hr></hr>
            
            <div className='profile-body'> 
              <div className='profile-input-container'>
                <label>Upload Profile</label>
                <div className="account-component">
                  <img className="account-profile" src={imgChanged === true ? (profileImg !== undefined ? URL.createObjectURL(profileImg) : currUser?.photoURL) : currUser.photoURL === undefined ? "https://firebasestorage.googleapis.com/v0/b/zumax-crm.appspot.com/o/DefaultProfile%2Fdefault-profile.webp?alt=media&token=1e765b3f-1a0b-4bc0-97c1-6a5a6cef7b0f": currUser?.photoURL}/>
                  <input className="account-input-file" type="file" accept="image/png,image/jpeg" onChange={(e)=>{handleOnChangeFile(e)}}/>
                </div>
              </div>
              <div className='input-container'>
                <label>Email</label>
                <input className={`account-input ${currUser?.email === "" ? "error-border" : ""}`} type='email' value={currUser?.email} placeholder='john26@gmail.com' onChange={(e)=>{handleAccountInput(e.target.value,"email")}} disabled/>
                {currUser?.email === "" &&<label className='error-label'>Please enter your email</label>}
                {(!isEmailFormat && currUser?.email !== "") &&<label className='error-label'>Invalid email</label>}
              </div>
              <div className='input-container'>
                <label><span style={{color:"#d13a3a"}}>* </span>Phone No.</label>
                <input className={`account-input ${currUser?.phoneNum === "" ? "error-border" : ""}`} type='text' value={currUser?.phoneNum} placeholder='+60163334444' onChange={(e)=>{handleAccountInput(e.target.value,"phoneNum")}}/>
                {currUser?.phoneNum === "" &&<label className='error-label'>Please enter your phone number</label>}
                {(!isPhoneFormat && currUser?.phoneNum !== "") &&<label className='error-label'>Invalid phone number</label>}
              </div>
              <div className='input-container'>
                <label><span style={{color:"#d13a3a"}}>* </span>First Name</label>
                <input className={`account-input ${currUser?.firstName === "" ? "error-border" : ""}`} type='text' value={currUser?.firstName} placeholder='Brendan' onChange={(e)=>{handleAccountInput(e.target.value,"firstName")}}/>
                {currUser?.firstName === "" &&<label className='error-label'>Please enter your first name</label>}
              </div>
              <div className='input-container'>
                <label>Last Name</label>
                <input className={`account-input ${currUser?.lastName === "" ? "error-border" : ""}`} type='text' value={currUser?.lastName} placeholder='Tan' onChange={(e)=>{handleAccountInput(e.target.value,"lastName")}}/>
                
              </div>
             
            </div>
          </div>
          <div className='action-container'>
            <hr></hr>
            <div className='button-container'>
              {isConfirm ? 
                <div className={`py-1 loadingContainer`}>
                    <div className={`${isProcessing ? "loading-spinner" : "hide-loading-spinner"}`}>
                    </div>
                    {isProcessing ?
                    <>
                    <span>Processing your request.</span>
                    </>
                    :
                    <div className="finished-loading-container">
                    <span>Updated Successfully</span> 
                    <div><BsCheck2Circle size={18}  style={{fontWeight:"600",color:"green"}}/></div>
                    </div>}
                </div>
                :
                  <>
                      <C_DialogButton
                        onClick={()=>{
                          setIsAccount(false);
                        }}
                        color={'#6d45b9'}
                        background={'white'}
                        borderColor={ '#6d45b9'}
                        buttonText={"CANCEL"}
                      />
                      {(isEmailFormat && isPhoneFormat && currUser?.email !== "" && currUser?.phoneNum !== "" && currUser?.firstName !== "") ?
                        <C_DialogButton
                          color={"white"}
                          onClick={() => {updateAccount();}}
                          background={'#6d45b9'}
                          borderColor={'#6d45b9'}
                          buttonText={"SUBMIT"}
                        />
                        :
                        <C_DialogButton
                          color={"white"}
                          onClick={() => {}}
                          background={'#acacac'}
                          borderColor={'#acacac'}
                          buttonText={"SUBMIT"}
                        />
                      }
                    </>
              }
              
             
            </div>
          </div>
      </div>
      
    </div>
    
  )
}

export default Header