import { call, delay, put, takeLatest } from "redux-saga/effects";
import { 
    createClientGroupLeader, 
    createClientGroupLeaderError, 
    createClientGroupLeaderSuccess, 

    deleteClientGroupLeader, 
    deleteClientGroupLeaderError, 
    deleteClientGroupLeaderSuccess, 

    retrieveAllClientGroupLeader, 
    retrieveAllClientGroupLeaderError, 
    retrieveAllClientGroupLeaderSuccess, 
    
    retrieveClientGroupLeader, 
    retrieveClientGroupLeaderError, 
    retrieveClientGroupLeaderSuccess 
} from "../stores/slices/clientGroupLeaderSlices";

import { ApiRoute } from "../enums/ApiRoute";
import { API } from "../http-common";

function* createClientGroupLeaderSaga({payload}) {
    try{
        const { data } = yield call(API.post, ApiRoute.clientGroupLeader.createClientGroupLeader, payload);
        yield put(createClientGroupLeaderSuccess(data));
    } catch (error){
        yield put(createClientGroupLeaderError(error));
        console.error(error);
    }
}

function* retrieveClientGroupLeaderSaga({payload}) {
    try{
        const { data } = yield call(API.post, ApiRoute.clientGroupLeader.retrieveClientGroupLeader, payload);
        yield put(retrieveClientGroupLeaderSuccess(data));
    } catch (error){
        yield put(retrieveClientGroupLeaderError(error));
        console.error(error);
    }
}

function* retrieveAllClientGroupLeaderSaga({payload}) {
    try{
        const { data } = yield call(API.post, ApiRoute.clientGroupLeader.retrieveAllClientGroupLeader, payload);
        yield put(retrieveAllClientGroupLeaderSuccess(data));
    } catch (error){
        yield put(retrieveAllClientGroupLeaderError(error));
        console.error(error);
    }
}

function* deleteClientGroupLeaderSaga({payload}) {
    try{
        const { data } = yield call(API.post, ApiRoute.clientGroupLeader.deleteClientGroupLeader, payload);
        yield put(deleteClientGroupLeaderSuccess(data));
    } catch (error){
        yield put(deleteClientGroupLeaderError(error));
        console.error(error);
    }
}



export function* clientGroupLeaderSagaWatcher(){
    yield takeLatest(createClientGroupLeader.type, createClientGroupLeaderSaga);
    yield takeLatest(retrieveClientGroupLeader.type, retrieveClientGroupLeaderSaga);
    yield takeLatest(retrieveAllClientGroupLeader.type, retrieveAllClientGroupLeaderSaga);
    yield takeLatest(deleteClientGroupLeader.type, deleteClientGroupLeaderSaga);
}