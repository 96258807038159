const settings = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 577,
      md: 769,
      lg: 993,
      xl: 1201,
      xxl: 1401,
    },
  },
};

export default settings;
