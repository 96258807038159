import React, { useEffect, useState } from "react";
import { MainContentContainer, NewFormLayout } from "../AccContainers/Layout";

import {
  Button,
  CircularProgress,
  Stack,
  Typography,
  Box,
  IconButton,
} from "@mui/material";
import { useForm, useFieldArray } from "react-hook-form";
import { MainForm } from "./MainForm";
// import {
//   SnackType,
//   transactionActiveTax,
//   transactionIsPurchases,
//   transactionTypeNameEnum,
//   transferTransactionList,
// } from "@enums";
import { SnackType } from "../../enums/Snacks";
import { transactionActiveTax } from "../../enums/CompanySetting";
import { transactionIsPurchases } from "../../enums/Transaction";
import { transactionTypeNameEnum } from "../../enums/Transaction";
import { transferTransactionList } from "../../enums/Transaction";

import { useNavigation, useSearchParams } from "react-router-dom";
import { TableContent } from "../../constants/Invoicing/TableContent";
import { TABLE } from "../../constants/Invoicing/Table";
import { DeleteOutlineRounded, AddRounded } from "@mui/icons-material";
import { FormInputToggleButton } from "../../components/FormInput/FormInputToggleButton";
// import { TransferItemsTable } from "./Component/TransferItemsTable";
// import {retrieveTransaction, retrieveTransactionLoading }
import { 
    retrieveTransaction,
    selectRetrieveTransactionLoading,
    selectTransactionCustomFields,
    selectCurrentTransaction,
    selectTransactionsOptions,
    updateTransaction,
    selectUpdateTransactionLoading,
    clearTransactionState,
} from "../../stores/slices/transactionSlices";

import { selectDialogBox, toggleDialogBox } from "../../stores/slices/toggleSlice";
// import {
//   retrieveTransaction,
//   selectRetrieveTransactionLoading,
//   selectTransactionCustomFields,
//   selectCurrentTransaction,
//   selectTransactionsOptions,
//   updateTransaction,
//   selectUpdateTransactionLoading,
//   selectDialogBox,
//   toggleDialogBox,
//   clearTransactionState,
// } from "@store/slices";

import moment from "moment";
import useSnack from "../../hooks/useSnack";
// import {
//   getFormDirtyValues,
//   simpleM2MOrganize,
//   advanceM2MOrganize,
//   hasManyM2MOrganize,
// } from "../../";

import { getFormDirtyValues } from "../../util/getFormDirtyValues";
import { simpleM2MOrganize } from "../../util/associations/m2mOrganize";
import { advanceM2MOrganize } from "../../util/associations/advanceM2MOrganize";
import { hasManyM2MOrganize } from "../../util/associations/hasManyM2MOrganize";

import { DataTable } from "../../components/DataTableRenderer/DataTable";
import { useAppDispatch } from "../../hooks/storeHooks";
import { ConfirmationDialog } from "../../components/Utils";
import { globalAccCompanyID } from "../../http-common";

export const ViewTransaction = (props) => {
  const category = props.category;
  const subCategory = props.subCategory;
  const removeSearchParams = props.removeSearchParams;
  const todayDate = new Date();
  const defaultData = {
    customer_id: null,
    billing_attention: "",
    billing_party: "",
    shipping_info: "",
    shipping_arettention: "",
    shipping_party: "",
    payment_term_id: null,
    number_format_id: null,
    reference_no: "",
    date: moment(todayDate),
    system_currency_code: "",
    tag_ids: [],
    form_items: [],
    tax_inclusive: false,
    title: "",
    remarks: "",
    descriptions: "",
    internal_note: "",
    attachment_id: null,
    status: "READY",
  };
  const {
    control,
    watch,
    handleSubmit,
    formState: { isDirty, dirtyFields },
    resetField,
    setValue,
    reset,
    getValues,
  } = useForm({
    defaultValues: defaultData,
    shouldFocusError: true,
    shouldUnregister: false,
  });

  const { append: transferAppend, remove: transferRemove } = useFieldArray({
    control,
    name: "TransferredFormItems",
  });

  const { callSnack } = useSnack();
  const [searchParams] = useSearchParams();

  // const { subCategory, category, handleNavigate } = useNavigation();
  const transferTransactionTypeList =
    transferTransactionList[`${category}/${subCategory}`];
  const dispatch = useAppDispatch();
  const dialogBox = selectDialogBox();
  const [transferTransactionValue, setTransferTransaction] = useState([]);

  const customFields = selectTransactionCustomFields();
  const transactionOption = selectTransactionsOptions();
  const currentTransaction = selectCurrentTransaction();
  const transactionDefaultData = () => {
    const {
      tags,
      FormItems: form_items = [],
      TransactionPaymentTerms,
      date,
      ...otherData
    } = currentTransaction;
    const companySetting = transactionOption.company_setting
      ? transactionOption.company_setting
      : null;

    const activeTaxKey = transactionActiveTax[transactionType];
    const activeTax =
      companySetting !== null && companySetting[activeTaxKey] !== null
        ? companySetting[activeTaxKey]
        : false;

    return {
      ...defaultData,
      ...otherData,
      date: moment(new Date(date)),
      payment_terms:
        subCategory === "invoices"
          ? TransactionPaymentTerms.map((item) => {
              const { payment_term_id = null, due_on, ...otherItem } = item;
              if (payment_term_id) {
                return {
                  ...otherItem,
                  payment_term_id,
                };
              }
              return {
                ...item,
                due_on: moment(due_on),
              };
            })
          : TransactionPaymentTerms,
      tag_ids: tags.map((tag) => tag.tag_id),
      active_tax: activeTax,
      form_items: form_items.map((item) => {
        // const {  ...restItem } = item;
        // const existingTax = taxList.find((t) => t.value === tax);

        //! need fix for total_amount
        return {
          ...item,
          total_amount: (item.quantity * parseFloat(item.amount)).toFixed(2),
          tax: item.tax === "0" || item.tax === "" ? null : item.tax,
        };
      }),
    };
  };

  console.log(globalAccCompanyID)
  useEffect(() => {
    if(globalAccCompanyID){
      const transactionId = searchParams.get("transaction_id");
      if (!transactionId) {
        callSnack({
          type: SnackType.Error,
          message: "Field not found, redirecting to transaction page...",
          timeout: 3000,
        });
        setTimeout(() => {
          // handleNavigate(`/${category}/${subCategory}`);
        }, 3000);
      }
      dispatch(retrieveTransaction(transactionId));
  
      return () => {
        dispatch(clearTransactionState());
      };
    }
    
  }, [globalAccCompanyID]);
  
  useEffect(() => {
    if (currentTransaction) {
      setTimeout(() => {
        reset(transactionDefaultData());

        const transferredTransaction =
          currentTransaction.TransferredFormItems.map(
            (item) => item.transferred_transaction_id
          );
        setTransferTransaction(transferredTransaction);
      }, 100);
    }
  }, [currentTransaction]);
  const updateTransactionLoading = selectUpdateTransactionLoading();
  const retrieveTransactionLoading = selectRetrieveTransactionLoading();
  const transactionType =
    subCategory !== "payments" &&
    subCategory !== "refunds" &&
    subCategory !== "credit-notes"
      ? subCategory
      : `${category}-${subCategory}`;

  const handleUpdateTransaction = (data) => {
    const dirtyData = getFormDirtyValues(dirtyFields, data);

    if (Object.keys(dirtyData).length > 0) {
      const {
        payment_terms: paymentTerms = null,
        // form_items_total: unused,
        form_items: formItems = null,
        TransferredFormItems = null,
        tag_ids = null,
        date = null,
        ...dirtyDataMap
      } = dirtyData;
      const otherDirtyData = Object.keys(dirtyDataMap).reduce((obj, item) => {
        if (
          item !== "form_items_total" &&
          item !== "active_tax" &&
          item !== "due_date"
        ) {
          return {
            ...obj,
            [item]: dirtyDataMap[item],
          };
        }
        return obj;
      }, {});
      const paymentTermsObj =
        Array.isArray(paymentTerms) === true
          ? {
              payment_terms: paymentTerms.map((term, index) => {
                const { transaction_payment_term_id, due_on, ...mappingTerm } =
                  data.payment_terms[index];
                const paymentTermId = transaction_payment_term_id
                  ? {
                      transaction_payment_term_id: transaction_payment_term_id,
                    }
                  : mappingTerm;
                return {
                  ...term,
                  index: index,
                  ...mappingTerm,
                  due_on: due_on ? due_on.format("YYYY-MM-DD") : null,
                  ...paymentTermId,
                };
              }),
            }
          : { payment_terms: data.payment_terms };
      const tagsObj = tag_ids
        ? {
            tag_ids: simpleM2MOrganize(
              currentTransaction.tags,
              tag_ids,
              "tag_id"
            ),
          }
        : {};

      const formItemsObj =
        formItems !== null
          ? {
              form_items: hasManyM2MOrganize(
                currentTransaction.FormItems,
                data.form_items,
                formItems,
                "form_item_id",
                "form_item_id"
              ),
            }
          : {};

      const transferredFormItemObj =
        TransferredFormItems !== null
          ? {
              TransferredFormItems: advanceM2MOrganize(
                currentTransaction.TransferredFormItems,
                data.TransferredFormItems,
                TransferredFormItems,
                "transferred_item_id"
              ),
            }
          : {};
      const dateObj = date ? { date: date.format("YYYY-MM-DD") } : {};
      const payload = {
        transaction_id: data.transaction_id,
        type: `${category}/${subCategory}`,
        ...dateObj,
        ...paymentTermsObj,
        ...formItemsObj,
        ...transferredFormItemObj,
        ...tagsObj,
        ...otherDirtyData,
        removeSearchParams,
      };

      const {
        form_items = [],
        payment_terms = [],
        form_items_total = 0,
      } = data;
      if (form_items.length > 0) {
        if (subCategory === "invoices") {
          const paymentTermTotal = payment_terms.reduce((total, term) => {
            if (term.amount.endsWith("%")) {
              const value =
                (form_items_total * parseFloat(term.amount.replace(/%$/, ""))) /
                100;
              return total + value;
            }
            return total + parseFloat(term.amount);
          }, 0);

          if (
            form_items_total > paymentTermTotal ||
            paymentTermTotal > form_items_total
          ) {
            callSnack({
              type: SnackType.Error,
              message: "Payment term total must be equal to invoice total",
              timeout: 3000,
            });
          } else {
            dispatch(updateTransaction(payload));
          }
        } else {
          dispatch(updateTransaction(payload));
        }
      } else {
        callSnack({
          type: SnackType.Error,
          message: "Please add at least one items",
          timeout: 3000,
        });
      }
    }
  };

  const handleOpenTransferTransaction = () => {
    dispatch(
      toggleDialogBox({
        update: dialogBox.update,
        transfer: {
          data: dialogBox.transfer.data,
          isOpen: true,
        },
      })
    );
  };
  const handleTransferTransaction = () => {
    const dialogParams = {
      data: dialogBox.transfer.data,
      isOpen: false,
    };
    dispatch(
      toggleDialogBox({
        transfer: dialogParams,
      })
    );
  };
  const handleToggleCloseDialog = (isUpdate = false) => {
    const dialogParams = {
      data: null,
      isOpen: false,
    };
    dispatch(
      toggleDialogBox(
        isUpdate
          ? {
              update: dialogParams,
            }
          : {
              transfer: dialogParams,
            }
      )
    );
  };

  const handleUpdateTransferItem = () => {
    dispatch(
      toggleDialogBox({
        update: {
          data: null,
          isOpen: false,
        },
      })
    );
  };
  //!rmb to do
  const handleRemoveTransferTransaction = () => {};

  const deleteButton = () => {
    return (
      <Box sx={{ alignSelf: "center", textAlign: "center" }} width={"35px"}>
        <IconButton
          onClick={() => {
            handleRemoveTransferTransaction();
          }}
          sx={({ icons }) => ({
            ...icons.standard.default,
          })}
        >
          <DeleteOutlineRounded fontSize="inherit" />
        </IconButton>
      </Box>
    );
  };
  const transactionTitle = (transaction) => {
    const { transaction_no, date, FormItems } = transaction;
    return (
      <Stack direction={"row"} sx={{ gap: "10px" }}>
        <Typography
          sx={({ typography }) => ({
            ...typography["bodyTextBold"],
            fontSize: "18px",
          })}
        >
          {`${transaction_no}: ${FormItems[0].descriptions}`}
        </Typography>
        <Typography
          sx={({ typography }) => ({
            ...typography["bodyTextBold"],
            alignContent: "center",
            fontSize: "12px",
            border: "1px solid grey",
            borderRadius: "3px",
            padding: "2px",
          })}
        >
          {date}
        </Typography>
      </Stack>
    );
  };
  const formStatusList = [
    {
      label: "Draft",
      value: "DRAFT",
    },
    {
      label: "Pending Approval",
      value: "PENDING APPROVAL",
    },
    {
      label: "Ready",
      value: "READY",
    },
  ];
  return (
    <MainContentContainer
      removeSearchParams={removeSearchParams}
      fullHeight
      actionChildren={
        <Stack direction={"row"} spacing={2}>
          
          <FormInputToggleButton
            name={"status"}
            control={control}
            list={formStatusList}
          />
          <Button
            variant="contained"
            type="submit"
            onClick={handleSubmit(handleUpdateTransaction)}
            disabled={retrieveTransactionLoading || updateTransactionLoading}
          >
            {retrieveTransactionLoading || updateTransactionLoading ? (
              <CircularProgress
                size="20px"
                sx={({ palette }) => ({ color: palette.primary.white })}
                data-testid="create-custom-field-progressbar"
              />
            ) : (
              "Save"
            )}
          </Button>
        </Stack>
      }
    >
      {retrieveTransactionLoading ? (
        <CircularProgress
          size="20px"
          sx={({ palette }) => ({ color: palette.primary.white })}
          data-testid="create-custom-field-progressbar"
        />
      ) : (
        <NewFormLayout title={"Transactions"}>
          {
            <MainForm
              control={control}
              isDirty={isDirty}
              watch={watch}
              resetField={resetField}
              setValue={setValue}
              getValues={getValues}
              options={transactionOption ? transactionOption : {}}
              currentTransaction={currentTransaction ? currentTransaction : {}}
              customFields={customFields}
              activeTaxKey={transactionActiveTax[transactionType]}
              is_invoice={subCategory === "invoices"}
              isBill={subCategory === "bills"}
              isPurchase={transactionIsPurchases.includes(
                `${category}/${subCategory}`
              )}
              transferTransactionTypeList={transferTransactionTypeList}
            />
          }
        </NewFormLayout>
      )}

      <ConfirmationDialog
        open={dialogBox.update.isOpen}
        title={`Transfer Items from ${
          dialogBox.update.data
            ? transactionTypeNameEnum[dialogBox.update.data]
            : ""
        }`}
        stackChildren={
          <Stack key={"transfer_items_content"}>
            {dialogBox.transfer.data ? (
              dialogBox.transfer.data.list
                .filter((item) => {
                  return transferTransactionValue.includes(item.transaction_id);
                })
                .map((item, index) => {
                  return (
                    <Stack key={item.transaction_id}>
                      <Stack
                        direction={"row"}
                        sx={{ justifyContent: "space-between" }}
                      >
                        {transactionTitle(item)}
                        {deleteButton(index)}
                      </Stack>
                      {/* <TransferItemsTable
                        transaction={item}
                        transactionItemList={item.FormItems}
                        appendTransferItem={transferAppend}
                        removeTransferredItem={transferRemove}
                        control={control}
                        setValue={setValue}
                        watch={watch}
                        isViewTransaction={true}
                      /> */}
                    </Stack>
                  );
                })
            ) : (
              <></>
            )}

            <Button
              key={"transfer_items_button"}
              variant="contained"
              onClick={handleOpenTransferTransaction}
              sx={{
                width: 0.2,
                margin: "10px 10px",
              }}
              startIcon={
                <AddRounded
                  sx={({ icons, palette }) => ({
                    ...icons.standard,
                    color: palette.primary.main,
                  })}
                />
              }
            >
              {dialogBox.update.data}
            </Button>
          </Stack>
        }
        maxWidth={"lg"}
        handleClose={handleUpdateTransferItem}
        childrenSx={[
          {
            height: "500px",
          },
        ]}
        handleConfirm={handleUpdateTransferItem}
      />

      <ConfirmationDialog
        open={dialogBox.transfer.isOpen}
        title={`Add ${
          dialogBox.transfer.data
            ? transactionTypeNameEnum[dialogBox.transfer.data.title]
            : ""
        }`}
        handleClose={() => {
          handleToggleCloseDialog();
        }}
        maxWidth={"lg"}
        stackChildren={
          <DataTable
            // isLoading={retrieveProductGroupsLoading || removeProductGroupLoading}
            table={TableContent[TABLE.TRANSACTIONS.TRANSFERS_TRANSACTION]}
            tableData={
              dialogBox.transfer.data && dialogBox.transfer.data.list
                ? dialogBox.transfer.data.list
                : []
            }
            rowSelection={transferTransactionValue}
            onSelectRow={(row) => {
              // const selectedRows = dialogBox.transfer.data.list.filter((item) =>
              //   row.includes(item.transaction_id)
              // );
              // setValue("transferTransactionList", selectedRows);
              setTransferTransaction(row);
            }}
          />
        }
        childrenSx={[
          {
            height: "500px",
          },
        ]}
        handleConfirm={handleTransferTransaction}
      />
    </MainContentContainer>
  );
};
