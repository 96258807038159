import React, {
    useState,
    useEffect,
    useRef,
} from "react";
import moment from "moment";
import C_Button from "../../components/C_Button";
import "./Kpi.scss";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import C_Search from "../../components/C_Search";
import { v4 } from "uuid";
import { MdRemoveCircleOutline } from "react-icons/md";
import { Modal } from "bootstrap";
import { BiArrowBack } from "react-icons/bi";
import { TbListDetails } from "react-icons/tb";
import { BsClipboardData, BsFillInfoCircleFill } from "react-icons/bs";
import { AiFillCheckCircle, AiOutlineCheckCircle, AiOutlineClose, AiOutlineStock } from "react-icons/ai";
import { CgUserList } from "react-icons/cg";

import Switch from "react-switch";
import C_Loading from "../../components/C_Loading";

//slices
import { retrieveCurrUsage } from "../../stores/slices/usageSlices";
import { createKpi, selectKpiContext, setKpiContext } from "../../stores/slices/kpiSlices";
import { selectCompanyID } from "../../stores/slices/companyIDSlices";
import { selectTenant } from "../../stores/slices/tenantSlices";
import { selectCurrTenant } from "../../stores/slices/persistRolesSlices";
import { selectFormTemplate } from "../../stores/slices/formTemplateSlices";
import { selectCurrUserContext } from "../../stores/slices/companySlices";
import { selectUser } from "../../stores/slices/userAuthSlices";
  
  function NewKpi() {

    const {
        newKpiData,
    } = selectKpiContext();

    const sourceData = selectFormTemplate();

    const modalRef = useRef();
    
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = selectUser();

    const companyID = selectCompanyID();
    const tenant = selectCurrTenant();

    const [searchText, setSearchText] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const { currUsers } = selectCurrUserContext();
    const [memberList, setMemberList] = useState([]);
    const [fixedMemberList, setFixedMemberList] = useState([]);
    const [selectedMemberID, setSelectedMemberID] = useState([]);
    const [selectedMemberList, setSelectedMemberList] = useState([]);
    const [validationError, setValidationError] = useState(false);
    const [metricValueOptions, setMetricValueOptions] = useState(null);
    const [showInfoMetricValue, setShowInfoMetricValue] = useState(false);
    const [showInfoDueDate, setShowInfoDueDate] = useState(false);
    const [showInfoTeamContribution, setShowInfoTeamContribution] = useState(false);

    async function refreshUsage(){
        await Promise.resolve(dispatch(retrieveCurrUsage({TenantID:tenant,CompanyID:companyID})));
    };

    useEffect(() => {

        // const filteredData = currUsers.filter(data => data.status === true);
        const searchedData = currUsers.filter(item => {
            if(item.displayName.toString().toLocaleLowerCase().includes(searchText.toString().toLocaleLowerCase())){
                return item
            }
        })

        setFixedMemberList(currUsers)
        setMemberList(searchedData);

    }, [currUsers, searchText]);

    useEffect(() => {

        const filteredSelectedData = fixedMemberList.filter(obj => selectedMemberID.includes(obj.uid));
        setSelectedMemberList(filteredSelectedData)

    },[fixedMemberList, selectedMemberID]);

    useEffect(() => {
        dispatch(setKpiContext({newKpiData : {...newKpiData, targetMembers: selectedMemberList} }))
        // dispatchNewKpiData({type: "NEW_KPI_DATA", payload: {...newKpiData, targetMembers: selectedMemberList}})
    },[selectedMemberList])

    const showModalFunc = () => {
        const modalEle = modalRef.current;
        const bsModal = new Modal(modalEle, {
          backdrop: "static",
          keyboard: false,
        });
        bsModal.show();
      };
    
    const hideModalFunc = () => {
        const modalEle = modalRef.current;
        const bsModal = Modal.getInstance(modalEle);
        bsModal.hide();
    };

    function notify(param){
        dispatch(setKpiContext({triggerAction : param}));
        // dispatchTriggerAction({type: "TRIGGER", payload: param})
        setTimeout(() => {
            dispatch(setKpiContext({triggerAction : ''}));
            // dispatchTriggerAction({type: "TRIGGER", payload: ''})
        }, 1500)
      };

    function handleContinue(){
        if(
            newKpiData.title !== "" &&
            newKpiData.startDate !== "" &&
            newKpiData.dueDate !== "" &&
            newKpiData.sourceOfData !== "" &&
            newKpiData.measuredBy !== "" &&
            newKpiData.target !== ""
        ){
            if(newKpiData.measuredBy !== "Submission"){
                if(newKpiData.metricValue !== ""){
                    showModalFunc();
                    setValidationError(false);
                }else{
                    setValidationError(true);
                }
            }else{
                setValidationError(false);
                showModalFunc();
            }
        }else{
            setValidationError(true);
        }
    }

    function handleCreateKPI(){

        setIsLoading(true);

        const finalizedData = {
            ...newKpiData, 
            KpiID: v4(),
            CompanyID:companyID,
            assignedBy: user.uid,
            targetMembers: JSON.stringify(newKpiData.targetMembers),
            teamContribution: newKpiData.targetMembers?.length > 1 ? newKpiData.teamContribution : false,
            createdAt: moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
        };

        dispatch(createKpi({TenantID:tenant,data:finalizedData}))
        hideModalFunc();
        setTimeout(() => {
            notify("CREATED KPI");
            setSelectedMemberID([]);

            dispatch(setKpiContext({
                newKpiData : {
                    KpiID: "",
                    title: "",
                    startDate: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                    dueDate: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                    definition: "",
                    sourceOfData: "",
                    measuredBy: "",
                    metricValue: "",
                    target: "",
                    unitOfMeasurement: "",
                    targetMembers: "",
                    teamContribution: false,
                    createdAt: "",
                    assignedBy: ""
                }, 
                newKpi : false
            }))
            // dispatchNewKpiData({type: "NEW_KPI_DATA", payload: {
            //     KpiID: "",
            //     title: "",
            //     startDate: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            //     dueDate: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            //     definition: "",
            //     sourceOfData: "",
            //     measuredBy: "",
            //     metricValue: "",
            //     target: "",
            //     unitOfMeasurement: "",
            //     targetMembers: "",
            //     teamContribution: false,
            //     createdAt: "",
            //     assignedBy: ""
            // }})
            // dispatchNewKpi({type: "SHOW_NEW_KPI_FORM", payload: false})
            refreshUsage();
            setIsLoading(false);
        }, 2000)
          

    }

    function handleInput({type, value}){
        switch(type){
            case "title":
                dispatch(setKpiContext({newKpiData : {...newKpiData, title: value} }))
                // dispatchNewKpiData({type: "NEW_KPI_DATA", payload: {...newKpiData, title: value}})
                break;
            case "startDate":
                dispatch(setKpiContext({newKpiData : {...newKpiData, startDate: value} }))
                // dispatchNewKpiData({type: "NEW_KPI_DATA", payload: {...newKpiData, startDate: value}})
                break;
            case "dueDate":
                dispatch(setKpiContext({newKpiData : {...newKpiData, dueDate: value} }))
                // dispatchNewKpiData({type: "NEW_KPI_DATA", payload: {...newKpiData, dueDate: value}})
                break;
            case "definition":
                dispatch(setKpiContext({newKpiData : {...newKpiData, definition: value} }))
                // dispatchNewKpiData({type: "NEW_KPI_DATA", payload: {...newKpiData, definition: value}})
                break;
            case "sourceOfData":
                dispatch(setKpiContext({newKpiData : {...newKpiData, sourceOfData: value, measuredBy: "", metricValue: ""} }))
                // dispatchNewKpiData({type: "NEW_KPI_DATA", payload: {...newKpiData, sourceOfData: value, measuredBy: "", metricValue: ""}})
                const selectedSourceData = sourceData.find(obj => obj.FormID === value);
                setMetricValueOptions(selectedSourceData)
                break;
            case "measuredBy":
                dispatch(setKpiContext({newKpiData : {...newKpiData, measuredBy: value, metricValue: ""} }))
                // dispatchNewKpiData({type: "NEW_KPI_DATA", payload: {...newKpiData, measuredBy: value, metricValue: ""}})
                break;
            case "metricValue":
                dispatch(setKpiContext({newKpiData : {...newKpiData, metricValue: value} }))
                // dispatchNewKpiData({type: "NEW_KPI_DATA", payload: {...newKpiData, metricValue: value}})
                break;
            case "target":
                dispatch(setKpiContext({newKpiData : {...newKpiData, target: value} }))
                // dispatchNewKpiData({type: "NEW_KPI_DATA", payload: {...newKpiData, target: value}})
                break;
            case "unitOfMeasurement":
                dispatch(setKpiContext({newKpiData : {...newKpiData, unitOfMeasurement: value} }))
                // dispatchNewKpiData({type: "NEW_KPI_DATA", payload: {...newKpiData, unitOfMeasurement: value}})
                break;
            case "teamContribution":
                dispatch(setKpiContext({newKpiData : {...newKpiData, teamContribution: value} }))
                // dispatchNewKpiData({type: "NEW_KPI_DATA", payload: {...newKpiData, teamContribution: value}})
                break;
            default:
                break;
        }
    };

    function handleSelectMember(uid){
        setSelectedMemberID([...selectedMemberID, uid])
    }

    function removeSelectedMember(uid){
        setSelectedMemberID(selectedMemberID.filter(member => member !== uid));
    }

    function handleBack(){
        // dispatchNewKpiData({type: "NEW_KPI_DATA", payload: {
        //     KpiID: "",
        //     title: "",
        //     startDate: moment().format("YYYY-MM-DD"),
        //     dueDate: moment().format("YYYY-MM-DD"),
        //     definition: "",
        //     sourceOfData: "",
        //     measuredBy: "",
        //     metricValue: "",
        //     target: "",
        //     unitOfMeasurement: "",
        //     targetMembers: "",
        //     teamContribution: false,
        //     createdAt: "",
        //     assignedBy: ""
        // }})
        // dispatchNewKpi({type: "SHOW_NEW_KPI_FORM", payload: false})

        dispatch(setKpiContext({
            newKpiData : {
                KpiID: "",
                title: "",
                startDate: moment().format("YYYY-MM-DD"),
                dueDate: moment().format("YYYY-MM-DD"),
                definition: "",
                sourceOfData: "",
                measuredBy: "",
                metricValue: "",
                target: "",
                unitOfMeasurement: "",
                targetMembers: "",
                teamContribution: false,
                createdAt: "",
                assignedBy: ""
            }, 
            newKpi : false
        }))
    }

    return (
        <>
        <C_Loading isLoading={isLoading}/>
        <div className="modal fade" tabIndex="-1" ref={modalRef} style={{}}>
            <div className="modal-dialog modal-dialog-centered" style={{}}>
                <div className="modal-content px-3" style={{ minHeight: "556px", overflowY: "scroll" }}>
                    <div className="modal-header modalHeader">
                        <div className="title">
                            <CgUserList size={16}/>
                            <span>Target Members</span>
                        </div>
                        <AiOutlineClose cursor='pointer' size={16} onClick={() => { hideModalFunc() }}/>
                    </div>
                    <div className="modal-body d-flex row">
                        <div className={`teamContributionContainer ${selectedMemberList.length > 1 && 'showSettings'}`}>
                            <span className={`info ${showInfoTeamContribution && 'showInfo'}`}>Check this to combine members submission as <strong><em>TOTAL</em></strong> KPI</span>
                            <BsFillInfoCircleFill cursor="pointer" size={14}
                                onMouseEnter={() => { setShowInfoTeamContribution(true) }}
                                onMouseLeave={() => { setShowInfoTeamContribution(false) }}
                            />
                            <span>Team Contribution</span>
                            <Switch
                                checked={newKpiData.teamContribution}
                                onChange={(e) => { handleInput({value: e, type: "teamContribution"}) }}
                                onColor="#4BAB00"
                                onHandleColor="#FFFFFF"
                                handleDiameter={20}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={15}
                                width={35}
                            />
                        </div>
                        <div className="selectedListContainer">
                            {selectedMemberList.length === 0 && <span>Select target member for this KPI or do it later</span>}
                            {selectedMemberList.length > 0 && [...selectedMemberList]?.sort((a, b) => { 
                                if(a.uid === user?.uid) return -1;
                                if(b.uid === user?.uid) return 1;
                                return a.displayName.localeCompare(b.displayName);
                            }).map(item => {
                                return(
                                    <div key={item.uid} className="selectedMembers">
                                        <span>{item.displayName}{item.uid === user?.uid && <span>{" ("}You{")"}</span> }</span>
                                        <MdRemoveCircleOutline size={14} color="red" cursor="pointer" onClick={() => { removeSelectedMember(item.uid) }}/>
                                    </div>
                                )
                            })
                            }
                        </div>
                        <C_Search
                            placeholder="Search"
                            onChange={(e) => {
                                setSearchText(e.target.value);
                            }}
                            value={searchText}
                            searchText={searchText}
                            closeFunction={() => {
                                setSearchText("");
                            }}
                        />
                        <div className="memberListContainer">
                            {[...memberList]?.sort((a, b) => { 
                                if(a.uid === user?.uid) return -1;
                                if(b.uid === user?.uid) return 1;
                                return a.displayName.localeCompare(b.displayName);
                            }).map(item => {
                                const isSelected = selectedMemberID.includes(item.uid);
                                return(
                                    <div key={item.uid}
                                        className='memberContainer'
                                        onClick={() => { 
                                            if(isSelected){
                                                removeSelectedMember(item.uid)
                                            }else{
                                                handleSelectMember(item.uid)
                                            }
                                    }}>
                                        <span>{item.displayName}{item.uid === user?.uid && <span>{" ("}You{")"}</span> }</span>
                                        {isSelected ? <AiFillCheckCircle size={18} color="green"/> : <AiOutlineCheckCircle size={18}/>}
                                    </div>
                                )
                            })}
                        </div>
                        <div className="actionContainer">
                            <C_Button
                                width={"100%"}
                                buttonText={"CREATE NOW"}
                                justify={"center"}
                                onClick={() => {
                                    handleCreateKPI();
                                }}
                                textColor={"#FFFFFF"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="newKpiContainer">
            <div className="headerContainer">
                <div className="headerLeft">
                    <BiArrowBack style={{ cursor: 'pointer' }} size={22} onClick={() => { handleBack() }}/>
                    <span>New KPI</span>
                </div>
                <div className="headerRight">
                    {validationError && <span className="validationError">Please complete required columns to continue!</span>}
                    <C_Button
                        width={"110px"}
                        buttonText={"Continue"}
                        justify={"center"}
                        color={"#ffffff"}
                        border={"1px solid"}
                        borderColor={"#BCBCBC"}
                        onClick={() => {
                            handleContinue();
                        }}
                        background={"#4287f5"}
                    />
                </div>
            </div>
            <div className={`newKpiFormContainer`}>
                <div className="sectionMainContainer">
                    <div className="sectionTitle">
                        <div className="iconContainerStyle generalDetials">
                            <TbListDetails size={14}/>
                        </div>
                        <span>General Details</span>
                    </div>
                    <div className="inputSectionContainer">
                        <div className='inputField'>
                            <div className='inputTitle'>
                                <span>Title <span className="font-xxs" style={{color :"#f54d4d"}}>*</span></span>
                            </div>
                            <input
                                placeholder='Name this KPI'
                                value={newKpiData?.title}
                                onChange={(e) => {
                                    handleInput({value: e.target.value, type: "title"})
                                }}
                            />
                        </div>
                        <div className='inputField'>
                            <div className='inputTitle'>
                                <span>Start Date <span className="font-xxs" style={{color :"#f54d4d"}}>*</span></span>
                            </div>
                            <input
                                type="date"
                                max={newKpiData?.dueDate}
                                value={newKpiData?.startDate}
                                onChange={(e) => {
                                    handleInput({value: e.target.value, type: "startDate"})
                                }}
                            />
                        </div>
                        <div className='inputField'>
                            <div className='inputTitle'>
                                <span>Due Date <span className="font-xxs" style={{color :"#f54d4d"}}>*</span></span>
                                <span className={`info ${showInfoDueDate && 'showInfo'}`}>Calculation will be finalized until the defined <strong><em>Due Date</em></strong></span>
                                <BsFillInfoCircleFill cursor="pointer" size={14}
                                    onMouseEnter={() => { setShowInfoDueDate(true) }}
                                    onMouseLeave={() => { setShowInfoDueDate(false) }}
                                />
                            </div>
                            <input
                                type="date"
                                min={newKpiData?.startDate}
                                value={newKpiData?.dueDate}
                                onChange={(e) => {
                                    handleInput({value: e.target.value, type: "dueDate"})
                                }}
                            />
                        </div>
                    </div>
                    <div className="inputSectionContainer">
                        <div className='inputField'>
                            <div className='inputTitle'>
                                <span>Definition (Optional)</span>
                            </div>
                            <textarea 
                                placeholder="A clear and concise definition of the KPI, including what it measures and why it is important" 
                                value={newKpiData?.definition}
                                onChange={(e) => {
                                    handleInput({value: e.target.value, type: "definition"})
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className="sectionMainContainer">
                    <div className="sectionTitle">
                        <div className="iconContainerStyle dataSource">
                            <BsClipboardData size={14}/>
                        </div>
                        <span>Data Source</span>
                    </div>
                    <div className="inputSectionContainer">
                        <div className='inputField'>
                            <div className='inputTitle'>
                                <span>Source of data <span className="font-xxs" style={{color :"#f54d4d"}}>*</span></span>
                                <span className="desc">The source of the data used to calculate the KPI</span>
                            </div>
                            <select
                                value={newKpiData?.sourceOfData}
                                onChange={(e) => {
                                    handleInput({value: e.target.value, type: "sourceOfData"})
                                }}
                            >
                                <option value="" disabled>Choose an option to continue</option>
                                {sourceData?.map(source => {
                                    return(
                                        <option key={source.FormID} value={source.FormID}>{source.FormName}</option>
                                    )
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="inputSectionContainer">
                        <div className='inputField'>
                            <div className='inputTitle'>
                                <span>Measured by <span className="font-xxs" style={{color :"#f54d4d"}}>*</span></span>
                            </div>
                            <select
                                className={`${newKpiData.sourceOfData === "" && "disableSelection"}`}
                                disabled={newKpiData.sourceOfData === "" ? true : false}
                                value={newKpiData?.measuredBy}
                                onChange={(e) => {
                                    handleInput({value: e.target.value, type: "measuredBy"})
                                }}
                            >
                                <option value="" disabled>{newKpiData.sourceOfData === "" ? "-" : "Choose an option"}</option>
                                <option value='Submission'>Submission</option>
                                <option value='Data Value'>Data value</option>
                            </select>
                        </div>
                        <div className='inputField'>
                            <div className='inputTitle'>
                                <span>Metric value {newKpiData.measuredBy !== "Submission" && <span className="font-xxs" style={{color :"#f54d4d"}}>*</span>}</span>
                                <span className={`info ${showInfoMetricValue && 'showInfo'}`}>Metric Value must be in type of <strong><em>Number</em></strong> or <strong><em>Decimal</em></strong> to perform calculation.</span>
                                <BsFillInfoCircleFill cursor="pointer" size={14}
                                    onMouseEnter={() => { setShowInfoMetricValue(true) }} 
                                    onMouseLeave={() => { setShowInfoMetricValue(false)  }}
                                />
                            </div>
                            <select
                                className={`${(newKpiData.measuredBy === "Submission" || newKpiData.measuredBy === "") && "disableSelection"}`}
                                disabled={(newKpiData.measuredBy === "Submission" || newKpiData.measuredBy === "") ? true : false}
                                value={newKpiData?.metricValue}
                                onChange={(e) => {
                                    handleInput({value: e.target.value, type: "metricValue"})
                                }}
                            >
                                <option value="" disabled>{(newKpiData.measuredBy === "Submission" || newKpiData.measuredBy === "") ? "-" : "Choose an option"}</option>
                                {metricValueOptions && metricValueOptions.FormData.map(value => {
                                    if(value.questionType === "Number" || value.questionType === "Decimal"){
                                        return(
                                            <option key={value.questionID} value={value.questionID}>{value.questionName}</option>
                                        )
                                    }
                                })
                                }
                            </select>
                        </div>
                    </div>
                </div>
                
                <div className="sectionMainContainer">
                    <div className="sectionTitle">
                        <div className="iconContainerStyle metrics">
                            <AiOutlineStock size={14}/>
                        </div>
                        <span>Metrics</span>
                    </div>
                    <div className="inputSectionContainer">
                        <div className='inputField'>
                            <div className='inputTitle'>
                                <span>Target <span className="font-xxs" style={{color :"#f54d4d"}}>*</span></span>
                            </div>
                            <input
                                type='number'
                                min="0"
                                placeholder='Target to achieve this KPI'
                                value={newKpiData?.target}
                                onChange={(e) => {
                                    handleInput({value: e.target.value, type: "target"})
                                }}
                            />
                        </div>
                        <div className='inputField'>
                            <div className='inputTitle'>
                                <span>Unit of measurement (Optional)</span>
                            </div>
                            <input
                                placeholder='e.g. RM, USD, Hours, Days...'
                                value={newKpiData?.unitOfMeasurement}
                                onChange={(e) => {
                                    handleInput({value: e.target.value, type: "unitOfMeasurement"})
                                }}
                            />
                        </div>
                    </div>
                </div>

            </div>
        </div>
        </>
    );
  }
  
  export default NewKpi;
  