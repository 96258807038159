import { call, delay, put, takeLatest } from "redux-saga/effects";
import { ApiRoute } from "../enums/ApiRoute";
import { createClientGroupRoleAgents, createClientGroupRoleAgentsError, createClientGroupRoleAgentsSuccess, deleteClientGroupRoleAgents, deleteClientGroupRoleAgentsError, deleteClientGroupRoleAgentsSuccess, getClientGroupRoleAgents, getClientGroupRoleAgentsError, getClientGroupRoleAgentsSuccess } from "../stores/slices/clientGroupRoleAgentsSlices";
import { API } from "../http-common";


function* createClientGroupRoleAgentsSaga({payload}) {
    //data is array
    try{
        yield call(API.post, ApiRoute.clientGroupRoleAgents.createClientGroupRoleAgents, payload);
        yield put(createClientGroupRoleAgentsSuccess(payload));
    } catch (error){
        
        yield put(createClientGroupRoleAgentsError(error));
        console.error(error);
    }
}

function* deleteClientGroupRoleAgentsSaga({payload}) {
    try{
        yield call(API.post, ApiRoute.clientGroupRoleAgents.deleteClientGroupRoleAgents, payload);
        yield put(deleteClientGroupRoleAgentsSuccess(payload));
    } catch (error){
        
        yield put(deleteClientGroupRoleAgentsError(error));
        console.error(error);
    }
}

export function* clientGroupRoleAgentsSagaWatcher(){
    yield takeLatest(createClientGroupRoleAgents.type, createClientGroupRoleAgentsSaga);
    yield takeLatest(deleteClientGroupRoleAgents.type, deleteClientGroupRoleAgentsSaga);
}