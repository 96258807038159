import React from "react";
import PropTypes from "prop-types";
import { Stack } from "@mui/material";
import { validatorRequired, validatorNumber } from "../../../util/validators";

import {
  FormInputDate,
  FormInputText,
  FormInputDropdownSearch,
} from "../../../components/FormInput";
import { FormInputContainer } from "../../AccContainers/Global";
import forms from "../../../styles/theme/forms";

export const CustomFieldsForm = ({ control, customFields }) => {
  // const formInputWidthFull = { ...forms.oneCol };
  const formInputWidth = { ...forms.twoCol };

  return (
    <Stack
      component="div"
      rowGap="30px"
      spacing={1}
      paddingY={3}
      width={0.9}
      sx={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-between",
        alignItems: "flex-start",
        paddingLeft: "30px"
      }}
    >
      {customFields.map((custom_field) => {
        const pattern =
          custom_field.data_type == "AMOUNT"
            ? { pattern: validatorNumber() }
            : {};
        const required = custom_field.required
          ? { required: validatorRequired() }
          : {};
        if (
          custom_field.data_type == "TEXT" ||
          custom_field.data_type == "AMOUNT"
        ) {
          return (
            <FormInputContainer
              key={custom_field.custom_field_id}
              minWidth={formInputWidth}
              maxWidth={formInputWidth}
              label={custom_field.name}
              renderInput={
                <FormInputText
                  control={control}
                  type="text"
                  name={`custom_fields.${custom_field.name}`}
                  label={custom_field.name}
                />
              }
              required={custom_field.required}
              rules={{
                ...required,
                ...pattern,
              }}
            />
          );
        }

        if (custom_field.data_type == "DATE") {
          return (
            <FormInputContainer
              key={custom_field.custom_field_id}
              minWidth={formInputWidth}
              maxWidth={formInputWidth}
              label={custom_field.name}
              renderInput={
                <FormInputDate
                  key={custom_field.custom_field_id}
                  control={control}
                  name={`custom_fields.${custom_field.name}`}
                  label={custom_field.name}
                  required={custom_field.required}
                  rules={{
                    ...required,
                  }}
                />
              }
            />
          );
        }

        if (custom_field.data_type == "DROPDOWN") {
          const dropdownOptionList = custom_field.dropdown_options;

          return (
            <FormInputContainer
              key={custom_field.custom_field_id}
              minWidth={formInputWidth}
              maxWidth={formInputWidth}
              label={custom_field.name}
              renderInput={
                <FormInputDropdownSearch
                  key={custom_field.custom_field_id}
                  control={control}
                  type="text"
                  options={dropdownOptionList}
                  required={custom_field.required}
                  name={`custom_fields.${custom_field.name}`}
                  label={custom_field.name}
                  rules={{
                    ...required,
                  }}
                />
              }
            />
          );
        }
      })}
    </Stack>
  );
};

CustomFieldsForm.propTypes = {
  control: PropTypes.any,
  customFields: PropTypes.any,
};
