//react
import React, { useEffect, useState, useRef, Fragment } from 'react';
import { CodeBlock, CopyBlock, dracula } from 'react-code-blocks';
import './ClientGroup.scss'
import { TbAlertCircle, TbCircleCheck, TbCopy } from "react-icons/tb"
import { apiUrl } from '../../constants/env';
import { selectClientContext } from '../../stores/slices/clientSlices';
import { selectCurrUserContext } from '../../stores/slices/companySlices';
import { createClientGroupRoles, deleteClientGroupRoles, getClientGroupRoles, selectClientGroupRoles, setClientGroupRoles } from '../../stores/slices/clientGroupRolesSlices';
import { selectFirebaseAuth } from '../../stores/slices/firebaseAuthSlices';
import { useDispatch } from 'react-redux';
import { v4 } from 'uuid';
import { Modal } from 'bootstrap';
import { HiMiniUser, HiOutlineEye, HiOutlinePencilSquare, HiOutlinePlusCircle, HiOutlineTrash } from 'react-icons/hi2';
import { AiOutlineClose } from 'react-icons/ai';
import C_PopoutNoti from '../../components/C_PopoutNoti';
import Switch from "react-switch";

function ClientGroupPermission({ groupID, companyID, tenantID }) {

    const {
        selectedGroup,
    } = selectClientContext();
    const dispatch = useDispatch();

    const [deleteRoleID, setDeleteRoleID] = useState("");
    const [notifyMessage, setNotifyMessage] = useState("");
    const [actionType, setActionType] = useState("");
    const [roles, setRoles] = useState([])
    const [roleNameErr, setRoleNameErr] = useState(false);
    const [roleDetails, setRoleDetails] = useState({
        ClientGroupRoleID : v4(),
        ClientGroupRoleName : "",
        GroupID : groupID,
        Description : "",
        AssignedMember : [],
        Client_Manage : false,
        Client_Export : false,
        Client_Delete : false,
        Client_Transfer : false,
        ViewableColumn : [],
        EditableColumn : [],
        
    })

    const { currUserSA } = selectCurrUserContext();
    const  clientGroupRoles  = selectClientGroupRoles();

    const { user } = selectFirebaseAuth();
 
    const viewableModalRef = useRef();
    const deleteModalRef = useRef();

    useEffect(() => {
        if (notifyMessage) {
          setTimeout(() => {
            setNotifyMessage("");
          }, 2500);
        }
    }, [notifyMessage]);

    useEffect(()=>{
        dispatch(getClientGroupRoles({TenantID : tenantID, CompanyID : companyID, GroupID : groupID}))
    }, [])

    useEffect(()=>{
        if(clientGroupRoles){
            setRoles(clientGroupRoles);
        }
    }, [clientGroupRoles])

    function isJSON(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    const ColumnData = isJSON(selectedGroup?.ColumnData) ? JSON.parse(selectedGroup?.ColumnData) : selectedGroup?.ColumnData;

    const showViewableModal = () => {
        const modalEle = viewableModalRef.current;
        const bsModal = new Modal(modalEle, {
            backdrop: "static",
            keyboard: false,
        });
        bsModal.show();
        
        };

    const hideViewableModal = () => {
        const modalEle = viewableModalRef.current;
        const bsModal = Modal.getInstance(modalEle);
        bsModal.hide();
    };

    const showDeleteModal = () => {
        const modalEle = deleteModalRef.current;
        const bsModal = new Modal(modalEle, {
            backdrop: "static",
            keyboard: false,
        });
        bsModal.show();
        
        };

    const hideDeleteModal = () => {
        const modalEle = deleteModalRef.current;
        const bsModal = Modal.getInstance(modalEle);
        bsModal.hide();
    };


    function updateRoleDetails(){
        const originalRole = [...clientGroupRoles];
        const id = roleDetails.ClientGroupRoleID;
        const roleIndex = originalRole.findIndex(role => role.ClientGroupRoleID === id);
        if(roleDetails.ClientGroupRoleName === ""){
            setRoleNameErr(true);
            return;
        }

        if(roleIndex !== -1){

            const oldAssignedMember = originalRole[roleIndex].AssignedMember;
            const newAssignedMember = roleDetails.AssignedMember;

            const updatedRoleDetails = {
                ClientGroupRoleID : roleDetails.ClientGroupRoleID,
                ClientGroupRoleName : roleDetails.ClientGroupRoleName,
                GroupID : roleDetails.GroupID,
                Description : roleDetails.Description,
                Client_Manage : roleDetails.Client_Manage,
                Client_Export : roleDetails.Client_Export,
                Client_Delete : roleDetails.Client_Delete,
                Client_Transfer : roleDetails.Client_Transfer,
                ViewableColumn : JSON.stringify(roleDetails.ViewableColumn),
                EditableColumn : JSON.stringify(roleDetails.EditableColumn)
            }

            console.log(updatedRoleDetails)

            dispatch(setClientGroupRoles({
                TenantID: tenantID,
                CompanyID : companyID,
                GroupID : groupID,
                oldAssignedMember : oldAssignedMember,
                newAssignedMember : newAssignedMember,
                RoleDetails : roleDetails, //set state
                data : updatedRoleDetails, //api
                setNotifyMessage
            }))
            hideViewableModal();
        } 
    }

    function createRoleDetails(){
        // const roleArr = [...roles];
        if(roleDetails.ClientGroupRoleName === ""){
            setRoleNameErr(true);
            return;
        }

        const assignedMember = roleDetails.AssignedMember;

        //without assignedMember
        const updatedRoleDetails = {
            ClientGroupRoleID : roleDetails.ClientGroupRoleID,
            ClientGroupRoleName : roleDetails.ClientGroupRoleName,
            GroupID : roleDetails.GroupID,
            Description : roleDetails.Description,
            Client_Manage : roleDetails.Client_Manage,
            Client_Export : roleDetails.Client_Export,
            Client_Delete : roleDetails.Client_Delete,
            Client_Transfer : roleDetails.Client_Transfer,
            ViewableColumn : JSON.stringify(roleDetails.ViewableColumn),
            EditableColumn : JSON.stringify(roleDetails.EditableColumn)
        }
        dispatch(createClientGroupRoles({
            TenantID : tenantID, 
            CompanyID : companyID,
            GroupID : groupID,
            data : updatedRoleDetails,
            RoleDetails : roleDetails,
            AssignedMember : assignedMember,
            setNotifyMessage
        }))

        hideViewableModal();
    }

    function handleNewRole(){
        const ClientGroupRoleID = v4();
        setRoleNameErr(false);
        setRoleDetails({
            ClientGroupRoleID : ClientGroupRoleID,
            ClientGroupRoleName : "",
            GroupID : groupID,
            Description : "",
            Client_Manage : false,
            Client_Export : false,
            Client_Delete : false,
            Client_Transfer : false,
            AssignedMember : [],
            ViewableColumn : [],
            EditableColumn : [],
        });
    }
    
    function handleEditRole(clientGroupRoleID){
        setRoleNameErr(false);
        const roleDetails = roles.find(role => role.ClientGroupRoleID === clientGroupRoleID);

        if(roleDetails){
            setRoleDetails(roleDetails);
            showViewableModal();
        }
    }

    function handleGroupRoleInput({value, type}){
        switch(type){
            case "RoleName" : 
                setRoleDetails({...roleDetails, ClientGroupRoleName : value});
                break;

            case "Description" : 
                setRoleDetails({...roleDetails, Description : value});
                break;

            case "Member" : 
                const memberList = roleDetails.AssignedMember;
                const isMemberSelected = memberList.includes(value);
                if(isMemberSelected){
                    const updatedList = memberList.filter(member => member !== value);
                    setRoleDetails({...roleDetails, AssignedMember : updatedList});
                } else {
                    setRoleDetails({...roleDetails, AssignedMember : [...memberList, value]});
                }
                break;
            case "Client_Manage" : 
                setRoleDetails({...roleDetails, Client_Manage : value});
                break;
            case "Client_Export" : 
                setRoleDetails({...roleDetails, Client_Export : value});
                break;
            case "Client_Delete" : 
                setRoleDetails({...roleDetails, Client_Delete : value});
                break;
            case "Client_Transfer" : 
                setRoleDetails({...roleDetails, Client_Transfer : value});
                break;
            case "Editable Column" :
                const editableList = roleDetails.EditableColumn;
                const editableColumnSelected = editableList.includes(value);
                if(editableColumnSelected){
                    const updatedList = editableList.filter(col => col !== value);
                    setRoleDetails({...roleDetails, EditableColumn : updatedList});
                } else {
                    setRoleDetails({...roleDetails, EditableColumn : [...editableList, value]});
                }
                break;

            case "Viewable Column" :
                const viewColumnList = roleDetails.ViewableColumn;
                const editColumnList = roleDetails.EditableColumn;
                const isColumnSelected = viewColumnList.includes(value);
                if(isColumnSelected){
                    const viewList = viewColumnList.filter(col => col !== value);
                    const editList = editColumnList.filter(col => viewList.includes(col));
                    setRoleDetails({...roleDetails, ViewableColumn : viewList, EditableColumn: editList});
                } else {
                    const viewList = [...viewColumnList,value];
                    const editList = editColumnList.filter(col => viewList.includes(col));
                    setRoleDetails({...roleDetails, ViewableColumn : viewList, EditableColumn: editList});
                }
                break;
        }
    }

    function handleRemoveRole(clientGroupRoleID){
        dispatch(deleteClientGroupRoles({ClientGroupRoleID : clientGroupRoleID, TenantID : tenantID, setNotifyMessage}));
    }

    return (
        <div className="client-group-permission-main-container">
            <C_PopoutNoti triggerSaved={notifyMessage} />
            <div className="client-group-permission-sub-container">
                <span className='permission-title'>Group Roles *</span>
                <div className='permission-card-container'>
                    <div className='permission-card-inner-action-container'>
                        <span>Each member can only have <strong>1 role</strong> for each group.</span>
                        <div className='permission-card-inner-container--add-button' 
                            onClick={() => {
                                setActionType("Create")
                                showViewableModal();
                                handleNewRole();
                            }}
                        >New Role <div><HiOutlinePlusCircle size={15}/></div></div>
                    </div>
                    <div className='permission-card-inner-container'>
                        {roles.length > 0 ? roles.map(roles => {
                            const memberCount = roles.AssignedMember.length;
                            const viewableColumnCount = roles.ViewableColumn.length;
                            const editableColumnCount = roles.EditableColumn.length;
                            // const columnCount = roles.ViewableColumn.length;
                            return (
                                <div className='permission-card-inner-container--card' >
                                    <div className='title-description-container'>
                                        <span className='title'>
                                            {roles.ClientGroupRoleName}
                                        </span>
                                        <div className='description-container'>
                                            <span className='description'>
                                                {roles.Description}
                                            </span>
                                        </div>
                                    </div>
                                    
                                    <div className='permission-card-information-container'>
                                           <div className='information'>
                                                <span className='count'>{memberCount}</span>
                                                <span className='category'>Member(s)</span>
                                           </div>
                                           <div className='information'>
                                                <span className='column-count'>{viewableColumnCount}</span>
                                                <span className='category'>View Column(s)</span>
                                           </div>
                                           <div className='information'>
                                                <span className='column-count'>{editableColumnCount}</span>
                                                <span className='category'>Edit Column(s)</span>
                                           </div>
                                    </div>
                                    <div className='action-buttons-container'>
                                        <div className='edit-view-buttons-container'>
                                            <div className='edit-button buttons' onClick={()=>{
                                                setActionType("Edit");
                                                handleEditRole(roles.ClientGroupRoleID);
                                            }}>
                                                <span>Edit</span>
                                                <div><HiOutlinePencilSquare size={13}/></div>
                                            </div>
                                            <div className='view-button buttons' onClick={()=>{
                                                setActionType("View");
                                                handleEditRole(roles.ClientGroupRoleID);
                                            }}>
                                                <span>View</span>
                                                <div><HiOutlineEye size={14}/></div>
                                            </div>
                                        </div>
                                        <div className='delete-button-container' onClick={()=>{
                                                setActionType("Delete");
                                                // handleRemoveRole(roles.ClientGroupRoleID);
                                                setDeleteRoleID(roles.ClientGroupRoleID);
                                                showDeleteModal();
                                            }}>
                                            <div className='delete-button buttons'>
                                                <span>Delete</span>
                                                <div><HiOutlineTrash size={14}/></div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            )
                        }) : <div className='empty-permissions-container'>
                                <span>You have no roles at the moment. Create now !</span>
                            </div>}
                    </div>
                </div>
            </div>
            <div
                className="modal fade"
                id=""
                tabIndex="-1"
                ref={viewableModalRef}
            >
                <div className="modal-dialog modal-dialog-centered" style={{}}>
                    <div className="modal-content px-3" style={{}}>
                        <div className="modal-header modalHeader">
                            <div className="viewable-modal-title">
                              Group Role
                            </div>
                            <AiOutlineClose
                                cursor="pointer"
                                size={16}
                                onClick={() => {
                                    hideViewableModal();
                                }}
                            />
                        </div>
                        <div className='modal-body viewable-modal-body'>
                            <div className='viewable-modal-inner-body'>
                                <div className="roles-input-container">
                                    <span className='title'>{actionType !== "View" ? "Role Name *" : "Role Name"}</span>
                                    <input 
                                        className={`name-input ${roleNameErr ? "error-name-input" : ""}`}
                                        placeholder='Give this role a name'
                                        value={roleDetails?.ClientGroupRoleName}
                                        onChange={(e) => {
                                            handleGroupRoleInput({value: e.target.value, type: "RoleName"})
                                        }}
                                        disabled={actionType === "View" ? true : false}
                                    />
                                    {roleNameErr && <span className='error-span'>Make sure role name is not empty !</span>}
                                </div>
                                <div className="roles-input-container">
                                    <span className='title'>{actionType !== "View" ? "Description *" : "Description"}</span>
                                    <input 
                                        className='name-input'
                                        placeholder='Give this role a name'
                                        value={roleDetails?.Description}
                                        onChange={(e) => {
                                            handleGroupRoleInput({value: e.target.value, type: "Description"})
                                        }}
                                        disabled={actionType === "View" ? true : false}
                                    />
                                </div>
                                
                                {actionType === "View" && 
                                    <div className="roles-input-container">
                                        <span className='title'>Member</span>
                                        <div className='selector-view-container'>
                                            {currUserSA ? currUserSA.filter(item => item.uid !== user?.uid).map((item)=>{
                                                const memberList = roleDetails.AssignedMember;
                                                const isSelected = memberList.includes(item.uid);
                                                if(isSelected){
                                                    return(
                                                        <div className='selector-view-container--column'>
                                                            <span className='item' title={item.displayName}>{item.displayName}</span>
                                                        </div>
                                                    )
                                                }
                                            }): <span> 0 members</span>}
                                        </div>
                                    </div>
                                }

                                {(actionType === "Edit" || actionType === "Create") && 
                                    <div className="roles-input-container">
                                        <span className='title'>Member *</span>
                                        <div className='member-selector-container'>
                                            {currUserSA ? currUserSA.filter(item => item.uid !== user?.uid).map((item)=>{
                                                const memberList = roleDetails.AssignedMember;
                                                const isSelected = memberList.includes(item.uid);
                                                return (
                                                    <label 
                                                        className='member' 
                                                        title={item.displayName} 
                                                        style={{ cursor: 'pointer',display: 'flex', alignItems: 'center', justifyContent: "space-around" }}
                                                        >
                                                        <input 
                                                            type='checkbox' 
                                                            style={{ width: '13px', height: '13px' }} 
                                                            checked={isSelected}
                                                            onClick={()=>{handleGroupRoleInput({value : item.uid, type : "Member"});}}
                                                            disabled={actionType === "View" ? true : false}
                                                        />
                                                        <span className={`checkmark ${actionType === "View" ? "disabled-checkmark" : ""}`}></span>
                                                        <span className='font-xxs list-items' style={{paddingLeft: '10px', fontWeight: 'normal', width: "85%"}}>{item.displayName}</span>
                                                    </label>
                                                )
                                            }) : <span> 0 members</span>}
                                        </div>
                                    </div>
                                }

                                {actionType === "View" && 
                                <Fragment>
                                    <div className="roles-input-container">
                                        <span className='title'>Viewable Columns</span>
                                        <div className='selector-view-container'>
                                            {ColumnData.map((col)=>{
                                                // const columnList = roleDetails.ViewableColumn;
                                                const viewableColumnList = roleDetails.ViewableColumn;
                                                const isSelected = viewableColumnList.includes(col.columnID);
                                                if(isSelected){
                                                    return(
                                                        <div className='selector-view-container--column'>
                                                            <span className='item' title={col.columnName}>{col.columnName}</span>
                                                        </div>
                                                    )
                                                }
                                            })}
                                        </div>
                                    </div>
                                    <div className="roles-input-container">
                                        <span className='title'>Editable Columns</span>
                                        <div className='selector-view-container'>
                                            {ColumnData.map((col)=>{
                                                // const columnList = roleDetails.ViewableColumn;
                                                const editableColumnList = roleDetails.EditableColumn;
                                                const isSelected = editableColumnList.includes(col.columnID);
                                                if(isSelected){
                                                    return(
                                                        <div className='selector-view-container--column'>
                                                            <span className='item' title={col.columnName}>{col.columnName}</span>
                                                        </div>
                                                    )
                                                }
                                            })}
                                        </div>
                                    </div>
                                </Fragment>
                                }

                                {(actionType === "Edit" || actionType === "Create") && 
                                <Fragment>
                                    <div className="roles-input-container">
                                        <span className='title'>Viewable Columns *</span>
                                        <div className='column-selector-container'>
                                            {ColumnData.map((col)=>{
                                                    // const columnList = roleDetails.ViewableColumn;
                                                    const editableColumnList = roleDetails.ViewableColumn;
                                                    const isSelected = editableColumnList.includes(col.columnID);
                                                return (
                                                    <div 
                                                        className={`viewable-modal-chips 
                                                        ${isSelected ? actionType === "View" ? "disabled-selected-chips" : "selected-chips" : ""}`}
                                                        onClick={()=>{
                                                            actionType !== "View" ?
                                                            handleGroupRoleInput({value : col.columnID, type : "Viewable Column"}): ""
                                                        }}
                                                    > {col.columnName}</div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    <div className="roles-input-container">
                                        <span className='title'>Editable Columns *</span>
                                        <div className='column-selector-container'>
                                            {ColumnData.map((col)=>{
                                                    // const columnList = roleDetails.ViewableColumn;
                                                    const editableColumnList = roleDetails.EditableColumn;
                                                    const viewableColumnList = roleDetails.ViewableColumn;
                                                    const isSelected = editableColumnList.includes(col.columnID);
                                                    const isViewableSelected = viewableColumnList.includes(col.columnID);
                                                    const disabledColumns = actionType === "View" || (viewableColumnList.length > 0 && !isViewableSelected);
                                                    if(!disabledColumns){
                                                        return (
                                                            <div 
                                                                className={`viewable-modal-chips 
                                                                ${disabledColumns ? "disabled-selected-chips" : isSelected ? "selected-chips" : ""}`}
                                                                onClick={()=>{
                                                                    actionType !== "View" ?
                                                                    handleGroupRoleInput({value : col.columnID, type : "Editable Column"}): ""
                                                                }}
                                                            > {col.columnName}</div>
                                                        )
                                                    }
                                                
                                            })}
                                        </div>
                                    </div>
                                    
                                </Fragment>     
                                }
                                <div className="roles-input-container">
                                    <span className='switch-title'>
                                    Client Manage
                                        <Switch
                                            checked={roleDetails?.Client_Manage}
                                            onChange={(e) => { handleGroupRoleInput({value: !roleDetails?.Client_Manage, type: "Client_Manage"}) }}
                                            onColor="#4BAB00"
                                            onHandleColor="#FFFFFF"
                                            handleDiameter={20}
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                            height={15}
                                            width={35}
                                            disabled={actionType === "View" ? true : false}
                                        />
                                    </span>
                                    <div className="switch-description">
                                        <span>Allows user to create, import and update client</span>
                                    </div>
                                </div>

                                <div className="roles-input-container">
                                    <span className='switch-title'>
                                    Client Export
                                        <Switch
                                            checked={roleDetails?.Client_Export}
                                            onChange={(e) => { handleGroupRoleInput({value: !roleDetails?.Client_Export, type: "Client_Export"}) }}
                                            onColor="#4BAB00"
                                            onHandleColor="#FFFFFF"
                                            handleDiameter={20}
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                            height={15}
                                            width={35}
                                            disabled={actionType === "View" ? true : false}
                                        />
                                    </span>
                                    <div className="switch-description">
                                        <span>Allows user to export current client data</span>
                                    </div>
                                </div>

                                <div className="roles-input-container">
                                    <span className='switch-title'>
                                    Client Delete
                                        <Switch
                                            checked={roleDetails?.Client_Delete}
                                            onChange={(e) => { handleGroupRoleInput({value : !roleDetails?.Client_Delete , type: "Client_Delete"}) }}
                                            onColor="#4BAB00"
                                            onHandleColor="#FFFFFF"
                                            handleDiameter={20}
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                            height={15}
                                            width={35}
                                            disabled={actionType === "View" ? true : false}
                                        />
                                    </span>
                                    <div className="switch-description">
                                        <span>Allows user to delete client</span>
                                    </div>
                                </div>
                                <div className="roles-input-container">
                                    <span className='switch-title'>
                                    Client Transfer
                                        <Switch
                                            checked={roleDetails?.Client_Transfer}
                                            onChange={(e) => { handleGroupRoleInput({value : !roleDetails?.Client_Transfer , type: "Client_Transfer"}) }}
                                            onColor="#4BAB00"
                                            onHandleColor="#FFFFFF"
                                            handleDiameter={20}
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                            height={15}
                                            width={35}
                                            disabled={actionType === "View" ? true : false}
                                        />
                                    </span>
                                    <div className="switch-description">
                                        <span>Allows user to transfer client</span>
                                    </div>
                                </div>
                            </div>
                            <div className='viewable-modal-action-container'>
                                <div className='viewable-modal-action-container--button' 
                                        onClick={() => {
                                            if(actionType === "Create"){
                                                createRoleDetails();
                                            } else if(actionType === "View"){
                                                hideViewableModal();
                                            } 
                                            else if(actionType === "Edit"){
                                                updateRoleDetails();
                                            }
                                    }}
                                >{actionType === "View" ? "Close" : "Save"}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="modal fade"
                id=""
                tabIndex="-1"
                ref={deleteModalRef}
            >
                <div className="modal-dialog modal-dialog-centered" style={{}}>
                    <div className="modal-content px-3" style={{}}>
                        <div className="modal-header modalHeader">
                            <div className="viewable-modal-title">
                                Delete Role
                            </div>
                            <AiOutlineClose
                                cursor="pointer"
                                size={16}
                                onClick={() => {
                                    hideDeleteModal();
                                }}
                            />
                        </div>
                        <div className='modal-body delete-modal-body'>
                            <span>Are you sure you want to delete this role? </span>

                            <div className='delete-modal-action-container'>
                                <div 
                                    className='delete-modal-action-container--no-button' 
                                    onClick={() => {hideDeleteModal(); setDeleteRoleID("");}}
                                >No</div>

                                <div 
                                    className='delete-modal-action-container--delete-button' 
                                    onClick={() => {handleRemoveRole(deleteRoleID); hideDeleteModal();}}
                                >Yes</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
  
}

export default ClientGroupPermission