const component = {
  MuiMenuItem: {
    styleOverrides: {
      root: ({ theme }) => ({
        ...theme.typography.bodyText,
        padding: "10px 16px",
        "&.Mui-selected": {
          backgroundColor: theme.palette.input.selected,
        },
      }),
    },
  },
};

export default component;
