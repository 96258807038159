import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { InputDate } from "../Input/InputDate";

export const FormInputDate = ({
  label,
  defaultValue,
  textType,
  startAdornment,
  endAdornment,
  tooltips,
  type,
  isDisabled,
  inputProps,
  multiline,
  minWidth,
  maxWidth,
  fullWidth,
  required,
  transform,
  helperText,
  hidden = false,
  maxDate,
  minDate,
  disableDateIcon = false,
  variant,
  formOnBlur = null,
  disabledErrorText = false,
  ...props
}) => {
  return (
    <Controller
      {...props}
      shouldUnregister
      defaultValue={defaultValue}
      render={({
        field: { onChange, onBlur, value, ref },
        fieldState: { error },
      }) => {
        return (
          <InputDate
            type={type}
            value={value ? value : null}
            label={label}
            textType={textType}
            startAdornment={startAdornment}
            endAdornment={endAdornment}
            tooltips={tooltips}
            onChange={onChange}
            onBlur={onBlur}
            error={error?.message}
            helperText={helperText}
            name={props.name}
            isDisabled={isDisabled}
            transform={transform}
            inputProps={inputProps}
            multiline={multiline}
            minWidth={minWidth}
            maxWidth={maxWidth}
            fullWidth={fullWidth}
            required={required}
            fieldRef={ref}
            hidden={hidden}
            maxDate={maxDate}
            disabledErrorText={disabledErrorText}
            minDate={minDate}
            disableDateIcon={disableDateIcon}
            variant={variant}
            formOnBlur={formOnBlur}
          />
        );
      }}
    />
  );
};

FormInputDate.propTypes = {
  label: PropTypes.any,
  defaultValue: PropTypes.any,
  textType: PropTypes.any,
  startAdornment: PropTypes.any,
  endAdornment: PropTypes.any,
  tooltips: PropTypes.any,
  type: PropTypes.any,
  isDisabled: PropTypes.any,
  inputProps: PropTypes.any,
  multiline: PropTypes.any,
  minWidth: PropTypes.any,
  fullWidth: PropTypes.any,
  maxWidth: PropTypes.any,
  required: PropTypes.any,
  transform: PropTypes.any,
  name: PropTypes.string,
  helperText: PropTypes.any,
  hiddenLabel: PropTypes.any,
  value: PropTypes.any,
  hidden: PropTypes.bool,
  maxDate: PropTypes.any,
  minDate: PropTypes.any,
  disableDateIcon: PropTypes.bool,
  variant: PropTypes.any,
  formOnBlur: PropTypes.any,
  disabledErrorText: PropTypes.any,
};
