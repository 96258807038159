import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { InputDropdownMultipleSearch } from "../Input/InputDropdownMultipleSearch";

export const FormInputDropdownMultipleSearch = ({
  label,
  buttonProps,
  options,
  defaultValue,
  textType,
  tooltips,
  type,
  groupBy = null,
  isDisabled,
  inputProps,
  minWidth,
  maxWidth,
  fullWidth,
  required,
  transform,
  helperText,
  isOptionEqualToValue,
  labelField,
  isStandard = false,
  limitTags = 2,
  ...props
}) => {
  return (
    <Controller
      {...props}
      // shouldUnregister
      defaultValue={defaultValue}
      render={({
        field: { onChange, onBlur, value, ref },
        fieldState: { error },
      }) => {
        return (
          <InputDropdownMultipleSearch
            type={type}
            value={value || []}
            label={label}
            buttonProps={buttonProps}
            options={options}
            textType={textType}
            tooltips={tooltips}
            onChange={onChange}
            onBlur={onBlur}
            error={error?.message}
            helperText={helperText}
            name={props.name}
            isDisabled={isDisabled}
            transform={transform}
            inputProps={inputProps}
            minWidth={minWidth}
            maxWidth={maxWidth}
            fullWidth={fullWidth}
            required={required}
            fieldRef={ref}
            groupBy={groupBy}
            labelField={labelField}
            isStandard={isStandard}
            limitTags={limitTags}
            isOptionEqualToValue={isOptionEqualToValue}
          />
        );
      }}
    />
  );
};

FormInputDropdownMultipleSearch.propTypes = {
  label: PropTypes.any,
  buttonProps: PropTypes.object,
  options: PropTypes.array,
  defaultValue: PropTypes.any,
  textType: PropTypes.any,
  tooltips: PropTypes.any,
  groupBy: PropTypes.func,
  type: PropTypes.any,
  isDisabled: PropTypes.any,
  inputProps: PropTypes.any,
  minWidth: PropTypes.any,
  fullWidth: PropTypes.any,
  maxWidth: PropTypes.any,
  required: PropTypes.any,
  transform: PropTypes.any,
  name: PropTypes.string,
  helperText: PropTypes.any,
  hiddenLabel: PropTypes.any,
  labelField: PropTypes.any,
  value: PropTypes.any,
  isOptionEqualToValue: PropTypes.any,
  limitTags: PropTypes.any,
  isStandard: PropTypes.any,
};
