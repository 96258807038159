import React, {
    useState,
    useEffect,
    useRef,
} from "react";
import {
    useSearchParams,
  } from "react-router-dom";
import './GaPortal.scss'
import GaSidebar from "./GaSidebar";
import { BiArrowBack } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { off, onValue, ref, update } from "firebase/database";
import { db } from "../../firebaseInit"; 
import { Modal } from "bootstrap";
import usageObject from '../../enums/json/plan.json';
import { MdOutlineAnalytics, MdOutlineManageAccounts, MdOutlineOtherHouses } from "react-icons/md";
import { HiOutlineUserGroup } from "react-icons/hi2";
import moment from "moment";
import { AiFillEdit, AiOutlineClose } from "react-icons/ai";
import { BsCheck2Circle, BsFillBoxSeamFill } from "react-icons/bs";
import { TbArrowBigLeftFilled } from "react-icons/tb";
import C_DialogButton from "../../components/C_DialogButton";

//slices
import { retrieveCurrUsage } from "../../stores/slices/usageSlices";
import { sendTenantExtendNotificationMail, sendTenantUpgradeNotificationMail } from "../../stores/slices/tenantSlices";


function GaTenantUpgrade() {
    const modalRef = useRef(null);
    const divRef = useRef(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [divWidth,setDivWidth] = useState(0);
    const [searchParams, setSearchParams] = useSearchParams();
    const [companyName,setCompanyName] = useState("");
    const [startDate,setStartDate] = useState("");
    const [expiryDate,setExpiryDate] = useState("");

    const [selectedAction,setSelectedAction] = useState("");
    const [isConfirm,setIsConfirm] = useState(false);
    const [isProcessing,setIsProcessing] = useState(false);
    const [processingMsg,setProcessingMsg] = useState(false);

    //plan usage  
    const [hoverIndex,setHoverIndex] = useState("");
    const [mainUsageArr,setMainUsageArr] = useState([]);
    const [usage,setUsage] = useState([]);
    const [plan,setPlan] = useState("");  
    const [currPlanUsage,setCurrPlanUsage] = useState([]);
    const [selectedPlan,setSelectedPlan] = useState("");
    const [selectedUsage,setSelectedUsage] = useState([]);
    const [finalUsage,setFinalUsage] = useState([]);

    //custom duration
    const inputRef = useRef(null);
    const [isCustomDuration,setIsCustomDuration] = useState(false);
    const [renewDuration,setRenewDuration] = useState({duration:"",type:"month"});
    const [selectedDuration,setSelectedDuration] = useState("");
    const [durationType,setDurationType] = useState("month");

    const showModal = () => {
        const modalEle = modalRef.current;
        const bsModal = new Modal(modalEle, {
            backdrop: "static",
            keyboard: false,
        });
        bsModal.show();
    };

    const hideModal = () => {
        const modalEle = modalRef.current;
        const bsModal = Modal.getInstance(modalEle);
        bsModal.hide();
    };

    useEffect(() => {
        if (isCustomDuration) {
            // Focus the input when switching to the search mode
            inputRef.current.focus();
            
          }
    }, [isCustomDuration]);


    // console.log(searchParams.get("code"))
    useEffect(() => {
        if (divRef.current) {
            const divWidth = divRef.current.offsetWidth;
            setDivWidth(divWidth)
        }
    }, []);

    useEffect(()=>{
        if(Array.from(searchParams).length > 0){
            if(searchParams.get("tenantID") !== null && searchParams.get("companyID") !== null && searchParams.get("plan") !== null && searchParams.get("companyName") !== null) {
                const tenantID = searchParams.get("tenantID");
                const companyID = searchParams.get("companyID");
                // const plan = searchParams.get("plan");
                const companyName = searchParams.get("companyName");
                dispatch(retrieveCurrUsage({TenantID:tenantID,CompanyID:companyID}));

                const usageRef = ref(db, 'usage/' + tenantID + '/' + companyID + '/');
                onValue(usageRef, async (snapshot) => {
        
                    if(snapshot.exists()){
                        const usageObj = snapshot.val();
                        const startDate = usageObj.startDate;
                        const expiryDate = usageObj.expiryDate;
                        const usages = Object.keys(usageObj.usage).map((key) => ({ ...usageObj.usage[key] }));
                        const plan = usageObj.plan;
                        //useState  
                        setPlan(plan);
                        setStartDate(startDate);
                        setExpiryDate(expiryDate);
                        setUsage(usages);
                        setMainUsageArr(usageObj.usage)
                        setCurrPlanUsage(usageObject[plan]);
                    }else{
                        setUsage([]);
                        setMainUsageArr([]);
                    }
                });
                //useState
                setCompanyName(companyName);

                // Clean up listener
                return () => off(usageRef);
            }
        }
    },[searchParams])

    useEffect(()=>{

        switch(selectedPlan){
            case "Standard" :
                setSelectedUsage(usageObject.Standard)
                break;
            case "Pro" : 
                setSelectedUsage(usageObject.Pro)
                break;
            case "Enterprise" :
                setSelectedUsage(usageObject.Enterprise)
                break;
            default :
                break;
        }

    },[selectedPlan])

    useEffect(()=>{
        if(isCustomDuration){
            setRenewDuration({duration:"1",type:"month"});
        }
    },[isCustomDuration])

    const category = [
        {
            CategoryName: "User Management",
            CategoryID: "c1"
        },
        {
            CategoryName: "Analytics & Data",
            CategoryID: "c2"
        },
        {
            CategoryName: "Client Management",
            CategoryID: "c3"
        },
        {
            CategoryName: "Others",
            CategoryID: "c4"
        }
    ];

    const renewalDateList = [
        {
            months: "3",
            label: "3 months"
        },
        {
            months: "6",
            label: "6 months"
        },
        {
            months: "12",
            label: "12 months"
        },
        {
            months: "24",
            label: "24 months"
        },
        {
            months: "48",
            label: "48 months"
        }
    ]

    const handleSelectedPlan = (value) => {
        setSelectedPlan(value);
    }

    const handleRenewDuration = (value,durationType) => {
        setRenewDuration({duration:value,type:durationType})
    }

    const editPlan = () => {
        const usageArr = [...usage];
        const mainList = mainUsageArr;
        showModal();
        switch(selectedAction){
            case "Upgrade":
                const finalUsg = usageArr.map((usg, index) => {
                    const clonedUsg = Object.assign({}, usg); //create new object, assign into it (target,source)
                    const oriPlanQuota = currPlanUsage[usg.SectionID]?.Quota;
                    const addonQuota = usg?.Quota - oriPlanQuota;
                    const finalQuota = selectedUsage[usg.SectionID]?.Quota !== "unlimited" 
                    ? addonQuota + selectedUsage[usg.SectionID]?.Quota : "unlimited";
                    clonedUsg.Quota = finalQuota;
                    return clonedUsg;
                });
        
                finalUsg.forEach((arr,index)=>{
                    Object.keys(mainList).map(key=>{
                        if(key === arr.SectionID){
                            mainList[key].Quota = arr.Quota;
                        }
                    })
                })
                setFinalUsage(finalUsg);
                setMainUsageArr(mainList);

                break;
            case "Extend":
                break;
            default:
                break;
        }
    }
    const confirmPlan = async () => {
        setProcessingMsg("Processing..")
        setIsConfirm(true);
        setIsProcessing(true);
        const tenantID = searchParams.get("tenantID");
        const companyID = searchParams.get("companyID");
        const userID = searchParams.get("userID");
        await new Promise((resolve) => setTimeout(resolve, 2000));
        // setProcessingMsg("Updating Quota..");
        
        switch(selectedAction){
            case "Upgrade":
                const finalExpiryDate = startDate === "" ? "" 
                : expiryDate === "" 
                ? moment(new Date()).add(1,'year').format('YYYY-MM-DD HH:mm:ss') 
                : moment(expiryDate).add(1,'year').format('YYYY-MM-DD HH:mm:ss');

                const finalStartDate = startDate === "" ? "" 
                : moment(new Date()).format('YYYY-MM-DD HH:mm:ss');

                setProcessingMsg("Upgrading Plan..");
                await new Promise((resolve) => setTimeout(resolve, 2000));

                await update(ref(db, 'usage/' + tenantID + '/' + companyID + '/'), {
                    startDate:finalStartDate,
                    expiryDate:finalExpiryDate,
                    plan:selectedPlan,
                    usage:mainUsageArr});
                
                setSelectedUsage({});
                setSelectedPlan("");
                setProcessingMsg("Adjusting Quota..");
                await new Promise((resolve) => setTimeout(resolve, 2000));
                await update(ref(db, 'planRequest/' + userID + '/' + companyID + '/'), {plan:selectedPlan});
                await new Promise((resolve) => setTimeout(resolve, 2000));
                setProcessingMsg("Sending Email..");
                dispatch(
                    sendTenantUpgradeNotificationMail({
                        hostEmail:searchParams.get("hostEmail"),
                        userName:searchParams.get("userName"),
                        companyName:searchParams.get("companyName"),
                        quotaArray:finalUsage,
                        plan:selectedPlan,
                        expiryDate:finalExpiryDate,
                    })
                )
                await new Promise((resolve) => setTimeout(resolve, 2000));
                setProcessingMsg("Plan has been upgraded");
                setIsProcessing(false)
                break;
            case "Extend":
                let extendedExpiryDate;
                const renewType = renewDuration.type;
                const duration = renewDuration.duration;
            
                setProcessingMsg("Adjusting date..");
                await new Promise((resolve) => setTimeout(resolve, 2000));
                extendedExpiryDate = moment(expiryDate).add(duration, renewType).format('YYYY-MM-DD HH:mm:ss');
                setSelectedDuration("");
                await update(ref(db, 'usage/' + tenantID + '/' + companyID + '/'), {
                    expiryDate:extendedExpiryDate,});
                await new Promise((resolve) => setTimeout(resolve, 2000));
                setProcessingMsg("Sending Email..");
                dispatch(
                    sendTenantExtendNotificationMail({
                        hostEmail:searchParams.get("hostEmail"),
                        userName:searchParams.get("userName"),
                        companyName:searchParams.get("companyName"),
                        plan:plan,
                        // extendedDuration:`${duration} ${renewType.type}`,
                        expiryDate:extendedExpiryDate,
                    })
                )
                await new Promise((resolve) => setTimeout(resolve, 2000));
                setProcessingMsg("Duration has been changed");
                setIsProcessing(false)
                break;
            default:
                console.log("err invalid value")
                break;
        }
    }

    const handleClearAll = () => {
        setSelectedUsage({});
        setSelectedPlan("");
        setSelectedDuration("");
        setIsCustomDuration(false);
    }

    return (
        <div className='gaPortalMainContainer'>
            <GaSidebar/>
            <div className="contentContainer" ref={divRef}>
                {/* modal start*/}
                <div className="modal fade" tabIndex="-1" id="tenant-confirmation" ref={modalRef} style={{}}>
                    <div className="modal-dialog modal-dialog-centered" style={{}}>
                        <div className="modal-content px-3" style={{overflowY: "scroll" }}>
                            <div className="modal-header modalHeader">
                                <div className="title">
                                    <span className="font-s">
                                        Confirmation
                                    </span>
                                </div>
                                <AiOutlineClose cursor='pointer' size={16} onClick={()=>{!isProcessing && hideModal();}}/>
                            </div>
                            <div className="modal-body upgrade-confirmation-body">
                                <div className="upgrade-confirmation-container">
                                    <span className="font-xs" style={{ fontWeight: "bold" }} >
                                        {isProcessing && isConfirm ? "Please wait while we are processing the data." : 
                                        <>
                                        {selectedAction === "Upgrade" &&
                                            <>
                                            {isConfirm && !isProcessing ? "Plan has been upgraded. A notification email has been sent to the user to notify them." : "Are you sure you want to proceed? This action cannot be reverted"}
                                            </>
                                        }

                                        {selectedAction === "Extend" &&
                                            <>
                                            {isConfirm && !isProcessing ? "Duration has been extended. A notification email has been sent to the user to notify them." : "Are you sure you want to proceed? This action cannot be reverted"}
                                            </>
                                        }
                                        
                                        </>
                                        }
                                    </span>
                                    <div className="confirmation-action-container">
                                        <div className={`py-1 loadingContainer ${!isConfirm && "hide-loading-container"}`}>
                                            <div className={`${isProcessing ? "loading-spinner" : "hide-loading-spinner"}`}>
                                            </div>
                                            {isProcessing ?
                                            <>
                                            <span>{processingMsg}</span>
                                            </>
                                            :
                                            <div className="finished-loading-container">
                                            <span>{processingMsg}</span> 
                                            <div><BsCheck2Circle size={18}  style={{fontWeight:"600",color:"green"}}/></div>
                                            </div>}
                                        </div>
                                        <div className="btn-container">
                                        {!isConfirm ?
                                        <>
                                            <C_DialogButton
                                            onClick={()=>{hideModal();}}
                                            color={'#4287f5'}
                                            background={'white'}
                                            borderColor={'#4287f5'}
                                            buttonText={"DISCARD"}
                                            
                                            />
                                            <C_DialogButton
                                                onClick={()=>{confirmPlan();}}
                                                color={"white"}
                                                background={'#4287f5'}
                                                borderColor={'#4287f5'}
                                                buttonText={"CONTINUE"}
                                            />
                                        </>
                                        :
                                        <>
                                            {isProcessing ? 
                                            <C_DialogButton 
                                                background={'#eeeeee'}
                                                borderColor={'#eeeeee'}
                                                buttonText={"CLOSE"}
                                                disabled
                                            />
                                            :
                                            <C_DialogButton 
                                                background={'#6f7df7'}
                                                borderColor={'#6f7df7'}
                                                buttonText={"CLOSE"}
                                                onClick={()=>{
                                                    setSelectedAction("")
                                                    hideModal();
                                                    handleClearAll();
                                                    setIsConfirm(false);
                                                }}
                                            />
                                            }
                                        </>
                                        }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* modal end */}
                <div className='tenantListMainContainer'style={{width:divWidth}}>
                    <div className="tenantHeader">
                        <div className="tenant-header-back-action" onClick={()=>{navigate("/GaPortal")}}><BiArrowBack size={30}/></div>
                        <span>Upgrade Plan</span>
                    </div>
                    <div className="tenant-upgrade-container">
                        <div className="plan-details-container">
                            <div className="plan-details">
                                <div className="existing-plan-details">
                                    <span className="existing-plan-title">COMPANY NAME</span>
                                    <span className="existing-plan">{companyName}</span>
                                </div>
                                <div className="existing-plan-details">
                                    <span className="existing-plan-title">PLAN</span>
                                    <span className="existing-plan">{plan}</span>
                                </div>
                                <div className="existing-plan-details">
                                    <span className="existing-plan-title">START DATE</span>
                                    <span className="existing-plan">{startDate === "" ? "N/A" : moment(startDate).format('YYYY-MM-DD | dddd')}</span>
                                </div>
                                <div className="existing-plan-details">
                                    <span className="existing-plan-title">PLAN EXPIRES</span>
                                    <span className="existing-plan">{expiryDate === "" ? "N/A" : moment(expiryDate).format('YYYY-MM-DD | dddd')}</span>
                                </div>
                                <div className="existing-plan-details">
                                    <span className="existing-plan-title">DAYS LEFT</span>
                                    <span className="existing-plan">{expiryDate === "" ? "0" : moment(expiryDate).diff(startDate,'days')} Days</span>
                                </div>
                            </div>
                        </div>
                        <div className="upgrade-plan-container">
                            <div className="plan-quota-container">
                                <div className="plan-quota-title-container">
                                    {Object.keys(selectedUsage).length === 0 ? <span className="plan-quota-title">{`Current Quota (${plan})`}</span> : <></>}
                                    {Object.keys(selectedUsage).length > 0 ? <span className="plan-quota-title">{`Updated Quota `}
                                    <span className="selected-plan-quota-title">{`(${selectedPlan})`}</span></span> :<></>}
                                </div>
                                <div className="plan-quota">
                                    {category.map((cat,idx)=>{
                                        return (
                                        <div className={`quota-category-card`}>
                                            <div className="category-title">
                                                <div>{cat.CategoryID === "c1" && <MdOutlineManageAccounts size={22}/>}
                                                {cat.CategoryID === "c2" && <MdOutlineAnalytics size={22}/>}
                                                {cat.CategoryID === "c3" && <HiOutlineUserGroup size={22}/>}
                                                {cat.CategoryID === "c4" && <MdOutlineOtherHouses size={22}/>}</div>
                                                <span className="category-title">{cat.CategoryName}</span>
                                            </div>
                                            <div className="quota-container">
                                                {(Array.isArray(usage) && usage.length > 0) && [...usage].sort((a,b)=>a.SectionName.localeCompare(b.SectionName)).map((usg,index)=>{
                                                    if(usg.CategoryID === cat.CategoryID){
                                                        const oriPlanQuota = currPlanUsage[usg.SectionID]?.Quota;
                                                        const addonQuota = usg?.Quota - oriPlanQuota;
                                                        const finalQuota = selectedUsage[usg.SectionID]?.Quota !== "unlimited" ? addonQuota + selectedUsage[usg.SectionID]?.Quota : "unlimited";
                                                        return (
                                                            <div className="quota">
                                                                <span>{usg.SectionName}</span>
                                                                <span>
                                                                    {(selectedPlan === "" && Object.keys(selectedUsage).length === 0)? 
                                                                    <>
                                                                    {usg?.Quota !== "unlimited" && <span>{`${usg?.Quota} ${usg?.Unit}`}</span> }
                                                                    {usg?.Quota === "unlimited" && <span>{`${usg?.Quota}`}</span> }
                                                                    </>
                                                                    :
                                                                    <>

                                                                    {finalQuota !== "unlimited" && !isNaN(finalQuota) ? 
                                                                        <span className="quota-span">
                                                                            {addonQuota !== 0 && 
                                                                            <span className="carried-over-quota" 
                                                                            onMouseOver={()=>{setHoverIndex(index);}} 
                                                                            onMouseLeave={()=>{setHoverIndex("");}}>
                                                                            {`${finalQuota} `}
                                                                            <div className="carried-over-underline"></div>
                                                                            <div className={`${(hoverIndex === index) ? "info-box" : "hide-info-box"}`}>
                                                                                <span className="title">Quota</span>
                                                                                <div className="info-container">
                                                                                    <span>Default: {selectedUsage[usg.SectionID]?.Quota}</span>
                                                                                    <span>Add-on: {addonQuota}</span>
                                                                                </div>
                                                                            </div>
                                                                            </span>}

                                                                            {addonQuota === 0 && 
                                                                            <span>{`${finalQuota} `}</span>}

                                                                            {usg?.Unit}
                                                                        </span>
                                                                    :
                                                                        <span>{`${finalQuota}`}</span>
                                                                    }
                                                                    </>
                                                                    }
                                                                </span>
                                                            </div>
                                                        );
                                                    }
                                                })}
                                            </div>
                                        </div>
                                        );
                                    })}
                                    
                                </div>
                            </div>
                            <div className="renew-plan-container">
                                {selectedAction === "" &&
                                <div className="action-selector-container">
                                    <div className="action-title-container"><span className="action-title">Choose an Action</span></div>
                                    
                                    <div className="action-card-container">
                                        <div className={`${plan !== "Enterprise" ? "action-card" : "disabled-action-card"}`} title={plan !== "Enterprise" && "Company is running with the highest plan already"} onClick={()=>{plan !== "Enterprise" && setSelectedAction("Upgrade")}}>
                                            <span className="action-name">Upgrade Plan</span>
                                        </div>
                                        
                                        <div className={`${startDate !== "" ? "action-card" : "disabled-action-card"}`} title={startDate === "" && "Company must be activated before extending its plan duration"}  onClick={()=>{startDate !== "" && setSelectedAction("Extend")}}>
                                            <span className="action-name">Extend Duration</span>
                                        </div>
                                    </div>
                                </div>
                                }

                                {selectedAction === "Upgrade" &&
                                <div className="plan-selector-container">
                                    <div className="plan-title-container">
                                        <span className="plan-title">Choose Your Plan</span>
                                        <span className="clear-all" onClick={()=>{handleClearAll();}}>Clear All</span>
                                    </div>
                                    
                                    <div className="plan-card-container">
                                        {plan === "Trial" &&
                                         <div className={`${selectedPlan === "Standard" && "selected-plan-card"} plan-card`} onClick={()=>{handleSelectedPlan("Standard")}}>
                                            <span className="plan-name">Standard</span>
                                            <span className="latest-expiry-date">Expiry date: {expiryDate === "" ? "N/A": moment(expiryDate).add(1,'year').format('YYYY-MM-DD')}</span>
                                         </div>
                                        }
                                        {(plan === "Trial" || plan === "Standard") &&
                                         <div className={`${selectedPlan === "Pro" && "selected-plan-card"} plan-card`}  onClick={()=>{handleSelectedPlan("Pro")}}>
                                            <span className="plan-name">Pro</span>
                                            <span className="latest-expiry-date">Expiry date: {expiryDate === "" ? "N/A": moment(expiryDate).add(1,'year').format('YYYY-MM-DD')}</span>
                                         </div>
                                        }
                                        {(plan === "Trial" || plan === "Standard" || plan === "Pro") &&
                                          <div className={`${selectedPlan === "Enterprise" && "selected-plan-card"} plan-card`}  onClick={()=>{handleSelectedPlan("Enterprise")}}>
                                            <span className="plan-name">Enterprise</span>
                                            <span className="latest-expiry-date">Expiry date: {expiryDate === "" ? "N/A": moment(expiryDate).add(1,'year').format('YYYY-MM-DD')}</span>
                                          </div>
                                        }
                                        
                                       
                                    </div>
                                </div>}

                                {selectedAction === "Extend" &&
                                <div className="renew-duration-container">
                                    <div className="renew-title-container">
                                        <span className="renew-duration-title">Extend Duration</span>
                                        <span className="clear-all" onClick={()=>{handleClearAll();}}>Clear All</span>
                                        {/* <span className="expiry-date-title">Expiry Date</span> */}
                                    </div>
                                    <div className="renew-card-container">
                                        {renewalDateList.map((item,index)=>{
                                            return (
                                                <div className={`${selectedDuration === item.months && "selected-renew-card"} renew-card`} onClick={()=>{
                                                    setSelectedDuration(item.months);
                                                    setIsCustomDuration(false);
                                                    setRenewDuration(moment(expiryDate).add(item.months,'month').format('YYYY-MM-DD'));
                                                    handleRenewDuration(item.months,durationType);
                                                    }}>
                                                    <span className="renew-duration">{item.label}</span>
                                                    <span className="latest-expiry-date">Expiry date: {expiryDate === "" ? "N/A": moment(expiryDate).add(item.months,'month').format('YYYY-MM-DD')}</span>
                                                </div>
                                            );
                                        })}

                                        <div className={`${isCustomDuration ? "custom-renew-card" : "renew-card"}`} onClick={(()=>{setIsCustomDuration(true);setSelectedDuration("");})}>
                                            {isCustomDuration && 
                                            <>
                                                <div className="renew-duration">
                                                    <input ref={inputRef} value={renewDuration.duration} className="custom-duration" type="number" 
                                                    onChange={(e)=>{handleRenewDuration(e.target.value,durationType);}}/>
                                                </div>
                                                <select className="custom-duration-option" onChange={(e)=>{
                                                    setDurationType(e.target.value);
                                                    handleRenewDuration(renewDuration.duration,e.target.value);
                                                }}
                                                >
                                                    <option value={"month"}>Month</option>
                                                    <option value={"year"}>Year</option>
                                                    <option value={"day"}>Day</option>
                                                </select>
                                                <span className="latest-expiry-date"> Expiry date: {
                                                    renewDuration.duration !== "" ? <> {expiryDate === "" ? "N/A" : 
                                                    <>
                                                    {   renewDuration.type === "month" ?
                                                        moment(expiryDate).add(renewDuration.duration,'month').format('YYYY-MM-DD') : 
                                                        renewDuration.type === "year" ?
                                                        moment(expiryDate).add(renewDuration.duration,'year').format('YYYY-MM-DD') :
                                                        moment(expiryDate).add(renewDuration.duration,'d').format('YYYY-MM-DD')
                                                    }
                                                    </>}
                                                </>
                                                :<>N/A</>
                                                }</span>
                                            </>}

                                            {!isCustomDuration &&
                                            <>
                                                <div className="renew-duration inactive-custom-duration">
                                                    <span>Custom Duration</span>
                                                    <div className="custom-icon-class"><AiFillEdit size={18}/></div>
                                                </div>
                                            </>}
                                        </div>
                                        <div className="alert-note">
                                            <span>Remark: The custom duration is capable of being negative.</span>
                                           
                                        </div>
                                    </div>
                                </div>}
                                {(selectedAction === "Upgrade" || selectedAction === "Extend") &&
                                <div className="action-container">
                                     <div className="back-btn" onClick={()=>{
                                        setSelectedAction("");
                                        setSelectedPlan("");
                                        setSelectedUsage([]);
                                        setSelectedDuration("");
                                        setIsCustomDuration(false);
                                    }}>
                                        <div className="back-icon"><TbArrowBigLeftFilled size={17}/></div>
                                        <span>Back</span>
                                    </div>
                                    <div className="save-btn" onClick={()=>{
                                        editPlan();
                                    }}>
                                        <div className="save-icon"><BsFillBoxSeamFill size={16}/></div>
                                        <span>Save</span>
                                    </div> 
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GaTenantUpgrade;