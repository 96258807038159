const component = {
  MuiTooltip: {
    styleOverrides: {
      tooltip: () => ({
        maxWidth: "none",
      }),
    },
  },
};

export default component;
