import { createSlice, current } from "@reduxjs/toolkit"

import { useAppSelector } from "../../hooks/storeHooks";
import moment from "moment";

const initialState = {
    //loading
    setGaPortalContextLoading : false,

    //error
    setGaPortalContextError : undefined,

    context : {
        gaCurrPage : 'DASHBOARD',
        currTenantList : [],
        currTotalTenants : 0,
        currAllUserList : [],
        currClientList : [],
        currAdminList : [],
    },

    defaultContext : {
        gaCurrPage : 'DASHBOARD',
        currTenantList : [],
        currTotalTenants : 0,
        currAllUserList : [],
        currClientList : [],
        currAdminList : [],
    },

}

const gaPortalSlice = createSlice({
    name:"gaPortal",
    initialState,
    reducers: {
        setGaPortalContext(state){
            state.setGaPortalContextLoading = false;
        },
        setGaPortalContextSuccess(state,{payload}){
            const currentState = current(state.context);
            if(Object.values(payload).length > 0){
                const updatedState = {
                    ...currentState,
                    ...payload,
                }
                state.context = updatedState;
                
            } else {
                state.context = state.defaultContext;
            }
            
            state.setGaPortalContextLoading = false;
        },

        setGaPortalContextError(state,{payload}){
            const errorState = payload
            state.setGaPortalContextError = errorState
            state.setGaPortalContextLoading = false
        },
    }
})

export const {
    setGaPortalContextSuccess,
    setGaPortalContextError,
    setGaPortalContext,
} = gaPortalSlice.actions

// data

export const selectGaPortalContext = () => 
  useAppSelector((state) => state.gaPortal.context);

// loading
export const selectGaPortalContextLoading = () =>
  useAppSelector((state) => state.gaPortal.setGaPortalContextLoading);

// error
export const selectGaPortalContextError = () =>
  useAppSelector((state) => state.gaPortal.setGaPortalContextError);

//export reducer
const gaPortalReducer = gaPortalSlice.reducer

export default gaPortalReducer