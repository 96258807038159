import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { getAuth } from "firebase/auth";
import { getDatabase } from 'firebase/database'
import { getStorage } from 'firebase/storage'

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAS6LS_fdqY4LmSpU0AT2jpPEm3260JnOw",
  authDomain: "zumax-crm.firebaseapp.com",
  databaseURL: "https://zumax-crm-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "zumax-crm",
  storageBucket: "zumax-crm.appspot.com",
  messagingSenderId: "924267387930",
  appId: "1:924267387930:web:288c9e482bd30ebace45a3"
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

export const messaging = getMessaging(app);

export const db = getDatabase(app);

export const storage = getStorage(app);

export default app;