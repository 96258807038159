const component = {
  MuiChip: {
    styleOverrides: {
      root: ({ theme }) => ({
        ...theme.typography.remark,
        "&.MuiChip-colorInfo": {
          color: theme.palette.chip.info,
          border: ` 1px solid ${theme.palette.chip.info}`,
          backgroundColor: theme.palette.chip.infoBg,
        },

        "&.MuiChip-colorPrimary": {
          color: theme.palette.chip.success,
          border: ` 1px solid ${theme.palette.chip.success}`,
          backgroundColor: theme.palette.chip.successBg,
        },
        "&.MuiChip-colorSecondary": {
          color: theme.palette.chip.pending,
          border: ` 1px solid ${theme.palette.chip.pending}`,
          backgroundColor: theme.palette.chip.pendingBg,
        },
        "&.MuiChip-colorWarning": {
          color: theme.palette.chip.warning,
          border: ` 1px solid ${theme.palette.chip.warning}`,
          backgroundColor: theme.palette.chip.warningBg,
        },
        "&.MuiChip-colorError": {
          color: theme.palette.chip.error,
          border: ` 1px solid ${theme.palette.chip.error}`,
          backgroundColor: theme.palette.chip.errorBg,
        },
      }),
    },
  },
};

export default component;
