import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { AiOutlineArrowRight } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { selectAppointments } from '../../stores/slices/appointmentsSlices';
import { selectUser } from '../../stores/slices/userAuthSlices';

function AppointmentSync() {
    
    const user = selectUser();
      const navigate = useNavigate();

      const appointments = selectAppointments();
    
      const [allAppointment, setAllAppointment] = useState([]);
    
      useEffect(() => {
    
          let isAttendees;
          const filterAppointment = appointments?.filter(item => {
    
            const formatedArray = typeof item?.memberAttendees === "string" ? JSON.parse(item?.memberAttendees) : item?.memberAttendees;
    
            if(Array.isArray(formatedArray))
            {
                isAttendees = formatedArray.find(item => item.UserID === user?.uid);
            }
            if(isAttendees || item.organizer === user?.uid){
                return item
            }
        });
        const sortedAppointment = filterAppointment.sort(function(a,b){ return new Date(a.start) - new Date(b.start)});
        setAllAppointment(sortedAppointment);

      },[appointments]);

      function getContrastColor(hexColor) {
        // Remove the # from the beginning of the color string
        const cleanHex = hexColor.replace("#", "");
      
        // Convert the color string to RGB values
        const red = parseInt(cleanHex.substr(0, 2), 16);
        const green = parseInt(cleanHex.substr(2, 2), 16);
        const blue = parseInt(cleanHex.substr(4, 2), 16);
      
        // Convert RGB to HSL
        const hslValues = rgbToHsl(red, green, blue);
      
        // Get the lightness value from the HSL representation
        const lightness = hslValues[2];
      
        // Use white font color if the lightness is less than or equal to 0.5, and gray font color otherwise
        return lightness <= 0.5 ? "#fff" : "#000";
    }
  
    // Utility function to convert RGB to HSL
    function rgbToHsl(r, g, b) {
        r /= 255;
        g /= 255;
        b /= 255;
        const max = Math.max(r, g, b);
        const min = Math.min(r, g, b);
        let h, s, l = (max + min) / 2;
    
        if (max == min) {
        h = s = 0;
        } else {
        const d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
            case r:
            h = (g - b) / d + (g < b ? 6 : 0);
            break;
            case g:
            h = (b - r) / d + 2;
            break;
            case b:
            h = (r - g) / d + 4;
            break;
        }
        h /= 6;
        }
    
        return [h, s, l];
      }

  return (
    <div className='AppointmentContainer'>
        <div className='titleContainer'>
            <span>Upcoming Appointments</span>
            <div className='actionContainer' onClick={() => {
                navigate('/Appointment');
            }}>
                <span>View all</span>
                <AiOutlineArrowRight size={10} color="#bcbcbc"/>
            </div>
        </div>
        {allAppointment && allAppointment.length > 0 ?
        <div className='mainAppointmentContainer'>
            {allAppointment.map((item, index) => {
                if(moment(item.end).format("DD/MM/YYYY") >= moment().format("DD/MM/YYYY")){
                    return(
                        <div className='appointmentSet'
                            style={{
                                borderBottom: `${item.AppointmentColor} solid 3px`
                            }}
                            key={index}
                        >
                            <div className='date' style={{ backgroundColor: item.AppointmentColor }}>
                                <span style={{ color: getContrastColor(item.AppointmentColor) }}>{moment(item.start).format('DD')}</span>
                                <span style={{ color: getContrastColor(item.AppointmentColor) }}>{moment(item.start).format('MMM')}</span>
                            </div>
                            <div className='infoSet'>
                                <span className='title' title={item.title}>{item.title}</span>
                                <span>{moment(item.start).format('HH:mm')}</span>
                            </div>
                        </div>
                    )
                }
            })}
        </div>
        :
        <div className='emptyAppointment'>
            <span>No Appointment Avaialble</span>
        </div>
        }
    </div>
  )
}

export default AppointmentSync