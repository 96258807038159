export const hasManyM2MOrganize = (
  originList,
  updatedList,
  updatedDirtyList,
  primaryField,
  nestedPrimaryField
) => {
  const organizedObj = updatedList.reduce(
    (obj, item, index) => {
      if (Object.keys(updatedDirtyList[index]).length > 0) {
        const isPrimaryFieldExist = item[primaryField];

        if (isPrimaryFieldExist) {
          const dirtyDataObj = {
            [primaryField]: item[primaryField],
          };
          const currentUpdatedDirtyData = updatedDirtyList[index];
          const updatedDirtyDataObj = Object.keys(
            currentUpdatedDirtyData
          ).reduce((nestedObj, nestedItemKey) => {
            const currentUpdatedDirtyValue =
              currentUpdatedDirtyData[nestedItemKey];
            if (Array.isArray(currentUpdatedDirtyValue)) {
              const nestedUpdatedItemList = item[nestedItemKey];
              const organizeNestedObj = nestedUpdatedItemList.reduce(
                (nestedObj, nestedItem, nestedIndex) => {
                  const isNestedPrimaryFieldExist =
                    nestedItem[nestedPrimaryField];

                  if (
                    Object.keys(currentUpdatedDirtyValue[nestedIndex]).length >
                    0
                  ) {
                    if (isNestedPrimaryFieldExist) {
                      nestedObj.updated = [
                        ...nestedObj.updated,
                        {
                          ...currentUpdatedDirtyValue[nestedIndex],
                          [nestedPrimaryField]: isNestedPrimaryFieldExist,
                        },
                      ];
                    }

                    if (!isPrimaryFieldExist) {
                      nestedObj.added = [...nestedObj.added, nestedItem];
                    }
                  }

                  return nestedObj;
                },
                {
                  added: [],
                  updated: [],
                }
              );
              const nestedArrayOrigin = originList[index][nestedItemKey];
              const updatedNestedIds = updatedList[index][nestedItemKey].map(
                (i) => i[nestedPrimaryField]
              );

              const nestedRemovedList = nestedArrayOrigin.reduce(
                (list, item) => {
                  const isIncludePrimaryField = item[nestedPrimaryField];
                  if (isIncludePrimaryField) {
                    const updatedListNotContainItem = updatedNestedIds.includes(
                      isIncludePrimaryField
                    );
                    if (!updatedListNotContainItem) {
                      list.push(isIncludePrimaryField);
                    }
                  }

                  return list;
                },
                []
              );
              let nestedItemValue = {};
              // const nestedRemovedObject =
              //   nestedRemovedList.length > 0
              //     ? { removed: nestedRemovedList }
              //     : {};
              if (nestedRemovedList.length > 0) {
                nestedItemValue["removed"] = nestedRemovedList;
              }
              if (organizeNestedObj.added.length > 0) {
                nestedItemValue["added"] = organizeNestedObj.added;
              }
              if (organizeNestedObj.updated.length > 0) {
                nestedItemValue["updated"] = organizeNestedObj.updated;
              }
              // const nestedAddedObject =
              //   organizeNestedObj.added.length > 0
              //     ? { added: organizeNestedObj.added }
              //     : {};
              // const nestedUpdatedObject =
              //   organizeNestedObj.updated.length > 0
              //     ? { updated: organizeNestedObj.updated }
              //     : {};
              // const nestedItemPayload = Object.keys(nestedItemValue).length > 0 ?

              return {
                ...nestedObj,
                ...nestedItemValue,
              };
            }
            nestedObj[nestedItemKey] = currentUpdatedDirtyValue;
            return nestedObj;
          }, {});

          const updatedDirtyDataPayload =
            Object.keys(updatedDirtyDataObj).length > 0
              ? [
                  {
                    ...dirtyDataObj,
                    ...updatedDirtyDataObj,
                  },
                ]
              : [];
          obj.updated = [...obj.updated, ...updatedDirtyDataPayload];
        }
        if (!isPrimaryFieldExist) {
          obj.added = [...obj.added, item];
        }
      }

      return obj;
    },
    {
      added: [],
      updated: [],
    }
  );
  const updatedIdsList = updatedList.map((item) => item[primaryField]);

  const removedList = originList.reduce((list, item) => {
    const isIncludePrimaryField = item[primaryField];
    if (isIncludePrimaryField) {
      const updatedListNotContainItem = updatedIdsList.includes(
        item[primaryField]
      );
      if (!updatedListNotContainItem) {
        list.push(item[primaryField]);
      }
    }

    return list;
  }, []);

  const removedObject = removedList.length > 0 ? { removed: removedList } : {};
  const addedObject =
    organizedObj.added.length > 0 ? { added: organizedObj.added } : {};
  const updatedObject =
    organizedObj.updated.length > 0 ? { updated: organizedObj.updated } : {};

  const results = {
    ...removedObject,
    ...addedObject,
    ...updatedObject,
  };
  return results;
};
