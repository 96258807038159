import palette from "./palette";

const button = {
  btn_primary: {
    color: palette.text.white,
    background: palette.background.primary,
    border: "2px solid",
    borderColor: palette.border.active,
    borderRadius: "6px",
    "&:hover": {
      transform: "scale(1.02)",
    },
    "&:focus": {
      background: palette.background.primary,
      boxShadow: "0 0 0 3px rgba(12, 197, 130, 0.3)",
    },
    svg: {
      color: palette.text.white,
    },
    "&:disabled": {
      opacity: "50%",
      svg: {
        color: palette.text.white,
      },
    },
  },
  btn_secondary: {
    color: palette.text.primary,
    background: palette.background.white,
    border: "2px solid",
    borderColor: palette.border.active,
    borderRadius: "6px",
    "&:hover": {
      transform: "scale(1.02)",
    },
    "&:focus": {
      boxShadow: "0 0 0 3px rgba(12, 197, 130, 0.3)",
    },
    svg: {
      color: palette.text.primary,
    },
    "&:disabled": {
      opacity: "50%",
      svg: {
        color: palette.text.primary,
      },
    },
  },
  btn_delete: {
    color: palette.text.white,
    background: palette.background.danger,
    border: "2px solid",
    borderColor: palette.border.delete,
    borderRadius: "6px",
    "&:hover": {
      transform: "scale(1.02)",
    },
    "&:focus": {
      background: palette.background.danger,
      boxShadow: "0 0 0 3px rgba(255, 107, 107, 0.3)",
    },
    svg: {
      color: palette.text.white,
    },
    "&:disabled": {
      opacity: "50%",
      svg: {
        color: palette.text.white,
      },
    },
  },
  btn_disabled: {
    color: palette.text.disabled,
    background: palette.background.disabled,
    border: "2px solid",
    borderColor: palette.border.disabled,
    borderRadius: "6px",
  },
};

export default button;
