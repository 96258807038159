import { Route } from "react-router-dom"
import { crmRoutes } from "../enums/Route"
import ProtectedRoute from "./ProtectedRoute"
import { Fragment } from "react"
import MainClients_Guide from "../_UserGuide/Clients/MainClients_Guide"

export const GuideClientRoute = () => (
    <Fragment>
        <Route 
            path={crmRoutes.guide.client}
            element={
                <ProtectedRoute>
                    <MainClients_Guide />
                </ProtectedRoute>
            }
        />
    </Fragment> 
)

