import { call, delay, put, takeLatest } from "redux-saga/effects";
import { 
    createFormSubmission,
    createFormSubmissionError,
    createFormSubmissionSuccess, 
    deleteFormSubmission, 
    deleteFormSubmissionError, 
    deleteFormSubmissionSuccess, 
    retrieveAllFormsCount, 
    retrieveAllFormsCountError, 
    retrieveAllFormsCountSuccess, 
    retrieveFormSubmission, 
    retrieveFormSubmissionError, 
    retrieveFormSubmissionSuccess,
    updateFormSubmission,
    updateFormSubmissionError,
    updateFormSubmissionSuccess
} from "../stores/slices/formSubmissionSlices";

import { ApiRoute } from "../enums/ApiRoute";
import { API } from "../http-common";
import { setFormContext } from "../stores/slices/formTemplateSlices";


function* createFormSubmissionSaga({payload}) {
    try{
        const { data } = yield call(API.post, ApiRoute.formSubmission.createFormSubmission, payload);
        yield put(createFormSubmissionSuccess(data));
    } catch (error){
        
        yield put(createFormSubmissionError(error));
        console.error(error);
    }
}

function* retrieveFormSubmissionSaga({payload}) {
    try{
        const { 
            TenantID, 
            UserID, 
            CurrPage, 
            Type, 
            FormID, 
            SubmissionID, 
            TrackingID, 
            FormTemplate = null, 
            CompanyID = null 
        } = payload;
        let data;
        
        if(UserID === ""){
            if(SubmissionID !== undefined) {
                    ({ data } = yield call(API.post, ApiRoute.formSubmission.retrieveAllFormSubmission,({TenantID,CurrPage,UserID,SubmissionID})));
            }
            else if(TrackingID !== undefined) {
                    ({ data } = yield call(API.post, ApiRoute.formSubmission.retrieveAllFormSubmission,({TenantID,CurrPage,UserID,TrackingID})));
            }
            else {
                    ({ data } = yield call(API.post, ApiRoute.formSubmission.retrieveAllFormSubmission,({TenantID,CurrPage,UserID})));
            }

        } else {
            switch(Type)
            {
                case 'personal':
                    ({ data } = yield call(API.post, ApiRoute.formSubmission.retrievePersonalFormSubmission,({TenantID,CurrPage:"", UserID:UserID})));
                    break;
                    
                case 'all':
                    ({ data } = yield call(API.post, ApiRoute.formSubmission.retrievePersonalFormSubmission,({TenantID,CurrPage,UserID})));
                    break;
                
                case 'approved':
                    ({ data } = yield call(API.post, ApiRoute.formSubmission.retrievePersonalApprovedForm,({TenantID,CurrPage,UserID,FormID})));
                    break;
                    
                case 'submission':
                    ({ data } = yield call(API.post, ApiRoute.formSubmission.retrievePersonalOngoingForm,({TenantID,CurrPage,UserID,FormID})));
                    break;
                    
                default:
                    ({ data } = yield call(API.post, ApiRoute.formSubmission.retrievePersonalFormSubmission,({TenantID,CurrPage:"", UserID:UserID})));
                    break;
            }
        }
       
        const processedData = data.map((submission) => {
            return {...submission, SubmissionData : submission.SubmissionData ? JSON.parse(submission.SubmissionData) : []}
        })

        if(UserID && UserID !== ""){
            const memberSubmissions = processedData.map((submission)=>{
                if(submission.UserID === UserID){
                    return submission;
                }
            })

            yield put(setFormContext({
                memberSubmissions : memberSubmissions
            }));
        }

        // if(FormTemplate && CompanyID){
        //     const filteredSubmission = processedData.map((submission) => {
        //         const formTemplateArray = FormTemplate.find((template) => template.FormID === submission.FormID);
        //         if(formTemplateArray && formTemplateArray.CompanyID === CompanyID){
        //             return submission;
        //         }
        //     })
        //     yield put(setFormContext({
        //         memberSubmissions : filteredSubmission
        //     }));
            
        // } else {
        //     yield put(setFormContext({
        //         memberSubmissions : memberSubmissions
        //     }));
        // }
       
        yield put(retrieveFormSubmissionSuccess(processedData));
       
    } catch (error){
        yield put(retrieveFormSubmissionError(error));
        console.error(error);
    }
}

function* updateFormSubmissionSaga({payload}) {
    try{
        const data = payload.data
        yield call(API.post, ApiRoute.formSubmission.updateFormSubmission, payload);
        yield put(updateFormSubmissionSuccess(data));
    } catch (error){
        yield put(updateFormSubmissionError(error));
        console.error(error);
    }
}

function* deleteFormSubmissionSaga({payload}) {
    try{
        yield call(API.post, ApiRoute.formSubmission.deleteFormSubmission, payload);
        yield put(deleteFormSubmissionSuccess(data));
    } catch (error){
        yield put(deleteFormSubmissionError(error));
        console.error(error);
    }
}

function* retrieveAllFormsCountSaga({payload}) {
    try{
        const { UserID, Type, TenantID, FormID } = payload;
        let data;
        if(UserID === ""){
            ({ data } = yield call(API.post, ApiRoute.formSubmission.retrieveAllFormsCount,(payload)));
            yield put(retrieveAllFormsCountSuccess(data));
        } else {
            switch(Type) {
                case 'all':
                    ({ data } = yield call(API.post, ApiRoute.formSubmission.retrieveAllPersonalFormsCount,({TenantID,UserID})));
                    yield put(retrieveAllFormsCountSuccess(data));
                    break;
                    
                case 'approved':
                    ({ data } = yield call(API.post, ApiRoute.formSubmission.retrievePersonalApprovedFormCount,({TenantID,UserID,FormID})));
                    yield put(retrieveAllFormsCountSuccess(data));
                    break;
                case 'submission':
                    ({ data } = yield call(API.post, ApiRoute.formSubmission.retrievePersonalOngoingFormCount,({TenantID,UserID,FormID})));
                    yield put(retrieveAllFormsCountSuccess(data));
                    break;
                default:
                    ({ data } = yield call(API.post, ApiRoute.formSubmission.retrieveAllPersonalFormsCount,({TenantID,UserID})));
                    yield put(retrieveAllFormsCountSuccess(data));
                    break;
            }
        }
       
    } catch (error){
        yield put(retrieveAllFormsCountError(error));
        console.error(error);
    }
}


export function* formSubmissionSagaWatcher(){
    yield takeLatest(createFormSubmission.type, createFormSubmissionSaga);
    yield takeLatest(updateFormSubmission.type, updateFormSubmissionSaga);
    yield takeLatest(deleteFormSubmission.type, deleteFormSubmissionSaga);
    yield takeLatest(retrieveFormSubmission.type, retrieveFormSubmissionSaga);
    yield takeLatest(retrieveAllFormsCount.type, retrieveAllFormsCountSaga);
}