const component = {
  MuiMenu: {
    styleOverrides: {
      root: () => ({
        maxHeight: "350px",
        "& .MuiList-root": {
          paddingTop: "unset",
          paddingBottom: "unset",
        },
      }),
    },
  },
};

export default component;
