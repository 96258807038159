import { call, delay, put, takeLatest } from "redux-saga/effects";

import {
  createRole,
  createRoleSuccess,
  createRoleError,
  retrieveRoles,
  retrieveRolesSuccess,
  retrieveRolesError,
  retrieveCurrRoles,
  retrieveCurrRolesSuccess,
  retrieveCurrRolesError,
  updateRole,
  updateRoleSuccess,
  updateRoleError,
  deleteRole,
  deleteRoleSuccess,
  deleteRoleError,
} from "../stores/slices/roleSlices";

import { ApiRoute } from "../enums/ApiRoute";
import { API } from "../http-common";

function* createRoleSaga({ payload }) {
  try {
    const { data } = yield call(API.post, ApiRoute.roles.createRole, payload);    
    yield put(createRoleSuccess(data));
  } catch (error) {
    yield put(createRoleError(error));
    console.error(error);
  }
}

function* retrieveRolesSaga({ payload }) {
  try {
    const { data } = yield call(API.post, ApiRoute.roles.retrieveAllRoles, payload);    
    yield put(retrieveRolesSuccess(data));
  } catch (error) {
    yield put(retrieveRolesError(error));
    console.error(error);
  }
}

function* retrieveCurrRolesSaga({ payload }) {
  try {
    const { data } = yield call(API.post, ApiRoute.roles.findCurrRoles, payload);    
    yield put(retrieveCurrRolesSuccess(data));
  } catch (error) {
    yield put(retrieveCurrRolesError(error));
    console.error(error);
  }
}

function* updateRoleSaga({ payload }) {

  try {
    yield call(API.post, ApiRoute.roles.updateRole, payload);    
    yield put(updateRoleSuccess(payload));
  } catch (error) {yield put(updateRoleError(error));
    console.error(error);
  }
}

function* deleteRoleSaga({ payload }) {
  try {
    yield call(API.post, ApiRoute.roles.deleteRole, payload);    
    yield put(deleteRoleSuccess(payload));
  } catch (error) {
    yield put(deleteRoleError(error));
    console.error(error);
  }
}

export function* rolesSagaWatcher() {
  yield takeLatest(createRole.type, createRoleSaga);
  yield takeLatest(retrieveRoles.type, retrieveRolesSaga);
  yield takeLatest(retrieveCurrRoles.type, retrieveCurrRolesSaga);
  yield takeLatest(updateRole.type, updateRoleSaga);
  yield takeLatest(deleteRole.type, deleteRoleSaga);
}
