const component = {
  MuiSwitch: {
    styleOverrides: {
      root: ({ theme }) => ({
        minWidth: "55px",
        width: "auto",
        height: "25px",
        padding: "0",
        marginRight: "10px",
        borderRadius: "30px",
        "&.Switch-sm": {
          "& .MuiSwitch-track": {
            width: "70px",
          },
        },
        "&.Switch-md": {
          "& .MuiSwitch-track": {
            width: "95px",
          },
        },
        "&.Switch-lg": {
          "& .MuiSwitch-track": {
            width: "100px",
          },
        },
        "&.Switch-xl": {
          "& .MuiSwitch-track": {
            width: "120px",
          },
        },
        "&.Activation-Switch": {
          "& .MuiSwitch-track": {
            "&:before": {
              content: '"Deactivate"',
            },
          },

          "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
            "&:before": {
              content: '""',
            },
            "&:after": {
              content: '"Activate"',
            },
          },
        },

        "&.Show-Switch": {
          "& .MuiSwitch-track": {
            "&:before": {
              content: '"Hide"',
            },
          },

          "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
            "&:before": {
              content: '""',
            },
            "&:after": {
              content: '"Show"',
            },
          },
          "& .MuiSwitch-switchBase.Mui-disabled.Mui-checked + .MuiSwitch-track":
            {
              opacity: "0.5",
            },
        },

        "&.Combine-Switch": {
          "& .MuiSwitch-track": {
            "&:before": {
              content: '"Combine"',
            },
          },

          "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
            "&:before": {
              content: '""',
            },
            "&:after": {
              content: '"Separate"',
            },
          },
          "& .MuiSwitch-switchBase.Mui-disabled.Mui-checked + .MuiSwitch-track":
            {
              opacity: "0.5",
            },
        },
        "&.Active-Only-Switch": {
          "& .MuiSwitch-track": {
            "&:before": {
              content: '"Active Only"',
            },
          },

          "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
            "&:before": {
              content: '""',
            },
            "&:after": {
              content: '"Inactive Only"',
            },
          },
          "& .MuiSwitch-switchBase.Mui-disabled.Mui-checked + .MuiSwitch-track":
            {
              opacity: "0.5",
            },
        },
        "& .MuiSwitch-thumb": {
          width: "18px",
          height: "18px",
          right: "50px",
          border: `1px solid ${theme.palette.border.main}`,
          boxShadow: "none",
          background: `${theme.palette.primary.white}`,
        },
        "& .MuiSwitch-track": {
          background: `${theme.palette.background.grey}`,
          border: `1px solid ${theme.palette.border.main}`,
          borderRadius: "30px",
          opacity: "1",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          "&:before, &:after": {
            fontSize: "14px",
            content: '""',
          },
          "&:before": {
            padding: "0px 8px 0px 25px",
          },
        },
        "& .MuiSwitch-sizeMedium": {
          padding: "0px !important",
          border: `1px solid ${theme.palette.border.main} !important`,
        },
        "& .MuiSwitch-switchBase": {
          transform: "translateX(4px)",
          padding: "0px",
          height: "25px",
          "&:hover": {
            background: "none",
          },
        },
        "& .MuiSwitch-switchBase.Mui-checked": {
          justifyContent: "flex-end",
          padding: "0px",
          right: "0",
          transform: "translateX(-3px)",
          "& .MuiSwitch-thumb": {
            background: `${theme.palette.primary.white}`,
            border: `1px solid ${theme.palette.border.active}`,
            boxShadow: "none",
          },
        },
        "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
          background: `${theme.palette.primary.primary}`,
          border: `1px solid ${theme.palette.border.active}`,
          opacity: "1",
          "&:before": {
            padding: "0px",
          },
          "&:after": {
            color: `${theme.palette.primary.white}`,
            padding: "0px 25px 0px 8px",
          },
        },
      }),
    },
  },
};

export default component;
