import { Route } from "react-router-dom"
import { crmRoutes } from "../enums/Route"
import ProtectedRoute from "./ProtectedRoute"
import TenantPortal from "../containers/tenant/TenantPortal"
import { Fragment } from "react"

export const TenantRoute = () => (
    <Fragment>
        <Route 
            path={crmRoutes.other.tenant}
            element={
                <ProtectedRoute>
                    <TenantPortal />
                </ProtectedRoute>
            }
        />
    </Fragment> 
)

