import React from "react";
import PropTypes from "prop-types";
import { Box, Skeleton, Stack } from "@mui/material";
import { FormLayoutTitle } from "../AccContainers/Layout";
import { GeneralForm, ItemsForm, CustomFieldsForm } from "./FormSection";
import { OtherInformationsForm } from "./FormSection/OtherInfoForm";
import { selectCurrSa } from "../../stores/slices/persistRolesSlices";
import { selectCurrRoles } from "../../stores/slices/roleSlices";
import { useSearchParams } from "react-router-dom";
export const MainForm = ({
  control,
  watch,
  resetField,
  loading = false,
  setValue,
  currentTransaction,
  options,
  getValues,
  customFields = null,
  activeTaxKey = null,
  is_invoice = false,
  isBill = false,
  isPurchase = false,
  transferTransactionTypeList,
}) => {

  const currSa = selectCurrSa();
  const currRoles = selectCurrRoles();
  const [searchParams, setSearchParams] = useSearchParams();
  const isNewTransaction = searchParams.get("action") !== undefined && searchParams.get("action") === "new";

  const editTransactionPermission = isNewTransaction ?  (currSa || currRoles[0]?.Invoice_Create) : (currSa || currRoles[0]?.Invoice_Edit_Transaction);
  const editItemsPermission = isNewTransaction ?  (currSa || currRoles[0]?.Invoice_Create) : (currSa || currRoles[0]?.Invoice_Edit_Items);
  const editIncludeOthersPermission = isNewTransaction ?  (currSa || currRoles[0]?.Invoice_Create) : (currSa || currRoles[0]?.Invoice_Edit_Include_Others);
  
  const renderSkeleton = (
    <Stack paddingY={2} spacing={1} width={1}>
      <Skeleton
        variant="text"
        width="50%"
        sx={({ typography }) => ({ ...typography.bodyText })}
      />
      <Skeleton variant="rounded" width="70%" height={100} />
    </Stack>
  );

  const {
    contacts = [],
    currencies = [],
    number_formats = [],
    tag_groups = [],
    company_setting = {},
    payment_terms = [],
    ...otherOptions
  } = options;

  return (
    <Stack component="form" autoComplete="off" width={1} spacing={1}>
      <Box component="div" width={1}>
        {loading ? (
          renderSkeleton
        ) : editTransactionPermission ? (
          
          <GeneralForm
            control={control}
            watch={watch}
            setValue={setValue}
            options={{
              number_formats: number_formats,
              currencies: currencies,
              tag_groups: tag_groups,
              contacts: contacts,
              payment_terms: payment_terms,
            }}
            transactionNo={
              currentTransaction ? currentTransaction.transaction_no : null
            }
            isInvoice={is_invoice}
            isBill={isBill}
            isPurchase={isPurchase}
          />
         
        ): null}
      </Box>
      <Box component="div" width={1}>
        {editItemsPermission ? <FormLayoutTitle title={"Items"} /> : null}
        {loading ? (
          renderSkeleton
        ) : editItemsPermission ? (
          <ItemsForm
            control={control}
            watch={watch}
            setValue={setValue}
            getValues={getValues}
            options={{
              ...otherOptions,
              payment_terms: payment_terms,
            }}
            companySetting={company_setting}
            activeTaxKey={activeTaxKey}
            is_invoice={is_invoice}
            isPurchase={isPurchase}
            transferTransactionTypeList={transferTransactionTypeList}
          />
        ):null}
      </Box>

      {customFields !== undefined && customFields.length > 0 ? (
        <Box component="div" width={1}>
          <FormLayoutTitle title={"Custom Fields"} />
          {loading ? (
            renderSkeleton
          ) : (
            <CustomFieldsForm control={control} customFields={customFields} />
          )}
        </Box>
      ) : null}

      <Box component="div" width={1}>
        {editIncludeOthersPermission ?  <FormLayoutTitle title={"Others"} /> : null}
        {loading ? (
          renderSkeleton
        ) : editIncludeOthersPermission ? (
          <OtherInformationsForm control={control} resetField={resetField} />
        ): null}
      </Box>
    </Stack>
  );
};

MainForm.propTypes = {
  control: PropTypes.any,
  isDirty: PropTypes.any,
  resetField: PropTypes.any,
  setValue: PropTypes.any,
  watch: PropTypes.any,
  options: PropTypes.any,
  contactOptions: PropTypes.any,
  loading: PropTypes.bool,
  getValues: PropTypes.any,
  customFields: PropTypes.any,
  transactionOptions: PropTypes.any,
  activeTaxKey: PropTypes.any,
  currentTransaction: PropTypes.any,
  is_invoice: PropTypes.any,
  isBill: PropTypes.any,
  isPurchase: PropTypes.any,
  transferTransactionTypeList: PropTypes.any,
};
