/* eslint-disable react/no-children-prop */
import React, { useState, useEffect, Fragment } from "react";
import { Column, Table } from "react-virtualized";
import Draggable from "react-draggable"
import Switch from "react-switch";
import moment from "moment";
import C_Button from "../../components/C_Button";
import { FaRegEye } from "react-icons/fa";
import { BsFonts } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { BiArrowBack, BiCheckboxChecked, BiDownload, BiListMinus, BiRadioCircleMarked } from "react-icons/bi";
import { RiArrowDownSFill, RiFileList3Line, RiTimer2Line, RiUser2Line } from "react-icons/ri";
import { AiFillCaretDown, AiFillCaretRight, AiFillPlusSquare, AiOutlineCheckCircle, AiOutlineClose, AiOutlineCloseCircle } from "react-icons/ai";
import { FiRotateCw } from "react-icons/fi";

import { HiOutlineCalendar, HiOutlineEnvelope, HiOutlineLink, HiOutlineMapPin, HiOutlinePhone } from "react-icons/hi2";
import { useRef } from "react";
import { Modal } from "bootstrap";
import ReactFlow, { isEdge, Background, Controls, ControlButton } from 'reactflow';
import { toPng } from "html-to-image";
import { v4 } from "uuid";
import C_Search from "../../components/C_Search";
import C_DialogButton from "../../components/C_DialogButton";

//slices
import { 
    retrieveAllClientsCount, 
    retrieveClient, 
    retrieveClientForm, 
    selectAvailableClientsGroup, 
    selectClient, 
    selectClientContext, 
    selectClientForms, 
    setClientContext, 
    updateClient 
} from "../../stores/slices/clientSlices";
import { retrieveClientSelected, selectClientSelected } from "../../stores/slices/clientSelectorSlices";
import { 
    bulkCreateClientAgent, 
    bulkRemoveClientAgent, 
    createClientAgent, 
    deleteClientAgent, 
    getClientAgent, 
    selectAllAgentHandler, 
    selectAllSelectedAgentHandler
} from "../../stores/slices/clientAgentSlices";
import { selectTenant } from "../../stores/slices/tenantSlices";
import { selectFormSubmission } from "../../stores/slices/formSubmissionSlices";
import { selectFormTemplate } from "../../stores/slices/formTemplateSlices";
import { selectAllClientGroupLeader } from "../../stores/slices/clientGroupLeaderSlices";
import { selectCurrTenant } from "../../stores/slices/persistRolesSlices";
import { selectCurrUserContext } from "../../stores/slices/companySlices";
import { selectUser } from "../../stores/slices/userAuthSlices";
import { selectCurrUserClientGroupRoles } from "../../stores/slices/clientGroupRolesSlices";

function ClientTable(props) {

     const user = selectUser();
     //ref
     const checkedRef = useRef([]);
     const handlerListModal = useRef(null);
     const flowRef = useRef(null);
     const rowFilterRef = useRef([]);
     const handlerFilterRef = useRef(null);
     const openFormRef = useRef(null);
     const creatorRef = useRef();
 
     //props
     const ClientManage = props.Client_Manage;
     const ClientDelete = props.Client_Delete;
     const tableLoader = props.tableLoader
     const showTable = props.showTable
     const isSa = props.isSa;
     const retrievalData = props.retrievalData;
     const retrievalDataCount = props.retrievalDataCount;
     const dataFilterArr = props.dataFilterArr;
     const selectedGroup = props.selectedGroup;
     const availableMemberList = props.availableMemberList;
     const rowFilterData = props.rowFilterData;
     const setDataFilterArr = props.setDataFilterArr;
     const tempSelectedFilter = props.tempSelectedFilter;
     const setTempSelectedFilter = props.setTempSelectedFilter;
     const filterRow = props.filterRow;
     const setFilterRow = props.setFilterRow;
     const handlerFilterRow = props.handlerFilterRow;
     const setHandlerFilterRow = props.setHandlerFilterRow;
     const isCreatedByFilterOpen = props.isCreatedByFilterOpen;
     const setIsCreatedByFilterOpen = props.setIsCreatedByFilterOpen;
 
     const filterConditionArr = props.filterConditionArr;
     const selectAllFilter = props.selectAllFilter;
     const setFilterHandlerList = props.setFilterHandlerList;
 
     const onHandleFilterConditionArr = props.onHandleFilterConditionArr;
     const onHandleNumberRangeInput = props.onHandleNumberRangeInput;
     const onHandleSelectedDateFilter = props.onHandleSelectedDateFilter;
     const onHandleSearchFilter = props.onHandleSearchFilter;
     const onHandleClearSearchFilter = props.onHandleClearSearchFilter;
     const onHandleSelectAllRowFilter = props.onHandleSelectAllRowFilter;
     const handleSelectedRow = props.handleSelectedRow;
     const handleSelectedFilterHandler = props.handleSelectedFilterHandler;
     const handleSelectedCreator = props.handleSelectedCreator;
     const filterHandlerList = props.filterHandlerList;
    
    const {
        filteredClientData, 
        filteredRowData,
        checkedClientState, 
        tempClientMergedData, 
        clientColArray,
        finalSelectedClientRow, 
        checkedAllClientState,
        clientColumnArray

    } = selectClientContext();
    const availableClientsGroup = selectAvailableClientsGroup();

    const { currUsers, currUserSA} = selectCurrUserContext();

    const dispatch = useDispatch();

    const tenant = selectCurrTenant();
    const formSubmission = selectFormSubmission();
    const formTemplate = selectFormTemplate();
    const ClientForms = selectClientForms();
    const client_selected = selectClientSelected();
    const allClientGroupLeader = selectAllClientGroupLeader();
    const allSelectedAgentHandler = selectAllSelectedAgentHandler();
    const clientAgents = selectAllAgentHandler();

    const [tableState, setTableState] = useState()
    const TABLE_TOTAL_WIDTH = window.screen.width * 0.8;
    const TABLE_TOTAL_HEIGHT = window.innerHeight * 0.55;
    const inner_height = window.innerHeight * 0.6;
    const template_card_height = window.innerHeight * 0.8;

    //components
    const [switchStatus, setSwitchStatus] = useState([])

    //form
    const [formClientID,setFormClientID] = useState("");
    const [currFormPreviewID,setCurrFormPreviewID] = useState("");
    const [isPreview,setIsPreview] = useState(false);
    const [checkboxArray,setCheckboxArray] = useState([]);
    const [radioArray,setRadioArray] = useState([]);
    const [currClientForm,setCurrClientForm] = useState([]);
    const [clientFormTemplate,setClientFormTemplate] = useState([]);
    const [clientSelectedList,setClientSelectedList] = useState([]);
    const [filterBy, setFilterBy] = useState([]);
    const [openHandlerList, setOpenHandlerList] = useState([]);
    const [searchTextAssignee, setSearchTextAssignee] = useState("");
    const [selectedAgentHandler, setSelectedAgentHandler] = useState(null);
    const [loadFlowChart, setLoadFlowChart] = useState(false);
    const [expandClientSection, setExpandClientSection] = useState([]);
    const [expandMemberSection, setExpandMemberSection] = useState("");
    const [clientGroupLeader,setClientGroupLeader] = useState([]);
    const [tempClientArr,setTempClientArr] = useState([]);
    const [clientColIndex, setClientColIndex] = useState("");
    const currUserClientGroupRole = selectCurrUserClientGroupRoles();

    const conditionArr = ["All", "Filled in", "Unfilled"]

    const showModalFunc = () => {
        const modalEle = openFormRef.current;
        const bsModal = new Modal(modalEle, {
          backdrop: "static",
          keyboard: false,
        });
        bsModal.show();
      };
    
      const hideModalFunc = () => {
        const modalEle = openFormRef.current;
        const bsModal = Modal.getInstance(modalEle);
        bsModal.hide();
      };

    useEffect(() => {
        function handleClickOutside(event) {
            // checkedRef.current.forEach(ref =>{if(ref) return ref.style.background = 'white'});
            rowFilterRef.current.forEach(ref => {
                if(ref && ref && !ref.contains(event.target)){
                    setFilterRow(false);
                }
            })

            if (handlerFilterRef.current && !handlerFilterRef.current.contains(event.target)) {
                setHandlerFilterRow(false);
            }

            if (creatorRef.current && !creatorRef.current.contains(event.target)) {
                setIsCreatedByFilterOpen(false);
            }

        }
        
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        
        // Unbind the event listener on cleanup
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [rowFilterRef, creatorRef, handlerFilterRef]);


    useEffect(() => {
        const flatHandlerArr = filteredClientData.flatMap(item => item?.handlerID?.split(",").map(item => item?.trim()));
        const filterOutEmptyHandler = flatHandlerArr?.filter(handler => handler?.trim() !== "");
        const constraintsHandlerArr = [...new Set(filterOutEmptyHandler)];
        
        setFilterHandlerList(constraintsHandlerArr);

      }, [filteredClientData])

    useEffect(()=>{
        setTempClientArr(filteredRowData);
    },[filteredRowData])

    useEffect(()=>{
        if(allClientGroupLeader !== undefined && allClientGroupLeader !== null && Array.isArray(allClientGroupLeader)){
            const newArray = allClientGroupLeader.filter(element => availableClientsGroup.map(clientGroup => clientGroup.GroupID).includes(element.GroupID));
            setClientGroupLeader(newArray)
        }
        
    },[allClientGroupLeader,availableClientsGroup])

    async function retrieveHandlers(item){
        dispatch(getClientAgent({ TenantID: tenant, ClientID: item }));
    }

    async function showHandlerListModalFunc(item){
        setOpenHandlerList([item])
        setLoadFlowChart(true);
        const modalEle = handlerListModal.current;
        const bsModal = new Modal(modalEle, {
            backdrop: "static",
            keyboard: false,
        });
        bsModal.show();
        try{
            retrieveHandlers([item]);
            setTimeout(() => {
                setLoadFlowChart(false);
            },2000);
         
        }catch (err){
            console.log(err)
        }
    
    };
    const hideHandlerListModalFunc = () => {
        const modalEle = handlerListModal.current;
        const bsModal = Modal.getInstance(modalEle);
        bsModal.hide();
    };

    useEffect(() => {
        
        if(!filteredClientData && !availableMemberList)
        return;
        const newNodes = allSelectedAgentHandler?.nodes?.map(node => {
          const getName = node?.typeOfData === "CLIENT" ? 
          filteredClientData?.find(client => client?.ClientID === node?.id)?.ClientName ?? "Invalid Data"
          :
          availableMemberList?.find(member => member?.uid === node?.id)?.displayName ?? "Invalid Data"
    
            return {
              ...node,
              sourcePosition: node?.typeOfData === "CLIENT" ? 'right' : 'left',
              targetPosition: node?.typeOfData === "CLIENT" ? 'right' : 'left',
              data: {
                label: getName,
              },
              name: getName
            }
    
        });
    
        const newHandler = allSelectedAgentHandler?.handler?.map(handle => {
          const members = handle.Handler.map(handlerItem => {
            const member = availableMemberList?.find(member => member.uid === handlerItem?.UserID);
            return {
              uid: handlerItem?.UserID,
              displayName: member?.displayName ?? "Invalid Member",
              ClientAgentID: handlerItem?.ClientAgentID
            };
          });
          return {
            ClientName: filteredClientData?.find(client => client?.ClientID === handle?.Client)?.ClientName ?? "Invalid Data",
            ClientID: handle?.Client,
            Handler: members
          }
        });
    
        const newEdges = allSelectedAgentHandler?.edges?.map(edge => {
          return {
            ...edge,
            source: edge?.source,
            sourceName: 
            availableMemberList?.find(member => member?.uid === edge?.source)
            ?.displayName ?? "Invalid Data",
            target: edge?.target,
            targetName: 
            filteredClientData?.find(client => client?.ClientID === edge?.target)
            ?.ClientName ?? "Invalid Data"
          }
        });
    
        const newData = {
          nodes: newNodes,
          handler: newHandler,
          edges: newEdges
        }
    
        setSelectedAgentHandler(newData);
      
    },[allSelectedAgentHandler, filteredClientData, availableMemberList, clientAgents]);
    
    useEffect(() => {
        
        if(ClientForms !== undefined && ClientForms !== null && Array.isArray(ClientForms)){
            setCurrClientForm(ClientForms)
        }

    },[ClientForms])

    useEffect(() => {
        if(client_selected !== undefined && client_selected !== null && Array.isArray(client_selected)){
            
            setClientSelectedList(client_selected)
        }

    },[client_selected])

    useEffect(()=>{
        if(formClientID !== "")
        {
            if(filterBy !== "")
            {
                dispatch(retrieveClientForm({TenantID:tenant,ClientID:formClientID,FormID:filterBy}))
            }
            else
            {
                dispatch(retrieveClientForm({TenantID:tenant,ClientID:formClientID,FormID:''}))
            }
       
        }

    },[formClientID,filterBy])
    
    useEffect(()=>{
        const filteredForms = formTemplate.filter(form =>form.FormData.some(data => data.questionType === "Client"));
        setClientFormTemplate(filteredForms)
    },[formTemplate])

    useEffect(()=>{
        if(currFormPreviewID !== "")
        {
            let clientIDList = [];

            clientFormTemplate?.map((form)=>{
                const clientTypeQuestion = form.FormData.find(element=>element.questionType === "Client");
                const previewForm = currClientForm.filter(element=>element.SubmissionID === currFormPreviewID);
                if(previewForm.length > 0)
                {
                    previewForm?.map((data)=>{
                        const clientTypeAnswer = JSON.parse(data.SubmissionData).find(element=>element.questionID === clientTypeQuestion.questionID)  
                        if(clientTypeAnswer !== undefined)
                        {
                            clientIDList.push(clientTypeAnswer.answer)
                        }
                    })
                }
               
            })

            dispatch(retrieveClientSelected({ TenantID:tenant,selectedClient: clientIDList }));
        }
    },[currFormPreviewID])
    
    useEffect(()=>{
        if(checkedClientState.length === 0)
        {
            // checkedRef.current.forEach(ref =>{if(ref) return ref.style.background = 'white'});
            checkedRef.current.forEach(ref =>{if(ref) return ref.classList.remove('selected-table-row')});
        }
   
    },[checkedClientState])

    useEffect(() => {
        let column = []
        let arrayA = []
        let arrayB = []
        let arrayBool = []

        clientColArray.map((col) => {
          arrayA.push([])
          arrayBool.push([])
        })
    
        //2D array
        clientColArray.map((col, index) => {
          tempClientMergedData.map((row) => {
            if(row[col.columnName] !== " " && row[col.columnName] !== "-" && row[col.columnName] !== "")
            {
                arrayA.map((data, dataIndex) => {
                    if (index === dataIndex){
                        
                        arrayA[dataIndex].push(row[col.columnName])
                    }
                })
            }
          })
        })
    
        //filter Dups
        arrayA.map((item) => {
          arrayB.push([...new Set(item)])
        })
    
    
        // setFinalSelectedClientRow({type:'FINAL_SELECTED_ROW',payload:arrayBool});
        // setTempFilterClientRowArray({type:'FILTER_ROW_ARRAY',payload:arrayB});

        dispatch(setClientContext({
            finalSelectedClientRow : arrayBool,
            tempFilterClientRowArray : arrayB
        }));
    
      }, [clientColArray])
    

      useEffect(() => {
        let array = []
    
        if (clientColIndex !== "" && clientColIndex > -1) {
          finalSelectedClientRow.map((item, index) => {
            if (index === clientColIndex) {
              item.map(data => {
                array.push(data);
              })
            }
          })
        }
        dispatch(setClientContext({
            selectedClientRow : array,
        }));
        // setSelectedClientRow({type:'SELECTED_ROW',payload:array})
    
      }, [clientColIndex, finalSelectedClientRow])

      useEffect(() => {
    
        if(currFormPreviewID !== undefined)
        {
        var checkbox = [];
        var radio = [];
        var quesArray = [];
        var question = [];
        var answer = [];
        var answerArray = [];
        var formID = "";
    
        //question
        formSubmission.map((item) => {
          if (item.SubmissionID === currFormPreviewID) {
            formID = item.FormID;
          }
        })
    
        formTemplate.map((element, index) => {
          if (element.FormID === formID) {
            quesArray.push(element.FormData);
          }
        })
    
        quesArray.map((item => {
          JSON.parse(item).map(data => {
            question.push(data);
          })
    
        }));
    
        formSubmission.map(item=>{
          if(currFormPreviewID === item.SubmissionID)
          {
            answerArray.push(JSON.parse(item.SubmissionData))
          }
        
        })
    
        answerArray.map((data)=>{
          data.map(item=>{
            answer.push(item);
          })
        })
    
        question.map((ques, index) => {
          checkbox.push([]);
          radio.push([]);
        })
    
        question.map((ques, index) => {
          answer.map((answer, answerIndex) => {
            if (answer.questionID === ques.questionID) {
    
              if (ques.questionType === "Checkboxes") {
                ques.option.map(option => {
                  if (answer.answer.includes(option)) {
                    checkbox[index].push(true);
                  }
                  else {
                    checkbox[index].push(false);
                  }
    
                })
              }
              if (ques.questionType === "Multiple Choice") {
                ques.option.map(option => {
                  if (answer.answer.includes(option)) {
                    radio[index].push(true);
                  }
                  else {
                    radio[index].push(false);
                  }
    
                })
              }
            }
    
          })
        })
        setCheckboxArray(checkbox);
        setRadioArray(radio);
        }
      }, [currFormPreviewID])

    useEffect(() => {
        let widths = {}
        for (var x = 0; x < clientColArray.length; x++) {
            widths = {
                ...widths,
                handler: clientColArray.length < 4 ? 0.25 :0.15,
                createdAt:clientColArray.length < 4 ? 0.25 :0.15,
                createdBy: clientColArray.length < 4 ? 0.25 :0.15,
                [clientColArray[x].columnName]: clientColArray.length < 4 ? 0.25 :0.15,
            }
        }

        setTableState({ width: widths })
    }, [clientColArray]);


    useEffect(() => {
        let tempStatus = [];

        tempClientMergedData.map((data)=>{
            tempStatus.push([]);
        })

        clientColArray.map((col)=>{
            tempClientMergedData.map((data,index)=>{
                for(const key in data)
                {
                    //console.log(key)
                    if(col.columnName === key)
                    {
                        if(col.columnType === "Switch")
                        {   
                            tempStatus[index] = data[key];
                        }
                    }
                }
            })
        })

        setSwitchStatus(tempStatus)

    }, [clientColArray,tempClientMergedData])

      useEffect(() => {
            dispatch(setClientContext({checkedAllClientState : false}));
            // setCheckedAllClientState({type:"CHECKED_ALL_CLIENT",payload:false});

    }, [tempClientMergedData])

    const openFilterRowModal = (index) => {
        setFilterRow(!filterRow);
        setClientColIndex(index);
        if(index === clientColIndex){
            setFilterRow(!filterRow);
        } else {
            setFilterRow(true);
        }
    }

    const onHandleSelectedDate = (value, columnIndex, type) => {
        const filterArr = [...dataFilterArr];
        const tempFilterArr = [...tempSelectedFilter];
        const hasDateFilter = filterArr.some(data => data.filterType === columnIndex);

        if(hasDateFilter){
            const index = filterArr.map(data => data.filterType).indexOf(columnIndex);
            filterArr[index].filterValue[type] = value;
            tempFilterArr[index].filterValue[type] = value;
        } else {
            if(type === "startDate") {
                filterArr.push({filterType:columnIndex, filterValue: {
                    startDate: value,
                    endDate: ""
                }});
                tempFilterArr.push({filterType:columnIndex, filterValue: {
                    startDate: value,
                    endDate: ""
                }, rowIndex: null});
            } else {
                filterArr.push({filterType:columnIndex, filterValue: {
                    startDate: "",
                    endDate: value
                }});
                tempFilterArr.push({filterType:columnIndex, filterValue: {
                    startDate: "",
                    endDate: value
                }, rowIndex: null});
            }
        }
        
        setDataFilterArr(filterArr);
        setTempSelectedFilter(tempFilterArr)
    }

    const handleSelectedClient = (e, index) => {

        const updatedCheckedState = [...checkedClientState]

        if(e.target.checked)
        {
            updatedCheckedState.push(e.target.value)
            checkedRef.current[index].classList.add('selected-table-row');
        }
        else
        {   
            let idx = updatedCheckedState.indexOf(e.target.value);
            updatedCheckedState.splice(idx,1)
            checkedRef.current[index].classList.remove('selected-table-row')
        }

        dispatch(setClientContext({
            checkedClientState : updatedCheckedState,
        }));
        // setCheckedClientState({ type:"CHECKED_CLIENT", payload: updatedCheckedState });

    };


    const handleSelectedAllClient = (e) => {
        const { checked } = e.target;
        let array = [];
        // setCheckedAllClientState(!checkedAllClientState)
        if(e.target.checked)
        {
            tempClientMergedData.map((item,index)=>{
                array.push(item.ClientID)
            })
            dispatch(setClientContext({
                checkedAllClientState : !checkedAllClientState,
                checkedClientState : array
            }));
            // setCheckedClientState({ type:"CHECKED_CLIENT", payload: array });
        }
        else
        {   
            dispatch(setClientContext({
                checkedAllClientState : !checkedAllClientState,
                checkedClientState : array
            }));
            // setCheckedClientState({ type:"CHECKED_CLIENT", payload: array });
        }
        
        // checkedRef.current.forEach(ref => ref.style.background = checked ? 'aliceblue' : 'white');
        checkedRef.current.forEach(ref => checked ? ref?.classList.add('selected-table-row') : ref?.classList.remove('selected-table-row'));
      
    };

    function _headerRenderer() {
        return (
            <label  style={{ display: 'flex', alignItems: 'center' }}>
                <input
                    className=""
                    type="checkbox"
                    onChange={(e) => {
                        handleSelectedAllClient(e);
                    }}
                    checked={checkedAllClientState}
                />
                <span className="checkmark"></span>
            </label>
        );
    }
    function _headerRendererCreatedBy() {
        return (
            <div className={`ReactVirtualized__Table__headerTruncatedText`} style={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between', width:tableState && tableState.width["createdBy"] * TABLE_TOTAL_WIDTH }}>
                
                <div className="d-flex" style={{position:'relative', width:'100%'}}>
                    <span>Created By</span>
                    <div><RiArrowDownSFill style={{ marginLeft: '10px', cursor: 'pointer' }} size={15} onClick={() => {
                        setIsCreatedByFilterOpen(!handlerFilterRow); setFilterRow(false); setHandlerFilterRow(false);
                        }} /></div>
                        {isCreatedByFilterOpen &&
                        <>
                            <div ref={creatorRef} className='client-filter-card d-flex flex-wrap hide_scrollbar' style={{ position: 'absolute', left:'0',top:'0', borderRadius: '10px', zIndex: 10000, marginBottom: '0px', marginTop: '30px', background: 'white', maxHeight: '300px', overflowY: 'scroll' }}>
                                <div className='client-filter-card-body'>
                                    <span className="font-s" style={{ padding: '0px', margin: '0px', fontWeight: 'normal'}}>Filters</span>
                                    <hr style={{ padding: '0px', margin: '0px', marginBottom: '3px' }}></hr>
                                    <ul className="list-group" style={{ width: '235px', borderRadius: '10px',rowGap:'5px' }}>
                                    {(currUserSA.length < 1) && <span className="font-xs" style={{color:'#019f9f'}}>No data can be filtered.</span>}
                                    {currUserSA?.map(user => {
                                        const UserID = user.uid;
                                        const displayName = user.displayName;
                                        const isSelected = tempSelectedFilter.some(data => data.filterValue === UserID && data.filterType === "Creator");
                                        return(
                                            <li className='list-group-items' style={{ listStyleType: 'none' }}>
                                                <label style={{ cursor: 'pointer',display: 'flex', alignItems: 'center' }}>
                                                <input type='checkbox' style={{ width: '13px', height: '13px' }} className='col-md-3' name={displayName} value={UserID} checked={isSelected} onChange={(e) => handleSelectedCreator(e)} />
                                                <span className="checkmark"></span>
                                                <span className='col-md-9 font-xxs' style={{paddingLeft: '10px', fontWeight: 'normal'}}>{displayName}</span>
                                                </label>
                                            </li>
                                        )
                                    })}
                                    </ul>
                                </div>
                            </div>
                        </>
                    }
                </div>
                
                <Draggable
                    axis="x"
                    defaultClassName="DragHandle"
                    defaultClassNameDragging="DragHandleActive"
                    onDrag={(event, { deltaX }) =>
                        resizeRow({
                            dataKey: "createdBy",
                            deltaX
                        })
                    }
                    position={{ x: 0 }}
                    zIndex={999}
                >
                    <span className="DragHandleIcon">⋮</span>
                </Draggable>
            </div>
        );
    }

    function _headerRendererHandler() {
        return (
            <div className={`ReactVirtualized__Table__headerTruncatedText`} style={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between', width:tableState && tableState.width["handler"] * TABLE_TOTAL_WIDTH }}>
                
                <div className="d-flex" style={{position:'relative', width:'100%'}}>
                    <span>Handler</span>
                    <div><RiArrowDownSFill style={{ marginLeft: '10px', cursor: 'pointer' }} size={15} onClick={() => {
                        setHandlerFilterRow(!handlerFilterRow); setFilterRow(false);
                        }} /></div>
                        {handlerFilterRow &&
                        <>
                            <div ref={handlerFilterRef} className='client-filter-card d-flex flex-wrap hide_scrollbar' style={{ position: 'absolute', left:'0',top:'0', borderRadius: '10px', zIndex: 10000, marginBottom: '0px', marginTop: '30px', background: 'white', maxHeight: '300px', overflowY: 'scroll' }}>
                                <div className='client-filter-card-body'>
                                    <span className="font-s" style={{ padding: '0px', margin: '0px', fontWeight: 'normal'}}>Filters</span>
                                    <hr style={{ padding: '0px', margin: '0px', marginBottom: '3px' }}></hr>
                                    <ul className="list-group" style={{ width: '235px', borderRadius: '10px',rowGap:'5px' }}>
                                    {(filterHandlerList.length < 1) && <span className="font-xs" style={{color:'#019f9f'}}>No data can be filtered.</span>}
                                    {filterHandlerList?.map(handler => {
                                        const isSelected = tempSelectedFilter.some(data => data.filterValue === handler && data.filterType === "Handler");
                                        const handlerObj = currUsers.find(member => member.uid === handler);
                                        const handlerName = handlerObj !== undefined ? handlerObj.displayName : ""
                                        return(
                                            <li className='list-group-items' style={{ listStyleType: 'none' }}>
                                                <label style={{ cursor: 'pointer',display: 'flex', alignItems: 'center' }}>
                                                <input type='checkbox' style={{ width: '13px', height: '13px' }} className='col-md-3' name={handler} value={handler} checked={isSelected} onChange={(e) => handleSelectedFilterHandler(e)} />
                                                <span className="checkmark"></span>
                                                <span className='col-md-9 font-xxs' style={{paddingLeft: '10px', fontWeight: 'normal'}}>{handlerName}</span>
                                                </label>
                                            </li>
                                        )
                                    })}
                                    </ul>
                                </div>
                            </div>
                        </>
                    }
                </div>
                
                <Draggable
                    axis="x"
                    defaultClassName="DragHandle"
                    defaultClassNameDragging="DragHandleActive"
                    onDrag={(event, { deltaX }) =>
                        resizeRow({
                            dataKey: "handler",
                            deltaX
                        })
                    }
                    position={{ x: 0 }}
                    zIndex={999}
                >
                    <span className="DragHandleIcon">⋮</span>
                </Draggable>
            </div>
        );
    }

    function _headerRendererCreatedAt() {
        return (
            <div className={`ReactVirtualized__Table__headerTruncatedText`} style={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between', width:tableState && tableState.width["handler"] * TABLE_TOTAL_WIDTH }}>
                <span>Creation Date</span>
                <Draggable
                    axis="x"
                    defaultClassName="DragHandle"
                    defaultClassNameDragging="DragHandleActive"
                    onDrag={(event, { deltaX }) =>
                        resizeRow({
                            dataKey: "createdAt",
                            deltaX
                        })
                    }
                    position={{ x: 0 }}
                    zIndex={999}
                >
                    <span className="DragHandleIcon">⋮</span>
                </Draggable>
            </div>
        );
    }

    const isJSON = (str) => {
        if (str !== undefined) {
          // Check if the string is not numeric
          if (!isNaN(str)) {
            return false;
          }
      
          // Check if the string is a valid JSON
          try {
            JSON.parse(str);
            return true;
          } catch (e) {
            return false;
          }
        }
    };

      
    // Count and return nearest alert color
    function calculateNearestAlert(item, index) {
        if(item && tempClientMergedData[index][item.columnName]?.expiry){
            const isCompleted = tempClientMergedData[index][item.columnName]?.status;
            const dueDate = moment(tempClientMergedData[index][item.columnName]?.expiry);
            const dateTimeNow = moment(); // Sample 2023-11-21 16:33:52
            const allExpiryPeriod = item?.option?.map(option => {
                const { period, unit, color } = option;
                const numericPeriod = parseInt(period);
                const effectiveDate = dueDate.clone().subtract(numericPeriod, unit);
                const differenceInDays = dateTimeNow.diff(effectiveDate, 'day') + 1;
                
                return { color, effectiveDate: moment(effectiveDate).format("DD/MM/YYYY"), diff: differenceInDays };
            });
            // Get effective date that are triggered
            const filteredExpiryPeriod = allExpiryPeriod.filter(item => dateTimeNow.isAfter(moment(item.effectiveDate, 'DD/MM/YYYY')));
            // Get smallest diff, means nearest
            const smallestDiffItem = filteredExpiryPeriod.reduce((min, item) => (item.diff < min.diff ? item : min), filteredExpiryPeriod[0]);
            return !isCompleted && smallestDiffItem ? smallestDiffItem?.color : "#FFFFFF";
        }else{
            return "#FFFFFF";
        };
    };

    function updateSwitchStatus(index, clientColIndex) {
        const clientArray = tempClientArr.map((client, clientIndex) => {
            if (clientIndex === index) {
                const jsonObj = isJSON(client.ClientData) ? JSON.parse(client.ClientData) : client.ClientData;
                const updatedJsonObj = jsonObj.map((item) => {
                    if (item.columnID === clientColArray[clientColIndex].columnID) {
                        return {
                            ...item,
                            rowValue: !item.rowValue
                        };
                    } else {
                        return item;
                    }
                });
                
                // Return a new client object with updated ClientData
                return {
                    ...client,
                    ClientData: updatedJsonObj 
                };
            } else {
                return client;
            }
        });
        
        
        const newArray = [{
            ClientID: clientArray[index]?.ClientID,
            ClientData: JSON.stringify(clientArray[index]?.ClientData),
          }]
        dispatch(updateClient({TenantID:tenant, data:newArray}))
        dispatch(retrieveAllClientsCount(retrievalDataCount));
        dispatch(retrieveClient(retrievalData));
   

    }

    //TABLE
    const headerRenderer = ({ columnData, dataKey, disableSort, label, sortBy, sortDirection }) => {
        let target = ""
        let type = ""
        let name;
        let privilege;
        let columnIndex;
        let duplicate;
        let dateType;
        let minimum;
        let maximum;
        let startDate;
        let endDate;
        clientColArray.map((item, index) => {
            if (item.columnName === dataKey) {
                target = index;
                type = item.columnType;
                privilege = item.privilege
                name = item.columnName;       
                columnIndex = item.columnIndex; 
                dateType = item.columnType === "Date" ? item.dateType : "YYYY-MM-DD";
            }
        })
        const isFiltered = props.dataFilterArr.some(item=>parseInt(item.filterType) === parseInt(columnIndex));
        const isUnfilled = filterConditionArr[name] === "Unfilled"
        const searchText = (dataFilterArr.length > 0 && dataFilterArr.find(data => data.filterType === columnIndex && data.action === "Search") !== undefined) 
        ? dataFilterArr.find(data => data.filterType === columnIndex && data.action === "Search")?.filterValue 
        : "";
        if(type === "Numbers"){
            minimum = (dataFilterArr.length > 0 && dataFilterArr.find(data => data.filterType === columnIndex && data.action === "Filter") !== undefined) 
            ? dataFilterArr.find(data => data.filterType === columnIndex && data.action === "Filter")?.filterValue.minimum 
            : "";
            maximum = (dataFilterArr.length > 0 && dataFilterArr.find(data => data.filterType === columnIndex && data.action === "Filter") !== undefined) 
            ? dataFilterArr.find(data => data.filterType === columnIndex && data.action === "Filter")?.filterValue.maximum 
            : "";
        }

        if(type === "Date"){
            startDate = (dataFilterArr.length > 0 && dataFilterArr.find(data => data.filterType === columnIndex && data.action === "Filter") !== undefined) 
            ? dataFilterArr.find(data => data.filterType === columnIndex && data.action === "Filter")?.filterValue.startDate 
            : "";
            endDate = (dataFilterArr.length > 0  && dataFilterArr.find(data => data.filterType === columnIndex && data.action === "Filter") !== undefined) 
            ? dataFilterArr.find(data => data.filterType === columnIndex && data.action === "Filter").filterValue.endDate 
            : "";
        }
        return (
            <Fragment key={dataKey} >
                <div className={`ReactVirtualized__Table__headerTruncatedText ${privilege === "Hidden" ? "sa-hidden-column-header" : ""}`} style={{ alignItems: 'center', display: 'flex', width:tableState && tableState.width[name] * TABLE_TOTAL_WIDTH }}>
                    <div className="d-flex" style={{position:'relative', width:'100%'}}>
                        <span>{label} {isFiltered ? <span className={`${privilege === "Hidden" ? "filtered-column-header-font": "filtered-column-header"}`}>{`(Filtered)`}</span> : <></>}</span>
                        {(type !== "Switch" && type !== "Checkboxes" && type !== "Multiple Choices" && type !== "Dropdown") 
                        && <div><RiArrowDownSFill style={{ marginLeft: '10px', cursor: 'pointer' }} size={15} onClick={() => {openFilterRowModal(target)}} /></div>}
                        {(duplicate === false) && <span className="dup-span">{`(unique)`}</span>}
                        {(filterRow && target === clientColIndex) &&
                        <>
                        <div className='client-filter-card' ref={element => rowFilterRef.current[columnIndex] = element}>
                            <div className='client-filter-card-body'>
                                <span className="font-s" style={{ padding: '0px', margin: '0px', fontWeight: 'normal'}}>Filters</span>
                                <hr style={{ padding: '0px', margin: '0px', marginBottom: '3px' }}></hr>
                                {/* {Object.keys(filterConditionArr).map(keys => {
                                    if(keys === name){
                                        return (
                                            <> */}
                                            <ul className="list-group">
                                                {/* {!isUnfilled && */}
                                                <div className="filter-search">
                                                    <C_Search
                                                        height={"35px"}
                                                        placeholder={`Search`}
                                                        onChange={(e) => {
                                                            onHandleSearchFilter(e, columnIndex, type)
                                                        }}
                                                        value={searchText}
                                                        searchText={searchText}
                                                        closeFunction={() => {
                                                            onHandleClearSearchFilter(columnIndex, type);
                                                        }}
                                                        borderRadius={"5px"}
                                                        // containerWidth={"30%"}
                                                    />
                                                </div>
                                                {/* } */}
                                                
                                                {type === "Numbers" && 
                                                    <li className="list-group-items">
                                                        <div className="number-range-filter">
                                                            <label>
                                                                <input type={"number"} className="number-input" placeholder="Minimum"
                                                                value={minimum} onChange={(e) => {onHandleNumberRangeInput(e.target.value, columnIndex, "minimum", type)}}/>
                                                            </label>
                                                            <span className="number-range-symbol"> ~ </span>
                                                            <label>
                                                                <input type={"number"} className="number-input" placeholder="Maximum"
                                                                value={maximum} onChange={(e) => {onHandleNumberRangeInput(e.target.value, columnIndex, "maximum", type)}}/>
                                                            </label>
                                                        </div>
                                                    </li>
                                                }
                                                {conditionArr.map(cond => {
                                                    return (
                                                    <li className='list-group-items' style={{ listStyleType: 'none' }}>
                                                        <label style={{ cursor: 'pointer',display: 'flex', alignItems: 'center' }}>
                                                        <input type='radio' style={{ width: '13px', height: '13px' }} checked={filterConditionArr[name] === cond} 
                                                            onChange={()=> {
                                                                onHandleFilterConditionArr(cond, name, type);
                                                            }} className='col-md-3' name={cond} value={cond}
                                                        />
                                                        <span className='col-md-9 font-xs' style={{paddingLeft: '10px', fontWeight: 'normal'}}>{cond}</span>
                                                        </label>
                                                    </li>
                                                    );
                                                })}
                                            </ul>
                                        {/*                                                 
                                            </>
                                        );
                                    }
                                })} */}
                                {/* HR line */}
                                {(rowFilterData[name] === undefined) || (type !== "Date" && type !== "Google Map" && type !== "Member" 
                                    && type !== "Short Text" && type !== "Multiline" && type !== "Rich Text Editor" && type !== "Numbers" && type !== "Email") 
                                    && <hr style={{ padding: '0px', margin: '0px', marginBottom: '3px' }}/>
                                }
                                <ul className="list-group">
                                <>
                                {((rowFilterData[name] === undefined || isUnfilled) && type !== "Date") && <span className="font-xs" style={{color:'#019f9f'}}>No data can be filtered.</span>}
                                {!isUnfilled && 
                                    <>
                                    {(rowFilterData[name] === undefined) || (type !== "Date" && type !== "Short Text" && type !== "Multiline" && type !== "Rich Text Editor" && type !== "Numbers" && type !== "Email") &&
                                    <li className='list-group-items' style={{ listStyleType: 'none' }}>
                                        <label style={{ cursor: 'pointer',display: 'flex', alignItems: 'center' }}>
                                        <input type='checkbox' style={{ width: '12px', height: '12px' }} className='col-md-3' 
                                        onChange={(e)=> {onHandleSelectAllRowFilter(e, name, columnIndex, rowFilterData, type)}}  checked={selectAllFilter[name]}/>
                                        <span className="checkmark"></span>
                                        <span className='col-md-9 font-xs' style={{paddingLeft: '10px', fontWeight: 'normal'}}>Select All</span>
                                        </label>
                                    </li>
                                    }
                                    
                                    {type === "Date" &&
                                        <div className="date-filter">
                                            <label>
                                                <input type={"datetime-local"} className="date-input"
                                                value={startDate} onChange={(e) => {onHandleSelectedDateFilter(e.target.value, columnIndex, "startDate", type)}}/>
                                            </label>
                                            <span> - </span>
                                            <label>
                                                <input type={"datetime-local"} className="date-input"
                                                value={endDate} onChange={(e) => {onHandleSelectedDateFilter(e.target.value, columnIndex, "endDate", type)}}/>
                                            </label>
                                        </div>
                                    }
                                    {type === "Google Map" &&
                                    <>
                                        {rowFilterData[name] && rowFilterData[name].map((row, rowIndex) => {
                                            if(row !== "" || row !== "-")
                                            {
                                                const isSelected = tempSelectedFilter.some(data => data.filterType === columnIndex && data.action === "Filter" && data.rowIndex === rowIndex);
                                                
                                                var regexResult = /\bplace([^@]*)/.exec(row);
                                                if (regexResult !== undefined && regexResult !== null && regexResult.length !== 0) 
                                                {
                                                    var extraction = regexResult[1].toString().replace(/\+/g, " ")
                                                    var result = extraction.replace(/\//g, "")
                                                    return (
                
                                                        //row[item.columnName]
                                                        <li className='list-group-items' key={rowIndex}  style={{ listStyleType: 'none' }}>
                                                            <label style={{ cursor: 'pointer',display: 'flex', alignItems: 'center' }}>
                                                            <input type='checkbox' style={{ width: '12px', height: '12px' }} className='col-md-3' name={row} value={row} checked={isSelected} onChange={(e) => handleSelectedRow(e, columnIndex, rowIndex, type)} />
                                                            <span className="checkmark"></span>
                                                            <span className='col-md-9 font-xs' style={{paddingLeft: '10px', fontWeight: 'normal', textTransform: 'capitalize' }}>{decodeURIComponent(result)}</span>
                                                            </label>
                                                        </li>
                                                        )
                                                }
                                                
                                            }
                                            
                                        })}
                                    </>
                                    }
                                    {type === "Member" &&
                                        <>
                                            {rowFilterData[name] && rowFilterData[name].map((row, rowIndex) => {
                                                const isSelected = tempSelectedFilter.some(data => data.filterType === columnIndex && data.action === "Filter" && data.rowIndex === rowIndex);
                                                var columnObj = columnArray.find(element => element.columnName === "UserName");
                                                
                                                return (
                                                //row[item.columnName]
                                                <li className='list-group-items' key={rowIndex} style={{ listStyleType: 'none' }}>
                                                    <label style={{ cursor: 'pointer',display: 'flex', alignItems: 'center'  }}>
                                                    <input type='checkbox' style={{ width: '12px', height: '12px' }} className='col-md-3' name={row} value={row} checked={isSelected} onChange={(e) => handleSelectedRow(e, columnIndex, rowIndex, type)} />
                                                    <span className="checkmark"></span>
                                                    <span className='col-md-9 font-xs' style={{paddingLeft: '10px', fontWeight: 'normal' }}>
                                                        {/* {row} */}
                                                        {
                                                        columnObj !== undefined &&   
                                                        rowData.map((item,index)=>{
                                                            if(item.UserID === row)
                                                            {
                                                            return (
                                                                item.MemberData.map((data,idx)=>{
                                                                        if(columnObj.columnID === data.columnID)
                                                                        {
                                                                            return (data.rowValue);
                                                                        }
                                                                })  
                                                            )
                                                            }
                                                        })
                                                        }
                                                    </span>
                                                    </label>
                                                </li>
                                                )
                                            })}
                                        </>
                                    }
                                    {type === "Alert" &&
                                        <>
                                            {rowFilterData[name] && rowFilterData[name]?.map((row, rowIndex) => {
                   
                                                if (typeof row !== 'object' || !row) {
                                                    // Skip if row is not an object or is null/undefined
                                                    return null;
                                                  }
                                                const isSelected = tempSelectedFilter.some(data => data.filterType === columnIndex && data.rowIndex === rowIndex);
                                                const status = row ? "Completed" : "Pending";
                                                return (
                                                <li className={`list-group-items`} key={rowIndex} style={{ listStyleType: 'none' }}>
                                                    <label title={row} style={{ cursor: 'pointer',display: 'flex', alignItems: 'center' }}>
                                                    <input type='checkbox' style={{ width: '13px', height: '13px' }} className='col-md-3' name={status} value={status} checked={isSelected} onChange={(e) => handleSelectedRow(e, columnIndex, rowIndex, type)} />
                                                    <span className="checkmark"></span>
                                                    <span className='col-md-9 font-xxs' style={{paddingLeft: '10px', fontWeight: 'normal', width: "85%"}}>{status}</span>
                                                    </label>
                                                </li>
                                                )
                                            })}
                                        </>
                                    }
                                    
                                    {(type !== "Date" && type !== "Google Map" && type !== "Member" && type !== "Alert"
                                    && type !== "Short Text" && type !== "Multiline" && type !== "Rich Text Editor" && type !== "Numbers" && type !== "Email") &&
                                        <>
                                            {rowFilterData[name] && rowFilterData[name].map((row, rowIndex) => {
                                                const isSelected = tempSelectedFilter.some(data => data.filterType === columnIndex && data.action === "Filter" && data.rowIndex === rowIndex);
                                                
                                                return (
                                                <li className='list-group-items' key={rowIndex} style={{ listStyleType: 'none' }}>
                                                    <label style={{ cursor: 'pointer',display: 'flex', alignItems: 'center'}}>
                                                    <input type='checkbox' style={{ width: '12px', height: '12px' }} className='col-md-3' name={row} value={row} checked={isSelected} onChange={(e) => handleSelectedRow(e, columnIndex, rowIndex, type)} />
                                                    <span className="checkmark"></span>
                                                    <span className='col-md-9 font-xss' style={{paddingLeft: '10px', fontWeight: 'normal' }}>{row}</span>
                                                    </label>
                                                </li>
                                                )
                                            })}
                                        </>
                                    }
                                    </>
                                }
                                </>
                                </ul>
                            </div>
                        </div>
                    </>
                    }
                </div>
            </div>
            <Draggable
                axis="x"
                defaultClassName="DragHandle"
                defaultClassNameDragging="DragHandleActive"
                onDrag={(event, { deltaX }) =>
                    resizeRow({
                        dataKey,
                        deltaX
                    })
                }
                position={{ x: 0 }}
                zIndex={999}
            >
                <span className="DragHandleIcon">⋮</span>
            </Draggable>
        </Fragment>
        );
    };

    const resizeRow = ({ dataKey, deltaX }) => {
        setTableState(prevState => {
            const prevWidths = prevState.width;
            const percentDelta = deltaX / TABLE_TOTAL_WIDTH;

            let nextDataKey = ""
            clientColArray.map((item, index, { length }) => {
                if (item.columnName === dataKey) {
                    if (index + 1 === length) {
                        nextDataKey = "IsLastIndex"
                    }
                    else if (index + 1 <= length) {
                        nextDataKey = clientColArray[index + 1].columnName
                    }
                }
            })

            if (nextDataKey === "IsLastIndex") {
                return {
                    width: {
                        ...prevWidths,
                        [dataKey]: prevWidths[dataKey] + percentDelta,
                    }
                };
            }
            else {
                return {
                    width: {
                        ...prevWidths,
                        [dataKey]: prevWidths[dataKey] + percentDelta,
                        [nextDataKey]: prevWidths[nextDataKey] - percentDelta
                    }
                };
            }

        });
    };

    function _rowRenderer({
        style,
        key, //Unique key within array of rows
        index //Index of row within collection
    }) {
        // const rawCurrentData = (tempClientMergedData[index] !== null && rawCurrClientData[index] !== null) ? tempClientMergedData[index]?.ClientID === rawCurrClientData[index]?.ClientID ? rawCurrClientData[index]?.handlerIDs : null : null;
        const rawID = tempClientMergedData.map(item => {
            return item.handlerID?.split(', ') ?? []
        })
        .map(array => {
            const filteredArray = array.filter(item => item.trim() !== '') ?? [];
            return filteredArray.length > 0 ? filteredArray : [];
          });
        
        const rawName = tempClientMergedData.map(item => {return item.handlerName?.split(', ') ?? []})
        .map(array => {
            const filteredArray = array.filter(item => item.trim() !== '' ?? '');
            return filteredArray.length > 0 ? filteredArray : [];
          });
        
        const rawHandlerID = rawID[index];
        const rawHandlerName = rawName[index];

        // Check Alert component
        const alertComponent = clientColArray.find(item => item.columnType === "Alert");
        const rowColor = calculateNearestAlert(alertComponent, index);

        const rowStyling = {
            ...style,
            background: rowColor,
        }
        const creatorUserID = tempClientMergedData[index].UserID;
        const creatorObj = currUserSA.find(user => user.uid === creatorUserID);
        // console.log(tempClientMergedData)
        const ViewableColumn = currUserClientGroupRole ? currUserClientGroupRole?.ViewableColumn : [];
    
        const hasClientNotCreatedByCurrUser = tempClientMergedData[index].UserID !== user?.uid;
        return (
            <div
                ref={element => checkedRef.current[index] = element}
                key={key}
                className={`ReactVirtualized__Table__row client-table-row`}
                role="row"
                style={{
                    ...rowStyling
                }}
            >
                    <div
                        className="ReactVirtualized__Table__rowColumn d-flex"
                        role="gridcell"
                        style={{ overflow: "hidden", width: "100px", justifyContent: 'center' }}
                    >
                        <label style={{ display: 'flex', alignItems: 'center' }}>
                            <input
                                className=""
                                type="checkbox"
                                onChange={(e) => {
                                    handleSelectedClient(e, index);
                                    //console.log(item) 
                                }}
                                value={tempClientMergedData[index]["ClientID"]}
                                checked={checkedClientState.includes(tempClientMergedData[index]["ClientID"])}
                            />
                            <span className="checkmark"></span>
                        </label>
                    </div>
                
                <div
                    className="ReactVirtualized__Table__rowColumn handlerListing"
                    role="gridcell"
                    key={"createdBy"}
                    style={{ overflow: "hidden", width: tableState && tableState.width["createdBy"] * TABLE_TOTAL_WIDTH }}
                    
                >
                    {creatorObj ? 
                        <span key={index} title={creatorObj.displayName}>
                            {creatorObj.displayName}
                        </span>
                        :
                        (<span className="naStyle">N/A</span>)
                    }
                </div>
                {/* {(isSa || clientGroupLeader.some(element=>element.UserID === user.uid)) && */}
                <div
                    className="ReactVirtualized__Table__rowColumn handlerListing"
                    role="gridcell"
                    key={"handler"}
                    style={{ overflow: "hidden", width: tableState && tableState.width["handler"] * TABLE_TOTAL_WIDTH }}
                    onClick={() => { 
                        if(rawHandlerID && availableMemberList && rawHandlerID?.length > 0){
                            // showHandlerListModalFunc(rawCurrClientData[index]?.ClientID);
                        }
                    }}
                >
                    {(rawHandlerID && availableMemberList && rawHandlerID?.length > 0) ?
                        rawHandlerID?.slice(0, 2)?.map((handler, index) => {
                            const UserName = rawHandlerName[index] ?? "N/A";
                            const remainingHandler = rawHandlerID?.length - 2;
                            const isLastElement = index === 1 && remainingHandler === 0 || rawHandlerID?.length === 1;
                            return (
                                    <span key={index} title={UserName}>
                                        {UserName}
                                        {!isLastElement && ","}
                                    </span>
                            );
                        })
                        :
                        <span className="naStyle">N/A</span>
                    }
                    {(rawHandlerID && availableMemberList && rawHandlerID?.length > 0 && (rawHandlerID?.length - 2 > 0)) &&
                        <span className="remainingCount">+{rawHandlerID?.length - 2}</span>
                    }
                </div>
                {/* } */}
                <div
                    className="ReactVirtualized__Table__rowColumn"
                    role="gridcell"
                    key={"createdAt"}
                    style={{ overflow: "hidden", width: tableState && tableState.width["createdAt"] * TABLE_TOTAL_WIDTH }}
                >
                    <span>{tempClientMergedData[index]["CreatedAt"]}</span>
                </div>
                {clientColArray.map((item, clientColIndex) => {
                     const viewableColumnPermission = (hasClientNotCreatedByCurrUser && (ViewableColumn?.length > 0 && !ViewableColumn?.includes(item.columnID)));
                    //  console.log("not created by me : ", hasClientNotCreatedByCurrUser)
                    if(item.columnName !== undefined)
                    {
                    if (item.columnName !== "ClientID" && item.columnName !== "DepartmentID" && item.columnName !== "PosID" && item.columnName !== "UserPassword" && item.privilege !== "Hidden") {
                        if (item.columnType === "Short Text") {
                            return (
                                <div
                                    className="ReactVirtualized__Table__rowColumn"
                                    role="gridcell"
                                    key={clientColIndex}
                                    title={viewableColumnPermission ? "N/A (Restricted)" : tempClientMergedData[index][item.columnName]}
                                    style={{ overflow: "hidden", width: tableState && tableState.width[item.columnName] * TABLE_TOTAL_WIDTH }}
                                >
                                    {viewableColumnPermission ? 
                                    <span className="naStyle">N/A (Restricted)</span>
                                    :
                                    <span>{tempClientMergedData[index][item.columnName] === "" ? "-" :tempClientMergedData[index][item.columnName]}</span>
                                    }
                                </div>
                            )
                        }
                        else if (item.columnType === "Alert"){
                            // console.log("Renderer:", tempClientMergedData[index][item.columnName]);
                            const alertData = tempClientMergedData[index][item.columnName]; // Directly assign the object
                            if (alertData !== undefined && alertData.expiry) {
                                const dueDate = moment(alertData.expiry);
                                const dateTimeNow = moment(new Date()).format("YYYY-MM-DD");
                                const differenceInDays = dueDate.diff(dateTimeNow, "days") + 1;
                                const isCompleted = alertData.status;
                                return (
                                    <div
                                        className="ReactVirtualized__Table__rowColumn"
                                        role="gridcell"
                                        key={clientColIndex}
                                        style={{ overflow: "hidden", width: tableState && tableState.width[item.columnName] * TABLE_TOTAL_WIDTH }}
                                    >
                                        <span style={{
                                            fontWeight: differenceInDays <= 0 && !isCompleted ? "bold" : "normal",
                                            ...differenceInDays <= 0 && !isCompleted ? { color: "#ca4254" } : {}
                                        }}>
                                            {isCompleted ? 
                                                "Completed" :
                                                differenceInDays <= 0 ? 
                                                "Expired" : 
                                                `Due after: ${differenceInDays} ${differenceInDays > 1 ? "Days" : "Day"}`
                                            }
                                        </span>
                                    </div>
                                );
                            } else {
                                return (
                                    <div
                                        className="ReactVirtualized__Table__rowColumn"
                                        role="gridcell"
                                        key={clientColIndex}
                                        style={{ overflow: "hidden", width: tableState && tableState.width[item.columnName] * TABLE_TOTAL_WIDTH }}
                                    >
                                        <span style={{ fontWeight: "normal" }}>No expiry</span>
                                    </div>
                                );
                            }
                        }
                        else if (item.columnType === "Multiline") {
                            return (
                                <div
                                    className="ReactVirtualized__Table__rowColumn"
                                    role="gridcell"
                                    key={clientColIndex}
                                    title={viewableColumnPermission ? "N/A (Restricted)" : tempClientMergedData[index][item.columnName]}
                                    style={{ overflow: "hidden", width: tableState && tableState.width[item.columnName] * TABLE_TOTAL_WIDTH }}
                                >
                                    {viewableColumnPermission ? 
                                    <span className="naStyle">N/A (Restricted)</span>
                                    :
                                    <span>{tempClientMergedData[index][item.columnName] === "" ? "-" :tempClientMergedData[index][item.columnName]}</span>
                                    }
                                </div>
                            )
                        }
                        else if (item.columnType === "Email") {
                            return (
                                <div
                                    className="ReactVirtualized__Table__rowColumn d-flex"
                                    role="gridcell"
                                    key={clientColIndex}
                                    title={viewableColumnPermission ? "N/A (Restricted)" : tempClientMergedData[index][item.columnName]}
                                    style={{ overflow: "hidden", width: tableState && tableState.width[item.columnName] * TABLE_TOTAL_WIDTH}}
                                >
                                    <div>
                                        <HiOutlineEnvelope size={17} color={""} style={{ marginRight: "10px" }} />
                                    </div>
                                    {viewableColumnPermission ? 
                                    <span className="naStyle">N/A (Restricted)</span>
                                    :
                                    <span style={{display:'flex',alignItems:'center'}}>{tempClientMergedData[index][item.columnName] === "" ? "-" :tempClientMergedData[index][item.columnName]}</span>
                                    }
                                  </div>
                            )
                        }
                        else if (item.columnType === "Phone") {
                            return (
                                <div
                                    className="ReactVirtualized__Table__rowColumn d-flex"
                                    role="gridcell"
                                    title={viewableColumnPermission ? "N/A (Restricted)" : tempClientMergedData[index][item.columnName]}
                                    style={{ overflow: "hidden", width: tableState && tableState.width[item.columnName] * TABLE_TOTAL_WIDTH }}
                                >
                                    <div>
                                        <HiOutlinePhone size={16} color={""} style={{ marginRight: "10px" }} />
                                    </div>
                                    {viewableColumnPermission ? 
                                    <span className="naStyle">N/A (Restricted)</span>
                                    :
                                    <span style={{display:'flex',alignItems:'center'}}>{tempClientMergedData[index][item.columnName] === "" ? "-" :tempClientMergedData[index][item.columnName]}</span>
                                    }
                                </div>
                            )
                        }
                        else if (item?.columnType === "Form") {
                            
                            return (
                                <div
                                    className="ReactVirtualized__Table__rowColumn d-flex px-1 py-1"
                                    role="gridcell"
                                    key={clientColIndex}
                                    style={{ overflow: "hidden", width: tableState && tableState.width[item.columnName] * TABLE_TOTAL_WIDTH, }}
                                >
                                    {viewableColumnPermission ? <span className="naStyle">N/A (Restricted)</span> : 
                                    <C_Button
                                        background={"rgb(95, 94, 175)"}
                                        paddingLeft={"5px"}
                                        paddingRight={"5px"}
                                        justify={"space-around"}
                                        onClick={() => {
                                            setFormClientID(tempClientMergedData[index].ClientID);
                                            setIsPreview(false);
                                            setCurrFormPreviewID("");
                                            setFilterBy("");
                                            showModalFunc();
                                        }}
                                        width={window.innerWidth > 1500 ? "130px" : "110px"}
                                        buttonText={"Submission"}
                                        icon={<RiFileList3Line size={15} />}
                                    />
                                    }
                                </div>
                            )
                        }
                        else if (item.columnType === "Link") {
                            return (
                                <div
                                    className="ReactVirtualized__Table__rowColumn d-flex"
                                    role="gridcell"
                                    key={clientColIndex}
                                    title={viewableColumnPermission ? "N/A (Restricted)" : tempClientMergedData[index][item.columnName]}
                                    style={{ overflow: "hidden", width: tableState && tableState.width[item.columnName] * TABLE_TOTAL_WIDTH }}
                                >
                                    <div>
                                        <HiOutlineLink size={16} color={""} style={{ marginRight: "10px" }} />
                                    </div>
                                    <span style={{display:'flex',alignItems:'center'}}>
                                    {viewableColumnPermission ? <span className="naStyle">N/A (Restricted)</span> : tempClientMergedData[index][item.columnName] === "" ? "-" :
                                    <a href={`//${tempClientMergedData[index][item.columnName]}`} target="_blank" rel="noopener noreferrer" >{tempClientMergedData[index][item.columnName]}</a>
                                    }
                                    </span>

                                    {/* {tempClientMergedData[index][item.columnName] === "-" ? <>{tempClientMergedData[index][item.columnName]}</> : <a href={`//${tempClientMergedData[index][item.columnName]}`} target="_blank" rel="noopener noreferrer" >{tempClientMergedData[index][item.columnName]}</a>} */}

                                </div>
                            )
                        }
                        else if (item.columnType === "Google Map") {
                            //REGEX SUBSTRING

                            if (tempClientMergedData[index][item.columnName] !== undefined) {
                                var regexResult = /\bplace([^@]*)/.exec(tempClientMergedData[index][item.columnName]);

                                if (regexResult !== undefined && regexResult !== null && regexResult.length !== 0) {
                                    var extraction = regexResult[1].toString().replace(/\+/g, " ")
                                    var result = extraction.replace(/\//g, "")

                                    return (
                                        <div
                                            className="ReactVirtualized__Table__rowColumn d-flex"
                                            role="gridcell"
                                            key={clientColIndex}
                                            title={viewableColumnPermission ? "N/A (Restricted)" : tempClientMergedData[index][item.columnName]}
                                            style={{ overflow: "hidden", width: tableState && tableState.width[item.columnName] * TABLE_TOTAL_WIDTH }}
                                        >
                                            
                                            <div>
                                            <HiOutlineMapPin size={16} color={""} style={{ marginRight: "10px" }} />
                                            </div>
                                            <span style={{display:'flex',alignItems:'center'}}>
                                            {viewableColumnPermission ? <span className="naStyle">N/A (Restricted)</span> : tempClientMergedData[index][item.columnName] === "" ? "-" :
                                                <a href={tempClientMergedData[index][item.columnName]} target="_blank" rel="noopener noreferrer">{decodeURIComponent(result)}</a>
                                            }
                                            </span>
                                        </div>
                                    )

                                }
                                else
                                {
                                        return (
                                        <div
                                            className="ReactVirtualized__Table__rowColumn d-flex"
                                            role="gridcell"
                                            key={clientColIndex}
                                            title={viewableColumnPermission ? "N/A " : tempClientMergedData[index][item.columnName]}
                                            style={{ overflow: "hidden", width: tableState && tableState.width[item.columnName] * TABLE_TOTAL_WIDTH }}
                                        >
                                            <div>
                                                <HiOutlineMapPin size={16} color={""} style={{ marginRight: "10px" }} />
                                            </div>
                                            <span style={{display:'flex',alignItems:'center'}}>
                                            {viewableColumnPermission ? <span className="naStyle">N/A (Restricted)</span> : tempClientMergedData[index][item.columnName] === "" ? "-" :
                                                tempClientMergedData[index][item.columnName]
                                            }
                                            </span>
                                            
                                        </div>
                                    )
                                }

                            }
                        }
                        else if (item.columnType === "Rich Text Editor") {
                            return (
                                <div
                                    className="ReactVirtualized__Table__rowColumn px-1 py-1"
                                    role="gridcell"
                                    key={clientColIndex}
                                    style={{ overflow: "hidden", width: tableState && tableState.width[item.columnName] * TABLE_TOTAL_WIDTH }}
                                >
                                    {viewableColumnPermission ? <span className="naStyle">N/A (Restricted)</span> : 
                                    <C_Button
                                        targetID={"rte-modal"}
                                        toggleAction={"modal"}
                                        background={"rgb(95, 94, 175)"}
                                        paddingLeft={"5px"}
                                        paddingRight={"5px"}
                                        justify={"space-around"}
                                        onClick={() => {
                                            dispatch(setClientContext({
                                                clientRteIndex : index,
                                            }));
                                            // setClientRteIndex({type:"RTE_INDEX",payload:index})
                                            //setTableRowIndex(index);
                                        }}
                                        width={"100px"}
                                        buttonText={"Preview"}
                                        icon={<BsFonts size={15} />}
                                    />
                                }
                                </div>
                            )
                        }
                        else if (item.columnType === "Switch") {
                            if(switchStatus[index] !== undefined)
                            {
                                return (
                                    <div
                                        className="ReactVirtualized__Table__rowColumn"
                                        role="gridcell"
                                        key={clientColIndex}
                                        style={{ overflow: "hidden", width: tableState && tableState.width[item.columnName] * TABLE_TOTAL_WIDTH }}
                                    >
                                        <Switch
                                            checked={switchStatus[index]}
                                            onChange={(e) => {updateSwitchStatus(index, clientColIndex);}}
                                            onColor="#4BAB00"
                                            onHandleColor="#FFFFFF"
                                            handleDiameter={16}
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            boxShadow="0px 1px 3px rgba(0, 0, 0, 0.6)"
                                            activeBoxShadow="0px 0px 1px 7px rgba(0, 0, 0, 0.2)"
                                            height={18}
                                            width={37}
                                        />
                                    </div>
                                )
                            }
                        }
                        else if (item.columnType === "Numbers") {
                            return (
                                <>

                                    <div
                                        className="ReactVirtualized__Table__rowColumn"
                                        role="gridcell"
                                        key={clientColIndex}
                                        title={viewableColumnPermission ? "N/A (Restricted)" : tempClientMergedData[index][item.columnName]}
                                        style={{ overflow: "hidden", width: tableState && tableState.width[item.columnName] * TABLE_TOTAL_WIDTH }}
                                    >
                                        {viewableColumnPermission ? 
                                        <span className="naStyle">N/A (Restricted)</span>
                                        :
                                        <span className="client-decimalNumber">{tempClientMergedData[index][item.columnName] === "" ? "-" :tempClientMergedData[index][item.columnName]}</span>
                                        }
                                    </div>

                                </>
                            )
                        }
                        else if (item.columnType === "Date") {
                            return (
                                <>
                                    <div
                                        className="ReactVirtualized__Table__rowColumn d-flex"
                                        role="gridcell"
                                        key={clientColIndex}
                                        title={viewableColumnPermission ? "N/A (Restricted)" : tempClientMergedData[index][item.columnName]}
                                        style={{ overflow: "hidden", width: tableState && tableState.width[item.columnName] * TABLE_TOTAL_WIDTH }}
                                    >
                                        <div>
                                            <HiOutlineCalendar size={17} style={{marginRight:'10px'}}/>
                                        </div>
                                        {viewableColumnPermission ? 
                                        <span className="naStyle">N/A (Restricted)</span>
                                        :
                                        <span style={{display:'flex',alignItems:'center'}}>{tempClientMergedData[index][item.columnName] === "" ? "-" :tempClientMergedData[index][item.columnName]}</span>
                                        }
                                    </div>
                                </>
                            )

                        }
                        else if (item.columnType === "Multiple Choices") {
                            return (
                                <div
                                    className="ReactVirtualized__Table__rowColumn d-flex"
                                    role="gridcell"
                                    key={clientColIndex}
                                    title={viewableColumnPermission ? "N/A (Restricted)" : tempClientMergedData[index][item.columnName]}
                                    style={{ overflow: "hidden", width: tableState && tableState.width[item.columnName] * TABLE_TOTAL_WIDTH}}
                                >
                                    
                                    <div>
                                        <BiRadioCircleMarked size={18} color={""} style={{ marginRight: "10px" }} />
                                    </div>
                                    {viewableColumnPermission ? 
                                    <span className="naStyle">N/A (Restricted)</span>
                                    :
                                    <span style={{display:'flex',alignItems:'center'}}>{tempClientMergedData[index][item.columnName] === "" ? "-" :tempClientMergedData[index][item.columnName]}</span>
                                    }
                                </div>
                            )
                        }
                        else if (item.columnType === "Dropdown") {
                            return (
                                <div
                                    className="ReactVirtualized__Table__rowColumn d-flex"
                                    role="gridcell"
                                    key={clientColIndex}
                                    title={viewableColumnPermission ? "N/A (Restricted)" : tempClientMergedData[index][item.columnName]}
                                    style={{ overflow: "hidden", width: tableState && tableState.width[item.columnName] * TABLE_TOTAL_WIDTH}}
                                >
                                    
                                    <div>
                                        <BiListMinus size={18} color={""} style={{ marginRight: "10px" }} />
                                    </div>
                                    {viewableColumnPermission ? 
                                    <span className="naStyle">N/A (Restricted)</span>
                                    :
                                    <span style={{display:'flex',alignItems:'center'}}>{tempClientMergedData[index][item.columnName] === "" ? "-" :tempClientMergedData[index][item.columnName]}</span>
                                    }
                                </div>
                            )
                        }
                        else if (item.columnType === "Checkboxes") {
                            return (
                                <div    
                                    className="ReactVirtualized__Table__rowColumn d-flex"
                                    role="gridcell"
                                    key={clientColIndex}
                                    title={viewableColumnPermission ? "N/A (Restricted) " : tempClientMergedData[index][item.columnName]}
                                    style={{ overflow: "hidden", width: tableState && tableState.width[item.columnName] * TABLE_TOTAL_WIDTH}}
                                >
                                    
                                    <div>
                                        <BiCheckboxChecked size={18} color={""} style={{ marginRight: "10px" }} />
                                    </div>
                                    <div className="row-checkbox-container">
                                    {tempClientMergedData[index][item.columnName]?.map((item,idx)=>{
                                        return(
                                            <>
                                                {idx === 0 ? <span style={{display:'flex',alignItems:'center'}}>{viewableColumnPermission ?  <span className="naStyle">N/A</span> : item === "" ? "-" :item}</span>
                                                :
                                               
                                                <span style={{display:'flex',alignItems:'center'}}>,{viewableColumnPermission ?  <span className="naStyle">N/A</span>: item === "" ? "-" :item}</span>
                                                }
                                            </>
                                        );
                                    })}
                                    </div>
                                </div>
                            )
                        }
                    }
                }})
                }
            </div>
        );
    };

    async function handleReloadFlowChart(){
        try{
            retrieveHandlers([openHandlerList])
            dispatch(retrieveAllClientsCount(retrievalDataCount));
            dispatch(retrieveClient(retrievalData));
            setTimeout(() => {
                setLoadFlowChart(false);
            },2000);
         
        }catch (err){
          console.log(err)
        }
      }
    
    async function handleAssignHandler(item){
        const { UserID, ClientID } = item;
        const body = {
          ClientAgentID: v4(),
          GroupID: selectedGroup,
          UserID: UserID,
          ClientID: ClientID
        }
        setLoadFlowChart(true);
        dispatch(createClientAgent({TenantID:tenant, data: body, ClientID: [ClientID]}))
        handleReloadFlowChart();
   
    }
    
    function handleRemoveHandler(handlerID, clientID){
        setLoadFlowChart(true);
        dispatch(deleteClientAgent({ TenantID: tenant, ClientAgentID: handlerID, ClientID: [clientID] }));
        handleReloadFlowChart();
        
    }
    
    // function handleBulkCreateClientAgent(UserID){
    //     setLoadFlowChart(true);
    //     dispatch(bulkCreateClientAgent({ TenantID: tenant, UserID: UserID, GroupID: selectedGroup, ClientID: openHandlerList }))
    //     handleReloadFlowChart();
       
    // }
    
    // function handleBulkRemoveClientAgent(UserID){
    //     setLoadFlowChart(true);
    //     dispatch(bulkRemoveClientAgent({ TenantID: tenant, UserID: UserID, GroupID: selectedGroup, ClientID: openHandlerList }))
    //     handleReloadFlowChart();
        
    // }
    
    const onLoadFlowChart = (reactFlowInstance) => {
        reactFlowInstance.getElements().forEach((element) => {
          if (!isEdge(element)) {
            reactFlowInstance.setElementEditable(element.id, false);
          }
        });
    };

    const proOptions = { hideAttribution: true };

    return (
        <>
        
        <div className={`loadingTable ${tableLoader && 'showLoadingTable'}`} style={{height: TABLE_TOTAL_HEIGHT}}>
            <img src={require('../../assets/retrieveDataGif.gif')} alt=''/>
            <span>Retrieving data...</span>
        </div>
        <div className={`blankData ${(!showTable && !tableLoader) && 'showBlankData'}`} style={{height: TABLE_TOTAL_HEIGHT}}>
            <img src={require('../../assets/img/no_data.png')} alt=''/>
            <span>No Group & Clients data available.</span>
            {isSa ? <span>Manage your group <span style={{color:"blue", textDecoration:'underline', cursor:'pointer'}} onClick={props.handleManageClient}>here</span>.</span> : <></>}
        </div>
        <div className={`table-container-style ${(tableLoader || !showTable) && 'hideLoadingTable'}`}>
            <Table
                width={TABLE_TOTAL_WIDTH}
                height={TABLE_TOTAL_HEIGHT}
                headerHeight={50}
                rowHeight={50}
                //onRowClick={row => { console.log(row.clientRowData) }}
                rowCount={tempClientMergedData.length}
                rowGetter={({ index }) => tempClientMergedData[index] !== undefined && tempClientMergedData[index]}
                rowRenderer={({ key, index, style }) => _rowRenderer({ key, index, style })}>
                
        
                <Column
                    dataKey="checkbox"
                    headerRenderer={_headerRenderer}
                    maxWidth={100}
                    minWidth={100}
                />


                <Column
                    dataKey="createdBy"
                    headerRenderer={_headerRendererCreatedBy}
                    width = {tableState && tableState.width["createdBy"] * TABLE_TOTAL_WIDTH}
                />
                <Column
                    dataKey="handler"
                    headerRenderer={_headerRendererHandler}
                    width={tableState && tableState.width["handler"] * TABLE_TOTAL_WIDTH}
                />
                <Column
                    dataKey="createdAt"
                    headerRenderer={_headerRendererCreatedAt}
                    width = {tableState && tableState.width["createdAt"] * TABLE_TOTAL_WIDTH}
                />
                {clientColArray.map((item, index) => {
                    if (item.columnName !== "DepartmentID" && item.columnName !== "PosID" && item.columnName !== "ClientID" && item.columnName !== "UserPassword" && item.privilege !== "Hidden") {
                        return (
                            <Column className="client-table-header" key={index} headerRenderer={headerRenderer} label={item.columnName} dataKey={item.columnName} width={tableState && tableState.width[item.columnName] * TABLE_TOTAL_WIDTH} />
                        ) 
                    }
                })}
            </Table>

            <div className="modal fade modal-xl" ref={openFormRef} id="client-form" tabIndex="-1" style={{}}>
                <div className="modal-dialog modal-dialog-centered" style={{}}>
                    <div className="modal-content" style={{}}>
                        <div className="modal-body mx-3 my-3 d-flex flex-column" style={{}}>

                            <div className="d-flex" style={{ alignItems: 'center',justifyContent:'space-between' }}>
                                <div className="d-flex" style={{ alignItems: 'center' }}>
                                        {isPreview ? <><BiArrowBack className="mt-1" style={{ marginBottom: '10px', cursor: 'pointer' }} 
                                    size={20} onClick={() => { setCurrFormPreviewID(""); setIsPreview(false); }} /></> 
                                    : <><BiArrowBack className="mt-1" style={{ marginBottom: '10px', cursor: 'pointer' }} size={20} data-bs-dismiss="modal" 
                                    onClick={() => {}} /></>}

                                    {isPreview ? <><h6 className="mt-2 mx-2">Preview</h6></> : <><h6 className="mt-2 mx-2">Approved Form{currClientForm.length > 0 && ` (${currClientForm.length})`}</h6></>}
                                </div>
                                {currClientForm.length > 0 && 
                                <div>
                                    <select value={filterBy} className="form-select filter-card-input font-xxs" onChange={(e) => {
                                        setFilterBy(e.target.value)
                                    }}
                                    style={{ height:"35px" }}>
                                    <option selected disabled value="">Filter By</option>
                                    {clientFormTemplate.map((form, index) => {
                                        return (
                                            <option value={form.FormID}>{form.FormName}</option>
                                        );
                                    })}
                                    </select>
                                </div>
                                }
                                
                            </div>
                            {isPreview ?
                          <>
                          <div className="" style={{}}>
                            {currClientForm && currClientForm.map((form,formIndex) => {
                              if (currFormPreviewID === form.SubmissionID) {
                                return (
                                  <>
                                    {clientFormTemplate && clientFormTemplate.map((item, index) => {

                                      if (item.FormID === form.FormID) {
                                        return (
                                          <>
                                            <div className="card"  style={{ marginTop:'10px', border: 'none', boxShadow: '5px 5px 5px -2px rgba(0,0,0,0.1)', background: 'rgb(95, 94, 175,0.1)' }}>
                                              <div className="card-body d-flex flex-column" >
                                                <span className="font-m">{item.FormName}</span>
                                                <span className="font-xs">{item.FormDescription}</span>
                                               
                                              </div>
                                            </div>
                                            <div className="px-2 py-2" style={{}}>
                                              <div className="px-1 py-1" style={{  height: inner_height, overflow: 'scroll'}}>
                                              {item.FormData.map((data, questionIndex) => {
                                                return (
                                                  <>
                                                    {JSON.parse(form.SubmissionData).map((answer, answerIndex) => {
                                                      if (data.questionID === answer.questionID) {
                                                        return (
                                                          <div className="card mt-3" style={{ border: 'none', boxShadow: '2px 2px 4px 1px rgba(0,0,0,0.1)' }}>
                                                            <div className="card-body d-flex flex-column">
                                                              <span className="font-s">{data.questionName}</span>
                                                              {data.option === undefined
                                                                ?
                                                                <>
                                                                  {data.questionType === "Multiline" ?
                                                                    <>
                                                                      <textarea style={{ border: 'none', borderBottom: '1px solid' }} value={answer.answer} disabled></textarea>
                                                                    </>
                                                                    :
                                                                    data.questionType === "Date Range" ? 
                                                                    <>
                                                                   <div className="mt-2 " style={{width:'100%'}}>
                                                                      <span className="mx-1 font-xxs" style={{opacity:'0.8'}}>Start Date :</span>
                                                                      <input type="date" value={answer.answer.start} name={data.questionType} placeholder="DateRange1" className="mx-1 font-xs" style={{marginBottom:'5px',border: 'none', width: '100%', background: 'none',borderBottom: '1px solid',marginBottom:'10px'}} disabled />
                                                                      
                                                                      <span className="mx-1 font-xxs" style={{opacity:'0.8'}}>End Date :</span>
                                                                      <input type="date" value={answer.answer.end} name={data.questionType} placeholder="DateRange2" className="mx-1 font-xs" style={{marginBottom:'5px',border: 'none', width: '100%', background: 'none',borderBottom: '1px solid'}} disabled />
                                                                    </div>
                                                                    </>
                                                                    :
                                                                    <>
                                                                      <div className="mt-2" style={{ borderBottom: '1px solid' }}>
                                                                          <input className="py-1" type={data.questionType === "ShortText" ? "text" : data.questionType === "Date" ? "date" : data.questionType === "Time" ? "time" : data.questionType === "Phone" ? "phone" : data.questionType === "Email" ? "email" : data.questionType === "Decimal" ? "number" :data.questionType === "Number" ? "number" : "text"} value={answer.answer} style={{ border: 'none', width: '100%', background: 'none' }} disabled />
                                                                        </div>
                                                                    </>
                                                                  }
                                                                </>
                                                                :
                                                                <>
                                                                  {data.questionType === "Multiple Choice" ?
                                                                    <>
                                                                      <label className="mt-2" >
                                                                        {data.option.map((options, optionIndex) => {
                                                                          return (
                                                                            <>
                                                                              <div className="d-flex" style={{ alignItems: 'center', textAlign: 'center' }}>
                                                                                <input className="py-1" type="radio" value={options} checked={radioArray.length !== 0 ? radioArray[questionIndex][optionIndex] : ""} style={{ width: '13px', height: '13px' }} disabled />
                                                                                <span className="mx-1 font-xxs" >{options}</span>
                                                                              </div>
                                                                            </>
                                                                          );

                                                                        })}
                                                                        
                                                                      </label>
                                                                    </>
                                                                    :
                                                                    data.questionType === "Checkboxes" ?
                                                                      <>
                                                                        <div className="mt-2">
                                                                          {data?.option.map((options, optionIndex) => {
                                                                            return (
                                                                              <label style={{ display: 'flex', alignItems: 'center' }}>
                                                                                <input type='checkbox' style={{ width: '15px', height: '15px' }} checked={checkboxArray.length !== 0 ? checkboxArray[questionIndex][optionIndex] : ""} value={options} className='col-md-3' disabled />
                                                                                <span className="checkmark" style={{ marginRight: '10px' }}></span>
                                                                                <span className="font-xs" style={{ marginRight: '10px' }}>{options}</span>
                                                                              </label>
                                                                            );
                                                                          })}
                                                                        </div>
                                                                        
                                                                      </>
                                                                      :
                                                                      data.questionType === "Client" ?
                                                                      <>
                                                                        <div className="mt-2" >
                                                                            <select className="form-select font-xs" style={{ minWidth: '130px', width: '20%' }} disabled>
                                                                                    
                                                                                <option className="" >{clientSelectedList?.find(client => client?.ClientID === answer.answer) !== undefined ? 
                                                                                clientSelectedList?.find(client => client?.ClientID === answer.answer).RowValue : 'N/A'}</option>
                                                                                        
                                                                            </select>

                                                                        </div>
                                                                        
                                                                      </>
                                                                      :
                                                                      data.questionType === "Dropdown" ?
                                                                        <>
                                                                          <div className="mt-2" >
                                                                            <select className="form-select font-xs" style={{ minWidth: '130px', width: '20%' }} disabled>

                                                                              <option className="" selected value={answer.answer}>{answer.answer}</option>
                                                                            </select>

                                                                          </div>
                                                                         
                                                                        </>
                                                                        :
                                                                        <>
                                                                        </>
                                                                  }
                                                                </>
                                                              }
                                                            </div>
                                                          </div>
                                                        );
                                                      }
                                                    })}
                                                  </>
                                                )
                                              })}
                                                
                                              </div>
                                            </div>
                                          </>
                                        )
                                      }
                                    })}
                                  </>
                                )
                              }
                            }//end if
                            )}
                          </div>
                          </>
                          :
                          <>
                            {currClientForm.length === 0 ? 
                              <>
                                <div className="px-3 mt-1 d-flex" style={{ height: inner_height,alignItems:'center',textAlign:'center',overflow:'hidden',justifyContent:'center'}}>
                                    <span>THERE IS NO FORM SUBMISSION THAT IS ASSOCIATED WITH THIS CLIENT</span>
                                </div>
                              </> 
                              :
                              <>
                                <div className="" style={{height: template_card_height,}}>
                                    <div className="px-3 mt-1 d-flex py-2 row" style={{ display:'flex',overflow:'scroll',marginLeft:'15px', gap:'20px'}}>
                                    {currClientForm.length > 0 && currClientForm.map((form, index) => {
                                            return(
                                                <>
                                                {clientFormTemplate.length > 0 && clientFormTemplate.map((item) => {
                                                    if(item.FormID === form.FormID)
                                                    {
                                                        return(
                                                            <div className="py-3" style={{ border: 'none', background: '#fcffff', 
                                                            boxShadow: '2px 2px 4px 1px rgba(0,0,0,0.1)', borderRadius: '10px',position:'relative',
                                                            paddingRight:'30px',paddingLeft:'30px',width:'450px'}}>
                                                                <div className="d-flex flex-column py-3 px-3" style={{ background: 'rgb(95, 94, 175,0.1)', borderRadius: '10px' }}>
                                                                    
                                                                    <div className="d-flex">
                                                                        <div className="col d-flex flex-column">
                                                                            <span className="font-xs" style={{fontWeight:'bold'}}>{item.FormName}</span>
                                                                            <span className="font-xxs">Submitted On: {moment(form.CreatedAt).format("YYYY-MM-DD | HH:mm | dddd")}</span>
                                                                        </div>
                                                                    </div>
                            
                                                                </div>
                                                                <hr className="" style={{ margin: "10px" }} />
                                                                <div className="d-flex mt-1" style={{ alignItems: 'center' }}>
                                                                    <div className="col-md-7" style={{ justifyContent: 'start' }}>
                                                                    <span className="remark-text" style={{ whiteSpace: 'nowrap' }}>
                                                                        {currUsers.map((user) => {
                                                                        if (user.uid === form.ApprovalUserID) {
                                                                            return (
                                                                            <>
                                                                                Approved by : {user.displayName}
                                                                            </>
                                                                            )
                                                                        }
                                                                        })}</span>
                                                                        <span className="clamp-text-formlist remark-text" style={{ opacity:'0.8' }}>
                                                                        Approved On: {moment(form.ApprovalDate).format("YYYY-MM-DD | HH:mm | dddd")}
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-md-5 d-flex" style={{ justifyContent: 'end' }}> 
                                                                    <C_Button
                                                                        background={'rgb(95, 94, 175,1)'}
                                                                        width={"110px"}
                                                                        height={"30px"}
                                                                        buttonText={"Preview"}
                                                                        justify={"space-between"}
                                                                        onClick={() => { setCurrFormPreviewID(form.SubmissionID); setIsPreview(true);}}
                                                                        paddingLeft={"10px"}
                                                                        paddingRight={"10px"}
                                                                        icon={<FaRegEye size={15} />}
                                                                        />
                                                                    
                                                                    </div>
                                                                    
                                                                </div>
                                                                <div className="col-md-6 d-flex" style={{ justifyContent: 'end' }}>

                                                                    <div className={`px-1 py-2 mx-1`}> 
                                                                        <div className={
                                                                        
                                                                        form.SubmissionStatus === "0" ? `px-1 py-2 my-1 form-status-pending`: 
                                                                        
                                                                        form.SubmissionStatus === "1" ? "px-1 py-2 my-1 form-status-approved" : 
                                                                        
                                                                        form.SubmissionStatus === "3" ? "px-1 py-2 my-1 form-status-reapplication" : 
                                                                        "px-1 py-2 mx-1 form-status-rejected" } >

                                                                            <div className="d-flex" style={{height:'100%',alignItems:'center'}}>
                                                                                <div className="col form-status-span">
                                                                                    <span>{form.SubmissionStatus === "0" ? <>Pending</>: form.SubmissionStatus === "1" ? <>Approved</> : form.SubmissionStatus === "3" ? <>Reapplication</> : <>Rejected</> }</span>
                                                                                </div>
                                                                                <div className="col">
                                                                                {form.SubmissionStatus === "0" ? <><RiTimer2Line size={20} /></>: form.SubmissionStatus === "1" ? <><AiOutlineCheckCircle size={20} /></> : form.SubmissionStatus === "3" ? <><FiRotateCw size={16} /></> : <><AiOutlineCloseCircle size={20} /></> }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    }
                                                })}
                                                </>
                                            );
                                    })}
                                    </div>
                                </div>
                              </>}
                          </>}
                        </div>
                    </div>
                </div>
            </div>

        </div>
        </>
                
        
    );
}

export default ClientTable;
/* eslint-enable */