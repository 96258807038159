import { createSlice, current } from "@reduxjs/toolkit"

import { useAppSelector } from "../../hooks/storeHooks";
//initial state
const initialState = {
    //loading
    createAnalyticsLoading : false,
    getAnalyticsLoading : false,
    getSingleAnalyticsLoading : false,
    getPublishAnalyticsLoading : false,
    getClientForAnalyticsLoading : false,
    updateAnalyticsLoading : false,
    deleteAnalyticsLoading : false,
    getAnalyticsDataSetLoading : false,
    getGraphDataLoading : false,
    getAvailableHandlerLoading : false,
    setAnalyticsContextLoading : false,

    //error
    createAnalyticsError : undefined,
    getAnalyticsError : undefined,
    getSingleAnalyticsError : undefined,
    getPublishAnalyticsError : undefined,
    getClientForAnalyticsError : undefined,
    updateAnalyticsError : undefined,
    deleteAnalyticsError : undefined,
    getAnalyticsDatasetError : undefined,
    getGraphDataError : undefined,
    getAvailableHandlerError : undefined,
    setAnalyticsContextError : undefined,
    getAnalyticsDataSetError : undefined,

    //data
    analyticsData : [],
    singleAnalytics : {
      AnalyticsID: "",
      CompanyID: "",
      UserID: "",
      DataID: "",
      Publish: false,
      Cover: "",
      GraphTitle: "",
      GraphType: "Line",
      yAxis: [],
      xAxis: [],
      PieProperty:  {
          innerRadius: 0,
          colorPalette: "default",
          textColor: "#FFFFFF",
          textSize: 12,
          strokeColor: "#000000",
          strokeWidth: 1,
          LabelPosition: 'inner',
          LabelKey: false,
          LabelLine: false,
          Percentage: true
      },
      Unit: "",
      Label: "",
      filterList: [],
      sortList: [],
      GraphData: [],
      CreatedAt: "",
      Dashboard: false,
      DataSource:"",
      CustomVariables: [],
      ViewableBy: [],
      dateSettings : {dateColumn: "", dateRange: ""}
    },
    analyticsDataset : [],
    publishAnalytics : [],
    clientForAnalytics : [],
    newGraphData : [],
    availableHandler : [],

    context : {
      formData : [],
      submissionData: [],
      mergedDataSet : [],
      graphData : [],
      analyticsData : [],
      analytics : null,
      formID : "",
      graphType : "Line",
      analyticsWork : null,
      yAxisTarget : null,
      xAxisTarget : null,
      graphDetail : null,
      filterList : [],
      sortList : null,
      selectedDateSettings : "",
      filterState : false,
      sortState : false,
      pieProperty : [],
      publishedGraph : [],
      publishedGraphData : [],
      triggerAction : "",
      advanceVariable : [],
      targetMember : [],
      availableHandlerIDs : [],
    },

    defaultContext : {
      formData : [],
      submissionData: [],
      mergedDataSet : [],
      graphData : [],
      analyticsData : [],
      analytics : null,
      formID : "",
      graphType : "Line",
      analyticsWork : null,
      yAxisTarget : null,
      xAxisTarget : null,
      graphDetail : null,
      filterList : [],
      sortList : null,
      selectedDateSettings : "",
      filterState : false,
      sortState : false,
      pieProperty : [],
      publishedGraph : [],
      publishedGraphData : [],
      triggerAction : "",
      advanceVariable : [],
      targetMember : [],
      availableHandlerIDs : [],
    },
}
// current(state.allAccounts)
//slices
const analyticsSlice = createSlice({
    name: "analytics",
    initialState,
    reducers : {
        createAnalytics(state){
            state.createAnalyticsLoading = true;
        },
        createAnalyticsSuccess(state, {payload}){
            const currentState = payload;
            state.analyticsData = currentState;

            state.createAnalyticsLoading = false;
        },
        createAnalyticsError(state, {payload}){
            const errorState = payload;
            state.createAnalyticsError = errorState;

            state.createAnalyticsLoading = false;
        },

        updateAnalytics(state){
            state.updateAnalyticsLoading = true;
        },
        updateAnalyticsSuccess(state, {payload}){
            const currentState = current(state.analyticsData);
            const updatedState = currentState.map((item) => {
                if (item.AnalyticsID === payload.AnalyticsID) {
                    return {
                        ...item,
                        ...payload,
                    };
                }
                return item;
            });
            state.analyticsData = updatedState;

            state.updateAnalyticsLoading = false;
        },
        updateAnalyticsError(state, {payload}){
            const errorState = payload;
            state.updateAnalyticsError = errorState;

            state.updateAnalyticsLoading = false;
        },

        deleteAnalytics(state){
            state.deleteAnalyticsLoading = true;
        },
        deleteAnalyticsSuccess(state, {payload}){
            const currentState = current(state.analyticsData);
            const updatedState = currentState.filter((item) => item.AnalyticsID !== payload.AnalyticsID);
            state.analyticsData = updatedState;

            state.deleteAnalyticsLoading = false;
        },
        deleteAnalyticsError(state, {payload}){
            const errorState = payload;
            state.deleteAnalyticsError = errorState;

            state.deleteAnalyticsLoading = false;
        },

        getAnalytics(state){
            state.getAnalyticsLoading = true;
        },
        getAnalyticsSuccess(state, {payload}){
            const currentState = payload;
            state.analyticsData = currentState;

            state.getAnalyticsLoading = false;
        },
        getAnalyticsError(state, {payload}){
            const errorState = payload;
            state.getAnalyticsError = errorState;

            state.getAnalyticsLoading = false;
        },

        getSingleAnalytics(state){
            state.getSingleAnalyticsLoading = true;
        },
        getSingleAnalyticsSuccess(state, {payload}){
            const currentState = payload;
            state.singleAnalytics = currentState;

            state.getSingleAnalyticsLoading = false;
        },
        getSingleAnalyticsError(state, {payload}){
            const errorState = payload;
            state.getSingleAnalyticsError = errorState;

            state.getSingleAnalyticsLoading = false;
        },

        getPublishAnalytics(state){
            state.getPublishAnalyticsLoading = true;
        },
        getPublishAnalyticsSuccess(state, {payload}){
            const currentState = payload;
            state.publishAnalytics = currentState;

            state.getPublishAnalyticsLoading = false;
        },
        getPublishAnalyticsError(state, {payload}){
            const errorState = payload;
            state.getPublishAnalyticsError = errorState;

            state.getPublishAnalyticsLoading = false;
        },

        getClientForAnalytics(state){
            state.getClientForAnalyticsLoading = true;
        },
        getClientForAnalyticsSuccess(state, {payload}){
            const currentState = payload;
            state.clientForAnalytics = currentState;

            state.getClientForAnalyticsLoading = false;
        },
        getClientForAnalyticsError(state, {payload}){
            const errorState = payload;
            state.getClientForAnalyticsError = errorState;

            state.getClientForAnalyticsLoading = false;
        },

        getAnalyticsDataSet(state){
            state.getAnalyticsDataSetLoading = true;
        },
        getAnalyticsDataSetSuccess(state, {payload}){
            const currentState = payload;
            state.analyticsDataset = currentState;

            state.getAnalyticsDataSetLoading = false;
        },
        getAnalyticsDataSetError(state, {payload}){
            const errorState = payload;
            state.getAnalyticsDatasetError = errorState;

            state.getAnalyticsDataSetLoading = false;
        },

        getGraphData(state){
            state.getGraphDataLoading = true;
        },
        getGraphDataSuccess(state, {payload}){
            const currentState = payload;
            state.newGraphData = currentState;

            state.getGraphDataLoading = false;
        },
        getGraphDataError(state, {payload}){
            const errorState = payload;
            state.getGraphDataError = errorState;

            state.getGraphDataLoading = false;
        },

        getAvailableHandler(state){
            state.getAvailableHandlerLoading = true;
        },
        getAvailableHandlerSuccess(state, {payload}){
            const currentState = payload;
            state.availableHandler = currentState;

            state.getAvailableHandlerLoading = false;
        },
        getAvailableHandlerError(state, {payload}){
            const errorState = payload;
            state.getAvailableHandlerError = errorState;

            state.getAvailableHandlerLoading = false;
        },
        setAnalyticsContext(state){
          state.setAnalyticsContextLoading = true;
        },
        setAnalyticsContextSuccess(state, {payload}){
        
          const currentState = current(state.context);
            if(Object.values(payload).length > 0){
              const updatedState = {
                ...currentState,
                ...payload,
              }
              state.context = updatedState;
              
            } else {
              state.context = state.defaultContext;
            }
           

          state.setAnalyticsContextLoading = false;
 
        },
        setAnalyticsContextError(state){
          state.setAnalyticsContextLoading = false;
        }
    }
})

//export function
export const {
    createAnalytics,
    createAnalyticsSuccess,
    createAnalyticsError,
    updateAnalytics,
    updateAnalyticsSuccess,
    updateAnalyticsError,
    deleteAnalytics,
    deleteAnalyticsSuccess,
    deleteAnalyticsError,
    getAnalytics,
    getAnalyticsSuccess,
    getAnalyticsError,
    getSingleAnalytics,
    getSingleAnalyticsSuccess,
    getSingleAnalyticsError,
    getPublishAnalytics,
    getPublishAnalyticsSuccess,
    getPublishAnalyticsError,
    getClientForAnalytics,
    getClientForAnalyticsSuccess,
    getClientForAnalyticsError,
    getAnalyticsDataSet,
    getAnalyticsDataSetSuccess,
    getAnalyticsDataSetError,
    getGraphData,
    getGraphDataSuccess,
    getGraphDataError,
    getAvailableHandler,
    getAvailableHandlerSuccess,
    getAvailableHandlerError,
    setAnalyticsContext,
    setAnalyticsContextSuccess,
    setAnalyticsContextError
} = analyticsSlice.actions

//export selector

//data
export const selectAnalyticsData = () =>
  useAppSelector((state) => state.analytics.analyticsData);

export const selectSingleAnalytics = () =>
  useAppSelector((state) => state.analytics.singleAnalytics);

export const selectPublishAnalytics = () =>
  useAppSelector((state) => state.analytics.publishAnalytics);

export const selectClientForAnalytics = () =>
  useAppSelector((state) => state.analytics.clientForAnalytics);

export const selectAnalyticsDataset = () =>
  useAppSelector((state) => state.analytics.analyticsDataset);

export const selectNewGraphData = () =>
  useAppSelector((state) => state.analytics.newGraphData);

export const selectAvailableHandler = () =>
  useAppSelector((state) => state.analytics.availableHandler);

export const selectAnalyticsContext = () => 
  useAppSelector((state) => state.analytics.context);

//loading
export const selectCreateAnalyticsLoading = () =>
  useAppSelector((state) => state.analytics.createAnalyticsLoading);

export const selectGetAnalyticsLoading = () =>
  useAppSelector((state) => state.analytics.getAnalyticsLoading);

export const selectGetSingleAnalyticsLoading = () =>
  useAppSelector((state) => state.analytics.getSingleAnalyticsLoading);

export const selectGetPublishAnalyticsLoading = () =>
  useAppSelector((state) => state.analytics.getPublishAnalyticsLoading);

export const selectGetClientForAnalyticsLoading = () =>
  useAppSelector((state) => state.analytics.getClientForAnalyticsLoading);

export const selectUpdateAnalyticsLoading = () =>
  useAppSelector((state) => state.analytics.updateAnalyticsLoading);

export const selectDeleteAnalyticsLoading = () =>
  useAppSelector((state) => state.analytics.deleteAnalyticsLoading);

export const selectGetAnalyticsDatasetLoading = () =>
  useAppSelector((state) => state.analytics.getAnalyticsDatasetLoading);

export const selectGetGraphDataLoading = () =>
  useAppSelector((state) => state.analytics.getGraphDataLoading);

export const selectGetAvailableHandlerLoading = () =>
  useAppSelector((state) => state.analytics.getAvailableHandlerLoading);

export const selectAnalyticsContextLoading = () =>
  useAppSelector((state) => state.analytics.setAnalyticsContextLoading);


//error
export const selectCreateAnalyticsError = () =>
  useAppSelector((state) => state.analytics.createAnalyticsError);

export const selectGetAnalyticsError = () =>
  useAppSelector((state) => state.analytics.getAnalyticsError);

export const selectGetSingleAnalyticsError = () =>
  useAppSelector((state) => state.analytics.getSingleAnalyticsError);

export const selectGetPublishAnalyticsError = () =>
  useAppSelector((state) => state.analytics.getPublishAnalyticsError);

export const selectGetClientForAnalyticsError = () =>
  useAppSelector((state) => state.analytics.getClientForAnalyticsError);

export const selectUpdateAnalyticsError = () =>
  useAppSelector((state) => state.analytics.updateAnalyticsError);

export const selectDeleteAnalyticsError = () =>
  useAppSelector((state) => state.analytics.deleteAnalyticsError);

export const selectGetAnalyticsDatasetError = () =>
  useAppSelector((state) => state.analytics.retrieveAnalyticsDatasetError);

export const selectGetGraphDataError = () =>
  useAppSelector((state) => state.analytics.getGraphDataError);

export const selectGetAvailableHandlerError = () =>
  useAppSelector((state) => state.analytics.getAvailableHandlerError);
  
export const selectSetAnalyticsContextError = () =>
  useAppSelector((state) => state.analytics.setAnalyticsContextError);


//export reducer
const analyticsReducer = analyticsSlice.reducer;

export default analyticsReducer;
