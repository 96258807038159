import React, {useEffect, useState, useRef} from 'react'
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import { TbFileExport } from 'react-icons/tb';
import { FaUserAlt } from "react-icons/fa";
import { BsClipboardData } from 'react-icons/bs';
import { AiTwotoneCalendar } from 'react-icons/ai';
import { BiUser } from 'react-icons/bi';

import { Modal } from 'bootstrap';
import jsPDF from 'jspdf';
import download from 'downloadjs'
import moment from "moment";
import * as htmlToImage from 'html-to-image'
import { AiFillCloseCircle, AiFillFilePdf, AiOutlineFileJpg, AiFillPicture } from 'react-icons/ai';
import C_Button from '../../../components/C_Button';

import C_Loading from '../../../components/C_Loading';

import { selectCurrUserContext } from '../../../stores/slices/companySlices';

function Graph_Area_LIVE(props) {


  const screenshotRef = useRef(null);
  const exportModalRef = useRef(null);

  const sidebarCollapse = props.sidebarCollapse
  const author = props.author;

  const graphData = props.GraphData;
  const analytics = props.analytics;
  const yAxis = analytics.yAxis;
  const xAxis = analytics.xAxis;
  const dataOrigin = analytics.DataSource;
  const CreatedAt = analytics.CreatedAt;
  const GraphTitle = analytics.GraphTitle
  const Unit = analytics.Unit;
  const Label = analytics.Label;

  const graphRef = useRef(null);
  const [graphWidth, setGraphWidth] = useState(0);
  const [graphHeight, setGraphHeight] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const { currUsers } = selectCurrUserContext();

  useEffect(() => {
    setGraphWidth(graphRef.current?.clientWidth * 0.9);
    setGraphHeight(graphRef.current?.clientWidth * 0.9 * 0.5);
  },[xAxis, yAxis, graphData, sidebarCollapse]);

  const renderArea = () => {

    const area = yAxis.map((value, index) => {
      if(value.key === "Handler"){
        const userObj = currUsers.find(user => user.uid === value.id);
        if(userObj){
         return <Area key={index} type={value.lineType} fillOpacity={value.areaFillOpacity} stroke={value.areaLineColor} strokeWidth={value.areaLineWidth} dataKey={userObj.displayName} fill={value.color} stackId={value.areaStack ? "stack" : null}/>
        }
      } else {
        return (
          <Area key={index} type={value.lineType} fillOpacity={value.areaFillOpacity} stroke={value.areaLineColor} strokeWidth={value.areaLineWidth} dataKey={value.key} fill={value.color} stackId={value.areaStack ? "stack" : null}/>
        );
      }
    })

    return area
  };

  const renderXAxis = () => {

    const x = xAxis.map((value, index) => (
      <XAxis key={index} 
        dataKey={value?.["key"]} 
        angle={value.angle} 
        tickSize={value.tickSize}
        minTickGap={value.minTickGap} 
        padding={{ left: value.paddingLeft, right: value.paddingRight }}
      />
    ))

    return x
  };


  const showExportModalFunc = () => {
    const modalEle = exportModalRef.current;
    const bsModal = new Modal(modalEle, {
      backdrop: "static",
      keyboard: false,
    });
    bsModal.show();
  };

  const hideExportModalFunc = () => {
    const modalEle = exportModalRef.current;
    const bsModal = Modal.getInstance(modalEle);
    bsModal.hide();
  };

  const generatePDF = async () => {
    const dataUrl = await htmlToImage.toPng(screenshotRef.current);
    var image = new Image();
    image.src = dataUrl
    var doc = new jsPDF('p', 'px', 'letter'); //orientation, unit, format
    var width = doc.internal.pageSize.getWidth();
    var height = doc.internal.pageSize.getHeight() * 0.6;

    doc.addImage(image, 'PNG', 0, 0, width, height); //base64 image, format, x-coordinate, y-coordinate, width, height

    doc.save(`${GraphTitle !== "" ? GraphTitle : moment().format("DDMMYYYYHHMMS")}.pdf`);
  }

  const generatePNG = async () => {
    await htmlToImage.toPng(screenshotRef.current, { quality: 0.95, backgroundColor: '#FFFFFF'})
      .then(function (dataUrl) {
        download(dataUrl, `${GraphTitle !== "" ? GraphTitle : moment().format("DDMMYYYYHHMMS")}.png`);
      });
  }

  const generateJPEG = async () => {
    await htmlToImage.toPng(screenshotRef.current, { quality: 0.95, backgroundColor: '#FFFFFF'})
      .then(function (dataUrl) {
        download(dataUrl, `${GraphTitle !== "" ? GraphTitle : moment().format("DDMMYYYYHHMMS")}.jpeg`);
      });
  }

  const handleOnChange = (position) => {
    const updatedCheckedState = userCheckState.map((item, index) =>
        index === position ? !item : item
    );

    setUserCheckedState(updatedCheckedState);

  };

  function dataURLtoFile(dataurl, filename) {

    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
        
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    
    return new File([u8arr], filename, {type:mime});
};

  return (
    <div className='graphContainer' ref={graphRef}>
      <C_Loading isLoading={isLoading}/>
      <div
        className="modal fade"
        tabIndex="-1"
        ref={exportModalRef}
      >
        <div className="modal-dialog modal-dialog-centered" style={{ width: "15%" }}>
          <div className="modal-content">
            <div className="modal-body d-flex flex-column" style={{  }}>
              <div className="exportHeader">
                <span>EXPORT</span>
                <AiFillCloseCircle className="close" size={16} onClick={() => { hideExportModalFunc() }}/>
              </div>
              <div className="exportOptions">
                <C_Button
                  width={"80%"}
                  buttonText={"PDF"}
                  justify={"space-evenly"}
                  onClick={() => { generatePDF() }}
                  icon={<AiFillFilePdf size={16} />}
                  textColor={"#000000"}
                  marginLeft={"0.5rem"}
                  dismissAction="modal"
                />
                <C_Button
                  width={"80%"}
                  buttonText={"PNG"}
                  justify={"space-evenly"}
                  onClick={() => { generatePNG() }}
                  icon={<AiFillPicture size={16} />}
                  textColor={"#000000"}
                  marginLeft={"0.5rem"}
                  dismissAction="modal"
                />
                <C_Button
                  width={"80%"}
                  buttonText={"JPG"}
                  justify={"space-evenly"}
                  onClick={() => { generateJPEG() }}
                  icon={<AiOutlineFileJpg size={16} />}
                  textColor={"#000000"}
                  marginLeft={"0.5rem"}
                  dismissAction="modal"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='user'>
        <div className='userDetails'>
          <FaUserAlt className='icon' size={13}/>
        </div>
        <div className='authorDetails'>
          <div className='data'>
            <BiUser size={12}/>
            <span>{author?.displayName ? author?.displayName : "N/A"}</span>
          </div>
          <div className='data'>
            <BsClipboardData size={12}/>
            <span>{dataOrigin}</span>
          </div>
          <div className='data'>
            <AiTwotoneCalendar size={12}/>
            <span>{CreatedAt}</span>
          </div>
        </div>
      </div>

      <div className='tools'>
        <div className='export'>
          <TbFileExport size={18} onClick={() => { showExportModalFunc() }}/>
        </div>
      </div>
      <div className='graph' ref={screenshotRef}>
        <div className='headerTools'>
          <div className='title'>
            <span>{GraphTitle}</span>
          </div>
        </div>
        <AreaChart
          width={graphWidth}
          height={graphHeight}
          data={graphData}
        >
            <CartesianGrid strokeDasharray="3 3" />
            <YAxis unit={Unit ? Unit : ""} label={{ value: Label ? Label : "", angle: -90, position: 'insideLeft' }}/>
            {renderXAxis()}
            <Tooltip />
            {renderArea()}
        </AreaChart>
      </div>
    </div>
  )
}

export default Graph_Area_LIVE