const component = {
  // Change to component name e.g MuiButton, MuiFilledInput. Please read MUI documentation
  MuiBreadcrumbs: {
    styleOverrides: {
      root: ({ theme }) => ({
        backgroundColor: theme.palette.background.breadcrumbBg,
        paddingBlock: "8px",
        paddingInline: "15px",
        borderRadius: "30px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }),
      separator: ({ theme }) => ({
        ".MuiSvgIcon-root": {
          color: theme.palette.text.success,
        },
        marginLeft: "3px",
        marginRight: "3px",
      }),
    },
  },
};

export default component;
