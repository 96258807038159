import React from "react";
import { v4 as uuid } from "uuid";
import { Snackbar, Box, Typography } from "@mui/material";
import {
  CheckCircle,
  CloseOutlined,
  Delete,
  Error,
  Task,
} from "@mui/icons-material";
import { selectSnacks } from "../../stores/slices/snackSlice";
import useSnack from "../../hooks/useSnack";
import { SnackType } from "../../enums/Snacks";

export const Snacks = () => {
  const snacks = selectSnacks();
  const { hideSnack } = useSnack();
  const snack = snacks[0] || null;
  const handleClose = (reason) => reason !== "clickaway" && hideSnack();

  const getIcon = () => {
    switch (snack.type) {
      case SnackType.Error:
        return <Error sx={[({ icons }) => ({ ...icons.standard })]} />;
      case SnackType.Delete:
        return <Delete sx={[({ icons }) => ({ ...icons.standard })]} />;
      case SnackType.Success:
        return <CheckCircle sx={[({ icons }) => ({ ...icons.standard })]} />;
      default:
        return <Task sx={[({ icons }) => ({ ...icons.standard })]} />;
    }
  };

  const getBgColor = (theme) => {
    switch (snack.type) {
      case SnackType.Error:
        return theme.palette.snack.error;
      case SnackType.Delete:
        return theme.palette.snack.warning;
      case SnackType.Success:
        return theme.palette.snack.success;
      default:
        return theme.palette.snack.warning;
    }
  };

  return (
    <>
      {snack && (
        <Snackbar
          open
          key={uuid()}
          ClickAwayListenerProps={{ onClickAway: () => null }}
          anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
          autoHideDuration={snack.timeout}
          onClose={handleClose}
          sx={{
            "& .MuiSvgIcon-root,& .MuiTypography-root": {
              color: (theme) => theme.palette.surface.default,
            },
          }}
        >
          <Box
            display="flex"
            sx={(theme) => ({
              px: 2,
              py: 1.5,
              alignItems: "center",
              borderRadius: theme.spacing(0.5),
              boxShadow: "0 8px 16px 2px rgba(0, 0, 0, 0.16)",
              bgcolor: getBgColor(theme),
            })}
            data-testid={`${snack.type}Snack`}
          >
            {getIcon()}
            <Typography variant="labelM" sx={{ ml: 2 }}>
              {snack.message}
            </Typography>
            <CloseOutlined
              sx={[
                ({ icons }) => ({
                  ml: 2,
                  cursor: "pointer",
                  ...icons.standard,
                }),
              ]}
              onClick={handleClose}
            />
          </Box>
        </Snackbar>
      )}
    </>
  );
};
