import React from "react";
import PropTypes from "prop-types";
import { Box, Stack, Typography } from "@mui/material";

export const NewFormLayout = ({
  children,
  title,
  is_view = false,
  substTitle = null,
}) => {
  //originally was uppercase and since typography was always unset
  const lowerCaseTitle = title.toLowerCase();
  const capitalizeTitle =
    lowerCaseTitle[0].toUpperCase() + lowerCaseTitle.slice(1);
  const newTitle = substTitle ? substTitle : is_view ? "View" : "New";
  return (
    <Stack
      component="form"
      autoComplete="off"
      width={1}
      direction="column"
      data-testid="NewFormLayoutContainer"
      sx={({ palette }) => ({
        border: `1px solid ${palette.border.default}`,
        borderRadius: "6px",
        // overflow: "scroll",
        color: palette.text.white,
        backgroundColor: palette.background.white,
      })}
    >
      <Box
        sx={({ palette }) => ({
          width: "100%",
          height: "7%",
          background: palette.background.default,
          borderRadius: "6px 6px 0px 0px",
          padding: "10px 30px",
          fontWeight: "500",
          display: "flex",
          alignItems: "center",
        })}
      >
        <Typography variant="displayH2">
          {`${newTitle} ${capitalizeTitle}`}
        </Typography>
      </Box>
      <Stack
        direction="column"
        sx={{
          height: "100%",
          padding:
            substTitle === "Batch" ? "0px 0px 20px 0px" : "0px 30px 20px 0px",
          overflow: "hidden",
        }}
      >
        {children}
      </Stack>
    </Stack>
  );
};

NewFormLayout.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
  is_view: PropTypes.any,
  substTitle: PropTypes.string,
};
