export const FormPaymentsColumn = () => [
  {
    field: "transaction_id",
    flex: 2,
    headerName: "Transaction",
    sortable: false,
    type: "string",
    minWidth: 200,
    disableColumnMenu: true,
  },

  {
    field: "description",
    flex: 2,
    headerName: "description",
    sortable: false,
    type: "string",
    minWidth: 200,
    disableColumnMenu: true,
  },

  {
    field: "date",
    flex: 2,
    headerName: "Date",
    sortable: false,
    type: "string",
    minWidth: 100,
    disableColumnMenu: true,
  },
  {
    field: "total",
    flex: 0,
    headerName: "Total",
    sortable: false,
    type: "string",
    minWidth: 100,
    disableColumnMenu: true,
  },
  {
    field: "apply_amount",
    flex: 0,
    headerName: "Apply Amount",
    sortable: false,
    type: "string",
    minWidth: 100,
    disableColumnMenu: true,
  },
  {
    field: "balance",
    flex: 0,
    headerName: "Balance",
    sortable: false,
    type: "string",
    minWidth: 100,
    disableColumnMenu: true,
  },
];
