import React from "react";
import GlobalStyles from "@mui/material/GlobalStyles";

export const globalStyles = (
  <GlobalStyles
    styles={(theme) => [
      {
        html: {
          WebkitFontSmoothing: "",
          MozOsxFontSmoothing: "grayscale",
          boxSizing: "border-box",
          WebkitTextSizeAdjust: "100%",
        },
        "*, *::before, *::after": {
          boxSizing: "inherit",
        },
        body: {
          margin: 0,
          ...theme.typography.paragraphM,
          color: theme.palette.text.default,
          backgroundColor: theme.palette.background.white,
          height: "100vh",
          width: "100vw",
          display: "initial",
        },
        root: {
          width: "100%",
        },
        "strong, b": {
          fontWeight: theme.typography.fontWeightBold,
        },
        a: {
          textDecoration: "none",
        },
        figure: {
          margin: 0,
        },
        reactResizable: {
          position: "relative",
          backgroundClip: "padding-box",
        },
        "*": {
          "::-webkit-scrollbar": {
            width: "2px",
            height: "2px",
          },
          "::-webkit-scrollbar-track": {
            background: "transparent",
          },
          "::-webkit-scrollbar-thumb": {
            background: "#5D45FE",
            borderRadius: "2px",
          },
          ".MuiBackdrop-root": {
            backgroundColor: "rgba(0, 0, 0, 0.4) !important",
          },
        },
        ".MuiDataGrid-virtualScroller::-webkit-scrollbar": {
          display: "initial",
        },
      },
    ]}
  />
);
