import { TableCell } from "../../TableCell";

import { TableFilterOperator } from "../../TableFilterOperator";

export const TransactionsColumn = {
  GENERAL_INFO: [
    {
      field: "transaction_no",
      headerName: "No.",
      type: "string",
      sortable: true,
      width: 140,
      disableColumnMenu: true,

      renderCell: (params) => {
        return TableCell.STRING(params);
      },
    },
    {
      field: "date",
      headerName: "Date",
      type: "string",
      sortable: true,
      minWidth: 70,
      disableColumnMenu: true,

      renderCell: (params) => TableCell.DATE(params),
      filterOperators: [TableFilterOperator.BETWEEN_DATE],
    },

    {
      field: "customer_id",
      headerName: "Customer",
      type: "string",
      sortable: true,
      flex: 1,
      minWidth: 150,
      disableColumnMenu: true,

      renderCell: (params) => {
        const customerName = params.row.customer_name;
        return TableCell.LINK({
          value: customerName,
        });
      },

      filterOperators: [TableFilterOperator.INCLUDE_ITEM],
    },
    {
      field: "reference_no",
      headerName: "Reference No",
      type: "string",
      sortable: true,
      minWidth: 120,
      disableColumnMenu: true,
      isVisible: true,

      renderCell: (params) => TableCell.STRING(params),
    },
  ],
  BILLING_INFO: [
    {
      field: "billing_party",
      headerName: "Billing Party",
      type: "string",
      sortable: true,
      minWidth: 150,
      disableColumnMenu: true,

      renderCell: (params) => TableCell.STRING(params),
    },
    {
      field: "shipping_info",
      headerName: "Shipping Info",
      type: "string",
      sortable: true,
      flex: 1,
      minWidth: 150,
      disableColumnMenu: true,

      renderCell: (params) => TableCell.STRING(params),
    },
    {
      field: "shipping_party",
      headerName: "Shipping Party",
      type: "string",
      sortable: true,
      flex: 1,
      minWidth: 150,
      disableColumnMenu: true,

      renderCell: (params) => TableCell.STRING(params),
    },
  ],

  BILLING_ONLY: [
    {
      field: "billing_party",
      headerName: "Billing Party",
      type: "string",
      sortable: true,
      minWidth: 150,
      disableColumnMenu: true,

      renderCell: (params) => TableCell.STRING(params),
    },
  ],

  TRANSACTION_INFO: [
    {
      field: "description",
      headerName: "Description",
      type: "string",
      sortable: true,
      flex: 1,
      minWidth: 150,
      disableColumnMenu: true,
      isVisible: true,

      renderCell: (params) => {
        const description = {
          value: params.row.descriptions ? params.row.descriptions : "-",
        };
        return TableCell.STRING(description);
      },
    },
    {
      field: "tags",
      headerName: "Tags",
      type: "string",
      sortable: true,
      flex: 1,
      minWidth: 120,
      disableColumnMenu: true,
      isVisible: true,

      renderCell: (params) => {
        return TableCell.TAGS(params);
      },
    },
  ],
  OTHER: [
    {
      field: "created_at",
      headerName: "Created At",
      type: "string",
      sortable: true,
      flex: 1,
      minWidth: 180,
      disableColumnMenu: true,

      renderCell: (params) => TableCell.DATETIME(params),
    },
    {
      field: "created_by",
      headerName: "Created By",
      type: "string",
      sortable: true,
      minWidth: 150,
      disableColumnMenu: true,

      renderCell: (params) => TableCell.STRING(params),
    },
  ],
};
