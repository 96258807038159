import { createSlice, current } from "@reduxjs/toolkit"

import { useAppSelector } from "../../hooks/storeHooks";
import { createSearchParams, useNavigate } from "react-router-dom";

//initial state
const initialState = {

    //loading
    createFirebaseUserLoading : false,
    deleteFirebaseUserLoading : false,
    updateFirebaseUserLoading : false,
    checkUserExistsLoading : false,
    loginWithEmailAndPasswordLoading : false,
    refreshUserAuthLoading : false,

    //error
    createFirebaseUserError : undefined,
    deleteFirebaseUserError : undefined,
    updateFirebaseUserError : undefined,
    checkUserExistsError : undefined,
    loginWithEmailAndPasswordError : undefined,
    refreshUserAuthError : undefined,

    //data
    firebaseAuth : [],
    firebaseUser : [],
    firebaseUserExist : "",

}

//slices
const firebaseAuthSlice = createSlice({
    name: "firebaseAuth",
    initialState,
    reducers : {
        createFirebaseUser(state){
            state.createFirebaseUserLoading = true;
        },
        createFirebaseUserSuccess(state, {payload}){
            const currentState = current(state.firebaseUser);
            const updatedState = [...currentState, payload];
            state.firebaseUser = updatedState;

            state.createFirebaseUserLoading = false;
        },
        createFirebaseUserError(state, {payload}){
            const errorState = payload;
            state.createFirebaseUserError = errorState;

            state.createFirebaseUserLoading = false;
        },


        updateFirebaseUser(state){
            state.updateFirebaseUserLoading = true;
        },
        updateFirebaseUserSuccess(state, {payload}){
            const currentState = current(state.company);
            const updatedState = currentState.map((item) => {
                if (item.UserID === payload.UserID) {
                    return {
                        ...item,
                        ...payload,
                    };
                }
                return item;
            });
            state.company = updatedState;

            state.updateFirebaseUserLoading = false;
        },
        updateFirebaseUserError(state, {payload}){
            const errorState = payload;
            state.updateFirebaseUserError = errorState;

            state.updateFirebaseUserLoading = false;
        },

        deleteFirebaseUser(state){
            state.deleteFirebaseUserLoading = true;
        },
        deleteFirebaseUserSuccess(state, {payload}){
            const currentState = current(state.firebaseUser);
            const updatedState = currentState.filter((item) => item.UserID !== payload.UserID);
            state.firebaseUser = updatedState;

            state.deleteFirebaseUserLoading = false;
        },
        deleteFirebaseUserError(state, {payload}){
            const errorState = payload;
            state.deleteFirebaseUserError = errorState;

            state.deleteFirebaseUserLoading = false;
        },

        checkUserExists(state){
            state.checkUserExistsLoading = true;
        },
        checkUserExistsSuccess(state, {payload}){
            const currentState = payload;
            state.firebaseUserExist = currentState;

            state.checkUserExistsLoading = false;
        },
        checkUserExistsError(state, {payload}){
            const errorState = payload;
            state.checkUserExistsError = errorState;

            state.checkUserExistsLoading = false;
        },

        loginWithEmailAndPassword(state){
            state.loginWithEmailAndPasswordLoading = true;
        },
        loginWithEmailAndPasswordGASuccess(state, {payload}){
            const currentState = payload;
            state.firebaseAuth = currentState;

            state.loginWithEmailAndPasswordLoading = false;
            // navigate('/GaPortal');
            window.location.replace("/#/GaPortal");
        },
        loginWithEmailAndPasswordTenantSuccess(state, {payload}){
          const currentState = payload;
          state.firebaseAuth = currentState;

          state.loginWithEmailAndPasswordLoading = false;
          // navigate('/Tenant');
          window.location.replace("/#/Tenant");
        },
        loginWithEmailAndPasswordVerifySuccess(state, {payload}){
          const currentState = payload;
          state.firebaseAuth = currentState;

          state.loginWithEmailAndPasswordLoading = false;
          const { data } = payload;
          const user = data?.user;
          const token = data?.tokens;
          const uid = user?.uid;
          const email = user?.email;
          window.location.replace(`/#/EmailVerification?uid=${uid}&email=${email}`)
          // navigate({ pathname: "/EmailVerification", search: createSearchParams({uid: uid, email: email}).toString()});
        },
        loginWithEmailAndPasswordError(state, {payload}){
            const errorState = payload;
            state.loginWithEmailAndPasswordError = errorState;

            state.loginWithEmailAndPasswordLoading = false;
        },

        refreshUserAuth(state){
            state.refreshUserAuthLoading = true;
        },
        refreshUserAuthSuccess(state, {payload}){
            const currentState = current(state.firebaseAuth);
            const updatedState = {...currentState, ...payload};
            
            state.firebaseAuth = updatedState;

            state.refreshUserAuthLoading = false;
        },
        refreshUserAuthError(state, {payload}){
            const errorState = payload;
            state.refreshUserAuthError = errorState;

            state.refreshUserAuthLoading = false;
        },
        
    }
})

//export function
export const {
    createFirebaseUser,
    createFirebaseUserSuccess,
    createFirebaseUserError,
    deleteFirebaseUser,
    deleteFirebaseUserSuccess,
    deleteFirebaseUserError,
    updateFirebaseUser,
    updateFirebaseUserSuccess,
    updateFirebaseUserError,
    checkUserExists,
    checkUserExistsSuccess,
    checkUserExistsError,
    loginWithEmailAndPassword,
    loginWithEmailAndPasswordGASuccess,
    loginWithEmailAndPasswordTenantSuccess,
    loginWithEmailAndPasswordVerifySuccess,
    loginWithEmailAndPasswordError,
    refreshUserAuth,
    refreshUserAuthSuccess,
    refreshUserAuthError
} = firebaseAuthSlice.actions

//export selector

// data
export const selectFirebaseUser = () =>
  useAppSelector((state) => state.firebaseAuth.firebaseUser);

export const selectFirebaseAuth = () =>
  useAppSelector((state) => state.firebaseAuth.firebaseAuth);

export const selectFirebaseUserExist = () =>
  useAppSelector((state) => state.firebaseAuth.firebaseUserExist);


// loading
export const selectCreateFirebaseUserLoading = () =>
  useAppSelector((state) => state.firebaseAuth.createFirebaseUserLoading);

export const selectDeleteFirebaseUserLoading = () =>
  useAppSelector((state) => state.firebaseAuth.deleteFirebaseUserLoading);

export const selectUpdateFirebaseUserLoading = () =>
  useAppSelector((state) => state.firebaseAuth.updateFirebaseUserLoading);

export const selectCheckUserExistsLoading = () =>
  useAppSelector((state) => state.firebaseAuth.checkUserExistsLoading);

export const selectLoginWithEmailAndPasswordLoading = () =>
  useAppSelector((state) => state.firebaseAuth.loginWithEmailAndPasswordLoading);

export const selectRefreshUserAuthLoading = () =>
  useAppSelector((state) => state.firebaseAuth.refreshUserAuthLoading);

// error
export const selectCreateFirebaseUserError = () =>
  useAppSelector((state) => state.firebaseAuth.createFirebaseUserError);

export const selectDeleteFirebaseUserError = () =>
  useAppSelector((state) => state.firebaseAuth.deleteFirebaseUserError);

export const selectUpdateFirebaseUserError = () =>
  useAppSelector((state) => state.firebaseAuth.updateFirebaseUserError);

export const selectCheckUserExistsError = () =>
  useAppSelector((state) => state.firebaseAuth.checkUserExistsError);

export const selectLoginWithEmailAndPasswordError = () =>
  useAppSelector((state) => state.firebaseAuth.loginWithEmailAndPasswordError);

export const selectRefreshUserAuthError = () =>
  useAppSelector((state) => state.firebaseAuth.refreshUserAuthError);

//export reducer
const firebaseAuthReducer = firebaseAuthSlice.reducer;

export default firebaseAuthReducer;
