import { call, delay, put, takeLatest } from "redux-saga/effects";
import{    
    createTenantAgents,
    createTenantAgentsSuccess,
    createTenantAgentsError,
    deleteTenantAgents,
    deleteTenantAgentsSuccess,
    deleteTenantAgentsError
} from "../stores/slices/tenantAgentSlices"
import { ApiRoute } from "../enums/ApiRoute";
import { API } from "../http-common";

function* createTenantAgentsSaga({payload}){
    try{
        const { data } = yield call(API.post, ApiRoute.tenantAgent.createTenantAgents, payload);
        yield put(createTenantAgentsSuccess(data));
    } catch (error){
        yield put(createTenantAgentsError(error));
        console.error(error);
    }
}
function* deleteTenantAgentsSaga({payload}){
    try{
        const { data } = yield call(API.post, ApiRoute.tenantAgent.deleteTenantAgents, payload);
        yield put(deleteTenantAgentsSuccess(data));
    } catch (error){
        yield put(deleteTenantAgentsError(error));
        console.error(error);
    }
}

export function* tenantAgentSagaWatcher(){
    yield takeLatest(createTenantAgents.type, createTenantAgentsSaga);
    yield takeLatest(deleteTenantAgents.type, deleteTenantAgentsSaga);
}