import { createSlice, current } from "@reduxjs/toolkit";

import { useAppSelector } from "../../hooks/storeHooks";

const initialState = {
  //loading
  retrieveCurrUsageLoading: false,

  //error
  retrieveCurrUsageError: undefined,

  //data
  usageData: [],
};

const usageSlice = createSlice({
  name: "usage",
  initialState,
  reducers: {
    retrieveCurrUsage(state) {
      state.retrieveCurrUsageLoading = true;
    },
    retrieveCurrUsageSuccess(state, { payload }) {
    
      const currentState = payload;
      state.usageData = currentState;
      state.retrieveCurrUsageLoading = false;
    },
    retrieveCurrUsageError(state, { payload }) {
      const errorState = payload;
      state.retrieveCurrUsageError = errorState;

      state.retrieveCurrUsageLoading = false;
    },
  },
});

//export function

export const {
  retrieveCurrUsage,
  retrieveCurrUsageSuccess,
  retrieveCurrUsageError,
} = usageSlice.actions;

//export selector
// data
export const selectUsageData = () =>
  useAppSelector((state) => state.usage.usageData);

// loading
export const selectRetrieveCurrUsageLoading = () =>
  useAppSelector((state) => state.usage.retrieveCurrUsageLoading);

// error
export const selectRetrieveCurrUsageError = () =>
  useAppSelector((state) => state.usage.retrieveCurrUsageError);


//export reducer
const usageReducer = usageSlice.reducer

export default usageReducer
