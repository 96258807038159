import { all, call, delay, put, takeLatest } from "redux-saga/effects";
import { ApiRoute } from "../enums/ApiRoute";
import { createClientGroupRoles, createClientGroupRolesError, createClientGroupRolesSuccess, deleteClientGroupRoles, deleteClientGroupRolesError, deleteClientGroupRolesSuccess, getClientGroupRoles, getClientGroupRolesError, getClientGroupRolesSuccess, getCurrUserClientGroupRoles, setClientGroupRoles, setClientGroupRolesError, setClientGroupRolesSuccess } from "../stores/slices/clientGroupRolesSlices";
import { createClientGroupRoleAgents, deleteClientGroupRoleAgents } from "../stores/slices/clientGroupRoleAgentsSlices";
import { API } from "../http-common";

function* getCurrUserClientGroupRolesSaga({payload}) {
    try{
        const { data } = yield call(API.post, ApiRoute.clientGroupRoles.getCurrUserClientGroupRoles, payload);
        yield put(getClientGroupRolesSuccess(data));
    } catch (error){
        
        yield put(getClientGroupRolesError(error));
        console.error(error);
    }
}

function* getClientGroupRolesSaga({payload}) {
    try{
        const { data } = yield call(API.post, ApiRoute.clientGroupRoles.getClientGroupRoles, payload);
        yield put(getClientGroupRolesSuccess(data));
    } catch (error){
        
        yield put(getClientGroupRolesError(error));
        console.error(error);
    }
}


function* createClientGroupRolesSaga({payload}) {
    const { TenantID, CompanyID, GroupID, RoleDetails, AssignedMember, data, setNotifyMessage } = payload;
    try{
        const effects = [];
        
        effects.push(call(API.post, ApiRoute.clientGroupRoles.createClientGroupRoles, {TenantID, data}));
        effects.push(put(createClientGroupRoleAgents({
            ClientGroupRoleID : RoleDetails.ClientGroupRoleID, 
            UserID : AssignedMember, 
            TenantID : TenantID,
            GroupID : RoleDetails.GroupID
        })));

        yield all(effects);

        const { data: clientGroupRoles } = yield call(API.post, ApiRoute.clientGroupRoles.getClientGroupRoles, {TenantID, GroupID, CompanyID});
        setNotifyMessage("Create role successfully.")
        yield put(createClientGroupRolesSuccess(clientGroupRoles));
    } catch (error){
        setNotifyMessage("Fail to create role.")
        yield put(createClientGroupRolesError(error));
        console.error(error);
    }
}

function* setClientGroupRolesSaga({payload}) {
    const { TenantID, CompanyID, GroupID, RoleDetails, oldAssignedMember, newAssignedMember, data, setNotifyMessage} = payload;

    try{
        const ClientGroupRoleID = data.ClientGroupRoleID;
        const addedArray = newAssignedMember
            .filter(newItem => !oldAssignedMember.some(oldItem => oldItem === newItem));

        const removedArray = oldAssignedMember
              .filter(oldItem => !newAssignedMember.some(newItem => newItem === oldItem))

        const effects = [];

        effects.push(call(API.post, ApiRoute.clientGroupRoles.setClientGroupRoles, {TenantID, data, ClientGroupRoleID}))

        if (addedArray.length > 0) {
            effects.push(put(createClientGroupRoleAgents({
                ClientGroupRoleID: RoleDetails.ClientGroupRoleID, 
                UserID: addedArray, 
                TenantID: TenantID,
                GroupID: RoleDetails.GroupID
            })));
        }

        if (removedArray.length > 0) {
            effects.push(put(deleteClientGroupRoleAgents({
                ClientGroupRoleID: RoleDetails.ClientGroupRoleID, 
                UserID: removedArray, 
                TenantID: TenantID,
            })));
        }

        // Wait for all the put effects to complete
        yield all(effects);

        const { data: clientGroupRoles } = yield call(API.post, ApiRoute.clientGroupRoles.getClientGroupRoles, {TenantID, GroupID, CompanyID});
        setNotifyMessage("Update role successfully.")
        yield put(setClientGroupRolesSuccess(clientGroupRoles));
    } catch (error){
        setNotifyMessage("Fail to update role")
        yield put(setClientGroupRolesError(error));
        console.error(error);
    }
}

function* deleteClientGroupRolesSaga({payload}) {
    const { TenantID, ClientGroupRoleID, setNotifyMessage } = payload;
    try{
        yield call(API.post, ApiRoute.clientGroupRoles.deleteClientGroupRoles, {TenantID, ClientGroupRoleID});
        yield put(deleteClientGroupRoleAgents({ClientGroupRoleID : ClientGroupRoleID, TenantID : TenantID}))
        setNotifyMessage("Delete role successfully.")
        yield put(deleteClientGroupRolesSuccess(ClientGroupRoleID));
    } catch (error){
        setNotifyMessage("Fail to delete role.")
        yield put(deleteClientGroupRolesError(error));
        console.error(error);
    }
}

export function* clientGroupRolesSagaWatcher(){
    yield takeLatest(getClientGroupRoles.type, getClientGroupRolesSaga);
    yield takeLatest(createClientGroupRoles.type, createClientGroupRolesSaga);
    yield takeLatest(setClientGroupRoles.type, setClientGroupRolesSaga);
    yield takeLatest(deleteClientGroupRoles.type, deleteClientGroupRolesSaga);
    yield takeLatest(getCurrUserClientGroupRoles.type, getCurrUserClientGroupRolesSaga);
}