import React, { useState, useEffect, useRef } from 'react'
import { AiFillCheckCircle, AiFillCloseCircle, AiOutlineCheckCircle, AiOutlineClose, AiOutlineNotification, AiOutlineSearch } from 'react-icons/ai'
import { Modal } from "bootstrap";
import { BiReset } from 'react-icons/bi';
import { TbFileDescription } from 'react-icons/tb';
import C_Button from '../../components/C_Button';
import { FaAngleDoubleLeft} from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { v4 } from 'uuid';
import C_Loading from '../../components/C_Loading';
import moment from 'moment';
import { MdDeleteOutline, MdNavigateBefore, MdNavigateNext, MdOutlineTipsAndUpdates, MdRemoveCircleOutline } from 'react-icons/md';
import { set, ref } from "firebase/database";
import { db } from '../../firebaseInit';
import SendNotification from '../../components/Notifications/SendNotification';
import { CgUserList } from 'react-icons/cg';
import C_DialogButton from '../../components/C_DialogButton';
import Colors from '../../constants/Colors';
import C_Search from '../../components/C_Search';
import ReactPaginate from 'react-paginate';
import { FiArrowRight } from 'react-icons/fi';

//slices
import { deleteAppointment, retrieveAppointment, selectAppointmentContext, setAppointmentContext, updateAppointment } from '../../stores/slices/appointmentsSlices';
import { retrieveClientSelected, retrieveClientSelector, retrieveClientSelectorCount, selectClientSelector, selectClientSelectorCount } from '../../stores/slices/clientSelectorSlices';
import { retrieveCurrUsage } from '../../stores/slices/usageSlices';
import { createLogs } from '../../stores/slices/logSlices';
import { selectCurrSa, selectCurrTenant } from '../../stores/slices/persistRolesSlices';
import { selectTenant } from '../../stores/slices/tenantSlices';
import { selectCompanyID } from '../../stores/slices/companyIDSlices';
import { selectCurrUserContext } from '../../stores/slices/companySlices';
import { getProcessedClientData, selectAvailableClientsGroup, selectClient, selectClientContext } from '../../stores/slices/clientSlices';
import { selectUser } from '../../stores/slices/userAuthSlices';

function EditAppointment(props) {

    const user = selectUser();

    const currSa = selectCurrSa();
    const tenant = selectCurrTenant();
    const companyID = selectCompanyID();

    const { filteredClientData, clientColData } = selectClientContext();
    const { currUsers, currUserSA} = selectCurrUserContext();
    const [isLoading, setIsLoading] = useState(false);
    const [extendAttendeesMember, setExtendAttendeesMember] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchText,setSearchText] = useState("");
    const [currClientList,setCurrClientList] = useState([]);

    const [loadData, setLoadData] = useState(true);

    const dispatch = useDispatch();

    function fetchInitializeData() {
        if(tenant){
            return Promise.all([
                dispatch(retrieveClientSelector({ TenantID:tenant,CompanyID: companyID, UserID: user?.uid, CurrPage: 0, SearchText: "", CurrGroup: "", isSA: currSa })),
                dispatch(retrieveClientSelectorCount({ TenantID:tenant,CompanyID: companyID, UserID: user?.uid, SearchText: "",  CurrGroup: "", isSA: currSa }))
            ]);
        } else {
            return Promise.all([]);
        }
        
    }
    
    async function refreshUsage(){
        await Promise.resolve(dispatch(retrieveCurrUsage({TenantID:tenant,CompanyID:companyID})));
    };

    useEffect(() => {

        fetchInitializeData()
            ?.then(() => setLoadData(false))
            .catch((error) => console.log(error));
    
    }, [])

    const modalRef = useRef();

    const {
        currAppointment,
        edit,
        selectedGroupValue,
        clientSelectedList,
        tempCurrAppointment,
    } = selectAppointmentContext();

    const client_selector = selectClientSelector();
    const client_selector_count = selectClientSelectorCount();

    const availableClientsGroup = selectAvailableClientsGroup();

    const client = selectClient();

    useEffect(()=>{
        dispatch(getProcessedClientData({client, availableClientsGroup, currUserSA, selectedGroupValue, isSA : currSa, user}))
    }, [client, availableClientsGroup])

    useEffect(()=>{
        if(!selectedGroupValue){
            dispatch(setAppointmentContext({
                selectedGroupValue : availableClientsGroup[0]
            }))
        }
    }, [])

    const editRef = useRef(null);

    const [memberCheckState, setMemberCheckedState] = useState([]);
    const [clientCheckState, setClientCheckedState] = useState([]);
    const [error, setError] = useState(false);

    const [unSelectedMember, setUnSelectedMember] = useState([]);
    const [unSelectedClient, setUnSelectedClient] = useState([]);

    const showModalFunc = () => {
        const modalEle = modalRef.current;
        const bsModal = new Modal(modalEle, {
            backdrop: "static",
            keyboard: false,
        });
        bsModal.show();
    };

    const hideModalFunc = () => {
            const modalEle = modalRef.current;
            const bsModal = Modal.getInstance(modalEle);
            bsModal.hide();
    };

    function retrieveClientFunction(retrieveFunc) {
        setLoadData(true);
        return Promise.all(
          retrieveFunc
        );
    }

    useEffect(() => {
        if(availableClientsGroup && availableClientsGroup?.length > 0 && tenant){
            dispatch(setAppointmentContext({
                availableClientsGroup : availableClientsGroup[0]
            }))
          retrieveClientFunction([
            // dispatchCurrGroup({type: 'SELECT_GROUP', payload: availableClientsGroup[0] }),
            dispatch(retrieveClientSelector({ TenantID:tenant,CompanyID: companyID, CurrPage: 0, SearchText: "", CurrGroup: availableClientsGroup[0]?.GroupID, isSA: currSa })),
            dispatch(retrieveClientSelectorCount({ TenantID:tenant,CompanyID: companyID, SearchText: "", CurrGroup: availableClientsGroup[0]?.GroupID, isSA: currSa }))
          ])
            .then(() => {
              setTimeout(() => {
                setLoadData(false);
                setSearchText("");
              },1000)
            })
            .catch((error) => console.log(error));
        }
    },[availableClientsGroup, tenant])

    useEffect(() => {

        if(Object.keys(currAppointment).length !== 0){

            const filterByReference = (arr1, arr2) => {
                const structuredArr1 = typeof arr1 === "string" ? JSON.parse(arr1) : arr1;
                const structuredArr2 = typeof arr2 === "string" ? JSON.parse(arr2) : arr2;
                let res = [];
                res = structuredArr1.filter(el => {
                    if(Array.isArray(structuredArr2))
                    {
                        return !structuredArr2.find(element => {
                            return element.uid === el.uid;
                        });
                    }
              
                });
                return res;
            }

            const filterByReferenceClient = (arr1, arr2) => {
                const structuredArr1 = typeof arr1 === "string" ? JSON.parse(arr1) : arr1;
                const structuredArr2 = typeof arr2 === "string" ? JSON.parse(arr2) : arr2;
                let res = [];
                    res = structuredArr1.filter(el => {
                    return !structuredArr2.find(element => {
                        return element?.ClientID === el?.ClientID;
                    });
                });
                return res;
            }
            const newMemberArray = filterByReference(currUsers, currAppointment?.memberAttendees);
            const newClientArray = filterByReferenceClient(filteredClientData, currAppointment?.clientAttendees);

            setUnSelectedMember(newMemberArray);

            setMemberCheckedState(new Array(newMemberArray.length).fill(false));
            setClientCheckedState(new Array(newClientArray.length).fill(false));
      
            dispatch(setAppointmentContext({
                clientSelectedList : client_selector
            }));
            setCurrClientList(currAppointment?.clientAttendees);
        }

    },[currUsers, filteredClientData, currAppointment]);

    function notify(action){
        dispatch(setAppointmentContext({
            triggerAction : action,

        }))
        // dispatchTriggerAction({ type: "TRIGGER", payload: action })
        setTimeout(() => {
            dispatch(setAppointmentContext({
                triggerAction : '',
            }))
            // dispatchTriggerAction({type: "TRIGGER", payload: ''})
        }, 1500)
    };

    function handleChangeInput({value, type}){
        switch(type){
            case "title":
                dispatch(setAppointmentContext({currAppointment : {...currAppointment, title: value}}))
                // dispatchCurrAppointment({ type:"CHANGE_CURR_APPOINTMENT", payload: {...currAppointment, title: value} });
                break;
            case "description":
                dispatch(setAppointmentContext({currAppointment : {...currAppointment, description: value}}))
                // dispatchCurrAppointment({ type:"CHANGE_CURR_APPOINTMENT", payload: {...currAppointment, description: value} });
                break;
            case "start":
                dispatch(setAppointmentContext({currAppointment : {...currAppointment, start: value}}))
                // dispatchCurrAppointment({ type:"CHANGE_CURR_APPOINTMENT", payload: {...currAppointment, start: value} });
                break;
            case "end":
                dispatch(setAppointmentContext({currAppointment : {...currAppointment, end: value}}))
                // dispatchCurrAppointment({ type:"CHANGE_CURR_APPOINTMENT", payload: {...currAppointment, end: value} });
                break;
            case "venue":
                dispatch(setAppointmentContext({currAppointment : {...currAppointment, AppointmentVenue: value}}))
                // dispatchCurrAppointment({ type:"CHANGE_CURR_APPOINTMENT", payload: {...currAppointment, AppointmentVenue: value} });
                break;
            case "color":
                dispatch(setAppointmentContext({currAppointment : {...currAppointment, AppointmentColor: value}}))
                // dispatchCurrAppointment({ type:"CHANGE_CURR_APPOINTMENT", payload: {...currAppointment, AppointmentColor: value} });
                break;
            default:
                return;
        }
    };

    function handleCloseEditor(){
        // dispatchEdit({type: 'EDIT', payload: false});
        dispatch(setAppointmentContext({
            currAppointment : {},
            edit : false
        }))
        // dispatchCurrAppointment({ type:"CHANGE_CURR_APPOINTMENT", payload: {} });
        setExtendAttendeesMember(false);
    }

    function handleUpdateAppointment(){

        if(currAppointment.title !== "" 
            && currAppointment.start !== ""
            && currAppointment.end !== "" 
        ){
            setError(false);
            dispatch(setAppointmentContext({
                edit : false
            }))
            // dispatchEdit({type: 'EDIT', payload: false})
            setExtendAttendeesMember(false);
            setIsLoading(true);
            
            var body = {...currAppointment,
                memberAttendees: JSON.stringify(currAppointment?.memberAttendees),
                clientAttendees: JSON.stringify(currClientList)
            }

            var logBody = {
                ...currAppointment,
                memberAttendees: currAppointment?.memberAttendees,
                clientAttendees: currClientList
            }

            let mergeArray = [{oriValue: JSON.parse(tempCurrAppointment),changedValue:logBody}];
            dispatch(updateAppointment({TenantID:tenant,data:body}))
            if(JSON.stringify(logBody) !== tempCurrAppointment)
            {
                const ActivityLogsID = v4();
                const UserID = user?.uid;
                const CompanyID = companyID;
                const GroupID = "";
                const Title = "Appointment has been updated";
                const Activity = `updated the event (${currAppointment.title})`;
                const Action = "update";
                const Section = "Appointments";
                const Keyword = JSON.stringify(mergeArray);
                const CreatedAt = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");

                const data = {ActivityLogsID,UserID,CompanyID,GroupID,Title,Activity,Action,Section,Keyword,CreatedAt}
                
                dispatch(createLogs({TenantID:tenant,data:data}))
            }
    
            setTimeout(() => {
                setCurrClientList([])
                notify("UPDATED");
                setIsLoading(false);
                dispatch(retrieveAppointment({TenantID:tenant,CompanyID:companyID}))
                dispatch(setAppointmentContext({
                    currAppointment : {},
                }))
                // dispatchCurrAppointment({ type:"CHANGE_CURR_APPOINTMENT", payload: {} });
            }, 2000)
        }else{
            setError(true);
        }
        
    }
    
    function handleRemoveAppointment(){
        const AppointmentID = currAppointment.AppointmentID

        setError(false);
        dispatch(setAppointmentContext({
            edit : false
        }))
        // dispatchEdit({type: 'EDIT', payload: false});
        setIsLoading(true);

        dispatch(deleteAppointment({TenantID:tenant,AppointmentID:AppointmentID}))

        const ActivityLogsID = v4();
        const UserID = user?.uid;
        const CompanyID = companyID;
        const GroupID = "";
        const Title = "Appointment has been removed";
        const Activity = `removed the event (${currAppointment.title})`;
        const Action = "delete";
        const Section = "Appointments";
        const Keyword = currAppointment.title;
        const CreatedAt = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
        const data = {ActivityLogsID,UserID,CompanyID,GroupID,Title,Activity,Action,Section,Keyword,CreatedAt}
        
        dispatch(createLogs({TenantID:tenant,data:data}))
           
        setTimeout(async () => {
            notify("REMOVED");
            setIsLoading(false);
            refreshUsage();
            dispatch(retrieveAppointment({TenantID:tenant,CompanyID:companyID}));
            dispatch(setAppointmentContext({
                currAppointment : {},
            }))
            // dispatchCurrAppointment({ type:"CHANGE_CURR_APPOINTMENT", payload: {} });
        }, 2000)

    }

    function handleRemoveAttendees({type, value}){
        switch(type){
            case "member":
                var newCurrAppointmentMember = currAppointment?.memberAttendees?.filter(item => item.uid !== value)
                dispatch(setAppointmentContext({
                    currAppointment : {...currAppointment, memberAttendees: newCurrAppointmentMember},
                }))
                // dispatchCurrAppointment({ type:"CHANGE_CURR_APPOINTMENT", payload: {...currAppointment, memberAttendees: newCurrAppointmentMember} });
                break;
            default:
                break;
        }
    }

    const handleOnChange = ({position, type}) => {

        switch(type){
            case "member":
                const updatedMemberCheckedState = memberCheckState.map((item, index) =>
                    index === position ? !item : item
                );

                setMemberCheckedState(updatedMemberCheckedState);

                const newMemberAttendees = [];
                
                updatedMemberCheckedState?.map((item, index) => {
                    if(item === true){
                        newMemberAttendees?.push(unSelectedMember[index])
                    }
                })

                const mergeArray = newMemberAttendees.concat(currAppointment?.memberAttendees)

                dispatch(setAppointmentContext({
                    currAppointment : {...currAppointment, memberAttendees: mergeArray},
                }))
                // dispatchCurrAppointment({ type:"CHANGE_CURR_APPOINTMENT", payload: {...currAppointment, memberAttendees: mergeArray} });

                break;
            default:
                break;
            
        }
    };

    function handleNotifiUser(){

        setIsLoading(true);
        dispatch(setAppointmentContext({
            edit : false
        }))
        // dispatchEdit({type: 'EDIT', payload: false});
        setExtendAttendeesMember(false);

        for(var x = 0; x < currAppointment?.memberAttendees?.length; x++){

            const uniqueID = v4();
            const dateNow = Date.now();
            const text = `${user.displayName} has created an appointment - ${currAppointment?.title}`;

            const postData = {
                id: uniqueID,
                senderId: user?.uid,
                quoteType: false,
                quote: false,
                quoteSender: false,
                quoteId: false,
                date: dateNow,
                text: text,
                img: false,
                fileSize: false,
                fileName: false,
                file: false,
                analytics: false,
                appointment: true,
                appointmentTitle: currAppointment?.title,
                appointmentStartDate: currAppointment?.start,
                hasRead: false
            };
            
            const combinedId = user?.uid > currAppointment?.memberAttendees[x]?.uid ? user?.uid + currAppointment?.memberAttendees[x]?.uid : currAppointment?.memberAttendees[x]?.uid + user?.uid
    
            set(ref(db, 'userChats/' + user?.uid + '/' + combinedId + '/lastMessage/recall' ), false);
            set(ref(db, 'userChats/' + currAppointment?.memberAttendees[x]?.uid + '/' + combinedId + '/lastMessage/recall' ), false);
    
            set(ref(db, 'chats/' + combinedId + '/messages/' + uniqueID ), postData)
    
            set(ref(db, '/userChats/' + user?.uid + '/' + combinedId + '/' + 'lastMessage/text'), text)
            set(ref(db, '/userChats/' + currAppointment?.memberAttendees[x]?.uid + '/' + combinedId + '/' + 'lastMessage/text'), text)
    
            set(ref(db, '/userChats/' + user?.uid + '/' + combinedId + '/' + 'date'), dateNow)
            set(ref(db, '/userChats/' + currAppointment?.memberAttendees[x]?.uid + '/' + combinedId + '/' + 'date'), dateNow)
    
            const targetUser = currUserSA.find(e => ( e.uid === currAppointment?.memberAttendees[x]?.uid ))

            const data = { 
                uid: user?.uid,
                displayName: user.displayName,
                photoURL: user.photoURL
            }

            set(ref(db, '/userChats/' + currAppointment?.memberAttendees[x]?.uid + '/' + combinedId + '/' + 'userInfo'), data)

            const dataTarget = { 
                uid: targetUser.uid,
                displayName: targetUser.displayName,
                photoURL: targetUser.photoURL
            }

            set(ref(db, '/userChats/' + user?.uid + '/' + combinedId + '/' + 'userInfo'), dataTarget)
    
            SendNotification({ fcm: targetUser.fcm, title: user.displayName, body: text, profileImg: user.photoURL, imgContent: null })
        }

        setTimeout(() => {
            setIsLoading(false);
            dispatch(setAppointmentContext({
                currAppointment : {},
            }))
            // dispatchCurrAppointment({ type:"CHANGE_CURR_APPOINTMENT", payload: {} });
            notify("Notified");
        }, 1500);
        
    }

    function retrieveClientFunction(retrieveFunc) {
        setLoadData(true);
        return Promise.all(
          retrieveFunc
        );
    }

    const onHandlePageChange = (CurrPage) =>{

        retrieveClientFunction([
            dispatch(retrieveClientSelector({ TenantID:tenant,CompanyID: companyID, CurrPage: CurrPage, SearchText: searchText, CurrGroup: selectedGroupValue?.GroupID, isSA: currSa})),
            dispatch(retrieveClientSelectorCount({ TenantID:tenant,CompanyID: companyID, SearchText: searchText, CurrGroup: selectedGroupValue?.GroupID, isSA: currSa}))
          ])
            .then(() => { 
                setLoadData(false);
                setCurrentPage(CurrPage);
            })
            .catch((error) => console.log(error));
    }
    
    function handleSearchQuery(){

        retrieveClientFunction([
            dispatch(retrieveClientSelector({ TenantID:tenant,CompanyID: companyID, CurrPage: 0, SearchText: searchText, CurrGroup: selectedGroupValue?.GroupID, isSA: currSa })),
            dispatch(retrieveClientSelectorCount({ TenantID:tenant,CompanyID: companyID, SearchText: searchText, CurrGroup: selectedGroupValue?.GroupID, isSA: currSa }))
          ])
            .then(() => { 
                setLoadData(false);
                setCurrentPage(0);
            })
            .catch((error) => console.log(error));
    }

    function handleClearQuery(){

        retrieveClientFunction([
            dispatch(retrieveClientSelector({ TenantID:tenant,CompanyID: companyID, CurrPage: 0, SearchText: "", CurrGroup: selectedGroupValue?.GroupID, isSA: currSa })),
            dispatch(retrieveClientSelectorCount({ TenantID:tenant,CompanyID: companyID, SearchText: "", CurrGroup: selectedGroupValue?.GroupID, isSA: currSa }))
          ])
            .then(() => { 
                setLoadData(false);
                setCurrentPage(0)
                setSearchText("");
            })
            .catch((error) => console.log(error));
    }

    const selectedGroup = (groupValue) => {

        const selectedGroupData = availableClientsGroup?.find(item => item.GroupID === groupValue);
        
        dispatch(setAppointmentContext({
            selectedGroupValue : selectedGroupData
        }))
        //   dispatchCurrGroup({type: 'SELECT_GROUP', payload: selectedGroupData }),
          dispatch(retrieveClientSelector({ 
            TenantID:tenant,
            CompanyID: companyID, 
            CurrPage: 0, 
            SearchText: "", 
            CurrGroup: groupValue,
            isSA: currSa, 
            isAppointment : true, 
            clientSelectedList : clientSelectedList 
        }))
          dispatch(retrieveClientSelectorCount({ TenantID:tenant,CompanyID: companyID, SearchText: "", CurrGroup: groupValue, isSA: currSa }))
        
            setTimeout(() => {
              setLoadData(false);
              setSearchText("");
            },1000)
    }

  return (
    <>
    <C_Loading isLoading={isLoading}/>
    
    <div ref={editRef} className={`EditAppointmentContainer ${edit && 'showEditContainer'} ${extendAttendeesMember && 'removeBorderRadius'}`}>
        <div className={`extendContainerMember ${extendAttendeesMember && 'extend-member-active'}`}>
            <div className='attendeesHeader'>
                <span>Attendees - Members</span>
            </div>
            <div className='memberList'>
                {((Object.keys(currAppointment).length !== 0) && Array.isArray(currAppointment?.memberAttendees)) && currAppointment?.memberAttendees?.map((item, index) => {
                    return(
                        <div key={index} className='list'>
                            <span>{item?.displayName}</span>
                            {currAppointment?.organizer === user?.uid && <AiFillCloseCircle className='icon' size={16} onClick={() => { handleRemoveAttendees({type: 'member', value: item.uid}) }}/> }
                        </div>
                    )
                })}
                {currAppointment?.organizer === user?.uid && unSelectedMember?.map((item, index) => {
                    if(item.uid !== user?.uid){
                        return(
                            <div key={index} className='listNonAttendees'>
                                <span>{item?.displayName}</span>
                                <div className='checkbox'>
                                    <label style={{ display: 'flex', alignItems: 'center' }}>
                                        <input
                                            className=""
                                            value={item.uid}
                                            checked={memberCheckState[index]}
                                            type="checkbox"
                                            onChange={() => handleOnChange({type: 'member', position: index})}
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                        )
                    }
                })}
            </div>
            {/* {currAppointment?.memberAttendees?.length > 0 && currAppointment?.organizer === user?.uid &&
                <div className='notifyAction'>
                    <C_Button
                        width={"100%"}
                        buttonText={"NOTIFY USER"}
                        onClick={() => {
                            handleNotifiUser();
                        }}
                        justify={"space-evenly"}
                        textColor={"#FFFFFF"}
                        icon={<AiOutlineNotification size={16} />}
                    />
                </div>
            } */}
        </div>

        <div className='appointmentInfoContainer'>
            <div className='infoHeader'>
                <FiArrowRight className='icon' size={22} onClick={() => { handleCloseEditor() }}/>
                <input
                    disabled={currAppointment?.organizer !== user?.uid ? true : false}
                    type="color"
                    onChange={(e) => {
                        handleChangeInput({value: e.target.value, type: "color"})
                    }}
                    value={currAppointment?.AppointmentColor}
                />
                <span className='title'>Appointment Details</span>
                <span className={`error ${error && 'showError'}`}>Please complete all field</span>
            </div>

            <div className='infoContent'>
                <div className='fields'>
                    <div className='inputField'>
                        <input
                            disabled={currAppointment?.organizer !== user?.uid ? true : false}
                            placeholder='Add title'
                            value={currAppointment?.title}
                            onChange={(e) => {
                                handleChangeInput({value: e.target.value, type: "title"})
                            }}   
                        />
                    </div>
                    <div className='inputFieldDateTime'>
                        <input
                            disabled={currAppointment?.organizer !== user?.uid ? true : false}
                            type="datetime-local"
                            value={currAppointment?.start}
                            min={moment(new Date).format("YYYY-MM-DDThh:mm")}
                            max={currAppointment?.end}
                            onChange={(e) => {
                                handleChangeInput({value: e.target.value, type: "start"})
                            }}
                        />
                        <span>TO</span>
                        <input
                            disabled={currAppointment?.organizer !== user?.uid ? true : false}
                            type="datetime-local"
                            value={currAppointment?.end}
                            min={currAppointment?.start}
                            onChange={(e) => {
                                handleChangeInput({value: e.target.value, type: "end"})
                            }}
                        />
                    </div>
                    <div className='inputField'>
                        <input 
                            disabled={currAppointment?.organizer !== user?.uid ? true : false}
                            placeholder='Add venue'
                            value={currAppointment?.AppointmentVenue}
                            onChange={(e) => {
                                handleChangeInput({value: e.target.value, type: "venue"})
                            }}
                        />
                    </div>
                    <div className='inputField'>
                        <div className='inputTitle'>
                            <TbFileDescription size={16}/>
                            <span>Description</span>
                        </div>
                        {currAppointment?.organizer === user?.uid ?
                        <textarea
                            className='textarea-input'
                            placeholder='Description'
                            value={currAppointment?.description}
                            onChange={(e) => {
                                handleChangeInput({value: e.target.value, type: "description"})
                            }}
                        >{currAppointment?.description}</textarea>
                        :
                        <div className='desription' dangerouslySetInnerHTML={{ __html: currAppointment?.description }}>

                        </div>
                        }
                    </div>
                    <div className='showAttendeesAction'>
                        <div className='showAttendees' onClick={() => { setExtendAttendeesMember(!extendAttendeesMember);  }}>
                            <div className='titleAttendees'>
                                <FaAngleDoubleLeft size={16} />
                                <span>Member Attendees</span>
                            </div>
                            <span className='countMember'>{currAppointment?.memberAttendees?.length > 99 ? '..' : currAppointment?.memberAttendees?.length}</span>
                        </div>
                        <div className='showAttendees' onClick={() => { showModalFunc();setSearchText("");}}>
                            <div className='titleAttendees'>
                                <FaAngleDoubleLeft size={16} />
                                <span>Client Attendees</span>
                            </div>
                            <span className='countClient'>{currClientList.length}</span>
                        </div>
                    </div>

                </div>
            </div>

        </div>
        
        {currAppointment?.organizer === user?.uid &&
            <div className='actionContainer'>
                <C_Button
                    background={"#ca4254"}
                    width={"35%"}
                    buttonText={"Remove Event"}
                    onClick={() => {
                        handleRemoveAppointment()
                    }}
                    justify={"center"}
                    gap={"15px"}
                    textColor={"#FFFFFF"}
                    icon={<MdDeleteOutline size={16} />}
                />
                <C_Button
                    background={"#6d45b9"}
                    width={"35%"}
                    buttonText={"Update Event"}
                    onClick={() => {
                        handleUpdateAppointment()
                    }}
                    justify={"center"}
                    gap={"15px"}
                    textColor={"#FFFFFF"}
                    icon={<MdOutlineTipsAndUpdates size={16} />}
                />
            </div>
        }
    </div>
    <div className="modal fade" tabIndex="-1" ref={modalRef} style={{}}>
        <div className="modal-dialog modal-dialog-centered" style={{}}>
            <div className="modal-content px-3" style={{ minHeight: "556px", overflowY: "scroll" }}>
                <div className="modal-header modalHeader">
                    <div className="title">
                        <CgUserList size={16}/>
                        <span>Client List</span>
                        <select defaultValue={selectedGroupValue?.GroupID} value={selectedGroupValue?.GroupID} className="form-select font-xs" onChange={(e) => {
                            selectedGroup(e.target.value)
                        }}
                        style={{ 
                            alignItems: 'center', 
                            backgroundColor: Colors.standardBackground, 
                            color: '#000000', 
                            width: '180px', 
                            marginLeft: '10px', 
                            borderRadius: '8px', 
                            height: '36px' }}>
                            <option value="" disabled>Select a group</option>
                            {Array.isArray(availableClientsGroup) && availableClientsGroup?.map((item, index) => {
                                return (
                                    <option key={index} value={item?.GroupID}>{item?.GroupName}</option>
                                );
                            })}
                        </select>
                    </div>
                    <AiOutlineClose cursor='pointer' size={16} onClick={() => { hideModalFunc(); }}/>
                </div>
                <div className="modal-body d-flex row" style={{paddingTop: '5px'}}>
                    {currClientList.length > 0 &&
                        <div className="appointmentSelectedListcontainer">
                            {currClientList.map((item, listIndex) => {
                                const matchingClient = clientSelectedList.find(client => client.ClientID === item);
                                const rowValue = matchingClient ? matchingClient.RowValue : null;
                                    return (
                                        <div key={listIndex} className="selectedClients">
                                            <span>{rowValue ? rowValue : "N/A"}</span>
                                            <MdRemoveCircleOutline size={14} color="red" cursor="pointer" onClick={() => { 
                                                const newList = currClientList.filter(client => client !== item);
                                                dispatch(retrieveClientSelected({ TenantID:tenant, selectedClient: newList }))
                                                setCurrClientList(newList)
                                            }}/>
                                        </div>
                                    )
                            })}
                        </div>
                    }
                    <div className="appointmentClientListSearchContainer">
                        <C_Search
                            placeholder="Search"
                            onChange={(e) => {
                                setSearchText(e.target.value);
                            }}
                            height={"35px"}
                            containerWidth={"80%"}
                            padding={"10px"}
                            value={searchText}
                            searchText={searchText}
                            closeFunction={() => {
                                setSearchText("");
                            }}
                        />
                        
                        <C_Button
                            background={Colors.generalButtonColor}
                            width={"120px"}
                            height={"30px"}
                            buttonText={"Search"}
                            justify={"center"}
                            onClick={() => {
                                handleSearchQuery()
                            }}
                            color={"#FFF"}
                            textColor={"#FFFFFF"}
                            border={"0px"}
                            gap={"10px"}
                            icon={<AiOutlineSearch size={16} />}
                        />
                        <C_Button
                            background={"#FFF"}
                            width={"120px"}
                            buttonText={"Reset"}
                            justify={"center"}
                            onClick={() => {
                                handleClearQuery()
                            }}
                            color={"#000"}
                            height={"30px"}
                            border={"1px solid #000"}
                            gap={"10px"}
                            icon={<BiReset size={15} />}
                        />
                    
                    </div>
                    <div className={`appointmentloadingTable ${loadData && 'showLoadingTable'}`} style={{height: "300px"}}>
                        <img src={require('../../assets/retrieveDataGif.gif')} alt=''/>
                        <span>Retrieving data...</span>
                    </div>
                    <div className="appointmentClientListContainer" style={{ display: loadData ? 'none' : 'flex'  }}>
                        {client_selector?.map((item, selectorIndex) => {
                            let isSelected = currClientList.includes(item.ClientID)
                            return(
                                <div
                                    key={selectorIndex}
                                    className='clientContainer'
                                    style={isSelected ? {background:'#e6e6e6' } : {background:'#fff'}}
                                    onClick={() => { 
                                        if(isSelected){
                                            const newList = currClientList.filter(client => client !== item.ClientID);
                                            setCurrClientList(newList)
                                            dispatch(retrieveClientSelected({ TenantID:tenant,selectedClient: newList }))
                                        }else{
                                            const newList = [...currClientList, item.ClientID];
                                            setCurrClientList(newList)
                                            dispatch(retrieveClientSelected({ TenantID:tenant,selectedClient: newList }))
                                        }
                                }}>
                                    <span>{item.RowValue}</span>
                                    {isSelected ? <AiFillCheckCircle size={18} color="green"/> : <AiOutlineCheckCircle size={18}/>}
                                </div>
                            )
                        })}
                    </div>
                    <div>
                    <ReactPaginate
                        containerClassName="paginationStyle"
                        activeClassName="activePage"
                        pageClassName="paginationElementStyle"
                        breakLabel="..."
                        nextLabel={<MdNavigateNext size={20}/>}
                        onPageChange={(page) => { onHandlePageChange(page.selected) }}
                        pageRangeDisplayed={5}
                        pageCount={(client_selector_count && client_selector_count.length > 0) && Math.ceil(client_selector_count[0].Total_Clients/500)}
                        previousLabel={<MdNavigateBefore size={20}/>}
                        renderOnZeroPageCount={null}
                        forcePage={currentPage}
                    />  
                    </div>
                    <div className="appointmentClientActionContainer mt-2">
                        <span className='counts'>Total {currClientList.length} clients selected</span>
                        <div className='buttonsContainer'>
                            <C_DialogButton
                                onClick={()=>{ hideModalFunc(); }}
                                color={'#4287f5'}
                                background={'white'}
                                borderColor={'#4287f5'}
                                buttonText={"DISCARD"}
                            />
                            <C_DialogButton
                                onClick={()=>{
                                    hideModalFunc();
                                    dispatch(setAppointmentContext({
                                        currAppointment : {...currAppointment, clientAttendees: currClientList}
                                    }))
                                    // dispatchCurrAppointment({ type:"CHANGE_CURR_APPOINTMENT", payload: {...currAppointment, clientAttendees: currClientList} });
                                }}
                                color={"white"}
                                background={'#4287f5'}
                                borderColor={'#4287f5'}
                                buttonText={"CONFIRM"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default EditAppointment