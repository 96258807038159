export const TransactionEnum = {
    //* number format types list
    STATUS: [
      {
        label: "Ready",
        value: "READY",
        group: "Status",
      },
      {
        label: "Pending Approval",
        value: "PENDING",
        group: "Status",
      },
      {
        label: "Draft",
        value: "DRAFT",
        group: "Status",
      },
      {
        label: "Void",
        value: "VOID",
        group: "Status",
      },
    ],
    EMAIL_TRACKER: [
      {
        label: "Not Sent",
        value: "NOT_SENT",
      },
      {
        label: "Pending",
        value: "PENDING",
      },
      {
        label: "Sent",
        value: "SENT",
      },
      {
        label: "Bounced",
        value: "BOUNCED",
      },
      {
        label: "Opened",
        value: "OPENED",
      },
      {
        label: "Viewed",
        value: "VIEWED",
      },
    ],
    TRANSFER_STATUS: [
      {
        label: "Not Transfer",
        value: "NOT_TRANSFER",
      },
      {
        label: "Partial",
        value: "PARTIAL",
      },
      {
        label: "Transfered",
        value: "TRANSFERED",
      },
    ],
  };
  