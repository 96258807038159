import React, {
  useState,
  useEffect,
  useRef,
  Fragment,
} from "react";
import { useDispatch } from "react-redux";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import C_Button from "../../components/C_Button";
import { v4 } from "uuid";
import moment from "moment";
import Colors from "../../constants/Colors";
import { BiArrowBack, BiReset } from "react-icons/bi";
import "../form_template/Form.scss"

import C_DialogButton from "../../components/C_DialogButton";
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md";
import { Modal } from "bootstrap";
import { AiFillCheckCircle, AiOutlineCheckCircle, AiOutlineClose, AiOutlineSearch } from "react-icons/ai";
import C_Search from "../../components/C_Search";
import { CgUserList } from "react-icons/cg";
import ReactPaginate from "react-paginate";
import "./FormSubmission.scss"

//slices
import { retrieveFormTemplate, selectFormContext, selectFormTemplate, setFormContext } from "../../stores/slices/formTemplateSlices";
import { createFormSubmission, retrieveFormSubmission, updateFormSubmission } from "../../stores/slices/formSubmissionSlices";
import { setFormID } from "../../stores/slices/formIDSlices";
import { retrieveClientSelected, retrieveClientSelector, retrieveClientSelectorCount, selectClientSelected, selectClientSelector, selectClientSelectorCount, selectRetrieveClientSelectorCountLoading, selectRetrieveClientSelectorLoading } from "../../stores/slices/clientSelectorSlices";
import { retrieveCurrUsage } from "../../stores/slices/usageSlices";
import { selectCurrSa, selectCurrTenant } from "../../stores/slices/persistRolesSlices";
import { selectAvailableClientsGroup } from "../../stores/slices/clientSlices";
import { selectCompanyID } from "../../stores/slices/companyIDSlices";
import { selectCurrUserContext } from "../../stores/slices/companySlices";
import { selectUser } from "../../stores/slices/userAuthSlices";

function FormSubmission() {
  const {
    memberSubmissions,
    memberFormTemplates,
    selectedGroupValue
  } = selectFormContext();

  const availableClientsGroup = selectAvailableClientsGroup();
  const client_selector = selectClientSelector();
  const client_selector_count = selectClientSelectorCount();
  const selectorLoading = selectRetrieveClientSelectorLoading();
  const selectorCountLoading = selectRetrieveClientSelectorCountLoading();

  const clientSelectedList = selectClientSelected();
  const { currUserSA } = selectCurrUserContext();
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const currSa = selectCurrSa();
  const tenant = selectCurrTenant();
  const companyID = selectCompanyID();
  const formTemplate = selectFormTemplate();

  const user = selectUser();

  const [questionsArray, setQuestionsArray] = useState([]);
  const [answersArray, setAnswersArray] = useState([]);

  const [checkboxArray, setCheckboxArray] = useState([]);
  const [radioArray, setRadioArray] = useState([]);

  const [applicant,setApplicant] = useState("");
  const [applicantFormID,setApplicantFormID] = useState("");
  const [createdAt,setCreatedAt] = useState("");
  const [formStatus,setFormStatus] = useState("");
  const [reason,setReason] = useState("");
  const [approvalDate,setApprovalDate] = useState("");
  const [approvalUserID,setApprovalUserID] = useState("");
  const [isEmailFormat, setIsEmailFormat] = useState(true);
  const [isPhoneFormat, setIsPhoneFormat] = useState(true);
  const [isRequiredEmpty,setIsRequiredEmpty] = useState(true);
  const [searchText,setSearchText] = useState("");
  const [currClient,setCurrClient] = useState("");
  const [currQuestionIndex,setCurrQuestionIndex] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [clientAnswerList, setClientAnswerList] = useState([]);
  const [answersTotallyEmpty,setAnswersTotallyEmpty] = useState(false);

  const [loadData, setLoadData] = useState(true);

  // function retrieveClientFunction(retrieveFunc) {
  //   setLoadData(true);
  //   return Promise.all(
  //     retrieveFunc
  //   );
  // }

  async function refreshUsage(){
    await Promise.resolve(dispatch(retrieveCurrUsage({TenantID:tenant,CompanyID:companyID})));
  };

  useEffect(()=>{
    setLoadData(false);
  },[selectorLoading, selectorCountLoading])

  useEffect(() => {
    if(availableClientsGroup && availableClientsGroup?.length > 0 && tenant){
      dispatch(setFormContext({
        selectedGroupValue: availableClientsGroup[0]
      }))
      dispatch(retrieveClientSelector({ TenantID:tenant,CompanyID: companyID, CurrPage: 0, SearchText: "", CurrGroup: availableClientsGroup[0]?.GroupID, isSA: currSa })),
      dispatch(retrieveClientSelectorCount({ TenantID:tenant,CompanyID: companyID, SearchText: "", CurrGroup: availableClientsGroup[0]?.GroupID, isSA: currSa }))

      setSearchText("");
    }
  },[availableClientsGroup, tenant, user, companyID, formTemplate])

  useEffect(() => {
    if(tenant){
      dispatch(retrieveClientSelected({TenantID:tenant, selectedClient: clientAnswerList }))
    }
  },[clientAnswerList, tenant])

  // useEffect(()=>{
  //   if(tenant && companyID){
  //     dispatch(retrieveAvailableGroup({ TenantID:tenant,CompanyID: companyID, UserID: user?.uid }))
  //   }
    
  // }, [tenant, companyID])
  

  const modalRef = useRef();

  const showModalFunc = () => {
    const modalEle = modalRef.current;
    const bsModal = new Modal(modalEle, {
        backdrop: "static",
        keyboard: false,
    });
    bsModal.show();
  };

  const hideModalFunc = () => {
        const modalEle = modalRef.current;
        const bsModal = Modal.getInstance(modalEle);
        bsModal.hide();
  };

  useEffect(() => {
    var question = [];
    var answer = [];

    if (searchParams.get("formID") !== null) {

      var memberObj = memberFormTemplates.find((element, index) => { if (element.FormID === searchParams.get("formID")) { return element } });
      if(memberObj !== undefined){
        question = memberObj.FormData
        answer = question.map(item => { return { answerID: v4(), questionID: item.questionID, answer: '',previousAnswer:'' } });
  
        //checkbox and radio
        let arrayBool = [];
        let radio = [];
        question.map((ques, index) => {
          arrayBool.push([]);
          radio.push([]);
        })
  
        question.map((ques, index) => {
          if (ques.questionType === "Checkboxes") {
            ques.option.map(option => {
              arrayBool[index].push(false);
            })
          }
          if (ques.questionType === "Multiple Choice") {
            ques.option.map(option => {
              radio[index].push(false);
            })
          }
        })
  
        setCheckboxArray(arrayBool);
        setRadioArray(radio);
  
      }
     
    } // end formID

    if (searchParams.get("formSubmissionID") !== null) {
      var array = [];
      var answerData = [];
      var creator = "";
      var formID = "";
      var dateCreated = ""; 
      var status = "";
      var rejectedReason = "";
      var approvalDate = "";
      var approvalUserID = "";
      var currentClient = [];
      
      memberFormTemplates.map(template => {
        memberSubmissions.map(form => {
          if (form.SubmissionID === searchParams.get("formSubmissionID")) {
            
            creator = form.UserID;
            formID = form.FormID;
            dateCreated = form.CreatedAt;
            status = form.SubmissionStatus;
            rejectedReason = form.ReasonRejected;
            approvalDate = form.ApprovalDate;
            approvalUserID = form.ApprovalUserID
            if (form.FormID === template.FormID) {
              question = template.FormData
            }

            if(form.SubmissionStatus === "2" || form.SubmissionStatus === "3")
            {
              status = "3";
            }
            else
            {
              status = "0";
            }
          }
        })
      })

      question.map((item,index)=>{
        answer.push({});
      })

      
      const submissionObj = memberSubmissions.find(element=>element.SubmissionID === searchParams.get("formSubmissionID"))
      if(submissionObj !== undefined)
      {
        answerData = submissionObj.SubmissionData
      }
    
      question.map((ques,quesIndex)=>{

        const correspondingAnswer = answerData.find(item => item.questionID === ques.questionID);

        if (correspondingAnswer) {
          const updatedAnswer = {
            ...correspondingAnswer,
            previousAnswer: correspondingAnswer.answer
          };

          answer[quesIndex] = updatedAnswer;
        } 
        
        if(Object.keys(answer[quesIndex]).length === 0)
        {
          answer[quesIndex] = {answerID:v4(),questionID:ques.questionID,answer:"", previousAnswer:""};
        }
       
      })

      let checkbox = [];
      let radio = [];
      question.map((ques, index) => {
        checkbox.push([]);
        radio.push([]);
      })
      question.map((ques, index) => {
        answer.map((answer, answerIndex) => {
          if (answer.questionID === ques.questionID) {
            if (ques.questionType === "Checkboxes") {
              ques.option.map(option => {
                if (answer.answer.includes(option)) {
                  checkbox[index].push(true);
                }
                else {
                  checkbox[index].push(false);
                }

              })
            }
            if (ques.questionType === "Multiple Choice") {
              ques.option.map(option => {
                if (answer.answer.includes(option)) {
                  radio[index].push(true);
                }
                else {
                  radio[index].push(false);
                }

              })
            }
            if(ques.questionType === "Client")
            {
              currentClient.push(answer.answer)
            }
          }

        })
      })

      setClientAnswerList([...clientAnswerList, ...currentClient])
      setCheckboxArray(checkbox);
      setRadioArray(radio);
      setApplicant(creator);
      setApplicantFormID(formID);
      setCreatedAt(dateCreated);
      setFormStatus(status);
      setReason(rejectedReason);
      setApprovalDate(approvalDate);
      setApprovalUserID(approvalUserID);
    }

    setQuestionsArray(question);
    setAnswersArray(answer);

  }, [searchParams, memberFormTemplates, memberSubmissions])



  useEffect(() => {

    if(tenant){
      dispatch(retrieveFormTemplate({TenantID:tenant,CompanyID:companyID, isSA : currSa}));
      if(searchParams.get("formSubmissionID") !== null){
        dispatch(retrieveFormSubmission({TenantID:tenant,CurrPage:"",UserID: "", SubmissionID:searchParams.get("formSubmissionID")}));
      }
    }
  }, [tenant])

  const handleOptionAnswerInput = (e, questionIndex, optionIndex) => {

    if (e.target.type === "checkbox") {
      let updatedCheckedState = [];
      let checkedState = [];
      const array = [];

      checkboxArray.map((item, boolIndex) => {

        if (boolIndex === questionIndex) {
          item.map((option, index) => {
            if (optionIndex === index) {
              option = !option;
              checkedState.push(option);
            }
            else {
              checkedState.push(option);
            }
          })
          updatedCheckedState.push(checkedState);
        }
        else {
          updatedCheckedState.push(item);
        }
      })

      //reset array
      if (questionsArray[questionIndex]?.option !== undefined) {
        questionsArray[questionIndex].option.map((option, index) => {
          checkedState.map((checked, checkedIndex) => {
            if (checked === true) {
              if (checkedIndex === index) {
                array.push(option);
              }
            }
          })
        })
      }

      answersArray[questionIndex].answer = array;
      setCheckboxArray(updatedCheckedState);

    }//end if checkbox

    if (e.target.type === "radio") {
      let updatedCheckedState = [];
      let checkedState = [];
      const array = [];

      radioArray.map((item, radioIndex) => {

        if (radioIndex === questionIndex) {
          item.map((option, index) => {
            if (optionIndex === index) {
              //option = true;
              checkedState.push(true);
            }
            else {
              //option = false;
              checkedState.push(false);
            }
          })
          updatedCheckedState.push(checkedState);
        }
        else {
          updatedCheckedState.push(item);
        }
      })

      //reset array
      if (questionsArray[questionIndex]?.option !== undefined) {
        questionsArray[questionIndex].option.map((option, index) => {
          checkedState.map((checked, checkedIndex) => {
            if (checked === true) {
              if (checkedIndex === index) {
                array.push(option);
              }
            }
          })
        })
      }

      answersArray[questionIndex].answer = array;
      setRadioArray(updatedCheckedState);

    }//end if radio
  }

  const handleAnswerInput = (e, questionIndex) => {
    let answer = [...answersArray];
    if (e.target.name === "Date Range") {
      let dateArray;
      if(answer[questionIndex].answer === "")
      {
        dateArray = {start:"",end:""}
      }
      else
      {
        dateArray = answer[questionIndex].answer
      }
      
      if(e.target.placeholder === "DateRange1")
      {
        dateArray.start = e.target.value;
        answer[questionIndex].answer = dateArray;
      }
      else {
        dateArray.end = e.target.value;
        answer[questionIndex].answer = dateArray;
      }
      
    }
    else if(e.target.name === "Decimal")
    {
      var val = e.target.value
      //e.value = (t.indexOf(".") >= 0) ? (t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)) : t;
      if(val.indexOf(".")>= 0)
      {
        val = (val.substr(0,val.indexOf(".")) + val.substr(val.indexOf("."), 3))
        answer[questionIndex].answer = val;
      }
      else
      {
        answer[questionIndex].answer = val;
      }
    }
    else
    {
      answer[questionIndex].answer = e.target.value;
    }

    setAnswersArray(answer);
  }

  const handleAnswerInputClient = () => {

      let answer = [...answersArray];
      
      answer[currQuestionIndex].answer = currClient;
      
      setClientAnswerList([...clientAnswerList, currClient]);
      setAnswersArray(answer);

  }


  
  const createTrackingNumber = (str) =>{
    //var str = "Medical Form"
    var firstLetters = "";
    var trackingNumber = "";
    var randNumber = Math.floor(Math.random()*900000) + 100000;
    var randFirstNumber = Math.floor(Math.random()*9) + 1;

    firstLetters = str.split(' ').map(word=>word[0]).join('');
    trackingNumber = firstLetters + randFirstNumber + "0" + randNumber;
    
    return trackingNumber;
  }

  //CRUD
  const submitForm = () => {

    let regexEmail = new RegExp(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/);
    let regexPhone = new RegExp(/^(\+?6?01)[02-46-9]-*[0-9]{7}$|^(\+?6?01)[1]-*[0-9]{8}$/);
    let emailFormat = true;
    let phoneFormat = true;
    let requiredEmpty = true;
    let formName = "";

    answersArray.map((answer,answerIndex)=>{
      questionsArray.map((question,questionIndex)=>{
        if(answer.questionID === question.questionID)
        {
          if(question.questionType === "Email")
          {
            emailFormat = regexEmail.test(answer.answer)
            // console.log(emailFormat)
          }
          if(question.questionType === "Phone")
          {
            phoneFormat = regexPhone.test(answer.answer)
            // console.log(phoneFormat)
          }
        }
        if(question.requiredInput === true)
        {
            if(answer.questionID === question.questionID)
            {
              if(answer.answer === "")
              {
                requiredEmpty = false;
              }
            }
             
        }
      })
    })
    
    const isTotallyEmpty = answersArray.every(answer=>answer.answer === "");
    memberFormTemplates.map((item)=>{
      if(item.FormID === searchParams.get("formID") )
      {
        formName = item.FormName;
      }
    })

    setIsEmailFormat(emailFormat);
    setIsPhoneFormat(phoneFormat);
    setIsRequiredEmpty(requiredEmpty);
    setAnswersTotallyEmpty(isTotallyEmpty);
    if (emailFormat === true && phoneFormat === true && requiredEmpty === true && !isTotallyEmpty) {
      let formArray = {
        SubmissionID: v4(),
        UserID: user?.uid,
        FormID: searchParams.get("formID"),
        FormTrackingID:createTrackingNumber(formName),
        CreatedAt: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        ModifiedAt: "",
        ApprovalDate: "",
        ApprovalUserID: "",
        SubmissionStatus: "0",
        ReasonRejected: "",
        SubmissionData: JSON.stringify(answersArray)
      };
      
      dispatch(createFormSubmission({TenantID:tenant,data:formArray}))
  
      if(requiredEmpty === true)
      {
        dispatch(setFormID(""));
        refreshUsage();
        navigate({pathname:"/FormList",search:createSearchParams({code:"200"}).toString()});
      }
   
    }
  }

  const editForm = () => {
    let regexEmail = new RegExp(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/);
    let regexPhone = new RegExp(/^(\+?6?01)[02-46-9]-*[0-9]{7}$|^(\+?6?01)[1]-*[0-9]{8}$/);
    let emailFormat = true;
    let phoneFormat = true;
    let requiredEmpty = true;

    answersArray.map((answer)=>{
      questionsArray.map((question)=>{
        if(answer.questionID === question.questionID)
        {
          if(question.questionType === "Email")
          {
            emailFormat = regexEmail.test(answer.answer)
            // console.log(emailFormat)
          }
          if(question.questionType === "Phone")
          {
            phoneFormat = regexPhone.test(answer.answer)
            // console.log(phoneFormat)
          }
        }
        if(question.requiredInput === true)
        {
            if(answer.questionID === question.questionID)
            {
              if(answer.answer === "")
              {
                requiredEmpty = false;
              }
            }
             
        }
      })
    })
    const isTotallyEmpty = answersArray.every(answer=>answer.answer === "");
    setIsEmailFormat(emailFormat)
    setIsPhoneFormat(phoneFormat)
    setIsRequiredEmpty(requiredEmpty);
    setAnswersTotallyEmpty(isTotallyEmpty);
    let body = {
      SubmissionID: searchParams.get("formSubmissionID"),
      UserID : applicant,
      FormID : applicantFormID,
      CreatedAt : createdAt,
      ModifiedAt : moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      ApprovalDate : approvalDate,
      ApprovalUserID : approvalUserID,
      SubmissionStatus: formStatus,
      ReasonRejected: reason,
      SubmissionData: JSON.stringify(answersArray)
    };

    if (emailFormat === true && phoneFormat === true && requiredEmpty === true && !isTotallyEmpty) {
      dispatch(updateFormSubmission({TenantID:tenant,data:body}))
        // console.log(res)
        if(requiredEmpty === true)
          {
          if(formStatus === "3" || formStatus === "2")
          {
            navigate({pathname:"/FormList",search:createSearchParams({code:"202"}).toString()})
          }
          else
          {
            navigate({pathname:"/FormList",search:createSearchParams({code:"201"}).toString()})
          }
        }
    }
    
  }

  const onHandlePageChange = (CurrPage) =>{

      dispatch(retrieveClientSelector({ TenantID:tenant,CompanyID: companyID, CurrPage: CurrPage, SearchText: searchText, CurrGroup: selectedGroupValue?.GroupID, isSA: currSa })),
      dispatch(retrieveClientSelectorCount({ TenantID:tenant,CompanyID: companyID, SearchText: searchText, CurrGroup: selectedGroupValue?.GroupID, isSA: currSa }))

      setCurrentPage(CurrPage);

}

function handleSearchQuery(){

    dispatch(retrieveClientSelector({ TenantID:tenant,CompanyID: companyID, CurrPage: 0, SearchText: searchText, CurrGroup: selectedGroupValue?.GroupID, isSA: currSa })),
    dispatch(retrieveClientSelectorCount({ TenantID:tenant,CompanyID: companyID, SearchText: searchText, CurrGroup: selectedGroupValue?.GroupID, isSA: currSa }))

    setCurrentPage(0);

}

function handleClearQuery(){

    dispatch(retrieveClientSelector({ TenantID:tenant,CompanyID: companyID, CurrPage: 0, SearchText: "", CurrGroup: selectedGroupValue?.GroupID, isSA: currSa })),
    dispatch(retrieveClientSelectorCount({ TenantID:tenant,CompanyID: companyID, SearchText: "", CurrGroup: selectedGroupValue?.GroupID, isSA: currSa }))

    setCurrentPage(0)
    setSearchText("");

}

  const selectedGroup = (groupValue) => {

    const selectedGroupData = availableClientsGroup?.find(item => item.GroupID === groupValue);
    dispatch(setFormContext({
      selectedGroupValue: selectedGroupData
    }))

      dispatch(retrieveClientSelector({ TenantID:tenant,CompanyID: companyID, CurrPage: 0, SearchText: "", CurrGroup: groupValue, isSA: currSa })),
      dispatch(retrieveClientSelectorCount({ TenantID:tenant,CompanyID: companyID, SearchText: "", CurrGroup: groupValue, isSA: currSa }))

      setSearchText("");
  }

  
  return (
    <Fragment>
      <ToastContainer />
      <div className="sidebar-main-container">
          <Sidebar/>
        </div>
        <div className="main-container">
        <Header title={"Apply Form"} />
        <div
          className="form-outer-container"
        >
          <div className="form-inner-container">
            <div className="form-content">
              <div className="col-6 d-flex" style={{justifyContent:'start'}}>
                <BiArrowBack className="mt-1" style={{marginBottom:'10px',cursor:'pointer'}} size = {23} onClick={()=>{  searchParams.get("from") !== null && searchParams.get("from") === "kpi" ? navigate("/Kpi") : navigate("/FormList"); }}/>
              </div>
              {searchParams.get("formSubmissionID") !== null ?
                <>
                  {memberSubmissions.map((form) => {
                    if(form.SubmissionID === searchParams.get("formSubmissionID"))
                    {
                    return (
                      <>
                        {memberFormTemplates.map((item, index) => {

                          if (item.FormID === form.FormID) {
                            {/* console.log(item) */}
                            return (
                              <>
                                <div className="card mt-2" style={{ border: 'none', boxShadow: '5px 5px 5px -2px rgba(0,0,0,0.1)', background: 'rgb(95, 94, 175,0.1)' }}>
                                  <div className="card-body d-flex flex-column" >
                                    <span className="font-m">{item.FormName}</span>
                                    <span className="font-xxs">{item.FormDescription}</span>
                                    {form.SubmissionStatus === "2" || form.SubmissionStatus === "3" ? 
                                    <span className="mt-2 font-xxs" style={{color:Colors.redButton}}>Reason Rejected : {form.ReasonRejected}</span>
                                  : <></>}
                                  </div>
                                  <div style={{color:'#f54d4d', marginLeft:'10px', marginTop:'10px'}}>
                                    {answersTotallyEmpty ? <span>Please answer at least one question or fill up all the required field to submit the form !</span> 
                                    : isRequiredEmpty === false ?<span className="" > * Make sure to fill up all the required field ! </span> :<></>
                                    }
                                  </div>
                                </div>
                                <div className="px-2 py-1" style={{ overflow: 'scroll', height: "80%",display:'flex', flexDirection:'column',gap:'15px' }}>
                                  {item.FormData.map((data, questionIndex) => {
                                    return (
                                      <>
                                        <div className="card" style={{ border: 'none', boxShadow: '2px 2px 4px 1px rgba(0,0,0,0.1)' }}>
                                          <div className="card-body submission-body d-flex flex-column">
                                            <span className="font-s">{data.questionName}{data.requiredInput === true ? <><span className="font-l" style={{color :"#f54d4d"}}>*</span></> : <></>}</span>
                                            {data.option === undefined
                                              ?
                                              <>
                                                {data.questionType === "Multiline" ?
                                                  <>
                                                    <textarea name={data.questionType} style={{ border: 'none', borderBottom: '1px solid' }} value={answersArray.length !== 0 ? answersArray[questionIndex].answer : ""} placeholder={`${data.questionName} :`} onChange={(e) => { handleAnswerInput(e, questionIndex) }} ></textarea>
                                                  </>
                                                  :
                                                  <>
                                                    {data.questionType === "Date Range" ? 
                                                    <>
                                                    <div className="mt-2" style={{width:'100%'}}>
                                                      <span className="mx-1 font-xxs" style={{opacity:'0.8'}}>Start Date :</span>
                                                      <input type="date" value={answersArray.length !== 0 ? answersArray[questionIndex].answer.start : ""} name={data.questionType} placeholder="DateRange1" className="mx-1 font-xs" style={{marginBottom:'5px',border: 'none', width: '100%', background: 'none',borderBottom: '1px solid',marginBottom:'10px'}} onChange={(e) => { handleAnswerInput(e, questionIndex) }} />

                                                      <span className="mx-1 font-xxs" style={{opacity:'0.8'}}>End Date :</span>
                                                      <input type="date" value={answersArray.length !== 0 ? answersArray[questionIndex].answer.end : ""} name={data.questionType} placeholder="DateRange2" className="mx-1 font-xs" style={{marginBottom:'5px',border: 'none', width: '100%', background: 'none',borderBottom: '1px solid'}} onChange={(e) => { handleAnswerInput(e, questionIndex) }} />
                                                    </div>
                                                    </>
                                                    :
                                                    <>
                                                    <div className="mt-2" style={{ borderBottom: '1px solid' }}>
                                                      <input className="py-1" name={data.questionType} type={data.questionType === "ShortText" ? "text" : data.questionType === "Date" ? "date" : data.questionType === "Time" ? "time" : data.questionType === "Phone" ? "phone" : data.questionType === "Email" ? "email" : data.questionType === "Decimal" ? "number" :data.questionType === "Number" ? "number" : "text"} value={answersArray.length !== 0 ? answersArray[questionIndex].answer : ""} placeholder={`${data.questionName} :`} onChange={(e) => { handleAnswerInput(e, questionIndex) }} style={{ border: 'none', width: '100%', background: 'none' }} />
                                                    </div>
                                                    {data.questionType === "Email" ?
                                                      <>
                                                      {!isEmailFormat ? <><span style={{color:Colors.redButton}}>Make sure your email format is correct ! e.g. xxxx@domain.com</span></> : <></>}
                                                      </>
                                                      :
                                                      <></>}
                                                      {data.questionType === "Phone" ?
                                                      <>
                                                      {!isPhoneFormat ? <><span style={{color:Colors.redButton}}>Make sure your phone format is correct ! e.g. 016 411 2345/011 2222 4444</span></> : <></>}
                                                      </>
                                                      :
                                                      <></>}
                                                    </>
                                                    }
                                                    </>
                                                
                                                }
                                              </>
                                              :
                                              <>
                                                {data.questionType === "Multiple Choice" ?
                                                  <>
                                                    <label className="mt-2" >
                                                      {data.option.map((options, optionIndex) => {
                                                        return (
                                                          <>
                                                            <div className="d-flex" style={{ alignItems: 'center', textAlign: 'center' }}>
                                                              <input className="py-1" type="radio" value={options} style={{ width: '13px', height: '13px' }} checked={radioArray.length !== 0 ? radioArray[questionIndex][optionIndex] : ""} onChange={(e) => { handleOptionAnswerInput(e, questionIndex, optionIndex) }} />
                                                              <span className="mx-1 font-xs">{options}</span>
                                                            </div>
                                                          </>

                                                        );

                                                      })}
                                                    </label>
                                                  </>
                                                  :
                                                  data.questionType === "Checkboxes" ?
                                                    <>
                                                      <div className="mt-2">
                                                        {data.option.map((options, optionIndex) => {
                                                          return (
                                                            <label style={{ display: 'flex', alignItems: 'center' }}>
                                                              <input type='checkbox' style={{ width: '15px', height: '15px' }} value={options} checked={checkboxArray.length !== 0 ? checkboxArray[questionIndex][optionIndex] : ""} className='col-md-3' onChange={(e) => { handleOptionAnswerInput(e, questionIndex, optionIndex) }} />
                                                              <span className="checkmark" style={{ marginRight: '10px' }}></span>
                                                              <span className="font-xs" style={{ marginRight: '10px' }}>{options}</span>
                                                            </label>
                                                          );
                                                        })}
                                                      </div>
                                                    </>
                                                    :
                                                    data.questionType === "Dropdown" ?
                                                      <>
                                                        <div className="mt-2" >
                                                          <select className="form-select font-xs" name={data.questionType} style={{ minWidth: '130px', width: '20%' }} onChange={(e) => { handleAnswerInput(e, questionIndex) }}>
                                                            {answersArray.length !== 0 ? <>
                                                              <option className="" selected value={answersArray.length !== 0 ? answersArray[questionIndex].answer : ""}>{answersArray.length !== 0 ? answersArray[questionIndex].answer : ""}</option>
                                                            </> : <></>}
                                                            {data.option.map(options => {

                                                              if (options !== answersArray[questionIndex]?.answer) {
                                                                return (
                                                                  <>
                                                                    <option className="" value={options}>{options}</option>

                                                                  </>

                                                                );
                                                              }

                                                            })}
                                                          </select>

                                                        </div>
                                                      </>
                                                      :
                                                      data.questionType === "Member" ?
                                                        <>
                                                          <div className="mt-2" >
                                                            <select className="form-select font-xs" name={data.questionType} style={{ minWidth: '130px', width: '20%' }} onChange={(e) => { handleAnswerInput(e, questionIndex) }}>
                                                              {answersArray.length !== 0 ? <>
                                                                <option className="" selected value={answersArray.length !== 0 ? answersArray[questionIndex].answer : ""}>{answersArray.length !== 0 ?
                                                                  answersArray[questionIndex].answer
                                                                : ""}</option>
                                                              </> : <></>}
                                                                  {currUserSA.map((item,index)=>{
                                                                    return(
                                                                      <option className="" value={item.displayName}>{item.displayName}</option>
                                                                    );
                                                                  })}
                                                            </select>
                                                              
                                                          </div>
                                                        </>
                                                        :
                                                        data.questionType === "Client" ?
                                                          <>
                                                            <div className="mt-2" >
                                                            <C_Button
                                                                buttonText={answersArray[questionIndex]?.answer !== "" ? clientSelectedList?.find(item => item?.ClientID === answersArray[questionIndex]?.answer)?.RowValue : "Select Your Client"}
                                                                width={window.innerWidth > 1500 ? "170px" : "150px"}
                                                                onClick={()=>{

                                                                  if(availableClientsGroup && availableClientsGroup?.length > 0){

                                                                    if(answersArray[questionIndex]?.answer !== ""){

                                                                      const selectedClient = clientSelectedList?.find(item => item?.ClientID === answersArray[questionIndex]?.answer);
                                                                      setSearchText(selectedClient?.RowValue); 
                                                                      setCurrClient(selectedClient?.ClientID);
                                                                      dispatch(setFormContext({
                                                                        selectedGroupValue: selectedClient
                                                                      }))
                                                                      // dispatchCurrGroup({type: 'SELECT_GROUP', payload: selectedClient })
                                                                      dispatch(retrieveClientSelector({ TenantID:tenant,CompanyID: companyID, CurrPage: 0, SearchText: selectedClient?.RowValue, CurrGroup: selectedClient?.GroupID, isSA: currSa }))
                                                                      dispatch(retrieveClientSelectorCount({ TenantID:tenant,CompanyID: companyID, SearchText: selectedClient?.RowValue, CurrGroup: selectedClient?.GroupID, isSA: currSa }))

                                                                    }else{

                                                                      setSearchText("");
                                                                      setCurrClient("");
                                                                      dispatch(setFormContext({
                                                                        selectedGroupValue: availableClientsGroup[0]
                                                                      }))
                                                                      // dispatchCurrGroup({type: 'SELECT_GROUP', payload: availableClientsGroup[0] })
                                                                      dispatch(retrieveClientSelector({ TenantID:tenant,CompanyID: companyID, CurrPage: 0, SearchText: "", CurrGroup: availableClientsGroup[0]?.GroupID, isSA: currSa }))
                                                                      dispatch(retrieveClientSelectorCount({ TenantID:tenant,CompanyID: companyID, SearchText: "", CurrGroup: availableClientsGroup[0]?.GroupID, isSA: currSa }))

                                                                    }
                                                                    }else{
                                                                      setSearchText(""); 
                                                                    }

                                                                    showModalFunc(); 
                                                                    setCurrQuestionIndex(questionIndex);
                                                                }}
                                                              />
                                                            </div>
                                                          </>
                                                      :
                                                      <>
                                                      </>
                                                }
                                              </>
                                            }
                                          </div>
                                        </div>
                                      </>
                                    )
                                  })}
                                </div>
                              </>
                            )
                          }
                        })}
                      </>
                    )
                  }
                  }//end if
                  )}
                </>
                :
                <>
                  {memberFormTemplates && memberFormTemplates.map((item, index) => {
                    if (item.FormID === searchParams.get("formID")) {
                      return (
                        <>
                          <div className="card mt-2" style={{ border: 'none', boxShadow: '5px 5px 5px -2px rgba(0,0,0,0.1)', background: 'rgb(95, 94, 175,0.1)' }}>
                            <div className="card-body d-flex flex-column" >
                              <span className="font-m">{item.FormName}</span>
                              <span className="font-s">{item.FormDescription}</span>
                            </div>
                          </div>
                          <div style={{color:'#f54d4d', marginLeft:'10px', marginTop:'10px'}}>
                          {answersTotallyEmpty ? <span>Please answer at least one question or fill up all the required field to submit the form !</span> 
                            : isRequiredEmpty === false ?<span className="" > * Make sure to fill up all the required field ! </span> :<></>
                          }
                          </div>
                          <div className="px-2 py-1" style={{ overflow: 'scroll', height: "80%",height: "80%",display:'flex', flexDirection:'column',gap:'15px' }}>
                            {item.FormData.map((data, questionIndex) => {
                              return (
                                <>
                                  <div className="card" style={{ border: 'none', boxShadow: '2px 2px 4px 1px rgba(0,0,0,0.1)' }}>
                                    <div className="card-body submission-body d-flex flex-column">
                                      <div className="d-flex">
                                        <div className="col" >
                                          <span className="font-s">{data.questionName}</span>
                                          {data.requiredInput === true ? <><span className="font-l" style={{color :"#f54d4d"}}>*</span></> : <></>}
                                        </div>
                                      </div>
                                      
                                      {data.option === undefined
                                        ?
                                        <>
                                          {data.questionType === "Multiline" ?
                                            <>
                                              <textarea name={data.questionType} style={{ border: 'none', borderBottom: '1px solid' }} value={answersArray.answer} placeholder={`${data.questionName} :`} onChange={(e) => { handleAnswerInput(e, questionIndex) }} ></textarea>
                                            </>
                                          :
                                          <>
                                            {data.questionType === "Date Range" ? 
                                            <>
                                            <div className="mt-2" style={{width:'100%'}}>
                                              <span className="mx-1 font-xxs" style={{opacity:'0.8'}}>Start Date :</span>
                                              <input type="date" name={data.questionType} className="mx-1 font-xs" style={{marginBottom:'5px',border: 'none', width: '100%', background: 'none',borderBottom: '1px solid',marginBottom:'10px'}} onChange={(e) => { handleAnswerInput(e, questionIndex) }} placeholder="DateRange1" />
                                              <span className="mx-1 font-xxs" style={{opacity:'0.8'}}>End Date :</span>
                                              <input type="date" name={data.questionType} className="mx-1 font-xs" style={{marginBottom:'5px',width:'100%',border: 'none', width: '100%', background: 'none',borderBottom: '1px solid'}} onChange={(e) => { handleAnswerInput(e, questionIndex) }} placeholder="DateRange2" />
                                            </div>
                                            </>
                                            :
                                            <>
                                            <div className="mt-2" style={{ borderBottom: '1px solid' }}>
                                              <input className="py-1" name={data.questionType} type={data.questionType === "ShortText" ? "text" : data.questionType === "Date" ? "date" : data.questionType === "Time" ? "time" : data.questionType === "Phone" ? "phone" : data.questionType === "Email" ? "email" : data.questionType === "Decimal" ? "number" : data.questionType === "Number" ? "number" : "text"} value={answersArray.length !== 0 ? answersArray[questionIndex].answer : ""} placeholder={`${data.questionName} :`} onChange={(e) => { handleAnswerInput(e, questionIndex) }} style={{ border: 'none', width: '100%', background: 'none' }} />
                                            </div>
                                            {data.questionType === "Email" ?
                                              <>
                                              {!isEmailFormat ? <><span style={{color:Colors.redButton}}>Make sure your email format is correct ! e.g. xxxx@domain.com</span></> : <></>}
                                              </>
                                              :
                                              <></>}
                                              {data.questionType === "Phone" ?
                                              <>
                                              {!isPhoneFormat ? <><span style={{color:Colors.redButton}}>Make sure your phone format is correct ! e.g. 016 411 2345/011 2222 4444</span></> : <></>}
                                              </>
                                              :
                                              <></>}
                                            </>
                                            }
                                            </>
                                          }
                                        </>
                                        :
                                        <>
                                          {data.questionType === "Multiple Choice" ?
                                            <>
                                              <label className="mt-2" >
                                                {data.option.map((options, optionIndex) => {
                                                  return (
                                                      <div key={optionIndex} className="d-flex" style={{ alignItems: 'center', textAlign: 'center' }}>
                                                        <input className="py-1" type="radio" value={options} style={{ width: '13px', height: '13px' }} checked={radioArray.length !== 0 ? radioArray[questionIndex][optionIndex] : ""} onChange={(e) => { handleOptionAnswerInput(e, questionIndex, optionIndex) }} />
                                                        <span className="mx-1 font-xs">{options}</span>
                                                      </div>

                                                  );

                                                })}
                                              </label>
                                            </>
                                            :
                                            data.questionType === "Checkboxes" ?
                                              <>
                                                <div className="mt-2">
                                                  {data.option.map((options, optionIndex) => {
                                                    return (
                                                      <label key={optionIndex} style={{ display: 'flex', alignItems: 'center' }}>
                                                        <input type='checkbox' style={{ width: '15px', height: '15px' }} value={options} checked={checkboxArray.length !== 0 ? checkboxArray[questionIndex][optionIndex] : ""} className='col-md-3' onChange={(e) => { handleOptionAnswerInput(e, questionIndex, optionIndex) }} />
                                                        <span className="checkmark" style={{ marginRight: '10px' }}></span>
                                                        <span className="font-xs" style={{ marginRight: '10px' }}>{options}</span>
                                                      </label>
                                                    );
                                                  })}
                                                </div>
                                              </>
                                              :
                                              data.questionType === "Dropdown" ?
                                                <>
                                                  <div className="mt-2" >
                                                    <select name={data.questionType} className="form-select font-xs" style={{ minWidth: '130px', width: '20%' }} onChange={(e) => { handleAnswerInput(e, questionIndex) }}>
                                                      <option className="" value={""}>{`Your Answer`}</option>
                                                      {data.option.map((options, optionIndex) => {
                                                        return (
                                                          <option key={optionIndex} className="" value={options}>{options}</option>
                                                        );
                                                      })}
                                                    </select>
                                                  </div>
                                                </>
                                                :
                                                data.questionType === "Client" ?
                                                  <>
                                                    <div className="mt-2" >
                                                      <div className="d-flex">
                                                        <C_Button
                                                          buttonText={answersArray[questionIndex]?.answer !== "" ? clientSelectedList?.find(item => item?.ClientID === answersArray[questionIndex]?.answer)?.RowValue : "Select Your Client"}
                                                          width={window.innerWidth > 1500 ? "170px" : "150px"}
                                                          onClick={()=>{

                                                            if(availableClientsGroup && availableClientsGroup?.length > 0){

                                                              if(answersArray[questionIndex]?.answer !== ""){

                                                                const selectedClient = clientSelectedList?.find(item => item?.ClientID === answersArray[questionIndex]?.answer);
                                                                setSearchText(selectedClient?.RowValue); 
                                                                setCurrClient(selectedClient?.ClientID);
                                                                dispatch(setFormContext({
                                                                  selectedGroupValue: selectedClient
                                                                }))
                                                                // dispatchCurrGroup({type: 'SELECT_GROUP', payload: selectedClient })
                                                                dispatch(retrieveClientSelector({ TenantID:tenant,CompanyID: companyID, CurrPage: 0, SearchText: selectedClient?.RowValue, CurrGroup: selectedClient?.GroupID, isSA: currSa }))
                                                                dispatch(retrieveClientSelectorCount({ TenantID:tenant,CompanyID: companyID, SearchText: selectedClient?.RowValue, CurrGroup: selectedClient?.GroupID, isSA: currSa }))

                                                              }else{

                                                                setSearchText("");
                                                                setCurrClient("");
                                                                dispatch(setFormContext({
                                                                  selectedGroupValue: availableClientsGroup[0]
                                                                }))
                                                                // dispatchCurrGroup({type: 'SELECT_GROUP', payload: availableClientsGroup[0] })
                                                                dispatch(retrieveClientSelector({ TenantID:tenant,CompanyID: companyID, CurrPage: 0, SearchText: "", CurrGroup: availableClientsGroup[0]?.GroupID, isSA: currSa }))
                                                                dispatch(retrieveClientSelectorCount({ TenantID:tenant,CompanyID: companyID, SearchText: "", CurrGroup: availableClientsGroup[0]?.GroupID, isSA: currSa }))

                                                              }
                                                            }else{
                                                              setSearchText(""); 
                                                            }
                                                            
                                                            showModalFunc(); 
                                                            setCurrQuestionIndex(questionIndex);
                                                          }}
                                                        />
                                                      </div> 
                                                    </div>
                                                  </>
                                                :
                                                data.questionType === "Member" ?
                                                  <>
                                                    <div className="mt-2" >
                                                      <select  defaultValue={""} name={data.questionType} className="form-select font-xs" style={{ minWidth: '130px', width: '20%' }} onChange={(e) => { handleAnswerInput(e, questionIndex) }}>
                                                        <option className="" value={""}>{`Your Answer`}</option>
                                                          {currUserSA.map((item,index)=>{
                                                              return(
                                                                <option key={index} className="" value={item.displayName}>{item.displayName}</option>
                                                              );
                                                          })}
                                                      </select>
                                                    </div>
                                                  </>
                                                :
                                                <>
                                                </>
                                          }
                                        </>
                                      }
                                    </div>
                                  </div>
                                </>
                              )
                            })}
                          </div>
                        </>
                      )
                    }
                  })}
                </>
              }
            </div>
            
            {searchParams.get("formSubmissionID") !== null ? 
              <div className="mt-3 d-flex justify-content-end" style={{columnGap:'20px'}}>
                <C_DialogButton
                  onClick={()=>{  searchParams.get("from") !== null && searchParams.get("from") === "kpi" ? navigate("/Kpi") : navigate("/FormList"); }}
                  color={'#6d45b9'}
                  background={'white'}
                  borderColor={'#6d45b9'}
                  buttonText={"DISCARD"}
                />
                <C_DialogButton
                  onClick={()=>{editForm()}}
                  color={"white"}
                  background={'#6d45b9'}
                  borderColor={'#6d45b9'}
                  buttonText={"UPDATE"}
                />
              </div>
            :
              <div className="mt-3 d-flex justify-content-end" style={{columnGap:'20px'}}>
                  <C_DialogButton
                    onClick={()=>{ searchParams.get("from") !== null && searchParams.get("from") === "kpi" ? navigate("/Kpi") : navigate("/FormList"); }}
                    color={'#3eb986'}
                    background={'white'}
                    borderColor={'#3eb986'}
                    buttonText={"DISCARD"}
                  />
                  <C_DialogButton
                    onClick={()=>{submitForm()}}
                    color={"white"}
                    background={'#3eb986'}
                    borderColor={'#3eb986'}
                    buttonText={"SUBMIT"}
                  />
              </div>
            }
          </div>
          {/* Modal */}
          <div className="modal fade" tabIndex="-1" ref={modalRef} style={{}}>
                <div className="modal-dialog modal-dialog-centered" style={{}}>
                    <div className="modal-content px-3" style={{ minHeight: "556px", overflowY: "scroll" }}>
                        <div className="modal-header modalHeader">
                            <div className="title">
                                <CgUserList size={16}/>
                                <span>Client List</span>
                                <select defaultValue={""} value={selectedGroupValue?.GroupID} className="form-select font-xs" onChange={(e) => {
                                  selectedGroup(e.target.value)
                                }}
                                style={{
                                    alignItems: 'center', 
                                    backgroundColor: Colors.standardBackground, 
                                    color: '#000000', 
                                    width: '180px', 
                                    marginLeft: '10px', 
                                    borderRadius: '8px', 
                                    height: '36px' }}>
                                    <option value="" disabled>Select a group</option>
                                    {Array.isArray(availableClientsGroup) && availableClientsGroup?.map((item, index) => {
                                        return (
                                        <>
                                            <option key={index} value={item?.GroupID}>{item?.GroupName}</option>
                                        </>
                                        );
                                    })}
                                </select>
                            </div>
                            <AiOutlineClose cursor='pointer' size={16} onClick={() => { hideModalFunc(); }}/>
                        </div>
                        <div className="modal-body d-flex row" style={{paddingTop: '5px'}}>
                          <div className="clientListSearchContainer">
                            <C_Search
                                placeholder="Search"
                                onChange={(e) => {
                                    setSearchText(e.target.value);
                                }}
                                height={"35px"}
                                containerWidth={"80%"}
                                padding={"10px"}
                                value={searchText}
                                searchText={searchText}
                                closeFunction={() => {
                                    setSearchText("");
                                }}
                            />
                            
                              <C_Button
                                background={Colors.generalButtonColor}
                                width={"120px"}
                                height={"30px"}
                                buttonText={"Search"}
                                justify={"center"}
                                onClick={() => {
                                  handleSearchQuery()
                                }}
                                color={"#FFF"}
                                textColor={"#FFFFFF"}
                                border={"0px"}
                                gap={"10px"}
                                icon={<AiOutlineSearch size={16} />}
                              />
                              <C_Button
                                background={"#FFF"}
                                width={"120px"}
                                buttonText={"Reset"}
                                justify={"center"}
                                onClick={() => {
                                  handleClearQuery()
                                }}
                                color={"#000"}
                                height={"30px"}
                                border={"1px solid #000"}
                                gap={"10px"}
                                icon={<BiReset size={15} />}
                              />
                           
                            
                              
                           
                          </div>
                            <div className={`loadingTable ${loadData && 'showLoadingTable'}`} style={{height: "300px"}}>
                              <img src={require('../../assets/retrieveDataGif.gif')} alt=''/>
                              <span>Retrieving data...</span>
                            </div>
                            <div className="clientListContainer" style={{ display: loadData ? 'none' : 'flex'  }}>
                                {client_selector?.map(item => {
                                    let isSelected = false;
                                    if(currClient === item.ClientID)
                                    {
                                      isSelected = true
                                    }
                                   
                                    return(
                                        <div
                                            className='clientContainer'
                                            style={isSelected ? {background:'#e6e6e6' } : {background:'#fff'}}
                                            onClick={() => { 
                                                if(isSelected){
                                                  setCurrClient("");
                                                }else{
                                                  setCurrClient(item.ClientID);
                                                }
                                        }}>
                                            <span>{item.RowValue}</span>
                                            {isSelected ? <AiFillCheckCircle size={18} color="green"/> : <AiOutlineCheckCircle size={18}/>}
                                        </div>
                                    )
                                })}
                            </div>
                            <div>
                            <ReactPaginate
                              containerClassName="paginationStyle"
                              activeClassName="activePage"
                              pageClassName="paginationElementStyle"
                              breakLabel="..."
                              nextLabel={<MdNavigateNext size={20}/>}
                              onPageChange={(page) => { onHandlePageChange(page.selected) }}
                              pageRangeDisplayed={5}
                              //pageCount={10}
                              pageCount={(client_selector_count && client_selector_count.length > 0) && Math.ceil(client_selector_count[0].Total_Clients/500)}
                              previousLabel={<MdNavigateBefore size={20}/>}
                              renderOnZeroPageCount={null}
                              forcePage={currentPage}
                            />  
                            </div>
                            <div className="clientActionContainer">
                                  <C_DialogButton
                                    onClick={()=>{ hideModalFunc(); }}
                                    color={'#4287f5'}
                                    background={'white'}
                                    borderColor={'#4287f5'}
                                    buttonText={"DISCARD"}
                                  />
                                  <C_DialogButton
                                    onClick={()=>{
                                      hideModalFunc(); 
                                      handleAnswerInputClient();
                                    }}
                                    color={"white"}
                                    background={'#4287f5'}
                                    borderColor={'#4287f5'}
                                    buttonText={"CONFIRM"}
                                  />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </Fragment>
  );
}


export default FormSubmission;