import React, { useState, useEffect, useRef, Fragment } from 'react'
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Area,
  AreaChart,
} from 'recharts';
import { BiTargetLock } from 'react-icons/bi';
import { RiNumbersFill } from 'react-icons/ri';
import { MdOutlineCompareArrows } from 'react-icons/md';
import { AiFillCheckCircle } from 'react-icons/ai';
import moment from 'moment';
import { easeQuadInOut } from 'd3-ease';
import AnimatedProgressProvider from '../../../components/AnimatedProgressProvider';
import { selectKpiContext } from '../../../stores/slices/kpiSlices';
import { selectFormTemplate } from '../../../stores/slices/formTemplateSlices';

function PersonalPerformance(props) {

    const [graphWidth, setGraphWidth] = useState(0);
    const [graphHeight, setGraphHeight] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const graphRef = useRef(null);

    const {
        analyticsGrouping,
        analyticsData,
    } = selectKpiContext();

    const [totalContribution, setTotalContribution] = useState(0);
    const [todaysContribution, setTodaysContribution] = useState(0);
    const [amountToHit, setAmountToHit] = useState(0);
    const [graphData, setGraphData] = useState([]);
    const [metricName, setMetricName] = useState("");

    const sourceData = selectFormTemplate();

    useEffect(() => {

        if(analyticsData){
            if(analyticsData?.item?.measuredBy !== "Submission"){
                const startDate = new Date(analyticsData?.item?.startDate);
                const filteredSubmissions = analyticsData?.allSubmission?.filter(item => item.UserID === analyticsData?.uid);
                const result = [...filteredSubmissions]?.sort((a, b) => { return moment(a.ApprovalDate) - moment(b.ApprovalDate) }).reduce((acc, cur) => {
                    const approvalDate = new Date(cur.ApprovalDate.split(' ')[0]);
                    let category;
                    switch (analyticsGrouping) {
                        case "Days":
                            category = approvalDate.toLocaleDateString();
                            break;
                        case "Weeks":
                            const weekNumber = Math.ceil((((approvalDate - startDate) / 86400000) + startDate.getDay() + 1) / 7);
                            category = `Week ${weekNumber}`;
                            break;
                        case "Months":
                            category = approvalDate.toLocaleString('default', { month: 'long' });
                            break;
                        case "Years":
                            category = approvalDate.getFullYear();
                            break;
                        default:
                            category = approvalDate.toLocaleDateString();
                    }
                    if (!acc[category]) {
                        acc[category] = { Category: category, ["Daily Amount"]: 0 };
                    }
                    const answer = cur.SubmissionData.find(data => data.questionID === analyticsData?.item?.metricValue).answer;
                    acc[category]["Daily Amount"] += parseInt(answer);

                    return acc;
                }, {});

                const summaryResult = [...filteredSubmissions]?.sort((a, b) => { return moment(a.ApprovalDate) - moment(b.ApprovalDate) }).reduce((acc, cur) => {
                  const approvalDate = new Date(cur.ApprovalDate.split(' ')[0]);
                  let category;
                  category = approvalDate.toLocaleDateString();
                  if (!acc[category]) {
                      acc[category] = { Category: category, ["Daily Amount"]: 0 };
                  }
                  const answer = cur.SubmissionData.find(data => data.questionID === analyticsData?.item?.metricValue).answer;
                  acc[category]["Daily Amount"] += parseInt(answer);

                  return acc;
              }, {});
                
                const tempGraphData = Object.values(result);
                const tempSummary = Object.values(summaryResult);

                let sum = 0;
                for (const item of tempGraphData) {
                  item["Total Amount"] = sum += item["Daily Amount"];
              }

                tempGraphData.forEach(item => {
                  item["Target"] =  parseInt(analyticsData?.item?.target);
                });

                const formData = sourceData.find(sd => sd.FormID === analyticsData?.item?.sourceOfData).FormData;
                const tempMetricName = formData.find(fd => fd.questionID === analyticsData?.item?.metricValue);
  
                setGraphData(tempGraphData);
                setMetricName(tempMetricName);

                const totalAmount = tempSummary.reduce((sum, item) => sum + item["Daily Amount"], 0);
                setTotalContribution(totalAmount);

                const today = new Date().toLocaleDateString();

                const todayAmount = tempSummary.find(data => data.Category === today)?.["Daily Amount"] || 0;

                setTodaysContribution(todayAmount);

                const difference = parseInt(analyticsData?.item?.target) - totalAmount;

                const amountToHit = difference > 0 ? difference : 0;

                setAmountToHit(amountToHit);
                
            }else{
              const startDate = new Date(analyticsData?.item?.startDate);
              const filteredSubmissions = analyticsData?.allSubmission?.filter(item => item.UserID === analyticsData?.uid);
              const result = [...filteredSubmissions]?.sort((a, b) => { return moment(a.ApprovalDate) - moment(b.ApprovalDate) }).reduce((acc, cur) => {
                  const approvalDate = new Date(cur.ApprovalDate.split(' ')[0]);
                  let category;
                  switch (analyticsGrouping) {
                      case "Days":
                          category = approvalDate.toLocaleDateString();
                          break;
                      case "Weeks":
                          const weekNumber = Math.ceil((((approvalDate - startDate) / 86400000) + startDate.getDay() + 1) / 7);
                          category = `Week ${weekNumber}`;
                          break;
                      case "Months":
                          category = approvalDate.toLocaleString('default', { month: 'long' });
                          break;
                      case "Years":
                          category = approvalDate.getFullYear();
                          break;
                      default:
                          category = approvalDate.toLocaleDateString();
                  }
                  if (!acc[category]) {
                      acc[category] = { Category: category, ["Daily Amount"]: 0 };
                  }
                  acc[category]["Daily Amount"] += 1;
                  return acc;
              }, {});

              const summaryResult = [...filteredSubmissions]?.sort((a, b) => { return moment(a.ApprovalDate) - moment(b.ApprovalDate) }).reduce((acc, cur) => {
                const approvalDate = new Date(cur.ApprovalDate.split(' ')[0]);
                let category;
                category = approvalDate.toLocaleDateString();
                if (!acc[category]) {
                    acc[category] = { Category: category, ["Daily Amount"]: 0 };
                }
                acc[category]["Daily Amount"] += 1;
                return acc;
              }, {});
                
              const tempGraphData = Object.values(result);
              const tempSummary = Object.values(summaryResult);

              let sum = 0;
              for (const item of tempGraphData) {
                  item["Total Amount"] = sum += item["Daily Amount"];
              }

              tempGraphData.forEach(item => {
                item["Target"] =  parseInt(analyticsData?.item?.target);
              });

              const formData = sourceData.find(sd => sd.FormID === analyticsData?.item?.sourceOfData).FormData;
              const tempMetricName = formData.find(fd => fd.questionID === analyticsData?.item?.metricValue);

              setGraphData(tempGraphData);
              setMetricName(tempMetricName);

              const totalAmount = tempSummary.reduce((sum, item) => sum + item["Daily Amount"], 0);
              setTotalContribution(totalAmount);

              const today = new Date().toLocaleDateString();
              const todayAmount = tempSummary.find(data => data.Category === today)?.["Daily Amount"] || 0;
              const previousAmount = tempSummary
                .filter(data => data.Category !== today)
                .reduce((acc, cur) => acc + cur["Daily Amount"], 0);

              setTodaysContribution(todayAmount);

              const difference = parseInt(analyticsData?.item?.target) - totalAmount;

              const amountToHit = difference > 0 ? difference : 0;

              setAmountToHit(amountToHit);
            }
        }

    },[analyticsData, analyticsGrouping])
    
    useEffect(() => {
      setIsLoading(true);
      setTimeout(() => {
        setGraphWidth(graphRef.current?.clientWidth * 0.95);
        setGraphHeight(graphRef.current?.clientWidth * 0.5);
        setIsLoading(false);
      },1500)
    },[analyticsData, analyticsGrouping, graphData, metricName]);

  const renderYAxis = () => {

    if(analyticsData){
        const y = <Area
          type={'monotone'}
          dataKey={"Total Amount"}
          fillOpacity={1} 
          stroke="#82ca9d"
          strokeWidth={1}
          fill="url(#colorTotal)"
        />

        return y
    }
  };

  const renderYAxisDaily = () => {

    if(analyticsData){
      const y = <Area 
        type={'monotone'}
        dataKey={'Daily Amount'}
        fillOpacity={1}
        stroke="#8884d8"
        strokeWidth={1}
        fill="url(#colorDaily)"
      />
      return y
    }
  };

  const renderYAxisTarget = () => {

    if(analyticsData){
      const y = <Area 
        type={'monotone'}
        dataKey={'Target'}
        fillOpacity={0}
        strokeDasharray={5}
        stroke={"#242188"}
        strokeWidth={1}
        fill={"#000"}
      />
    
      return y
    }
  };

  const renderXAxis = () => {

    const x = <XAxis
      dataKey={"Category"} 
      angle={0} 
      axisLine={false}
      tickSize={8} 
      tickLine={false}
      minTickGap={10} 
      letterSpacing={1}
      padding={{ left: 0, right: 0 }}
    />
    return x

  };

  return (
    <>
    
    <div className={`loadingContainer ${!isLoading && 'hideLoadingContainer'}`}>
      <div className="loading-spinner">
      </div>
    </div>

    <div className={`graphContainer ${!isLoading && 'showGraphContainer'}`}>
      <div className='linechartContainer'>
        <div className='linechartSubContainer' ref={graphRef}>
          <AreaChart
            width={graphWidth}
            height={graphHeight}
            data={graphData}
          >
            <CartesianGrid strokeDasharray="1 10" />
            <YAxis
              tickLine={false}
              axisLine={false}
              letterSpacing={1.5}
                // label={{ value: graphDetail?.Label ? graphDetail?.Label : "", angle: -90, position: 'insideLeft' }}
            />
            <Tooltip/>
            <Legend
              verticalAlign='top'
              align='center'
              height={50}
              iconSize={14}
              iconType="diamond"
            />
            <defs>
              <linearGradient id="colorDaily" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#0400ff" stopOpacity={0.8}/>
                <stop offset="95%" stopColor="#0400ff" stopOpacity={0}/>
              </linearGradient>
              <linearGradient id="colorTotal" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#00ff40" stopOpacity={0.8}/>
                <stop offset="95%" stopColor="#00ff40" stopOpacity={0}/>
              </linearGradient>
            </defs>
            {renderXAxis()}
            {renderYAxisTarget()}
            {renderYAxisDaily()}
            {renderYAxis()}
          </AreaChart>
        </div>
        <div className='summaryContainer'>
          <div className='totalContributionContainer'>
            <div className='iconContainer'>
              <RiNumbersFill className='icon' size={20}/>
            </div>
            <div className='detailsContainer'>
              <div className='title'>
                <span>Total Amount</span>
              </div>
              <div className='value'>
                <AnimatedProgressProvider
                        valueStart={0}
                        valueEnd={totalContribution}
                        duration={1.5}
                        easingFunction={easeQuadInOut}
                    >
                        {(value) => {
                            const roundedValue = Math.round(value);
                            return (
                              <span>{roundedValue}</span>
                            );
                        }}
                </AnimatedProgressProvider>
              </div>
            </div>
          </div>
          <div className='comparisonProgressContainer'>
            <div className='iconContainer'>
              <MdOutlineCompareArrows className='icon' size={20}/>
            </div>
            <div className='detailsContainer'>
              <div className='title'>
                <span>Today's<br/>Performance</span>
              </div>
              <div className='value'>
                <AnimatedProgressProvider
                        valueStart={0}
                        valueEnd={todaysContribution}
                        duration={1.5}
                        easingFunction={easeQuadInOut}
                    >
                        {(value) => {
                            const roundedValue = Math.round(value);
                            return (
                              <span>{roundedValue}</span>
                            );
                        }}
                </AnimatedProgressProvider>
              </div>
            </div>
          </div>
          <div className='contributionProgressContainer'>
            <div className='iconContainer'>
              <BiTargetLock className='icon' size={20}/>
            </div>
            <div className='detailsContainer'>
            <div className='title'>
                <span>Range to KPI</span>
              </div>
              <div className='value'>
                {amountToHit > 0 ?
                  <>
                    <AnimatedProgressProvider
                          valueStart={0}
                          valueEnd={amountToHit}
                          duration={1.5}
                          easingFunction={easeQuadInOut}
                      >
                          {(value) => {
                              const roundedValue = Math.round(value);
                              return (
                                <span>{roundedValue}</span>
                              );
                          }}
                    </AnimatedProgressProvider>
                  </>
                  :
                  <>
                  <div className='achievedContainer'>
                    <span className='achieved'>KPI ACHIEVED</span>
                    <AiFillCheckCircle color='#eff3ef' size={14}/>
                  </div>
                  </>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  )
};

export default PersonalPerformance;