import React from "react";
import { Chip } from "@mui/material";
import { convertSxToArray } from "../../util/mui/convertSxToArray";

export const ChipText = ({
  variant,
  label,
  status,
  onClick,
  onIconClick,
  sx = [],
  icon,
  // disabled,
  // key,
  // onDelete,
}) => {
  return (
    <Chip
      sx={[
        (typography) => ({
          ...typography.remark,
        }),
        ...convertSxToArray(sx),
      ]}
      size={"small"}
      variant={variant}
      label={label}
      onClick={onClick}
      // * onDelete is close icon's onClick function on the right hand side
      onDelete={onIconClick}
      // * if deleteIcon is null and onDelete != null,it will show default closeIcon
      deleteIcon={icon}
      color={status}
    />
  );
};
