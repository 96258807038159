import { call, delay, put, takeLatest } from "redux-saga/effects";
import { 
    createClientGroups, 
    createClientGroupsError, 
    createClientGroupsSuccess, 

    deleteClientGroups, 
    deleteClientGroupsError, 
    deleteClientGroupsSuccess, 

    retrieveClientGroups, 
    retrieveClientGroupsError, 
    retrieveClientGroupsSuccess,
    
    updateClientGroups, 
    updateClientGroupsError, 
    updateClientGroupsSuccess 
} from "../stores/slices/clientGroupSlices";

import { ApiRoute } from "../enums/ApiRoute";
import { API } from "../http-common";

function* createClientGroupsSaga({payload}) {
    try{
        const { data } = yield call(API.post, ApiRoute.clientGroup.createClientGroups, payload);
        yield put(createClientGroupsSuccess(data));
    } catch (error){
        yield put(createClientGroupsError(error));
        console.error(error);
    }
}

function* retrieveClientGroupsSaga({payload}) {
    try{
        const { data } = yield call(API.post, ApiRoute.clientGroup.retrieveAllClientGroups, payload);
        yield put(retrieveClientGroupsSuccess(data));
    } catch (error){
        yield put(retrieveClientGroupsError(error));
        console.error(error);
    }
}

function* updateClientGroupsSaga({payload}) {
    try{
        yield call(API.post, ApiRoute.clientGroup.updateClientGroups, payload);
        yield put(updateClientGroupsSuccess(payload));
    } catch (error){
        yield put(updateClientGroupsError(error));
        console.error(error);
    }
}

function* deleteClientGroupsSaga({payload}) {
    try{
        const { data } = yield call(API.post, ApiRoute.clientGroup.deleteClientGroups, payload);
        yield put(deleteClientGroupsSuccess(payload));
    } catch (error){
        yield put(deleteClientGroupsError(error));
        console.error(error);
    }
}

export function* clientGroupSagaWatcher(){
    yield takeLatest(createClientGroups.type, createClientGroupsSaga);
    yield takeLatest(updateClientGroups.type, updateClientGroupsSaga);
    yield takeLatest(deleteClientGroups.type, deleteClientGroupsSaga);
    yield takeLatest(retrieveClientGroups.type, retrieveClientGroupsSaga);
}