import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import C_Loading from "../../components/C_Loading";
import './UsersAndPermissions.scss'
import { v4 } from "uuid";
import C_Button from "../../components/C_Button";
import { AiFillPlusCircle, AiFillSave, AiOutlineCheck, AiOutlineClose, AiOutlineCopy, AiOutlineDelete } from "react-icons/ai";
import C_PopoutNoti from "../../components/C_PopoutNoti";
import { Modal } from "bootstrap";
import C_Search from "../../components/C_Search";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import permission from '../../enums/json/permission.json';
import { FaAnchor, FaCalendarAlt, FaComments, FaPoll, FaProjectDiagram, FaScroll, FaUsers } from "react-icons/fa";
import Switch from "react-switch";
import Colors from "../../constants/Colors";
import C_DialogButton from "../../components/C_DialogButton";

//slices
import { retrieveCurrUsage } from "../../stores/slices/usageSlices";
import { selectCurrUserContext } from "../../stores/slices/companySlices";
import { createRole, deleteRole, selectCurrRoles, selectRoles, updateRole } from "../../stores/slices/roleSlices";
import { updateCompanyAgentRole } from "../../stores/slices/companyAgentSlices";
import { selectCompanyID } from "../../stores/slices/companyIDSlices";
import { selectCurrSa, selectCurrTenant } from "../../stores/slices/persistRolesSlices";
import { selectUser } from "../../stores/slices/userAuthSlices";

function Permissions(props) {

    const roleManagementRef = useRef(null);
    const deleteModalRef = useRef();
    const permissions = permission.permissions

    const dispatch = useDispatch();
    const { currUsage, currUserInvitation, currTotalUsage } = selectCurrUserContext();
    
    const companyID = selectCompanyID();
    const tenant = selectCurrTenant();
    const currSa = selectCurrSa();
    const roles = selectRoles();
    const currRoles = selectCurrRoles();

    const [isLoading, setIsLoading] = useState(false);
    const [updateError, setUpdateError] = useState(false);
    const [notifyMessage, setNotifyMessage] = useState("");

    const [subSection, setSubSection] = useState("users");
    const [searchText, setSearchText] = useState("");
    const [loadingRoleDetails, setLoadingRoleDetails] = useState(false);
    const [isNewRole, setIsNewRole] = useState(false);
    const [selectedRole, setSelectedRole] = useState(null);

    const [companyRolesList, setCompanyRolesList] = useState([]);

    const [roleQuota, setRoleQuota] = useState(0);
    const [roleUsage, setRoleUsage] = useState(0);
    
    useEffect(() => {
        if(currUsage && currTotalUsage !== undefined && currTotalUsage && currUserInvitation){
            const filterQuota = currUsage?.find(item => item.SectionID === "s2")?.Quota;
            const filterUsage = currTotalUsage?.find(item => item.SectionID === "s2")?.Usage;
            setRoleQuota(filterQuota);
            setRoleUsage(filterUsage);
        }else{
          setRoleQuota(0);
          setRoleUsage(0);
        }
    },[currUsage, currTotalUsage, currUserInvitation]);

    async function refreshUsage(){
        dispatch(retrieveCurrUsage({TenantID:tenant,CompanyID:companyID}));
    };

    const colorPalette = [
      "#3498DB",
      "#2ECC71",
      "#1ABC9C",
      "#95A5A6",
      "#2C3E50",
      "#E82C0C",
      "#BF00FF",
      "#FFBF00",
      "#1E90FF",
      "#FF0080",
      "#2E8B57",
      "#FFA500",
      "#9400D3",
      "#FF00FF"
    ];

    const showDeleteModalFunc = () => {
      const modalEle = deleteModalRef.current;
      const bsModal = new Modal(modalEle, {
        backdrop: "static",
        keyboard: false,
      });
      bsModal.show();
    };
  
    const hideDeleteModalFunc = () => {
        const modalEle = deleteModalRef.current;
        const bsModal = Modal.getInstance(modalEle);
        bsModal.hide();
    };

    useEffect(() => {
      // setSelectedRole(null);
      if(Array.isArray(roles) && roles && roles.length > 0){
        setCompanyRolesList(roles);
      }
    },[roles]);

    useEffect(() => {
      function handleClickOutside(event) {
          if (roleManagementRef.current && !roleManagementRef.current.contains(event.target) && deleteModalRef.current && !deleteModalRef.current.contains(event.target)) {
              setSelectedRole(null);
          }
      }
    
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
    
      // Unbind the event listener on cleanup
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [roleManagementRef]);

    function notify(type){
        switch(type){
          case "create":
            setNotifyMessage("CREATED ROLE")
            break;
          case "update":
            setNotifyMessage("UPDATED ROLE")
            break;
          case "delete":
            setNotifyMessage("DELETED ROLE")
            break;
          case "copied":
            setNotifyMessage("COPIED TEXT")
          default:
            break;
        }
    
        setTimeout(() => {
          setNotifyMessage("");
        }, 1500)
    };
  
    function handleInput({value, type}){
      if(type === "Invoice_View"){
        const updatedRole = {...selectedRole, [type]: value }

        if (value === false) {
          updatedRole['Invoice_Create'] = false;
          updatedRole['Invoice_Send'] = false;
          updatedRole['Invoice_Edit'] = false;
          updatedRole['Invoice_Edit_Transaction'] = false;
          updatedRole['Invoice_Edit_Items'] = false;
          updatedRole['Invoice_Edit_Include_Others'] = false;
        }
      
        setSelectedRole(updatedRole);
      } else {
        setSelectedRole({...selectedRole, [type]: value });
      }
     
    }

    async function copyToClipboard(text){
      try {
        await navigator.clipboard.writeText(text);
        notify("copied")
      } catch (err) {
        console.error('Failed to copy text: ', err);
      }
    }

    async function handleShowRoleDetails(item){
      
      setLoadingRoleDetails(true);
      setIsNewRole(false);
      await Promise.resolve(setSelectedRole({
        ...item, 
        // "Invoice_Send" : 0,
        // "Invoice_View" : 0,
        // "Invoice_Edit" : 0,
        // "Invoice_Edit_Transaction" : 0,
        // "Invoice_Edit_Items" : 0,
        // "Invoice_Edit_Include_Others" : 0,
      })).then(() => {
        setTimeout(() => {
          return setLoadingRoleDetails(false);
        },1000);
      });
      
    };

    async function handleShowNewRoleDetails(){

      setLoadingRoleDetails(true);
      setIsNewRole(true);
      const newRoleData = {
        RoleID: v4(),
        CompanyID: companyID,
        DefaultRole: false,
        RoleName: "New Role",
        RoleColor: "#919191"
      };

      const permissionFiltered = permissions.filter(item => item.type === "permission")
      const permissionData = permissionFiltered.reduce((acc, item) => {
        acc[item.key] = false;
        return acc;
      }, {});

      const newData = {...newRoleData, ...permissionData};

      await Promise.resolve(setSelectedRole(newData)).then(() => {
        setTimeout(() => {
          return setLoadingRoleDetails(false);
        },500);
      });
      
    };

    async function handleUpdateRole(){

      setLoadingRoleDetails(true);
      const data = selectedRole;

      try{
        dispatch(updateRole({ TenantID:tenant, data:data }));
          
        setTimeout(() => {
          setLoadingRoleDetails(false);
          notify("update");
          return setSelectedRole(null);
        },1000);

      }catch (err){
        alert("Failed to update role", err);
      }

    };

    async function handleAddRoles(){

      refreshUsage();
      setLoadingRoleDetails(true);
      const data = selectedRole;
      if(roleUsage < roleQuota){
        try{
         dispatch(createRole({ TenantID: tenant, data:data }))
           
         setTimeout(() => {
            setLoadingRoleDetails(false);
            notify("create");
            return setSelectedRole(null);
         },1000);
        
        }catch(err){
          alert("Failed to create role", err);
          setTimeout(() => {
            setLoadingRoleDetails(false);
            return setSelectedRole(null);
          },1000);
        }
      }else{
        alert("You've reached the limit");
        setLoadingRoleDetails(false);
        return setSelectedRole(null);
      }
  
    };

    async function handleUpdateMember({DefaultRoleID, RoleID}){

      dispatch(updateCompanyAgentRole({TenantID:tenant,data:{
        RoleID: RoleID, 
        NewRoleID: DefaultRoleID
      }}))
  
    }
  
    async function handleDeleteRole(){
      
      hideDeleteModalFunc();
      setLoadingRoleDetails(true);
      const RoleID = selectedRole?.RoleID;

      const DefaultRoleID = companyRolesList?.find(item => item?.DefaultRole)?.RoleID;
      handleUpdateMember({DefaultRoleID, RoleID});

      try{
        dispatch(deleteRole({ TenantID:tenant, data: { RoleID:RoleID } }))
        
        setTimeout(() => {
          setLoadingRoleDetails(false);
          notify("delete");
          refreshUsage();
          return setSelectedRole(null);
        },2000);
         
      }catch(err){
        alert("Failed to delete role", err);
        setTimeout(() => {
          setLoadingRoleDetails(false);
          return setSelectedRole(null);
        },1000);
      }

    };

    return (
        <>
        <C_Loading isLoading={isLoading}/>
        <C_PopoutNoti triggerSaved={notifyMessage}/>
        <div className="modal fade" tabIndex="-1" ref={deleteModalRef}>
          <div className="modal-dialog modal-dialog-centered" style={{}}>
            <div className="modal-content px-3" style={{overflowY: "scroll" }}>
                <div className="modal-header modalHeader">
                    <div className="title">
                        <AiOutlineDelete size={17} color={"#ca4254"}/>
                        <span className="font-s">Delete Role</span>
                    </div>
                    <AiOutlineClose cursor='pointer' size={16} onClick={()=>{
                        hideDeleteModalFunc();
                    }}/>
                </div>
                <div className="modal-body">

                  <div className="">
                    <span className="font-xs" style={{ fontWeight: "bold" }}>
                        Are you sure you want to delete this role? This action can not be reverted. <span
                        style={{
                          fontWeight: "bold",
                          color: Colors.redButton,
                        }}
                      >
                        Member assigned with this role will be re-assgined to Default role
                      </span>
                    </span>
                  </div>
                  
                  <div
                    style={{ display: "flex", justifyContent: "flex-end",columnGap:'20px' }}
                    className="mt-3 modalshit"
                  >
                    <C_DialogButton 
                      color={'#ca4254'}
                      background={'white'}
                      borderColor={'#ca4254'}
                      buttonText={"DISCARD"}
                      onClick={() => { hideDeleteModalFunc() }}
                    />
                    <C_DialogButton 
                      color={"white"}
                      background={'#ca4254'}
                      borderColor={'#ca4254'}
                      buttonText={"DELETE"}
                      onClick={() => { handleDeleteRole() }}
                    />
                  </div>
              </div>
            </div>
          </div>
        </div>
        <div ref={roleManagementRef} className={`PermissionManagementMainContainer ${selectedRole && 'showManagementContainer'}`}>
          <div className={`loadingContainer ${!loadingRoleDetails && 'hideLoadingContainer'}`}>
            <div className="loading-spinner">
            </div>
          </div>
          <div className="roleDetailsMainContainer">
          {!isNewRole &&
           <div className="actionContainer">
              <span className="title">Manage Role</span>
              <div className="mainAction">
                <C_Button
                  buttonText={"Save"}
                  background={"#6d45b9"}
                  width="100px"
                  onClick={() => {
                    handleUpdateRole();
                  }}
                  justify={"center"}
                  textColor={"#FFFFFF"}
                  gap={"10px"}
                  icon={<AiFillSave size={16} />}
                />
                {!selectedRole?.DefaultRole && <span className="delete" onClick={() => { showDeleteModalFunc(); }}>Delete</span>}
              </div>
            </div>
          }
          {isNewRole &&
           <div className="actionContainer">
              <span className="title">New Role</span>
              <div className="mainAction">
                <C_Button
                  buttonText={"Create Now"}
                  background={"#6d45b9"}
                  width="130px"
                  onClick={() => {
                    handleAddRoles();
                  }}
                  justify={"center"}
                  textColor={"#FFFFFF"}
                  gap={"10px"}
                  icon={<AiFillPlusCircle size={16} />}
                />
              </div>
            </div>
          }
            <div className="settingsContent">
              <div className="generalDetails">
                <div className="detailsInputContainer">
                  <span>Role Name *</span>
                  <input 
                      placeholder='Give this role a name'
                      value={selectedRole?.RoleName}
                      onChange={(e) => {
                          handleInput({value: e.target.value, type: "RoleName"})
                      }}
                  />
                </div>
                <div className="detailsInputContainer">
                  <span>Role Color *</span>
                  <div className="colorSelectionContainer">
                    <div className="defaultColor" onClick={() => {
                          handleInput({value: "#919191", type: "RoleColor"})
                    }}>
                      {selectedRole?.RoleColor === "#919191" && <AiOutlineCheck size={35} color="white"/>}
                    </div>
                    <div className="colorSelectionSubContainer">
                      {colorPalette.map((item, index) => {
                          return(
                              <div key={index} className="colorSelection" style={{ backgroundColor: item }} onClick={() => {
                                  handleInput({value: item, type: "RoleColor"})
                              }}>
                                  {selectedRole?.RoleColor === item && <AiOutlineCheck size={15} color="white"/>}
                              </div>
                          )
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="permissionSettingContainer">
                {selectedRole && permissions?.map((item, index) => {
                  const keySubString = item.key ? item.key.substring(0,7) : "";
                  const invoiceEditSubString = item.key ? item.key.substring(8,12) : "";
                  const isInvoiceView = item.key === "Invoice_View" ;
                  const isInvoiceSend = item.key === "Invoice_Send";
                  const isInvoiceEdit = item.key === "Invoice_Edit";
                  const isInvoiceCreate = item.key === "Invoice_Create";
                  const isInvoicePermissionItem = (keySubString === "Invoice")
                  const isInvoiceEditPermissionItem = (invoiceEditSubString === "Edit");
                  const invoiceViewPermission = selectedRole.Invoice_View === 1 || selectedRole.Invoice_View === true;
                  const invoiceEditPermission = selectedRole.Invoice_Edit === 1 || selectedRole.Invoice_Edit === true;

                  const renderPermissionItem = () => (
                    <div key={index} className="rolePermissionContainer">
                      {item.type === "title" && (
                        <div className="rolePermissionTitleContainer">
                          {item.title === "ANALYTICS PERMISSIONS" && <FaPoll size={18} />}
                          {item.title === "CONVERSATION PERMISSIONS" && <FaComments size={18} />}
                          {item.title === "ACTIVITY LOGS PERMISSIONS" && <FaProjectDiagram size={18} />}
                          {item.title === "ClIENTS PERMISSIONS" && <FaUsers size={18} />}
                          {item.title === "KPI PERMISSIONS" && <FaAnchor size={18} />}
                          {item.title === "APPOINTMENT PERMISSIONS" && <FaCalendarAlt size={18} />}
                          {item.title === "FORMS PERMISSIONS" && <FaScroll size={18} />}
                          <span>{item.title}</span>
                        </div>
                      )}
                      {item.type === "permission" && (
                        <div className="rolePermissionSubContainer">
                          <div className="rolePermissionTitle">
                            <span>{item.title}</span>
                            <Switch
                              checked={selectedRole[item?.key]}
                              onChange={(e) => handleInput({ value: e, type: item?.key })}
                              onColor="#4BAB00"
                              onHandleColor="#FFFFFF"
                              handleDiameter={20}
                              uncheckedIcon={false}
                              checkedIcon={false}
                              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                              height={15}
                              width={35}
                            />
                          </div>
                          <div className="rolePermissionDescription">
                            <span>{item.description}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                 
                  if(invoiceViewPermission && (isInvoiceEdit || isInvoiceSend || isInvoiceCreate) && item.title !== "EDIT INVOICE PERMISSION"){
                    return renderPermissionItem();
                  }

                  if(invoiceEditPermission && (isInvoiceEditPermissionItem || item.title === "EDIT INVOICE PERMISSION")){
                    return renderPermissionItem();
                  }
                  
                  if((!isInvoicePermissionItem || isInvoiceView) && item.title !== "EDIT INVOICE PERMISSION"){
                    return renderPermissionItem();
                  }
                  
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="PermissionsMainContainer">
          <div className="PermissionListHeaderContainer">
            <div className="searchRolesContainer">
              <C_Search
                  placeholder="Search roles by name or id"
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                  value={searchText}
                  searchText={searchText}
                  closeFunction={() => {
                    setSearchText("");
                  }}
                  containerWidth="100%"
              />
            </div>
            <div className="actionContainer">
              {roleUsage < roleQuota ?
                <div className='newRoleContainer'>
                  <span className="newRole" onClick={() => { handleShowNewRoleDetails() }}>New Role <AiFillPlusCircle size={18}/></span>
                </div>
                :
                <div className="quotaRestrictedContainerNoPadding">
                  <span>You've reached the limit, <a href="https://zumaxdigital.com/crm-v1/" target="_blank" className="link">upgrade now!</a></span>
                </div>
              }
            </div>
          </div>
          <div className="PermissionListContainer">
            <div className="PermissionListColumns">
              <div className="ColumnContainer Identity">
                  <span></span>
              </div>
              <div className="ColumnContainer Role">
                  <span>Role Name</span>
              </div>
              <div className="ColumnContainer Id">
                  <span>ID</span>
              </div>
              <div className="ColumnContainer Action">
                  <span>Action</span>
              </div>
            </div>
            <div className="RoleListRows">
              {companyRolesList && companyRolesList?.length > 0 && companyRolesList?.filter((filterItem) => {
                  const findByName = filterItem?.RoleName?.toString().toLowerCase().includes(searchText.toString().toLowerCase());
                  const findByID = filterItem?.RoleID?.toString().toLowerCase().includes(searchText.toString().toLowerCase());
                  const isDefault = filterItem?.DefaultRole;

                  if (isDefault) {
                    if (searchText) {
                      return findByName || findByID;
                    } else {
                      return true; // Include the item as it is the default role
                    }
                  }
                  return false;
                }).map((item, index) => {
                return(
                  <div key={index} className={`RoleList DefaultRow ${selectedRole?.RoleID === item?.RoleID && 'selectedRoleRow'}`}>
                    <div className="Identity defaultIdentity">
                      <div className="color" style={{ backgroundColor: item?.RoleColor }}></div>
                    </div>
                    <div className="Role">
                      <span>{item?.RoleName}</span>
                    </div>
                    <div className="Id">
                      <span>{item?.RoleID}</span>
                      <AiOutlineCopy className="copyIcon" size={16} onClick={() => { copyToClipboard(item?.RoleID); }}/>
                    </div>
                    <div className="Action">
                      <div className="ManageContainer" onClick={() => { handleShowRoleDetails(item) }}>
                        <span>MANAGE</span>
                        <BsFillArrowRightCircleFill className="icon" size={14}/>
                      </div>
                    </div>
                  </div>
                )
              })}
              {companyRolesList && companyRolesList?.length > 0 && companyRolesList?.filter((filterItem) => {
                  const findByName = filterItem?.RoleName?.toString().toLowerCase().includes(searchText.toString().toLowerCase());
                  const findByID = filterItem?.RoleID?.toString().toLowerCase().includes(searchText.toString().toLowerCase());
                  const isNotDefault = !filterItem?.DefaultRole;

                  if (isNotDefault) {
                    if (searchText) {
                      return findByName || findByID;
                    } else {
                      return true; // Include the item as it is the default role
                    }
                  }

                  return false;
                }).map((item, index) => {
                return(
                  <div key={index} className={`RoleList ${selectedRole?.RoleID === item?.RoleID && 'selectedRoleRow'}`}>
                    <div className="Identity defaultIdentity">
                      <div className="color" style={{ backgroundColor: item?.RoleColor }}></div>
                    </div>
                    <div className="Role">
                      <span>{item?.RoleName}</span>
                    </div>
                    <div className="Id">
                      <span>{item?.RoleID}</span>
                      <AiOutlineCopy className="copyIcon" size={16} onClick={() => { copyToClipboard(item?.RoleID); }}/>
                    </div>
                    <div className="Action">
                      <div className="ManageContainer" onClick={() => { handleShowRoleDetails(item) }}>
                        <span>MANAGE</span>
                        <BsFillArrowRightCircleFill className="icon" size={14}/>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        </>
    );
};

export default Permissions;