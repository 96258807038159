import MuiAppbar from "./MuiAppBar";
import MuiPopover from "./MuiPopover";
import MuiButton from "./MuiButton";
import MuiBox from "./MuiBox";
import MuiFilledInput from "./MuiFilledInput";
import MuiInput from "./MuiInput";
import MuiInputLabel from "./MuiInputLabel";
import MuiInputBase from "./MuiInputBase";
import MuiFormHelperText from "./MuiFormHelperText";
import MuiIconButton from "./MuiIconButton";
import MuiBreadcrumbs from "./MuiBreadcrumbs";
import MuiCheckbox from "./MuiCheckbox";
import MuiFormControlLabel from "./MuiFormControlLabel";
import MuiAccordion from "./MuiAccordion";
import MuiAccordionSummary from "./MuiAccordionSummary";
import MuiAccordionDetails from "./MuiAccordionDetails";
import MuiChip from "./MuiChip";
import MuiDataGrid from "./MuiDataGrid";
import MuiDivider from "./MuiDivider";
import MuiSwitch from "./MuiSwitch";
import MuiTabs from "./MuiTabs";
import MuiTab from "./MuiTab";
import MuiMenu from "./MuiMenu";
import MuiMenuItem from "./MuiMenuItem";
import MuiAutocomplete from "./MuiAutocomplete";
import MuiSelect from "./MuiSelect";
import MuiTooltip from "./MuiTooltip";
import MuiDatePicker from "./MuiDatePicker";

const overrides = {
  ...MuiAppbar,
  ...MuiPopover,
  ...MuiButton,
  ...MuiBox,
  ...MuiFilledInput,
  ...MuiInput,
  ...MuiInputLabel,
  ...MuiInputBase,
  ...MuiFormHelperText,
  ...MuiIconButton,
  ...MuiBreadcrumbs,
  ...MuiCheckbox,
  ...MuiFormControlLabel,
  ...MuiAccordion,
  ...MuiAccordionSummary,
  ...MuiAccordionDetails,
  ...MuiChip,
  ...MuiDataGrid,
  ...MuiDivider,
  ...MuiSwitch,
  ...MuiTabs,
  ...MuiTab,
  ...MuiMenu,
  ...MuiMenuItem,
  ...MuiAutocomplete,
  ...MuiSelect,
  ...MuiTooltip,
  ...MuiDatePicker,
};

export default overrides;
