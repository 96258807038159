import React from "react";
import PropTypes from "prop-types";
import {
  FormControlLabel,
  IconButton,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import { HelpOutlineRounded } from "@mui/icons-material";

export const InputSwitch = ({
  name,
  disabled,
  value,
  onChange,
  tooltips,
  label,
  required,
  fieldRef,
  type = "plain",
}) => {
  const handleChange = (event) => {
    onChange(event);
  };

  const inputTextProps = {
    "data-testid": `${name}Switch`,
  };

  const switchClassName = () => {
    switch (type) {
      case "show":
        return "Show-Switch Switch-sm";
      case "active":
        return "Activation-Switch Switch-lg";
      case "combine":
        return "Combine-Switch Switch-md";
      case "active-only":
        return "Active-Only-Switch Switch-xl";
      default:
        return "";
    }
  };

  const tooltipsAdornmentComponent = tooltips ? (
    <Tooltip title={tooltips}>
      <IconButton>
        <HelpOutlineRounded sx={({ icons }) => ({ ...icons.standard })} />
      </IconButton>
    </Tooltip>
  ) : null;

  const renderLabel = (
    <Typography
      variant="bodyText"
      sx={{ display: "flex", alignItems: "center", gap: 1 }}
    >
      {label}
      {tooltipsAdornmentComponent}
    </Typography>
  );
  return (
    <FormControlLabel
      control={
        <Switch
          className={switchClassName()}
          onChange={handleChange}
          checked={value}
          inputProps={inputTextProps}
        />
      }
      label={renderLabel}
      required={required}
      inputRef={fieldRef}
      disabled={disabled}
    />
  );
};

InputSwitch.propTypes = {
  name: PropTypes.any,
  disabled: PropTypes.any,
  value: PropTypes.any,
  onChange: PropTypes.any,
  tooltips: PropTypes.any,
  label: PropTypes.any,
  required: PropTypes.any,
  fieldRef: PropTypes.any,
  error: PropTypes.any,
  helperText: PropTypes.any,
  type: PropTypes.string,
};
