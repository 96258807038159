import React from 'react'
import { MdOutlineTipsAndUpdates } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'

function ImportDataPractices() {

    const navigate = useNavigate();

  return (
    <div className='content'>

        <span>Importing data does save your time, but it still have some rules and format to follow as well.</span>
        <span>There may be circumstances that you face while importing data, but worry not - the details below will provide you with all the necessary information.</span>

        <ul>
            <li>Phone Number</li>
                <span>In CSV, if a number starts with 0, it will be automatically formatted without the leading 0. For instance, a number like "0164335555" would be formatted as "164335555" in the CSV file. Therefore, you are advised to follow the method below.</span>
                {/* <span>While importing CSV data that has a phone number as a column, please make sure that entered phone numbers are come with country code.</span>
                <span>Ideally, the phone number should be entered as a string of numbers, without any spaces or special characters.</span>
                <span>(e.g. +14155552671/+60163334444).</span>
                <div className='hintsContainer'>
                    <MdOutlineTipsAndUpdates className='icon' size={18}/>
                    <span>In CSV, if a number starts with 0, it will be automatically formatted without the leading 0. For instance, a number like "0164335555" would be formatted as "164335555" in the CSV file. Therefore, you are advised to put the country code.</span>
                </div> */}
                <span>Change the column type from "Number" to "Text" helps preventing the phone number automatically formatted without the leading 0.</span>
                <div className='imgContainer'>
                    <img src={require("./assets/numbertotextcropped.jpg")} alt=""/>
                </div>
            <li>Email</li>
                <span>While importing a CSV file, it is important to ensure that all email address are in the correct format.
                Incorrect email format can lead to failed deliveries of important communications between each other.</span>
                <span>Therefore, email should be entered as a string of characters together with '@' and domain name.</span>
                <span>(e.g. johnny@gmail.com).</span>
            <li>Number/Integer</li>
                <span>While importing CSV data, if there is a 'Number' type column, the data must be strictly entered as a string of numbers only.</span>
                <span>Any other than string of numbers will be treated as error import.</span>
            <li>Status/Toggle Button</li>
                <span>It is strictly prohibited to put any value other than "active" and "inactive" in this component.</span>
                <span>An error will be prompted if any other value is inserted into the cell as a import data.</span>
            <li>Date</li>
                <span>For Date type column, there is a fixed format for it. To ensure that the data is imported successfully, it is advised to comply with the fixed format of the date componenet as follows:</span>
                <span>1. YYYY-MM-DD = 2023-08-2/2023-8-2</span>
                <span>2. YYYY-MM-DD HH:mm:ss = 2023-9-2 16:40:55</span>
                <div className='hintsContainer'>
                    <MdOutlineTipsAndUpdates className='icon' size={18}/>
                    <span>'YYYY-MM-DD' represents Year, Month and Day while 'HH:mm:ss' represents Hour, Minute and Second.</span>
                </div>
                <div className='imgContainer'>
                    <img src={require("./assets/date.png")} alt=""/>
                </div>
            <li>Handler/Member Dropdown Component</li>
                <span>For handler and member, UID is required to serve as a valid value in the system.</span>
                <span>Therefore, copying member UID is available in the system under the "Users and P
                    ermissions".</span>
                <div className='hintsContainer'>
                    <MdOutlineTipsAndUpdates className='icon' size={18}/>
                    <span>Handler column can be put more than 1 UID within a cell, but it has to be separated with comma (,) to indicate different UID and it has to be unique for each UID.</span>
                </div>
                <span>1. Import 1 member = "JhCyggz1cbVBOyVQeoYFCd6xJeS2" </span>
                <span>2. Import more than 2 members = "JhCyggz1cbVBOyVQeoYFCd6xJeS2,x5il4AzuYhX1uNyCQ0qlIlIAtUW2" </span>
                <div className='imgContainer'>
                    <img src={require("./assets/copy-uid.png")} alt=""/>
                </div>
                <span>In CSV under the handler column, just paste the UID that has just copied into the cell as screenshot shown below.</span>
                <div className='imgContainer'>
                    <img src={require("./assets/import-handler-member.png")} alt=""/>
                </div>
            <li>Checkbox/Dropdown/Multiple Choice Component</li>
                <span>These components required a valid answer that is based on whatever options are set to the column.</span>
                <span>Therefore, you are advised to check what are the options that is set to these components while you are importing.</span>
                <div className='imgContainer'>
                    <img src={require("./assets/checkbox-option-1.png")} alt=""/>
                </div>
                <div className='imgContainer'>
                    <img src={require("./assets/checkbox-option-2.png")} alt=""/>
                </div>
                <span>Once you know the answer, you can just type in the answer into the cell under the column in CSV</span>
                <div className='hintsContainer'>
                    <MdOutlineTipsAndUpdates className='icon' size={18}/>
                    <span>Checkbox column can be put more than 1 answer within a cell, but it has to be separated with comma (,) to indicate different answer.</span>
                </div>
                <span>1. Import 1 checkbox answer = "A" </span>
                <span>2. Import more than 2 checkbox answers = "A,B" </span>
                <div className='imgContainer'>
                    <img src={require("./assets/import-checkbox.png")} alt=""/>
                </div>
        </ul>
    </div>
  )
}

export default ImportDataPractices