import { Route } from "react-router-dom"
import { crmRoutes } from "../enums/Route"
import ProtectedRoute from "./ProtectedRoute"
import { Fragment } from "react"
import WorkSpace from "../containers/analytics/WorkSpace"

export const AnalyticsWorkspaceRoute = ({currSaState, currRolesState}) => (
   
    <Fragment>
         {(currSaState || currRolesState?.Analytics_Manage) &&
            <Route 
                path={crmRoutes.analytics.workspace}
                element={
                    <ProtectedRoute>
                        <WorkSpace isSuperAdmin={currSaState} Analytics_Publish={currRolesState !== undefined ? currRolesState?.Analytics_Publish : false}/>
                    </ProtectedRoute>
                }
            />
        }
    </Fragment> 
)

