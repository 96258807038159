import React, { useEffect } from 'react'
import Draggable from 'react-draggable'
import { selectAnalyticsContext, setAnalyticsContext } from '../../../stores/slices/analyticsSlices';
import { useDispatch } from 'react-redux';

function SortComponent(props) {

    const {
        analytics,
        sortList,
        dispatchSortList,
        sortState,
    } = selectAnalyticsContext();
    const dispatch = useDispatch();

    const xAxis = analytics?.xAxis;
    useEffect(() => {
        if(xAxis && xAxis?.length === 1){
            dispatch(setAnalyticsContext({sortList : {...sortList, target: xAxis[0]?.["key"], type: "ASCENDING" }}));
            // dispatchSortList({ type: 'CHANGE_SORT_LIST', payload: {...sortList, target: xAxis[0]?.["key"], type: "ASCENDING" }})
        }
    },[xAxis])

    function handleSelectTarget(e){
        dispatch(setAnalyticsContext(setAnalyticsContext({sortList : {...sortList, target: e.target.value}})));
        // dispatchSortList({ type: 'CHANGE_SORT_LIST', payload: {...sortList, target: e.target.value}})
    };

    function handleSelectType(e){
        dispatch(setAnalyticsContext(setAnalyticsContext({sortList : {...sortList, type: e.target.value}})));
        // dispatchSortList({ type: 'CHANGE_SORT_LIST', payload: {...sortList, type: e.target.value}})
    }

  return (
    <Draggable
        handle='.sortTitle'
        bounds="parent"
    >
        <div className={`sortContainer ${sortState && 'sortContainer-active'}`}>
            <div className='sortTitle'>
                <span>Sort</span>
            </div>
            <div className='sortList'>
                <div className="optionsContainer">
                    <select onChange={(e) => { 
                        handleSelectTarget(e)
                    }} value={sortList && sortList.target}>
                    <option disabled value>Select an option</option>
                        {xAxis && xAxis.map(item => {
                            return(
                                <option key={item?.["id"]} value={item?.["key"]}>
                                    {item?.["key"]}
                                </option>
                            )
                        })
                        }
                    </select>
                </div>
                <div className='sortTypeContainer'>
                    <select onChange={(e) => { 
                        handleSelectType(e)
                    }} value={sortList && sortList.type}>
                        <option disabled value>Select an option</option>
                        <option value="ASCENDING">
                            ASCENDING
                        </option>
                        <option value="DESCENDING">
                            DESCENDING
                        </option>
                    </select>
                </div>
            </div>
        </div>
    </Draggable>
  )
}

export default SortComponent