export const TABLE = {
    SALES: {
      QUOTATIONS: "QUOTATIONS",
      SALE_ORDERS: "SALE_ORDER",
      DELIVERY_ORDERS: "SALE_DELIVERY_ORDERS",
      INVOICES: "SALE_INVOICES",
      CREDIT_NOTES: "SALE_CREDIT_NOTES",
      PAYMENTS: "SALE_PAYMENTS",
      REFUNDS: "SALE_REFUNDS",
    },
    PURCHASES: {
      PURCHASE_ORDERS: "PURCHASE_ORDERS",
      GOODS_RECEIVED_NOTES: "GOODS_RECEIVED_NOTES",
      BILLS: "PURCHASE_BILLS",
      CREDIT_NOTES: "PURCHASE_CREDIT_NOTES",
      PAYMENTS: "PURCHASE_PAYMENTS",
      REFUNDS: "PURCHASE_REFUNDS",
    },
    TRANSACTIONS: {
      FORM_ITEMS: "FORM_ITEMS",
      TRANSACTION_PAYMENTS: "TRANSACTION_PAYMENTS",
      FORM_PAYMENTS: "FORM_PAYMENTS",
      TRANSACTION_PAYMENT_TERMS: "TRANSACTION_PAYMENT_TERMS",
      TRANSFERS_TRANSACTION: "TRANSFERS_TRANSACTION",
      TRANSFERS_ITEM: "TRANSFERS_ITEM",
    },
  };
  