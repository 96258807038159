import React from "react";
import PropTypes from "prop-types";
import { Box, Stack, Typography } from "@mui/material";

export const FormLayoutTitle = ({ children, title }) => {
  //originally was uppercase and since typography was always unset

  return (
    <Stack
      component="form"
      autoComplete="off"
      width={1}
      // direction="column"
      data-testid="NewFormLayoutrTitle"
      sx={({ palette }) => ({
        // border: `1px solid ${palette.border.default}`,
        // borderRadius: "6px",
        // overflow: "scroll",
        color: palette.text.white,
        backgroundColor: palette.background.white,
        marginTop: "10px",
      })}
    >
      <Box
        sx={({ palette }) => ({
          width: "115%",
          height: "7%",
          background: palette.background.default,
          padding: "10px 30px",
          fontWeight: "500",
          display: "flex",
          alignItems: "flex-start",
        })}
      >
        <Typography variant="displayH2">{title}</Typography>
      </Box>
      <Stack
        direction="column"
        sx={{
          height: "100%",
          padding: "0px 30px 20px 0px",
          overflow: "hidden",
        }}
      >
        {children}
      </Stack>
    </Stack>
  );
};

FormLayoutTitle.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
  is_view: PropTypes.any,
  substTitle: PropTypes.string,
};
