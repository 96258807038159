import React, { Fragment, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import './Dashboard.scss'
import AppointmentSync from "./Appointment.sync";
import ActivityLogsSync from "./ActivityLogs.sync";

import KpiSync from "./Kpi.sync";

//slices
import { retrieveKpi } from "../../stores/slices/kpiSlices";
import { getPublishAnalytics } from "../../stores/slices/analyticsSlices";
import { retrieveLogs } from "../../stores/slices/logSlices";
import { retrieveAppointment } from "../../stores/slices/appointmentsSlices";
import { retrieveClientGroups } from "../../stores/slices/clientGroupSlices";
import { retrieveFormTemplate } from "../../stores/slices/formTemplateSlices";
import { selectCompanyID } from "../../stores/slices/companyIDSlices";
import { selectCurrTenant } from "../../stores/slices/persistRolesSlices";
import { retrieveFormSubmission } from "../../stores/slices/formSubmissionSlices";
import { selectCurrUserContext, setCompanyUsage } from "../../stores/slices/companySlices";
import { selectUser } from "../../stores/slices/userAuthSlices";

function Dashboard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = selectUser();

  const companyID = selectCompanyID();
  const tenant = selectCurrTenant();

  const { currUsers } = selectCurrUserContext();

  useEffect(() => {
    const CurrPage = 0;
    if(tenant && user){
      dispatch(getPublishAnalytics({TenantID:tenant, CompanyID:companyID, MemberData: currUsers}))
      dispatch(retrieveLogs({ TenantID:tenant,CompanyID: companyID, GroupID:"",UserID: user?.uid, CurrPage, Action:[],MemberID: [], Section: [] }));
      dispatch(retrieveAppointment({TenantID:tenant,CompanyID:companyID}))
      dispatch(retrieveClientGroups({TenantID:tenant,CompanyID:companyID}))
      dispatch(retrieveFormTemplate({TenantID:tenant,CompanyID:companyID}));
      dispatch(retrieveKpi({TenantID:tenant,CompanyID:companyID}));
      dispatch(retrieveFormSubmission({TenantID:tenant,CurrPage:"",UserID: ""}));
    }
    
  },[companyID, tenant, user])

  return (
      <Fragment>
        <div className="sidebar-main-container">
          <Sidebar/>
        </div>
        <div className="main-container">
          <Header title="Dashboard"/>
          <div className="content-box" >
            
              <div className="row dashboardMainContent">
                <div className="topContainer">
                <KpiSync/>
                </div>
                <div className="bottomContainer">
                  <div className="bottomLeftContainer">
                    <AppointmentSync/>
                  </div>
                  <div className="bottomRightContainer">
                    <ActivityLogsSync/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
  );
}

export default Dashboard;
