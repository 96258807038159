import { createSlice, current } from "@reduxjs/toolkit"

import { useAppSelector } from "../../hooks/storeHooks";

//initial state
const initialState = {

    //loading
    createClientGroupsLoading : false,
    updateClientGroupsLoading : false,
    deleteClientGroupsLoading : false,
    retrieveClientGroupsLoading : false,

    //error
    createClientGroupsError : undefined,
    updateClientGroupsError : undefined,
    deleteClientGroupsError : undefined,
    retrieveClientGroupsError : undefined,

    //data
    clientGroups : [],

}

//slices
const clientGroupsSlice = createSlice({
    name: "clientGroups",
    initialState,
    reducers : {
        createClientGroups(state){
            state.createClientGroupsLoading = true;
        },
        createClientGroupsSuccess(state, {payload}){
            const currentState = payload;
            state.clientGroups = currentState;

            state.createClientGroupsLoading = false;
        },
        createClientGroupsError(state, {payload}){
            const errorState = payload;
            state.createClientGroupsError = errorState;

            state.createClientGroupsLoading = false;
        },

        updateClientGroups(state){
            state.updateClientGroupsLoading = true;
        },
        updateClientGroupsSuccess(state, {payload}){
            const data = payload.data
            const currentState = current(state.clientGroups);
            const updatedState = currentState.map((item) => {
                if (item.GroupID === data.GroupID) {
                    return {
                        ...item,
                        ...data,
                    };
                }
                return item;
            });
            state.clientGroups = updatedState;
            state.updateClientGroupsLoading = false;
        },
        updateClientGroupsError(state, {payload}){
            const errorState = payload;
            state.updateClientGroupsError = errorState;

            state.updateClientGroupsLoading = false;
        },

        deleteClientGroups(state){
            state.deleteClientGroupsLoading = true;
        },
        deleteClientGroupsSuccess(state, {payload}){
            const currentState = current(state.clientGroups);
            const updatedState = currentState.filter((item) => item.GroupID !== payload.GroupID);
            state.clientGroups = updatedState;

            state.deleteClientGroupsLoading = false;
        },
        deleteClientGroupsError(state, {payload}){
            const errorState = payload;
            state.deleteClientGroupsError = errorState;

            state.deleteClientGroupsLoading = false;
        },


        retrieveClientGroups(state){
            state.retrieveClientGroupsLoading = true;
        },
        retrieveClientGroupsSuccess(state, {payload}){
            const currentState = payload;
            state.clientGroups = currentState;

            state.retrieveClientGroupsLoading = false;
        },
        retrieveClientGroupsError(state, {payload}){
            const errorState = payload;
            state.retrieveClientGroupsError = errorState;

            state.retrieveClientGroupsLoading = false;
        },
        
    }
})

//export function
export const {
    createClientGroups,
    createClientGroupsSuccess,
    createClientGroupsError,
    updateClientGroups,
    updateClientGroupsSuccess,
    updateClientGroupsError,
    deleteClientGroups,
    deleteClientGroupsSuccess,
    deleteClientGroupsError,
    retrieveClientGroups,
    retrieveClientGroupsSuccess,
    retrieveClientGroupsError
} = clientGroupsSlice.actions

//export selector
// data
export const selectClientGroups = () =>
  useAppSelector((state) => state.clientGroups.clientGroups);

// loading
export const selectCreateClientGroupsLoading = () =>
  useAppSelector((state) => state.clientGroups.createClientGroupsLoading);

export const selectUpdateClientGroupsLoading = () =>
  useAppSelector((state) => state.clientGroups.updateClientGroupsLoading);

export const selectDeleteClientGroupsLoading = () =>
  useAppSelector((state) => state.clientGroups.deleteClientGroupsLoading);

export const selectRetrieveClientGroupsLoading = () =>
  useAppSelector((state) => state.clientGroups.retrieveClientGroupsLoading);

// error
export const selectCreateClientGroupsError = () =>
  useAppSelector((state) => state.clientGroups.createClientGroupsError);

export const selectUpdateClientGroupsError = () =>
  useAppSelector((state) => state.clientGroups.updateClientGroupsError);

export const selectDeleteClientGroupsError = () =>
  useAppSelector((state) => state.clientGroups.deleteClientGroupsError);

export const selectRetrieveClientGroupsError = () =>
  useAppSelector((state) => state.clientGroups.retrieveClientGroupsError);

//export reducer
const clientGroupsReducer = clientGroupsSlice.reducer;

export default clientGroupsReducer;
