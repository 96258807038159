import { createSlice, current } from "@reduxjs/toolkit"

import { useAppSelector } from "../../hooks/storeHooks";

//initial state
const initialState = {

    //loading
    retrieveClientSelectorLoading : false,
    retrieveClientSelectorCountLoading : false,
    retrieveClientSelectedLoading : false,

    //error
    retrieveClientSelectorError : undefined,
    retrieveClientSelectorCountError : undefined,
    retrieveClientSelectedError : undefined,

    //data
    client_selector : [],
    client_selector_count : 0,
    client_selected : []

}

//slices
const clientSelectorSlice = createSlice({
    name: "clientSelectors",
    initialState,
    reducers : {
        retrieveClientSelector(state){
            state.retrieveClientSelectorLoading = true;
        },
        retrieveClientSelectorSuccess(state, {payload}){
            const currentState = payload;
            state.client_selector = currentState;

            state.retrieveClientSelectorLoading = false;
        },
        retrieveClientSelectorError(state, {payload}){
            const errorState = payload;
            state.retrieveClientSelectorError = errorState;

            state.retrieveClientSelectorLoading = false;
        },

        retrieveClientSelectorCount(state){
            state.retrieveClientSelectorCountLoading = true;
        },
        retrieveClientSelectorCountSuccess(state, {payload}){
            const currentState = payload;
            state.client_selector_count = currentState;

            state.retrieveClientSelectorCountLoading = false;
        },
        retrieveClientSelectorCountError(state, {payload}){
            const errorState = payload;
            state.retrieveClientSelectorCountError = errorState;

            state.retrieveClientSelectorCountLoading = false;
        },

        retrieveClientSelected(state){
            state.retrieveClientSelectedLoading = true;
        },
        retrieveClientSelectedSuccess(state, {payload}){
            const currentState = payload;
            state.client_selected = currentState;

            state.retrieveClientSelectedLoading = false;
        },
        retrieveClientSelectedError(state, {payload}){
            const errorState = payload;
            state.retrieveClientSelectedError = errorState;

            state.retrieveClientSelectedLoading = false;
        },
        
    }
})

//export function
export const {
    retrieveClientSelector,
    retrieveClientSelectorSuccess,
    retrieveClientSelectorError,
    retrieveClientSelectorCount,
    retrieveClientSelectorCountSuccess,
    retrieveClientSelectorCountError,
    retrieveClientSelected,
    retrieveClientSelectedSuccess,
    retrieveClientSelectedError
} = clientSelectorSlice.actions

//export selector

// data
export const selectClientSelector = () =>
  useAppSelector((state) => state.clientSelectors.client_selector);

export const selectClientSelectorCount = () =>
  useAppSelector((state) => state.clientSelectors.client_selector_count);

export const selectClientSelected = () =>
  useAppSelector((state) => state.clientSelectors.client_selected);

// loading
export const selectRetrieveClientSelectorLoading = () =>
  useAppSelector((state) => state.clientSelectors.retrieveClientSelectorLoading);

export const selectRetrieveClientSelectorCountLoading = () =>
  useAppSelector((state) => state.clientSelectors.retrieveClientSelectorCountLoading);

export const selectRetrieveClientSelectedLoading = () =>
  useAppSelector((state) => state.clientSelectors.retrieveClientSelectedLoading);

// error
export const selectRetrieveClientSelectorError = () =>
  useAppSelector((state) => state.clientSelectors.retrieveClientSelectorError);

export const selectRetrieveClientSelectorCountError = () =>
  useAppSelector((state) => state.clientSelectors.retrieveClientSelectorCountError);

export const selectRetrieveClientSelectedError = () =>
  useAppSelector((state) => state.clientSelectors.retrieveClientSelectedError);

//export reducer
const clientSelectorReducer = clientSelectorSlice.reducer;

export default clientSelectorReducer;
