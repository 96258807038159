import React, {
  useState,
  useEffect,
  useRef,
  Fragment,
} from "react";
import { useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import C_Button from "../../components/C_Button";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import { FiRotateCw } from "react-icons/fi";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import "./Form.scss"
import "./FormTemplate.scss"
import { BiArrowBack } from "react-icons/bi";
import { RiTimer2Line } from "react-icons/ri";
import { AiOutlineCheckCircle, AiOutlineClose, AiOutlineCloseCircle } from "react-icons/ai";
import Colors from "../../constants/Colors";
import moment from "moment";
import { FaPoll, FaScroll, FaStamp } from "react-icons/fa";
import { TbCheckupList } from "react-icons/tb";
import { MdNavigateBefore, MdNavigateNext, MdOutlineContentCopy } from "react-icons/md";
import Switch from "react-switch";

import C_Loading from "../../components/C_Loading";
import { Modal } from "bootstrap";
import C_DialogButton from "../../components/C_DialogButton";
import C_IconButton from "../../components/C_IconButton";
import { HiOutlineMagnifyingGlass } from "react-icons/hi2";
import { HiOutlinePencilAlt, HiOutlineTrash } from "react-icons/hi";
import C_PopoutNoti from "../../components/C_PopoutNoti";
import ReactPaginate from "react-paginate";

//slices
import { setFormTemplateID } from "../../stores/slices/formTemplateIDSlices.js";
import { retrieveAllFormsCount, retrieveFormSubmission, selectFormSubmission, selectFormsCount, updateFormSubmission } from "../../stores/slices/formSubmissionSlices.js";
import { retrieveRoles, selectRoles } from "../../stores/slices/roleSlices.js";
import { retrieveClientSelected, selectClientSelected } from "../../stores/slices/clientSelectorSlices.js";
import { retrieveCurrUsage } from "../../stores/slices/usageSlices.js";
import { retrieveCompanyAgent } from "../../stores/slices/companyAgentSlices.js";
import { deleteAllSubmission, deleteAnalyticsByFormID, deleteFormTemplate, retrieveFormTemplate, selectFormTemplate, updateFormTemplate } from "../../stores/slices/formTemplateSlices.js";
import { selectCompanyID } from "../../stores/slices/companyIDSlices.js";
import { getProcessedClientData, selectAvailableClientsGroup, selectClient, selectClientContext } from "../../stores/slices/clientSlices.js";
import { selectTenant } from "../../stores/slices/tenantSlices.js";
import { selectCurrTenant } from "../../stores/slices/persistRolesSlices.js";
import { selectCurrUserContext, setCompanyUsage } from "../../stores/slices/companySlices.js";
import { selectUser } from "../../stores/slices/userAuthSlices.js";

function FormTemplate(props) {

  const isSuperAdmin = props.isSuperAdmin;
  const Forms_Manage = props.Forms_Manage;
  const Forms_Delete = props.Forms_Delete;
  const Forms_Approval = props.Forms_Approval;

  const { currUserSA, currUsage, currTotalUsage, currUserInvitation } = selectCurrUserContext();
  const {
    filteredClientData, selectedGroupValue
  } = selectClientContext();

  const user = selectUser();
  const navigate = useNavigate()
  const dispatch = useDispatch();
  

  const companyID = selectCompanyID();
  const formTemplate = selectFormTemplate();
  const submissions = selectFormSubmission();
  const roles = selectRoles();
  const formsCount = selectFormsCount();
  const tenant = selectCurrTenant();
  const clientSelectedList = selectClientSelected();
  
  const responses_card_height = window.innerHeight * 0.22;
  const inner_height = window.innerHeight * 0.6;
  const template_card_height = window.innerHeight * 0.75;

  const [formIndex, setFormIndex] = useState();
  const [deleteFormID,setDeleteFormID] = useState("");
  const [currFormID, setCurrFormID] = useState();
  const [currFormPreviewID, setCurrFormPreviewID] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isPreview, setIsPreview] = useState(false);
  const [isReject,setIsReject] = useState(false);
  const [checkboxArray, setCheckboxArray] = useState([]);
  const [radioArray, setRadioArray] = useState([]);
  const [responsesArray, setResponsesArray] = useState([]);
  const [previousReason,setPreviousReason] = useState("");
  const [reason,setReason] = useState("");
  const [approvalLog,setApprovalLog] = useState(false);
  const [activeArray,setActiveArray] = useState(formTemplate.map((item)=>
    item.Active
  ));
  const [notifyMessage,setNotifyMessage] = useState("");
  const [isLoading,setIsLoading] = useState(false);

  const [formTemplateQuota, setFormTemplateQuota] = useState(0);
  const [formTemplateUsage, setFormTemplateUsage] = useState(0);

  const client = selectClient();
  const availableClientsGroup = selectAvailableClientsGroup();

  useEffect(()=>{
    dispatch(getProcessedClientData({client, availableClientsGroup, currUserSA, selectedGroupValue, isSA : isSuperAdmin, user}))
     
  }, [client, availableClientsGroup])
  
  useEffect(() => {
      if(currUsage && currTotalUsage !== undefined && currTotalUsage && currUserInvitation){
          const filterQuota = currUsage?.find(item => item.SectionID === "s8")?.Quota;
          const filterUsage = currTotalUsage?.find(item => item.SectionID === "s8")?.Usage;
          setFormTemplateQuota(filterQuota);
          setFormTemplateUsage(filterUsage);
      }else{
          setFormTemplateQuota(0);
          setFormTemplateUsage(0);
      }
  },[currUsage, currTotalUsage, currUserInvitation])

  async function refreshUsage(){
      dispatch(retrieveCurrUsage({TenantID:tenant,CompanyID:companyID}));
  };

  useEffect(() => {
    if(companyID && tenant){
      refreshUsage();
      // dispatch(setCompanyUsage({
      //   currUserSA : currUserSA, 
      //   currUserInvitation : currUserInvitation,
      //   TenantID : tenant,
      //   CompanyID : companyID,
      // }))
      dispatch(retrieveFormTemplate({TenantID:tenant,CompanyID:companyID}));
   
    }
  },[companyID, tenant])

  const deleteFormRef = useRef();
  const viewResponsesRef = useRef();
  const previewFormRef = useRef();
  const previewApprovalFormRef = useRef();

  const showPreviewApprovalFormModal = () => {
    const modalEle = previewApprovalFormRef.current;
    const bsModal = new Modal(modalEle, {
        backdrop: "static",
        keyboard: false,
    });
    bsModal.show();
  };

  const closePreviewApprovalFormModal = () => {
        const modalEle = previewApprovalFormRef.current;
        const bsModal = Modal.getInstance(modalEle);
        bsModal.hide();
  };

  const showPreviewFormModal = () => {
    const modalEle = previewFormRef.current;
    const bsModal = new Modal(modalEle, {
        backdrop: "static",
        keyboard: false,
    });
    bsModal.show();
  };

  const closePreviewFormModal = () => {
        const modalEle = previewFormRef.current;
        const bsModal = Modal.getInstance(modalEle);
        bsModal.hide();
  };

  const deleteModalFunc = () => {
    const modalEle = deleteFormRef.current;
    const bsModal = new Modal(modalEle, {
        backdrop: "static",
        keyboard: false,
    });
    bsModal.show();
  };

  const closeModalFunc = () => {
        const modalEle = deleteFormRef.current;
        const bsModal = Modal.getInstance(modalEle);
        bsModal.hide();
  };

  const showResponsesModal = () => {
    const modalEle = viewResponsesRef.current;
    const bsModal = new Modal(modalEle, {
        backdrop: "static",
        keyboard: false,
    });
    bsModal.show();
  };

  const hideResponsesModal = () => {
        const modalEle = viewResponsesRef.current;
        const bsModal = Modal.getInstance(modalEle);
        bsModal.hide();
  };


  useEffect(() => {
    function receiveCode() {
      if (searchParams.get("code") !== null && searchParams.get("code") === moment(new Date()).format("YYYY-MM-DD HH:mm:ss")) {
        notify("update")
        setSearchParams("")
        //await new Promise(()=>{setTimeout(, 100)})
      } else if (searchParams.get("code") !== null && searchParams.get("code") === "200") {
        notify("create")
        setSearchParams("");
      }
      
      searchParams.delete("code");
    }

    return () => {
      receiveCode();
    }
  })

  useEffect(() => {

      const CurrPage = 0;

      if(tenant){
        if((approvalLog || currFormID))
        {
          dispatch(retrieveFormSubmission({ TenantID:tenant,CurrPage:CurrPage,UserID: ""}));
          dispatch(retrieveAllFormsCount({ TenantID:tenant,UserID: "" }))
        }
        dispatch(retrieveCompanyAgent({TenantID:tenant,CompanyID:companyID}))
        dispatch(retrieveFormSubmission({TenantID:tenant,CurrPage:"" , UserID: ""}));
        dispatch(retrieveFormTemplate({TenantID:tenant,CompanyID:companyID}));
        dispatch(retrieveRoles({TenantID:tenant,CompanyID:companyID}));  
      }
      
  }, [tenant])


  useEffect(() => {

      var array = [];

      formTemplate.map((form)=>{
        array.push(form.Active);
      })
      setActiveArray(array)

  }, [formTemplate])

  useEffect(() => {
    var responses = [];

    submissions.map((item, index) => {
      if(item.SubmissionStatus === "0" || item.SubmissionStatus === "3")
      {
        if (item.FormID === currFormID) {
          responses.push(item);
        }
      }
      
    })

    setResponsesArray(responses);


  }, [currFormID, submissions,formTemplate])


  useEffect(() => {

    if(currFormPreviewID !== undefined)
    {
    var checkbox = [];
    var radio = [];
    var question = [];
    var answer = [];
    var answerArray = [];
    var clientList = [];
    var reason = "";
    var formID = "";

    //question
    submissions.map((item) => {
      if (item.SubmissionID === currFormPreviewID) {
        formID = item.FormID;
        reason = item.ReasonRejected;
      }
    })

    formTemplate.map((element, index) => {
      if (element.FormID === formID) {
        question = element.FormData;
      }
    })

    submissions.map(item=>{
      if(currFormPreviewID === item.SubmissionID)
      {
        answer = item.SubmissionData;
      }
    
    })


    question.map((ques, index) => {
      checkbox.push([]);
      radio.push([]);
    })

    question.map((ques, index) => {
      answer.map((answer, answerIndex) => {
        if (answer.questionID === ques.questionID) {

          if (ques.questionType === "Checkboxes") {
            ques.option.map(option => {
              if (answer.answer.includes(option)) {
                checkbox[index].push(true);
              }
              else {
                checkbox[index].push(false);
              }

            })
          }
          if (ques.questionType === "Multiple Choice") {
            ques.option.map(option => {
              if (answer.answer.includes(option)) {
                radio[index].push(true);
              }
              else {
                radio[index].push(false);
              }

            })
          }
          if (ques.questionType === "Client"){
            clientList.push(answer.answer)
          }
        }

      })
    })

    dispatch(retrieveClientSelected({ TenantID:tenant,selectedClient: clientList }))
    setCheckboxArray(checkbox);
    setRadioArray(radio);
    setPreviousReason(reason)
    }
  }, [currFormPreviewID])

  const onHandlePageChange = (CurrPage) =>{

    dispatch(retrieveAllFormsCount({ TenantID:tenant,UserID: "" }))
    dispatch(retrieveFormSubmission({ TenantID:tenant,CurrPage:CurrPage,UserID:""}))

  }
  //function
  const handleFormActiveStatus = (FormID, index) => {

    let active = [...activeArray];
    const body = [...formTemplate];

    const selectedBody = formTemplate[index];
    const updatedBody = {
      ...selectedBody, 
      Active : selectedBody.Active === 1 ? 0 : 1,
      FormData : JSON.stringify(selectedBody.FormData)
    };
    body[index] = updatedBody
    active = body;

    // body[index].Active = body[index].Active === 1 ? 0 : 1;
    // active[index] = body[index].Active;
    dispatch(updateFormTemplate({ TenantID: tenant, data: updatedBody }));

    notify("publish");
    setActiveArray(activeArray);
}

  const submissionCount = (formID,status) =>{
    var i = 0;

    submissions.map((form)=>{
      if(form.SubmissionStatus === status)
      {
        if(form.FormID === formID)
        {
          i++;
        }
      }
      
    })

    return i;
  }
  const isJSON = (str) => {

    if(str !== undefined)
    {
      try{
        JSON.parse(str)
      }
      catch(e)
      {
        return false;
      }
  
      return true;
    }
  }

  const isBothArrayEqual = (previous,answer) => {

    // console.log(answer,previous)

    if(JSON.stringify(previous) === JSON.stringify(answer))
    {
      return false
    }
    else
    {
      return true
    }
  }

  const deleteForm = () => {
      const formID = deleteFormID;
      closeModalFunc();
      setIsLoading(true);
      dispatch(deleteAnalyticsByFormID({TenantID:tenant,FormID:formID}));
      // dispatch(deleteAllSubmission({TenantID:tenant,FormID:formID}));
      // dispatch(deleteFormTemplate({TenantID:tenant, FormID:formID }))
      setTimeout(()=>{
            setDeleteFormID("");
            setIsLoading(false);
            refreshUsage();
            notify("delete")        
      },1000)
  }

  const approveForm = (id) => {
    var user_id = "";
    var form_id = "";
    var created_at = "";
    var modified_at = "";
    var data = "";

    submissions.map(form=>{
      if(form.SubmissionID === id)
      {
        user_id = form.UserID;
        form_id = form.FormID;
        created_at = form.CreatedAt;
        modified_at = form.ModifiedAt;
        data = JSON.stringify(form.SubmissionData);
      }
    })

    let body = {
      SubmissionID: id,
      UserID : user_id,
      FormID : form_id,
      CreatedAt : created_at,
      ModifiedAt : modified_at,
      ApprovalDate: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      ApprovalUserID: user?.uid,
      SubmissionStatus: '1',
      ReasonRejected: previousReason,
      SubmissionData: data
    };

    dispatch(updateFormSubmission({TenantID:tenant,data:body}))

    setIsPreview(false);

    notify("approve")

  }

  const rejectForm = (id) => {
    var user_id = "";
    var form_id = "";
    var created_at = "";
    var modified_at = "";
    var data = "";

    submissions.map(form=>{
      if(form.SubmissionID === id)
      {
        user_id = form.UserID;
        form_id = form.FormID;
        created_at = form.CreatedAt;
        modified_at = form.ModifiedAt;
        data = JSON.stringify(form.SubmissionData);
      }
    })

    let body = {
      SubmissionID: id,
      UserID : user_id,
      FormID : form_id,
      CreatedAt : created_at,
      ModifiedAt : modified_at,
      ApprovalDate: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      ApprovalUserID: user?.uid,
      SubmissionStatus: '2',
      ReasonRejected: reason,
      SubmissionData: data
    };

    dispatch(updateFormSubmission({TenantID:tenant,data:body}))
    setIsPreview(false);
    dispatch(retrieveFormSubmission({TenantID:tenant,CurrPage:0,UserID: ""}));
    setReason("");
    notify("reject")
    
  }

  function notify(type){
    switch(type)
    {
      case "create":
        setNotifyMessage("CREATED")
        break;
      case "approve":
        setNotifyMessage("APPROVED")
        break;
      case "reject":
        setNotifyMessage("REJECTED")
        break;
      case "delete":
        setNotifyMessage("DELETED")
        break;
      case "update":
        setNotifyMessage("UPDATED")
        break;
      case "copy":
        setNotifyMessage("COPIED")
        break;
      case "publish":
        setNotifyMessage("Your form has updated")
        break;
      default:
        setNotifyMessage("SAVED")
        break;
    }

    setTimeout(() => {
      setNotifyMessage("");
    }, 2500)
  };


  return (
    <Fragment>
      <C_Loading isLoading={isLoading} />
      <ToastContainer />
      <div className="sidebar-main-container">
          <Sidebar />
        </div>
        <div className="main-container">
        <Header title={"Form Template"} />
        <div className="px-5 py-3" style={{position:'relative', overflow: 'hidden',height:'100%' }} >
          <C_PopoutNoti triggerSaved={notifyMessage}/>

          <div className="row d-flex">
            <div className="col-6 d-flex" style={approvalLog ?{paddingLeft:'80px',justifyContent: 'start', alignItems: 'center' }:{paddingLeft:'40px',justifyContent: 'start', alignItems: 'center' }}>
                {approvalLog && <BiArrowBack className="mt-1" style={{marginBottom: '10px', cursor: 'pointer',alignItems:'center' }} size={20} onClick={()=>{setApprovalLog(false);}}  />}
                <span className="my-3 font-xl" style={{ marginLeft:'30px', marginRight:'10px',whiteSpace:'nowrap'  }}>{approvalLog ? <>{`Approval Logs`}<span className="font-s">{` (${'Approved/Rejected Forms'})`}</span></> : <>Created Template</>}</span>
            </div>
            <div className="col-6 py-3 px-3 d-flex" style={{ justifyContent: 'end' }}>
              {approvalLog === false && 
              <>
              {(isSuperAdmin || Forms_Manage) &&
                <C_Button
                  width={window.innerWidth > 1500 ? "130px" : "110px"}
                  buttonText={"Track & Trace"}
                  justify={"center"}
                  onClick={() => {navigate("/FormTemplate/FormSearch");}}
                  background={"#f8fafa"}
                  color={"#000000"}
                  border={"1px solid"}
                  borderColor={"#BCBCBC"}
                  marginRight={"0.3rem"}
                />
              }

              <C_Button
                width={window.innerWidth > 1500 ? "130px" : "110px"}
                buttonText={"Approval Logs"}
                justify={"center"}
                onClick={() => {setApprovalLog(!approvalLog);}}
                background={"#f8fafa"}
                color={"#000000"}
                border={"1px solid"}
                borderColor={"#BCBCBC"}
                marginRight={"0.3rem"}
                
                // icon={<AiOutlineCheckCircle size={14} />}
              />

              {(isSuperAdmin || Forms_Manage) &&
                <>
                {formTemplateUsage < formTemplateQuota ?
                  <C_Button
                    width={window.innerWidth > 1500 ? "150px" : "130px"}
                    buttonText={"Create Template"}
                    justify={"center"}
                    onClick={async () => { 
                      await refreshUsage();
                      if(formTemplateUsage < formTemplateQuota){
                        navigate("/FormTemplate/FormsSettings");
                        // dispatch(setFormTemplateID(""));
                      }else{
                        alert("You've reached the limit");
                      }
                    }}
                    background={"#f8fafa"}
                    color={"#000000"}
                    border={"1px solid"}
                    borderColor={"#BCBCBC"}
                  /> 
                  :
                  <div className="quotaRestrictedContainerMarginInline">
                    <span>You've reached the limit, <a href="https://zumaxdigital.com/crm-v1/" target="_blank" className="link">upgrade now!</a></span>
                  </div>
                }
                </>
              }
              </>
              }
              
              
            </div>
          </div>

          {approvalLog ?

          <div className="template-container">
          {formTemplate.length > 0 ? formTemplate.map((item)=>{
            if(submissions && submissions.some((form) => form.FormID === item.FormID && (form.SubmissionStatus === "1" || form.SubmissionStatus === "2")))
            {
            return (
              <>
                {submissions && submissions.map((form, index) => {
                  if(item.FormID === form.FormID)
                  {
                    if(form.SubmissionStatus === "2" || form.SubmissionStatus === "1")
                    {
                      let userFoundHeader = false;
                      let userFoundCreatedBy = false;
                      let userFoundApprovedBy = false;
                      return (
                        <>
                          <div className="card mt-1" style={{ border: 'none', background: '#fdfdfd', boxShadow: '2px 2px 4px 1px rgba(0,0,0,0.1)',position:'relative' }} >
                            <div className="card-body">
                            <div className="d-flex remark-text" style={{opacity:'0.8',paddingBottom:'5px',fontWeight:'bold',alignItems:'center'}} ><span style={{cursor:'pointer',color:'#4895cf'}} 
                            onClick={()=>{ notify("copy");navigator.clipboard.writeText(form.FormTrackingID);}}>Ref ID: {form.FormTrackingID}</span>
                            <MdOutlineContentCopy size={14} onClick={()=>{notify("copy"); navigator.clipboard.writeText(form.FormTrackingID);}} style={{cursor:'pointer',marginLeft:'5px'}}/>
                            </div>
                              <div className="d-flex flex-column py-3 px-3" style={{ background: 'rgb(95, 94, 175,0.1)', borderRadius: '10px' }}>
                                {/* <span className="font-s" style={{ fontWeight: 'bold', whiteSpace: 'nowrap', textOverflow: 'ellipsis', marginBottom: '5px' }}>{item.FormName}</span> */}
                                <span className="font-s" style={{fontWeight: 'bold', whiteSpace: 'nowrap' }}>
                                    {currUserSA.map((users) => {
                                      if (users.uid === form.UserID) {
                                          userFoundHeader = true;
                                          
                                          return(<>{users.displayName}</>)
                                      }

                                    })}
                                    {/* {!userFoundHeader && archivedUserHeader !== undefined ? <span style={{color:"#2b87f5"}}>{`${archivedUserHeader.UserName} (Archived)`}</span> : !userFoundHeader && <><span style={{color:'#b53816'}}>Deleted User</span></>} */}
                                    
                                 </span>
                                  <span className="clamp-text-formlist font-xs">
                                    {currUserSA.map(users => {
                                      if (users.uid === form.UserID) {
                                        userFoundHeader = true;
                                        return (
                                          <>
                                            {roles.map(role => {
                                              if (users.RoleID === role.RoleID) {
                                                return (
                                                  <>
                                                    {
                                                      role.RoleName
                                                    }
                                                  </>
                                                );
                                              }
                                            })}
                                          </>
                                        );
                                      }
                                    })}
                                  </span>
                                  
                              </div>
                              <div className="mt-2 d-flex flex-column date-span">
                                  <span style={{opacity:'0.8'}}>Date Submitted: {moment(form.CreatedAt).format("YYYY-MM-DD | HH:mm")} <span> / Created By: 
                                    {currUserSA.map((users) => {
                                    if (users.uid === item.CreatedBy) {
                                        userFoundCreatedBy = true;
                                        return(<>{users.displayName}</>)
                                    }
                                  })}
                                  {/* {!userFoundCreatedBy && archivedUserCreatedBy !== undefined ? <span style={{color:"#2b87f5"}}>{`${archivedUserCreatedBy.UserName} (Archived)`}</span> : !userFoundCreatedBy && <><span style={{color:'#b53816'}}>Deleted User</span></>} */}
                                  </span>
                                </span>

                                {form.ApprovalDate !== "" ? <span style={{opacity:'0.8'}}>Approval Date : {moment(form.ApprovalDate).format("YYYY-MM-DD | HH:mm")} <span> / Approved By: 
                                    {currUserSA.map((users) => {
                                    if (users.uid === form.ApprovalUserID) {
                                        userFoundApprovedBy = true;
                                        return(<>{users.displayName}</>)
                                    }
                                  })}
                                   {/* {!userFoundApprovedBy && archivedUserApprovedBy !== undefined ? <span style={{color:"#2b87f5"}}>{`${archivedUserApprovedBy.UserName} (Archived)`}</span> : !userFoundApprovedBy && <><span style={{color:'#b53816'}}>Deleted User</span></>} */}
                                  </span>
                                </span> : <><span>&nbsp;</span></>}
                              </div>
                              <hr className="" style={{ margin: "10px" }} />
                              <div className="d-flex">
                                <div className="col d-flex justify-content-start">
                                   <span className="d-flex justify-content-center font-xxs" style={{alignItems:'center',letterSpacing:'1px'}}>{item.FormName}</span>
                                </div>
                                <div className="col d-flex" style={{ justifyContent: 'end',alignItems:'center' }}>
                                  {(isSuperAdmin || Forms_Approval) &&
                                    <C_Button
                                      width={"110px"}
                                      gap={"10px"}
                                      buttonText={"Preview"}
                                      background={"#463EB0"}
                                      justify={"center"}
                                      onClick={() => { setCurrFormPreviewID(form.SubmissionID); showPreviewApprovalFormModal(); }}
                                      paddingLeft={"10px"}
                                      paddingRight={"10px"}
                                      icon={<HiOutlineMagnifyingGlass size={17} />}
                                    />
                                  }
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6 d-flex" style={{ justifyContent: 'end' }}>
                               
                                <div className={
                                
                                form.SubmissionStatus === "0" ? `px-1 py-2 my-1 form-status-pending`: 
                                
                                form.SubmissionStatus === "1" ? "px-1 py-2 my-1 form-status-approved" : 
                                
                                form.SubmissionStatus === "3" ? "px-1 py-2 my-1 form-status-reapplication" : 
                                "px-1 py-2 mx-1 form-status-rejected" } >

                                  <div className="d-flex" style={{height:'100%',alignItems:'center'}}>
                                      <div className="col form-status-span">
                                          <span>{form.SubmissionStatus === "0" ? <>Pending</>: form.SubmissionStatus === "1" ? <>Approved</> : form.SubmissionStatus === "3" ? <>Reapplication</> : <>Rejected</> }</span>
                                      </div>
                                      <div className="col">
                                      {form.SubmissionStatus === "0" ? <><RiTimer2Line size={20} /></>: form.SubmissionStatus === "1" ? <><AiOutlineCheckCircle size={20} /></> : form.SubmissionStatus === "3" ? <><FiRotateCw size={16} /></> : <><AiOutlineCloseCircle size={20} /></> }
                                          
                                      </div>
                                  </div>
                                </div>
                              </div>
                          </div>
                          <div><br /></div>
                        </>
                      )
                  }
                }
                })}
                
          </>
            );
          }
          else
          {
            if(submissions.length === 0)
            {
              return(
                <div className="empty-form-container">
                  <img src={require("../../assets/img/no_data.png")}/>
                  <span>NO FORM HAS SUBMITTED</span>
                </div>
              );
            }
           
          } 
          })
          :
          <>
              <div className="empty-form-container">
                <img src={require("../../assets/img/no_data.png")}/>
                <span>NO FORM HAS CREATED</span>
            </div>
            
          </>
          
          }
          
        </div>
          
          : 
          <div className="template-container">
            {formTemplate.length > 0 ? formTemplate.map((item, index) => {
              let responsesCount = submissions.filter(form=>item.FormID === form.FormID && (form.SubmissionStatus === "0" || form.SubmissionStatus === "3")).length
              let userFoundCreatedBy = false;
              let userFoundModifiedBy = false;
              return (
                <>
                  <div className="card mt-1" style={{ border: 'none', background: '#fdfdfd', boxShadow: '2px 2px 4px 1px rgba(0,0,0,0.1)',position:'relative' }} >
                    <div className="card-body">
                      <div className="d-flex flex-column py-3 px-3" style={{ background: 'rgb(95, 94, 175,0.1)', borderRadius: '10px' }}>
                        <span className="font-m" style={{ fontWeight: 'bold', whiteSpace: 'nowrap', textOverflow: 'ellipsis', marginBottom: '5px' }}>{item.FormName}</span>
                        <span className="clamp-text font-xs" style={{ lineHeight: "15px", height: "18px", overflow: "hidden", textOverflow: "ellipsis" }}>{item.FormDescription !== "" ? item.FormDescription : "No description available."}</span>
                        
                      </div>
                      <div className="mt-2 d-flex flex-column font-xxs">
                        <span style={{opacity:'0.8'}}>Date Created: {moment(item.CreatedAt).format("YYYY-MM-DD | HH:mm | dddd")} <span> / Created By: 
                          {currUserSA.map((users) => {
                          if (users.uid === item.CreatedBy) {
                            userFoundCreatedBy = true;
                            return(users.displayName)
                          }
                          
                        })}
                        </span></span>
                        {item.ModifiedBy !== "" ? <span style={{opacity:'0.8'}}>Last Modified : {moment(item.ModifiedAt).format("YYYY-MM-DD | HH:mm | dddd")} <span> / Modified By: {currUserSA.map((users) => {
                          if (users.uid === item.ModifiedBy) {
                            userFoundModifiedBy = true;
                            return(users.displayName)
                          }
                        })}
                        </span></span> : <><span>&nbsp;</span></>}
                      </div>
                      <hr className="" style={{ margin: "10px" }} />
                      <div className="d-flex flex-row">
                        <div className="col-md-6 d-flex" style={{ justifyContent: 'start' }}>
                          <C_Button
                            background={Colors.generalButtonColor}
                            width={window.innerWidth > 1500 ? "200px" : "180px"}
                            gap={"10px"}
                            buttonText={`Form Responses (${responsesCount})`}
                            justify={"center"}
                            onClick={() => { 
                              showResponsesModal();
                              setCurrFormID(item.FormID); 
                              setCurrFormPreviewID(""); 
                              setIsPreview(false); 
                              setIsReject(false);
                            }}
                            paddingLeft={"10px"}
                            paddingRight={"10px"}
                            marginLeft={"8px"}
                            icon={<TbCheckupList size={17} />}
                            // targetID={"view-responses"}
                            // toggleAction={"modal"}
                          />
                        </div>
                        <div className="col-md-6 d-flex flex-row" style={{ justifyContent: 'end', alignItems:'center' }}>
                        
                            
                            <div className="d-flex" style={{columnGap:'5px'}}>
                              
                              <C_IconButton
                                onClick={() => { setFormIndex(index); showPreviewFormModal(); }}
                                size={32}
                                title={"Preview"}
                                color="#CDCDCD"
                                icon={<HiOutlineMagnifyingGlass color="#FFF" size={16}/>}
                                backgroundImage="linear-gradient(to right top, #463EB0, #463EB0, #463EB0, #463EB0, #463EB0)"
                              />
                              {(isSuperAdmin || Forms_Manage) &&
                                <C_IconButton
                                  onClick={() => { 
                                    navigate({ pathname: "/FormTemplate/FormsSettings", search: createSearchParams({ FormID: item.FormID }).toString() })
                                    // dispatch(setFormTemplateID(item.FormID)); 
                                    // navigate("/FormTemplate/FormsSettings"); 
                                  }}
                                  size={32}
                                  title={"Update Template"}
                                  color="#CDCDCD"
                                  backgroundImage="linear-gradient(to right top, #8165b9, #7c5db9, #7755b9, #724db9, #6d45b9)"
                                  icon={<HiOutlinePencilAlt color="#FFF" size={16}/>}
                                />
                              }
                              {(isSuperAdmin || Forms_Delete) &&
                                <C_IconButton
                                  onClick={() => { deleteModalFunc(); setDeleteFormID(item.FormID) }}
                                  size={32}
                                  title={"Delete Template"}
                                  color="#CDCDCD"
                                  backgroundImage="linear-gradient(to right top, #c65261, #d94453, #e83440, #f52029, #ff0000)"
                                  icon={<HiOutlineTrash color="#FFF" size={16}/>}
                                />
                              }
                              {/* <MdOutlineDelete title="Delete Template" size={20} style={{ color: "#eb6565", cursor: 'pointer' }} onClick={() => { deleteModalFunc(); setDeleteFormID(item.FormID) }} /> */}
                            </div>
                            <span className="mx-1 font-m"></span>
                            {(isSuperAdmin || Forms_Manage) &&
                            <div className="d-flex justify-content-center" title={activeArray[index] === 1 ? "Accepting Responses" : "Not Accepting Responses"}>
                              <Switch
                                checked={activeArray[index]}
                                onChange={(e) => {handleFormActiveStatus(item.FormID,index);}}
                                onColor="#4BAB00"
                                onHandleColor="#FFFFFF"
                                handleDiameter={15}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 3px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 5px rgba(0, 0, 0, 0.2)"
                                height={15}
                                width={35}
                              />
                            </div>
                            }
                        </div>
                      </div>
                    </div>
                  </div>
                  <div><br /></div>
                </>
              )
            })
            :
            <>
              <div className="empty-form-container">
                  <img src={require("../../assets/img/no_data.png")}/>
                  <span>NO FORM HAS CREATED</span>
              </div>
            </>
            }
          </div> }
        </div>

        {/**modal preview */}
        <div
          className="modal fade modal-lg"
          id="preview-form"
          tabIndex="-1"
          style={{}}
          ref={previewFormRef}
        >
          <div className="modal-dialog modal-dialog-centered modal-xl" >
            <div className="modal-content" style={{ background: '#f8fafa', minHeight: inner_height}}>
              <div className="modal-body p-3" style={{paddingTop:'0px'}} >
                <div className="modal-header modalHeader">
                    <div className="title font-s">
                        <h6 className="mt-2 mx-2">Template Preview</h6>
                    </div>
                    <AiOutlineClose cursor='pointer' size={18} data-bs-dismiss="modal"/>
                </div>

                <div style={{}}>
                  {formTemplate && formTemplate.map((item, index) => {
                    if (index === formIndex) {
                      return (
                        <>
                          <div className="card mt-2" style={{ border: 'none', boxShadow: '5px 5px 5px -2px rgba(0,0,0,0.1)', background: 'rgb(95, 94, 175,0.1)' }}>
                            <div className="card-body d-flex flex-column" >
                              <span className="font-m">{item.FormName}</span>
                              <span className="font-xs">{item.FormDescription !== "" ? item.FormDescription : "No description available."}</span>
                            </div>
                          </div>
                          <div className="px-2 py-1 mt-1" style={{ overflowY: 'scroll', height: "60vh" }}>
                            {item.FormData.map(data => {
                              return (
                                <>
                                  <div className="card mt-3" style={{ border: 'none', boxShadow: '2px 2px 4px 1px rgba(0,0,0,0.1)' }}>
                                    <div className="card-body d-flex flex-column">
                                      <span className="font-s">{data.questionName}</span>
                                      {data.option === undefined
                                        ?
                                        <>
                                          {data.questionType === "Multiline" ?
                                            <>
                                              <textarea style={{ border: 'none', borderBottom: '1px solid' }} placeholder={`${data.questionName} :`} disabled></textarea>
                                            </>
                                            :
                                            <>
                                            {data.questionType === "Date Range" ? 
                                            <>
                                            <div className="mt-2" style={{width:'100%'}}>
                                              <span className="mx-1 font-xxs" style={{opacity:'0.8'}}>Start Date :</span>
                                              <input type="date" className="mx-1 font-xs" style={{marginBottom:'5px',border: 'none', width: '100%', background: 'none',borderBottom: '1px solid',marginBottom:'10px'}} disabled/>

                                              <span className="mx-1 font-xxs" style={{opacity:'0.8'}}>End Date :</span>
                                              <input type="date" className="mx-1 font-xs" style={{marginBottom:'5px',border: 'none', width: '100%', background: 'none',borderBottom: '1px solid'}} disabled/>
                                            </div>
                                            </>
                                            :
                                            <>
                                            <div className="mt-2" style={{ borderBottom: '1px solid' }}>
                                                <input className="py-1" type={data.questionType === "ShortText" ? "text" : data.questionType === "Date" ? "date" : data.questionType === "Time" ? "time" : data.questionType === "Phone" ? "phone" : data.questionType === "Email" ? "email" : data.questionType === "Decimal" ? "number" :data.questionType === "Number" ? "number" : "text"} value="" placeholder={`${data.questionName} :`} style={{ border: 'none', width: '100%', background: 'none' }} disabled />
                                              </div>
                                            </>
                                            }
                                            </>
                                          }
                                        </>
                                        :
                                        <>
                                          {data.questionType === "Multiple Choice" ?
                                            <>
                                              <label className="mt-2" >
                                                {data.option.map((options,optionIndex) => {
                                                  return (
                                                    <>
                                                      <div className="d-flex" style={{ alignItems: 'center', textAlign: 'center' }}>
                                                        <input className="py-1" type="radio" value="" style={{ width: '13px', height: '13px' }} disabled />
                                                        <span className="mx-1 font-xs">{options}</span>
                                                      </div>
                                                    </>

                                                  );

                                                })}
                                              </label>
                                            </>
                                            :
                                            data.questionType === "Checkboxes" ?
                                              <>
                                                <div className="mt-2">
                                                  {data.option.map(options => {
                                                    return (
                                                      <label style={{ display: 'flex', alignItems: 'center' }}>
                                                        <input type='checkbox' style={{ width: '15px', height: '15px' }} className='col-md-3' onChange={(e) => { }} disabled />
                                                        <span className="checkmark" style={{ marginRight: '10px' }}></span>
                                                        <span className="font-xs" style={{ marginRight: '10px' }}>{options}</span>
                                                      </label>
                                                    );
                                                  })}
                                                </div>
                                              </>
                                              :
                                              data.questionType === "Dropdown" ?
                                                <>
                                                  <div className="mt-2" >
                                                    <select className="form-select font-xs" style={{ minWidth: '130px', width: '10%' }}>
                                                      {data.option.map(options => {
                                                        return (
                                                          <option className="" value={options}>{options}</option>
                                                        );
                                                      })}
                                                    </select>

                                                  </div>
                                                </>
                                              :
                                              data.questionType === "Member" ?
                                              <>
                                               
                                                <select className="form-select font-xs" style={{ minWidth: '130px', width: '10%' }}>
                                                  {data.option.map(options => {
                                                    return (
                                                      <>
                                                      
                                                        <option className="" value={options}>{options}</option>
                                                         
                                                      
                                                      </>
                                                    );
                                                  })}
                                                </select>
                                               
                                              </>
                                              :
                                              data.questionType === "Client" ?
                                              <>
                                                <div className="d-flex">
                                                  <C_Button
                                                    buttonText={"Select Your Client"}
                                                    width={window.innerWidth > 1500 ? "170px" : "150px"}
                                                  />
                                                </div>
                                              </>
                                              :
                                              <>
                                              </>
                                          }
                                        </>
                                      }
                                    </div>
                                  </div>
                                </>
                              )
                            })
                            }
                          </div>
                        </>
                      )
                    }
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/**responses */}
        <div
          className="modal fade modal-xl"
          id="view-responses"
          tabIndex="-1"
          ref={viewResponsesRef}
          style={{overflow: "hidden"}}
        >
          <div className="modal-dialog modal-dialog-centered" style={{height:template_card_height}}>
            <div className="modal-content" style={{ background: '#f8fafa' }}>
              <div className="modal-body mx-3" style={{}} >
                
                <div className="modal-header modalHeader" style={{padding:'5px'}}>
                    <div className="title font-s">
                        {isPreview ? <><h6 className="mt-2 mx-2">Preview</h6></> : <><h6 className="mt-2 mx-2">Responses ({responsesArray.length})</h6></>}
                    </div>
                    {isPreview ? <><AiOutlineClose className="mt-1" style={{ marginBottom: '10px', cursor: 'pointer' }} size={20} onClick={() => { setCurrFormPreviewID(""); setIsPreview(false); }} /></> : <><AiOutlineClose className="mt-1" style={{ marginBottom: '10px', cursor: 'pointer' }} size={20} data-bs-dismiss="modal" onClick={() => { setFormIndex("");}} /></>}
                 </div>
                  {isPreview ?
                    <>
                    <div className="" style={{}}>
                      {submissions && submissions.map((form,formIndex) => {
                        if (form.SubmissionID === currFormPreviewID) {
                          return (
                            <>
                              {formTemplate && formTemplate.map((item, index) => {

                                if (item.FormID === form.FormID) {
                                  
                                  return (
                                    <>
                                      <div className="card"  style={{ marginTop:'10px', border: 'none', boxShadow: '5px 5px 5px -2px rgba(0,0,0,0.1)', background: 'rgb(95, 94, 175,0.1)' }}>
                                        <div className="card-body d-flex flex-column" >
                                          <span className="font-m">{item.FormName}</span>
                                          <span className="font-xs">{item.FormDescription !== "" ? item.FormDescription : "No description available."}</span>
                                          {form.SubmissionStatus === "2" || form.SubmissionStatus === "3" ? 
                                          <span className="mt-2 font-xxs" style={{color:Colors.redButton}}>Reason Rejected : {previousReason}</span>
                                        : <></>}
                                        </div>
                                      </div>
                                      <div className="px-2 py-2" style={{}}>
                                        <div className="px-1 py-1" style={{  height: inner_height, overflow: 'scroll'}}>
                                        {item.FormData.map((data, questionIndex) => {
                                          return (
                                            <>
                                              {form.SubmissionData.map((answer, answerIndex) => {
                                              
                                                if (data.questionID === answer.questionID) {
                                                  return (
                                                    <div className="card mt-3" style={{ border: 'none', boxShadow: '2px 2px 4px 1px rgba(0,0,0,0.1)' }}>
                                                      <div className="card-body d-flex flex-column">
                                                        <span className="font-s">{data.questionName}</span>
                                                        {data.option === undefined
                                                          ?
                                                          <>
                                                            {data.questionType === "Multiline" ?
                                                              <>
                                                                <textarea style={{ border: 'none', borderBottom: '1px solid' }} value={answer.answer} disabled></textarea>
                                                                {form.SubmissionStatus === "3" ? answer.previousAnswer !== answer.answer ? <><div className="mt-1" style={{color:Colors.redButton}}><span className="font-xxs">Previous Answer : {answer.previousAnswer}</span></div></> : <></> : <></>}
                                                              </>
                                                              :
                                                              data.questionType === "Date Range" ? 
                                                              <>
                                                              <div className="mt-2 " style={{width:'100%'}}>
                                                                <span className="mx-1 font-xxs" style={{opacity:'0.8'}}>Start Date :</span>
                                                                <input type="date" value={answer.answer.start} name={data.questionType} placeholder="DateRange1" className="mx-1 font-xs" style={{marginBottom:'5px',border: 'none', width: '100%', background: 'none',borderBottom: '1px solid',marginBottom:'10px'}} disabled />
                                                                
                                                                <span className="mx-1 font-xxs" style={{opacity:'0.8'}}>End Date :</span>
                                                                <input type="date" value={answer.answer.end} name={data.questionType} placeholder="DateRange2" className="mx-1 font-xs" style={{marginBottom:'5px',border: 'none', width: '100%', background: 'none',borderBottom: '1px solid'}} disabled />
                                                              </div>
                                                              {form.SubmissionStatus === "3" ? isBothArrayEqual(answer.previousAnswer,answer.answer)
                                                                  ? <><div className="mt-1" style={{color:Colors.redButton}}><span className="font-xxs">Previous Answer : {moment(answer.previousAnswer.start).format("DD-MM-YYYY HH:mm")} | {moment(answer.previousAnswer.end).format("DD-MM-YYYY HH:mm")}</span></div></> : <></> : <></>}
                                                              </>
                                                              
                                                              :
                                                              <>
                                                                <div className="mt-2" style={{ borderBottom: '1px solid' }}>
                                                                    <input className="py-1" type={data.questionType === "ShortText" ? "text" : data.questionType === "Date" ? "date" : data.questionType === "Time" ? "time" : data.questionType === "Phone" ? "phone" : data.questionType === "Email" ? "email" : data.questionType === "Decimal" ? "number" :data.questionType === "Number" ? "number" : "text"} value={answer.answer} style={{ border: 'none', width: '100%', background: 'none' }} disabled />
                                                                  </div>
                                                                  {form.SubmissionStatus === "3" ? answer.previousAnswer !== answer.answer ? <><div className="mt-1" style={{color:Colors.redButton}}><span className="font-xxs">Previous Answer : {answer.previousAnswer}</span></div></> : <></> : <></>}
                                                              
                                                              </>
                                                            }
                                                          </>
                                                          :
                                                          <>
                                                            {data.questionType === "Multiple Choice" ?
                                                              <>
                                                                <label className="mt-2" >
                                                                  {data.option.map((options, optionIndex) => {
                                                                    return (
                                                                      <>
                                                                        <div className="d-flex" style={{ alignItems: 'center', textAlign: 'center' }}>
                                                                          <input className="py-1" type="radio" value={options} checked={radioArray.length !== 0 ? radioArray[questionIndex][optionIndex] : ""} style={{ width: '13px', height: '13px' }} disabled />
                                                                          <span className="mx-1 font-xxs">{options}</span>
                                                                        </div>
                                                                      </>
                                                                    );

                                                                  })}
                                                                  {form.SubmissionStatus === "3" ?
                                                                  answer.previousAnswer !== "" ?
                                                                  answer.previousAnswer.map(prev => {
                                                                    {/* console.log(prev) */}
                                                                    return (
                                                                      <>
                                                                      {answer.answer.map(ans=>{
                                                                        if(ans !== prev)
                                                                        {
                                                                          return(<><div className="mt-1" style={{color:Colors.redButton}}><span className="font-xxs">Previous Answer : {prev}</span></div></>);
                                                                        }
                                                                      })}
                                                                      </>
                                                                    )
                                                                  }):<></>:<></>}
                                                                 </label>
                                                              </>
                                                              :
                                                              data.questionType === "Checkboxes" ?
                                                                <>
                                                                  <div className="mt-2">
                                                                    {data?.option.map((options, optionIndex) => {
                                                                      return (
                                                                        <label style={{ display: 'flex', alignItems: 'center' }}>
                                                                          <input type='checkbox' style={{ width: '15px', height: '15px' }} checked={checkboxArray.length !== 0 ? checkboxArray[questionIndex][optionIndex] : ""} value={options} className='col-md-3' disabled />
                                                                          <span className="checkmark" style={{ marginRight: '10px' }}></span>
                                                                          <span className="font-xs" style={{ marginRight: '10px'}}>{options}</span>
                                                                        </label>
                                                                      );
                                                                    })}
                                                                  </div>
                                                                  {form.SubmissionStatus === "3" ?
                                                                  answer.previousAnswer !== "" ?
                                                                  isBothArrayEqual(answer.previousAnswer,answer.answer)
                                                                  ?
                                                                  <><div className="mt-1 mx-1" style={{color:Colors.redButton}}><span className="font-xxs">Previous Answer : {answer.previousAnswer.map(previous=>{return(<>{previous} </>)})}</span></div></>
                                                                  :<></>:<></>:<></>}
                                                                </>
                                                                :
                                                                data.questionType === "Dropdown" ?
                                                                  <>
                                                                    <div className="mt-2" >
                                                                      <select className="form-select font-xs" style={{ minWidth: '130px', width: '20%' }} disabled>

                                                                        <option className="" selected value={answer.answer}>{answer.answer}</option>
                                                                      </select>

                                                                    </div>
                                                                    {form.SubmissionStatus === "3" ? answer.previousAnswer !== answer.answer ? <><div className="mt-1" style={{color:Colors.redButton}}><span className="font-xxs">Previous Answer : {answer.previousAnswer}</span></div></> : <></> : <></>}
                                                                  </>
                                                              :
                                                              data.questionType === "Member" ?
                                                                <>
                                                                  <div className="mt-2" >
                                                                    <select className="form-select font-xs" style={{ minWidth: '130px', width: '20%' }} disabled>

                                                                          <option className="" value={answer.answer}>{answer.answer}</option>
                                                                            
                                                                    </select>

                                                                  </div>
                                                                  {form.SubmissionStatus === "3" ? answer.previousAnswer !== answer.answer ? <><div className="mt-1" style={{color:Colors.redButton}}><span className="font-xxs">Previous Answer :  
                                                                  {currUserSA.map((item,index)=>{
                                                                          if(item.uid === answer.previousAnswer)
                                                                          {
                                                                            return(
                                                                              item.displayName
                                                                            );
                                                                          }
                                                                        
                                                                        })}</span></div></> : <></> : <></>}
                                                                </>
                                                                :
                                                                data.questionType === "Client" ?
                                                                  <>
                                                                    <div className="mt-2" >
                                                                      <select className="form-select font-xs" style={{ minWidth: '130px', width: '20%' }} disabled>

                                                                            <option className="" value={answer.answer}>{clientSelectedList?.find(item => item?.ClientID === answer.answer) !== undefined ? clientSelectedList?.find(item => item?.ClientID === answer.answer)?.RowValue : "N/A"}</option>
                                                                             
                                                                      
                                                                      </select>

                                                                    </div>
                                                                    {form.SubmissionStatus === "3" ? answer.previousAnswer !== answer.answer ? <><div className="mt-1" style={{color:Colors.redButton}}><span className="font-xxs">Previous Answer : 
                                                                    {filteredClientData.map((item,index)=>{
                                                                          if(item.ClientID === answer.previousAnswer)
                                                                          {
                                                                            return(
                                                                              item.ClientName
                                                                            );
                                                                          }
                                                                        
                                                                        })}</span></div></> : <></> : <></>}
                                                                  </>
                                                                  :
                                                                  <>
                                                                  </>
                                                            }
                                                          </>
                                                        }
                                                      </div>
                                                    </div>
                                                  );
                                                }
                                              })}
                                            </>
                                          )
                                        })}
                                          
                                        </div>
                                        {isReject ? 
                                        <>
                                          
                                          <div className="d-flex mt-2 col" style={{justifyContent:'end', columnGap:'20px'}}>
                                            <div className="d-flex" style={{justifyContent:'start',background:'white',boxShadow: 'inset 1px 1px 2px 1px rgba(0,0,0,0.1)',width:'100%',borderRadius:'7px' }}>
                                              <input className="px-2" type="text" style={{background:'none',border:'none',width:'100%'}} value={reason} onChange={(e)=>{setReason(e.target.value)}} placeholder="Reason (Optional)"/>
                                            </div>

                                            <C_DialogButton
                                              onClick={()=>{rejectForm(form.SubmissionID);}}
                                              color={"white"}
                                              background={'#ca4254'}
                                              borderColor={'#ca4254'}
                                              buttonText={"REJECT"}
                                            />
                                            <C_DialogButton
                                              onClick={()=>{setIsReject(false);setReason("");}}
                                              color={'#ca4254'}
                                              background={'white'}
                                              borderColor={'#ca4254'}
                                              buttonText={"DISCARD"}
                                            />
                                        </div>
                                        
                                        </>
                                        :
                                          <div className="d-flex mt-2 col" style={{justifyContent:'end',columnGap:'20px'}}>

                                            <C_DialogButton
                                              onClick={()=>{setIsReject(true);}}
                                              color={"white"}
                                              background={'#ca4254'}
                                              borderColor={'#ca4254'}
                                              buttonText={"REJECT"}
                                            />

                                            <C_DialogButton
                                              onClick={()=>{approveForm(form.SubmissionID);}}
                                              color={"white"}
                                              background={'#3eb986'}
                                              borderColor={'#3eb986'}
                                              buttonText={"APPROVE"}
                                            />  
                                            
                                        </div>
                                        }
                                       
                                        
                                      </div>
                                    </>
                                  )
                                }
                              })}
                            </>
                          )
                        }
                      }//end if
                      )}
                    </div>
                    </>
                    :
                    <>
                    <div style={{height:template_card_height,overflow:'scroll'}}>
                    <div className="form-submission-container">
                      {submissions && submissions.map((form, index) => {
                        if (currFormID === form.FormID) {
                          return (
                            <>
                              {formTemplate && formTemplate?.map((item) => {
                                if (item.FormID === form.FormID) {
                                  if(form.SubmissionStatus === "0" || form.SubmissionStatus === "3")
                                  {
                                    let userFound = false;
                                    // const archivedUser = archivedMergedData.find(element=>element.UserID === form.UserID)
                                    return (
                                      <div className="form-submission-card" style={{ height: responses_card_height }}>
                                        <div className="d-flex remark-text" style={{opacity:'0.8',paddingBottom:'5px',fontWeight:'bold',alignItems:'center',color:'#4895cf'}} ><span style={{cursor:'pointer'}} 
                                        onClick={()=>{ notify("copy");navigator.clipboard.writeText(form.FormTrackingID);}}>Ref ID: {form.FormTrackingID}</span>
                                        <MdOutlineContentCopy size={14} onClick={()=>{notify("copy"); navigator.clipboard.writeText(form.FormTrackingID);}} style={{cursor:'pointer',marginLeft:'5px'}}/>
                                        </div>
                                        <div className="d-flex flex-column py-3 px-3" style={{ background: 'rgb(95, 94, 175,0.1)', borderRadius: '10px' }}>
                                          <div className="d-flex">
                                            <div className=" d-flex flex-column">
                                            <span className="font-s" style={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>
                                            {currUserSA.map((users) => {
                                              if (users.uid === form.UserID) {
                                                userFound = true;
                                                return(users.displayName)
                                              }
                                            })}
                                            </span>
                                              <span className="clamp-text-formlist font-xxs" style={{ opacity:'0.8' }}>
                                                {currUserSA.map(users => {
                                                  if (users.uid === form.UserID) {
                                                    return (
                                                      <>
                                                        {roles.map(role => {
                                                          if (users.RoleID === role.RoleID) {
                                                            return (
                                                              <>
                                                                {
                                                                  role.RoleName
                                                                }
                                                              </>
                                                            );
                                                          }
                                                        })}
                                                      </>
                                                    );
                                                  }
                                                })}
                                              </span>
                                            </div>
                                            <div className="col-md-6 d-flex" style={{ justifyContent: 'end' }}>

                                              <div className={
                                              
                                              form.SubmissionStatus === "0" ? `px-1 py-2 my-1 form-status-pending`: 
                                              
                                              form.SubmissionStatus === "1" ? "px-1 py-2 my-1 form-status-approved" : 
                                              
                                              form.SubmissionStatus === "3" ? "px-1 py-2 my-1 form-status-reapplication" : 
                                              "px-1 py-2 mx-1 form-status-rejected" } >

                                                <div className="d-flex" style={{height:'100%',alignItems:'center'}}>
                                                    <div className="col form-status-span">
                                                        <span>{form.SubmissionStatus === "0" ? <>Pending</>: form.SubmissionStatus === "1" ? <>Approved</> : form.SubmissionStatus === "3" ? <>Reapplication</> : <>Rejected</> }</span>
                                                    </div>
                                                    <div className="col">
                                                    {form.SubmissionStatus === "0" ? <><RiTimer2Line size={20} /></>: form.SubmissionStatus === "1" ? <><AiOutlineCheckCircle size={20} /></> : form.SubmissionStatus === "3" ? <><FiRotateCw size={16} /></> : <><AiOutlineCloseCircle size={20} /></> }
                                                        
                                                    </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
  
                                        </div>
                                        <hr className="" style={{ margin: "10px" }} />
                                        <div className="d-flex mt-1" style={{ alignItems: 'center' }}>
                                          <div className="col-md-7" style={{ justifyContent: 'start' }}>
                                            <span className="font-xxs" style={{ fontWeight: 'lighter',opacity:'0.8' }}>Submitted On: {moment(form.CreatedAt).format("YYYY-MM-DD | HH:mm | dddd")}</span>
                                          </div>
                                          {(isSuperAdmin || Forms_Approval) &&
                                          <div className="col-md-5 d-flex" style={{ justifyContent: 'end' }}> 
                                            <C_Button
                                                width={"110px"}
                                                gap={"10px"}
                                                buttonText={"Action"}
                                                justify={"center"}
                                                onClick={() => {setCurrFormPreviewID(form.SubmissionID); setIsPreview(true);setIsReject(false);}}
                                                paddingLeft={"10px"}
                                                paddingRight={"10px"}
                                                icon={<FaStamp size={13} />}
                                              />
                                          </div>
                                          }
                                        </div>
                                      </div>
                                    );
                                  }
                                }
                              })}
                            </>
                          )
                        }
                      })}

                      {responsesArray.length === 0 ? <>
                        <div className="empty-form-container">
                          <img src={require("../../assets/img/no_data.png")}/>
                          <span>NO FORM HAS SUBMITTED</span>
                        </div>
                      </> 
                      : 
                      <></>
                      }
                      </div>
                    </div>
                    </>}
                
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" tabIndex="-1" ref={deleteFormRef} style={{}}>
                <div className="modal-dialog modal-dialog-centered" style={{}}>
                    <div className="modal-content px-3" style={{overflowY: "scroll" }}>
                        <div className="modal-header modalHeader">
                            <div className="title">
                                <span className="font-s">Delete Form</span>
                            </div>
                            <AiOutlineClose cursor='pointer' size={16} onClick={() => { closeModalFunc(); }}/>
                        </div>
                        
                        <div className="modal-body">
                            <span className="font-xs">
                              {"Are you sure to delete this form ?"}
                            </span>
                            <div className="row" style={{margin:'0px'}}>
                              <div className="col-6 delete-form-container">
                                <div className="delete-form-icon delete-graph">
                                  <FaPoll size={18}/>
                                </div>
                                <p className="delete-form-info">Remove all graphs from analytics associated with the form.</p>
                              </div>
                              <div className="col-6 delete-form-container">
                                <div className="delete-form-icon delete-submission">
                                  <FaScroll size={18}/>
                                </div>
                                <p className="delete-form-info">Erase all form submissions associated with the form.</p>
                              </div>
                            </div>
                            <div className="new-button-container" >
                              <C_DialogButton
                                onClick={()=>{closeModalFunc();}}
                                color={'#ca4254'}
                                background={'white'}
                                borderColor={'#ca4254'}
                                buttonText={"DISCARD"}
                              />
                              <C_DialogButton
                                onClick={()=>{deleteForm();}}
                                color={"white"}
                                background={'#ca4254'}
                                borderColor={'#ca4254'}
                                buttonText={"DELETE"}
                              />
                            </div>
                        </div>
                    </div>
                </div>
          </div>

        <div
            className="modal fade modal-xl"
            id="preview-approval-form"
            tabIndex="-1"
            style={{}}
            ref={previewApprovalFormRef}
          >
            <div className="modal-dialog modal-dialog-centered" style={{}}>
              <div className="modal-content" style={{ background: '#f8fafa' }}>
                <div className="modal-body" style={{paddingTop:'0px'}}>
                    <div className="modal-header modalHeader">
                      <div className="title font-s">
                          <h6 className="mt-2 mx-2">Template Preview</h6>
                      </div>
                      <AiOutlineClose cursor='pointer' size={18} data-bs-dismiss="modal"/>
                    </div>
                  <div className="" style={{}}>
                  {submissions && submissions.map((form,formIndex) => {
                    if (form.SubmissionID === currFormPreviewID) {
                  
                      return (
                        <>
                          {formTemplate && formTemplate.map((item, index) => {

                            if (item.FormID === form.FormID) {
                              {/* console.log(item) */}
                              return (
                                <>
                                  <div className="card"  style={{ marginTop:'10px', border: 'none', boxShadow: '5px 5px 5px -2px rgba(0,0,0,0.1)', background: 'rgb(95, 94, 175,0.1)' }}>
                                    <div className="card-body d-flex flex-column" >
                                      <span className="font-m">{item.FormName}</span>
                                      <span className="font-xs">{item.FormDescription !== "" ? item.FormDescription : "No description available."}</span>
                                      {form.SubmissionStatus === "2" || form.SubmissionStatus === "3" ? 
                                      <span className="mt-2 font-xxs" style={{color:Colors.redButton}}>Reason Rejected : {previousReason}</span>
                                    : <></>}
                                    </div>
                                  </div>
                                  <div className="px-2 py-2" style={{}}>
                                    <div style={{  height: inner_height, overflow: 'scroll'}}>
                                    {item.FormData.map((data, questionIndex) => {
                                      return (
                                        <>
                                          {form.SubmissionData.map((answer, answerIndex) => {
                                            if (data.questionID === answer.questionID) {
                                              return (
                                                <div className="card mt-3" style={{ border: 'none', boxShadow: '2px 2px 4px 1px rgba(0,0,0,0.1)' }}>
                                                  <div className="card-body d-flex flex-column">
                                                    <span className="font-s">{data.questionName}</span>
                                                    {data.option === undefined
                                                      ?
                                                      <>
                                                        {data.questionType === "Multiline" ?
                                                          <>
                                                            <textarea style={{ border: 'none', borderBottom: '1px solid' }} value={answer.answer} disabled></textarea>
                                                            
                                                          </>
                                                          :
                                                          data.questionType === "Date Range" ? 
                                                          <>
                                                          <div className="mt-2 " style={{width:'100%'}}>
                                                            <span className="mx-1 font-xxs" style={{opacity:'0.8'}}>Start Date :</span>
                                                            <input type="date" value={answer.answer.start} name={data.questionType} placeholder="DateRange1" className="mx-1 font-xs" style={{marginBottom:'5px',border: 'none', width: '100%', background: 'none',borderBottom: '1px solid',marginBottom:'10px'}} disabled />
                                                            
                                                            <span className="mx-1 font-xxs" style={{opacity:'0.8'}}>End Date :</span>
                                                            <input type="date" value={answer.answer.end} name={data.questionType} placeholder="DateRange2" className="mx-1 font-xs" style={{marginBottom:'5px',border: 'none', width: '100%', background: 'none',borderBottom: '1px solid'}} disabled />
                                                          </div>
                                                          </>
                                                          :
                                                          
                                                          <>
                                                            <div className="mt-2" style={{ borderBottom: '1px solid' }}>
                                                                <input className="py-1" type={data.questionType === "ShortText" ? "text" : data.questionType === "Date" ? "date" : data.questionType === "Time" ? "time" : data.questionType === "Phone" ? "phone" : data.questionType === "Email" ? "email" : data.questionType === "Decimal" ? "number" : data.questionType === "Number" ? "number" : "text"} value={answer.answer} style={{ border: 'none', width: '100%', background: 'none' }} disabled />
                                                              </div>
                                                              
                                                          
                                                          </>
                                                        }
                                                      </>
                                                      :
                                                      <>
                                                        {data.questionType === "Multiple Choice" ?
                                                          <>
                                                            <label className="mt-2" >
                                                              {data.option.map((options, optionIndex) => {
                                                                return (
                                                                  <>
                                                                    <div className="d-flex" style={{ alignItems: 'center', textAlign: 'center' }}>
                                                                      <input className="py-1" type="radio" value={options} checked={radioArray.length !== 0 ? radioArray[questionIndex][optionIndex] : ""} style={{ width: '13px', height: '13px' }} disabled />
                                                                      <span className="mx-1 font-xxs">{options}</span>
                                                                    </div>
                                                                  </>
                                                                );

                                                              })}
                                                            
                                                            </label>
                                                          </>
                                                          :
                                                          data.questionType === "Checkboxes" ?
                                                            <>
                                                              <div className="mt-2">
                                                                {data?.option.map((options, optionIndex) => {
                                                                  return (
                                                                    <label style={{ display: 'flex', alignItems: 'center' }}>
                                                                      <input type='checkbox' style={{ width: '15px', height: '15px' }} checked={checkboxArray.length !== 0 ? checkboxArray[questionIndex][optionIndex] : ""} value={options} className='col-md-3' disabled />
                                                                      <span className="checkmark" style={{ marginRight: '10px' }}></span>
                                                                      <span className="font-xs" style={{ marginRight: '10px'}}>{options}</span>
                                                                    </label>
                                                                  );
                                                                })}
                                                              </div>
                                                              
                                                            </>
                                                            :
                                                            data.questionType === "Dropdown" ?
                                                              <>
                                                                <div className="mt-2" >
                                                                  <select className="form-select font-xs" style={{ minWidth: '130px', width: '20%' }} disabled>

                                                                    <option className="" selected value={answer.answer}>{answer.answer}</option>
                                                                  </select>

                                                                </div>
                                                                
                                                              </>
                                                            :
                                                            data.questionType === "Member" ?
                                                              <>
                                                                <div className="mt-2" >
                                                                  <select className="form-select font-xs" style={{ minWidth: '130px', width: '20%' }} disabled>

                                                                    <option className="" selected value={answer.answer}>{answer.answer}</option>
                                                                  </select>

                                                                </div>
                                                                
                                                              </>
                                                            :
                                                            data.questionType === "Client" ?
                                                              <>
                                                                <div className="mt-2" >
                                                                  <select className="form-select font-xs" style={{ minWidth: '130px', width: '20%' }} disabled>

                                                                    <option className="" selected value={answer.answer}>{clientSelectedList?.find(item => item?.ClientID === answer.answer) !== undefined ? clientSelectedList?.find(item => item?.ClientID === answer.answer)?.RowValue : "N/A"}</option>
                                                                  </select>

                                                                </div>
                                                                
                                                              </>
                                                              :
                                                              <>
                                                              </>
                                                        }
                                                      </>
                                                    }
                                                  </div>
                                                </div>
                                              );
                                            }
                                          })}
                                        </>
                                      )
                                    })}
                                      
                                    </div>
                                  </div>
                                </>
                              )
                            }
                          })}
                        </>
                      )
                    }
                  }//end if
                  )}
                </div>
                </div>
              </div>
            </div>
          </div>
          {approvalLog && 
            <div className="" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '1rem' }}>
              <div style={{ display: 'flex',justifyContent: 'center', alignItems: 'center', overflow: 'scroll' }}>
                <ReactPaginate
                  containerClassName="paginationStyle"
                  activeClassName="activePage"
                  pageClassName="paginationElementStyle"
                  breakLabel="..."
                  nextLabel={<MdNavigateNext size={20}/>}
                  onPageChange={(page) => { onHandlePageChange(page.selected) }}
                  pageRangeDisplayed={5}
                  // pageCount={10}
                  pageCount={(formsCount && formsCount.length > 0) && Math.ceil(formsCount[0].Total_submission/50)}
                  previousLabel={<MdNavigateBefore size={20}/>}
                  renderOnZeroPageCount={null}
                />
              </div>
          </div>
          }
      </div>
    </Fragment>
  );
}

export default FormTemplate;
