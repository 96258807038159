import API from '../../constants/API';

import { apiUrl } from '../../constants/env';
import axios from 'axios';

const client = axios.create({
    baseURL: apiUrl,
    timeout: 100000,
});

const GET = async function (url) {
    try {
        const { data } = await client.get(url);
        if (data.error) {

        }
        else {
            return data;
        }
    }
    catch (err) {

    }
}

const POST = async function (url, params, config = {}) {
    try {

        const { data } = await client.post(url, params, config);
        if (data.error) {

        }
        else {
            return data;
        }
    }
    catch (err) {

    }
}

const PATCH = async function (url, params) {
    try {
        const { data } = await client.patch(url, params);
        if (data.error) {

        }
        else {
            return data;
        }
    }
    catch (err) {

    }
}

const PUT = async function (url, params) {
    try {
        const { data } = await client.put(url, params);
        if (data.error) {

        }
        else {
            return data;
        }
    }
    catch (err) {

    }
}

const ApiClient = {
    GET,
    POST,
    PATCH,
    PUT,
}


const SendNotification = ({ fcm, title, body, profileImg, imgContent }) => {

      const message = {
        title: title,
        body: body,
        profileImg: profileImg,
        imgContent: imgContent ? imgContent : 'EMPTY',
        fcm: fcm
      };
      
      if(message.fcm){
        ApiClient.POST(API.registerFCM, message).then((result) => {
    
        }).catch(err => {
    
          console.log(err)
    
        })
      }

}

export default SendNotification