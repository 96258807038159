import React, {
    useState,
    useEffect,
    useRef,
    Fragment,
  } from "react";
import moment from "moment";
import C_Button from "../../components/C_Button";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import { useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import Colors from "../../constants/Colors";
import { FiPlusCircle } from "react-icons/fi";
import { v4 } from "uuid";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import { MdRemoveCircleOutline } from "react-icons/md";
import * as Icons from "react-icons/bs";
import { TbTemplate } from "react-icons/tb";
import "./FormsSettings.scss";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { AiFillCheckCircle, AiOutlineCheckCircle, AiOutlineClose, AiOutlineUsergroupAdd } from "react-icons/ai";
import Switch from "react-switch";

import { Modal } from "bootstrap";
import { CgUserList } from "react-icons/cg";
import C_Search from "../../components/C_Search";
import C_DialogButton from "../../components/C_DialogButton";
import { RiDeleteBin5Line } from "react-icons/ri";

//slices
import { createFormTemplate, retrieveFormTemplate, selectFormTemplate, updateFormTemplate } from "../../stores/slices/formTemplateSlices";
import { setFormTemplateID } from "../../stores/slices/formTemplateIDSlices";
import { retrieveCurrUsage } from "../../stores/slices/usageSlices";
import { retrieveCompanyAgent } from "../../stores/slices/companyAgentSlices";
import { selectCompanyID } from "../../stores/slices/companyIDSlices";
import { selectTenant } from "../../stores/slices/tenantSlices";
import { selectClient } from "../../stores/slices/clientSlices";
import { selectCurrTenant } from "../../stores/slices/persistRolesSlices";
import { selectCurrUserContext } from "../../stores/slices/companySlices";
import { selectUser } from "../../stores/slices/userAuthSlices";


  function FormsSettings() {

    const { currUserSA } = selectCurrUserContext();

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = selectUser();

    const companyID = selectCompanyID();
    const tenant = selectCurrTenant();
    const client = selectClient();
    const forms = selectFormTemplate();

    const [title,setTitle] = useState("");
    const [description,setDescription] = useState("");
    const [createdAt,setCreatedAt] = useState("");
    const [creator,setCreator] = useState("");
    const [expiryDate,setExpiryDate] = useState("");
    const [effectiveDate,setEffectiveDate] = useState("");
    const [status,setStatus] = useState(1);
    const [targetMemberList,setTargetMemberList] = useState(currUserSA);
    const [currMemberList,setCurrMemberList] = useState([]);
    const [questionsArray,setQuestionsArray] = useState([]);
    const [isHeaderEmpty,setIsHeaderEmpty] = useState(false);
    const [isQuestionEmpty,setIsQuestionEmpty] = useState(false);
    const [isQuestionNameEmpty,setIsQuestionNameEmpty] = useState(false);
    const [isOptionEmpty,setIsOptionEmpty] = useState(false);
    const [searchText,setSearchText] = useState("");
    const [clicked,setClicked] = useState(false);
    const [formTemplateID, setFormTemplateID] = useState("");
    const [searchParams,setSearchParams] = useSearchParams();

    const [memberArray,setMemberArray] = useState(currUserSA.map(item=>{return item.displayName}));

    const TABLE_TOTAL_HEIGHT = window.innerHeight * 0.8;
    const inner_height = TABLE_TOTAL_HEIGHT * 0.85;

    const modalRef = useRef();
    const saveFormRef = useRef();
    const scrollRef = useRef({});

    async function refreshUsage(){
      await Promise.resolve(dispatch(retrieveCurrUsage({TenantID:tenant,CompanyID:companyID})));
    };

    const showModalFunc = () => {
      const modalEle = modalRef.current;
      const bsModal = new Modal(modalEle, {
          backdrop: "static",
          keyboard: false,
      });
      bsModal.show();
    };

    const hideModalFunc = () => {
          const modalEle = modalRef.current;
          const bsModal = Modal.getInstance(modalEle);
          bsModal.hide();
    };

    const saveModalFunc = () => {
      const modalEle = saveFormRef.current;
      const bsModal = new Modal(modalEle, {
          backdrop: "static",
          keyboard: false,
      });
      bsModal.show();
    };

    const closeModalFunc = () => {
          const modalEle = saveFormRef.current;
          const bsModal = Modal.getInstance(modalEle);
          bsModal.hide();
    };

    const formComponents = [
      {
        Name:'Short Text',
        Type:'Short Text',
        Icon:'BsFonts',
        DataType: 'String',
      },
      {
        Name:'Multiline',
        Type:'Multiline',
        Icon:'BsTextareaResize',
        DataType: 'String',
      },
      {
        Name:'Choices',
        Type:'Multiple Choice',
        Icon:'BsStopCircleFill',
        DataType: 'String',
      },
      {
        Name:'Checkboxes',
        Type:'Checkboxes',
        Icon:'BsFillCheckSquareFill',
        DataType: 'String',
      },
      {
        Name:'Dropdown',
        Type:'Dropdown',
        Icon:'BsFillMenuAppFill',
        DataType: 'String',
      },
      {
        Name:'Date',
        Type:'Date',
        Icon:'BsCalendarCheck',
        DataType: 'Date',
      },
      {
        Name:'Time',
        Type:'Time',
        Icon:'BsClockFill',
        DataType: 'Time',
      },
      {
        Name:'Phone',
        Type:'Phone',
        Icon:'BsFillTelephoneFill',
        DataType: 'String',
      },
      {
        Name:'Email',
        Type:'Email',
        Icon:'BsFillEnvelopeFill',
        DataType: 'String',
      },
      {
        Name:'Date Range',
        Type:'Date Range',
        Icon :'BsFillCalendarRangeFill',
        DataType: 'Date',
      },
      {
        Name:'Number',
        Type:'Number',
        Icon :'BsSuitDiamondFill',
        DataType: 'Integer',
      },
      {
        Name:'Decimal',
        Type:'Decimal',
        Icon :'BsAsterisk',
        DataType: 'Float',
      },
    ]

    useEffect(()=>{
      if(Array.from(searchParams).length > 0){
        setFormTemplateID(searchParams.get("FormID"));
      }
    }, [searchParams])

    useEffect(() => {
      if(tenant){
        dispatch(retrieveCompanyAgent({TenantID:tenant,CompanyID:companyID}))
        dispatch(retrieveFormTemplate({TenantID:tenant,CompanyID:companyID}));
      }
      
    }, [tenant, companyID])

    useEffect(()=>{
      let array = [];

      currUserSA.filter((item,index)=>{
        if(searchText!=="")
        {
            if(item["displayName"].toString().toLocaleLowerCase().includes(searchText.toString().toLocaleLowerCase()))
            {
                return item
            }
        }
        else
        {
            return item
        }
    })
    .map((data)=>{
        if(data.uid)
        {
            array.push(data)
        }
        
    })

    setTargetMemberList(array);

    },[searchText,currUserSA])

    useEffect(()=>{
      let array = [];

      currUserSA.map((item)=>{
        array.push(item);
      })
      setTargetMemberList(array)
    },[currUserSA])

    useEffect(() => {

        if(formTemplateID)
        {
           var owner = "";
           var formName = "";
           var formDescription = "";
           var createdDate = "";
           var active = 1;
           var startDate = "";
           var dueDate = "";
           var questions=[];
           var memberList=[];

           forms.map((form,index)=>{
            if(form.FormID === formTemplateID)
            {
              formName = form.FormName;
              formDescription = form.FormDescription;
              createdDate = form.CreatedAt;
              owner = form.CreatedBy;
              active = form.Active;
              startDate = form.EffectiveDate;
              dueDate = form.ExpiryDate;
              questions = form.FormData;
              form.TargetMember.map((item)=>{
                const member = targetMemberList.find(m => m.uid === item);
                if(member !== undefined)
                {
                  if(member.uid === item)
                  {
                    memberList.push(item)
                  }
                }
                
              })
            }
           })
           setTitle(formName);
           setDescription(formDescription);
           setCreator(owner);
           setCreatedAt(createdDate);
           setQuestionsArray(questions);
           setStatus(active);
           setEffectiveDate(startDate);
           setExpiryDate(dueDate);
           setCurrMemberList(memberList);
        }

    }, [formTemplateID, forms])

    //Function
    const addQuestions = (name,type) => {
      let id = v4();
      
      if(name === "Multiple Choice" || name === "Checkboxes" || name === "Dropdown")
      {
        setQuestionsArray([...questionsArray,
          {
            questionID:id,
            questionName:'',
            questionType:name,
            questionDataType:type,
            requiredInput:false,
            option:[""]
          }
        ])
      }
      else if(name === "Member")
      {
        setQuestionsArray([...questionsArray,
          {
            questionID:id,
            questionName:'Member',
            questionType:name,
            questionDataType:type,
            requiredInput:false,
            option:memberArray
          }
        ])
      }
      else if(name === "Client")
      {
        setQuestionsArray([...questionsArray,
          {
            questionID:id,
            questionName:'Client',
            questionType:name,
            questionDataType:type,
            requiredInput:false,
            option:["Client List"]
          }
        ])
      }
      else {
        setQuestionsArray([...questionsArray,
          {
            questionID:id,
            questionName:'',
            questionType:name,
            questionDataType:type,
            requiredInput:false
          }
        ])
      }
      setTimeout(() => scrollToQuestion(id), 50);
    }

    const addOptions = (questionIndex) => {
      let question = [...questionsArray];

      question[questionIndex].option.push('')
      setQuestionsArray(question);
    }

    const handleOptionInput = (e,questionIndex,optionIndex) => {

      let question = [...questionsArray];

      question[questionIndex].option[optionIndex]= e.target.value;
      setQuestionsArray(question);
    }

    const removeOptions = (questionIndex,optionIndex) => {
      let question = [...questionsArray];

      question[questionIndex].option.splice(optionIndex,1);
      setQuestionsArray(question);

    }

    const handleQuestionName = (e,questionIndex) =>{
      let question = [...questionsArray];

      question[questionIndex].questionName= e.target.value;
      setQuestionsArray(question);
    }

    const handleQuestionRequiredInput = (questionIndex) =>{
      let question = [...questionsArray];

      question[questionIndex].requiredInput = !question[questionIndex].requiredInput;

      setQuestionsArray(question);
    }

    const changeQuestionType = (e,questionIndex) => {
      let type = e.target.value;
      let question = questionsArray[questionIndex];
      let array = [...questionsArray];

      if(type === "Short Text" || type === "Multiline" || type === "Date" || type === "Time" || type === "Date Range" || type === "Decimal" || type === "Number"){
        question.questionType = type
        delete question["option"]
        array[questionIndex] = question

        setQuestionsArray(array);
      }
      else {
        let oldOption = array[questionIndex].option !== undefined ? array[questionIndex].option : [""];

        question = {...question, option: oldOption}
        array[questionIndex] = question
        array[questionIndex].questionType = type

        setQuestionsArray(array);
      }
    }

    const deleteQuestion = (questionIndex) => {
      let question = [...questionsArray];

      question.splice(questionIndex,1);
      setQuestionsArray(question);
    }


    {/**CRUD */}
    const saveForm = () => {
      var emptyHeader = false;
      var emptyQuestion = false;
      var emptyQuestionName = false;
      var emptyOption = false;
      var emptyEffectiveDate = false;

      if(title === "" || effectiveDate === "")
      {
        emptyHeader = true;
        closeModalFunc();
      }
      else {
        if(questionsArray.length === 0)
        {
          emptyQuestion = true;
        }
        else
        {
          if(questionsArray.some(row => row["questionName"] === ""))
          {
            emptyQuestionName = true;
          }

          if(questionsArray.some(row => row["option"]?.some(option => option === "")) || questionsArray.some(row => row["option"]?.length < 2 && row["questionType"] !== "Client" && row["questionType"] !== "Member"))
          {
            emptyOption = true;
          }
        }
      }

      setIsHeaderEmpty(emptyHeader);
      setIsQuestionEmpty(emptyQuestion);
      setIsQuestionNameEmpty(emptyQuestionName);
      setIsOptionEmpty(emptyOption);

      if(emptyHeader === false && emptyQuestion === false && emptyQuestionName === false && emptyOption === false)
      {
        let formArray = {
              FormID : v4(),
              CompanyID:companyID,
              FormName : title,
              FormDescription : description,
              CreatedBy : user?.uid,
              ModifiedBy : "",
              CreatedAt : moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
              ModifiedAt : moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
              EffectiveDate:effectiveDate,
              ExpiryDate:expiryDate,
              TargetMember:JSON.stringify(currMemberList),
              Active : 1,
              FormData : JSON.stringify(questionsArray)
            };
            dispatch(createFormTemplate({TenantID:tenant,data:formArray}));
            //notifyCreatedForm();
            closeModalFunc();
            setTitle(""); 
            setDescription("");
            setQuestionsArray([]);
            setIsHeaderEmpty(false);
            setIsQuestionEmpty(false);
            setIsQuestionNameEmpty(false);
            setIsOptionEmpty(false);
            refreshUsage();
            navigate({pathname:"/FormTemplate",search:createSearchParams({code:"200"}).toString()});
         
      } else {
        closeModalFunc();
      }
   
    }

    const updateForm = () => {

      var emptyHeader = false;
      var emptyQuestion = false;
      var emptyQuestionName = false;
      var emptyOption = false;
      var emptyEffectiveDate = false;

      if(title === "" || effectiveDate === "")
      {
        emptyHeader = true;
        closeModalFunc();
      }
      else {
        if(questionsArray.length === 0)
        {
          emptyQuestion = true;
        }
        else
        {
          if(questionsArray.some(row => row["questionName"] === ""))
          {
            emptyQuestionName = true;
          }

          if(questionsArray.some(row => row["option"]?.some(option => option === "")) || questionsArray.some(row => row["option"]?.length < 2  && row["questionType"] !== "Client" && row["questionType"] !== "Member"))
          {
            emptyOption = true;
          }
        }
      }

      setIsHeaderEmpty(emptyHeader);
      setIsQuestionEmpty(emptyQuestion);
      setIsQuestionNameEmpty(emptyQuestionName);
      setIsOptionEmpty(emptyOption);

      if(emptyHeader === false && emptyQuestion === false && emptyQuestionName === false && emptyOption === false)
      {
            let body = {
              FormID : formTemplateID,
              FormName : title,
              FormDescription : description,
              CreatedBy : creator,
              ModifiedBy : user?.uid,
              CreatedAt : createdAt,
              ModifiedAt : moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
              EffectiveDate:effectiveDate,
              ExpiryDate:expiryDate,
              TargetMember:JSON.stringify(currMemberList),
              Active : status,
              FormData : JSON.stringify(questionsArray)
            };
            dispatch(updateFormTemplate({TenantID:tenant,data:body}));

            //notifyCreatedForm();
            closeModalFunc();
            setTitle(""); 
            setDescription("");
            setQuestionsArray([]);
            setIsHeaderEmpty(false);
            setIsQuestionEmpty(false);
            setIsQuestionNameEmpty(false);
            setIsOptionEmpty(false);
            refreshUsage();
            navigate({pathname:"/FormTemplate",search:createSearchParams({code:moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}).toString()})
      
      } else {
        closeModalFunc();
      }
    }
    
    function handleSelectedMember(uid){
        setCurrMemberList([...currMemberList, uid])
    }

    function removeSelectedMember(uid){
      setCurrMemberList(currMemberList.filter(member => member !== uid));
    }

    const scrollToQuestion = (questionID) =>{

      if (scrollRef.current[questionID]) {
        scrollRef.current[questionID].scrollIntoView({ behavior: 'smooth',block: 'end' });
      }
    }

    return (
      <Fragment>
        <ToastContainer />
        <div className="sidebar-main-container">
          <Sidebar />
        </div>
        <div className="main-container">
          <Header title={"Form Template"}/>
          <div
            className="d-flex px-5 py-4" 
          >
            <div className="col-md-9 card" style={{marginRight:'10px',background:'#fcffff',height:TABLE_TOTAL_HEIGHT,border:'none',boxShadow: '1px 1px 1px 1px rgba(0,0,0,0.1)'}}>
                <div className="card-body">
                  <div className="row">
                    <div className="col-6 d-flex" style={{justifyContent:'start'}}>
                      <BiArrowBack className="mt-1" style={{marginBottom:'10px',cursor:'pointer'}} size = {23} onClick={()=>{
                        navigate("/FormTemplate"); 
                        // dispatch(setFormTemplateID(""));
                        }}/>
                    </div>
                    <div className="col-6 d-flex" style={{justifyContent:'end',alignItems:'center'}}>
                      {isHeaderEmpty === false ?  <span className="font-xs" style={{color:Colors.redButton}}>{isQuestionEmpty? <>Question must not be empty !</> : isQuestionNameEmpty ? <>Question name must not be empty !</> : isOptionEmpty ? <>Option must not be empty and must be more than 1 option !</> : <></>}</span>: <></>}
                    </div>
                  </div>
                  <div className="px-1 py-1" style={{overflow:'scroll',height:inner_height}}>
                  {title === "" && description === "" && questionsArray.length === 0 ? 
                    <div className="empty-form-container">
                      <img src={require("../../assets/img/editing_table.png")}/>
                      <span>USE THE FORM OPTION TO CREATE YOUR FORM NOW</span>
                    </div>
                    :<></>
                  }
                  {title !== "" || description !== "" ? 
                  <>
                  <div className="card" style={{border:'none',boxShadow: '2px 2px 4px 1px rgba(0,0,0,0.1)',background: 'rgb(95, 94, 175,0.1)'}}>
                      <div className="card-body d-flex">
                          <div className="col-1" style={{position:'relative',textAlign:'center'}}>
                            <TbTemplate size={23} style={{position:'absolute',top:'30%',right:'50%'}}/>
                          </div>
                          <div className="col-11 d-flex flex-column">
                            <span className="font-l">{title}</span>
                            <span className="font-xs">{description}</span>
                          </div>
                      </div>
                    </div>
                  </> 
                  : <></>}
                    
                    {/**array.map */}
                    {questionsArray && questionsArray.map((question,questionIndex)=>{
                      if(question["option"] === undefined)
                      {
                        return (
                          <>
                              <div className="card mt-3" key={question.questionID} ref={(ref)=>{scrollRef.current[question.questionID] = ref}}  style={{border:'none',boxShadow: '2px 2px 4px 1px rgba(0,0,0,0.1)',background:'#f8fafa'}}>
                                <div className="card-body d-flex" style={{position:'relative'}} >
                                  <div className="col-9 d-flex flex-column">
                                   
                                    <div className="" style={{borderBottom :'solid 1px'}}>
                                      <input type="text" className="font-xs" value={question.questionName} style={{border:'none',width:'100%',background:'#f8fafa'}} onChange={(e)=>{handleQuestionName(e,questionIndex)}} placeholder="Question Name"/>
                                    </div>
                                    {question.questionType === "Short Text" ?
                                    <>
                                    <div className="mt-2" style={{borderBottom:'1px dotted',width:'100px'}}>
                                        <span className="mx-1 font-xxs">Short Text</span>
                                    </div>
                                    </>
                                    :
                                    question.questionType === "Multiline" ?
                                    <>
                                    <div className="mt-2" style={{borderBottom:'1px dotted',width:'100px'}}>
                                        <textarea className="" disabled style={{width:'100px'}}>Multiline</textarea>
                                    </div>
                                    </>
                                    :
                                    question.questionType === "Date" ?
                                    <>
                                    <div className="mt-2" style={{borderBottom:'1px dotted',width:'100px'}}>
                                      <input type="date" className="font-xxs" style={{marginBottom:'5px',width:'100px'}} disabled/>
                                    </div>
                                    </>
                                    :
                                    question.questionType === "Time" ?
                                    <>
                                    <div className="mt-2" style={{borderBottom:'1px dotted',width:'100px'}}>
                                      <input type="time" className="font-xxs" style={{marginBottom:'5px',width:'100px'}} disabled/>
                                    </div>
                                    </>
                                    :
                                    question.questionType === "Phone" ?
                                    <>
                                    <div className="mt-2" style={{borderBottom:'1px dotted',width:'100px'}}>
                                      <div className="font-xxs" style={{marginBottom:'5px',width:'100px'}} disabled>+6 0161234567</div>
                                    </div>
                                    </>
                                    :
                                    question.questionType === "Email" ?
                                    <>
                                    <div className="mt-2" style={{borderBottom:'1px dotted',width:'100px'}}>
                                      <div className="font-xxs" style={{marginBottom:'5px',width:'100px'}} disabled>abc@domain.com</div>
                                    </div>
                                    </>
                                    :
                                    question.questionType === "Date Range" ?
                                    <>
                                    <div className="mt-2 d-flex" style={{borderBottom:'1px dotted',width:'200px'}}>
                                      <input type="date" className="mx-1 font-xxs" style={{marginBottom:'5px',width:'100px'}} disabled/>
                                      -
                                      <input type="date" className="mx-1 font-xxs" style={{marginBottom:'5px',width:'100px'}} disabled/>
                                    </div>
                                    </>
                                    :
                                    question.questionType === "Number" ?
                                    <>
                                    <div className="mt-2" style={{borderBottom:'1px dotted',width:'100px'}}>
                                      <div className="font-xxs" style={{marginBottom:'5px',width:'100px'}} disabled>123456</div>
                                    </div>
                                    </>
                                    :
                                    question.questionType === "Decimal" ?
                                    <>
                                    <div className="mt-2" style={{borderBottom:'1px dotted',width:'100px'}}>
                                      <div className="font-xxs" style={{marginBottom:'5px',width:'100px'}} disabled>0.41</div>
                                    </div>
                                    </>
                                    :
                                    <>
                                    </>
                                    }
                                    
                                  </div>
                                  <div className="col-2 d-flex" style={{textAlign:'center',alignItems:'center',position:'relative',justifyContent:'center'}}>
                                  <select className="form-select font-xxs" onChange={(e) => {changeQuestionType(e,questionIndex)}} style={{position:'absolute',alignItems: 'center', top:'0', width:'120px', color: '#000000', marginRight: '5px', borderRadius: '8px' }}>
                                    <option selected value={question.questionType}>{question.questionType}</option>
                                    {formComponents.map(item=>{
                                      if(item.Type !== question.questionType)
                                      {
                                        return(
                                          <option value={item.Type}>{item.Type}</option>
                                        );
                                      }
                                    
                                    })}
                                  </select>
                                  </div>
                                  <div className="col-1 d-flex" style={{justifyContent:'end'}}>
                                  <RiDeleteBin5Line size={18} style={{cursor:'pointer',color:Colors.redButton}} onClick={()=>{deleteQuestion(questionIndex)}}/>
                                  </div>
                                  <div className="d-flex" style={{position:'absolute',bottom:'0',right:'0',paddingRight:'20px',paddingBottom:'15px',textAlign:'center',alignItems:'center'}}>
                                    <span className="font-xxs">Required Input</span>
                                    <span className="mx-1" > | </span>
                                    <Switch
                                      checked={questionsArray[questionIndex].requiredInput}
                                      onChange={(e) => {handleQuestionRequiredInput(questionIndex);}}
                                      onColor="#4BAB00"
                                      onHandleColor="#FFFFFF"
                                      handleDiameter={15}
                                      uncheckedIcon={false}
                                      checkedIcon={false}
                                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                      height={15}
                                      width={35}
                                    />
                                  </div>
                                </div>
                              </div>
                          </>
                        );
                      }
                      else {
                        return (
                          <>
                            <div className="card mt-3" key={question?.questionID} ref={(ref)=>{scrollRef.current[question?.questionID] = ref}} style={{border:'none',boxShadow: '2px 2px 4px 1px rgba(0,0,0,0.1)',background:'#f8fafa'}}>
                              <div className="card-body d-flex " style={{position:'relative'}}>
                                <div className="col-9 d-flex flex-column">
                                  <label>Question Name</label> 
                                  <div style={{borderBottom :'solid 1px'}}>
                                    <input type="text" className="" value={question.questionName} style={{border:'none',width:'100%',background:'#f8fafa'}} onChange={(e)=>{handleQuestionName(e,questionIndex)}}/>
                                  </div>
                                  <div style={{}}>
                                      <ul className="list-group" style={question.questionType !== "Member" && question.questionType !== "Client" ? { listStyleType:'none'} : {listStyleType:'none',height:'80px',overflow:'scroll'}}>
                                          {question.option.map((options, optionIndex) => {
                                              return (
                                                //row[item.columnName]
                                                <li className='list-group-items mt-2 d-flex' style={{}}>
                                                  {question.questionType === "Checkboxes" ?
                                                  <>
                                                   <label style={{display:'flex',alignItems:'center' }}>
                                                    {optionIndex === 0 ?  <input type='checkbox' style={{ width: '15px', height: '15px' }} className='col-md-3' onChange={(e) => {}} disabled checked/> : <input type='checkbox' style={{ width: '15px', height: '15px' }} className='col-md-3' onChange={(e) => {}} disabled /> }
                                                    
                                                    <span className="disabled-checkmark" style={{marginRight:'10px'}}></span>
                                                    <input type='text' value={options} placeholder={"Options " + `${optionIndex+1}`} onChange={((e)=>{handleOptionInput(e,questionIndex,optionIndex)})} style={{marginRight:'10px'}}/>
                                                  </label>
                                                  </>
                                                  :
                                                  question.questionType === "Multiple Choice" ?
                                                  <>
                                                  <div style={{display:'flex',alignItems:'center' }}>
                                                    {optionIndex === 0 ? <input type='radio' style={{ width: '15px', height: '15px' }} className='col-md-3' onChange={(e) => {}} disabled checked/> : <input type='radio' style={{ width: '15px', height: '15px' }} className='col-md-3' onChange={(e) => {}} disabled/> }
                                                    
                                                    <input type='text' value={options} placeholder={"Options " + `${optionIndex+1}`} onChange={((e)=>{handleOptionInput(e,questionIndex,optionIndex)})} style={{marginRight:'10px',marginLeft:'10px'}}/>
                                                    
                                                  </div>
                                                    
                                                  </>
                                                  :
                                                  question.questionType === "Dropdown" ?
                                                  <>
                                                  <div style={{display:'flex',alignItems:'center' }}>
                                                    <span>{optionIndex+1 + "."}</span>
                                                    <input type='text' value={options} placeholder={"Options " + `${optionIndex+1}`} onChange={((e)=>{handleOptionInput(e,questionIndex,optionIndex)})} style={{marginRight:'10px',marginLeft:'10px'}}/>
                                                  </div>
                                                    
                                                  </>
                                                  :
                                                  question.questionType === "Member" ?
                                                  <>

                                                  <div style={{display:'flex',alignItems:'center' }}>
                                                    <span>{optionIndex+1 + "."}</span>
                                                    <span style={{marginLeft:'10px',marginRight:'10px'}}>
                                                      {options}
                                                    </span>
                                                    {/* <input type='text' value={options} placeholder={"Options " + `${optionIndex+1}`} onChange={((e)=>{handleOptionInput(e,questionIndex,optionIndex)})} style={{marginRight:'10px',marginLeft:'10px'}}/> */}
                                                    
                                                   
                                                  </div>
                                                    
                                                  </>
                                                  :
                                                  question.questionType === "Client" ?
                                                  <>
                                                  <div style={{display:'flex',alignItems:'center'}}>
                                                  <span>{optionIndex+1 + "."}</span>
                                                    <span style={{marginLeft:'10px',marginRight:'10px'}}>
                                                      {options}
                                                    </span>
                                                  </div>
                                                  </>
                                                  :
                                                  <>
                                                  </>
                                                  }
                                                 {question.questionType !== "Client" && question.questionType !== "Member" ?
                                                 <div className="delete-option-icon">
                                                  <MdRemoveCircleOutline size={18} style={{cursor:'pointer',color:Colors.redButton}} onClick={()=>{removeOptions(questionIndex,optionIndex)}}/>
                                                </div>
                                                 
                                                  :<></>
                                                 }
                                                 
                                                </li>
                                              )
                                          })}
                                          {question.questionType !== "Client" && question.questionType !== "Member" ?
                                          <li className="mt-3">
                                           <C_Button
                                              width={"130px"}
                                              height={"30px"}
                                              buttonText={"Add Option"}
                                              onClick={() => {addOptions(questionIndex)}}
                                              paddingLeft={"10px"}
                                              paddingRight={"10px"}
                                              gap={"10px"}
                                              icon={<FiPlusCircle size={13}/>}
                                              />
                                          
                                          </li>
                                          :<></>}
                                        </ul>
                                  </div>
                                </div>
                                <div className="col-2 d-flex" style={{textAlign:'center',alignItems:'center',position:'relative',justifyContent:'center'}}>
                                <select className="form-select font-xxs" onChange={(e) => {changeQuestionType(e,questionIndex)}} style={{position:'absolute',alignItems: 'center', top:'0', width:'120px', color: '#000000', marginRight: '5px', borderRadius: '8px' }}>
                                  <option selected value={question.questionType}>{question.questionType}</option>
                                  {formComponents.map(item=>{
                                    if(item.Type !== question.questionType)
                                    {
                                      return(
                                        <option value={item.Type}>{item.Type}</option>
                                      );
                                    }
                                  
                                  })}
                                </select>
                                </div>
                                <div className="col-1 d-flex" style={{justifyContent:'end'}}>
                                <RiDeleteBin5Line size={18} style={{cursor:'pointer',color:Colors.redButton}} onClick={()=>{deleteQuestion(questionIndex)}}/>
                                </div>
                              </div>
                              <div className="d-flex" style={{position:'absolute',bottom:'0',right:'0',paddingRight:'20px',paddingBottom:'15px',textAlign:'center',alignItems:'center'}}>
                                    <span className="font-xxs">Required Input</span>
                                    <span className="mx-1" > | </span>
                                    <Switch
                                      checked={questionsArray[questionIndex].requiredInput}
                                      onChange={(e) => {handleQuestionRequiredInput(questionIndex);}}
                                      onColor="#4BAB00"
                                      onHandleColor="#FFFFFF"
                                      handleDiameter={15}
                                      uncheckedIcon={false}
                                      checkedIcon={false}
                                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                      height={15}
                                      width={35}
                                    />
                                </div>
                            </div>
                          </>
                        )
                      }
                     
                    })}
                    </div>


                </div>
            </div>
            <div className="col-md-3 card px-3 py-4" style={{border:'none',background:'#fcffff',boxShadow: '1px 1px 1px 1px rgba(0,0,0,0.1)',height:TABLE_TOTAL_HEIGHT,position:'relative'}}>
                <h6 style={{whiteSpace:'nowrap',paddingLeft:'20px',margin:'0'}}>Form Options </h6> 
                {isHeaderEmpty ? <span className="font-xxs" style={{color:Colors.redButton,paddingLeft:'20px'}}>Please make sure that the required fields are not empty !</span>: <></>}
                <div className="card-body" style={{height:'100%',paddingBlock:'1rem'}}>
                    <div className="d-flex" style={{height:'35%', flexDirection:'column', justifyContent:'space-evenly',paddingBottom:'10px'}}>
                      <div className="details-tab d-flex" onClick={()=>{showModalFunc();setClicked(true)}}>
                        {currMemberList.length === 0 ? <AiOutlineUsergroupAdd className="tab-icon" size={25} />: <span className="tab-target">{currMemberList.length}</span>}
                        {currMemberList.length === 0 ? <span className="tab-text">Add Target Member</span>: <span className="tab-text">Members Are Added</span>}
                      </div>
                      
                      <div className="d-flex flex-column" >
                          <label className="form-label-field">Title<span className="font-xs" style={{color:Colors.redButton}}>*</span></label>
                          <input className="form-input-field" type="text" value={title} onChange={(e)=> {setTitle(e.target.value)}}/>
                      </div>
                      <div className="d-flex flex-column" >
                        <label className="form-label-field" style={{whiteSpace:'nowrap'}}>Effective Date<span className="font-xs" style={{color:Colors.redButton}}>*</span></label>
                        <input className="form-input-field" type="datetime-local" value={effectiveDate} onChange={(e)=> {setEffectiveDate(e.target.value)}}/>
                      </div>
                      <div className="d-flex flex-column" >
                          <label className="form-label-field" style={{whiteSpace:'nowrap'}}>{`Expiry Date(optional)`}</label>
                          <input className="form-input-field" type="datetime-local" value={expiryDate} onChange={(e)=> {setExpiryDate(e.target.value)}}/>
                      </div>
                      <div className="d-flex flex-column" >
                          <label className="form-label-field" style={{whiteSpace:'nowrap'}}>{`Description(optional)`}</label>
                          <textarea className="form-input-field" onChange={(e)=> {setDescription(e.target.value)}}>{description}</textarea>
                      </div>
                    </div>
                      <div className="form-component-container">
                        {formComponents.map((form,index)=>{
                          const IconComponent = Icons[form.Icon];  
                          return (
                            <div className="component-button">
                                <C_Button
                                    background={"linear-gradient(to right top, #e6e1f8, #e0d9f6, #dad1f5, #d4c9f3, #cec1f1)"}
                                    width={"100%"}
                                    height={"40px"}
                                    buttonText={form.Name}
                                    justify={"space-between"}
                                    onClick={() => {addQuestions(form.Type,form.DataType);}}
                                    // allRef.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' })
                                    color={"#000000"}
                                    paddingLeft={"10px"}
                                    paddingRight={"10px"}
                                    icon={<IconComponent size={14}/>}
                                    />
                            </div>
                          );
                        })}
                        {currUserSA.length > 0 && 
                          <div className="component-button">
                                <C_Button
                                    background={"linear-gradient(to right top, #e6e1f8, #e0d9f6, #dad1f5, #d4c9f3, #cec1f1)"}
                                    width={"100%"}
                                    height={"40px"}
                                    buttonText={'Member'}
                                    justify={"space-between"}
                                    onClick={() => {addQuestions('Member','String');}}
                                    color={"#000000"}
                                    paddingLeft={"10px"}
                                    paddingRight={"10px"}
                                    icon={<Icons.BsPersonSquare size={14}/>}
                                    />
                          </div>
                        }
                        {client.length > 0 &&
                            <div className="component-button">
                              <C_Button
                                  background={"linear-gradient(to right top, #e6e1f8, #e0d9f6, #dad1f5, #d4c9f3, #cec1f1)"}
                                  width={"100%"}
                                  height={"40px"}
                                  buttonText={'Client'}
                                  justify={"space-between"}
                                  onClick={() => {addQuestions('Client','String');}}
                                  color={"#000000"}
                                  paddingLeft={"10px"}
                                  paddingRight={"10px"}
                                  icon={<Icons.BsPeopleFill size={14}/>}
                                  />
                            </div>
                        }
                      </div>
                    <div className="d-flex" style={{justifyContent:'end',height:'10%',paddingBlock:'0.5rem', alignItems:'center'}}>
                      <C_DialogButton
                        onClick={()=>{saveModalFunc()}}
                        color={"white"}
                        background={formTemplateID !== "" ? '#6d45b9' : '#3eb986'}
                        borderColor={formTemplateID !== "" ? '#6d45b9' : '#3eb986'}
                        buttonText={formTemplateID !== "" ? "UPDATE" : "CREATE"}
                      />
                    </div>
                  </div>
              </div>
              <div className="modal fade" tabIndex="-1" ref={modalRef} style={{}}>
                <div className="modal-dialog modal-dialog-centered" style={{}}>
                    <div className="modal-content px-3" style={{ minHeight: "556px", overflowY: "scroll" }}>
                        <div className="modal-header modalHeader">
                            <div className="title">
                                <CgUserList size={16}/>
                                <span>Target Members</span>
                            </div>
                            <AiOutlineClose cursor='pointer' size={16} onClick={() => { hideModalFunc(); }}/>
                        </div>
                        <div className="modal-body d-flex row">
                            <div className="selectedListContainer">
                                {currMemberList.length > 0 && [...currMemberList].sort((a, b) => { 
                                    if(a.uid === user?.uid) return -1;
                                    if(b.uid === user?.uid) return 1;
                                    let aName = ""
                                    let bName = ""
                                    targetMemberList.map((member)=>{
                                      
                                      if(member.uid === a.uid){
                                        aName = member.displayName
                                      }
                                      if(member.uid === b.uid){
                                        bName = member.displayName
                                      }
                                    })
                                    return aName.localeCompare(bName);
                                }).map(item => {
                                    const member = targetMemberList.find(m => m.uid === item);
                                    if (!member) {
                                      return null;
                                    }
                                    return(
                                      <>
                                        <div className="selectedMembers">
                                          {targetMemberList.map((member)=>{
                                            
                                              if(member.uid === item)
                                              {
                                                
                                                return (
                                                  <>
                                                    <span>{member.displayName}{item.uid === user?.uid && <span>{`(You)`}</span> }</span>
                                                    <div className="delete-option-icon">
                                                      <MdRemoveCircleOutline size={14} color="red" cursor="pointer" onClick={() => { removeSelectedMember(item) }}/>
                                                    </div>
                                                  </>
                                                );
                                              }
                                          })}
                                            
                                        </div>
                                      
                                      </>
                                    )
                                })
                                }
                            </div>
                            <C_Search
                                placeholder="Search"
                                onChange={(e) => {
                                    setSearchText(e.target.value);
                                }}
                                padding={"10px"}
                                value={searchText}
                                searchText={searchText}
                                closeFunction={() => {
                                    setSearchText("");
                                }}
                            />
                            <div className="memberListContainer">
                                {[...targetMemberList]?.sort((a, b) => {
                                    if(a.uid === user?.uid) return -1;
                                    if(b.uid === user?.uid) return 1;
                                    return a.displayName.localeCompare(b.displayName);
                                }).map(item => {
                                    let isSelected = currMemberList.includes(item.uid)
                                    return(
                                        <div
                                            className='memberContainer'
                                            onClick={() => { 
                                                if(isSelected){
                                                    removeSelectedMember(item.uid)
                                                }else{
                                                    handleSelectedMember(item.uid)
                                                }
                                        }}>
                                            <span>{item.displayName}{item.uid === user?.uid && <span>{`(You)`}</span> }</span>
                                            {isSelected ? <AiFillCheckCircle size={18} color="green"/> : <AiOutlineCheckCircle size={18}/>}
                                        </div>
                                    )
                                })}
                            </div>
                            <div className="actionContainer">
                                <C_Button
                                    width={"100%"}
                                    buttonText={"CLOSE"}
                                    onClick={() => {
                                        hideModalFunc();
                                    }}
                                    textColor={"#FFFFFF"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" tabIndex="-1" ref={saveFormRef} style={{}}>
                <div className="modal-dialog modal-dialog-centered" style={{}}>
                    <div className="modal-content px-3" style={{overflowY: "scroll" }}>
                        <div className="modal-header modalHeader">
                            <div className="title font-s">
                                <span>{formTemplateID ? "Update Form" : "Add Form"}</span>
                            </div>
                            <AiOutlineClose cursor='pointer' size={16} onClick={() => { closeModalFunc(); }}/>
                        </div>
                        <div className="modal-body">
                            <span className="font-xs">
                              {formTemplateID === "" && <>
                              {clicked ? <>{currMemberList.length > 0 ? `Are you sure you want to save this form with the selected target members? (${currMemberList.length} selected)` : "Are you sure you want to save this form without selecting any target members?"}</> :"Target member can be added to this form, are you sure you want to save this form without selecting any of them ?"}</>}
                              {formTemplateID && <>Are you sure to update this form?</>}
                            </span>
                            <div className="new-button-container">
                              <C_DialogButton
                                onClick={()=>{closeModalFunc();}}
                                color={formTemplateID !== "" ? '#6d45b9' : '#3eb986'}
                                background={'white'}
                                borderColor={formTemplateID !== "" ? '#6d45b9' : '#3eb986'}
                                buttonText={"DISCARD"}
                              />
                              <C_DialogButton
                                onClick={()=>{formTemplateID !== "" ? updateForm() : saveForm();}}
                                color={"white"}
                                background={formTemplateID !== "" ? '#6d45b9' : '#3eb986'}
                                borderColor={formTemplateID !== "" ? '#6d45b9' : '#3eb986'}
                                buttonText={formTemplateID !== "" ? "UPDATE" : "CREATE"}
                              />
                              {/* <div className={`${formTemplateID !== "" ? "update-discard-button":"discard-button"} new-btn`} onClick={()=>{closeModalFunc();}}>
                                    DISCARD
                              </div>
                              <div className={`${formTemplateID !== "" ? "update-button":"save-button"} new-btn`} onClick={()=>{formTemplateID !== "" ? updateForm() : saveForm();}}>
                                    {formTemplateID !=="" ? "UPDATE" : "SAVE"}
                              </div>
                             */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
  
  export default FormsSettings;
  