import { Fragment, useEffect, useState } from "react";
import C_DialogButton from "../../../components/C_DialogButton";
import { bulkTransferClient, selectAvailableClientsGroup, selectClientContext, setClientContext, singleTransferClient } from "../../../stores/slices/clientSlices";
import "./ClientTransferModal.scss";
import dateFormat from "../../../enums/json/dateType.json";
import PhoneInput from "react-phone-input-2";
import { useDispatch } from "react-redux";
import { v4 } from "uuid";
import moment from "moment";
import { selectCurrSa, selectCurrTenant } from "../../../stores/slices/persistRolesSlices";
import { HiOutlineArrowsRightLeft } from "react-icons/hi2";
import { selectCurrUserClientGroupRoles } from "../../../stores/slices/clientGroupRolesSlices";
import { selectUser } from "../../../stores/slices/userAuthSlices";

export const ClientTransferModal = (props) => {
    
    const {
        filteredClientData,
        filteredRowData,
        clientColumnArray,
        checkedClientState,
        clientRteIndex,
        tempClientMergedData,
        clientColArray,
        clientCsvTemplateHeader,
        clientCsvData,
        clientCsvHeader,
        selectedGroupValue,
        clientColData,
        exportHandlerType,
        filteredClientColumnArray,
        filteredMergedData
      } = selectClientContext();
    //   mixedClients !== 0 ? mixedClients + " selected" :

    const clientTransferRef = props.clientTransferRef;
    const mixedClients = props.mixedClients;
    const tempClient = props.tempClient;
    const setNotifyMessage = props.setNotifyMessage;

    const [targetTransferGroupColumnData, setTargetTransferGroupColumnData] = useState([]);
    const [forwardedAffectedColumn, setForwardedAffectedColumn] = useState([]);
    const [destinationViewableColumn, setDestinationViewableColumn] = useState([]);
    const dispatch = useDispatch();

    const availableClientsGroup = selectAvailableClientsGroup();
    const [transferableGroups, setTransferableGroups] = useState([]);
    const [destinationClientData, setDestinationClientData] = useState([]);
    const [sourceClientData, setSourceClientData] = useState([]);
    const [targetGroupID, setTargetGroupID] = useState("");
    const tenant = selectCurrTenant();
    const currUserClientGroupRole = selectCurrUserClientGroupRoles();
    const ViewableColumn = currUserClientGroupRole ? currUserClientGroupRole?.ViewableColumn : [];
    const EditableColumn = currUserClientGroupRole ? currUserClientGroupRole?.EditableColumn : [];
    const user = selectUser();
    const currSa = selectCurrSa();

    useEffect(() => {
       const parsedGroups = availableClientsGroup.filter(group => group.GroupID !== selectedGroupValue?.GroupID).map((group) => {
            return {...group, ColumnData : JSON.parse(group.ColumnData)};
       })

       setTransferableGroups(parsedGroups);
    }, [availableClientsGroup])

    const handleBulkTransfer = (targetID) => {
      if(targetID !== ""){
        setTargetGroupID(targetID);
        const targetTransferColumn = transferableGroups.find((group) => group.GroupID === targetID).ColumnData;
        setTargetTransferGroupColumnData(targetTransferColumn);
        
        const currentColumn = [...clientColumnArray];

        //start
        const bulkClientData = checkedClientState.map((id) => {
          const clientDetails = tempClient.find((client) => client.ClientID === id); 

          if(clientDetails){
            const clientData = clientDetails.ClientData;
            const reducedCurrClientData =  clientData ? clientData.map(res => {
              const columnDetails = currentColumn.find(col => col.columnID === res.columnID);
              if(columnDetails){
                return {
                  ...res,
                  columnType: columnDetails.columnType,
                  columnName: columnDetails.columnName
                }
              }
            }).filter(item => item !== null) : [];

            return reducedCurrClientData;
          }
          return null;
        }).filter(item => item !== null);
        //end

        let column = [];
        let destinationViewableCol = [];
        //start

        const bulkTransferData = bulkClientData.map((client) => {

          destinationViewableCol = ViewableColumn.map((viewableCol) => {
            const sourceObj = client?.find(data => data.columnID === viewableCol);
            if(sourceObj){
              return {
                columnID : viewableCol,
                columnName : sourceObj.columnName,
                columnType : sourceObj.columnType
              }
            }
          }).filter(data => data !== undefined);

          const transferData = targetTransferColumn.sort((a,b) => a.columnIndex-b.columnIndex).map((col)=>{
            let rowValue = "";
            let otherValue = "";

            const transferDataDetails = client.find(data => data.columnName === col.columnName && data.columnType === col.columnType);
            
            if(transferDataDetails){
              //carry forwarded affected columns
              if(!column.includes(col.columnID)){
                column.push(col.columnID)
              }
              
              rowValue = transferDataDetails.rowValue;
              if(col.columnType === "Checkboxes"){
                rowValue = transferDataDetails.rowValue;
                otherValue = transferDataDetails.otherValue;
              }

            } else {
              switch (col.columnType) {
                case "Form":
                  rowValue = "-";
                  break;
                case "Checkboxes":
                  rowValue = [];
                  break;
                case "Rich Text Editor":
                  rowValue = "";
                  break;
                case "Switch":
                  rowValue = true;
                  break;
                case "ClientPassword":
                  rowValue = "abc123";
                  break;
                case "Alert":
                  rowValue = { status: false, expiry: null };
                  break;
                case "CreatedAt":
                  rowValue = moment().format("YYYY-MM-DD HH:mm:ss");
                  break;
              }
            }
            if(col.columnType === "Checkboxes"){
              return { 
                rowID: v4(), 
                columnID: col.columnID, 
                rowValue,
                otherValue
              };
            } else {
              return { 
                rowID: v4(), 
                columnID: col.columnID, 
                rowValue,
              };
            }
            
          })
          return transferData;
        })
        //end


        setForwardedAffectedColumn(column);
        setDestinationViewableColumn(destinationViewableCol);

        setSourceClientData(bulkClientData)
        setDestinationClientData(bulkTransferData);
      }
    }

    const handleInput = (e, index, columnIndex, type, alertType, otherValue) => {
      const userData = [...destinationClientData];
      const newRowData = { ...userData[index][columnIndex] };
    
      if (type === "Phone") {
        newRowData.rowValue = e;
      } else if (type === "Google Map") {
        let value = e.target.value;
        let location = clientColumnArray.find(element => element.columnType === "Google Map");
  
        if (location !== undefined) {
          newRowData.rowValue = decodeURIComponent(value);
        }
      } else if (type === "Multiple Choices") {
        newRowData.rowValue = e.target.value;
      } else if (type === "Checkboxes") {
        if(otherValue){
          let value = e.target.value;
          newRowData.otherValue = value;
        } else {
          if (Array.isArray(newRowData.rowValue)) {
            if (e.target.checked) {
              newRowData.rowValue = [...newRowData.rowValue, e.target.value];
            } else {
              newRowData.rowValue = newRowData.rowValue.filter(val => val !== e.target.value);
            }
          }
        }
       
      } else if (type === "Alert") {
        newRowData.rowValue = { ...newRowData.rowValue, [alertType]: e.target.value };
      } else {
        let value = e.target.value;
        let rte = clientColumnArray.find(element => element.columnType === "Rich Text Editor");
  
        newRowData.rowValue = value;
      }
    
      userData[index][columnIndex] = newRowData;
      setDestinationClientData(userData);
    };

    const transferPreviewRenderer = (col, index, category, id) => {
      const columnWidth = col.width || "25%";
      const columnID = col.columnID;
      const columnIndex = col.columnIndex;
      const name = col.columnName;
      const type = col.columnType;
      let otherData = category === "destination" ? destinationClientData[index][columnIndex].otherValue : sourceClientData[index][columnIndex].otherValue;
      let data = category === "destination" ? destinationClientData[index][columnIndex].rowValue : sourceClientData[index][columnIndex].rowValue;
      const optionChoices = category === "destination" ? destinationClientData[index][columnIndex].optionChoices : sourceClientData[index][columnIndex].optionChoices;
      const otherOption = category === "destination" ? destinationClientData[index][columnIndex].otherOption : sourceClientData[index][columnIndex].otherOption;
      const dateType = col.dateType;
      const option = col.option;
      let unfitOptions = [];

      const isAffectedColumn = forwardedAffectedColumn.includes(columnID);
      const isDestinationViewable = destinationViewableColumn.some(viewableCol => viewableCol.columnName === name && viewableCol.columnType === type);

      //if is not affected then return true
      //if is affected check if is the input editable
      const clientObj = tempClient.find(client => client.ClientID === id)
      const isCreatedByUser = clientObj ? user.uid === clientObj.UserID : false;
      const isDestinationEditable = category === "destination" && (currSa ? true : ((!isCreatedByUser && isAffectedColumn) ? isDestinationViewable : true)) 
      const isSourceViewable = category === "source" && (currSa ? true : (!isCreatedByUser ? ViewableColumn.includes(columnID) : true))
      const viewable = (isSourceViewable || isDestinationEditable);

      const inputClass = isDestinationEditable ? "" : "source-input-bg";
      const disabledInput = category === "source" ? true : !viewable;

      //destination which column is matched with 

      if(type !== "Alert" && type !== "Switch" && type !== "Form"){
          if(type === "Checkboxes" && category === "destination"){
            // data = data.filter(ans => option.includes(ans));
            unfitOptions = data.filter(ans => !option.includes(ans));
          }

          return (
              <div className="data" key={columnIndex}>
                  <span className="title">{name} : </span>
                  {/* <input 
                      className="input-value"
                      value={data}
                  /> */}
                  {/* {columnType === "Alert" ?
                  (isSuperAdmin || clientGroupLeader.some(element=>element.UserID === user.uid)) ?
                    <input
                      className={`transfer-input`}
                      type={"date"}
                      value={selectedClient && selectedClient[columnIndex]?.rowValue?.expiry ? selectedClient[columnIndex]?.rowValue?.expiry : null}
                      name={columnName}
                      onChange={(e) => { handleInput(e, columnIndex, columnType, 'expiry'); }}
                    />
                    :
                    null
                    :
                    null
                } */}
                
                  {type === "Date" &&
                    <input
                      type={viewable ? dateFormat[dateType] : "text" }
                      className={`transfer-input ${inputClass}`}
                      value={viewable ? dateType === "YYYY" ? data : moment(data).format(dateType) : "N/A (Restricted)"}
                      name={name}
                      onChange={(e) => { handleInput(e, index, columnIndex, type); }}
                      disabled={disabledInput}
                    />
                  }

                  {type === "Member" &&
                    <>
                      <select
                        className={`transfer-input ${inputClass}`}
                        name={name}
                        value={viewable ? data : "N/A (Restricted)"}
                        onChange={(e) => { handleInput(e, index, columnIndex, type); }}
                        disabled={disabledInput}
                        >
                        <option selected value={"-"}>{"Select a Member"}</option>
                        {filteredMergedData?.map(user => {
                          return <option value={user?.uid}>{user?.displayName}</option>
                        })}
                      </select>
                    </>
                  }

                  {type === "Short Text" &&
                    <input
                      className={`transfer-input ${inputClass}`}
                      placeholder={"Enter " + name}
                      name={name}
                      value={viewable ? data : "N/A (Restricted)"}
                      onChange={(e) => {
                        handleInput(e, index, columnIndex, type);
                      }}
                      disabled={disabledInput}
                    />
                  }

                  {type === "Multiline" &&
                    <textarea
                      className={`transfer-input ${inputClass}`}
                      placeholder={"Enter " + name}
                      name={name}
                      value={viewable ? data : "N/A (Restricted)"}
                      onChange={(e) => {
                        handleInput(e, index, columnIndex, type);
                      }}
                      disabled={disabledInput}
                    />
                  }

                  {type === "Email" &&
                    <input
                      className={`transfer-input ${inputClass}`}
                      placeholder={"Enter " + name}
                      name={name}
                      value={viewable ? data : "N/A (Restricted)"}
                      onChange={(e) => {
                        handleInput(e, index, columnIndex, type);
                      }}
                      disabled={disabledInput}
                    />
                  }

                  {type === "Phone" &&
                
                    <PhoneInput
                      inputClass="phone-input"
                      className={`transfer-phone-input ${inputClass}`}
                      country={'my'}
                      preferredCountries={['my']}
                      value={viewable ? data : "N/A (Restricted)"}
                      disabled={disabledInput}
                      onChange={(e)=>{handleInput(e, index, columnIndex, type);}}
                    />
                  }

                  {type === "Link" &&
                    <>
                      <input
                        className={`transfer-input ${inputClass}`}
                        placeholder={"Enter Your Link Here"}
                        name={name}
                        value={viewable ? data  : "N/A (Restricted)"}
                        onChange={(e) => {
                          handleInput(e, index, columnIndex, type);
                        }}
                        disabled={disabledInput}
                      />
                    </>
                  }

                  {type === "Google Map" &&
                    <input
                      className={`transfer-input ${inputClass}`}
                      placeholder={"Enter Your Location Link"}
                      name={name}
                      value={viewable ? data : "N/A (Restricted)"}
                      onChange={(e) => {
                        handleInput(e, index, columnIndex, type);
                      }}
                      disabled={disabledInput}
                    />
                  }

                  {/* {type === "Rich Text Editor" &&
                    <ReactQuill
                      className={`member-rte`}
                      theme="snow"
                      placeholder={mixedClients !== 0 ? mixedClients !== undefined ? mixedClients + " selected" : "Enter your Description" : "Enter Your Description"}
                      value={data}
                      onChange={(e) => {
                        e !== undefined && handleRteInput(e, columnIndex)
                      }} />
                  } */}

                  {type === "Dropdown" &&
                      <div className="dropdown-input-container">
                        {optionChoices === undefined ?
                          <select value={viewable ? data !== "null"  ? data : "" : "N/A (Restricted)"} className={`form-select filter-card-input font-xxs`} onChange={(e) => {
                            handleInput(e, index, columnIndex, type);
                          }}
                          style={(otherOption !== undefined && otherOption) ? { height:"37px", width:"75%" } : { height:"37px", width:"100%" }}
                          disabled={disabledInput}
                          >
                            <option selected disabled value=""></option>
                            {option?.map((opt, optIndex) => {
                                return (
                                    <option value={opt}>{opt}</option>
                                );
                            })}
                          </select>
                          :
                          <>
                            {(optionChoices === "Default") ?
                              <select value={viewable ? data !== "null"  ? data : "" : "N/A (Restricted)"} className={`form-select filter-card-input font-xxs`} onChange={(e) => {
                                handleInput(e, index, columnIndex, type);
                              }}
                              style={(otherOption !== undefined && otherOption) ? { height:"37px", width:"75%" } : { height:"37px", width:"100%" }}
                              disabled={disabledInput}
                              >
                                <option selected disabled value=""></option>
                                {option?.map((opt, optIndex) => {
                                    return (
                                        <option value={opt}>{opt}</option>
                                    );
                                })}
                              </select>
                            :
                              <input
                                className={`transfer-input ${inputClass}`}
                                placeholder={data === "null" ? data : "Enter " + name}
                                name={name}
                                value={viewable ? data  && data !== "null"  ? data : "" : "N/A (Restricted)"}
                                onChange={(e) => {
                                  handleInput(e, index, columnIndex, type);
                                }}
                                disabled={disabledInput}
                              />
                            }
                          </>
                        }
                        
                        {(otherOption !== undefined && otherOption) &&
                          <select value={viewable ? optionChoices : "N/A (Restricted)"} className={`form-select filter-card-input font-xxs`} onChange={(e) => {
                            handleDropdownChoices(e, columnIndex, type);
                          }}
                          style={{ height:"37px", width:"25%" }}
                          disabled={disabledInput}
                          >
                            <option value={"Default"} selected={optionChoices === "Default" ? true : false}>Default</option>
                            <option value={"Others"} selected={optionChoices === "Others" ? true : false}>Others</option>
                          </select>
                        }
                      </div>
                  }

                  {type === "Multiple Choices" &&
                    <Fragment>
                      {viewable ? 
                         <div className={`transfer-mcq-container`}>
                         {option?.map((opt, optIndex) => {
                             return (
                                 <div className="transfer-mcq">
                                   <input 
                                    type={"radio"} 
                                    value={opt} checked={data === opt} 
                                    onChange={(e) => {handleInput(e, index, columnIndex, type); }} 
                                    disabled={disabledInput}
                                   />
                                   <label className="transfer-mcq-span" title={opt}>{opt}</label>
                                 </div>
                             );
                         })}
                         </div>
                         :
                         <input
                          className={`transfer-input ${inputClass}`}
                          placeholder={"Enter Your Answer"}
                          name={name}
                          value={viewable ? data : "N/A (Restricted)"}
                          onChange={(e) => {
                            handleInput(e, index, columnIndex, type);
                          }}
                          disabled={disabledInput}
                        />
                      }
                    </Fragment>
                  }

                  {type === "Checkboxes" &&
                    <Fragment>
                       {category === "destination" ? 
                       <div className="transfer-mcq-outer-container">
                        <div className={`transfer-mcq-container`}>
                        {viewable ? option.concat(unfitOptions)?.map((opt, optIndex) => {
                            return (
                                <div className="transfer-mcq">
                                  <label style={{ display: 'flex', alignItems: 'center' }}>
                                        <input type={"checkbox"} value={opt} checked={
                                          viewable ?
                                          data !== undefined ?
                                          data.includes(opt) : ""
                                          : false
                                        } 
                                          onChange={(e) => {viewable ? handleInput(e, index, columnIndex, type) : ""}} 
                                          disabled={disabledInput}
                                        />
                                        <span className={`checkmark ${viewable ? "" : "disabled-checkbox-bg"}`} style={{ marginRight: '10px' }}></span>
                                        <span className={`transfer-mcq-span font-xs ${unfitOptions.includes(opt) ? "unfit-option" : ""}` } title={unfitOptions.includes(opt) ? "Unavailable option. Uncheck to remove it from the list." : opt}>{opt}</span>
                                  </label>
                                </div>
                            );
                        })
                        : 
                          option?.map((opt, optIndex) => {
                          
                            return (
                                <div className="transfer-mcq">
                                  <label style={{ display: 'flex', alignItems: 'center' }}>
                                        <input type={"checkbox"} value={opt} checked={
                                          viewable ?
                                          data !== undefined ?
                                          data.includes(opt) : ""
                                          : false
                                        } 
                                          onChange={(e) => {viewable ? handleInput(e, index, columnIndex, type) : ""}} 
                                          disabled={disabledInput}
                                        />
                                        <span className={`checkmark ${viewable ? "" : "disabled-checkbox-bg"}`} style={{ marginRight: '10px' }}></span>
                                        <span className={`transfer-mcq-span font-xs ${unfitOptions.includes(opt) ? "unfit-option" : ""}` } title={unfitOptions.includes(opt) ? "Unavailable option. Uncheck to remove it from the list." : opt}>{opt}</span>
                                  </label>
                                </div>
                            );
                          })
                        }
                       </div>
                       <div className="transfer-mcq-other-input-container">
                        <input 
                          className={`transfer-mcq-other-input ${inputClass}`}
                          type="text" 
                          title="Other Options"
                          value={viewable ? otherData ? otherData : "" : "N/A (Restricted)"}
                          onChange={(e)=>{handleInput(e, index, columnIndex, type, null, "otherValue")}}
                          placeholder="Other Options"
                          disabled={disabledInput}
                        />
                       </div>
                      </div>
                      // end viewable condition
                       :
                       <div className="transfer-mcq-outer-container">
                        <div className={`transfer-mcq-container`}>
                        {viewable ? Array.isArray(data) && data?.map((opt, optIndex) => {
                            return (
                                <div className="transfer-mcq">
                                  <label style={{ display: 'flex', alignItems: 'center' }}>
                                      <span className="transfer-mcq-span font-xs" title={opt}>{opt}</span>
                                  </label>
                                </div>
                            );
                        }) : "N/A (Restricted)"}
                        </div>
                        <div className="transfer-mcq-other-input-container">
                          <input 
                            className={`transfer-mcq-other-input ${inputClass}`}
                            type="text" 
                            title="Other Options"
                            value={viewable ? otherData ? otherData : "" : "N/A (Restricted)"}
                            onChange={(e)=>{handleInput(e, index, columnIndex, type, null, "otherValue")}}
                            placeholder="Other Options"
                            disabled
                          />
                        </div>
                       </div>
                       }
                    </Fragment>
                  }

                  {type === "Numbers" &&
                    <input
                      type={"number"}
                      className={`transfer-input ${inputClass}`}
                      placeholder={"Enter " + name}
                      name={name}
                      value={viewable ? data : "N/A (Restricted)"}
                      onChange={(e) => {
                        handleInput(e, index, columnIndex, type);
                      }}
                      disabled={disabledInput}
                    />
                  }
              </div>
          )
        }
    }

    function transferClient(){
      // TenantID, ClientID, GroupID, ClientData
      // const ClientID = checkedClientState[0];
      // const ClientData = JSON.stringify(destinationClientData);

      const GroupID = targetGroupID;
      const data = checkedClientState.reduce((acc, res, index) => {
        const ClientID = res;
        const ClientData = JSON.stringify(destinationClientData[index]);
        const clientDetails = {ClientID : ClientID, ClientData : ClientData};
        acc.push(clientDetails);

        return acc;
      }, [])

      dispatch(bulkTransferClient({
        TenantID: tenant,
        GroupID: GroupID,
        data: data,
        setNotifyMessage
      }))
      dispatch(setClientContext({
        checkedClientState : [],
        checkedAllClientState : false
      }))
      setDestinationClientData([]);
      setSourceClientData([]);
      setTargetTransferGroupColumnData([]);
      setTargetGroupID("");
      setForwardedAffectedColumn([]);
      setDestinationViewableColumn([]);

      // dispatch(singleTransferClient({
      //   TenantID : tenant,
      //   ClientID : ClientID,
      //   GroupID : GroupID,
      //   ClientData : ClientData
      // }))
    }

    return (

        <div
          className="modal fade new_member transfer-modal modal-lg"
          id="client-transfer"
          tabIndex="-1"
          ref={clientTransferRef}
        >
          <div className="modal-dialog modal-dialog-centered" style={{}}>
            <div className="modal-content client-transfer-content" style={{}}>
                <div className="modal-header modalHeader">
                    <div className="modal-title">Client Transfer Modal <div><HiOutlineArrowsRightLeft size={15}/></div></div>
                </div>
                <div className="modal-body client-transfer-body">
                    <div className="client-transfer-body-container">
                        {checkedClientState.length > 0 ? 
                        <Fragment>
                        <div className="target-group-container">
                            <span className="input-title">Target Group</span>
                            <select 
                              className="group-transfer-selection" 
                              defaultValue={""} 
                              value={targetGroupID}
                              onChange={(e)=>{
                                handleBulkTransfer(e.target.value)
                              }}
                            >
                                <option value={""} disabled></option>
                                {transferableGroups.map((group)=>{
                                    return <option value={group.GroupID}>{group.GroupName}</option>
                                })}
                            </select>
                        </div>
                      
                        {targetTransferGroupColumnData.length > 0 &&
                        <div className="client-transfer-container">
                            <span className="input-title">Client Data {`${checkedClientState.length > 1 ? `(${checkedClientState.length + " selected"})` : ""}`}</span>
                            <div className="data-transfer-outer-container">
                              {checkedClientState.map((id, index)=>{
                                return (
                                  <div className="data-transfer-container">
                                      {/* Source */}
                                      <span>{index + 1}.</span>
                                      <div className="transfer-container source-container-bg">
                                        <div className="transfer-category"><span className="source-span">Source</span></div>
                                        {clientColumnArray.map((col)=>transferPreviewRenderer(col, index, "source", id))}
                                      </div>
                                      {/* Destination */}
                                      <div className="transfer-container destination-container-bg">
                                        <div className="transfer-category"><span className="target-span">Target</span></div>
                                        {targetTransferGroupColumnData.map((col)=> transferPreviewRenderer(col, index, "destination", id))}      
                                      </div>
                                  </div>
                                );
                              })}
                            </div>
                            
                        </div>
                        }
                        </Fragment>
                        :
                        <div className="client-transfer-container">
                            Please checked at least 1 client to transfer
                        </div>
                        }
                    </div>
                </div>
                <div className="footer" >
                    <C_DialogButton
                        buttonText={"DISCARD"}
                        dismissAction={"modal"}
                        borderColor={"#4287f5"}
                        background={"#fff"}
                        color={"#4287f5"}
                        onClick={()=>{
                            dispatch(setClientContext({
                                checkedClientState : [],
                                checkedAllClientState : false
                            }))
                            setDestinationClientData([]);
                            setSourceClientData([]);
                            setTargetTransferGroupColumnData([]);
                            setTargetGroupID("");
                            setForwardedAffectedColumn([]);
                            setDestinationViewableColumn([]);
                        }}
                    />
                    {checkedClientState.length > 0 && 
                     <C_DialogButton
                          marginRight={10}
                          buttonText={"TRANSFER"}
                          dismissAction={"modal"}
                          background={"#4287f5 "}
                          onClick={()=>{
                            transferClient();
                          }}
                     />
                    }
                   
                </div>
            </div>
          </div>
        </div>
    )
}
