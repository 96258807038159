import React from "react";
import { Typography, Button, Stack, Avatar } from "@mui/material";
import moment from "moment";

import {
  LaunchRounded,
  CloseRounded,
  CheckRounded,
  VisibilityOffRounded,
  StarRateRounded,
  LockOutlined,
} from "@mui/icons-material";
import { TableActionEnum } from "../../enums/TableActionEnum";
import { ChipStatus } from "../../enums/components/Chip";
import { ChipText } from "../../components/Utils/ChipText";

export const TableCell = {
  STRING: (params) => {
    return (
      <Typography
        variant="bodyText"
        sx={({ typography }) => ({
          ...typography.bodyText,
          ...typography.textOverflow,
        })}
      >
        {params.value ? params.value : "-"}
      </Typography>
    );
  },
  BOLD_STRING: (params) => (
    <Typography
      key={params.id}
      variant="bodyText"
      sx={({ typography }) => ({
        ...typography.bodyTextSemiBold,
        ...typography.textOverflow,
      })}
    >
      {params.value ? params.value : "-"}
    </Typography>
  ),
  STRING_ACTIVATION: (params, is_active = true) => {
    return (
      <Stack direction="row" spacing={1}>
        <Typography
          variant="bodyText"
          sx={({ typography }) => ({
            ...typography.bodyText,
            ...typography.textOverflow,
          })}
        >
          {params.value}
        </Typography>
        {!is_active ? (
          <VisibilityOffRounded
            sx={({ icons }) => ({
              ...icons.standard,
            })}
          />
        ) : null}
      </Stack>
    );
  },
  ACCOUNTNAMESTRING: (params) => {
    const isLocked = params.row.is_locked;
    return (
      <Stack direction="row" spacing={1}>
        <Typography
          variant="bodyText"
          sx={({ typography }) => ({
            ...typography.bodyText,
            ...typography.textOverflow,
          })}
        >
          {params.value}
        </Typography>
        {isLocked ? (
          <LockOutlined
            sx={({ icons }) => ({
              ...icons.standard,
            })}
          />
        ) : null}
      </Stack>
    );
  },
  ACCOUNTSTRING: (params) => {
    const isParent = params.row.parent_id;
    return (
      <Typography
        variant="bodyText"
        sx={({ typography }) => ({
          ...typography.bodyText,
          ...typography.textOverflow,
          textIndent: !isParent ? "0px" : "40px",
        })}
      >
        {params.row.code}
      </Typography>
    );
  },
  TAGSTRING: (params) => {
    const is_active = params.value;
    const isTagGroup = !Object.prototype.hasOwnProperty.call(
      params.row,
      "tag_id"
    );
    return (
      <Stack direction="row">
        <Typography
          variant="bodyText"
          sx={({ typography }) => ({
            ...typography.bodyText,
            ...typography.textOverflow,
            textIndent: isTagGroup ? "0px" : "40px",
          })}
        >
          {params.row.name}
        </Typography>
        {!is_active ? (
          <VisibilityOffRounded
            sx={({ icons }) => ({
              ...icons.standard,
              marginLeft: "15px",
            })}
          />
        ) : null}
      </Stack>
    );
  },
  NUMBER: (params) => {
    const numberValue = params.value > 0 ? params.value : "-";
    return (
      <Typography
        key={params.id}
        variant="bodyText"
        sx={({ typography }) => ({
          ...typography.bodyText,
          ...typography.textOverflow,
        })}
      >
        {numberValue}
      </Typography>
    );
  },
  DATE: (params) => {
    const date = moment(params.value);
    const formattedDate = date.format("DD/MM/YYYY");
    return (
      <Typography
        variant="bodyText"
        sx={({ typography }) => ({
          ...typography.bodyText,
          ...typography.textOverflow,
        })}
      >
        {formattedDate}
      </Typography>
    );
  },
  DATETIME: (params) => {
    const moment = require("moment");

    const date = moment(params.value);
    // Format the date component
    const formattedDate = date.format("DD/MM/YYYY");

    // Format the time component
    const formattedTime = date.format("hh:mmA");
    return (
      <Stack direction={"row"}>
        <Typography
          variant="bodyText"
          sx={({ typography }) => ({
            ...typography.bodyText,
          })}
        >
          {formattedDate}
        </Typography>
        <Typography
          variant="bodyText"
          sx={({ typography }) => ({
            ...typography.bodyTextLight,
            ...typography.textOverflow,

            marginLeft: "2px",
          })}
        >
          {formattedTime}
        </Typography>
      </Stack>
    );
  },
  TAG: (params) =>
    params.value ? (
      <ChipText label={params.value} status={ChipStatus.Success} />
    ) : null,
  TAGINFO: (params) => {
    const isNotEmpty = params.value != null;

    return isNotEmpty ? (
      <ChipText label={params.value} status={ChipStatus.Info} />
    ) : null;
  },
  TAGS: (params) => (
    <Stack direction="row">
      {params.value
        ? params.value.map((tag, index) => {
            return (
              <ChipText
                key={`tag-${index}`}
                sx={{
                  marginLeft: index != 0 ? "5px " : "none",
                }}
                label={tag}
                status={ChipStatus.Info}
              />
            );
          })
        : null}
    </Stack>
  ),
  PAYMENT_STATUS: (params) => {
    const paymentStatus = params.value;
    const paymentStatusColor =
      paymentStatus == "Overdue"
        ? ChipStatus.Error
        : paymentStatus == "Coming Due"
        ? ChipStatus.Warning
        : ChipStatus.Success;

    return <ChipText label={paymentStatus} status={paymentStatusColor} />;
  },
  CREDIT_NOTE_STATUS: (params) => {
    const paymentStatus = params.value;
    const paymentStatusColor =
      paymentStatus == "Outstanding" ? ChipStatus.Error : ChipStatus.Success;

    return <ChipText label={paymentStatus} status={paymentStatusColor} />;
  },
  LINK: (params) => (
    <Stack direction="row"
      sx={()=>({
        alignItems:"center"
      })}
    >
      <Typography
        variant="bodyText"
        sx={({ typography }) => ({
          ...typography.textLink,
        })}
      >
        {params.value ? params.value : "-"}
      </Typography>

      {params.value ? (
        <LaunchRounded
          sx={({ icons }) => ({
            ...icons.standard.primary,
            marginLeft: "10px",
          })}
        />
      ) : (
        <></>
      )}
    </Stack>
  ),
  AMOUNT: (params) => {
    const isNotEmpty = params.value != null;
    const textValue = isNotEmpty ? `${params.value}` : "";
    return (
      <Typography
        key={params.id}
        variant="bodyText"
        sx={({ typography }) => ({
          ...typography.bodyText,
          ...typography.textOverflow,
        })}
      >
        {textValue}
      </Typography>
    );
  },

  PURCHASE_AMOUNT: (params) => {
    const isPurchase = params.value ? params.value : null;
    const symbol = params[TableActionEnum.CURRENCIES]
      ? params[TableActionEnum.CURRENCIES].value
      : params.row.currency;
    const label = isPurchase ? `${symbol} ${isPurchase}` : "-";
    return (
      <Typography
        key={params.id}
        variant="bodyText"
        sx={({ typography }) => ({
          ...typography.bodyText,
          ...typography.textOverflow,
        })}
      >
        {label}
      </Typography>
    );
  },
  SALE_AMOUNT: (params) => {
    const isSale = params.value ? params.value : null;
    const symbol = params[TableActionEnum.CURRENCIES]
      ? params[TableActionEnum.CURRENCIES].value
      : params.row.currency;
    const label = isSale ? `${symbol} ${isSale}` : "-";
    return (
      <Typography
        key={params.id}
        variant="bodyText"
        sx={({ typography }) => ({
          ...typography.bodyText,
          ...typography.textOverflow,
        })}
      >
        {label}
      </Typography>
    );
  },
  CURRENCY_RATE: (params) => {
    const isEffective = params.row.isEffective;
    return (
      <Stack direction="row" spacing={0.5} alignItems="center">
        <Typography
          key={params.id}
          variant="bodyText"
          sx={({ typography, palette }) => ({
            ...typography.bodyText,
            ...typography.textOverflow,
            ...(isEffective
              ? { fontWeight: "bold !important", color: palette.primary.main }
              : {}),
          })}
        >
          {`${params.value ? params.value : "-"}`}
        </Typography>
        {isEffective ? (
          <StarRateRounded
            sx={({ icons, palette }) => ({
              ...icons.standard,
              color: palette.primary.main,
            })}
          />
        ) : null}
      </Stack>
    );
  },
  ACTIONS: (
    <Button
      sx={[
        {
          minWidth: 100,
        },
      ]}
      variant={"Actions"}
      onClick={() => {}}
    >
      {"Actions"}
    </Button>
  ),
  DEPRECIATION: (params) => {
    const isDepreciationRate = params.value == "Rate";
    // const periodText = `${params.row.default_useful_year}`;
    // const rateText = `${params.row.default_depreciation_rate}%year`
    const depreciationText = isDepreciationRate
      ? `${params.row.default_depreciation_rate}% yearly`
      : `${params.row.default_useful_year} years ${params.row.default_useful_month} months`;
    return (
      <Typography
        key={params.id}
        variant="bodyText"
        sx={({ typography }) => ({
          ...typography.bodyText,
          ...typography.textOverflow,
        })}
      >
        {`${depreciationText}`}
      </Typography>
    );
  },
  ASSET_TYPE_NAME: (params) => {
    return (
      <Stack direction={"row"} key={params.id}>
        <Typography
          variant="bodyText"
          sx={({ typography }) => ({
            ...typography.bodyText,
          })}
        >
          {`${params.value}`}
        </Typography>

        <Typography
          variant="bodyText"
          sx={({ typography }) => ({
            ...typography.bodyText,
            ...typography.textOverflow,

            textIndent: "10px",
          })}
        >
          {`(${params.row.assets_count.toString()})`}
        </Typography>
      </Stack>
    );
  },
  USER_ROLE: (params) => {
    const role_name = params.value.role_name;
    const isOwner = params.row.details.is_owner;
    return (
      <Stack direction={"row"} key={params.id}>
        {isOwner ? (
          <ChipText
            sx={[
              {
                marginRight: "15px",
              },
            ]}
            label="Owner"
            status={ChipStatus.Error}
          />
        ) : null}
        <ChipText label={role_name} status={ChipStatus.Pending} />
      </Stack>
    );
  },

  BOOL_ICON: (params) => {
    const bool_icon = params.value ? (
      <CheckRounded
        key={params.id}
        sx={({ icons }) => ({
          ...icons.standard,
        })}
      />
    ) : (
      <CloseRounded
        key={params.id}
        sx={({ icons }) => ({
          ...icons.standard,
        })}
      />
    );
    return bool_icon;
  },

  DEFAULT_LOCATION: (params) => {
    const isDefault = params.row.default;
    return (
      <Stack direction={"row"} key={params.id}>
        <Typography
          variant="bodyText"
          sx={({ typography }) => ({
            ...typography.bodyText,
            alignSelf: "center",
            marginRight: "10px",
          })}
        >
          {params.value}
        </Typography>

        {isDefault ? (
          <ChipText label={"Default"} status={ChipStatus.Pending} />
        ) : null}
      </Stack>
    );
  },

  ACCOUNT_DETAILS: (params) => {
    const account = params.value;

    const account_code = account ? account.code : "";
    const account_name = account ? account.name : "-";
    return (
      <Stack direction={"row"} key={params.id}>
        <Typography
          variant="bodyText"
          sx={({ typography }) => ({
            ...typography.bodyText,

            fontWeight: "bold",
          })}
        >
          {account_code}
        </Typography>
        <Typography
          variant="bodyText"
          sx={({ typography }) => ({
            ...typography.bodyText,
            ...typography.textOverflow,

            marginLeft: "5px",
          })}
        >
          {account_name}
        </Typography>
      </Stack>
    );
  },

  CONTACT_TYPE: (params) => {
    // const contact_type_list = JSON.parse(params.value);
    const contact_type_list = params.value;
    return (
      <Stack direction="row" spacing={1}>
        {contact_type_list.map((contact_type) => {
          const lowerCasedValue = contact_type.toLowerCase();
          const contactType =
            lowerCasedValue == "employee"
              ? ChipStatus.Pending
              : lowerCasedValue == "supplier"
              ? ChipStatus.Error
              : ChipStatus.Success;
          const contactTypeFirstLetter = contact_type[0].toUpperCase();

          return (
            <ChipText
              key={`contact-type-${contact_type}`}
              label={contactTypeFirstLetter}
              status={contactType}
            />
          );
        })}
      </Stack>
    );
  },
  MARGIN: (params) => {
    const salePrice = params.row.for_sale ? params.row.sale_price : null;
    const purchasePrice = params.row.for_purchase
      ? params.row.purchase_price
      : null;
    const ableCalculateMargin = salePrice != null && purchasePrice != null;
    const marginPercentage = (salePrice, purchasePrice) => {
      return (
        ((parseFloat(salePrice) - parseFloat(purchasePrice)) /
          parseFloat(salePrice)) *
        100
      );
    };
    const returnedValue = ableCalculateMargin
      ? `${marginPercentage(salePrice, purchasePrice)}%`
      : "-";

    return (
      <Typography
        key={params.id}
        variant="bodyText"
        sx={({ typography }) => ({
          ...typography.bodyText,
        })}
      >
        {`${returnedValue}`}
      </Typography>
    );
  },
  IMAGE: (params) => {
    return <Avatar sx={{}} variant="square" src={params.value} />;
  },

  PERIOD: (params) => {
    const moment = require("moment");
    const startDate = moment(params.value);
    const endDate =
      params.row.end_date != null ? moment(params.row.end_date) : null;
    const formattedStartDate = startDate.format("DD/MM/YYYY");
    const formattedEndDate =
      endDate != null ? endDate.format("DD/MM/YYYY") : "Forever";

    return (
      <Typography
        key={params.id}
        variant="bodyText"
        sx={({ typography }) => ({
          ...typography.bodyText,
        })}
      >
        {`${formattedStartDate} - ${formattedEndDate}`}
      </Typography>
    );
  },

  FREQUENCY: (params) => {
    const year = params.value;
    const month = params.row.frequency_month;
    const week = params.row.frequency_week;
    const day = params.row.frequency_day;
    const isYear = year != null;
    const isWeek = week != null;
    const isMonth = !isYear && !isWeek && month != null;
    const isDay = !isYear && !isWeek && !isMonth;
    const yearText = `${year} year(s) on ${day}/${month}`;
    const monthText = `${month} month(s) on day ${day}`;
    const weekText = `${week} week(s)`;
    const dayText = `${day} day(s)`;
    const textValue = isYear
      ? yearText
      : isMonth
      ? monthText
      : isWeek
      ? weekText
      : isDay
      ? dayText
      : "";

    return (
      <Typography
        key={params.id}
        variant="bodyText"
        sx={({ typography }) => ({
          ...typography.bodyText,
        })}
      >
        {`Every ${textValue}`}
      </Typography>
    );
  },
};
