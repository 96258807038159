import { call, delay, put, takeLatest } from "redux-saga/effects";

import {
    createFormTemplate,
    createFormTemplateSuccess,
    createFormTemplateError,
    retrieveFormTemplate,
    retrieveFormTemplateSuccess,
    retrieveFormTemplateError,
    updateFormTemplate,
    updateFormTemplateSuccess,
    updateFormTemplateError,
    deleteFormTemplate,
    deleteFormTemplateSuccess,
    deleteFormTemplateError,
    deleteAllSubmission,
    deleteAllSubmissionSuccess,
    deleteAllSubmissionError,
    deleteAnalyticsByFormID,
    deleteAnalyticsByFormIDSuccess,
    deleteAnalyticsByFormIDError,
    setFormContext,
    setFormContextError,
    setFormContextSuccess,
} from "../stores/slices/formTemplateSlices"

import { ApiRoute } from "../enums/ApiRoute";
import { API } from "../http-common";
import moment from "moment";
import { retrieveFormSubmission } from "../stores/slices/formSubmissionSlices";

function* createFormTemplateSaga({payload}){
    try{
        const { data } = yield call(API.post, ApiRoute.formTemplate.createFormTemplate, payload);
        yield put(createFormTemplateSuccess(data))
    } catch (error){
        
        yield put(createFormTemplateError(error));
        console.error(error);
    }
}

function* retrieveFormTemplateSaga({payload}){
    const {isSA = null, UserID = null, ...otherPayload} = payload;
    try{
        const { data } = yield call(API.post, ApiRoute.formTemplate.retrieveAllFormTemplate, otherPayload);

        const processedData = data.map((form)=>{
            return {
              ...form,
              FormData : form.FormData ? JSON.parse(form.FormData) : [],
              TargetMember : form.TargetMember ? JSON.parse(form.TargetMember): []
            }
        })

        if(isSA){
            yield put(setFormContext({memberFormTemplates : processedData}));
        } else {
            const memberTemplate = processedData.map((form) => {
                let effectiveDate = moment(form.EffectiveDate)
                let expiryDate = form.ExpiryDate !== "" ? moment(form.ExpiryDate) : "";
                let currentDate = moment(new Date());
                let targetMembers = form.TargetMember
                
                if(targetMembers.includes(UserID)){
                    if(expiryDate !== "" ? effectiveDate.unix() <= currentDate.unix() && currentDate.unix() < expiryDate.unix() : effectiveDate.unix() <= currentDate.unix()){
                        return form;
                    }
                } else if(targetMembers.length === 0){
                    return form;
                } 
            })
            yield put(setFormContext({memberTemplates : memberTemplate}));
        }
        
        yield put(retrieveFormTemplateSuccess(processedData))
    } catch (error){
        
        yield put(retrieveFormTemplateError(error));
        console.error(error);
    }
}

function* updateFormTemplateSaga({payload}){
    try{
        yield call(API.post, ApiRoute.formTemplate.updateFormTemplate, payload);
        yield put(updateFormTemplateSuccess(payload))
    } catch (error){
        
        yield put(updateFormTemplateError(error));
        console.error(error);
    }
}

function* deleteFormTemplateSaga({payload}){
    try{
        yield call(API.post, ApiRoute.formTemplate.deleteFormTemplate, payload);
        yield put(deleteFormTemplateSuccess(payload))
    } catch (error){
        
        yield put(deleteFormTemplateError(error));
        console.error(error);
    }
}

function* deleteAllSubmissionSaga({payload}){
    try{
        const { data } = yield call(API.post, ApiRoute.formTemplate.deleteAllSubmission, payload);
        yield put(deleteAllSubmissionSuccess(data))
    } catch (error){
        
        yield put(deleteAllSubmissionError(error));
        console.error(error);
    }
}

function* deleteAnalyticsByFormIDSaga({payload}){

    try{
        yield call(API.post, ApiRoute.formTemplate.deleteAnalyticsByFormID, payload);
        yield put(deleteAllSubmission(payload))
        yield put(deleteFormTemplate(payload))
        yield put(deleteAnalyticsByFormIDSuccess(payload))
    } catch (error){
        yield put(deleteAnalyticsByFormIDError(error));
        console.error(error);
    }
}

function* setFormContextSaga({payload}){
    try{
        yield put(setFormContextSuccess(payload));
    } catch (error){
        
        yield put(setFormContextError(error));
        console.error(error);
    }
}

export function* formTemplateSagaWatcher(){
    yield takeLatest(createFormTemplate.type, createFormTemplateSaga);
    yield takeLatest(retrieveFormTemplate.type, retrieveFormTemplateSaga);
    yield takeLatest(updateFormTemplate.type, updateFormTemplateSaga);
    yield takeLatest(deleteFormTemplate.type, deleteFormTemplateSaga);
    yield takeLatest(deleteAllSubmission.type, deleteAllSubmissionSaga);
    yield takeLatest(deleteAnalyticsByFormID.type, deleteAnalyticsByFormIDSaga);
    yield takeLatest(setFormContext.type, setFormContextSaga);
}