import { createSlice, current } from "@reduxjs/toolkit"

import { useAppSelector } from "../../hooks/storeHooks";

//initial state
const initialState = {

    //loading
    createFormSubmissionLoading : false,
    retrieveFormSubmissionLoading : false,
    updateFormSubmissionLoading : false,
    deleteFormSubmissionLoading : false,
    retrieveAllFormsCountLoading : false,

    //error
    createFormSubmissionError : undefined,
    retrieveFormSubmissionError : undefined,
    updateFormSubmissionError : undefined,
    deleteFormSubmissionError : undefined,
    retrieveAllFormsCountError : undefined,

    //data
    formSubmission : [],
    formsCount : null

}


//slices
const formSubmissionSlice = createSlice({
    name: "formSubmission",
    initialState,
    reducers : {
        createFormSubmission(state){
            state.createFormSubmissionLoading = true;
        },
        createFormSubmissionSuccess(state, {payload}){
            const currentState = payload;
            state.formSubmission = currentState;

            state.createFormSubmissionLoading = false;
        },
        createFormSubmissionError(state, {payload}){
            const errorState = payload;
            state.createFormSubmissionError = errorState;

            state.createFormSubmissionLoading = false;
        },

        retrieveFormSubmission(state){
            state.retrieveFormSubmissionLoading = true;
        },
        retrieveFormSubmissionSuccess(state, {payload}){
            const currentState = payload;
            state.formSubmission = currentState;

            state.retrieveFormSubmissionLoading = false;
        },
        retrieveFormSubmissionError(state, {payload}){
            const errorState = payload;
            state.retrieveFormSubmissionError = errorState;

            state.retrieveFormSubmissionLoading = false;
        },

        updateFormSubmission(state){
            state.updateFormSubmissionLoading = true;
        },
        updateFormSubmissionSuccess(state, {payload}){
            const currentState = current(state.formSubmission);
            const updatedState = currentState.map((item) => {
                if (item.SubmissionID === payload.SubmissionID) {
                    return {
                        ...payload,
                        SubmissionData : JSON.parse(payload.SubmissionData)
                    };
                } else {
                  return item;
                }
            });
            state.formSubmission = updatedState;

            state.updateFormSubmissionLoading = false;
        },
        updateFormSubmissionError(state, {payload}){
            const errorState = payload;
            state.updateFormSubmissionError = errorState;

            state.updateFormSubmissionLoading = false;
        },

        deleteFormSubmission(state){
            state.deleteFormSubmissionLoading = true;
        },
        deleteFormSubmissionSuccess(state, {payload}){
            const currentState = current(state.formSubmission);
            const updatedState = currentState.filter((item) => item.SubmissionID !== payload.SubmissionID);
            state.formSubmission = updatedState;

            state.deleteFormSubmissionLoading = false;
        },
        deleteFormSubmissionError(state, {payload}){
            const errorState = payload;
            state.deleteFormSubmissionError = errorState;

            state.deleteFormSubmissionLoading = false;
        },

        retrieveAllFormsCount(state){
            state.retrieveAllFormsCountLoading = true;
        },
        retrieveAllFormsCountSuccess(state, {payload}){
            const currentState = payload;
            state.formsCount = currentState;

            state.retrieveAllFormsCountLoading = false;
        },
        retrieveAllFormsCountError(state, {payload}){
            const errorState = payload;
            state.retrieveAllFormsCountError = errorState;

            state.retrieveAllFormsCountLoading = false;
        },
        
    }
})

//export function
export const {
    createFormSubmission,
    createFormSubmissionSuccess,
    createFormSubmissionError,
    updateFormSubmission,
    updateFormSubmissionSuccess,
    updateFormSubmissionError,
    retrieveFormSubmission,
    retrieveFormSubmissionSuccess,
    retrieveFormSubmissionError,
    deleteFormSubmission,
    deleteFormSubmissionSuccess,
    deleteFormSubmissionError,
    retrieveAllFormsCount,
    retrieveAllFormsCountSuccess,
    retrieveAllFormsCountError,
} = formSubmissionSlice.actions

//export selector
// data
export const selectFormSubmission = () =>
  useAppSelector((state) => state.formSubmissions.formSubmission);

export const selectFormsCount = () =>
  useAppSelector((state) => state.formSubmissions.formsCount);

// loading
export const selectCreateFormSubmissionLoading = () =>
  useAppSelector((state) => state.formSubmissions.createFormSubmissionLoading);

export const selectRetrieveFormSubmissionLoading = () =>
  useAppSelector((state) => state.formSubmissions.retrieveFormSubmissionLoading);

export const selectUpdateFormSubmissionLoading = () =>
  useAppSelector((state) => state.formSubmissions.updateFormSubmissionLoading);

export const selectDeleteFormSubmissionLoading = () =>
  useAppSelector((state) => state.formSubmissions.deleteFormSubmissionLoading);

export const selectRetrieveAllFormsCountLoading = () =>
  useAppSelector((state) => state.formSubmissions.retrieveAllFormsCountLoading);

// error
export const selectCreateFormSubmissionError = () =>
  useAppSelector((state) => state.formSubmissions.createFormSubmissionError);

export const selectRetrieveFormSubmissionError = () =>
  useAppSelector((state) => state.formSubmissions.retrieveFormSubmissionError);

export const selectUpdateFormSubmissionError = () =>
  useAppSelector((state) => state.formSubmissions.updateFormSubmissionError);

export const selectDeleteFormSubmissionError = () =>
  useAppSelector((state) => state.formSubmissions.deleteFormSubmissionError);

export const selectRetrieveAllFormsCountError = () =>
  useAppSelector((state) => state.formSubmissions.retrieveAllFormsCountError);

//export reducer
const formSubmissionReducer = formSubmissionSlice.reducer;

export default formSubmissionReducer;
