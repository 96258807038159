import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { InputText } from "../Input/InputText";

export const FormInputText = ({
  label,
  defaultValue,
  textType,
  startAdornment,
  endAdornment,
  tooltips,
  type,
  isDisabled,
  inputProps,
  multiline,
  minWidth,
  maxWidth,
  fullWidth,
  required,
  transform,
  helperText,
  hidden = false,
  variant = null,
  placeholder = "",
  formOnChange = null,
  formOnBlur = null,
  disabledErrorText = false,
  formOnFocus = null,
  formValue = null,
  ...props
}) => {
  return (
    <Controller
      {...props}
      // shouldUnregister
      defaultValue={defaultValue}
      render={({
        field: { onChange, onBlur, value, ref },

        fieldState: { error },
      }) => {
        return (
          <InputText
            type={type}
            placeholder={placeholder}
            value={formValue ? formValue : value ? value : ""}
            label={label}
            textType={textType}
            startAdornment={startAdornment}
            endAdornment={endAdornment}
            tooltips={tooltips}
            onChange={
              formOnChange
                ? (event) => {
                    const data = event.target.value;
                    formOnChange(data);
                    onChange(data);
                  }
                : onChange
            }
            onBlur={onBlur}
            formOnBlur={formOnBlur}
            error={error?.message}
            helperText={helperText}
            name={props.name}
            isDisabled={isDisabled}
            transform={transform}
            inputProps={inputProps}
            multiline={multiline}
            minWidth={minWidth}
            maxWidth={maxWidth}
            fullWidth={fullWidth}
            required={required}
            fieldRef={ref}
            hidden={hidden}
            variant={variant}
            disabledErrorText={disabledErrorText}
            formOnFocus={formOnFocus}
            id={props.id}
          />
        );
      }}
    />
  );
};

FormInputText.propTypes = {
  id: PropTypes.any,
  label: PropTypes.any,
  defaultValue: PropTypes.any,
  textType: PropTypes.any,
  startAdornment: PropTypes.any,
  endAdornment: PropTypes.any,
  tooltips: PropTypes.any,
  type: PropTypes.any,
  isDisabled: PropTypes.any,
  inputProps: PropTypes.any,
  multiline: PropTypes.any,
  minWidth: PropTypes.any,
  fullWidth: PropTypes.any,
  maxWidth: PropTypes.any,
  required: PropTypes.any,
  transform: PropTypes.any,
  name: PropTypes.string,
  helperText: PropTypes.any,
  hiddenLabel: PropTypes.any,
  value: PropTypes.any,
  hidden: PropTypes.bool,
  placeholder: PropTypes.any,
  variant: PropTypes.any,
  formOnChange: PropTypes.any,
  formOnBlur: PropTypes.any,
  formOnFocus: PropTypes.any,
  disabledErrorText: PropTypes.any,
  formValue: PropTypes.any,
};
