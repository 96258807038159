import React from "react";
import { Button, Typography } from "@mui/material";
import { convertSxToArray } from "../../util/mui/convertSxToArray";

export const PopoverChildrenButton = ({
  label,
  disabled,
  startAdornment,
  endAdornment,
  sx = [],
  fullWidth,
  onClick,
  onClose,
}) => {
  return (
    <Button
      className={"popover-children-button"}
      variant="text"
      disabled={disabled}
      fullWidth={fullWidth}
      sx={[
        {
          borderRadius: "unset",
          minWidth: 0,
          margin: 0,
          alignItems: "center",
          justifyContent: "flex-start",
          gap: 1,
        },
        ...convertSxToArray(sx),
      ]}
      onClick={() => {
        onClick();
        onClose();
      }}
    >
      {startAdornment}
      <Typography
        variant="bodyText"
        sx={{
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        }}
      >
        {label}
      </Typography>
      {endAdornment}
    </Button>
  );
};

