const transactionSettingNumberFormat = {
    ["quotations"]: "default_quotation_format",
    ["sale-orders"]: "default_sales_order_format",
    ["delivery-orders"]: "default_delivery_order_format",
    ["invoices"]: "default_invoice_format",
    ["sales-credit-notes"]: "default_credit_note_format",
    ["sales-payments"]: "default_sales_payment_format",
    ["sales-refunds"]: "default_sales_refund_format",
    ["purchase-orders"]: "default_purchase_order_format",
    ["goods-received-notes"]: "default_goods_received_note_format",
    ["bills"]: "default_bill_format",
    ["purchases-credit-notes"]: "default_supplier_credit_format",
    ["purchases-payments"]: "default_purchase_payment_format",
    ["purchase-refunds"]: "default_purchase_refund_format",
  };
  
  const transactionSettingAccounts = {
    ["quotations"]: "default_income_account",
    ["sale-orders"]: "default_income_account",
    ["delivery-orders"]: "default_income_account",
    ["invoices"]: "default_income_account",
    ["sales-credit-notes"]: "default_income_account",
    ["sales-payments"]: "default_income_account",
    ["sales-refunds"]: "default_income_account",
    ["purchase-orders"]: "default_expense_account",
    ["goods-received-notes"]: "default_expense_account",
    ["bills"]: "default_expense_account",
    ["purchases-credit-notes"]: "default_expense_account",
    ["purchases-payments"]: "default_expense_account",
    ["purchase-refunds"]: "default_expense_account",
  };
  
  const transactionActiveTax = {
    ["quotations"]: "active_sales_tax",
    ["sale-orders"]: "active_sales_tax",
    ["delivery-orders"]: "active_sales_tax",
    ["invoices"]: "active_sales_tax",
    ["sales-credit-notes"]: "active_sales_tax",
    ["sales-payments"]: "active_sales_tax",
    ["sales-refunds"]: "active_sales_tax",
    ["purchase-orders"]: "active_services_tax",
    ["goods-received-notes"]: "active_services_tax",
    ["bills"]: "active_services_tax",
    ["purchases-credit-notes"]: "active_services_tax",
    ["purchases-payments"]: "active_services_tax",
    ["purchase-refunds"]: "active_services_tax",
    ["money-in"]: "active_sales_tax",
    ["money-out"]: "active_services_tax",
  };
  
  module.exports = {
    transactionSettingNumberFormat,
    transactionSettingAccounts,
    transactionActiveTax,
  };
  