import React from "react";
import PropTypes from "prop-types";
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  CheckBoxOutlineBlankRounded,
  DoneRounded,
  HelpOutlineRounded,
} from "@mui/icons-material";

export const InputCheckbox = ({
  name,
  isDisabled,
  value,
  indeterminate,
  onChange,
  tooltips,
  label,
  required,
  fieldRef,
}) => {
  const handleChange = (event) => {
    onChange(event);
  };

  const inputTextProps = {
    "data-testid": `${name}Checkbox`,
  };

  const tooltipsAdornmentComponent = tooltips ? (
    <Tooltip title={tooltips}>
      <IconButton>
        <HelpOutlineRounded sx={({ icons }) => ({ ...icons.standard })} />
      </IconButton>
    </Tooltip>
  ) : null;

  const renderLabel = (
    <Typography
      variant="bodyText"
      sx={{ display: "flex", alignItems: "center", gap: 1 }}
    >
      {label}
      {tooltipsAdornmentComponent}
    </Typography>
  );
  return (
    <FormControlLabel
      control={
        <Checkbox
          onChange={handleChange}
          checked={value}
          indeterminate={indeterminate}
          inputProps={inputTextProps}
          icon={<CheckBoxOutlineBlankRounded />}
          checkedIcon={<DoneRounded />}
        />
      }
      label={renderLabel}
      required={required}
      inputRef={fieldRef}
      disabled={isDisabled}
    />
  );
};

InputCheckbox.propTypes = {
  name: PropTypes.any,
  isDisabled: PropTypes.any,
  value: PropTypes.any,
  onChange: PropTypes.any,
  tooltips: PropTypes.any,
  label: PropTypes.any,
  required: PropTypes.any,
  fieldRef: PropTypes.any,
  error: PropTypes.any,
  helperText: PropTypes.any,
  indeterminate: PropTypes.any,
};
