import React, { useState, cloneElement, Children } from "react";
import { Popover, IconButton, Box, Tooltip, Stack } from "@mui/material";
import { convertSxToArray } from "../../util/mui/convertSxToArray";
export const PopoverIconButton = ({
  buttonChild,
  children,
  hint,
  sxButton,
  sxPopover = [],
  anchor = {
    vertical: "bottom",
    horizontal: "right",
  },
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [disableTooltip, setDisableTooltip] = useState(false);

  const handleOpenPopover = (event) => {
    setAnchorEl(event.currentTarget);
    setDisableTooltip(true);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
    setDisableTooltip(false);
  };

  const isOpen = Boolean(anchorEl);
  // const closeTooltip =
  return (
    <Tooltip title={hint} disableHoverListener={disableTooltip}>
      <Box>
        <IconButton
          sx={[
            {
              minWidth: 0,
            },
            ...convertSxToArray(sxButton),
          ]}
          onClick={handleOpenPopover}
        >
          {buttonChild}
        </IconButton>
        <Popover
          sx={[...convertSxToArray(sxPopover)]}
          open={isOpen}
          anchorEl={anchorEl}
          onClose={handleClosePopover}
          anchorOrigin={anchor}
        >
          <Stack>
            {children
              ? Children.map(children, (child) =>
                  cloneElement(child, { onClose: handleClosePopover })
                )
              : null}
          </Stack>
        </Popover>
      </Box>
    </Tooltip>
  );
};
