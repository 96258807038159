import React from "react";
import PropTypes from "prop-types";
import { Box, IconButton } from "@mui/material";
import { convertSxToArray } from "../../../../util/mui/convertSxToArray";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export const SecondaryHeader = ({
  removeSearchParams,
  actionChildren,
  customComponent,
  hideBreadCrumbs,
  justifyEnd,
  sx = [],
  testId,
}) => {
  return (
    <Box
      component="div"
      sx={[
        ({ spacing, sizing }) => ({
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: 1,
          marginBottom: spacing(1),
          height: sizing.headerHeight,
          zIndex: 100,
          padding: testId === "new_contact" ? spacing(4, 6) : "0px",
          marginTop: testId === "new_contact" ? "60px" : "0px",
        }),
        ...convertSxToArray(sx),
      ]}
    >
      <IconButton onClick={()=>{removeSearchParams()}}>
      <ArrowBackIcon />
      </IconButton>
     
      {customComponent}
      {actionChildren}
    </Box>
  );
};

SecondaryHeader.propTypes = {
  testId: PropTypes.any,
  actionChildren: PropTypes.any,
  customComponent: PropTypes.node,
  hideBreadCrumbs: PropTypes.bool,
  justifyEnd: PropTypes.bool,
  sx: PropTypes.object,
};
