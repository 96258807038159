import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import './404.scss'
import './EmailInvitation.scss'
import { db, auth } from "../firebaseInit";

import { v4 } from "uuid";
import { ref, remove, set } from "firebase/database";
import moment from "moment";
import { useDispatch } from "react-redux";
import usageObject from '../enums/json/plan.json';
import { sendEmailVerification, signOut } from "firebase/auth";

//slices
import { createCompanyAgent, sendAgentInvitation } from "../stores/slices/companyAgentSlices";
import { createTenantAgents } from "../stores/slices/tenantAgentSlices";
import { selectUser } from "../stores/slices/userAuthSlices";

function EmailInvitation(props) {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [searchParams,setSearchParams] = useSearchParams();
    const user = selectUser();

    const handleLogout = async () => {
        if(user && user.uid){
          try {
            await signOut(auth)
            navigate("/Auth")
          } catch (error) {
              console.log(error.message)
          }
        }
      };

    const createInvitationMail = async (mailID,TenantID,CompanyID,UserID,RoleID,email,isExist) =>{
        const createdAt = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
        await set(ref(db, 'invitationMail/' + `${TenantID}/` + `${CompanyID}/` +mailID ), {
            mailID:mailID,
            tenantID:TenantID,
            email: email,
            companyID:CompanyID,
            hostID:UserID,
            roleID:RoleID,
            createdAt:createdAt,
            isExist:isExist
        })
    }

    const verifyEmail = () => {
        sendEmailVerification(user).then(() => {
            // Verification email sent
            // handleLogout();
            console.log("Verification email sent.");
        })
        .catch((error) => {
        // An error occurred
        console.error("Error sending verification email:", error);
        });
    }

    //when invite users
    const sendMail = async () =>{
        // 6711ecd1-d7fe-40a3-a3b6-2d7fa02a17f1 zumax
        // e73821c0-933f-41ac-b6d6-8ef6c9334cdb leith

        const mailID = v4();
        const TenantID = "93ab26eaddfa47e2a8be426785a3378c";
        const CompanyID = "e73821c0-933f-41ac-b6d6-8ef6c9334cdb"; //companyID that users invited to
        const UserID = user.uid; 
        const RoleID = "c8e23e9f-9680-418d-9fa7-2ef304676b26";
        const displayName = user.displayName;
        const email = "xdh4t3u@live.com.my";
        const hostEmail = user.email;
        const tenantName = "Leith"; //companyName from tenant table
        const companyName = "Company"
        const isExist = false; //existing member UserID or false

        await createInvitationMail(mailID,TenantID,CompanyID,UserID,RoleID,email,isExist);
        //mail template condition is made based on isExist whether is true or false
        dispatch(sendAgentInvitation({mailID,TenantID,CompanyID,UserID,RoleID,displayName,email,hostEmail,tenantName,companyName,isExist}))
        
    } 

    //invitation mail management (data can be retrieved from firebase-invitationMail)

    //delete
    const deleteMail = () => {
        const mailID = "0b888755-33b3-4bce-bc80-83cedfac3402";
        const tenantID = '93ab26eaddfa47e2a8be426785a3378c';
        const companyID = '6711ecd1-d7fe-40a3-a3b6-2d7fa02a17f1';
        remove(ref(db, 'invitationMail/' + `${tenantID}/` + `${companyID}/` + mailID ))
    }

    //resend
    const resendMail = () => {
        const mailID = "m1234"; 
        const TenantID = "tenant1234";
        const CompanyID = "c1234"; //companyID that users invited to
        const UserID = user.uid; 
        const RoleID = "roleID"
        const displayName = user.displayName;
        const email = "xdh4t3u@live.com.my";
        const hostEmail = user.email;
        const tenantName = "tamadun"; //companyName from tenant table
        const companyName = "Company"
        const isExist = false; //existing member true or false

        dispatch(sendAgentInvitation({mailID,TenantID,CompanyID,UserID,RoleID,displayName,email,hostEmail,tenantName,companyName,isExist}))
    }

    const acceptInvitation = async () =>{
        const createdAt = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
        if(Array.from(searchParams).length > 0)
        {
        //  const displayName = user.displayName;
         const userEmail = searchParams.get("Email");
         const hostEmail = searchParams.get("HostEmail");
         const TenantAgentID = v4();
         const TenantID = searchParams.get("TenantID");
         const UserID = searchParams.get("UserID");
         const MailID = searchParams.get("MailID");
         const CompanyID = searchParams.get("CompanyID");
         const CompanyName = searchParams.get("CompanyName");
         const RoleID = searchParams.get("RoleID");

          dispatch(createTenantAgents({TenantAgentID:TenantAgentID,TenantID:TenantID,UserID:UserID,CreatedAt:createdAt}))
            await remove(ref(db, 'invitationMail/' + `${TenantID}/` + `${CompanyID}/` + MailID)).then(()=>{
                const companyAgentData = {
                    CompanyAgentID: v4(),
                    CompanyID: CompanyID,
                    UserID: UserID,
                    RoleID: RoleID,
                    CreatedAt: createdAt
                }
                dispatch(createCompanyAgent({TenantID:TenantID,data:companyAgentData}))

                //ini dispathc can work or not ??
                dispatch(sendTenantNotificationMail({hostEmail:hostEmail,userEmail:userEmail,companyName:CompanyName,createdAt:createdAt}));
                
            })
        }
    }

    const [usage,setUsage] = useState(usageObject.Trial);
    const createUsage = async () => {
        const uid = "bJUGQzV8Lqcb6tsx47xObr9kRIQ2"
        const tenantid = "crm_652dd68f7c81423c802016d0fcb7e531"
        const companyID = 'a689f1e3-e37c-4e2b-8869-5b91e9ac9bec';
        const plan = "Trial";
        const object = {
            companyID:'a689f1e3-e37c-4e2b-8869-5b91e9ac9bec',
            plan:plan,
            startDate: "",
            activation: false,
            usage:usage
        }
        // const usage = usageObject[plan];

        set(ref(db, 'usage/' + `${tenantid}/` + `${companyID}/`),object).then(()=>{
            console.log("success")
        })
        .catch((error) => {
            console.error('Error setting usage data:', error);
        });
    }

    //edit quota value
    const handleQuota = (sectionID,value) => {
        switch(sectionID)
        {
            case "s1" : 
                setUsage({...usage,[sectionID] :{...usage[sectionID],Quota:value}})
                break;
            case "s2" :
                setUsage({...usage,[sectionID] :{...usage[sectionID],Quota:value}})
                break;
            case "s3" :
                setUsage({...usage,[sectionID] :{...usage[sectionID],Quota:value}})
                break;
            case "s4" :
                setUsage({...usage,[sectionID] :{...usage[sectionID],Quota:value}})
                break;
            case "s5" :
                setUsage({...usage,[sectionID] :{...usage[sectionID],Quota:value}})
                break;   
            case "s6" :
                setUsage({...usage,[sectionID] :{...usage[sectionID],Quota:value}})
                break;
            case "s7" :
                setUsage({...usage,[sectionID] :{...usage[sectionID],Quota:value}})
                break;
            case "s8" :
                setUsage({...usage,[sectionID] :{...usage[sectionID],Quota:value}})
                break;
            case "s9" :
                setUsage({...usage,[sectionID] :{...usage[sectionID],Quota:value}})
                break;
            case "s10" :
                setUsage({...usage,[sectionID] :{...usage[sectionID],Quota:value}})
                break;
            default:
                break;  
            
        }
    }

    return (
        <div className="d-flex flex-row" style={{ height: "100vh" }}>
            <div className="w-100" style={{}}>
            {/* <Header title={"Test"}/> */}
                <div
                className="content-box d-flex"
                style={{ width: "100%", overflow: "scroll",gap:"1rem" }}>
                    <div className="invitation-container" onClick={()=>{sendMail();}}>
                        <div className="invitation-box" >
                            Invitation Mail
                        </div>
                    </div>
                    <div className="invitation-container" onClick={()=>{resendMail();}}>
                        <div className="invitation-box" >
                            Resend Mail
                        </div>
                    </div>
                    <div className="invitation-container" onClick={()=>{deleteMail();}}>
                        <div className="invitation-box" >
                            Delete Mail
                        </div>
                    </div>
                    <div className="invitation-container" onClick={()=>{createUsage();}}>
                        <div className="invitation-box" >
                            Create Usage
                        </div>
                    </div>
                    <div className="invitation-container" onClick={()=>{handleLogout();}}>
                        <div className="invitation-box" >
                            Logout
                        </div>
                    </div>
                    <div className="invitation-container" onClick={()=>{verifyEmail();}}>
                        <div className="invitation-box" >
                            Verify Email
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EmailInvitation;
