/* eslint-disable no-unused-vars */
import { createSlice, current } from "@reduxjs/toolkit";
import { useAppSelector } from "../../hooks/storeHooks";
import { selectCurrRoles } from "./roleSlices";

const initialState = {
  transactions: [],
  currentTransaction: null,
  shareTransaction: null,
  formDesign: null,
  bankTransactions: [],
  options: {},
  paymentTermOptions: [],
  customFields: [],
  importTransactions: [],
  retrieveAllTransactionsLoading: false,
  retrieveAllTransactionsError: undefined,

  retrieveTransactionOptionsLoading: false,
  retrieveTransactionOptionsError: undefined,

  retrieveTransactionPaymentOptionsLoading: false,

  retrieveTransactionLoading: false,
  retrieveTransactionError: undefined,

  createTransactionLoading: false,
  createTransactionError: undefined,

  removeTransactionLoading: false,

  voidTransactionLoading: false,

  updateTransactionLoading: false,

  createTransactionPaymentLoading: false,

  retrieveTransactionPaymentLoading: false,

  updateTransactionPaymentLoading: false,

  retrieveCreditNoteLoading: false,

  retrieveCreditNoteOptionsLoading: false,

  createCreditNoteLoading: false,

  updateCreditNoteLoading: false,

  retrieveBankTransactionLoading: false,
  retrieveBankTransactionOptionsLoading: false,

  createBankTransactionLoading: false,

  updateBankTransactionLoading: false,

  retrieveBankTransferLoading: false,
  retrieveBankTransferOptionsLoading: false,

  updateBankTransferLoading: false,

  retrieveBankTransactionsLoading: false,

  bulkCreateTransactionLoading: false,
  bulkCreateTransactionError: undefined,

  getShareTransactionLoading: false,

  sendEmailTransactionLoading: false,
  importTransactionsLoading: false,

  bulkCreatePaymentsLoading: false,
  // getShareTransactionError: undefined,

  openShareTransactionDialogLoading: false,
};

const invoiceBillsStatus = (date, formPaymentBalanceValue) => {
  const todayDate = new Date();
  todayDate.setHours(0, 0, 0, 0);

  if (date.length <= 1) {
    const transactionDueDate = new Date(date[0]);
    transactionDueDate.setHours(0, 0, 0, 0);

    const paymentDateStatus =
      formPaymentBalanceValue == 0.0
        ? "Paid"
        : todayDate > transactionDueDate
        ? "Overdue"
        : "Coming Due";
    return paymentDateStatus;
  } else {
    const paymentDateStatuses = date.map((d) => {
      const transactionDueDate = new Date(d);
      transactionDueDate.setHours(0, 0, 0, 0);

      const paymentDateStatus =
        formPaymentBalanceValue == 0.0
          ? "Paid"
          : todayDate > transactionDueDate
          ? "Overdue"
          : "Coming Due";

      return paymentDateStatus;
    });

    const overallPaymentStatus = paymentDateStatuses.reduce((acc, status) => {
      if (status === "Overdue") {
        return "Overdue";
      } else if (status === "Coming Due" && acc !== "Overdue") {
        return "Coming Due";
      } else if (
        status === "Paid" &&
        acc !== "Overdue" &&
        acc !== "Coming Due"
      ) {
        return "Paid";
      }
      return acc;
    }, "Paid");

    return overallPaymentStatus;
  }
};

const totalVATFunction = (formItem, taxIncluded) => {
  const { quantity, amount, tax = null, discount = null } = formItem;
  const amountMultiplyAmount = parseFloat(quantity) * parseFloat(amount);
  const taxIncludedPercentage = (100 + parseFloat(tax || 0)) / 100;
  const amountWithTax =
    tax && !taxIncluded
      ? amountMultiplyAmount * taxIncludedPercentage
      : amountMultiplyAmount;
  const discountIsPercentage = discount ? discount.endsWith("%") : false;
  const discountPercentage = discountIsPercentage
    ? parseFloat(discount.replace(/%$/, "")) / 100
    : 1;
  const discountAmount =
    discount && discountIsPercentage
      ? amountMultiplyAmount * discountPercentage
      : discount;

  const totalVATResult = amountWithTax - (discountAmount || 0);
  return totalVATResult;
};

const transactionSlice = createSlice({
  name: "transaction",
  initialState,
  reducers: {
    retrieveAllTransactions(state) {
      state.retrieveAllTransactionsLoading = true;
    },
    retrieveAllTransactionsSuccess(state, { payload }) {
      const { transactions, options, type, custom_fields, currSa, currRoles } = payload;
      const isCreditNote =
        type == "sales-credit-notes" || type == "purchases-credit-notes";
      const isInvoice = type == "invoices";
      const isBills = type == "bills";
      const isPaymentRefund =
        type == "sales-payments" ||
        type == "sales-refunds" ||
        type == "purchases-refunds" ||
        type == "purchases-payments";
      const isBank =
        type == "money-in" || type == "money-out" || type == "transfer";
      const convertedTransactions = transactions.map((transactions) => {
        const {
          customer,
          FormPayments = null,
          form_payments = null,
          TransactionPayments = null,
          date,
          FormItems: form_items = null,
          tax_inclusive,
          ...transactionJson
        } = transactions;
        const generalInfoObject = {
          ...transactionJson,
          tags: transactionJson.tags.map((tag) => {
            return tag.name;
          }),
          date: date,
          customer_name: customer ? customer.display_name : null,
          currSa : currSa,
          currRoles : currRoles
          //sa
          //role
        };

        const formItemTotal = form_items
          ? form_items.reduce(
              (sum, item) => sum + totalVATFunction(item, tax_inclusive),
              0
            )
          : 0;
        const formPaymentTotal = isPaymentRefund
          ? FormPayments.reduce(
              (sum, item) => sum + parseFloat(item.apply_amount),
              0
            )
          : form_payments
          ? form_payments.reduce(
              (sum, item) => sum + parseFloat(item.apply_amount),
              0
            )
          : FormPayments
          ? FormPayments.reduce(
              (sum, item) => sum + parseFloat(item.apply_amount),
              0
            )
          : 0.0;

        const refundPaymentTotal =
          type === "sales-payments" || type === "purchases-payments"
            ? form_payments.reduce(
                (total, item) => total + parseFloat(item.apply_amount),
                0
              )
            : 0;
        if (isPaymentRefund) {
          const transactionPaymentTotal = TransactionPayments
            ? TransactionPayments.reduce(
                (sum, item) => sum + parseFloat(item.amount),
                0
              )
            : 0;
          const overpaymentTotal =
            transactionPaymentTotal - formPaymentTotal - refundPaymentTotal;
          const paymentRefundStatus =
            overpaymentTotal > 0 ? "Outstanding" : "Applied";

          return {
            ...generalInfoObject,
            overpayment: overpaymentTotal.toFixed(2),
            amount: transactionPaymentTotal.toFixed(2),
            payment_account:
              TransactionPayments && TransactionPayments.length > 0
                ? TransactionPayments[0].payment_account
                : null,
            payment_status: paymentRefundStatus,
          };
        }

        //

        if (isInvoice || isCreditNote) {
          const { TransactionPaymentTerms = [] } = transactions;
          const dueOnValue = TransactionPaymentTerms.map((term) => {
            return term.due_on;
          });
          const formPaymentBalanceValue = (
            formItemTotal - formPaymentTotal
          ).toFixed(2);

          const paymentStatusObject = isInvoice
            ? invoiceBillsStatus(dueOnValue, formPaymentBalanceValue)
            : formPaymentBalanceValue > 0
            ? "Outstanding"
            : "Applied";
          return {
            ...generalInfoObject,
            descriptions: form_items[0] ? form_items[0].descriptions : null,
            amount: formItemTotal.toFixed(2),
            balance: formPaymentBalanceValue,
            payment_status: paymentStatusObject,
          };
        }

        if (isBills) {
          const { date } = transactions;
          const dueOnValue = new Array(date);
          const formPaymentBalanceValue = (
            formItemTotal - formPaymentTotal
          ).toFixed(2);
          const paymentStatusObject = isBills
            ? invoiceBillsStatus(dueOnValue, formPaymentBalanceValue)
            : formPaymentBalanceValue > 0
            ? "Outstanding"
            : "Applied";

          return {
            ...generalInfoObject,
            descriptions: form_items[0] ? form_items[0].descriptions : null,
            amount: formItemTotal.toFixed(2),
            balance: formPaymentBalanceValue,
            payment_status: paymentStatusObject,
          };
        }
        if (isBank) {
          return {
            ...generalInfoObject,
            TransactionPayments: TransactionPayments,
            account_id: TransactionPayments[0]
              ? TransactionPayments[0].payment_account.account_id
              : null,
            descriptions: form_items[0] ? form_items[0].descriptions : null,
          };
        }

        return {
          ...generalInfoObject,
          descriptions: form_items[0] ? form_items[0].descriptions : null,
          amount: formItemTotal.toFixed(2),
        };
      });
      state.transactions = convertedTransactions;
      state.options = options;
      state.customFields = custom_fields;
      state.retrieveAllTransactionsLoading = false;
    },
    retrieveAllTransactionsError(state, { payload }) {
      state.retrieveAllTransactionsError = payload;
      state.retrieveAllTransactionsLoading = false;
    },

    retrieveTransactionOptions(state) {
      state.retrieveTransactionOptionsLoading = true;
    },
    retrieveTransactionOptionsSuccess(state, { payload }) {
      const { custom_fields = null, options } = payload;
      state.options = options;
      state.customFields = custom_fields;
      state.retrieveTransactionOptionsLoading = false;
    },

    retrieveTransactionOptionsError(state, { payload }) {
      state.createTransactionError = payload;
      state.createTransactionLoading = false;
    },

    createTransaction(state) {
      state.createTransactionLoading = true;
    },
    createTransactionSuccess(state) {
      state.createTransactionLoading = false;
    },
    createTransactionError(state, { payload }) {
      state.createTransactionError = payload;
      state.createTransactionLoading = false;
    },

    retrieveTransaction(state) {
      state.retrieveTransactionLoading = true;
    },
    retrieveTransactionSuccess(state, { payload }) {
      state.options = payload.options;
      state.customFields = payload.custom_fields;
      state.currentTransaction = payload.transaction;
      state.retrieveTransactionLoading = false;
    },

    retrieveTransactionError(state, { payload }) {
      state.retrieveTransactionLoading = false;
      state.retrieveTransactionError = payload;
    },

    removeTransaction(state) {
      state.removeTransactionLoading = true;
    },
    removeTransactionSuccess(state, { payload }) {
      const currentState = current(state.transactions);
      const updatedData = currentState.filter(
        (transaction) => transaction.transaction_id !== payload
      );
      state.transactions = updatedData;
      state.removeTransactionLoading = false;
    },

    voidTransaction(state) {
      state.voidTransactionLoading = true;
    },
    voidTransactionSuccess(state, { payload }) {
      const currentState = current(state.transactions);
      const updatedData = currentState.map((transaction) => {
        if (transaction.transaction_id == payload.transaction_id) {
          return {
            ...transaction,
            status: payload.status,
          };
        }
        return transaction;
      });

      state.transactions = updatedData;
      state.voidTransactionLoading = false;
    },

    updateTransaction(state) {
      state.updateTransactionLoading = true;
    },
    updateTransactionSuccess(state, { payload }) {
      state.updateTransactionLoading = false;
    },

    retrieveTransactionPaymentOptions(state) {
      state.retrieveTransactionPaymentOptionsLoading = true;
    },

    retrieveTransactionPaymentOptionsSuccess(state, { payload }) {
      const { custom_fields = null, options } = payload;
      state.options = options;
      state.customFields = custom_fields;
      state.retrieveTransactionPaymentOptionsLoading = false;
    },

    retrieveTransactionPayment(state) {
      state.retrieveTransactionPaymentLoading = true;
    },

    retrieveTransactionPaymentSuccess(state, { payload }) {
      const { custom_fields = null, options, transaction } = payload;
      state.options = options;
      state.customFields = custom_fields;
      state.currentTransaction = transaction;

      state.retrieveTransactionPaymentLoading = false;
    },

    createTransactionPayment(state) {
      state.createTransactionPaymentLoading = true;
    },
    createTransactionPaymentSuccess(state) {
      state.createTransactionPaymentLoading = false;
    },

    updateTransactionPayment(state) {
      state.updateTransactionPaymentLoading = true;
    },
    updateTransactionPaymentSuccess(state, { payload }) {
      state.updateTransactionPaymentLoading = false;
    },

    retrieveCreditNoteOptions(state, { payload }) {
      state.retrieveCreditNoteOptionsLoading = true;
    },

    retrieveCreditNoteOptionsSuccess(state, { payload }) {
      const { custom_fields = null, options } = payload;
      state.options = options;
      state.customFields = custom_fields;
      state.retrieveCreditNoteOptionsLoading = false;
    },

    retrieveCreditNote(state) {
      state.retrieveCreditNoteLoading = true;
    },

    retrieveCreditNoteSuccess(state, { payload }) {
      const { custom_fields = null, options, transaction } = payload;
      state.options = options;
      state.customFields = custom_fields;
      state.currentTransaction = transaction;
      state.retrieveCreditNoteLoading = false;
    },

    createCreditNote(state) {
      state.createCreditNoteLoading = true;
    },
    createCreditNoteSuccess(state, { payload }) {
      state.createCreditNoteLoading = false;
    },
    updateCreditNote(state) {
      state.updateCreditNoteLoading = true;
    },
    updateCreditNoteSuccess(state) {
      state.updateCreditNoteLoading = false;
    },

    createBankTransaction(state) {
      state.createBankTransactionLoading = true;
    },
    createBankTransactionSuccess(state, { payload }) {
      state.createBankTransactionLoading = false;
    },
    createBankTransactionError(state) {
      state.createBankTransactionLoading = false;
    },

    updateBankTransaction(state) {
      state.updateBankTransactionLoading = true;
    },
    updateBankTransactionSuccess(state) {
      state.updateBankTransactionLoading = false;
    },
    updateBankTransactionError(state) {
      state.updateBankTransactionLoading = false;
    },

    updateBankTransfer(state) {
      state.updateBankTransferLoading = true;
    },
    updateBankTransferSuccess(state) {
      state.updateBankTransferLoading = false;
    },
    updateBankTransferError(state) {
      state.updateBankTransferLoading = false;
    },
    retrieveBankTransaction(state) {
      state.retrieveBankTransactionLoading = true;
    },
    retrieveBankTransactionSuccess(state, { payload }) {
      state.currentTransaction = payload.transaction;
      state.options = payload.options;
      state.retrieveBankTransactionLoading = false;
    },
    retrieveBankTransactionError(state) {
      state.retrieveBankTransactionLoading = false;
    },
    retrieveBankTransfer(state) {
      state.retrieveBankTransferLoading = true;
    },
    retrieveBankTransferSuccess(state) {
      state.retrieveBankTransferLoading = false;
    },
    retrieveBankTransferError(state) {
      state.retrieveBankTransferLoading = false;
    },

    retrieveBankTransactionOptions(state) {
      state.retrieveBankTransactionOptionsLoading = true;
    },
    retrieveBankTransactionOptionsSuccess(state, { payload }) {
      state.options = payload.options;
      state.retrieveBankTransactionOptionsLoading = false;
    },
    retrieveBankTransactionOptionsError(state) {
      state.retrieveBankTransactionOptionsLoading = false;
    },
    retrieveBankTransferOptions(state) {
      state.retrieveBankTransferOptionsLoading = true;
    },
    retrieveBankTransferOptionsSuccess(state, { payload }) {
      state.options = payload.options;
      state.retrieveBankTransferOptionsLoading = false;
    },
    retrieveBankTransferOptionsError(state) {
      state.retrieveBankTransferOptionsLoading = false;
    },
    bulkCreateTransactions(state) {
      state.bulkCreateTransactionLoading = true;
    },
    bulkCreateTransactionsSuccess(state) {
      state.bulkCreateTransactionLoading = false;
    },
    bulkCreateTransactionsError(state) {
      state.bulkCreateTransactionLoading = false;
    },
    getShareTransaction(state) {
      state.getShareTransactionLoading = true;
    },
    getShareTransactionSuccess(state, { payload }) {
      const { transaction, form_design = null } = payload;
      state.formDesign = form_design;
      state.shareTransaction = transaction;

      state.getShareTransactionLoading = false;
    },
    getShareTransactionError(state) {
      state.getShareTransactionLoading = false;
    },

    sendEmailTransaction(state) {
      state.sendEmailTransactionLoading = true;
    },
    sendEmailTransactionSuccess(state) {
      state.sendEmailTransactionLoading = false;
    },
    sendEmailTransactionError(state) {
      state.sendEmailTransactionLoading = false;
    },
    importTransactions(state) {
      state.importTransactionsLoading = true;
    },
    importTransactionsSuccess(state) {
      state.importTransactionsLoading = false;
    },
    importTransactionsError(state) {
      state.importTransactionsLoading = false;
    },

    bulkCreatePayments(state) {
      state.bulkCreatePaymentsLoading = true;
    },
    bulkCreatePaymentsSuccess(state) {
      state.bulkCreatePaymentsLoading = false;
    },
    bulkCreatePaymentsError(state) {
      state.bulkCreatePaymentsLoading = false;
    },

    openShareTransactionDialog(state) {
      state.openShareTransactionDialogLoading = true;
    },
    openShareTransactionDialogSuccess(state) {
      state.openShareTransactionDialogLoading = false;
    },
    openShareTransactionDialogError(state) {
      state.openShareTransactionDialogLoading = false;
    },

    clearTransactionState(state) {
      state.transactions = [];
      state.currentTransaction = null;
      state.shareTransaction = null;
      state.formDesign = null;
      state.bankTransactions = [];
      state.options = {};
      state.paymentTermOptions = [];
      state.customFields = [];
      state.importTransactions = [];
    },
  },
});

export const {
  retrieveAllTransactions,
  retrieveAllTransactionsSuccess,
  retrieveAllTransactionsError,
  createTransaction,
  checkPriceRegex,
  createTransactionSuccess,
  createTransactionError,
  retrieveTransactionOptions,
  retrieveTransactionOptionsSuccess,
  retrieveTransactionOptionsError,
  retrieveTransaction,
  retrieveTransactionSuccess,
  retrieveTransactionError,
  removeTransaction,
  removeTransactionSuccess,
  voidTransaction,
  voidTransactionSuccess,
  updateTransaction,
  updateTransactionSuccess,
  retrieveTransactionPaymentOptions,
  retrieveTransactionPaymentOptionsSuccess,
  createTransactionPayment,
  createTransactionPaymentSuccess,
  retrieveTransactionPayment,
  retrieveTransactionPaymentSuccess,
  updateTransactionPayment,
  updateTransactionPaymentSuccess,

  createCreditNote,
  createCreditNoteSuccess,

  retrieveCreditNote,
  retrieveCreditNoteSuccess,
  retrieveCreditNoteOptions,
  retrieveCreditNoteOptionsSuccess,
  updateCreditNote,
  updateCreditNoteSuccess,

  retrieveBankTransaction,
  retrieveBankTransactionSuccess,
  retrieveBankTransactionError,

  createBankTransaction,
  createBankTransactionSuccess,
  createBankTransactionError,

  updateBankTransaction,
  updateBankTransactionSuccess,
  updateBankTransactionError,

  retrieveBankTransfer,
  retrieveBankTransferSuccess,
  retrieveBankTransferError,

  updateBankTransfer,
  updateBankTransferSuccess,
  updateBankTransferError,
  retrieveBankTransactionOptions,
  retrieveBankTransactionOptionsSuccess,
  retrieveBankTransactionOptionsError,
  retrieveBankTransferOptions,
  retrieveBankTransferOptionsSuccess,
  retrieveBankTransferOptionsError,
  bulkCreateTransactions,
  bulkCreateTransactionsSuccess,
  bulkCreateTransactionsError,
  getShareTransaction,
  getShareTransactionError,
  getShareTransactionSuccess,
  sendEmailTransaction,
  sendEmailTransactionSuccess,
  sendEmailTransactionError,
  importTransactions,
  importTransactionsError,
  importTransactionsSuccess,
  bulkCreatePayments,
  bulkCreatePaymentsError,
  bulkCreatePaymentsSuccess,
  importPayments,
  importPaymentsError,
  importPaymentsSuccess,
  openShareTransactionDialog,
  openShareTransactionDialogError,
  openShareTransactionDialogSuccess,
  clearTransactionState,
} = transactionSlice.actions;

export const selectAllTransactions = () =>
  useAppSelector((state) => state.transaction.transactions);

export const selectCurrentTransaction = () =>
  useAppSelector((state) => state.transaction.currentTransaction);
export const selectTransactionCustomFields = () =>
  useAppSelector((state) => state.transaction.customFields);

export const selectretrieveAllTransactionsLoading = () =>
  useAppSelector((state) => state.transaction.retrieveAllTransactionsLoading);
export const selectretrieveAllTransactionsError = () =>
  useAppSelector((state) => state.transaction.retrieveAllTransactionsError);

//create
export const selectCreateTransactionLoading = () =>
  useAppSelector((state) => state.transaction.createTransactionLoading);
export const selectCreateTransactionError = () =>
  useAppSelector((state) => state.transaction.createTransactionError);

//retrieve options
export const selectTransactionsOptions = () =>
  useAppSelector((state) => state.transaction.options);
export const selectTransactionsOptionsLoading = () =>
  useAppSelector(
    (state) => state.transaction.retrieveTransactionOptionsLoading
  );
export const selectTransactionOptionsError = () =>
  useAppSelector((state) => state.transaction.retrieveTransactionOptionsError);

export const selectRetrieveTransactionLoading = () =>
  useAppSelector((state) => state.transaction.retrieveTransactionLoading);

export const selectRemoveTransactionLoading = () =>
  useAppSelector((state) => state.transaction.removeTransactionLoading);

export const selectVoidTransactionLoading = () =>
  useAppSelector((state) => state.transaction.voidTransactionLoading);

export const selectUpdateTransactionLoading = () =>
  useAppSelector((state) => state.transaction.updateTransactionLoading);

export const selectRetrieveTransactionPaymentOptionsLoading = () =>
  useAppSelector(
    (state) => state.transaction.retrieveTransactionPaymentOptionsLoading
  );

export const selectCreateTransactionPaymentLoading = () =>
  useAppSelector((state) => state.transaction.createTransactionPaymentLoading);

export const selectRetrieveTransactionPaymentLoading = () =>
  useAppSelector(
    (state) => state.transaction.retrieveTransactionPaymentLoading
  );

export const selectUpdateTransactionPaymentLoading = () =>
  useAppSelector((state) => state.transaction.updateTransactionPaymentLoading);

export const selectRetrieveCreditNoteLoading = () =>
  useAppSelector((state) => state.transaction.retrieveCreditNoteLoading);

export const selectRetrieveCreditNoteOptionsLoading = () =>
  useAppSelector((state) => state.transaction.retrieveCreditNoteOptionsLoading);

export const selectCreateCreditNoteLoading = () =>
  useAppSelector((state) => state.transaction.createCreditNoteLoading);

export const selectUpdateCreditNoteLoading = () =>
  useAppSelector((state) => state.transaction.updateCreditNoteLoading);

export const selectCreateBankTransactionLoading = () =>
  useAppSelector((state) => state.transaction.createBankTransactionLoading);

export const selectUpdateBankTransactionLoading = () =>
  useAppSelector((state) => state.transaction.updateBankTransactionLoading);

export const selectUpdateBankTransferLoading = () =>
  useAppSelector((state) => state.transaction.updateBankTransferLoading);

export const selectRetrieveBankTransactionLoading = () =>
  useAppSelector((state) => state.transaction.retrieveBankTransactionLoading);

export const selectRetrieveBankTransferLoading = () =>
  useAppSelector((state) => state.transaction.retrieveBankTransferLoading);

export const selectRetrieveBankTransactionOptionsLoading = () =>
  useAppSelector(
    (state) => state.transaction.retrieveBankTransactionOptionsLoading
  );

export const selectRetrieveBankTransferOptionsLoading = () =>
  useAppSelector(
    (state) => state.transaction.retrieveBankTransferOptionsLoading
  );

export const selectBulkCreateTransactionsLoading = () =>
  useAppSelector((state) => state.transaction.bulkCreateTransactionLoading);

export const selectGetShareTransactionLoading = () =>
  useAppSelector((state) => state.transaction.getShareTransactionLoading);

export const selectShareTransaction = () =>
  useAppSelector((state) => state.transaction.shareTransaction);

export const selectTransactionFormDesign = () =>
  useAppSelector((state) => state.transaction.formDesign);
// export const testing = () => {
//   const result = Object.keys(initialState).reduce((result, stateKey) => {
//     return {
//       ...result,
//       [stateKey]: useAppSelector((state) => state.transaction[stateKey]),
//     };
//   }, {});
//   return result;
// };

export const selectSendEmailTransactionLoading = () =>
  useAppSelector((state) => state.transaction.sendEmailTransactionLoading);

export const selectImportTransactionsLoading = () =>
  useAppSelector((state) => state.transaction.importTransactionsLoading);

export const selectBulkCreatePaymentsLoading = () =>
  useAppSelector((state) => state.transaction.bulkCreatePaymentsLoading);

export const selectOpenShareDialogLoading = () =>
  useAppSelector(
    (state) => state.transaction.openShareTransactionDialogLoading
  );

const transactionReducer = transactionSlice.reducer;

export default transactionReducer;
