export const simpleM2MOrganize = (originList, updatedList, primaryField) => {
  const originIdsList = originList.map((item) => item[primaryField]);

  const removedList = originIdsList.filter((item) => {
    return !updatedList.includes(item);
  });

  const removedObject = removedList.length > 0 ? { removed: removedList } : {};

  const addedList = updatedList.reduce((result, item) => {
    if (!originIdsList.includes(item)) {
      result.push(item[primaryField]);
    }
    return result;
  }, []);
  const addedObject =
    addedList.length > 0
      ? {
          added: addedList,
        }
      : {};
  const results = {
    ...removedObject,
    ...addedObject,
  };
  return results;
};
