import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

export const useNavigation = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const rawLocation = useLocation();
  const location = rawLocation.pathname.split("/")[1];
  const subLocation = rawLocation.pathname.split("/")[2];
  const actionLocation = rawLocation.pathname.split("/")[3];
  const category = searchParams.get("category");
  const subCategory = searchParams.get("sub_category");
  const handleNavigate = (route) => navigate(route);

  return { location, subLocation, actionLocation, handleNavigate };
};
