import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { InputToggleButton } from "../Input/InputToggleButton";

export const FormInputToggleButton = ({
  formOnChange = null,
  formValue = null,
  list = [],
  defaultValue,
  disabled,
  ...props
}) => {
  return (
    <Controller
      {...props}
      // shouldUnregister
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }) => {
        return (
          <InputToggleButton
            value={formValue ? formValue : value}
            onChange={(newValue) => {
              if (formOnChange) {
                formOnChange(newValue);
              }
              onChange(newValue);
            }}
            list={list}
            disabled={disabled}
          />
        );
      }}
    />
  );
};

FormInputToggleButton.propTypes = {
  formOnChange: PropTypes.any,
  formValue: PropTypes.any,
  list: PropTypes.any,
  defaultValue: PropTypes.any,
  disabled: PropTypes.any,
};
