import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import {
  Autocomplete,
  Box,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Button,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  AddCircleOutlineRounded,
  HelpOutlineRounded,
  HighlightOffRounded,
  KeyboardArrowDownRounded,
  SearchRounded,
  WarningRounded,
} from "@mui/icons-material";
import { InputTextType } from "../../enums/components/InputTextType";

export const InputCurrency = ({
  buttonProps,
  options,
  textType = InputTextType.Normal,
  name,
  isDisabled,
  value,
  transform,
  onChange,
  onBlur,
  inputProps,
  tooltips,
  groupBy = undefined,
  minWidth,
  maxWidth,
  fullWidth = true,
  label,
  required,
  fieldRef,
  error,
  helperText,
  variant,
  disabledErrorText,
  placeholder = "",
  getOptionDisabled = null,
  disableClearable = true,
  formOnClear = null,
}) => {
  const [isInputFocused, setIsInputFocused] = useState(false);

  /**
   * * listen to focus element changes to render icon
   */
  const handleActiveElementChange = () => {
    setIsInputFocused(document.activeElement.id === `auto-complete-${name}`);
  };

  useEffect(() => {
    document.addEventListener("focus", handleActiveElementChange, true);
    document.addEventListener("blur", handleActiveElementChange, true);

    return () => {
      document.removeEventListener("focus", handleActiveElementChange, true);
      document.removeEventListener("blur", handleActiveElementChange, true);
    };
  }, []);

  const handleChange = (payload) => {
    if (payload)
      transform ? onChange(transform(payload.value)) : onChange(payload.value);
  };

  const handleBlur = () => {
    const trimmedValue = value && value?.trim ? value.trim() : value;
    onChange({ target: { value: trimmedValue } });
    onBlur && onBlur();
  };

  // Handle jest test ID and text transformation
  const setTextTransform = useMemo(() => {
    if (textType === InputTextType.Uppercase) return "uppercase";
    if (textType === InputTextType.Capitalize) return "capitalize";
    return "unset";
  }, [textType]);

  const inputTextProps = {
    style: { textTransform: setTextTransform, paddingTop: "0px", minWidth:"50px" },
    "data-testid": `${name}DropdownSearch`,
    ...inputProps,
  };

  // Handle render helper text and error text
  const helperHandling = () => {
    if (helperText && !error)
      return (
        <Typography
          className="helper"
          variant="remark"
          color={({ palette }) => palette.text.light}
          sx={{ marginLeft: "10px" }}
        >
          {helperText}
        </Typography>
      );
    if (error && !disabledErrorText)
      return (
        <Typography
          variant="bodyText"
          color={({ palette }) => palette.snack.error}
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <WarningRounded
            sx={({ palette, icons }) => ({
              ...icons.standard,
              color: palette.snack.error,
              pointerEvents: "none",
            })}
          />
          {error}
        </Typography>
      );
    return null;
  };

  const tooltipsAdornmentComponent = tooltips ? (
    <Tooltip title={tooltips}>
      <IconButton sx={{ position: "absolute", right: 0, marginRight: "-40px" }}>
        <HelpOutlineRounded sx={({ icons }) => ({ ...icons.standard })} />
      </IconButton>
    </Tooltip>
  ) : null;

  const renderEndAdornment = (params) => {
    return (
      <InputAdornment position="end">
        {params || null}
        {tooltipsAdornmentComponent}
      </InputAdornment>
    );
  };

  const renderPopupIcon = isInputFocused ? (
    <SearchRounded
      sx={({ icons }) => ({
        fontSize: "1.5rem",
        position: "absolute",
        right: "0px",
        bottom: "-22px",
        ...icons.standard,
      })}
    />
  ) : (
    <KeyboardArrowDownRounded
      sx={({ icons }) => ({
        fontSize: "1.5rem",
        position: "absolute",
        right: "0px",
        bottom: "-22px",
        ...icons.standard,
      })}
    />
  );

  const shouldRenderMenuButton = buttonProps ? (
    <Box component="div" role="button" justifyContent="center">
      <Button
        variant="text"
        className="MuiButton-text-plain"
        direction="row"
        sx={({ palette }) => ({
          height: "unset",
          width: 1,
          padding: "12px 16px",
          alignItems: "center",
          justifyContent: "space-between",
          cursor: "pointer",
          borderBottom: `1px solid ${palette.border.main}`,
          ":hover": {
            backgroundColor: palette.primary.light,
          },
        })}
        onMouseDown={buttonProps.onClick}
      >
        <Typography
          variant="bodyText"
          sx={({ palette }) => ({
            color: palette.primary.main,
          })}
        >
          {buttonProps.label}
        </Typography>
        <AddCircleOutlineRounded
          sx={({ icons, palette }) => ({
            ...icons.standard,
            color: palette.primary.main,
          })}
        />
      </Button>
    </Box>
  ) : null;

  const renderPopperComponent = ({ children }) => {
    return (
      <Paper
        sx={{
          boxShadow:
            "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
          ul: {
            maxHeight: "250px",
            padding: 0,
            ".MuiAutocomplete-option": {
              padding: "10px 16px",
            },
          },
        }}
      >
        {shouldRenderMenuButton}
        {children}
      </Paper>
    );
  };
  //* if got group by the value will have spacing
  const renderMenuOptions = (item) => {
    return (
      <MenuItem {...item} sx={{ textIndent: groupBy ? "20px" : "0px" }}>
        {item.key}
      </MenuItem>
    );
  };

  return (
    <Autocomplete
      id={`auto-complete-${name}`}
      onChange={(event, value, reason) => {
        if (reason === "clear" && formOnClear) {
          formOnClear();
        }
        handleChange(value);
      }}
      disablePortal
      options={options}
      getOptionLabel={(option) => {
        if (option.label) {
          return option.label;
        }
        if (option.value) {
          return option.value;
        }
        const findOption = options.find((option) => option.value === value);
        if (findOption) {
          return findOption.label || findOption.value;
        } else {
          return null;
        }
      }}
      getOptionDisabled={(option) => {
        return getOptionDisabled ? getOptionDisabled(option) : false;
      }}
      isOptionEqualToValue={(option, value) => {
        return (
          option.value === value ||
          value === "" ||
          option === value ||
          (option.length === 0 && value === null)
        );
      }}
      renderOption={renderMenuOptions}
      sx={{ minWidth: minWidth, maxWidth: maxWidth }}
      fullWidth={fullWidth}
      disabled={isDisabled}
      disableClearable={disableClearable}
      clearOnBlur
      value={value || null}
      clearIcon={<HighlightOffRounded />}
      popupIcon={renderPopupIcon}
      groupBy={groupBy}
      PaperComponent={renderPopperComponent}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            variant={variant || "filled"}
            autoComplete="off"
            aria-autocomplete="none"
            error={!!error}
            onBlur={handleBlur}
            onChange={({ target }) => {
              handleChange(target.value);
            }}
            value={value || ""}
            required={required}
            label={label}
            inputRef={fieldRef}
            helperText={helperHandling()}
            placeholder={placeholder}
            inputProps={{
              ...params.inputProps,
              ...inputTextProps,
            }}
            InputProps={{
              ...params.InputProps,
              style: { height: "50px" },
              endAdornment: renderEndAdornment(params.InputProps.endAdornment),
            }}
          />
        );
      }}
    />
  );
};

InputCurrency.propTypes = {
  buttonProps: PropTypes.object,
  options: PropTypes.array,
  textType: PropTypes.any,
  name: PropTypes.any,
  isDisabled: PropTypes.any,
  value: PropTypes.any,
  onChange: PropTypes.any,
  onBlur: PropTypes.any,
  transform: PropTypes.any,
  inputProps: PropTypes.any,
  tooltips: PropTypes.any,
  groupBy: PropTypes.func,
  minWidth: PropTypes.any,
  maxWidth: PropTypes.any,
  fullWidth: PropTypes.any,
  label: PropTypes.any,
  required: PropTypes.any,
  fieldRef: PropTypes.any,
  error: PropTypes.any,
  helperText: PropTypes.any,
  InputProps: PropTypes.any,
  variant: PropTypes.any,
  disabledErrorText: PropTypes.any,
  placeholder: PropTypes.any,
  getOptionDisabled: PropTypes.any,
  disableClearable: PropTypes.any,
  formOnClear: PropTypes.any,
};
