import { call, delay, put, takeLatest } from "redux-saga/effects";
import { 
    bulkCreateClientAgent, 
    bulkCreateClientAgentError, 
    bulkCreateClientAgentSuccess, 

    bulkRemoveClientAgent, 
    bulkRemoveClientAgentError, 
    bulkRemoveClientAgentSuccess, 

    createClientAgent, 
    createClientAgentError, 
    createClientAgentSuccess, 

    deleteClientAgent, 
    deleteClientAgentError, 
    deleteClientAgentSuccess,

    getAllClientAgent, 
    getAllClientAgentError, 
    getAllClientAgentSuccess, 

    getClientAgent, 
    getClientAgentError, 
    getClientAgentsSuccess, 

    importClientAgent, 
    importClientAgentError, 
    importClientAgentSuccess, 

    insertClientAgents, 
    insertClientAgentsError, 
    insertClientAgentsSuccess, 
    
    removeClientAgents, 
    removeClientAgentsError, 
    removeClientAgentsSuccess 
} from "../stores/slices/clientAgentSlices";

import { ApiRoute } from "../enums/ApiRoute";
import { API } from "../http-common";

function* importClientAgentSaga({payload}) {
    try{
        const { data } = yield call(API.post, ApiRoute.clientAgent.importClientAgent, payload);
        yield put(importClientAgentSuccess(data));
    } catch (error){
        yield put(importClientAgentError(error));
        console.error(error);
    }
}

function* createClientAgentSaga({payload}) {
    const { ClientID = null, ...otherPayload } = payload;
    try{
        yield call(API.post, ApiRoute.clientAgent.createClientAgent, otherPayload);
        if(ClientID){
            yield put(getClientAgent(payload));
            yield put(createClientAgentSuccess(otherPayload));
        } else {
            yield put(createClientAgentSuccess(otherPayload));
        }
      
       
    } catch (error){
        yield put(createClientAgentError(error));
        console.error(error);
    }
}

function* getAllClientAgentSaga({payload}) {
    try{
        const { data } = yield call(API.post, ApiRoute.clientAgent.getAllClientAgent, payload);
        yield put(getAllClientAgentSuccess(data));
    } catch (error){
        yield put(getAllClientAgentError(error));
        console.error(error);
    }
}


function* deleteClientAgentSaga({payload}) {
    const { ClientID = null, ...otherPayload } = payload;
    try{
        yield call(API.post, ApiRoute.clientAgent.deleteClientAgent, otherPayload);
        if(ClientID){
            yield put(getClientAgent(payload));
            yield put(deleteClientAgentSuccess(otherPayload));
        } else {
            yield put(deleteClientAgentSuccess(otherPayload));
        }
    } catch (error){
        yield put(deleteClientAgentError(error));
        console.error(error);
    }
}

function* bulkCreateClientAgentSaga({payload}) {
    try{
        const { data } = yield call(API.post, ApiRoute.clientAgent.bulkCreateClientAgent, payload);
        yield put(bulkCreateClientAgentSuccess(data));
    } catch (error){
        yield put(bulkCreateClientAgentError(error));
        console.error(error);
    }
}

function* bulkRemoveClientAgentSaga({payload}) {
    try{
        const { data } = yield call(API.post, ApiRoute.clientAgent.bulkRemoveClientAgent, payload);
        yield put(bulkRemoveClientAgentSuccess(data));
    } catch (error){
        yield put(bulkRemoveClientAgentError(error));
        console.error(error);
    }
}

function* insertClientAgentsSaga({payload}) {
    try{
        yield call(API.post, ApiRoute.clientAgent.insertClientAgents, payload);
        yield put(insertClientAgentsSuccess(payload));
    } catch (error){
        yield put(insertClientAgentsError(error));
        console.error(error);
    }
}

function* removeClientAgentsSaga({payload}) {
    try{
        yield call(API.post, ApiRoute.clientAgent.removeClientAgents, payload);
        yield put(removeClientAgentsSuccess(payload));
    } catch (error){
        yield put(removeClientAgentsError(error));
        console.error(error);
    }
}

function* getClientAgentSaga({payload}) {
    try{
        const { data } = yield call(API.post, ApiRoute.clientAgent.getClientAgent, payload);
        yield put(getClientAgentsSuccess(data));
    } catch (error){
        yield put(getClientAgentError(error));
        console.error(error);
    }
}

export function* clientAgentSagaWatcher(){
    yield takeLatest(importClientAgent.type, importClientAgentSaga);
    yield takeLatest(createClientAgent.type, createClientAgentSaga);
    yield takeLatest(getAllClientAgent.type, getAllClientAgentSaga);
    yield takeLatest(deleteClientAgent.type, deleteClientAgentSaga);
    yield takeLatest(bulkCreateClientAgent.type, bulkCreateClientAgentSaga);
    yield takeLatest(bulkRemoveClientAgent.type, bulkRemoveClientAgentSaga);
    yield takeLatest(insertClientAgents.type, insertClientAgentsSaga);
    yield takeLatest(removeClientAgents.type, removeClientAgentsSaga);
    yield takeLatest(getClientAgent.type, getClientAgentSaga);
}