import { createSlice, current } from "@reduxjs/toolkit"

import { useAppSelector } from "../../hooks/storeHooks";


//initial state
const initialState = {

    //loading
    retrieveAllAgentLoading : false,
    createCompanyAgentLoading : false,
    retrieveCompanyAgentLoading : false,
    updateCompanyAgentLoading : false,
    updateCompanyAgentRoleLoading : false,
    updateCompanyAgentMemberRoleLoading : false,
    deleteCompanyAgentLoading : false,
    sendAgentInvitationLoading : false,
    checkAgentExistLoading : false,


    //error
    retrieveAllAgentError : undefined,
    createCompanyAgentError : undefined,
    retrieveCompanyAgentError : undefined,
    updateCompanyAgentError : undefined,
    updateCompanyAgentRoleError : undefined,
    updateCompanyAgentMemberRoleError : undefined,
    deleteCompanyAgentError : undefined,
    sendAgentInvitationError : undefined,
    checkAgentExistError : undefined,

    //data
    companyAgent : [],
    companyAgentCheck : false,
    allCompanyAgent : [],
   
}

//slices
const companyAgentSlices = createSlice({
    name: "companyAgents",
    initialState,
    reducers : {
        retrieveAllAgent(state){
            state.retrieveAllAgentLoading = true;
        },
        retrieveAllAgentSuccess(state, {payload}){
            const currentState = payload;
            state.allCompanyAgent = currentState;

            state.retrieveAllAgentLoading = false;
        },
        retrieveAllAgentError(state, {payload}){
            const errorState = payload;
            state.retrieveAllAgentError = errorState;

            state.retrieveAllAgentLoading = false;
        },

        checkAgentExist(state){
            state.checkAgentExistLoading = true;
        },
        checkAgentExistSuccess(state, {payload}){
            const currentState = payload;
            state.companyAgentCheck = currentState;

            state.checkAgentExistLoading = false;
        },
        checkAgentExistError(state, {payload}){
            const errorState = payload;
            state.checkAgentExistError = errorState;

            state.checkAgentExistLoading = false;
        },

        createCompanyAgent(state){
            state.createCompanyAgentLoading = true;
        },
        createCompanyAgentSuccess(state, {payload}){
            // const currentState = payload;
            // state.companyAgent = currentState;

            state.createCompanyAgentLoading = false;
        },
        createCompanyAgentError(state, {payload}){
            const errorState = payload;
            state.createCompanyAgentError = errorState;

            state.createCompanyAgentLoading = false;
        },

        retrieveCompanyAgent(state){
            state.retrieveCompanyAgentLoading = true;
        },
        retrieveCompanyAgentSuccess(state, {payload}){
            const currentState = payload;
            state.companyAgent = currentState;

            state.retrieveCompanyAgentLoading = false;
        },
        retrieveCompanyAgentError(state, {payload}){
            const errorState = payload;
            state.retrieveCompanyAgentError = errorState;

            state.retrieveCompanyAgentLoading = false;
        },

        updateCompanyAgent(state){
            state.updateCompanyAgentLoading = true;
        },
        updateCompanyAgentSuccess(state, {payload}){
            const currentState = current(state.companyAgent);
            const updatedState = currentState.map((item) => {
                if (item.CompanyAgentID === payload.CompanyAgentID) {
                    return {
                        ...item,
                        ...payload,
                    };
                }
                return item;
            });
            state.companyAgent = updatedState;
            state.updateCompanyAgentLoading = false;
        },
        updateCompanyAgentError(state, {payload}){
            const errorState = payload;
            state.updateCompanyAgentError = errorState;

            state.updateCompanyAgentLoading = false;
        },

        updateCompanyAgentRole(state){
            state.updateCompanyAgentRoleLoading = true;
        },
        updateCompanyAgentRoleSuccess(state, {payload}){
            const currentState = current(state.companyAgent);
            const updatedState = currentState.map((item) => {
                if (item.CompanyAgentID === payload.CompanyAgentID) {
                    return {
                        ...item,
                        ...payload,
                    };
                }
                return item;
            });
            state.companyAgent = updatedState;
            state.updateCompanyAgentRoleLoading = false;
        },
        updateCompanyAgentRoleError(state, {payload}){
            const errorState = payload;
            state.updateCompanyAgentRoleError = errorState;

            state.updateCompanyAgentRoleLoading = false;
        },

        updateCompanyAgentMemberRole(state){
            state.updateCompanyAgentMemberRoleLoading = true;
        },
        updateCompanyAgentMemberRoleSuccess(state, {payload}){
            const currentState = current(state.companyAgent);
            const updatedState = currentState.map((item) => {
                if (item.UserID === payload.UserID) {
                    return {
                        ...item,
                        RoleID : payload.NewRoleID,
                    };
                }
                return item;
            });
            state.companyAgent = updatedState;
            state.updateCompanyAgentMemberRoleLoading = false;
        },
        updateCompanyAgentMemberRoleError(state, {payload}){
            const errorState = payload;
            state.updateCompanyAgentMemberRoleError = errorState;

            state.updateCompanyAgentMemberRoleLoading = false;
        },

        deleteCompanyAgent(state){
            state.deleteCompanyAgentLoading = true;
        },
        deleteCompanyAgentSuccess(state, {payload}){
            const currentState = current(state.companyAgent);
            const updatedState = currentState.filter((item) => item.CompanyAgentID !== payload.CompanyAgentID);
            state.companyAgent = updatedState;

            state.deleteCompanyAgentLoading = false;
        },
        deleteCompanyAgentError(state, {payload}){
            const errorState = payload;
            state.deleteCompanyAgentError = errorState;

            state.deleteCompanyAgentLoading = false;
        },

        sendAgentInvitation(state){
            state.sendAgentInvitationLoading = true;
        },
        sendAgentInvitationSuccess(state, {payload}){
            // const currentState = payload;
            // state.companyAgent = currentState;

            state.sendAgentInvitationLoading = false;
        },
        sendAgentInvitationError(state, {payload}){
            const errorState = payload;
            state.sendAgentInvitationError = errorState;

            state.sendAgentInvitationLoading = false;
        },

        
    }
})

//export function
export const {
    createCompanyAgent,
    createCompanyAgentSuccess,
    createCompanyAgentError,

    retrieveCompanyAgent,
    retrieveCompanyAgentSuccess,
    retrieveCompanyAgentError,

    retrieveAllAgent,
    retrieveAllAgentSuccess,
    retrieveAllAgentError,

    updateCompanyAgent,
    updateCompanyAgentSuccess,
    updateCompanyAgentError,

    updateCompanyAgentRole,
    updateCompanyAgentRoleSuccess,
    updateCompanyAgentRoleError,
    
    updateCompanyAgentMemberRole,
    updateCompanyAgentMemberRoleSuccess,
    updateCompanyAgentMemberRoleError,

    deleteCompanyAgent,
    deleteCompanyAgentSuccess,
    deleteCompanyAgentError,
    
    sendAgentInvitation,
    sendAgentInvitationSuccess,
    sendAgentInvitationError,

    checkAgentExist,
    checkAgentExistSuccess,
    checkAgentExistError

} = companyAgentSlices.actions

//export selector

// data
export const selectCompanyAgent = () =>
  useAppSelector((state) => state.companyAgents.companyAgent);

export const selectAllCompanyAgent = () =>
  useAppSelector((state) => state.companyAgents.allCompanyAgent);

export const selectCompanyAgentCheck = () =>
  useAppSelector((state) => state.companyAgents.companyAgentCheck);

// loading
export const selectRetrieveAllAgentLoading = () =>
  useAppSelector((state) => state.companyAgents.retrieveAllAgentLoading);

export const selectCreateCompanyAgentLoading = () =>
  useAppSelector((state) => state.companyAgents.createCompanyAgentLoading);

export const selectRetrieveCompanyAgentLoading = () =>
  useAppSelector((state) => state.companyAgents.retrieveCompanyAgentLoading);

export const selectUpdateCompanyAgentLoading = () =>
  useAppSelector((state) => state.companyAgents.updateCompanyAgentLoading);

export const selectUpdateCompanyAgentRoleLoading = () =>
  useAppSelector((state) => state.companyAgents.updateCompanyAgentRoleLoading);

export const selectUpdateCompanyAgentMemberRoleLoading = () =>
  useAppSelector((state) => state.companyAgents.updateCompanyAgentMemberRoleLoading);

export const selectDeleteCompanyAgentLoading = () =>
  useAppSelector((state) => state.companyAgents.deleteCompanyAgentLoading);

export const selectSendAgentInvitationLoading = () =>
  useAppSelector((state) => state.companyAgents.sendAgentInvitationLoading);

export const selectCheckAgentExistLoading = () =>
  useAppSelector((state) => state.companyAgents.checkAgentExistLoading);

// error
export const selectRetrieveAllAgentError = () =>
  useAppSelector((state) => state.companyAgents.retrieveAllAgentError);

export const selectCreateCompanyAgentError = () =>
  useAppSelector((state) => state.companyAgents.createCompanyAgentError);

export const selectRetrieveCompanyAgentError = () =>
  useAppSelector((state) => state.companyAgents.retrieveCompanyAgentError);

export const selectUpdateCompanyAgentError = () =>
  useAppSelector((state) => state.companyAgents.updateCompanyAgentError);

export const selectUpdateCompanyAgentRoleError = () =>
  useAppSelector((state) => state.companyAgents.updateCompanyAgentRoleError);

export const selectUpdateCompanyAgentMemberRoleError = () =>
  useAppSelector((state) => state.companyAgents.updateCompanyAgentMemberRoleError);

export const selectDeleteCompanyAgentError = () =>
  useAppSelector((state) => state.companyAgents.deleteCompanyAgentError);

export const selectSendAgentInvitationError = () =>
  useAppSelector((state) => state.companyAgents.sendAgentInvitationError);

export const selectCheckAgentExistError = () =>
  useAppSelector((state) => state.companyAgents.checkAgentExistError);


//export reducer
const companyAgentReducer = companyAgentSlices.reducer;

export default companyAgentReducer;
