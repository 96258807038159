export const TableRouteConverter = {
    quotations: "QUOTATIONS",
    "sale-orders": "SALE_ORDERS",
    "delivery-orders": "DELIVERY_ORDERS",
    invoices: "INVOICES",
    "credit-notes": "CREDIT_NOTES",
    "purchase-orders": "PURCHASE_ORDERS",
    "goods-received-notes": "GOODS_RECEIVED_NOTES",
    bills: "BILLS",
    payments: "PAYMENTS",
    refunds: "REFUNDS",
    "money-in": "MONEY_IN",
    "money-out": "MONEY_OUT",
};
  