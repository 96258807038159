import React from "react";
import { Dialog, Stack, Button, Typography } from "@mui/material";
import { ErrorRounded, MailOutlineRounded } from "@mui/icons-material";
import { convertSxToArray } from "../../util/mui/convertSxToArray";

export const ConfirmationDialog = ({
  open,
  handleClose,
  handleCancel = null,
  handleConfirm,
  title,
  content,
  stackChildren,
  maxWidth = "xs",
  type,
  childrenSx,
  disableCancel = false,
  disableConfirm = false,
}) => {
  const iconRenderer = () => {
    switch (type) {
      case "delete":
        return (
          <ErrorRounded
            sx={({ palette, icons }) => ({
              ...icons.large,
              color: palette.chip.error,
            })}
          />
        );
      case "invitation":
        return (
          <MailOutlineRounded
            sx={({ icons }) => ({
              ...icons.large,
            })}
          />
        );
      default:
        break;
    }
  };

  const colorRenderer = (type, palette) => {
    switch (type) {
      case "delete":
        return palette.chip.error;
      default:
        return palette.text.main;
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      maxWidth={maxWidth}
      fullWidth
      PaperProps={{
        sx: { padding: "24px", gap: 2 },
      }}
    >
      <Stack
        direction="row"
        spacing={1.5}
        alignItems="center"
        marginLeft={"28px"}
      >
        {iconRenderer()}
        <Typography
          variant="displayH3"
          sx={({ palette }) => ({
            color: colorRenderer(type, palette),
          })}
        >
          {title}
        </Typography>
      </Stack>
      <Stack sx={[...convertSxToArray(childrenSx)]}>
        <Typography marginLeft={"70px"} variant="bodyText">
          {content}
        </Typography>
        {stackChildren}
      </Stack>
      <Stack
        direction="row"
        spacing={1.5}
        justifyContent="flex-end"
        marginTop={"12px"}
      >
        {!disableCancel ? (
          <Button
            className="MuiButton-cancel"
            variant="contained"
            onClick={handleCancel ? handleCancel : handleClose}
            sx={{
              backgroundColor: "#FFFFFF !important",
              borderColor: "#959C94 !important",
              color: "#788077 !important",
            }}
          >
            Cancel
          </Button>
        ) : (
          <></>
        )}
        {!disableConfirm ? (
          <Button variant="contained" onClick={handleConfirm}>
            Confirm
          </Button>
        ) : (
          <></>
        )}
      </Stack>
    </Dialog>
  );
};
