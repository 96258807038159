import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { InputCurrency } from "../Input/InputCurrency";

export const FormInputCurrency = ({
  label,
  buttonProps,
  options,
  defaultValue,
  textType,
  tooltips,
  type,
  groupBy = null,
  isDisabled,
  inputProps,
  minWidth,
  maxWidth,
  fullWidth,
  required,
  transform,
  helperText,
  variant = null,
  disabledErrorText = false,
  placeholder = "",
  formOnChange = null,
  getOptionDisabled = null,
  disableClearable = true,
  formOnClear = null,
  ...props
}) => {
  return (
    <Controller
      {...props}
      // shouldUnregister
      defaultValue={defaultValue}
      render={({
        field: { onChange, onBlur, value, ref },
        fieldState: { error },
      }) => {
        return (
          <InputCurrency
            type={type}
            value={value || ""}
            label={label}
            buttonProps={buttonProps}
            options={options}
            textType={textType}
            tooltips={tooltips}
            onChange={
              formOnChange
                ? (payload) => {
                    onChange(payload);
                    formOnChange(payload);
                  }
                : onChange
            }
            onBlur={onBlur}
            error={error?.message}
            helperText={helperText}
            name={props.name}
            isDisabled={isDisabled}
            transform={transform}
            inputProps={inputProps}
            minWidth={minWidth}
            maxWidth={maxWidth}
            fullWidth={fullWidth}
            required={required}
            fieldRef={ref}
            variant={variant}
            groupBy={groupBy}
            disabledErrorText={disabledErrorText}
            placeholder={placeholder}
            getOptionDisabled={(option) => {
              if (getOptionDisabled) {
                return getOptionDisabled(option);
              }
              return false;
            }}
            formOnClear={formOnClear}
            disableClearable={disableClearable}
          />
        );
      }}
    />
  );
};

FormInputCurrency.propTypes = {
  label: PropTypes.any,
  buttonProps: PropTypes.object,
  options: PropTypes.array,
  defaultValue: PropTypes.any,
  textType: PropTypes.any,
  tooltips: PropTypes.any,
  groupBy: PropTypes.func,
  type: PropTypes.any,
  isDisabled: PropTypes.any,
  inputProps: PropTypes.any,
  minWidth: PropTypes.any,
  fullWidth: PropTypes.any,
  maxWidth: PropTypes.any,
  required: PropTypes.any,
  transform: PropTypes.any,
  name: PropTypes.string,
  helperText: PropTypes.any,
  hiddenLabel: PropTypes.any,
  value: PropTypes.any,
  variant: PropTypes.any,
  disabledErrorText: PropTypes.any,
  placeholder: PropTypes.any,
  getOptionDisabled: PropTypes.any,
  formOnChange: PropTypes.any,
  disableClearable: PropTypes.any,
  formOnClear: PropTypes.any,
};
