import { Route } from "react-router-dom"
import { crmRoutes } from "../enums/Route"
import ProtectedRoute from "./ProtectedRoute"
import { Fragment } from "react"
import ClientGroup from "../containers/clients/ClientGroup"

export const ClientGroupRoute = ({currSaState}) => (
    <Fragment>
        {currSaState &&
            <Route 
                path={crmRoutes.client.clientGroup}
                element={
                    <ProtectedRoute>
                        <ClientGroup />
                    </ProtectedRoute>
                }
            />
        }
    </Fragment> 
)

